import { Box, ButtonBase, Fade, Typography } from '@material-ui/core'
import React from 'react'

import moment from 'moment'

import { Messages } from 'objects/Messages'

import ConversationAvatar from 'components/Messages/Conversation/ConversationAvatar'

import { Node } from 'slate'

import { AuthContext } from 'context/AuthContext'

import { TRANSITION_LENGTH } from 'data/Constants'

// styles
import useStyles from '../styles'

export default function Conversation({ avatar, lu, messages, name, onSelect, photo, selected, type, mType, views, ...props }) {
    var classes = useStyles()

    const { user } = React.useContext(AuthContext)

    function getRelativeTime(cd) {
        if (cd) {
            let relative = {
                sameDay: 'h:mm a',
                lastDay: '[Yesterday]',
                lastWeek: 'dddd',
                sameElse: 'MMMM D',
            }
            if (cd.seconds || cd._seconds) {
                let date = new Date(0)
                date.setUTCSeconds(cd._seconds ? cd._seconds : cd.seconds)

                return moment(date).calendar(null, relative)
            } else {
                return moment(cd).calendar(null, relative)
            }
        }

        return ''
    }

    function getMostRecentMessage() {
        if (messages && messages.length > 0) {
            let message = messages[messages.length - 1].message
            let val = message.map(n => Node.string(n)).join('\n')

            return val
        }
        return 'No recent messages'
    }

    function getConversation() {
        return (
            <Box style={{ width: '100%' }} {...props}>
                <Box className={classes.conversationOuter}>
                    <Box style={{ display: 'flex', flexDirection: 'row' }}>
                        <Box
                            style={{
                                marginRight: 8,
                                marginTop: 4,
                                marginBottom: 4,
                            }}
                        >
                            <ConversationAvatar
                                avatar={avatar}
                                selected={selected}
                                channel={type === Messages.TYPE_CHANNEL ? 'true' : undefined}
                                src={photo}
                                size={40}
                                unread={Array.isArray(views) && user && !views.includes(user.getId()) ? 'true' : undefined}
                            />
                        </Box>
                        <Box className={classes.messageConversationBody}>
                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography
                                    variant="h6"
                                    component="p"
                                    style={{
                                        fontWeight: 500,
                                        lineHeight: 1.125,
                                        flexGrow: 1,
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {name}
                                </Typography>
                                <Typography
                                    style={{
                                        lineHeight: 1.125,
                                        flexShrink: 0
                                    }}
                                >
                                    {getRelativeTime(lu)}
                                </Typography>
                            </Box>
                            <Typography style={{ lineHeight: 1.125, maxHeight: 36, overflow: 'hidden', wordBreak: 'break-word' }}>
                                {getMostRecentMessage()}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return selected ? (
        <Fade appear in timeout={TRANSITION_LENGTH}>
            {getConversation()}
        </Fade>
    ) : (
        <Fade appear in timeout={TRANSITION_LENGTH}>
            <ButtonBase onClick={onSelect} style={{ textAlign: 'inherit', display: 'flex', borderRadius: 4, width: '100%' }} aria-label={name}>
                {getConversation()}
            </ButtonBase>
        </Fade>
    )
}
