import React from 'react'
import { Box, IconButton } from '@material-ui/core'
import { CancelTwoTone as CloseFilledIcon } from '@material-ui/icons'

import { MessagesContext } from 'context/MessagesContext'

// styles
import useStyles from '../styles'

export default function AttachmentsBox(props) {
    var classes = useStyles()

    const { renderElement } = React.useContext(MessagesContext)

    let { attachments, history, onRemoveAttachment, style } = props

    return (
        <Box className={classes.attachmentsBox} style={style}>
            <Box style={{ height: 64, width: 0, paddingLeft: 8, paddingRight: 8 }} />
            {attachments.map((attachment, index) => (
                <Box key={attachment.type === 'events' || attachment.type === 'polls' ? attachment.id : attachment.giphyId}>
                    {attachment.type === 'image' && (
                        <Box style={{ marginLeft: 4, marginRight: 4, height: '6em', position: 'relative' }}>
                            <img className={classes.attachment} src={attachment.src} alt="" />
                            <IconButton style={{ position: 'absolute', top: 3, right: 3 }} size="small" onClick={() => onRemoveAttachment(index)}>
                                <CloseFilledIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    )}
                    {(attachment.type === 'events' || attachment.type === 'polls') && (
                        <Box style={{ marginLeft: 4, marginRight: 4, position: 'relative' }}>
                            {renderElement({ preview: true, element: attachment, history: history })}
                            {!attachment.loading && (
                                <IconButton style={{ position: 'absolute', top: 3, right: 3 }} size="small" onClick={() => onRemoveAttachment(index)}>
                                    <CloseFilledIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                    )}
                </Box>
            ))}
            <Box style={{ height: 64, width: 0, paddingLeft: 8, paddingRight: 8 }} />
        </Box>
    )
}
