import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    parent: {
        display: `flex`,
        overflow: 'visible',
    },
    boxWhite: {
        '&::before': {
            backgroundColor: 'white',
            backgroundImage: 'none !important',
        },
        '&::after': {
            backgroundColor: 'white',
            backgroundImage: 'none !important',
        },
        color: `${theme.palette.primary.main} !important`,
        boxShadow: theme.customShadows.widget,
    },
    boxOrangeColor: {
        color: `${theme.palette.orange.main} !important`,
    },
    boxGreenColor: {
        color: `${theme.palette.green.main} !important`,
    },
    boxBlueColor: {
        color: `${theme.palette.blue.main} !important`,
    },
    boxDarkorangeColor: {
        color: `${theme.palette.darkorange.main} !important`,
    },
    boxRedColor: {
        color: `${theme.palette.red.main} !important`,
    },
    boxGrayColor: {
        color: `${theme.palette.gray.dark} !important`,
    },
    boxBluegrayColor: {
        color: `${theme.palette.bluegray.main} !important`,
    },
    boxPurpleColor: {
        color: `${theme.palette.purple.main} !important`,
    },
    boxYellowColor: {
        color: `${theme.palette.yellow.main} !important`,
    },
    boxOrange: {
        '&::before': {
            backgroundImage: `linear-gradient(${theme.palette.orange.main}, ${theme.palette.orange.main}) !important`,
        },
        '&::after': {
            backgroundImage: `linear-gradient(${theme.palette.orange.main}, ${theme.palette.orange.main}) !important`,
        },
    },
    boxGreen: {
        '&::before': {
            backgroundImage: `linear-gradient(${theme.palette.green.main}, ${theme.palette.green.main}) !important`,
        },
        '&::after': {
            backgroundImage: `linear-gradient(${theme.palette.green.main}, ${theme.palette.green.main}) !important`,
        },
    },
    boxBlue: {
        '&::before': {
            backgroundImage: `linear-gradient(${theme.palette.blue.main}, ${theme.palette.blue.main}) !important`,
        },
        '&::after': {
            backgroundImage: `linear-gradient(${theme.palette.blue.main}, ${theme.palette.blue.main}) !important`,
        },
    },
    boxDarkorange: {
        '&::before': {
            backgroundImage: `linear-gradient(${theme.palette.orange.main}, ${theme.palette.orange.main}) !important`,
        },
        '&::after': {
            backgroundImage: `linear-gradient(${theme.palette.orange.main}, ${theme.palette.orange.main}) !important`,
        },
    },
    boxRed: {
        '&::before': {
            backgroundImage: `linear-gradient(${theme.palette.red.main}, ${theme.palette.red.main}) !important`,
        },
        '&::after': {
            backgroundImage: `linear-gradient(${theme.palette.red.main}, ${theme.palette.red.main}) !important`,
        },
    },
    boxGray: {
        '&::before': {
            backgroundImage: `linear-gradient(${theme.palette.gray.dark}, ${theme.palette.gray.dark}) !important`,
        },
        '&::after': {
            backgroundImage: `linear-gradient(${theme.palette.gray.dark}, ${theme.palette.gray.dark}) !important`,
        },
    },
    boxBluegray: {
        '&::before': {
            backgroundImage: `linear-gradient(${theme.palette.bluegray.main}, ${theme.palette.bluegray.main}) !important`,
        },
        '&::after': {
            backgroundImage: `linear-gradient(${theme.palette.bluegray.main}, ${theme.palette.bluegray.main}) !important`,
        },
    },
    boxYellow: {
        '&::before': {
            backgroundImage: `linear-gradient(${theme.palette.yellow.main}, ${theme.palette.yellow.main}) !important`,
        },
        '&::after': {
            backgroundImage: `linear-gradient(${theme.palette.yellow.main}, ${theme.palette.yellow.main}) !important`,
        },
    },
    boxPurple: {
        '&::before': {
            backgroundImage: `linear-gradient(${theme.palette.purple.main}, ${theme.palette.purple.main}) !important`,
        },
        '&::after': {
            backgroundImage: `linear-gradient(${theme.palette.purple.main}, ${theme.palette.purple.main}) !important`,
        },
    },
    boxSkewRight: {
        '&::after': {
            transform: 'skew(-15deg)',
        },
    },
    boxSkewLeft: {
        '&::before': {
            transform: 'skew(-15deg)',
        },
    },
    buttonSkewRight: {
        marginRight: 6,
    },
    buttonSkewLeft: {
        marginLeft: 6,
    },
    box: {
        position: 'relative',
        overflow: 'visible',
        borderRadius: 6,
        color: 'white',
        '&::before': {
            position: 'absolute',
            content: '""',
            top: 0,
            height: '100%',
            width: '55%',
            zIndex: 1,
            left: 0,
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
            backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
        },
        '&::after': {
            position: 'absolute',
            content: '""',
            top: 0,
            height: '100%',
            width: '55%',
            zIndex: 1,
            right: 0,
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
            borderLeft: 'none',
            backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main})`,
        },
    },
    fullWidthBox: {
        width: '100%',
    },
    buttonVertical: {
        flexDirection: 'row',
        width: '100%',
    },
    buttonVerticalInner: {
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        overflow: 'visible',
        position: 'relative',
        zIndex: 2,
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: 6,
    },
    titleVertical: {
        padding: 8,
    },
    title: {
        padding: 8,
        paddingLeft: 12,
    },
    titleIcon: {
        padding: 8,
        paddingLeft: 0,
    },
    /*
    style={{
        backgroundImage: `url(${AnnouncementsBackground})`,
    }}
    */
}))
