import { Box, Grid, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import React from 'react'

import app from 'firebase/app'

import Conversation from 'components/Messages/Conversation'
import UpdateConversation from 'components/Messages/UpdateConversation'
import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { MessagesContext } from 'context/MessagesContext'

import { Messages } from 'objects/Messages'

import { getPossibleMembers, unboxTS } from 'code/Helper'

import useStyles from 'pages/messages/styles'

const ChatConversationsList = ({ conversationId, history, ...props }) => {
    const classes = useStyles()

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)
    const { getAllConversations, markConversationAsViewed, createConversation } = React.useContext(MessagesContext)

    const [newConversation, setNewConversation] = React.useState(Messages.getNewChat().getRaw())

    const [calendarEvent, setCalendarEvent] = React.useState({ loading: false, data: null })

    let conversations = getAllConversations()

    const [state, setState] = React.useState({ makeNewConversation: false })
    function setMakeNewConversation(isTrue) {
        setState({ makeNewConversation: isTrue })
    }

    const makeNewConversation = state.makeNewConversation
    const viewConversations = !state.makeNewConversation

    React.useEffect(() => {
        if (props.newConversation) {
            const newChat = Messages.getNewChatFromNewConversation(props.newConversation)

            if (newChat) {
                let raw = newChat.getRaw()

                setNewConversation(raw)
                setMakeNewConversation(true)

                setCalendarEvent(ce => ({ ...ce, loading: true }))

                const db = app.firestore()
                db.collection('chapters')
                    .doc(user.getChapter())
                    .collection('events')
                    .doc(raw.event.id)
                    .get()
                    .then(function(doc) {
                        if (doc.exists) {
                            let data = doc.data()
                            setCalendarEvent(ce => ({ ...ce, loading: false, data: data }))
                            setNewConversation(convo => ({ ...convo, name: data.name, event: { ...convo.event, name: data.name } }))
                        } else {
                            setCalendarEvent(ce => ({ ...ce, loading: false }))
                        }
                    })
                    .catch(function(error) {
                        setCalendarEvent(ce => ({ ...ce, loading: false }))
                    })
            }

            // Update the URL
            history.push('/app/messages/')
        }
    }, [])

    let sortedConversations = Object.keys(conversations)
        .filter(a => !(conversations[a].exists === false))
        .sort((a, b) => unboxTS(conversations[b].lu) - unboxTS(conversations[a].lu))

    return (
        <Box>
            {viewConversations &&
                sortedConversations.map((convoId, index) => (
                    <Box key={convoId} style={{ position: 'relative' }}>
                        {index > 0 && <Box className={classes.conversationSeparator} />}
                        <Conversation
                            {...conversations[convoId]}
                            selected={conversationId === convoId}
                            onSelect={() => {
                                history.push(`/app/messages/${convoId}`)

                                if (Array.isArray(conversations[convoId].views) && !conversations[convoId].views.includes(user.getId())) {
                                    markConversationAsViewed(convoId)
                                }
                            }}
                        />
                    </Box>
                ))}
            {makeNewConversation && (
                <Grid container spacing={2}>
                    <NavigationBar
                        titles={[
                            {
                                name: 'New Conversation',
                            },
                        ]}
                        grid
                        leftButtons={[
                            {
                                name: 'Close',
                                type: 'icon',
                                innerIcon: <CloseIcon />,
                                onClick: () => {
                                    setNewConversation(Messages.getNewChat().getRaw())
                                    setMakeNewConversation(false)
                                },
                            },
                        ]}
                        rightButtons={[]}
                    />
                    <Grid item xs={12}>
                        <Widget noBodyPadding disableWidgetMenu bodyClass={classes.newConversationWidget}>
                            {calendarEvent.loading ? (
                                <Typography align="center" style={{ fontWeight: 500 }}>
                                    Loading event...
                                </Typography>
                            ) : (
                                <UpdateConversation
                                    conversation={newConversation}
                                    getPossibleMembers={() => getPossibleMembers(chapter.members, [user.getId()])}
                                    isNew
                                    onCancel={() => {
                                        setMakeNewConversation(false)
                                        setNewConversation(Messages.getNewChat().getRaw())
                                    }}
                                    onUpdate={(field, val) => setNewConversation({ ...newConversation, [field]: val })}
                                    onSubmit={conversation => {
                                        delete conversation.id

                                        if (conversation.type === 1 && 'messages' in conversation) {
                                            delete conversation.messages
                                        }

                                        // If it is a custom group chat, set the members
                                        if (conversation.mType === 0) {
                                            if ('mems' in conversation) {
                                                delete conversation['mems']
                                            }
                                        } else {
                                            if ('mems' in conversation) {
                                                conversation.mems = [user.getId(), ...conversation.mems]
                                            } else {
                                                conversation.mems = [user.getId()]
                                            }
                                        }

                                        createConversation({
                                            ...conversation,
                                            owner: user.getId(),
                                            photo: 'https://i.imgur.com/uHitHJE.jpeg',
                                        })

                                        // Reset the conversation
                                        setNewConversation(Messages.getNewChat().getRaw())

                                        setMakeNewConversation(false)
                                    }}
                                    history={history}
                                />
                            )}
                        </Widget>
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}

export default React.memo(ChatConversationsList)
