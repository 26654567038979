import { Box, ButtonBase, Typography } from '@material-ui/core'
import { PollOutlined as PollsIcon } from '@material-ui/icons'
import React from 'react'

import cn from 'classnames'

import Sway from '../Sway'

import useStyles from './styles'

const PollVote = ({ value, duration, options, onSelect, rotation, title, ...props }) => {
    const classes = useStyles()

    const optionsArr = Object.keys(options)

    return (
        <Sway rotation={rotation} duration={duration * 1.5}>
            <Box className={cn(classes.parentBox)}>
                <Box className={classes.titleBox}>
                    <Box className={classes.titleIconBox}>
                        <PollsIcon className={classes.titleIcon} />
                    </Box>
                    <Box className={classes.titleTextBox}>
                        <Typography className={classes.titleText}>{title}</Typography>
                    </Box>
                </Box>
                <Box className={classes.votesBox}>
                    {optionsArr.map(option => (
                        <PollVoteOption key={option} value={options[option]} onSelect={() => onSelect(option)} selected={value === option} />
                    ))}
                </Box>
            </Box>
        </Sway>
    )
}

const PollVoteOption = ({ value, selected, onSelect, ...props }) => {
    const classes = useStyles()

    if (selected) {
        return (
            <Box className={cn(classes.voteBox, classes.voteBoxSelected)} style={{ borderColor: value.color, backgroundColor: value.color }}>
                <Typography className={classes.voteText}>{value.label}</Typography>
            </Box>
        )
    }

    return (
        <ButtonBase className={classes.voteBox} onClick={onSelect} style={{ '& .MuiTouchRippleRoot span': { backgroundColor: value.color } }}>
            <Typography className={classes.voteText}>{value.label}</Typography>
        </ButtonBase>
    )
}

export default React.memo(PollVote)
