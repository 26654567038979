import React from 'react'

import { Document as DocumentBase, Page as PageBase, Text as TextBase, StyleSheet, Font, View, Image } from '@react-pdf/renderer'

import robotoLight from 'fonts/Roboto-Light.ttf'
import robotoRegular from 'fonts/Roboto-Regular.ttf'
import robotoBold from 'fonts/Roboto-Bold.ttf'

import document_logo from 'images/document_logo.png'

import moment from 'moment'

const styles = StyleSheet.create({
    body: {
        padding: 48,
    },
    title: {
        fontSize: 16,
        fontWeight: 700,
        fontFamily: 'Roboto-Bold',
    },
    subtitle: {
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Roboto',
    },
    header_one: {
        fontSize: 14,
        fontWeight: 700,
        fontFamily: 'Roboto-Bold',
    },
    header_two: {
        fontSize: 13,
        fontWeight: 700,
        fontFamily: 'Roboto-Bold',
    },
    header_three: {
        fontSize: 12,
        fontWeight: 700,
        fontFamily: 'Roboto-Bold',
    },
    text: {
        fontSize: 11,
        fontWeight: 300,
        fontFamily: 'Roboto-Thin',
    },
    footerIcon: {
        position: 'absolute',
        left: 48,
        bottom: 29 - 5,
        width: 18,
        height: 18,
    },
    footerPN: {
        position: 'absolute',
        textAlign: 'right',
        right: 46,
        bottom: 32 - 6,
        left: 66,
        fontSize: 10,
        fontWeight: 300,
        fontFamily: 'Roboto-Thin',
        color: '#6e6e6e',
    },
    footerAN: {
        position: 'absolute',
        textAlign: 'left',
        fontSize: 10,
        fontWeight: 300,
        fontFamily: 'Roboto-Thin',
        left: 68,
        bottom: 32 - 6,
        color: '#6e6e6e',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerBar: {
        position: 'absolute',
        left: 48,
        right: 48,
        bottom: 47,
        height: 1,
        backgroundColor: '#6e6e6e',
    },
})

function Document({ children, ...props }) {
    Font.register({ family: 'Roboto-Thin', src: robotoLight })
    Font.register({ family: 'Roboto', src: robotoRegular })
    Font.register({ family: 'Roboto-Bold', src: robotoBold })

    return <DocumentBase {...props}>{children}</DocumentBase>
}

function Text({ children, variant, ...props }) {
    const variantOptions = {
        title: styles.title,
        subtitle: styles.subtitle,
        h1: styles.header_one,
        h2: styles.header_two,
        h3: styles.header_three,
        text: styles.text,
    }

    let style = variant in variantOptions ? variantOptions[variant] : styles.text

    return (
        <TextBase style={{ ...style, ...props.style }} {...props}>
            {children}
        </TextBase>
    )
}

function Page({ children, footer, ...props }) {
    return (
        <PageBase style={styles.body} size="LETTER" {...props}>
            {children}
            {footer && (
                <>
                    <View style={styles.footerBar} fixed />
                    <Image style={styles.footerIcon} src={document_logo} fixed />
                    <TextBase style={styles.footerAN} fixed>
                        {`Greek Connect - ${moment().format('MMMM D, YYYY')}`}
                    </TextBase>
                    <TextBase style={styles.footerPN} render={({ pageNumber }) => `${pageNumber}`} fixed />
                </>
            )}
        </PageBase>
    )
}

export { Document, Text, Page }
