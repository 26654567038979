import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    testing: {
        margin: '0',
    },
    nonOverflowText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '100%',
    },
}))
