import React from 'react'

import { TextField } from '@material-ui/core'

import { Button, DialogContent, DialogTitle, DialogActions, Dialog } from 'components/Wrappers'

import SelectAutocomplete from 'components/SelectAutocomplete'

import { expandVisibility, compileVisibility, visibilityOptions, defaultVisibilityOptions } from 'data/Visibility'

const CreateFolderDialog = ({ open, onClose, onCreate, parentVis }) => {
    const [name, setName] = React.useState('')
    const [url, setURL] = React.useState('')

    const [vis, setVis] = React.useState(compileVisibility(defaultVisibilityOptions).filter(a => !parentVis || parentVis.includes(a)))

    const closeDialog = () => {
        setName('')
        setURL('')
        setVis(compileVisibility(defaultVisibilityOptions).filter(a => !parentVis || parentVis.includes(a)))
        onClose()
    }

    const createFolder = () => {
        if (name && url) {
            let updatedURL = url

            if (!/^https?:\/\//i.test(updatedURL.toLowerCase())) {
                updatedURL = `https://${updatedURL}`
            }

            onCreate({ name: name, url: updatedURL, vis: vis })
            closeDialog()
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog} fullWidth aria-labelledby="create-folder-dialog-title" maxWidth="xs">
            <DialogTitle id="create-folder-dialog-title" onClose={closeDialog}>
                New Link
            </DialogTitle>
            <DialogContent dividers>
                <TextField autoFocus margin="dense" variant="outlined" label="Name" value={name} onChange={e => setName(e.target.value)} fullWidth />
                <TextField
                    margin="dense"
                    variant="outlined"
                    label="URL"
                    type="url"
                    value={url}
                    placeholder="https://..."
                    onChange={e => setURL(e.target.value)}
                    fullWidth
                />
                <SelectAutocomplete
                    variant="outlined"
                    value={expandVisibility(vis)}
                    hideTitleOnUse={false}
                    onUpdate={(event, value) => {
                        setVis(value ? compileVisibility(value) : [])
                    }}
                    freeSolo={false}
                    title="Visibility"
                    events={visibilityOptions(true).filter(a => !parentVis || parentVis.includes(a.id))}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={createFolder} disabled={!name || !url || vis.length === 0} color="primary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateFolderDialog
