import React from 'react'
import { Avatar, IconButton, Box } from '@material-ui/core'

import { Emoji } from 'emoji-mart'

import { ChannelIcon } from 'components/Icons'

import { getNativeEmoji, getEmojiSet } from 'code/Helper'

import cn from 'classnames'

// styles
import useStyles from '../../styles'

const ConversationAvatar = ({ size, avatar, channel, onClick, unread, selected, ...props }) => {
    var classes = useStyles()

    let renderedSize = size ? size : 40
    let channelSize = renderedSize * 0.4
    let channelOffset = -1 * renderedSize * 0.0875

    let emojiSizeBlock = renderedSize * (1 / 6)

    let unreadSize = renderedSize * 0.3
    let unreadOffset = -1 * renderedSize * 0.0875 * 0.75

    const getAvatar = () => {
        if (avatar && avatar.type === 0) {
            return (
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Box
                        style={{
                            width: renderedSize,
                            height: renderedSize,
                            backgroundColor: avatar.background ? avatar.background : '#EEEEEE',
                            borderRadius: '50%',
                            padding: emojiSizeBlock,
                        }}
                    >
                        <Box
                            style={{
                                width: emojiSizeBlock * 4,
                                height: emojiSizeBlock * 4,
                                textShadow: '0 2px 2px rgba(0, 0, 0, .25)',
                            }}
                            className={classes.emojiParent}
                        >
                            <Emoji
                                size={emojiSizeBlock * 4}
                                emoji={avatar.emoji ? getNativeEmoji(avatar.emoji) : ':smile:'}
                                native={getEmojiSet() === 'apple'}
                                set={getEmojiSet()}
                            />
                        </Box>
                    </Box>
                </Box>
            )
        }

        if (avatar && avatar.type === 1) {
            return (
                <Avatar
                    {...props}
                    src={avatar.url}
                    style={{ width: renderedSize, height: renderedSize }}
                    className={cn(props.className, classes.conversationAvatar)}
                />
            )
        }

        return <Avatar {...props} style={{ width: renderedSize, height: renderedSize }} className={cn(props.className, classes.conversationAvatar)} />
    }

    const getCombined = () => {
        return (
            <Box
                style={{
                    position: 'relative',
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                }}
            >
                {getAvatar()}
                {channel && (
                    <Box
                        className={cn(classes.channelCircle, { [classes.circleSelected]: selected === true })}
                        style={{ width: channelSize + 3, height: channelSize + 3, right: channelOffset, bottom: channelOffset }}
                    >
                        <Box style={{ margin: 1.5, display: 'flex' }}>
                            <ChannelIcon style={{ width: channelSize, height: channelSize, zIndex: 100 }} />
                        </Box>
                    </Box>
                )}
                {unread && (
                    <Box
                        className={cn(classes.unreadCircle, { [classes.circleSelected]: selected === true })}
                        style={{ width: unreadSize + 3, height: unreadSize + 3, right: unreadOffset, top: unreadOffset }}
                    >
                        <Box style={{ margin: 1.5, display: 'flex' }}>
                            <Box className={classes.unreadDot} style={{ width: unreadSize, height: unreadSize }} />
                        </Box>
                    </Box>
                )}
            </Box>
        )
    }

    return onClick ? (
        <IconButton onClick={onClick} style={{ padding: 6 }}>
            {getCombined()}
        </IconButton>
    ) : (
        <>{getCombined()}</>
    )
}

export default React.memo(ConversationAvatar)
