import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    tipWidget: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tipPaper: {
        flexGrow: 0,
    },
    red: {
        backgroundColor: theme.palette.red.main,
    },
    yellow: {
        backgroundColor: theme.palette.yellow.main,
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
    },
    boxOuter: { padding: 8, display: 'flex', flexDirection: 'row' },
    boxOuterNormal: { padding: 8, display: 'flex', flexDirection: 'row' },
    boxIcon: { display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: 8 },
    boxText: { display: 'flex', flexDirection: 'column', justifyContent: 'center' },
    boxClose: { display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 8 },
    boxCloseLeft: { display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: 8 },
    disableElevation: {
        boxShadow: 'none',
    },
}))
