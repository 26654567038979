export default class FileTypes {
    static get IMAGE_JPG() {
        return 'image/jpeg'
    }

    static get IMAGE_PNG() {
        return 'image/png'
    }

    static get IMAGE_GIF() {
        return 'image/gif'
    }

    static get APPLICATION_PDF() {
        return 'application/pdf'
    }

    constructor(types) {
        this.types = [...types]
    }

    getUserFacingTypes() {
        let str = ''

        for (let i = 0; i < this.types.length; i++) {
            str += this.types[i].split('/')[1]

            if (i < this.types.length - 2) {
                str += ', '
            } else if (i === this.types.length - 2) {
                if (i > 0) {
                    str += ', or '
                } else {
                    str += ' or '
                }
            }
        }

        if (this.types.length > 1) {
            return `Accepted file types: ${str}.`
        }

        return `Accepted file type: ${str}.`
    }

    getSystemFacingTypes() {
        let str = ''

        for (let i = 0; i < this.types.length; i++) {
            str += this.types[i]

            if (i < this.types.length - 1) {
                str += ','
            }
        }

        return str
    }

    getRaw() {
        return this.types
    }
}
