export const reminders = [
    {
        id: 1,
        date: '3 minutes ago',
        sender: {
            firstName: 'Hunter',
            lastName: 'Copp',
            id: 'ejHNzreH9GMn6aaoiyeQoA0iANI3',
        },
        sentTime: {
            _seconds: 1586990990,
        },
        text: "Don't forget to complete your semesterly risk prevention presentation / workshop! This should be done as early into the semester as possible",
    },
]
export const accredidationStatus = [
    { id: 1, name: 'All True Men', value: 5, color: 'primary' },
    { id: 2, name: 'Accredidation', value: 1, color: 'green' },
    {
        id: 3,
        name: 'Submitted',
        value: 1,
        color: 'yellow',
    },
    {
        id: 4,
        name: 'Denied',
        value: 1,
        color: 'red',
    },
    {
        id: 0,
        name: 'Not Completed',
        value: 1,
        color: 'white',
    },
]
export const chapters = [
    {
        id: 1,
        name: 'Beta Alpha',
        subtitle: 'Accredited | ATM',
    },
    {
        id: 2,
        name: 'Beta Beta',
        subtitle: 'Accredited',
        chip: {
            color: 'red',
            label: '3',
        },
    },
    {
        id: 3,
        name: 'Beta Omega',
        subtitle: 'Accredited | ATM',
    },
]
export const standards = [
    {
        id: 1,
        title: 'Ritual',
        modifiedText: 'Modified 1 day ago',
    },
    {
        id: 2,
        title: 'Recruitment',
        modifiedText: 'Modified 1 day ago',
        description: "Recruitment supports Theta Xi's third purpose; sincerity in a brother's association with others and confidence in himself",
        accredidationCheckboxes: [
            'Be in possession of a current, updated version of The Ritual, matching headquarters records.',
            'Conduct Ritual ceremonies regularly, correctly, and appropriately; including but not limited to, Associate Member pinning, Initiation, and the Graduating Member ceremony.',
            'Conduct at least two Ritual meetings per month.',
        ],
        atmCheckboxes: [
            'Complete accredidation for this standard',
            'Require 100% of chapter members to be able to recite their secrets correctly each semester.',
        ],
        questions: [
            {
                isRequired: true,
                text: 'How many members does your chapter currently have?',
                type: 'NUMBER',
                maxSize: 1000,
                minSize: 1,
            },
            {
                isRequired: true,
                text: 'What is the average IFC chapter size on your campus?',
                type: 'NUMBER',
                maxSize: 1000,
                minSize: 1,
            },
            {
                isRequired: true,
                text: "What is your chapter's highest roll number?",
                type: 'NUMBER',
            },
            {
                isRequired: true,
                text:
                    'Chapter recruitment data for fall 2019 (separated by commas: ex: 12, 10, 10, 8)\n# bids given?\n# bids accepted\n# AMs pinned?\n# Associate Members initiated?',
                type: 'PARAGRAPH',
            },
        ],
    },
    {
        id: 3,
        title: 'Membership Education',
        modifiedText: 'Published 1 week ago',
        chip: {
            color: 'yellow',
            label: 'Submitted',
        },
    },
    {
        id: 4,
        title: 'Scholarship',
        modifiedText: 'Published 2 weeks ago',
    },
    {
        id: 5,
        title: 'Finance',
        modifiedText: 'Published 3 weeks ago',
        chip: {
            color: 'green',
            label: 'Accredited',
        },
    },
    {
        id: 6,
        title: 'Chapter Operations',
        modifiedText: 'Published 3 weeks ago',
        chip: {
            color: 'primary',
            label: 'ATM',
        },
    },
    {
        id: 7,
        title: 'Campus & Community',
        modifiedText: 'Published 3 weeks ago',
        chip: {
            color: 'red',
            label: 'Denied',
        },
    },
    {
        id: 8,
        title: 'Risk Prevention',
        modifiedText: 'Published 3 weeks ago',
        chip: {
            color: 'green',
            label: 'Approved',
        },
    },
    {
        id: 9,
        title: 'Alumni Relations',
        modifiedText: 'Published 3 weeks ago',
        chip: {
            color: 'green',
            label: 'Approved',
        },
    },
]
