import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        width: '100vw',
    },
    /* Logo */
    logoContainer: {
        width: '60%',
        height: '100%',
        background: `linear-gradient(0deg, #0B6ABE 0%, #3493E7 100%)`,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        width: 240,
        height: 240,
    },
    logoText: {
        color: 'white',
        fontWeight: `600 !important`,
        textShadow: '0 10px 15px rgba(0, 0, 0, 0.13)',
        fontSize: `84px !important`,
    },
    /* Form */
    formContainer: {
        width: '40%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        paddingTop: `env(safe-area-inset-top)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
    },
    form: {
        width: 320,
        margin: 'auto',
    },
    formHeader: {
        fontSize: '2rem !important',
        fontWeight: '600 !important',
        textAlign: 'center',
    },
}))
