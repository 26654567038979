import React from 'react'

import NavigationBar from 'components/NavigationBar'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Widget from 'components/Widget'

import { AccessTime as TimeIcon } from '@material-ui/icons'

export default function Courses(props) {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <NavigationBar
                        titles={[
                            {
                                name: 'Courses',
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Widget noBodyPadding inheritHeight>
                        <Box style={{ padding: 16 }}>
                            <Typography variant="h2" style={{ fontSize: 20, fontWeight: 'bold' }}>
                                Alcohol Education
                            </Typography>
                            <Typography variant="subtitle1">
                                <TimeIcon style={{ fontSize: '1.15em', height: '1em', width: '1em', verticalAlign: 'middle', marginRight: 4 }} />
                                Due April 22
                            </Typography>
                            <img src="https://i.imgur.com/RcxirMr.jpg" loading="lazy" alt="" />
                            <Box sx={{ display: 'flex' }}>
                                <Box>
                                    <Typography level="body3">Total price:</Typography>
                                    <Typography>$2,900</Typography>
                                </Box>
                                <Button>Explore</Button>
                            </Box>
                        </Box>
                    </Widget>
                </Grid>
            </Grid>
        </>
    )
}
