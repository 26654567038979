import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    appSeparator: {
        position: 'absolute',
        top: 0,
        left: 44,
        right: 8,
        backgroundColor: `${theme.palette.text.hint}80`,
        height: 1,
    },
}))
