import { Box, Typography } from '@material-ui/core'
import React from 'react'

import useStyles from '../styles'

const QuestionPercentage = ({ showValue, value, text, count }) => {
    const classes = useStyles()

    const percentage = count > 0 ? (value / count) * 100 : 0

    return (
        <Box className={classes.responseBlockProgress}>
            {showValue && <Typography className={classes.responseBlockAccessory}>{`${value} `}</Typography>}
            <Typography className={classes.responseBlockText}>{text}</Typography>
            <Typography className={classes.responseBlockAccessory}>{`${percentage.toFixed(2).replace(/\.0+$/, '')}%`}</Typography>
            <Box className={classes.responseBlockProgressInner} style={{ width: `${percentage}%` }} />
        </Box>
    )
}

export default React.memo(QuestionPercentage)
