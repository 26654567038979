import React from 'react'

import app from 'firebase/app'

import { Box, ButtonBase, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, Link, Select, TextField } from '@material-ui/core'

import LoadingButton from '@mui/lab/LoadingButton'

import moment from 'moment'
import { cutoffs } from './settings/data'

import {
    Add as AddIcon,
    Check as CheckIcon,
    ChevronLeft as LeftIcon,
    ChevronRight as RightIcon,
    Create as EditIcon,
    FastfoodRounded as FoodIcon,
    HomeOutlined as HomeIcon,
    ListAlt as OrdersIcon,
    MenuBookRounded as MenusIcon,
    NotInterestedOutlined as NoneIcon,
    SettingsOutlined as SettingsIcon,
} from '@material-ui/icons'

import MuiAlert from '@mui/material/Alert'

import format from 'date-fns/format'

import { DietaryRestrictionsIcon, TakeoutIcon } from 'components/Icons'

// components
import FullScreenImage from 'components/FullScreenImage'
import MenuImage from 'components/MenuImage'
import NavigationBar from 'components/NavigationBar'
import ErrorTypography from 'components/Typography/ErrorTypography'
import Widget from 'components/Widget'

import { convertListToObject, convertObjectToList } from 'code/Helper'

import MiniCard from 'components/Cards/MiniCard'
import {
    Button,
    Dialog as DialogMobile,
    DialogActions as DialogActionsMobile,
    DialogContent as DialogContentMobile,
    DialogTitle as DialogTitleMobile,
    Link as LinkWrapper,
    Typography,
} from 'components/Wrappers'

import Tip from 'components/Tip'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

// styles
import useStyles from './styles'

export default function Meals(props) {
    var classes = useStyles()

    const db = app.firestore()

    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)

    const [lpDialog, setLPDialog] = React.useState({ open: false })
    const [clpDialog, setCLPDialog] = React.useState({ open: false })

    const [showMenu, setShowMenu] = React.useState(false)

    const [drDialog, setDRDialog] = React.useState({ open: false })
    const [dietaryRestrictions, innerSetDietaryRestrictions] = React.useState(null)

    const [hasGrabbed, setHasGrabbed] = React.useState(false)
    const [usedCache, setUsedCache] = React.useState(false)

    const [hasTried, setHasTried] = React.useState(false)

    const [latePlates, setLatePlates] = React.useState([])

    const [menus, setMenus] = React.useState([])

    const [meals, setMeals] = React.useState([])

    const [activeDate, innerSetActiveDate] = React.useState(getSunday())
    const [activeMenu, setActiveMenu] = React.useState(null)

    const [activePlan, setActivePlan] = React.useState(null)

    function isUserMealsAdmin() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || (chapter.perms['mealsAdmin'] && chapter.perms['mealsAdmin'].includes(mem.role))
            }
        }

        return false
    }

    function grabMenu(menuId, curMeals) {
        setHasGrabbed(false)
        setActiveMenu(null)

        db.collection('chapters')
            .doc(user.getChapter())
            .collection('menus')
            .doc(menuId)
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()
                    data.id = doc.id
                    setActiveMenu(data)

                    let mealsObj = convertListToObject(curMeals)

                    let requestsObj = data.requests

                    let requests = convertObjectToList(requestsObj)

                    requests = requests.filter(request => request.member === user.getId())

                    for (let i = 0; i < requests.length; i++) {
                        if (requests[i].meal in mealsObj) {
                            let m = mealsObj[requests[i].meal]
                            requests[i].name = m.name
                            requests[i].dow = m.dow
                            requests[i].mod = m.mod
                        } else {
                            requests.splice(i, 1)
                            i--
                        }
                    }

                    setLatePlates(curRequests => {
                        let combinedRequests = [...requests]

                        for (let i = 0; i < curRequests.length; i++) {
                            let req = curRequests[i]

                            let found = false
                            for (let j = 0; j < combinedRequests.length; j++) {
                                let combReq = combinedRequests[j]

                                if (req.id === combReq.id) {
                                    found = true
                                    j = combinedRequests.length
                                }
                            }

                            if (!found) {
                                combinedRequests.push(req)
                            }
                        }

                        return combinedRequests.sort((b, a) => (a.dow !== b.dow ? b.dow - a.dow : b.mod - a.mod))
                    })
                }

                setHasGrabbed(true)
                setUsedCache(doc.metadata && doc.metadata.fromCache)
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
                setHasGrabbed(true)
                setUsedCache(false)
            })
    }

    function setActiveDate(date) {
        let dateMoment = moment(date)
        let foundMenu = null

        for (let i = 0; i < menus.length; i++) {
            let menu = menus[i]

            let menuMoment = moment()
                .day('Sunday')
                .year(menu.year)
                .week(menu.week)

            let isSameWeek = menuMoment.isSame(dateMoment, 'week')

            if (isSameWeek) {
                foundMenu = menu
            }
        }

        if (foundMenu !== null) {
            grabMenu(foundMenu.id, meals)
        } else {
            setHasGrabbed(true)
            setActiveMenu(null)
        }
        innerSetActiveDate(date)
    }

    function getSunday() {
        return moment()
            .day('Sunday')
            .toDate()
    }

    if (!hasTried) {
        setHasTried(true)
        grabSettings()
    }

    function setDietaryRestrictions(restrictions) {
        db.collection('chapters')
            .doc(user.getChapter())
            .collection('menus')
            .doc('INFO')
            .update({ [`restrictions.${user.getId()}`]: restrictions })
            .then(function() {
                innerSetDietaryRestrictions(restrictions)
                setDRDialog({ open: false })
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setDRDialog({ ...drDialog, loading: false, error: message })
            })
    }

    function cancelLatePlate() {
        let submission = {
            chapter: user.getChapter(),
            id: clpDialog.id,
            meal: clpDialog.meal,
            pickup: clpDialog.pickup,
            request: clpDialog.requests,
            restrictions: clpDialog.restrictions,
            recurring: clpDialog.recurring,
            reusable: false,
            menu: activeMenu.id,
            status: -1,
        }

        let functionsCall = app.functions().httpsCallable('updateMealRequest')
        functionsCall(submission)
            .then(function(result) {
                grabSettings()
                setCLPDialog({ open: false })
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setCLPDialog({ ...clpDialog, loading: false, error: message })
            })

        setCLPDialog({ ...clpDialog, loading: true })
    }

    function orderLatePlate() {
        let submission = {
            chapter: user.getChapter(),
            meal: lpDialog.meal,
            pickup: lpDialog.pickup,
            request: lpDialog.requests,
            restrictions: lpDialog.restrictions,
            recurring: lpDialog.recurring,
            reusable: false,
            menu: activeMenu.id,
        }

        if (lpDialog.id) {
            submission.id = lpDialog.id
        }

        setLPDialog({ ...lpDialog, loading: true })

        let functionsCall = app.functions().httpsCallable('updateMealRequest')
        functionsCall(submission)
            .then(function(result) {
                setLPDialog({ open: false })
                grabSettings()
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setLPDialog(lpDialog => ({ ...lpDialog, loading: false, error: message }))
            })
    }

    function grabSettings() {
        db.collection('chapters')
            .doc(user.getChapter())
            .collection('menus')
            .doc('INFO')
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()

                    let ps = data.plans
                    let psKeys = Object.keys(ps)

                    let userPlan = null

                    for (let i = 0; i < psKeys.length; i++) {
                        let key = psKeys[i]
                        let plan = ps[key]
                        let planData = {
                            ...plan,
                            id: key,
                        }

                        if (plan.members.includes(user.getId())) {
                            userPlan = planData
                        }
                    }

                    let curMeals = []
                    let mealsData = convertObjectToList(data.meals)

                    for (let i = 0; i < mealsData.length; i++) {
                        let mealData = { ...mealsData[i] }

                        mealData.name = getMealName(mealData)

                        curMeals.push(mealData)
                    }

                    curMeals.sort(function(a, b) {
                        if (a.dow !== b.dow) {
                            return a.dow - b.dow
                        }

                        if (a.mod !== b.mod) {
                            return a.mod - b.mod
                        }

                        return a.cutoffs[0] - b.cutoff[0]
                    })

                    if (data.recurring !== undefined) {
                        let recurring = convertObjectToList(data.recurring)

                        recurring = recurring.filter(plate => plate.member === user.getId())

                        let mealsObj = convertListToObject(curMeals)

                        for (let i = 0; i < recurring.length; i++) {
                            recurring[i].recurring = true

                            if (recurring[i].meal in mealsObj) {
                                let m = mealsObj[recurring[i].meal]
                                recurring[i].name = m.name
                                recurring[i].dow = m.dow
                                recurring[i].mod = m.mod
                            } else {
                                recurring.splice(i, 1)
                                i--
                            }
                        }

                        setLatePlates(curRequests => {
                            let combinedRequests = [...curRequests]

                            for (let i = 0; i < recurring.length; i++) {
                                let req = recurring[i]

                                let found = false
                                for (let j = 0; j < combinedRequests.length; j++) {
                                    let combReq = combinedRequests[j]

                                    if (req.id === combReq.id) {
                                        found = true
                                        j = combinedRequests.length
                                    }
                                }

                                if (!found) {
                                    combinedRequests.push(req)
                                }
                            }

                            return combinedRequests.sort((b, a) => (a.dow !== b.dow ? b.dow - a.dow : b.mod - a.mod))
                        })
                    }

                    let menus = []
                    let ms = data.menus

                    let msKeys = Object.keys(ms)

                    let activeId = null

                    for (let i = 0; i < msKeys.length; i++) {
                        let key = msKeys[i]
                        let menuID = ms[key]

                        let date = key.split('.')

                        let year = parseInt(date[0])
                        let week = parseInt(date[1])

                        let momentDate = moment()
                            .day('Sunday')
                            .year(year)
                            .week(week)
                            .toDate()

                        let isActive = moment().isSame(momentDate, 'week')

                        let data = {
                            week: week,
                            year: year,
                            title: format(momentDate, 'MMMM do'),
                            subtitle: String(year),
                            id: menuID,
                            active: isActive,
                        }

                        if (isActive) {
                            activeId = menuID
                        }

                        menus.push(data)
                    }

                    if (activeId) {
                        grabMenu(activeId, curMeals)
                    } else {
                        setHasGrabbed(true)
                    }

                    menus.sort(function(a, b) {
                        if (b.year !== a.year) {
                            return b.year - a.year
                        }

                        return b.week - a.week
                    })

                    let restrictions = data.restrictions

                    if (restrictions !== undefined && restrictions !== null) {
                        if (user.getId() in restrictions) {
                            setDietaryRestrictions(restrictions[user.getId()])
                        } else {
                            setDietaryRestrictions('')
                        }
                    }

                    setMenus(menus)
                    setMeals(curMeals)

                    if (userPlan !== null) {
                        setActivePlan(userPlan)
                    }
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    function getMealName(meal) {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let meals = ['Breakfast', 'Lunch', 'Dinner']

        return days[meal.dow] + ' ' + meals[meal.mod]
    }

    function getCompiledMeals() {
        let ms = []

        for (let i = 0; i < meals.length; i++) {
            let meal = meals[i]
            if (activePlan && activePlan.meals.includes(meal.id) && activeMenu && activeMenu.meals.includes(meal.id)) {
                ms.push(meal)
            }
        }

        return ms
    }

    const compiledMeals = getCompiledMeals()

    function getOrderChips(plate) {
        let chips = []

        if (plate.completed) {
            chips.push({ color: 'green', label: 'Completed', id: 'completed' })
        }

        if (plate.recurring) {
            chips.push({ color: 'primary', label: 'Recurring', id: 'recurring' })
        }

        return chips
    }

    function getSubtitles(plate) {
        let subtitles = []

        if (plate.request) {
            subtitles.push(`Requests: ${plate.request ? plate.request : 'None'}`)
        }

        if (plate.restrictions || !plate.request) {
            subtitles.push(`Dietary Restrictions: ${plate.restrictions ? plate.restrictions : 'None'}`)
        }

        return subtitles
    }

    function hasPassedCutoff(plate) {
        if (!chapter || !activeMenu || !meals) {
            return false
        }

        let cMeals = convertListToObject(meals)

        if (!(plate.meal in cMeals)) {
            return true
        }

        let mealData = cMeals[plate.meal]

        let cPickups = convertListToObject(mealData.pickups)

        if (!(plate.pickup in cPickups)) {
            return true
        }

        let pickupData = cPickups[plate.pickup]

        let curDate = moment.tz(chapter.settings.timezone ? chapter.settings.timezone : 'America/New_York')

        let momDate = moment
            .tz(chapter.settings.timezone ? chapter.settings.timezone : 'America/New_York')
            .day('Sunday')
            .year(activeMenu.year)
            .week(activeMenu.week)

        let momentDate = momDate.toDate()

        let nextWeekMoment = momDate.add(7, 'days').toDate()

        if (curDate.isAfter(nextWeekMoment)) {
            return true
        }

        if (curDate.isSame(momentDate, 'week')) {
            let dow = curDate.day()

            if (mealData.dow < dow) {
                return true
            }

            if (mealData.dow === dow && pickupData.cutoff < curDate.get('hour') * 60 + curDate.get('minute')) {
                return true
            }
        }

        return false
    }

    function renderPlate(plate) {
        const isPassedCutoff = hasPassedCutoff(plate)
        if (plate.status === 1) {
            return (
                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                    <Box className={classes.specialtyCard}>
                        <Box className={classes.specialtyCardIcon}>
                            <TakeoutIcon className={classes.specialtyCardIconInner} />
                            <CheckIcon style={{ color: '#2ecc71' }} className={classes.specialtyCardIconOverlay} />
                        </Box>
                        <Typography variant="caption" align="center">
                            Your order for {plate.name} has been fulfilled
                        </Typography>
                    </Box>
                </Widget>
            )
        }
        if (plate.status === -1) {
            return (
                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                    <Box className={classes.specialtyCard}>
                        <Box className={classes.specialtyCardIcon}>
                            <TakeoutIcon className={classes.specialtyCardIconInner} />
                            <NoneIcon className={classes.specialtyCardIconOverlay} />
                        </Box>
                        <Typography variant="caption" align="center">
                            Your order for {plate.name} has been cancelled
                            {!isPassedCutoff && !plate.recurring && (
                                <>
                                    {` `}
                                    <LinkWrapper
                                        underline="none"
                                        onClick={() => {
                                            setLPDialog({
                                                open: true,
                                                meal: plate.meal,
                                                pickup: plate.pickup,
                                                requests: plate.request,
                                                restrictions: plate.restrictions,
                                                recurring: plate.recurring,
                                                id: plate.id,
                                            })
                                        }}
                                        className={classes.reorderLink}
                                    >
                                        Reorder
                                    </LinkWrapper>
                                </>
                            )}
                        </Typography>
                    </Box>
                </Widget>
            )
        }

        if (!activeMenu.meals.includes(plate.meal)) {
            return (
                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                    <Box className={classes.specialtyCard}>
                        <Box className={classes.specialtyCardIcon}>
                            <TakeoutIcon className={classes.specialtyCardIconInner} />
                            <NoneIcon className={classes.specialtyCardIconOverlay} />
                        </Box>
                        <Typography variant="caption" align="center">
                            {plate.name} is not being served this week
                        </Typography>
                    </Box>
                </Widget>
            )
        }

        if (!activePlan.meals.includes(plate.meal)) {
            return (
                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                    <Box className={classes.specialtyCard}>
                        <Box className={classes.specialtyCardIcon}>
                            <TakeoutIcon className={classes.specialtyCardIconInner} />
                            <NoneIcon className={classes.specialtyCardIconOverlay} />
                        </Box>
                        <Typography variant="caption" align="center">
                            Your meal plan no longer includes {plate.name}
                        </Typography>
                    </Box>
                </Widget>
            )
        }

        if (isPassedCutoff) {
            return (
                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                    <Box className={classes.specialtyCard}>
                        <Box className={classes.specialtyCardIcon}>
                            <TakeoutIcon className={classes.specialtyCardIconInner} />
                        </Box>
                        <Typography variant="caption" align="center">
                            Your order for {plate.name} has been received
                        </Typography>
                    </Box>
                </Widget>
            )
        }

        return (
            <MiniCard
                title={plate.name}
                subtitle={getSubtitles(plate)}
                chips={getOrderChips(plate)}
                leftButton={
                    plate.reusable
                        ? { label: 'Scan in', onClick: () => console.log('todo') }
                        : plate.recurring
                        ? null
                        : {
                              label: 'Cancel',
                              onClick: () => {
                                  setCLPDialog({
                                      open: true,
                                      meal: plate.meal,
                                      pickup: plate.pickup,
                                      requests: plate.request,
                                      restrictions: plate.restrictions,
                                      recurring: plate.recurring,
                                      id: plate.id,
                                  })
                              },
                          }
                }
                rightButton={[
                    {
                        label: 'Edit',
                        onClick: () => {
                            setLPDialog({
                                open: true,
                                meal: plate.meal,
                                pickup: plate.pickup,
                                requests: plate.request,
                                restrictions: plate.restrictions,
                                recurring: plate.recurring,
                                id: plate.id,
                            })
                        },
                        icon: <EditIcon />,
                    },
                ]}
            />
        )
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    grid
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Meals',
                        },
                    ]}
                    rightButtons={
                        isUserMealsAdmin()
                            ? [
                                  {
                                      name: 'Orders',
                                      tooltip: 'Orders',
                                      type: 'icon',
                                      innerIcon: <OrdersIcon />,
                                      onClick: () => {
                                          props.history.push('/app/applications/meals/orders/')
                                      },
                                  },
                                  {
                                      name: 'Menus',
                                      tooltip: 'Menus',
                                      type: 'icon',
                                      innerIcon: <MenusIcon />,
                                      onClick: () => {
                                          props.history.push('/app/applications/meals/menus/')
                                      },
                                  },
                                  {
                                      name: 'Settings',
                                      type: 'icon',
                                      innerIcon: <SettingsIcon />,
                                      onClick: () => {
                                          props.history.push('/app/applications/meals/settings/')
                                      },
                                  },
                              ]
                            : []
                    }
                    key={isUserMealsAdmin() ? 'admin' : 'none'}
                />
                <Grid item xs={12}>
                    <Tip
                        color="default"
                        id="week_description"
                        actionIcon={<RightIcon />}
                        title="Week Of"
                        onActionClick={() => {
                            setActiveDate(
                                moment(activeDate)
                                    .add(7, 'days')
                                    .toDate(),
                            )
                        }}
                        leftAction={{
                            icon: <LeftIcon />,
                            onClick: () =>
                                setActiveDate(
                                    moment(activeDate)
                                        .subtract(7, 'days')
                                        .toDate(),
                                ),
                            disabled: moment(activeDate).isSame(moment(), 'week'),
                        }}
                        description={format(activeDate, 'MMMM do')}
                        center
                    />
                </Grid>
                {activeMenu && (
                    <>
                        <Grid item xs={12} style={{ display: 'flex' }}>
                            <ButtonBase
                                onClick={() => {
                                    setShowMenu(true)
                                }}
                                style={{ margin: '0 auto' }}
                            >
                                <MenuImage src={activeMenu.photo} />
                            </ButtonBase>
                        </Grid>
                        {activeMenu.feedback && (
                            <Grid item xs={12}>
                                <Link href={activeMenu.feedback}>
                                    <Typography align="center">Give feedback</Typography>
                                </Link>
                            </Grid>
                        )}
                        {hasGrabbed && usedCache && (
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                                <MuiAlert severity="warning">Your internet connection appears to be offline</MuiAlert>
                            </Grid>
                        )}
                    </>
                )}
                {hasGrabbed && !activeMenu && (
                    <>
                        <Grid item xs={12}>
                            <Widget disableWidgetMenu inheritHeight>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{ height: 'calc(10rem + 16px)' }}>
                                        <Box style={{ position: 'relative' }}>
                                            <FoodIcon style={{ fontSize: '5rem', position: 'absolute', top: '2.25rem', left: 'calc(50% - 2.5rem)' }} />
                                            <NoneIcon
                                                style={{ fontSize: '10rem', position: 'absolute', top: 0, left: 'calc(50% - 5rem)' }}
                                                className={classes.redColor}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="center" variant="h6" style={{ fontWeight: 600 }}>
                                            There is no menu for this week
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="center" variant="caption">
                                            You cannot add orders without a menu
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Widget>
                        </Grid>
                    </>
                )}
                {hasGrabbed && (
                    <Grid item xs={12}>
                        <Tip
                            color="default"
                            id="dietary_restrictions"
                            icon={<DietaryRestrictionsIcon />}
                            actionIcon={<EditIcon />}
                            title="Dietary Restrictions"
                            onActionClick={() => {
                                setDRDialog({ open: true, restrictions: dietaryRestrictions ? dietaryRestrictions : '' })
                            }}
                            description={dietaryRestrictions ? dietaryRestrictions : 'None'}
                        />
                    </Grid>
                )}
                {false && (
                    <Grid item xs={12}>
                        <Tip
                            color="red"
                            id="dietary_restrictions"
                            icon={<TakeoutIcon />}
                            hideAction
                            title="Return Reusable Plate"
                            description="Your Friday Lunch plate is 4 hours late"
                        />
                    </Grid>
                )}
                {hasGrabbed && activePlan === null && (
                    <Grid item xs={12}>
                        <Tip
                            color="red"
                            id="no_plan"
                            hideAction
                            title="NOT ON PLAN"
                            description="You are not on a meal plan"
                            titleStyle={{ textAlign: 'center' }}
                        />
                    </Grid>
                )}
                {hasGrabbed && compiledMeals.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <NavigationBar
                                titles={[
                                    {
                                        name: 'Your Orders',
                                    },
                                ]}
                                rightButtons={[
                                    {
                                        name: 'Add',
                                        type: 'icon',
                                        innerIcon: <AddIcon />,
                                        onClick: () => {
                                            let mealId = compiledMeals[0].id
                                            let pickups = convertListToObject(meals)[mealId].pickups
                                            setLPDialog({
                                                open: true,
                                                meal: mealId,
                                                pickup: pickups[0].id,
                                                requests: '',
                                                restrictions: dietaryRestrictions ? dietaryRestrictions : '',
                                                recurring: false,
                                            })
                                        },
                                    },
                                ]}
                                keep
                            />
                        </Grid>
                        {latePlates.map(plate => (
                            <Grid item xs={12} md={6} lg={4} key={plate.id}>
                                {renderPlate(plate)}
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>
            {drDialog.open && (
                <DialogMobile
                    open={drDialog.open}
                    fullWidth
                    onClose={() => {
                        if (!drDialog.loading) {
                            setDRDialog({ open: false })
                        }
                    }}
                    disableClose={drDialog.loading}
                >
                    <DialogTitleMobile onClose={() => setDRDialog({ open: false })} disableClose={drDialog.loading}>
                        Dietary Restrictions
                    </DialogTitleMobile>
                    <DialogContentMobile dividers>
                        <TextField
                            margin="dense"
                            type="text"
                            variant="outlined"
                            placeholder="None"
                            inputProps={{
                                maxLength: 128,
                            }}
                            onChange={e => {
                                setDRDialog({ ...drDialog, restrictions: e.target.value })
                            }}
                            value={drDialog.restrictions}
                            fullWidth
                        />
                        {drDialog.error && <ErrorTypography text={drDialog.error} isCentered={true} />}
                    </DialogContentMobile>
                    <DialogActionsMobile>
                        <Button disabled={drDialog.loading} onClick={() => setDRDialog({ open: false })} color="primary">
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={drDialog.loading}
                            onClick={() => {
                                setDietaryRestrictions(drDialog.restrictions)
                                setDRDialog({ ...drDialog, loading: true })
                            }}
                            color="primary"
                        >
                            Update
                        </LoadingButton>
                    </DialogActionsMobile>
                </DialogMobile>
            )}
            {lpDialog.open && (
                <DialogMobile
                    open={lpDialog.open}
                    onClose={() => {
                        if (!lpDialog.loading) {
                            setLPDialog({ open: false })
                        }
                    }}
                    disableClose={!!lpDialog.loading}
                >
                    <DialogTitleMobile aria-labelledby="export-calendar-title" onClose={() => setLPDialog({ open: false })} disableClose={!!lpDialog.loading}>
                        {lpDialog.id ? 'Update Order' : 'Order Late Plate'}
                    </DialogTitleMobile>
                    <DialogContentMobile dividers>
                        <FormControl variant="outlined" fullWidth margin="dense">
                            <InputLabel id="meal-select">Meal</InputLabel>
                            <Select
                                native
                                autoFocus={lpDialog.id ? false : true}
                                labelId="meal-select"
                                id="meal-select"
                                value={lpDialog.meal}
                                onChange={e => {
                                    let mealId = e.target.value

                                    if (mealId !== lpDialog.meal) {
                                        let pickups = convertListToObject(meals)[mealId].pickups
                                        setLPDialog({ ...lpDialog, meal: mealId, pickup: pickups[0].id })
                                    }
                                }}
                                disabled={typeof lpDialog.id === 'string'}
                                label="Meal"
                            >
                                {getCompiledMeals().map(meal => (
                                    <option key={meal.id} value={meal.id}>
                                        {meal.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" fullWidth margin="dense">
                            <InputLabel id="pickup-select">Pickup</InputLabel>
                            <Select
                                native
                                labelId="pickup-select"
                                id="pickup-select"
                                value={lpDialog.pickup}
                                onChange={e => setLPDialog({ ...lpDialog, pickup: e.target.value })}
                                label="Pickup"
                            >
                                {convertListToObject(meals)[lpDialog.meal].pickups.map(pickup => (
                                    <option key={pickup.id} value={pickup.id}>
                                        {`${cutoffs[pickup.time]} (Cutoff: ${cutoffs[pickup.cutoff]})`}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            label="Special Requests"
                            type="text"
                            variant="outlined"
                            inputProps={{
                                maxLength: 64,
                            }}
                            onChange={e => {
                                setLPDialog({ ...lpDialog, requests: e.target.value })
                            }}
                            value={lpDialog.requests}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            label="Dietary Restrictions"
                            type="text"
                            variant="outlined"
                            inputProps={{
                                maxLength: 128,
                            }}
                            onChange={e => {
                                setLPDialog({ ...lpDialog, restrictions: e.target.value })
                            }}
                            value={lpDialog.restrictions}
                            fullWidth
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={lpDialog.recurring}
                                        disabled={typeof lpDialog.id === 'string'}
                                        onChange={e => setLPDialog({ ...lpDialog, recurring: e.target.checked })}
                                    />
                                }
                                label="Recurring (weekly)"
                            />
                        </FormGroup>
                        {lpDialog.error && <ErrorTypography text={lpDialog.error} isCentered={true} />}
                    </DialogContentMobile>
                    <DialogActionsMobile>
                        <Button onClick={() => setLPDialog({ open: false })} disabled={lpDialog.loading} color="primary">
                            Cancel
                        </Button>
                        <LoadingButton onClick={() => orderLatePlate()} loading={lpDialog.loading} color="primary">
                            {lpDialog.id ? 'Update' : 'Order'}
                        </LoadingButton>
                    </DialogActionsMobile>
                </DialogMobile>
            )}
            {clpDialog.open && (
                <DialogMobile
                    open={clpDialog.open}
                    onClose={() => {
                        if (!clpDialog.loading) {
                            setCLPDialog({ open: false })
                        }
                    }}
                    disableClose={clpDialog.loading}
                >
                    <DialogTitleMobile aria-labelledby="clpdialog-title" onClose={() => setCLPDialog({ open: false })} disableClose={clpDialog.loading}>
                        Cancel Late Plate
                    </DialogTitleMobile>
                    <DialogContentMobile dividers>
                        <Typography align="center">Are you sure you want to cancel your late plate?</Typography>
                        {clpDialog.error && <ErrorTypography text={clpDialog.error} isCentered={true} />}
                    </DialogContentMobile>
                    <DialogActionsMobile>
                        <Button onClick={() => setCLPDialog({ open: false })} disabled={clpDialog.loading} color="primary">
                            Don't Cancel
                        </Button>
                        <LoadingButton onClick={() => cancelLatePlate()} loading={clpDialog.loading} color="red">
                            Cancel
                        </LoadingButton>
                    </DialogActionsMobile>
                </DialogMobile>
            )}
            {activeMenu && <FullScreenImage open={showMenu} onClose={() => setShowMenu(false)} src={activeMenu.photo} />}
        </>
    )
}
