import React from 'react'

import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { accredidationStatus } from '../mock.js'

import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'

import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'
import Typography from '@mui/material/Typography'
export default function Accreditation(props) {
    const theme = useTheme()
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <NavigationBar
                        titles={[
                            {
                                name: 'Accreditation',
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Widget disableWidgetMenu inheritHeight noPadding>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" weight="bold" style={{ textAlign: 'center' }}>
                                    Submissions
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: 6,
                                }}
                            >
                                <Typography
                                    style={{
                                        position: 'absolute',
                                    }}
                                    weight="bold"
                                >
                                    44/50
                                </Typography>
                                <ResponsiveContainer width="100%" height={88}>
                                    <PieChart>
                                        <Pie data={accredidationStatus} innerRadius={30} outerRadius={40} dataKey="value">
                                            {accredidationStatus.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={theme.palette[entry.color].main} stroke={''} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" style={{ textAlign: 'center' }}>
                                    12 All True Men 34 Accredited 8 Awaiting Review 4 Not Submitted
                                </Typography>
                            </Grid>
                        </Grid>
                    </Widget>
                </Grid>
            </Grid>
        </>
    )
}
