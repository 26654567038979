import app from 'firebase/app'
import React from 'react'

import qs from 'querystringify'

import {
    Avatar,
    Box,
    ButtonBase,
    Chip as ChipBase,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    InputBase,
    Link,
    MenuItem,
    NativeSelect,
    Select,
    Snackbar,
    Switch,
    TextField,
    Tooltip,
} from '@material-ui/core'
import MUITextField from '@mui/material/TextField'
import {
    Check as CheckIcon,
    ChevronLeft as PrevPageIcon,
    ChevronRight as NextPageIcon,
    Close as CloseIcon,
    CloudDownloadOutlined as ExportIcon,
    ControlPointRounded as AddQuestionIcon,
    Create as EditIcon,
    DeleteForeverOutlined as DeleteForeverIcon,
    ExpandLess as ExpandHiddenIcon,
    ExpandMore as ExpandShownIcon,
    FirstPage as FirstPageIcon,
    HomeOutlined as HomeIcon,
    LastPage as LastPageIcon,
    PollOutlined as PollsIcon,
} from '@material-ui/icons'
import MuiAlert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import { PreviewIcon, ShareIosIcon } from 'components/Icons'

import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

import { MobileDatePicker as DatePicker } from '@mui/x-date-pickers/MobileDatePicker'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import _ from 'lodash'

// components
import ErrorTypography from 'components/Typography/ErrorTypography'
import {
    Button,
    Dialog as DialogMobile,
    DialogActions as DialogActionsMobile,
    DialogContent as DialogContentMobile,
    DialogTitle as DialogTitleMobile,
    Typography,
} from 'components/Wrappers'
import FormSubmission from 'components/Forms/FormSubmission'
import NavigationBar from 'components/NavigationBar'
import Question from 'components/Question'
import SelectAutocomplete from 'components/SelectAutocomplete'
import Tabs from 'components/Tabs'
import Widget from 'components/Widget'
import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import cn from 'classnames'

import Table from 'components/Tables/Table'

import { convertListToObject, convertObjectToList, isIOSDevice } from 'code/Helper'
import { expandVisibility, visibilityOptions } from 'data/Visibility'
import { Question as Qs } from 'objects/AnnualReport/Question'
import { getFirebaseDate, times } from 'code/TimeAgo'
import { generateUUID } from 'code/UUID'

import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard'

import moment from 'moment'

import Papa from 'papaparse'

// styles
import useStyles from '../styles'

import { withStyles } from '@material-ui/core/styles'

export default function EditPoll(props) {
    var classes = useStyles()

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }

    const colors = ['blue', 'red', 'green', 'orange', 'purple', 'bluegray']

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const [timer, setTimer] = React.useState(0)

    const [copiedToClipboard, setCopiedToClipboard] = React.useState(false)

    function handleKeyUp() {
        if (timer > 0) {
            setTimer(0)
        }
    }

    const db = app.firestore()
    const databaseDB = app.database()

    const userEditor = { id: user.getId(), name: user.getFullName(), color: _.sample(colors) }

    const [isFocused, innerSetIsFocused] = React.useState('')

    const [viewResponsesType, setViewResponsesType] = React.useState('overall')

    //Actions: edit, responses, view
    const [action, innerSetAction] = React.useState(getDefaultAction(props.match.params.action))

    const qsParams = qs.parse(props.location.search)

    const [pollID, setPollID] = React.useState(props.match.params.id)
    const [eventID, setEventID] = React.useState(qsParams.eid ? qsParams.eid : '')
    const [eventData, setEventData] = React.useState(null)
    const [isNew, setIsNew] = React.useState(props.match.params.id === 'new')

    const [showResponseSettings, setShowResponseSettings] = React.useState(false)
    const [showVisibilitySettings, setShowVisibilitySettings] = React.useState(false)
    const [showResponses, setShowResponses] = React.useState(false)

    const [poll, innerSetPoll] = React.useState(getBlankPoll())
    const [responses, setResponses] = React.useState([])
    const [authorResponses, setAuthorResponses] = React.useState([])

    function setAction(action) {
        if (action !== 'edit' && isFocused.length > 0) {
            setIsFocused('')
        }

        innerSetAction(action)
    }

    function getBlankPoll() {
        return {
            title: '',
            description: '',
            questions: [],
            submitted: [],
            author: {
                id: user.getId(),
                first: user.first,
                last: user.last,
                photo: user.photoURL ? user.photoURL : '',
            },
            settings: {
                open: false,
                luopen: app.firestore.Timestamp.fromDate(new Date()),
                //autoClose: false,
                //closeTime: null,
                anonymous: false,
                multiple: false,
                editing: false,
                viewing: false,
                results: false,
            },
            vis: {
                event: { id: qsParams.eid ? qsParams.eid : '', name: qsParams.ename ? decodeURIComponent(qsParams.ename) : '' },
                groups: visibilityOptions(false),
                roles: [],
            },
        }
    }

    function setIsFocused(questionId, addedQuestion) {
        let newQuestions = []

        for (let i = 0; i < poll.questions.length; i++) {
            let newQuestion = _.cloneDeep(poll.questions[i])
            if (newQuestion.editor && newQuestion.editor.id === user.getId()) {
                delete newQuestion.editor
                newQuestion.lastUpdated = new Date()
            }

            if (questionId === newQuestion.id) {
                newQuestion.editor = userEditor
                newQuestion.lastUpdated = new Date()
            }

            newQuestions.push(newQuestion)
        }

        if (addedQuestion !== undefined) {
            if (questionId === addedQuestion.id) {
                addedQuestion.editor = userEditor
                addedQuestion.lastUpdated = new Date()
            }

            newQuestions.push(addedQuestion)
        }
        let newPoll = { ...poll, questions: newQuestions }
        innerSetIsFocused(questionId)
        setPoll(newPoll)
        submitPollUpdate(newPoll)
    }

    function getDefaultAction(val) {
        if (val === 'view' || val === 'responses') {
            return val
        }

        return 'edit'
    }

    function setPoll(val) {
        innerSetPoll(val)
        setSaveStatus(-2)
    }

    const [loadingStatus, setLoadingStatus] = React.useState(-2)
    const [saveStatus, setSaveStatus] = React.useState(0)

    const [hasGrabbed, setHasGrabbed] = React.useState(false)

    let memberVisibility = React.useCallback(getMemberVisibility(poll), [chapter, poll, eventData])
    let memberResponses = React.useCallback(getMemberResponses(poll), [chapter, poll, eventData])

    const [shareDialog, setShareDialog] = React.useState({
        open: false,
        tab: 'app',
        loading: false,
        notification: 'none',
        notificationMessage: '',
    })

    if (loadingStatus === -2) {
        if (isNew) {
            let pollsDoc = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('polls')
                .doc()
            setPollID(pollsDoc.id)
            setupEditorUpdates(pollsDoc.id)
            setLoadingStatus(0)
            setHasGrabbed(true)
        } else {
            getPoll()
            setLoadingStatus(-1)
        }
    }

    function getEditorRef(docId) {
        return databaseDB.ref(`chapters/${user.getChapter()}/polls/${docId}/editors/${user.getId()}`)
    }

    function setupEditorUpdates(docId) {
        let editorRef = getEditorRef(docId)
        editorRef.set(userEditor)
        editorRef.onDisconnect().remove()
    }

    function diconnectFromEditor(docId) {
        let editorRef = getEditorRef(docId)
        editorRef.remove()
        editorRef.onDisconnect().cancel()
    }

    React.useEffect(() => {
        if (pollID !== 'new' && !isNew) {
            const unsubscribe = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('polls')
                .doc(pollID)
                .onSnapshot(
                    function(doc) {
                        if (doc.exists) {
                            let data = doc.data()
                            let pollData = decompilePollData(mergePolls(poll, data))
                            innerSetPoll(pollData)
                            setEventID(pollData.vis && pollData.vis.event && pollData.vis.event.id ? pollData.vis.event.id : '')
                        } else {
                            if (!isNew) {
                                props.history.replace(`/app/applications/polls/`)
                            }
                        }
                    },
                    function(error) {
                        console.log('error', pollID, error)
                        unsubscribe()
                    },
                )

            const responsesUnsubscribe = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('polls')
                .doc(pollID)
                .collection('data')
                .doc('meta')
                .onSnapshot(function(doc) {
                    if (doc.exists) {
                        let data = doc.data()

                        let authorIDs = []
                        let authors = []

                        if (data.responses) {
                            for (let i = 0; i < data.responses.length; i++) {
                                let response = data.responses[i]
                                if (response.author && response.author.id) {
                                    if (!authorIDs.includes(response.author.id)) {
                                        authorIDs.push(response.author.id)
                                        authors.push(response.author)
                                    } else {
                                        let j = authorIDs.indexOf(response.author.id)
                                        authors[j] = response.author
                                    }
                                }
                            }
                        }

                        setResponses(data.responses ? data.responses : [])

                        let newI = -1
                        for (let i = 0; i < authors.length; i++) {
                            let author = authors[i]
                            if (author.id === viewResponseAuthor.id) {
                                newI = i
                            }
                        }

                        if (newI !== viewResponseAuthor.i) {
                            setViewReponseAuthor({ ...viewResponseAuthor, i: newI })
                        }

                        setAuthorResponses(authors)
                    } else {
                        setResponses([])
                        setAuthorResponses([])
                    }
                })

            return () => {
                unsubscribe()
                responsesUnsubscribe()
                diconnectFromEditor(pollID)
            }
        }
    }, [pollID, isNew])

    React.useEffect(() => {
        if (eventID !== '') {
            const eventUnsubscribe = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('events')
                .doc(eventID)
                .onSnapshot(
                    function(doc) {
                        if (doc.exists) {
                            let data = doc.data()
                            data.id = doc.id
                            setEventData(data)
                        } else {
                            setEventData(null)
                        }
                    },
                    function(error) {
                        console.log('error', error)
                    },
                )
            return () => {
                eventUnsubscribe()
                setEventData(null)
            }
        }
    }, [eventID])

    React.useEffect(() => {
        let interval = setInterval(() => {
            if (timer >= 2) {
                if (saveStatus < 0) {
                    submitPollUpdate()
                }
            } else {
                if (hasGrabbed) {
                    setTimer(timer => timer + 1)
                }
            }
        }, 1000)

        document.addEventListener('keyup', handleKeyUp)
        return () => {
            document.removeEventListener('keyup', handleKeyUp)
            clearInterval(interval)
        }
    }, [timer, hasGrabbed, saveStatus])

    function deleteResponse(responseId) {
        let newResponses = []

        for (let i = 0; i < responses.length; i++) {
            let response = responses[i]
            if (response.id !== responseId) {
                newResponses.push(response)
            }
        }

        setResponses(responses)

        let responsesRef = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('polls')
            .doc(pollID)
            .collection('data')
            .doc('meta')

        db.runTransaction(async function(transaction) {
            const responsesDoc = await transaction.get(responsesRef)
            if (!responsesDoc.exists) {
                throw new Error('Document does not exist!')
            } else {
                let data = responsesDoc.data()

                let responses = data.responses

                let newResponses = []

                for (let i = 0; i < responses.length; i++) {
                    let response = responses[i]
                    if (response.id !== responseId) {
                        newResponses.push(response)
                    }
                }

                transaction.update(responsesRef, { responses: newResponses })
            }
        })
    }

    function compilePollData(data) {
        if (isPollCompiled(data)) {
            return data
        }

        let gps = []
        let roles = []

        for (let i = 0; i < data.vis.groups.length; i++) {
            gps.push(data.vis.groups[i].id)
        }

        for (let i = 0; i < data.vis.roles.length; i++) {
            roles.push(data.vis.roles[i].id)
        }

        let d = _.cloneDeep(data)

        d.vis.groups = gps
        d.vis.roles = roles

        return d
    }

    function decompilePollData(data) {
        if (!isPollCompiled(data)) {
            return data
        }

        let visibility = expandVisibility(data.vis.groups)

        let excludedRoles = []

        for (let i = 0; i < roles.length; i++) {
            let role = roles[i]

            if (data.vis.roles.includes(role.id)) {
                excludedRoles.push(role)
            }
        }

        let d = _.cloneDeep(data)

        d.vis.roles = excludedRoles
        d.vis.groups = visibility

        return d
    }

    function getPoll() {
        let pollsDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('polls')
            .doc(pollID)

        pollsDoc
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = decompilePollData(doc.data())

                    innerSetPoll(data)

                    setEventID(data.vis.event && data.vis.event.id ? data.vis.event.id : '')

                    setLoadingStatus(0)
                    setHasGrabbed(true)

                    setupEditorUpdates(pollID)
                } else {
                    window.alert('This poll does not exist anymore')
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
                window.alert('This poll does not exist anymore')
            })
    }

    const questions = poll.questions

    function setQuestions(newQs) {
        setPoll({ ...poll, questions: newQs })
        setSaveStatus(-2)
    }

    function getResponsesTitle() {
        if (responses.length === 1) {
            return '1 Response'
        }

        return String(responses.length) + ' Responses'
    }

    function isPollCompiled(data) {
        if (data.vis.groups.length > 0) {
            if (typeof data.vis.groups[0] !== 'number') {
                return false
            }
        }

        if (data.vis.roles.length > 0) {
            if (typeof data.vis.roles[0] !== 'string') {
                return false
            }
        }

        return true
    }

    const mergePolls = (og, upd) => {
        let updated = { ...upd }

        if (!og && !updated) {
            return getBlankPoll()
        }

        if (!og) {
            return updated
        }

        if (!updated) {
            return og
        }

        og = compilePollData(og)
        updated = compilePollData(updated)

        for (let i = 0; i < updated.questions.length; i++) {
            let myQuestion = updated.questions[i]
            let origQuestion = null
            for (let j = 0; j < og.questions.length; j++) {
                let serverQuestion = og.questions[j]
                if (myQuestion.id === serverQuestion.id) {
                    origQuestion = serverQuestion
                }
            }

            if (origQuestion !== null) {
                if (origQuestion.lastUpdated) {
                    if (!myQuestion.lastUpdated || getDateTime(origQuestion.lastUpdated) >= getDateTime(myQuestion.lastUpdated)) {
                        updated.questions[i] = origQuestion
                    }
                }
            }
        }

        //Decompile poll data

        return updated
    }

    function isValidDate(value) {
        var dateWrapper = new Date(value)
        return !isNaN(dateWrapper.getDate())
    }

    function getDateTime(data) {
        if (isValidDate(data)) {
            return data
        }

        let date = new Date(0)
        date.setUTCSeconds(data._seconds ? data._seconds : data.seconds)
        return date
    }

    function updateSetting(name, value) {
        let pollDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('polls')
            .doc(pollID)

        if (name === 'open') {
            const data = {
                [`settings.open`]: value,
                [`settings.luopen`]: app.firestore.Timestamp.fromDate(new Date()),
                [`settings.autoClose`]: !!value ? getDefaultClose() : false,
            }
            pollDoc.update(data).catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
            })
        } else {
            pollDoc.update({ [`settings.${name}`]: value }).catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
            })
        }
    }

    function updateVis(name, value) {
        let pollDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('polls')
            .doc(pollID)

        pollDoc.update({ [`vis.${name}`]: value }).catch(function(error) {
            // Getting the Error details.
            var code = error.code
            var message = error.message
            var details = error.details
            console.log('AN ERROR OCCURRED', code, message, details)
        })
    }

    function submitPollUpdate(data) {
        const pollData = data !== undefined ? data : poll
        setSaveStatus(-1)
        let pollDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('polls')
            .doc(pollID)

        if (isNew) {
            pollDoc
                .set(compilePollData(pollData))
                .then(async () => {
                    setSaveStatus(0)

                    if (isNew) {
                        if (props.location?.state?.path) {
                            const folderId = props.location.state.path
                            await db
                                .collection('chapters')
                                .doc(user.getChapter())
                                .collection('polls')
                                .doc('INFO')
                                .update({ [`folders.${folderId}.items`]: app.firestore.FieldValue.arrayUnion(pollID) })
                        }

                        props.history.replace(`/app/applications/polls/edit/${pollID}`)
                        setIsNew(false)
                    }
                })
                .catch(function(err) {
                    console.error(err)
                    setSaveStatus(0)
                })
        } else {
            db.runTransaction(function(transaction) {
                return transaction.get(pollDoc).then(function(plDoc) {
                    if (!plDoc.exists) {
                        if (!isNew) {
                            throw new Error('Document does not exist!')
                        }

                        transaction.set(pollDoc, compilePollData(pollData))
                    } else {
                        let data = plDoc.data()

                        let mergedPoll = mergePolls(data, pollData)

                        transaction.update(pollDoc, mergedPoll)
                    }
                })
            })
                .then(function() {
                    setSaveStatus(0)

                    if (isNew) {
                        props.history.replace(`/app/applications/polls/edit/${pollID}`)
                        setIsNew(false)
                    }
                })
                .catch(function(err) {
                    console.error(err)
                    setSaveStatus(0)
                })
        }
    }

    function getStrSaveStatus() {
        if (saveStatus === -2) {
            return 'Not Saved'
        } else if (saveStatus === -1) {
            return 'Saving...'
        } else if (saveStatus === 0) {
            return 'Saved'
        }

        return ''
    }

    const [qValues, setQValues] = React.useState({})
    const [qOtherValues, setQOtherValues] = React.useState({})
    const [viewSubmission, setViewSubmission] = React.useState({ error: null, loading: false })
    const [viewResponseIndex, setViewResponseIndex] = React.useState(0)
    const [viewResponseAuthor, setViewReponseAuthor] = React.useState({ id: '', i: 0 })

    function renderQuestion(question, index, resultType) {
        if (resultType) {
            let values = []

            if (poll && resultType === 'overall') {
                for (let i = 0; i < responses.length; i++) {
                    let response = responses[i]
                    if (question.id in response) {
                        values.push(response[question.id])
                    }
                }
            }

            if (poll && resultType === 'individual' && responses.length > 0) {
                let i = viewResponseIndex % responses.length

                let response = responses[i]

                if (question.id in response) {
                    values.push(response[question.id])
                }
            }

            if (poll && resultType === 'member') {
                let author = viewResponseAuthor

                if (!author || !author.id) {
                    for (let i = 0; i < authorResponses.length; i++) {
                        if (authorResponses[i] && authorResponses[i].id) {
                            author = authorResponses[i]
                            setViewReponseAuthor({ ...author, i: i })
                            i = responses.length
                        }
                    }
                }

                for (let i = 0; i < responses.length; i++) {
                    let response = responses[i]
                    if (response.author && response.author.id === author.id) {
                        if (question.id in response) {
                            values.push(response[question.id])
                        }
                    }
                }
                //TODO: Do something when 0 responses by that author
            }

            return <Question question={question} viewMode={'results'} value={values} />
        }

        return (
            <Question
                question={question}
                isFocused={isFocused === question.id && action === 'edit'}
                onAction={(action, q) => {
                    q.lastUpdated = new Date()
                    if (action === 'update') {
                        let qs = [...questions]
                        qs[index] = q
                        setQuestions(qs)
                    } else if (action === 'duplicate') {
                        let qNew = { ...q }
                        qNew.id = generateUUID()
                        let qs = [...questions]
                        qs.splice(index + 1, 0, qNew)
                        setQuestions(qs)
                    } else if (action === 'delete') {
                        let qs = [...questions]
                        qs.splice(index, 1)
                        setQuestions(qs)
                    } else if (action === 'done') {
                        setIsFocused('')
                    } else if (action === 'moveUp') {
                        if (index > 0) {
                            let tempQuestion = questions[index - 1]
                            let qs = [...questions]
                            qs[index - 1] = q
                            qs[index] = tempQuestion
                            setQuestions(qs)
                        }
                    } else if (action === 'moveDown') {
                        if (index < questions.length - 1) {
                            let tempQuestion = questions[index + 1]
                            let qs = [...questions]
                            qs[index + 1] = q
                            qs[index] = tempQuestion
                            setQuestions(qs)
                        }
                    }
                }}
                actions={{ moveUp: index > 0, moveDown: index < questions.length - 1 }}
                onChange={(type, val) => {
                    if (type === 'other') {
                        setQOtherValues({ ...qOtherValues, [question.id]: val })
                    } else {
                        if (action === 'view') {
                            setQValues({ ...qValues, [question.id]: val })
                        }
                    }
                }}
                viewMode={action}
                value={action === 'view' ? qValues[question.id] : null}
                isDisabled={action === 'edit'}
            />
        )
    }

    function getRightButtons() {
        let buttons = []

        if (action === 'view') {
            buttons.push({
                name: 'Edit',
                tooltip: 'Edit',
                type: 'icon',
                innerIcon: <EditIcon />,
                onClick: () => {
                    props.history.replace('/app/applications/polls/edit/' + pollID)
                    setAction('edit')
                },
            })
        } else {
            buttons.push({
                name: 'Share',
                tooltip: 'Share',
                type: 'icon',
                innerIcon: <ShareIosIcon />,
                onClick: () => {
                    setShareDialog({
                        ...shareDialog,
                        tab: 'app',
                        open: true,
                        error: null,
                    })
                },
            })
            buttons.push({
                name: 'Preview',
                tooltip: 'Preview',
                type: 'icon',
                innerIcon: <PreviewIcon />,
                onClick: () => {
                    props.history.replace('/app/applications/polls/view/' + user.getChapter() + '/' + pollID)
                    setAction('view')
                },
            })
        }

        return buttons
    }

    function getPollVisibilityText() {
        if (!poll.vis.groups || poll.vis.groups.length === 0) {
            return 'No one will be able to view the poll as no statuses are included.'
        }

        if (poll.vis.event) {
            if (poll.vis.roles && poll.vis.roles.length > 0) {
                return 'Members who are in one of the included statuses, are not in one of the exluded roles, and are present at the selected event will be able to view the poll.'
            }

            return 'Members who are in one of the included statuses and are present at the selected event will be able to view the poll.'
        }

        if (poll.vis.roles && poll.vis.roles.length > 0) {
            return 'Members who are in one of the included statuses and not in of one of the excluded roles will be able to view the poll.'
        }

        return 'Members who are in one of the included statuses will be able to view the poll.'
    }

    function getChapterEvents() {
        let events = []
        let eventKeys = Object.keys(chapter.events)

        for (let i = 0; i < eventKeys.length; i++) {
            let id = eventKeys[i]
            let e = chapter.events[id]

            if (e.signIn !== undefined && e.signIn) {
                let date = getFirebaseDate(e)

                events.push({
                    id: id,
                    name: `${e.name} (${moment(date).format('M/D')})`,
                })
            }
        }

        events.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })

        return events
    }

    function getChapterRoles() {
        let roles = []
        let roleKeys = Object.keys(chapter.roles)

        for (let i = 0; i < roleKeys.length; i++) {
            roles.push({
                id: roleKeys[i],
                name: chapter.roles[roleKeys[i]].title,
            })
        }

        roles.sort((a, b) => {
            return a.name.localeCompare(b.name)
        })

        return roles
    }

    function submitPoll() {
        if (hasCompletedPoll()) {
            let finalizedResponse = {}

            // TODO: Go question by question and finalize response
            for (let i = 0; i < poll.questions.length; i++) {
                let question = poll.questions[i]
                let response = qValues[question.id]

                if (!response) {
                    if (question.type === Qs.CHECKBOX) {
                        response = []
                    } else {
                        response = ''
                    }
                }

                if (question.type === Qs.MC) {
                    if (response !== 'OTHER') {
                        for (let j = 0; j < question.choices.length; j++) {
                            let choice = question.choices[j]
                            if (choice.id === response) {
                                response = choice.text
                                j = question.choices.length
                            }
                        }
                    } else {
                        // Handle other option
                        response = qOtherValues[question.id]
                    }
                } else if (question.type === Qs.CHECKBOX) {
                    let newResponse = []

                    for (let j = 0; j < response.length; j++) {
                        let r = response[j]
                        if (r !== 'OTHER') {
                            for (let k = 0; k < question.choices.length; k++) {
                                let choice = question.choices[k]
                                if (choice.id === r) {
                                    r = choice.text
                                    k = question.choices.length
                                }
                            }
                        } else {
                            // Handle other option
                            r = qOtherValues[question.id]
                        }
                        newResponse.push(r)
                    }

                    response = newResponse
                } else if (question.type === Qs.NUMBER) {
                    // Do nothing?
                    // Maybe talk about invalid answers
                } else if (question.type === Qs.TEXT || question.type === Qs.PARAGRAPH) {
                    // Do nothing?
                }
                finalizedResponse[question.id] = response
            }

            let submission = {
                chapter: user.getChapter(),
                pollID: pollID,
                response: finalizedResponse,
            }

            let functionsCall = app.functions().httpsCallable('submitPoll')
            functionsCall(submission)
                .then(function(result) {
                    setViewSubmission({ ...viewSubmission, loading: false })
                    if (props.history.length === 0) {
                        props.history.replace('/app/applications/polls/')
                    } else {
                        props.history.goBack()
                    }
                })
                .catch(function(error) {
                    // Getting the Error details.
                    var code = error.code
                    var message = error.message
                    var details = error.details
                    console.log('AN ERROR OCCURRED', code, message, details)
                    setViewSubmission({ ...viewSubmission, error: message, loading: false })
                })

            // TODO: Submit the response & show confirmation page...
            setViewSubmission({ ...viewSubmission, error: null, loading: true })
        } else {
            // TODO: Set error
            setViewSubmission({ ...viewSubmission, error: 'Form not completed', loading: false })
        }
    }

    function hasCompletedPoll() {
        let finished = true
        //qValues
        for (let i = 0; i < poll.questions.length; i++) {
            let question = poll.questions[i]
            if (question.required && question.type !== Qs.TITLE) {
                if (!qValues[question.id]) {
                    finished = false
                }
            }
        }
        return finished
    }

    function canMemberCompletePoll(member, pollData) {
        if (!pollData || !member) {
            return false
        }

        let id = member.id

        let vis = pollData.vis

        if (!vis.groups.includes(member.status)) {
            return false
        }

        if (vis.event && vis.event.id && eventData && vis.event.id === eventData.id && eventData.signIn && eventData.signIn.enabled) {
            let contains = false

            if (eventData.attendance) {
                let attendance = convertListToObject(eventData.attendance)

                contains = id in attendance && attendance[id].present
            }

            if (!contains) {
                return false
            }
        }

        if (vis.roles.includes(member.role)) {
            return false
        }

        return true
    }

    function getMemberVisibility(pollData) {
        let visibleMems = []
        let notVisibleMems = []

        let newData = compilePollData(pollData)

        let members = convertObjectToList(chapter.members)
        for (let i = 0; i < members.length; i++) {
            let mem = members[i]

            let canCompletePoll = canMemberCompletePoll(mem, newData)

            if (canCompletePoll) {
                visibleMems.push({
                    roll: mem.roll,
                    first: mem.first,
                    last: mem.last,
                })
            } else {
                notVisibleMems.push({
                    roll: mem.roll,
                    first: mem.first,
                    last: mem.last,
                })
            }
        }

        return {
            visible: visibleMems,
            notVisible: notVisibleMems,
        }
    }

    function getValidTabs() {
        let tabs = [
            { id: 'overall', name: 'Overall', width: 65 },
            { id: 'individual', name: 'Individual', width: 80 },
        ]

        if (authorResponses.length > 0) {
            tabs.push({ id: 'member', name: 'Member', width: 70 })
        }

        return tabs
    }

    function renderAuthor(author, authors) {
        if (author) {
            const BootstrapInput = withStyles(theme => ({
                root: {
                    'label + &': {
                        marginTop: theme.spacing(3),
                    },
                },
                input: {
                    borderRadius: 4,
                    position: 'relative',
                    border: 'none',
                    fontSize: 16,
                    padding: '10px 26px 10px 12px',
                    transition: theme.transitions.create(['border-color', 'box-shadow']),
                    '&:focus': {
                        borderRadius: 4,
                        borderColor: theme.palette.primary.main,
                    },
                },
            }))(InputBase)

            return (
                <Box style={{ display: 'flex', justifyContent: 'center', margin: 3 }}>
                    <Box style={{ flexGrow: 1 }}>
                        {authors === null && responses[viewResponseIndex % responses.length].id && <Box style={{ width: 48, height: 48 }}></Box>}
                    </Box>
                    <IconButton
                        aria-haspopup="true"
                        color="inherit"
                        className={classes.headerMenuButton}
                        onClick={e => props.history.push('/app/profile/' + author.id)}
                    >
                        <Avatar color="primary" alt="" src={author.photoURL}>
                            {`${author.first.charAt(0)}${author.last.charAt(0)}`}
                        </Avatar>
                    </IconButton>
                    {authors ? (
                        <FormControl style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <NativeSelect
                                aria-label="Name"
                                value={author.id}
                                onChange={e => {
                                    let val = e.target.value
                                    for (let i = 0; i < authors.length; i++) {
                                        if (authors[i].id === val) {
                                            setViewReponseAuthor({ ...authors[i], i: i })
                                        }
                                    }
                                }}
                                input={<BootstrapInput />}
                            >
                                {authors.map(a => (
                                    <option value={a.id}>{`${a.first} ${a.last}`}</option>
                                ))}
                            </NativeSelect>
                        </FormControl>
                    ) : (
                        <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <Typography>{`${author.first} ${author.last}`}</Typography>
                        </Box>
                    )}
                    <Box style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {authors === null && responses[viewResponseIndex % responses.length].id && (
                            <Tooltip title="Delete Response">
                                <IconButton onClick={e => deleteResponse(responses[viewResponseIndex % responses.length].id)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>
            )
        }

        return (
            <Box style={{ display: 'flex', justifyContent: 'center', margin: 3 }}>
                <Box style={{ flexGrow: 1 }}>
                    {authors === null && responses[viewResponseIndex % responses.length].id && <Box style={{ width: 48, height: 48 }}></Box>}
                </Box>
                <IconButton aria-haspopup="true" color="inherit" className={classes.headerMenuButton} disabled>
                    <Avatar color="primary" alt="">
                        A
                    </Avatar>
                </IconButton>
                <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <Typography>Anonymous</Typography>
                </Box>
                <Box style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    {authors === null && responses[viewResponseIndex % responses.length].id && (
                        <Tooltip title="Delete Response">
                            <IconButton onClick={e => deleteResponse(responses[viewResponseIndex % responses.length].id)}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </Box>
        )
    }

    function getMemberResponses(pollData) {
        let respondedMems = []
        let notRespondedMems = []

        let newData = compilePollData(pollData)

        let mems = convertObjectToList(chapter.members)
        for (let i = 0; i < mems.length; i++) {
            let id = mems[i].id
            let mem = mems[i]

            if (canMemberCompletePoll(mem, newData) || newData.submitted.includes(id)) {
                if (newData.submitted.includes(id)) {
                    respondedMems.push({
                        roll: mem.roll,
                        first: mem.first,
                        last: mem.last,
                    })
                } else {
                    notRespondedMems.push({
                        roll: mem.roll,
                        first: mem.first,
                        last: mem.last,
                    })
                }
            }
        }

        return {
            responded: respondedMems,
            notResponded: notRespondedMems,
        }
    }

    function exportResponses() {
        let compiledResponses = []

        for (let i = 0; i < responses.length; i++) {
            let response = responses[i]
            let compiled = {}

            if (response.time) {
                compiled.Time = moment(getFirebaseDate({ date: response.time })).format('MMMM Do YYYY, h:mm:ss a')
            }

            if (response.author && !poll.settings.anonymous) {
                compiled.Roll = response.author.roll
                compiled.Name = `${response.author.first} ${response.author.last}`
                // TODO: Get email
                if (response.author.id in chapter.members) {
                    compiled.Email = chapter.members[response.author.id].email
                }
            }

            for (let j = 0; j < poll.questions.length; j++) {
                let question = poll.questions[j]

                if (question.id in response) {
                    let val = response[question.id]

                    if (Array.isArray(val)) {
                        let newValue = ''
                        val = val.sort((a, b) => a.localeCompare(b))
                        for (let k = 0; k < val.length; k++) {
                            if (k > 0) {
                                newValue += ', '
                            }
                            newValue += val[k]
                        }
                        val = newValue
                    }

                    compiled[question.text ? question.text : 'Question'] = val
                }
            }

            compiledResponses.push(compiled)
        }

        let pollTitle = poll.title.replace(/\W/g, '')

        const element = document.createElement('a')
        const file = new Blob([Papa.unparse(compiledResponses)], {
            type: 'text/csv',
        })

        element.href = URL.createObjectURL(file)
        element.download = 'responses_' + pollTitle + '.csv'
        document.body.appendChild(element) // Required for this to work in FireFox
        element.click()
    }

    const [events] = React.useState(getChapterEvents())
    const [roles] = React.useState(getChapterRoles())

    const getResponseColumns = () => {
        let cols = []

        if (chapter && chapter.settings && chapter.settings.roll) {
            cols.push({
                title: 'Roll',
                value: 'roll',
                type: 'NUMBER',
                size: 'small',
                sortable: true,
            })
        }

        cols.push({
            title: 'First',
            value: 'first',
            sortable: true,
        })

        cols.push({
            title: 'Last',
            value: 'last',
            sortable: true,
        })

        return cols
    }

    const defaultSortableCol =
        chapter && chapter.settings && chapter.settings.roll
            ? {
                  value: 'roll',
                  dir: 'asc',
              }
            : {
                  value: 'first',
                  dir: 'asc',
              }

    const responseCols = getResponseColumns()

    const DateTextField = function DateTextField(props) {
        return (
            <MUITextField
                {...props}
                label={props.value && props.value.length > 0 ? '' : 'Date'}
                margin="dense"
                size="small"
                style={{ marginTop: 4, width: 130.5, marginRight: 0 }}
                InputLabelProps={{ shrink: false }}
                fullWidth={false}
                helperText={null}
                variant="outlined"
            />
        )
    }

    const DateSelect = ({ date, setDate }) => {
        const dateRef = React.useRef(null)

        const [isSafari] = React.useState(
            isIOSDevice() || (navigator.userAgent && navigator.userAgent.indexOf('Safari') > -1 && !(navigator.userAgent.indexOf('Chrome') > -1)),
        )

        if (isSafari) {
            let value = moment(date).format('YYYY-MM-DD')

            if (dateRef.current) {
                dateRef.current.value = value
            }

            return (
                <TextField
                    label={value && value.length > 0 ? '' : 'Date'}
                    margin="dense"
                    size="small"
                    style={{ marginTop: 4, width: 123, marginRight: 0 }}
                    InputLabelProps={{ shrink: false }}
                    fullWidth={false}
                    helperText={null}
                    type="date"
                    variant="outlined"
                    defaultValue={value}
                    ref={dateRef}
                    onBlur={e => {
                        if (e && e.target.value) {
                            let date = moment(e.target.value).toDate()
                            setDate(date)
                        }
                    }}
                />
            )
        }

        return (
            <DatePicker
                label="Date"
                value={moment(date).startOf('day')}
                onChange={newValue => {
                    setDate(newValue.toDate())
                }}
                disablePast
                slots={{
                    textField: DateTextField,
                }}
            />
        )
    }

    const getDefaultClose = () => {
        const min_minutes = 5
        let date = new Date(new Date().getTime() + min_minutes * 60000)
        date.setMinutes(15 - (date.getMinutes() % 15) + date.getMinutes())
        return date.getTime()
    }

    const preventPastDate = date => {
        if (date instanceof Date) {
            if (date.getTime() < new Date().getTime()) return getDefaultClose()
            return date.getTime()
        }

        if (date < new Date().getTime()) return getDefaultClose()
        return date
    }

    const datesAreOnSameDay = (first, second) =>
        first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate()

    const getFilteredTimes = date => {
        const curTime = new Date()
        if (datesAreOnSameDay(date, curTime)) {
            const curMinutes = curTime.getMinutes() + curTime.getHours() * 60
            const minutes = date.getMinutes() + date.getHours() * 60
            return times.filter(t => t.val >= minutes || t.val >= curMinutes)
        }

        return times
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Polls',
                            link: '/app/applications/polls',
                            icon: <PollsIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: action === 'view' ? 'View' : 'Edit',
                        },
                    ]}
                    rightButtons={getRightButtons()}
                    rightSubtitle={loadingStatus === 0 && action !== 'view' ? getStrSaveStatus() : null}
                    key={action}
                    grid
                />
                {(action === 'edit' || action === 'responses') && (
                    <Grid item xs={12}>
                        <Box
                            style={{
                                maxWidth: 770,
                                margin: `0 auto`,
                            }}
                        >
                            <Widget disableWidgetMenu inheritHeight bodyClass={classes.sectionNoHighlight}>
                                <Tabs
                                    tabs={[
                                        { id: 'questions', name: 'Questions', width: 90 },
                                        { id: 'responses', name: 'Responses', width: 100, disabled: isNew },
                                    ]}
                                    selected={action === 'edit' ? 'questions' : 'responses'}
                                    onChange={val => {
                                        setAction(val === 'questions' ? 'edit' : 'responses')
                                    }}
                                />
                            </Widget>
                        </Box>
                    </Grid>
                )}
                {action === 'edit' || action === 'view' ? (
                    <>
                        <Grid item xs={12}>
                            <Box style={{ maxWidth: 770, margin: `0 auto` }}>
                                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center" className={classes.innerPadding}>
                                            {action === 'edit' ? (
                                                <TextField
                                                    margin="dense"
                                                    type="text"
                                                    inputProps={{
                                                        maxLength: 64,
                                                    }}
                                                    value={poll.title}
                                                    onChange={e => {
                                                        setPoll({ ...poll, title: e.target.value })
                                                    }}
                                                    fullWidth
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.title,
                                                        },
                                                    }}
                                                    placeholder="Title"
                                                />
                                            ) : (
                                                <Typography className={classes.title}>{poll.title}</Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center" className={classes.innerPadding}>
                                            {action === 'edit' ? (
                                                <TextField
                                                    margin="dense"
                                                    type="text"
                                                    inputProps={{
                                                        maxLength: 1024,
                                                    }}
                                                    InputProps={{
                                                        multiline: true,
                                                    }}
                                                    value={poll.description}
                                                    onChange={e => {
                                                        setPoll({ ...poll, description: e.target.value })
                                                    }}
                                                    fullWidth
                                                    placeholder="Description"
                                                />
                                            ) : (
                                                <Typography>{poll.description}</Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                    {poll && hasGrabbed && action === 'edit' && (
                                        <Grid item xs={12}>
                                            <Box display="flex" alignItems="center" className={cn(classes.innerPadding, classes.chipHolder)}>
                                                {poll.settings.open ? (
                                                    <ChipBase variant="outlined" size="small" icon={<CheckIcon />} label="Open for responses" />
                                                ) : (
                                                    <ChipBase variant="outlined" size="small" icon={<CloseIcon />} label="Not open for responses" />
                                                )}
                                                {poll.settings.anonymous ? (
                                                    <ChipBase variant="outlined" size="small" icon={<CheckIcon />} label="Anonymous" />
                                                ) : (
                                                    <ChipBase variant="outlined" size="small" icon={<CloseIcon />} label="Not anonymous" />
                                                )}
                                            </Box>
                                        </Grid>
                                    )}
                                    {action === 'edit' && (
                                        <>
                                            <Grid item xs={12}>
                                                <Divider style={{ marginTop: 8 }} />
                                                <ButtonBase
                                                    style={{
                                                        width: `100%`,
                                                    }}
                                                    onClick={() => {
                                                        setShowVisibilitySettings(!showVisibilitySettings)
                                                    }}
                                                >
                                                    <Typography variant="h6">Visibility</Typography>
                                                    {showVisibilitySettings ? <ExpandHiddenIcon /> : <ExpandShownIcon />}
                                                </ButtonBase>
                                                {showVisibilitySettings && (
                                                    <>
                                                        <Divider />
                                                        <Box className={classes.innerPadding} style={{ marginTop: 6 }}>
                                                            <SelectAutocomplete
                                                                variant="outlined"
                                                                value={poll.vis.groups}
                                                                onUpdate={(event, value) => {
                                                                    let arr = []

                                                                    for (let i = 0; i < value.length; i++) {
                                                                        arr.push(value[i].id)
                                                                    }

                                                                    updateVis('groups', arr)
                                                                }}
                                                                freeSolo={false}
                                                                title="Included Statuses"
                                                                events={visibilityOptions(true).slice(0, 6)}
                                                            />
                                                            {roles.length > 0 && (
                                                                <SelectAutocomplete
                                                                    variant="outlined"
                                                                    value={poll.vis.roles}
                                                                    onUpdate={(event, value) => {
                                                                        let arr = []

                                                                        for (let i = 0; i < value.length; i++) {
                                                                            arr.push(value[i].id)
                                                                        }

                                                                        updateVis('roles', arr)
                                                                    }}
                                                                    freeSolo={false}
                                                                    title="Excluded Roles"
                                                                    events={roles}
                                                                />
                                                            )}
                                                            {events.length > 0 && (
                                                                <Autocomplete
                                                                    fullWidth
                                                                    getOptionLabel={option => option.name}
                                                                    options={events}
                                                                    value={poll.vis.event}
                                                                    onChange={(e, value) => {
                                                                        if (value !== null && value.id) {
                                                                            setEventID(value.id)
                                                                        } else {
                                                                            setEventID('')
                                                                        }

                                                                        updateVis('event', value)
                                                                    }}
                                                                    renderInput={params => (
                                                                        <MUITextField
                                                                            {...params}
                                                                            variant="outlined"
                                                                            label="Event"
                                                                            fullWidth
                                                                            margin="dense"
                                                                            height="small"
                                                                        />
                                                                    )}
                                                                    disableCloseOnSelect
                                                                />
                                                            )}
                                                            <Box>
                                                                <Typography variant="caption" component="p" align="center">
                                                                    {getPollVisibilityText()}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </>
                                                )}
                                                {showVisibilitySettings && (
                                                    <>
                                                        <Divider />
                                                        <Box style={{ marginTop: -8 }}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Table
                                                                        title="Visible"
                                                                        columns={responseCols}
                                                                        sortable={true}
                                                                        defaultSortable={defaultSortableCol}
                                                                        showOnEmpty={true}
                                                                        emptyText="None Visible"
                                                                        data={memberVisibility.visible}
                                                                        numRows={5}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </>
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                </Widget>
                            </Box>
                        </Grid>
                        {questions.map((question, index) => (
                            <Grid item xs={12}>
                                <Box
                                    style={{
                                        maxWidth: 770,
                                        margin: `0 auto`,
                                    }}
                                >
                                    {action === 'edit' && question.id !== isFocused ? (
                                        <>
                                            {question.editor && question.editor.id !== user.id ? (
                                                <span style={{ userSelect: 'none', WebkitUserSelect: 'none', cursor: 'default' }}>
                                                    {renderQuestion(question, index)}
                                                </span>
                                            ) : (
                                                <button
                                                    onClick={() => {
                                                        setIsFocused(question.id)
                                                    }}
                                                    style={{ all: 'unset', width: '100%', cursor: 'pointer' }}
                                                >
                                                    {renderQuestion(question, index)}
                                                </button>
                                            )}
                                        </>
                                    ) : (
                                        renderQuestion(question, index)
                                    )}
                                </Box>
                            </Grid>
                        ))}
                        {questions.length < 50 && action === 'edit' && (
                            <Grid item xs={12}>
                                <Link
                                    underline="none"
                                    onClick={() => {
                                        let id = generateUUID()
                                        let qNew = {
                                            id: id,
                                            text: '',
                                            type: Qs.MC,
                                            required: false,
                                            choices: [{ id: generateUUID(), text: 'Option 1' }],
                                            other: false,
                                            lastUpdated: new Date(),
                                        }

                                        setIsFocused(id, qNew)
                                    }}
                                >
                                    <Box
                                        style={{
                                            maxWidth: 770,
                                            margin: `0 auto`,
                                        }}
                                    >
                                        <Widget disableWidgetMenu inheritHeight bodyClass={classes.section}>
                                            <Box display="flex" style={{ justifyContent: `center` }}>
                                                <AddQuestionIcon style={{ marginRight: 4 }} />
                                                <Typography style={{ marginLeft: 4 }}>Add Question</Typography>
                                            </Box>
                                        </Widget>
                                    </Box>
                                </Link>
                            </Grid>
                        )}
                        {action === 'view' && (
                            <Grid item xs={12}>
                                <FormSubmission
                                    isLoading={viewSubmission.loading}
                                    hideCancel={true}
                                    onSubmit={() => submitPoll()}
                                    submitDisabled={!hasCompletedPoll()}
                                    submitText="Submit"
                                    errorText={viewSubmission.error}
                                />
                            </Grid>
                        )}
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    maxWidth: 770,
                                    margin: `0 auto`,
                                }}
                            >
                                <Widget disableWidgetMenu inheritHeight noBodyPadding bodyClass={classes.sectionNoHighlight}>
                                    <Box>
                                        <Box className={classes.innerPadding}>
                                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <Box style={{ width: 48, height: 48 }}></Box>
                                                <Typography style={{ fontSize: 36, textAlign: `center` }}>{getResponsesTitle()}</Typography>
                                                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <Tooltip title="Export Responses">
                                                        <span>
                                                            <IconButton
                                                                onClick={() => {
                                                                    exportResponses()
                                                                }}
                                                                disabled={responses.length === 0}
                                                            >
                                                                <ExportIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Box>
                                            </Box>
                                            <FormGroup row style={{ display: `flex`, justifyContent: `center` }}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            checked={poll.settings.open}
                                                            onChange={() => {
                                                                updateSetting('open', poll.settings.open === false)
                                                            }}
                                                        />
                                                    }
                                                    style={{ marginRight: 0 }}
                                                    label="Open for responses"
                                                />
                                            </FormGroup>
                                            {!!poll.settings.autoClose && (
                                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8, justifyContent: 'center' }}>
                                                    <Typography>closes at</Typography>
                                                    <Select
                                                        native
                                                        variant="outlined"
                                                        margin="dense"
                                                        size="small"
                                                        style={{ marginTop: 4, marginBottom: 4 }}
                                                        value={
                                                            new Date(poll.settings.autoClose).getMinutes() + new Date(poll.settings.autoClose).getHours() * 60
                                                        }
                                                        onChange={e => {
                                                            const autoCloseDate = new Date(poll.settings.autoClose)
                                                            const val = e.target.value * 60 * 1000
                                                            autoCloseDate.setHours(0, 0, 0, 0)
                                                            updateSetting('autoClose', preventPastDate(autoCloseDate.getTime() + val))
                                                        }}
                                                    >
                                                        {getFilteredTimes(new Date(poll.settings.autoClose)).map(time => (
                                                            <option value={time.val} key={time.val}>
                                                                {time.str}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                    <DateSelect
                                                        date={new Date(poll.settings.autoClose)}
                                                        setDate={date => {
                                                            const autoCloseDate = new Date(poll.settings.autoClose)
                                                            const dateMillis = (autoCloseDate.getMinutes() + autoCloseDate.getHours() * 60) * 60 * 1000
                                                            updateSetting('autoClose', preventPastDate(date.getTime() + dateMillis))
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                            {false && poll.settings.open && poll.settings.autoClose && (
                                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                    <Box>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                disableToolbar
                                                                inputVariant="outlined"
                                                                format="MM/dd/yyyy"
                                                                placeholder={'mm/dd/yyyy'}
                                                                value={'05/04/2021'}
                                                                style={{ marginTop: 4, width: 172, marginRight: 4 }}
                                                                InputLabelProps={{ shrink: false }}
                                                                onChange={val => {
                                                                    if (val && !isNaN(val)) {
                                                                        //onChange('value', val)
                                                                    } else {
                                                                        //onChange('value', null)
                                                                    }
                                                                }}
                                                                margin="dense"
                                                                type="text"
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </Box>
                                                    <Box style={{ marginLeft: 4 }}>
                                                        <Select
                                                            variant="outlined"
                                                            margin="dense"
                                                            value={15}
                                                            onChange={event => console.log(event.target.value)}
                                                            style={{ marginTop: 4, marginBottom: 4 }}
                                                        >
                                                            {times.map(time => (
                                                                <MenuItem value={time.val}>{time.str}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                        <ButtonBase
                                            style={{
                                                width: `100%`,
                                            }}
                                            onClick={() => {
                                                setShowResponseSettings(!showResponseSettings)
                                            }}
                                        >
                                            <Typography variant="h6">Settings</Typography>
                                            {showResponseSettings ? <ExpandHiddenIcon /> : <ExpandShownIcon />}
                                        </ButtonBase>
                                        {showResponseSettings && (
                                            <>
                                                <Divider />
                                                <Box className={classes.innerPadding} style={{ marginTop: 6, marginBottom: 6 }}>
                                                    <FormGroup row style={{ display: `flex`, justifyContent: `center` }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    checked={poll.settings.anonymous}
                                                                    onChange={() => updateSetting('anonymous', !poll.settings.anonymous)}
                                                                />
                                                            }
                                                            label="Make responses anonymous"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    checked={poll.settings.multiple}
                                                                    onChange={() => updateSetting('multiple', !poll.settings.multiple)}
                                                                />
                                                            }
                                                            label="Allow multiple responses"
                                                        />
                                                        {false && !poll.settings.multiple && !poll.settings.anonymous && (
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        color="primary"
                                                                        checked={poll.settings.editing}
                                                                        onChange={() => updateSetting('editing', !poll.settings.editing)}
                                                                    />
                                                                }
                                                                label="Allow response editing"
                                                            />
                                                        )}
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    color="primary"
                                                                    checked={poll.settings.results}
                                                                    onChange={() => updateSetting('results', !poll.settings.results)}
                                                                />
                                                            }
                                                            label="Allow overall results viewing"
                                                        />
                                                        {!poll.settings.anonymous && (
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        color="primary"
                                                                        checked={poll.settings.viewing}
                                                                        onChange={() => updateSetting('viewing', !poll.settings.viewing)}
                                                                    />
                                                                }
                                                                label="Allow personal responses viewing"
                                                            />
                                                        )}
                                                    </FormGroup>
                                                </Box>
                                            </>
                                        )}
                                        <Divider />
                                        <ButtonBase
                                            style={{
                                                width: `100%`,
                                                paddingTop: 2,
                                            }}
                                            onClick={() => {
                                                setShowResponses(!showResponses)
                                            }}
                                        >
                                            <Typography variant="h6">Member Responses</Typography>
                                            {showResponses ? <ExpandHiddenIcon /> : <ExpandShownIcon />}
                                        </ButtonBase>
                                        {showResponses && (
                                            <>
                                                <Divider />
                                                <Box className={classes.innerPadding} style={{ marginTop: 6, marginBottom: 6 }}>
                                                    <Grid container>
                                                        <Grid item xs={12} md={6}>
                                                            <Table
                                                                title="Responded"
                                                                columns={responseCols}
                                                                sortable={true}
                                                                defaultSortable={defaultSortableCol}
                                                                showOnEmpty={true}
                                                                emptyText="None Responded"
                                                                data={memberResponses.responded}
                                                                numRows={5}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Table
                                                                title="Not Responded"
                                                                columns={responseCols}
                                                                sortable={true}
                                                                defaultSortable={defaultSortableCol}
                                                                showOnEmpty={true}
                                                                emptyText="All Responded"
                                                                data={memberResponses.notResponded}
                                                                numRows={5}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </>
                                        )}
                                        <Divider style={{ marginBottom: 6 }} />
                                        <Box className={classes.innerPadding}>
                                            <Tabs
                                                tabs={getValidTabs()}
                                                selected={viewResponsesType}
                                                onChange={val => {
                                                    setViewResponsesType(val)
                                                }}
                                            />
                                        </Box>
                                        {viewResponsesType === 'individual' && responses.length > 0 && (
                                            <>
                                                <Divider />
                                                {renderAuthor(
                                                    responses[viewResponseIndex % responses.length].author
                                                        ? responses[viewResponseIndex % responses.length].author
                                                        : false,
                                                    null,
                                                )}
                                                {responses[viewResponseIndex % responses.length].time && (
                                                    <Typography align="center">
                                                        {moment(getFirebaseDate({ date: responses[viewResponseIndex % responses.length].time })).format(
                                                            'MMMM Do YYYY, h:mm:ss a',
                                                        )}
                                                    </Typography>
                                                )}
                                                <Divider />
                                                <Box style={{ display: 'flex', justifyContent: 'center', margin: 3 }}>
                                                    <Tooltip title="First">
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => setViewResponseIndex(0)}
                                                                style={{
                                                                    marginRight: 4,
                                                                    padding: 2,
                                                                }}
                                                                disabled={viewResponseIndex <= 0}
                                                            >
                                                                <FirstPageIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title="Previous">
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => setViewResponseIndex(viewResponseIndex - 1)}
                                                                style={{
                                                                    marginRight: 4,
                                                                    padding: 2,
                                                                }}
                                                                disabled={viewResponseIndex <= 0}
                                                            >
                                                                <PrevPageIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Typography style={{ margin: 3 }} noWrap>
                                                        {`${(viewResponseIndex % responses.length) + 1} of ${responses.length}`}
                                                    </Typography>
                                                    <Tooltip title="Next">
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => setViewResponseIndex(viewResponseIndex + 1)}
                                                                style={{
                                                                    marginRight: 4,
                                                                    padding: 2,
                                                                }}
                                                                disabled={viewResponseIndex >= responses.length - 1}
                                                            >
                                                                <NextPageIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title="Last">
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => setViewResponseIndex(responses.length - 1)}
                                                                style={{
                                                                    marginRight: 4,
                                                                    padding: 2,
                                                                }}
                                                                disabled={viewResponseIndex >= responses.length - 1}
                                                            >
                                                                <LastPageIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Box>
                                            </>
                                        )}
                                        {viewResponsesType === 'member' && responses.length > 0 && (
                                            <>
                                                <Divider />
                                                {renderAuthor(viewResponseAuthor && viewResponseAuthor.id ? viewResponseAuthor : false, authorResponses)}
                                                <Divider />
                                                <Box style={{ display: 'flex', justifyContent: 'center', margin: 3 }}>
                                                    <Tooltip title="First">
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => setViewReponseAuthor({ ...authorResponses[0], i: 0 })}
                                                                style={{
                                                                    marginRight: 4,
                                                                    padding: 2,
                                                                }}
                                                                disabled={viewResponseAuthor.i <= 0}
                                                            >
                                                                <FirstPageIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title="Previous">
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    setViewReponseAuthor({
                                                                        ...authorResponses[viewResponseAuthor.i - 1],
                                                                        i: viewResponseAuthor.i - 1,
                                                                    })
                                                                }
                                                                style={{
                                                                    marginRight: 4,
                                                                    padding: 2,
                                                                }}
                                                                disabled={viewResponseAuthor.i <= 0}
                                                            >
                                                                <PrevPageIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Typography style={{ margin: 3 }} noWrap>
                                                        {`${viewResponseAuthor.i + 1} of ${authorResponses.length}`}
                                                    </Typography>
                                                    <Tooltip title="Next">
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    setViewReponseAuthor({
                                                                        ...authorResponses[viewResponseAuthor.i + 1],
                                                                        i: viewResponseAuthor.i + 1,
                                                                    })
                                                                }
                                                                style={{
                                                                    marginRight: 4,
                                                                    padding: 2,
                                                                }}
                                                                disabled={viewResponseAuthor.i >= authorResponses.length - 1}
                                                            >
                                                                <NextPageIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title="Last">
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    setViewReponseAuthor({
                                                                        ...authorResponses[authorResponses.length - 1],
                                                                        i: authorResponses.length - 1,
                                                                    })
                                                                }
                                                                style={{
                                                                    marginRight: 4,
                                                                    padding: 2,
                                                                }}
                                                                disabled={viewResponseAuthor.i >= authorResponses.length - 1}
                                                            >
                                                                <LastPageIcon />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                </Widget>
                            </Box>
                        </Grid>
                    </>
                )}
                {action === 'responses' && (
                    <>
                        {questions.map((question, index) => (
                            <>
                                {question.type !== Qs.TITLE && question.type !== Qs.PICTURE && (
                                    <Grid item xs={12}>
                                        <Box
                                            style={{
                                                maxWidth: 770,
                                                margin: `0 auto`,
                                            }}
                                        >
                                            {renderQuestion(question, index, viewResponsesType)}
                                        </Box>
                                    </Grid>
                                )}
                            </>
                        ))}
                    </>
                )}
            </Grid>
            <DialogMobile
                maxWidth="md"
                fullWidth
                aria-labelledby="share-dialog-title"
                open={shareDialog.open}
                onClose={() => {
                    if (!shareDialog.loading) {
                        setShareDialog({ ...shareDialog, open: false })
                    }
                }}
            >
                <DialogTitleMobile
                    id="share-dialog-title"
                    onClose={() => {
                        if (!shareDialog.loading) {
                            setShareDialog({ ...shareDialog, open: false })
                        }
                    }}
                >
                    Share
                </DialogTitleMobile>
                <DialogContentMobile dividers>
                    <Tabs
                        tabs={[
                            { id: 'link', name: 'Link', width: 50 },
                            { id: 'app', name: 'Notification', width: 80 },
                        ]}
                        selected={shareDialog.tab}
                        onChange={val => {
                            setShareDialog({ ...shareDialog, tab: val })
                        }}
                        style={{ marginTop: -8 }}
                        disabled={shareDialog.loading}
                    />
                    <Divider style={{ marginLeft: -16, marginRight: -16, marginBottom: '0.35em' }} />
                    {poll && !poll.settings.open && <ErrorTypography text="Poll not open for responses" isCentered={true} />}
                    {shareDialog.tab && shareDialog.tab === 'app' && (
                        <>
                            <Typography gutterBottom align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                                Notification
                            </Typography>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                label="Message"
                                type="text"
                                disabled={shareDialog.loading || !poll.settings.open}
                                value={shareDialog.notificationMessage}
                                onChange={e => {
                                    setShareDialog({ ...shareDialog, notificationMessage: e.target.value })
                                }}
                                InputProps={{
                                    multiline: true,
                                    rows: 4,
                                }}
                                inputProps={{
                                    maxLength: 512,
                                }}
                                fullWidth
                            />
                        </>
                    )}
                    {shareDialog.tab && shareDialog.tab === 'link' && (
                        <>
                            <Typography gutterBottom align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                                Link Sharing
                            </Typography>
                            <Typography align="center">Useful for sharing via almost any medium</Typography>
                            <TextField value={`https://greekconnect.app/app/applications/polls/view/${user.getChapter()}/${pollID}`} disabled fullWidth />
                        </>
                    )}
                    {shareDialog.error && <ErrorTypography text={shareDialog.error} isCentered={true} />}
                </DialogContentMobile>
                <DialogActionsMobile>
                    <Button
                        onClick={() => {
                            setShareDialog({ open: false })
                        }}
                        color="primary"
                        disabled={shareDialog.loading}
                    >
                        Cancel
                    </Button>
                    {shareDialog.tab && shareDialog.tab === 'app' && (
                        <Button
                            disabled={shareDialog.loading || !poll.settings.open}
                            onClick={() => {
                                setShareDialog({ ...shareDialog, loading: true })

                                const data = {
                                    chapter: user.getChapter(),
                                    pollID: pollID,
                                    message: shareDialog.notificationMessage ? shareDialog.notificationMessage : '',
                                }

                                var functionsCall = app.functions().httpsCallable('publishPoll')
                                functionsCall(data)
                                    .then(function() {
                                        setShareDialog({ ...shareDialog, open: false })
                                    })
                                    .catch(function(error) {
                                        console.log('AN ERROR OCCURRED', error.code, error.message, error.details)
                                        setShareDialog({ ...shareDialog, loading: false, error: error.message })
                                    })
                            }}
                            color="primary"
                        >
                            Send
                        </Button>
                    )}
                    {shareDialog.tab && shareDialog.tab === 'link' && (
                        <CopyToClipboard>
                            {({ copy }) => (
                                <Button
                                    disabled={shareDialog.loading}
                                    onClick={() => {
                                        setCopiedToClipboard(true)
                                        copy(`https://greekconnect.app/app/applications/polls/view/${user.getChapter()}/${pollID}`)
                                    }}
                                    color="primary"
                                >
                                    Copy
                                </Button>
                            )}
                        </CopyToClipboard>
                    )}
                </DialogActionsMobile>
            </DialogMobile>
            <Snackbar open={copiedToClipboard} autoHideDuration={2000} onClose={() => setCopiedToClipboard(false)}>
                <Alert onClose={() => setCopiedToClipboard(false)} severity="success">
                    Copied to Clipboard
                </Alert>
            </Snackbar>
        </LocalizationProvider>
    )
}
