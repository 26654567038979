import React from 'react'

import UpdateConversation from 'components/Messages/UpdateConversation'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { HeaderContext } from 'context/HeaderContext'
import { MessagesContext } from 'context/MessagesContext'

import { getPossibleMembers, markSetupStepComplete } from 'code/Helper'

import { Messages } from 'objects/Messages'

const UpdateMessages = ({ history, ...props }) => {
    const { setIcons } = React.useContext(HeaderContext)

    const conversationId = props.match.params.conversationId
    const isNew = typeof conversationId !== 'string'

    const eventId = props.match.params.eventId
    const trigger = props.match.params.trigger

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const { createConversation, getConversation, updateConversation } = React.useContext(MessagesContext)

    const [conversation, setConversation] = React.useState(
        isNew
            ? (trigger && eventId ? Messages.getNewChatFromNewConversation({ trigger: trigger, eventId: eventId }) : Messages.getNewChat()).getRaw()
            : getConversation(conversationId),
    )

    React.useEffect(() => {
        setIcons([])
    }, [])

    const createConvo = async convo => {
        delete convo.id

        if (convo.type === 1 && 'messages' in convo) {
            delete convo.messages
        }

        if (convo.mType === 0) {
            if ('mems' in convo) {
                delete convo['mems']
            }
        } else {
            if ('mems' in convo) {
                convo.mems = [user.getId(), ...convo.mems]
            } else {
                convo.mems = [user.getId()]
            }
        }

        let id = await createConversation({
            ...convo,
            owner: user.getId(),
        })

        if (id) {
            history.replace(`/app/messages/${id}`)
        }
    }

    if (isNew) {
        return (
            <UpdateConversation
                isNew
                conversation={conversation}
                onCancel={() => history.goBack()}
                onUpdate={(field, val) => setConversation(conversation => ({ ...conversation, [field]: val }))}
                onSubmit={convo => {
                    createConvo(convo)
                    markSetupStepComplete('messages')
                }}
                history={history}
                getPossibleMembers={() => getPossibleMembers(chapter.members, [user.getId()])}
            />
        )
    }

    if (!conversation) {
        return <></>
    }

    return (
        <UpdateConversation
            conversationId={conversationId}
            conversation={conversation}
            onCancel={() => history.goBack()}
            onUpdate={(field, val) => setConversation(conversation => ({ ...conversation, [field]: val }))}
            onSubmit={convo => {
                updateConversation(
                    conversationId,
                    conversation.mType === 0 ? { name: convo.name, avatar: convo.avatar, vis: convo.vis } : { name: convo.name, avatar: convo.avatar },
                )
                history.goBack()
            }}
            history={history}
        />
    )
}

export default React.memo(UpdateMessages)
