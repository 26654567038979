import React from 'react'

import { Grid } from '@material-ui/core'
import ErrorTypography from '../../Typography/ErrorTypography'
import { Button } from '../../Wrappers'

// styles
import useStyles from './styles'

export default function FormSubmission(props) {
    var classes = useStyles()

    const { isLoading, onCancel, onSubmit, submitText, errorText, hideCancel, submitDisabled } = props

    if (hideCancel) {
        return (
            <Grid container spacing={2}>
                {errorText && (
                    <Grid item xs={12}>
                        <ErrorTypography text={errorText} isCentered={true} />
                    </Grid>
                )}
                <Grid item xs={12} className={classes.submitParentCentered}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={onSubmit}
                        className={classes.submit}
                        disabled={submitDisabled || isLoading}
                        loading={isLoading}
                    >
                        {submitText ? submitText : 'Create'}
                    </Button>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={2}>
            {errorText && (
                <Grid item xs={12}>
                    <ErrorTypography text={errorText} isCentered={true} />
                </Grid>
            )}
            <Grid item xs={6} className={classes.cancelParent}>
                <Button variant={'contained'} color={'secondary'} className={classes.cancel} onClick={onCancel} disableElevation>
                    Cancel
                </Button>
            </Grid>
            <Grid item xs={6} className={classes.submitParent}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={onSubmit}
                    className={classes.submit}
                    disabled={submitDisabled || isLoading}
                    loading={isLoading}
                    disableElevation
                >
                    {submitText ? submitText : 'Create'}
                </Button>
            </Grid>
        </Grid>
    )
}
