import React from 'react'
import { Box } from '@material-ui/core'

import PollResults from '../PollResults'
import PollVote from '../PollVote'

import useStyles from './styles'

const Poll = () => {
    const classes = useStyles()

    const [votes, setVotes] = React.useState(['andrew', 'andrew', 'jackson', 'caleb', 'andrew'])

    const title = 'Presidential Election'

    const options = {
        andrew: {
            color: '#1E88E5',
            label: 'Andrew Wood',
        },

        jackson: {
            color: '#FFC409',
            label: 'Jackson Young',
        },
        caleb: {
            color: '#2DD36F',
            label: 'Caleb Lawson',
        },
    }

    const updateVotes = React.useCallback((i, val) => {
        setVotes(votes => {
            let arr = [...votes]
            arr[i] = val
            return arr
        })
    }, [])

    return (
        <Box className={classes.parentBox}>
            <Box className={classes.sectionBox}>
                <PollVote value={votes[3]} title={title} rotation={4} duration={2500} options={options} onSelect={val => updateVotes(3, val)} />
                <PollVote value={votes[4]} title={title} rotation={7} duration={1500} options={options} onSelect={val => updateVotes(4, val)} />
            </Box>
            <Box className={classes.sectionBox}>
                <PollResults options={options} rotation={1} duration={1500} title={title} values={votes} />
                <PollVote value={votes[2]} title={title} rotation={-2} duration={4000} options={options} onSelect={val => updateVotes(2, val)} />
            </Box>
            <Box className={classes.sectionBox}>
                <PollVote key="votes-0" value={votes[0]} title={title} rotation={-5} duration={2000} options={options} onSelect={val => updateVotes(0, val)} />
                <PollVote key="votes-1" value={votes[1]} title={title} rotation={-8} duration={3500} options={options} onSelect={val => updateVotes(1, val)} />
            </Box>
        </Box>
    )
}

export default React.memo(Poll)
