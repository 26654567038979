import { makeStyles } from '@material-ui/styles'

/* eslint-disable no-dupe-keys */
export default makeStyles(theme => ({
    action: {
        borderRadius: 0,
        fontSize: '0.875em',
        padding: '0.575em 0.5em',
        width: '100%',
    },
    avatar: {
        fontSize: '1em',
        width: '2em',
        height: '2em',
    },
    avatarButton: {
        fontSize: '1em',
        padding: '0.25em',
    },
    nonOverflowText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '100%',
    },
    avatarParent: { margin: '-0.25em', marginRight: '0.125em' },
    body: {
        margin: '0.5em 0.75em',
    },
    bodyImage: {
        borderRadius: `2px 2px 0 0`,
        display: 'block',
        margin: '-0.5em -0.75em',
        width: '100%',
        width: '-moz-available',
        width: '-webkit-fill-available',
        width: 'fill-available',
        height: '12em',
        objectFit: 'cover',
    },
    bottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    boxLoading: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: `0.5em 0.75em`, minHeight: `${210 / 18}em` },
    icon: {
        fontSize: `1.15em`,
        width: '1em',
        height: '1em',
        verticalAlign: 'text-bottom',
    },
    iconShrunk: {
        verticalAlign: 'middle',
    },
    menuImage: {
        display: 'block',
        maxWidth: '100%',
        height: '18em',
        objectFit: 'contain',
        margin: '0 auto',
        borderRadius: 8,
        border: `2px solid ${theme.palette.text.primary}22`,
    },
    root: {
        color: theme.palette.text.primary,
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        margin: `0.5em 0.75em`,
    },
    topIconHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '0.5em',
        marginBottom: '0.125em',
        fontSize: '2em',
    },
    topIconHeaderIcon: {
        lineHeight: 1,
    },
    topTitle: { display: 'flex', flexGrow: 1, flexDirection: 'row' },
    progressCircle: { color: theme.palette.text.primary, marginRight: 24 },
}))
