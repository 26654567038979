import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    uploadFile: {
        borderRadius: 4,
        border: theme.border,
        width: `100%`,
        height: 274,
        '&:hover, &:focus': {
            borderColor: theme.palette.text.primary,
        },
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    uploadFilePic: {
        flexGrow: 1,
        display: 'flex',
    },
    uploadFilePicInner: {
        margin: 'auto',
        fontSize: '6rem',
    },
    fileImage: {
        display: 'flex',
        height: 196,
        objectFit: 'cover',
    },
    fileImageBox: {
        borderRadius: 4,
        border: theme.border,
        margin: '0 auto',
        overflow: 'hidden',
    },
    uploadFileDesc: {
        display: 'flex',
        flexDirection: 'column',
    },
}))
