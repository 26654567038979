export class Question {
    constructor(question) {
        this.question = question
    }

    static get QUESTION_TYPES() {
        return [
            {
                name: 'Header',
                value: this.TITLE,
            },
            /*{
                name: 'Picture',
                value: this.PICTURE,
            },*/
            {
                name: 'Short Answer',
                value: this.TEXT,
            },
            {
                name: 'Paragraph',
                value: this.PARAGRAPH,
            },
            {
                name: 'Multiple Choice',
                value: this.MC,
            },
            {
                name: 'Checkboxes',
                value: this.CHECKBOX,
            },
            {
                name: 'Number',
                value: this.NUMBER,
            },
            {
                name: 'Date',
                value: this.DATE,
            },
            {
                name: 'Ranked',
                value: this.RANKED,
            },
            /*{
                name: 'File Upload',
                value: this.FILE,
            },
            {
                name: 'Files Upload',
                value: this.FILES,
            },*/
        ]
    }

    static get TITLE() {
        return 0
    }

    static get PICTURE() {
        return 8
    }

    static get MC() {
        return 1
    }

    static get CHECKBOX() {
        return 2
    }

    static get NUMBER() {
        return 3
    }

    static get TEXT() {
        return 4
    }

    static get PARAGRAPH() {
        return 5
    }

    static get FILE() {
        return 6
    }

    static get FILES() {
        return 7
    }

    static get DATE() {
        return 9
    }

    static get RANKED() {
        return 10
    }

    getId() {
        return this.qeustion.id
    }

    isRequired() {
        return this.question.required
    }

    getType() {
        return this.question.type
    }

    getText() {
        return this.question.text
    }

    getDescription() {
        return this.question.description
    }

    // Get options (for mc & checkboxes)
    getOptions() {
        return this.question.options
    }
}
