import React from 'react'

import { Grid, Box, Tooltip, IconButton, Typography } from '@material-ui/core'

// styles
import useStyles from './styles'

export default function ParentTable(props) {
    var classes = useStyles()

    const { title, actions, children, rows } = props

    //index === rows.length - 1 && rows.length % 2 === 1 ? 12 :
    function getSize(minSize, num, index) {
        //           4        5    2
        // 5 items but 3 per row
        let numPerRow = 12 / minSize // 12 / 4 = 3
        let overflow = num % numPerRow // 5 % 3 = 2

        if (overflow !== 0 && index >= num - overflow) {
            // There's an uneven amount & we are in the last row
            return 12 / overflow
        }

        return minSize
    }

    return (
        <Grid container key={1}>
            <Grid item xs={12} key={1}>
                <Box display="flex" alignItems="center" justifyContent="space-between" className={classes.tableHeader}>
                    <Typography variant="h6" style={{ fontWeight: 500 }}>
                        {title}
                    </Typography>
                    <Box>
                        {actions ? (
                            actions.map(action => (
                                <Tooltip title={action.title} key={action.title}>
                                    <span>
                                        <IconButton
                                            size="small"
                                            onClick={action.onClick}
                                            style={{
                                                marginRight: 4,
                                            }}
                                            {...action}
                                        >
                                            {action.icon}
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ))
                        ) : (
                            <></>
                        )}
                    </Box>
                </Box>
            </Grid>
            {rows &&
                rows.map((row, index) => (
                    <Grid item xs={12} md={getSize(6, rows.length, index)} lg={getSize(4, rows.length, index)} key={index}>
                        {row}
                    </Grid>
                ))}
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    )
}
