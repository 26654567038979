import React, { useRef } from 'react'

import cn from 'classnames'

import app from 'firebase/app'

import { generateUUID } from 'code/UUID'

import { getWeek, getWeekYear, isMobileDevice } from 'code/Helper'

import { Fastfood as FoodIcon, HomeOutlined as HomeIcon, FastfoodOutlined as MealsIcon, MenuBookRounded as MenusIcon } from '@material-ui/icons'

import { Box, Button, CircularProgress, Grid, IconButton, TextField, Typography } from '@material-ui/core'

import { Document, Page, pdfjs } from 'react-pdf'

import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { DateTime } from 'luxon'

import dayjs from 'dayjs'
import moment from 'moment'

import endOfWeek from 'date-fns/endOfWeek'
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import isValid from 'date-fns/isValid'
import isWithinInterval from 'date-fns/isWithinInterval'
import startOfWeek from 'date-fns/startOfWeek'

// components
import FormSubmission from 'components/Forms/FormSubmission'
import NavigationBar from 'components/NavigationBar'
import SelectAutocomplete from 'components/SelectAutocomplete'
import Widget from 'components/Widget'

import { AuthContext } from 'context/AuthContext'

import Resizer from 'react-image-file-resizer'

// styles
import useStyles from '../../styles'

pdfjs.GlobalWorkerOptions.workerSrc = `${isMobileDevice() ? 'https:' : ''}//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function NewMenu(props) {
    var classes = useStyles()

    const { user } = React.useContext(AuthContext)

    const menuInputRef = useRef(null)

    const [menuID] = React.useState(props.match.params.id)
    const [isNew] = React.useState(props.match.params.id === 'new')

    const fileTypes = ['image/png', 'image/jpeg', 'application/pdf']
    const [date, setDate] = React.useState(new Date())
    const [feedbackURL, setFeedbackURL] = React.useState('')
    const [meals, setMeals] = React.useState([])
    const [mealOptions, setMealOptions] = React.useState([])
    let [menuFile, setMenuFile] = React.useState(null)
    let [isPDF, setIsPDF] = React.useState(false)
    let [menuImage, setMenuImage] = React.useState(null)

    const [error, setError] = React.useState(false)

    function fileListToArray(list) {
        const array = []
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }

    function onDrop(e) {
        const files = e.dataTransfer.files
        const array = fileListToArray(files)
        if (!menuFile && array[0]) {
            if (fileTypes.includes(array[0].type)) {
                addFile(array[0])
            }
        }
        e.preventDefault()
    }

    function onFilesAdded(event) {
        const files = event.target.files
        const array = fileListToArray(files)
        if (!menuFile && array[0]) {
            addFile(array[0])
        }
    }

    function addFile(file) {
        setMenuFile(URL.createObjectURL(file))

        if (file.type !== 'application/pdf') {
            uploadImage(file)
            setIsPDF(false)
        } else {
            setIsPDF(true)
        }
    }

    function delMenu() {
        if (menuFile != null) {
            setMenuFile(null)
            setMenuImage(null)
            if (menuInputRef.current) {
                menuInputRef.current.value = null
            }
        }
    }

    const [hasTried, setHasTried] = React.useState(false)
    const [hasLoaded, setHasLoaded] = React.useState(false)

    function getMealName(meal) {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let meals = ['Breakfast', 'Lunch', 'Dinner']

        return days[meal.dow] + ' ' + meals[meal.mod]
    }

    function grabPlan() {
        setHasTried(true)
        const db = app.firestore()
        db.collection('chapters')
            .doc(user.getChapter())
            .collection('menus')
            .doc('INFO')
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()

                    let msKeys = Object.keys(data.meals)
                    let ms = []

                    for (let i = 0; i < msKeys.length; i++) {
                        let key = msKeys[i]
                        let meal = data.meals[key]
                        ms.push({
                            id: key,
                            name: getMealName(meal),
                            dow: meal.dow,
                            mod: meal.mod,
                            cutoffs: meal.cutoffs,
                        })
                    }

                    ms.sort(function(a, b) {
                        if (a.dow !== b.dow) {
                            return a.dow - b.dow
                        }

                        if (a.mod !== b.mod) {
                            return a.mod - b.mod
                        }

                        return a.cutoffs[0] - b.cutoffs[0]
                    })

                    if (!isNew) {
                        const db = app.firestore()
                        db.collection('chapters')
                            .doc(user.getChapter())
                            .collection('menus')
                            .doc(menuID)
                            .get()
                            .then(function(doc) {
                                if (doc.exists) {
                                    let data = doc.data()
                                    if (data.feedback !== undefined) {
                                        setFeedbackURL(data.feedback)
                                        setMenuImage(data.photo)
                                        setMenuFile(true)
                                        setMeals(ms.filter(m => data.meals.includes(m.id)))
                                        let momentDate = moment()
                                            .day('Sunday')
                                            .year(data.year)
                                            .week(data.week)
                                            .toDate()
                                        setDate(momentDate)
                                        setHasLoaded(true)
                                    } else {
                                        window.alert('Cannot find this meal plan')
                                    }
                                } else {
                                    window.alert('Cannot find this meal plan')
                                }
                            })
                            .catch(function(error) {
                                console.log('Error getting document:', error)
                                window.alert('Cannot find this meal plan')
                            })
                    } else {
                        setMeals(ms)
                        setHasLoaded(true)
                    }
                    setMealOptions(ms)
                } else {
                    window.alert("Chapter hasn't have enabled meal plan")
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
                window.alert("Chapter hasn't have enabled meal plan")
            })
    }

    if (!hasTried) {
        grabPlan()
    }

    const formatWeekSelectLabel = (date, invalidLabel) => {
        let dateClone = makeJSDateObject(date)

        return dateClone && isValid(dateClone) ? `Week of ${format(startOfWeek(dateClone), 'MMM do, yyyy')}` : invalidLabel
    }

    const renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
        let dateClone = makeJSDateObject(date)
        let selectedDateClone = makeJSDateObject(selectedDate)

        const start = startOfWeek(selectedDateClone)
        const end = endOfWeek(selectedDateClone)

        const dayIsBetween = isWithinInterval(dateClone, { start, end })
        const isFirstDay = isSameDay(dateClone, start)
        const isLastDay = isSameDay(dateClone, end)

        const wrapperClassName = cn({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        })

        const dayClassName = cn(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        })

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {format(dateClone, 'd')} </span>
                </IconButton>
            </div>
        )
    }

    function makeJSDateObject(date) {
        if (date instanceof dayjs) {
            return date.clone().toDate()
        }

        if (moment.isMoment(date)) {
            return date.clone().toDate()
        }

        if (date instanceof DateTime) {
            return date.toJSDate()
        }

        if (date instanceof Date) {
            return new Date(date.getTime())
        }

        return date
    }

    function uploadImage(image) {
        Resizer.imageFileResizer(
            image, //is the file of the new image that can now be uploaded...
            1536, // is the maxWidth of the  new image
            1536, // is the maxHeight of the  new image
            'JPEG', // is the compressFormat of the  new image
            90, // is the quality of the  new image
            0, // is the rotation of the  new image
            uri => {
                uploadURI(uri)
            },
            'blob', // is the output type of the new image
        )
    }

    function uploadURI(uri) {
        let imageName = `chapters/` + user.getChapter() + `/menus/` + generateUUID() + `.jpg`
        const uploadTask = app
            .storage()
            .ref(imageName)
            .put(uri, { cacheControl: 'private, max-age=15552000' })
        uploadTask.on(
            'state_changed',
            snapshot => {},
            error => {
                // Error function ...
                console.log('error', error)
            },
            () => {
                // complete function ...
                app.storage()
                    .ref(imageName)
                    .getDownloadURL()
                    .then(function(url) {
                        setMenuImage(url)
                    })
            },
        )
    }

    const [isSubmitting, setIsSubmitting] = React.useState(false)

    function submitUpdate() {
        setIsSubmitting(true)

        let ms = []
        for (let i = 0; i < meals.length; i++) {
            ms.push(meals[i].id)
        }

        let d = makeJSDateObject(date)

        let submission = {
            chapter: user.getChapter(),
            menuID: isNew ? null : menuID,
            week: getWeek(d),
            year: getWeekYear(d),
            feedback: feedbackURL,
            meals: ms,
            photo: menuImage,
        }

        console.log('submitting menu', submission)

        let functionsCall = app.functions().httpsCallable('updateMenu')
        functionsCall(submission)
            .then(function(result) {
                setIsSubmitting(false)
                if (props.history.length === 0) {
                    props.history.replace('/app/applications/meals/menus/')
                } else {
                    props.history.goBack()
                }
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setError(message)
                setIsSubmitting(false)
            })
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Meals',
                            link: '/app/applications/meals/',
                            icon: <MealsIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: 'Menus',
                            link: '/app/applications/meals/menus',
                            icon: <MenusIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: isNew ? 'New' : 'Update',
                        },
                    ]}
                    grid
                />
                {hasLoaded && (
                    <Grid item xs={12}>
                        <Widget disableWidgetMenu inheritHeight>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            disabled={!isNew}
                                            inputVariant="outlined"
                                            label="Week"
                                            value={date}
                                            onChange={date => setDate(date)}
                                            renderDay={renderWrappedWeekDay}
                                            labelFunc={formatWeekSelectLabel}
                                            TextFieldComponent={props => <TextField margin="dense" fullWidth required {...props} />}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <input
                                        type="file"
                                        multiple={false}
                                        style={{ display: 'none' }}
                                        ref={menuInputRef}
                                        onChange={event => onFilesAdded(event)}
                                        accept="image/png,image/jpeg,application/pdf"
                                    />
                                    <Box
                                        className={classes.uploadPhoto}
                                        onClick={() => {
                                            if (!menuFile) {
                                                if (menuInputRef.current) {
                                                    menuInputRef.current.click()
                                                }
                                            }
                                        }}
                                        style={{ cursor: menuFile ? 'default' : 'pointer' }}
                                        onDrop={e => onDrop(e)}
                                        onDragOver={e => {
                                            e.preventDefault()
                                        }}
                                    >
                                        <Box className={classes.uploadPhotoPic} id="pdfRenderMenu">
                                            {menuFile ? (
                                                <>
                                                    {isPDF && (
                                                        <>
                                                            <Box style={{ position: 'absolute', left: -9999, top: -9999 }}>
                                                                <Document loading="" className={classes.menuImagePDFOuter} renderMode="canvas" file={menuFile}>
                                                                    <Page
                                                                        pageNumber={1}
                                                                        renderTextLayer={false}
                                                                        height={1960}
                                                                        onRenderSuccess={() => {
                                                                            const canvas = document.getElementById('pdfRenderMenu').children[0].children[0]
                                                                                .children[0].children[0]

                                                                            canvas.toBlob(function(blob) {
                                                                                uploadImage(blob)
                                                                            })
                                                                        }}
                                                                    />
                                                                </Document>
                                                            </Box>
                                                        </>
                                                    )}
                                                    {menuImage ? (
                                                        <img src={menuImage} className={classes.menuImage} alt="" />
                                                    ) : (
                                                        <Box style={{ height: 196, width: '100%', display: 'flex' }}>
                                                            <CircularProgress size={28} style={{ margin: 'auto' }} />
                                                        </Box>
                                                    )}
                                                </>
                                            ) : (
                                                <FoodIcon className={classes.uploadPhotoPicInner} />
                                            )}
                                        </Box>
                                        <Box className={classes.uploadPhotoDesc}>
                                            {menuFile ? (
                                                <>
                                                    <Button onClick={() => delMenu()} color="primary" style={{ margin: '0 auto' }}>
                                                        <Typography align="center">Delete Menu</Typography>
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography style={{ userSelect: 'none', WebkitUserSelect: 'none' }} align="center">
                                                        Drag a menu or click to upload
                                                    </Typography>
                                                    <Typography style={{ userSelect: 'none', WebkitUserSelect: 'none' }} align="center">
                                                        Accepted file types: jpg, pdf, or png.
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        label="Feedback URL"
                                        type="url"
                                        placeholder="http://... or https://..."
                                        value={feedbackURL}
                                        onChange={e => {
                                            setFeedbackURL(e.target.value)
                                        }}
                                        inputProps={{
                                            maxLength: 512,
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectAutocomplete
                                        variant="outlined"
                                        value={meals}
                                        onUpdate={(event, value) => {
                                            setMeals(value)
                                        }}
                                        freeSolo={false}
                                        title="Meals"
                                        events={mealOptions}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSubmission
                                        isLoading={isSubmitting}
                                        onCancel={() => props.history.push('/app/applications/meals/menus')}
                                        onSubmit={() => submitUpdate()}
                                        submitText={isNew ? 'Create' : 'Update'}
                                        errorText={error ? error : meals.length === 0 ? 'Must select at least 1 meal' : false}
                                        submitDisabled={meals.length === 0 || menuImage === null}
                                    />
                                </Grid>
                            </Grid>
                        </Widget>
                    </Grid>
                )}
            </Grid>
        </>
    )
}
