import React, { useRef } from 'react'
import { Typography } from '../../Wrappers'
import useStyles from './styles'

import app from 'firebase/app'

import { generateUUID } from 'code/UUID'

import { isMobileDevice } from 'code/Helper'

import { Box, Button, CircularProgress } from '@material-ui/core'

import { Document, Page, pdfjs } from 'react-pdf'

import Resizer from 'react-image-file-resizer'

import { AuthContext } from 'context/AuthContext'

import { uploadURI as uploadURIImage } from 'code/Image'

pdfjs.GlobalWorkerOptions.workerSrc = `${isMobileDevice() ? 'https:' : ''}//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function DocumentUploadLarge(props) {
    var classes = useStyles()

    const { user } = React.useContext(AuthContext)

    const inputRef = useRef(null)

    const fileTypes = ['image/png', 'image/jpeg', 'application/pdf']

    const { icon, title, onUpdateFile } = props

    let [file, setFile] = React.useState(null)
    let [isPDF, setIsPDF] = React.useState(false)
    let [image, setImage] = React.useState(null)

    function updateImage(url) {
        setImage(url)
        onUpdateFile(url)
    }

    function removeFile() {
        if (file != null) {
            setFile(null)
            updateImage(null)
            if (inputRef.current) {
                inputRef.current.value = null
            }
        }
    }

    function uploadImage(image) {
        Resizer.imageFileResizer(
            image, //is the file of the new image that can now be uploaded...
            1536, // is the maxWidth of the  new image
            1536, // is the maxHeight of the  new image
            'JPEG', // is the compressFormat of the  new image
            90, // is the quality of the  new image
            0, // is the rotation of the  new image
            uri => {
                uploadURI(uri)
            },
            'blob', // is the output type of the new image
        )
    }

    async function uploadURI(uri) {
        let imageName = `chapters/` + user.getChapter() + `/points/` + generateUUID() + `.jpg`

        const downloadUrl = await uploadURIImage(uri, imageName)
        updateImage(downloadUrl)
    }

    function fileListToArray(list) {
        const array = []
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }

    function onDrop(e) {
        const files = e.dataTransfer.files
        const array = fileListToArray(files)
        if (!file && array[0]) {
            if (fileTypes.includes(array[0].type)) {
                addFile(array[0])
            }
        }
        e.preventDefault()
    }

    function onFilesAdded(event) {
        const files = event.target.files
        const array = fileListToArray(files)
        if (!file && array[0]) {
            addFile(array[0])
        }
    }

    function addFile(file) {
        setFile(URL.createObjectURL(file))

        if (file.type !== 'application/pdf') {
            uploadImage(file)
            setIsPDF(false)
        } else {
            setIsPDF(true)
        }
    }

    return (
        <>
            <input
                type="file"
                multiple={false}
                style={{ display: 'none' }}
                ref={inputRef}
                onChange={event => onFilesAdded(event)}
                accept="image/png,image/jpeg,application/pdf"
            />
            <Box
                className={classes.uploadFile}
                onClick={() => {
                    if (!file) {
                        if (inputRef.current) {
                            inputRef.current.click()
                        }
                    }
                }}
                style={{ cursor: file ? 'default' : 'pointer' }}
                onDrop={e => onDrop(e)}
                onDragOver={e => {
                    e.preventDefault()
                }}
            >
                <Box className={classes.uploadFilePreview} id="pdfRenderMenu">
                    {file ? (
                        <>
                            {isPDF && (
                                <>
                                    <Box style={{ position: 'absolute', left: -9999, top: -9999 }}>
                                        <Document loading="" renderMode="canvas" file={file}>
                                            <Page
                                                pageNumber={1}
                                                renderTextLayer={false}
                                                height={1960}
                                                onRenderSuccess={() => {
                                                    const canvas = document.getElementById('pdfRenderMenu').children[0].children[0].children[0].children[0]

                                                    canvas.toBlob(function(blob) {
                                                        uploadImage(blob)
                                                    })
                                                }}
                                            />
                                        </Document>
                                    </Box>
                                </>
                            )}
                            {image ? (
                                <img src={image} className={classes.file} alt="" />
                            ) : (
                                <Box style={{ height: 196, width: '100%', display: 'flex' }}>
                                    <CircularProgress size={28} style={{ margin: 'auto' }} />
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box className={classes.uploadFileIcon}>{icon}</Box>
                    )}
                </Box>
                <Box className={classes.uploadFileDesc}>
                    {file ? (
                        <>
                            <Button onClick={() => removeFile()} color="primary" style={{ margin: '0 auto' }}>
                                <Typography align="center">Remove file</Typography>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography style={{ userSelect: 'none', WebkitUserSelect: 'none' }} align="center">
                                {title}
                            </Typography>
                            <Typography style={{ userSelect: 'none', WebkitUserSelect: 'none' }} align="center">
                                Accepted file types: jpg, pdf, or png.
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}
