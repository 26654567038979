import React from 'react'

import {
    ArrowForward as ArrowIcon,
    Folder as FolderIcon,
    FolderOutlined as FolderOutlinedIcon,
    VisibilityOutlined as VisibilityIcon
} from '@material-ui/icons'

import { useTheme } from '@material-ui/styles'

import { DriveIcon, DriveFilledIcon } from 'components/Icons/Icons'

import Box from '@mui/material/Box'
import DialogContentText from '@mui/material/DialogContentText'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { renderVisibility } from 'data/Visibility'

import { Button, DialogContent, DialogTitle, DialogActions, Dialog } from 'components/Wrappers'

import ErrorTypography from 'components/Typography/ErrorTypography'
import WarningTypography from 'components/Typography/WarningTypography'

const MoveToDialog = ({ open, file, items, folders, onClose, onMove }) => {
    const theme = useTheme()

    const closeDialog = onClose

    const [selectedFolder, setSelectedFolder] = React.useState('')

    React.useEffect(() => {
        if (file) setSelectedFolder(file.parent ?? '')
    }, [file])

    const RenderFolder = ({ folder, padding, disabled }) => (
        <>
            <ListItemButton
                selected={selectedFolder === folder.id}
                onClick={() => setSelectedFolder(folder.id)}
                disabled={disabled || (file && file.id === folder.id)}
                sx={{ pl: padding }}
            >
                <ListItemIcon>
                    {selectedFolder === folder.id ? <FolderIcon /> : <FolderOutlinedIcon />}
                </ListItemIcon>
                <ListItemText primary={folder.name} />
            </ListItemButton>
            {folder.folders && (
                <List component="div" disablePadding>
                    {folder.folders.map(folder => <RenderFolder folder={folder} key={folder.id} padding={padding + 2} disabled={disabled || (file && file.id === folder.id)} />)}
                </List>
            )}
        </>
    )

    const getIntersection = (file, selectedFolder, items) => {
        if (!file || !file.vis || !items) return []
        if (selectedFolder === '') return file.vis
        if (selectedFolder in items && Array.isArray(items[selectedFolder].vis)) {
            const newVisLimits = items[selectedFolder].vis
            return file.vis.filter(i => newVisLimits.includes(i))
        }
        return []
    }

    const intersection = React.useMemo(() => getIntersection(file, selectedFolder, items), [file, selectedFolder, items])

    const getIntersectionVisibility = () => {
        if (intersection.length === 0) return <span style={{ color: theme.palette.red.main, fontWeight: 'bold' }}>None</span>
        return renderVisibility(intersection)
    }

    return (
        <Dialog open={open} onClose={closeDialog} fullWidth aria-labelledby="move-to-dialog-title" maxWidth="sm">
            <DialogTitle id="move-to-dialog-title" onClose={closeDialog}>
                Move
            </DialogTitle>
            <DialogContent dividers>
                {file && file.name && (
                    <DialogContentText sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingBottom: 1 }}>
                        {file.icon && React.createElement(file.icon, { style: { marginInline: 12, verticalAlign: 'middle' } })}
                        {file.name}
                    </DialogContentText>
                )}
                <Box sx={{ paddingTop: 1, paddingBottom: 2 }}>
                    <Box sx={theme => ({ border: theme.border, borderRadius: 4, overflow: 'hidden' })}>
                        <List sx={{ padding: 0 }}>
                            <ListItemButton
                                selected={selectedFolder === ''}
                                onClick={() => setSelectedFolder('')}
                                sx={{ pl: 2 }}
                            >
                                <ListItemIcon>
                                    {selectedFolder === '' ? <DriveFilledIcon /> : <DriveIcon />}
                                </ListItemIcon>
                                <ListItemText primary={"Drive"} />
                            </ListItemButton>
                            {folders && folders.map(folder => 
                                <RenderFolder folder={folder} key={folder.id} padding={4} />
                            )}
                        </List>
                    </Box>
                </Box>
                {file && file.vis && items && (
                    <>
                        <DialogContentText>
                            <VisibilityIcon style={{ marginRight: 12, verticalAlign: 'middle' }} />
                            {renderVisibility(file.vis)}
                            <ArrowIcon style={{ marginInline: 12, verticalAlign: 'middle' }} />
                            {getIntersectionVisibility()}
                        </DialogContentText>
                        {intersection.length === 0 ? (
                            <ErrorTypography style={{ marginTop: 4 }} isCentered text="New folder needs to contain at least 1 overlapping visibility status" />
                        ) : file.vis.length !== intersection.length && (
                            <WarningTypography style={{ marginTop: 4 }} isCentered text="Only overlapping visibility statuses are kept" />
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onMove(selectedFolder)} disabled={intersection.length === 0 || (selectedFolder === '' && file && file.base) || (file && file.parent === selectedFolder)} color="primary">
                    Move
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MoveToDialog
