export const canShowTip = (user, id) => {
    let cacheTip = localStorage.getItem('cache_tip')

    if (cacheTip) {
        cacheTip = JSON.parse(cacheTip)

        let timeDiff = Math.floor((new Date() - new Date(cacheTip.date)) / 1000 / 60)

        if (cacheTip.id !== id && !isNaN(timeDiff) && timeDiff < 2) {
            return false
        }
    }

    // Set the name with the cache so only that one is shown for the next two minutes

    if (!user) {
        return false
    }

    if (user.tips && user.tips.includes(id)) {
        return false
    }

    localStorage.setItem('cache_tip', JSON.stringify({ date: new Date().toString(), id: id }))
    return true
}
