import React from 'react'

import app from 'firebase/app'

import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core'
import { Settings as AdminIcon, HomeOutlined as HomeIcon, GroupOutlined as RolesIcon } from '@material-ui/icons'

import FormSection from 'components/Forms/FormSection'
import FormSubmission from 'components/Forms/FormSubmission'
import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'
import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import { perms } from '../data.js'

import { isMobileDevice } from 'code/Helper'

export default function UpdateRole(props) {
    let roleID = props.match.params.id

    let isNew = roleID ? false : true

    const { chapter, requestChapterUpdate } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)

    const [errorText, setErrorText] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)
    const [roleTitle, setRoleTitle] = React.useState(isNew ? '' : chapter.roles[roleID].title)

    const dividerStyle = isMobileDevice() ? { marginLeft: -16, marginRight: -16 } : { marginLeft: -24, marginRight: -24 }

    let [permissions, setPermissions] = React.useState(
        perms.map((perm, index) => {
            let defaultVal = perm.perms[0].value
            // Grab the current value if existing role
            if (!isNew && chapter && chapter.perms) {
                for (let i = 1; i < perm.perms.length; i++) {
                    let curPerm = perm.perms[i]
                    if (chapter.perms[curPerm.value] !== undefined && chapter.perms[curPerm.value].includes(roleID)) {
                        defaultVal = curPerm.value
                    }
                }
            }
            return { value: defaultVal, index: index }
        }),
    )

    function handleSubmit() {
        if (roleTitle.length === 0) {
            setErrorText('Title cannot be blank')
        } else {
            submitRole()
        }
    }

    function submitRole() {
        let compiledPermissions = {}

        // Compiles the permissions for storing in the database
        permissions.forEach(permission => {
            let perm = perms[permission.index]
            let hasUsed = false
            for (let i = 0; i < perm.perms.length; i++) {
                let curPermPerm = perm.perms[i]
                let curValue = curPermPerm.value

                if (curValue !== '') {
                    if (!hasUsed) {
                        compiledPermissions[curValue] = true
                    } else {
                        compiledPermissions[curValue] = false
                    }
                }

                if (curValue === permission.value) {
                    hasUsed = true
                }
            }
        })

        setIsLoading(true)

        let submission = {
            chapter: user.getChapter(),
            title: roleTitle,
            perms: compiledPermissions,
            id: isNew ? '' : roleID,
            action: isNew ? 'add' : 'update',
        }

        let functionsCall = app.functions().httpsCallable('updateRole')
        functionsCall(submission)
            .then(async function(result) {
                await requestChapterUpdate()

                setTimeout(() => {
                    setIsLoading(false)

                    if (props.history.length === 0) {
                        props.history.replace(user.isHQ() ? '/app/hq/roles' : '/app/admin/roles')
                    } else {
                        props.history.goBack()
                    }
                }, 500)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('Update role error', code, message, details)
                setErrorText(message)
                setIsLoading(false)
            })
    }

    function handlePermChange(event, index) {
        let val = event.target.value
        let newPerms = [...permissions]

        newPerms[index].value = val
        setPermissions(newPerms)
    }

    const renderEditor = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    autoFocus={isNew}
                    variant="outlined"
                    margin="dense"
                    id="roleTitle"
                    aria-label="Title"
                    label={roleTitle ? '' : 'Title'}
                    InputLabelProps={{ shrink: false }}
                    type="text"
                    inputProps={{
                        maxLength: 32,
                    }}
                    onChange={e => {
                        setRoleTitle(e.target.value)
                    }}
                    value={roleTitle}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <FormSection label={'Permissions'} showHeader={true}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Divider style={dividerStyle} />
                        </Grid>
                        {permissions.map(perm => (
                            <>
                                <Grid item xs={12} key={perms[perm.index].id + '_section'}>
                                    <FormSection label={perms[perm.index].title} icon={perms[perm.index].icon} showHeader={true}>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                aria-label={perms[perm.index].title + ' Permissions'}
                                                name={perms[perm.index].id}
                                                value={perm.value}
                                                onChange={event => handlePermChange(event, perm.index)}
                                            >
                                                {perms[perm.index].perms.map(option => (
                                                    <FormControlLabel value={option.value} control={<Radio color="primary" />} label={option.title} />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </FormSection>
                                </Grid>
                                <Grid item xs={12} key={perm.id + '_divider'}>
                                    <Divider style={dividerStyle} />
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <FormSubmission
                    isLoading={isLoading}
                    onCancel={() => props.history.push('/app/admin/roles')}
                    onSubmit={() => handleSubmit()}
                    submitText={isNew ? 'Create' : 'Update'}
                    errorText={errorText}
                />
            </Grid>
        </Grid>
    )

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Admin',
                            link: '/app/admin',
                            iconMobileOnly: true,
                            icon: <AdminIcon />,
                        },
                        {
                            name: 'Roles',
                            link: '/app/admin/roles',
                            icon: <RolesIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: isNew ? 'Add' : 'Edit',
                        },
                    ]}
                    grid
                />
                <Grid item xs={12}>
                    {isMobileDevice() ? (
                        renderEditor()
                    ) : (
                        <Widget disableWidgetMenu inheritHeight>
                            {renderEditor()}
                        </Widget>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
