import { ArrowBack as ArrowBackIcon, LibraryBooks as DocumentationIcon, Feedback as FeedbackIcon, BugReport as BugReportIcon } from '@material-ui/icons'
import { Drawer, IconButton, List } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../context/LayoutContext'
import SidebarLink from './components/SidebarLink/SidebarLink'
import useStyles from './styles'

import { isMobileDevice } from 'code/Helper'

import { ChapterContext } from '../../context/ChapterContext'
import { AuthContext } from '../../context/AuthContext'

function Sidebar({ location, structure, staticContext, ...props }) {
    var classes = useStyles()
    var theme = useTheme()

    let { user } = React.useContext(AuthContext)
    let { chapter } = React.useContext(ChapterContext)

    const toggleDrawer = value => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        if (value && !isPermanent) toggleSidebar(layoutDispatch)
    }

    function isAdmin() {
        return user.isGCStaff()
    }

    function isHQ() {
        return user.isHeadquartersStaff()
    }

    function hasPermission(permission) {
        if (user && chapter) {
            let uid = user.getId()
            let permissions = chapter.perms
            if (uid && permissions && permission && chapter.members[uid]) {
                let userRole = chapter.members[uid].role
                if (userRole) {
                    if (permission === 'ADMIN') {
                        return userRole === 'ADMIN'
                    }
                    return userRole === 'ADMIN' || (Array.isArray(permissions[permission]) && permissions[permission].includes(userRole))
                }
            }
        }
        return false
    }

    function isInDevMode() {
        return '_self' in React.createElement('div')
    }

    // global
    var { isSidebarOpened: _isSidebarOpened  } = useLayoutState()
    var layoutDispatch = useLayoutDispatch()

    // local
    var [isPermanent, setPermanent] = useState(!isMobileDevice())
    const [isDevENV] = useState(isInDevMode())

    const isSidebarOpened = _isSidebarOpened || isPermanent

    useEffect(function() {
        window.addEventListener('resize', handleWindowWidthChange)
        handleWindowWidthChange()
        return function cleanup() {
            window.removeEventListener('resize', handleWindowWidthChange)
        }
    })

    return (
        <Drawer
            {...props}
            variant={isPermanent ? 'permanent' : 'temporary'}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
                paper: classNames(classes.drawerPaper, {
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened,
                }),
            }}
            open={isSidebarOpened}
            onClose={toggleDrawer(true)}
        >
            <div className={classes.toolbar} />
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse),
                        }}
                    />
                </IconButton>
            </div>
            <List className={classes.sidebarList} classes={{ padding: classes.padding }}>
                {structure.map(
                    link =>
                        ((isAdmin() && link.isAdmin) ||
                            (isHQ() && link.isHeadquarters) ||
                            (!link.isHeadquarters &&
                                !link.isAdmin &&
                                !isAdmin() &&
                                !isHQ() &&
                                (!link.feature || !chapter || !chapter.enabledFeatures || chapter.enabledFeatures[link.feature] !== false) &&
                                ((((link.permission && hasPermission(link.permission)) || !link.permission) && !link.shouldHide) || isDevENV))) && (
                            <SidebarLink key={link.id} location={location} isSidebarOpened={isSidebarOpened} {...link} toggleDrawer={toggleDrawer(true)} />
                        ),
                )}
                <SidebarLink
                    key={'documentationURL'}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    icon={<DocumentationIcon />}
                    label="Documentation"
                    url="https://getgreekconnect.com/docs/getting-started/overview"
                    toggleDrawer={toggleDrawer(true)}
                />
                <SidebarLink
                    key={'feedbackURL'}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    icon={<FeedbackIcon />}
                    label="Feedback"
                    url="https://forms.gle/2DnmXruEvMfx2TXn9"
                    toggleDrawer={toggleDrawer(true)}
                />
                <SidebarLink
                    key={'bugReportURL'}
                    location={location}
                    isSidebarOpened={isSidebarOpened}
                    icon={<BugReportIcon />}
                    label="Report Bug"
                    url="https://forms.gle/qCpAL7Wzbq3Btxt3A"
                    toggleDrawer={toggleDrawer(true)}
                />
            </List>
        </Drawer>
    )

    // ##################################################################
    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth
        var breakpointWidth = theme.breakpoints.values.md
        var isLargeScreen = windowWidth >= breakpointWidth
        var isSmallScreen = !isLargeScreen

        if (isSmallScreen && isPermanent) {
            setPermanent(false)
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true)
        }
    }
}

export default withRouter(Sidebar)
