import React from 'react'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'

import { Add as AddIcon, SchoolOutlined as CourseIcon, FolderOutlined as FolderIcon, HomeOutlined as HomeIcon, MoreVert as MoreIcon } from '@material-ui/icons'

import NavigationBar from 'components/NavigationBar'
import Paper from 'components/Paper'

import { GridIcon, ListIcon } from 'components/Icons'

import { getRelativeTime, tsToDate } from 'code/Helper'

import useStyles from './styles'

export default function Courses(props) {
    const classes = useStyles()

    const [view, setView] = React.useState('list')

    const courses = [
        {
            id: 'abc123',
            name: 'Anti Harassment Training',
            lu: { seconds: 0 },
            status: 'active',
        },
        {
            id: 'def456',
            name: 'Associate Member',
            type: 'folder',
        },
        {
            id: 'ghi789',
            name: 'New Officer',
            type: 'folder',
        },
        {
            id: 'dfk839',
            name: 'Recruitment',
            type: 'folder',
        },
        {
            id: 'asf132',
            name: 'Risk Prevention & Management',
            type: 'folder',
        },
    ]

    function renderNavBar(isGrid) {
        let icons = []

        if (view === 'list') {
            icons.push({
                name: 'List',
                type: 'icon',
                innerIcon: <ListIcon />,
                onClick: () => {
                    setView('grid')
                },
            })
        } else {
            icons.push({
                name: 'Grid',
                type: 'icon',
                innerIcon: <GridIcon />,
                onClick: () => {
                    setView('list')
                },
            })
        }

        icons.push({
            name: 'New',
            type: 'icon',
            innerIcon: <AddIcon />,
            onClick: () => {},
        })

        return (
            <NavigationBar
                titles={[
                    {
                        name: 'My House',
                        link: '/app/dashboard/',
                        icon: <HomeIcon />,
                    },
                    {
                        name: 'Courses',
                    },
                ]}
                rightButtons={icons}
                grid={isGrid}
            />
        )
    }

    if (view === 'list' || view === 'grid') {
        return (
            <Stack direction="column" spacing={2} sx={{ height: '100%' }} className={classes.table}>
                {renderNavBar(false)}
                <Paper style={{ display: 'flex', flexGrow: 1, height: `calc(100vh - 155px)` }}>
                    <DataGrid
                        sx={{ border: 'none' }}
                        rows={courses ?? []}
                        loading={courses === null}
                        rowHeight={44}
                        columnHeaderHeight={44}
                        columns={[
                            {
                                field: 'name',
                                headerName: 'Name',
                                minWidth: 250,
                                flex: 2,
                                renderCell: ({ value, row }) => (
                                    <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        {row.type === 'folder' ? (
                                            <FolderIcon style={{ marginInline: 12, verticalAlign: 'middle' }} />
                                        ) : (
                                            <CourseIcon style={{ marginInline: 12, verticalAlign: 'middle' }} />
                                        )}
                                        {value}
                                    </Typography>
                                ),
                                valueGetter: ({ value }) => (value ? value : 'Unnamed'),
                                renderHeader: () => (
                                    <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                        Name
                                    </Typography>
                                ),
                            },
                            {
                                field: 'status',
                                headerName: 'Status',
                                type: 'singleSelect',
                                valueOptions: ['active', 'inactive', 'draft'],
                                align: 'center',
                                headerAlign: 'center',
                                minWidth: 125,
                                flex: 1,
                                renderCell: ({ value }) => (
                                    <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
                                        {value}
                                    </Typography>
                                ),
                                renderHeader: () => <Typography fontWeight="500">Status</Typography>,
                            },
                            {
                                field: 'lu',
                                headerName: 'Last updated',
                                type: 'dateTime',
                                align: 'right',
                                headerAlign: 'right',
                                minWidth: 125,
                                flex: 1,
                                renderCell: ({ value }) => (
                                    <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        {value ? getRelativeTime(value) : ''}
                                    </Typography>
                                ),
                                valueGetter: ({ value }) => (value ? tsToDate(value) : null),
                                renderHeader: () => <Typography fontWeight="500">Last updated</Typography>,
                            },
                            {
                                field: 'actions',
                                type: 'actions',
                                getActions: ({ row }) => [<GridActionsCellItem icon={<MoreIcon />} onClick={() => {}} label="More" />],
                            },
                        ]}
                        onRowClick={params => {
                            props.history.push(`/app/applications/courses/${params.row.id}`)
                        }}
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        hideFooter
                        disableColumnMenu
                    />
                </Paper>
            </Stack>
        )
    }
}
