export const isValidEmail = email => {
    if (typeof email !== 'string' || email.length === 0) {
        return false
    }

    return /\S+@\S+\.\S+/.test(email)
}

export const State = { LOGIN: 'Login', ACTIVATE: 'Activate', AUTHENTICATE: 'Authenticate', UPDATE: 'Update', REGISTER: 'Register' }
export const MarketingState = {
    LOGO: 'Logo',
    CALENDAR: 'Calendar',
    POLLS: 'Polls',
    MESSAGES: 'Messages',
    POINTS: 'Points',
    OPERATIONS: 'Operations',
    RSVP: 'RSVP',
}
