import React from 'react'
import { Box } from '@material-ui/core'

import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized'

import { MessagesContext } from 'context/MessagesContext'

import Message from 'components/Messages/Message'

import { isAndroidDevice } from 'code/Helper'

import useStyles from '../styles'

const Chat = ({ conversationId, history, messages }) => {
    const classes = useStyles()

    const cache = new CellMeasurerCache({
        defaultHeight: 20,
        fixedWidth: true,
        keyMapper: i => messages[i].id,
    })

    const [prevMessageCount, setPrevMessageCount] = React.useState(messages.length)

    const { getConversation } = React.useContext(MessagesContext)

    const [, setCounter] = React.useState(0)

    const conversation = getConversation(conversationId)

    const includeBlocked = isAndroidDevice()

    const getBlocked = () => {
        let blockedItems = localStorage.getItem('cache_messagesBlocked')

        if (blockedItems) {
            return JSON.parse(blockedItems)
        }

        return { users: [], messages: [] }
    }

    const messageRenderer = ({ index, style, key, parent }) => {
        let message = messages[index]
        return (
            <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                <Box style={style} key={key}>
                    <Message
                        conversationId={conversationId}
                        {...message}
                        history={history}
                        conversation={conversation}
                        includeBlocked={includeBlocked}
                        blockedItems={getBlocked()}
                        onIncrement={() => setCounter(counter => counter + 1)}
                    />
                </Box>
            </CellMeasurer>
        )
    }

    React.useEffect(() => {
        if (messages.length !== prevMessageCount) {
            setPrevMessageCount(messages.length)
        }
    }, [prevMessageCount, messages])

    if (messages.length === 0) {
        return <></>
    }

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    className={classes.chatList}
                    width={width}
                    height={height}
                    rowCount={messages.length}
                    rowHeight={cache.rowHeight}
                    rowRenderer={messageRenderer}
                    deferredMeasurementCache={cache}
                    scrollToIndex={prevMessageCount - 1}
                />
            )}
        </AutoSizer>
    )
}

export default Chat
