import React from 'react'

import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar'

import EmojiPicker from 'components/EmojiPicker'

import { Dialog as DialogMobile, DialogContent as DialogContentMobile, DialogTitle as DialogTitleMobile } from 'components/Wrappers'

import { Capacitor } from '@capacitor/core'

import { useTheme } from '@material-ui/styles'

const SelectEmojiDialog = ({ id, onSelect, title, onClose }) => {
    const theme = useTheme()

    React.useEffect(() => {
        if (Capacitor.isPluginAvailable('PushNotifications') && !(theme.palette.type === 'dark')) {
            StatusBar.setStyle({
                style: StatusBarStyle.Light,
            })
        }

        return () => {
            if (Capacitor.isPluginAvailable('PushNotifications')) {
                StatusBar.setStyle({
                    style: StatusBarStyle.Dark,
                })
            }
        }
    }, [theme.palette.type])

    return (
        <DialogMobile maxWidth="md" aria-labelledby={`${id}-title`} open fullWidth onClose={onClose}>
            <DialogTitleMobile id={`${id}-title`} onClose={onClose}>
                {title}
            </DialogTitleMobile>
            <DialogContentMobile
                dividers
                style={{
                    height: '100%',
                    overflow: 'hidden',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: 'none',
                    position: 'relative',
                    padding: 0,
                }}
            >
                <EmojiPicker onSelect={onSelect} style={{ width: 'initial', height: '100%' }} />
            </DialogContentMobile>
        </DialogMobile>
    )
}

export default React.memo(SelectEmojiDialog)
