import React from 'react'
import {
    Timeline as PointsIcon,
    CalendarToday as CalendarIcon,
    ForumOutlined as MessagesIcon,
    WhatshotOutlined as RushIcon,
    PollOutlined as PollsIcon,
    FastfoodOutlined as MealsIcon,
    AssignmentOutlined as TasksIcon,
} from '@material-ui/icons'

import { DriveIcon } from 'components/Icons'

// Must go up in permissions as the index increases
export const perms = [
    {
        id: 'calendar',
        title: 'Calendar',
        icon: <CalendarIcon />,
        perms: [
            {
                title: 'User',
                value: '',
                desc: 'Test description goes here',
            },
            {
                title: 'Creator',
                value: 'calendarCreate',
                desc: 'Test description goes here of perm',
            },
            {
                title: 'Admin',
                value: 'calendarAdmin',
                desc: 'Test description goes here of perm',
            },
        ],
    },
    {
        id: 'drive',
        title: 'Drive',
        icon: <DriveIcon />,
        perms: [
            {
                title: 'User',
                value: '',
                desc: 'Test description goes here',
            },
            {
                title: 'Creator',
                value: 'driveCreate',
                desc: 'Test description goes here of perm',
            },
            {
                title: 'Admin',
                value: 'driveAdmin',
                desc: 'Test description goes here of perm',
            },
        ],
    },
    /*{
        id: 'newsletters',
        title: 'Newsletters',
        icon: <Icon className="far fa-newspaper" style={{ fontSize: 20, minWidth: 24 }} />,
        perms: [
            {
                title: 'User',
                value: '',
                desc: 'Test description goes here',
            },
            {
                title: 'Admin',
                value: 'newsletterAdmin',
                desc: 'Test description goes here of perm',
            },
        ],
    },*/
    {
        id: 'meals',
        title: 'Meals',
        icon: <MealsIcon />,
        perms: [
            {
                title: 'User',
                value: '',
                desc: 'Test description goes here',
            },
            {
                title: 'Admin',
                value: 'mealsAdmin',
                desc: 'Test description goes here of perm',
            },
        ],
    },
    {
        id: 'messages',
        title: 'Messages',
        icon: <MessagesIcon />,
        perms: [
            {
                title: 'User',
                value: '',
                desc: 'Test description goes here',
            },
            {
                title: 'Create',
                value: 'messagesCreate',
                desc: 'Test description goes here of perm',
            },
            {
                title: 'Admin',
                value: 'messagesAdmin',
                desc: 'Test description goes here of perm',
            },
        ],
    },
    {
        id: 'points',
        title: 'Points',
        icon: <PointsIcon />,
        perms: [
            {
                title: 'User',
                value: '',
                desc: 'Test description goes here',
            },
            {
                title: 'Create',
                value: 'pointsCreate',
                desc: 'Test description goes here of perm',
            },
            {
                title: 'Admin',
                value: 'pointsAdmin',
                desc: 'Test description goes here of perm',
            },
        ],
    },
    {
        id: 'polls',
        title: 'Polls',
        icon: <PollsIcon />,
        perms: [
            {
                title: 'User',
                value: '',
                desc: 'Test description goes here',
            },
            {
                title: 'Create',
                value: 'pollCreate',
                desc: 'Test description goes here of perm',
            },
            {
                title: 'Admin',
                value: 'pollAdmin',
                desc: 'Test description goes here of perm',
            },
        ],
    },
    {
        id: 'rush',
        title: 'Rush',
        icon: <RushIcon />,
        perms: [
            {
                title: 'None',
                value: '',
                desc: 'Test description goes here',
            },
            {
                title: 'User',
                value: 'rushView',
                desc: 'Test description goes here of perm',
            },
            {
                title: 'Admin',
                value: 'rushAdmin',
                desc: 'Test description goes here of perm',
            },
        ],
    },
    {
        id: 'tasks',
        title: 'Tasks',
        icon: <TasksIcon />,
        perms: [
            {
                title: 'User',
                value: '',
                desc: 'Test description goes here',
            },
            {
                title: 'Create',
                value: 'tasksCreate',
                desc: 'Test description goes here',
            },
            {
                title: 'Admin',
                value: 'tasksAdmin',
                desc: 'Test description goes here of perm',
            },
        ],
    },
]
