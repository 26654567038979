import React, { useEffect } from 'react'
import app from 'firebase/app'
import 'firebase/firestore'

import { AuthContext } from './AuthContext'

export const ChapterContext = React.createContext()

export const ChapterProvider = props => {
    //TODO: Get user and if user exists and has chapter id, get chapter and store it in chapter cache...
    const { user } = React.useContext(AuthContext)
    const children = props.children
    const db = app.firestore()

    let chapterStorageItem = localStorage.getItem('cache_chapter')
    let chapterDateStorageItem = localStorage.getItem('cache_chapterDate') ? new Date(localStorage.getItem('cache_chapterDate')) : null
    const [chapter, setChapter] = React.useState(chapterStorageItem ? JSON.parse(chapterStorageItem) : null)

    function isCacheOld(cacheTimestamp) {
        let timeDiff = Math.floor((new Date() - cacheTimestamp) / 1000 / 60)
        return timeDiff > 1 || isNaN(timeDiff)
    }

    function setChapterCache(chapterData) {
        localStorage.setItem('cache_chapter', JSON.stringify(chapterData))
        setChapter(chapterData)
    }

    function requestChapterUpdateFromCache() {
        let cache = localStorage.getItem('cache_chapter')
        if (cache) {
            setChapter(JSON.parse(chapterStorageItem))
        }
    }

    async function requestChapterUpdate() {
        if (user && user.getChapter()) {
            let doc = await db
                .collection('chapters')
                .doc(user.getChapter())
                .get()

            if (doc.exists) {
                var chapterData = doc.data()
                setChapterCache(chapterData)

                setChapter(chapterData)

                resetChapterDate()
            }
        }
    }

    async function requestChapterUpdateWithDelay() {
        return new Promise(async (resolve, reject) => {
            await requestChapterUpdate()
            setTimeout(() => {
                resolve()
            }, 500)
        })
    }

    function resetChapterDate() {
        chapterDateStorageItem = new Date()
        localStorage.setItem('cache_chapterDate', new Date().toString())
    }

    useEffect(() => {
        if (needsChapterUpdate()) {
            requestChapterUpdate()
        }
    })

    function needsChapterUpdate() {
        return !(user && user.fraternity) && (!chapterDateStorageItem || !chapter || isCacheOld(chapterDateStorageItem))
    }

    return (
        <ChapterContext.Provider
            value={{
                chapter,
                requestChapterUpdate,
                requestChapterUpdateFromCache,
                requestChapterUpdateWithDelay,
                needsChapterUpdate,
                setChapter,
                setChapterCache,
            }}
        >
            {children}
        </ChapterContext.Provider>
    )
}
