import { alpha, makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
    navPadding: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
        paddingLeft: 12,
        paddingRight: 12,
        height: 51,
        display: 'flex',
    },
    toggleRightButton: {
        marginRight: 6,
        marginLeft: 6,
    },
    navTitle: {
        paddingBottom: 4,
        paddingTop: 3,
        '-webkit-touch-callout': 'none',
    },
    buttonWhite: {
        color: 'white',
    },
    searchField: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    searchFieldLarge: {
        [theme.breakpoints.down('sm')]: {
            //TODO: This is where it should take up the full screen
            flexGrow: 1,
        },
    },
    navButton: {
        marginRight: 14,
        boxShadow: theme.customShadows.widget,
        color: theme.palette.text.primary,
        '&:hover': {
            boxShadow: theme.customShadows.widgetWide,
        },
    },
    searchIcon: {
        color: theme.palette.text.primary,
    },
    button: {
        marginLeft: 6,
        marginRight: 6,
    },
    searchSmallIcon: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    searchInputBase: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIconInputBase: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
    iconTitle: {
        display: 'flex',
    },
    breadcrumbsText: {
        lineHeight: '30px',
    },
    breadcrumbsLink: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}))
