import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    map: {
        '& .syrup-canvas': {
            borderRadius: 4,
        },
    },
    table: {
        '& .MuiDataGrid-root': {
            color: 'inherit',
        },
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-withBorderColor': {
            outline: 'none !important',
        },
        '& .MuiTablePagination-toolbar, & .MuiDataGrid-footerContainer': {
            minHeight: '44px !important',
            height: '44px !important',
        },
    },
}))
