import { Box, Container } from '@material-ui/core'
import React from 'react'

import cn from 'classnames'

import ChatInfo from 'components/Messages/ChatInfo'

import { MessagesContext } from 'context/MessagesContext'

import { clearNotifications } from 'code/Helper'

import useStyles from '../styles'

const ChatContainer = ({ conversationId, history, showInfo, version, numAttachments }) => {
    const classes = useStyles()

    const { renderChat, getConversation, viewingConversation, setViewingConversation } = React.useContext(MessagesContext)

    const conversation = getConversation(conversationId)

    React.useEffect(() => {
        if (viewingConversation !== conversationId) {
            setViewingConversation(conversationId)
        }

        if (conversation) {
            clearNotifications(conversation.name)
        }

        return () => {
            setViewingConversation('')
        }
    }, [conversationId])

    if (conversation && conversation.exists === false) {
        history.push('/app/messages')
    }

    if (conversation && conversation.messages) {
        if (version === 'mobile') {
            if (showInfo) {
                return (
                    <Container
                        className={cn(classes.mobileContainer, classes.mobileContainerInfo, {
                            [classes.mobileContainerAttachment]: numAttachments > 0 && showInfo !== 'true',
                        })}
                        maxWidth={false}
                    >
                        <ChatInfo conversationId={conversationId} history={history} version="mobile" />
                    </Container>
                )
            }
            return (
                <Container
                    className={cn(classes.mobileContainer, classes.mobileContainerChat, {
                        [classes.mobileContainerAttachment]: numAttachments > 0 && showInfo !== 'true',
                    })}
                    maxWidth={false}
                >
                    {renderChat(conversationId, history, conversation.messages)}
                </Container>
            )
        }

        return <Box style={{ height: '100%', width: '100%' }}>{renderChat(conversationId, history, conversation.messages)}</Box>
    }

    return <></>
}

export default React.memo(ChatContainer)
