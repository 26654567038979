import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    button: {
        boxShadow: theme.customShadows.widget,
        color: theme.palette.text.primary,
        '&:hover': {
            boxShadow: theme.customShadows.widgetWide,
        },
    },
    listItem: {
        borderBottom: theme.border,
        padding: `0 !important`,
        marginLeft: -8,
        marginRight: -8,
        width: '100vw',
        maxWidth: '100vw',
        flexBasis: 'unset',
    },
    buttonWhite: {
        color: 'white',
    },
    navPadding: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
    },
    navButton: {
        marginRight: 14,
        boxShadow: theme.customShadows.widget,
        color: theme.palette.text.primary,
        '&:hover': {
            boxShadow: theme.customShadows.widgetWide,
        },
    },
    table: {
        alignContent: 'flex-start',
    },
    chip: {
        margin: 4,
    },
    chipOutlined: {
        margin: 4,
        color: theme.palette.text.primary,
        borderWidth: 2,
        height: 34,
        borderRadius: 18,
    },
    guestIcon: {
        color: theme.palette.text.primary,
    },
    chips: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        margin: -4,
    },
    chipContainer: {
        '& .chipChild': {
            paddingTop: 8,
            '&:first-child': {
                paddingTop: 0,
            },
        },
    },
    eventWidget: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    eventWidgetBody: {
        '&:hover': {
            background: 'rgba(150, 150, 150, 0.16)',
            cursor: 'pointer',
        },
        WebkitUserSelect: 'none',
        userSelect: `none`,
    },
    widgetBottomPadding: {
        paddingBottom: 8,
    },
    borderTop: {
        borderTop: '1px solid rgba(224, 224, 224, 0.498039)',
        marginTop: 2,
    },
    emptyIcon: {
        width: 24,
        height: 24,
        margin: 3,
    },
    tableHeader: {
        padding: 18,
        paddingBottom: 0,
    },
    tableTopRow: {
        paddingLeft: `16px !important`,
        paddingRight: `16px !important`,
    },
    columnHeader: {
        margin: 3,
        width: `50%`,
        textAlign: `center`,
        fontWeight: 500,
    },
    tableRow: {
        borderTop: `1px solid rgba(224, 224, 224, 0.498039)`,
        paddingLeft: `16px !important`,
        paddingRight: `16px !important`,
    },
    colorRow: {
        borderRadius: 12,
        width: 24,
        height: 24,
        margin: `3px 11px 3px 11px`,
        border: `1.5px solid black`,
    },
    colorRowParent: {
        width: `50%`,
        display: 'flex',
        justifyContent: `center`,
    },
    rowText: {
        margin: 3,
        width: `50%`,
        textAlign: `center`,
    },
    rowTextField: {
        maxWidth: 256,
        margin: '0 auto',
    },
    rowTFWrapper: {
        width: '100%',
        marginLeft: 3,
        marginRight: 3,
    },
    progress: {
        marginBottom: 10,
        marginTop: 10,
        marginRight: 12,
        backgroundColor: theme.palette.gray.main,
    },
    progressTitle: {
        marginRight: 12,
        textAlign: 'right',
    },
    progressBar: {
        backgroundColor: theme.palette.primary.main,
    },
    calendarWidgetHeader: {
        margin: '8px 24px',
        display: 'flex',
    },
    calendarWidgetItem: {
        padding: '6px 24px',
    },
    hover: {
        '&:hover': {
            background: 'rgba(150, 150, 150, 0.16)',
            cursor: 'pointer',
        },
    },
    sectionNoHighlight: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `0px !important`,
    },
    qrCodeDialog: { 'backdrop-filter': 'blur(5px)', '-webkit-backdrop-filter': 'blur(5px)' },
    iconButton: {
        color: theme.palette.text.primary,
    },
    qrCode: {
        maxWidth: 128,
        maxHeight: 128,
    },
    qrCodeOuter: {
        display: `inline-flex`,
        flexDirection: 'column',
        padding: 6,
        backgroundColor: 'white',
        borderRadius: 6,
        border: '2px solid rgba(0, 0, 0, 0.23)',
    },
    qrCodeParent: {
        display: 'flex',
        justifyContent: `center`,
    },
    saveQrCodeButton: {
        marginTop: 6,
    },
    emailPreview: {
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        borderRadius: 4,
        width: '100%',
        maxWidth: 144,
        height: 256,
        margin: `0 auto`,
    },
    chipHolder: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}))
