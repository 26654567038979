import React from 'react'

import { Box, TextField, Typography } from '@material-ui/core'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Wrappers'

import { InsertDriveFileOutlined as FileIcon } from '@material-ui/icons'

import { Document, Page, pdfjs } from 'react-pdf'

import { compileVisibility, defaultVisibilityOptions, expandVisibility, visibilityOptions } from 'data/Visibility'

import ErrorTypography from 'components/Typography/ErrorTypography'

import { fileListToArray, isMobileDevice } from 'code/Helper'

import SelectAutocomplete from 'components/SelectAutocomplete'

import html2canvas from 'html2canvas'

// styles
import useStyles from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `${isMobileDevice() ? 'https:' : ''}//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const UploadFileDialog = ({ open, onClose, onUpload, isLoading, parentVis }) => {
    var classes = useStyles()

    const inputRef = React.useRef(null)

    const blankData = {
        file: null,
        extension: null,
        image: null,
        name: '',
        vis: compileVisibility(defaultVisibilityOptions).filter(a => !parentVis || parentVis.includes(a)),
        error: null,
    }

    const [data, setData] = React.useState(blankData)

    const imageRef = React.useRef(null)

    React.useEffect(() => {
        if (!open) {
            setData(blankData)
        }
    }, [open, blankData])

    const closeDialog = () => {
        if (!isLoading) {
            delFile()
            onClose()
        }
    }

    const uploadFile = async () => {
        let imgData = null
        if ((data.image || data.extension === 'pdf') && imageRef.current) {
            let canvas = await (data.image ? html2canvas(imageRef.current, { allowTaint: true, logging: false, useCORS: true }) : false)
            imgData = canvas && canvas.toDataURL('image/png')
        }

        let dataCompiled = { file: data.file, name: data.name, extension: data.extension, vis: data.vis }

        if (imgData) {
            console.log('got image data', imgData)
            dataCompiled.preview = imgData
        }

        onUpload(dataCompiled)
    }

    const renderFile = file => {
        if (file.extension !== 'pdf') {
            let reader = new FileReader()
            reader.onload = function(ev) {
                const d = ev.target.result
                setData(data => ({ ...data, image: d }))
            }
            reader.readAsDataURL(file)
        }
    }

    const onDrop = e => {
        const files = e.dataTransfer.files
        const arr = fileListToArray(files)
        if (!data.file && arr[0]) {
            addFile(arr[0])
        }
        e.preventDefault()
    }

    const onFilesAdded = e => {
        const files = e.target.files
        const arr = fileListToArray(files)
        if (!data.file && arr[0]) {
            addFile(arr[0])
        }
    }

    const delFile = () => {
        if (data.file != null && !isLoading) {
            setData(blankData)
            if (inputRef.current) {
                inputRef.current.value = null
            }
        }
    }

    const addFile = file => {
        var filesize = (file.size / 1024 / 1024).toFixed(4) // MB
        if (filesize <= 20) {
            renderFile(file)

            let name = file.name.split('.')
            let extension = name.pop()

            setData(data => ({ ...data, extension, name: name.join('.'), file, error: null }))
        } else {
            setData(data => ({ ...data, error: 'File is larger than the maximum alloted size' }))
        }
    }

    const updateField = (name, value) => setData(data => ({ ...data, [name]: value }))

    return (
        <Dialog open={open} onClose={closeDialog} fullWidth aria-labelledby="create-folder-dialog-title" maxWidth="xs">
            <DialogTitle id="create-folder-dialog-title" onClose={closeDialog}>
                Upload Files
            </DialogTitle>
            <DialogContent dividers>
                <Box>
                    <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={event => onFilesAdded(event)} />
                    <Box
                        className={classes.uploadFile}
                        onClick={() => {
                            if (!data.file) {
                                if (inputRef.current) {
                                    inputRef.current.click()
                                }
                            }
                        }}
                        style={{ cursor: data.file ? 'default' : 'pointer' }}
                        onDrop={e => onDrop(e)}
                        onDragOver={e => {
                            e.preventDefault()
                        }}
                    >
                        <Box className={classes.uploadFilePic} id="pdfRenderMenu">
                            {data.file ? (
                                <>
                                    {data.extension === 'pdf' ? (
                                        <Box className={classes.fileImageBox} style={{ height: 196 }}>
                                            <Document file={data.file} className={classes.fileImage}>
                                                <Page pageNumber={1} height={196} canvasRef={imageRef} />
                                            </Document>
                                        </Box>
                                    ) : data.image ? (
                                        <Box className={classes.fileImageBox}>
                                            <img src={data.image} className={classes.fileImage} ref={imageRef} alt=""></img>
                                        </Box>
                                    ) : (
                                        <FileIcon className={classes.uploadFilePicInner} />
                                    )}
                                </>
                            ) : (
                                <FileIcon className={classes.uploadFilePicInner} />
                            )}
                        </Box>
                        <Box className={classes.uploadFileDesc}>
                            {data.file ? (
                                <>
                                    <Button onClick={() => delFile()} color="primary" style={{ margin: '0 auto' }} disabled={isLoading}>
                                        <Typography align="center">Delete File</Typography>
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Typography style={{ userSelect: 'none', WebkitUserSelect: 'none' }} align="center">
                                        Drag and drop file or click to upload
                                    </Typography>
                                    <Typography style={{ userSelect: 'none', WebkitUserSelect: 'none' }} align="center">
                                        Max file size: 20 mb.
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Box>
                    <TextField
                        autoFocus
                        margin="dense"
                        variant="outlined"
                        label="Name"
                        value={data.name}
                        onChange={e => updateField('name', e.target.value)}
                        fullWidth
                        disabled={!data.file || isLoading}
                    />
                    <SelectAutocomplete
                        variant="outlined"
                        value={expandVisibility(data.vis)}
                        hideTitleOnUse={false}
                        onUpdate={(event, value) => {
                            updateField('vis', value ? compileVisibility(value) : [])
                        }}
                        freeSolo={false}
                        title="Visibility"
                        events={visibilityOptions(true).filter(a => !parentVis || parentVis.includes(a.id))}
                        disabled={!data.file || isLoading}
                    />
                    {data.error && <ErrorTypography text={data.error} isCentered />}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} disabled={isLoading} color="primary">
                    Cancel
                </Button>
                <Button onClick={uploadFile} disabled={!data.file || !data.name || isLoading || data.vis.length === 0} color="primary">
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadFileDialog
