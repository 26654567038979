import 'firebase/firestore'
import React from 'react'

import { Link, useLocation } from 'react-router-dom'

import appIcon from 'pages/login/assets/app_icon.png'

import axios from 'axios'

import { apiPrefix } from 'code/Helper'

import { useHistory } from 'react-router-dom'

import { Box, DialogContentText } from '@material-ui/core'

import { Button, Dialog, DialogActions, DialogContent } from 'components/Wrappers'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import useStyles from './PlanManagementStyles'

export const PlanManagement = () => {
    const classes = useStyles()

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const location = useLocation()
    const history = useHistory()

    const isUserAdmin = React.useCallback(() => {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN'
            }
        }

        return false
    }, [chapter, user])

    const [isAdmin, setIsAdmin] = React.useState(isUserAdmin())
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(() => {
        setIsAdmin(isUserAdmin())
    }, [isUserAdmin])

    const canViewLink = link => link.indexOf('/app/admin') === 0 || link.indexOf('/app/settings') === 0

    const upgradeChapter = async () => {
        setIsLoading(true)

        try {
            let val = await axios.get(`${apiPrefix}/chapter/${user.getChapter()}`)

            if (val && val.data) {
                let chapterQuote = val.data

                if (chapterQuote && chapterQuote.session && chapterQuote.session.url) {
                    window.open(chapterQuote.session.url, '_blank')
                }
            } else {
                history.push('/app/admin')
            }
        } catch (e) {
            console.log('got error', e)
            history.push('/app/admin')
        } finally {
            setIsLoading(false)
        }
    }

    const isChapterLocked = chapter && chapter.settings && chapter.settings.disabled
    const subscriptionHasLapsed = chapter && chapter.settings && chapter.settings.tier !== 'base' && chapter.settings.tier !== 'lite'

    return (
        <>
            {isChapterLocked && !canViewLink(location.pathname) ? (
                <Dialog open={true} onClose={null} disableClose={true} rootClass={classes.dialogBackground}>
                    <DialogContent style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={appIcon} alt="" style={{ width: 60, height: 60, marginBottom: 12 }} className={classes.appIcon} />
                        <DialogContentText style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}>
                            {subscriptionHasLapsed ? "Your chapter's subscription has lapsed" : "Your chapter's trial has ended"}
                        </DialogContentText>
                        <DialogContentText style={{ fontSize: 16, fontWeight: 500, textAlign: 'center' }}>
                            {isAdmin ? 'Upgrade to continue using Greek Connect' : 'Ask an officer to upgrade'}
                        </DialogContentText>
                        <Box style={{ maxWidth: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                            <img
                                src="https://getgreekconnect.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fhome_screenshot.63f2662b.png&w=3840&q=75"
                                style={{ maxWidth: '100%' }}
                                alt="Greek Connect App Screenshot"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions className={classes.dialogBottomFade}>
                        {isAdmin ? (
                            <>
                                <Button component={Link} to="/app/admin" disabled={isLoading}>
                                    Manage Chapter
                                </Button>
                                <Button color="primary" onClick={() => upgradeChapter()} disabled={isLoading}>
                                    Upgrade Now
                                </Button>
                            </>
                        ) : (
                            <Button component={Link} to="/app/settings" disabled={isLoading}>
                                Manage Account
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            ) : null}
        </>
    )
}
