import React from 'react'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import CloseIcon from '@material-ui/icons/Close'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import ZoomInIcon from '@material-ui/icons/ZoomIn'

import { FileDownloadIcon } from 'components/Icons'

import PrismaZoom from 'react-prismazoom'

import { isMobileDevice } from 'code/Helper'

import { Document, Page } from 'react-pdf'

// styles
import useStyles from '../FullScreenImage/styles'

const FileViewer = ({ open, onClose, src, isObj, extension, title, onSelectAction }) => {
    var classes = useStyles()

    const [numPages, setNumPages] = React.useState(null)

    const scaleOptions = [0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1.0, 1.1, 1.25, 1.5, 1.75, 2.0, 2.5, 3.0, 4.0, 5.0]
    const [scale, setScale] = React.useState(scaleOptions.indexOf(1.0))

    const appBarRef = React.useRef(null)
    const backdropRef = React.useRef(null)

    const [width, setWidth] = React.useState(null)

    const [PDFBlob, setPDFBlob] = React.useState(null)

    React.useEffect(() => {
        if (isObj) {
            if (extension === 'pdf') {
                const controller = new AbortController()
                fetch(src, { signal: controller.signal })
                    .then(res => res.blob())
                    .then(file => setPDFBlob(file))
                    .catch(e => {
                        if (e.name !== 'AbortError') {
                            /* handle error*/
                            console.log('got error', e)
                        }
                    })
                return () => controller.abort()
            }
        }
    }, [src, isObj, extension, setPDFBlob])

    React.useEffect(() => {
        sessionStorage.setItem('previewItem', true)

        const onResize = () => {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', onResize)
        onResize()
        return () => {
            sessionStorage.removeItem('previewItem')
            window.removeEventListener('resize', onResize)
        }
    }, [])

    React.useEffect(() => {
        if (!isMobileDevice()) {
            const onScroll = () => {
                if (appBarRef.current && backdropRef.current) {
                    if (backdropRef.current.scrollTop > 0) {
                        appBarRef.current.style.backgroundColor = 'rgba(0,0,0,0.75)'
                    } else {
                        appBarRef.current.style.backgroundColor = 'transparent'
                    }
                }
            }

            if (backdropRef.current) {
                backdropRef.current.addEventListener('scroll', onScroll)
                onScroll()
                return () => {
                    if (backdropRef.current) backdropRef.current.removeEventListener('scroll', onScroll)
                }
            }
        }
    }, [backdropRef.current, appBarRef.current, open, src, isObj, extension, PDFBlob])

    const RenderDocument = () => {
        let pageWidth = width ?? 800

        if (pageWidth > 800) {
            pageWidth = 800
        }

        return (
            <Document
                file={PDFBlob}
                onLoadSuccess={pdf => {
                    setNumPages(pdf.numPages)
                }}
                className={classes.pdfDocument}
                onLoadError={console.error}
                loading={<CircularProgress />}
                noData={<CircularProgress />}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page
                        loading={<CircularProgress />}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className={classes.pdfPage}
                        scale={scaleOptions[scale]}
                        width={pageWidth}
                    />
                ))}
            </Document>
        )
    }

    const renderItem = () => {
        if (isObj) {
            if (extension === 'pdf') {
                return (
                    <Box sx={{ maxWidth: '100vw', maxHeight: '100vh', objectFit: 'contain' }}>
                        {!isMobileDevice() ? (
                            <RenderDocument />
                        ) : (
                            <PrismaZoom maxZoom={2} animDuration={0.25} style={{ display: 'flex', justifyContent: 'center' }}>
                                <RenderDocument />
                            </PrismaZoom>
                        )}
                    </Box>
                )
            }

            return <></>
        }

        return (
            <PrismaZoom maxZoom={2} animDuration={0.25} style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={src} className={classes.img} alt="" />
            </PrismaZoom>
        )
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={theme => ({ zIndex: theme.zIndex.drawer + 10, width: '100%', display: 'flex', flexDirection: 'column', overflow: 'scroll' })}
            ref={backdropRef}
            slotProps={{ backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, 0.86)', backdropFilter: 'blur(4px)' } } }}
        >
            <Box sx={{ minWidth: `100%`, minHeight: `100%`, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                {open && renderItem()}
                {!isMobileDevice() && (
                    <Box
                        sx={theme => ({
                            bottom: `calc(${theme.spacing(4)} + env(safe-area-inset-bottom))`,
                            left: 0,
                            right: 0,
                            position: `fixed`,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        })}
                    >
                        <Box sx={theme => ({ zIndex: theme.zIndex.drawer + 11, backgroundColor: 'rgba(0,0,0, 0.5)', borderRadius: theme.spacing(8) })}>
                            <IconButton size="large" sx={{ mr: 1 }} onClick={() => setScale(scale => scale - 1)} disabled={scale <= 0}>
                                <ZoomOutIcon style={{ color: 'white', opacity: scale <= 0 ? 0.5 : 1 }} />
                            </IconButton>
                            <Typography sx={{ color: 'white !important', fontWeight: 500, display: 'inline', verticalAlign: 'middle' }}>
                                {Math.round(scaleOptions[scale] * 100)}%
                            </Typography>
                            <IconButton size="large" sx={{ ml: 1 }} onClick={() => setScale(scale => scale + 1)} disabled={scale >= scaleOptions.length - 1}>
                                <ZoomInIcon style={{ color: 'white', opacity: scale >= scaleOptions.length - 1 ? 0.5 : 1 }} />
                            </IconButton>
                        </Box>
                    </Box>
                )}
                <Box sx={{ left: 0, top: 0, right: 0, position: `fixed` }}>
                    <AppBar
                        position="static"
                        elevation={0}
                        ref={appBarRef}
                        sx={theme => ({
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            backgroundColor: !isMobileDevice() ? 'transparent' : 'rgba(0,0,0,0.75)',
                            paddingLeft: `calc(${theme.spacing(2)} + env(safe-area-inset-left))`,
                            paddingRight: `calc(${theme.spacing(2)} + env(safe-area-inset-left))`,
                            minHeight: 56,
                            paddingTop: `env(safe-area-inset-top)`,
                            height: 'calc(48px + env(safe-area-inset-top))',
                            width: '100vw',
                            transition: 'background-color 0.125s ease',
                        })}
                    >
                        <Toolbar sx={{ paddingLeft: '0 !important', paddingRight: '0 !important', minHeight: 'unset !important' }}>
                            <IconButton size="large" edge="start" sx={{ mr: 2 }} onClick={onClose}>
                                <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={theme => ({
                                    flexGrow: 1,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    zIndex: theme.zIndex.drawer + 11,
                                })}
                            >
                                {title}
                            </Typography>
                            <IconButton size="large" edge="start" sx={{ ml: 2 }} onClick={() => onSelectAction('download')}>
                                <FileDownloadIcon style={{ color: 'white' }} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
        </Modal>
    )
}

export default React.memo(FileViewer)
