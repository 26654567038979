import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  menuContainer: {
    margin: "0 auto"
  },
  menu: {
    maxHeight: 512,
    objectFit: "scale-down",
    maxWidth: "100%",
    margin: "0 auto",
    boxShadow: theme.customShadows.widget,
    borderRadius: 4
  },
}));
