import React from 'react'

import { Box, Grid, IconButton, SwipeableDrawer, Typography } from '@material-ui/core'
import { Close as CloseIcon, InfoOutlined as InfoIcon } from '@material-ui/icons'

import cn from 'classnames'

import useStyles from 'pages/messages/styles'

import { useTheme } from '@material-ui/styles'

const ReactionDrawer = ({ reaction, open, onClose, history, ...props }) => {
    const classes = useStyles()
    const theme = useTheme()

    const getMembers = () => {
        if (!reaction || !reaction.users) {
            return <></>
        }

        const memObjs = reaction.users

        const members = []

        for (let i = 0; i < memObjs.length; i++) {
            let mem = memObjs[i]

            let fullSize = i === memObjs.length - 1 && i % 2 === 0
            let lastRow = i === memObjs.length - 1 || (i === memObjs.length - 2 && memObjs.length % 2 === 0 && !fullSize)
            let firstRow = i === 0 || (i === 1 && !fullSize)

            members.push(
                <Grid
                    item
                    sm={fullSize ? 12 : 6}
                    xs={12}
                    className={cn(fullSize ? classes.membersGridItemLast : classes.membersGridItem, {
                        [classes.membersGridItemBottom]: !lastRow,
                        [classes.membersGridItemTop]: !firstRow,
                    })}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', paddingLeft: 12 }}>
                        <Box style={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ lineHeight: '30px' }}>
                                {mem.first} {mem.last}
                            </Typography>
                        </Box>
                        <IconButton aria-label="info" size="small" onClick={() => history.push(`/app/profile/${mem.id}`)}>
                            <InfoIcon />
                        </IconButton>
                    </Box>
                </Grid>,
            )
        }

        return members
    }

    return (
        <SwipeableDrawer anchor="bottom" open={open} onClose={onClose} disableSwipeToOpen onOpen={() => {}}>
            <Box
                style={{
                    height: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    paddingTop: 12,
                    position: 'relative',
                    maxHeight: 'calc(100vh - env(safe-area-inset-bottom) - 16px)',
                    overflowY: 'hidden',
                }}
            >
                <Box style={{ position: 'absolute', top: 8, right: 8 }}>
                    <IconButton onClick={() => onClose()} size="medium" className={classes.iconClose}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
                </Box>
                <Box style={{ flexDirection: 'row', paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 12 }}>
                    <Typography align="center" style={{ fontWeight: 'bold' }}>
                        {reaction && reaction.users && reaction.users.length ? `${reaction.users.length} ` : ''}
                        {reaction ? reaction.id : ''}
                    </Typography>
                </Box>
                {reaction && reaction.users && (
                    <Box style={{ padding: 4, overflowY: 'scroll', borderTop: theme.border, paddingBottom: 'calc(env(safe-area-inset-bottom) + 4px)' }}>
                        <Grid container spacing={1}>
                            {getMembers()}
                        </Grid>
                    </Box>
                )}
            </Box>
        </SwipeableDrawer>
    )
}

export default React.memo(ReactionDrawer)
