import React from 'react'

import { Spring, animated } from '@react-spring/web'

const Sway = ({ rotation, duration, children, ...props }) => {
    let animations = [
        { transform: `rotate(${rotation}deg) translate(-4px, -6px)` } /* Bottom Left */,
        { transform: `rotate(${rotation}deg) translate(-6px, 1px)` } /* Center Left */,
        { transform: `rotate(${rotation}deg) translate(-2px, 5px)` } /* Top Left */,
        { transform: `rotate(${rotation}deg) translate(-1px, 6px)` } /* Top Center */,
        { transform: `rotate(${rotation}deg) translate(4px, 4px)` } /* Top Right */,
        { transform: `rotate(${rotation}deg) translate(3px, 1px)` } /* Center Right */,
        { transform: `rotate(${rotation}deg) translate(4px, -4px)` } /* Bottom Right */,
        { transform: `rotate(${rotation}deg) translate(-1px, -7px)` } /* Bottom Center */,
        { transform: `rotate(${rotation}deg) translate(-4px, -6px)` } /* Bottom Left */,
    ]

    const [animationOffset] = React.useState(Math.floor(Math.random() * animations.length))

    return (
        <Spring
            loop
            from={animations[animationOffset]}
            to={[...animations.slice(animationOffset + 1), ...animations.slice(0, animationOffset)]}
            config={{
                duration: duration,
            }}
        >
            {styles => <animated.div style={styles}>{children}</animated.div>}
        </Spring>
    )
}

export default React.memo(Sway)
