import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    root: {
        display: 'flex',
        maxWidth: '100vw',
        overflowX: 'hidden',
    },
    mobileRootBox: { width: '100vw', height: '100vh', position: 'absolute', backgroundColor: theme.palette.background.default },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        width: `calc(100vw - 240px)`,
        minHeight: '100vh',
        paddingLeft: `max(${theme.spacing(2)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(2)}px, env(safe-area-inset-right))`,
        paddingBottom: `max(${theme.spacing(2)}px, env(safe-area-inset-bottom))`,
        display: 'flex',
        flexDirection: 'column',
    },
    contentMobile: {
        flexGrow: 1,
        padding: theme.spacing(2),
        minHeight: '100vh',
        width: '100vw',
        paddingLeft: `max(${theme.spacing(2)}px, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(2)}px, env(safe-area-inset-right))`,
        paddingBottom: `max(${theme.spacing(2)}px, env(safe-area-inset-bottom))`,
        position: 'absolute',
        top: 0,
        left: 0,
        transition: `all ${theme.transitionDuration}ms ease-out`,
        display: 'flex',
        flexDirection: 'column',
    },
    contentNoBottom: {
        flexGrow: 1,
        padding: theme.spacing(2),
        width: `calc(100vw - 240px)`,
        minHeight: '100vh',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    contentShift: {
        width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    fakeToolbar: {
        ...theme.mixins.toolbar,
    },
    fakeToolbarNew: {
        minHeight: 'calc(56px + env(safe-area-inset-top)) !important',
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
    },
    fakeFooterNew: {
        minHeight: 'calc(56px + env(safe-area-inset-bottom)) !important',
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
    },
    headerIcon: {
        fontSize: 28,
        color: 'rgba(255, 255, 255, 0.35)',
    },
    link: {
        marginRight: theme.spacing(2),
    },
    defaultRadio: {
        color: '#536DFE',
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: '#536DFE',
        },
    },
    successRadio: {
        color: '#23a075',
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: '#23a075',
        },
    },
    secondaryRadio: {
        color: '#FF5C93',
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: '#FF5C93',
        },
    },
    warningRadio: {
        color: '#FFC260',
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: '#FFC260',
        },
    },
    infoRadio: {
        color: '#9013FE',
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: '#9013FE',
        },
    },
    button: {
        boxShadow: theme.customShadows.widget,
        textTransform: 'none',
        '&:active': {
            boxShadow: theme.customShadows.widgetWide,
        },
    },
    ecommerceIcon: {
        color: '#6E6E6E',
    },
    calendarIcon: {
        color: theme.palette.primary.main,
        marginRight: 14,
    },
    margin: {
        marginBottom: 24,
    },
    changeThemeFab: {
        position: 'fixed',
        top: theme.spacing(15),
        right: 0,
        zIndex: 1,
        borderRadius: 0,
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    },
    navPadding: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
    },
    date: {
        marginRight: 38,
        color: theme.palette.type === 'dark' ? '#D6D6D6' : '#4A494A',
    },
}))
