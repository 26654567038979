import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { App } from '@capacitor/app'

import { Capacitor } from '@capacitor/core'

import { PushNotifications } from '@capacitor/push-notifications'

export default function AppUrlListener(props) {
    let history = useHistory()

    useEffect(() => {
        App.addListener('appUrlOpen', data => {
            // Example url: https://greekconnect.app/app/dashboard
            // slug = /app/dashboard
            const slug = data.url.split('.app').pop()
            if (slug) {
                history.push(slug)
            }
            // If no match, do nothing - let regular routing
            // logic take over
        })

        if (Capacitor.isPluginAvailable('PushNotifications')) {
            PushNotifications.addListener('pushNotificationActionPerformed', actionPerformed => {
                let containsURL = actionPerformed && actionPerformed.notification && actionPerformed.notification.data && actionPerformed.notification.data.url

                if (containsURL) {
                    history.push(actionPerformed.notification.data.url)
                }
            })
        }
    }, [history])

    return null
}
