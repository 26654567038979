import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import throttle from 'lodash/throttle'
import React from 'react'

import { getCoordinate } from 'code/Helper'

import { LocationContext } from 'context/LocationContext'

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}))

export default function Search({ onChange, defaultValue, location, ...props }) {
    const classes = useStyles()
    const [value, setValue] = React.useState(null)
    const [inputValue, setInputValue] = React.useState('')
    const [options, setOptions] = React.useState([])

    const { mapkit } = React.useContext(LocationContext)

    const search = React.useRef(null)

    React.useEffect(() => {
        if (mapkit && !search.current) {
            let options = { language: 'en' }

            if (location) {
                options.coordinate = getCoordinate(mapkit, location)
                options.region = new mapkit.CoordinateRegion(options.coordinate, new mapkit.CoordinateSpan(0.016, 0.016))
            }

            search.current = new mapkit.Search(options)
        }
    }, [mapkit, location])

    const fetch = React.useMemo(
        () =>
            throttle(async (request, callback) => {
                if (search.current) {
                    search.current.search(request, callback)
                }
            }, 200),
        [search],
    )

    React.useEffect(() => {
        let active = true

        if (inputValue === '') {
            setOptions(value ? [value] : [])
            return undefined
        }

        fetch(inputValue, (error, result) => {
            if (active && result) {
                let newOptions = []

                let value = result.places

                if (Array.isArray(value) && value.length > 0) {
                    newOptions = [...value]
                }

                setOptions(newOptions)
            }
        })

        return () => {
            active = false
        }
    }, [value, inputValue, fetch])

    return (
        <Autocomplete
            getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
            filterOptions={(options, params) => {
                const filtered = options.map(option => ({ ...option, title: option.name }))

                const { inputValue } = params
                // Suggest the creation of a new value
                if (inputValue !== '') {
                    filtered.push({
                        custom: true,
                        name: inputValue,
                        title: `"${inputValue}"`,
                    })
                }

                return filtered
            }}
            options={options}
            autoComplete
            freeSolo
            includeInputInList
            filterSelectedOptions
            clearOnBlur
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options)
                setValue(newValue)
                if (newValue && newValue.name) {
                    onChange(newValue.name, newValue.coordinate)
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            renderInput={params => <TextField {...props} {...params} defaultValue={defaultValue} />}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item style={{ display: 'flex' }}>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                {option.title}
                                <Typography variant="body2" color="textSecondary">
                                    {option.fullThoroughfare}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                )
            }}
        />
    )
}
