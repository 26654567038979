import { useTheme } from '@material-ui/core/styles'
import React from 'react'

import app from 'firebase/app'

import format from 'date-fns/format'

import {
    ChevronLeft as LeftIcon,
    ChevronRight as RightIcon,
    DeleteForeverOutlined as DeleteIcon,
    FastfoodOutlined as MealsIcon,
    HomeOutlined as HomeIcon,
} from '@material-ui/icons'

import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'

import moment from 'moment'

import { cutoffs } from '../settings/data'

import { convertListToObject, convertObjectToList } from 'code/Helper'

import { Box, Grid } from '@material-ui/core'

// components
import NavigationBar from 'components/NavigationBar'
import ParentTable from 'components/Tables/ParentTable'
import Table from 'components/Tables/Table'
import Tabs from 'components/Tabs'
import Tip from 'components/Tip'
import Widget from 'components/Widget'

import { Typography } from 'components/Wrappers'

import { AuthContext } from 'context/AuthContext'

// styles
import useStyles from '../styles'

export default function Orders(props) {
    var classes = useStyles()
    const theme = useTheme()

    const db = app.firestore()

    const { user } = React.useContext(AuthContext)

    const [hasTried, setHasTried] = React.useState(false)

    const [dow, setDOW] = React.useState(0)
    const [mod, setMOD] = React.useState(0)

    const [recurringPlates, setRecurringPlates] = React.useState([])

    const [menus, setMenus] = React.useState([])
    const [meals, setMeals] = React.useState([])
    const [plans, setPlans] = React.useState([])
    const [activeMenuId, setActiveMenuId] = React.useState(null)
    const [activeMenu, setActiveMenu] = React.useState(null)
    const [daysOfWeek, setDaysOfWeek] = React.useState([])
    const [mealsOfDay, setMealsOfDay] = React.useState([[], [], [], [], [], [], []])
    const [hasAnyMeals, setHasAnyMeals] = React.useState(false)
    const [mealIds, setMealIds] = React.useState({})

    const [activeDate, innerSetActiveDate] = React.useState(getSunday())

    if (!hasTried) {
        setHasTried(true)
        grabSettings()
    }

    function getSunday() {
        return moment()
            .day('Sunday')
            .toDate()
    }

    function setActiveDate(date) {
        let dateMoment = moment(date)
        let foundMenu = null

        for (let i = 0; i < menus.length; i++) {
            let menu = menus[i]

            let menuMoment = moment()
                .day('Sunday')
                .year(menu.year)
                .week(menu.week)

            let isSameWeek = menuMoment.isSame(dateMoment, 'week')

            if (isSameWeek) {
                foundMenu = menu
            }
        }

        if (foundMenu !== null) {
            setActiveMenuId(foundMenu.id)
        } else {
            setActiveMenuId(null)
        }
        innerSetActiveDate(date)
    }

    React.useEffect(() => {
        if (activeMenuId !== null) {
            const unsubscribe = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('menus')
                .doc(activeMenuId)
                .onSnapshot(
                    function(doc) {
                        if (doc.exists) {
                            setActiveMenu(doc.data())
                        }
                    },
                    function(error) {
                        console.log('error', error)
                    },
                )
            return () => {
                unsubscribe()
            }
        }
    }, [activeMenuId])

    function getMealName(meal) {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let meals = ['Breakfast', 'Lunch', 'Dinner']

        return days[meal.dow] + ' ' + meals[meal.mod]
    }

    function getDailyChart() {
        let data = {
            name: 'Late Plates',
            data: [
                {
                    color: 'red',
                    value: 10,
                    name: 'Incomplete',
                },
                {
                    color: 'yellow',
                    value: 30,
                    name: 'Sorta complete',
                },
                {
                    color: 'green',
                    value: 20,
                    name: 'Complete',
                },
            ],
        }

        return renderChart(data)
    }

    function renderChart(chart) {
        let overallDataLength = chart.data.reduce((a, b) => {
            let val = 0
            val += typeof a === 'object' ? a.value : a
            val += typeof b === 'object' ? b.value : b
            return val
        })

        return (
            <Grid item sm={6} xs={12}>
                <Widget disableWidgetMenu inheritHeight noPadding>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" weight="bold" style={{ textAlign: 'center' }}>
                                {chart.name}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 6,
                            }}
                        >
                            <Typography
                                style={{
                                    position: 'absolute',
                                }}
                                weight="bold"
                            >
                                {overallDataLength}
                            </Typography>
                            <ResponsiveContainer width="100%" height={88}>
                                <PieChart>
                                    <Pie data={chart.data} innerRadius={30} outerRadius={40} dataKey="value">
                                        {chart.data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={theme.palette[entry.color].main} stroke={''} />
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" style={{ textAlign: 'center' }}>
                                {chart.data.map((entry, index) => (
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            margin: '0 4px',
                                        }}
                                        key={`key-${index}`}
                                    >
                                        {' '}
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                color: theme.palette[entry.color].main,
                                                marginRight: 2,
                                            }}
                                        >
                                            •
                                        </span>
                                        {`${entry.name} (${entry.value})`}
                                    </span>
                                ))}
                            </Typography>
                        </Grid>
                    </Grid>
                </Widget>
            </Grid>
        )
    }

    function updateOrder(data) {
        if (activeMenuId !== null) {
            // Update the request, marking it as fulfilled
            const id = data.id

            let cleanedData = { ...data }

            delete cleanedData.id
            delete cleanedData.pickupStr
            delete cleanedData.statusStr

            const menuRef = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('menus')
                .doc(activeMenuId)

            menuRef.update({ [`requests.${id}`]: cleanedData }).catch(error => {
                console.log('An error occurred', error.code, error.message)
            })
        }
    }

    function deleteOrder(data) {
        if (activeMenuId !== null) {
            const id = data.id
            const recurring = data.recurring

            const menuRef = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('menus')
                .doc(activeMenuId)

            const menuInfoRef = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('menus')
                .doc('INFO')

            menuRef.update({ [`requests.${id}`]: app.firestore.FieldValue.delete() }).catch(error => {
                console.log('An error occurred', error.code, error.message)
            })

            if (recurring) {
                // Delete it from the recurring as well
                menuInfoRef.update({ [`recurring.${id}`]: app.firestore.FieldValue.delete() }).catch(error => {
                    console.log('An error occurred', error.code, error.message)
                })

                let recPlates = convertListToObject(recurringPlates)
                delete recPlates[id]
                setRecurringPlates(convertObjectToList(recPlates))
            }
        }
    }

    function grabSettings() {
        db.collection('chapters')
            .doc(user.getChapter())
            .collection('menus')
            .doc('INFO')
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()

                    let curPlans = []
                    let ps = data.plans
                    let psKeys = Object.keys(ps)

                    for (let i = 0; i < psKeys.length; i++) {
                        let key = psKeys[i]
                        let plan = ps[key]
                        let planData = {
                            ...plan,
                            id: key,
                        }

                        curPlans.push(planData)
                    }

                    curPlans.sort(function(a, b) {
                        return b.members.length - a.members.length
                    })

                    let curMeals = []
                    let ms = data.meals
                    let msKeys = Object.keys(ms)

                    for (let i = 0; i < msKeys.length; i++) {
                        let mealData = {
                            ...ms[msKeys[i]],
                            id: msKeys[i],
                            name: getMealName(ms[msKeys[i]]),
                        }

                        curMeals.push(mealData)
                    }

                    let mealIds = {}

                    let dows = [...daysOfWeek]

                    let mods = [...mealsOfDay]

                    let earliestDay = 7
                    let earliestMeal = 3

                    for (let i = 0; i < curMeals.length; i++) {
                        let meal = curMeals[i]
                        if (!dows.includes(meal.dow)) {
                            dows.push(meal.dow)
                        }

                        if (!mods[meal.dow].includes(meal.mod)) {
                            mods[meal.dow].push(meal.mod)
                        }

                        mealIds[`${meal.dow}.${meal.mod}`] = meal.id

                        if (meal.dow < earliestDay) {
                            earliestDay = meal.dow
                            earliestMeal = meal.mod
                        } else if (meal.dow === earliestDay && meal.mod < earliestMeal) {
                            earliestMeal = meal.mod
                        }
                    }

                    curMeals.sort(function(a, b) {
                        if (a.dow !== b.dow) {
                            return a.dow - b.dow
                        }

                        if (a.mod !== b.mod) {
                            return a.mod - b.mod
                        }

                        return a.cutoffs[0] - b.cutoff[0]
                    })

                    let menus = []
                    ms = data.menus

                    msKeys = Object.keys(ms)

                    let activeId = null

                    for (let i = 0; i < msKeys.length; i++) {
                        let key = msKeys[i]
                        let menuID = ms[key]

                        let date = key.split('.')

                        let year = parseInt(date[0])
                        let week = parseInt(date[1])

                        let momentDate = moment()
                            .day('Sunday')
                            .year(year)
                            .week(week)
                            .toDate()

                        let isActive = moment().isSame(momentDate, 'week')

                        let data = {
                            week: week,
                            year: year,
                            title: format(momentDate, 'MMMM do'),
                            subtitle: String(year),
                            id: menuID,
                            active: isActive,
                        }

                        if (isActive) {
                            activeId = menuID
                        }

                        menus.push(data)
                    }

                    if (activeId) {
                        setActiveMenuId(activeId)
                    }

                    menus.sort(function(a, b) {
                        if (b.year !== a.year) {
                            return b.year - a.year
                        }

                        return b.week - a.week
                    })

                    if (data.recurring !== undefined) {
                        let recurring = convertObjectToList(data.recurring)

                        for (let i = 0; i < recurring.length; i++) {
                            recurring[i].recurring = true
                        }

                        setRecurringPlates(recurring)
                    }

                    setHasAnyMeals(curMeals.length > 0)
                    setDOW(earliestDay)
                    setMOD(earliestMeal)
                    setMealsOfDay(mods)
                    setDaysOfWeek(dows)
                    setMenus(menus)
                    setMeals(curMeals)
                    setMealIds(mealIds)
                    setPlans(curPlans)
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    function getMemberPlan(memId) {
        for (let i = 0; i < plans.length; i++) {
            if (plans[i].members.includes(memId)) {
                return plans[i]
            }
        }

        return null
    }

    function getPickupOptions() {
        if (activeMenu) {
            if (daysOfWeek.includes(dow) && mealsOfDay[dow].includes(mod)) {
                let mealId = mealIds[`${dow}.${mod}`]

                if (mealId) {
                    for (let i = 0; i < meals.length; i++) {
                        let meal = meals[i]
                        if (meal.id === mealId) {
                            let pickups = meal.pickups
                            let pickupOptions = {}

                            for (let j = 0; j < pickups.length; j++) {
                                let pickup = pickups[j]
                                pickupOptions[pickup.id] = { title: cutoffs[pickup.time] }
                            }

                            return pickupOptions
                        }
                    }
                }

                return []
            }
        }

        return []
    }

    const pickupOptions = getPickupOptions()

    const statusOptions = {
        '-1': {
            title: 'Cancelled',
            default: false,
        },
        0: {
            title: 'Received',
            default: true,
        },
        1: {
            title: 'Fulfilled',
            default: false,
        },
    }

    function getOrders() {
        if (activeMenu) {
            if (daysOfWeek.includes(dow) && mealsOfDay[dow].includes(mod)) {
                let orders = []
                let orderIds = []
                let mealId = mealIds[`${dow}.${mod}`]

                if (mealId) {
                    let requestIds = Object.keys(activeMenu.requests)
                    for (let i = 0; i < requestIds.length; i++) {
                        let requestId = requestIds[i]

                        let request = activeMenu.requests[requestId]

                        if (request.meal === mealId) {
                            let curPlan = getMemberPlan(request.member)

                            if (curPlan !== null && curPlan.meals.includes(request.meal)) {
                                let obj = { ...request, id: requestId }

                                if (obj.pickup in pickupOptions) {
                                    obj.pickupStr = pickupOptions[obj.pickup].title
                                }

                                obj.statusStr = statusOptions[obj.status ? `${obj.status}` : '0'].title

                                orders.push(obj)
                                orderIds.push(requestId)
                            }
                        }
                    }

                    for (let i = 0; i < recurringPlates.length; i++) {
                        let recurringPlate = recurringPlates[i]

                        if (!orderIds.includes(recurringPlate.id)) {
                            if (recurringPlate.meal === mealId) {
                                let curPlan = getMemberPlan(recurringPlate.member)

                                // TODO: check to make sure it hasn't already been added to this week

                                if (curPlan !== null && curPlan.meals.includes(recurringPlate.meal)) {
                                    let obj = { ...recurringPlate }

                                    if (obj.pickup in pickupOptions) {
                                        obj.pickupStr = pickupOptions[obj.pickup].title
                                    }

                                    obj.statusStr = statusOptions[obj.status ? `${obj.status}` : '0'].title

                                    orders.push(obj)
                                    orderIds.push(obj.id)
                                }
                            }
                        }
                    }
                }

                orders = orders.filter(a => !a.status || a.status !== -1)

                return orders
            }
        }

        return []
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Meals',
                            link: '/app/applications/meals/',
                            icon: <MealsIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: 'Orders',
                        },
                    ]}
                    grid
                />
                <Grid item xs={12}>
                    <Tip
                        color="default"
                        id="week_description"
                        actionIcon={<RightIcon />}
                        title="Week Of"
                        onActionClick={() => {
                            setActiveDate(
                                moment(activeDate)
                                    .add(7, 'days')
                                    .toDate(),
                            )
                        }}
                        leftAction={{
                            icon: <LeftIcon />,
                            onClick: () =>
                                setActiveDate(
                                    moment(activeDate)
                                        .subtract(7, 'days')
                                        .toDate(),
                                ),
                            disabled: moment(activeDate).isSame(moment(), 'week'),
                        }}
                        description={format(activeDate, 'MMMM do')}
                        center
                    />
                </Grid>
                {hasAnyMeals ? (
                    <>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    maxWidth: 480,
                                    margin: `0 auto`,
                                }}
                            >
                                <Widget disableWidgetMenu inheritHeight bodyClass={classes.tabSection}>
                                    <Tabs
                                        tabs={[
                                            { id: 0, name: 'Sun', width: 50, disabled: !daysOfWeek.includes(0) },
                                            { id: 1, name: 'Mon', width: 50, disabled: !daysOfWeek.includes(1) },
                                            { id: 2, name: 'Tue', width: 50, disabled: !daysOfWeek.includes(2) },
                                            { id: 3, name: 'Wed', width: 50, disabled: !daysOfWeek.includes(3) },
                                            { id: 4, name: 'Thu', width: 50, disabled: !daysOfWeek.includes(4) },
                                            { id: 5, name: 'Fri', width: 50, disabled: !daysOfWeek.includes(5) },
                                            { id: 6, name: 'Sat', width: 50, disabled: !daysOfWeek.includes(6) },
                                        ]}
                                        selected={dow}
                                        onChange={newDOW => {
                                            if (!mealsOfDay[newDOW].includes(mod)) {
                                                setMOD(mealsOfDay[newDOW][0])
                                            }
                                            setDOW(newDOW)
                                        }}
                                    />
                                </Widget>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                style={{
                                    maxWidth: 480,
                                    margin: `0 auto`,
                                }}
                            >
                                <Widget disableWidgetMenu inheritHeight bodyClass={classes.tabSection}>
                                    <Tabs
                                        tabs={[
                                            { id: 0, name: 'Breakfast', width: 80, disabled: !mealsOfDay[dow].includes(0) },
                                            { id: 1, name: 'Lunch', width: 60, disabled: !mealsOfDay[dow].includes(1) },
                                            { id: 2, name: 'Dinner', width: 65, disabled: !mealsOfDay[dow].includes(2) },
                                        ]}
                                        selected={mod}
                                        onChange={newMOD => {
                                            setMOD(newMOD)
                                        }}
                                    />
                                </Widget>
                            </Box>
                        </Grid>
                        {false && getDailyChart()}
                        <Grid item xs={12}>
                            <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                <ParentTable title="Orders">
                                    <Grid container>
                                        <Grid item xs={12} key={`submissions_${activeDate}_${dow}_${mod}`}>
                                            <Table
                                                title="Submissions"
                                                sortable
                                                defaultSortable={{
                                                    value: 'statusStr',
                                                    dir: 'desc',
                                                }}
                                                expandToFit
                                                onUpdateRow={data => {
                                                    updateOrder(data)
                                                }}
                                                rowActions={[
                                                    {
                                                        title: 'Delete',
                                                        icon: <DeleteIcon />,
                                                        onClick: rowData => {
                                                            deleteOrder(rowData)
                                                        },
                                                    },
                                                ]}
                                                columns={[
                                                    {
                                                        title: 'Name',
                                                        value: 'name',
                                                        required: true,
                                                        disabled: true,
                                                        sortable: true,
                                                    },
                                                    {
                                                        title: 'Pickup',
                                                        value: 'pickupStr',
                                                        type: 'SELECT',
                                                        options: pickupOptions,
                                                        required: true,
                                                        sortable: true,
                                                    },
                                                    {
                                                        title: 'Recurring',
                                                        value: 'recurring',
                                                        type: 'BOOLEAN',
                                                        disabled: true,
                                                        sortable: true,
                                                    },
                                                    {
                                                        title: 'Requests',
                                                        value: 'requests',
                                                        sortable: true,
                                                    },
                                                    {
                                                        title: 'Dietary Restrictions',
                                                        value: 'restrictions',
                                                        sortable: true,
                                                    },
                                                ]}
                                                showOnEmpty={true}
                                                emptyText="No Submissions"
                                                data={getOrders()}
                                            />
                                        </Grid>
                                    </Grid>
                                </ParentTable>
                            </Widget>
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
            </Grid>
        </>
    )
}
