import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'

import { PushNotifications } from '@capacitor/push-notifications'

import { getEmojiDataFromNative } from 'emoji-mart'

import { generateUUID } from 'code/UUID'
import data from 'emoji-mart/data/all.json'

import Papa from 'papaparse'

import moment from 'moment'

export const parseMembersFromFile = async file => {
    const waitParsing = file => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                worker: true, // Don't bog down the main thread if its a big file
                dynamicTyping: true,
                skipEmptyLines: true,
                complete: function(results) {
                    resolve(results)
                },
            })
        })
    }

    const setField = (mappings, fieldName, fieldValue) => {
        const similarity = (s1, s2) => {
            var longer = s1
            var shorter = s2
            if (s1.length < s2.length) {
                longer = s2
                shorter = s1
            }
            var longerLength = longer.length
            if (longerLength === 0) {
                return 1.0
            }
            return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
        }

        const editDistance = (s1, s2) => {
            s1 = s1.toLowerCase()
            s2 = s2.toLowerCase()

            var costs = []
            for (var i = 0; i <= s1.length; i++) {
                var lastValue = i
                for (var j = 0; j <= s2.length; j++) {
                    if (i === 0) costs[j] = j
                    else {
                        if (j > 0) {
                            var newValue = costs[j - 1]
                            if (s1.charAt(i - 1) !== s2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
                            costs[j - 1] = lastValue
                            lastValue = newValue
                        }
                    }
                }
                if (i > 0) costs[s2.length] = lastValue
            }
            return costs[s2.length]
        }

        if (fieldName in mappings) {
            // Check to see which field is closer
            if (similarity(fieldName, fieldValue) > similarity(fieldName, mappings[fieldName])) {
                mappings[fieldName] = fieldValue
            }
        } else {
            mappings[fieldName] = fieldValue
        }
    }

    const formatDataFields = arr => {
        const fields = Object.keys(arr[0])

        const mappings = {}

        for (let i = 0; i < fields.length; i++) {
            const _field = fields[i]
            const field = _field.toLowerCase()

            if (field.includes('email')) {
                setField(mappings, 'email', _field)
            } else if (field.includes('status')) {
                setField(mappings, 'status', _field)
            } else if (field.includes('role')) {
                setField(mappings, 'role', _field)
            } else if (field.includes('first')) {
                setField(mappings, 'first', _field)
            } else if (field.includes('last')) {
                setField(mappings, 'last', _field)
            } else if (field.includes('roll')) {
                setField(mappings, 'roll', _field)
            } else if (field.includes('name')) {
                const name = arr[0][_field]
                if (name.split(' ').length === 2) {
                    setField(mappings, 'fullName', _field)
                }
            }
        }

        const mappingFields = Object.keys(mappings)

        if (mappingFields.includes('fullName')) {
            if (mappingFields.includes('first') && mappingFields.includes('last')) {
                delete mappings.fullName
            } else {
                if (mappingFields.includes('first')) {
                    delete mappings.first
                } else {
                    delete mappings.last
                }
            }
        }

        // Try to find first / name, status, email, roll, and role
        if (!((mappingFields.includes('first') && mappingFields.includes('last')) || mappingFields.includes('fullName'))) {
            // TODO: throw error because it does not include names
        }

        return mappings
    }

    const mapDataFields = (arr, mappings) => {
        const mappedArr = []

        const mappedFields = Object.keys(mappings)

        for (let i = 0; i < arr.length; i++) {
            const item = arr[i]
            const mappedItem = {}

            for (let j = 0; j < mappedFields.length; j++) {
                const mappedField = mappedFields[j]
                if (mappings[mappedField] in item) {
                    mappedItem[mappedField] = item[mappings[mappedField]]
                }
            }

            mappedArr.push(mappedItem)
        }

        return mappedArr
    }

    const results = await waitParsing(file)

    let data = results.data

    if (!Array.isArray(data) || data.length === 0) {
        return [] // TODO: throw error because it is empty
    }

    // Sanitize fields then go through array and get rid of empty/invalid ones
    data = mapDataFields(data, formatDataFields(data))
        .filter(item => {
            // Check if it is missing the required fields or not
            return item.first && item.last
        })
        .map(_item => {
            const item = { ..._item }
            if (item.email !== undefined && (!item.email || !isEmailValid(item.email))) {
                delete item.email
            }
            return item
        })

    if (data.length === 0) {
        return [] // TODO: throw error because it is empty
    }

    return data
}

export const capitalizeFirstLetter = str => {
    if (!str || !str.length) return

    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const combinePaths = (base, extension) => {
    let val = base + '/' + extension
    if (base.charAt(base.length - 1) === '/') val = base + extension

    return val
}

export const isEmailValid = email => {
    if (typeof email !== 'string' || email.length === 0) {
        return false
    }

    return /\S+@\S+\.\S+/.test(email)
}

export const generateRandomString = length => {
    const stringOptions = 'ABCDEFGHJKLMPQRSTUVWXYZ23456789'
    let str = ''

    for (let i = 1; i <= length; i++) {
        str += stringOptions[Math.floor(Math.random() * stringOptions.length)]
    }

    return str
}

export const getCoordinate = (mapkit, obj) => {
    return obj && typeof obj.copy === 'function' ? obj : mapkit ? new mapkit.Coordinate(obj.latitude, obj.longitude) : null
}

export const calculateCoordinateDistanceInMeters = (coord1, coord2) => {
    const R = 6371e3 // metres
    const φ1 = (coord1.latitude * Math.PI) / 180 // φ, λ in radians
    const φ2 = (coord2.latitude * Math.PI) / 180
    const Δφ = ((coord2.latitude - coord1.latitude) * Math.PI) / 180
    const Δλ = ((coord2.longitude - coord1.longitude) * Math.PI) / 180

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c // in metres
}

export const metersToFeet = meters => {
    return meters / 0.3048
}

export const joinArrayWithLimit = arr => {
    var outStr = ''
    if (arr.length === 1) {
        outStr = arr[0]
    } else if (arr.length === 2) {
        //joins all with "and" but no commas
        //example: "bob and sam"
        outStr = arr.join(' and ')
    } else if (arr.length === 3) {
        //joins all with "and" but no commas
        //example: "bob and sam"
        outStr = arr.slice(0, 2).join(', ') + `, and ${arr[2]}`
    } else if (arr.length > 2) {
        //joins all with commas, but last one gets ", and" (oxford comma!)
        //example: "bob, joe, and sam"
        outStr = arr.slice(0, 2).join(', ') + `, and ${arr.length - 2} more`
    }
    return outStr
}

export const exportFile = (text, name, filetype = 'text/csv') => {
    const element = document.createElement('a')
    const file = new File([text], name, { type: filetype })
    const blob = new Blob([text], {
        type: filetype,
    })

    const url = URL.createObjectURL(blob)

    if (isMobileDevice() && navigator.canShare && navigator.canShare({ files: [file] })) {
        navigator.share({
            files: [file],
        })
    } else {
        element.href = url
        element.download = name
        document.body.appendChild(element) // Required for this to work in FireFox
        element.click()
    }
}

export const convertListToObject = (list, includeIndex) => {
    if (!Array.isArray(list)) {
        return list
    }

    let object = {}

    for (let i = 0; i < list.length; i++) {
        let item = { ...list[i] }

        if (includeIndex) {
            item.i = i
        }

        let id = item.id

        if (!id) {
            id = generateUUID()
        } else {
            delete item.id
        }

        object[id] = item
    }

    return object
}

export const isCacheOld = (cacheTimestamp, minutesOff) => {
    let timeDiff = Math.floor((new Date() - cacheTimestamp) / 1000 / 60)
    return timeDiff > minutesOff || isNaN(timeDiff)
}

export const convertObjectToList = object => {
    if (Array.isArray(object)) {
        return object
    }

    let list = []
    let keys = Object.keys(object)
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let item = { ...object[key] }
        item.id = key
        list.push(item)
    }

    list.sort((a, b) => a.i - b.i)

    for (let i = 0; i < list.length; i++) {
        let item = list[i]
        delete item.i
        list[i] = item
    }

    return list
}

export const addNames = arr => {
    for (let i = 0; i < arr.length; i++) {
        arr[i].name = `${arr[i].first} ${arr[i].last}`
    }

    return arr
}

export const tsToDate = ts => {
    if (ts.seconds || ts._seconds) {
        let date = new Date(0)
        date.setUTCSeconds(ts._seconds ? ts._seconds : ts.seconds)
        return date
    }

    return new Date()
}

export const getRelativeTime = ts => {
    if (ts) {
        let relative = {
            sameDay: 'h:mm a',
            lastDay: '[Yesterday]',
            lastWeek: 'dddd',
            sameElse: 'MMMM D, YYYY',
        }
        if (ts.seconds || ts._seconds) {
            let date = new Date(0)
            date.setUTCSeconds(ts._seconds ? ts._seconds : ts.seconds)

            return moment(date).calendar(null, relative)
        } else {
            return moment(ts).calendar(null, relative)
        }
    }

    return ''
}

export const getEmojiSet = () => {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
        iosPlatforms = /(iphone|ipad|ipod)/i

    if (macosPlatforms.test(userAgent) || iosPlatforms.test(userAgent)) {
        return 'apple'
    }

    return 'google'
}

export const isMobileDevice = () => {
    return isIOSDevice() || isAndroidDevice()
}

export const isAndroidDevice = () => {
    return Capacitor.getPlatform() === 'android'
}

export const isIOSDevice = () => {
    return Capacitor.getPlatform() === 'ios'
}

export const apiPrefix = `https://getgreekconnect.com/api` // 'http://localhost:3030/api' // 'https://deploy-preview-1--greekconnect.netlify.app/api'  // `https://getgreekconnect.com/api`

export const getAppVersion = async () => {
    const platform = Capacitor.getPlatform()
    if (platform === 'android' || platform === 'ios') {
        let app = await App.getInfo()
        return app.version
    }

    return null
}

export const markSetupComplete = () => {
    localStorage.removeItem('chapter_setup')
}

export const shouldShowSetup = () => {
    return !!localStorage.getItem('chapter_setup')
}

export const getChapterSetup = () => {
    let chapterSetup = localStorage.getItem('chapter_setup')
    if (!!chapterSetup) {
        chapterSetup = JSON.parse(chapterSetup)

        return chapterSetup
    }

    return null
}

export const markSetupStepComplete = step => {
    let chapterSetup = localStorage.getItem('chapter_setup')
    if (!!chapterSetup) {
        chapterSetup = JSON.parse(chapterSetup)

        if (Array.isArray(chapterSetup.complete) && !chapterSetup.complete.includes(step)) {
            chapterSetup.complete.push(step)
            localStorage.setItem('chapter_setup', JSON.stringify(chapterSetup))
        }
    }
}

export const clearNotifications = async title => {
    if (Capacitor.isPluginAvailable('PushNotifications')) {
        let notifications = await PushNotifications.getDeliveredNotifications()
        let notifsToDelete = []

        for (let i = 0; i < notifications.length; i++) {
            let notif = notifications[i]

            if (notif.name === title) {
                notifsToDelete.push(notif)
            }
        }

        if (notifsToDelete.length > 0) {
            await PushNotifications.removeDeliveredNotifications(notifsToDelete)
        }
    }
}

export const unboxTS = obj => {
    if (obj.seconds) {
        return obj.seconds
    }

    return obj._seconds
}

export const getOrdinalSuffix = i => {
    var j = i % 10,
        k = i % 100
    if (j === 1 && k !== 11) {
        return 'st'
    }
    if (j === 2 && k !== 12) {
        return 'nd'
    }
    if (j === 3 && k !== 13) {
        return 'rd'
    }
    return 'th'
}

export const getPossibleMembers = (membersObj, membersToExclude = []) => {
    let memIds = Object.keys(membersObj).filter(memId => !membersToExclude.includes(memId))

    let arr = []

    for (let i = 0; i < memIds.length; i++) {
        let memId = memIds[i]
        let mem = membersObj[memId]

        arr.push({ id: memId, name: `${mem.first} ${mem.last}` })
    }

    return arr
}

export const getNativeEmoji = native => {
    return getEmojiDataFromNative(native, 'native', data)
}

export const fileListToArray = list => {
    const array = []
    for (var i = 0; i < list.length; i++) {
        array.push(list.item(i))
    }
    return array
}

export const getWeek = date => {
    return moment(new Date(date.getTime())).week()
}

export const getWeekYear = date => {
    return moment(new Date(date.getTime())).weekYear()
}
