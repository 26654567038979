import tinycolor from 'tinycolor2'

const primary = '#1e88e5' //'#F4511E' //'#f44336' //'#007fff' //"#0984e3";//"#536DFE";
const secondary = '#f5cd7a' //"#34495e";//"#2ecc71";//"#FF5C93";

export const orange = '#ffb74d'
export const darkorange = '#ef6c00'
export const yellow = '#F5CD7A'
export const blue = '#1e88e5'
export const red = '#E66868'
export const pink = '#F78FB3'
export const bluegray = '#303A52'
export const green = '#2ecc71'
export const gray = '#dfe6e9'
export const purple = '#6c5ce7'
export const white = '#ffffff'
export const blackgray = '#4a4a4a'

const lightenRate = 7.5
const darkenRate = 15

export default {
    title: 'Default',
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: primary,
            light: tinycolor(primary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(primary)
                .darken(darkenRate)
                .toHexString(),
        },
        purple: {
            main: purple,
            light: tinycolor(purple)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(purple)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#fff',
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(secondary)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#ccc',
        },
        red: {
            main: red,
            light: tinycolor(red)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(red)
                .darken(darkenRate)
                .toHexString(),
        },
        white: {
            main: white,
            light: tinycolor(white)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(white)
                .darken(darkenRate)
                .toHexString(),
        },
        gray: {
            main: gray,
            light: tinycolor(gray)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(gray)
                .darken(darkenRate)
                .toHexString(),
        },
        green: {
            main: green,
            light: tinycolor(green)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(green)
                .darken(darkenRate)
                .toHexString(),
        },
        orange: {
            main: orange,
            light: tinycolor(orange)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(orange)
                .darken(darkenRate)
                .toHexString(),
        },
        darkorange: {
            main: darkorange,
            light: tinycolor(darkorange)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(darkorange)
                .darken(darkenRate)
                .toHexString(),
        },
        pink: {
            main: pink,
            light: tinycolor(pink)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(pink)
                .darken(darkenRate)
                .toHexString(),
        },
        blue: {
            main: blue,
            light: tinycolor(blue)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(blue)
                .darken(darkenRate)
                .toHexString(),
        },
        yellow: {
            main: yellow,
            light: tinycolor(yellow)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(yellow)
                .darken(darkenRate)
                .toHexString(),
        },
        bluegray: {
            main: bluegray,
            light: tinycolor(bluegray)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(bluegray)
                .darken(darkenRate)
                .toHexString(),
        },
        blackgray: {
            main: blackgray,
            light: tinycolor(blackgray)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(blackgray)
                .darken(darkenRate)
                .toHexString(),
        },
        inherit: {
            main: 'inherit',
            light: tinycolor('inherit')
                .lighten('inherit')
                .toHexString(),
            dark: tinycolor('inherit')
                .darken('inherit')
                .toHexString(),
        },
        text: {
            primary: '#6E6E6E',
            secondary: '#6E6E6E',
            hint: '#B9B9B9',
        },
        background: {
            default: '#F6F7FF',
            light: '#F5F5F5',
            paper: '#FFFFFF',
        },
    },
    border: '1px solid #dcdcdc',
    borderColor: '#dcdcdc',
    document: {
        outline: 'rgba(0, 0, 0, 0.23)',
        hoverOutline: 'rgb(110, 110, 110)',
        color: '#6e6e6e',
    },
    customShadows: {
        widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    },
    transitionDuration: 275,
    components: {
        MuiButton: {
            variants: [
                {
                    // @ts-ignore internal repo module augmentation issue
                    props: { variant: 'code' },
                    style: ({ theme }) => [
                        {
                            border: '1px solid',
                            color: (theme.vars || theme).palette.grey[800],
                            borderColor: (theme.vars || theme).palette.grey[300],
                            backgroundColor: (theme.vars || theme).palette.grey[50],
                            fontFamily: theme.typography.fontFamilyCode,
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: 21 / 14,
                            letterSpacing: 0,
                            WebkitFontSmoothing: 'subpixel-antialiased',
                            '&:hover, &.Mui-focusVisible': {
                                borderColor: (theme.vars || theme).palette.primary.main,
                                backgroundColor: (theme.vars || theme).palette.grey[50],
                                '& .MuiButton-endIcon': {
                                    color: (theme.vars || theme).palette.primary.main,
                                },
                            },
                            '& .MuiButton-startIcon': {
                                color: (theme.vars || theme).palette.grey[400],
                            },
                            '& .MuiButton-endIcon': {
                                display: 'inline-block',
                                position: 'absolute',
                                right: 0,
                                marginRight: 10,
                                color: (theme.vars || theme).palette.grey[700],
                            },
                        },
                        theme.applyDarkStyles({
                            color: (theme.vars || theme).palette.grey[400],
                            borderColor: (theme.vars || theme).palette.primaryDark[400],
                            backgroundColor: (theme.vars || theme).palette.primaryDark[700],
                            '&:hover, &.Mui-focusVisible': {
                                backgroundColor: (theme.vars || theme).palette.primaryDark[600],
                                '& .MuiButton-endIcon': {
                                    color: (theme.vars || theme).palette.primary[300],
                                },
                            },
                            '& .MuiButton-endIcon': {
                                color: (theme.vars || theme).palette.grey[400],
                            },
                        }),
                    ],
                },
            ],
        },
    },
}
