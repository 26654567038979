import React from 'react'
import { Box, Tooltip, IconButton, Typography } from '@material-ui/core'
import { LastPage as LastPageIcon, FirstPage as FirstPageIcon, ChevronLeft as PrevPageIcon, ChevronRight as NextPageIcon } from '@material-ui/icons'

const PollResponseOrdering = ({ responses, curIndex, onChangeIndex }) => {
    return (
        <Box style={{ display: 'flex', justifyContent: 'center', margin: 3 }}>
            <Tooltip title="First">
                <span>
                    <IconButton
                        size="small"
                        onClick={() => onChangeIndex(0)}
                        style={{
                            marginRight: 4,
                            padding: 2,
                        }}
                        disabled={curIndex <= 0}
                    >
                        <FirstPageIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Previous">
                <span>
                    <IconButton
                        size="small"
                        onClick={() => onChangeIndex(curIndex - 1)}
                        style={{
                            marginRight: 4,
                            padding: 2,
                        }}
                        disabled={curIndex <= 0}
                    >
                        <PrevPageIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Typography style={{ margin: 3 }} noWrap>
                {`${(curIndex % responses.length) + 1} of ${responses.length}`}
            </Typography>
            <Tooltip title="Next">
                <span>
                    <IconButton
                        size="small"
                        onClick={() => onChangeIndex(curIndex + 1)}
                        style={{
                            marginRight: 4,
                            padding: 2,
                        }}
                        disabled={curIndex >= responses.length - 1}
                    >
                        <NextPageIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Last">
                <span>
                    <IconButton
                        size="small"
                        onClick={() => onChangeIndex(responses.length - 1)}
                        style={{
                            marginRight: 4,
                            padding: 2,
                        }}
                        disabled={curIndex >= responses.length - 1}
                    >
                        <LastPageIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    )
}

export default React.memo(PollResponseOrdering)
