import React from 'react'

import app from 'firebase/app'

import { Grid } from '@material-ui/core'

import moment from 'moment'

import { Add as AddIcon, HomeOutlined as HomeIcon, FastfoodOutlined as MealsIcon } from '@material-ui/icons'

import format from 'date-fns/format'

// components
import MiniCard from 'components/Cards/MiniCard'
import NavigationBar from 'components/NavigationBar'
import Page from 'components/Page'

import { AuthContext } from 'context/AuthContext'

export default function Menus(props) {
    const { user } = React.useContext(AuthContext)

    const [menus, setMenus] = React.useState([])
    const [hasTried, setHasTried] = React.useState(false)

    if (!hasTried) {
        setHasTried(true)
        grabSettings()
    }

    function grabSettings() {
        const db = app.firestore()
        db.collection('chapters')
            .doc(user.getChapter())
            .collection('menus')
            .doc('INFO')
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()

                    let menus = []
                    let ms = data.menus

                    let msKeys = Object.keys(ms)

                    for (let i = 0; i < msKeys.length; i++) {
                        let key = msKeys[i]
                        let menuID = ms[key]

                        let date = key.split('.')

                        let year = parseInt(date[0])
                        let week = parseInt(date[1])

                        let momentDate = moment()
                            .day('Sunday')
                            .year(year)
                            .week(week)
                            .toDate()

                        let data = {
                            week: week,
                            year: year,
                            title: format(momentDate, 'MMMM do'),
                            subtitle: String(year),
                            id: menuID,
                            active: moment().isSame(momentDate, 'week'),
                        }

                        menus.push(data)
                    }

                    menus.sort(function(a, b) {
                        if (b.year !== a.year) {
                            return b.year - a.year
                        }

                        return b.week - a.week
                    })

                    setMenus(menus)
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    return (
        <>
            <Page footer="Menus that are not in use may get automatically deleted once you've reached your limit">
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Meals',
                            link: '/app/applications/meals/',
                            icon: <MealsIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: 'Menus',
                        },
                    ]}
                    rightButtons={[
                        {
                            name: 'Add',
                            type: 'icon',
                            innerIcon: <AddIcon />,
                            onClick: () => {
                                props.history.push('/app/applications/meals/menus/new')
                            },
                        },
                    ]}
                    grid
                />
                {menus.map(menu => (
                    <Grid item lg={4} md={6} sm={6} xs={12} key={menu.id}>
                        <MiniCard
                            title={menu.title}
                            subtitle={menu.subtitle}
                            chips={
                                menu.active
                                    ? [
                                          {
                                              color: 'green',
                                              label: 'Active',
                                              id: 'ACTIVE',
                                          },
                                      ]
                                    : []
                            }
                            leftButton={{
                                onClick: e => {
                                    props.history.push('/app/applications/meals/menus/' + menu.id)
                                },
                                label: 'Edit',
                            }}
                        />
                    </Grid>
                ))}
            </Page>
        </>
    )
}
