import React from 'react'

import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from '@material-ui/core'
import { ReportOutlined as ReportIcon, InfoOutlined as InfoIcon } from '@material-ui/icons'

import { VisibilityOffIcon as BlockIcon } from 'components/Icons'

import { useTheme } from '@material-ui/styles'

const MessageReportDrawer = ({ message, open, onClose, onBlock, onReport, sender, ...props }) => {
    const theme = useTheme()

    let name = `${sender.first} ${sender.last}`

    return (
        <SwipeableDrawer anchor="bottom" open={open} onClose={onClose} disableSwipeToOpen onOpen={() => {}}>
            <Box
                style={{
                    height: '100%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    paddingTop: 12,
                    paddingBottom: 'env(safe-area-inset-bottom)',
                }}
            >
                <List>
                    <ListItem button onClick={() => window.open('https://getgreekconnect.com/terms#objectionable_content', '_blank')}>
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText primary="View Objectionable Content Policy" />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={() => onBlock('message')} style={{ color: theme.palette.red.main }}>
                        <ListItemIcon>
                            <BlockIcon style={{ color: theme.palette.red.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Block Message" />
                    </ListItem>
                    <ListItem button onClick={() => onReport('message')} style={{ color: theme.palette.red.main }}>
                        <ListItemIcon>
                            <ReportIcon style={{ color: theme.palette.red.main }} />
                        </ListItemIcon>
                        <ListItemText primary="Report Message" />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={() => onBlock('user')} style={{ color: theme.palette.red.main }}>
                        <ListItemIcon>
                            <BlockIcon style={{ color: theme.palette.red.main }} />
                        </ListItemIcon>
                        <ListItemText primary={`Block ${name}`} />
                    </ListItem>
                    <ListItem button onClick={() => onReport('user')} style={{ color: theme.palette.red.main }}>
                        <ListItemIcon>
                            <ReportIcon style={{ color: theme.palette.red.main }} />
                        </ListItemIcon>
                        <ListItemText primary={`Report ${name}`} />
                    </ListItem>
                </List>
            </Box>
        </SwipeableDrawer>
    )
}

export default React.memo(MessageReportDrawer)
