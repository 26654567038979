import app from 'firebase/app'
import 'firebase/functions'
import React from 'react'

import axios from 'axios'

import { Box, Button, ButtonBase, CircularProgress, Grid, Link } from '@material-ui/core'
import {
    HomeOutlined as HomeIcon,
    FastfoodOutlined as MealsIcon,
    ForumOutlined as MessagesIcon,
    Timeline as PointsIcon,
    PollOutlined as PollsIcon,
    Group as RolesIcon,
    WhatshotOutlined as RushIcon,
    AssignmentOutlined as TasksIcon,
} from '@material-ui/icons'

import moment from 'moment'

import { CrispIcon, DriveIcon, ManageAccountsIcon as ManageMembersIcon } from 'components/Icons'

import NavigationBar from 'components/NavigationBar'
import Title from 'components/Title'
import ToggleSection from 'components/ToggleSection'
import Widget from 'components/Widget'
import { Typography } from 'components/Wrappers'
import useStyles from './styles'

import { apiPrefix, isMobileDevice } from 'code/Helper'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { CRISP_STATUS, CrispContext } from 'context/CrispContext'

import TimeLeft from './components/TimeLeft'

export default function ManageChapter(props) {
    var classes = useStyles()

    const titleVariant = 'default' // theme.palette.type === 'dark' ? 'default' : 'white'

    const { user } = React.useContext(AuthContext)
    const { chapter, setChapter, setChapterCache } = React.useContext(ChapterContext)
    const { crispStatus, isChatAvailable, turnOffCrisp, turnOnCrisp } = React.useContext(CrispContext)

    const [rep, setRep] = React.useState(null)
    const [hasTried, setHasTried] = React.useState(null)

    const applications = [
        /*{
            id: 'courses',
            label: 'Courses',
            icon: <CoursesIcon />,
            isPaid: true,
            href: 'https://getgreekconnect.com/docs/courses/overview',
            sizing: {
                xs: 6,
                md: 3,
            },
        },*/
        {
            id: 'drive',
            label: 'Drive',
            icon: <DriveIcon />,
            href: 'https://getgreekconnect.com/docs/drive/overview',
            sizing: {
                xs: 6,
                md: 3,
            },
        },
        {
            id: 'meals',
            label: 'Meals',
            icon: <MealsIcon />,
            isPaid: true,
            href: 'https://getgreekconnect.com/docs/meal-plan/overview',
            sizing: {
                xs: 6,
                md: 3,
            },
        },
        {
            id: 'messages',
            label: 'Messages',
            icon: <MessagesIcon />,
            href: 'https://getgreekconnect.com/docs/messages/overview',
            sizing: {
                xs: 6,
                md: 3,
            },
        },
        {
            id: 'points',
            label: 'Points',
            icon: <PointsIcon />,
            isPaid: true,
            href: 'https://getgreekconnect.com/docs/points/overview',
            sizing: {
                xs: 6,
                md: 3,
            },
        },
        {
            id: 'polls',
            label: 'Polls',
            icon: <PollsIcon />,
            href: 'https://getgreekconnect.com/docs/polls/overview',
            sizing: {
                xs: 6,
                md: 4,
            },
        },
        {
            id: 'rush',
            label: 'Rush',
            icon: <RushIcon />,
            isPaid: true,
            href: 'https://getgreekconnect.com/docs/rush/overview',
            sizing: {
                xs: 6,
                md: 4,
            },
        },
        {
            id: 'tasks',
            label: 'Tasks',
            icon: <TasksIcon />,
            isPaid: true,
            href: 'https://getgreekconnect.com/docs/tasks/overview',
            sizing: {
                xs: 12,
                md: 4,
            },
        },
    ]

    const getInitialChecked = () => {
        let checked = {}

        if (chapter && chapter.enabledFeatures) {
            if (Array.isArray(chapter.enabledFeatures)) {
                for (let i = 0; i < applications.length; i++) {
                    let app = applications[i]
                    if (chapter.enabledFeatures.includes(app.id)) {
                        checked[app.id] = true
                    }
                }
            } else if (typeof chapter.enabledFeatures === 'object') {
                checked = chapter.enabledFeatures
            }
        }

        return checked
    }

    const [checked, setChecked] = React.useState(getInitialChecked())

    const _isChapterFree = () => {
        if (chapter && chapter.settings) {
            if (chapter.settings.tier === 'lite') {
                return true
            } else if (chapter.settings.tier === 'base') {
                if (chapter.settings.trialExpiration) {
                    let time = chapter.settings.trialExpiration.seconds ? chapter.settings.trialExpiration.seconds : chapter.settings.trialExpiration._seconds
                    let deadline = new Date(time * 1000)

                    return moment().isAfter(deadline)
                }
            }
        }

        return false
    }

    const isChapterFree = React.useCallback(_isChapterFree, [chapter])

    if (!hasTried && chapter && chapter.settings && !isChapterFree()) {
        getRepresentative()
        setHasTried(true)
    }

    function getRepresentative() {
        if (chapter && chapter.settings && chapter.settings.representative) {
            const db = app.firestore()
            let representativeRef = db.collection('users').doc(chapter.settings.representative)
            representativeRef.get().then(doc => {
                if (doc.exists) {
                    let data = doc.data()
                    setRep(data)
                }
            })
        }
    }

    const updateFeature = async (id, isChecked) => {
        const db = app.firestore()
        let ref = db.collection('chapters').doc(user.getChapter())

        let updatedChecked = { ...checked, [id]: isChecked }

        setChapter(chapter => ({ ...chapter, enabledFeatures: { ...updatedChecked } }))
        setChapterCache({ ...chapter, enabledFeatures: { ...updatedChecked } })

        await ref.update({ enabledFeatures: { ...updatedChecked } }).catch(function(error) {
            console.log('Error updating document:', error)
        })
    }

    const [chapterQuote, setChapterQuote] = React.useState(null)

    const grabChapterQuote = async () => {
        let val = await axios.get(`${apiPrefix}/chapter/${user.getChapter()}`)

        if (val && val.data) {
            setChapterQuote(val.data)
        }
    }

    const goToSubscription = async () => {
        if (chapterQuote && chapterQuote.session && chapterQuote.session.url) {
            window.open(chapterQuote.session.url)
        }
    }

    const [tutorial, setTutorial] = React.useState(null)

    const grabTutorial = async () => {
        const sessionTutorial = sessionStorage.getItem('recommended_tutorial')

        if (sessionTutorial) {
            setTutorial(JSON.parse(sessionTutorial))
        } else {
            let val = await axios.get(`${apiPrefix}/chapter/tutorial`)

            if (val && val.data) {
                const data = val.data
                if (data.url && data.title && data.image) {
                    sessionStorage.setItem('recommended_tutorial', JSON.stringify(data))
                    setTutorial(data)
                }
            }
        }
    }

    React.useEffect(() => {
        if (chapter) {
            if (!isChapterFree()) {
                grabTutorial()
            }

            if (chapter.settings && chapter.settings.tier) {
                grabChapterQuote()
            }
        }
    }, [])

    const renderTimeLeft = () => {
        let time = chapter.settings.trialExpiration.seconds ? chapter.settings.trialExpiration.seconds : chapter.settings.trialExpiration._seconds

        let deadline = new Date(time * 1000)

        return (
            <>
                {moment(deadline).isAfter(moment()) ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ fontWeight: 500 }} align="center">
                                Time Left
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                <Box style={{ maxWidth: 512, width: '100%' }}>
                                    <TimeLeft deadline={moment(deadline)} />
                                </Box>
                            </Box>
                        </Grid>
                    </>
                ) : (
                    <Grid item xs={12}>
                        <Typography align="center" color="red" style={{ fontWeight: 'bold' }}>
                            Your chapter's trial has expired, upgrade now!
                        </Typography>
                    </Grid>
                )}
            </>
        )
    }

    const renderPricingTier = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: 500 }} align="center">
                    Upgrade Your Chapter
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center">Continue using Greek Connect to streamline your chapter's operations</Typography>
            </Grid>
            {chapter && chapter.settings && chapter.settings.trialExpiration && renderTimeLeft()}
            {chapterQuote ? (
                <>
                    <Typography style={{ fontWeight: 'bold', fontSize: 24, width: '100%' }} align="center">
                        ${Math.round(chapterQuote.price.unit_amount / 100 / chapterQuote.price.recurring.interval_count)}/mo.
                    </Typography>
                    <Typography style={{ width: '100%' }} align="center">
                        billed every 6 months
                    </Typography>
                    <Typography style={{ width: '100%' }} align="center" variant="caption">
                        {chapterQuote.price.metadata.maxMembers === 'unlimited'
                            ? 'any number of members'
                            : `up to ${chapterQuote.price.metadata.maxMembers} members`}
                    </Typography>
                    <Typography style={{ width: '100%' }} align="center" variant="caption">
                        {isChapterFree() ? (
                            <Link href={`mailto:contact@greekconnect.app`}>Contact support to change this size</Link>
                        ) : (
                            `contact your chapter's representative to change this size`
                        )}
                    </Typography>
                </>
            ) : (
                <div style={{ height: 98, width: '100%' }}></div>
            )}
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Button variant="contained" color="primary" disableElevation onClick={() => goToSubscription()} disabled={!chapterQuote}>
                    Upgrade Now
                </Button>
            </Grid>
        </Grid>
    )

    const renderCustomerPortal = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: 500 }} align="center">
                    Subscription Info
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center">View details about your chapter's subscription</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Button variant="contained" color="primary" disableElevation onClick={() => goToSubscription()} disabled={!chapterQuote}>
                    View Details
                </Button>
            </Grid>
        </Grid>
    )

    const renderFeatures = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: 500 }} align="center">
                    Features
                </Typography>
            </Grid>
            {applications.map(application => (
                <Grid item {...application.sizing} key={application.id}>
                    <ToggleSection
                        icon={application.icon}
                        label={application.label}
                        href={application.href}
                        disabled={application.isPaid && isChapterFree()}
                        checked={checked[application.id] !== false}
                        onChange={() => {
                            setChecked(ch => ({ ...ch, [application.id]: ch[application.id] === false }))

                            let isChecked = checked[application.id] === false

                            updateFeature(application.id, isChecked)
                        }}
                    />
                </Grid>
            ))}
            <Grid item xs={12}>
                <Typography variant="caption" align="center">
                    Only features that can be turned off are visible
                </Typography>
            </Grid>
        </Grid>
    )

    const renderRepresentative = () => (
        <Box display="flex" flexDirection="column" className={classes.representativeBox}>
            {rep === null ? (
                <Box style={{ display: 'flex', height: 283, justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography
                        variant="h3"
                        align="center"
                        style={{
                            fontWeight: 'semibold',
                        }}
                    >
                        Chapter Representative
                    </Typography>
                    <img className={classes.representativePhoto} src={rep.photoURL} alt="" />
                    <Typography
                        variant="h4"
                        align="center"
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {`${rep.first} ${rep.last}`}
                    </Typography>
                    <Typography align="center" style={{ height: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        Feel free to contact me whenever and I will get back to you as soon as possible!
                    </Typography>
                    {!isChatAvailable() ? (
                        <>
                            <Typography align="center">
                                <Link href={`tel:${rep.phone}`}>{rep.phone}</Link>
                            </Typography>
                            <Typography align="center">
                                <Link href={`mailto:${rep.email}`}>{rep.email}</Link>
                            </Typography>
                        </>
                    ) : (
                        <Box style={{ margin: 'auto', marginTop: 10.25 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation
                                startIcon={<CrispIcon />}
                                disabled={crispStatus === CRISP_STATUS.INITIALIZING}
                                onClick={() => {
                                    if (crispStatus === CRISP_STATUS.OPEN) {
                                        turnOffCrisp()
                                    } else {
                                        turnOnCrisp(true)
                                    }
                                }}
                            >
                                {crispStatus === CRISP_STATUS.OPEN ? 'Hide chat' : crispStatus === CRISP_STATUS.CLOSED ? 'Show chat' : 'Chat with me'}
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </Box>
    )

    const hasRepresentative = chapter && chapter.settings && chapter.settings.representative

    return (
        <Grid container spacing={2}>
            <NavigationBar
                titles={[
                    {
                        name: 'My House',
                        link: '/app/dashboard/',
                        icon: <HomeIcon />,
                    },
                    {
                        name: 'Admin',
                    },
                ]}
                grid
            />
            {/*!isChapterFree() && */ hasRepresentative && (
                <Grid item lg={!tutorial ? 12 : 6} xs={12}>
                    {isMobileDevice() ? (
                        renderRepresentative()
                    ) : (
                        <Widget disableWidgetMenu inheritHeight noBodyPadding>
                            {renderRepresentative()}
                        </Widget>
                    )}
                </Grid>
            )}
            {tutorial && (
                <Grid item lg={!hasRepresentative ? 12 : 6} xs={12}>
                    <Widget disableWidgetMenu inheritHeight noBodyPadding>
                        <ButtonBase
                            onClick={() => {
                                window.open(tutorial.url, '_blank')
                            }}
                            className={classes.recommendedArticleBody}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: 100,
                                    bottom: 0,
                                    width: '100%',
                                    padding: 12,
                                    paddingTop: 24,
                                    backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.4))',
                                }}
                            >
                                <Typography variant="h6" weight="bold" style={{ color: 'white', marginTop: 12, textTransform: 'uppercase' }}>
                                    Recommended Tutorial
                                </Typography>
                                <Typography variant="h3" weight="bold" style={{ color: 'white' }}>
                                    {tutorial.title}
                                </Typography>
                            </div>
                            <div style={{ minHeight: '100%', backgroundColor: 'white', width: '100%' }}>
                                <img style={{ width: '100%', height: 307, display: 'block', objectFit: 'cover' }} src={tutorial.image} alt="" />
                            </div>
                        </ButtonBase>
                    </Widget>
                </Grid>
            )}
            <Grid item xs={12} sm={6}>
                <Title
                    title="Roles"
                    icon={<RolesIcon />}
                    color="green"
                    direction="column"
                    fullWidth
                    variant={titleVariant}
                    onClick={() => props.history.push('/app/admin/roles')}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Title
                    title="Member Management"
                    icon={<ManageMembersIcon />}
                    color="blue"
                    direction="column"
                    fullWidth
                    variant={titleVariant}
                    onClick={() => props.history.push('/app/admin/member-management')}
                />
            </Grid>
            {chapter && chapter.settings && (chapter.settings.tier === 'lite' || chapter.settings.tier === 'base') && (
                <Grid item xs={12}>
                    {isMobileDevice() ? (
                        renderPricingTier()
                    ) : (
                        <Widget disableWidgetMenu inheritHeight>
                            {renderPricingTier()}
                        </Widget>
                    )}
                </Grid>
            )}
            {chapter && chapter.settings && chapter.settings.tier === 'standard' && (
                <Grid item xs={12}>
                    {isMobileDevice() ? (
                        renderCustomerPortal()
                    ) : (
                        <Widget disableWidgetMenu inheritHeight>
                            {renderCustomerPortal()}
                        </Widget>
                    )}
                </Grid>
            )}
            <Grid item xs={12}>
                {isMobileDevice() ? (
                    renderFeatures()
                ) : (
                    <Widget disableWidgetMenu inheritHeight>
                        {renderFeatures()}
                    </Widget>
                )}
            </Grid>
            {/*<Grid item xs={12}>
                {isMobileDevice() ? (
                    renderLog()
                ) : (
                    <Widget disableWidgetMenu inheritHeight>
                        {renderLog()}
                    </Widget>
                )}
            </Grid>*/}
        </Grid>
    )
}
