import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    card: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    divider: {
        margin: `0px -${theme.spacing(3)}px`,
    },
    chip: {
        marginBottom: `0 !important`,
        marginTop: `0 !important`,
    },
    title: {
        marginTop: 2,
        marginBottom: 2,
    },
    cardActions: {
        minHeight: 44,
    },
    buttonLink: {
        cursor: `pointer`,
        textDecoration: `none !important`,
    },
    subtitle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    cardMedia: {},
}))
