import 'firebase/analytics'
import app from 'firebase/app'
import 'firebase/auth'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    appId: process.env.REACT_APP_ID,
}

class Firebase {
    constructor() {
        app.initializeApp(config)

        app.firestore()
            .enablePersistence({ synchronizeTabs: true })
            .catch(err => {
                console.log('Unable to enable persistence', err)
            })

        app.analytics()
    }
}

export default Firebase
