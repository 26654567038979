import React from 'react'

import { PollAddIcon, FolderAddIcon } from 'components/Icons'

import Item from 'components/Menu/Item'
import Menu from 'components/Menu'

const PollsCreateItem = ({ disableFolder, onSelectAction, ...props }) => {
    const getItems = () => {
        return [
            <Item key="poll" icon={PollAddIcon} onClick={() => onSelectAction('poll')} label="New Poll" type={props.type} />,
            <Item icon={FolderAddIcon} key="folder" onClick={() => onSelectAction('folder')} label="New Folder" type={props.type} />,
        ]
    }

    return <Menu {...props}>{getItems()}</Menu>
}

export default React.memo(PollsCreateItem)
