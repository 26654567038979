import { Box, Grid, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Add as AddIcon, Create as EditIcon, HomeOutlined as HomeIcon } from '@material-ui/icons'
import { LinearProgress, Stack } from '@mui/material'
import React from 'react'

import { Points as PointsObject } from 'objects/Points'

import { TumbleWeedOutlinedIcon } from 'components/Icons'

import TinyCard from 'components/Cards/TinyCard'
import NavigationBar from 'components/NavigationBar'
import app from 'firebase/app'

import useStyles from './styles'

import { isMobileDevice } from 'code/Helper'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

export default function Points(props) {
    const classes = useStyles()
    const theme = useTheme()

    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)

    const [points, setPoints] = React.useState(null)
    const [hasTried, setHasTried] = React.useState(false)

    const isAdmin = isUserPointsAdmin()
    const isCreateAdmin = isUserPointsCreate()

    function isUserPointsAdmin() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || (Array.isArray(chapter.perms['pointsAdmin']) && chapter.perms['pointsAdmin'].includes(mem.role))
            }
        }

        return false
    }

    function isUserPointsCreate() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || (Array.isArray(chapter.perms['pointsCreate']) && chapter.perms['pointsCreate'].includes(mem.role))
            }
        }

        return false
    }

    if (!hasTried) {
        setHasTried(true)
        grabPoints()
    }

    function grabPoints() {
        const db = app.firestore()
        const baseRef = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('points')
        let refs = []

        if (isAdmin) {
            refs.push(baseRef)
        } else if (isCreateAdmin) {
            refs.push(baseRef.where('author.id', '==', user.getId()))
            refs.push(baseRef.where('admins', 'array-contains', user.getId()))
            refs.push(baseRef.where('mType', '!=', 1).where('vis', 'array-contains', chapter.members[user.getId()].status))
            refs.push(baseRef.where('mType', '==', 1).where('mems', 'array-contains', user.getId()))
        } else if (chapter && chapter.members && user && user.getId() in chapter.members) {
            refs.push(baseRef.where('mType', '!=', 1).where('vis', 'array-contains', chapter.members[user.getId()].status))
            refs.push(baseRef.where('mType', '==', 1).where('mems', 'array-contains', user.getId()))
        }

        Promise.all(refs.map(ref => ref.get()))
            .then(function(querySnapshots) {
                let curPoints = []
                let addedIds = []
                querySnapshots.forEach(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        let data = new PointsObject(doc.data())
                        data['id'] = doc.id

                        if (!addedIds.includes(doc.id)) {
                            curPoints.push(data)
                            addedIds.push(doc.id)
                        }
                    })
                })

                curPoints.sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })

                setPoints(curPoints)
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    const renderNavBar = isGrid => (
        <NavigationBar
            titles={[
                {
                    name: 'My House',
                    link: '/app/dashboard/',
                    icon: <HomeIcon />,
                },
                {
                    name: 'Points',
                },
            ]}
            rightButtons={
                isAdmin || isCreateAdmin
                    ? [
                          {
                              name: 'Add',
                              type: 'icon',
                              innerIcon: <AddIcon />,
                              onClick: () => {
                                  props.history.push('/app/applications/points/edit/new')
                              },
                          },
                      ]
                    : []
            }
            key={isAdmin || isCreateAdmin ? 'admin' : 'nah'}
            grid={isGrid}
        />
    )

    if (Array.isArray(points) && points.length === 0) {
        return (
            <>
                <Box>{renderNavBar(false)}</Box>
                <Box style={{ padding: 8, width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 16,
                            flexGrow: 1,
                        }}
                    >
                        <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor }} />
                        <Typography style={{ color: theme.borderColor }}>There are no point systems here</Typography>
                    </Box>
                </Box>
            </>
        )
    }

    const getDescription = system => {
        let userPoints = system.getPoints(user.getId())

        if (system.getType() === PointsObject.TYPE_GOAL) {
            let goal = 100
            let progress = 0

            goal = system.goal

            progress = (userPoints / goal) * 100

            if (progress > 100) {
                progress = 100
            } else if (progress < 0) {
                progress = 0
            }

            return (
                <Stack direction="row" sx={{ height: '20px' }} alignItems="center" gap={2}>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        style={{ flexGrow: 1 }}
                        classes={{
                            barColorPrimary: classes.progressBar,
                        }}
                        className={classes.progress}
                    />
                    <Typography variant="caption">
                        {userPoints}/{goal}
                    </Typography>
                </Stack>
            )
        }

        return (
            <Typography variant="caption" style={{ overflow: 'hidden', textOverflow: 'ellipsis', height: '20px' }}>
                {userPoints} points
            </Typography>
        )
    }

    return (
        <>
            <Grid container spacing={2}>
                {renderNavBar(true)}
                {points &&
                    points.map(point => (
                        <Grid item xs={12} sm={6} lg={4} key={point.id}>
                            <TinyCard
                                onClick={() => props.history.push('/app/applications/points/view/' + point.id)}
                                text={point.name}
                                description={getDescription(point)}
                                author={isCreateAdmin ? point.author : null}
                                buttons={
                                    isAdmin || (user.getId() === point.author.id && isCreateAdmin)
                                        ? [
                                              {
                                                  onClick: () => {
                                                      props.history.push('/app/applications/points/edit/' + point.id)
                                                  },
                                                  icon: <EditIcon />,
                                                  id: `${point.id}.edit`,
                                              },
                                          ]
                                        : null
                                }
                                variant={isMobileDevice() ? 'outlined' : 'default'}
                            />
                        </Grid>
                    ))}
            </Grid>
        </>
    )
}
