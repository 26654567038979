import React from 'react'

import moment from 'moment'

import { Box, Typography } from '@material-ui/core'

const TimeLeft = ({ deadline, ...props }) => {
    const [curTime, setCurTime] = React.useState(moment())

    React.useEffect(() => {
        let interval = setInterval(() => {
            setCurTime(moment())
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    const units = ['month', 'day', 'hour', 'minute', 'second']
    const unitMax = [12, 31, 24, 60, 60]

    let values = units.map((u, i) => deadline.diff(curTime, u) % unitMax[i])
    values = values.map(val => (val < 0 ? 0 : val))

    let maxUnit = 4
    let curUnit = 0

    while (curUnit < maxUnit) {
        if (values[curUnit] > 0) {
            maxUnit = curUnit
        } else {
            curUnit += 1
        }
    }
    const renderTimeSlot = (value, unit) => {
        return (
            <Box style={{ display: 'flex', flexDirection: 'column' }} key={unit}>
                <Typography align="center" style={{ fontSize: 24, fontWeight: 'bold', lineHeight: 1 }}>
                    {value}
                </Typography>
                <Typography align="center" style={{ fontSize: 12, lineHeight: 1 }}>
                    {`${unit}${value !== 1 ? 's' : ''}`}
                </Typography>
            </Box>
        )
    }

    return (
        <Box style={{ display: 'flex', flexDirection: 'row', gap: 32, justifyContent: 'center' }}>
            {values.map((val, i) => (
                <>{i >= maxUnit && renderTimeSlot(val, units[i])}</>
            ))}
        </Box>
    )
}

export default TimeLeft
