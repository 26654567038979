import React from 'react'

import app from 'firebase/app'

import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import {
    CalendarToday as CalendarIcon,
    ContactsOutlined as MembersIcon,
    DeleteOutlined as DeleteIcon,
    ExpandLess as CloseIcon,
    ExpandMore as ExpandIcon,
    PollOutlined as PollsIcon,
    SettingsOutlined as SettingsIcon,
    Timeline as PointsIcon,
} from '@material-ui/icons'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'

import NavigationBar from 'components/NavigationBar'
import Title from 'components/Title'
import Widget from 'components/Widget'

import { visibilityColorOptions, visibilityNameOptions } from 'data/Visibility'

export default function ViewChapter(props) {
    var theme = useTheme()

    const chapterId = props.match.params.id

    const [chapter, setChapter] = React.useState(null)
    const [chapterMembers, setChapterMembers] = React.useState(null)
    const [hasTried, setHasTried] = React.useState(false)
    if (!hasTried) {
        setHasTried(true)
        grabChapter()
    }

    const getChapterData = React.useCallback(() => {
        if (chapter) {
            let chapterKeys = Object.keys(chapter).sort((a, b) => a.localeCompare(b))

            let chapterData = {}

            for (let i = 0; i < chapterKeys.length; i++) {
                const chapterKey = chapterKeys[i]
                chapterData[chapterKey] = chapter[chapterKey]
            }

            return chapterData
        }

        return null
    }, [chapter])

    const chapterData = getChapterData()

    function grabChapter() {
        const db = app.firestore()
        db.collection('chapters')
            .doc(chapterId)
            .get()
            .then(function(doc) {
                let data = doc.data()
                let mems = []
                let memKeys = Object.keys(data.members)

                for (let i = 0; i < memKeys.length; i++) {
                    let key = memKeys[i]
                    let mem = data.members[key]
                    mem.id = key
                    mems.push(mem)
                }

                setChapterMembers(mems)
                setChapter(data)

                console.log(data)
            })
            .catch(function(error) {
                console.log('Error getting document: ', error)
            })
    }

    function getMemberData() {
        let statuses = {}

        for (let i = 0; i < chapterMembers.length; i++) {
            let mem = chapterMembers[i]

            if (mem.status in statuses) {
                let s = statuses[mem.status]
                s.value += 1
                statuses[mem.status] = s
            } else {
                let status = { name: visibilityNameOptions[mem.status], value: 1, color: visibilityColorOptions[mem.status] }
                statuses[mem.status] = status
            }
        }

        let arr = []
        let keys = Object.keys(statuses)

        for (let i = 0; i < keys.length; i++) {
            arr.push({ ...statuses[keys[i]], id: keys[i] })
        }

        return arr
    }

    function getRolesData() {
        let roles = {}

        for (let i = 0; i < chapterMembers.length; i++) {
            let mem = chapterMembers[i]

            if (mem.role in roles) {
                let s = roles[mem.role]
                s.value += 1
                roles[mem.role] = s
            } else {
                let role = {
                    name: mem.role in chapter.roles ? chapter.roles[mem.role].title : mem.role,
                    value: 1,
                    color: visibilityColorOptions[Object.keys(roles).length % visibilityColorOptions.length],
                    default: mem.role in chapter.roles ? chapter.roles[mem.role].default : false,
                }
                roles[mem.role] = role
            }
        }

        let arr = []
        let keys = Object.keys(roles)

        for (let i = 0; i < keys.length; i++) {
            arr.push({ ...roles[keys[i]], id: keys[i] })
        }

        return arr
    }

    const [expandedItems, setExpandedItems] = React.useState([])

    const renderItem = (key, val) => {
        if (typeof val === 'object' && !Array.isArray(val)) {
            if (expandedItems.includes(key)) {
                let vals = Object.keys(val)
                return (
                    <>
                        <Typography key={key}>
                            {key}
                            {
                                <IconButton size="small" onClick={() => setExpandedItems(items => items.filter(item => item !== key))}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        </Typography>
                        <Box style={{ marginLeft: 8 }}>{vals.map(v => renderItem(v, val[v]))}</Box>
                    </>
                )
            }
            return (
                <Typography key={key}>
                    {key} - {'{...}'}
                    {
                        <IconButton size="small" onClick={() => setExpandedItems(items => [...items, key])}>
                            <ExpandIcon />
                        </IconButton>
                    }
                </Typography>
            )
        }

        return (
            <Typography key={key}>
                {key} - {JSON.stringify(val)}
            </Typography>
        )
    }

    const getRenderedItems = React.useCallback(() => {
        return renderItem('data', chapterData)
    }, [chapterData, expandedItems])

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'Chapters',
                            link: '/app/gc/chapters',
                        },
                        {
                            name: 'View',
                        },
                    ]}
                    rightButtons={
                        chapter && chapter.settings && (chapter.settings.tier === 'lite' || chapter.settings.tier === 'base')
                            ? [
                                  {
                                      name: 'Delete',
                                      type: 'icon',
                                      innerIcon: <DeleteIcon />,
                                      onClick: () => {
                                          let confirmAction = window.confirm('Are you sure you want to delete this chapter? (cannot be undone)')
                                          if (confirmAction) {
                                              const db = app.firestore()
                                              db.collection('chapters')
                                                  .doc(chapterId)
                                                  .delete()
                                                  .then(function(doc) {
                                                      if (props.history) {
                                                          props.history.goBack()
                                                      }
                                                  })
                                          }
                                      },
                                  },
                              ]
                            : []
                    }
                    grid
                />
                <Grid item xs={12}>
                    <Widget disableWidgetMenu inheritHeight>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 700,
                                        margin: '0 auto',
                                    }}
                                    align="center"
                                >
                                    {chapter ? `${chapter.fullName} (${chapter.letters})` : 'Loading...'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center">Test</Typography>
                            </Grid>
                            <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                                <Grid
                                    item
                                    style={{
                                        height: 24,
                                    }}
                                >
                                    <SettingsIcon />
                                </Grid>

                                <Grid item>{getRenderedItems()}</Grid>
                            </Grid>
                        </Grid>
                    </Widget>
                </Grid>
                {chapter && (
                    <>
                        <Grid item md={6} xs={12}>
                            <Widget disableWidgetMenu inheritHeight noPadding>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" weight="bold" style={{ textAlign: 'center' }}>
                                            Members
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: 6,
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                position: 'absolute',
                                            }}
                                            weight="bold"
                                        >
                                            {chapterMembers.length}
                                        </Typography>
                                        <ResponsiveContainer width="100%" height={88}>
                                            <PieChart>
                                                <Pie data={getMemberData()} innerRadius={30} outerRadius={40} dataKey="value">
                                                    {getMemberData().map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={theme.palette[entry.color].main} stroke={''} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{ textAlign: 'center' }}>
                                            {getMemberData().map((entry, index) => (
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        margin: '0 4px',
                                                    }}
                                                    key={`key-${index}`}
                                                >
                                                    {' '}
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            color: theme.palette[entry.color].main,
                                                            marginRight: 2,
                                                        }}
                                                    >
                                                        •
                                                    </span>
                                                    {`${entry.name} (${entry.value})`}
                                                </span>
                                            ))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Widget>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Widget disableWidgetMenu inheritHeight noPadding>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" weight="bold" style={{ textAlign: 'center' }}>
                                            Roles
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: 6,
                                        }}
                                    >
                                        <ResponsiveContainer width="100%" height={88}>
                                            <PieChart>
                                                <Pie data={getRolesData()} innerRadius={0} outerRadius={40} dataKey="value">
                                                    {getRolesData().map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={theme.palette[entry.color].main} stroke={''} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{ textAlign: 'center' }}>
                                            {getRolesData().map((entry, index) => (
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        margin: '0 4px',
                                                    }}
                                                    key={`key-${index}`}
                                                >
                                                    {' '}
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            color: theme.palette[entry.color].main,
                                                            marginRight: 2,
                                                        }}
                                                    >
                                                        •
                                                    </span>
                                                    {`${entry.name} (${entry.value})${entry.default ? ' (DEFAULT)' : ''}`}
                                                </span>
                                            ))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Widget>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Title
                                title="Calendar"
                                icon={<CalendarIcon />}
                                variant="white"
                                color="blue"
                                direction="column"
                                fullWidth
                                onClick={() => props.history.push('/app/applications/members/')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Title
                                title="Members"
                                icon={<MembersIcon />}
                                variant="white"
                                color="bluegray"
                                direction="column"
                                fullWidth
                                onClick={() => props.history.push(`/app/gc/chapters/${chapterId}/member-management`)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Title
                                title="Points"
                                icon={<PointsIcon />}
                                variant="white"
                                color="green"
                                direction="column"
                                fullWidth
                                onClick={() => props.history.push('/app/applications/points/')}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Title
                                title="Polls"
                                icon={<PollsIcon />}
                                variant="white"
                                color="purple"
                                direction="column"
                                fullWidth
                                onClick={() => props.history.push('/app/applications/polls/')}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}
