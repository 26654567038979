import React from 'react'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { getAppName } from 'code/App'

// styles
import useStyles from './styles'

import { Box } from '@material-ui/core'

import logo from '../login/assets/logo.png'

//components
import { Button, Typography } from 'components/Wrappers'
import { FraternityContext } from 'context/FraternityContext'

export default function Error() {
    var classes = useStyles()
    let { fraternity } = React.useContext(FraternityContext)

    return (
        <Box className={classes.container}>
            <Box className={classes.logoContainer}>
                <img src={logo} alt="" className={classes.logo} />
                <Typography variant="h1" className={classes.logoText}>
                    {getAppName(fraternity)}
                </Typography>
            </Box>
            <Box className={classes.formContainer}>
                <Box className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2" className={classes.formHeader}>
                                404
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="center">Oops. Looks like the page you're looking for no longer exists</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography align="center">But we're here to bring you back to safety</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Button component={Link} to="/" variant="contained" color="primary" size="large" style={{ margin: '0 auto' }}>
                                Back to Home
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}
