export default {
    type: 'Feature',
    properties: { name: 'Midwest' },
    geometry: {
        type: 'MultiPolygon',
        coordinates: [
            [
                [
                    [-104.057698, 44.997431],
                    [-104.055963, 44.768236],
                    [-104.055963, 44.767962],
                    [-104.055934, 44.72372],
                    [-104.05587, 44.723422],
                    [-104.055777, 44.700466],
                    [-104.055938, 44.693881],
                    [-104.05581, 44.691343],
                    [-104.055877, 44.571016],
                    [-104.055892, 44.543341],
                    [-104.055927, 44.51773],
                    [-104.054487, 44.180381],
                    [-104.054562, 44.141081],
                    [-104.05495, 43.93809],
                    [-104.055077, 43.936535],
                    [-104.055104, 43.853478],
                    [-104.055138, 43.750421],
                    [-104.055133, 43.747105],
                    [-104.054902, 43.583852],
                    [-104.054885, 43.583512],
                    [-104.05484, 43.579368],
                    [-104.055032, 43.558603],
                    [-104.054787, 43.503328],
                    [-104.054786, 43.503072],
                    [-104.054779, 43.477815],
                    [-104.054766, 43.428914],
                    [-104.054614, 43.390949],
                    [-104.054403, 43.325914],
                    [-104.054218, 43.30437],
                    [-104.053884, 43.297047],
                    [-104.053876, 43.289801],
                    [-104.053127, 43.000585],
                    [-104.052863, 42.754569],
                    [-104.052809, 42.749966],
                    [-104.052583, 42.650062],
                    [-104.052741, 42.633982],
                    [-104.052586, 42.630917],
                    [-104.052773, 42.611766],
                    [-104.052775, 42.61159],
                    [-104.052775, 42.610813],
                    [-104.052776, 42.25822],
                    [-104.052793, 42.249962],
                    [-104.052761, 42.170278],
                    [-104.052547, 42.166801],
                    [-104.053001, 42.137254],
                    [-104.052738, 42.133769],
                    [-104.0526, 42.124963],
                    [-104.052954, 42.089077],
                    [-104.052967, 42.075004],
                    [-104.05288, 42.021761],
                    [-104.052729, 42.016318],
                    [-104.052704, 42.001718],
                    [-104.052699, 41.998673],
                    [-104.052761, 41.994967],
                    [-104.05283, 41.9946],
                    [-104.052856, 41.975958],
                    [-104.052734, 41.973007],
                    [-104.052991, 41.914973],
                    [-104.052931, 41.906143],
                    [-104.053026, 41.885464],
                    [-104.052774, 41.733401],
                    [-104.05283, 41.697954],
                    [-104.052913, 41.64519],
                    [-104.052945, 41.638167],
                    [-104.052975, 41.622931],
                    [-104.052735, 41.613676],
                    [-104.052859, 41.592254],
                    [-104.05254, 41.564274],
                    [-104.052531, 41.552723],
                    [-104.052584, 41.55265],
                    [-104.052692, 41.541154],
                    [-104.052686, 41.539111],
                    [-104.052476, 41.522343],
                    [-104.052478, 41.515754],
                    [-104.05234, 41.417865],
                    [-104.05216, 41.407662],
                    [-104.052287, 41.393307],
                    [-104.052287, 41.393214],
                    [-104.052324, 41.321144],
                    [-104.052476, 41.320961],
                    [-104.052568, 41.316202],
                    [-104.052453, 41.278202],
                    [-104.052574, 41.278019],
                    [-104.052666, 41.275251],
                    [-104.053142, 41.114457],
                    [-104.053083, 41.104985],
                    [-104.053025, 41.090274],
                    [-104.053177, 41.089725],
                    [-104.053097, 41.018045],
                    [-104.053158, 41.016809],
                    [-104.053249, 41.001406],
                    [-104.039238, 41.001502],
                    [-104.023383, 41.001887],
                    [-104.018223, 41.001617],
                    [-103.972642, 41.001615],
                    [-103.971373, 41.001524],
                    [-103.953525, 41.001596],
                    [-103.896207, 41.00175],
                    [-103.877967, 41.001673],
                    [-103.858449, 41.001681],
                    [-103.574522, 41.001721],
                    [-103.497447, 41.001635],
                    [-103.486697, 41.001914],
                    [-103.421975, 41.002007],
                    [-103.421925, 41.001969],
                    [-103.382493, 41.001883],
                    [-103.365314, 41.001846],
                    [-103.362979, 41.001844],
                    [-103.077804, 41.002298],
                    [-103.076536, 41.002253],
                    [-103.059538, 41.002368],
                    [-103.057998, 41.002368],
                    [-103.043444, 41.002344],
                    [-103.038704, 41.002251],
                    [-103.002026, 41.002486],
                    [-103.000102, 41.0024],
                    [-102.98269, 41.002157],
                    [-102.981483, 41.002112],
                    [-102.963669, 41.002186],
                    [-102.962522, 41.002072],
                    [-102.960706, 41.002059],
                    [-102.959624, 41.002095],
                    [-102.94483, 41.002303],
                    [-102.943109, 41.002051],
                    [-102.925568, 41.00228],
                    [-102.924029, 41.002142],
                    [-102.906547, 41.002276],
                    [-102.904796, 41.002207],
                    [-102.887407, 41.002178],
                    [-102.885746, 41.002131],
                    [-102.867822, 41.002183],
                    [-102.865784, 41.001988],
                    [-102.849263, 41.002301],
                    [-102.846455, 41.002256],
                    [-102.830303, 41.002351],
                    [-102.82728, 41.002143],
                    [-102.773546, 41.002414],
                    [-102.766723, 41.002275],
                    [-102.754617, 41.002361],
                    [-102.739624, 41.00223],
                    [-102.653463, 41.002332],
                    [-102.621033, 41.002597],
                    [-102.578696, 41.002291],
                    [-102.575738, 41.002268],
                    [-102.575496, 41.0022],
                    [-102.566048, 41.0022],
                    [-102.556789, 41.002219],
                    [-102.487955, 41.002445],
                    [-102.470537, 41.002382],
                    [-102.469223, 41.002424],
                    [-102.379593, 41.002301],
                    [-102.364066, 41.002174],
                    [-102.292833, 41.002207],
                    [-102.292622, 41.00223],
                    [-102.292553, 41.002207],
                    [-102.291354, 41.002207],
                    [-102.2721, 41.002245],
                    [-102.267812, 41.002383],
                    [-102.231931, 41.002327],
                    [-102.2122, 41.002462],
                    [-102.209361, 41.002442],
                    [-102.19121, 41.002326],
                    [-102.124972, 41.002338],
                    [-102.070598, 41.002423],
                    [-102.051718, 41.002377],
                    [-102.051614, 41.002377],
                    [-102.051292, 40.749591],
                    [-102.051292, 40.749586],
                    [-102.051398, 40.697542],
                    [-102.051725, 40.537839],
                    [-102.051519, 40.520094],
                    [-102.051465, 40.440008],
                    [-102.05184, 40.396396],
                    [-102.051572, 40.39308],
                    [-102.051669, 40.349213],
                    [-102.051922, 40.235344],
                    [-102.051894, 40.229193],
                    [-102.051909, 40.162674],
                    [-102.052001, 40.148359],
                    [-102.051744, 40.003078],
                    [-102.051569, 39.849805],
                    [-102.051363, 39.843471],
                    [-102.051318, 39.833311],
                    [-102.051254, 39.818992],
                    [-102.050594, 39.675594],
                    [-102.049954, 39.592331],
                    [-102.049806, 39.574058],
                    [-102.049764, 39.56818],
                    [-102.049554, 39.538932],
                    [-102.049673, 39.536691],
                    [-102.049679, 39.506183],
                    [-102.049369, 39.423333],
                    [-102.04937, 39.41821],
                    [-102.049167, 39.403597],
                    [-102.04896, 39.373712],
                    [-102.048449, 39.303138],
                    [-102.04725, 39.13702],
                    [-102.047189, 39.133147],
                    [-102.047134, 39.129701],
                    [-102.046571, 39.047038],
                    [-102.045388, 38.813392],
                    [-102.045334, 38.799463],
                    [-102.045448, 38.783453],
                    [-102.045371, 38.770064],
                    [-102.045287, 38.755528],
                    [-102.045375, 38.754339],
                    [-102.045212, 38.697567],
                    [-102.045156, 38.688555],
                    [-102.045127, 38.686725],
                    [-102.04516, 38.675221],
                    [-102.045102, 38.674946],
                    [-102.045074, 38.669617],
                    [-102.045288, 38.615249],
                    [-102.045288, 38.615168],
                    [-102.045211, 38.581609],
                    [-102.045189, 38.558732],
                    [-102.045223, 38.543797],
                    [-102.045112, 38.523784],
                    [-102.045262, 38.505532],
                    [-102.045263, 38.505395],
                    [-102.044944, 38.384419],
                    [-102.044613, 38.312324],
                    [-102.044568, 38.268819],
                    [-102.044567, 38.268749],
                    [-102.04451, 38.262412],
                    [-102.044398, 38.250015],
                    [-102.043844, 37.928102],
                    [-102.043845, 37.926135],
                    [-102.043219, 37.867929],
                    [-102.043033, 37.824146],
                    [-102.042953, 37.803535],
                    [-102.042668, 37.788758],
                    [-102.042158, 37.760164],
                    [-102.04199, 37.738541],
                    [-102.041876, 37.723875],
                    [-102.041574, 37.680436],
                    [-102.041694, 37.665681],
                    [-102.041582, 37.654495],
                    [-102.041585, 37.644282],
                    [-102.041618, 37.607868],
                    [-102.041894, 37.557977],
                    [-102.041899, 37.541186],
                    [-102.042016, 37.535261],
                    [-102.041786, 37.506066],
                    [-102.041801, 37.469488],
                    [-102.041755, 37.434855],
                    [-102.041669, 37.43474],
                    [-102.041676, 37.409898],
                    [-102.041826, 37.389191],
                    [-102.042089, 37.352819],
                    [-102.041974, 37.352613],
                    [-102.041817, 37.30949],
                    [-102.041664, 37.29765],
                    [-102.041963, 37.258164],
                    [-102.042002, 37.141744],
                    [-102.042135, 37.125021],
                    [-102.042092, 37.125021],
                    [-102.041809, 37.111973],
                    [-102.041983, 37.106551],
                    [-102.04192, 37.035083],
                    [-102.041749, 37.034397],
                    [-102.041921, 37.032178],
                    [-102.04195, 37.030805],
                    [-102.041952, 37.024742],
                    [-102.04224, 36.993083],
                    [-102.028207, 36.993125],
                    [-102.028204, 36.993125],
                    [-102.000447, 36.993272],
                    [-102.000447, 36.993249],
                    [-101.90244, 36.993702],
                    [-101.601593, 36.995095],
                    [-101.600396, 36.995153],
                    [-101.55526, 36.995414],
                    [-101.555239, 36.995414],
                    [-101.519066, 36.995546],
                    [-101.485326, 36.995611],
                    [-101.415005, 36.995966],
                    [-101.413868, 36.996008],
                    [-101.37818, 36.996164],
                    [-101.359674, 36.996232],
                    [-101.357797, 36.996271],
                    [-101.212909, 36.997044],
                    [-101.211486, 36.997124],
                    [-101.066742, 36.997921],
                    [-101.053589, 36.997967],
                    [-101.012641, 36.998176],
                    [-100.996502, 36.998044],
                    [-100.945566, 36.998152],
                    [-100.945469, 36.998153],
                    [-100.904588, 36.998561],
                    [-100.904274, 36.998745],
                    [-100.89166, 36.998604],
                    [-100.855634, 36.998626],
                    [-100.814277, 36.999085],
                    [-100.806116, 36.999091],
                    [-100.765484, 36.999177],
                    [-100.756894, 36.999357],
                    [-100.675552, 36.999688],
                    [-100.633327, 36.999936],
                    [-100.633323, 36.999936],
                    [-100.62977, 37.000025],
                    [-100.591413, 37.000399],
                    [-100.591328, 37.000376],
                    [-100.552683, 37.000735],
                    [-100.551598, 37.00062],
                    [-100.201676, 37.002081],
                    [-100.193754, 37.002133],
                    [-100.192371, 37.002036],
                    [-100.187547, 37.002082],
                    [-100.115722, 37.002206],
                    [-100.089484, 37.002092],
                    [-100.005706, 37.001726],
                    [-100.002563, 37.001706],
                    [-100.001286, 37.001699],
                    [-99.995201, 37.001631],
                    [-99.786016, 37.000931],
                    [-99.774816, 37.000841],
                    [-99.774255, 37.000837],
                    [-99.657658, 37.000197],
                    [-99.625399, 36.999671],
                    [-99.558068, 36.999528],
                    [-99.541116, 36.999573],
                    [-99.508574, 36.999658],
                    [-99.504093, 36.999648],
                    [-99.502665, 36.999645],
                    [-99.500395, 36.999637],
                    [-99.500395, 36.999576],
                    [-99.484333, 36.999626],
                    [-99.456203, 36.999471],
                    [-99.277506, 36.999579],
                    [-99.24812, 36.999565],
                    [-99.129449, 36.999422],
                    [-99.124883, 36.99942],
                    [-99.029337, 36.999595],
                    [-99.000303, 36.99951],
                    [-98.994371, 36.999493],
                    [-98.88058, 36.999309],
                    [-98.880009, 36.999263],
                    [-98.869449, 36.999286],
                    [-98.797452, 36.999229],
                    [-98.793711, 36.999227],
                    [-98.791936, 36.999255],
                    [-98.761597, 36.999425],
                    [-98.718465, 36.99918],
                    [-98.714512, 36.99906],
                    [-98.544872, 36.998997],
                    [-98.54466, 36.998996],
                    [-98.420209, 36.998516],
                    [-98.418268, 36.998538],
                    [-98.408991, 36.998513],
                    [-98.354073, 36.997961],
                    [-98.347149, 36.997962],
                    [-98.346188, 36.997962],
                    [-98.237712, 36.997972],
                    [-98.219499, 36.997824],
                    [-98.208218, 36.997997],
                    [-98.177596, 36.998009],
                    [-98.147452, 36.998162],
                    [-98.111985, 36.998133],
                    [-98.045342, 36.998327],
                    [-98.03989, 36.998349],
                    [-98.033955, 36.998366],
                    [-97.802313, 36.998713],
                    [-97.802298, 36.998713],
                    [-97.783489, 36.998847],
                    [-97.783432, 36.998961],
                    [-97.768704, 36.99875],
                    [-97.697104, 36.998826],
                    [-97.650466, 36.999004],
                    [-97.637137, 36.99909],
                    [-97.606549, 36.998682],
                    [-97.564536, 36.998711],
                    [-97.546498, 36.998747],
                    [-97.5459, 36.998709],
                    [-97.527292, 36.99875],
                    [-97.472861, 36.998721],
                    [-97.462346, 36.998685],
                    [-97.46228, 36.998685],
                    [-97.384925, 36.998843],
                    [-97.372421, 36.998861],
                    [-97.147721, 36.999111],
                    [-97.122597, 36.999036],
                    [-97.120285, 36.999014],
                    [-97.104276, 36.99902],
                    [-97.100652, 36.998998],
                    [-97.039784, 36.999],
                    [-97.030082, 36.998929],
                    [-96.975562, 36.999019],
                    [-96.967371, 36.999067],
                    [-96.934642, 36.99907],
                    [-96.921915, 36.999151],
                    [-96.917093, 36.999182],
                    [-96.90351, 36.999132],
                    [-96.902083, 36.999155],
                    [-96.87629, 36.999233],
                    [-96.867517, 36.999217],
                    [-96.822791, 36.999182],
                    [-96.795199, 36.99886],
                    [-96.79206, 36.99918],
                    [-96.749838, 36.998988],
                    [-96.74127, 36.999239],
                    [-96.73659, 36.999286],
                    [-96.710482, 36.999271],
                    [-96.705431, 36.999203],
                    [-96.525493, 36.999147],
                    [-96.415412, 36.999113],
                    [-96.394272, 36.999221],
                    [-96.279079, 36.999272],
                    [-96.276368, 36.999271],
                    [-96.217571, 36.99907],
                    [-96.200028, 36.999028],
                    [-96.184768, 36.999211],
                    [-96.154017, 36.999161],
                    [-96.152384, 36.999051],
                    [-96.149709, 36.99904],
                    [-96.147143, 36.999022],
                    [-96.143207, 36.999134],
                    [-96.14121, 36.998973],
                    [-96.00081, 36.99886],
                    [-95.96427, 36.999094],
                    [-95.936992, 36.999268],
                    [-95.928122, 36.999245],
                    [-95.91018, 36.999336],
                    [-95.877151, 36.999304],
                    [-95.875257, 36.999302],
                    [-95.873944, 36.9993],
                    [-95.866899, 36.999261],
                    [-95.80798, 36.999124],
                    [-95.786762, 36.99931],
                    [-95.768719, 36.999205],
                    [-95.759905, 36.999271],
                    [-95.741908, 36.999244],
                    [-95.718054, 36.999255],
                    [-95.714887, 36.999279],
                    [-95.71038, 36.999371],
                    [-95.696659, 36.999215],
                    [-95.686452, 36.999349],
                    [-95.664301, 36.999322],
                    [-95.630079, 36.99932],
                    [-95.62435, 36.99936],
                    [-95.615934, 36.999365],
                    [-95.61214, 36.999321],
                    [-95.573598, 36.99931],
                    [-95.534401, 36.999332],
                    [-95.522415, 36.999281],
                    [-95.511578, 36.999235],
                    [-95.407683, 36.999241],
                    [-95.407572, 36.999241],
                    [-95.33121, 36.99938],
                    [-95.328327, 36.999366],
                    [-95.328058, 36.999365],
                    [-95.322565, 36.999358],
                    [-95.195307, 36.999565],
                    [-95.177301, 36.99952],
                    [-95.155372, 36.99954],
                    [-95.155187, 36.999539],
                    [-95.073509, 36.999509],
                    [-95.073504, 36.999509],
                    [-95.049499, 36.99958],
                    [-95.037857, 36.999497],
                    [-95.030324, 36.999517],
                    [-95.011433, 36.999535],
                    [-95.00762, 36.999514],
                    [-94.995293, 36.999529],
                    [-94.853197, 36.998874],
                    [-94.849801, 36.998876],
                    [-94.840926, 36.998833],
                    [-94.83128, 36.998812],
                    [-94.777257, 36.998764],
                    [-94.739324, 36.998687],
                    [-94.737183, 36.998665],
                    [-94.71277, 36.998794],
                    [-94.701797, 36.998814],
                    [-94.699735, 36.998805],
                    [-94.61808, 36.998135],
                    [-94.618049, 36.996208],
                    [-94.618031, 36.994704],
                    [-94.618026, 36.950158],
                    [-94.618109, 36.946564],
                    [-94.618166, 36.937584],
                    [-94.618295, 36.929647],
                    [-94.618207, 36.926236],
                    [-94.618282, 36.911472],
                    [-94.618243, 36.897027],
                    [-94.618658, 36.880064],
                    [-94.61838, 36.84732],
                    [-94.618307, 36.76656],
                    [-94.61813, 36.701423],
                    [-94.618025, 36.66943],
                    [-94.618019, 36.667921],
                    [-94.617815, 36.612604],
                    [-94.617865, 36.606851],
                    [-94.617853, 36.59912],
                    [-94.617814, 36.577732],
                    [-94.617897, 36.536983],
                    [-94.617868, 36.53609],
                    [-94.617997, 36.53428],
                    [-94.617883, 36.517799],
                    [-94.617877, 36.514999],
                    [-94.617919, 36.499414],
                    [-94.55929, 36.499496],
                    [-94.519478, 36.499214],
                    [-94.111473, 36.498597],
                    [-94.110673, 36.498587],
                    [-94.100252, 36.49867],
                    [-94.098588, 36.498676],
                    [-94.077089, 36.49873],
                    [-93.96392, 36.498717],
                    [-93.95919, 36.498717],
                    [-93.92184, 36.498718],
                    [-93.906128, 36.498718],
                    [-93.866758, 36.498789],
                    [-93.728022, 36.499037],
                    [-93.727552, 36.499055],
                    [-93.718893, 36.499178],
                    [-93.709956, 36.499179],
                    [-93.700171, 36.499135],
                    [-93.584282, 36.498896],
                    [-93.584281, 36.498896],
                    [-93.514512, 36.498881],
                    [-93.507408, 36.498911],
                    [-93.426989, 36.498585],
                    [-93.396079, 36.498669],
                    [-93.394718, 36.498519],
                    [-93.315337, 36.498408],
                    [-93.315324, 36.498408],
                    [-93.296117, 36.498389],
                    [-93.088988, 36.498184],
                    [-93.087635, 36.498239],
                    [-93.069512, 36.498242],
                    [-93.068455, 36.49825],
                    [-93.013742, 36.49813],
                    [-92.894336, 36.497867],
                    [-92.894001, 36.49785],
                    [-92.854049, 36.497983],
                    [-92.838876, 36.498033],
                    [-92.838621, 36.498079],
                    [-92.772341, 36.497772],
                    [-92.772333, 36.497772],
                    [-92.529145, 36.497739],
                    [-92.318415, 36.497711],
                    [-92.309424, 36.497894],
                    [-92.216412, 36.498417],
                    [-92.214143, 36.498372],
                    [-92.211449, 36.498395],
                    [-92.199396, 36.498351],
                    [-92.150295, 36.498634],
                    [-92.137741, 36.498706],
                    [-92.120415, 36.498863],
                    [-92.120306, 36.498864],
                    [-92.098356, 36.498803],
                    [-92.074934, 36.498761],
                    [-92.057178, 36.49867],
                    [-92.055789, 36.49867],
                    [-92.028847, 36.498642],
                    [-92.019375, 36.498524],
                    [-91.988751, 36.498498],
                    [-91.985802, 36.498431],
                    [-91.865995, 36.498783],
                    [-91.864385, 36.498789],
                    [-91.805981, 36.498987],
                    [-91.80204, 36.498963],
                    [-91.7995, 36.498952],
                    [-91.784713, 36.499074],
                    [-91.766111, 36.499114],
                    [-91.726663, 36.499209],
                    [-91.687615, 36.499397],
                    [-91.686026, 36.499374],
                    [-91.672343, 36.499463],
                    [-91.64259, 36.499335],
                    [-91.631439, 36.499198],
                    [-91.601317, 36.499343],
                    [-91.596213, 36.499162],
                    [-91.549163, 36.499161],
                    [-91.539359, 36.499116],
                    [-91.53687, 36.499156],
                    [-91.529774, 36.499022],
                    [-91.450005, 36.497538],
                    [-91.446284, 36.497469],
                    [-91.436502, 36.497377],
                    [-91.433298, 36.497262],
                    [-91.407261, 36.497123],
                    [-91.407137, 36.497112],
                    [-91.405141, 36.497165],
                    [-91.404915, 36.49712],
                    [-91.227398, 36.497617],
                    [-91.218645, 36.497564],
                    [-91.21736, 36.497511],
                    [-91.126529, 36.497712],
                    [-91.096277, 36.497893],
                    [-91.09588, 36.49787],
                    [-91.017974, 36.498062],
                    [-91.008558, 36.49827],
                    [-90.963063, 36.498418],
                    [-90.960648, 36.498426],
                    [-90.87922, 36.498378],
                    [-90.876867, 36.498423],
                    [-90.876567, 36.498313],
                    [-90.873775, 36.498074],
                    [-90.850434, 36.498548],
                    [-90.784398, 36.498524],
                    [-90.782454, 36.498523],
                    [-90.765672, 36.498494],
                    [-90.711226, 36.498318],
                    [-90.693005, 36.49851],
                    [-90.653246, 36.498488],
                    [-90.648494, 36.498447],
                    [-90.612554, 36.498559],
                    [-90.60545, 36.498459],
                    [-90.5943, 36.498459],
                    [-90.585342, 36.498497],
                    [-90.57618, 36.498446],
                    [-90.576112, 36.498446],
                    [-90.50016, 36.498399],
                    [-90.495027, 36.498371],
                    [-90.494575, 36.498368],
                    [-90.339892, 36.498213],
                    [-90.228943, 36.497771],
                    [-90.220732, 36.497858],
                    [-90.220702, 36.497858],
                    [-90.217323, 36.497797],
                    [-90.193943, 36.497823],
                    [-90.152481, 36.497952],
                    [-90.154409, 36.496832],
                    [-90.156369, 36.487748],
                    [-90.133993, 36.437906],
                    [-90.072897, 36.393007],
                    [-90.064514, 36.382085],
                    [-90.06398, 36.303038],
                    [-90.076301, 36.280708],
                    [-90.083731, 36.272332],
                    [-90.114922, 36.265595],
                    [-90.198735, 36.201382],
                    [-90.220425, 36.184764],
                    [-90.319168, 36.089976],
                    [-90.36443, 36.013625],
                    [-90.368718, 35.995812],
                    [-90.342616, 35.995895],
                    [-90.339434, 35.996033],
                    [-90.292376, 35.996397],
                    [-90.288947, 35.996418],
                    [-90.2888, 35.996419],
                    [-90.158812, 35.997375],
                    [-90.127331, 35.997635],
                    [-90.12635, 35.997596],
                    [-89.972563, 35.998994],
                    [-89.965327, 35.998813],
                    [-89.961075, 35.999135],
                    [-89.959893, 35.99902],
                    [-89.959375, 35.99902],
                    [-89.901183, 35.999365],
                    [-89.896508, 35.999432],
                    [-89.875586, 35.999562],
                    [-89.875085, 35.999578],
                    [-89.87459, 35.999575],
                    [-89.86901, 35.99964],
                    [-89.770255, 36.000524],
                    [-89.769973, 36.000536],
                    [-89.737648, 36.000567],
                    [-89.737564, 36.000522],
                    [-89.733095, 36.000608],
                    [-89.706932, 36.000981],
                    [-89.690306, 36.024835],
                    [-89.687254, 36.034048],
                    [-89.684439, 36.051719],
                    [-89.624235, 36.108626],
                    [-89.601936, 36.11947],
                    [-89.594, 36.12719],
                    [-89.591605, 36.144096],
                    [-89.594397, 36.155457],
                    [-89.607004, 36.171179],
                    [-89.618228, 36.179966],
                    [-89.629452, 36.185382],
                    [-89.699817, 36.248384],
                    [-89.691308, 36.252079],
                    [-89.678046, 36.248284],
                    [-89.602374, 36.238106],
                    [-89.589561, 36.239116],
                    [-89.541621, 36.247891],
                    [-89.534745, 36.252576],
                    [-89.535529, 36.270541],
                    [-89.539487, 36.277368],
                    [-89.544797, 36.280458],
                    [-89.554289, 36.277751],
                    [-89.578492, 36.288317],
                    [-89.611819, 36.309088],
                    [-89.620255, 36.323006],
                    [-89.6198, 36.329546],
                    [-89.615841, 36.336085],
                    [-89.610689, 36.340442],
                    [-89.605668, 36.342234],
                    [-89.581636, 36.342357],
                    [-89.560439, 36.337746],
                    [-89.545006, 36.336809],
                    [-89.531822, 36.339246],
                    [-89.519, 36.3486],
                    [-89.513178, 36.359897],
                    [-89.509558, 36.375065],
                    [-89.519501, 36.475419],
                    [-89.522674, 36.481305],
                    [-89.5391, 36.498201],
                    [-89.560344, 36.525436],
                    [-89.570071, 36.544387],
                    [-89.571509, 36.552569],
                    [-89.566817, 36.564216],
                    [-89.55264, 36.577178],
                    [-89.546113, 36.579989],
                    [-89.527583, 36.581147],
                    [-89.500076, 36.576305],
                    [-89.484836, 36.571821],
                    [-89.479093, 36.568206],
                    [-89.473341, 36.559918],
                    [-89.467761, 36.546847],
                    [-89.465445, 36.536163],
                    [-89.465888, 36.529946],
                    [-89.47246, 36.513741],
                    [-89.485106, 36.497692],
                    [-89.493495, 36.4787],
                    [-89.493198, 36.470124],
                    [-89.486215, 36.46162],
                    [-89.471718, 36.457001],
                    [-89.460436, 36.45814],
                    [-89.448468, 36.46442],
                    [-89.429311, 36.481875],
                    [-89.417293, 36.499033],
                    [-89.400581, 36.538337],
                    [-89.38814, 36.573416],
                    [-89.376367, 36.613868],
                    [-89.365548, 36.625059],
                    [-89.343753, 36.630991],
                    [-89.327578, 36.632127],
                    [-89.326731, 36.632186],
                    [-89.313405, 36.62012],
                    [-89.294637, 36.593729],
                    [-89.27171, 36.571387],
                    [-89.259994, 36.565149],
                    [-89.236542, 36.566824],
                    [-89.227319, 36.569375],
                    [-89.213563, 36.580119],
                    [-89.202607, 36.601576],
                    [-89.200902, 36.618177],
                    [-89.197654, 36.628936],
                    [-89.187749, 36.641115],
                    [-89.174741, 36.650416],
                    [-89.174733, 36.650476],
                    [-89.171882, 36.672526],
                    [-89.19948, 36.716045],
                    [-89.201047, 36.725772],
                    [-89.197808, 36.739412],
                    [-89.184523, 36.753638],
                    [-89.169106, 36.759473],
                    [-89.142313, 36.755369],
                    [-89.130399, 36.751702],
                    [-89.123548, 36.78522],
                    [-89.12353, 36.785309],
                    [-89.179229, 36.812915],
                    [-89.178574, 36.816554],
                    [-89.177177, 36.835779],
                    [-89.147674, 36.847148],
                    [-89.100766, 36.943973],
                    [-89.100794, 36.944006],
                    [-89.132685, 36.9822],
                    [-89.166447, 37.003337],
                    [-89.173595, 37.011409],
                    [-89.178975, 37.020928],
                    [-89.182509, 37.037275],
                    [-89.181369, 37.046305],
                    [-89.175725, 37.062069],
                    [-89.171881, 37.068184],
                    [-89.168087, 37.074218],
                    [-89.086526, 37.165602],
                    [-89.041263, 37.202881],
                    [-89.000968, 37.224401],
                    [-88.98326, 37.228685],
                    [-88.966831, 37.229891],
                    [-88.933399, 37.225007],
                    [-88.933385, 37.225005],
                    [-88.942111, 37.228811],
                    [-88.928021, 37.226281],
                    [-88.916934, 37.224291],
                    [-88.809206, 37.189235],
                    [-88.625889, 37.119458],
                    [-88.589207, 37.099655],
                    [-88.576718, 37.085852],
                    [-88.564152, 37.07844],
                    [-88.560032, 37.07601],
                    [-88.545403, 37.070003],
                    [-88.531576, 37.067192],
                    [-88.514356, 37.065231],
                    [-88.490297, 37.066669],
                    [-88.482856, 37.067114],
                    [-88.458948, 37.073796],
                    [-88.444605, 37.098601],
                    [-88.424403, 37.152428],
                    [-88.447764, 37.203527],
                    [-88.458763, 37.213536],
                    [-88.471753, 37.220155],
                    [-88.509328, 37.26213],
                    [-88.515939, 37.284043],
                    [-88.514661, 37.290948],
                    [-88.476592, 37.386875],
                    [-88.470224, 37.396255],
                    [-88.456, 37.408482],
                    [-88.439333, 37.416416],
                    [-88.414895, 37.423461],
                    [-88.408808, 37.425216],
                    [-88.358436, 37.40486],
                    [-88.330622, 37.429316],
                    [-88.312585, 37.440591],
                    [-88.281667, 37.452596],
                    [-88.255193, 37.456748],
                    [-88.083395, 37.473683],
                    [-88.062562, 37.489385],
                    [-88.061292, 37.505232],
                    [-88.061311, 37.505332],
                    [-88.063311, 37.515755],
                    [-88.072242, 37.528826],
                    [-88.121517, 37.568166],
                    [-88.133235, 37.574154],
                    [-88.133306, 37.574191],
                    [-88.133393, 37.574235],
                    [-88.133416, 37.574277],
                    [-88.139973, 37.586451],
                    [-88.156827, 37.632801],
                    [-88.160187, 37.657592],
                    [-88.158207, 37.664542],
                    [-88.151646, 37.675098],
                    [-88.122412, 37.709685],
                    [-88.072538, 37.733286],
                    [-88.059588, 37.742608],
                    [-88.050969, 37.752599],
                    [-88.045008, 37.762436],
                    [-88.039, 37.775454],
                    [-88.02803, 37.799224],
                    [-88.017314, 37.801911],
                    [-88.004706, 37.800145],
                    [-87.976389, 37.788004],
                    [-87.970869, 37.783456],
                    [-87.943756, 37.776843],
                    [-87.90681, 37.807624],
                    [-87.903804, 37.817762],
                    [-87.907773, 37.837611],
                    [-87.910276, 37.843416],
                    [-87.914892, 37.849618],
                    [-87.927303, 37.858709],
                    [-87.936228, 37.867937],
                    [-87.940005, 37.875044],
                    [-87.940839, 37.883338],
                    [-87.940069, 37.88767],
                    [-87.936784, 37.892587],
                    [-87.927424, 37.902038],
                    [-87.904789, 37.924892],
                    [-87.898062, 37.927514],
                    [-87.883321, 37.926238],
                    [-87.87254, 37.920999],
                    [-87.865558, 37.915056],
                    [-87.857243, 37.900649],
                    [-87.841193, 37.882325],
                    [-87.830578, 37.876516],
                    [-87.7909, 37.875714],
                    [-87.783643, 37.877759],
                    [-87.76226, 37.890906],
                    [-87.700421, 37.900858],
                    [-87.684018, 37.903498],
                    [-87.67573, 37.90193],
                    [-87.666481, 37.895786],
                    [-87.662865, 37.885578],
                    [-87.681633, 37.855917],
                    [-87.6819, 37.84641],
                    [-87.679188, 37.836321],
                    [-87.670889, 37.828429],
                    [-87.645858, 37.825899],
                    [-87.615399, 37.831974],
                    [-87.606599, 37.838669],
                    [-87.591504, 37.856642],
                    [-87.588729, 37.860984],
                    [-87.588426, 37.868791],
                    [-87.591582, 37.887194],
                    [-87.597118, 37.892394],
                    [-87.620272, 37.906922],
                    [-87.626256, 37.916138],
                    [-87.62896, 37.926714],
                    [-87.601416, 37.972542],
                    [-87.592916, 37.975842],
                    [-87.585916, 37.975442],
                    [-87.581115, 37.973442],
                    [-87.574715, 37.967742],
                    [-87.573415, 37.962642],
                    [-87.574287, 37.954842],
                    [-87.57203, 37.947466],
                    [-87.559342, 37.931146],
                    [-87.550333, 37.92489],
                    [-87.511499, 37.906426],
                    [-87.451185, 37.941034],
                    [-87.450458, 37.941451],
                    [-87.436859, 37.944192],
                    [-87.418585, 37.944763],
                    [-87.402632, 37.942267],
                    [-87.380247, 37.935596],
                    [-87.302324, 37.898445],
                    [-87.269561, 37.877155],
                    [-87.26293, 37.872846],
                    [-87.20224, 37.843791],
                    [-87.132621, 37.791008],
                    [-87.128749, 37.785728],
                    [-87.119229, 37.782848],
                    [-87.111133, 37.782512],
                    [-87.090636, 37.787808],
                    [-87.077404, 37.796209],
                    [-87.067836, 37.806065],
                    [-87.057836, 37.827457],
                    [-87.043854, 37.870796],
                    [-87.043407, 37.87994],
                    [-87.046237, 37.889866],
                    [-87.042249, 37.898291],
                    [-87.033444, 37.906593],
                    [-87.010315, 37.919668],
                    [-86.978957, 37.9302],
                    [-86.97883, 37.930214],
                    [-86.919329, 37.936664],
                    [-86.902413, 37.946161],
                    [-86.884961, 37.964373],
                    [-86.85595, 37.987292],
                    [-86.823491, 37.998939],
                    [-86.815267, 37.998877],
                    [-86.814776, 37.998638],
                    [-86.794985, 37.988982],
                    [-86.787695, 37.971449],
                    [-86.770179, 37.940264],
                    [-86.75099, 37.912893],
                    [-86.740877, 37.902568],
                    [-86.73146, 37.89434],
                    [-86.72351, 37.892831],
                    [-86.718462, 37.893123],
                    [-86.709164, 37.89764],
                    [-86.660293, 37.865057],
                    [-86.655296, 37.842508],
                    [-86.655285, 37.842506],
                    [-86.648028, 37.841425],
                    [-86.634271, 37.843845],
                    [-86.604624, 37.858272],
                    [-86.525174, 37.968228],
                    [-86.524969, 38.027879],
                    [-86.521825, 38.038327],
                    [-86.517289, 38.042634],
                    [-86.51176, 38.044448],
                    [-86.490858, 38.045796],
                    [-86.490853, 38.045796],
                    [-86.466876, 38.046597],
                    [-86.452192, 38.05049],
                    [-86.438236, 38.060426],
                    [-86.432789, 38.067171],
                    [-86.430091, 38.078638],
                    [-86.426508, 38.081784],
                    [-86.398661, 38.106238],
                    [-86.332567, 38.130131],
                    [-86.326788, 38.134604],
                    [-86.323453, 38.139032],
                    [-86.314292, 38.14261],
                    [-86.309727, 38.144393],
                    [-86.283964, 38.143185],
                    [-86.271223, 38.130112],
                    [-86.278034, 38.102426],
                    [-86.27872, 38.089303],
                    [-86.273584, 38.067443],
                    [-86.266891, 38.057125],
                    [-86.220371, 38.027922],
                    [-86.178983, 38.011308],
                    [-86.172186, 38.00992],
                    [-86.029509, 37.99264],
                    [-85.998609, 37.997775],
                    [-85.951467, 38.005608],
                    [-85.947952, 38.007083],
                    [-85.941403, 38.009831],
                    [-85.930235, 38.018311],
                    [-85.922395, 38.028679],
                    [-85.911607, 38.06689],
                    [-85.906163, 38.08617],
                    [-85.904564, 38.10027],
                    [-85.905164, 38.11107],
                    [-85.909464, 38.14007],
                    [-85.908764, 38.161169],
                    [-85.89955, 38.180343],
                    [-85.897664, 38.184269],
                    [-85.829364, 38.276769],
                    [-85.823764, 38.280569],
                    [-85.816164, 38.282969],
                    [-85.791575, 38.288566],
                    [-85.791563, 38.288569],
                    [-85.780963, 38.288469],
                    [-85.773363, 38.286169],
                    [-85.765963, 38.280469],
                    [-85.766563, 38.27767],
                    [-85.761062, 38.27257],
                    [-85.750962, 38.26787],
                    [-85.744862, 38.26717],
                    [-85.683561, 38.295469],
                    [-85.675017, 38.301317],
                    [-85.653641, 38.327108],
                    [-85.638777, 38.361443],
                    [-85.638044, 38.38027],
                    [-85.638041, 38.380338],
                    [-85.632937, 38.395666],
                    [-85.620521, 38.423105],
                    [-85.607629, 38.439295],
                    [-85.462518, 38.512602],
                    [-85.433136, 38.523914],
                    [-85.432928, 38.524072],
                    [-85.423077, 38.531581],
                    [-85.417322, 38.540763],
                    [-85.4156, 38.546341],
                    [-85.415821, 38.563558],
                    [-85.427467, 38.586735],
                    [-85.439351, 38.610388],
                    [-85.456978, 38.689135],
                    [-85.452114, 38.709348],
                    [-85.434065, 38.729455],
                    [-85.422021, 38.734834],
                    [-85.410925, 38.73708],
                    [-85.333285, 38.74048],
                    [-85.289226, 38.74241],
                    [-85.275454, 38.741172],
                    [-85.258846, 38.737754],
                    [-85.246505, 38.731821],
                    [-85.226062, 38.705456],
                    [-85.213257, 38.695446],
                    [-85.201498, 38.691575],
                    [-85.189362, 38.687579],
                    [-85.172528, 38.688082],
                    [-85.156158, 38.692251],
                    [-85.13868, 38.699168],
                    [-85.106902, 38.720789],
                    [-85.100963, 38.7268],
                    [-85.024205, 38.763311],
                    [-84.995939, 38.776756],
                    [-84.984627, 38.779072],
                    [-84.973432, 38.778847],
                    [-84.944296, 38.775184],
                    [-84.844412, 38.787078],
                    [-84.81335, 38.79833],
                    [-84.791078, 38.855859],
                    [-84.785799, 38.869496],
                    [-84.785111, 38.879988],
                    [-84.789521, 38.884687],
                    [-84.813182, 38.930583],
                    [-84.849445, 39.000923],
                    [-84.878614, 39.030296],
                    [-84.889065, 39.04082],
                    [-84.897171, 39.052407],
                    [-84.897364, 39.057378],
                    [-84.888873, 39.066376],
                    [-84.831197, 39.10192],
                    [-84.820157, 39.10548],
                    [-84.78768, 39.115297],
                    [-84.766749, 39.138558],
                    [-84.754449, 39.146658],
                    [-84.744149, 39.147458],
                    [-84.732048, 39.144458],
                    [-84.718548, 39.137059],
                    [-84.689747, 39.104159],
                    [-84.684847, 39.100459],
                    [-84.632446, 39.07676],
                    [-84.623732, 39.074427],
                    [-84.620112, 39.073457],
                    [-84.603444, 39.073699],
                    [-84.510057, 39.093593],
                    [-84.509743, 39.09366],
                    [-84.506082, 39.095081],
                    [-84.502062, 39.096641],
                    [-84.493743, 39.10246],
                    [-84.432841, 39.094261],
                    [-84.326539, 39.027463],
                    [-84.319936, 39.022081],
                    [-84.31368, 39.016981],
                    [-84.304698, 39.006455],
                    [-84.234453, 38.893226],
                    [-84.232132, 38.880483],
                    [-84.232478, 38.874638],
                    [-84.233727, 38.853576],
                    [-84.231306, 38.830552],
                    [-84.229954, 38.82765],
                    [-84.2253, 38.817665],
                    [-84.212904, 38.805707],
                    [-84.205592, 38.802588],
                    [-84.071491, 38.770475],
                    [-84.052707, 38.770542],
                    [-84.044486, 38.770572],
                    [-83.978814, 38.787104],
                    [-83.962123, 38.787384],
                    [-83.943978, 38.783616],
                    [-83.903971, 38.76816],
                    [-83.903918, 38.76815],
                    [-83.870443, 38.761608],
                    [-83.859028, 38.756793],
                    [-83.85333, 38.752572],
                    [-83.777823, 38.674453],
                    [-83.773682, 38.661309],
                    [-83.769347, 38.65522],
                    [-83.762445, 38.652103],
                    [-83.706043, 38.637101],
                    [-83.679484, 38.630036],
                    [-83.663911, 38.62793],
                    [-83.655425, 38.629735],
                    [-83.649737, 38.632753],
                    [-83.646701, 38.637489],
                    [-83.642994, 38.643273],
                    [-83.636208, 38.670584],
                    [-83.626922, 38.679387],
                    [-83.615736, 38.684145],
                    [-83.574754, 38.692671],
                    [-83.533339, 38.702105],
                    [-83.520953, 38.703045],
                    [-83.512571, 38.701716],
                    [-83.369077, 38.659334],
                    [-83.333347, 38.641562],
                    [-83.324157, 38.633326],
                    [-83.320531, 38.622713],
                    [-83.2821, 38.602987],
                    [-83.265769, 38.605278],
                    [-83.156926, 38.620547],
                    [-83.142836, 38.625076],
                    [-83.135046, 38.631719],
                    [-83.127652, 38.642451],
                    [-83.030889, 38.72556],
                    [-83.030702, 38.72572],
                    [-83.011816, 38.730057],
                    [-82.979395, 38.725976],
                    [-82.968695, 38.728776],
                    [-82.943147, 38.74328],
                    [-82.923694, 38.750076],
                    [-82.894193, 38.756576],
                    [-82.889193, 38.756076],
                    [-82.879492, 38.751476],
                    [-82.875492, 38.747276],
                    [-82.874466, 38.745346],
                    [-82.871292, 38.739376],
                    [-82.869992, 38.730477],
                    [-82.870392, 38.722077],
                    [-82.873492, 38.710077],
                    [-82.869592, 38.678177],
                    [-82.853554, 38.610926],
                    [-82.847186, 38.595166],
                    [-82.839538, 38.586159],
                    [-82.820161, 38.572703],
                    [-82.816012, 38.570733],
                    [-82.800112, 38.563183],
                    [-82.789776, 38.559951],
                    [-82.730958, 38.559264],
                    [-82.724846, 38.5576],
                    [-82.696621, 38.542112],
                    [-82.690509, 38.536576],
                    [-82.665548, 38.505808],
                    [-82.665485, 38.505734],
                    [-82.593673, 38.421809],
                    [-82.560664, 38.404338],
                    [-82.520178, 38.407582],
                    [-82.50667, 38.410092],
                    [-82.34064, 38.440948],
                    [-82.330335, 38.4445],
                    [-82.323999, 38.449268],
                    [-82.320223, 38.454516],
                    [-82.313935, 38.468084],
                    [-82.295571, 38.539783],
                    [-82.293271, 38.560283],
                    [-82.293871, 38.572683],
                    [-82.291271, 38.578983],
                    [-82.287102, 38.582588],
                    [-82.27427, 38.593683],
                    [-82.26207, 38.598183],
                    [-82.245969, 38.598483],
                    [-82.218967, 38.591683],
                    [-82.193824, 38.593096],
                    [-82.181967, 38.599384],
                    [-82.177267, 38.603784],
                    [-82.175167, 38.608484],
                    [-82.172066, 38.619284],
                    [-82.172066, 38.625984],
                    [-82.182467, 38.708782],
                    [-82.188268, 38.734082],
                    [-82.198882, 38.757725],
                    [-82.220449, 38.773739],
                    [-82.221566, 38.787187],
                    [-82.215759, 38.797462],
                    [-82.191172, 38.815137],
                    [-82.184428, 38.816529],
                    [-82.139279, 38.866407],
                    [-82.098762, 38.958288],
                    [-82.093165, 38.97098],
                    [-82.051563, 38.994378],
                    [-82.045663, 39.003778],
                    [-82.041563, 39.017878],
                    [-82.035963, 39.025478],
                    [-82.017562, 39.030078],
                    [-82.002261, 39.027878],
                    [-81.935619, 38.9895],
                    [-81.919783, 38.968494],
                    [-81.900595, 38.937671],
                    [-81.89847, 38.929603],
                    [-81.90091, 38.924338],
                    [-81.926671, 38.901311],
                    [-81.928352, 38.895371],
                    [-81.926967, 38.891602],
                    [-81.908645, 38.87846],
                    [-81.874857, 38.881174],
                    [-81.759995, 38.925828],
                    [-81.756131, 38.933545],
                    [-81.764253, 39.015279],
                    [-81.772854, 39.026179],
                    [-81.793304, 39.040353],
                    [-81.803355, 39.047678],
                    [-81.811655, 39.059578],
                    [-81.814155, 39.073478],
                    [-81.812355, 39.082078],
                    [-81.747253, 39.095378],
                    [-81.747253, 39.095379],
                    [-81.749689, 39.180742],
                    [-81.749853, 39.186489],
                    [-81.740061, 39.190652],
                    [-81.721551, 39.212448],
                    [-81.695724, 39.242859],
                    [-81.605816, 39.275851],
                    [-81.542346, 39.352874],
                    [-81.467744, 39.403774],
                    [-81.456143, 39.409274],
                    [-81.446543, 39.410374],
                    [-81.435642, 39.408474],
                    [-81.412706, 39.394618],
                    [-81.406689, 39.38809],
                    [-81.393794, 39.351706],
                    [-81.384556, 39.343449],
                    [-81.375961, 39.341697],
                    [-81.371271, 39.342062],
                    [-81.356911, 39.343178],
                    [-81.347567, 39.34577],
                    [-81.295373, 39.37529],
                    [-81.270716, 39.385914],
                    [-81.249088, 39.389992],
                    [-81.223581, 39.386062],
                    [-81.215617, 39.388602],
                    [-81.127696, 39.464879],
                    [-81.063587, 39.520497],
                    [-81.038691, 39.540464],
                    [-81.020055, 39.55541],
                    [-80.943782, 39.606926],
                    [-80.892208, 39.616756],
                    [-80.88036, 39.620706],
                    [-80.831871, 39.705655],
                    [-80.829764, 39.711839],
                    [-80.828618, 39.720848],
                    [-80.812342, 39.848845],
                    [-80.806179, 39.897306],
                    [-80.809619, 39.905626],
                    [-80.808722, 39.91257],
                    [-80.806018, 39.91713],
                    [-80.759455, 39.958233],
                    [-80.743166, 39.969113],
                    [-80.740126, 39.970793],
                    [-80.732051, 40.03324],
                    [-80.731504, 40.037472],
                    [-80.730904, 40.049172],
                    [-80.733104, 40.058772],
                    [-80.726504, 40.089072],
                    [-80.705482, 40.153047],
                    [-80.703052, 40.157231],
                    [-80.685391, 40.187642],
                    [-80.652098, 40.24497],
                    [-80.644598, 40.25127],
                    [-80.637198, 40.25507],
                    [-80.616696, 40.28027],
                    [-80.602895, 40.307069],
                    [-80.599895, 40.317669],
                    [-80.609313, 40.360468],
                    [-80.619196, 40.381768],
                    [-80.617346, 40.396004],
                    [-80.612295, 40.434867],
                    [-80.599194, 40.482566],
                    [-80.610259, 40.490962],
                    [-80.666917, 40.573664],
                    [-80.667957, 40.582496],
                    [-80.665892, 40.587728],
                    [-80.634355, 40.616095],
                    [-80.627171, 40.619936],
                    [-80.601494, 40.625387],
                    [-80.594065, 40.623664],
                    [-80.589041, 40.620128],
                    [-80.56072, 40.62368],
                    [-80.518991, 40.638801],
                    [-80.519058, 40.792298],
                    [-80.518992, 40.801221],
                    [-80.519081, 40.849157],
                    [-80.51902, 40.850073],
                    [-80.519039, 40.851339],
                    [-80.519764, 40.899858],
                    [-80.51979, 40.900761],
                    [-80.519091, 40.921061],
                    [-80.519, 40.98738],
                    [-80.518989, 40.995445],
                    [-80.518927, 41.015387],
                    [-80.51896, 41.061546],
                    [-80.518928, 41.070954],
                    [-80.51896, 41.071866],
                    [-80.518999, 41.075014],
                    [-80.519088, 41.082074],
                    [-80.519092, 41.090658],
                    [-80.519125, 41.100819],
                    [-80.519192, 41.105358],
                    [-80.518992, 41.115958],
                    [-80.519056, 41.125057],
                    [-80.519012, 41.125057],
                    [-80.519012, 41.125116],
                    [-80.519167, 41.133343],
                    [-80.519167, 41.133388],
                    [-80.519115, 41.14552],
                    [-80.519144, 41.171203],
                    [-80.51883, 41.209213],
                    [-80.518893, 41.219356],
                    [-80.518893, 41.232556],
                    [-80.518693, 41.248855],
                    [-80.518893, 41.265155],
                    [-80.518993, 41.268155],
                    [-80.518996, 41.2683],
                    [-80.518794, 41.305509],
                    [-80.519129, 41.312408],
                    [-80.519265, 41.333495],
                    [-80.519281, 41.335958],
                    [-80.519281, 41.337174],
                    [-80.519311, 41.339052],
                    [-80.519293, 41.339054],
                    [-80.519293, 41.339654],
                    [-80.519345, 41.340145],
                    [-80.519345, 41.34074],
                    [-80.519297, 41.350833],
                    [-80.519249, 41.36103],
                    [-80.519217, 41.372006],
                    [-80.519249, 41.378918],
                    [-80.519025, 41.416438],
                    [-80.518993, 41.416437],
                    [-80.518993, 41.435454],
                    [-80.519169, 41.462581],
                    [-80.519209, 41.489013],
                    [-80.519225, 41.499924],
                    [-80.519157, 41.528769],
                    [-80.519339, 41.539297],
                    [-80.519357, 41.669767],
                    [-80.519424, 41.671228],
                    [-80.519373, 41.701473],
                    [-80.519408, 41.739359],
                    [-80.519369, 41.752487],
                    [-80.519239, 41.765138],
                    [-80.519294, 41.849563],
                    [-80.519345, 41.929168],
                    [-80.519304, 41.943992],
                    [-80.519405, 41.976158],
                    [-80.519425, 41.977522],
                    [-80.519425, 41.977523],
                    [-80.519461, 41.977513],
                    [-80.522057, 41.97678],
                    [-80.525521, 41.975803],
                    [-80.529274, 41.974744],
                    [-80.530164, 41.974493],
                    [-80.53139, 41.974147],
                    [-80.533774, 41.973475],
                    [-80.553836, 41.967815],
                    [-80.555898, 41.967065],
                    [-80.560671, 41.965328],
                    [-80.563327, 41.964362],
                    [-80.572028, 41.961196],
                    [-80.5755, 41.959932],
                    [-80.576591, 41.959535],
                    [-80.576735, 41.959483],
                    [-80.579439, 41.958499],
                    [-80.581882, 41.95761],
                    [-80.60861, 41.950325],
                    [-80.619242, 41.947428],
                    [-80.619443, 41.947373],
                    [-80.635576, 41.942976],
                    [-80.693429, 41.927208],
                    [-80.699363, 41.925591],
                    [-80.708293, 41.923157],
                    [-80.712218, 41.922087],
                    [-80.717469, 41.920656],
                    [-80.720816, 41.919744],
                    [-80.727504, 41.918593],
                    [-80.757945, 41.913352],
                    [-80.762176, 41.912173],
                    [-80.766046, 41.911095],
                    [-80.76802, 41.910545],
                    [-80.770756, 41.909782],
                    [-80.771784, 41.909496],
                    [-80.77709, 41.908018],
                    [-80.781112, 41.906897],
                    [-80.782052, 41.906635],
                    [-80.782944, 41.908294],
                    [-80.784682, 41.911525],
                    [-80.785497, 41.911429],
                    [-80.78641, 41.911322],
                    [-80.794388, 41.910386],
                    [-80.798311, 41.909926],
                    [-80.799822, 41.909749],
                    [-80.800794, 41.909635],
                    [-80.801436, 41.908982],
                    [-80.806133, 41.904211],
                    [-80.80801, 41.902304],
                    [-80.808697, 41.901606],
                    [-80.812659, 41.899125],
                    [-80.81324, 41.89876],
                    [-80.814943, 41.897694],
                    [-80.816444, 41.897188],
                    [-80.819165, 41.896271],
                    [-80.826889, 41.893668],
                    [-80.852064, 41.885183],
                    [-80.853542, 41.884685],
                    [-80.900342, 41.868912],
                    [-80.916528, 41.865954],
                    [-80.936244, 41.862352],
                    [-80.958494, 41.859838],
                    [-80.971141, 41.858409],
                    [-80.973674, 41.858122],
                    [-80.991799, 41.856074],
                    [-81.002117, 41.854908],
                    [-81.002663, 41.854846],
                    [-81.01049, 41.853962],
                    [-81.024525, 41.846469],
                    [-81.05192, 41.839557],
                    [-81.092716, 41.822988],
                    [-81.095592, 41.822216],
                    [-81.098931, 41.821319],
                    [-81.112885, 41.817571],
                    [-81.122295, 41.813503],
                    [-81.167638, 41.793903],
                    [-81.183328, 41.78712],
                    [-81.184368, 41.786671],
                    [-81.192684, 41.783389],
                    [-81.201721, 41.779822],
                    [-81.202359, 41.77957],
                    [-81.247607, 41.761712],
                    [-81.247832, 41.761623],
                    [-81.248609, 41.761316],
                    [-81.248672, 41.761291],
                    [-81.251918, 41.760634],
                    [-81.252977, 41.76042],
                    [-81.25552, 41.759905],
                    [-81.259349, 41.75913],
                    [-81.26311, 41.758369],
                    [-81.263751, 41.758239],
                    [-81.264224, 41.758143],
                    [-81.265576, 41.758298],
                    [-81.279187, 41.759859],
                    [-81.279925, 41.759944],
                    [-81.286925, 41.760243],
                    [-81.288892, 41.758945],
                    [-81.301626, 41.750543],
                    [-81.306495, 41.748392],
                    [-81.30701, 41.748164],
                    [-81.309499, 41.747064],
                    [-81.330154, 41.737938],
                    [-81.353229, 41.727743],
                    [-81.367498, 41.719441],
                    [-81.36867, 41.718759],
                    [-81.368913, 41.718618],
                    [-81.372587, 41.71648],
                    [-81.373666, 41.715852],
                    [-81.374454, 41.715393],
                    [-81.374509, 41.715361],
                    [-81.380935, 41.711622],
                    [-81.388269, 41.707355],
                    [-81.388632, 41.707144],
                    [-81.400617, 41.699624],
                    [-81.400686, 41.699581],
                    [-81.402417, 41.698495],
                    [-81.404476, 41.697203],
                    [-81.406098, 41.696185],
                    [-81.407984, 41.695002],
                    [-81.413062, 41.691816],
                    [-81.431441, 41.680285],
                    [-81.435582, 41.677686],
                    [-81.437014, 41.676788],
                    [-81.437809, 41.676289],
                    [-81.438971, 41.67556],
                    [-81.441339, 41.674074],
                    [-81.441803, 41.673783],
                    [-81.442552, 41.673314],
                    [-81.442645, 41.673255],
                    [-81.44272, 41.673178],
                    [-81.442843, 41.673051],
                    [-81.443647, 41.672222],
                    [-81.447734, 41.668011],
                    [-81.452461, 41.663139],
                    [-81.466038, 41.649148],
                    [-81.477544, 41.640086],
                    [-81.486919, 41.632704],
                    [-81.48864, 41.631348],
                    [-81.50044, 41.623448],
                    [-81.509359, 41.620739],
                    [-81.520707, 41.617292],
                    [-81.527026, 41.615373],
                    [-81.529742, 41.614548],
                    [-81.529955, 41.614374],
                    [-81.531612, 41.613023],
                    [-81.531674, 41.612972],
                    [-81.562844, 41.587549],
                    [-81.575577, 41.578267],
                    [-81.575715, 41.578167],
                    [-81.577459, 41.576896],
                    [-81.578666, 41.576016],
                    [-81.579285, 41.575565],
                    [-81.579727, 41.575243],
                    [-81.579746, 41.575228],
                    [-81.579815, 41.575178],
                    [-81.591471, 41.566682],
                    [-81.591981, 41.56631],
                    [-81.593092, 41.5655],
                    [-81.593144, 41.565462],
                    [-81.599747, 41.560649],
                    [-81.60967, 41.55474],
                    [-81.610163, 41.554446],
                    [-81.615816, 41.551079],
                    [-81.616567, 41.550633],
                    [-81.61691, 41.550428],
                    [-81.627431, 41.544163],
                    [-81.633652, 41.540458],
                    [-81.641055, 41.538321],
                    [-81.648453, 41.536185],
                    [-81.649872, 41.535775],
                    [-81.664851, 41.53145],
                    [-81.664884, 41.53143],
                    [-81.688058, 41.517321],
                    [-81.691248, 41.515379],
                    [-81.69325, 41.514161],
                    [-81.706864, 41.505872],
                    [-81.707622, 41.505108],
                    [-81.710953, 41.50175],
                    [-81.710986, 41.501734],
                    [-81.71559, 41.499549],
                    [-81.716836, 41.498957],
                    [-81.71931, 41.497782],
                    [-81.725763, 41.494718],
                    [-81.726429, 41.494402],
                    [-81.727806, 41.493749],
                    [-81.732674, 41.491437],
                    [-81.738755, 41.48855],
                    [-81.741852, 41.487827],
                    [-81.744272, 41.487263],
                    [-81.744755, 41.48715],
                    [-81.745818, 41.487348],
                    [-81.746095, 41.4874],
                    [-81.75861, 41.489736],
                    [-81.762489, 41.49046],
                    [-81.768856, 41.491649],
                    [-81.768898, 41.491663],
                    [-81.782258, 41.49605],
                    [-81.794157, 41.496648],
                    [-81.794449, 41.49663],
                    [-81.799746, 41.496311],
                    [-81.800253, 41.496281],
                    [-81.801207, 41.496223],
                    [-81.810758, 41.495648],
                    [-81.810992, 41.495592],
                    [-81.836059, 41.489614],
                    [-81.837092, 41.489367],
                    [-81.850141, 41.486255],
                    [-81.860262, 41.483841],
                    [-81.87736, 41.483445],
                    [-81.928652, 41.490226],
                    [-81.930404, 41.490457],
                    [-81.937862, 41.491443],
                    [-81.958463, 41.498642],
                    [-81.962664, 41.501341],
                    [-81.964912, 41.505446],
                    [-81.96813, 41.506422],
                    [-81.994565, 41.51444],
                    [-82.011966, 41.515639],
                    [-82.094169, 41.495039],
                    [-82.165373, 41.47444],
                    [-82.181598, 41.471634],
                    [-82.184774, 41.47404],
                    [-82.186174, 41.47344],
                    [-82.18885, 41.468097],
                    [-82.193375, 41.46454],
                    [-82.254678, 41.434441],
                    [-82.268479, 41.430842],
                    [-82.283488, 41.429283],
                    [-82.29158, 41.428442],
                    [-82.334182, 41.430243],
                    [-82.348076, 41.428431],
                    [-82.361784, 41.426644],
                    [-82.398086, 41.413945],
                    [-82.431315, 41.396866],
                    [-82.460599, 41.386316],
                    [-82.481214, 41.381342],
                    [-82.499099, 41.381541],
                    [-82.513827, 41.384257],
                    [-82.55808, 41.400005],
                    [-82.616952, 41.428425],
                    [-82.617745, 41.431833],
                    [-82.658302, 41.461878],
                    [-82.687921, 41.492324],
                    [-82.694722, 41.493945],
                    [-82.710013, 41.49759],
                    [-82.713904, 41.501697],
                    [-82.719811, 41.510296],
                    [-82.719956, 41.510735],
                    [-82.721914, 41.516677],
                    [-82.716492, 41.522227],
                    [-82.711632, 41.527201],
                    [-82.710967, 41.536221],
                    [-82.710935, 41.536648],
                    [-82.711332, 41.53695],
                    [-82.715227, 41.539913],
                    [-82.717595, 41.541715],
                    [-82.717878, 41.54193],
                    [-82.720539, 41.542307],
                    [-82.722069, 41.542524],
                    [-82.727656, 41.543316],
                    [-82.728489, 41.543434],
                    [-82.730516, 41.543721],
                    [-82.731628, 41.543879],
                    [-82.7319, 41.543918],
                    [-82.732886, 41.544057],
                    [-82.739868, 41.545047],
                    [-82.749907, 41.54647],
                    [-82.77201, 41.54058],
                    [-82.785496, 41.540675],
                    [-82.794324, 41.546486],
                    [-82.820207, 41.570664],
                    [-82.834101, 41.587587],
                    [-82.847657, 41.585639],
                    [-82.852957, 41.583327],
                    [-82.854263, 41.581945],
                    [-82.856919, 41.579135],
                    [-82.857316, 41.578714],
                    [-82.857945, 41.578049],
                    [-82.859531, 41.576371],
                    [-82.858702, 41.574025],
                    [-82.856647, 41.568216],
                    [-82.856088, 41.566633],
                    [-82.855197, 41.564114],
                    [-82.856533, 41.55065],
                    [-82.85677, 41.548262],
                    [-82.857841, 41.547051],
                    [-82.861323, 41.543116],
                    [-82.866262, 41.537534],
                    [-82.869422, 41.533962],
                    [-82.875229, 41.529684],
                    [-82.882305, 41.52577],
                    [-82.8882, 41.522508],
                    [-82.896854, 41.519541],
                    [-82.897728, 41.519241],
                    [-82.934369, 41.514353],
                    [-82.958481, 41.521104],
                    [-82.969574, 41.52421],
                    [-82.969642, 41.524229],
                    [-82.96985, 41.524327],
                    [-82.999897, 41.538525],
                    [-82.999916, 41.538534],
                    [-83.016753, 41.548773],
                    [-83.017473, 41.549211],
                    [-83.019057, 41.550174],
                    [-83.028072, 41.555656],
                    [-83.030764, 41.557864],
                    [-83.031653, 41.558592],
                    [-83.042909, 41.567823],
                    [-83.043079, 41.567963],
                    [-83.043287, 41.568205],
                    [-83.048222, 41.573951],
                    [-83.066593, 41.59534],
                    [-83.086036, 41.60668],
                    [-83.103928, 41.613558],
                    [-83.145887, 41.617904],
                    [-83.165713, 41.623246],
                    [-83.194524, 41.631008],
                    [-83.23166, 41.644218],
                    [-83.238191, 41.651167],
                    [-83.278455, 41.672078],
                    [-83.285181, 41.674673],
                    [-83.287125, 41.675423],
                    [-83.288469, 41.675941],
                    [-83.289682, 41.676409],
                    [-83.29068, 41.676794],
                    [-83.293928, 41.680846],
                    [-83.29561, 41.681905],
                    [-83.298731, 41.683871],
                    [-83.326825, 41.701562],
                    [-83.337977, 41.70341],
                    [-83.337985, 41.698682],
                    [-83.341817, 41.693518],
                    [-83.357073, 41.687763],
                    [-83.37573, 41.686647],
                    [-83.39263, 41.691947],
                    [-83.409531, 41.691247],
                    [-83.446032, 41.706847],
                    [-83.449001, 41.710719],
                    [-83.455626, 41.727445],
                    [-83.453832, 41.732647],
                    [-83.451897, 41.734486],
                    [-83.43436, 41.737058],
                    [-83.424155, 41.74071],
                    [-83.424076, 41.740738],
                    [-83.42418, 41.741042],
                    [-83.42643, 41.747639],
                    [-83.427308, 41.750214],
                    [-83.427336, 41.750267],
                    [-83.427377, 41.750346],
                    [-83.431103, 41.757457],
                    [-83.431951, 41.759074],
                    [-83.432078, 41.759316],
                    [-83.432832, 41.760756],
                    [-83.432973, 41.761025],
                    [-83.434238, 41.763439],
                    [-83.435571, 41.765983],
                    [-83.437197, 41.769085],
                    [-83.437231, 41.76915],
                    [-83.437516, 41.769694],
                    [-83.437935, 41.771086],
                    [-83.443364, 41.789118],
                    [-83.442843, 41.795121],
                    [-83.442521, 41.79883],
                    [-83.442316, 41.80119],
                    [-83.441668, 41.808646],
                    [-83.439612, 41.813162],
                    [-83.436298, 41.816471],
                    [-83.435946, 41.816823],
                    [-83.434204, 41.818562],
                    [-83.431183, 41.819506],
                    [-83.426321, 41.821026],
                    [-83.425393, 41.821316],
                    [-83.422391, 41.822255],
                    [-83.422316, 41.822278],
                    [-83.409596, 41.830325],
                    [-83.40822, 41.832654],
                    [-83.39622, 41.852965],
                    [-83.393822, 41.855976],
                    [-83.389289, 41.861668],
                    [-83.381955, 41.870877],
                    [-83.379705, 41.871729],
                    [-83.372445, 41.874477],
                    [-83.372198, 41.874122],
                    [-83.366187, 41.865505],
                    [-83.359467, 41.867849],
                    [-83.341557, 41.879956],
                    [-83.335961, 41.889721],
                    [-83.335658, 41.892009],
                    [-83.335132, 41.895992],
                    [-83.335103, 41.896209],
                    [-83.334346, 41.901939],
                    [-83.334173, 41.903247],
                    [-83.333642, 41.907261],
                    [-83.332998, 41.908757],
                    [-83.330498, 41.914565],
                    [-83.32706, 41.922554],
                    [-83.326029, 41.924948],
                    [-83.326024, 41.924961],
                    [-83.326007, 41.924979],
                    [-83.315859, 41.935893],
                    [-83.305639, 41.941557],
                    [-83.303465, 41.942762],
                    [-83.302904, 41.943073],
                    [-83.299467, 41.943902],
                    [-83.29823, 41.9442],
                    [-83.295982, 41.944742],
                    [-83.293015, 41.944626],
                    [-83.292761, 41.944616],
                    [-83.28713, 41.944397],
                    [-83.270491, 41.939337],
                    [-83.269521, 41.939042],
                    [-83.257292, 41.950745],
                    [-83.257199, 41.95367],
                    [-83.257143, 41.955438],
                    [-83.257043, 41.958615],
                    [-83.257009, 41.959686],
                    [-83.256803, 41.960021],
                    [-83.255123, 41.962759],
                    [-83.249828, 41.971386],
                    [-83.249204, 41.972402],
                    [-83.248741, 41.972735],
                    [-83.22929, 41.986725],
                    [-83.228607, 41.987216],
                    [-83.228502, 41.987291],
                    [-83.22841, 41.987325],
                    [-83.227744, 41.987571],
                    [-83.224947, 41.988603],
                    [-83.223369, 41.989185],
                    [-83.223354, 41.989191],
                    [-83.216897, 41.988561],
                    [-83.216835, 41.98862],
                    [-83.209379, 41.995736],
                    [-83.208647, 42.00504],
                    [-83.190535, 42.006172],
                    [-83.187246, 42.007573],
                    [-83.181475, 42.019301],
                    [-83.185822, 42.029367],
                    [-83.185858, 42.029451],
                    [-83.18824, 42.031329],
                    [-83.185526, 42.052243],
                    [-83.186877, 42.061206],
                    [-83.189115, 42.061853],
                    [-83.188598, 42.066431],
                    [-83.170589, 42.07294],
                    [-83.168759, 42.073601],
                    [-83.157624, 42.085542],
                    [-83.133511, 42.088143],
                    [-83.124522, 42.137641],
                    [-83.133923, 42.17474],
                    [-83.131343, 42.20276],
                    [-83.128022, 42.238839],
                    [-83.096521, 42.290138],
                    [-83.079721, 42.308638],
                    [-83.064121, 42.317738],
                    [-83.01832, 42.329739],
                    [-82.988619, 42.332439],
                    [-82.959416, 42.339638],
                    [-82.92397, 42.352068],
                    [-82.928815, 42.359437],
                    [-82.919114, 42.374437],
                    [-82.915114, 42.378137],
                    [-82.898413, 42.385437],
                    [-82.894013, 42.389437],
                    [-82.888413, 42.398237],
                    [-82.886113, 42.408137],
                    [-82.870347, 42.450888],
                    [-82.870572, 42.451235],
                    [-82.883915, 42.471836],
                    [-82.882316, 42.501035],
                    [-82.874416, 42.523535],
                    [-82.860213, 42.540842],
                    [-82.859316, 42.541935],
                    [-82.851016, 42.548935],
                    [-82.849316, 42.555734],
                    [-82.845916, 42.560634],
                    [-82.834216, 42.567849],
                    [-82.821016, 42.570734],
                    [-82.796715, 42.571034],
                    [-82.789114, 42.568434],
                    [-82.784514, 42.563634],
                    [-82.782414, 42.564834],
                    [-82.781514, 42.571634],
                    [-82.788116, 42.582835],
                    [-82.788977, 42.592661],
                    [-82.787573, 42.5983],
                    [-82.789017, 42.603434],
                    [-82.811017, 42.610933],
                    [-82.819017, 42.616333],
                    [-82.820118, 42.626333],
                    [-82.813518, 42.640833],
                    [-82.797318, 42.654032],
                    [-82.792418, 42.655132],
                    [-82.780817, 42.652232],
                    [-82.765583, 42.655725],
                    [-82.753317, 42.669732],
                    [-82.726366, 42.682768],
                    [-82.707841, 42.68351],
                    [-82.706135, 42.683578],
                    [-82.700964, 42.689548],
                    [-82.6855, 42.690036],
                    [-82.674287, 42.687049],
                    [-82.659781, 42.678618],
                    [-82.635262, 42.675552],
                    [-82.630851, 42.673341],
                    [-82.623797, 42.665395],
                    [-82.623043, 42.655951],
                    [-82.626396, 42.647385],
                    [-82.630922, 42.64211],
                    [-82.645715, 42.631145],
                    [-82.669103, 42.637225],
                    [-82.689836, 42.627148],
                    [-82.690124, 42.625033],
                    [-82.681593, 42.618672],
                    [-82.683482, 42.609433],
                    [-82.700818, 42.606687],
                    [-82.713042, 42.597904],
                    [-82.711151, 42.590884],
                    [-82.701152, 42.585991],
                    [-82.688061, 42.588417],
                    [-82.681036, 42.574695],
                    [-82.680758, 42.557909],
                    [-82.664335, 42.546244],
                    [-82.670956, 42.537989],
                    [-82.679522, 42.53552],
                    [-82.685397, 42.528659],
                    [-82.686417, 42.518597],
                    [-82.679059, 42.52221],
                    [-82.624907, 42.557229],
                    [-82.607068, 42.548843],
                    [-82.589779, 42.550678],
                    [-82.583996, 42.554041],
                    [-82.523337, 42.607486],
                    [-82.518782, 42.613888],
                    [-82.509935, 42.637294],
                    [-82.483697, 42.733212],
                    [-82.469912, 42.887459],
                    [-82.455027, 42.926866],
                    [-82.447778, 42.937066],
                    [-82.415937, 43.005555],
                    [-82.422768, 43.007956],
                    [-82.443433, 43.039942],
                    [-82.450724, 43.051229],
                    [-82.457221, 43.061285],
                    [-82.457319, 43.06147],
                    [-82.471053, 43.087581],
                    [-82.486042, 43.102486],
                    [-82.486684, 43.104688],
                    [-82.490494, 43.117759],
                    [-82.490614, 43.118172],
                    [-82.490634, 43.118314],
                    [-82.490979, 43.120782],
                    [-82.493021, 43.135363],
                    [-82.493347, 43.137685],
                    [-82.493977, 43.142186],
                    [-82.494014, 43.142453],
                    [-82.494052, 43.142722],
                    [-82.494072, 43.142864],
                    [-82.494194, 43.143736],
                    [-82.495685, 43.147316],
                    [-82.500061, 43.157827],
                    [-82.501529, 43.161351],
                    [-82.501656, 43.161656],
                    [-82.50299, 43.168137],
                    [-82.503157, 43.168946],
                    [-82.508881, 43.196748],
                    [-82.519123, 43.212737],
                    [-82.523086, 43.225361],
                    [-82.532396, 43.30577],
                    [-82.529416, 43.316243],
                    [-82.530128, 43.333805],
                    [-82.536794, 43.34851],
                    [-82.535627, 43.368062],
                    [-82.53993, 43.422378],
                    [-82.538578, 43.431594],
                    [-82.539517, 43.437539],
                    [-82.55354, 43.464111],
                    [-82.565505, 43.497063],
                    [-82.565691, 43.502904],
                    [-82.585654, 43.543969],
                    [-82.593785, 43.581467],
                    [-82.597911, 43.590016],
                    [-82.6005, 43.602935],
                    [-82.605783, 43.669489],
                    [-82.60483, 43.678884],
                    [-82.606233, 43.690437],
                    [-82.612224, 43.739771],
                    [-82.617213, 43.746788],
                    [-82.619079, 43.756088],
                    [-82.617955, 43.768596],
                    [-82.633641, 43.831224],
                    [-82.644345, 43.837539],
                    [-82.647784, 43.842684],
                    [-82.647467, 43.84449],
                    [-82.642899, 43.846419],
                    [-82.643166, 43.852468],
                    [-82.65545, 43.867883],
                    [-82.678642, 43.88373],
                    [-82.693505, 43.91798],
                    [-82.709839, 43.948226],
                    [-82.712235, 43.94961],
                    [-82.728528, 43.972615],
                    [-82.738992, 43.989506],
                    [-82.746255, 43.996037],
                    [-82.765018, 44.006845],
                    [-82.783198, 44.009366],
                    [-82.788298, 44.013712],
                    [-82.793205, 44.023247],
                    [-82.833103, 44.036851],
                    [-82.875889, 44.045046],
                    [-82.889831, 44.050952],
                    [-82.915976, 44.070503],
                    [-82.928884, 44.069389],
                    [-82.947368, 44.062187],
                    [-82.956658, 44.063306],
                    [-82.958688, 44.065774],
                    [-82.967439, 44.066138],
                    [-82.990728, 44.048846],
                    [-82.999283, 44.04651],
                    [-83.024604, 44.045174],
                    [-83.029868, 44.041175],
                    [-83.046577, 44.01571],
                    [-83.058741, 44.006224],
                    [-83.066026, 44.003366],
                    [-83.079297, 44.001079],
                    [-83.10782, 44.003245],
                    [-83.120659, 44.00095],
                    [-83.134881, 43.993147],
                    [-83.145407, 43.989441],
                    [-83.180618, 43.982109],
                    [-83.195688, 43.983137],
                    [-83.227093, 43.981003],
                    [-83.26153, 43.973525],
                    [-83.26185, 43.969021],
                    [-83.26898, 43.956132],
                    [-83.28231, 43.938031],
                    [-83.30569, 43.922489],
                    [-83.318656, 43.91762],
                    [-83.338067, 43.915687],
                    [-83.347365, 43.91216],
                    [-83.348648, 43.909915],
                    [-83.348007, 43.906388],
                    [-83.340976, 43.904541],
                    [-83.333532, 43.89852],
                    [-83.331788, 43.893901],
                    [-83.33227, 43.880522],
                    [-83.358869, 43.857395],
                    [-83.389424, 43.844229],
                    [-83.389017, 43.840457],
                    [-83.390344, 43.839132],
                    [-83.407647, 43.831998],
                    [-83.410853, 43.825585],
                    [-83.412456, 43.817569],
                    [-83.410663, 43.80773],
                    [-83.411453, 43.805033],
                    [-83.416378, 43.801034],
                    [-83.426068, 43.799915],
                    [-83.438311, 43.786846],
                    [-83.446752, 43.77186],
                    [-83.441591, 43.770175],
                    [-83.438878, 43.767135],
                    [-83.440171, 43.761694],
                    [-83.459628, 43.740931],
                    [-83.46508, 43.733843],
                    [-83.467429, 43.72892],
                    [-83.470053, 43.723418],
                    [-83.48007, 43.714636],
                    [-83.506657, 43.710907],
                    [-83.513461, 43.714607],
                    [-83.515853, 43.718157],
                    [-83.524837, 43.716948],
                    [-83.540187, 43.708746],
                    [-83.55147, 43.699901],
                    [-83.549044, 43.693798],
                    [-83.553707, 43.685432],
                    [-83.563157, 43.684564],
                    [-83.595579, 43.650249],
                    [-83.618602, 43.628891],
                    [-83.654192, 43.59929],
                    [-83.666052, 43.591292],
                    [-83.669795, 43.59079],
                    [-83.699253, 43.596792],
                    [-83.703446, 43.597646],
                    [-83.725793, 43.618691],
                    [-83.769886, 43.634924],
                    [-83.770693, 43.628691],
                    [-83.778919, 43.630056],
                    [-83.806774, 43.641221],
                    [-83.814674, 43.643022],
                    [-83.852076, 43.644922],
                    [-83.897078, 43.664022],
                    [-83.909479, 43.672622],
                    [-83.929462, 43.701269],
                    [-83.939297, 43.715369],
                    [-83.954347, 43.750647],
                    [-83.956021, 43.759286],
                    [-83.954792, 43.760932],
                    [-83.945426, 43.759946],
                    [-83.929375, 43.777091],
                    [-83.926345, 43.787398],
                    [-83.917875, 43.856509],
                    [-83.916815, 43.89905],
                    [-83.911128, 43.91052],
                    [-83.907388, 43.918062],
                    [-83.890912, 43.923314],
                    [-83.890145, 43.934672],
                    [-83.885543, 43.946154],
                    [-83.885526, 43.946197],
                    [-83.885328, 43.946691],
                    [-83.880113, 43.955261],
                    [-83.880011, 43.955428],
                    [-83.877694, 43.959235],
                    [-83.877047, 43.959351],
                    [-83.869614, 43.960682],
                    [-83.869406, 43.960719],
                    [-83.859743, 43.969389],
                    [-83.859615, 43.969504],
                    [-83.859459, 43.969643],
                    [-83.859305, 43.969782],
                    [-83.859114, 43.969953],
                    [-83.858528, 43.970479],
                    [-83.858373, 43.970618],
                    [-83.856371, 43.972414],
                    [-83.856128, 43.972632],
                    [-83.856077, 43.972822],
                    [-83.855653, 43.974391],
                    [-83.855602, 43.974581],
                    [-83.855572, 43.974691],
                    [-83.855516, 43.974899],
                    [-83.855219, 43.975997],
                    [-83.85493, 43.977067],
                    [-83.853582, 43.977984],
                    [-83.851884, 43.97914],
                    [-83.85175, 43.979231],
                    [-83.851496, 43.979403],
                    [-83.848276, 43.981594],
                    [-83.829123, 43.989077],
                    [-83.829102, 43.989085],
                    [-83.829077, 43.989095],
                    [-83.828398, 43.989032],
                    [-83.82808, 43.989003],
                    [-83.787863, 43.985279],
                    [-83.779086, 43.985235],
                    [-83.763774, 43.985158],
                    [-83.763345, 43.98525],
                    [-83.763015, 43.985321],
                    [-83.76283, 43.985361],
                    [-83.757063, 43.986599],
                    [-83.746779, 43.988807],
                    [-83.743806, 43.991529],
                    [-83.708741, 43.992997],
                    [-83.680108, 43.994196],
                    [-83.687892, 44.020709],
                    [-83.679654, 44.036365],
                    [-83.650116, 44.052404],
                    [-83.621078, 44.056186],
                    [-83.601173, 44.054686],
                    [-83.58409, 44.056748],
                    [-83.590437, 44.069569],
                    [-83.591361, 44.079237],
                    [-83.588004, 44.086758],
                    [-83.573071, 44.101298],
                    [-83.567714, 44.119652],
                    [-83.567978, 44.121207],
                    [-83.568509, 44.124343],
                    [-83.568915, 44.126734],
                    [-83.568831, 44.128819],
                    [-83.568803, 44.129513],
                    [-83.568243, 44.143475],
                    [-83.568238, 44.143587],
                    [-83.5682, 44.144542],
                    [-83.567941, 44.150983],
                    [-83.567744, 44.155899],
                    [-83.566366, 44.160066],
                    [-83.565984, 44.161219],
                    [-83.565257, 44.163418],
                    [-83.565225, 44.163517],
                    [-83.553834, 44.197956],
                    [-83.552872, 44.210718],
                    [-83.549096, 44.227282],
                    [-83.53771, 44.248171],
                    [-83.524817, 44.261558],
                    [-83.508839, 44.273711],
                    [-83.500392, 44.27661],
                    [-83.479531, 44.28009],
                    [-83.463049, 44.278838],
                    [-83.460958, 44.278176],
                    [-83.447742, 44.273991],
                    [-83.445805, 44.273378],
                    [-83.442731, 44.265361],
                    [-83.429689, 44.269708],
                    [-83.425762, 44.272487],
                    [-83.419236, 44.2878],
                    [-83.414301, 44.294543],
                    [-83.401822, 44.301831],
                    [-83.373607, 44.327784],
                    [-83.364312, 44.33259],
                    [-83.352115, 44.332366],
                    [-83.343738, 44.329763],
                    [-83.336988, 44.332919],
                    [-83.332533, 44.340464],
                    [-83.335248, 44.357995],
                    [-83.333757, 44.372486],
                    [-83.321648, 44.404502],
                    [-83.321553, 44.409119],
                    [-83.324616, 44.415039],
                    [-83.327171, 44.429234],
                    [-83.326824, 44.444411],
                    [-83.31761, 44.486058],
                    [-83.318216, 44.511734],
                    [-83.318276, 44.514294],
                    [-83.318279, 44.514416],
                    [-83.318104, 44.514871],
                    [-83.311529, 44.531956],
                    [-83.311245, 44.532693],
                    [-83.310376, 44.534952],
                    [-83.310185, 44.535448],
                    [-83.309385, 44.537528],
                    [-83.308471, 44.539902],
                    [-83.308906, 44.54814],
                    [-83.308918, 44.54836],
                    [-83.309117, 44.549043],
                    [-83.309234, 44.549444],
                    [-83.313623, 44.564498],
                    [-83.313649, 44.564588],
                    [-83.313744, 44.566076],
                    [-83.313792, 44.566822],
                    [-83.313813, 44.567148],
                    [-83.313893, 44.568395],
                    [-83.313925, 44.568888],
                    [-83.314013, 44.570266],
                    [-83.314098, 44.571592],
                    [-83.314127, 44.572043],
                    [-83.315503, 44.593514],
                    [-83.315603, 44.595079],
                    [-83.315435, 44.597193],
                    [-83.315277, 44.599178],
                    [-83.31504, 44.602152],
                    [-83.315037, 44.60219],
                    [-83.314534, 44.608512],
                    [-83.314517, 44.608725],
                    [-83.31445, 44.608926],
                    [-83.309869, 44.622703],
                    [-83.309802, 44.622906],
                    [-83.309222, 44.62465],
                    [-83.307504, 44.629816],
                    [-83.307116, 44.630313],
                    [-83.295377, 44.64536],
                    [-83.289442, 44.652968],
                    [-83.287802, 44.657703],
                    [-83.287585, 44.658328],
                    [-83.287211, 44.659409],
                    [-83.286874, 44.66038],
                    [-83.285992, 44.662927],
                    [-83.279288, 44.682278],
                    [-83.279129, 44.682735],
                    [-83.277476, 44.687507],
                    [-83.276836, 44.689354],
                    [-83.276137, 44.69434],
                    [-83.275601, 44.698161],
                    [-83.275543, 44.698574],
                    [-83.2755, 44.698882],
                    [-83.275318, 44.700177],
                    [-83.275281, 44.700441],
                    [-83.275078, 44.701891],
                    [-83.274674, 44.70477],
                    [-83.273393, 44.713901],
                    [-83.274103, 44.714421],
                    [-83.274747, 44.714893],
                    [-83.277213, 44.7167],
                    [-83.283098, 44.721011],
                    [-83.284128, 44.721766],
                    [-83.284724, 44.72245],
                    [-83.288214, 44.726453],
                    [-83.290665, 44.729265],
                    [-83.290944, 44.729974],
                    [-83.296265, 44.743502],
                    [-83.2973, 44.746134],
                    [-83.29742, 44.747203],
                    [-83.297526, 44.74814],
                    [-83.297797, 44.750547],
                    [-83.298237, 44.754465],
                    [-83.298287, 44.754907],
                    [-83.298021, 44.755632],
                    [-83.296971, 44.758495],
                    [-83.296969, 44.758543],
                    [-83.296125, 44.778828],
                    [-83.295696, 44.789112],
                    [-83.29565, 44.790237],
                    [-83.295518, 44.793397],
                    [-83.29553, 44.793486],
                    [-83.298618, 44.815416],
                    [-83.299737, 44.823362],
                    [-83.300648, 44.829831],
                    [-83.306854, 44.83541],
                    [-83.308578, 44.83696],
                    [-83.312478, 44.840466],
                    [-83.312831, 44.840783],
                    [-83.31294, 44.840882],
                    [-83.31433, 44.842131],
                    [-83.314429, 44.84222],
                    [-83.318373, 44.84844],
                    [-83.321089, 44.852723],
                    [-83.321241, 44.852962],
                    [-83.321237, 44.853102],
                    [-83.321093, 44.858494],
                    [-83.320503, 44.880571],
                    [-83.352815, 44.886164],
                    [-83.39396, 44.903056],
                    [-83.398879, 44.906417],
                    [-83.404596, 44.918761],
                    [-83.425311, 44.926741],
                    [-83.433032, 44.93289],
                    [-83.438856, 44.940843],
                    [-83.443718, 44.952247],
                    [-83.450013, 44.990219],
                    [-83.438948, 45.000011],
                    [-83.435822, 45.000012],
                    [-83.431254, 45.007998],
                    [-83.435249, 45.011883],
                    [-83.446342, 45.016655],
                    [-83.454168, 45.03188],
                    [-83.453363, 45.035331],
                    [-83.442052, 45.051056],
                    [-83.433798, 45.057616],
                    [-83.399255, 45.070364],
                    [-83.36747, 45.062268],
                    [-83.357609, 45.050613],
                    [-83.340257, 45.041545],
                    [-83.302153, 45.032315],
                    [-83.287974, 45.026462],
                    [-83.271506, 45.023417],
                    [-83.265896, 45.026844],
                    [-83.271464, 45.038114],
                    [-83.277037, 45.044767],
                    [-83.280272, 45.045962],
                    [-83.291346, 45.062597],
                    [-83.290827, 45.069157],
                    [-83.298275, 45.090483],
                    [-83.30788, 45.099093],
                    [-83.318442, 45.12893],
                    [-83.319315, 45.137684],
                    [-83.315924, 45.139992],
                    [-83.316118, 45.141958],
                    [-83.337822, 45.14712],
                    [-83.348684, 45.161516],
                    [-83.359895, 45.16302],
                    [-83.363678, 45.166469],
                    [-83.368046, 45.172478],
                    [-83.368896, 45.182168],
                    [-83.381647, 45.203357],
                    [-83.384265, 45.203472],
                    [-83.387587, 45.207107],
                    [-83.405914, 45.227157],
                    [-83.41241, 45.238905],
                    [-83.412569, 45.245807],
                    [-83.388034, 45.254976],
                    [-83.381743, 45.268983],
                    [-83.385104, 45.274195],
                    [-83.388274, 45.276916],
                    [-83.398845, 45.279107],
                    [-83.400261, 45.2794],
                    [-83.400753, 45.279502],
                    [-83.40088, 45.279528],
                    [-83.401091, 45.279572],
                    [-83.42051, 45.289787],
                    [-83.422272, 45.290713],
                    [-83.422389, 45.290775],
                    [-83.422486, 45.290989],
                    [-83.423178, 45.292506],
                    [-83.42514, 45.296808],
                    [-83.43304, 45.303688],
                    [-83.445672, 45.310612],
                    [-83.468099, 45.33245],
                    [-83.477728, 45.341827],
                    [-83.477794, 45.341891],
                    [-83.488826, 45.355872],
                    [-83.496704, 45.357536],
                    [-83.514717, 45.34646],
                    [-83.520258, 45.347239],
                    [-83.538306, 45.358167],
                    [-83.545729, 45.358397],
                    [-83.546799, 45.352637],
                    [-83.550268, 45.350832],
                    [-83.570361, 45.347198],
                    [-83.599273, 45.352561],
                    [-83.64379, 45.37171],
                    [-83.667934, 45.384675],
                    [-83.697316, 45.396239],
                    [-83.721815, 45.413304],
                    [-83.737321, 45.410943],
                    [-83.755569, 45.411034],
                    [-83.773171, 45.417302],
                    [-83.788777, 45.416415],
                    [-83.806622, 45.419159],
                    [-83.841543, 45.435287],
                    [-83.85856, 45.446865],
                    [-83.881813, 45.467907],
                    [-83.909472, 45.485784],
                    [-83.939261, 45.493189],
                    [-83.978017, 45.494138],
                    [-83.99835, 45.491158],
                    [-84.009582, 45.495069],
                    [-84.028813, 45.497225],
                    [-84.036286, 45.496245],
                    [-84.039958, 45.493733],
                    [-84.056138, 45.489349],
                    [-84.075792, 45.490537],
                    [-84.095905, 45.497298],
                    [-84.109238, 45.505171],
                    [-84.116687, 45.51305],
                    [-84.122309, 45.523788],
                    [-84.126971, 45.542428],
                    [-84.126532, 45.556616],
                    [-84.128867, 45.562284],
                    [-84.139462, 45.573714],
                    [-84.157121, 45.585305],
                    [-84.180514, 45.604639],
                    [-84.196043, 45.621456],
                    [-84.204218, 45.627116],
                    [-84.215268, 45.634767],
                    [-84.270238, 45.64479],
                    [-84.289685, 45.653296],
                    [-84.329537, 45.66438],
                    [-84.376403, 45.655565],
                    [-84.400283, 45.663345],
                    [-84.413642, 45.669427],
                    [-84.427495, 45.669201],
                    [-84.435415, 45.664106],
                    [-84.442348, 45.654771],
                    [-84.46168, 45.652404],
                    [-84.538998, 45.690383],
                    [-84.539165, 45.690478],
                    [-84.539167, 45.69048],
                    [-84.553311, 45.698566],
                    [-84.555496, 45.702268],
                    [-84.573631, 45.710381],
                    [-84.604712, 45.721668],
                    [-84.644822, 45.73999],
                    [-84.679546, 45.749095],
                    [-84.681967, 45.756197],
                    [-84.715996, 45.766174],
                    [-84.718904, 45.777599],
                    [-84.726192, 45.786905],
                    [-84.732388, 45.787928],
                    [-84.734065, 45.788205],
                    [-84.742, 45.784134],
                    [-84.751571, 45.782733],
                    [-84.772765, 45.789301],
                    [-84.774014, 45.788957],
                    [-84.774156, 45.788918],
                    [-84.780313, 45.787224],
                    [-84.793153, 45.780463],
                    [-84.792337, 45.778497],
                    [-84.79229, 45.778464],
                    [-84.787228, 45.774892],
                    [-84.7798, 45.76965],
                    [-84.781373, 45.76108],
                    [-84.781995, 45.760345],
                    [-84.788808, 45.752298],
                    [-84.788821, 45.752283],
                    [-84.788893, 45.752248],
                    [-84.78958, 45.751919],
                    [-84.792474, 45.75053],
                    [-84.799558, 45.74713],
                    [-84.800664, 45.74698],
                    [-84.805114, 45.746378],
                    [-84.806642, 45.746171],
                    [-84.808285, 45.746103],
                    [-84.810411, 45.746015],
                    [-84.810953, 45.745993],
                    [-84.840981, 45.744751],
                    [-84.866976, 45.752066],
                    [-84.910398, 45.75001],
                    [-84.919996, 45.754643],
                    [-84.920691, 45.754979],
                    [-84.922478, 45.755842],
                    [-84.924664, 45.756897],
                    [-84.92523, 45.757021],
                    [-84.926802, 45.757366],
                    [-84.931463, 45.758389],
                    [-84.93779, 45.759777],
                    [-84.938312, 45.759892],
                    [-84.995105, 45.759855],
                    [-85.00741, 45.763168],
                    [-85.009173, 45.762463],
                    [-85.014509, 45.760329],
                    [-85.011433, 45.757962],
                    [-85.002914, 45.75394],
                    [-85.001656, 45.753819],
                    [-84.996755, 45.753347],
                    [-84.985318, 45.752247],
                    [-84.984568, 45.752175],
                    [-84.983933, 45.752114],
                    [-84.983829, 45.752104],
                    [-84.983316, 45.752055],
                    [-84.982973, 45.752022],
                    [-84.982328, 45.75196],
                    [-84.968309, 45.745252],
                    [-84.95813, 45.740381],
                    [-84.955394, 45.739072],
                    [-84.954275, 45.738537],
                    [-84.951745, 45.737326],
                    [-84.95084, 45.736893],
                    [-84.950226, 45.736299],
                    [-84.947266, 45.733435],
                    [-84.945958, 45.732169],
                    [-84.943982, 45.730257],
                    [-84.942543, 45.728865],
                    [-84.942125, 45.72846],
                    [-84.942098, 45.728349],
                    [-84.940526, 45.721832],
                    [-84.941073, 45.719879],
                    [-84.942636, 45.714292],
                    [-84.943756, 45.71029],
                    [-84.94565, 45.708621],
                    [-84.970213, 45.686984],
                    [-84.97095, 45.686334],
                    [-84.974237, 45.684178],
                    [-84.996067, 45.669861],
                    [-84.996336, 45.669685],
                    [-84.997431, 45.667762],
                    [-85.001154, 45.661225],
                    [-85.002344, 45.660239],
                    [-85.005645, 45.657504],
                    [-85.007026, 45.65636],
                    [-85.013218, 45.652789],
                    [-85.014352, 45.652134],
                    [-85.015341, 45.651564],
                    [-85.019374, 45.65051],
                    [-85.020107, 45.650319],
                    [-85.03559, 45.646273],
                    [-85.041037, 45.644849],
                    [-85.044872, 45.643847],
                    [-85.061006, 45.639631],
                    [-85.061488, 45.639505],
                    [-85.063974, 45.637604],
                    [-85.065716, 45.636272],
                    [-85.071381, 45.63194],
                    [-85.074243, 45.629752],
                    [-85.07491, 45.629242],
                    [-85.075461, 45.625295],
                    [-85.075678, 45.623742],
                    [-85.075686, 45.623688],
                    [-85.076284, 45.62266],
                    [-85.07937, 45.617355],
                    [-85.079528, 45.617083],
                    [-85.093525, 45.600121],
                    [-85.095531, 45.598562],
                    [-85.096798, 45.597576],
                    [-85.101977, 45.59355],
                    [-85.102221, 45.59336],
                    [-85.102917, 45.59282],
                    [-85.103077, 45.592695],
                    [-85.103521, 45.59235],
                    [-85.105458, 45.590844],
                    [-85.106246, 45.590231],
                    [-85.106909, 45.589716],
                    [-85.107744, 45.589067],
                    [-85.111737, 45.585962],
                    [-85.111909, 45.585829],
                    [-85.112406, 45.585042],
                    [-85.11243, 45.585004],
                    [-85.117812, 45.576482],
                    [-85.118353, 45.575625],
                    [-85.118637, 45.575175],
                    [-85.118825, 45.574123],
                    [-85.119026, 45.573002],
                    [-85.119737, 45.569026],
                    [-85.119721, 45.568915],
                    [-85.119675, 45.568597],
                    [-85.119494, 45.567338],
                    [-85.117656, 45.55455],
                    [-85.117406, 45.552811],
                    [-85.11587, 45.542125],
                    [-85.115785, 45.541533],
                    [-85.115479, 45.539406],
                    [-85.110955, 45.526488],
                    [-85.110884, 45.526285],
                    [-85.109353, 45.521915],
                    [-85.109252, 45.521626],
                    [-85.103943, 45.510252],
                    [-85.097142, 45.495684],
                    [-85.088386, 45.476928],
                    [-85.087756, 45.476335],
                    [-85.070485, 45.460096],
                    [-85.069573, 45.459239],
                    [-85.052994, 45.446191],
                    [-85.050747, 45.444423],
                    [-85.050234, 45.444019],
                    [-85.046943, 45.441429],
                    [-85.040936, 45.436701],
                    [-85.040272, 45.436509],
                    [-85.037741, 45.435776],
                    [-85.034856, 45.434941],
                    [-84.994191, 45.423173],
                    [-84.978608, 45.418663],
                    [-84.977116, 45.420035],
                    [-84.978373, 45.420171],
                    [-84.983836, 45.420764],
                    [-84.984928, 45.421471],
                    [-84.987143, 45.422906],
                    [-84.987416, 45.423082],
                    [-84.989224, 45.424253],
                    [-84.990785, 45.425264],
                    [-84.990041, 45.427618],
                    [-84.981192, 45.429336],
                    [-84.980953, 45.429382],
                    [-84.980467, 45.42932],
                    [-84.978969, 45.429131],
                    [-84.976623, 45.428833],
                    [-84.97637, 45.428801],
                    [-84.922006, 45.421914],
                    [-84.916165, 45.417639],
                    [-84.912956, 45.409776],
                    [-84.912537, 45.402828],
                    [-84.91585, 45.393115],
                    [-84.959119, 45.375973],
                    [-84.975357, 45.373587],
                    [-84.998603, 45.370173],
                    [-85.022234, 45.366701],
                    [-85.032813, 45.361251],
                    [-85.043101, 45.361506],
                    [-85.043446, 45.361582],
                    [-85.043721, 45.361643],
                    [-85.045526, 45.362041],
                    [-85.046635, 45.362287],
                    [-85.054805, 45.364091],
                    [-85.060905, 45.364522],
                    [-85.063459, 45.364703],
                    [-85.06425, 45.364758],
                    [-85.066969, 45.364951],
                    [-85.073617, 45.36542],
                    [-85.095985, 45.367001],
                    [-85.143651, 45.370369],
                    [-85.182471, 45.360824],
                    [-85.196704, 45.360641],
                    [-85.209673, 45.356937],
                    [-85.235629, 45.339374],
                    [-85.252193, 45.330863],
                    [-85.25505, 45.325675],
                    [-85.262996, 45.319507],
                    [-85.273789, 45.315443],
                    [-85.289568, 45.314052],
                    [-85.294848, 45.316408],
                    [-85.307646, 45.31314],
                    [-85.323941, 45.303355],
                    [-85.335016, 45.294027],
                    [-85.355478, 45.282774],
                    [-85.371593, 45.270834],
                    [-85.388593, 45.23524],
                    [-85.387463, 45.207565],
                    [-85.386726, 45.189497],
                    [-85.380464, 45.180876],
                    [-85.376948, 45.142881],
                    [-85.372571, 45.126241],
                    [-85.366908, 45.116938],
                    [-85.366412, 45.069023],
                    [-85.377586, 45.055713],
                    [-85.380659, 45.046319],
                    [-85.381654, 45.018407],
                    [-85.378286, 44.998587],
                    [-85.3958, 44.931018],
                    [-85.406173, 44.911773],
                    [-85.423003, 44.895019],
                    [-85.425804, 44.881646],
                    [-85.44225, 44.859817],
                    [-85.452683, 44.845969],
                    [-85.455336, 44.842448],
                    [-85.456007, 44.841558],
                    [-85.457328, 44.839804],
                    [-85.457472, 44.839613],
                    [-85.457475, 44.839609],
                    [-85.460445, 44.835667],
                    [-85.462622, 44.826327],
                    [-85.462906, 44.82511],
                    [-85.462916, 44.825067],
                    [-85.462943, 44.825044],
                    [-85.474622, 44.815107],
                    [-85.474796, 44.814959],
                    [-85.475114, 44.814816],
                    [-85.475748, 44.814532],
                    [-85.477423, 44.813781],
                    [-85.481972, 44.81174],
                    [-85.484668, 44.810531],
                    [-85.496314, 44.805308],
                    [-85.499591, 44.803838],
                    [-85.507473, 44.790372],
                    [-85.508462, 44.788682],
                    [-85.509251, 44.787334],
                    [-85.507113, 44.784271],
                    [-85.505244, 44.781594],
                    [-85.504787, 44.778577],
                    [-85.504079, 44.773901],
                    [-85.503935, 44.772951],
                    [-85.5047, 44.768518],
                    [-85.504772, 44.768102],
                    [-85.504775, 44.768082],
                    [-85.509432, 44.763964],
                    [-85.523296, 44.751702],
                    [-85.527216, 44.748235],
                    [-85.538285, 44.746821],
                    [-85.554083, 44.748715],
                    [-85.554326, 44.748744],
                    [-85.554774, 44.748917],
                    [-85.571301, 44.755293],
                    [-85.575916, 44.759602],
                    [-85.576566, 44.760208],
                    [-85.576239, 44.760807],
                    [-85.57517, 44.762766],
                    [-85.571018, 44.770377],
                    [-85.568781, 44.774477],
                    [-85.560488, 44.789679],
                    [-85.560488, 44.78969],
                    [-85.560424, 44.794772],
                    [-85.560409, 44.79595],
                    [-85.5604, 44.796655],
                    [-85.560352, 44.800454],
                    [-85.560348, 44.800772],
                    [-85.560253, 44.808359],
                    [-85.560232, 44.809974],
                    [-85.560231, 44.810072],
                    [-85.557952, 44.814372],
                    [-85.555894, 44.818256],
                    [-85.553844, 44.820298],
                    [-85.55285, 44.821288],
                    [-85.551797, 44.822338],
                    [-85.547119, 44.826998],
                    [-85.546021, 44.828092],
                    [-85.542706, 44.831395],
                    [-85.539924, 44.834166],
                    [-85.538522, 44.834918],
                    [-85.533434, 44.837648],
                    [-85.527181, 44.841002],
                    [-85.519096, 44.845339],
                    [-85.518845, 44.8454],
                    [-85.516997, 44.845846],
                    [-85.513575, 44.846674],
                    [-85.511751, 44.847114],
                    [-85.508617, 44.847872],
                    [-85.508227, 44.848352],
                    [-85.502386, 44.855551],
                    [-85.502182, 44.855802],
                    [-85.500872, 44.85883],
                    [-85.498007, 44.865451],
                    [-85.489921, 44.896694],
                    [-85.489704, 44.897533],
                    [-85.488624, 44.901707],
                    [-85.488927, 44.902217],
                    [-85.489477, 44.903145],
                    [-85.49249, 44.90822],
                    [-85.492397, 44.909719],
                    [-85.491496, 44.924205],
                    [-85.491471, 44.92461],
                    [-85.491414, 44.925531],
                    [-85.491403, 44.925695],
                    [-85.491393, 44.925868],
                    [-85.491286, 44.927585],
                    [-85.491239, 44.927804],
                    [-85.491215, 44.927918],
                    [-85.490713, 44.930274],
                    [-85.489895, 44.934118],
                    [-85.489049, 44.938087],
                    [-85.48574, 44.953626],
                    [-85.474274, 44.958529],
                    [-85.472258, 44.959391],
                    [-85.471646, 44.959331],
                    [-85.471261, 44.959294],
                    [-85.470688, 44.959238],
                    [-85.46665, 44.958844],
                    [-85.464944, 44.961062],
                    [-85.466848, 44.967853],
                    [-85.46802, 44.972035],
                    [-85.468293, 44.973008],
                    [-85.470215, 44.979864],
                    [-85.470462, 44.980745],
                    [-85.471708, 44.983453],
                    [-85.472676, 44.985558],
                    [-85.475204, 44.991053],
                    [-85.492386, 44.989849],
                    [-85.4926, 44.989834],
                    [-85.518875, 44.974665],
                    [-85.520034, 44.973996],
                    [-85.520789, 44.971338],
                    [-85.521896, 44.967446],
                    [-85.5221, 44.966727],
                    [-85.520698, 44.962008],
                    [-85.520443, 44.961149],
                    [-85.520205, 44.960347],
                    [-85.529233, 44.936955],
                    [-85.530477, 44.933732],
                    [-85.533553, 44.925762],
                    [-85.538288, 44.918845],
                    [-85.538945, 44.917885],
                    [-85.539703, 44.916779],
                    [-85.551567, 44.906481],
                    [-85.556471, 44.902223],
                    [-85.556647, 44.90207],
                    [-85.557257, 44.901541],
                    [-85.562503, 44.896987],
                    [-85.562936, 44.896612],
                    [-85.564509, 44.895246],
                    [-85.559524, 44.888113],
                    [-85.553509, 44.890924],
                    [-85.553348, 44.890916],
                    [-85.530649, 44.889763],
                    [-85.530711, 44.889314],
                    [-85.530729, 44.889182],
                    [-85.532931, 44.87319],
                    [-85.539042, 44.868868],
                    [-85.545891, 44.864024],
                    [-85.581392, 44.808294],
                    [-85.581717, 44.807784],
                    [-85.590985, 44.783914],
                    [-85.591124, 44.7831],
                    [-85.591145, 44.782981],
                    [-85.591852, 44.778839],
                    [-85.593449, 44.769499],
                    [-85.593474, 44.769348],
                    [-85.593571, 44.768783],
                    [-85.593833, 44.768651],
                    [-85.599103, 44.765996],
                    [-85.599256, 44.765919],
                    [-85.599874, 44.765878],
                    [-85.605586, 44.765502],
                    [-85.607701, 44.765363],
                    [-85.610776, 44.76516],
                    [-85.619648, 44.766371],
                    [-85.620551, 44.766494],
                    [-85.623607, 44.766911],
                    [-85.624541, 44.767038],
                    [-85.627982, 44.767508],
                    [-85.636097, 44.771329],
                    [-85.640216, 44.775051],
                    [-85.640781, 44.775561],
                    [-85.637, 44.790078],
                    [-85.641652, 44.810816],
                    [-85.651435, 44.831624],
                    [-85.652355, 44.849092],
                    [-85.648932, 44.87401],
                    [-85.645456, 44.883645],
                    [-85.639842, 44.890255],
                    [-85.625497, 44.921107],
                    [-85.621403, 44.923123],
                    [-85.602034, 44.926743],
                    [-85.602356, 44.974272],
                    [-85.604301, 44.990983],
                    [-85.606588, 44.990662],
                    [-85.621878, 45.004529],
                    [-85.609123, 45.013103],
                    [-85.599652, 45.021749],
                    [-85.597181, 45.040547],
                    [-85.573976, 45.043361],
                    [-85.57016, 45.041278],
                    [-85.56613, 45.043633],
                    [-85.566066, 45.059201],
                    [-85.573353, 45.068382],
                    [-85.583198, 45.071304],
                    [-85.609266, 45.11351],
                    [-85.614319, 45.127562],
                    [-85.599801, 45.149286],
                    [-85.590434, 45.153175],
                    [-85.573893, 45.155488],
                    [-85.570178, 45.155145],
                    [-85.568489, 45.153762],
                    [-85.566493, 45.153762],
                    [-85.564897, 45.153962],
                    [-85.5639, 45.154361],
                    [-85.563102, 45.155358],
                    [-85.562104, 45.156954],
                    [-85.56168, 45.15894],
                    [-85.552179, 45.167352],
                    [-85.536892, 45.173385],
                    [-85.531461, 45.177247],
                    [-85.526734, 45.189316],
                    [-85.540497, 45.210169],
                    [-85.551072, 45.210742],
                    [-85.561809, 45.200524],
                    [-85.564654, 45.192546],
                    [-85.585986, 45.180381],
                    [-85.593064, 45.178527],
                    [-85.606963, 45.178477],
                    [-85.611684, 45.181104],
                    [-85.613174, 45.184624],
                    [-85.618639, 45.186771],
                    [-85.656024, 45.145788],
                    [-85.674861, 45.116216],
                    [-85.675671, 45.10554],
                    [-85.681096, 45.092693],
                    [-85.695715, 45.076461],
                    [-85.712262, 45.065622],
                    [-85.740836, 45.055575],
                    [-85.746444, 45.051229],
                    [-85.761943, 45.023454],
                    [-85.771395, 45.015181],
                    [-85.776207, 45.000574],
                    [-85.778278, 44.983075],
                    [-85.780439, 44.977932],
                    [-85.807403, 44.949814],
                    [-85.815451, 44.945631],
                    [-85.83615, 44.940256],
                    [-85.854304, 44.938147],
                    [-85.869852, 44.939031],
                    [-85.879934, 44.943305],
                    [-85.891543, 44.957783],
                    [-85.897626, 44.962014],
                    [-85.915851, 44.968307],
                    [-85.9316, 44.968788],
                    [-85.938589, 44.964559],
                    [-85.942099, 44.954317],
                    [-85.952721, 44.940758],
                    [-85.961603, 44.935567],
                    [-85.967169, 44.929484],
                    [-85.972824, 44.914781],
                    [-85.980219, 44.906136],
                    [-85.992535, 44.900026],
                    [-86.009355, 44.899454],
                    [-86.021513, 44.902774],
                    [-86.031194, 44.907349],
                    [-86.038332, 44.915696],
                    [-86.058862, 44.911012],
                    [-86.066745, 44.905685],
                    [-86.07099, 44.895876],
                    [-86.072468, 44.884788],
                    [-86.071112, 44.86542],
                    [-86.066031, 44.834852],
                    [-86.065966, 44.821522],
                    [-86.071746, 44.804717],
                    [-86.073073, 44.778393],
                    [-86.073506, 44.769803],
                    [-86.074658, 44.766792],
                    [-86.076719, 44.761407],
                    [-86.076811, 44.761167],
                    [-86.077068, 44.760494],
                    [-86.077933, 44.758234],
                    [-86.089186, 44.741496],
                    [-86.09074, 44.740544],
                    [-86.105473, 44.731522],
                    [-86.106182, 44.731088],
                    [-86.108303, 44.730646],
                    [-86.120759, 44.728048],
                    [-86.121125, 44.727972],
                    [-86.122466, 44.727979],
                    [-86.122946, 44.727982],
                    [-86.160268, 44.728189],
                    [-86.160585, 44.727988],
                    [-86.169323, 44.722448],
                    [-86.172201, 44.720623],
                    [-86.232482, 44.70605],
                    [-86.248474, 44.699046],
                    [-86.254996, 44.691935],
                    [-86.256796, 44.686769],
                    [-86.259029, 44.663654],
                    [-86.25395, 44.64808],
                    [-86.231828, 44.609107],
                    [-86.22545, 44.59459],
                    [-86.220697, 44.566742],
                    [-86.223788, 44.549043],
                    [-86.233503, 44.518278],
                    [-86.238743, 44.501682],
                    [-86.243745, 44.488929],
                    [-86.248816, 44.483116],
                    [-86.248897, 44.483024],
                    [-86.248914, 44.483004],
                    [-86.251605, 44.465443],
                    [-86.251843, 44.451632],
                    [-86.24832, 44.434758],
                    [-86.248083, 44.420946],
                    [-86.251926, 44.400984],
                    [-86.26871, 44.345324],
                    [-86.300264, 44.308197],
                    [-86.304433, 44.301852],
                    [-86.304691, 44.301459],
                    [-86.311943, 44.290422],
                    [-86.312774, 44.289158],
                    [-86.313612, 44.287882],
                    [-86.316025, 44.28421],
                    [-86.316513, 44.283293],
                    [-86.316651, 44.283034],
                    [-86.318328, 44.279884],
                    [-86.319063, 44.278503],
                    [-86.326902, 44.263781],
                    [-86.327287, 44.263057],
                    [-86.343793, 44.249608],
                    [-86.349294, 44.235459],
                    [-86.351638, 44.229429],
                    [-86.354313, 44.224342],
                    [-86.354592, 44.223811],
                    [-86.362847, 44.208113],
                    [-86.380062, 44.189472],
                    [-86.380188, 44.189272],
                    [-86.386867, 44.178685],
                    [-86.3965, 44.163418],
                    [-86.397023, 44.162589],
                    [-86.397874, 44.161239],
                    [-86.400645, 44.156848],
                    [-86.421108, 44.12948],
                    [-86.421576, 44.128962],
                    [-86.425162, 44.124993],
                    [-86.425876, 44.124203],
                    [-86.426873, 44.123099],
                    [-86.429871, 44.119782],
                    [-86.446609, 44.106193],
                    [-86.446883, 44.10597],
                    [-86.447126, 44.105832],
                    [-86.447678, 44.105519],
                    [-86.454895, 44.101429],
                    [-86.469295, 44.093267],
                    [-86.497937, 44.077033],
                    [-86.500453, 44.075607],
                    [-86.501243, 44.074873],
                    [-86.508415, 44.068206],
                    [-86.508764, 44.067881],
                    [-86.509052, 44.067408],
                    [-86.514573, 44.05833],
                    [-86.514702, 44.058119],
                    [-86.514704, 44.057672],
                    [-86.514742, 44.04792],
                    [-86.508827, 44.032755],
                    [-86.50444, 44.026044],
                    [-86.504133, 44.025575],
                    [-86.502998, 44.023839],
                    [-86.501738, 44.021912],
                    [-86.484399, 44.002382],
                    [-86.483331, 44.001179],
                    [-86.481606, 43.998599],
                    [-86.46322, 43.971101],
                    [-86.463136, 43.970976],
                    [-86.463087, 43.970807],
                    [-86.462756, 43.969655],
                    [-86.454401, 43.940624],
                    [-86.451275, 43.929763],
                    [-86.44955, 43.92377],
                    [-86.448147, 43.918897],
                    [-86.448009, 43.918416],
                    [-86.447915, 43.918089],
                    [-86.446463, 43.901349],
                    [-86.445938, 43.89529],
                    [-86.44573, 43.892897],
                    [-86.445455, 43.889726],
                    [-86.434985, 43.858771],
                    [-86.434258, 43.856623],
                    [-86.434078, 43.85609],
                    [-86.433915, 43.855608],
                    [-86.433471, 43.853176],
                    [-86.432894, 43.850013],
                    [-86.431238, 43.840941],
                    [-86.431198, 43.84072],
                    [-86.431182, 43.838119],
                    [-86.431176, 43.837177],
                    [-86.431172, 43.836638],
                    [-86.431064, 43.819406],
                    [-86.431063, 43.819178],
                    [-86.431043, 43.815975],
                    [-86.43114, 43.815569],
                    [-86.437391, 43.789334],
                    [-86.437722, 43.788573],
                    [-86.442786, 43.776934],
                    [-86.444903, 43.772069],
                    [-86.445123, 43.771564],
                    [-86.445532, 43.770945],
                    [-86.460444, 43.748366],
                    [-86.461554, 43.746685],
                    [-86.463436, 43.744687],
                    [-86.480267, 43.726819],
                    [-86.480628, 43.726436],
                    [-86.480655, 43.726407],
                    [-86.481854, 43.725135],
                    [-86.486983, 43.720358],
                    [-86.488381, 43.719056],
                    [-86.496187, 43.711787],
                    [-86.501899, 43.706467],
                    [-86.502989, 43.705452],
                    [-86.503192, 43.705263],
                    [-86.504679, 43.703878],
                    [-86.50669, 43.702004],
                    [-86.50762, 43.701138],
                    [-86.510319, 43.698625],
                    [-86.511008, 43.697867],
                    [-86.514371, 43.69417],
                    [-86.516152, 43.692212],
                    [-86.517867, 43.690326],
                    [-86.526863, 43.680435],
                    [-86.527667, 43.679552],
                    [-86.529179, 43.677889],
                    [-86.529686, 43.676849],
                    [-86.538186, 43.659403],
                    [-86.538482, 43.658795],
                    [-86.538763, 43.657061],
                    [-86.54064, 43.645499],
                    [-86.540787, 43.644593],
                    [-86.54081, 43.642516],
                    [-86.540896, 43.634969],
                    [-86.540906, 43.634047],
                    [-86.540916, 43.633158],
                    [-86.539303, 43.62272],
                    [-86.539291, 43.622642],
                    [-86.539103, 43.621424],
                    [-86.538921, 43.620244],
                    [-86.538876, 43.619957],
                    [-86.538497, 43.617501],
                    [-86.537923, 43.615965],
                    [-86.529507, 43.593462],
                    [-86.520248, 43.576796],
                    [-86.520205, 43.576718],
                    [-86.519912, 43.576278],
                    [-86.518698, 43.574458],
                    [-86.517493, 43.572651],
                    [-86.516295, 43.570854],
                    [-86.515838, 43.570169],
                    [-86.514168, 43.567664],
                    [-86.512317, 43.564887],
                    [-86.509339, 43.560421],
                    [-86.509205, 43.560221],
                    [-86.505799, 43.555112],
                    [-86.483083, 43.521045],
                    [-86.483043, 43.520984],
                    [-86.482255, 43.519803],
                    [-86.48187, 43.519225],
                    [-86.48165, 43.518896],
                    [-86.481158, 43.518158],
                    [-86.479552, 43.51575],
                    [-86.479276, 43.515335],
                    [-86.478716, 43.514091],
                    [-86.477424, 43.511223],
                    [-86.476956, 43.510184],
                    [-86.468919, 43.492344],
                    [-86.468754, 43.491979],
                    [-86.468747, 43.491963],
                    [-86.462195, 43.472328],
                    [-86.457561, 43.458441],
                    [-86.456845, 43.456294],
                    [-86.456083, 43.45401],
                    [-86.45457, 43.449475],
                    [-86.448743, 43.432013],
                    [-86.448479, 43.431329],
                    [-86.438875, 43.406427],
                    [-86.438267, 43.404851],
                    [-86.438028, 43.404231],
                    [-86.437896, 43.40389],
                    [-86.435271, 43.397083],
                    [-86.435124, 43.396702],
                    [-86.434842, 43.3961],
                    [-86.434757, 43.395919],
                    [-86.427585, 43.380607],
                    [-86.411941, 43.347209],
                    [-86.407832, 43.338436],
                    [-86.404831, 43.33292],
                    [-86.403541, 43.330548],
                    [-86.40301, 43.329572],
                    [-86.401684, 43.327135],
                    [-86.39575, 43.316225],
                    [-86.393385, 43.312622],
                    [-86.392124, 43.310701],
                    [-86.38941, 43.306565],
                    [-86.388493, 43.305168],
                    [-86.386374, 43.301941],
                    [-86.386096, 43.301516],
                    [-86.383821, 43.298051],
                    [-86.382882, 43.29662],
                    [-86.357996, 43.258703],
                    [-86.354505, 43.253385],
                    [-86.352314, 43.250047],
                    [-86.352192, 43.24986],
                    [-86.350027, 43.246562],
                    [-86.349801, 43.246217],
                    [-86.34891, 43.24486],
                    [-86.348647, 43.244459],
                    [-86.344643, 43.23836],
                    [-86.329836, 43.2158],
                    [-86.316259, 43.195114],
                    [-86.311887, 43.187837],
                    [-86.311336, 43.18692],
                    [-86.299048, 43.166465],
                    [-86.280756, 43.136015],
                    [-86.271996, 43.118365],
                    [-86.254646, 43.083409],
                    [-86.250517, 43.066993],
                    [-86.250069, 43.057489],
                    [-86.244277, 43.049681],
                    [-86.232707, 43.015762],
                    [-86.226305, 42.988284],
                    [-86.216209, 42.919007],
                    [-86.214138, 42.883555],
                    [-86.210737, 42.859128],
                    [-86.211815, 42.833236],
                    [-86.210863, 42.783832],
                    [-86.208886, 42.76754],
                    [-86.208309, 42.762789],
                    [-86.206834, 42.719424],
                    [-86.208654, 42.69209],
                    [-86.21602, 42.664413],
                    [-86.226638, 42.644922],
                    [-86.22905, 42.637693],
                    [-86.225613, 42.594765],
                    [-86.225978, 42.593084],
                    [-86.226037, 42.592811],
                    [-86.228082, 42.583397],
                    [-86.23119, 42.575435],
                    [-86.234594, 42.566715],
                    [-86.234755, 42.566302],
                    [-86.235254, 42.565023],
                    [-86.23528, 42.564958],
                    [-86.240642, 42.54],
                    [-86.241446, 42.534697],
                    [-86.24971, 42.480212],
                    [-86.261573, 42.443894],
                    [-86.273893, 42.41928],
                    [-86.276878, 42.413317],
                    [-86.284969, 42.401814],
                    [-86.284448, 42.394563],
                    [-86.297168, 42.358207],
                    [-86.321803, 42.310743],
                    [-86.356218, 42.254166],
                    [-86.36488, 42.243133],
                    [-86.380035, 42.22383],
                    [-86.380407, 42.223357],
                    [-86.381434, 42.222048],
                    [-86.383585, 42.21931],
                    [-86.383665, 42.219207],
                    [-86.385179, 42.217279],
                    [-86.386591, 42.215723],
                    [-86.393518, 42.20809],
                    [-86.393565, 42.208039],
                    [-86.394183, 42.207357],
                    [-86.394997, 42.20646],
                    [-86.397692, 42.203491],
                    [-86.398536, 42.202561],
                    [-86.398915, 42.202143],
                    [-86.402183, 42.198542],
                    [-86.404146, 42.196379],
                    [-86.437148, 42.163453],
                    [-86.437909, 42.162694],
                    [-86.439416, 42.16119],
                    [-86.440762, 42.159847],
                    [-86.463685, 42.136977],
                    [-86.463967, 42.136696],
                    [-86.464356, 42.136308],
                    [-86.464897, 42.135767],
                    [-86.464913, 42.135752],
                    [-86.466262, 42.134406],
                    [-86.466576, 42.134138],
                    [-86.479031, 42.123519],
                    [-86.485223, 42.118239],
                    [-86.490122, 42.105139],
                    [-86.501322, 42.08454],
                    [-86.508856, 42.072986],
                    [-86.532161, 42.037246],
                    [-86.53264, 42.036512],
                    [-86.532934, 42.036061],
                    [-86.533855, 42.034648],
                    [-86.536063, 42.031262],
                    [-86.541089, 42.023555],
                    [-86.541718, 42.02259],
                    [-86.544867, 42.017761],
                    [-86.545288, 42.017115],
                    [-86.549237, 42.011059],
                    [-86.549328, 42.01092],
                    [-86.556421, 42.000042],
                    [-86.556537, 41.999782],
                    [-86.561542, 41.988559],
                    [-86.563598, 41.983948],
                    [-86.563904, 41.983262],
                    [-86.564077, 41.982874],
                    [-86.564276, 41.982427],
                    [-86.564722, 41.981427],
                    [-86.581518, 41.943765],
                    [-86.582197, 41.942241],
                    [-86.582894, 41.941179],
                    [-86.585264, 41.937562],
                    [-86.585696, 41.936904],
                    [-86.586721, 41.935341],
                    [-86.587763, 41.933752],
                    [-86.587827, 41.933653],
                    [-86.588662, 41.93238],
                    [-86.596802, 41.919964],
                    [-86.597899, 41.918291],
                    [-86.600524, 41.91531],
                    [-86.610896, 41.903532],
                    [-86.611511, 41.902833],
                    [-86.61208, 41.902188],
                    [-86.612251, 41.901993],
                    [-86.616029, 41.897703],
                    [-86.616886, 41.89673],
                    [-86.616978, 41.896625],
                    [-86.619442, 41.893827],
                    [-86.621259, 41.89234],
                    [-86.623774, 41.890282],
                    [-86.623837, 41.89023],
                    [-86.629344, 41.885723],
                    [-86.629867, 41.885295],
                    [-86.643965, 41.873757],
                    [-86.644048, 41.873689],
                    [-86.645296, 41.872668],
                    [-86.6476, 41.870782],
                    [-86.648971, 41.869659],
                    [-86.679355, 41.844793],
                    [-86.679672, 41.844579],
                    [-86.697541, 41.832513],
                    [-86.715578, 41.820334],
                    [-86.717037, 41.819349],
                    [-86.735207, 41.808901],
                    [-86.751292, 41.799652],
                    [-86.774798, 41.786137],
                    [-86.77608, 41.785399],
                    [-86.777227, 41.78474],
                    [-86.777943, 41.784371],
                    [-86.79351, 41.776359],
                    [-86.794632, 41.775782],
                    [-86.794712, 41.77574],
                    [-86.801772, 41.772107],
                    [-86.802065, 41.771956],
                    [-86.82355, 41.760898],
                    [-86.824828, 41.76024],
                    [-86.875429, 41.737939],
                    [-86.90913, 41.726938],
                    [-86.932747, 41.71104],
                    [-86.93483, 41.709638],
                    [-87.00964, 41.68152],
                    [-87.027888, 41.674661],
                    [-87.066033, 41.661845],
                    [-87.120322, 41.645701],
                    [-87.125835, 41.650302],
                    [-87.160784, 41.645385],
                    [-87.160625, 41.637266],
                    [-87.187651, 41.629653],
                    [-87.22066, 41.624356],
                    [-87.222644, 41.624161],
                    [-87.261536, 41.620336],
                    [-87.278437, 41.619736],
                    [-87.287637, 41.622236],
                    [-87.324338, 41.623036],
                    [-87.365439, 41.629536],
                    [-87.394539, 41.637235],
                    [-87.42344, 41.642835],
                    [-87.42984, 41.646035],
                    [-87.438941, 41.654335],
                    [-87.432396, 41.66053],
                    [-87.432953, 41.665102],
                    [-87.434849, 41.666887],
                    [-87.437191, 41.669006],
                    [-87.43853, 41.670679],
                    [-87.441987, 41.671905],
                    [-87.446113, 41.66934],
                    [-87.453041, 41.673035],
                    [-87.463142, 41.675535],
                    [-87.470742, 41.672835],
                    [-87.505343, 41.691535],
                    [-87.511043, 41.696535],
                    [-87.515243, 41.704235],
                    [-87.520544, 41.709935],
                    [-87.524044, 41.708335],
                    [-87.524141, 41.72399],
                    [-87.530745, 41.748235],
                    [-87.542845, 41.752135],
                    [-87.560646, 41.766034],
                    [-87.576347, 41.786034],
                    [-87.58055, 41.80275],
                    [-87.58092, 41.804225],
                    [-87.580948, 41.804334],
                    [-87.587054, 41.811342],
                    [-87.587123, 41.811422],
                    [-87.600549, 41.826833],
                    [-87.60945, 41.845233],
                    [-87.616251, 41.868933],
                    [-87.616537, 41.882396],
                    [-87.615734, 41.882958],
                    [-87.613654, 41.884412],
                    [-87.613556, 41.88448],
                    [-87.614188, 41.888421],
                    [-87.61268, 41.889248],
                    [-87.611659, 41.890708],
                    [-87.611659, 41.892216],
                    [-87.612291, 41.893335],
                    [-87.614163, 41.893418],
                    [-87.617433, 41.898032],
                    [-87.619852, 41.901392],
                    [-87.622944, 41.90202],
                    [-87.624052, 41.904232],
                    [-87.624134, 41.904574],
                    [-87.624763, 41.907209],
                    [-87.627038, 41.916738],
                    [-87.63087, 41.932784],
                    [-87.630953, 41.933132],
                    [-87.668982, 42.029142],
                    [-87.671094, 42.042802],
                    [-87.671894, 42.047972],
                    [-87.671184, 42.050548],
                    [-87.671119, 42.050781],
                    [-87.670699, 42.052305],
                    [-87.670512, 42.05298],
                    [-87.671462, 42.058334],
                    [-87.679843, 42.071713],
                    [-87.68075, 42.07316],
                    [-87.682179, 42.075441],
                    [-87.682359, 42.075729],
                    [-87.70237, 42.089442],
                    [-87.703882, 42.090478],
                    [-87.704594, 42.090966],
                    [-87.706415, 42.092213],
                    [-87.71096, 42.095328],
                    [-87.712206, 42.096455],
                    [-87.717169, 42.100947],
                    [-87.717276, 42.101044],
                    [-87.717504, 42.10125],
                    [-87.718065, 42.101758],
                    [-87.720303, 42.103783],
                    [-87.720407, 42.103878],
                    [-87.7215, 42.104866],
                    [-87.72222, 42.105518],
                    [-87.722306, 42.105596],
                    [-87.723723, 42.106879],
                    [-87.724603, 42.107674],
                    [-87.724661, 42.107727],
                    [-87.725196, 42.108373],
                    [-87.727005, 42.110554],
                    [-87.727248, 42.110847],
                    [-87.728191, 42.111984],
                    [-87.728335, 42.112157],
                    [-87.729378, 42.113414],
                    [-87.729387, 42.113426],
                    [-87.730463, 42.114723],
                    [-87.733929, 42.118903],
                    [-87.739529, 42.125655],
                    [-87.739735, 42.125903],
                    [-87.740718, 42.127089],
                    [-87.740787, 42.127172],
                    [-87.741318, 42.127812],
                    [-87.741662, 42.128227],
                    [-87.746421, 42.134729],
                    [-87.748486, 42.13755],
                    [-87.750396, 42.14016],
                    [-87.754358, 42.145573],
                    [-87.754444, 42.145691],
                    [-87.754767, 42.146132],
                    [-87.755826, 42.147579],
                    [-87.75639, 42.14835],
                    [-87.759327, 42.152362],
                    [-87.775295, 42.174179],
                    [-87.787241, 42.190501],
                    [-87.788313, 42.191966],
                    [-87.796779, 42.203533],
                    [-87.796937, 42.203748],
                    [-87.797444, 42.204442],
                    [-87.797497, 42.204514],
                    [-87.7983, 42.205611],
                    [-87.798589, 42.206007],
                    [-87.800066, 42.208024],
                    [-87.803873, 42.215449],
                    [-87.808395, 42.224271],
                    [-87.812267, 42.231823],
                    [-87.812315, 42.231935],
                    [-87.812422, 42.232185],
                    [-87.812461, 42.232278],
                    [-87.825313, 42.262313],
                    [-87.826944, 42.266125],
                    [-87.82772, 42.267937],
                    [-87.828034, 42.268673],
                    [-87.828569, 42.269922],
                    [-87.831286, 42.283772],
                    [-87.83253, 42.290109],
                    [-87.832738, 42.291173],
                    [-87.833468, 42.294892],
                    [-87.834769, 42.301522],
                    [-87.831221, 42.32853],
                    [-87.830986, 42.330317],
                    [-87.830646, 42.331368],
                    [-87.826717, 42.343497],
                    [-87.820871, 42.361544],
                    [-87.820858, 42.361584],
                    [-87.81657, 42.364621],
                    [-87.81557, 42.366416],
                    [-87.80613, 42.383357],
                    [-87.80537, 42.384721],
                    [-87.80375, 42.413793],
                    [-87.803529, 42.417759],
                    [-87.80337, 42.420621],
                    [-87.800752, 42.445867],
                    [-87.798071, 42.471721],
                    [-87.79823, 42.473054],
                    [-87.798971, 42.479278],
                    [-87.800317, 42.490578],
                    [-87.800477, 42.49192],
                    [-87.809672, 42.51482],
                    [-87.812273, 42.52982],
                    [-87.812632, 42.547562],
                    [-87.812724, 42.552104],
                    [-87.812851, 42.558352],
                    [-87.813135, 42.572424],
                    [-87.813142, 42.572768],
                    [-87.813223, 42.576759],
                    [-87.813273, 42.57922],
                    [-87.812871, 42.580576],
                    [-87.811617, 42.584809],
                    [-87.810873, 42.58732],
                    [-87.815074, 42.59412],
                    [-87.815736, 42.596045],
                    [-87.819374, 42.60662],
                    [-87.819674, 42.61582],
                    [-87.819407, 42.617327],
                    [-87.814674, 42.64402],
                    [-87.813445, 42.647345],
                    [-87.811371, 42.652959],
                    [-87.809885, 42.656982],
                    [-87.80988, 42.656997],
                    [-87.807082, 42.664571],
                    [-87.805768, 42.668128],
                    [-87.805421, 42.669067],
                    [-87.803127, 42.675276],
                    [-87.803074, 42.675419],
                    [-87.803028, 42.675501],
                    [-87.802989, 42.675569],
                    [-87.802876, 42.675768],
                    [-87.802377, 42.676651],
                    [-87.794874, 42.689919],
                    [-87.786774, 42.700719],
                    [-87.785074, 42.700819],
                    [-87.784015, 42.70372],
                    [-87.783489, 42.705164],
                    [-87.782374, 42.708219],
                    [-87.782023, 42.710008],
                    [-87.78032, 42.718679],
                    [-87.778627, 42.727299],
                    [-87.778824, 42.728432],
                    [-87.779527, 42.732482],
                    [-87.781333, 42.742879],
                    [-87.781642, 42.744656],
                    [-87.781655, 42.744733],
                    [-87.781849, 42.745847],
                    [-87.782174, 42.747719],
                    [-87.781949, 42.74857],
                    [-87.781016, 42.75209],
                    [-87.780782, 42.752973],
                    [-87.778774, 42.760556],
                    [-87.778174, 42.762819],
                    [-87.777432, 42.763781],
                    [-87.77134, 42.771687],
                    [-87.769774, 42.773719],
                    [-87.766675, 42.784896],
                    [-87.773623, 42.793388],
                    [-87.773699, 42.793481],
                    [-87.785936, 42.801168],
                    [-87.786291, 42.801391],
                    [-87.789953, 42.803691],
                    [-87.793976, 42.806218],
                    [-87.796201, 42.808428],
                    [-87.803773, 42.815948],
                    [-87.804033, 42.816205],
                    [-87.822324, 42.834371],
                    [-87.823278, 42.835318],
                    [-87.824, 42.836649],
                    [-87.825352, 42.839144],
                    [-87.827021, 42.842222],
                    [-87.831176, 42.849886],
                    [-87.834879, 42.856717],
                    [-87.845811, 42.884256],
                    [-87.84778, 42.889216],
                    [-87.847745, 42.889595],
                    [-87.846708, 42.900756],
                    [-87.844578, 42.923688],
                    [-87.842681, 42.944116],
                    [-87.84276, 42.944679],
                    [-87.842786, 42.944865],
                    [-87.843575, 42.950519],
                    [-87.843835, 42.952375],
                    [-87.844739, 42.958848],
                    [-87.845181, 42.962015],
                    [-87.857182, 42.978015],
                    [-87.878683, 42.992415],
                    [-87.887683, 43.000514],
                    [-87.887703, 43.000552],
                    [-87.887789, 43.000715],
                    [-87.888804, 43.002631],
                    [-87.889095, 43.003181],
                    [-87.889342, 43.003647],
                    [-87.893511, 43.011521],
                    [-87.895784, 43.015814],
                    [-87.896157, 43.017486],
                    [-87.896398, 43.018565],
                    [-87.896647, 43.01968],
                    [-87.896836, 43.02053],
                    [-87.898684, 43.028813],
                    [-87.898184, 43.030689],
                    [-87.896598, 43.036637],
                    [-87.896227, 43.038028],
                    [-87.895127, 43.042152],
                    [-87.895084, 43.042313],
                    [-87.895028, 43.042351],
                    [-87.894813, 43.042497],
                    [-87.889883, 43.045834],
                    [-87.882084, 43.051113],
                    [-87.882069, 43.05113],
                    [-87.88193, 43.051285],
                    [-87.876343, 43.057529],
                    [-87.87546, 43.058516],
                    [-87.870217, 43.064376],
                    [-87.870184, 43.064412],
                    [-87.866484, 43.074412],
                    [-87.866487, 43.074419],
                    [-87.870459, 43.084598],
                    [-87.871928, 43.088363],
                    [-87.872255, 43.089199],
                    [-87.872352, 43.089449],
                    [-87.872362, 43.089474],
                    [-87.872493, 43.08981],
                    [-87.876084, 43.099011],
                    [-87.877915, 43.100617],
                    [-87.879169, 43.101717],
                    [-87.893185, 43.114011],
                    [-87.900463, 43.125874],
                    [-87.900485, 43.12591],
                    [-87.900496, 43.126],
                    [-87.901359, 43.133002],
                    [-87.901385, 43.13321],
                    [-87.901345, 43.133357],
                    [-87.900285, 43.13731],
                    [-87.89902, 43.139112],
                    [-87.898105, 43.140417],
                    [-87.896008, 43.143405],
                    [-87.892285, 43.14871],
                    [-87.891059, 43.151108],
                    [-87.889634, 43.153894],
                    [-87.889375, 43.1544],
                    [-87.889327, 43.154493],
                    [-87.888849, 43.155429],
                    [-87.88638, 43.160255],
                    [-87.881085, 43.170609],
                    [-87.884769, 43.179674],
                    [-87.884872, 43.179929],
                    [-87.884878, 43.179944],
                    [-87.886266, 43.183359],
                    [-87.887586, 43.186608],
                    [-87.88857, 43.187795],
                    [-87.89209, 43.192044],
                    [-87.895554, 43.196225],
                    [-87.896286, 43.197108],
                    [-87.897125, 43.199468],
                    [-87.897316, 43.200003],
                    [-87.897326, 43.200031],
                    [-87.897331, 43.200046],
                    [-87.910087, 43.235907],
                    [-87.910172, 43.236634],
                    [-87.911512, 43.248064],
                    [-87.911787, 43.250406],
                    [-87.910345, 43.255296],
                    [-87.909053, 43.259678],
                    [-87.907306, 43.265604],
                    [-87.907291, 43.265653],
                    [-87.907289, 43.265661],
                    [-87.906611, 43.26796],
                    [-87.906605, 43.26798],
                    [-87.906599, 43.268002],
                    [-87.906583, 43.268055],
                    [-87.906565, 43.268115],
                    [-87.906502, 43.26833],
                    [-87.906483, 43.268393],
                    [-87.906465, 43.268454],
                    [-87.904251, 43.275962],
                    [-87.903451, 43.278676],
                    [-87.903141, 43.279728],
                    [-87.903094, 43.279887],
                    [-87.902967, 43.280319],
                    [-87.901847, 43.284117],
                    [-87.900255, 43.287081],
                    [-87.897813, 43.291628],
                    [-87.889207, 43.307652],
                    [-87.888342, 43.313294],
                    [-87.888335, 43.313342],
                    [-87.888328, 43.313388],
                    [-87.888314, 43.313476],
                    [-87.888312, 43.313489],
                    [-87.888122, 43.314731],
                    [-87.882392, 43.352099],
                    [-87.877448, 43.369235],
                    [-87.872504, 43.380178],
                    [-87.869099, 43.386295],
                    [-87.867504, 43.389158],
                    [-87.865048, 43.39357],
                    [-87.855608, 43.405441],
                    [-87.827319, 43.434849],
                    [-87.807799, 43.461136],
                    [-87.793239, 43.492783],
                    [-87.797336, 43.510623],
                    [-87.797608, 43.52731],
                    [-87.794321, 43.543034],
                    [-87.793075, 43.54899],
                    [-87.790277, 43.562376],
                    [-87.790135, 43.563054],
                    [-87.789105, 43.564844],
                    [-87.782271, 43.576726],
                    [-87.781255, 43.578493],
                    [-87.780175, 43.579889],
                    [-87.742522, 43.628574],
                    [-87.742485, 43.628622],
                    [-87.738638, 43.633597],
                    [-87.737859, 43.634604],
                    [-87.736146, 43.636819],
                    [-87.735972, 43.637044],
                    [-87.735489, 43.637668],
                    [-87.735065, 43.638217],
                    [-87.734312, 43.63919],
                    [-87.733622, 43.640181],
                    [-87.72646, 43.650462],
                    [-87.706242, 43.679488],
                    [-87.706204, 43.679542],
                    [-87.703671, 43.685339],
                    [-87.702685, 43.687596],
                    [-87.702985, 43.706395],
                    [-87.703144, 43.706901],
                    [-87.707923, 43.722064],
                    [-87.708185, 43.722895],
                    [-87.709885, 43.735795],
                    [-87.708321, 43.742737],
                    [-87.708285, 43.742895],
                    [-87.708167, 43.742979],
                    [-87.705185, 43.745095],
                    [-87.70463, 43.746256],
                    [-87.703047, 43.749565],
                    [-87.702985, 43.749695],
                    [-87.700085, 43.761395],
                    [-87.70009, 43.76159],
                    [-87.700245, 43.767129],
                    [-87.700251, 43.76735],
                    [-87.726405, 43.810442],
                    [-87.726407, 43.810445],
                    [-87.726408, 43.810454],
                    [-87.726772, 43.812885],
                    [-87.727686, 43.818989],
                    [-87.7296, 43.831782],
                    [-87.729217, 43.840582],
                    [-87.728728, 43.851833],
                    [-87.728698, 43.852524],
                    [-87.734881, 43.87043],
                    [-87.736017, 43.873721],
                    [-87.736178, 43.880421],
                    [-87.735436, 43.882219],
                    [-87.734457, 43.88459],
                    [-87.733735, 43.88634],
                    [-87.731322, 43.892184],
                    [-87.730645, 43.893825],
                    [-87.73063, 43.893862],
                    [-87.728885, 43.89809],
                    [-87.728542, 43.89892],
                    [-87.726841, 43.90304],
                    [-87.726803, 43.903133],
                    [-87.726766, 43.903297],
                    [-87.724244, 43.914557],
                    [-87.723716, 43.916915],
                    [-87.7221, 43.924128],
                    [-87.721268, 43.92784],
                    [-87.720734, 43.930224],
                    [-87.720382, 43.931794],
                    [-87.720121, 43.932958],
                    [-87.719194, 43.9371],
                    [-87.719041, 43.93778],
                    [-87.719041, 43.937781],
                    [-87.71817, 43.939498],
                    [-87.716037, 43.943705],
                    [-87.709463, 43.950747],
                    [-87.706656, 43.953753],
                    [-87.704679, 43.955872],
                    [-87.703951, 43.956651],
                    [-87.702719, 43.958926],
                    [-87.702707, 43.958946],
                    [-87.700321, 43.963351],
                    [-87.700125, 43.963712],
                    [-87.699029, 43.965734],
                    [-87.69892, 43.965936],
                    [-87.695511, 43.989524],
                    [-87.695503, 43.989582],
                    [-87.695053, 43.990715],
                    [-87.691479, 43.99971],
                    [-87.686389, 44.01252],
                    [-87.683361, 44.020139],
                    [-87.671316, 44.03735],
                    [-87.656083, 44.051794],
                    [-87.656062, 44.051919],
                    [-87.653691, 44.065961],
                    [-87.653483, 44.067194],
                    [-87.655183, 44.081894],
                    [-87.654935, 44.082552],
                    [-87.651507, 44.091639],
                    [-87.647603, 44.101991],
                    [-87.647551, 44.102128],
                    [-87.646719, 44.104335],
                    [-87.646583, 44.104694],
                    [-87.6458, 44.105222],
                    [-87.621082, 44.121895],
                    [-87.603572, 44.13039],
                    [-87.600882, 44.131695],
                    [-87.599055, 44.132301],
                    [-87.59791, 44.13268],
                    [-87.59668, 44.133088],
                    [-87.570533, 44.141757],
                    [-87.567817, 44.142658],
                    [-87.567045, 44.142914],
                    [-87.563551, 44.144072],
                    [-87.563181, 44.144195],
                    [-87.550076, 44.152933],
                    [-87.53994, 44.15969],
                    [-87.51966, 44.17987],
                    [-87.512903, 44.192808],
                    [-87.507419, 44.210803],
                    [-87.508412, 44.229546],
                    [-87.508419, 44.229669],
                    [-87.508457, 44.229755],
                    [-87.515314, 44.245328],
                    [-87.515399, 44.245522],
                    [-87.515406, 44.245537],
                    [-87.521318, 44.258965],
                    [-87.52133, 44.258993],
                    [-87.521755, 44.259957],
                    [-87.521862, 44.26014],
                    [-87.522557, 44.261329],
                    [-87.52284, 44.261813],
                    [-87.523179, 44.262393],
                    [-87.524193, 44.264128],
                    [-87.524459, 44.264583],
                    [-87.524691, 44.264979],
                    [-87.525211, 44.265869],
                    [-87.525294, 44.26601],
                    [-87.525455, 44.266285],
                    [-87.525485, 44.266338],
                    [-87.525643, 44.266609],
                    [-87.525703, 44.266711],
                    [-87.525797, 44.266871],
                    [-87.525824, 44.266918],
                    [-87.526226, 44.267605],
                    [-87.526391, 44.267888],
                    [-87.526466, 44.268016],
                    [-87.541053, 44.292968],
                    [-87.541155, 44.293143],
                    [-87.541156, 44.293147],
                    [-87.541265, 44.293567],
                    [-87.541382, 44.294018],
                    [-87.544675, 44.306707],
                    [-87.544716, 44.306864],
                    [-87.544725, 44.307068],
                    [-87.545382, 44.321385],
                    [-87.54295, 44.327513],
                    [-87.541517, 44.331123],
                    [-87.533606, 44.351052],
                    [-87.533583, 44.351111],
                    [-87.521663, 44.366719],
                    [-87.521047, 44.367526],
                    [-87.519106, 44.372122],
                    [-87.518094, 44.374519],
                    [-87.51774, 44.375357],
                    [-87.517597, 44.375696],
                    [-87.517881, 44.390082],
                    [-87.517965, 44.394356],
                    [-87.515482, 44.402077],
                    [-87.512886, 44.410153],
                    [-87.512311, 44.411941],
                    [-87.511635, 44.414043],
                    [-87.511387, 44.414502],
                    [-87.507651, 44.421418],
                    [-87.506362, 44.423804],
                    [-87.502309, 44.443219],
                    [-87.499719, 44.455625],
                    [-87.499674, 44.455837],
                    [-87.499349, 44.457395],
                    [-87.498934, 44.459384],
                    [-87.498872, 44.45968],
                    [-87.49886, 44.459737],
                    [-87.498662, 44.460686],
                    [-87.495252, 44.467215],
                    [-87.495176, 44.46736],
                    [-87.494678, 44.468314],
                    [-87.492333, 44.472804],
                    [-87.490024, 44.477224],
                    [-87.486822, 44.491477],
                    [-87.484042, 44.503856],
                    [-87.483914, 44.504425],
                    [-87.483873, 44.505742],
                    [-87.483696, 44.511354],
                    [-87.481884, 44.516065],
                    [-87.472934, 44.539338],
                    [-87.468093, 44.551925],
                    [-87.467398, 44.553055],
                    [-87.467214, 44.553355],
                    [-87.467089, 44.553557],
                    [-87.446963, 44.586274],
                    [-87.437751, 44.604559],
                    [-87.437493, 44.605071],
                    [-87.435766, 44.606472],
                    [-87.418028, 44.62087],
                    [-87.40541, 44.627191],
                    [-87.403724, 44.628974],
                    [-87.401629, 44.631191],
                    [-87.393521, 44.640655],
                    [-87.38684, 44.652157],
                    [-87.37849, 44.666534],
                    [-87.373228, 44.675593],
                    [-87.368263, 44.684141],
                    [-87.367992, 44.684474],
                    [-87.36115, 44.692876],
                    [-87.354249, 44.70135],
                    [-87.354172, 44.701445],
                    [-87.353789, 44.701915],
                    [-87.349559, 44.709039],
                    [-87.348082, 44.711526],
                    [-87.343508, 44.719228],
                    [-87.341491, 44.725535],
                    [-87.341226, 44.726364],
                    [-87.339904, 44.730498],
                    [-87.339155, 44.732839],
                    [-87.337584, 44.737751],
                    [-87.320855, 44.767953],
                    [-87.319903, 44.769672],
                    [-87.318982, 44.771335],
                    [-87.319013, 44.771632],
                    [-87.31903, 44.771794],
                    [-87.319169, 44.773135],
                    [-87.319796, 44.77917],
                    [-87.320397, 44.784963],
                    [-87.315813, 44.791034],
                    [-87.314247, 44.793109],
                    [-87.31388, 44.793595],
                    [-87.313751, 44.793766],
                    [-87.313692, 44.793838],
                    [-87.313363, 44.794237],
                    [-87.307482, 44.801377],
                    [-87.304824, 44.804603],
                    [-87.294869, 44.809131],
                    [-87.282561, 44.814729],
                    [-87.27603, 44.83318],
                    [-87.274769, 44.835187],
                    [-87.267564, 44.846655],
                    [-87.267441, 44.846851],
                    [-87.267061, 44.847025],
                    [-87.246171, 44.856571],
                    [-87.224891, 44.866295],
                    [-87.211398, 44.872461],
                    [-87.204904, 44.875429],
                    [-87.204545, 44.875593],
                    [-87.204809, 44.877159],
                    [-87.204815, 44.877199],
                    [-87.206184, 44.885329],
                    [-87.206285, 44.885928],
                    [-87.209265, 44.889182],
                    [-87.212587, 44.892809],
                    [-87.21671, 44.897309],
                    [-87.217149, 44.897789],
                    [-87.217195, 44.897839],
                    [-87.217171, 44.898013],
                    [-87.216146, 44.905379],
                    [-87.216074, 44.9059],
                    [-87.216059, 44.906009],
                    [-87.215977, 44.906597],
                    [-87.215808, 44.906744],
                    [-87.204238, 44.916819],
                    [-87.187984, 44.924845],
                    [-87.186732, 44.925463],
                    [-87.185858, 44.925632],
                    [-87.182421, 44.926297],
                    [-87.17492, 44.927749],
                    [-87.1717, 44.931476],
                    [-87.17524, 44.939753],
                    [-87.179974, 44.942753],
                    [-87.188074, 44.947886],
                    [-87.188375, 44.948077],
                    [-87.188399, 44.94856],
                    [-87.188582, 44.952193],
                    [-87.189383, 44.968164],
                    [-87.189402, 44.968524],
                    [-87.189407, 44.968632],
                    [-87.189134, 44.969078],
                    [-87.187585, 44.971606],
                    [-87.182922, 44.979221],
                    [-87.181901, 44.980887],
                    [-87.181428, 44.981505],
                    [-87.169694, 44.996836],
                    [-87.169676, 44.99686],
                    [-87.169494, 44.997096],
                    [-87.16797, 44.999088],
                    [-87.167474, 44.999736],
                    [-87.163561, 45.004848],
                    [-87.163529, 45.00489],
                    [-87.163477, 45.004913],
                    [-87.154084, 45.009117],
                    [-87.141048, 45.012175],
                    [-87.139384, 45.012565],
                    [-87.138024, 45.015327],
                    [-87.134354, 45.02278],
                    [-87.132344, 45.026862],
                    [-87.124808, 45.042167],
                    [-87.124797, 45.043262],
                    [-87.124788, 45.044141],
                    [-87.124744, 45.048608],
                    [-87.124737, 45.049268],
                    [-87.124727, 45.050344],
                    [-87.124722, 45.050833],
                    [-87.124701, 45.052936],
                    [-87.121156, 45.058311],
                    [-87.097479, 45.055771],
                    [-87.096387, 45.055654],
                    [-87.092177, 45.055203],
                    [-87.091761, 45.055158],
                    [-87.091639, 45.055145],
                    [-87.090849, 45.055465],
                    [-87.086351, 45.057287],
                    [-87.082419, 45.058879],
                    [-87.081866, 45.059103],
                    [-87.080755, 45.06471],
                    [-87.080271, 45.067154],
                    [-87.079555, 45.07077],
                    [-87.079552, 45.070783],
                    [-87.064864, 45.078427],
                    [-87.063376, 45.079202],
                    [-87.063157, 45.079316],
                    [-87.063129, 45.079356],
                    [-87.061966, 45.081015],
                    [-87.058259, 45.086304],
                    [-87.057444, 45.087467],
                    [-87.057415, 45.087472],
                    [-87.05078, 45.088663],
                    [-87.048359, 45.089098],
                    [-87.048213, 45.089124],
                    [-87.048346, 45.092647],
                    [-87.048406, 45.094259],
                    [-87.048911, 45.107665],
                    [-87.048951, 45.108718],
                    [-87.049231, 45.109712],
                    [-87.049268, 45.109845],
                    [-87.049346, 45.110122],
                    [-87.05075, 45.115111],
                    [-87.051049, 45.116172],
                    [-87.054342, 45.119968],
                    [-87.054282, 45.120074],
                    [-87.045748, 45.134987],
                    [-87.042286, 45.136841],
                    [-87.042114, 45.136933],
                    [-87.041564, 45.137228],
                    [-87.040175, 45.137972],
                    [-87.035838, 45.140294],
                    [-87.035465, 45.140494],
                    [-87.034667, 45.140921],
                    [-87.034621, 45.140946],
                    [-87.03355, 45.141519],
                    [-87.033546, 45.141522],
                    [-87.033117, 45.141752],
                    [-87.033114, 45.141753],
                    [-87.03292, 45.141963],
                    [-87.032461, 45.14246],
                    [-87.032344, 45.142586],
                    [-87.032194, 45.142748],
                    [-87.031307, 45.143709],
                    [-87.031167, 45.14386],
                    [-87.030126, 45.144986],
                    [-87.030105, 45.145009],
                    [-87.028957, 45.14625],
                    [-87.028847, 45.14637],
                    [-87.02898, 45.146467],
                    [-87.029164, 45.146603],
                    [-87.030225, 45.147382],
                    [-87.034083, 45.150216],
                    [-87.036716, 45.15215],
                    [-87.038479, 45.153445],
                    [-87.041145, 45.155404],
                    [-87.044176, 45.15763],
                    [-87.045225, 45.158401],
                    [-87.045242, 45.158798],
                    [-87.04548, 45.164271],
                    [-87.04566, 45.168403],
                    [-87.045681, 45.168889],
                    [-87.045687, 45.169016],
                    [-87.045701, 45.169351],
                    [-87.045883, 45.173543],
                    [-87.045899, 45.173902],
                    [-87.041106, 45.210052],
                    [-87.041075, 45.210283],
                    [-87.041063, 45.210375],
                    [-87.040924, 45.211421],
                    [-87.040909, 45.211535],
                    [-87.034524, 45.219734],
                    [-87.032576, 45.222235],
                    [-87.032546, 45.222274],
                    [-87.032521, 45.222274],
                    [-87.007583, 45.222127],
                    [-87.00754, 45.222127],
                    [-87.005468, 45.214114],
                    [-87.005359, 45.213694],
                    [-87.005243, 45.213607],
                    [-87.002806, 45.211773],
                    [-87.000112, 45.212429],
                    [-86.997316, 45.21311],
                    [-86.995804, 45.213478],
                    [-86.988075, 45.21536],
                    [-86.985973, 45.215872],
                    [-86.983708, 45.21947],
                    [-86.983001, 45.220593],
                    [-86.981956, 45.222254],
                    [-86.98108, 45.223646],
                    [-86.978759, 45.227333],
                    [-86.978697, 45.227538],
                    [-86.974086, 45.242864],
                    [-86.973651, 45.244308],
                    [-86.973483, 45.244867],
                    [-86.97346, 45.244944],
                    [-86.973453, 45.244966],
                    [-86.973022, 45.246399],
                    [-86.973287, 45.246381],
                    [-86.976711, 45.246146],
                    [-86.982393, 45.24898],
                    [-86.982669, 45.249117],
                    [-86.983066, 45.250764],
                    [-86.984172, 45.255346],
                    [-86.984975, 45.258674],
                    [-86.984938, 45.259036],
                    [-86.984495, 45.263409],
                    [-86.984398, 45.264366],
                    [-86.983597, 45.264971],
                    [-86.977355, 45.269687],
                    [-86.974622, 45.271752],
                    [-86.974528, 45.271823],
                    [-86.974346, 45.272113],
                    [-86.973227, 45.27389],
                    [-86.972786, 45.274591],
                    [-86.970355, 45.278455],
                    [-86.977714, 45.290575],
                    [-86.97778, 45.290684],
                    [-86.980058, 45.292598],
                    [-86.983227, 45.29526],
                    [-86.983355, 45.295368],
                    [-86.98388, 45.295499],
                    [-86.984223, 45.295585],
                    [-86.993201, 45.297833],
                    [-86.993528, 45.297915],
                    [-86.994112, 45.298061],
                    [-87.015797, 45.29919],
                    [-87.017036, 45.299254],
                    [-87.021088, 45.296541],
                    [-87.034077, 45.287844],
                    [-87.034206, 45.287758],
                    [-87.041463, 45.285518],
                    [-87.04313, 45.285003],
                    [-87.043497, 45.28489],
                    [-87.043724, 45.28482],
                    [-87.043895, 45.284767],
                    [-87.048745, 45.285464],
                    [-87.050796, 45.285758],
                    [-87.0517, 45.285888],
                    [-87.051979, 45.286215],
                    [-87.052872, 45.287262],
                    [-87.052927, 45.287326],
                    [-87.053417, 45.287901],
                    [-87.057627, 45.292838],
                    [-87.070845, 45.280532],
                    [-87.071035, 45.280355],
                    [-87.078316, 45.265723],
                    [-87.101681, 45.259027],
                    [-87.10843, 45.257093],
                    [-87.108743, 45.257003],
                    [-87.109014, 45.256458],
                    [-87.109541, 45.255397],
                    [-87.116432, 45.24152],
                    [-87.116456, 45.24145],
                    [-87.117391, 45.23874],
                    [-87.121266, 45.2275],
                    [-87.121288, 45.227438],
                    [-87.121667, 45.226337],
                    [-87.122819, 45.222997],
                    [-87.122708, 45.221786],
                    [-87.122364, 45.218023],
                    [-87.122248, 45.216764],
                    [-87.122226, 45.216519],
                    [-87.122194, 45.216168],
                    [-87.122098, 45.215121],
                    [-87.121609, 45.209783],
                    [-87.121566, 45.209698],
                    [-87.121541, 45.209649],
                    [-87.119654, 45.20593],
                    [-87.11945, 45.205529],
                    [-87.119405, 45.20544],
                    [-87.119413, 45.205237],
                    [-87.119511, 45.202754],
                    [-87.119522, 45.202488],
                    [-87.119534, 45.202184],
                    [-87.119768, 45.196271],
                    [-87.11978, 45.195965],
                    [-87.119784, 45.195866],
                    [-87.119822, 45.194908],
                    [-87.119825, 45.194814],
                    [-87.119864, 45.193843],
                    [-87.119868, 45.193724],
                    [-87.119874, 45.193581],
                    [-87.119876, 45.193527],
                    [-87.119887, 45.193242],
                    [-87.119894, 45.193071],
                    [-87.119921, 45.192386],
                    [-87.119925, 45.19228],
                    [-87.119968, 45.191198],
                    [-87.119971, 45.191125],
                    [-87.119972, 45.191103],
                    [-87.123689, 45.18989],
                    [-87.124549, 45.190162],
                    [-87.128597, 45.191441],
                    [-87.12981, 45.191825],
                    [-87.132874, 45.192794],
                    [-87.13303, 45.192843],
                    [-87.142708, 45.191437],
                    [-87.147657, 45.190719],
                    [-87.147709, 45.190711],
                    [-87.163169, 45.185331],
                    [-87.167179, 45.183594],
                    [-87.168252, 45.18216],
                    [-87.168298, 45.182099],
                    [-87.170062, 45.179741],
                    [-87.170266, 45.179468],
                    [-87.171233, 45.178175],
                    [-87.171272, 45.178123],
                    [-87.173722, 45.17485],
                    [-87.175068, 45.17305],
                    [-87.17517, 45.173],
                    [-87.195213, 45.16311],
                    [-87.195876, 45.163201],
                    [-87.200385, 45.163819],
                    [-87.207963, 45.164857],
                    [-87.213483, 45.165614],
                    [-87.21437, 45.165735],
                    [-87.220937, 45.173773],
                    [-87.221707, 45.174716],
                    [-87.221971, 45.175039],
                    [-87.222316, 45.174959],
                    [-87.224065, 45.174551],
                    [-87.231214, 45.172887],
                    [-87.231925, 45.172316],
                    [-87.23822, 45.167262],
                    [-87.238224, 45.167259],
                    [-87.238229, 45.167238],
                    [-87.238426, 45.166492],
                    [-87.242924, 45.149377],
                    [-87.242331, 45.146773],
                    [-87.240308, 45.137886],
                    [-87.240813, 45.137559],
                    [-87.250487, 45.131289],
                    [-87.254072, 45.126323],
                    [-87.257449, 45.121644],
                    [-87.260595, 45.106007],
                    [-87.260542, 45.092585],
                    [-87.260631, 45.092355],
                    [-87.264877, 45.081361],
                    [-87.268695, 45.077884],
                    [-87.268737, 45.077847],
                    [-87.269231, 45.077396],
                    [-87.284208, 45.06376],
                    [-87.28428, 45.063694],
                    [-87.300146, 45.054075],
                    [-87.300724, 45.053724],
                    [-87.300887, 45.053625],
                    [-87.30247, 45.052666],
                    [-87.302831, 45.052447],
                    [-87.303149, 45.052146],
                    [-87.30342, 45.05189],
                    [-87.303475, 45.051838],
                    [-87.307394, 45.04813],
                    [-87.315879, 45.040103],
                    [-87.322022, 45.03429],
                    [-87.322117, 45.034201],
                    [-87.336457, 45.01353],
                    [-87.336548, 45.013431],
                    [-87.345091, 45.004151],
                    [-87.351487, 44.997203],
                    [-87.360288, 44.987643],
                    [-87.363492, 44.980798],
                    [-87.370075, 44.966736],
                    [-87.371854, 44.962935],
                    [-87.372568, 44.96141],
                    [-87.372599, 44.961343],
                    [-87.373046, 44.960388],
                    [-87.374805, 44.956631],
                    [-87.382436, 44.94608],
                    [-87.384669, 44.942993],
                    [-87.385066, 44.942445],
                    [-87.387253, 44.939421],
                    [-87.389006, 44.937988],
                    [-87.393058, 44.934677],
                    [-87.393405, 44.934393],
                    [-87.393672, 44.933899],
                    [-87.393752, 44.933751],
                    [-87.398368, 44.925226],
                    [-87.405005, 44.911806],
                    [-87.405361, 44.909626],
                    [-87.406199, 44.90449],
                    [-87.401947, 44.9024],
                    [-87.400488, 44.901683],
                    [-87.397359, 44.900145],
                    [-87.397184, 44.900059],
                    [-87.396972, 44.899955],
                    [-87.393399, 44.898199],
                    [-87.39227, 44.897037],
                    [-87.39198, 44.896739],
                    [-87.39107, 44.895803],
                    [-87.3908, 44.895524],
                    [-87.390651, 44.895372],
                    [-87.390604, 44.895323],
                    [-87.38649, 44.89109],
                    [-87.386396, 44.890993],
                    [-87.386375, 44.890972],
                    [-87.386111, 44.890699],
                    [-87.385396, 44.889964],
                    [-87.383631, 44.885115],
                    [-87.383655, 44.884723],
                    [-87.38367, 44.884475],
                    [-87.383833, 44.881788],
                    [-87.383839, 44.881685],
                    [-87.383874, 44.881116],
                    [-87.384593, 44.869277],
                    [-87.384821, 44.865532],
                    [-87.405541, 44.860047],
                    [-87.405658, 44.860098],
                    [-87.408779, 44.861453],
                    [-87.410015, 44.86199],
                    [-87.410919, 44.863259],
                    [-87.419253, 44.87496],
                    [-87.419951, 44.87594],
                    [-87.419135, 44.885052],
                    [-87.419114, 44.885285],
                    [-87.419106, 44.885378],
                    [-87.420327, 44.887596],
                    [-87.421007, 44.887869],
                    [-87.428144, 44.890738],
                    [-87.433005, 44.892691],
                    [-87.433128, 44.892741],
                    [-87.437084, 44.892718],
                    [-87.441339, 44.889723],
                    [-87.456688, 44.878918],
                    [-87.472639, 44.86769],
                    [-87.478489, 44.863572],
                    [-87.499063, 44.864207],
                    [-87.501578, 44.864285],
                    [-87.502431, 44.864619],
                    [-87.504303, 44.865352],
                    [-87.504405, 44.865392],
                    [-87.504868, 44.865573],
                    [-87.512129, 44.868416],
                    [-87.513413, 44.868919],
                    [-87.513775, 44.869061],
                    [-87.51459, 44.86938],
                    [-87.515142, 44.869596],
                    [-87.530794, 44.857594],
                    [-87.530999, 44.857437],
                    [-87.534723, 44.85625],
                    [-87.550003, 44.851381],
                    [-87.550288, 44.85129],
                    [-87.560183, 44.85208],
                    [-87.570851, 44.852932],
                    [-87.573175, 44.853118],
                    [-87.581306, 44.851791],
                    [-87.581635, 44.851638],
                    [-87.609784, 44.838514],
                    [-87.610018, 44.838405],
                    [-87.610063, 44.838384],
                    [-87.610245, 44.838217],
                    [-87.611118, 44.837416],
                    [-87.611852, 44.836743],
                    [-87.616242, 44.832715],
                    [-87.616384, 44.832585],
                    [-87.622014, 44.827419],
                    [-87.632699, 44.817616],
                    [-87.632892, 44.817439],
                    [-87.632977, 44.817361],
                    [-87.633418, 44.816956],
                    [-87.634059, 44.816369],
                    [-87.634381, 44.816073],
                    [-87.63439, 44.816065],
                    [-87.634476, 44.815986],
                    [-87.635265, 44.815263],
                    [-87.635416, 44.815124],
                    [-87.637104, 44.813575],
                    [-87.644545, 44.801571],
                    [-87.644637, 44.801423],
                    [-87.644646, 44.801407],
                    [-87.645229, 44.800466],
                    [-87.6463, 44.798739],
                    [-87.647764, 44.797347],
                    [-87.662056, 44.783758],
                    [-87.677995, 44.768602],
                    [-87.678368, 44.768247],
                    [-87.679709, 44.766973],
                    [-87.687497, 44.759567],
                    [-87.688207, 44.758892],
                    [-87.698167, 44.747226],
                    [-87.698246, 44.747133],
                    [-87.698584, 44.746738],
                    [-87.698653, 44.746657],
                    [-87.700539, 44.744448],
                    [-87.700778, 44.744169],
                    [-87.701022, 44.743882],
                    [-87.703557, 44.740914],
                    [-87.704653, 44.73963],
                    [-87.705733, 44.738364],
                    [-87.705852, 44.738225],
                    [-87.714401, 44.730449],
                    [-87.720312, 44.725073],
                    [-87.720889, 44.724548],
                    [-87.721059, 44.723522],
                    [-87.721252, 44.722361],
                    [-87.721816, 44.718969],
                    [-87.720599, 44.714984],
                    [-87.720248, 44.713833],
                    [-87.718427, 44.70787],
                    [-87.718409, 44.707811],
                    [-87.718413, 44.707765],
                    [-87.71978, 44.693246],
                    [-87.729836, 44.682015],
                    [-87.733701, 44.678916],
                    [-87.736058, 44.677025],
                    [-87.748409, 44.667122],
                    [-87.74909, 44.664131],
                    [-87.750899, 44.656192],
                    [-87.756031, 44.649129],
                    [-87.756048, 44.649117],
                    [-87.762376, 44.644501],
                    [-87.762912, 44.64411],
                    [-87.765774, 44.642023],
                    [-87.77516, 44.639281],
                    [-87.78824, 44.638137],
                    [-87.808366, 44.636378],
                    [-87.808819, 44.636338],
                    [-87.809076, 44.636189],
                    [-87.820083, 44.629816],
                    [-87.823444, 44.62787],
                    [-87.829229, 44.624521],
                    [-87.830848, 44.623583],
                    [-87.836101, 44.621375],
                    [-87.837416, 44.620822],
                    [-87.842164, 44.618826],
                    [-87.866884, 44.608434],
                    [-87.867941, 44.607606],
                    [-87.88741, 44.592356],
                    [-87.890868, 44.589647],
                    [-87.891717, 44.588982],
                    [-87.891727, 44.588977],
                    [-87.894356, 44.587745],
                    [-87.896412, 44.58678],
                    [-87.901179, 44.584545],
                    [-87.902319, 44.583079],
                    [-87.903689, 44.581317],
                    [-87.901236, 44.577648],
                    [-87.901061, 44.577386],
                    [-87.900634, 44.576747],
                    [-87.900198, 44.576095],
                    [-87.899183, 44.574576],
                    [-87.898888, 44.574135],
                    [-87.898907, 44.574091],
                    [-87.899368, 44.573043],
                    [-87.900668, 44.570084],
                    [-87.901177, 44.568925],
                    [-87.901178, 44.568924],
                    [-87.901206, 44.568887],
                    [-87.915846, 44.549612],
                    [-87.917, 44.548093],
                    [-87.917467, 44.547622],
                    [-87.924044, 44.540991],
                    [-87.929001, 44.535993],
                    [-87.935707, 44.533138],
                    [-87.939972, 44.531323],
                    [-87.943801, 44.529693],
                    [-87.970702, 44.530292],
                    [-87.977901, 44.532137],
                    [-87.982821, 44.533398],
                    [-87.986059, 44.534228],
                    [-87.990468, 44.535358],
                    [-87.990985, 44.535491],
                    [-87.998536, 44.537426],
                    [-87.999002, 44.537546],
                    [-88.000099, 44.537827],
                    [-88.005518, 44.539216],
                    [-88.016709, 44.547518],
                    [-88.020707, 44.550484],
                    [-88.037178, 44.562705],
                    [-88.042414, 44.566589],
                    [-88.042261, 44.567344],
                    [-88.041775, 44.569746],
                    [-88.041659, 44.57032],
                    [-88.041561, 44.570804],
                    [-88.041485, 44.571183],
                    [-88.041358, 44.571809],
                    [-88.041202, 44.572581],
                    [-88.041162, 44.572614],
                    [-88.039489, 44.573995],
                    [-88.039092, 44.574324],
                    [-88.036104, 44.576791],
                    [-88.036103, 44.576792],
                    [-88.034535, 44.577175],
                    [-88.028148, 44.578736],
                    [-88.027103, 44.578992],
                    [-88.022853, 44.584196],
                    [-88.019465, 44.588344],
                    [-88.016404, 44.592092],
                    [-88.016373, 44.592172],
                    [-88.015092, 44.595478],
                    [-88.014735, 44.5964],
                    [-88.012395, 44.602438],
                    [-88.001943, 44.603909],
                    [-87.998716, 44.609288],
                    [-87.998836, 44.609523],
                    [-88.001172, 44.614112],
                    [-88.006409, 44.624399],
                    [-88.006871, 44.625306],
                    [-88.007013, 44.625586],
                    [-88.007158, 44.625871],
                    [-88.007489, 44.626521],
                    [-88.008717, 44.628932],
                    [-88.009463, 44.630398],
                    [-88.009472, 44.630594],
                    [-88.009486, 44.630897],
                    [-88.009523, 44.631711],
                    [-88.009766, 44.637081],
                    [-88.007287, 44.645779],
                    [-88.00334, 44.65963],
                    [-88.002677, 44.661956],
                    [-88.002254, 44.663442],
                    [-88.002085, 44.664035],
                    [-88.000321, 44.664686],
                    [-87.998964, 44.665187],
                    [-87.99011, 44.668455],
                    [-87.990081, 44.669791],
                    [-87.989912, 44.677587],
                    [-87.989717, 44.686582],
                    [-87.98947, 44.687918],
                    [-87.989376, 44.688424],
                    [-87.989344, 44.688598],
                    [-87.98925, 44.689107],
                    [-87.988667, 44.692255],
                    [-87.987417, 44.699005],
                    [-87.987296, 44.699657],
                    [-87.986134, 44.705936],
                    [-87.986055, 44.70636],
                    [-87.984401, 44.715298],
                    [-87.984247, 44.716129],
                    [-87.984094, 44.716955],
                    [-87.98395, 44.717735],
                    [-87.983941, 44.717781],
                    [-87.983494, 44.720196],
                    [-87.983065, 44.72073],
                    [-87.972654, 44.733687],
                    [-87.964714, 44.74357],
                    [-87.956849, 44.749272],
                    [-87.95156, 44.753107],
                    [-87.941453, 44.75608],
                    [-87.926816, 44.778555],
                    [-87.925845, 44.780301],
                    [-87.923588, 44.784361],
                    [-87.918874, 44.79284],
                    [-87.911366, 44.806345],
                    [-87.906582, 44.814949],
                    [-87.905444, 44.816997],
                    [-87.904484, 44.818723],
                    [-87.904281, 44.819246],
                    [-87.903844, 44.820376],
                    [-87.903395, 44.821534],
                    [-87.902166, 44.824708],
                    [-87.901814, 44.825616],
                    [-87.901137, 44.827365],
                    [-87.899787, 44.828051],
                    [-87.897931, 44.828995],
                    [-87.895812, 44.830072],
                    [-87.89257, 44.83172],
                    [-87.885709, 44.835208],
                    [-87.878218, 44.839016],
                    [-87.871679, 44.839816],
                    [-87.866237, 44.840481],
                    [-87.865898, 44.840988],
                    [-87.857469, 44.853599],
                    [-87.857121, 44.85412],
                    [-87.855962, 44.855854],
                    [-87.854938, 44.857386],
                    [-87.854681, 44.857771],
                    [-87.854184, 44.859633],
                    [-87.852789, 44.86486],
                    [-87.84914, 44.86942],
                    [-87.848324, 44.87044],
                    [-87.847616, 44.870692],
                    [-87.838359, 44.873987],
                    [-87.836916, 44.87578],
                    [-87.835497, 44.877544],
                    [-87.832764, 44.880939],
                    [-87.827751, 44.891229],
                    [-87.832137, 44.896569],
                    [-87.833521, 44.898254],
                    [-87.833731, 44.89851],
                    [-87.834275, 44.899172],
                    [-87.836082, 44.901371],
                    [-87.836743, 44.902177],
                    [-87.837017, 44.902509],
                    [-87.839561, 44.905607],
                    [-87.840382, 44.907289],
                    [-87.842375, 44.911371],
                    [-87.84264, 44.911916],
                    [-87.842719, 44.912077],
                    [-87.842844, 44.912586],
                    [-87.844299, 44.918524],
                    [-87.843433, 44.924355],
                    [-87.839028, 44.931718],
                    [-87.837647, 44.933091],
                    [-87.822192, 44.948457],
                    [-87.821983, 44.948665],
                    [-87.821485, 44.949161],
                    [-87.82105, 44.949593],
                    [-87.820848, 44.949793],
                    [-87.820281, 44.950358],
                    [-87.819525, 44.951109],
                    [-87.819369, 44.951178],
                    [-87.819313, 44.951203],
                    [-87.818113, 44.951736],
                    [-87.817912, 44.951826],
                    [-87.817713, 44.951914],
                    [-87.817551, 44.951986],
                    [-87.817285, 44.952104],
                    [-87.815134, 44.95306],
                    [-87.813866, 44.953623],
                    [-87.813276, 44.953885],
                    [-87.812989, 44.954013],
                    [-87.812594, 44.954109],
                    [-87.792325, 44.95905],
                    [-87.791987, 44.959132],
                    [-87.791372, 44.959282],
                    [-87.789934, 44.959633],
                    [-87.788987, 44.959863],
                    [-87.781464, 44.961697],
                    [-87.781298, 44.961738],
                    [-87.766872, 44.965254],
                    [-87.766115, 44.965351],
                    [-87.762846, 44.965768],
                    [-87.7627, 44.965786],
                    [-87.76262, 44.965796],
                    [-87.696492, 44.974233],
                    [-87.661964, 44.973035],
                    [-87.630298, 44.976865],
                    [-87.624693, 45.014176],
                    [-87.625748, 45.045157],
                    [-87.610395, 45.075617],
                    [-87.601849, 45.082297],
                    [-87.594718, 45.085134],
                    [-87.591583, 45.083792],
                    [-87.587992, 45.085271],
                    [-87.587147, 45.089495],
                    [-87.590208, 45.095264],
                    [-87.581969, 45.097206],
                    [-87.589396, 45.09649],
                    [-87.59027, 45.096406],
                    [-87.592318, 45.097779],
                    [-87.597665, 45.101365],
                    [-87.598689, 45.102052],
                    [-87.60012, 45.103011],
                    [-87.601086, 45.104092],
                    [-87.601649, 45.104721],
                    [-87.601981, 45.105092],
                    [-87.602179, 45.105314],
                    [-87.602217, 45.105356],
                    [-87.602542, 45.105719],
                    [-87.604464, 45.107868],
                    [-87.605193, 45.108684],
                    [-87.606757, 45.110433],
                    [-87.608951, 45.112886],
                    [-87.610073, 45.114141],
                    [-87.611216, 45.119568],
                    [-87.611497, 45.120901],
                    [-87.6115, 45.120915],
                    [-87.611814, 45.122406],
                    [-87.612019, 45.123377],
                    [-87.60928, 45.13232],
                    [-87.607958, 45.134583],
                    [-87.607812, 45.134833],
                    [-87.600796, 45.146842],
                    [-87.596556, 45.152316],
                    [-87.587822, 45.163591],
                    [-87.585651, 45.166394],
                    [-87.582324, 45.169039],
                    [-87.563417, 45.18407],
                    [-87.556327, 45.187759],
                    [-87.552468, 45.189767],
                    [-87.548964, 45.191591],
                    [-87.535346, 45.203734],
                    [-87.530499, 45.208056],
                    [-87.512336, 45.224252],
                    [-87.504186, 45.232742],
                    [-87.503302, 45.233662],
                    [-87.496869, 45.240364],
                    [-87.465201, 45.273351],
                    [-87.464794, 45.273662],
                    [-87.456414, 45.280053],
                    [-87.455519, 45.280736],
                    [-87.438908, 45.293405],
                    [-87.437507, 45.30367],
                    [-87.437257, 45.3055],
                    [-87.436128, 45.307705],
                    [-87.431684, 45.316383],
                    [-87.427379, 45.320854],
                    [-87.409987, 45.33892],
                    [-87.399973, 45.349322],
                    [-87.39452, 45.363702],
                    [-87.3925, 45.369028],
                    [-87.391446, 45.369759],
                    [-87.389849, 45.370866],
                    [-87.389478, 45.371123],
                    [-87.389181, 45.371329],
                    [-87.387851, 45.372251],
                    [-87.371282, 45.383738],
                    [-87.364368, 45.388532],
                    [-87.360529, 45.397464],
                    [-87.360078, 45.398512],
                    [-87.359512, 45.399829],
                    [-87.351306, 45.407328],
                    [-87.350852, 45.407743],
                    [-87.338083, 45.41436],
                    [-87.336152, 45.41536],
                    [-87.330882, 45.421598],
                    [-87.329505, 45.423229],
                    [-87.328248, 45.424716],
                    [-87.327749, 45.425307],
                    [-87.326069, 45.429459],
                    [-87.325834, 45.43004],
                    [-87.326855, 45.43051],
                    [-87.327367, 45.430745],
                    [-87.32864, 45.431331],
                    [-87.329958, 45.431937],
                    [-87.331107, 45.433673],
                    [-87.33324, 45.436897],
                    [-87.333309, 45.437269],
                    [-87.334249, 45.442315],
                    [-87.334033, 45.443273],
                    [-87.333407, 45.446056],
                    [-87.333147, 45.447208],
                    [-87.330262, 45.451011],
                    [-87.319703, 45.464929],
                    [-87.317416, 45.466712],
                    [-87.306122, 45.475513],
                    [-87.289443, 45.50053],
                    [-87.288726, 45.501606],
                    [-87.280709, 45.517624],
                    [-87.264446, 45.550118],
                    [-87.263763, 45.551482],
                    [-87.263488, 45.552032],
                    [-87.261979, 45.553954],
                    [-87.260709, 45.555573],
                    [-87.250122, 45.569059],
                    [-87.234612, 45.588817],
                    [-87.232472, 45.59087],
                    [-87.223647, 45.599338],
                    [-87.218989, 45.60576],
                    [-87.215616, 45.610408],
                    [-87.21122, 45.616468],
                    [-87.196852, 45.636275],
                    [-87.191496, 45.641827],
                    [-87.173493, 45.66049],
                    [-87.172241, 45.661788],
                    [-87.161593, 45.666741],
                    [-87.15033, 45.67198],
                    [-87.146713, 45.673662],
                    [-87.129412, 45.68171],
                    [-87.12857, 45.681909],
                    [-87.112524, 45.685696],
                    [-87.111638, 45.685905],
                    [-87.100265, 45.694808],
                    [-87.099725, 45.695231],
                    [-87.099411, 45.698514],
                    [-87.099401, 45.698614],
                    [-87.095455, 45.701039],
                    [-87.093365, 45.701473],
                    [-87.074873, 45.705312],
                    [-87.074813, 45.705325],
                    [-87.073932, 45.705508],
                    [-87.061339, 45.708122],
                    [-87.059533, 45.708497],
                    [-87.059953, 45.708893],
                    [-87.070442, 45.718779],
                    [-87.061721, 45.732821],
                    [-87.057444, 45.736822],
                    [-87.052908, 45.747983],
                    [-87.05555, 45.751535],
                    [-87.062406, 45.753296],
                    [-87.064302, 45.758828],
                    [-87.063975, 45.76651],
                    [-87.058601, 45.778126],
                    [-87.058127, 45.779152],
                    [-87.058413, 45.788071],
                    [-87.058628, 45.794772],
                    [-87.058682, 45.796447],
                    [-87.058844, 45.80151],
                    [-87.058489, 45.804285],
                    [-87.058277, 45.80594],
                    [-87.058207, 45.806483],
                    [-87.057882, 45.809021],
                    [-87.057863, 45.80917],
                    [-87.057439, 45.812483],
                    [-87.052043, 45.821879],
                    [-87.050104, 45.823844],
                    [-87.039842, 45.834245],
                    [-87.031435, 45.837238],
                    [-87.018902, 45.838886],
                    [-87.006225, 45.832312],
                    [-87.006095, 45.832244],
                    [-87.00508, 45.831718],
                    [-86.988438, 45.810621],
                    [-86.981624, 45.792221],
                    [-86.981341, 45.76616],
                    [-86.975224, 45.75313],
                    [-86.977655, 45.728768],
                    [-86.982413, 45.719873],
                    [-86.984588, 45.705812],
                    [-86.981349, 45.696463],
                    [-86.969765, 45.691895],
                    [-86.967315, 45.684923],
                    [-86.966885, 45.675001],
                    [-86.964275, 45.672761],
                    [-86.944158, 45.695833],
                    [-86.92106, 45.697868],
                    [-86.904089, 45.709546],
                    [-86.895342, 45.711464],
                    [-86.876904, 45.711891],
                    [-86.870392, 45.710087],
                    [-86.838746, 45.722307],
                    [-86.841818, 45.729051],
                    [-86.838658, 45.741831],
                    [-86.821814, 45.757164],
                    [-86.820868, 45.760776],
                    [-86.823743, 45.765486],
                    [-86.821523, 45.770356],
                    [-86.801476, 45.780027],
                    [-86.785722, 45.794517],
                    [-86.773279, 45.811385],
                    [-86.774612, 45.821696],
                    [-86.777225, 45.827183],
                    [-86.782259, 45.82995],
                    [-86.784177, 45.854641],
                    [-86.78208, 45.860195],
                    [-86.758449, 45.867274],
                    [-86.749638, 45.867796],
                    [-86.742466, 45.864719],
                    [-86.72852, 45.848759],
                    [-86.721113, 45.845431],
                    [-86.645998, 45.833888],
                    [-86.632478, 45.843309],
                    [-86.633168, 45.860068],
                    [-86.625736, 45.868295],
                    [-86.613536, 45.875982],
                    [-86.603293, 45.876626],
                    [-86.593184, 45.88511],
                    [-86.583304, 45.898784],
                    [-86.567719, 45.9005],
                    [-86.553608, 45.896476],
                    [-86.541464, 45.890234],
                    [-86.532989, 45.882665],
                    [-86.529573, 45.874974],
                    [-86.528224, 45.856974],
                    [-86.529208, 45.853043],
                    [-86.538831, 45.840083],
                    [-86.545602, 45.836495],
                    [-86.549723, 45.836039],
                    [-86.555186, 45.831696],
                    [-86.559044, 45.822323],
                    [-86.555547, 45.813499],
                    [-86.557215, 45.808172],
                    [-86.563392, 45.804469],
                    [-86.571172, 45.805452],
                    [-86.576858, 45.801473],
                    [-86.581759, 45.794797],
                    [-86.581071, 45.791802],
                    [-86.576869, 45.788502],
                    [-86.583391, 45.778242],
                    [-86.597661, 45.775385],
                    [-86.612137, 45.779356],
                    [-86.617336, 45.783538],
                    [-86.631018, 45.782019],
                    [-86.634902, 45.763536],
                    [-86.633138, 45.747654],
                    [-86.647319, 45.732618],
                    [-86.662762, 45.728964],
                    [-86.67148, 45.72053],
                    [-86.669263, 45.71086],
                    [-86.665511, 45.70903],
                    [-86.665677, 45.702217],
                    [-86.676184, 45.691862],
                    [-86.689102, 45.687862],
                    [-86.705184, 45.690901],
                    [-86.715781, 45.683949],
                    [-86.718191, 45.67732],
                    [-86.717828, 45.668106],
                    [-86.708038, 45.649202],
                    [-86.695275, 45.648175],
                    [-86.688772, 45.639969],
                    [-86.687208, 45.634253],
                    [-86.666127, 45.621689],
                    [-86.648439, 45.615992],
                    [-86.633224, 45.618249],
                    [-86.62387, 45.613262],
                    [-86.616893, 45.606796],
                    [-86.616972, 45.620581],
                    [-86.627938, 45.659293],
                    [-86.625132, 45.663819],
                    [-86.62043, 45.667098],
                    [-86.611306, 45.669733],
                    [-86.593613, 45.665625],
                    [-86.587528, 45.666456],
                    [-86.584771, 45.682007],
                    [-86.585847, 45.704922],
                    [-86.580936, 45.71192],
                    [-86.570627, 45.716412],
                    [-86.54143, 45.70811],
                    [-86.537258, 45.708361],
                    [-86.53328, 45.710849],
                    [-86.525166, 45.720797],
                    [-86.523197, 45.736498],
                    [-86.518281, 45.747688],
                    [-86.51457, 45.752337],
                    [-86.504216, 45.75423],
                    [-86.496251, 45.749255],
                    [-86.486028, 45.746608],
                    [-86.47905, 45.757416],
                    [-86.466039, 45.759741],
                    [-86.459866, 45.758042],
                    [-86.455534, 45.75685],
                    [-86.442335, 45.760026],
                    [-86.440329, 45.760508],
                    [-86.439661, 45.760669],
                    [-86.43553, 45.764452],
                    [-86.435493, 45.764485],
                    [-86.431921, 45.767756],
                    [-86.428294, 45.77562],
                    [-86.427183, 45.77905],
                    [-86.428946, 45.782524],
                    [-86.428423, 45.785587],
                    [-86.424828, 45.789747],
                    [-86.415971, 45.793793],
                    [-86.401656, 45.795412],
                    [-86.395809, 45.78974],
                    [-86.369918, 45.789254],
                    [-86.363808, 45.790057],
                    [-86.351658, 45.798132],
                    [-86.355062, 45.805355],
                    [-86.349134, 45.83416],
                    [-86.332625, 45.851813],
                    [-86.324232, 45.90608],
                    [-86.315981, 45.915247],
                    [-86.278007, 45.942057],
                    [-86.254768, 45.94864],
                    [-86.248008, 45.944849],
                    [-86.233613, 45.945802],
                    [-86.22906, 45.94857],
                    [-86.220546, 45.958883],
                    [-86.208255, 45.962978],
                    [-86.196618, 45.963185],
                    [-86.159415, 45.953765],
                    [-86.150173, 45.954494],
                    [-86.145714, 45.957372],
                    [-86.123567, 45.964748],
                    [-86.094753, 45.966704],
                    [-86.072067, 45.965313],
                    [-86.050956, 45.962205],
                    [-85.998868, 45.950968],
                    [-85.954063, 45.936629],
                    [-85.920581, 45.920994],
                    [-85.913769, 45.919439],
                    [-85.910264, 45.922112],
                    [-85.917238, 45.927782],
                    [-85.926017, 45.932104],
                    [-85.926213, 45.938093],
                    [-85.922737, 45.948287],
                    [-85.912604, 45.956302],
                    [-85.91237, 45.956487],
                    [-85.9091, 45.959074],
                    [-85.893196, 45.967253],
                    [-85.882442, 45.96862],
                    [-85.865402, 45.968257],
                    [-85.861157, 45.968167],
                    [-85.86019, 45.968016],
                    [-85.843016, 45.965342],
                    [-85.842404, 45.965247],
                    [-85.839025, 45.966107],
                    [-85.838366, 45.966275],
                    [-85.838133, 45.966334],
                    [-85.835456, 45.967016],
                    [-85.835217, 45.967077],
                    [-85.834504, 45.967258],
                    [-85.832603, 45.967742],
                    [-85.832261, 45.968172],
                    [-85.82978, 45.9713],
                    [-85.826278, 45.975714],
                    [-85.826034, 45.976021],
                    [-85.825819, 45.976292],
                    [-85.820721, 45.978239],
                    [-85.818006, 45.979276],
                    [-85.817558, 45.979447],
                    [-85.817066, 45.979491],
                    [-85.811054, 45.980032],
                    [-85.810442, 45.980087],
                    [-85.8092, 45.979931],
                    [-85.791841, 45.977745],
                    [-85.790639, 45.977594],
                    [-85.790279, 45.97748],
                    [-85.774055, 45.972337],
                    [-85.770938, 45.971349],
                    [-85.760165, 45.968914],
                    [-85.743618, 45.965173],
                    [-85.74353, 45.965174],
                    [-85.731776, 45.965317],
                    [-85.725153, 45.965398],
                    [-85.724246, 45.965409],
                    [-85.716228, 45.963852],
                    [-85.706548, 45.961973],
                    [-85.704951, 45.961662],
                    [-85.704237, 45.961524],
                    [-85.701627, 45.961017],
                    [-85.697609, 45.960237],
                    [-85.697203, 45.960158],
                    [-85.688149, 45.962025],
                    [-85.664609, 45.96688],
                    [-85.663966, 45.967013],
                    [-85.654738, 45.973648],
                    [-85.654686, 45.973686],
                    [-85.650942, 45.979824],
                    [-85.650434, 45.980656],
                    [-85.648942, 45.983103],
                    [-85.648581, 45.983695],
                    [-85.647554, 45.984519],
                    [-85.64743, 45.984619],
                    [-85.646359, 45.985477],
                    [-85.646279, 45.985541],
                    [-85.62933, 45.999136],
                    [-85.628998, 45.999403],
                    [-85.628293, 45.999968],
                    [-85.625118, 46.002515],
                    [-85.617709, 46.008458],
                    [-85.617577, 46.008665],
                    [-85.615661, 46.01168],
                    [-85.603785, 46.030363],
                    [-85.563567, 46.06182],
                    [-85.561097, 46.063751],
                    [-85.54595, 46.075598],
                    [-85.542974, 46.077926],
                    [-85.540858, 46.079581],
                    [-85.539479, 46.080416],
                    [-85.533341, 46.084131],
                    [-85.529058, 46.086724],
                    [-85.528947, 46.086791],
                    [-85.528403, 46.087121],
                    [-85.521636, 46.091217],
                    [-85.52157, 46.091257],
                    [-85.521424, 46.091314],
                    [-85.512696, 46.094727],
                    [-85.50024, 46.096915],
                    [-85.5001, 46.09694],
                    [-85.499422, 46.09692],
                    [-85.486316, 46.096543],
                    [-85.48072, 46.096382],
                    [-85.480603, 46.096379],
                    [-85.480315, 46.096283],
                    [-85.476097, 46.094876],
                    [-85.468899, 46.092474],
                    [-85.466828, 46.091783],
                    [-85.45315, 46.087219],
                    [-85.44699, 46.085164],
                    [-85.445976, 46.086272],
                    [-85.445835, 46.086426],
                    [-85.440191, 46.092593],
                    [-85.440908, 46.093053],
                    [-85.442255, 46.093916],
                    [-85.442293, 46.093941],
                    [-85.442112, 46.094867],
                    [-85.441934, 46.095783],
                    [-85.441932, 46.095793],
                    [-85.441879, 46.095815],
                    [-85.44157, 46.095942],
                    [-85.437768, 46.097504],
                    [-85.437352, 46.097675],
                    [-85.432064, 46.099848],
                    [-85.429533, 46.100888],
                    [-85.42921, 46.101021],
                    [-85.426916, 46.101964],
                    [-85.412064, 46.101437],
                    [-85.409463, 46.100585],
                    [-85.404194, 46.098859],
                    [-85.393832, 46.095465],
                    [-85.390047, 46.091381],
                    [-85.382927, 46.083698],
                    [-85.382781, 46.08354],
                    [-85.382312, 46.083035],
                    [-85.381394, 46.082044],
                    [-85.381263, 46.082086],
                    [-85.367158, 46.086606],
                    [-85.366622, 46.086778],
                    [-85.356339, 46.092022],
                    [-85.356214, 46.092086],
                    [-85.3561, 46.092089],
                    [-85.33628, 46.092586],
                    [-85.335911, 46.092595],
                    [-85.335466, 46.092459],
                    [-85.316264, 46.086608],
                    [-85.310613, 46.083773],
                    [-85.304238, 46.080576],
                    [-85.295321, 46.076103],
                    [-85.290046, 46.073456],
                    [-85.287936, 46.072398],
                    [-85.287693, 46.072276],
                    [-85.278345, 46.069426],
                    [-85.268308, 46.066365],
                    [-85.266385, 46.065779],
                    [-85.255375, 46.064502],
                    [-85.248414, 46.063694],
                    [-85.225052, 46.060984],
                    [-85.222959, 46.060741],
                    [-85.222511, 46.060689],
                    [-85.222416, 46.060629],
                    [-85.221206, 46.059864],
                    [-85.220289, 46.059283],
                    [-85.217705, 46.057648],
                    [-85.214657, 46.055719],
                    [-85.210834, 46.053301],
                    [-85.197523, 46.044878],
                    [-85.195201, 46.045802],
                    [-85.194724, 46.045992],
                    [-85.19063, 46.047622],
                    [-85.152027, 46.050725],
                    [-85.151825, 46.050705],
                    [-85.151738, 46.050696],
                    [-85.147103, 46.05023],
                    [-85.14516, 46.050035],
                    [-85.142456, 46.049764],
                    [-85.140835, 46.049601],
                    [-85.139572, 46.049173],
                    [-85.13765, 46.048522],
                    [-85.133704, 46.047184],
                    [-85.130433, 46.046076],
                    [-85.127202, 46.044481],
                    [-85.127046, 46.044404],
                    [-85.125243, 46.043515],
                    [-85.124796, 46.043294],
                    [-85.113601, 46.037769],
                    [-85.106684, 46.034356],
                    [-85.105148, 46.033598],
                    [-85.102899, 46.032488],
                    [-85.102862, 46.032477],
                    [-85.088818, 46.028378],
                    [-85.088345, 46.028304],
                    [-85.081934, 46.027295],
                    [-85.055581, 46.023148],
                    [-85.054297, 46.022766],
                    [-85.048972, 46.021182],
                    [-85.047974, 46.020885],
                    [-85.047028, 46.020603],
                    [-85.020951, 46.012845],
                    [-85.01399, 46.010774],
                    [-85.010941, 46.009412],
                    [-85.010208, 46.009084],
                    [-85.003597, 46.00613],
                    [-84.992511, 45.998751],
                    [-84.974714, 45.986905],
                    [-84.974668, 45.986874],
                    [-84.973556, 45.986134],
                    [-84.971232, 45.984208],
                    [-84.937134, 45.955949],
                    [-84.936953, 45.955716],
                    [-84.936565, 45.955217],
                    [-84.917484, 45.93067],
                    [-84.917288, 45.930576],
                    [-84.902913, 45.923673],
                    [-84.902572, 45.923557],
                    [-84.879835, 45.915847],
                    [-84.879507, 45.915546],
                    [-84.878969, 45.915053],
                    [-84.877253, 45.91348],
                    [-84.873273, 45.909832],
                    [-84.873254, 45.909815],
                    [-84.870355, 45.908432],
                    [-84.867851, 45.907237],
                    [-84.861494, 45.904204],
                    [-84.860544, 45.903751],
                    [-84.859025, 45.903026],
                    [-84.855982, 45.901574],
                    [-84.853303, 45.900296],
                    [-84.852916, 45.900111],
                    [-84.850592, 45.899694],
                    [-84.846035, 45.898875],
                    [-84.844615, 45.89862],
                    [-84.843087, 45.898346],
                    [-84.842243, 45.898194],
                    [-84.842147, 45.898005],
                    [-84.840666, 45.895074],
                    [-84.839639, 45.893042],
                    [-84.837635, 45.889075],
                    [-84.837624, 45.889054],
                    [-84.837717, 45.888226],
                    [-84.837841, 45.887123],
                    [-84.838228, 45.883685],
                    [-84.838472, 45.881512],
                    [-84.838427, 45.881452],
                    [-84.838282, 45.881258],
                    [-84.835147, 45.87706],
                    [-84.834236, 45.875841],
                    [-84.834213, 45.87581],
                    [-84.831947, 45.872776],
                    [-84.831396, 45.872038],
                    [-84.828996, 45.871209],
                    [-84.820557, 45.868293],
                    [-84.792763, 45.858691],
                    [-84.7754, 45.849932],
                    [-84.773761, 45.849105],
                    [-84.758333, 45.841322],
                    [-84.754672, 45.839475],
                    [-84.751507, 45.837878],
                    [-84.747033, 45.835621],
                    [-84.746985, 45.835597],
                    [-84.726921, 45.836964],
                    [-84.725734, 45.837045],
                    [-84.723552, 45.84408],
                    [-84.723005, 45.845845],
                    [-84.722764, 45.846621],
                    [-84.721468, 45.84762],
                    [-84.721011, 45.847972],
                    [-84.720836, 45.848107],
                    [-84.720609, 45.848116],
                    [-84.709261, 45.848548],
                    [-84.707676, 45.848609],
                    [-84.706383, 45.848658],
                    [-84.705978, 45.848837],
                    [-84.705923, 45.848861],
                    [-84.705036, 45.849253],
                    [-84.704647, 45.849425],
                    [-84.702295, 45.850464],
                    [-84.702256, 45.850557],
                    [-84.701183, 45.853092],
                    [-84.701594, 45.853461],
                    [-84.702122, 45.853935],
                    [-84.709727, 45.860766],
                    [-84.715365, 45.86583],
                    [-84.715481, 45.865934],
                    [-84.715501, 45.865962],
                    [-84.721276, 45.873908],
                    [-84.721277, 45.873911],
                    [-84.729482, 45.895263],
                    [-84.730252, 45.897267],
                    [-84.734002, 45.907026],
                    [-84.713251, 45.916047],
                    [-84.713614, 45.920366],
                    [-84.718955, 45.927449],
                    [-84.733041, 45.932837],
                    [-84.73937, 45.941816],
                    [-84.738849, 45.945792],
                    [-84.730179, 45.961198],
                    [-84.723039, 45.967279],
                    [-84.703948, 45.970901],
                    [-84.687712, 45.97126],
                    [-84.685254, 45.973454],
                    [-84.684368, 45.977499],
                    [-84.686269, 45.979144],
                    [-84.6927, 46.016963],
                    [-84.692735, 46.027019],
                    [-84.687322, 46.03488],
                    [-84.675835, 46.046009],
                    [-84.66671, 46.050486],
                    [-84.656567, 46.052654],
                    [-84.647609, 46.049704],
                    [-84.609063, 46.026418],
                    [-84.586592, 46.026584],
                    [-84.581081, 46.031041],
                    [-84.563891, 46.032459],
                    [-84.544405, 46.02286],
                    [-84.540995, 46.019501],
                    [-84.533426, 46.00572],
                    [-84.530444, 45.991385],
                    [-84.534648, 45.978132],
                    [-84.534422, 45.972762],
                    [-84.532392, 45.969448],
                    [-84.525052, 45.968578],
                    [-84.514071, 45.971292],
                    [-84.514123, 45.987242],
                    [-84.507201, 45.991169],
                    [-84.488536, 45.98882],
                    [-84.485625, 45.988453],
                    [-84.484009, 45.98825],
                    [-84.482773, 45.986035],
                    [-84.482442, 45.985441],
                    [-84.482506, 45.985109],
                    [-84.483062, 45.982242],
                    [-84.482563, 45.981391],
                    [-84.4808, 45.978385],
                    [-84.480436, 45.977764],
                    [-84.478576, 45.976814],
                    [-84.473694, 45.974321],
                    [-84.463128, 45.968925],
                    [-84.459956, 45.970343],
                    [-84.443336, 45.977775],
                    [-84.44314, 45.977862],
                    [-84.443138, 45.977863],
                    [-84.443086, 45.977825],
                    [-84.437633, 45.97375],
                    [-84.436574, 45.97193],
                    [-84.436454, 45.971722],
                    [-84.428689, 45.958371],
                    [-84.428239, 45.958144],
                    [-84.411212, 45.949539],
                    [-84.399582, 45.943662],
                    [-84.39946, 45.9436],
                    [-84.399134, 45.943436],
                    [-84.39854, 45.943136],
                    [-84.398323, 45.943026],
                    [-84.398007, 45.942866],
                    [-84.391464, 45.93956],
                    [-84.387383, 45.937497],
                    [-84.376431, 45.931963],
                    [-84.376429, 45.931962],
                    [-84.357525, 45.939881],
                    [-84.353714, 45.941478],
                    [-84.353272, 45.941663],
                    [-84.353259, 45.941671],
                    [-84.345451, 45.946569],
                    [-84.331572, 45.955274],
                    [-84.330346, 45.956043],
                    [-84.330021, 45.956247],
                    [-84.327177, 45.95624],
                    [-84.326413, 45.956238],
                    [-84.325787, 45.956237],
                    [-84.325115, 45.956235],
                    [-84.324647, 45.956234],
                    [-84.320494, 45.956224],
                    [-84.320017, 45.956223],
                    [-84.319565, 45.956222],
                    [-84.317422, 45.956217],
                    [-84.281663, 45.956132],
                    [-84.266673, 45.956096],
                    [-84.261858, 45.956084],
                    [-84.2613, 45.956083],
                    [-84.254952, 45.956068],
                    [-84.253993, 45.956727],
                    [-84.238174, 45.967595],
                    [-84.218951, 45.9681],
                    [-84.213885, 45.968233],
                    [-84.203135, 45.968516],
                    [-84.17806, 45.969175],
                    [-84.175244, 45.967671],
                    [-84.174763, 45.967414],
                    [-84.17225, 45.966072],
                    [-84.169368, 45.966919],
                    [-84.140816, 45.975308],
                    [-84.140461, 45.975348],
                    [-84.114284, 45.978322],
                    [-84.111174, 45.978675],
                    [-84.107204, 45.977161],
                    [-84.10537, 45.972948],
                    [-84.090391, 45.967256],
                    [-84.080071, 45.970822],
                    [-84.017565, 45.959046],
                    [-83.996471, 45.961461],
                    [-83.985141, 45.967133],
                    [-83.952183, 45.965498],
                    [-83.921257, 45.958075],
                    [-83.910838, 45.965613],
                    [-83.881055, 45.968185],
                    [-83.879616, 45.966196],
                    [-83.864859, 45.959465],
                    [-83.846437, 45.953181],
                    [-83.840869, 45.952726],
                    [-83.835505, 45.941843],
                    [-83.827566, 45.941236],
                    [-83.82281, 45.943985],
                    [-83.808147, 45.945693],
                    [-83.803332, 45.943362],
                    [-83.80104, 45.937582],
                    [-83.78611, 45.933375],
                    [-83.768854, 45.932069],
                    [-83.766233, 45.935221],
                    [-83.742778, 45.938002],
                    [-83.732986, 45.937641],
                    [-83.719433, 45.934078],
                    [-83.687695, 45.935389],
                    [-83.65766, 45.945463],
                    [-83.632214, 45.932287],
                    [-83.583052, 45.915919],
                    [-83.561838, 45.912562],
                    [-83.526347, 45.918636],
                    [-83.517242, 45.923614],
                    [-83.510623, 45.929324],
                    [-83.488809, 45.96874],
                    [-83.481765, 45.971873],
                    [-83.473221, 45.984422],
                    [-83.473946, 45.988558],
                    [-83.480639, 45.996164],
                    [-83.488348, 45.999543],
                    [-83.49484, 45.999541],
                    [-83.532913, 46.011328],
                    [-83.540845, 46.021247],
                    [-83.543366, 46.037196],
                    [-83.547202, 46.047868],
                    [-83.554059, 46.058882],
                    [-83.565351, 46.061898],
                    [-83.572574, 46.069895],
                    [-83.57264, 46.074922],
                    [-83.57609, 46.083513],
                    [-83.581315, 46.089613],
                    [-83.598612, 46.090085],
                    [-83.615343, 46.095976],
                    [-83.625554, 46.102212],
                    [-83.63498, 46.103953],
                    [-83.661161, 46.100258],
                    [-83.703861, 46.103366],
                    [-83.719791, 46.101031],
                    [-83.7233, 46.09381],
                    [-83.728165, 46.090957],
                    [-83.755991, 46.092159],
                    [-83.771821, 46.090999],
                    [-83.779996, 46.093515],
                    [-83.792867, 46.101971],
                    [-83.81241, 46.108598],
                    [-83.815826, 46.108529],
                    [-83.824036, 46.103638],
                    [-83.81252, 46.073469],
                    [-83.796555, 46.056688],
                    [-83.773785, 46.051471],
                    [-83.765233, 46.031935],
                    [-83.765277, 46.018363],
                    [-83.776436, 46.004202],
                    [-83.794055, 45.995801],
                    [-83.818202, 46.002425],
                    [-83.830146, 46.022324],
                    [-83.845399, 46.025679],
                    [-83.868233, 45.995075],
                    [-83.873147, 45.993426],
                    [-83.900535, 45.998918],
                    [-83.908583, 46.011471],
                    [-83.931175, 46.017871],
                    [-83.93547, 46.020385],
                    [-83.939012, 46.029226],
                    [-83.943933, 46.031465],
                    [-83.95141, 46.029042],
                    [-83.963808, 46.027833],
                    [-83.989526, 46.032823],
                    [-84.006082, 46.044586],
                    [-84.027861, 46.054784],
                    [-84.051712, 46.079189],
                    [-84.066257, 46.087438],
                    [-84.071741, 46.092441],
                    [-84.072398, 46.09669],
                    [-84.069147, 46.103978],
                    [-84.061329, 46.113482],
                    [-84.0519, 46.11981],
                    [-84.038696, 46.12562],
                    [-84.031036, 46.123186],
                    [-84.026536, 46.131648],
                    [-84.060383, 46.146138],
                    [-84.089309, 46.146432],
                    [-84.095818, 46.147733],
                    [-84.100126, 46.15077],
                    [-84.113259, 46.16886],
                    [-84.114941, 46.174114],
                    [-84.125022, 46.180209],
                    [-84.17164, 46.181731],
                    [-84.177298, 46.183993],
                    [-84.196669, 46.16615],
                    [-84.221001, 46.163062],
                    [-84.251424, 46.175888],
                    [-84.247687, 46.17989],
                    [-84.245233, 46.192571],
                    [-84.249164, 46.206461],
                    [-84.233117, 46.224037],
                    [-84.219494, 46.231992],
                    [-84.182732, 46.23545],
                    [-84.159485, 46.233233],
                    [-84.152499, 46.227875],
                    [-84.15223, 46.226254],
                    [-84.152042, 46.224937],
                    [-84.151666, 46.224184],
                    [-84.150725, 46.223808],
                    [-84.14922, 46.223808],
                    [-84.14715, 46.224184],
                    [-84.14595, 46.224995],
                    [-84.134652, 46.23214],
                    [-84.125024, 46.232885],
                    [-84.118175, 46.233968],
                    [-84.108089, 46.241238],
                    [-84.115563, 46.268225],
                    [-84.138906, 46.372221],
                    [-84.146172, 46.41852],
                    [-84.125026, 46.470143],
                    [-84.111225, 46.504119],
                    [-84.117925, 46.517619],
                    [-84.128925, 46.530119],
                    [-84.139426, 46.532219],
                    [-84.193729, 46.53992],
                    [-84.226131, 46.53392],
                    [-84.264266, 46.495055],
                    [-84.325371, 46.500021],
                    [-84.37504, 46.508669],
                    [-84.394725, 46.499242],
                    [-84.420274, 46.501077],
                    [-84.445149, 46.489016],
                    [-84.463322, 46.467435],
                    [-84.455256, 46.462785],
                    [-84.455527, 46.453897],
                    [-84.462597, 46.44094],
                    [-84.471848, 46.434289],
                    [-84.479513, 46.432573],
                    [-84.493401, 46.440313],
                    [-84.503719, 46.43919],
                    [-84.551496, 46.418522],
                    [-84.573522, 46.427895],
                    [-84.584167, 46.43941],
                    [-84.607945, 46.456747],
                    [-84.616489, 46.47187],
                    [-84.63102, 46.484868],
                    [-84.65388, 46.48225],
                    [-84.678423, 46.487694],
                    [-84.689672, 46.483923],
                    [-84.723338, 46.468266],
                    [-84.769151, 46.453523],
                    [-84.800101, 46.446219],
                    [-84.829491, 46.444071],
                    [-84.843907, 46.448661],
                    [-84.849767, 46.460245],
                    [-84.861448, 46.46993],
                    [-84.87507, 46.466781],
                    [-84.893423, 46.465406],
                    [-84.915184, 46.467515],
                    [-84.921931, 46.469962],
                    [-84.934432, 46.480315],
                    [-84.937145, 46.489252],
                    [-84.947269, 46.487399],
                    [-84.955307, 46.480269],
                    [-84.969464, 46.47629],
                    [-85.015211, 46.479712],
                    [-85.025598, 46.483028],
                    [-85.033766, 46.48767],
                    [-85.049847, 46.503963],
                    [-85.054943, 46.51475],
                    [-85.056133, 46.52652],
                    [-85.052954, 46.532827],
                    [-85.045534, 46.537694],
                    [-85.027083, 46.543038],
                    [-85.025491, 46.546397],
                    [-85.027374, 46.553756],
                    [-85.029594, 46.554419],
                    [-85.031507, 46.568703],
                    [-85.035476, 46.581547],
                    [-85.037056, 46.600995],
                    [-85.035504, 46.625021],
                    [-85.028291, 46.675125],
                    [-85.030078, 46.684769],
                    [-85.027513, 46.697451],
                    [-85.020159, 46.712463],
                    [-85.007616, 46.728339],
                    [-84.987539, 46.745483],
                    [-84.95158, 46.769488],
                    [-84.954009, 46.771362],
                    [-84.964652, 46.772845],
                    [-84.989497, 46.772403],
                    [-85.00924, 46.769224],
                    [-85.036286, 46.760435],
                    [-85.063556, 46.757856],
                    [-85.173042, 46.763634],
                    [-85.237873, 46.755703],
                    [-85.256031, 46.753481],
                    [-85.25686, 46.75338],
                    [-85.257999, 46.753078],
                    [-85.289846, 46.744644],
                    [-85.296395, 46.742114],
                    [-85.347047, 46.722546],
                    [-85.362572, 46.716548],
                    [-85.369805, 46.713754],
                    [-85.482096, 46.680432],
                    [-85.482978, 46.680283],
                    [-85.50951, 46.675786],
                    [-85.542517, 46.674263],
                    [-85.547593, 46.674304],
                    [-85.558762, 46.674395],
                    [-85.584989, 46.674608],
                    [-85.587345, 46.674627],
                    [-85.587741, 46.674672],
                    [-85.593558, 46.675334],
                    [-85.594158, 46.675402],
                    [-85.595806, 46.67559],
                    [-85.61747, 46.678054],
                    [-85.624573, 46.678862],
                    [-85.647387, 46.679658],
                    [-85.663673, 46.680227],
                    [-85.668753, 46.680404],
                    [-85.674011, 46.68003],
                    [-85.700945, 46.678114],
                    [-85.714415, 46.677156],
                    [-85.738624, 46.677298],
                    [-85.739929, 46.677305],
                    [-85.742834, 46.677322],
                    [-85.750606, 46.677368],
                    [-85.751345, 46.67743],
                    [-85.792506, 46.68088],
                    [-85.794923, 46.681083],
                    [-85.796641, 46.681374],
                    [-85.815747, 46.68461],
                    [-85.840211, 46.688753],
                    [-85.841057, 46.688896],
                    [-85.85662, 46.689748],
                    [-85.857755, 46.68981],
                    [-85.864549, 46.690182],
                    [-85.877908, 46.690914],
                    [-85.924047, 46.684733],
                    [-85.95367, 46.676869],
                    [-85.995044, 46.673676],
                    [-86.036969, 46.667627],
                    [-86.074219, 46.657799],
                    [-86.099843, 46.654615],
                    [-86.112126, 46.655044],
                    [-86.119862, 46.657256],
                    [-86.138295, 46.672935],
                    [-86.161681, 46.669475],
                    [-86.188024, 46.654008],
                    [-86.34989, 46.578035],
                    [-86.390409, 46.563194],
                    [-86.437167, 46.54896],
                    [-86.44439, 46.548137],
                    [-86.45993, 46.551928],
                    [-86.469306, 46.551422],
                    [-86.481956, 46.542709],
                    [-86.484003, 46.535965],
                    [-86.495054, 46.524874],
                    [-86.524959, 46.505381],
                    [-86.557731, 46.487434],
                    [-86.586168, 46.463324],
                    [-86.609039, 46.470239],
                    [-86.606932, 46.478531],
                    [-86.609393, 46.492976],
                    [-86.612173, 46.493295],
                    [-86.618061, 46.489452],
                    [-86.620603, 46.483873],
                    [-86.627441, 46.47754],
                    [-86.636671, 46.478298],
                    [-86.646393, 46.485776],
                    [-86.645528, 46.492039],
                    [-86.641088, 46.500438],
                    [-86.63453, 46.504523],
                    [-86.632109, 46.508865],
                    [-86.629086, 46.518144],
                    [-86.62738, 46.53371],
                    [-86.652865, 46.560555],
                    [-86.656479, 46.558453],
                    [-86.670927, 46.556489],
                    [-86.675764, 46.557061],
                    [-86.678182, 46.561039],
                    [-86.695645, 46.555026],
                    [-86.709325, 46.543914],
                    [-86.701929, 46.511571],
                    [-86.696001, 46.50316],
                    [-86.683819, 46.498079],
                    [-86.686468, 46.471655],
                    [-86.688816, 46.463152],
                    [-86.686412, 46.454965],
                    [-86.698139, 46.438624],
                    [-86.70323, 46.439378],
                    [-86.710573, 46.444908],
                    [-86.730829, 46.468057],
                    [-86.731096, 46.47176],
                    [-86.735929, 46.475231],
                    [-86.750157, 46.479109],
                    [-86.768516, 46.479072],
                    [-86.787905, 46.477729],
                    [-86.803557, 46.466669],
                    [-86.808817, 46.460611],
                    [-86.810967, 46.449663],
                    [-86.816026, 46.437892],
                    [-86.837448, 46.434186],
                    [-86.850111, 46.434114],
                    [-86.875151, 46.43728],
                    [-86.883919, 46.441514],
                    [-86.883976, 46.450976],
                    [-86.889094, 46.458499],
                    [-86.903742, 46.466138],
                    [-86.927725, 46.464566],
                    [-86.947077, 46.472064],
                    [-86.949526, 46.476315],
                    [-86.946218, 46.479059],
                    [-86.94698, 46.484567],
                    [-86.962842, 46.509646],
                    [-86.964534, 46.516549],
                    [-86.976958, 46.526581],
                    [-87.008724, 46.532723],
                    [-87.017136, 46.53355],
                    [-87.029892, 46.525599],
                    [-87.046022, 46.519956],
                    [-87.077279, 46.515339],
                    [-87.09876, 46.503609],
                    [-87.107559, 46.496124],
                    [-87.116618, 46.495163],
                    [-87.12744, 46.494014],
                    [-87.145946, 46.495387],
                    [-87.175065, 46.497548],
                    [-87.175105, 46.497538],
                    [-87.200147, 46.491382],
                    [-87.202404, 46.490827],
                    [-87.205147, 46.490702],
                    [-87.258145, 46.488282],
                    [-87.258732, 46.488255],
                    [-87.259116, 46.488283],
                    [-87.309402, 46.491919],
                    [-87.310755, 46.492017],
                    [-87.312109, 46.49231],
                    [-87.351071, 46.500749],
                    [-87.352448, 46.501324],
                    [-87.366767, 46.507303],
                    [-87.381349, 46.517292],
                    [-87.38929, 46.524472],
                    [-87.393985, 46.533183],
                    [-87.3903, 46.542577],
                    [-87.375613, 46.54714],
                    [-87.382206, 46.553681],
                    [-87.392828, 46.570852],
                    [-87.392974, 46.572523],
                    [-87.381649, 46.580059],
                    [-87.383961, 46.59307],
                    [-87.403275, 46.595215],
                    [-87.411167, 46.601669],
                    [-87.442612, 46.602776],
                    [-87.451368, 46.605923],
                    [-87.464108, 46.614811],
                    [-87.467563, 46.626228],
                    [-87.466537, 46.631555],
                    [-87.467965, 46.635623],
                    [-87.469023, 46.635918],
                    [-87.492409, 46.642435],
                    [-87.49286, 46.642561],
                    [-87.493405, 46.642826],
                    [-87.501511, 46.646762],
                    [-87.503025, 46.647497],
                    [-87.503238, 46.647796],
                    [-87.506657, 46.652599],
                    [-87.519176, 46.670186],
                    [-87.524444, 46.677586],
                    [-87.524419, 46.67783],
                    [-87.523744, 46.6843],
                    [-87.523361, 46.687977],
                    [-87.523308, 46.688488],
                    [-87.532638, 46.694469],
                    [-87.533529, 46.69504],
                    [-87.534504, 46.695664],
                    [-87.54392, 46.7017],
                    [-87.549097, 46.705019],
                    [-87.551989, 46.706872],
                    [-87.553048, 46.707551],
                    [-87.557436, 46.710364],
                    [-87.567343, 46.716714],
                    [-87.573203, 46.720471],
                    [-87.581674, 46.729399],
                    [-87.582745, 46.730527],
                    [-87.58357, 46.732839],
                    [-87.584568, 46.735637],
                    [-87.587626, 46.744206],
                    [-87.587755, 46.744568],
                    [-87.589671, 46.749937],
                    [-87.590502, 46.752267],
                    [-87.590767, 46.753009],
                    [-87.590865, 46.753653],
                    [-87.591082, 46.755087],
                    [-87.591362, 46.756932],
                    [-87.591455, 46.757544],
                    [-87.591907, 46.760525],
                    [-87.591973, 46.760965],
                    [-87.59222, 46.762594],
                    [-87.592227, 46.762635],
                    [-87.593148, 46.768713],
                    [-87.594384, 46.776863],
                    [-87.595276, 46.782745],
                    [-87.595307, 46.78295],
                    [-87.607988, 46.788408],
                    [-87.609008, 46.789258],
                    [-87.610582, 46.790571],
                    [-87.613586, 46.793074],
                    [-87.613654, 46.793131],
                    [-87.6168, 46.795753],
                    [-87.617852, 46.796631],
                    [-87.619747, 46.79821],
                    [-87.627124, 46.80436],
                    [-87.627356, 46.804553],
                    [-87.628081, 46.805157],
                    [-87.628284, 46.805428],
                    [-87.628786, 46.806096],
                    [-87.6333, 46.812107],
                    [-87.640839, 46.813534],
                    [-87.641887, 46.813733],
                    [-87.642789, 46.813609],
                    [-87.646539, 46.813094],
                    [-87.65151, 46.812411],
                    [-87.662261, 46.815157],
                    [-87.672015, 46.820415],
                    [-87.674345, 46.82405],
                    [-87.673973, 46.825179],
                    [-87.673274, 46.8273],
                    [-87.673177, 46.827593],
                    [-87.673433, 46.82935],
                    [-87.673469, 46.829598],
                    [-87.674541, 46.836964],
                    [-87.675046, 46.83742],
                    [-87.679573, 46.841507],
                    [-87.680668, 46.842496],
                    [-87.681561, 46.842392],
                    [-87.68672, 46.841794],
                    [-87.687164, 46.841742],
                    [-87.68728, 46.841351],
                    [-87.687875, 46.839343],
                    [-87.68793, 46.839159],
                    [-87.685735, 46.832639],
                    [-87.685698, 46.83253],
                    [-87.685787, 46.832477],
                    [-87.69459, 46.827182],
                    [-87.713737, 46.825534],
                    [-87.725649, 46.82739],
                    [-87.72588, 46.827426],
                    [-87.727358, 46.827656],
                    [-87.731522, 46.831196],
                    [-87.734325, 46.836955],
                    [-87.736469, 46.846096],
                    [-87.736539, 46.846393],
                    [-87.736732, 46.847216],
                    [-87.735106, 46.849751],
                    [-87.735039, 46.849856],
                    [-87.73487, 46.85012],
                    [-87.740846, 46.864834],
                    [-87.740917, 46.865007],
                    [-87.741014, 46.865247],
                    [-87.741857, 46.865274],
                    [-87.744403, 46.865355],
                    [-87.744893, 46.865371],
                    [-87.745665, 46.865396],
                    [-87.746646, 46.865427],
                    [-87.748498, 46.864428],
                    [-87.754448, 46.861219],
                    [-87.755748, 46.860518],
                    [-87.755868, 46.860453],
                    [-87.755937, 46.860459],
                    [-87.765835, 46.861303],
                    [-87.765989, 46.861316],
                    [-87.766243, 46.861446],
                    [-87.776804, 46.866823],
                    [-87.778752, 46.870422],
                    [-87.778289, 46.870834],
                    [-87.776346, 46.872562],
                    [-87.776313, 46.872591],
                    [-87.776567, 46.874293],
                    [-87.776672, 46.874999],
                    [-87.77693, 46.876726],
                    [-87.777156, 46.876854],
                    [-87.777527, 46.877064],
                    [-87.777749, 46.87719],
                    [-87.781969, 46.87958],
                    [-87.782461, 46.879859],
                    [-87.783216, 46.879927],
                    [-87.788186, 46.880373],
                    [-87.793194, 46.880822],
                    [-87.813226, 46.888023],
                    [-87.813228, 46.888025],
                    [-87.81412, 46.888808],
                    [-87.816794, 46.891154],
                    [-87.827162, 46.889713],
                    [-87.830204, 46.888556],
                    [-87.838889, 46.885252],
                    [-87.841228, 46.884363],
                    [-87.844637, 46.884049],
                    [-87.846195, 46.883905],
                    [-87.847037, 46.884163],
                    [-87.873893, 46.892381],
                    [-87.874538, 46.892578],
                    [-87.875051, 46.892918],
                    [-87.88792, 46.901451],
                    [-87.90007, 46.909508],
                    [-87.900339, 46.909686],
                    [-87.900695, 46.909682],
                    [-87.908791, 46.90959],
                    [-87.910624, 46.909569],
                    [-87.911474, 46.909559],
                    [-87.914489, 46.909525],
                    [-87.915943, 46.909508],
                    [-87.954687, 46.909066],
                    [-87.955745, 46.909054],
                    [-87.956, 46.909051],
                    [-87.957115, 46.908936],
                    [-87.958255, 46.908819],
                    [-87.97549, 46.907048],
                    [-87.982258, 46.906353],
                    [-87.98334, 46.906242],
                    [-87.986113, 46.905957],
                    [-87.98629, 46.905967],
                    [-88.003693, 46.906948],
                    [-88.004298, 46.906982],
                    [-88.029373, 46.908684],
                    [-88.030053, 46.90873],
                    [-88.032408, 46.90889],
                    [-88.032911, 46.909038],
                    [-88.044551, 46.912473],
                    [-88.063614, 46.918097],
                    [-88.065192, 46.918563],
                    [-88.074736, 46.919647],
                    [-88.080087, 46.920255],
                    [-88.08187, 46.920458],
                    [-88.082263, 46.920392],
                    [-88.08377, 46.92014],
                    [-88.083937, 46.920112],
                    [-88.097609, 46.917827],
                    [-88.098858, 46.917618],
                    [-88.0989, 46.917611],
                    [-88.099227, 46.917556],
                    [-88.100133, 46.917405],
                    [-88.101315, 46.917207],
                    [-88.101457, 46.917166],
                    [-88.102362, 46.916906],
                    [-88.102816, 46.916775],
                    [-88.105195, 46.916091],
                    [-88.106377, 46.915751],
                    [-88.122862, 46.911009],
                    [-88.124573, 46.910517],
                    [-88.124935, 46.910413],
                    [-88.126378, 46.909998],
                    [-88.126927, 46.90984],
                    [-88.130288, 46.909369],
                    [-88.160704, 46.90511],
                    [-88.161913, 46.904941],
                    [-88.175197, 46.90458],
                    [-88.181908, 46.912431],
                    [-88.18295, 46.91365],
                    [-88.187522, 46.918999],
                    [-88.185964, 46.920025],
                    [-88.177921, 46.92532],
                    [-88.176156, 46.926483],
                    [-88.175568, 46.92687],
                    [-88.171317, 46.929669],
                    [-88.170905, 46.92994],
                    [-88.167989, 46.931861],
                    [-88.156359, 46.939518],
                    [-88.150114, 46.94363],
                    [-88.13521, 46.959689],
                    [-88.132876, 46.962204],
                    [-88.132957, 46.962237],
                    [-88.14175, 46.965865],
                    [-88.142057, 46.965992],
                    [-88.142339, 46.966108],
                    [-88.142528, 46.966186],
                    [-88.142807, 46.966302],
                    [-88.142893, 46.966337],
                    [-88.143614, 46.966635],
                    [-88.143688, 46.966665],
                    [-88.145561, 46.966409],
                    [-88.155374, 46.965069],
                    [-88.15543, 46.96504],
                    [-88.156273, 46.964598],
                    [-88.160086, 46.962599],
                    [-88.160571, 46.962345],
                    [-88.162437, 46.961366],
                    [-88.162833, 46.961158],
                    [-88.162856, 46.961146],
                    [-88.164059, 46.960516],
                    [-88.164704, 46.960178],
                    [-88.167227, 46.958855],
                    [-88.167373, 46.9588],
                    [-88.176758, 46.955245],
                    [-88.194361, 46.948578],
                    [-88.232969, 46.933955],
                    [-88.233334, 46.933817],
                    [-88.235702, 46.93292],
                    [-88.2358, 46.932883],
                    [-88.238324, 46.931927],
                    [-88.240846, 46.930972],
                    [-88.240926, 46.930942],
                    [-88.24134, 46.930785],
                    [-88.241858, 46.930589],
                    [-88.243175, 46.93009],
                    [-88.244437, 46.929612],
                    [-88.246872, 46.927611],
                    [-88.246953, 46.927545],
                    [-88.250141, 46.924926],
                    [-88.258072, 46.918409],
                    [-88.261593, 46.915516],
                    [-88.272478, 46.910595],
                    [-88.273929, 46.909939],
                    [-88.27473, 46.909577],
                    [-88.276915, 46.908589],
                    [-88.281244, 46.906632],
                    [-88.282066, 46.906154],
                    [-88.282087, 46.906142],
                    [-88.286473, 46.903592],
                    [-88.288767, 46.902259],
                    [-88.289298, 46.90195],
                    [-88.296476, 46.897778],
                    [-88.299515, 46.896012],
                    [-88.305045, 46.892797],
                    [-88.307261, 46.891509],
                    [-88.307978, 46.891092],
                    [-88.308786, 46.890622],
                    [-88.31029, 46.889748],
                    [-88.310714, 46.889415],
                    [-88.333092, 46.871835],
                    [-88.333168, 46.871775],
                    [-88.334647, 46.870613],
                    [-88.34743, 46.860571],
                    [-88.35173, 46.857193],
                    [-88.35194, 46.857028],
                    [-88.352145, 46.857009],
                    [-88.36026, 46.856258],
                    [-88.360868, 46.856202],
                    [-88.361936, 46.856352],
                    [-88.362502, 46.856432],
                    [-88.368767, 46.857313],
                    [-88.372501, 46.858192],
                    [-88.375577, 46.857313],
                    [-88.378432, 46.854897],
                    [-88.38019, 46.852481],
                    [-88.380849, 46.850064],
                    [-88.381727, 46.847868],
                    [-88.381947, 46.84611],
                    [-88.382204, 46.844477],
                    [-88.38141, 46.838466],
                    [-88.415225, 46.811715],
                    [-88.433835, 46.793502],
                    [-88.438427, 46.786714],
                    [-88.462349, 46.786711],
                    [-88.473342, 46.806226],
                    [-88.482579, 46.826197],
                    [-88.483748, 46.831727],
                    [-88.477935, 46.85056],
                    [-88.477845, 46.852091],
                    [-88.477681, 46.854902],
                    [-88.47762, 46.855937],
                    [-88.476918, 46.867946],
                    [-88.476098, 46.881957],
                    [-88.475859, 46.886042],
                    [-88.474217, 46.889034],
                    [-88.457776, 46.918999],
                    [-88.455404, 46.923321],
                    [-88.455117, 46.924305],
                    [-88.454781, 46.925458],
                    [-88.454353, 46.926928],
                    [-88.454333, 46.926996],
                    [-88.452354, 46.933787],
                    [-88.452129, 46.934557],
                    [-88.450823, 46.939038],
                    [-88.44857, 46.946769],
                    [-88.443901, 46.972251],
                    [-88.416364, 46.977071],
                    [-88.41616, 46.977106],
                    [-88.411145, 46.977984],
                    [-88.41089, 46.97819],
                    [-88.410157, 46.978782],
                    [-88.406229, 46.981955],
                    [-88.405352, 46.982663],
                    [-88.404498, 46.983353],
                    [-88.389698, 46.999937],
                    [-88.388564, 47.001208],
                    [-88.387612, 47.002275],
                    [-88.386538, 47.003477],
                    [-88.386153, 47.003909],
                    [-88.385606, 47.004522],
                    [-88.385507, 47.004588],
                    [-88.385338, 47.0047],
                    [-88.384442, 47.005296],
                    [-88.379268, 47.008736],
                    [-88.374238, 47.012081],
                    [-88.373966, 47.012262],
                    [-88.368358, 47.018408],
                    [-88.368062, 47.018733],
                    [-88.367624, 47.019213],
                    [-88.359139, 47.039536],
                    [-88.359054, 47.039739],
                    [-88.358873, 47.040389],
                    [-88.35855, 47.041546],
                    [-88.358201, 47.042798],
                    [-88.357661, 47.044739],
                    [-88.357556, 47.045113],
                    [-88.356884, 47.047524],
                    [-88.354011, 47.057835],
                    [-88.353952, 47.058047],
                    [-88.353191, 47.069063],
                    [-88.349952, 47.076377],
                    [-88.347197, 47.078921],
                    [-88.346709, 47.079372],
                    [-88.346501, 47.079407],
                    [-88.344658, 47.079718],
                    [-88.340052, 47.080494],
                    [-88.337406, 47.081617],
                    [-88.333076, 47.083455],
                    [-88.315051, 47.091108],
                    [-88.313033, 47.091964],
                    [-88.297772, 47.098443],
                    [-88.297625, 47.098505],
                    [-88.297547, 47.098639],
                    [-88.296645, 47.100199],
                    [-88.294082, 47.10463],
                    [-88.291688, 47.10877],
                    [-88.291217, 47.109585],
                    [-88.290708, 47.110465],
                    [-88.290598, 47.110655],
                    [-88.289534, 47.112494],
                    [-88.289005, 47.11341],
                    [-88.28893, 47.113539],
                    [-88.28869, 47.113954],
                    [-88.288347, 47.114547],
                    [-88.288309, 47.114738],
                    [-88.287981, 47.116376],
                    [-88.287901, 47.116779],
                    [-88.287822, 47.117174],
                    [-88.287313, 47.119721],
                    [-88.287173, 47.12042],
                    [-88.28726, 47.121041],
                    [-88.287792, 47.124822],
                    [-88.28787, 47.125374],
                    [-88.288481, 47.125823],
                    [-88.28865, 47.125947],
                    [-88.289543, 47.126604],
                    [-88.289058, 47.129581],
                    [-88.28904, 47.129689],
                    [-88.288994, 47.129743],
                    [-88.281743, 47.138163],
                    [-88.281701, 47.138212],
                    [-88.281652, 47.138239],
                    [-88.272017, 47.143511],
                    [-88.263292, 47.145115],
                    [-88.262972, 47.145174],
                    [-88.262537, 47.145087],
                    [-88.255676, 47.143715],
                    [-88.255303, 47.14364],
                    [-88.254267, 47.142853],
                    [-88.254205, 47.142807],
                    [-88.253834, 47.142524],
                    [-88.250814, 47.140231],
                    [-88.250785, 47.140209],
                    [-88.250645, 47.139752],
                    [-88.249838, 47.137106],
                    [-88.249571, 47.136231],
                    [-88.247628, 47.135981],
                    [-88.239895, 47.139436],
                    [-88.232164, 47.145975],
                    [-88.231797, 47.149609],
                    [-88.234295, 47.149446],
                    [-88.236566, 47.149297],
                    [-88.236721, 47.149287],
                    [-88.236961, 47.149449],
                    [-88.238408, 47.150423],
                    [-88.23947, 47.151137],
                    [-88.239487, 47.151176],
                    [-88.242062, 47.157059],
                    [-88.24266, 47.158426],
                    [-88.242561, 47.160902],
                    [-88.242157, 47.170988],
                    [-88.242142, 47.171358],
                    [-88.242109, 47.172184],
                    [-88.242006, 47.174767],
                    [-88.240295, 47.179609],
                    [-88.237024, 47.188862],
                    [-88.236892, 47.189236],
                    [-88.235041, 47.191532],
                    [-88.229132, 47.198862],
                    [-88.228987, 47.199042],
                    [-88.227552, 47.199938],
                    [-88.212361, 47.209423],
                    [-88.204849, 47.210498],
                    [-88.194218, 47.209242],
                    [-88.163059, 47.216278],
                    [-88.131943, 47.239554],
                    [-88.117456, 47.255174],
                    [-88.108833, 47.259131],
                    [-88.096851, 47.261351],
                    [-88.071476, 47.286768],
                    [-88.06009, 47.295796],
                    [-88.054849, 47.29824],
                    [-88.016478, 47.306275],
                    [-87.989133, 47.322633],
                    [-87.968604, 47.332582],
                    [-87.958386, 47.334435],
                    [-87.946352, 47.334254],
                    [-87.94336, 47.335899],
                    [-87.93825, 47.342299],
                    [-87.938787, 47.346777],
                    [-87.947397, 47.355461],
                    [-87.954796, 47.356809],
                    [-87.962567, 47.362543],
                    [-87.965598, 47.368645],
                    [-87.965063, 47.37443],
                    [-87.957058, 47.38726],
                    [-87.941613, 47.390073],
                    [-87.882245, 47.395588],
                    [-87.8567, 47.395387],
                    [-87.848252, 47.394864],
                    [-87.834822, 47.390478],
                    [-87.827115, 47.38616],
                    [-87.815371, 47.38479],
                    [-87.800294, 47.392148],
                    [-87.765019, 47.398652],
                    [-87.759057, 47.403013],
                    [-87.75138, 47.405066],
                    [-87.742417, 47.405823],
                    [-87.721274, 47.401032],
                    [-87.712421, 47.4014],
                    [-87.710471, 47.4062],
                    [-87.715942, 47.439816],
                    [-87.730804, 47.449112],
                    [-87.756739, 47.460717],
                    [-87.801184, 47.473301],
                    [-87.816958, 47.471998],
                    [-87.898036, 47.474872],
                    [-87.902416, 47.477045],
                    [-87.920259, 47.478169],
                    [-87.920321, 47.478173],
                    [-87.921103, 47.478222],
                    [-87.922224, 47.478293],
                    [-87.927548, 47.478629],
                    [-87.928726, 47.478703],
                    [-87.929269, 47.478737],
                    [-87.929672, 47.478743],
                    [-87.948287, 47.478999],
                    [-87.951912, 47.479048],
                    [-87.954458, 47.479083],
                    [-87.954672, 47.479086],
                    [-87.955374, 47.479096],
                    [-87.95669, 47.479114],
                    [-87.957582, 47.479126],
                    [-87.958042, 47.479133],
                    [-87.959166, 47.479148],
                    [-87.962546, 47.479195],
                    [-87.978121, 47.479409],
                    [-87.978934, 47.47942],
                    [-87.98174, 47.479264],
                    [-87.982227, 47.479236],
                    [-88.009704, 47.477704],
                    [-88.010415, 47.477665],
                    [-88.01083, 47.477642],
                    [-88.014516, 47.477436],
                    [-88.015502, 47.477381],
                    [-88.017268, 47.477283],
                    [-88.021555, 47.477044],
                    [-88.027085, 47.476735],
                    [-88.031089, 47.476512],
                    [-88.03113, 47.47651],
                    [-88.03659, 47.476205],
                    [-88.040242, 47.476002],
                    [-88.040291, 47.475999],
                    [-88.046665, 47.475159],
                    [-88.047857, 47.475002],
                    [-88.048077, 47.474973],
                    [-88.048226, 47.470008],
                    [-88.049326, 47.469785],
                    [-88.067043, 47.468454],
                    [-88.07358, 47.467963],
                    [-88.073982, 47.467933],
                    [-88.074681, 47.46788],
                    [-88.076388, 47.467752],
                    [-88.079183, 47.468133],
                    [-88.080271, 47.468282],
                    [-88.080796, 47.468353],
                    [-88.080916, 47.46837],
                    [-88.081277, 47.468419],
                    [-88.081892, 47.468503],
                    [-88.08358, 47.468733],
                    [-88.08485, 47.468906],
                    [-88.085252, 47.468961],
                    [-88.089966, 47.468418],
                    [-88.090299, 47.46838],
                    [-88.12848, 47.46398],
                    [-88.129274, 47.463889],
                    [-88.137698, 47.462918],
                    [-88.139651, 47.462693],
                    [-88.140537, 47.462482],
                    [-88.143573, 47.461759],
                    [-88.150437, 47.460125],
                    [-88.150571, 47.460093],
                    [-88.15076, 47.460078],
                    [-88.167009, 47.458812],
                    [-88.167703, 47.458758],
                    [-88.169383, 47.458627],
                    [-88.179998, 47.457799],
                    [-88.181763, 47.457661],
                    [-88.18182, 47.457657],
                    [-88.206036, 47.451658],
                    [-88.207239, 47.45136],
                    [-88.207853, 47.451208],
                    [-88.213059, 47.449918],
                    [-88.213141, 47.449898],
                    [-88.217662, 47.448778],
                    [-88.217822, 47.448738],
                    [-88.216977, 47.445493],
                    [-88.218424, 47.441585],
                    [-88.225797, 47.436279],
                    [-88.227446, 47.435093],
                    [-88.228645, 47.434568],
                    [-88.238091, 47.430437],
                    [-88.239161, 47.429969],
                    [-88.23944, 47.429923],
                    [-88.274709, 47.424118],
                    [-88.28406, 47.422579],
                    [-88.285195, 47.422392],
                    [-88.285635, 47.422146],
                    [-88.301214, 47.413451],
                    [-88.303447, 47.412204],
                    [-88.324083, 47.403542],
                    [-88.360313, 47.392951],
                    [-88.378917, 47.387513],
                    [-88.389459, 47.384431],
                    [-88.39144, 47.383533],
                    [-88.394239, 47.382264],
                    [-88.399726, 47.379777],
                    [-88.400224, 47.379551],
                    [-88.418673, 47.371188],
                    [-88.418841, 47.371058],
                    [-88.431446, 47.361343],
                    [-88.431753, 47.361106],
                    [-88.432162, 47.360791],
                    [-88.458952, 47.340142],
                    [-88.459262, 47.339903],
                    [-88.465089, 47.333542],
                    [-88.465888, 47.33267],
                    [-88.470484, 47.327653],
                    [-88.471103, 47.326442],
                    [-88.477733, 47.31346],
                    [-88.478951, 47.312405],
                    [-88.487116, 47.305335],
                    [-88.498756, 47.295256],
                    [-88.50078, 47.293503],
                    [-88.50116, 47.293256],
                    [-88.504898, 47.290831],
                    [-88.512421, 47.285948],
                    [-88.514786, 47.284414],
                    [-88.515276, 47.284096],
                    [-88.518091, 47.282269],
                    [-88.526764, 47.276641],
                    [-88.57172, 47.247466],
                    [-88.573301, 47.246441],
                    [-88.573326, 47.246424],
                    [-88.573997, 47.245989],
                    [-88.584652, 47.242447],
                    [-88.584912, 47.242361],
                    [-88.585196, 47.242321],
                    [-88.598622, 47.240454],
                    [-88.608087, 47.239137],
                    [-88.60983, 47.238894],
                    [-88.609867, 47.238877],
                    [-88.618613, 47.234715],
                    [-88.623302, 47.232484],
                    [-88.623579, 47.232352],
                    [-88.625119, 47.23184],
                    [-88.633915, 47.228915],
                    [-88.640323, 47.226784],
                    [-88.642047, 47.226659],
                    [-88.648001, 47.226229],
                    [-88.656359, 47.225624],
                    [-88.657496, 47.225164],
                    [-88.666614, 47.221475],
                    [-88.672395, 47.219137],
                    [-88.673172, 47.218729],
                    [-88.673771, 47.218415],
                    [-88.674917, 47.217814],
                    [-88.676624, 47.216918],
                    [-88.698282, 47.205554],
                    [-88.698919, 47.20522],
                    [-88.69966, 47.204831],
                    [-88.700047, 47.204586],
                    [-88.702029, 47.203333],
                    [-88.702086, 47.203296],
                    [-88.712138, 47.196937],
                    [-88.728327, 47.186695],
                    [-88.728541, 47.18656],
                    [-88.729688, 47.185834],
                    [-88.74665, 47.171118],
                    [-88.746724, 47.171055],
                    [-88.750496, 47.167782],
                    [-88.764351, 47.155762],
                    [-88.778022, 47.150465],
                    [-88.779188, 47.15051],
                    [-88.783821, 47.150691],
                    [-88.784612, 47.150722],
                    [-88.789813, 47.150925],
                    [-88.798779, 47.147511],
                    [-88.813748, 47.141813],
                    [-88.814834, 47.141399],
                    [-88.815355, 47.140987],
                    [-88.815403, 47.14095],
                    [-88.816553, 47.140042],
                    [-88.816684, 47.139938],
                    [-88.825475, 47.132994],
                    [-88.825619, 47.132881],
                    [-88.826028, 47.132558],
                    [-88.848176, 47.115065],
                    [-88.854656, 47.114343],
                    [-88.855126, 47.11429],
                    [-88.855372, 47.114263],
                    [-88.872087, 47.107487],
                    [-88.885361, 47.102107],
                    [-88.885418, 47.102084],
                    [-88.88914, 47.100575],
                    [-88.890122, 47.099603],
                    [-88.890422, 47.099306],
                    [-88.890708, 47.099024],
                    [-88.899857, 47.089969],
                    [-88.903706, 47.086161],
                    [-88.903933, 47.085579],
                    [-88.908288, 47.074396],
                    [-88.914189, 47.059246],
                    [-88.914976, 47.05794],
                    [-88.923458, 47.043872],
                    [-88.924492, 47.042156],
                    [-88.925586, 47.040923],
                    [-88.933329, 47.0322],
                    [-88.944045, 47.020129],
                    [-88.957276, 47.010111],
                    [-88.959409, 47.008496],
                    [-88.960337, 47.008053],
                    [-88.972802, 47.002096],
                    [-88.978433, 47.000196],
                    [-88.978813, 47.000068],
                    [-88.982483, 46.99883],
                    [-88.987197, 46.997239],
                    [-88.99172, 46.996463],
                    [-88.992399, 46.996347],
                    [-88.998417, 46.995314],
                    [-88.998907, 46.99531],
                    [-89.022994, 46.99512],
                    [-89.027648, 46.999839],
                    [-89.02893, 47.00114],
                    [-89.038116, 46.999643],
                    [-89.03949, 46.999419],
                    [-89.048164, 46.995416],
                    [-89.058591, 46.990604],
                    [-89.063103, 46.988522],
                    [-89.086742, 46.985298],
                    [-89.106277, 46.98648],
                    [-89.113158, 46.989356],
                    [-89.115903, 46.991933],
                    [-89.118106, 46.994002],
                    [-89.118339, 46.99422],
                    [-89.124799, 46.993209],
                    [-89.128101, 46.992692],
                    [-89.128698, 46.992599],
                    [-89.142282, 46.985033],
                    [-89.142425, 46.984954],
                    [-89.142595, 46.984859],
                    [-89.168244, 46.965536],
                    [-89.168493, 46.965279],
                    [-89.18373, 46.949529],
                    [-89.199648, 46.933075],
                    [-89.201511, 46.931149],
                    [-89.202811, 46.930253],
                    [-89.226244, 46.914105],
                    [-89.227914, 46.912954],
                    [-89.228362, 46.912751],
                    [-89.249143, 46.903326],
                    [-89.249938, 46.903029],
                    [-89.278612, 46.89231],
                    [-89.28578, 46.88963],
                    [-89.304623, 46.882586],
                    [-89.317396, 46.877811],
                    [-89.33613, 46.870807],
                    [-89.370788, 46.857851],
                    [-89.372032, 46.857386],
                    [-89.395386, 46.850127],
                    [-89.401123, 46.848344],
                    [-89.413413, 46.844524],
                    [-89.415154, 46.843983],
                    [-89.434016, 46.840131],
                    [-89.437047, 46.839512],
                    [-89.440145, 46.839319],
                    [-89.445969, 46.838955],
                    [-89.446583, 46.838917],
                    [-89.448445, 46.838801],
                    [-89.448454, 46.8388],
                    [-89.453185, 46.838505],
                    [-89.469446, 46.83749],
                    [-89.469651, 46.837477],
                    [-89.470547, 46.837421],
                    [-89.470619, 46.837416],
                    [-89.470915, 46.837398],
                    [-89.47154, 46.837359],
                    [-89.471849, 46.837376],
                    [-89.475333, 46.837569],
                    [-89.485166, 46.838112],
                    [-89.491079, 46.838438],
                    [-89.491252, 46.838448],
                    [-89.491307, 46.83847],
                    [-89.494019, 46.83957],
                    [-89.49776, 46.841086],
                    [-89.49908, 46.841621],
                    [-89.499248, 46.841623],
                    [-89.500227, 46.841638],
                    [-89.513938, 46.841835],
                    [-89.515665, 46.841362],
                    [-89.516895, 46.841025],
                    [-89.525656, 46.838625],
                    [-89.534331, 46.836248],
                    [-89.535683, 46.835878],
                    [-89.538927, 46.835496],
                    [-89.540709, 46.835286],
                    [-89.542408, 46.835086],
                    [-89.564509, 46.832483],
                    [-89.569808, 46.831859],
                    [-89.570326, 46.831723],
                    [-89.572423, 46.831174],
                    [-89.578217, 46.829657],
                    [-89.598527, 46.824338],
                    [-89.619329, 46.81889],
                    [-89.634938, 46.819488],
                    [-89.637309, 46.821384],
                    [-89.642255, 46.82534],
                    [-89.64344, 46.825709],
                    [-89.646854, 46.826771],
                    [-89.651795, 46.828309],
                    [-89.659681, 46.830762],
                    [-89.660625, 46.831056],
                    [-89.673375, 46.833229],
                    [-89.676878, 46.833019],
                    [-89.677771, 46.832965],
                    [-89.678469, 46.832923],
                    [-89.708084, 46.831145],
                    [-89.708303, 46.831132],
                    [-89.708873, 46.831098],
                    [-89.710062, 46.831026],
                    [-89.713354, 46.830829],
                    [-89.717848, 46.830559],
                    [-89.720277, 46.830413],
                    [-89.724471, 46.829701],
                    [-89.757585, 46.824082],
                    [-89.78708, 46.819077],
                    [-89.788353, 46.818861],
                    [-89.790663, 46.818469],
                    [-89.828129, 46.805389],
                    [-89.830093, 46.804703],
                    [-89.830863, 46.804434],
                    [-89.831956, 46.804053],
                    [-89.841453, 46.799308],
                    [-89.844691, 46.79769],
                    [-89.846962, 46.796556],
                    [-89.848652, 46.795711],
                    [-89.850979, 46.793974],
                    [-89.851966, 46.793237],
                    [-89.862544, 46.785341],
                    [-89.875069, 46.775991],
                    [-89.888687, 46.765826],
                    [-89.892355, 46.763088],
                    [-89.907516, 46.74987],
                    [-89.918466, 46.740324],
                    [-89.957101, 46.716929],
                    [-89.973803, 46.710322],
                    [-89.985817, 46.70319],
                    [-89.996034, 46.693225],
                    [-90.028392, 46.67439],
                    [-90.04542, 46.668272],
                    [-90.100695, 46.655132],
                    [-90.164026, 46.645515],
                    [-90.237609, 46.624485],
                    [-90.250176, 46.621776],
                    [-90.251669, 46.621454],
                    [-90.265291, 46.618517],
                    [-90.265294, 46.618516],
                    [-90.278287, 46.613555],
                    [-90.279723, 46.613007],
                    [-90.28315, 46.611698],
                    [-90.284189, 46.611302],
                    [-90.28455, 46.611164],
                    [-90.29546, 46.606998],
                    [-90.306609, 46.602741],
                    [-90.327604, 46.607739],
                    [-90.327626, 46.607744],
                    [-90.347801, 46.600842],
                    [-90.348407, 46.600635],
                    [-90.353284, 46.598219],
                    [-90.355341, 46.5972],
                    [-90.371717, 46.589088],
                    [-90.394936, 46.577586],
                    [-90.396772, 46.576677],
                    [-90.39731, 46.57641],
                    [-90.39735, 46.576391],
                    [-90.398478, 46.575832],
                    [-90.417943, 46.56619],
                    [-90.418136, 46.566094],
                    [-90.433367, 46.562492],
                    [-90.434812, 46.56215],
                    [-90.436314, 46.561795],
                    [-90.436512, 46.561748],
                    [-90.437532, 46.561507],
                    [-90.437596, 46.561492],
                    [-90.438174, 46.561695],
                    [-90.440085, 46.562365],
                    [-90.472483, 46.57373],
                    [-90.47376, 46.574178],
                    [-90.476046, 46.575276],
                    [-90.476315, 46.575405],
                    [-90.47648, 46.575484],
                    [-90.478469, 46.576439],
                    [-90.478826, 46.57661],
                    [-90.497359, 46.585509],
                    [-90.505909, 46.589614],
                    [-90.51851, 46.587885],
                    [-90.519002, 46.587817],
                    [-90.519105, 46.587803],
                    [-90.522674, 46.587313],
                    [-90.525334, 46.586949],
                    [-90.525498, 46.586926],
                    [-90.525788, 46.58679],
                    [-90.527776, 46.585858],
                    [-90.537829, 46.581143],
                    [-90.537962, 46.581081],
                    [-90.538346, 46.581182],
                    [-90.549596, 46.584138],
                    [-90.556224, 46.58588],
                    [-90.558141, 46.586384],
                    [-90.561126, 46.587816],
                    [-90.561966, 46.588218],
                    [-90.563668, 46.589034],
                    [-90.579422, 46.596589],
                    [-90.580191, 46.596958],
                    [-90.581408, 46.597541],
                    [-90.586249, 46.599863],
                    [-90.590712, 46.602433],
                    [-90.590811, 46.60249],
                    [-90.591894, 46.603114],
                    [-90.595583, 46.605238],
                    [-90.59585, 46.605392],
                    [-90.599375, 46.607422],
                    [-90.599602, 46.607552],
                    [-90.606177, 46.611339],
                    [-90.60672, 46.611651],
                    [-90.607649, 46.612186],
                    [-90.627885, 46.623839],
                    [-90.650949, 46.637837],
                    [-90.654497, 46.63999],
                    [-90.694721, 46.664402],
                    [-90.705375, 46.671381],
                    [-90.724924, 46.684186],
                    [-90.73726, 46.692267],
                    [-90.739549, 46.689981],
                    [-90.739565, 46.689943],
                    [-90.748026, 46.669968],
                    [-90.74809, 46.669817],
                    [-90.756495, 46.664591],
                    [-90.756312, 46.66182],
                    [-90.755289, 46.646323],
                    [-90.755287, 46.646289],
                    [-90.755381, 46.646225],
                    [-90.768381, 46.637362],
                    [-90.770192, 46.636127],
                    [-90.772455, 46.635097],
                    [-90.792583, 46.625938],
                    [-90.794775, 46.624941],
                    [-90.829031, 46.616066],
                    [-90.82907, 46.616051],
                    [-90.830011, 46.615702],
                    [-90.831226, 46.61525],
                    [-90.831868, 46.615012],
                    [-90.834259, 46.614123],
                    [-90.837306, 46.612991],
                    [-90.841708, 46.611355],
                    [-90.842058, 46.611225],
                    [-90.843189, 46.610805],
                    [-90.843225, 46.610791],
                    [-90.849684, 46.608391],
                    [-90.851889, 46.607571],
                    [-90.852153, 46.607473],
                    [-90.85338, 46.607017],
                    [-90.854575, 46.606573],
                    [-90.85609, 46.60601],
                    [-90.856244, 46.605953],
                    [-90.856811, 46.605742],
                    [-90.858725, 46.605031],
                    [-90.864284, 46.602965],
                    [-90.86712, 46.601911],
                    [-90.873154, 46.601223],
                    [-90.885007, 46.594752],
                    [-90.885103, 46.5947],
                    [-90.886197, 46.594102],
                    [-90.891944, 46.590964],
                    [-90.901725, 46.585624],
                    [-90.901918, 46.585519],
                    [-90.905572, 46.583524],
                    [-90.906058, 46.58343],
                    [-90.909815, 46.582703],
                    [-90.916982, 46.583014],
                    [-90.918266, 46.58307],
                    [-90.918352, 46.583089],
                    [-90.918414, 46.583103],
                    [-90.91992, 46.583444],
                    [-90.920132, 46.583492],
                    [-90.920411, 46.583555],
                    [-90.921483, 46.583797],
                    [-90.923438, 46.584239],
                    [-90.92356, 46.584267],
                    [-90.927387, 46.585132],
                    [-90.941102, 46.588232],
                    [-90.941374, 46.588293],
                    [-90.94193, 46.588419],
                    [-90.942101, 46.588573],
                    [-90.947159, 46.593138],
                    [-90.947287, 46.593253],
                    [-90.947572, 46.59351],
                    [-90.951476, 46.597033],
                    [-90.951528, 46.599827],
                    [-90.951543, 46.600621],
                    [-90.951418, 46.600774],
                    [-90.950544, 46.601845],
                    [-90.950215, 46.602248],
                    [-90.949621, 46.602975],
                    [-90.949532, 46.603019],
                    [-90.93868, 46.608322],
                    [-90.938617, 46.608398],
                    [-90.93831, 46.608768],
                    [-90.933208, 46.614913],
                    [-90.93212, 46.616223],
                    [-90.931623, 46.616822],
                    [-90.926745, 46.622698],
                    [-90.924489, 46.625415],
                    [-90.924487, 46.625417],
                    [-90.920936, 46.631584],
                    [-90.920936, 46.631606],
                    [-90.920867, 46.635515],
                    [-90.92085, 46.636492],
                    [-90.920835, 46.637351],
                    [-90.920813, 46.637432],
                    [-90.915367, 46.657615],
                    [-90.915152, 46.65841],
                    [-90.914619, 46.659054],
                    [-90.911353, 46.662996],
                    [-90.911281, 46.663083],
                    [-90.909688, 46.66354],
                    [-90.909176, 46.663687],
                    [-90.905567, 46.664722],
                    [-90.905273, 46.664807],
                    [-90.896529, 46.667315],
                    [-90.885943, 46.670353],
                    [-90.885869, 46.670374],
                    [-90.870956, 46.678945],
                    [-90.870739, 46.67907],
                    [-90.870532, 46.679189],
                    [-90.870079, 46.679449],
                    [-90.868468, 46.680375],
                    [-90.868376, 46.680457],
                    [-90.867981, 46.68081],
                    [-90.867823, 46.680952],
                    [-90.867423, 46.681309],
                    [-90.865745, 46.682808],
                    [-90.865494, 46.683033],
                    [-90.854692, 46.692686],
                    [-90.854539, 46.692822],
                    [-90.853927, 46.693369],
                    [-90.853829, 46.693457],
                    [-90.853807, 46.693579],
                    [-90.853644, 46.694464],
                    [-90.852731, 46.699437],
                    [-90.852704, 46.699582],
                    [-90.852916, 46.699866],
                    [-90.853225, 46.70028],
                    [-90.859601, 46.708826],
                    [-90.859797, 46.709088],
                    [-90.860897, 46.710562],
                    [-90.861353, 46.711173],
                    [-90.863864, 46.714539],
                    [-90.868024, 46.720115],
                    [-90.868273, 46.720448],
                    [-90.868531, 46.720794],
                    [-90.868607, 46.720896],
                    [-90.869461, 46.722039],
                    [-90.870396, 46.723293],
                    [-90.870577, 46.723623],
                    [-90.871612, 46.725509],
                    [-90.875829, 46.733195],
                    [-90.876039, 46.733577],
                    [-90.876334, 46.734115],
                    [-90.876426, 46.734284],
                    [-90.876538, 46.734487],
                    [-90.878343, 46.737777],
                    [-90.87881, 46.738629],
                    [-90.881622, 46.743753],
                    [-90.882099, 46.744622],
                    [-90.883396, 46.746987],
                    [-90.883443, 46.747255],
                    [-90.885021, 46.756341],
                    [-90.875925, 46.760321],
                    [-90.874598, 46.760902],
                    [-90.866694, 46.764361],
                    [-90.866586, 46.764408],
                    [-90.866337, 46.764626],
                    [-90.862333, 46.768135],
                    [-90.86088, 46.771079],
                    [-90.859445, 46.773985],
                    [-90.859724, 46.774433],
                    [-90.863542, 46.780565],
                    [-90.859999, 46.784769],
                    [-90.856677, 46.788712],
                    [-90.856531, 46.788885],
                    [-90.856317, 46.788894],
                    [-90.855746, 46.788918],
                    [-90.855472, 46.788929],
                    [-90.854916, 46.788952],
                    [-90.847214, 46.789274],
                    [-90.835607, 46.789759],
                    [-90.835319, 46.790051],
                    [-90.835028, 46.790346],
                    [-90.835008, 46.790366],
                    [-90.831645, 46.793777],
                    [-90.830094, 46.795349],
                    [-90.829933, 46.795513],
                    [-90.829048, 46.79641],
                    [-90.828057, 46.797415],
                    [-90.82735, 46.799345],
                    [-90.826337, 46.80211],
                    [-90.825696, 46.803858],
                    [-90.821967, 46.806545],
                    [-90.80927, 46.815695],
                    [-90.80137, 46.821389],
                    [-90.801041, 46.821626],
                    [-90.798936, 46.823143],
                    [-90.798545, 46.823922],
                    [-90.797404, 46.8262],
                    [-90.796437, 46.828129],
                    [-90.795957, 46.829087],
                    [-90.795693, 46.829614],
                    [-90.793713, 46.833566],
                    [-90.792034, 46.836916],
                    [-90.78804, 46.844886],
                    [-90.781011, 46.858911],
                    [-90.780972, 46.858989],
                    [-90.77024, 46.876184],
                    [-90.77017, 46.876296],
                    [-90.761567, 46.88317],
                    [-90.761295, 46.883238],
                    [-90.754753, 46.884875],
                    [-90.754734, 46.88488],
                    [-90.754692, 46.884915],
                    [-90.751151, 46.887863],
                    [-90.751031, 46.887963],
                    [-90.750952, 46.890293],
                    [-90.750889, 46.892136],
                    [-90.750871, 46.892643],
                    [-90.750858, 46.893035],
                    [-90.751048, 46.893305],
                    [-90.751329, 46.893702],
                    [-90.752725, 46.89568],
                    [-90.754388, 46.898037],
                    [-90.754552, 46.89827],
                    [-90.75563, 46.899247],
                    [-90.759364, 46.902634],
                    [-90.760095, 46.903296],
                    [-90.785606, 46.926431],
                    [-90.78601, 46.926672],
                    [-90.786502, 46.926964],
                    [-90.786595, 46.927019],
                    [-90.805028, 46.937987],
                    [-90.833091, 46.954686],
                    [-90.837617, 46.957379],
                    [-90.837716, 46.957438],
                    [-90.837973, 46.957506],
                    [-90.838814, 46.957728],
                    [-90.855165, 46.962045],
                    [-90.855874, 46.962232],
                    [-90.871126, 46.961129],
                    [-90.875635, 46.959515],
                    [-90.875995, 46.959386],
                    [-90.876006, 46.959382],
                    [-90.876211, 46.959309],
                    [-90.876213, 46.959308],
                    [-90.876544, 46.959189],
                    [-90.876778, 46.959106],
                    [-90.877092, 46.958993],
                    [-90.877165, 46.958967],
                    [-90.877324, 46.95891],
                    [-90.879621, 46.958088],
                    [-90.880358, 46.957661],
                    [-90.908598, 46.941305],
                    [-90.908654, 46.941221],
                    [-90.913838, 46.9334],
                    [-90.914044, 46.933346],
                    [-90.921382, 46.931434],
                    [-90.921811, 46.931322],
                    [-90.92204, 46.931372],
                    [-90.94056, 46.935394],
                    [-90.953685, 46.938244],
                    [-90.953865, 46.938283],
                    [-90.954537, 46.938429],
                    [-90.954612, 46.938468],
                    [-90.964072, 46.943369],
                    [-90.964865, 46.94378],
                    [-90.968419, 46.94391],
                    [-90.973755, 46.941304],
                    [-90.980235, 46.931937],
                    [-90.983192, 46.927662],
                    [-90.984617, 46.925602],
                    [-90.987013, 46.923776],
                    [-90.995149, 46.917577],
                    [-90.996825, 46.916851],
                    [-90.997943, 46.916367],
                    [-90.998848, 46.915975],
                    [-91.004752, 46.916187],
                    [-91.004892, 46.916192],
                    [-91.005199, 46.916203],
                    [-91.005872, 46.915976],
                    [-91.007997, 46.915259],
                    [-91.010689, 46.914352],
                    [-91.016184, 46.912499],
                    [-91.016237, 46.912481],
                    [-91.018024, 46.911879],
                    [-91.018045, 46.911872],
                    [-91.019141, 46.911502],
                    [-91.01967, 46.911211],
                    [-91.021538, 46.910185],
                    [-91.023976, 46.908845],
                    [-91.024009, 46.908827],
                    [-91.030583, 46.905215],
                    [-91.032508, 46.904158],
                    [-91.033447, 46.903642],
                    [-91.034518, 46.903053],
                    [-91.035936, 46.896679],
                    [-91.036193, 46.895523],
                    [-91.036622, 46.893594],
                    [-91.03989, 46.88923],
                    [-91.042992, 46.887358],
                    [-91.04922, 46.8836],
                    [-91.049232, 46.883593],
                    [-91.050153, 46.883037],
                    [-91.052991, 46.881325],
                    [-91.056258, 46.880678],
                    [-91.066193, 46.87871],
                    [-91.06822, 46.878309],
                    [-91.069331, 46.878772],
                    [-91.072584, 46.880126],
                    [-91.080951, 46.883609],
                    [-91.085077, 46.88322],
                    [-91.088721, 46.882877],
                    [-91.090916, 46.88267],
                    [-91.093714, 46.879882],
                    [-91.094096, 46.877423],
                    [-91.094498, 46.874837],
                    [-91.09462, 46.87405],
                    [-91.094724, 46.873383],
                    [-91.094828, 46.872714],
                    [-91.095058, 46.871234],
                    [-91.096028, 46.864987],
                    [-91.096342, 46.862965],
                    [-91.096565, 46.86153],
                    [-91.09755, 46.861098],
                    [-91.098125, 46.860847],
                    [-91.098346, 46.86075],
                    [-91.09878, 46.86056],
                    [-91.10549, 46.85762],
                    [-91.107323, 46.857469],
                    [-91.11854, 46.856548],
                    [-91.123109, 46.856173],
                    [-91.12353, 46.856273],
                    [-91.134732, 46.858935],
                    [-91.134948, 46.858986],
                    [-91.134977, 46.859023],
                    [-91.136512, 46.860975],
                    [-91.134882, 46.865784],
                    [-91.134724, 46.86625],
                    [-91.134184, 46.867843],
                    [-91.133674, 46.869348],
                    [-91.133337, 46.870341],
                    [-91.133772, 46.871043],
                    [-91.134668, 46.87249],
                    [-91.139758, 46.873148],
                    [-91.140165, 46.873201],
                    [-91.140301, 46.873105],
                    [-91.14385, 46.870595],
                    [-91.143877, 46.870576],
                    [-91.144266, 46.870301],
                    [-91.147837, 46.863082],
                    [-91.148026, 46.862906],
                    [-91.156108, 46.855414],
                    [-91.167601, 46.84476],
                    [-91.168297, 46.844727],
                    [-91.178292, 46.844259],
                    [-91.200107, 46.854017],
                    [-91.204439, 46.858816],
                    [-91.204839, 46.859727],
                    [-91.207524, 46.865835],
                    [-91.211113, 46.866696],
                    [-91.211647, 46.866824],
                    [-91.214886, 46.866137],
                    [-91.226796, 46.86361],
                    [-91.232733, 46.860035],
                    [-91.235283, 46.857368],
                    [-91.23773, 46.854809],
                    [-91.249471, 46.842531],
                    [-91.250806, 46.841135],
                    [-91.256563, 46.836989],
                    [-91.256705, 46.836887],
                    [-91.256873, 46.836833],
                    [-91.263445, 46.834722],
                    [-91.263725, 46.834632],
                    [-91.265816, 46.83396],
                    [-91.265866, 46.833944],
                    [-91.266404, 46.833891],
                    [-91.278558, 46.832689],
                    [-91.289541, 46.831604],
                    [-91.301879, 46.830384],
                    [-91.302295, 46.830343],
                    [-91.303594, 46.829978],
                    [-91.304512, 46.82972],
                    [-91.305487, 46.829446],
                    [-91.30705, 46.829007],
                    [-91.314815, 46.826825],
                    [-91.315061, 46.826729],
                    [-91.32101, 46.824414],
                    [-91.322202, 46.82395],
                    [-91.326045, 46.822454],
                    [-91.328848, 46.821363],
                    [-91.330433, 46.820746],
                    [-91.330463, 46.820735],
                    [-91.33757, 46.817969],
                    [-91.33825, 46.817704],
                    [-91.352191, 46.807417],
                    [-91.35533, 46.804035],
                    [-91.359434, 46.799612],
                    [-91.360804, 46.798136],
                    [-91.363644, 46.796612],
                    [-91.365277, 46.795736],
                    [-91.365536, 46.795597],
                    [-91.365974, 46.795362],
                    [-91.366888, 46.794872],
                    [-91.367498, 46.794545],
                    [-91.368819, 46.793836],
                    [-91.369387, 46.793745],
                    [-91.380577, 46.791951],
                    [-91.386155, 46.791057],
                    [-91.390774, 46.790316],
                    [-91.391469, 46.790205],
                    [-91.394764, 46.790694],
                    [-91.396261, 46.790917],
                    [-91.396959, 46.79102],
                    [-91.39714, 46.791047],
                    [-91.398256, 46.791213],
                    [-91.411799, 46.78964],
                    [-91.423713, 46.78217],
                    [-91.426491, 46.781208],
                    [-91.428526, 46.780504],
                    [-91.436955, 46.777586],
                    [-91.449327, 46.773303],
                    [-91.44957, 46.773252],
                    [-91.462774, 46.770471],
                    [-91.467168, 46.769546],
                    [-91.470124, 46.768923],
                    [-91.470181, 46.768911],
                    [-91.472097, 46.768717],
                    [-91.488247, 46.767086],
                    [-91.489125, 46.766997],
                    [-91.491744, 46.766732],
                    [-91.492429, 46.766663],
                    [-91.492493, 46.766615],
                    [-91.493037, 46.76621],
                    [-91.493643, 46.765757],
                    [-91.499696, 46.761243],
                    [-91.500042, 46.761128],
                    [-91.50716, 46.758757],
                    [-91.507586, 46.758615],
                    [-91.507819, 46.758538],
                    [-91.510595, 46.757614],
                    [-91.511077, 46.757453],
                    [-91.517712, 46.756774],
                    [-91.524766, 46.756052],
                    [-91.535764, 46.754926],
                    [-91.537115, 46.754788],
                    [-91.537583, 46.754817],
                    [-91.543057, 46.755153],
                    [-91.551408, 46.755666],
                    [-91.551445, 46.755674],
                    [-91.557503, 46.756155],
                    [-91.569075, 46.757074],
                    [-91.571423, 46.75726],
                    [-91.574019, 46.757466],
                    [-91.574291, 46.757488],
                    [-91.576632, 46.757037],
                    [-91.590684, 46.754331],
                    [-91.592203, 46.753788],
                    [-91.592742, 46.753595],
                    [-91.593442, 46.753345],
                    [-91.625387, 46.741924],
                    [-91.635107, 46.738449],
                    [-91.635161, 46.73843],
                    [-91.636005, 46.738128],
                    [-91.63604, 46.738116],
                    [-91.636919, 46.737802],
                    [-91.645502, 46.734733],
                    [-91.646146, 46.734575],
                    [-91.652915, 46.732911],
                    [-91.658739, 46.731479],
                    [-91.66067, 46.731004],
                    [-91.662426, 46.730572],
                    [-91.667534, 46.729317],
                    [-91.675621, 46.727329],
                    [-91.677021, 46.726984],
                    [-91.735632, 46.712575],
                    [-91.748787, 46.709341],
                    [-91.74965, 46.709129],
                    [-91.758619, 46.705927],
                    [-91.781928, 46.697604],
                    [-91.790132, 46.694675],
                    [-91.790473, 46.694624],
                    [-91.798455, 46.693422],
                    [-91.799987, 46.693192],
                    [-91.814669, 46.690982],
                    [-91.817099, 46.690617],
                    [-91.817185, 46.690604],
                    [-91.820027, 46.690176],
                    [-91.831355, 46.689906],
                    [-91.840288, 46.689693],
                    [-91.857462, 46.692362],
                    [-91.864491, 46.69185],
                    [-91.866583, 46.691697],
                    [-91.87484, 46.691095],
                    [-91.876183, 46.690997],
                    [-91.877371, 46.69091],
                    [-91.87873, 46.690811],
                    [-91.886963, 46.690211],
                    [-91.930261, 46.682273],
                    [-91.942988, 46.679939],
                    [-91.961889, 46.682539],
                    [-91.973389, 46.686439],
                    [-91.987889, 46.692739],
                    [-92.007989, 46.705039],
                    [-92.01529, 46.706469],
                    [-92.025789, 46.710839],
                    [-92.06449, 46.745439],
                    [-92.088289, 46.773639],
                    [-92.094089, 46.787839],
                    [-92.086089, 46.794339],
                    [-92.062088, 46.804038],
                    [-92.058888, 46.809938],
                    [-92.013405, 46.833727],
                    [-91.997987, 46.838737],
                    [-91.985086, 46.849637],
                    [-91.952985, 46.867037],
                    [-91.914984, 46.883836],
                    [-91.906483, 46.891236],
                    [-91.883238, 46.905728],
                    [-91.871286, 46.908352],
                    [-91.841349, 46.925215],
                    [-91.834852, 46.927135],
                    [-91.826068, 46.927199],
                    [-91.806851, 46.933727],
                    [-91.794039, 46.939676],
                    [-91.780675, 46.945881],
                    [-91.7773, 46.951799],
                    [-91.737098, 46.982853],
                    [-91.704649, 47.005246],
                    [-91.666477, 47.014297],
                    [-91.660248, 47.019288],
                    [-91.644564, 47.026491],
                    [-91.637164, 47.040429],
                    [-91.626824, 47.049953],
                    [-91.613173, 47.059192],
                    [-91.604949, 47.063309],
                    [-91.600969, 47.063425],
                    [-91.591508, 47.068684],
                    [-91.573817, 47.089917],
                    [-91.518793, 47.108121],
                    [-91.506998, 47.118489],
                    [-91.497902, 47.122579],
                    [-91.477351, 47.125667],
                    [-91.456965, 47.139156],
                    [-91.452031, 47.145158],
                    [-91.418805, 47.172152],
                    [-91.398455, 47.183916],
                    [-91.387021, 47.187293],
                    [-91.374191, 47.1978],
                    [-91.357803, 47.206743],
                    [-91.35385, 47.212686],
                    [-91.326019, 47.238993],
                    [-91.288478, 47.26596],
                    [-91.270697, 47.277134],
                    [-91.26595, 47.279479],
                    [-91.262512, 47.27929],
                    [-91.250163, 47.29049],
                    [-91.238658, 47.304976],
                    [-91.206248, 47.329182],
                    [-91.188772, 47.340082],
                    [-91.170037, 47.366266],
                    [-91.156513, 47.378816],
                    [-91.146958, 47.381464],
                    [-91.131268, 47.393567],
                    [-91.128131, 47.399619],
                    [-91.106218, 47.411806],
                    [-91.097569, 47.413888],
                    [-91.077712, 47.428767],
                    [-91.045646, 47.456525],
                    [-91.032945, 47.458236],
                    [-91.023125, 47.464964],
                    [-91.023124, 47.464964],
                    [-90.939072, 47.514532],
                    [-90.927975, 47.519008],
                    [-90.919375, 47.519784],
                    [-90.914247, 47.522639],
                    [-90.909801, 47.526215],
                    [-90.910127, 47.530178],
                    [-90.907494, 47.532873],
                    [-90.86827, 47.5569],
                    [-90.735927, 47.624343],
                    [-90.686382, 47.643594],
                    [-90.647837, 47.656176],
                    [-90.584954, 47.68074],
                    [-90.551291, 47.690266],
                    [-90.537105, 47.703055],
                    [-90.458365, 47.7214],
                    [-90.441912, 47.726404],
                    [-90.437712, 47.731612],
                    [-90.42139, 47.73515],
                    [-90.393823, 47.738271],
                    [-90.386234, 47.7411],
                    [-90.332686, 47.746387],
                    [-90.330254, 47.750892],
                    [-90.323446, 47.753771],
                    [-90.313958, 47.756681],
                    [-90.30634, 47.756627],
                    [-90.295952, 47.759054],
                    [-90.248794, 47.772763],
                    [-90.229145, 47.776198],
                    [-90.187636, 47.77813],
                    [-90.178755, 47.786414],
                    [-90.16079, 47.792807],
                    [-90.132078, 47.79572],
                    [-90.1168, 47.79538],
                    [-90.08816, 47.803041],
                    [-90.082354, 47.803619],
                    [-90.075559, 47.803303],
                    [-90.072241, 47.807727],
                    [-90.072025, 47.811105],
                    [-90.042761, 47.817568],
                    [-90.01373, 47.821373],
                    [-89.974296, 47.830514],
                    [-89.933899, 47.84676],
                    [-89.92752, 47.850825],
                    [-89.930844, 47.857723],
                    [-89.923649, 47.862062],
                    [-89.87158, 47.874194],
                    [-89.85396, 47.873997],
                    [-89.793539, 47.891358],
                    [-89.758714, 47.906993],
                    [-89.737539, 47.918183],
                    [-89.72973, 47.925245],
                    [-89.697619, 47.941288],
                    [-89.660616, 47.951216],
                    [-89.639545, 47.95359],
                    [-89.638285, 47.954275],
                    [-89.639844, 47.959826],
                    [-89.640129, 47.96793],
                    [-89.637015, 47.973465],
                    [-89.631825, 47.980039],
                    [-89.624559, 47.983153],
                    [-89.611412, 47.980731],
                    [-89.59589, 47.971046],
                    [-89.58823, 47.9662],
                    [-89.572315, 47.967238],
                    [-89.555015, 47.974849],
                    [-89.552939, 47.980731],
                    [-89.551555, 47.987305],
                    [-89.541521, 47.992841],
                    [-89.495344, 48.002356],
                    [-89.491739, 48.005212],
                    [-89.489226, 48.014528],
                    [-89.564288, 48.00293],
                    [-89.655793, 48.007532],
                    [-89.687891, 48.010826],
                    [-89.724184, 48.019818],
                    [-89.749314, 48.023325],
                    [-89.763967, 48.022969],
                    [-89.819802, 48.015099],
                    [-89.847571, 47.992442],
                    [-89.873286, 47.985419],
                    [-89.897414, 47.987599],
                    [-89.968255, 48.014482],
                    [-89.99305, 48.028404],
                    [-89.996702, 48.035391],
                    [-89.993822, 48.049027],
                    [-89.997852, 48.057567],
                    [-90.023595, 48.084708],
                    [-90.073873, 48.101138],
                    [-90.091639, 48.10463],
                    [-90.1239, 48.107131],
                    [-90.12509, 48.107702],
                    [-90.136191, 48.112136],
                    [-90.176605, 48.112445],
                    [-90.312386, 48.1053],
                    [-90.330052, 48.102399],
                    [-90.337177, 48.099771],
                    [-90.343484, 48.095064],
                    [-90.344234, 48.094447],
                    [-90.403219, 48.105114],
                    [-90.452022, 48.105006],
                    [-90.495398, 48.099787],
                    [-90.495637, 48.099444],
                    [-90.569763, 48.106951],
                    [-90.751608, 48.090968],
                    [-90.761625, 48.098283],
                    [-90.774191, 48.118575],
                    [-90.774225, 48.118894],
                    [-90.775962, 48.122229],
                    [-90.776133, 48.122481],
                    [-90.793841, 48.135569],
                    [-90.795308, 48.135523],
                    [-90.789776, 48.143472],
                    [-90.804207, 48.177833],
                    [-90.839176, 48.239511],
                    [-90.843624, 48.243576],
                    [-90.847352, 48.244443],
                    [-90.88548, 48.245784],
                    [-90.976955, 48.219452],
                    [-91.032942, 48.190794],
                    [-91.03555, 48.189459],
                    [-91.035858, 48.189436],
                    [-91.138482, 48.151458],
                    [-91.138311, 48.151024],
                    [-91.140773, 48.147689],
                    [-91.176181, 48.125811],
                    [-91.250112, 48.084087],
                    [-91.26638, 48.078713],
                    [-91.290215, 48.073945],
                    [-91.328738, 48.070588],
                    [-91.340159, 48.073236],
                    [-91.488067, 48.068111],
                    [-91.569746, 48.093348],
                    [-91.641062, 48.097703],
                    [-91.71185, 48.114598],
                    [-91.712226, 48.116883],
                    [-91.708099, 48.122985],
                    [-91.699336, 48.144728],
                    [-91.710519, 48.193898],
                    [-91.714931, 48.19913],
                    [-91.742313, 48.204491],
                    [-91.789693, 48.197829],
                    [-91.798268, 48.200086],
                    [-91.867641, 48.218341],
                    [-91.906967, 48.23777],
                    [-91.907597, 48.238183],
                    [-91.954397, 48.251199],
                    [-91.954432, 48.251678],
                    [-91.977486, 48.24634],
                    [-91.977555, 48.24714],
                    [-91.989545, 48.260214],
                    [-92.030872, 48.325824],
                    [-92.055228, 48.359213],
                    [-92.26228, 48.354933],
                    [-92.288994, 48.342991],
                    [-92.304561, 48.322977],
                    [-92.306309, 48.316442],
                    [-92.301451, 48.288608],
                    [-92.295668, 48.278118],
                    [-92.295053, 48.276587],
                    [-92.325304, 48.23703],
                    [-92.384387, 48.242914],
                    [-92.415121, 48.293841],
                    [-92.456325, 48.414204],
                    [-92.507285, 48.447875],
                    [-92.537202, 48.447703],
                    [-92.575636, 48.440827],
                    [-92.656027, 48.436709],
                    [-92.687998, 48.443889],
                    [-92.712562, 48.463013],
                    [-92.698824, 48.494892],
                    [-92.684866, 48.497611],
                    [-92.661418, 48.496557],
                    [-92.636696, 48.499428],
                    [-92.627237, 48.503383],
                    [-92.631137, 48.508077],
                    [-92.631117, 48.508252],
                    [-92.625374, 48.512916],
                    [-92.625151, 48.513048],
                    [-92.625739, 48.518189],
                    [-92.634931, 48.542873],
                    [-92.657881, 48.546263],
                    [-92.728046, 48.53929],
                    [-92.980484, 48.624915],
                    [-93.088438, 48.627597],
                    [-93.14242, 48.624924],
                    [-93.17999, 48.624926],
                    [-93.207398, 48.642474],
                    [-93.254854, 48.642784],
                    [-93.347528, 48.62662],
                    [-93.367025, 48.608283],
                    [-93.40366, 48.607593],
                    [-93.464308, 48.591792],
                    [-93.466007, 48.587291],
                    [-93.461731, 48.57403],
                    [-93.457046, 48.567199],
                    [-93.456675, 48.561834],
                    [-93.460798, 48.550552],
                    [-93.467504, 48.545664],
                    [-93.544361, 48.529109],
                    [-93.662337, 48.515708],
                    [-93.756483, 48.515366],
                    [-93.771741, 48.515825],
                    [-93.794454, 48.516021],
                    [-93.815178, 48.526508],
                    [-93.818253, 48.530046],
                    [-93.818375, 48.534442],
                    [-93.811201, 48.542385],
                    [-93.805369, 48.568393],
                    [-93.806763, 48.577616],
                    [-93.822644, 48.609067],
                    [-93.834323, 48.624954],
                    [-93.840754, 48.628548],
                    [-94.052452, 48.64402],
                    [-94.126336, 48.644447],
                    [-94.224276, 48.649527],
                    [-94.244394, 48.653442],
                    [-94.250191, 48.656323],
                    [-94.250497, 48.656654],
                    [-94.251169, 48.683514],
                    [-94.260541, 48.696381],
                    [-94.290737, 48.707747],
                    [-94.308446, 48.710239],
                    [-94.388848, 48.711945],
                    [-94.416191, 48.710948],
                    [-94.431854, 48.706588],
                    [-94.468728, 48.696324],
                    [-94.533057, 48.701262],
                    [-94.628854, 48.738789],
                    [-94.64515, 48.748991],
                    [-94.646256, 48.749975],
                    [-94.690863, 48.778047],
                    [-94.690889, 48.778066],
                    [-94.690246, 48.863363],
                    [-94.690302, 48.863711],
                    [-94.683127, 48.883376],
                    [-94.683069, 48.883929],
                    [-94.718932, 48.999991],
                    [-94.750218, 48.999992],
                    [-94.750221, 49.099763],
                    [-94.773223, 49.120733],
                    [-94.797527, 49.197791],
                    [-94.824291, 49.308834],
                    [-94.909273, 49.350176],
                    [-94.957465, 49.370186],
                    [-94.988908, 49.368897],
                    [-95.014415, 49.356405],
                    [-95.058404, 49.35317],
                    [-95.126467, 49.369439],
                    [-95.153314, 49.384358],
                    [-95.15335, 49.383079],
                    [-95.153293, 49.369107],
                    [-95.153259, 49.367691],
                    [-95.15333, 49.365886],
                    [-95.153407, 49.354397],
                    [-95.153344, 49.343662],
                    [-95.153284, 49.343409],
                    [-95.15333, 49.309287],
                    [-95.153331, 49.308442],
                    [-95.153319, 49.30772],
                    [-95.153333, 49.305655],
                    [-95.153424, 49.249995],
                    [-95.153309, 49.18488],
                    [-95.153711, 48.998903],
                    [-95.319895, 48.998769],
                    [-95.322946, 48.998767],
                    [-95.340962, 48.99874],
                    [-95.355819, 48.998735],
                    [-95.368698, 48.998729],
                    [-96.405412, 48.99982],
                    [-97.229039, 49.000687],
                    [-97.411216, 49.00051],
                    [-97.77575, 49.000574],
                    [-97.950205, 49.000459],
                    [-98.999819, 48.999769],
                    [-99.5257, 48.999423],
                    [-99.861454, 48.999202],
                    [-99.861488, 48.999156],
                    [-99.913705, 48.999049],
                    [-99.91378, 48.999049],
                    [-100.182707, 48.99923],
                    [-100.431676, 48.999398],
                    [-100.431642, 48.999604],
                    [-100.434351, 48.99957],
                    [-100.433981, 48.99941],
                    [-100.907107, 48.999593],
                    [-100.913634, 48.999662],
                    [-100.917939, 48.999571],
                    [-100.920577, 48.99956],
                    [-101.216182, 48.999469],
                    [-101.220754, 48.999455],
                    [-101.225187, 48.999566],
                    [-101.225915, 48.999531],
                    [-101.496737, 48.999355],
                    [-102.021144, 48.999015],
                    [-102.131614, 48.998842],
                    [-102.151847, 48.998798],
                    [-102.211301, 48.998554],
                    [-102.216993, 48.998553],
                    [-102.938793, 48.998998],
                    [-103.852287, 48.999561],
                    [-103.856072, 48.999572],
                    [-103.858363, 48.999606],
                    [-103.862738, 48.999639],
                    [-103.865336, 48.999591],
                    [-103.876905, 48.999544],
                    [-103.917428, 48.999585],
                    [-103.918921, 48.999551],
                    [-103.921976, 48.999551],
                    [-103.923261, 48.999562],
                    [-103.968611, 48.999525],
                    [-103.969479, 48.999525],
                    [-103.976459, 48.999605],
                    [-103.980868, 48.999581],
                    [-103.982361, 48.999615],
                    [-103.983786, 48.999604],
                    [-103.988925, 48.99958],
                    [-103.992467, 48.999567],
                    [-104.048736, 48.999877],
                    [-104.048478, 48.987007],
                    [-104.048616, 48.966736],
                    [-104.048555, 48.963772],
                    [-104.0488, 48.958997],
                    [-104.048627, 48.957124],
                    [-104.048698, 48.951823],
                    [-104.048872, 48.94963],
                    [-104.04877, 48.943301],
                    [-104.048701, 48.940331],
                    [-104.048807, 48.933636],
                    [-104.048744, 48.912113],
                    [-104.048746, 48.906858],
                    [-104.048643, 48.902609],
                    [-104.048719, 48.879921],
                    [-104.048893, 48.875739],
                    [-104.048883, 48.874008],
                    [-104.048824, 48.867539],
                    [-104.048652, 48.865734],
                    [-104.0489, 48.847387],
                    [-104.048569, 48.797052],
                    [-104.048537, 48.788552],
                    [-104.048548, 48.751356],
                    [-104.04834, 48.747133],
                    [-104.047883, 48.664191],
                    [-104.047849, 48.663163],
                    [-104.047861, 48.658856],
                    [-104.047865, 48.65745],
                    [-104.047887, 48.649911],
                    [-104.047819, 48.648631],
                    [-104.047582, 48.633984],
                    [-104.047582, 48.633976],
                    [-104.04762, 48.627015],
                    [-104.047586, 48.625644],
                    [-104.04793, 48.62019],
                    [-104.048212, 48.599055],
                    [-104.047974, 48.591606],
                    [-104.047811, 48.56277],
                    [-104.047783, 48.539737],
                    [-104.047648, 48.531489],
                    [-104.047876, 48.530798],
                    [-104.047513, 48.525913],
                    [-104.047675, 48.517852],
                    [-104.047555, 48.49414],
                    [-104.047392, 48.467086],
                    [-104.047259, 48.452941],
                    [-104.047294, 48.452529],
                    [-104.047192, 48.447251],
                    [-104.04709, 48.445903],
                    [-104.04696, 48.421065],
                    [-104.047134, 48.411057],
                    [-104.046969, 48.390675],
                    [-104.046913, 48.389433],
                    [-104.046913, 48.389429],
                    [-104.046654, 48.374773],
                    [-104.046371, 48.374154],
                    [-104.046332, 48.34229],
                    [-104.046039, 48.256761],
                    [-104.045861, 48.255097],
                    [-104.045645, 48.246179],
                    [-104.045729, 48.244586],
                    [-104.045692, 48.241415],
                    [-104.04556, 48.193913],
                    [-104.045424, 48.192473],
                    [-104.045498, 48.176249],
                    [-104.045399, 48.16439],
                    [-104.04412, 47.996107],
                    [-104.044162, 47.992836],
                    [-104.043933, 47.971515],
                    [-104.043497, 47.95449],
                    [-104.043329, 47.949554],
                    [-104.04223, 47.891031],
                    [-104.041662, 47.862282],
                    [-104.041869, 47.841699],
                    [-104.042567, 47.808237],
                    [-104.042432, 47.805358],
                    [-104.042384, 47.803256],
                    [-104.043199, 47.747292],
                    [-104.043242, 47.747106],
                    [-104.044621, 47.45938],
                    [-104.044797, 47.438445],
                    [-104.045069, 47.397461],
                    [-104.045333, 47.343452],
                    [-104.045313, 47.331955],
                    [-104.045307, 47.330128],
                    [-104.045121, 47.276969],
                    [-104.045155, 47.27393],
                    [-104.045088, 47.271406],
                    [-104.045057, 47.266868],
                    [-104.045091, 47.265953],
                    [-104.045159, 47.263874],
                    [-104.045081, 47.092813],
                    [-104.045018, 47.081202],
                    [-104.045354, 47.078574],
                    [-104.045259, 47.063901],
                    [-104.045227, 47.057502],
                    [-104.045195, 47.053639],
                    [-104.045052, 47.040863],
                    [-104.045076, 47.037589],
                    [-104.045566, 46.941231],
                    [-104.045535, 46.934009],
                    [-104.045542, 46.933887],
                    [-104.045402, 46.725423],
                    [-104.045403, 46.722177],
                    [-104.04537, 46.721332],
                    [-104.045572, 46.713881],
                    [-104.045474, 46.708738],
                    [-104.045271, 46.641449],
                    [-104.045271, 46.641443],
                    [-104.045335, 46.540929],
                    [-104.045462, 46.341895],
                    [-104.045469, 46.324545],
                    [-104.045633, 46.280188],
                    [-104.04667, 46.000001],
                    [-104.045443, 45.94531],
                    [-104.04403, 45.881975],
                    [-104.04403, 45.881971],
                    [-104.044009, 45.871974],
                    [-104.043814, 45.868385],
                    [-104.041937, 45.557915],
                    [-104.041647, 45.550691],
                    [-104.041717, 45.539122],
                    [-104.041145, 45.503367],
                    [-104.041274, 45.499994],
                    [-104.041764, 45.490789],
                    [-104.04041, 45.393474],
                    [-104.040114, 45.374214],
                    [-104.040265, 45.345356],
                    [-104.040358, 45.335946],
                    [-104.040274, 45.212891],
                    [-104.040128, 44.999987],
                    [-104.057698, 44.997431],
                ],
            ],
            [
                [
                    [-90.791562, 46.784983],
                    [-90.791517, 46.784713],
                    [-90.791392, 46.783955],
                    [-90.791358, 46.783752],
                    [-90.791085, 46.782097],
                    [-90.790992, 46.781534],
                    [-90.790965, 46.781373],
                    [-90.789243, 46.779529],
                    [-90.788926, 46.779191],
                    [-90.783086, 46.772939],
                    [-90.785415, 46.76452],
                    [-90.787554, 46.756786],
                    [-90.788285, 46.754145],
                    [-90.78843, 46.753619],
                    [-90.788515, 46.753313],
                    [-90.788304, 46.75331],
                    [-90.78781, 46.753302],
                    [-90.787751, 46.753301],
                    [-90.774436, 46.753086],
                    [-90.771228, 46.753034],
                    [-90.766611, 46.754187],
                    [-90.765221, 46.754534],
                    [-90.763647, 46.754927],
                    [-90.7625, 46.755547],
                    [-90.758019, 46.757969],
                    [-90.739425, 46.768021],
                    [-90.73884, 46.768539],
                    [-90.734915, 46.772015],
                    [-90.723938, 46.781737],
                    [-90.716456, 46.785418],
                    [-90.706594, 46.783752],
                    [-90.696934, 46.782119],
                    [-90.696465, 46.78204],
                    [-90.692974, 46.782641],
                    [-90.691473, 46.782899],
                    [-90.689259, 46.78328],
                    [-90.688602, 46.783393],
                    [-90.68763, 46.78356],
                    [-90.679469, 46.784964],
                    [-90.66326, 46.787754],
                    [-90.661517, 46.788054],
                    [-90.661154, 46.788116],
                    [-90.660541, 46.788221],
                    [-90.65892, 46.7885],
                    [-90.657179, 46.7888],
                    [-90.656358, 46.789745],
                    [-90.655682, 46.790524],
                    [-90.655611, 46.790606],
                    [-90.655483, 46.790753],
                    [-90.655375, 46.790877],
                    [-90.655339, 46.790919],
                    [-90.654103, 46.792342],
                    [-90.653417, 46.793131],
                    [-90.652591, 46.794083],
                    [-90.652219, 46.794511],
                    [-90.652236, 46.794589],
                    [-90.652343, 46.795088],
                    [-90.652906, 46.797707],
                    [-90.652916, 46.797755],
                    [-90.65455, 46.798723],
                    [-90.654861, 46.798907],
                    [-90.65531, 46.799173],
                    [-90.665389, 46.799394],
                    [-90.670049, 46.799496],
                    [-90.670438, 46.799633],
                    [-90.685753, 46.805003],
                    [-90.683356, 46.813275],
                    [-90.676133, 46.818986],
                    [-90.673838, 46.819684],
                    [-90.658031, 46.824493],
                    [-90.62548, 46.834395],
                    [-90.61424, 46.837815],
                    [-90.613822, 46.837942],
                    [-90.613569, 46.837958],
                    [-90.610306, 46.838168],
                    [-90.609501, 46.838219],
                    [-90.60329, 46.838618],
                    [-90.601288, 46.838747],
                    [-90.589921, 46.839476],
                    [-90.58505, 46.839789],
                    [-90.584489, 46.839825],
                    [-90.582347, 46.840454],
                    [-90.57839, 46.841616],
                    [-90.578263, 46.841653],
                    [-90.570652, 46.846192],
                    [-90.569108, 46.847112],
                    [-90.568874, 46.847252],
                    [-90.568938, 46.847391],
                    [-90.569868, 46.849398],
                    [-90.570006, 46.849696],
                    [-90.587306, 46.858015],
                    [-90.587392, 46.858056],
                    [-90.58819, 46.858827],
                    [-90.593899, 46.864344],
                    [-90.601924, 46.872099],
                    [-90.602552, 46.872706],
                    [-90.602619, 46.872715],
                    [-90.616452, 46.874466],
                    [-90.622048, 46.872872],
                    [-90.634048, 46.864396],
                    [-90.636386, 46.861705],
                    [-90.636718, 46.861323],
                    [-90.637091, 46.860895],
                    [-90.637885, 46.859981],
                    [-90.643218, 46.853844],
                    [-90.646982, 46.851001],
                    [-90.647486, 46.85062],
                    [-90.650914, 46.848031],
                    [-90.651231, 46.847792],
                    [-90.651804, 46.847359],
                    [-90.654472, 46.845344],
                    [-90.654497, 46.845325],
                    [-90.655691, 46.844424],
                    [-90.655873, 46.844286],
                    [-90.656109, 46.844108],
                    [-90.656946, 46.843476],
                    [-90.672908, 46.835202],
                    [-90.675364, 46.833929],
                    [-90.682954, 46.831225],
                    [-90.694496, 46.827114],
                    [-90.704619, 46.823508],
                    [-90.712381, 46.820743],
                    [-90.720932, 46.815897],
                    [-90.727818, 46.810434],
                    [-90.727952, 46.809319],
                    [-90.728384, 46.805707],
                    [-90.728546, 46.804356],
                    [-90.729581, 46.803395],
                    [-90.732823, 46.800385],
                    [-90.733001, 46.800219],
                    [-90.733231, 46.800183],
                    [-90.736609, 46.799654],
                    [-90.740603, 46.799029],
                    [-90.74572, 46.798228],
                    [-90.758586, 46.796214],
                    [-90.759119, 46.796044],
                    [-90.781682, 46.788835],
                    [-90.790231, 46.786103],
                    [-90.791562, 46.784983],
                ],
            ],
            [
                [
                    [-90.654805, 46.919259],
                    [-90.654796, 46.919249],
                    [-90.644218, 46.908455],
                    [-90.644144, 46.908379],
                    [-90.64412, 46.908373],
                    [-90.637124, 46.906724],
                    [-90.625685, 46.909011],
                    [-90.624635, 46.909221],
                    [-90.623547, 46.909438],
                    [-90.592933, 46.915558],
                    [-90.579568, 46.91823],
                    [-90.569169, 46.920309],
                    [-90.557319, 46.918693],
                    [-90.55328, 46.916674],
                    [-90.550371, 46.915829],
                    [-90.549104, 46.915461],
                    [-90.545872, 46.916673],
                    [-90.545105, 46.917287],
                    [-90.543852, 46.918289],
                    [-90.543583, 46.923002],
                    [-90.539947, 46.92785],
                    [-90.535368, 46.931486],
                    [-90.532652, 46.932489],
                    [-90.524056, 46.935664],
                    [-90.524018, 46.935714],
                    [-90.511124, 46.952885],
                    [-90.508157, 46.956836],
                    [-90.509372, 46.958438],
                    [-90.50988, 46.959108],
                    [-90.511623, 46.961407],
                    [-90.512211, 46.961719],
                    [-90.512884, 46.962076],
                    [-90.513328, 46.962312],
                    [-90.514616, 46.962996],
                    [-90.514782, 46.963084],
                    [-90.521791, 46.966804],
                    [-90.523298, 46.967604],
                    [-90.524874, 46.96786],
                    [-90.528182, 46.968396],
                    [-90.528804, 46.968497],
                    [-90.530597, 46.968099],
                    [-90.572383, 46.958835],
                    [-90.601153, 46.952457],
                    [-90.601876, 46.952251],
                    [-90.633915, 46.943113],
                    [-90.634507, 46.942944],
                    [-90.643328, 46.933944],
                    [-90.643966, 46.933294],
                    [-90.64479, 46.932453],
                    [-90.64534, 46.931893],
                    [-90.646351, 46.93086],
                    [-90.651043, 46.926074],
                    [-90.651834, 46.925267],
                    [-90.654797, 46.919276],
                    [-90.654805, 46.919259],
                ],
            ],
            [
                [
                    [-90.465465, 47.002593],
                    [-90.464087, 46.994681],
                    [-90.464079, 46.994636],
                    [-90.457445, 46.996735],
                    [-90.453619, 46.997946],
                    [-90.448857, 46.999452],
                    [-90.429539, 47.005565],
                    [-90.429397, 47.00561],
                    [-90.428438, 47.006064],
                    [-90.428273, 47.006142],
                    [-90.42732, 47.006593],
                    [-90.425351, 47.007526],
                    [-90.413428, 47.01317],
                    [-90.413133, 47.013533],
                    [-90.412886, 47.013836],
                    [-90.411972, 47.014958],
                    [-90.409437, 47.018072],
                    [-90.405033, 47.023482],
                    [-90.403908, 47.024864],
                    [-90.403499, 47.025366],
                    [-90.403321, 47.02659],
                    [-90.403306, 47.026693],
                    [-90.400427, 47.046463],
                    [-90.400409, 47.046588],
                    [-90.400365, 47.046691],
                    [-90.400219, 47.047034],
                    [-90.400067, 47.047391],
                    [-90.396275, 47.056299],
                    [-90.396177, 47.056528],
                    [-90.395988, 47.056972],
                    [-90.394312, 47.060909],
                    [-90.394212, 47.061452],
                    [-90.393507, 47.065302],
                    [-90.393351, 47.066153],
                    [-90.393342, 47.066204],
                    [-90.393277, 47.066557],
                    [-90.393241, 47.066753],
                    [-90.393035, 47.067877],
                    [-90.393077, 47.068304],
                    [-90.393111, 47.068644],
                    [-90.393115, 47.068687],
                    [-90.393723, 47.074859],
                    [-90.39383, 47.075941],
                    [-90.393848, 47.075956],
                    [-90.39495, 47.076841],
                    [-90.39517, 47.077017],
                    [-90.395367, 47.077175],
                    [-90.398913, 47.077239],
                    [-90.400234, 47.077263],
                    [-90.417272, 47.07757],
                    [-90.425277, 47.075934],
                    [-90.427087, 47.075564],
                    [-90.42837, 47.075302],
                    [-90.434543, 47.07404],
                    [-90.434762, 47.073996],
                    [-90.435387, 47.073868],
                    [-90.437271, 47.073483],
                    [-90.438734, 47.072557],
                    [-90.441968, 47.070511],
                    [-90.448439, 47.066415],
                    [-90.449024, 47.066045],
                    [-90.449075, 47.066013],
                    [-90.449083, 47.065995],
                    [-90.449572, 47.064965],
                    [-90.455024, 47.05347],
                    [-90.455113, 47.053071],
                    [-90.455502, 47.051331],
                    [-90.457128, 47.044058],
                    [-90.457076, 47.043506],
                    [-90.45638, 47.036062],
                    [-90.456008, 47.032085],
                    [-90.455251, 47.024],
                    [-90.4553, 47.02375],
                    [-90.456677, 47.016737],
                    [-90.457272, 47.013707],
                    [-90.457315, 47.013488],
                    [-90.457453, 47.012783],
                    [-90.457688, 47.012484],
                    [-90.465321, 47.002777],
                    [-90.465465, 47.002593],
                ],
            ],
            [
                [
                    [-89.255202, 47.876102],
                    [-89.250936, 47.870377],
                    [-89.246774, 47.871016],
                    [-89.228507, 47.858039],
                    [-89.234535, 47.855373],
                    [-89.235552, 47.853774],
                    [-89.234533, 47.851718],
                    [-89.201812, 47.850243],
                    [-89.192207, 47.84106],
                    [-89.192681, 47.83343],
                    [-89.19017, 47.831603],
                    [-89.157738, 47.824015],
                    [-89.124134, 47.828616],
                    [-89.107991, 47.835705],
                    [-89.056412, 47.852598],
                    [-89.044463, 47.85575],
                    [-89.022736, 47.858532],
                    [-88.998939, 47.86749],
                    [-88.911665, 47.891344],
                    [-88.898986, 47.900685],
                    [-88.899698, 47.902445],
                    [-88.942387, 47.895436],
                    [-88.957985, 47.895436],
                    [-88.968903, 47.901675],
                    [-88.968903, 47.909474],
                    [-88.962664, 47.923512],
                    [-88.923573, 47.937976],
                    [-88.918029, 47.945605],
                    [-88.899184, 47.9533],
                    [-88.852923, 47.965322],
                    [-88.832063, 47.965213],
                    [-88.791959, 47.978938],
                    [-88.772357, 47.981126],
                    [-88.718555, 47.995134],
                    [-88.670073, 48.011446],
                    [-88.579784, 48.058669],
                    [-88.575048, 48.064154],
                    [-88.573924, 48.068861],
                    [-88.575869, 48.075166],
                    [-88.578395, 48.078003],
                    [-88.578053, 48.084373],
                    [-88.566938, 48.093719],
                    [-88.511902, 48.121699],
                    [-88.4857, 48.137683],
                    [-88.469573, 48.152879],
                    [-88.459697, 48.158551],
                    [-88.449502, 48.163312],
                    [-88.427373, 48.166764],
                    [-88.419875, 48.170731],
                    [-88.418244, 48.18037],
                    [-88.422601, 48.190975],
                    [-88.447236, 48.182916],
                    [-88.459735, 48.183807],
                    [-88.482039, 48.179915],
                    [-88.491961, 48.175466],
                    [-88.501088, 48.168181],
                    [-88.524753, 48.165291],
                    [-88.547033, 48.174891],
                    [-88.578413, 48.16237],
                    [-88.614026, 48.154797],
                    [-88.656915, 48.139225],
                    [-88.674192, 48.127165],
                    [-88.676395, 48.124876],
                    [-88.675628, 48.120444],
                    [-88.684434, 48.115785],
                    [-88.695353, 48.110549],
                    [-88.705586, 48.111013],
                    [-88.728198, 48.101914],
                    [-88.744458, 48.092769],
                    [-88.764256, 48.085189],
                    [-88.77183, 48.079457],
                    [-88.772077, 48.070502],
                    [-88.787556, 48.063035],
                    [-88.810461, 48.055247],
                    [-88.816084, 48.057006],
                    [-88.835714, 48.056752],
                    [-88.893701, 48.03477],
                    [-88.896327, 48.031801],
                    [-88.895069, 48.029059],
                    [-88.915032, 48.020681],
                    [-88.927529, 48.019615],
                    [-88.931487, 48.021637],
                    [-88.940886, 48.01959],
                    [-88.994163, 48.00229],
                    [-89.018303, 47.992525],
                    [-89.095207, 47.967922],
                    [-89.179154, 47.93503],
                    [-89.214499, 47.913895],
                    [-89.221332, 47.908069],
                    [-89.22071, 47.90085],
                    [-89.226327, 47.895438],
                    [-89.247127, 47.888503],
                    [-89.255202, 47.876102],
                ],
            ],
            [
                [
                    [-87.506184, 45.059565],
                    [-87.506086, 45.059461],
                    [-87.506055, 45.059428],
                    [-87.504967, 45.059287],
                    [-87.504281, 45.059198],
                    [-87.501572, 45.058602],
                    [-87.500105, 45.058167],
                    [-87.498863, 45.057847],
                    [-87.49867, 45.057779],
                    [-87.498379, 45.057733],
                    [-87.494475, 45.056523],
                    [-87.492668, 45.055792],
                    [-87.490925, 45.055039],
                    [-87.490312, 45.054948],
                    [-87.490189, 45.055017],
                    [-87.490151, 45.055039],
                    [-87.490216, 45.055222],
                    [-87.491345, 45.056022],
                    [-87.491507, 45.05609],
                    [-87.491636, 45.056227],
                    [-87.491765, 45.056273],
                    [-87.492023, 45.056502],
                    [-87.492314, 45.056936],
                    [-87.492411, 45.057233],
                    [-87.492516, 45.057419],
                    [-87.49254, 45.057462],
                    [-87.492563, 45.057653],
                    [-87.492573, 45.057736],
                    [-87.492691, 45.057799],
                    [-87.492702, 45.057805],
                    [-87.49285, 45.058325],
                    [-87.492864, 45.058376],
                    [-87.493381, 45.059107],
                    [-87.494543, 45.060044],
                    [-87.495479, 45.060661],
                    [-87.496415, 45.061072],
                    [-87.497254, 45.061278],
                    [-87.498964, 45.061231],
                    [-87.500105, 45.061117],
                    [-87.500603, 45.061094],
                    [-87.501802, 45.060841],
                    [-87.50244, 45.060706],
                    [-87.503602, 45.060272],
                    [-87.50428, 45.060067],
                    [-87.504488, 45.060024],
                    [-87.506054, 45.059702],
                    [-87.506109, 45.059644],
                    [-87.506184, 45.059565],
                ],
            ],
            [
                [
                    [-86.956198, 45.352006],
                    [-86.956192, 45.351179],
                    [-86.95614, 45.343785],
                    [-86.956129, 45.342267],
                    [-86.956054, 45.342202],
                    [-86.955691, 45.341888],
                    [-86.955577, 45.341788],
                    [-86.955456, 45.341684],
                    [-86.95499, 45.34128],
                    [-86.95155, 45.338298],
                    [-86.948087, 45.335296],
                    [-86.946943, 45.335058],
                    [-86.946796, 45.335027],
                    [-86.94107, 45.333836],
                    [-86.940715, 45.333762],
                    [-86.937368, 45.333065],
                    [-86.925681, 45.3242],
                    [-86.913995, 45.31211],
                    [-86.904898, 45.296839],
                    [-86.904362, 45.296662],
                    [-86.901602, 45.29575],
                    [-86.900636, 45.295431],
                    [-86.899891, 45.295185],
                    [-86.896928, 45.2983],
                    [-86.896922, 45.298521],
                    [-86.896667, 45.307275],
                    [-86.899488, 45.322588],
                    [-86.895055, 45.329035],
                    [-86.887802, 45.332259],
                    [-86.880572, 45.331467],
                    [-86.879361, 45.331335],
                    [-86.875117, 45.33087],
                    [-86.874761, 45.331008],
                    [-86.874685, 45.331037],
                    [-86.872296, 45.331962],
                    [-86.86951, 45.333041],
                    [-86.869041, 45.333223],
                    [-86.869031, 45.333244],
                    [-86.866054, 45.339765],
                    [-86.865634, 45.340686],
                    [-86.865499, 45.340981],
                    [-86.865528, 45.341136],
                    [-86.866126, 45.344356],
                    [-86.866244, 45.344992],
                    [-86.86771, 45.35289],
                    [-86.867743, 45.353065],
                    [-86.864314, 45.362764],
                    [-86.863719, 45.364446],
                    [-86.863694, 45.364518],
                    [-86.8636, 45.364783],
                    [-86.863563, 45.364888],
                    [-86.863367, 45.365],
                    [-86.858384, 45.367846],
                    [-86.857308, 45.36846],
                    [-86.853182, 45.370816],
                    [-86.853103, 45.370861],
                    [-86.841432, 45.389601],
                    [-86.837482, 45.393432],
                    [-86.82473, 45.405798],
                    [-86.824383, 45.406135],
                    [-86.822083, 45.406868],
                    [-86.81954, 45.407679],
                    [-86.818073, 45.408147],
                    [-86.817148, 45.40795],
                    [-86.815881, 45.40768],
                    [-86.814956, 45.407483],
                    [-86.808658, 45.406143],
                    [-86.808303, 45.406067],
                    [-86.805415, 45.407324],
                    [-86.805652, 45.410247],
                    [-86.805767, 45.411661],
                    [-86.805843, 45.412592],
                    [-86.805868, 45.412903],
                    [-86.805978, 45.413159],
                    [-86.80991, 45.422283],
                    [-86.810055, 45.422619],
                    [-86.81551, 45.425998],
                    [-86.816741, 45.42676],
                    [-86.817069, 45.426963],
                    [-86.82177, 45.427602],
                    [-86.828071, 45.428459],
                    [-86.828661, 45.428539],
                    [-86.828731, 45.428461],
                    [-86.830755, 45.426186],
                    [-86.8309, 45.426023],
                    [-86.830175, 45.422092],
                    [-86.830123, 45.421805],
                    [-86.829905, 45.420623],
                    [-86.829143, 45.41649],
                    [-86.829352, 45.415516],
                    [-86.829741, 45.413705],
                    [-86.830331, 45.410955],
                    [-86.830353, 45.410852],
                    [-86.853082, 45.405562],
                    [-86.853145, 45.405547],
                    [-86.855993, 45.407777],
                    [-86.859922, 45.410853],
                    [-86.861317, 45.411946],
                    [-86.861472, 45.412067],
                    [-86.862174, 45.412151],
                    [-86.865002, 45.412489],
                    [-86.877502, 45.413981],
                    [-86.880629, 45.412965],
                    [-86.883364, 45.412076],
                    [-86.892893, 45.40898],
                    [-86.898049, 45.408753],
                    [-86.902739, 45.408547],
                    [-86.909743, 45.408239],
                    [-86.914004, 45.408052],
                    [-86.917686, 45.40789],
                    [-86.927768, 45.411183],
                    [-86.928045, 45.411273],
                    [-86.928246, 45.411784],
                    [-86.929028, 45.41377],
                    [-86.930511, 45.417536],
                    [-86.934091, 45.420972],
                    [-86.934276, 45.421149],
                    [-86.934724, 45.421123],
                    [-86.936224, 45.421035],
                    [-86.937393, 45.420966],
                    [-86.941237, 45.417075],
                    [-86.942901, 45.415392],
                    [-86.943041, 45.41525],
                    [-86.945964, 45.407673],
                    [-86.946837, 45.40541],
                    [-86.947074, 45.404796],
                    [-86.947346, 45.40409],
                    [-86.951176, 45.394162],
                    [-86.951866, 45.392373],
                    [-86.953773, 45.38743],
                    [-86.954329, 45.385989],
                    [-86.955204, 45.383721],
                    [-86.95497, 45.383194],
                    [-86.948803, 45.369338],
                    [-86.948743, 45.369205],
                    [-86.948731, 45.369178],
                    [-86.947684, 45.366825],
                    [-86.947604, 45.366645],
                    [-86.94751, 45.366434],
                    [-86.947142, 45.364085],
                    [-86.946791, 45.361845],
                    [-86.946503, 45.360004],
                    [-86.946475, 45.359826],
                    [-86.946297, 45.35869],
                    [-86.948272, 45.355682],
                    [-86.95336, 45.35472],
                    [-86.953389, 45.354715],
                    [-86.954435, 45.353706],
                    [-86.956198, 45.352006],
                ],
            ],
            [
                [
                    [-86.156689, 45.010535],
                    [-86.154824, 45.002394],
                    [-86.133655, 44.996874],
                    [-86.115699, 44.999093],
                    [-86.093536, 45.007838],
                    [-86.101214, 45.018101],
                    [-86.101894, 45.022811],
                    [-86.100315, 45.02624],
                    [-86.097094, 45.030128],
                    [-86.093451, 45.03166],
                    [-86.079103, 45.030795],
                    [-86.093166, 45.041492],
                    [-86.117908, 45.048478],
                    [-86.138095, 45.043038],
                    [-86.141644, 45.040251],
                    [-86.154557, 45.018102],
                    [-86.156689, 45.010535],
                ],
            ],
            [
                [
                    [-86.065016, 45.140266],
                    [-86.060396, 45.104617],
                    [-86.058653, 45.100776],
                    [-86.052424, 45.095311],
                    [-86.037129, 45.086576],
                    [-86.019874, 45.071665],
                    [-86.013073, 45.063774],
                    [-85.99736, 45.055929],
                    [-85.976883, 45.06266],
                    [-85.977082, 45.072993],
                    [-85.982799, 45.080787],
                    [-85.984095, 45.087073],
                    [-85.980433, 45.113046],
                    [-85.976434, 45.120706],
                    [-85.976803, 45.138363],
                    [-85.989412, 45.151069],
                    [-85.993194, 45.152805],
                    [-86.005946, 45.155751],
                    [-86.033174, 45.15842],
                    [-86.04443, 45.159582],
                    [-86.050473, 45.158418],
                    [-86.059393, 45.152291],
                    [-86.065016, 45.140266],
                ],
            ],
            [
                [
                    [-85.630016, 45.598166],
                    [-85.622741, 45.586028],
                    [-85.618049, 45.582647],
                    [-85.561634, 45.572213],
                    [-85.541129, 45.575045],
                    [-85.534064, 45.578198],
                    [-85.530273, 45.589253],
                    [-85.526895, 45.59159],
                    [-85.518038, 45.592912],
                    [-85.509276, 45.596475],
                    [-85.491347, 45.609665],
                    [-85.487026, 45.621211],
                    [-85.490252, 45.652122],
                    [-85.500451, 45.664298],
                    [-85.503767, 45.670472],
                    [-85.506104, 45.681148],
                    [-85.5028, 45.690998],
                    [-85.494016, 45.698476],
                    [-85.494154, 45.705378],
                    [-85.498777, 45.726291],
                    [-85.510895, 45.734414],
                    [-85.520803, 45.737247],
                    [-85.521911, 45.739419],
                    [-85.520569, 45.744745],
                    [-85.515145, 45.749451],
                    [-85.50904, 45.748488],
                    [-85.508522, 45.744991],
                    [-85.510091, 45.742888],
                    [-85.508818, 45.742358],
                    [-85.503758, 45.742771],
                    [-85.497656, 45.746246],
                    [-85.501267, 45.754415],
                    [-85.506133, 45.754715],
                    [-85.525237, 45.750462],
                    [-85.53562, 45.750394],
                    [-85.54375, 45.751413],
                    [-85.54956, 45.757266],
                    [-85.566441, 45.760222],
                    [-85.567781, 45.757655],
                    [-85.567128, 45.750419],
                    [-85.564774, 45.745462],
                    [-85.565132, 45.730719],
                    [-85.572309, 45.711449],
                    [-85.583724, 45.700796],
                    [-85.590769, 45.698051],
                    [-85.600842, 45.68886],
                    [-85.604881, 45.681932],
                    [-85.609295, 45.658067],
                    [-85.604951, 45.647599],
                    [-85.604521, 45.639256],
                    [-85.608653, 45.632008],
                    [-85.61985, 45.624547],
                    [-85.630016, 45.598166],
                ],
            ],
            [
                [
                    [-84.651336, 45.862844],
                    [-84.650783, 45.85921],
                    [-84.6397, 45.852624],
                    [-84.629239, 45.850014],
                    [-84.623863, 45.846579],
                    [-84.612845, 45.834528],
                    [-84.611893, 45.833487],
                    [-84.61184, 45.833429],
                    [-84.597043, 45.822629],
                    [-84.594241, 45.818776],
                    [-84.593232, 45.817389],
                    [-84.590198, 45.813217],
                    [-84.587572, 45.8067],
                    [-84.58195, 45.802633],
                    [-84.561562, 45.796213],
                    [-84.549902, 45.789859],
                    [-84.52506, 45.764168],
                    [-84.509301, 45.754336],
                    [-84.507476, 45.744644],
                    [-84.500892, 45.737259],
                    [-84.484128, 45.73071],
                    [-84.469183, 45.732246],
                    [-84.45104, 45.727952],
                    [-84.430026, 45.726307],
                    [-84.418902, 45.721712],
                    [-84.405852, 45.722417],
                    [-84.394038, 45.727623],
                    [-84.3956, 45.732925],
                    [-84.394292, 45.735301],
                    [-84.372248, 45.745784],
                    [-84.370241, 45.75619],
                    [-84.356312, 45.769495],
                    [-84.35602, 45.771895],
                    [-84.403208, 45.784394],
                    [-84.41091, 45.797217],
                    [-84.419335, 45.806747],
                    [-84.42159, 45.805651],
                    [-84.419696, 45.799823],
                    [-84.421267, 45.792694],
                    [-84.426724, 45.788194],
                    [-84.432472, 45.786732],
                    [-84.492951, 45.805343],
                    [-84.514441, 45.81012],
                    [-84.538395, 45.807843],
                    [-84.578328, 45.820092],
                    [-84.589272, 45.825795],
                    [-84.602922, 45.85164],
                    [-84.6082, 45.861631],
                    [-84.622515, 45.87753],
                    [-84.629437, 45.882578],
                    [-84.641804, 45.885486],
                    [-84.646876, 45.884642],
                    [-84.651336, 45.862844],
                ],
            ],
            [
                [
                    [-82.843602, 41.647009],
                    [-82.842099, 41.628323],
                    [-82.834662, 41.629331],
                    [-82.827011, 41.633701],
                    [-82.797493, 41.658197],
                    [-82.794198, 41.662496],
                    [-82.793069, 41.664692],
                    [-82.796333, 41.665973],
                    [-82.805179, 41.664281],
                    [-82.808587, 41.661682],
                    [-82.824731, 41.659428],
                    [-82.834092, 41.657121],
                    [-82.843602, 41.647009],
                ],
            ],
            [
                [
                    [-82.835577, 41.710823],
                    [-82.835118, 41.708971],
                    [-82.816133, 41.706732],
                    [-82.808869, 41.708333],
                    [-82.810487, 41.720524],
                    [-82.813489, 41.723468],
                    [-82.820409, 41.724549],
                    [-82.82572, 41.72281],
                    [-82.832709, 41.71565],
                    [-82.835577, 41.710823],
                ],
            ],
            [
                [
                    [-82.826443, 41.684774],
                    [-82.813781, 41.670594],
                    [-82.805423, 41.67192],
                    [-82.805137, 41.674931],
                    [-82.801539, 41.682577],
                    [-82.790561, 41.68997],
                    [-82.782719, 41.694003],
                    [-82.786304, 41.69714],
                    [-82.803341, 41.693837],
                    [-82.812805, 41.692002],
                    [-82.826443, 41.684774],
                ],
            ],
            [
                [
                    [-82.735766, 41.600982],
                    [-82.725827, 41.595199],
                    [-82.702027, 41.585437],
                    [-82.688744, 41.585896],
                    [-82.686033, 41.587246],
                    [-82.680669, 41.594611],
                    [-82.677772, 41.617986],
                    [-82.68015, 41.61897],
                    [-82.691123, 41.611331],
                    [-82.700208, 41.61219],
                    [-82.703438, 41.617734],
                    [-82.70731, 41.619609],
                    [-82.718802, 41.619629],
                    [-82.735707, 41.603361],
                    [-82.735766, 41.600982],
                ],
            ],
        ],
    },
}
