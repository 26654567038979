import React from 'react'

import app from 'firebase/app'

import { Grid, Typography } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'

import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'

import TinyCard from 'components/Cards/TinyCard'
import NavigationBar from 'components/NavigationBar'
import { AuthContext } from 'context/AuthContext'
import { LocationContext } from 'context/LocationContext'
import Widget from 'components/Widget'

import Map from 'components/Map'
import MapItems from 'components/Map/Items'
import { StagnantMarker as MapMarker, MarkerAnnotation as MapMarkerAnnotation } from 'components/Map/Marker'

import { capitalizeFirstLetter } from 'code/Helper'

import useStyles from './styles'

import geoData from './data'

export default function Chapters(props) {
    const theme = useTheme()
    const classes = useStyles()

    const { user } = React.useContext(AuthContext)
    const { mapkit } = React.useContext(LocationContext)

    const [chapters, setChapters] = React.useState([])
    const [hasTried, setHasTried] = React.useState(false)

    const [chapterSearch, setChapterSearch] = React.useState('')
    const [geoJSON, setGeoJSON] = React.useState(null)

    const chapterCoords = {
        '5I5k2ZDw0zyJl7l5ZOWC': new mapkit.Coordinate(37.2672966, -89.6037552),
        '6kdo5TqarfI3XD5huSJO': new mapkit.Coordinate(47.2329256, -119.8952444),
        GwE6hR7uj2nNYAj6jRae: new mapkit.Coordinate(38.6487895, -90.3458151),
        WBHWAclFSSlVMHHPnvEB: new mapkit.Coordinate(33.4348895, -112.000018),
        aRJv1lfQMHVjlhtZ0dAY: new mapkit.Coordinate(28.0646888, -80.623022),
        bt1d00lDdZT0rzsD2p7f: new mapkit.Coordinate(33.7758625, -84.3943155),
        oaEIEMLXmGfGb5jfBw23: new mapkit.Coordinate(39.4338337, -77.8044869),
        vr0ivK65DTlQkfOJ4auF: new mapkit.Coordinate(34.0381785, -84.5826712),
    }

    const chapterRegions = {
        '5I5k2ZDw0zyJl7l5ZOWC': 'midwest',
        '6kdo5TqarfI3XD5huSJO': 'west',
        GwE6hR7uj2nNYAj6jRae: 'midwest',
        WBHWAclFSSlVMHHPnvEB: 'southwest',
        aRJv1lfQMHVjlhtZ0dAY: 'south',
        bt1d00lDdZT0rzsD2p7f: 'south',
        oaEIEMLXmGfGb5jfBw23: 'northeast',
        vr0ivK65DTlQkfOJ4auF: 'south',
    }

    /*const districts = [
        {
            id: 'south',
            title: 'Southern District',
            ref: React.useRef(),
        },

        {
            id: 'west',
            title: 'Western District',
            ref: React.useRef(),
        },

        {
            id: 'midwest',
            title: 'Midwestern District',
            ref: React.useRef(),
        },

        {
            id: 'northeast',
            title: 'Northeastern District',
            ref: React.useRef(),
        },

        {
            id: 'southwest',
            title: 'Southwestern District',
            ref: React.useRef(),
        },
    ]*/

    const annotationForCluster = clusterAnnotation => {
        clusterAnnotation.title = `${capitalizeFirstLetter(clusterAnnotation.clusteringIdentifier)}ern District`
        clusterAnnotation.color = theme.palette.primary.main
        clusterAnnotation.subtitle = `${clusterAnnotation.memberAnnotations.length} chapters`
        return clusterAnnotation
    }

    React.useEffect(() => {
        if (!!mapkit) {
            mapkit.importGeoJSON(geoData, {
                geoJSONDidComplete: function(overlays) {
                    setGeoJSON(overlays)
                },
                itemForMultiPolygon: function(collection, geoJSON) {
                    var overlays = collection.getFlattenedItemList()
                    var points = overlays.reduce(function(points, overlay) {
                        return points.concat(overlay.points)
                    }, [])
                    return new mapkit.PolygonOverlay(points)
                },
                itemForFeature: function(overlay, geoJSON) {
                    overlay.style = new mapkit.Style({
                        lineWidth: 2,
                    })
                    return overlay
                },
            })
        }
    }, [mapkit])

    if (!hasTried) {
        setHasTried(true)
        grabChapters()
    }

    function grabChapters() {
        const db = app.firestore()
        db.collection('chapters')
            .where('fraternity', '==', user.fraternity)
            .get()
            .then(function(querySnapshot) {
                let curChapters = []
                querySnapshot.forEach(function(doc) {
                    let data = doc.data()
                    data['id'] = doc.id
                    curChapters.push(data)
                })

                setChapters(curChapters)
            })
            .catch(function(error) {
                console.log('Error getting documents: ', error)
            })
    }

    function renderChapters() {
        if (!!chapters && chapters.length > 0) {
            let newChapters = chapters.filter(
                chapter =>
                    chapter.fullName
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .indexOf(chapterSearch) !== -1,
            )
            if (newChapters.length > 0) {
                return newChapters.map(chapter => (
                    <Grid item xl={3} lg={4} sm={6} xs={12} key={chapter.id}>
                        <TinyCard
                            onClick={() => props.history.push('/app/hq/chapters/' + chapter.id)}
                            text={chapter.fullName}
                            description={`${chapter.id === 'aRJv1lfQMHVjlhtZ0dAY' ? 'Colony' : 'Chapter'} at ${chapter.school}`}
                        />
                    </Grid>
                ))
            } else {
                return (
                    <Grid item xs={12}>
                        <TinyCard text="No chapters found" centered={true} />
                    </Grid>
                )
            }
        }

        return <></>
    }

    const data = [
        { id: 1, name: 'Chapter', value: chapters.length - 1, color: 'primary' },
        { id: 2, name: 'Colony', value: 1, color: 'gray' },
    ]

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <NavigationBar
                        titles={[
                            {
                                name: 'Chapters',
                            },
                        ]}
                        rightButtons={[
                            {
                                name: 'Add',
                                type: 'icon',
                                innerIcon: <AddIcon />,
                                onClick: () => {},
                            },
                        ]}
                        onSearch={text => setChapterSearch(text.toLowerCase().replace(/\s+/g, ''))}
                    />
                </Grid>
                {chapters.length > 0 && (
                    <Grid item xs={12} md={6}>
                        <Widget disableWidgetMenu inheritHeight noPadding>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: 6,
                                    }}
                                >
                                    <Typography
                                        style={{
                                            position: 'absolute',
                                        }}
                                        weight="bold"
                                    >
                                        {chapters.length}
                                    </Typography>
                                    <ResponsiveContainer width="100%" height={88 + 32 + 12}>
                                        <PieChart>
                                            <Pie data={data} innerRadius={30} outerRadius={40} dataKey="value">
                                                {data.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={theme.palette[entry.color].main} stroke={''} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ textAlign: 'center' }}>
                                        {data.map((entry, index) => (
                                            <span
                                                style={{
                                                    display: 'inline-block',
                                                    margin: '0 4px',
                                                }}
                                                key={`key-${index}`}
                                            >
                                                {' '}
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                        color: theme.palette[entry.color].main,
                                                        marginRight: 2,
                                                    }}
                                                >
                                                    •
                                                </span>
                                                {`${entry.name} (${entry.value})`}
                                            </span>
                                        ))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Widget>
                    </Grid>
                )}
                {chapters && chapters.length > 0 && (
                    <Grid item xs={12} md={6} className={classes.map}>
                        <Widget disableWidgetMenu inheritHeight noBodyPadding>
                            <Map
                                style={{
                                    height: 216,
                                    borderRadius: 4,
                                }}
                                center={new mapkit.Coordinate(39.5, -98.35)}
                                span={new mapkit.CoordinateSpan(28)}
                            >
                                {geoJSON && <MapItems items={geoJSON} />}
                                {chapters.map(chapter => (
                                    <MapMarker
                                        center={chapterCoords[chapter.id]}
                                        subtitle={chapter.school}
                                        title={chapter.fullName}
                                        glyphText={chapter.id === 'bt1d00lDdZT0rzsD2p7f' ? 'BA' : chapter.letters} /* 47.2329256,-119.8952444*/
                                        color={chapter.id === 'aRJv1lfQMHVjlhtZ0dAY' ? '#C0C0C0' : theme.palette.primary.main}
                                        key={chapter.id}
                                        clusteringIdentifier={chapterRegions[chapter.id]}
                                        titleVisibility={mapkit.FeatureVisibility.Visible}
                                    />
                                ))}
                                <MapMarkerAnnotation annotationForCluster={annotationForCluster} />
                            </Map>
                        </Widget>
                    </Grid>
                )}
                {renderChapters()}
            </Grid>
        </>
    )
}
