import app from 'firebase/app'
import React from 'react'

import { Box, Button, Grid, IconButton, TextField, Typography } from '@material-ui/core'

import { DeleteOutlined as DeleteIcon, ExpandLess as CloseIcon, ExpandMore as ExpandIcon } from '@material-ui/icons'

import NavigationBar from 'components/NavigationBar'

import moment from 'moment'

import _ from 'lodash'

import Widget from 'components/Widget'

export default function ViewQuote(props) {
    const quoteId = props.match.params.id

    const [quote, setQuote] = React.useState(null)
    const [hasTried, setHasTried] = React.useState(false)

    if (!hasTried) {
        setHasTried(true)
        grabQuote()
    }

    function grabQuote() {
        const db = app.firestore()
        db.collection('quotes')
            .doc(quoteId)
            .get()
            .then(function(doc) {
                setQuote(doc.data())
            })
            .catch(function(error) {
                console.log('Error getting document: ', error)
            })
    }

    const renderQuoteActivity = () => {
        let history = []

        let activity = [...quote.activity].filter(a => a.ts)

        activity = activity.map(a => ({ ...a, ts: a.ts.seconds ? a.ts.seconds : a.ts._seconds })).sort((a, b) => b.ts - a.ts)

        activity = _.uniq(activity.map(a => `${moment(new Date(0).setUTCSeconds(a.ts)).format('MMMM D @ h:mm a')} - ${a.label}`))

        for (let i = 0; i < activity.length; i++) {
            let quote = activity[i]

            history.push(<Typography>{quote}</Typography>)
        }

        return history ? history : <></>
    }

    const [expandedItems, setExpandedItems] = React.useState([])

    const renderItem = (key, val) => {
        if (typeof val === 'object' && !Array.isArray(val)) {
            if (expandedItems.includes(key)) {
                let vals = Object.keys(val)
                return (
                    <>
                        <Typography key={key}>
                            {key}
                            {
                                <IconButton size="small" onClick={() => setExpandedItems(items => items.filter(item => item !== key))}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        </Typography>
                        <Box style={{ marginLeft: 8 }}>{vals.map(v => renderItem(v, val[v]))}</Box>
                    </>
                )
            }
            return (
                <Typography key={key}>
                    {key} - {'{...}'}
                    {
                        <IconButton size="small" onClick={() => setExpandedItems(items => [...items, key])}>
                            <ExpandIcon />
                        </IconButton>
                    }
                </Typography>
            )
        }

        return (
            <Typography key={key}>
                {key} - {JSON.stringify(val)}
            </Typography>
        )
    }

    const getRenderedItems = React.useCallback(() => {
        return renderItem('data', quote)
    }, [quote, expandedItems])

    const [newActivity, setNewActivity] = React.useState('')

    const title = quote
        ? quote.orgName && quote.orgSchool
            ? `${quote.orgName} @ ${quote.orgSchool}`
            : quote.orgSchool
            ? quote.orgSchool
            : quote.orgName
        : 'Loading...'

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'Quotes',
                            link: '/app/gc/quotes',
                        },
                        {
                            name: 'View',
                        },
                    ]}
                    rightButtons={[
                        {
                            name: 'Delete',
                            type: 'icon',
                            innerIcon: <DeleteIcon />,
                            onClick: () => {
                                const db = app.firestore()
                                db.collection('quotes')
                                    .doc(quoteId)
                                    .delete()
                                    .then(function(doc) {
                                        if (props.history) {
                                            props.history.goBack()
                                        }
                                    })
                            },
                        },
                    ]}
                    grid
                />
                <Grid item xs={12}>
                    <Widget disableWidgetMenu inheritHeight>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 700,
                                        margin: '0 auto',
                                    }}
                                    align="center"
                                >
                                    {title}
                                </Typography>
                            </Grid>
                            {quote && (
                                <Grid item xs={12}>
                                    <Typography
                                        style={{
                                            margin: '0 auto',
                                        }}
                                        align="center"
                                    >
                                        {quote.contactName}
                                    </Typography>
                                    <Typography
                                        style={{
                                            margin: '0 auto',
                                        }}
                                        align="center"
                                    >
                                        {quote.contactTitle}
                                    </Typography>
                                    <Typography
                                        style={{
                                            margin: '0 auto',
                                        }}
                                        align="center"
                                    >
                                        {quote.contactPhone} - {quote.contactEmail}
                                    </Typography>
                                    <Typography
                                        style={{
                                            margin: '0 auto',
                                        }}
                                        align="center"
                                    >
                                        {quote.orgSize} members
                                    </Typography>
                                </Grid>
                            )}
                            {quote && Array.isArray(quote.activity) && (
                                <Grid item xs={12}>
                                    {renderQuoteActivity()}
                                </Grid>
                            )}
                            {quote && Array.isArray(quote.activity) && (
                                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        placeholder="New Activity"
                                        value={newActivity}
                                        onChange={e => setNewActivity(e.target.value)}
                                    />
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disableElevation
                                        disabled={!newActivity}
                                        onClick={() => {
                                            const db = app.firestore()

                                            const activityUpdate = {
                                                label: newActivity,
                                                ts: app.firestore.Timestamp.fromDate(new Date()),
                                            }

                                            db.collection('quotes')
                                                .doc(quoteId)
                                                .update({ activity: app.firestore.FieldValue.arrayUnion(activityUpdate) })
                                                .then(() => setNewActivity(''))
                                        }}
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            )}
                            {quote && (
                                <Grid item xs={12}>
                                    {getRenderedItems()}
                                </Grid>
                            )}
                        </Grid>
                    </Widget>
                </Grid>
            </Grid>
        </>
    )
}
