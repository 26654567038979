import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import React from 'react'

const filter = createFilterOptions()

export default function SelectAutocomplete(props) {
    const { value, onUpdate, title, events, disabled, variant, freeSolo, single, style, inputLabelProps, hideTitleOnUse, inputRef } = props

    return (
        <Autocomplete
            multiple={single ? false : true}
            fullWidth
            freeSolo={freeSolo}
            clearOnBlur={true}
            selectOnFocus={true}
            handleHomeEndKeys={true}
            options={events}
            isOptionEqualToValue={(option, value) => {
                let o = typeof option === 'string' ? option : option.id ? option.id : option.name
                let v = typeof value === 'string' ? value : value.id ? value.id : value.name

                return o === v
            }}
            ChipProps={{
                variant: 'outlined',
                size: 'small',
            }}
            disabled={disabled}
            filterOptions={(options, params) => {
                const filtered = filter(options, params)

                // Suggest the creation of a new value
                if (params.inputValue) {
                    let contained = false
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].name === params.inputValue) {
                            contained = true
                            i = value.length
                        }
                    }

                    if (freeSolo) {
                        if (contained) {
                            filtered.push({
                                name: params.inputValue,
                                title: `Remove "${params.inputValue}"`,
                            })
                        } else {
                            filtered.push({
                                name: params.inputValue,
                                title: `Add "${params.inputValue}"`,
                            })
                        }
                    }
                }

                return filtered
            }}
            getOptionLabel={option => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option
                }

                // For item that is created
                if (option.title) {
                    return option.title
                }

                return option.name
            }}
            value={value}
            onChange={(e, newValue) => {
                if (newValue && newValue.length > 0) {
                    let newVal = newValue
                    let last = newVal[newVal.length - 1]
                    if (typeof last === 'string') {
                        newVal[newVal.length - 1] = { id: last, name: last }
                        onUpdate(e, newVal)
                    } else if (last.title) {
                        newVal[newVal.length - 1] = { id: last.name, name: last.name }
                        onUpdate(e, newVal)
                    } else {
                        onUpdate(e, newValue)
                    }
                } else {
                    onUpdate(e, newValue)
                }
            }}
            renderInput={params => (
                <>
                    <TextField
                        {...params}
                        variant={variant ? variant : 'standard'}
                        label={hideTitleOnUse && (value.length > 0 || params.inputProps.value) ? '' : title}
                        fullWidth
                        margin="dense"
                        size="small"
                        style={style}
                        InputLabelProps={inputLabelProps ? { ...params.InputLabelProps, ...inputLabelProps } : params.InputLabelProps}
                        inputRef={inputRef}
                    />
                </>
            )}
            disableCloseOnSelect
        />
    )
}
