import React from 'react'

import app from 'firebase/app'

import { AddCircleOutline as AddIcon, RemoveCircleOutline as RemoveIcon } from '@material-ui/icons'

import { Grid, TextField, Typography } from '@material-ui/core'

// components
import FormSubmission from 'components/Forms/FormSubmission'
import NavigationBar from 'components/NavigationBar'
import SelectAutocomplete from 'components/SelectAutocomplete'
import Widget from 'components/Widget'

import ParentTable from 'components/Tables/ParentTable'
import Table from 'components/Tables/Table'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

export default function EditMealsPlan(props) {
    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)

    const [planID] = React.useState(props.match.params.id)
    const [isNew] = React.useState(props.match.params.id === 'new')

    const [name, setName] = React.useState('')
    const [nameError, setNameError] = React.useState(false)

    const [meals, setMeals] = React.useState([])
    const [mealsOptions, setMealsOptions] = React.useState([])
    const [members, setMembers] = React.useState([])

    const [error, setError] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const [isLoading, setIsLoading] = React.useState(true)
    const [hasTried, setHasTried] = React.useState(false)

    function getMealName(meal) {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        let meals = ['Breakfast', 'Lunch', 'Dinner']

        return days[meal.dow] + ' ' + meals[meal.mod]
    }

    function submitUpdate() {
        setIsSubmitting(true)

        let mems = []
        for (let i = 0; i < members.length; i++) {
            mems.push(members[i].id)
        }

        let ms = []
        for (let i = 0; i < meals.length; i++) {
            ms.push(meals[i].id)
        }

        let submission = {
            chapter: user.getChapter(),
            name: name,
            meals: ms,
            members: mems,
            planID: isNew ? null : planID,
        }

        let functionsCall = app.functions().httpsCallable('updateMealsPlan')
        functionsCall(submission)
            .then(function(result) {
                setIsSubmitting(false)
                if (props.history.length === 0) {
                    props.history.push('/app/applications/meals/settings/')
                } else {
                    props.history.goBack()
                }
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setError(message)
                setIsSubmitting(false)
            })
    }

    function grabPlan() {
        setHasTried(true)
        const db = app.firestore()
        db.collection('chapters')
            .doc(user.getChapter())
            .collection('menus')
            .doc('INFO')
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()
                    if (!isNew && !(planID in data.plans)) {
                        window.alert('Plan does not exist')
                    } else {
                        let planData = isNew ? null : data.plans[planID]
                        setIsLoading(false)

                        let ms = []
                        let mealKeys = Object.keys(data.meals)

                        for (let i = 0; i < mealKeys.length; i++) {
                            ms.push({ ...data.meals[mealKeys[i]], id: mealKeys[i], name: getMealName(data.meals[mealKeys[i]]) })
                        }

                        if (!isNew) {
                            setName(planData.name)

                            let newMeals = []
                            for (let i = 0; i < ms.length; i++) {
                                let m = ms[i]
                                if (planData.meals.includes(m.id)) {
                                    newMeals.push(m)
                                }
                            }

                            setMeals(newMeals)
                        }

                        ms.sort(function(a, b) {
                            if (a.dow !== b.dow) {
                                return a.dow - b.dow
                            }

                            if (a.mod !== b.mod) {
                                return a.mod - b.mod
                            }

                            return a.cutoffs[0] - b.cutoffs[0]
                        })

                        setMealsOptions(ms)

                        let mems = []
                        let availMems = []

                        let memKeys = Object.keys(chapter.members)
                        for (let i = 0; i < memKeys.length; i++) {
                            let id = memKeys[i]
                            let mem = chapter.members[id]

                            let memData = {
                                roll: mem.roll,
                                first: mem.first,
                                last: mem.last,
                                id: id,
                            }

                            if (!isNew && planData.members.includes(id)) {
                                mems.push(memData)
                            } else {
                                let found = false
                                let planKeys = Object.keys(data.plans)
                                for (let j = 0; j < planKeys.length; j++) {
                                    let plan = data.plans[planKeys[j]]

                                    if (plan.members.includes(id)) {
                                        found = true
                                    }
                                }

                                if (!found) {
                                    availMems.push(memData)
                                }
                            }
                        }

                        setAvailableMembers(availMems)
                        setMembers(mems)
                    }
                } else {
                    window.alert('Plan does not exist')
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
                window.alert('Plan does not exist')
            })
    }

    if (!hasTried && isLoading) {
        grabPlan()
    }

    const [availableMembers, setAvailableMembers] = React.useState([])

    function toggleMember(member, onPlan) {
        let curMems = onPlan ? [...members] : [...availableMembers]
        let otherMems = onPlan ? [...availableMembers] : [...members]

        for (let i = 0; i < curMems.length; i++) {
            if (curMems[i].id === member.id) {
                curMems.splice(i, 1)
                i = curMems.length
            }
        }

        otherMems.push(member)

        setMembers(onPlan ? curMems : otherMems)
        setAvailableMembers(onPlan ? otherMems : curMems)
    }

    const getResponseColumns = () => {
        let cols = []

        if (chapter && chapter.settings && chapter.settings.roll) {
            cols.push({
                title: 'Roll',
                value: 'roll',
                size: 'small',
                type: 'NUMBER',
                sortable: true,
            })
        }

        cols.push({
            title: 'First',
            value: 'first',
            sortable: true,
        })

        cols.push({
            title: 'Last',
            value: 'last',
            sortable: true,
        })

        return cols
    }

    const defaultSortableCol =
        chapter && chapter.settings && chapter.settings.roll
            ? {
                  value: 'roll',
                  dir: 'asc',
              }
            : {
                  value: 'first',
                  dir: 'asc',
              }

    const responseCols = getResponseColumns()

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <NavigationBar
                        titles={[
                            {
                                name: 'Meals',
                                link: '/app/applications/meals/',
                            },
                            {
                                name: 'Settings',
                                link: '/app/applications/meals/settings/',
                            },
                            {
                                name: isNew ? 'New Plan' : 'Edit Plan',
                            },
                        ]}
                    />
                </Grid>
                {!isLoading && (
                    <Grid item xs={12}>
                        <Widget disableWidgetMenu inheritHeight>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        autoFocus
                                        margin="dense"
                                        label="Name"
                                        type="text"
                                        value={name}
                                        onChange={e => {
                                            setName(e.target.value)

                                            if (e.target.value.length > 0) {
                                                setNameError(false)
                                            }
                                        }}
                                        onBlur={() => {
                                            setNameError(name.length === 0)
                                        }}
                                        inputProps={{
                                            maxLength: 32,
                                        }}
                                        fullWidth
                                        required
                                        error={nameError}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectAutocomplete
                                        variant="outlined"
                                        value={meals}
                                        onUpdate={(event, value) => {
                                            setMeals(value)
                                        }}
                                        freeSolo={false}
                                        title="Meals"
                                        events={mealsOptions}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ParentTable title="Members">
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <Table
                                                    title="On Plan"
                                                    columns={responseCols}
                                                    sortable={true}
                                                    defaultSortable={defaultSortableCol}
                                                    rowActions={[
                                                        {
                                                            title: 'Remove',
                                                            icon: <RemoveIcon />,
                                                            onClick: rowData => {
                                                                toggleMember(rowData, true)
                                                            },
                                                        },
                                                    ]}
                                                    showOnEmpty={true}
                                                    emptyText="None on plan"
                                                    data={members}
                                                    numRows={10}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Table
                                                    title="Available"
                                                    columns={responseCols}
                                                    sortable={true}
                                                    defaultSortable={defaultSortableCol}
                                                    rowActions={[
                                                        {
                                                            title: 'Add',
                                                            icon: <AddIcon />,
                                                            onClick: rowData => {
                                                                toggleMember(rowData, false)
                                                            },
                                                        },
                                                    ]}
                                                    showOnEmpty={true}
                                                    emptyText="No available members"
                                                    data={availableMembers}
                                                    numRows={10}
                                                />
                                            </Grid>
                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Typography variant="caption" align="center">
                                                    Members can be on a maximum of 1 meal plan
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ParentTable>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSubmission
                                        isLoading={isSubmitting}
                                        onCancel={() => props.history.push('/app/applications/meals/settings/')}
                                        onSubmit={() => submitUpdate()}
                                        submitText={isNew ? 'Create' : 'Update'}
                                        errorText={error}
                                        submitDisabled={name.length === 0}
                                    />
                                </Grid>
                            </Grid>
                        </Widget>
                    </Grid>
                )}
            </Grid>
        </>
    )
}
