import { convertObjectToList, convertListToObject } from 'code/Helper'

import { defaultVisibilityOptions } from 'data/Visibility'

import { generateUUID } from 'code/UUID'

import _ from 'lodash'

export class Messages {
    /*
    id - string
    name - string
    type - integer: 0 = chat, 1 = channel
    lu (last updated) - Date
    mType - integer: 0 = automatic, 1 = custom
    vis - array of integers: visible groups
    messages - map of messages
    views - array of viewed
    links - map of links

    muted - array of ids: muted members

    owner - string memberId
    mems - array of ids: visible members
    admins - array of ids: admin members
    */

    static get TYPE_CHAT() {
        return 0
    }

    static get TYPE_CHANNEL() {
        return 1
    }

    static get MEMBERSHIP_AUTOMATIC() {
        return 0
    }

    static get MEMBERSHIP_CUSTOM() {
        return 1
    }

    static get MEMBERSHIP_EVENT_PRESENT() {
        return 2
    }

    static get MEMBERSHIP_EVENT_RSVP() {
        return 3
    }

    static get SEND_PERMISSIONS_EVERYONE() {
        return 0
    }

    static get SEND_PERMISSIONS_OWNER() {
        return 1
    }

    static get SEND_PERMISSIONS_OWNER_ADMINS() {
        return 2
    }

    static get REACTION_PERMISSIONS_ENABLED() {
        return 0
    }

    static get REACTION_PERMISSIONS_DISABLED() {
        return 1
    }

    static getDefaultSettings() {
        return {
            sendPerms: Messages.SEND_PERMISSIONS_EVERYONE,
            reactionsPerms: Messages.REACTION_PERMISSIONS_ENABLED,
        }
    }

    constructor(m) {
        this.id = m.id
        this.name = m.name
        this.type = m.type
        this.lu = m.lu
        this.mType = m.mType
        this.vis = m.vis
        this.views = m.views

        this.links = m.links

        this.owner = m.owner
        this.mems = m.mems
        this.admins = m.admins

        this.avatar = m.avatar

        this.muted = m.muted

        if (m.attachments) {
            this.attachments = m.attachments
        }

        if (m.settings) {
            this.settings = m.settings
        } else {
            m.settings = Messages.getDefaultSettings()
        }

        if (m.type === Messages.TYPE_CHAT) {
            this.messages = convertObjectToList(m.messages).sort((a, b) => {
                return a.cd.seconds - b.cd.seconds
            })
            this._messages = m.messages
        }

        if (m.event) {
            this.event = m.event
        }
    }

    static getNewChat() {
        return new Messages({
            id: generateUUID(),
            name: '',
            type: Messages.TYPE_CHAT,
            mType: Messages.MEMBERSHIP_CUSTOM,
            vis: defaultVisibilityOptions,
            lu: new Date(),
            messages: [],
            views: [],
            owner: '',
            mems: [],
            admins: [],
            muted: [],
            avatar: { type: 0, emoji: '', background: _.sample(emojiColors) },
            links: [],
            settings: Messages.getDefaultSettings(),
        })
    }

    static getNewChatFromNewConversation(newConversation) {
        let trigger = parseInt(newConversation.trigger)

        if (typeof trigger !== 'number' || trigger < 2 || trigger > 3) {
            return null
        }

        if (typeof newConversation.eventId !== 'string') {
            return null
        }

        return new Messages({
            id: generateUUID(),
            name: '',
            type: Messages.TYPE_CHAT,
            mType: trigger,
            vis: defaultVisibilityOptions,
            lu: new Date(),
            messages: [],
            views: [],
            owner: '',
            mems: [],
            admins: [],
            muted: [],
            avatar: { type: 0, emoji: '', background: _.sample(emojiColors) },
            links: [],
            event: { id: newConversation.eventId },
            settings: Messages.getDefaultSettings(),
        })
    }

    static getNewChannel() {
        return new Messages({
            id: generateUUID(),
            name: '',
            type: Messages.TYPE_CHANNEL,
            mType: Messages.MEMBERSHIP_CUSTOM,
            vis: defaultVisibilityOptions,
            lu: new Date(),
            views: [],
            owner: '',
            mems: [],
            admins: [],
            muted: [],
            avatar: { type: 0, emoji: '', background: _.sample(emojiColors) },
            links: [],
            settings: Messages.getDefaultSettings(),
        })
    }

    getRaw() {
        let data = {
            id: this.id,
            name: this.name,
            type: this.type,
            mType: this.mType,
            lu: this.lu,
            views: this.views,
            owner: this.owner,
            admins: this.admins,
            muted: this.muted,
            avatar: this.avatar,
        }

        if (this.attachments) {
            data.attachments = this.attachments
        }

        if (this.links) {
            data.links = this.links
        } else {
            data.links = []
        }

        if (this.mType === Messages.MEMBERSHIP_AUTOMATIC) {
            data.vis = this.vis
        } else {
            data.mems = this.mems
        }

        if (this.settings) {
            data.settings = this.settings
        }

        if (this.mType === Messages.MEMBERSHIP_EVENT_RSVP || this.mType === Messages.MEMBERSHIP_EVENT_PRESENT) {
            data.event = this.event
        }

        if (this.type === Messages.TYPE_CHAT) {
            data.messages = convertListToObject(this.messages, true)
        }

        return data
    }

    getId() {
        return this.id
    }

    setId(id) {
        this.id = id
    }

    setName(name) {
        this.name = name
    }

    getName() {
        return this.name
    }

    getType() {
        return this.type
    }

    getMembershipType() {
        return this.mType
    }

    setMembershipType(mType) {
        this.mType = mType
    }

    getLastUpdated() {
        return this.lu
    }

    addMessage(message) {
        if (this.type === Messages.TYPE_CHAT) {
            let messages = [...this.messages]
            messages.push(message)
            this.messages = messages
        }
    }
}

/*export const emojiColors = [
    '#EF9A9A',
    '#F48FB1',
    '#CE93D8',
    '#B39DDB',
    '#9FA8DA',
    '#90CAF9',
    '#81D4FA',
    '#B2EBF2',
    '#80CBC4',
    '#A5D6A7',
    '#C5E1A5',
    '#E6EE9C',
    '#FFF59D',
    '#FFE082',
    '#FFCC80',
    '#FFAB91',
    '#BCAAA4',
    '#EEEEEE',
    '#B0BEC5',
]*/

export const emojiColors = [
    '#C62828',
    '#AD1457',
    '#6A1B9A',
    '#4527A0',
    '#283593',
    '#1565C0',
    '#0277BD',
    '#006064',
    '#00695C',
    '#2E7D32',
    '#33691E',
    '#BF360C',
    '#795548',
    '#424242',
]
