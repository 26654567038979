import React from 'react'

import { Close as CloseIcon } from '@material-ui/icons'

import { Box, IconButton } from '@material-ui/core'
import { Typography } from '../Wrappers'
import Widget from '../Widget'

import { AuthContext } from 'context/AuthContext'

import app from 'firebase/app'

// styles
import useStyles from './styles'
import cn from 'classnames'

export default function Tip(props) {
    var classes = useStyles()

    const { user, setUser, currentUser } = React.useContext(AuthContext)

    const { id, icon, description, actionIcon, hideAction, titleStyle, disableElevation, onActionClick, leftAction, center, actionDisabled } = props

    function dismissTip() {
        let tips = user.tips
        let submitUpdate = true
        if (tips === undefined) {
            tips = [id]
        } else if (!tips.includes(id)) {
            tips.push(id)
        } else {
            submitUpdate = false
        }

        if (submitUpdate) {
            updateUser(tips)
            let curU = { ...currentUser }
            curU.tips = tips
            setUser(curU)
        }
    }

    function updateUser(tipsData) {
        const db = app.firestore()
        let userDoc = db.collection('users').doc(user.id)

        userDoc.update({ tips: tipsData }).catch(function(error) {
            // Getting the Error details.
            var code = error.code
            var message = error.message
            var details = error.details
            console.log('AN ERROR OCCURRED', code, message, details)
        })
    }

    let color = props.color ? props.color : 'primary'

    let origTitle = props.title

    const [title] = React.useState(origTitle ? origTitle : ['Secret Tip', 'Insider Tip', 'Pro Tip', 'Helpful Tip', 'Expert Tip'][Math.floor(Math.random() * 5)])

    return (
        <Widget
            disableWidgetMenu
            noBodyPadding
            className={classes.tipWidget}
            paperClass={cn(classes.tipPaper, {
                [classes.red]: color === 'red',
                [classes.yellow]: color === 'yellow',
                [classes.primary]: color === 'primary',
                [classes.disableElevation]: disableElevation === true,
            })}
        >
            <Box className={classes.boxOuter} style={{ color: color === 'default' ? 'inherit' : 'white' }}>
                {leftAction ? (
                    <Box className={classes.boxCloseLeft}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                leftAction.onClick()
                            }}
                            disabled={leftAction.disabled}
                        >
                            {leftAction.icon}
                        </IconButton>
                    </Box>
                ) : (
                    icon && <Box className={classes.boxIcon}>{icon}</Box>
                )}
                <Box className={classes.boxText}>
                    <Typography
                        variant="overline"
                        style={{ lineHeight: 1, textTransform: 'uppercase', fontWeight: 500, textAlign: center ? 'center' : 'inherit', ...titleStyle }}
                    >
                        {title}
                    </Typography>
                    <Typography variant="subtitle2" style={{ lineHeight: 1, margin: '2px 0', textAlign: center ? 'center' : 'inherit' }}>
                        {description}
                    </Typography>
                </Box>
                {!hideAction && (
                    <Box className={classes.boxClose}>
                        <IconButton
                            size="small"
                            onClick={() => {
                                if (actionIcon) {
                                    onActionClick()
                                } else {
                                    if (id) {
                                        dismissTip()
                                    }
                                }
                            }}
                            disabled={actionDisabled}
                        >
                            {actionIcon ? actionIcon : <CloseIcon style={{ color: color === 'default' ? 'inherit' : 'white' }} />}
                        </IconButton>
                    </Box>
                )}
            </Box>
        </Widget>
    )
}
