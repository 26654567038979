import React from 'react'

import { getCoordinate } from 'code/Helper'

import { LocationContext } from 'context/LocationContext'

import { MapContext } from '../Map'

const Marker = React.forwardRef((props, marker) => {
    const { mapkit } = React.useContext(LocationContext)
    const { map } = React.useContext(MapContext)

    const isMountedRef = React.useRef(true)
    const isMounted = React.useCallback(() => isMountedRef.current, [])

    React.useEffect(() => {
        return () => (isMountedRef.current = false)
    }, [])

    const center = getCoordinate(mapkit, props.center)

    React.useEffect(() => {
        if (mapkit && map) {
            if (marker.current) {
                marker.current.coordinate = center
                marker.current.options = props
            } else {
                marker.current = new mapkit.MarkerAnnotation(center, props)
                map.addAnnotation(marker.current)
            }
        }
        return () => {
            !isMounted && marker.current && map && map.removeAnnotation(marker.current)
        }
    }, [mapkit, map, center, props])
    return null
})

const StagnantMarker = props => {
    const ref = React.useRef()
    return <Marker {...props} ref={ref} />
}

const MarkerAnnotation = ({ annotationForCluster }) => {
    const { map } = React.useContext(MapContext)

    React.useEffect(() => {
        if (!!map && !!annotationForCluster) {
            map.annotationForCluster = annotationForCluster
        }
    }, [map, annotationForCluster])

    return <></>
}

export default Marker

export { StagnantMarker, MarkerAnnotation }
