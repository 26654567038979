import React from 'react'

import { Box, ButtonBase, Typography } from '@material-ui/core'

import useStyles from '../styles'

import cn from 'classnames'

const RequestButton = ({ title, description, icon, disabled, ...props }) => {
    const classes = useStyles()

    return (
        <ButtonBase {...props} disabled={disabled} className={cn(classes.requestButton, { [classes.requestButtonDisabled]: disabled })}>
            <Box>{React.cloneElement(icon, { className: classes.requestIcon })}</Box>
            <Typography style={{ fontSize: '2em', fontWeight: 'bold' }}>{title}</Typography>
            <Typography>{description}</Typography>
        </ButtonBase>
    )
}

export default RequestButton
