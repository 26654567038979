import DateFnsUtils from '@date-io/date-fns'
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Tooltip,
} from '@material-ui/core'
import {
    ArrowDownward as ArrowDownIcon,
    ArrowUpward as ArrowUpIcon,
    Close as CloseIcon,
    Delete as DeleteIcon,
    FileCopy as DuplicateIcon,
} from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import QuestionPercentage from 'components/Question/QuestionPercentage'
import Ranked from 'components/Question/Ranked'
import React from 'react'
import Document from '../Document'
import DocumentUpload from '../DocumentUpload'
import Widget from '../Widget'
import { Typography } from '../Wrappers'

import { getOrdinalSuffix } from 'code/Helper'
import { generateUUID } from '../../code/UUID'

import _ from 'lodash'

import { Question as Qs } from '../../objects/AnnualReport/Question'

import { blue, bluegray, green, orange, purple, red } from 'themes/default.js'

// styles
import useStyles from './styles'

import './styles.css'

export default function Question(props) {
    var classes = useStyles()

    const rankedRef = React.useRef(null)

    const colors = [blue, red, green, orange, purple, bluegray]

    const { question, isDisabled, isFocused, onAction, onFocus, onBlur, value, onChange, viewMode, actions, hideTitle } = props

    function updateQ(name, value) {
        let q = question
        q[name] = value
        onAction('update', q)
    }

    function duplicateQ() {
        onAction('duplicate', question)
    }

    function moveUpQ() {
        onAction('moveUp', question)
    }

    function moveDownQ() {
        onAction('moveDown', question)
    }

    function deleteQ() {
        onAction('delete', question)
    }

    const getRankedVotes = viewingResults => {
        let results = []

        let i = 0

        while (i in viewingResults) {
            let result = viewingResults[i]

            i++

            results.push(
                <Grid item xs={12} key={result.name}>
                    <Box display="flex" alignItems="center">
                        <Box
                            className={classes.responseBlockProgress}
                            style={{
                                marginTop: 4,
                            }}
                        >
                            <Typography className={classes.responseBlockAccessory}>{`${i}${getOrdinalSuffix(i)}`}</Typography>
                            <Typography style={{ flexGrow: 1, marginLeft: 4, marginRight: 4 }}>{result.name}</Typography>
                        </Box>
                    </Box>
                </Grid>,
            )
        }

        return results
    }

    var responsesText = ''

    var voteCount = 0

    if (viewMode === 'results') {
        var viewingResults = {}

        let numItems = 0

        if (question.type === Qs.CHECKBOX) {
            for (let i = 0; i < value.length; i++) {
                let v = value[i]

                if (typeof v === 'string' && v.length > 0) {
                    v = [v]
                }

                if (Array.isArray(v) && v.length > 0) {
                    for (let j = 0; j < v.length; j++) {
                        let a = v[j]
                        if (a in viewingResults) {
                            viewingResults[a].value += 1
                        } else {
                            viewingResults[a] = { name: a, value: 1, color: colors[numItems % colors.length] }
                            numItems += 1
                        }
                    }

                    voteCount += 1
                }
            }

            let keys = Object.keys(viewingResults)

            for (let i = 0; i < keys.length; i++) {
                let val = viewingResults[keys[i]]
                let percent = val.value / voteCount
                let render = `${val.value} (${+(percent * 100).toFixed(1).replace(/\.0+$/, '')}%)`
                viewingResults[keys[i]].render = render
            }
        } else if (question.type === Qs.RANKED) {
            // Value is all the votes in order
            let validVotes = value.filter(val => Array.isArray(val) && val.length > 0)
            let votesNeeded = Math.ceil(validVotes.length / 2)
            let candidates = _.uniq(_.flatten(validVotes))

            voteCount += validVotes.length

            let rankings = []

            while (candidates.length > 0) {
                if (rankings.length > 0) {
                    validVotes = validVotes.filter(val => val.length > 0)
                    votesNeeded = Math.ceil(validVotes.length / 2)
                }

                let curVotes = {}
                let curRound = 0
                let foundWinner = false

                for (let i = 0; i < candidates.length; i++) {
                    curVotes[candidates[i]] = 0
                }

                while (!foundWinner) {
                    let foundVote = false
                    for (let i = 0; i < validVotes.length; i++) {
                        if (validVotes[i].length > curRound) {
                            let vote = validVotes[i][curRound]
                            foundVote = true
                            curVotes[vote] = curVotes[vote] + 1
                        }
                    }

                    let possibleCandidates = Object.keys(curVotes)

                    let highestVotes = []
                    let highestVoteCounts = 0

                    for (let i = 0; i < possibleCandidates.length; i++) {
                        let candidate = possibleCandidates[i]
                        let candidateVotes = curVotes[candidate]

                        if (candidateVotes > highestVoteCounts) {
                            highestVotes = [candidate]
                            highestVoteCounts = candidateVotes
                        } else if (candidateVotes > highestVoteCounts) {
                            highestVotes.append(candidate)
                        }
                    }

                    if (!foundVote || (highestVotes.length === 1 && highestVoteCounts >= votesNeeded)) {
                        foundWinner = true
                        rankings.push(highestVotes)
                        candidates = candidates.filter(candidate => !highestVotes.includes(candidate))

                        for (let i = 0; i < validVotes.length; i++) {
                            validVotes[i] = validVotes[i].filter(vote => !highestVotes.includes(vote))
                        }
                    }

                    curRound += 1
                }
            }

            for (let i = 0; i < rankings.length; i++) {
                let ranking = rankings[i]

                let str = ''

                for (let j = 0; j < ranking.length; j++) {
                    if (j > 0) {
                        str += ', '
                    }

                    str += ranking[j]
                }

                viewingResults[i] = { name: str, value: rankings.length - i, color: colors[i % colors.length] }
            }
        } else {
            for (let i = 0; i < value.length; i++) {
                let v = value[i]

                if (Array.isArray(v)) {
                    v = v.sort((a, b) => a.localeCompare(b))

                    let str = ''

                    for (let i = 0; i < v.length; i++) {
                        if (i > 0) {
                            str += ', '
                        }

                        str += v[i]
                    }
                    v = str
                }

                if (typeof v === 'string' && v.length > 0) {
                    if (v in viewingResults) {
                        viewingResults[v].value += 1
                    } else {
                        viewingResults[v] = { name: v, value: 1, color: colors[numItems % colors.length] }
                        numItems += 1
                    }

                    voteCount += 1
                }
            }
        }

        let finalizedResults = []

        for (const [, value] of Object.entries(viewingResults)) {
            finalizedResults.push({ ...value, id: value.name })
        }

        finalizedResults.sort((a, b) => b.value - a.value)

        viewingResults = finalizedResults

        /*let largestValue = 0
        let largestIndex = 0

        for (let i = 0; i < viewingResults.length; i++) {
            let val = viewingResults[i].value
            if (val > largestValue) {
                largestValue = val
                largestIndex = i
            }
        }*/

        responsesText = voteCount === 1 ? '1 Response' : `${voteCount} Responses`
    }

    if (viewMode === 'results') {
        const inner = () => (
            <Grid container>
                {!hideTitle && (
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6">{question.text ? question.text : 'Question'}</Typography>
                            {question.required && (
                                <Typography variant="h6" color="red">
                                    *
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                )}
                {question.description && (
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="caption">{question.description}</Typography>
                        </Box>
                    </Grid>
                )}
                {question.type !== Qs.TITLE && question.type !== Qs.PICTURE && (
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="caption">{responsesText}</Typography>
                        </Box>
                    </Grid>
                )}
                {(question.type === Qs.TEXT || question.type === Qs.PARAGRAPH || question.type === Qs.NUMBER) && (
                    <>
                        {viewingResults.map(result => (
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography className={classes.responseBlock}>
                                        {result.value !== 1 && <span style={{ fontWeight: 'bold' }}>{`${result.value} `}</span>}
                                        {result.name}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </>
                )}
                {question.type === Qs.DATE && (
                    <>
                        {viewingResults.map(result => (
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography className={classes.responseBlock}>
                                        {result.value !== 1 && <span style={{ fontWeight: 'bold' }}>{`${result.value} `}</span>}
                                        {result.name}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </>
                )}
                {(question.type === Qs.MC || question.type === Qs.CHECKBOX) && (
                    <>
                        {viewingResults.map(result => (
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Box
                                        style={{
                                            marginTop: 4,
                                            width: '100%',
                                        }}
                                    >
                                        <QuestionPercentage showValue value={result.value} text={result.name} count={voteCount} />
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </>
                )}
                {question.type === Qs.RANKED && <>{getRankedVotes(viewingResults)}</>}
            </Grid>
        )

        if (props.disableCard) {
            return <>{inner()}</>
        }

        if (question.type === Qs.TITLE || question.type === Qs.PICTURE) {
            return <></>
        }

        return (
            <Widget disableWidgetMenu inheritHeight bodyClass={classes.section}>
                {inner()}
            </Widget>
        )
    }

    if (isFocused) {
        return (
            <Widget disableWidgetMenu inheritHeight bodyClass={classes.section} paperClass={classes.focused}>
                <Grid container>
                    {question.type === Qs.TITLE || question.type === Qs.PICTURE ? (
                        <>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <TextField
                                        margin="dense"
                                        type="text"
                                        inputProps={{
                                            maxLength: 64,
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: classes.titleInput,
                                            },
                                        }}
                                        value={question.text}
                                        onChange={e => updateQ('text', e.target.value)}
                                        fullWidth
                                        placeholder="Title"
                                        style={{ flexGrow: 1 }}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <TextField
                                        margin="dense"
                                        type="text"
                                        inputProps={{
                                            maxLength: 256,
                                        }}
                                        InputProps={{
                                            multiline: true,
                                            classes: {
                                                input: classes.descriptionInput,
                                                root: classes.descriptionRoot,
                                            },
                                        }}
                                        value={question.description}
                                        onChange={e => updateQ('description', e.target.value)}
                                        fullWidth
                                        placeholder="Description"
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                    />
                                </Box>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <TextField
                                        margin="dense"
                                        type="text"
                                        inputProps={{
                                            maxLength: 128,
                                        }}
                                        value={question.text}
                                        onChange={e => updateQ('text', e.target.value)}
                                        fullWidth
                                        placeholder="Question"
                                        style={{ flexGrow: 1 }}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <TextField
                                        margin="dense"
                                        type="text"
                                        inputProps={{
                                            maxLength: 256,
                                        }}
                                        InputProps={{
                                            multiline: true,
                                            classes: {
                                                input: classes.descriptionInput,
                                                root: classes.descriptionRoot,
                                            },
                                        }}
                                        value={question.description}
                                        onChange={e => updateQ('description', e.target.value)}
                                        fullWidth
                                        placeholder="Description"
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                    />
                                </Box>
                            </Grid>
                        </>
                    )}
                    {question.type === Qs.NUMBER && (
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    margin="dense"
                                    type="number"
                                    fullWidth
                                    inputProps={{
                                        min: question.minSize,
                                        max: question.maxSize,
                                    }}
                                    disabled={isDisabled}
                                    placeholder={isDisabled ? 'Number' : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                />
                            </Box>
                        </Grid>
                    )}
                    {question.type === Qs.TEXT && (
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    margin="dense"
                                    type="text"
                                    inputProps={{
                                        maxLength: 128,
                                    }}
                                    fullWidth
                                    disabled={isDisabled}
                                    placeholder={isDisabled ? 'Short Answer' : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                />
                            </Box>
                        </Grid>
                    )}
                    {question.type === Qs.DATE && (
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    margin="dense"
                                    type="text"
                                    inputProps={{
                                        maxLength: 128,
                                    }}
                                    disabled={isDisabled}
                                    placeholder={isDisabled ? 'Date' : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                />
                            </Box>
                        </Grid>
                    )}
                    {question.type === Qs.PARAGRAPH && (
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    margin="dense"
                                    type="text"
                                    inputProps={{
                                        maxLength: 1024,
                                    }}
                                    InputProps={{
                                        multiline: true,
                                    }}
                                    fullWidth
                                    disabled={isDisabled}
                                    placeholder={isDisabled ? 'Paragraph' : ''}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                />
                            </Box>
                        </Grid>
                    )}
                    {question.type === Qs.CHECKBOX && (
                        <>
                            <Grid item xs={12}>
                                {question.choices.map((choice, index) => (
                                    <Box display="flex" style={{ marginLeft: -11 }}>
                                        <Checkbox disabled={isDisabled} />
                                        <TextField
                                            margin="dense"
                                            type="text"
                                            inputProps={{
                                                maxLength: 64,
                                            }}
                                            fullWidth
                                            value={choice.text}
                                            onChange={e => {
                                                let choiceText = e.target.value
                                                let q = question
                                                q.choices[index].text = choiceText
                                                onAction('update', q)
                                            }}
                                            style={{ flexGrow: 1 }}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                        />
                                        {question.choices.length > 1 && (
                                            <Tooltip title="Remove">
                                                <IconButton
                                                    onClick={() => {
                                                        let q = question
                                                        q.choices.splice(index, 1)
                                                        onAction('update', q)
                                                    }}
                                                    size="small"
                                                    style={{ width: 42 }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                ))}
                            </Grid>
                            {question.other && (
                                <Grid item xs={12}>
                                    <Box display="flex" style={{ marginLeft: -11 }}>
                                        <Checkbox disabled={isDisabled} />
                                        <TextField
                                            margin="dense"
                                            type="text"
                                            inputProps={{
                                                maxLength: 64,
                                            }}
                                            fullWidth
                                            style={{ flexGrow: 1 }}
                                            placeholder="Other"
                                            disabled={isDisabled}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                        />
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box display="flex" style={{ justifyContent: 'center' }}>
                                    <Button
                                        onClick={() => {
                                            let q = question
                                            q.choices.push({ id: generateUUID(), text: 'Option ' + (q.choices.length + 1) })
                                            onAction('update', q)
                                        }}
                                        disabled={question.choices.length >= 300}
                                    >
                                        Add Option
                                    </Button>
                                    <Divider orientation="vertical" flexItem style={{ margin: 6 }} />
                                    <FormControlLabel
                                        control={<Switch checked={question.other} onChange={e => updateQ('other', e.target.checked)} color="primary" />}
                                        label="Other"
                                        labelPlacement="start"
                                    />
                                </Box>
                            </Grid>
                        </>
                    )}
                    {question.type === Qs.MC && (
                        <>
                            <Grid item xs={12}>
                                {question.choices.map((choice, index) => (
                                    <Box display="flex" style={{ marginLeft: -11 }}>
                                        <Radio disabled={isDisabled} />
                                        <TextField
                                            margin="dense"
                                            type="text"
                                            inputProps={{
                                                maxLength: 64,
                                            }}
                                            value={choice.text}
                                            onChange={e => {
                                                let choiceText = e.target.value
                                                let q = question
                                                q.choices[index].text = choiceText
                                                onAction('update', q)
                                            }}
                                            fullWidth
                                            style={{ flexGrow: 1 }}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                        />
                                        {question.choices.length > 1 && (
                                            <Tooltip title="Remove">
                                                <IconButton
                                                    onClick={() => {
                                                        let q = question
                                                        q.choices.splice(index, 1)
                                                        onAction('update', q)
                                                    }}
                                                    size="small"
                                                    style={{ width: 42 }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                ))}
                            </Grid>

                            {question.other && (
                                <Grid item xs={12}>
                                    <Box display="flex" style={{ marginLeft: -11 }}>
                                        <Radio disabled={isDisabled} />
                                        <TextField
                                            margin="dense"
                                            type="text"
                                            inputProps={{
                                                maxLength: 64,
                                            }}
                                            fullWidth
                                            style={{ flexGrow: 1 }}
                                            placeholder="Other"
                                            disabled={isDisabled}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                        />
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Box display="flex" style={{ justifyContent: 'center' }}>
                                    <Button
                                        onClick={() => {
                                            let q = question
                                            q.choices.push({ id: generateUUID(), text: 'Option ' + (q.choices.length + 1) })
                                            onAction('update', q)
                                        }}
                                        disabled={question.choices.length >= 300}
                                    >
                                        Add Option
                                    </Button>
                                    <Divider orientation="vertical" flexItem style={{ margin: 6 }} />
                                    <FormControlLabel
                                        control={<Switch checked={question.other} onChange={e => updateQ('other', e.target.checked)} color="primary" />}
                                        label="Other"
                                        labelPlacement="start"
                                    />
                                </Box>
                            </Grid>
                        </>
                    )}
                    {question.type === Qs.RANKED && (
                        <>
                            <Grid item xs={12}>
                                {question.choices.map((choice, index) => (
                                    <Box display="flex">
                                        <TextField
                                            margin="dense"
                                            type="text"
                                            inputProps={{
                                                maxLength: 64,
                                            }}
                                            value={choice.text}
                                            onChange={e => {
                                                let choiceText = e.target.value
                                                let q = question
                                                q.choices[index].text = choiceText
                                                onAction('update', q)
                                            }}
                                            fullWidth
                                            style={{ flexGrow: 1 }}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                        />
                                        {question.choices.length > 1 && (
                                            <Tooltip title="Remove">
                                                <IconButton
                                                    onClick={() => {
                                                        let q = question
                                                        q.choices.splice(index, 1)
                                                        onAction('update', q)
                                                    }}
                                                    size="small"
                                                    style={{ width: 42 }}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                ))}
                            </Grid>

                            <Grid item xs={12}>
                                <Box display="flex" style={{ justifyContent: 'center' }}>
                                    <Button
                                        onClick={() => {
                                            let q = question
                                            q.choices.push({ id: generateUUID(), text: 'Option ' + (q.choices.length + 1) })
                                            onAction('update', q)
                                        }}
                                        disabled={question.choices.length >= 300}
                                    >
                                        Add Option
                                    </Button>
                                </Box>
                            </Grid>
                        </>
                    )}
                    {question.type === Qs.FILE && (
                        <Grid item xs={12}>
                            <Document
                                title="Drag File or Click to Upload"
                                icon="upload"
                                onClick={() => {
                                    console.log('clicked')
                                }}
                                size="medium"
                                disabled={isDisabled}
                            />
                        </Grid>
                    )}
                    {question.type === Qs.FILES && (
                        <Grid item xs={12}>
                            <Document
                                title="Drag File(s) or Click to Upload"
                                icon="upload"
                                onClick={() => {
                                    console.log('clicked')
                                }}
                                size="medium"
                                disabled={isDisabled}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" flexWrap="wrap">
                            <FormControl variant="outlined" margin="dense" size="small" style={{ marginTop: 6 }}>
                                <Select
                                    native
                                    value={question.type}
                                    onChange={e => updateQ('type', parseInt(e.target.value))}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                >
                                    {Qs.QUESTION_TYPES.map(qtype => (
                                        <option value={qtype.value}>{qtype.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box display="flex">
                                <Tooltip title="Move Down">
                                    <IconButton onClick={() => moveDownQ()} disabled={!actions || !actions.moveDown}>
                                        <ArrowDownIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Move Up">
                                    <IconButton onClick={() => moveUpQ()} disabled={!actions || !actions.moveUp}>
                                        <ArrowUpIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Duplicate">
                                    <IconButton onClick={() => duplicateQ()}>
                                        <DuplicateIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton onClick={() => deleteQ()}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            {question.type !== Qs.TITLE && question.type !== Qs.PICTURE && (
                                <>
                                    {question.type === Qs.CHECKBOX ? (
                                        <Box style={{ display: `flex`, flexDirection: `column` }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch checked={question.required} onChange={e => updateQ('required', e.target.checked)} color="primary" />
                                                }
                                                label="Required"
                                                labelPlacement="start"
                                            />
                                            <Typography variant="caption" style={{ fontSize: 12, lineHeight: `12px`, textAlign: `center` }}>
                                                To check at least one
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <FormControlLabel
                                            control={
                                                <Switch checked={question.required} onChange={e => updateQ('required', e.target.checked)} color="primary" />
                                            }
                                            label="Required"
                                            labelPlacement="start"
                                        />
                                    )}
                                </>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            style={{ margin: '6px auto 0', display: 'block' }}
                            onClick={() => {
                                onAction('done', question)
                            }}
                        >
                            Done
                        </Button>
                    </Grid>
                </Grid>
            </Widget>
        )
    }

    const inner = () => (
        <Grid container>
            {!hideTitle && (
                <>
                    {question.type === Qs.TITLE || question.type === Qs.PICTURE ? (
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h4" weight="bold">
                                    {question.text ? question.text : question.type === Qs.TITLE ? 'Header' : 'Picture'}
                                </Typography>
                            </Box>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6">{question.text ? question.text : 'Question'}</Typography>
                                {question.required && (
                                    <Typography variant="h6" color="red">
                                        *
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    )}
                </>
            )}
            {question.description && (
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="caption">{question.description}</Typography>
                    </Box>
                </Grid>
            )}
            {question.type === Qs.PICTURE && question.src && (
                <Grid item xs={12} className={classes.pictureHolder}>
                    <img className={classes.picture} src={question.src} alt="" />
                </Grid>
            )}
            {question.type === Qs.NUMBER && (
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            margin="dense"
                            type="number"
                            fullWidth
                            inputProps={{
                                min: question.minSize,
                                max: question.maxSize,
                            }}
                            disabled={isDisabled}
                            placeholder={isDisabled ? 'Number' : ''}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            value={value ? value : ''}
                            onChange={e => {
                                if (onChange) {
                                    onChange('value', e.target.value)
                                }
                            }}
                        />
                    </Box>
                </Grid>
            )}
            {question.type === Qs.TEXT && (
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            margin="dense"
                            type="text"
                            inputProps={{
                                maxLength: 128,
                            }}
                            fullWidth
                            disabled={isDisabled}
                            placeholder={isDisabled ? 'Short Answer' : ''}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            value={value ? value : ''}
                            onChange={e => {
                                if (onChange) {
                                    onChange('value', e.target.value)
                                }
                            }}
                        />
                    </Box>
                </Grid>
            )}
            {question.type === Qs.DATE && (
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                format="MM/dd/yyyy"
                                disabled={isDisabled}
                                placeholder={isDisabled ? 'Date' : 'mm/dd/yyyy'}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                value={value ? value : null}
                                onChange={val => {
                                    if (onChange) {
                                        if (val && !isNaN(val)) {
                                            onChange('value', val)
                                        } else {
                                            onChange('value', null)
                                        }
                                    }
                                }}
                                margin="dense"
                                type="text"
                                inputProps={{
                                    maxLength: 128,
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                </Grid>
            )}
            {question.type === Qs.PARAGRAPH && (
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <TextField
                            margin="dense"
                            type="text"
                            inputProps={{
                                maxLength: 1024,
                            }}
                            InputProps={{
                                multiline: true,
                            }}
                            fullWidth
                            disabled={isDisabled}
                            placeholder={isDisabled ? 'Paragraph' : ''}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            value={value ? value : ''}
                            onChange={e => {
                                if (onChange) {
                                    onChange('value', e.target.value)
                                }
                            }}
                        />
                    </Box>
                </Grid>
            )}
            {question.type === Qs.CHECKBOX && (
                <>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormGroup>
                                {question.choices.map(choice => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={value ? value.includes(choice.id) : false}
                                                onChange={e => {
                                                    if (onChange) {
                                                        let isChecked = e.target.checked

                                                        if (value) {
                                                            if (value.includes(choice.id) !== isChecked) {
                                                                if (isChecked) {
                                                                    value.push(choice.id)
                                                                    onChange('value', value)
                                                                } else {
                                                                    value.splice(value.indexOf(choice.id), 1)
                                                                    onChange('value', value)
                                                                }
                                                            }
                                                        } else {
                                                            onChange('value', isChecked ? [choice.id] : [])
                                                        }
                                                    }
                                                }}
                                            />
                                        }
                                        label={choice.text}
                                        disabled={isDisabled}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    {question.other && (
                        <Grid item xs={12}>
                            <Box display="flex" style={{ marginLeft: -11 }}>
                                <Checkbox
                                    disabled={isDisabled}
                                    color="primary"
                                    checked={value ? value.includes('OTHER') : false}
                                    onChange={e => {
                                        if (onChange) {
                                            let isChecked = e.target.checked

                                            if (value) {
                                                if (value.includes('OTHER') !== isChecked) {
                                                    if (isChecked) {
                                                        value.push('OTHER')
                                                        onChange('value', value)
                                                    } else {
                                                        value.splice(value.indexOf('OTHER'), 1)
                                                        onChange('value', value)
                                                    }
                                                }
                                            } else {
                                                onChange('value', isChecked ? ['OTHER'] : [])
                                            }
                                        }
                                    }}
                                />
                                <TextField
                                    margin="dense"
                                    type="text"
                                    inputProps={{
                                        maxLength: 64,
                                    }}
                                    fullWidth
                                    style={{ flexGrow: 1 }}
                                    placeholder="Other"
                                    disabled={isDisabled}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onChange={e => onChange('other', e.target.value)}
                                />
                            </Box>
                        </Grid>
                    )}
                </>
            )}
            {question.type === Qs.MC && (
                <>
                    <Grid item xs={12}>
                        <FormControl component="fieldset" fullWidth>
                            <RadioGroup value={value ? value : ''} onChange={e => onChange('value', e.target.value)}>
                                {question.choices.map(choice => (
                                    <FormControlLabel value={choice.id} control={<Radio color="primary" />} label={choice.text} disabled={isDisabled} />
                                ))}
                                {question.other && (
                                    <Box display="flex">
                                        <FormControlLabel value="OTHER" control={<Radio color="primary" />} disabled={isDisabled} style={{ marginRight: 0 }} />
                                        <TextField
                                            margin="dense"
                                            type="text"
                                            inputProps={{
                                                maxLength: 64,
                                            }}
                                            fullWidth
                                            style={{ flexGrow: 1 }}
                                            placeholder="Other"
                                            disabled={isDisabled}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            onChange={e => onChange('other', e.target.value)}
                                        />
                                    </Box>
                                )}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {value && !isDisabled && !question.required && (
                        <Grid item xs={12} style={{ display: `flex`, justifyContent: `flex-end` }}>
                            <Button color="primary" onClick={() => onChange('value', '')}>
                                Clear Selection
                            </Button>
                        </Grid>
                    )}
                </>
            )}
            {question.type === Qs.RANKED && (
                <>
                    <Grid item xs={12}>
                        <Box ref={rankedRef} style={{ marginTop: 4 }}>
                            <Ranked
                                value={value ? value : null}
                                options={question.choices}
                                container={rankedRef}
                                onChange={val => {
                                    onChange('value', val)
                                }}
                            />
                        </Box>
                    </Grid>
                </>
            )}
            {question.type === Qs.FILE && (
                <Grid item xs={12}>
                    <DocumentUpload size="medium" disabled={isDisabled} />
                </Grid>
            )}
            {question.type === Qs.FILES && (
                <Grid item xs={12}>
                    <Typography>Test</Typography>
                    <DocumentUpload multiple size="medium" disabled={isDisabled} />
                </Grid>
            )}
        </Grid>
    )

    if (props.disableCard) {
        return <>{inner()}</>
    }

    return (
        <Widget
            disableWidgetMenu
            inheritHeight
            bodyClass={classes.section}
            paperClass={question.editor && viewMode === 'edit' ? `${classes.focusedOther} ${question.editor.color}-editor` : ''}
        >
            <>
                {question.editor && viewMode === 'edit' && (
                    <div className={`${classes.focusedOtherInner} focusedOtherChild`}>{`${question.editor.name} is editing`}</div>
                )}
            </>
            {inner()}
        </Widget>
    )
}
