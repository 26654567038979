import tinycolor from 'tinycolor2'

const lightenRate = 7.5
const darkenRate = 15

const primary = tinycolor('3CD4A0')
    .darken(darkenRate)
    .toHexString()
const secondary = '#FF5C93'
const warning = '#E66868'
const success = '#3CD4A0'
const info = '#9013FE'
const gray = '#bdc3c7'
const white = '#FFFFFF'

export default {
    title: 'Success',
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: primary,
            light: tinycolor(primary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(primary)
                .darken(darkenRate)
                .toHexString(),
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(secondary)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#FFFFFF',
        },
        warning: {
            main: warning,
            light: tinycolor(warning)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(warning)
                .darken(darkenRate)
                .toHexString(),
        },
        white: {
            main: white,
            light: tinycolor(white)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(white)
                .darken(darkenRate)
                .toHexString(),
        },
        success: {
            main: success,
            light: tinycolor(success)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(success)
                .darken(darkenRate)
                .toHexString(),
        },
        info: {
            main: info,
            light: tinycolor(info)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(info)
                .darken(darkenRate)
                .toHexString(),
        },
        gray: {
            main: gray,
            light: tinycolor(gray)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(gray)
                .darken(darkenRate)
                .toHexString(),
        },
        inherit: {
            main: 'inherit',
            light: tinycolor('inherit')
                .lighten('inherit')
                .toHexString(),
            dark: tinycolor('inherit')
                .darken('inherit')
                .toHexString(),
        },
        text: {
            primary: '#6E6E6E',
            secondary: '#6E6E6E',
            hint: '#B9B9B9',
        },
        background: {
            default: '#F6F7FF',
            light: '#F5F5F5',
        },
    },
    customShadows: {
        widget: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    },
}
