import React from 'react'
import app from 'firebase/app'

import { Grid } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

import NavigationBar from 'components/NavigationBar'
import TinyCard from 'components/Cards/TinyCard'

export default function Chapters(props) {
    const [orgs, setOrgs] = React.useState({})
    const [chapters, setChapters] = React.useState(null)
    const [hasTried, setHasTried] = React.useState(false)

    const [chapterSearch, setChapterSearch] = React.useState('')

    if (!hasTried) {
        setHasTried(true)
        grabChapters()
    }

    function grabChapters() {
        const db = app.firestore()
        db.collection('chapters')
            .get()
            .then(function(querySnapshot) {
                let curChapters = []
                querySnapshot.forEach(function(doc) {
                    let data = doc.data()
                    data['id'] = doc.id
                    curChapters.push(data)
                })

                setChapters(curChapters)
            })
            .catch(function(error) {
                console.log('Error getting documents: ', error)
            })

        db.collection('fraternities')
            .get()
            .then(function(querySnapshot) {
                let curOrgs = {}
                querySnapshot.forEach(function(doc) {
                    let data = doc.data()
                    curOrgs[doc.id] = data
                })

                setOrgs(orgs => ({ ...orgs, ...curOrgs }))
            })
            .catch(function(error) {
                console.log('Error getting documents: ', error)
            })
    }

    const getDescription = chapter => {
        let chapterLabel =
            chapter && chapter.settings && chapter.settings.tier
                ? chapter.settings.tier[0].toUpperCase() + chapter.settings.tier.slice(1) + (chapter.settings.unpaid ? ' - UNPAID' : '')
                : 'Premium'

        let numMembers = Object.keys(chapter.members).length

        let membersString = numMembers === 1 ? '1 member' : `${numMembers} members`

        if (chapterLabel) {
            return `${chapterLabel} ${membersString}`
        }

        return membersString
    }

    function renderChapters() {
        if (chapters !== null) {
            let newChapters = chapters
                .filter(
                    org =>
                        org.fullName
                            .toLowerCase()
                            .replace(/\s+/g, '')
                            .indexOf(chapterSearch) !== -1,
                )
                .sort((a, b) => a.fullName.localeCompare(b.fullName))
            if (newChapters.length > 0) {
                return newChapters.map(chapter => (
                    <Grid item xl={3} lg={4} sm={6} xs={12} key={chapter.id}>
                        <TinyCard
                            onClick={() => props.history.push('/app/gc/chapters/' + chapter.id)}
                            text={`${chapter.fullName}${chapter.fraternity && orgs[chapter.fraternity] ? ` @ ${orgs[chapter.fraternity].fullName}` : ''}`}
                            description={getDescription(chapter)}
                        />
                    </Grid>
                ))
            } else {
                return (
                    <Grid item xs={12}>
                        <TinyCard text="No chapters found" centered={true} />
                    </Grid>
                )
            }
        }

        return <></>
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'Chapters',
                        },
                    ]}
                    rightButtons={[
                        {
                            name: 'Add',
                            type: 'icon',
                            innerIcon: <AddIcon />,
                            onClick: () => {
                                props.history.push('/app/gc/chapters/new')
                            },
                        },
                    ]}
                    grid
                    onSearch={text => setChapterSearch(text.toLowerCase().replace(/\s+/g, ''))}
                />
                {renderChapters()}
            </Grid>
        </>
    )
}
