import React from 'react'

import { Grid, Card, CardActions, CardContent, IconButton, Divider, Link, Tooltip } from '@material-ui/core'
import { Typography, Chip } from '../../Wrappers'

// styles
import useStyles from './styles'

export default function MiniCard(props) {
    var classes = useStyles()

    const { title, chips, leftButton, rightButton } = props

    let subtitle = Array.isArray(props.subtitle) ? props.subtitle : props.subtitle ? [props.subtitle] : []

    const includesBottom = leftButton || rightButton

    const [subtitleIndex, setSubtitleIndex] = React.useState(0)

    React.useEffect(() => {
        setTimeout(
            () =>
                setSubtitleIndex(subtitleIndex => {
                    return subtitleIndex + 1
                }),
            2000,
        )
    }, [subtitleIndex])

    return (
        <Card className={classes.cardMedia}>
            <CardContent style={{ paddingBottom: 16 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap={'nowrap'} style={{ overflowX: 'auto' }}>
                    <Grid item>
                        <Typography gutterBottom={subtitle !== undefined && subtitle ? true : false} variant="h5" component="h2">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {chips &&
                            chips.length > 0 &&
                            chips.map(chip =>
                                chip.icon && !chip.label && chip.tooltip ? (
                                    <Tooltip title={chip.tooltip}>
                                        <span>
                                            <Chip className={classes.chip} color={chip.color} icon={chip.icon} style={{ width: 32 }} key={chip.id} />
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <Chip className={classes.chip} color={chip.color} icon={chip.icon} label={chip.label} key={chip.id} />
                                ),
                            )}
                    </Grid>
                </Grid>
                {subtitle && (
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap={'nowrap'} style={{ overflowX: 'auto' }}>
                        <Grid item style={{ overflow: 'hidden' }}>
                            <Typography className={classes.subtitle}>{subtitle[subtitleIndex % subtitle.length]}</Typography>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
            {includesBottom && (
                <>
                    <Divider className={classes.divider} />
                    <CardActions classes={{ root: classes.cardActions }}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                {leftButton &&
                                    (leftButton.icon ? (
                                        <IconButton aria-label={leftButton.label} onClick={leftButton.onClick} style={{ padding: 2 }}>
                                            {leftButton.icon}
                                        </IconButton>
                                    ) : (
                                        <Link onClick={leftButton.onClick} className={classes.buttonLink}>
                                            <Typography
                                                style={{
                                                    padding: `0 8px`,
                                                }}
                                            >
                                                {leftButton.label}
                                            </Typography>
                                        </Link>
                                    ))}
                            </Grid>
                            <Grid item>
                                {rightButton &&
                                    Array.isArray(rightButton) &&
                                    rightButton.map(button => (
                                        <IconButton aria-label={button.label} onClick={button.onClick} style={{ padding: 2 }} id={button.id} key={button.key}>
                                            {button.icon}
                                        </IconButton>
                                    ))}
                                {rightButton && !Array.isArray(rightButton) && (
                                    <IconButton aria-label={rightButton.label} onClick={rightButton.onClick} style={{ padding: 2 }} id={rightButton.id}>
                                        {rightButton.icon}
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    </CardActions>
                </>
            )}
        </Card>
    )
}
