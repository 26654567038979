import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    card: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    visitsNumberContainer: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        paddingBottom: theme.spacing(1),
    },
    progressSection: {
        marginBottom: theme.spacing(1),
    },
    progressTitle: {
        marginBottom: theme.spacing(2),
    },
    progress: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    reorderLink: {
        color: `${theme.palette.primary.main} !important`,
        cursor: 'pointer',
    },
    pieChartLegendWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginRight: theme.spacing(1),
    },
    legendItemContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    fullHeightBody: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableWidget: {
        overflowX: 'auto',
    },
    progressBar: {
        backgroundColor: theme.palette.orange.main,
    },
    performanceLegendWrapper: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    legendElement: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
    },
    legendElementText: {
        marginLeft: theme.spacing(1),
    },
    serverOverviewElement: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
    },
    serverOverviewElementText: {
        minWidth: 145,
        paddingRight: theme.spacing(2),
    },
    serverOverviewElementChartWrapper: {
        width: '100%',
    },
    mainChartBody: {
        overflowX: 'auto',
    },
    mainChartHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
            flexWrap: 'wrap',
        },
    },
    mainChartHeaderLabels: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            order: 3,
            width: '100%',
            justifyContent: 'center',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
    },
    mainChartHeaderLabel: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(3),
    },
    mainChartSelectRoot: {
        borderColor: theme.palette.text.hint + '80 !important',
    },
    mainChartSelect: {
        padding: 10,
        paddingRight: 25,
    },
    mainChartLegentElement: {
        fontSize: '18px !important',
        marginLeft: theme.spacing(1),
    },
    navPadding: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
    },
    formControl: {
        margin: theme.spacing(3),
    },
    menuImage: {
        height: 196,
        objectFit: 'cover',
        margin: '0 auto',
        borderRadius: 4,
        border: `1px solid ${theme.palette.text.hint}`,
    },
    dayWrapper: {
        position: 'relative',
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: '0 2px',
        color: 'inherit',
    },
    customDayHighlight: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '2px',
        right: '2px',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '50%',
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: '#676767',
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    firstHighlight: {
        extend: 'highlight',
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    },
    endHighlight: {
        extend: 'highlight',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    },
    tabSection: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `0px !important`,
        paddingLeft: 0,
        paddingRight: 0,
        overflow: 'auto',
        borderRadius: 4,
    },
    redColor: {
        color: theme.palette.red.main,
    },
    specialtyCard: {
        height: 136,
        border: `3px solid ${theme.palette.text.hint}`,
        padding: 11.5,
    },
    specialtyCardIcon: {
        position: 'relative',
        height: 88,
    },
    specialtyCardIconOverlay: {
        stroke: theme.palette.background.paper,
        fontSize: '88px',
        position: 'absolute',
        top: 0,
        left: 'calc(50% - 44px)',
    },
    specialtyCardIconInner: {
        fontSize: '56px',
        position: 'absolute',
        top: '16px',
        left: 'calc(50% - 28px)',
    },
    uploadPhoto: {
        borderRadius: 4,
        border: `1px solid ${theme.palette.text.hint}`,
        width: `100%`,
        height: 256,
        '&:hover, &:focus': {
            borderColor: theme.palette.text.primary,
        },
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    uploadPhotoDesc: {
        display: 'flex',
        flexDirection: 'column',
    },
    uploadPhotoPic: {
        flexGrow: 1,
        display: 'flex',
    },
    uploadPhotoPicInner: {
        margin: 'auto',
        fontSize: '6rem',
    },
}))
