import cn from 'classnames'
import React from 'react'
import { Link, Typography } from '../Wrappers'

import { Box, Icon, IconButton } from '@material-ui/core'
import { CancelRounded as CancelIcon } from '@material-ui/icons'

// styles
import useStyles from './styles'

export default function Document(props) {
    var classes = useStyles()

    const { title, icon, size, onClick, onDelete, disabled } = props

    function getIconURL() {
        if (icon === 'link') {
            return 'fas fa-link'
        }

        if (icon === 'upload') {
            return 'fas fa-file-upload'
        }

        return 'far fa-file-' + icon
    }

    //onClick, icon,
    if (onClick) {
        if (size === 'large') {
            return (
                <div className={cn(classes.large, classes.button, disabled ? classes.disabled : null)}>
                    <Link underline="none" onClick={onClick}>
                        <Box display="flex" alignItems="center">
                            <Icon className={getIconURL()} fontSize={size} style={{ marginLeft: 12, marginRight: 2 }} />
                            <Typography variant="h6" className={classes.typography} weight="bold" noWrap>
                                {title}
                            </Typography>
                            {onDelete && (
                                <IconButton className={classes.closeLarge} onClick={onDelete}>
                                    <CancelIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Link>
                </div>
            )
        } else if (size === 'small') {
            return (
                <div className={cn(classes.small, classes.button, disabled ? classes.disabled : null)}>
                    <Link underline="none" onClick={onClick}>
                        <Box display="flex" alignItems="center">
                            <Icon className={getIconURL()} fontSize={size} style={{ marginLeft: 4, marginRight: 0 }} />
                            <Typography variant="body2" className={classes.typography} weight="bold" noWrap>
                                {title}
                            </Typography>
                            {onDelete && (
                                <IconButton className={classes.closeSmall} onClick={onDelete}>
                                    <CancelIcon fontSize={size} />
                                </IconButton>
                            )}
                        </Box>
                    </Link>
                </div>
            )
        } else {
            return (
                <div className={cn(classes.medium, classes.button, disabled ? classes.disabled : null)}>
                    <Link underline="none" onClick={onClick}>
                        <Box display="flex" alignItems="center">
                            <Icon className={getIconURL()} fontSize={size} style={{ marginLeft: 8, marginRight: 1 }} />
                            <Typography variant="body1" className={classes.typography} weight="bold" noWrap>
                                {title}
                            </Typography>
                            {onDelete && (
                                <IconButton className={classes.closeMedium} onClick={onDelete}>
                                    <CancelIcon />
                                </IconButton>
                            )}
                        </Box>
                    </Link>
                </div>
            )
        }
    } else {
        if (size === 'large') {
            return (
                <div className={cn(classes.large, disabled ? classes.disabled : null)}>
                    <Box display="flex" alignItems="center">
                        <Icon className={getIconURL()} fontSize={size} style={{ marginLeft: 12, marginRight: 2 }} />
                        <Typography variant="h6" className={classes.typography} weight="bold" noWrap>
                            {title}
                        </Typography>
                        {onDelete && (
                            <IconButton className={classes.closeLarge} onClick={onDelete}>
                                <CancelIcon />
                            </IconButton>
                        )}
                    </Box>
                </div>
            )
        } else if (size === 'small') {
            return (
                <div className={cn(classes.small, disabled ? classes.disabled : null)}>
                    <Box display="flex" alignItems="center">
                        <Icon className={getIconURL()} fontSize={size} style={{ marginLeft: 4, marginRight: 0 }} />
                        <Typography variant="body2" className={classes.typography} weight="bold" noWrap>
                            {title}
                        </Typography>
                        {onDelete && (
                            <IconButton className={classes.closeSmall} onClick={onDelete}>
                                <CancelIcon fontSize={size} />
                            </IconButton>
                        )}
                    </Box>
                </div>
            )
        } else {
            return (
                <div className={cn(classes.medium, disabled ? classes.disabled : null)}>
                    <Box display="flex" alignItems="center">
                        <Icon className={getIconURL()} fontSize={size} style={{ marginLeft: 8, marginRight: 1 }} />
                        <Typography variant="body1" className={classes.typography} weight="bold" noWrap>
                            {title}
                        </Typography>
                        {onDelete && (
                            <IconButton className={classes.closeMedium} onClick={onDelete}>
                                <CancelIcon />
                            </IconButton>
                        )}
                    </Box>
                </div>
            )
        }
    }
}
