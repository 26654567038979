import React from 'react'

import { Box, Checkbox, Divider, FormControlLabel, IconButton, InputBase, NativeSelect } from '@material-ui/core'
import { CalendarToday as CalendarIcon, Close as CloseIcon, ImageOutlined as ImageIcon, PollOutlined as PollsIcon } from '@material-ui/icons'
import { GCOutlineIcon as GCIcon, GifOutlineIcon as GifIcon } from 'components/Icons'

import Calendar from 'components/Calendar'
import Event from 'components/Calendar/Event'
import FileUpload from 'components/FileUpload'
import GiphySelector from 'components/Messages/GiphySelector'
import { TabsIcon } from 'components/Tabs'
import ErrorTypography from 'components/Typography/ErrorTypography'

import FileTypes from 'objects/FileTypes'

import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar'

import {
    Button,
    Dialog as DialogMobile,
    DialogActions as DialogActionsMobile,
    DialogContent as DialogContentMobile,
    DialogTitle as DialogTitleMobile,
    Link,
    Typography,
} from 'components/Wrappers'

import giphyLogo from 'images/giphy.png'
import giphyLogoBlack from 'images/giphy_black.png'

import { Capacitor } from '@capacitor/core'

import cn from 'classnames'
import moment from 'moment'

import { convertObjectToList } from 'code/Helper'
import { getEventMoment, getEvents } from 'code/TimeAgo'

import { generateUUID } from 'code/UUID'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { MessagesContext } from 'context/MessagesContext'

import { Question as Qs } from 'objects/AnnualReport/Question'

import { useTheme } from '@material-ui/styles'

import useStyles from 'pages/messages/styles'

const AttachmentsDialog = ({ conversationId, onAddAttachment, onClose }) => {
    const classes = useStyles()
    const theme = useTheme()

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)
    const { getGCWidget } = React.useContext(MessagesContext)

    const generateOption = () => {
        return { id: generateUUID(), text: '' }
    }

    const [state, setState] = React.useState({
        tab: 'gc',
        subtab: 'events',
        question: {
            title: '',
            type: Qs.MC,
            options: [generateOption()],
        },
    })
    const [curDate, setCurDate] = React.useState(moment())

    const curEvents = React.useMemo(() => getEvents(curDate, convertObjectToList(chapter.events)), [curDate, chapter])

    React.useEffect(() => {
        if (Capacitor.isPluginAvailable('PushNotifications') && !(theme.palette.type === 'dark')) {
            StatusBar.setStyle({
                style: StatusBarStyle.Light,
            })
        }

        return () => {
            if (Capacitor.isPluginAvailable('PushNotifications')) {
                StatusBar.setStyle({
                    style: StatusBarStyle.Dark,
                })
            }
        }
    }, [theme.palette.type])

    const generatePoll = question => {
        return {
            id: generateUUID(),
            title: question.title,
            question: {
                type: question.type,
                choices: question.options,
            },
            anonymous: question.anonymous === true,
        }
    }

    const sendPoll = async () => {
        let pollData = generatePoll(state.question)

        const text = { text: `Poll: ${state.question.title}` }
        const attachment = { type: 'polls', data: pollData, children: [text] }

        onAddAttachment(attachment)
    }

    return (
        <DialogMobile maxWidth="md" aria-labelledby="attachment-dialog" open fullWidth onClose={onClose}>
            <DialogTitleMobile id="attachment-dialog-title" onClose={onClose}>
                Add Attachment
            </DialogTitleMobile>
            <DialogContentMobile
                dividers
                style={{
                    height: '100%',
                    overflow: 'hidden',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: 'none',
                    position: 'relative',
                }}
            >
                <Box style={{ marginTop: -8, height: 32 }}>
                    <TabsIcon
                        tabs={[
                            { id: 'image', icon: <ImageIcon aria-label="image" /> },
                            { id: 'gc', icon: <GCIcon aria-label="greek connect" /> },
                            { id: 'gif', icon: <GifIcon aria-label="gif" /> },
                        ]}
                        selected={state.tab}
                        onChange={val => {
                            if (val === 'gc') {
                                setState(state => ({
                                    ...state,
                                    tab: 'gc',
                                    subtab: 'events',
                                    question: {
                                        title: '',
                                        type: Qs.MC,
                                        options: [generateOption()],
                                    },
                                }))
                                setCurDate(moment())
                            } else {
                                setState(state => ({
                                    ...state,
                                    tab: val,
                                    subtab: '',
                                }))
                            }
                        }}
                        disabled={state.loading}
                    />
                </Box>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                {state.tab === 'image' && (
                    <Box style={{ paddingTop: 8 }}>
                        <Typography gutterBottom align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                            Image
                        </Typography>
                        <FileUpload
                            allowedTypes={[FileTypes.IMAGE_JPG, FileTypes.IMAGE_PNG, FileTypes.IMAGE_GIF]}
                            folderPath={`chapters/${user.getChapter()}/messages/${conversationId}/attachments`}
                            onUploadImage={url => {
                                const text = { text: 'Attachment: Image' }
                                const attachment = {
                                    type: 'image',
                                    src: url,
                                    children: [text],
                                }

                                onAddAttachment(attachment)
                            }}
                        />
                    </Box>
                )}
                {state.tab === 'gc' && (
                    <>
                        <Box style={{ marginTop: 8, height: 32 }}>
                            <TabsIcon
                                tabs={[
                                    { id: 'events', icon: <CalendarIcon aria-label="event" /> },
                                    { id: 'polls', icon: <PollsIcon aria-label="poll" /> },
                                    /*{
                                        id: 'newsletter',
                                        icon: <Icon aria-label="newsletter" className="far fa-newspaper" style={{ fontSize: 24, minWidth: 27 }} />,
                                        disabled: true,
                                    },*/
                                ]}
                                selected={state.subtab}
                                onChange={val => {
                                    setState(state => ({
                                        ...state,
                                        subtab: val,
                                    }))
                                }}
                                disabled={state.loading}
                            />
                        </Box>
                        <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                    </>
                )}
                {state.subtab === 'events' && (
                    <Box style={{ overflowY: 'scroll', marginLeft: -16, marginRight: -16, width: 'calc(100% + 32px)' }}>
                        <Box style={{ paddingTop: 8 }}>
                            <Typography gutterBottom align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                                Event
                            </Typography>
                            <Box className={classes.calendarBox}>
                                <Calendar
                                    events={convertObjectToList(chapter.events)}
                                    date={curDate}
                                    setDate={date => {
                                        if (typeof date === 'string') {
                                            setCurDate(moment(date))
                                        } else {
                                            setCurDate(date)
                                        }
                                    }}
                                    maxPastMonths={0}
                                    maxFutureMonths={11}
                                />
                            </Box>
                            {curEvents.length > 0 && (
                                <Box className={cn(classes.calendarEventsBox, classes.calendarBox)}>
                                    {curEvents.map(e => (
                                        <Link
                                            underline="none"
                                            onClick={() => {
                                                const text = { text: 'Attachment: Event' }
                                                const attachment = { type: 'events', id: e.id, children: [text] }

                                                onAddAttachment(attachment)

                                                getGCWidget('events', e.id)
                                            }}
                                            key={e.id}
                                        >
                                            <Box className={classes.eventHover}>
                                                <Box style={{ padding: '0.375em 1.5em' }}>
                                                    <Event event={e} showDate={!getEventMoment(e).isSame(moment(), 'day')} showLength={true} showSmall={true} />
                                                </Box>
                                            </Box>
                                        </Link>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
                {state.subtab === 'polls' && (
                    <Box style={{ overflowY: 'scroll', marginLeft: -16, marginRight: -16, width: 'calc(100% + 32px)' }}>
                        <Box style={{ paddingTop: 8 }}>
                            <Typography gutterBottom align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                                Poll
                            </Typography>
                            <Box className={classes.pollInputBox}>
                                <InputBase
                                    className={classes.pollInput}
                                    placeholder="Question"
                                    value={state.question.title}
                                    onChange={e => {
                                        setState(state => ({
                                            ...state,
                                            question: {
                                                ...state.question,
                                                title: e.target.value,
                                            },
                                        }))
                                    }}
                                    fullWidth
                                    inputProps={{ 'aria-label': 'Question', maxLength: 64 }}
                                />
                            </Box>
                            <Box className={classes.pollInputBox}>
                                <Typography className={classes.pollInputLabel}>Type</Typography>
                                <NativeSelect
                                    value={state.question.type}
                                    onChange={e =>
                                        setState(state => ({
                                            ...state,
                                            question: {
                                                ...state.question,
                                                type: parseInt(e.target.value),
                                            },
                                        }))
                                    }
                                    input={<InputBase className={classes.pollInput} placeholder="Type" inputProps={{ 'aria-label': 'Type' }} fullWidth />}
                                >
                                    <option value={Qs.MC}>Multiple Choice</option>
                                    <option value={Qs.CHECKBOX}>Checkboxes</option>
                                </NativeSelect>
                            </Box>
                            <Box className={classes.pollInputBox}>
                                <Typography className={classes.pollInputLabel}>Options</Typography>
                                {state.question.options.map((option, index) => (
                                    <Box key={option.id} style={{ position: 'relative' }}>
                                        <InputBase
                                            className={classes.pollInput}
                                            placeholder={`Option ${index + 1}`}
                                            value={option.text}
                                            onChange={e => {
                                                let options = [...state.question.options]
                                                options[index] = { ...options[index], text: e.target.value }
                                                setState(state => ({
                                                    ...state,
                                                    question: {
                                                        ...state.question,
                                                        options: options,
                                                    },
                                                }))
                                            }}
                                            fullWidth
                                            inputProps={{ 'aria-label': 'Option', maxLength: 64 }}
                                        />
                                        {index > 0 && (
                                            <IconButton
                                                size="small"
                                                style={{ position: 'absolute', right: 8, top: 8 }}
                                                onClick={() => {
                                                    let options = [...state.question.options]
                                                    options.splice(index, 1)
                                                    setState(state => ({
                                                        ...state,
                                                        question: {
                                                            ...state.question,
                                                            options: options,
                                                        },
                                                    }))
                                                }}
                                            >
                                                <CloseIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                            <Box className={classes.pollInputBox} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <Button
                                    onClick={() => {
                                        setState(state => ({
                                            ...state,
                                            question: {
                                                ...state.question,
                                                options: [...state.question.options, generateOption()],
                                            },
                                        }))
                                    }}
                                    disabled={state.question.options.length >= 30}
                                >
                                    Add Option
                                </Button>
                            </Box>
                            <Box className={classes.pollInputBox}>
                                <Box className={classes.pollInput}>
                                    <FormControlLabel
                                        style={{ width: '100%', marginLeft: -6 }}
                                        control={
                                            <Checkbox
                                                size="small"
                                                color="info"
                                                checked={state.question.anonymous}
                                                style={{ padding: 6 }}
                                                onChange={e => {
                                                    setState(state => ({
                                                        ...state,
                                                        question: {
                                                            ...state.question,
                                                            anonymous: state.question.anonymous ? false : true,
                                                        },
                                                    }))
                                                }}
                                            />
                                        }
                                        label="Anonymous"
                                    />
                                </Box>
                            </Box>
                            <Typography variant="caption" align="center">
                                Messages polls are separate from regular polls, and are not visible in the regular poll screen
                            </Typography>
                        </Box>
                    </Box>
                )}
                {state.tab === 'gif' && (
                    <>
                        <Box className={classes.giphySelectorBox}>
                            <Box className={classes.giphySelectorMarginTop} />
                            <GiphySelector
                                onClick={gif => {
                                    const text = { text: 'Attachment: GIF' }
                                    const attachment = {
                                        type: 'image',
                                        src: gif.images.original.url,
                                        giphyId: gif.id,
                                        children: [text],
                                    }

                                    if (gif.title) {
                                        attachment.alt = gif.title
                                    }

                                    onAddAttachment(attachment)
                                }}
                            />
                            <Box className={classes.giphySelectorMarginBottom} />
                        </Box>
                        <Box className={classes.giphyAttributionPadding}>
                            <Divider />
                            <Box className={classes.giphyAttributionBox}>
                                <Typography className={classes.giphyPoweredBy}>Powered by</Typography>
                                <a href="https://giphy.com" className={classes.giphyLink}>
                                    <img src={giphyLogo} className={classes.giphyLogo} alt="Giphy" />
                                </a>
                                <a href="https://giphy.com" className={classes.giphyLinkBlack}>
                                    <img src={giphyLogoBlack} className={classes.giphyLogoBlack} alt="Giphy" />
                                </a>
                            </Box>
                        </Box>
                    </>
                )}
                {state.error && <ErrorTypography text={state.error} isCentered={true} />}
            </DialogContentMobile>
            {state.subtab === 'polls' && (
                <DialogActionsMobile>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            sendPoll()
                        }}
                        color="primary"
                        disabled={state.loading || state.question.title.length === 0 || state.question.options.map(option => option.text).includes('')}
                    >
                        Insert
                    </Button>
                </DialogActionsMobile>
            )}
        </DialogMobile>
    )
}

export default React.memo(AttachmentsDialog)
