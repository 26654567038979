import React from "react";

// styles
import useStyles from "./styles";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.menuContainer}>
      <img className={classes.menu} src={props.src} alt="Menu" />
    </div>
  );
}
