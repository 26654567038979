import React from 'react'
import { Box, TextField } from '@material-ui/core'

import { Grid as GiphyGrid } from '@giphy/react-components'
import { GiphyFetch } from '@giphy/js-fetch-api'
import ResizeObserver from 'react-resize-observer'

export default function GiphySelector(props) {
    const { onClick } = props

    const gf = new GiphyFetch('2rPQfJD72kA2cZBiWFrBe2tBUXQ1WSV4')

    const [search, setSearch] = React.useState('')

    const fetchGifs = offset => (search ? gf.search(search, { offset, limit: 10 }) : gf.trending({ offset, limit: 10 }))
    const [width, setWidth] = React.useState(512)

    return (
        <Box>
            <TextField
                placeholder="Search"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 32 }}
                value={search}
                onChange={e => setSearch(e.target.value)}
                style={{ marginBottom: 8 }}
                size="small"
            />
            <GiphyGrid
                onGifClick={(gif, e) => {
                    onClick(gif)
                    e.preventDefault()
                }}
                key={search}
                width={width}
                fetchGifs={fetchGifs}
                columns={3}
                gutter={8}
                noResultsMessage="No results"
                hideAttribution
            />
            <ResizeObserver
                onResize={({ width }) => {
                    setWidth(width)
                }}
            />
        </Box>
    )
}
