import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    widgetTop: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        margin: `8px 12px`,
    },
    widgetTopTitle: { display: 'flex', flexGrow: 1, flexDirection: 'row' },
    widgetParent: {
        maxWidth: `max(640px, 75%)`,
        margin: '0 auto',
        fontSize: '1rem',
    },
    widgetBody: {
        margin: `8px 12px`,
    },
    widgetBottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    widgetAction: {
        borderRadius: 0,
        fontSize: '0.875em',
        padding: '0.575em 0.5em',
        width: '100%',
    },
    widgetAvatar: {
        width: 32,
        height: 32,
    },
    widgetAvatarButton: {
        padding: 4,
    },
    menuImage: {
        display: 'block',
        maxWidth: '100%',
        maxHeight: 384,
        objectFit: 'contain',
        margin: '0 auto',
        borderRadius: 8,
        border: `2px solid ${theme.palette.text.primary}22`,
    },
    progressBar: {
        backgroundColor: theme.palette.primary.main,
    },
    progress: {
        margin: `10px auto`,
        maxWidth: 512,
        backgroundColor: theme.palette.gray.main,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        width: '100%',
        overflow: 'scroll',
        backgroundColor: 'white',
    },
    exitButton: {
        position: 'absolute',
        right: 16,
        bottom: 16,
        width: 48,
        height: 48,
        zIndex: theme.zIndex.drawer + 2,
        backgroundColor: 'rgba(0,0,0,0.4)',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
    },
    grid: {},
    gridActiveNewsletter: {
        pointerEvents: `none`,
        userSelect: `none`,
        overflowX: `hidden`,
        overflowY: `hidden`,
    },
    downloadAppBox: {
        borderRadius: 4,
        backgroundImage: `linear-gradient(to right bottom, ${theme.palette.primary.light}, ${theme.palette.primary.dark} 120%)`,
        color: 'white',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        position: 'relative',
    },
    downloadAppButton: {
        padding: 0,
        margin: 8,
        '&:hover': {
            backgroundColor: 'initial',
            textDecoration: 'none',
        },
    },
    downloadAppBadge: {
        display: 'block',
        filter: 'drop-shadow(0 6px 4px rgba(0, 0, 0, 0.25))',
        margin: '0px auto',
        paddingBottom: 6,
        paddingTop: 0,
        transition: '0.1s all',
        width: 120,
        '&:hover': {
            filter: 'drop-shadow(0 3px 4px rgba(0, 0, 0, 0.25))',
            paddingBottom: 3,
            paddingTop: 3,
        },
    },
    downloadAppPaper: {
        borderRadius: theme.spacing(2),
    },
    componentBox: {
        padding: 8,
        width: '100%',
    },
    componentBoxContainer: {
        width: '100%',
    },
    emptyComponent: {
        boxShadow: theme.customShadows.widget,
        width: 256,
        borderRadius: 8,
    },
    stepper: { padding: '8px 8px 0 4px', boxShadow: 'none !important' },
}))
