import React from 'react'
import { Box } from '@material-ui/core'
import { CheckCircleRounded as CheckIcon } from '@material-ui/icons'
import { Typography } from '../../Wrappers'

export default function CheckedTypography(props) {
    const { text, isCentered } = props
    return (
        <Box
            display="flex"
            style={{
                justifyContent: isCentered ? 'center' : 'flex-start',
                width: '100%',
                alignItems: 'center',
            }}
        >
            <CheckIcon style={{ color: '#2ecc71', margin: 2 }} fontSize="small" />
            <Typography color="green" style={{ marginLeft: 4, fontWeight: 700 }}>
                {text}
            </Typography>
        </Box>
    )
}
