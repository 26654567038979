import React from 'react'
import { Divider, Box, Typography, IconButton } from '@material-ui/core'

import { ChevronRight as RightIcon, ChevronLeft as LeftIcon } from '@material-ui/icons'

const Header = ({ date, onGoPrevious, onGoNext, canGoPrevious, canGoNext }) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const percentage = '' + 100 / 7 + '%'

    return (
        <>
            <Box display="flex">
                <IconButton onClick={onGoPrevious} disabled={canGoPrevious === 'false'} style={{ fontSize: '1.5em', padding: '0.575em' }}>
                    <LeftIcon style={{ fontSize: '1.15em', width: '1.15em', height: '1.15em' }} />
                </IconButton>
                <Box style={{ fontSize: '1.5em', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="h4" style={{ fontSize: '1em', textAlign: `center`, lineHeight: `1em`, fontWeight: 900 }}>
                        {date}
                    </Typography>
                </Box>
                <IconButton onClick={onGoNext} disabled={canGoNext === 'false'} style={{ fontSize: '1.5em', padding: '0.575em' }}>
                    <RightIcon style={{ fontSize: '1.15em', width: '1.15em', height: '1.15em' }} />
                </IconButton>
            </Box>
            <Box>
                <Divider />
            </Box>
            <Box display="flex">
                {days.map(day => (
                    <Box key={`Calendar.Header.${day}`} style={{ width: percentage }}>
                        <Typography style={{ textAlign: `center`, fontWeight: 700, fontSize: '1.375em' }}>{day}</Typography>
                    </Box>
                ))}
            </Box>
            <Box>
                <Divider />
            </Box>
        </>
    )
}

export default React.memo(Header)
