import React from 'react'

import { DialogContentText } from '@material-ui/core'

import LoadingButton from '@mui/lab/LoadingButton'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Wrappers'

const DeleteDialog = ({ open, title, text, onClose, onConfirmDelete, loading }) => {
    return (
        <Dialog open={open} onClose={onClose} disableClose={!!loading}>
            <DialogTitle onClose={onClose} disableClose={!!loading}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={!!loading}>
                    Cancel
                </Button>
                <LoadingButton onClick={onConfirmDelete} color="red" loading={!!loading}>
                    Delete
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDialog
