import React from 'react'

import { DeleteOutline as DeleteIcon, LocalOfferOutlined as DefaultIcon } from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'

import MenuItem from 'components/Menu/Item'
import Menu from 'components/Menu'

const RoleMenu = ({ role, onSelectAction, isMobileDevice, type, ...props }) => {
    const theme = useTheme()

    return (
        <Menu
            {...props}
            type={type}
        >
            {!role.default && (
                <MenuItem
                    icon={DefaultIcon}
                    key="set_default"
                    onClick={() => onSelectAction('set_default')}
                    label="Set Default"
                    type={type}
                />
            )}
            {role.id !== 'ADMIN' && (
                <MenuItem
                    style={{ color: theme.palette.red.main }}
                    icon={DeleteIcon}
                    key="delete"
                    onClick={() => onSelectAction('delete')}
                    label="Delete"
                    type={type}
                />
            )}
        </Menu>
    )
}

export default React.memo(RoleMenu)
