import React from 'react'
import { Grid } from '@material-ui/core'

// styles
import useStyles from './styles'

// components
import { Typography } from 'components/Wrappers'

export default function Page(props) {
    var classes = useStyles()

    const { children, footer } = props

    return (
        <Grid container spacing={2} className={footer ? classes.footerPage : null}>
            {children}
            {footer && (
                <>
                    <Grid item xs={12} style={{ width: '100%', height: 35 }}></Grid>
                    <Grid item xs={12} className={classes.footer}>
                        <Typography variant="caption" align="center">
                            {footer}
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    )
}
