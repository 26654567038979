import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    parentBox: {
        backgroundColor: 'white',
        border: `2px solid #D8D8D8`,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.16)',
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
        paddingRight: 32,
    },
    titleIcon: {
        color: theme.palette.primary.main,
    },
    titleIconBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    titleText: {
        paddingLeft: 5,
        fontWeight: 'bold !important',
        fontSize: '18px !important',
        color: 'black',
        lineHeight: 1,
    },
    titleTextBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    votesBox: {
        display: 'flex',
        flexDirection: 'column',
    },
    voteBox: {
        marginTop: 8,
        borderRadius: 8,
        padding: 2,
        border: `2px solid #D8D8D8`,
        color: 'black',
    },
    voteBoxSelected: {
        color: 'white',
    },
    voteText: {
        textAlign: 'left',
        marginLeft: 4,
        fontWeight: '500 !important',
        width: '100%',
    },
}))
