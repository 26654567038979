export const compileMembers = arr => {
    if (!arr || !Array.isArray(arr)) {
        return []
    }

    let mems = []
    for (let i = 0; i < arr.length; i++) {
        mems.push(arr[i].id)
    }

    return mems
}

export const expandMembers = (arr, options) => {
    if (!arr || !Array.isArray(arr)) {
        return []
    }

    let mems = []

    for (let i = 0; i < options.length; i++) {
        let option = options[i]

        if (arr.includes(option.id)) {
            mems.push(option)
        }
    }

    return mems
}
