export const visibilityOptions = includeOthers => {
    if (includeOthers) {
        return [
            { id: 0, name: 'New Member' },
            { id: 1, name: 'Member' },
            { id: 2, name: 'Inactive Member' },
            { id: 3, name: 'Officer' },
            { id: 4, name: 'Alumni' },
            { id: 5, name: 'Guest' },
            { id: 6, name: 'Public' },
        ]
    }

    return [
        { id: 0, name: 'New Member' },
        { id: 1, name: 'Member' },
        { id: 2, name: 'Inactive Member' },
        { id: 3, name: 'Officer' },
    ]
}

export const convertToSelect = data => {
    let obj = {}

    for (let i = 0; i < data.length; i++) {
        let d = data[i]
        obj[d.id] = { title: d.name, default: d.id === 1 }
    }

    return obj
}

export const renderVisibility = visibility => {
    if (!Array.isArray(visibility) || visibility.length === 0) {
        return 'None'
    }
    let str = ''

    let sortedVis = [...visibility].sort((a, b) => a - b)

    for (let i = 0; i < sortedVis.length; i++) {
        str += visibilityNameOptions[sortedVis[i]]
        if (i === 0 && sortedVis.length === 2) {
            str += ' & '
        } else if (sortedVis.length - i === 2) {
            str += ', & '
        } else if (sortedVis.length - i > 2) {
            str += ', '
        }
    }

    return str
}

export const visibilityNameOptions = ['New Member', 'Member', 'Inactive Member', 'Officer', 'Alumni', 'Guest', 'Public']

export const visibilityNameOptionsNoPublic = ['New Member', 'Member', 'Inactive Member', 'Officer', 'Alumni', 'Guest']

export const visibilityColorOptions = ['yellow', 'green', 'orange', 'blue', 'red', 'pink', 'darkorange']

export const defaultVisibilityOptions = [
    { id: 0, name: 'New Member' },
    { id: 1, name: 'Member' },
    { id: 3, name: 'Officer' },
]

export const compileVisibility = arr => {
    if (!arr || !Array.isArray(arr)) {
        return []
    }

    let vis = []
    for (let i = 0; i < arr.length; i++) {
        vis.push(arr[i].id)
    }

    return vis
}

export const expandVisibility = arr => {
    if (!arr || !Array.isArray(arr)) {
        return []
    }

    let visibility = []

    let options = visibilityOptions(true)

    arr.sort((a, b) => {
        return a - b
    })

    for (let i = 0; i < arr.length; i++) {
        if (arr[i] >= 0 && arr[i] < options.length) {
            visibility.push(options[arr[i]])
        }
    }

    return visibility
}
