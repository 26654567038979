import { compileVisibility, defaultVisibilityOptions } from 'data/Visibility'

import { PointRecord } from './Record'

export class Points {
    /*
    id - string
    name - string
    description - string
    links - array of link objects
    type - integer: 0 = maximum (get as many as possible), 1 = goal (set number), 2 = percentage (percentage of available points)
    goal - integer: goal if points type = 1
    visbility - usual visibility
    rankingsVisibility - integer: 0 = admin only; 1 = show place & admin; 2 = visible to everyone
    records - map of arrays by user id
    triggers - array of strings containing 'eventID.triggerID'
    allowRequests - bool
    */

    static get RANKINGS_ADMIN() {
        return 0
    }

    static get RANKINGS_PLACE() {
        return 1
    }

    static get RANKINGS_ALL() {
        return 2
    }

    static get TYPE_MAXIMUM() {
        return 0
    }

    static get TYPE_GOAL() {
        return 1
    }

    static get TYPE_PERCENTAGE() {
        return 2
    }

    static get MEMBERSHIP_TYPE_AUTOMATIC() {
        return 0
    }

    static get MEMBERSHIP_TYPE_CUSTOM() {
        return 1
    }

    constructor(points) {
        this.id = points.id
        this.name = points.name
        this.description = points.description
        this.type = points.type
        this.goal = points.goal
        this.links = points.links
        this.rankingsVisibility = points.rankingsVisibility
        this.records = points.records
        this.author = points.author
        this.triggers = points.triggers ?? []
        this.allowRequests = !!points.allowRequests
        this.cd = points.cd
        this.allowLocationTrackingRequests = !!points.allowLocationTrackingRequests
        this.locations = points.locations ?? []
        this.admins = points.admins ?? []
        this.mType = points.mType ?? 0

        if (this.mType === Points.MEMBERSHIP_TYPE_CUSTOM) {
            this.mems = points.mems ?? []
        } else {
            this.vis = points.vis ?? []
        }
    }

    static getNew() {
        return new Points({
            id: '',
            name: '',
            description: '',
            type: Points.TYPE_GOAL,
            goal: 100,
            links: [],
            mType: Points.MEMBERSHIP_TYPE_AUTOMATIC,
            vis: compileVisibility(defaultVisibilityOptions),
            rankingsVisibility: Points.RANKINGS_PLACE,
            records: {},
            author: {
                id: '',
            },
            admins: [],
            allowRequests: true,
            triggers: [],
            allowLocationTrackingRequests: false,
            locations: [],
        })
    }

    removeRecord(userId, recordId) {
        if (userId in this.records) {
            let recs = this.records[userId]
            for (let i = 0; i < recs.length; i++) {
                if (recs[i].id === recordId) {
                    recs.splice(i, 1)
                    this.records[userId] = recs
                    return
                }
            }
        }
    }

    addRecord(userId, record) {
        if (userId in this.records) {
            let rec = this.records[userId]
            rec.push(record)
            this.records[userId] = rec
        } else {
            if (userId !== '') {
                let rec = [record]
                this.records[userId] = rec
            }
        }
    }

    getRecords(userId) {
        if (userId in this.records) {
            let records = this.records[userId]

            for (let i = 0; i < records.length; i++) {
                let record = new PointRecord(records[i])
                record.statusStr = record.getStatusString()
                records[i] = record
            }

            return records
        }

        return []
    }

    getNumOpenRequests(userId) {
        if (userId) {
            let records = this.getRecords(userId)

            let count = 0

            for (let i = 0; i < records.length; i++) {
                let record = new PointRecord(records[i])
                if (record.status === 0) {
                    count += 1
                }
            }

            return count
        }

        let keys = Object.keys(this.records)

        let chapterData = localStorage.getItem('cache_chapter') ? JSON.parse(localStorage.getItem('cache_chapter')) : null

        let count = 0

        if (chapterData) {
            keys = keys.filter(key => key in chapterData.members)
        }

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i] // userId

            if (
                (!this.mType && Array.isArray(this.vis) && this.vis.includes(chapterData.members[key].status)) ||
                (this.mType === 1 && Array.isArray(this.mems) && this.mems.includes(key))
            ) {
                let curRecords = this.records[key]

                for (let j = 0; j < curRecords.length; j++) {
                    let record = new PointRecord(curRecords[j])
                    if (record.status === 0) {
                        count += 1
                    }
                }
            }
        }

        return count
    }

    getPoints(userId) {
        let records = this.getRecords(userId)
        let points = 0

        for (let i = 0; i < records.length; i++) {
            let record = new PointRecord(records[i])
            if (record.arePointsCounted()) {
                points += record.getPoints()
            }
        }

        return points
    }

    allowsRequests() {
        return this.allowRequests
    }

    setAllowRequests(allowRequests) {
        this.allowRequests = allowRequests
    }

    getId() {
        return this.id
    }

    setId(id) {
        this.id = id
    }

    setName(name) {
        this.name = name
    }

    getName() {
        return this.name
    }

    setDescription(description) {
        this.description = description
    }

    getDescription() {
        return this.description
    }

    setLinks(links) {
        this.links = links
    }

    getLinks() {
        return this.links
    }

    getType() {
        return this.type
    }

    setType(type) {
        this.type = type
    }

    getGoal() {
        return this.goal
    }

    setGoal(goal) {
        this.goal = goal
    }
}
