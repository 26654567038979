import { makeStyles } from '@material-ui/styles'
import { alpha } from '@material-ui/core/styles'

export default makeStyles(theme => ({
    logotype: {
        color: 'white',
        marginLeft: theme.spacing(2.5),
        marginRight: theme.spacing(2.5),
        fontWeight: 500,
        fontSize: 18,
        whiteSpace: 'nowrap',
    },
    logotypeMobileContainer: {
        display: 'flex',
        width: 'calc(100% - 32px)',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
    },
    hiddenOnSmall: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    appBar: {
        width: '100%',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
        height: `calc(56px + env(safe-area-inset-top))`,
    },
    appBarBorder: {
        borderBottom: theme.palette.type === 'dark' ? theme.border : 'none',
        boxShadow: 'none !important',
    },
    toolbar: {
        position: 'absolute',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: `env(safe-area-inset-top)`,
        height: `calc(48px + env(safe-area-inset-top))`,
        width: '100vw',
    },
    hide: {
        display: 'none',
    },
    grow: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: 25,
        paddingLeft: theme.spacing(2.5),
        width: 36,
        backgroundColor: alpha(theme.palette.common.black, 0),
        transition: theme.transitions.create(['background-color', 'width']),
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: alpha(theme.palette.common.black, 0.08),
        },
    },
    searchFocused: {
        backgroundColor: alpha(theme.palette.common.black, 0.08),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 250,
        },
    },
    searchIcon: {
        width: 36,
        right: 0,
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: theme.transitions.create('right'),
        '&:hover': {
            cursor: 'pointer',
        },
    },
    searchIconOpened: {
        right: theme.spacing(1.25),
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        height: 36,
        padding: 0,
        paddingRight: 36 + theme.spacing(1.25),
        width: '100%',
    },
    messageContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    headerMenu: {
        marginTop: theme.spacing(7),
    },
    headerMenuList: {
        display: 'flex',
        flexDirection: 'column',
    },
    headerMenuItem: {
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
    },
    headerMenuButtonCollapse: {
        marginRight: theme.spacing(2),
        height: 34,
    },
    headerIconCollapse: {
        color: 'white',
    },
    profileMenu: {
        minWidth: 265,
    },
    profileMenuUser: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    profileMenuItem: {
        color: theme.palette.text.primary,
    },
    profileMenuIcon: {
        marginRight: theme.spacing(2),
    },
    profileMenuLink: {
        fontSize: 16,
        textDecoration: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    messageNotification: {
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        '&:hover, &:focus': {
            backgroundColor: theme.palette.background.light,
        },
    },
    messageNotificationSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: theme.spacing(2),
    },
    messageNotificationBodySide: {
        alignItems: 'flex-start',
        marginRight: 0,
    },
    sendMessageButton: {
        margin: theme.spacing(4),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textTransform: 'none',
    },
    sendButtonIcon: {
        marginLeft: theme.spacing(2),
    },
}))
