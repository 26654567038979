import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    parentBox: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'space-around',
    },
    sectionBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
}))
