import React from 'react'

import { Box, Typography } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

import { TumbleWeedOutlinedIcon } from 'components/Icons'

import { useTheme } from '@material-ui/core/styles'

import ChatConversationsList from 'components/Messages/ChatConversationsList'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { HeaderContext } from 'context/HeaderContext'
import { MessagesContext } from 'context/MessagesContext'

const Messages = ({ history, ...props }) => {
    const theme = useTheme()

    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)
    const { setIcons } = React.useContext(HeaderContext)
    const { getAllConversations } = React.useContext(MessagesContext)

    const [hasBeenASecond, setHasBeenASecond] = React.useState(false)
    const [hasAnimated, setHasAnimated] = React.useState(false)

    React.useEffect(() => {
        const isUserMessagesCreateAdmin = () => {
            if (chapter && chapter.members && user) {
                let mem = chapter.members[user.getId()]
                if (mem) {
                    return (
                        mem.role === 'ADMIN' ||
                        (chapter.perms['messagesAdmin'] && chapter.perms['messagesAdmin'].includes(mem.role)) ||
                        (chapter.perms['messagesCreate'] && chapter.perms['messagesCreate'].includes(mem.role))
                    )
                }
            }

            return false
        }

        setIcons(
            isUserMessagesCreateAdmin()
                ? [
                      {
                          name: 'New Conversation',
                          type: 'icon',
                          innerIcon: <AddIcon />,
                          onClick: () => history.push('/app/messages/new'),
                      },
                  ]
                : [],
        )

        setTimeout(() => {
            setHasBeenASecond(true)
        }, 1000)

        setTimeout(() => {
            setHasAnimated(true)
        }, 225)
    }, [])

    if (hasBeenASecond && Object.keys(getAllConversations()).length === 0) {
        return (
            <Box style={{ padding: 8, width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 16,
                        flexGrow: 1,
                    }}
                >
                    <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor }} />
                    <Typography style={{ color: theme.borderColor }}>There are no conversations here</Typography>
                </Box>
            </Box>
        )
    }

    if (!hasAnimated && history.action === 'PUSH') return <></>

    return <ChatConversationsList history={history} conversationId={null} />

    /*return (
        <Box style={{ marginTop: -8 }}>
            <Tabs
                autoSize
                onChange={val => {
                    console.log('todo')
                }}
                selected={'conversations'}
                tabs={[
                    { id: 'conversations', name: 'Conversations' },
                    { id: 'explore', name: 'Explore' },
                ]}
            />
            <Divider style={{ marginLeft: -16, marginRight: -16, marginBottom: 16 }} />
            <ChatConversationsList history={history} conversationId={null} />
        </Box>
    )*/
}

export default React.memo(Messages)
