import { generateUUID } from 'code/UUID'

import moment from 'moment'

export class Subtasks {
    /*
    id - string
    name - string
    status - integer: 0 = not completed, 1 = completed
    lu - { user: User, date: last updated }
    */

    static get STATUS_NOT_COMPLETED() {
        return 0
    }

    static get STATUS_COMPLETED() {
        return 1
    }

    constructor(item) {
        this.id = item.id
        this.name = item.name
        this.status = item.status
        this.lu = item.lu
    }

    static convertArrayToRaw(arr) {
        let a = []

        for (let i = 0; i < arr.length; i++) {
            a.push(arr[i].getRaw())
        }

        return a
    }

    getRaw() {
        let data = {
            id: this.id,
            name: this.name,
            status: this.status,
            lu: this.lu,
        }

        return data
    }

    static getNew() {
        return new Subtasks({
            id: generateUUID(),
            name: '',
            status: this.STATUS_NOT_COMPLETED,
            lu: null,
        })
    }

    getId() {
        return this.id
    }

    setId(id) {
        this.id = id
    }

    setName(name) {
        this.name = name
    }

    getName() {
        return this.name
    }

    updateStatus(status, user) {
        this.status = status
        this.lu = {
            dt: new Date(),
            user: {
                id: user.id,
                first: user.first,
                last: user.last,
            },
        }
    }

    getStatus() {
        return this.status
    }

    getCaption(user) {
        if (this.lu && this.lu.user) {
            let name = this.lu.user.id === user.id ? 'You' : `${this.lu.user.first} ${this.lu.user.last}`
            let str = `${name} marked as `

            if (this.status === 1) {
                str += 'complete @ '
            } else {
                str += 'not complete @ '
            }

            str += moment(1000 * (this.lu.dt.seconds ? this.lu.dt.seconds : this.lu.dt._seconds)).format('h:mm a on YYYY/MM/DD')

            return str
        }

        if (this.status === 1) {
            return 'Complete'
        }

        return 'Not complete'
    }
}
