import { Box, ButtonBase, Checkbox, Divider, IconButton, Link } from '@material-ui/core'
import React from 'react'
import { Typography } from '../../Wrappers'

import { CheckCircle as RadioSelectedIcon, RadioButtonUnchecked as RadioEmptyIcon } from '@material-ui/icons'
import Widget from '../../Widget'

import useLongPress from 'code/LongPress'

// styles
import { useTheme } from '@material-ui/styles'
import useStyles from './styles'

export default function ContactCard(props) {
    var theme = useTheme()
    var classes = useStyles()

    const { first, last, subtitle, actions, selected, selectable, onClick, onLongClick, checkbox, icons, variant, showBorder } = props

    const longPressEvent = useLongPress(onLongClick, onClick, {
        shouldPreventDefault: true,
        delay: 600,
    })

    if (variant === 'mobile') {
        return (
            <ButtonBase {...longPressEvent} className={classes.selectableWidgetMobile} style={showBorder ? { borderTop: theme.border } : null}>
                <Box display="flex" flexDirection="column">
                    <Box display="flex" my={2} className={classes.containerBox}>
                        {selectable && !selected && (
                            <Box style={{ margin: 12, marginLeft: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <RadioEmptyIcon />
                            </Box>
                        )}
                        {selectable && selected && (
                            <Box style={{ margin: 12, marginLeft: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <RadioSelectedIcon color="primary" />
                            </Box>
                        )}
                        <Box display="flex" style={{ flexDirection: `column`, flexGrow: 1 }}>
                            <Box display="flex" style={{ flexDirection: `row` }}>
                                <Typography>{first}</Typography>
                                <Typography style={{ marginLeft: 6, fontWeight: 600 }}>{last}</Typography>
                            </Box>
                            <Box display="flex" style={{ flexDirection: `row` }}>
                                <Typography>{subtitle}</Typography>
                                <Box style={{ marginLeft: 8 }}>{icons && icons.map(icon => icon)}</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ButtonBase>
        )
    }

    return (
        <Link underline="none" onClick={!checkbox.active ? onClick : null}>
            <Widget noBodyPadding disableWidgetMenu inheritHeight bodyClass={onClick && !checkbox.active ? classes.selectableWidget : classes.widget}>
                <Box display={'flex'} flexDirection="column">
                    <Box display="flex" my={2} className={classes.containerBox}>
                        {checkbox.active && (
                            <Box display="flex" style={{ flexDirection: `column`, justifyContent: `center` }}>
                                <Box>
                                    <Checkbox
                                        checked={checkbox.checked}
                                        onChange={e => {
                                            checkbox.onChange(e)
                                        }}
                                        style={{ padding: 2 }}
                                    />
                                </Box>
                            </Box>
                        )}
                        <Box display="flex" style={{ flexDirection: `column`, flexGrow: 1 }}>
                            <Box display="flex" style={{ flexDirection: `row` }}>
                                <Typography>{first}</Typography>
                                <Typography style={{ marginLeft: 6, fontWeight: 600 }}>{last}</Typography>
                            </Box>
                            <Box display="flex" style={{ flexDirection: `row` }}>
                                <Typography>{subtitle}</Typography>
                                <Box style={{ marginLeft: 8 }}>{icons && icons.map(icon => icon)}</Box>
                            </Box>
                        </Box>
                        {actions && (
                            <>
                                <Divider flexItem orientation="vertical" className={classes.divider} />
                                {actions.map(action => (
                                    <Box style={{ display: `flex` }}>
                                        <Box style={{ margin: `auto` }} key={action.key}>
                                            <IconButton size="small" onClick={action.onClick}>
                                                {action.icon}
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))}
                            </>
                        )}
                    </Box>
                </Box>
            </Widget>
        </Link>
    )
}
