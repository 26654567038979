import React from 'react'

import { Box, Button, Switch, Typography } from '@material-ui/core'

import cn from 'classnames'

import useStyles from './styles'

const ToggleItem = ({ icon, title, subtitle, href, checked, onChange, disabled, ...props }) => {
    let classes = useStyles()

    return (
        <Box className={classes.box} {...props}>
            {icon && React.cloneElement(icon, { className: classes.applicationIcon })}
            <Box className={classes.titleBox} style={icon ? null : { margin: '0.5rem' }}>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
                <Typography className={classes.subtitle}>{subtitle}</Typography>
            </Box>

            {onChange ? (
                <Switch checked={checked} onChange={onChange} color="primary" disabled={disabled} />
            ) : (
                <Typography style={{ marginRight: '0.5rem', fontWeight: '500', whiteSpace: 'nowrap' }}>Always On</Typography>
            )}
        </Box>
    )
}

export const ButtonItem = ({ icon, title, subtitle, selected, ...props }) => {
    let classes = useStyles()

    return (
        <Button className={cn(classes.box, classes.button, { [classes.buttonSelected]: !!selected })} aria-checked={!!selected} {...props}>
            {icon && React.cloneElement(icon, { className: classes.applicationIcon })}
            <Box className={classes.titleBox} style={icon ? null : { paddingLeft: '0.5rem' }}>
                <Typography variant="h6" className={classes.title} align="left">
                    {title}
                </Typography>
                <Typography className={classes.subtitle} align="left">
                    {subtitle}
                </Typography>
            </Box>
        </Button>
    )
}

export const ButtonLargeItem = ({ icon, title, subtitle, selected, ...props }) => {
    let classes = useStyles()

    return (
        <Button className={cn(classes.boxLarge, classes.button, { [classes.buttonSelected]: !!selected })} {...props}>
            {icon && React.cloneElement(icon, { className: classes.applicationIconLarge })}
            <Box className={classes.titleBox} style={icon ? null : { paddingLeft: '0.5rem' }}>
                <Typography variant="h6" className={classes.title} align="center">
                    {title}
                </Typography>
                <Typography className={classes.subtitle} align="center">
                    {subtitle}
                </Typography>
            </Box>
        </Button>
    )
}

export default ToggleItem
