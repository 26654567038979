import React from 'react'

import app from 'firebase/app'

import { Box, Divider, Grid, Switch } from '@material-ui/core'

import { HomeOutlined as HomeIcon } from '@material-ui/icons'

// components
import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'
import { Typography } from 'components/Wrappers'

import { AuthContext } from 'context/AuthContext'

import { isMobileDevice } from 'code/Helper'

// styles
import useStyles from './styles'

export default function Notifications() {
    var classes = useStyles()

    const { user, setUser, currentUser } = React.useContext(AuthContext)

    function toggleNotification(id) {
        let notifications = user.notifications
        if (notifications === undefined) {
            notifications = {
                [id]: true,
            }
        } else if (notifications[id] === undefined) {
            notifications[id] = true
        } else {
            notifications[id] = notifications[id] === false
        }

        updateUser(notifications)
        let curU = { ...currentUser }
        curU.notifications = notifications
        setUser(curU)
    }

    function updateUser(notifData) {
        const db = app.firestore()
        let userDoc = db.collection('users').doc(user.id)

        userDoc.update({ notifications: notifData }).catch(function(error) {
            // Getting the Error details.
            var code = error.code
            var message = error.message
            var details = error.details
            console.log('AN ERROR OCCURRED', code, message, details)
        })
    }

    const getInnerBox = () => {
        return (
            <Box display="flex" flexDirection="column">
                <Grid container spacing={2}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12} className={classes.sectionOuter}>
                        <Box className={classes.sectionInner}>
                            <Box className={classes.sectionInfo}>
                                <Typography>Event Sign In</Typography>
                                <Typography variant="caption">When an event's sign in has opened</Typography>
                            </Box>
                            <Box className={classes.sectionSwitch}>
                                <Switch
                                    color="primary"
                                    checked={user.notifications && user.notifications.eventSignIn}
                                    onChange={e => toggleNotification('eventSignIn')}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className={classes.sectionOuter}>
                        <Box className={classes.sectionInner}>
                            <Box className={classes.sectionInfo}>
                                <Typography>Event Excuse Deadline</Typography>
                                <Typography variant="caption">When an excuse deadline is coming up</Typography>
                            </Box>
                            <Box className={classes.sectionSwitch}>
                                <Switch
                                    color="primary"
                                    checked={user.notifications && user.notifications.eventExcuseDeadline}
                                    onChange={e => toggleNotification('eventExcuseDeadline')}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className={classes.sectionOuter}>
                        <Box className={classes.sectionInner}>
                            <Box className={classes.sectionInfo}>
                                <Typography>Event Start</Typography>
                                <Typography variant="caption">When an event is starting soon</Typography>
                            </Box>
                            <Box className={classes.sectionSwitch}>
                                <Switch
                                    color="primary"
                                    checked={user.notifications && user.notifications.eventStart}
                                    onChange={e => toggleNotification('eventStart')}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className={classes.sectionOuter}>
                        <Box className={classes.sectionInner}>
                            <Box className={classes.sectionInfo}>
                                <Typography>Event RSVP Deadline</Typography>
                                <Typography variant="caption">When an event's rsvp is due soon</Typography>
                            </Box>
                            <Box className={classes.sectionSwitch}>
                                <Switch
                                    color="primary"
                                    checked={user.notifications && user.notifications.eventRSVPDeadline !== false}
                                    onChange={e => toggleNotification('eventRSVPDeadline')}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className={classes.sectionOuter}>
                        <Box className={classes.sectionInner}>
                            <Box className={classes.sectionInfo}>
                                <Typography>Tasks</Typography>
                                <Typography variant="caption">
                                    When tasks assigned to you start or when tasks you are supervising are awaiting approval
                                </Typography>
                            </Box>
                            <Box className={classes.sectionSwitch}>
                                <Switch color="primary" checked={user.notifications && user.notifications.tasks} onChange={e => toggleNotification('tasks')} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className={classes.sectionOuter}>
                        <Box className={classes.sectionInner}>
                            <Box className={classes.sectionInfo}>
                                <Typography>Default</Typography>
                                <Typography variant="caption">For future notification types</Typography>
                            </Box>
                            <Box className={classes.sectionSwitch}>
                                <Switch
                                    color="primary"
                                    checked={user.notifications && user.notifications.default}
                                    onChange={e => toggleNotification('default')}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    {/*<Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12} className={classes.sectionOuter}>
                <Box className={classes.sectionInner}>
                    <Box className={classes.sectionInfo}>
                        <Typography>Newsletters</Typography>
                        <Typography variant="caption">Publishing of a newsletter</Typography>
                    </Box>
                    <Box className={classes.sectionSwitch}>
                        <Switch
                            color="primary"
                            checked={user.notifications && user.notifications.newsletters}
                            onChange={e => setNotification('newsletters')}
                        />
                    </Box>
                </Box>
            </Grid>*/}
                    <Grid item xs={12}></Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Notifications',
                        },
                    ]}
                    grid
                />
                <Grid item xs={12}>
                    {isMobileDevice() ? (
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            maxWidth="md"
                        >
                            {getInnerBox()}
                        </Box>
                    ) : (
                        <Widget
                            disableWidgetMenu
                            inheritHeight
                            noBodyPadding
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {getInnerBox()}
                        </Widget>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
