import React from 'react'

import { BottomNavigation, BottomNavigationAction as BottomNavigationActionBase, Paper } from '@material-ui/core'

import {
    HouseOutlined as ChaptersIcon,
    HomeWorkOutlined as OrganizationsIcon,
    Settings as AdminIcon,
    SettingsOutlined as AdminOutlinedIcon,
} from '@material-ui/icons'

import {
    HomeIcon,
    CalendarIcon,
    MessagesIcon,
    AppsIcon,
    HomeOutlinedIcon,
    CalendarOutlinedIcon,
    MessagesOutlinedIcon,
    AppsOutlinedIcon,
    QuoteIcon,
} from 'components/Icons'

import { ChapterContext } from 'context/ChapterContext'
import { AuthContext } from 'context/AuthContext'

import useStyles from './styles'

const BottomNavigationAction = ({ children, ...props }) => (
    <BottomNavigationActionBase {...props} style={{ minWidth: 'unset' }}>
        {children}
    </BottomNavigationActionBase>
)

const Footer = ({ history, style, ...props }) => {
    const classes = useStyles()

    let { user } = React.useContext(AuthContext)
    let { chapter } = React.useContext(ChapterContext)

    const isChapterAdmin = () => {
        if (user && chapter) {
            let uid = user.getId()
            if (uid && chapter.members[uid]) {
                let userRole = chapter.members[uid].role
                if (userRole) {
                    return userRole === 'ADMIN'
                }
            }
        }
        return false
    }

    const location = history.location.pathname

    let val = location.split('/')

    val = val.length > 2 ? val[2] : 'dashboard'

    const isSelected = url => location.indexOf(url) !== -1

    if (user && user.isGCStaff()) {
        val = location.split('/')
        val = val.length > 3 ? val[3] : 'chapters'
        return (
            <Paper square style={{ ...style, zIndex: 2000, position: 'fixed', bottom: 0, width: '100%' }} {...props} className={classes.paper}>
                <BottomNavigation
                    value={val}
                    onChange={(e, newVal) => {
                        let url = '/app/gc/chapters'

                        if (newVal === 'chapters') {
                            url = '/app/gc/chapters'
                        } else if (newVal === 'organizations') {
                            url = '/app/gc/organizations'
                        } else if (newVal === 'quotes') {
                            url = '/app/gc/quotes'
                        }

                        history.push(url)
                    }}
                    showLabels
                >
                    <BottomNavigationAction label="Chapters" value="chapters" icon={<ChaptersIcon />} name="Chapters" />
                    <BottomNavigationAction label="Organizations" value="organizations" icon={<OrganizationsIcon />} name="Organizations" />
                    <BottomNavigationAction label="Quotes" value="quotes" icon={<QuoteIcon />} name="Quotes" />
                </BottomNavigation>
            </Paper>
        )
    }

    return (
        <Paper square style={{ ...style, zIndex: 2000, position: 'fixed', bottom: 0, width: '100%' }} {...props} className={classes.paper}>
            <BottomNavigation
                value={val}
                onChange={(e, newVal) => {
                    let url = '/app/dashboard'

                    if (newVal === 'calendar') {
                        url = '/app/calendar'
                    } else if (newVal === 'messages') {
                        url = '/app/messages'
                    } else if (newVal === 'applications') {
                        url = '/app/applications'
                    } else if (newVal === 'admin') {
                        url = '/app/admin'
                    }

                    history.push(url)
                }}
                showLabels
            >
                <BottomNavigationAction label="Home" value="dashboard" icon={isSelected('/app/dashboard') ? <HomeIcon /> : <HomeOutlinedIcon />} name="Home" />
                <BottomNavigationAction
                    label="Calendar"
                    value="calendar"
                    icon={isSelected('/app/calendar') ? <CalendarIcon /> : <CalendarOutlinedIcon />}
                    name="Calendar"
                />
                {(!chapter || !chapter.enabledFeatures || chapter.enabledFeatures.messages !== false) && (
                    <BottomNavigationAction
                        label="Messages"
                        value="messages"
                        icon={isSelected('/app/messages') ? <MessagesIcon /> : <MessagesOutlinedIcon />}
                        name="Messages"
                    />
                )}
                <BottomNavigationAction
                    label="Apps"
                    value="applications"
                    icon={isSelected('/app/applications') ? <AppsIcon /> : <AppsOutlinedIcon />}
                    name="Apps"
                />
                {isChapterAdmin() && (
                    <BottomNavigationAction label="Admin" value="admin" icon={isSelected('/app/admin') ? <AdminIcon /> : <AdminOutlinedIcon />} name="Admin" />
                )}
            </BottomNavigation>
        </Paper>
    )
}

export default React.forwardRef((props, ref) => <Footer innerRef={ref} {...props} />)
