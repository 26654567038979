import React from 'react'

import { Box, ButtonBase, Grid } from '@material-ui/core'
import {
    AssignmentOutlined as TasksIcon,
    ChevronRightRounded as ArrowIcon,
    ContactsOutlined as MembersIcon,
    FastfoodOutlined as MealsIcon,
    HomeOutlined as HomeIcon,
    PollOutlined as PollsIcon,
    Timeline as PointsIcon,
    WhatshotOutlined as RushIcon,
    SchoolOutlined as CoursesIcon,
} from '@material-ui/icons'

import { isMobileDevice } from 'code/Helper'

import { Typography } from 'components/Wrappers'

import Widget from 'components/Widget'

import { useTheme } from '@material-ui/styles'

import { DriveIcon } from 'components/Icons'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import Permissions from 'objects/Permissions'

import NavigationBar from 'components/NavigationBar'

import useStyles from './styles'

const Applications = props => {
    const theme = useTheme()
    const classes = useStyles()

    let { user } = React.useContext(AuthContext)
    let { chapter } = React.useContext(ChapterContext)

    function isInDevMode() {
        return '_self' in React.createElement('div')
    }

    const [isDevENV] = React.useState(isInDevMode())

    const hasPermission = permission => {
        if (user && chapter) {
            let uid = user.getId()
            let permissions = chapter.perms
            if (uid && permissions && permission && chapter.members[uid]) {
                let userRole = chapter.members[uid].role
                if (userRole) {
                    if (permission === 'ADMIN') {
                        return userRole === 'ADMIN'
                    }
                    return userRole === 'ADMIN' || (Array.isArray(permissions[permission]) && permissions[permission].includes(userRole))
                }
            }
        }
        return false
    }

    // Render Icon + name (plus arrow to the right?) with a line above if not the first one
    const apps = [
        {
            id: 'courses',
            icon: <CoursesIcon style={{ fontSize: '1.5rem' }} />,
            name: 'Courses',
            color: 'green',
            link: '/app/applications/courses/',
            shouldHide: true,
        },
        {
            id: 'drive',
            icon: <DriveIcon style={{ fontSize: '1.5rem' }} />,
            name: 'Drive',
            color: 'blue',
            link: '/app/applications/drive/',
        },
        {
            id: 'meals',
            icon: <MealsIcon style={{ fontSize: '1.5rem' }} />,
            name: 'Meals',
            color: 'red',
            link: '/app/applications/meals/',
        },
        {
            id: 'members',
            icon: <MembersIcon style={{ fontSize: '1.5rem' }} />,
            name: 'Members',
            color: 'orange',
            link: '/app/applications/members/',
        },
        {
            id: 'points',
            icon: <PointsIcon style={{ fontSize: '1.5rem' }} />,
            name: 'Points',
            color: 'green',
            link: '/app/applications/points/',
        },
        {
            id: 'polls',
            icon: <PollsIcon style={{ fontSize: '1.5rem' }} />,
            name: 'Polls',
            color: 'purple',
            link: '/app/applications/polls/',
            permission: Permissions.POLL_CREATE,
        },
        {
            id: 'rush',
            icon: <RushIcon style={{ fontSize: '1.5rem' }} />,
            name: 'Rush',
            color: 'yellow',
            link: '/app/applications/rush/',
            permission: Permissions.RUSH_VIEW,
        },
        {
            id: 'tasks',
            icon: <TasksIcon style={{ fontSize: '1.5rem' }} />,
            name: 'Tasks',
            /*color: 'darkorange',*/
            color: theme.palette && theme.palette.type === 'dark' ? 'gray' : 'bluegray',
            link: '/app/applications/tasks/',
        },
        /*{
            id: 'newsletters',
            icon: <NewsletterIcon style={{ fontSize: '1.5rem' }} />,
            name: 'Newsletters',
            color: theme.palette && theme.palette.type === 'dark' ? 'gray' : 'bluegray',
            link: '/app/applications/newsletters/',
            permision: Permissions.NEWSLETTER_ADMIN,
        },*/
    ]
        .sort((a, b) => a.id.localeCompare(b.id))
        .filter(a => !a.shouldHide || isDevENV)
        .filter(a => !a.permission || hasPermission(a.permission))
        .filter(a => !chapter || !chapter.enabledFeatures || chapter.enabledFeatures[a.id] !== false)

    const renderApps = () => (
        <>
            {apps.map((app, index) => (
                <Box key={app.id} style={{ position: 'relative', display: 'flex' }}>
                    {index > 0 && <Box className={classes.appSeparator} />}
                    <ButtonBase
                        onClick={() => props.history.push(app.link)}
                        style={{
                            textAlign: 'inherit',
                            display: 'flex',
                            borderRadius: 4,
                            width: '100%',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            flexGrow: 1,
                            gap: 8,
                            padding: 12,
                        }}
                    >
                        <Box style={{ color: theme.palette[app.color].main, display: 'flex' }}>{app.icon}</Box>
                        <Typography
                            component="p"
                            style={{
                                fontWeight: 600,
                                fontSize: '1.5rem',
                                lineHeight: 1,
                                flexGrow: 1,
                            }}
                            color={app.color}
                        >
                            {app.name}
                        </Typography>
                        <Box style={{ display: 'flex' }}>
                            <ArrowIcon />
                        </Box>
                    </ButtonBase>
                </Box>
            ))}
        </>
    )

    return (
        <Grid container spacing={2}>
            <NavigationBar
                titles={[
                    {
                        name: 'My House',
                        link: '/app/dashboard/',
                        icon: <HomeIcon />,
                    },
                    {
                        name: 'Applications',
                    },
                ]}
                grid
            />
            <Grid item xs={12}>
                {isMobileDevice() ? (
                    renderApps()
                ) : (
                    <Widget disableWidgetMenu inheritHeight noBodyPadding>
                        {renderApps()}
                    </Widget>
                )}
            </Grid>
        </Grid>
    )
}

export default React.memo(Applications)
