import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    icon: {
        fontSize: theme.spacing(2),
    },
    divider: {
        margin: `0px -${theme.spacing(2)}px`,
    },
    wrapper: {
        '& > *': { paddingTop: theme.spacing(5) },
        '& > :first-child': {
            paddingTop: 0,
        },
    },
    navPadding: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
    },
    addCommentBox: {
        marginTop: 0,
    },
    loadingRushee: {
        margin: `0 auto`,
    },
    commentProgress: {
        paddingLeft: 1,
        paddingRight: 1,
        display: `flex`,
    },
    commentsSection: { padding: '4px 8px' },
    progressSection: {
        marginBottom: theme.spacing(1),
    },
    progressTitle: {
        marginBottom: theme.spacing(2),
    },
    deleteButton: {
        paddingLeft: `8px !important`,
        paddingRight: `8px !important`,
    },
    progress: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.gray.main,
    },
    progressBar: {
        backgroundColor: theme.palette.primary.main,
    },
    chip: {
        margin: 4,
    },
}))
