import { Box } from '@material-ui/core'
import React from 'react'

import CalendarDate from 'components/Calendar/CalendarDate'
import CalendarHeader from 'components/Calendar/CalendarHeader'

import moment from 'moment'

import { getEventMoment, getFirebaseEndDate } from 'code/TimeAgo'

// styles
import useStyles from './styles'

const Calendar = ({ events, date, setDate, maxPastMonths, maxFutureMonths }) => {
    const classes = useStyles()

    function weekDays(strDate) {
        const date = typeof strDate === 'string' ? moment(strDate) : strDate
        let month = date.month()
        let year = date.year()

        const lastDayOfMonth = moment()
            .date(0)
            .month(month)
            .year(year)

        let array = Array(lastDayOfMonth.date())
            .fill(0)
            .map((_, i) =>
                moment()
                    .year(year)
                    .month(month)
                    .date(i + 1),
            )
            .map(day => ({ day, week: day.week() }))

        let array2 = array
            .filter(({ week }, i, arr) => arr.findIndex(info => info.week === week) === i)
            .map(({ day, week }) => ({
                week,
                days: Array(7)
                    .fill(0)
                    .map((_, i) =>
                        moment(day)
                            .week(week)
                            .startOf('week')
                            .add(i, 'day'),
                    ),
            }))

        return array2
    }

    const getFilteredEvents = (weeks, events) => {
        if (!Array.isArray(events) || !Array.isArray(weeks) || events.length === 0 || weeks.length === 0) {
            return []
        }

        let firstDate = moment(weeks[0].days[0])
        let lastDate = weeks[weeks.length - 1]
        lastDate = moment(lastDate.days[lastDate.days.length - 1]).endOf('day')

        let newEvents = []
        for (let i = 0; i < events.length; i++) {
            let e = events[i]
            let eMoment = getEventMoment(e)
            let eMomentEnd = moment(getFirebaseEndDate(e))
            if (
                eMomentEnd.isSame(firstDate, 'day') ||
                eMoment.isSame(firstDate, 'day') ||
                eMomentEnd.isSame(lastDate, 'day') ||
                eMoment.isSame(lastDate, 'day') ||
                (eMoment.isBefore(lastDate) && eMomentEnd.isAfter(firstDate))
            ) {
                newEvents.push(e)
            }
        }

        return newEvents
    }

    let curDay = moment()

    let weeks = React.useCallback(weekDays(date), [date])
    let filteredEvents = React.useCallback(getFilteredEvents(weeks, events), [date, events])

    let monthsOffset = moment(curDay)
        .startOf('month')
        .diff(moment(date).startOf('month'), 'months')

    let canGoPrevious = !(typeof maxPastMonths === 'number' ? monthsOffset >= maxPastMonths : false)
    let canGoNext = !(typeof maxFutureMonths === 'number' ? monthsOffset <= -1 * maxFutureMonths : false)

    return (
        <Box className={classes.calendarParent}>
            <CalendarHeader
                date={date.format('MMMM YYYY')}
                canGoNext={canGoNext ? 'true' : 'false'}
                canGoPrevious={canGoPrevious ? 'true' : 'false'}
                onGoNext={() => setDate(moment(date.add('month', 1)))}
                onGoPrevious={() => setDate(moment(date.subtract('month', 1)))}
            />
            {weeks.map(week => (
                <Box display="flex" key={`Calendar.Week.${week.week}`}>
                    {week.days.map(day => (
                        <CalendarDate
                            key={`Calendar.Date.${day.date()}`}
                            date={day.date()}
                            day={day.format('YYYY-MM-DD')}
                            setDate={setDate}
                            isSameWeek={curDay.isSame(day, 'week')}
                            isSameMonth={date.isSame(day, 'month')}
                            isSelected={date.isSame(day, 'day')}
                            isToday={curDay.isSame(day, 'day')}
                            events={filteredEvents}
                        />
                    ))}
                </Box>
            ))}
        </Box>
    )
}

export default React.memo(Calendar)
