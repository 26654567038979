import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    sectionWidget: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sectionWidgetBody: {
        '&:hover': {
            background: 'rgba(150, 150, 150, 0.16)',
            cursor: 'pointer',
        },
    },
    sectionWidgetBodyComplex: {
        '&:hover': {
            background: 'rgba(150, 150, 150, 0.16)',
            cursor: 'pointer',
        },
        marginBlockStart: '-8px',
        marginBlockEnd: '-6px',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    sectionInner: {
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 6,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    sectionWidgetBodyInnerComplex: {
        marginTop: 8,
        marginBottom: 6,
    },
    button: {
        display: 'block',
        color: theme.palette.text.primary,
        fontSize: 'unset',
        justifyContent: 'unset',
        width: '100%',
        textAlign: 'unset',
    },
    divider: {
        margin: `0px -${theme.spacing(3)}px`,
    },
    outlined: {
        border: theme.border,
        borderRadius: 4,
    },
}))
