import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    divider: {
        borderTop: theme.border,
    },
    navPadding: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
        paddingLeft: 12,
        paddingRight: 12,
    },
    navTitle: {
        paddingBottom: 4,
        paddingTop: 3,
    },
    navButtonIcon: {
        padding: '0 !important',
        margin: 6,
    },
    tabSection: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `0px !important`,
        paddingLeft: 0,
        paddingRight: 0,
        overflow: 'auto',
        borderRadius: 4,
    },
    noScrollbar: {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}))
