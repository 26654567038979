import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    dialog: {
        height: '100%',
        overflow: 'scroll',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        borderBottom: 'none',
        position: 'relative',
        fontSize: '1rem',
        borderTop: 'none',
    },
    drawer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        fontSize: '1rem',
        padding: '1rem',
        paddingBottom: 'calc(1rem + env(safe-area-inset-bottom))',
    },
    icon: { fontSize: '1.15em', lineHeight: 1, width: '1em', height: '1em', verticalAlign: 'text-bottom' },
    iconBox: { display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: '2em', marginBottom: '0.25em' },
    nonOverflowText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '100%',
    },
}))
