import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core'
import { ArrowBack as BackIcon, Close as CloseIcon, MoreVert as ExpandIcon } from '@material-ui/icons'
import React from 'react'

import { MessagesContext } from 'context/MessagesContext'

import useStyles from '../../styles'

const ChatMobileTitleBar = ({ conversationId, onClose, showInfo, toggleShowInfo }) => {
    const classes = useStyles()

    const { getConversationName, getConversation } = React.useContext(MessagesContext)

    const conversation = getConversation(conversationId)

    return (
        <AppBar
            className={classes.mobileAppBarTop}
            position="fixed"
            elevation="0"
            style={
                conversation && conversation.avatar && conversation.avatar.type === 0 && conversation.avatar.background
                    ? { backgroundColor: conversation.avatar.background }
                    : null
            }
        >
            <Toolbar>
                {showInfo ? (
                    <>
                        <IconButton edge="start" className={classes.mobileMenuButton} aria-label="close" onClick={toggleShowInfo} color="inherit">
                            <BackIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.mobileTitle} align="center">
                            Info
                        </Typography>
                        {/*<IconButton edge="end" aria-label="more" onClick={() => console.log('todo')} color="inherit">
                            <EditIcon />
                        </IconButton>*/}
                    </>
                ) : (
                    <>
                        <IconButton edge="start" className={classes.mobileMenuButton} aria-label="close" onClick={onClose} color="inherit">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.mobileTitle} align="center">
                            {getConversationName(conversationId)}
                        </Typography>
                        <IconButton edge="end" aria-label="more" onClick={toggleShowInfo} color="inherit">
                            <ExpandIcon />
                        </IconButton>
                    </>
                )}
            </Toolbar>
        </AppBar>
    )
}

export default React.memo(ChatMobileTitleBar)
