import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    appIcon: {
        height: 120,
        borderRadius: 30,
        boxShadow: '0 20px 30px rgba(30, 136, 229, 0.13)',
    },
    backgroundPaper: { backgroundColor: theme.palette.background.paper },
    chapterButton: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#3493e7',
        textTransform: 'none',
        margin: '0 auto',
        backgroundColor: 'white',
        padding: '10.5px 16px',
        borderRadius: 21,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.25)',
        transform: 'translateY(-4px)',
        transition: 'all 0.0625s ease-out',
        '&:active': {
            transform: 'translateY(0)',
            boxShadow: '0 0 6px rgba(0, 0, 0, 0.25)',
        },
    },
    createChapterBox: {
        position: 'absolute',
        bottom: -16,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.paper,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
    },
    stepper: {
        backgroundColor: 'transparent',
        '& .MuiMobileStepper-progress': {
            borderRadius: 2,
            overflow: 'hidden',
            '& .MuiLinearProgress-bar': {
                borderRadius: 2,
            },
        },
        paddingBottom: `calc(8px + env(safe-area-inset-bottom))`,
    },
    viewsBox: {
        flexGrow: 1,
        overflow: 'hidden',
        '& > div': {
            '& > div': {
                height: '100%',
                '& > div': {
                    overflow: 'visible !important',
                },
            },
            height: '100%',
        },
        /*'& div': {
            height: '100%',
        },*/
    },
    enabledFeatures: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        overflowY: 'scroll',
        overflowX: 'hidden',
        margin: '-16px 0',
        paddingTop: 16,
        paddingBottom: 16,
        '&::-webkit-scrollbar': {
            '-webkit-appearance': 'none',
            display: 'none',
        },
    },
    tableContainer: {
        border: `1px solid ${theme.palette.text.hint}`,
        borderRadius: 4,
    },
    scrollableContainer: {
        height: '100%',
        paddingTop: 16,
        paddingBottom: 16,
    },
    scrollableBox: { width: '100%', display: 'flex', flexDirection: 'column', gap: 16, height: '100%' },
    videoButton: {
        borderRadius: 32,
        boxShadow: `0 12px 16px rgba(0, 0, 0, 0.25)`,
        border: '6px solid white',
        transition: 'all 0.125s ease-out',
        '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: `0 20px 16px rgba(0, 0, 0, 0.25)`,
        },
        '& .MuiTouchRippleRoot': {
            borderRadius: 26,
        },
    },
    /* Prefilled Email*/
    accountDisplayBox: {
        border: `1px solid ${theme.palette.text.hint}`,
        borderRadius: `calc(14px + ${theme.spacing(0.5)}px)`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '100%',
    },
    accountDisplay: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontWeight: '500 !important',
        padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
}))
