import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    animateRotation: {
        transition: 'all .25s',
    },
    applicationIcon: {
        fontSize: '2rem',
        height: '2rem',
        margin: '0.5rem',
        width: '2rem',
    },
    applicationIconLarge: {
        fontSize: '4rem',
        height: '4rem',
        margin: '0.5rem',
        width: '4rem',
    },
    box: {
        alignItems: 'center',
        border: theme.border,
        borderRadius: 4,
        gap: '0.5rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '0.5rem',
        position: 'relative',
    },
    boxLarge: {
        border: theme.border,
        borderRadius: 4,
        gap: '0.5rem',
        padding: '0.5rem',
        position: 'relative',
        '& .MuiButton-label': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    button: {
        width: '100%',
        textTransform: 'none',
    },
    buttonSelected: {
        border: `3px solid ${theme.palette.primary.main}`,
    },
    overflowWrap: {
        overflowWrap: 'anywhere',
    },
    sectionBox: {
        borderTop: theme.border,
        width: '100%',
        padding: '0.5rem',
    },
    titleBox: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    title: { fontWeight: 500, lineHeight: 1.125 },
    subtitle: { lineHeight: 1 },
}))
