import React from 'react'

import { DialogContentText } from '@material-ui/core'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Wrappers'

const DeleteMemberDialog = ({ open, name, type, onClose, onConfirmDelete }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete {type}?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the {type === 'member' ? 'account' : type} for <span style={{ fontWeight: 700 }}>{name}</span>?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onConfirmDelete} color="red">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteMemberDialog
