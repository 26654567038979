export const subscriptionOptions = [
    {
        id: 'price_1LALc1ENhaZFjt1VFhRtyiVV',
        maxMembers: '20',
        label: '< 20',
        unit_amount: 6000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALcRENhaZFjt1VzUSoFwRA',
        maxMembers: '30',
        label: '20 - 30',
        unit_amount: 9000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALclENhaZFjt1VePYe2FOP',
        maxMembers: '40',
        label: '30 - 40',
        unit_amount: 12000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALd8ENhaZFjt1VccWcKSox',
        maxMembers: '50',
        label: '40 - 50',
        unit_amount: 15000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALdOENhaZFjt1VKpIsxQkS',
        maxMembers: '60',
        label: '50 - 60',
        unit_amount: 18000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALdoENhaZFjt1VIEvt7twe',
        maxMembers: '80',
        label: '60 - 80',
        unit_amount: 24000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALeEENhaZFjt1VUoiilhVT',
        maxMembers: '100',
        label: '80 - 100',
        unit_amount: 30000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALeTENhaZFjt1VTuFYM9ab',
        maxMembers: '120',
        label: '100 - 120',
        unit_amount: 36000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALfHENhaZFjt1VfBxP51gq',
        maxMembers: '140',
        label: '120 - 140',
        unit_amount: 42000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALfVENhaZFjt1VSRgTc1v0',
        maxMembers: '160',
        label: '140 - 160',
        unit_amount: 48000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALfkENhaZFjt1VuQTxUphV',
        maxMembers: '180',
        label: '160 - 180',
        unit_amount: 54000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALg3ENhaZFjt1V5Syejd0k',
        maxMembers: '200',
        label: '180 - 200',
        unit_amount: 60000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALggENhaZFjt1VohEhr7g1',
        maxMembers: '250',
        label: '200 - 250',
        unit_amount: 75000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALhBENhaZFjt1VgK9FcFnG',
        maxMembers: '300',
        label: '250 - 300',
        unit_amount: 90000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
    {
        id: 'price_1LALhSENhaZFjt1VVa3Uo7QN',
        maxMembers: 'unlimited',
        label: '> 300',
        unit_amount: 120000,
        recurring: {
            aggregate_usage: null,
            interval: 'month',
            interval_count: 6,
            trial_period_days: null,
            usage_type: 'licensed',
        },
    },
]

export const convertSubscriptionOptionsToObject = optionsArr => {
    let obj = {}

    let prevOption = null

    for (let i = 0; i < optionsArr.length; i++) {
        let option = { ...optionsArr[i] }

        const optionId = option.id

        delete option.id

        if (prevOption !== null) {
            option.prev = prevOption
        }

        if (i < optionsArr.length - 1) {
            option.next = optionsArr[i + 1].id
        }

        obj[optionId] = option

        prevOption = optionId
    }

    return obj
}
