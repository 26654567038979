import React from 'react'

const HeaderContext = React.createContext()

const HeaderProvider = ({ children }) => {
    const [icons, setIcons] = React.useState([])

    return (
        <HeaderContext.Provider
            value={{
                icons,
                setIcons,
            }}
        >
            {children}
        </HeaderContext.Provider>
    )
}

export { HeaderContext, HeaderProvider }
