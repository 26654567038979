import { Box, Typography } from '@material-ui/core'
import { PollOutlined as PollsIcon } from '@material-ui/icons'
import React from 'react'

import { animated, useSpring } from '@react-spring/web'

import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'

import cn from 'classnames'

import _ from 'lodash'

import useStyles from './styles'

const PollResults = ({ options, rotation, title, values }) => {
    const classes = useStyles()

    const counts = _.countBy(values)

    const optionsArr = Object.keys(options)

    const sortedOptionsArr = optionsArr.sort((a, b) => (counts[b] ? counts[b] : 0) - (counts[a] ? counts[a] : 0))

    const getPieData = () => {
        let arr = []

        for (let i = 0; i < optionsArr.length; i++) {
            let optionId = optionsArr[i]
            let option = options[optionId]
            arr.push({
                id: optionId,
                color: option.color,
                value: counts[optionId] ? counts[optionId] : 0,
            })
        }

        return arr
    }

    const pieData = getPieData()

    return (
        <Box className={cn(classes.parentBox /*, animatedClasses.boxSway*/)} style={{ transform: `rotate(${rotation}deg)` }}>
            <Box className={classes.titleBox}>
                <Box className={classes.titleIconBox}>
                    <PollsIcon className={classes.titleIcon} />
                </Box>
                <Box className={classes.titleTextBox}>
                    <Typography className={classes.titleText}>{title}</Typography>
                </Box>
            </Box>
            <PollVoteLegend options={options} optionsArr={sortedOptionsArr} counts={counts} numVotes={values.length} />
            <Box className={classes.voteCountBox}>
                <Typography align="center" className={classes.voteCountText}>
                    {values.length} votes
                </Typography>
            </Box>
            <Box className={classes.voteResults}>
                <Box className={classes.voteChart}>
                    <ResponsiveContainer width={96} height={96}>
                        <PieChart>
                            <Pie
                                data={pieData}
                                innerRadius={0}
                                outerRadius={48}
                                dataKey="value"
                                animationDuration={250}
                                animationBegin={0}
                                animationEasing="ease-in-out"
                            >
                                {pieData.map(entry => (
                                    <Cell key={`cell-${entry.id}`} fill={entry.color} stroke={''} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </Box>
                <Box className={classes.voteLegend}>
                    {optionsArr.map(option => (
                        <PollVoteKey key={option} label={options[option].label.split(' ')[0]} color={options[option].color} />
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

const PollVoteLegend = ({ options, optionsArr, counts, numVotes, ...props }) => {
    const classes = useStyles()

    return (
        <Box className={classes.votesBox}>
            {optionsArr.map((option, index) => (
                <PollVotePercentage
                    offset={index * 20}
                    key={option}
                    label={options[option].label}
                    percent={counts[option] > 0 ? (100 * counts[option]) / numVotes : 0}
                />
            ))}
        </Box>
    )
}

const PollVoteKey = ({ label, color, ...props }) => {
    const classes = useStyles()

    return (
        <Box className={classes.voteKeyBox}>
            <Box className={classes.voteKeyColor} style={{ backgroundColor: color }} />
            <Typography align="center" className={classes.voteKeyLabel}>
                {label}
            </Typography>
        </Box>
    )
}

const PollVotePercentage = ({ label, offset, percent, ...props }) => {
    const classes = useStyles()

    const prevTransform = React.useRef(`translate(0, ${offset}px)`)
    const prevPercent = React.useRef(percent)

    const { percentage, transform } = useSpring({
        from: { percentage: prevPercent.current, offsetHeight: prevTransform.current },
        percentage: percent,
        transform: `translate(0, ${offset}px)`,
        onChange: props => {
            prevPercent.current = props.percentage
            prevTransform.current = props.transform
        },
    })

    return (
        <animated.div style={{ position: 'absolute', left: 0, right: 0, transform: transform }}>
            <Box className={classes.votePercentageBox}>
                <Typography className={classes.votePercentageLabel}>{label}</Typography>
                <Typography className={classes.votePercentage}>
                    <animated.span>{percentage.interpolate(val => Math.floor(val))}</animated.span>%
                </Typography>
            </Box>
        </animated.div>
    )
}

export default React.memo(PollResults)
