import React from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'

import {
    AccessTime as TimeIcon,
    HomeOutlined as HomeIcon,
    SchoolOutlined as CoursesIcon,
    Add as AddIcon,
    SettingsOutlined as SettingsIcon,
    Link as LinkIcon,
    VisibilityOutlined as PreviewIcon,
    MoreVert as MoreIcon,
} from '@material-ui/icons'

import NavigationBar from 'components/NavigationBar'
import Paper from 'components/Paper'

import { FileDownloadIcon, AwardIcon, HourglassIcon } from 'components/Icons'

import { capitalizeFirstLetter } from 'code/Helper'
import { timeAgo } from 'code/TimeAgo'

import moment from 'moment'

import useStyles from '../styles'

export default function ViewCourse(props) {
    const classes = useStyles()

    const courseId = 'abc123'

    const users = [
        {
            id: 'abc123',
            name: 'Daniel Baker',
            status: 'passed',
            score: '85%',
            lu: moment()
                .subtract(7, 'd')
                .toDate(),
            progress: 1,
            tt: '3h 45m',
            chapter: 'Chapter Name',
        },
        {
            id: 'ghi789',
            name: 'Drake Tao',
            status: 'incomplete',
            score: '92%',
            progress: 0.63,
            tt: '1h 22m',
            lu: moment().toDate(),
            chapter: 'Chapter Name',
        },
    ]

    const versions = [
        {
            id: 'def456',
            name: '1.2',
            description: '2024 rendition',
            status: 'active',
            type: 'SCORM 2004',
        },
        {
            id: 'abc123',
            name: '1.0',
            description: 'Initial version',
            status: 'inactive',
            type: 'SCORM 1.2',
        },
    ]

    function renderNavBar(isGrid) {
        return (
            <NavigationBar
                titles={[
                    {
                        name: 'My House',
                        link: '/app/dashboard/',
                        icon: <HomeIcon />,
                    },
                    {
                        name: 'Courses',
                        link: '/app/applications/courses',
                        icon: <CoursesIcon />,
                        iconMobileOnly: true,
                    },
                    {
                        name: 'View',
                    },
                ]}
                rightButtons={[
                    {
                        name: 'Settings',
                        type: 'icon',
                        innerIcon: <SettingsIcon />,
                        onClick: () => {},
                    },
                ]}
                grid={isGrid}
            />
        )
    }

    return (
        <Grid container spacing={2}>
            {renderNavBar(true)}
            <Grid item xs={12} md={4}>
                <Paper>
                    <img
                        src="https://fiskal.kemenkeu.go.id/nda_gcf/media/pages/zw0n-1123-1.jpg"
                        width="100%"
                        style={{ maxHeight: 192, objectFit: 'cover' }}
                        alt=""
                    />
                    <Stack sx={{ p: 2 }} spacing={1}>
                        <Typography variant="h3" fontWeight="bold">
                            Anti Harassment Training
                        </Typography>
                        <Stack direction="row" spacing={1}>
                            <Chip color="primary" variant="outlined" size="small" icon={<PreviewIcon />} onClick={() => {}} label="Preview" />
                            <Chip color="primary" variant="outlined" size="small" icon={<LinkIcon />} onClick={() => {}} label="Copy Link" />
                        </Stack>
                        <Typography>
                            <TimeIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
                            45 minutes
                        </Typography>
                        <Typography>
                            <HourglassIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
                            due April 5
                        </Typography>
                        <Typography>
                            <AwardIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
                            85% or above to pass
                        </Typography>
                        <Typography sx={{ pt: 1 }}>
                            The goal of this course is to lay out clear expectations for brothers, to create a safe working environment for everyone in the
                            chapter, and those who come to events.
                        </Typography>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.table}>
                        <Paper>
                            <Stack sx={{ pt: 2, pb: 1, ml: 2.5, mr: 2.5 }} spacing={1}>
                                <Stack direction="row" spacing={1}>
                                    <Typography sx={{ flexGrow: 1 }} fontWeight="500" fontSize="1.5em">
                                        Versions
                                    </Typography>
                                    <Button variant="outlined" startIcon={<AddIcon />} sx={{ textTransform: 'inherit' }}>
                                        New
                                    </Button>
                                </Stack>
                            </Stack>
                            <DataGrid
                                autoHeight
                                sx={{ border: 'none' }}
                                rows={versions ?? []}
                                loading={versions === null}
                                rowHeight={44}
                                columnHeaderHeight={44}
                                footerHeight={44}
                                columns={[
                                    {
                                        field: 'name',
                                        headerName: 'Name',
                                        minWidth: 125,
                                        renderCell: ({ value }) => (
                                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ml: 1.5 }}>
                                                {value}
                                            </Typography>
                                        ),
                                        valueGetter: ({ value }) => (value ? value : 'Unnamed'),
                                        renderHeader: () => (
                                            <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                                Name
                                            </Typography>
                                        ),
                                    },
                                    {
                                        field: 'description',
                                        headerName: 'Description',
                                        minWidth: 250,
                                        flex: 2,
                                        renderCell: ({ value }) => (
                                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ml: 1.5 }}>
                                                {value}
                                            </Typography>
                                        ),
                                        valueGetter: ({ value }) => (value ? value : ''),
                                        renderHeader: () => (
                                            <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                                Description
                                            </Typography>
                                        ),
                                    },
                                    {
                                        field: 'type',
                                        headerName: 'Type',
                                        type: 'singleSelect',
                                        valueOptions: ['active', 'inactive'],
                                        align: 'center',
                                        headerAlign: 'center',
                                        minWidth: 125,
                                        flex: 1,
                                        renderCell: ({ value }) => (
                                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{value}</Typography>
                                        ),
                                        renderHeader: () => <Typography fontWeight="500">Type</Typography>,
                                    },
                                    {
                                        field: 'status',
                                        headerName: 'Status',
                                        type: 'singleSelect',
                                        valueOptions: ['active', 'inactive'],
                                        align: 'center',
                                        headerAlign: 'center',
                                        minWidth: 125,
                                        flex: 1,
                                        renderCell: ({ value }) => (
                                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                {capitalizeFirstLetter(value).replaceAll('_', ' ')}
                                            </Typography>
                                        ),
                                        renderHeader: () => <Typography fontWeight="500">Status</Typography>,
                                    },
                                    {
                                        field: 'actions',
                                        type: 'actions',
                                        getActions: ({ row }) => [
                                            <GridActionsCellItem icon={<PreviewIcon />} onClick={() => {}} label="Preview" />,
                                            <GridActionsCellItem icon={<MoreIcon />} onClick={() => {}} label="More" />,
                                        ],
                                    },
                                ]}
                                initialState={{
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                pageSizeOptions={[5, 10, 25, 50, 100]}
                                hideFooterSelectedRowCount
                                disableColumnMenu
                                onRowClick={params => {
                                    window.open(`https://storage.googleapis.com/greek-connect-courses/${courseId}/index.html`)
                                    // props.history.push(`/app/applications/courses/${courseId}/preview`)
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} className={classes.table}>
                        <Paper>
                            <Stack sx={{ pt: 2, pb: 1, ml: 2.5, mr: 2.5 }} spacing={1}>
                                <Stack direction="row" spacing={1}>
                                    <Typography sx={{ flexGrow: 1 }} fontWeight="500" fontSize="1.5em">
                                        Results
                                    </Typography>
                                    <Button variant="outlined" startIcon={<FileDownloadIcon />} sx={{ textTransform: 'inherit' }}>
                                        Export
                                    </Button>
                                </Stack>
                                {false && (
                                    <>
                                        <Typography>Quickly show some insights here on pass rates, current completion stats, etc</Typography>
                                        <Typography>Also, let them dive deep into specific questions if possible</Typography>
                                        <Typography>Lastly, explain who has access to the results</Typography>
                                    </>
                                )}
                            </Stack>
                            <DataGrid
                                autoHeight
                                sx={{ border: 'none' }}
                                rows={users ?? []}
                                loading={users === null}
                                rowHeight={44}
                                columnHeaderHeight={44}
                                footerHeight={44}
                                columns={[
                                    {
                                        field: 'name',
                                        headerName: 'Name',
                                        minWidth: 250,
                                        flex: 2,
                                        renderCell: ({ value }) => (
                                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ml: 1.5 }}>
                                                {value}
                                            </Typography>
                                        ),
                                        valueGetter: ({ value }) => (value ? value : 'Unnamed'),
                                        renderHeader: () => (
                                            <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                                Name
                                            </Typography>
                                        ),
                                    },
                                    {
                                        field: 'chapter',
                                        headerName: 'Chapter',
                                        minWidth: 125,
                                        flex: 2,
                                        renderCell: ({ value }) => (
                                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ml: 1.5 }}>
                                                {value}
                                            </Typography>
                                        ),
                                        valueGetter: ({ value }) => value,
                                        renderHeader: () => (
                                            <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                                Chapter
                                            </Typography>
                                        ),
                                    },
                                    {
                                        field: 'lu',
                                        headerName: 'Last Active',
                                        align: 'center',
                                        headerAlign: 'center',
                                        type: 'dateTime',
                                        minWidth: 125,
                                        flex: 1,
                                        renderCell: ({ value }) => (
                                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ml: 1.5 }}>
                                                {value ? timeAgo(value) : ''}
                                            </Typography>
                                        ),
                                        renderHeader: () => (
                                            <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                                Last Active
                                            </Typography>
                                        ),
                                    },
                                    {
                                        field: 'score',
                                        headerName: 'Score',
                                        align: 'center',
                                        headerAlign: 'center',
                                        minWidth: 125,
                                        flex: 1,
                                        renderCell: ({ value }) => (
                                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{value}</Typography>
                                        ),
                                        renderHeader: () => <Typography fontWeight="500">Score</Typography>,
                                    },
                                    {
                                        field: 'status',
                                        headerName: 'Status',
                                        type: 'singleSelect',
                                        valueOptions: ['passed', 'completed', 'failed', 'incomplete', 'browsed', 'not attempted'],
                                        align: 'center',
                                        headerAlign: 'center',
                                        minWidth: 125,
                                        flex: 1,
                                        renderCell: ({ value, row }) =>
                                            value === 'incomplete' ? (
                                                <Box sx={{ width: '100%', maxWidth: 160, borderRadius: '2px', overflow: 'hidden' }}>
                                                    <LinearProgress variant="determinate" value={row.progress * 100} />
                                                </Box>
                                            ) : (
                                                <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                    {capitalizeFirstLetter(value).replaceAll('_', ' ')}
                                                </Typography>
                                            ),
                                        renderHeader: () => <Typography fontWeight="500">Status</Typography>,
                                    },
                                    {
                                        field: 'tt',
                                        headerName: 'Time Spent',
                                        align: 'center',
                                        headerAlign: 'center',
                                        minWidth: 125,
                                        flex: 1,
                                        renderCell: ({ value }) => (
                                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ml: 1.5 }}>
                                                {value}
                                            </Typography>
                                        ),
                                        renderHeader: () => (
                                            <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                                Time Spent
                                            </Typography>
                                        ),
                                    },
                                ]}
                                initialState={{
                                    pagination: { paginationModel: { pageSize: 25 } },
                                }}
                                pageSizeOptions={[5, 10, 25, 50, 100]}
                                hideFooterSelectedRowCount
                                disableColumnMenu
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
