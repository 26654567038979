import React from 'react'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Menu from '@mui/material/Menu'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

const CustomMenu = ({ open, onClose, type, target, children, ...props }) => {
    if (type === 'menu') {
        return (
            <Menu anchorEl={target} open={!!open} onClose={onClose} variant="menu">
                {children}
            </Menu>
        )
    }

    return (
        <Box style={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
            <SwipeableDrawer anchor="bottom" open={open} onClose={onClose} disableSwipeToOpen onOpen={() => {}}>
                <Box
                    style={{
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                        paddingBottom: `env(safe-area-inset-bottom)`,
                        paddingLeft: `env(safe-area-inset-left)`,
                        paddingRight: `env(safe-area-inset-right)`,
                    }}
                >
                    <List>{children}</List>
                </Box>
            </SwipeableDrawer>
        </Box>
    )
}

export default React.memo(CustomMenu)
