import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    iconBox: {
        marginRight: 15,
        height: 48,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    mainBox: {
        flexGrow: 1,
    },
    parentBox: {
        display: 'flex',
        flexDirection: 'row',
    },
    headerBox: {
        display: 'flex',
        flexDirection: 'row',
    },
    parentBoxSmall: {
        display: 'flex',
        flexDirection: 'column',
    },
}))
