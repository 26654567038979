import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    accountDisplayBox: {
        border: `1px solid ${theme.palette.text.hint}`,
        borderRadius: `calc(14px + ${theme.spacing(0.5)}px)`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: '100%',
    },
    accountDisplay: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontWeight: '500 !important',
        padding: `0 ${theme.spacing(2)}px`,
    },
    accountDisplayBack: {
        paddingLeft: theme.spacing(0.5),
    },
    accountDisplayClose: {
        paddingRight: theme.spacing(0.5),
    },
    container: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    logotypeContainer: {
        background: theme.palette.primary.main,
        /* eslint-disable no-dupe-keys */
        background: `linear-gradient(0deg, #0B6ABE 0%, #3493E7 100%)`,
        width: '60%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    logotypeImageDark: {
        height: 120,
        borderRadius: 30,
        boxShadow: '0 20px 30px rgba(30, 136, 229, 0.13)',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    logotypeImage: {
        height: 120,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    logotypeImageLarge: {
        width: 240,
        height: 240,
        position: 'absolute',
        top: 0,
        left: 0,
        /*transition: `all 0.45s ease-in-out`,*/
        transformOrigin: 'center',
    },
    logotypeImageLargeLogo: {
        transform: `translate(calc(30vw - 120px), calc(50vh - 169px))`,
    },
    logotypeImageLargeCalendar: {
        transform: `translate(calc(30vw - 248.5px), -70px) scale(0.35)`,
    },
    logotypeTextLogo: {
        transformOrigin: 'center',
        /*transition: `all 0.75s ease-in-out`,*/
        position: 'absolute',
        top: 0,
        left: 0,
        transform: `translate(calc(30vw - 270.5px), calc(50vh + 71px))`,
    },
    logotypeTextCalendar: {
        transformOrigin: 'center',
        /*transition: `all 0.75s ease-in-out`,*/
        position: 'absolute',
        top: 0,
        left: 0,
        transform: `translate(calc(30vw - 221.5px), 8px) scale(0.5)`,
    },
    logotypeText: {
        color: 'white',
        fontWeight: `600 !important`,
        textShadow: '0 10px 15px rgba(0, 0, 0, 0.13)',
        fontSize: `84px !important`,
        [theme.breakpoints.down('md')]: {
            fontSize: `48px !important`,
            textShadow: '0 5px 7.5px rgba(30, 136, 229, 0.13)',
            color: theme.palette.primary.main,
            marginTop: theme.spacing(1),
            textAlign: 'center',
        },
    },
    marketingTextTitle: {
        color: 'white',
        textShadow: '0 10px 15px rgba(0, 0, 0, 0.13)',
        fontSize: `32px !important`,
        fontWeight: `600 !important`,
    },
    marketingTextSubtitle: {
        color: 'white',
        textShadow: '0 7px 10px rgba(0, 0, 0, 0.13)',
        fontSize: `24px !important`,
        fontWeight: `500 !important`,
    },
    sidebarLogoText: { [theme.breakpoints.up('lg')]: { display: 'none' } },
    formContainer: {
        width: '40%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
        paddingTop: `env(safe-area-inset-top)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
    },
    form: {
        width: 320,
        margin: 'auto',
    },
    tab: {
        fontWeight: 400,
        fontSize: 18,
    },
    greeting: {
        fontSize: '2rem !important',
        fontWeight: '600 !important',
        textAlign: 'center',
    },
    subGreeting: {
        fontWeight: 500,
        textAlign: 'center',
    },
    googleButton: {
        boxShadow: theme.customShadows.widget,
        width: '100%',
        textTransform: 'none',
        backgroundColor: theme.palette.background.paper,
    },
    googleButtonCreating: {
        marginTop: 0,
    },
    googleIcon: {
        width: 30,
        marginRight: theme.spacing(2),
    },
    creatingButtonContainer: {
        height: 46,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    createAccountButton: {
        height: 46,
        textTransform: 'none',
    },
    formDividerContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    formDividerWord: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    formDivider: {
        flexGrow: 1,
        height: 1,
        backgroundColor: theme.palette.text.hint + '40',
    },
    errorMessage: {
        textAlign: 'center',
    },
    textFieldUnderline: {
        '&:before': {
            borderBottomColor: theme.palette.primary.light,
        },
        '&:after': {
            borderBottomColor: theme.palette.primary.main,
        },
        '&:hover:before': {
            borderBottomColor: `${theme.palette.primary.light} !important`,
        },
    },
    textField: {
        marginTop: 0,
        marginBottom: 0,
    },
    textFieldInput: {
        borderBottomColor: theme.palette.background.light,
    },
    formButtons: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    forgetButton: {
        textTransform: 'none',
        fontWeight: 400,
    },
    loginLoader: {
        margin: '0 auto',
        padding: 8,
    },
    copyright: {
        whiteSpace: 'nowrap',
        marginTop: theme.spacing(1),
    },
    terms: {
        whiteSpace: 'nowrap',
        marginTop: theme.spacing(2),
    },
    copyrightBox: {
        marginTop: theme.spacing(4),
    },
    marketingBox: {
        display: 'flex',
        width: '100%',
        height: '100%',
        '@media (max-height:662px)': { display: 'none' },
    },
    marketingLogo: {
        display: 'none',
        '@media (max-height:662px)': { display: 'flex' },
    },
}))
