import React, { useRef } from 'react'
import './DocumentUpload.css'
import { Typography, Link } from '../Wrappers'
import cn from 'classnames'
import useStyles from './styles'

import { CancelRounded as CancelIcon } from '@material-ui/icons'
import { Box, IconButton, Icon } from '@material-ui/core'

export default function DocumentUpload(props) {
    var classes = useStyles()
    const myInput = useRef(null)

    const { multiple, title, fileType, fileTypeText, onUpdateFile, progress, disabled } = props

    let [file, setFile] = React.useState(null)

    function onFilesAdded(event) {
        const files = event.target.files
        const array = fileListToArray(files)
        if (!file && array[0]) {
            addFile(array[0])
        }
    }

    function onDragOver(event) {
        event.preventDefault()
    }

    function onDrop(event) {
        event.preventDefault()
        const files = event.dataTransfer.files
        const array = fileListToArray(files)
        if (!file && array[0]) {
            addFile(array[0])
        }
    }

    function addFile(newFile) {
        if (fileType) {
            if (fileType.includes(newFile.type)) {
                setFile(newFile)
                if (onUpdateFile) {
                    onUpdateFile(newFile)
                }
            }
        } else {
            setFile(newFile)
            if (onUpdateFile) {
                onUpdateFile(newFile)
            }
        }
    }

    function delFile() {
        if (file != null) {
            setFile(null)
            if (myInput.current) {
                myInput.current.value = null
            }
            if (onUpdateFile) {
                onUpdateFile(null)
            }
        }
    }

    function fileListToArray(list) {
        const array = []
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }

    function openFileDialog() {
        if (myInput.current) {
            myInput.current.click()
        }
    }

    function getTitle() {
        if (file) {
            return file.name
        }

        if (title) {
            return title
        }

        let text = 'Drag File'

        if (multiple) {
            text = 'Drag File(s)'
        }

        if (fileTypeText) {
            return text + ' or Click to Upload (' + fileTypeText + ')'
        }
        return text + ' or Click to Upload'
    }

    function getIconType() {
        if (file) {
            if (file.type.includes('image')) {
                return 'image'
            }

            if (file.type.includes('word')) {
                return 'word'
            }

            if (file.type.includes('presentation') || file.type.includes('powerpoint')) {
                return 'powerpoint'
            }

            if (file.type.includes('pdf')) {
                return 'pdf'
            }

            if (file.type.includes('video')) {
                return 'video'
            }

            if (file.type.includes('zip')) {
                return 'archive'
            }

            if (file.type.includes('csv') || file.type.includes('excel')) {
                return 'excel'
            }

            if (file.type.includes('audio')) {
                return 'audio'
            }

            return 'alt'
        }

        return 'upload'
    }

    function getIconURL(icon) {
        if (icon === 'upload') {
            return 'fas fa-file-upload'
        }

        return 'far fa-file-' + icon
    }

    //onClick, icon,
    if (progress) {
        return (
            <div
                className={cn(classes.outline, {
                    button: !file,
                    disabled: disabled,
                })}
                onDrop={event => onDrop(event)}
                onDragOver={event => onDragOver(event)}
                style={{
                    background: `linear-gradient(to left, #00000000 ` + (100 - progress) + `%, #dadce0 ` + (100 - progress) + `%)`,
                }}
            >
                <Link
                    underline="none"
                    onClick={() => {
                        if (!file) {
                            openFileDialog()
                        }
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <input
                            type="file"
                            multiple={multiple}
                            onChange={event => onFilesAdded(event)}
                            style={{ display: 'none' }}
                            ref={myInput}
                            accept={fileType}
                        />
                        <Icon className={getIconURL(getIconType())} fontSize={'medium'} style={{ marginLeft: 12, marginRight: 2 }} />
                        <Typography className={'typography'} noWrap>
                            {getTitle()}
                        </Typography>
                        {file && progress >= 100 && (
                            <IconButton
                                className={'closemedium'}
                                onClick={() => {
                                    delFile()
                                }}
                            >
                                <CancelIcon fontSize={'medium'} />
                            </IconButton>
                        )}
                    </Box>
                </Link>
            </div>
        )
    }

    return (
        <div
            className={cn(classes.outline, {
                [classes.button]: !file,
                [classes.selected]: file,
                disabled: disabled,
            })}
            onDrop={event => onDrop(event)}
            onDragOver={event => onDragOver(event)}
        >
            <Link
                underline="none"
                onClick={() => {
                    if (!file) {
                        openFileDialog()
                    }
                }}
            >
                <Box display="flex" alignItems="center">
                    <input
                        type="file"
                        multiple={multiple}
                        onChange={event => onFilesAdded(event)}
                        style={{ display: 'none' }}
                        ref={myInput}
                        accept={fileType}
                    />
                    <Icon className={getIconURL(getIconType())} fontSize="medium" style={{ marginLeft: 12, marginRight: 2 }} />
                    <Typography className={'typography'} noWrap>
                        {getTitle()}
                    </Typography>
                    {file && (
                        <IconButton
                            className={'closemedium'}
                            onClick={() => {
                                delFile()
                            }}
                        >
                            <CancelIcon fontSize="medium" />
                        </IconButton>
                    )}
                </Box>
            </Link>
        </div>
    )
}
