import DateFnsUtils from '@date-io/date-fns'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, Link, TextField } from '@material-ui/core'
import {
    CheckCircleOutlineOutlined as ApproveIcon,
    PersonOutlineOutlined as AuthorIcon,
    CancelOutlined as CancelOutlinedIcon,
    Subject as DescriptionIcon,
    CloudDownloadOutlined as DownloadIcon,
    CreateOutlined as EditIcon,
    CreateOutlined as EditOutline,
    HomeOutlined as HomeIcon,
    InsertPhoto as ImageIcon,
    Link as LinkIcon,
    LocationOnOutlined as LocationIcon,
    Timeline as PointsIcon,
    RemoveCircleOutline as RemoveIcon,
    VisibilityOutlined as VisibilityIcon,
} from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DocumentUploadLarge from 'components/DocumentUpload/DocumentUploadLarge'
import React from 'react'

import Papa from 'papaparse'

import { Points } from 'objects/Points'
import { PointRecord } from 'objects/Points/Record'

import MuiLinkify from 'material-ui-linkify'

import { Capacitor } from '@capacitor/core'

import _ from 'lodash'

import moment from 'moment'

import FullScreenImage from 'components/FullScreenImage'
import NavigationBar from 'components/NavigationBar'
import ParentTable from 'components/Tables/ParentTable'
import Table from 'components/Tables/Table'
import { Link as LinkHeader, Progress as ProgressHeader, Status as StatusHeader } from 'components/Tables/TableHeader'
import ErrorTypography from 'components/Typography/ErrorTypography'
import Widget from 'components/Widget'
import { Button, Typography } from 'components/Wrappers'

import Map from 'components/Map'
import { StagnantCircle as MapCircle } from 'components/Map/Circle'
import { StagnantMarker as MapMarker } from 'components/Map/Marker'
import MapUserMarker from 'components/Map/Marker/UserMarker'
import MapRef from 'components/Map/Ref'

import LocationOptions from '../components/LocationOptions'
import LocationTrackingDialog from '../components/LocationTrackingDialog'
import RequestButton from '../components/RequestButton'

import { convertObjectToList, getOrdinalSuffix, isMobileDevice } from 'code/Helper'
import { getFirebaseDate } from 'code/TimeAgo'

import app from 'firebase/app'
import useStyles from '../styles'

import { renderVisibility } from 'data/Visibility'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { LocationContext } from 'context/LocationContext'

export default function ViewPoints(props) {
    var classes = useStyles()

    const pointsId = props.match.params.id

    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)
    const { mapkit, getCurrentLocation } = React.useContext(LocationContext)

    const [clearPointsDialog, setClearPointsDialog] = React.useState({ open: false })
    const [approveOpenRequestsDialog, setApproveOpenRequestsDialog] = React.useState({ open: false })

    const [isLoading, setIsLoading] = React.useState(false)
    const [hasGrabbed, setHasGrabbed] = React.useState(false)

    const [viewAttachment, setViewAttachment] = React.useState(false)

    const [points, setPoints] = React.useState(Points.getNew())
    const [compiledMembers, setCompiledMembers] = React.useState([])

    const [pointsRequest, setPointsRequest] = React.useState(null)

    const [locationTracking, setLocationTracking] = React.useState(false)
    const [hasActiveLocationTrackingRequest, setHasActiveLocationTrackingRequest] = React.useState(false)

    const userLocation = React.useRef()
    const mapRef = React.useRef()

    const canViewPersonalPoints =
        points &&
        user.getId() in chapter.members &&
        ((!points.mType && points.vis && points.vis.includes(chapter.members[user.getId()].status)) ||
            (points.mType === 1 && Array.isArray(points.mems) && points.mems.includes(user.getId())))

    function hasPointsAdminIgnoringSupervisorInner() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    (Array.isArray(chapter.perms['pointsAdmin']) && chapter.perms['pointsAdmin'].includes(mem.role)) ||
                    (Array.isArray(chapter.perms['pointsCreate']) &&
                        chapter.perms['pointsCreate'].includes(mem.role) &&
                        points &&
                        points.author &&
                        user.getId() === points.author.id)
                )
            }
        }

        return false
    }

    function hasPointsAdminInner() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    (Array.isArray(chapter.perms['pointsAdmin']) && chapter.perms['pointsAdmin'].includes(mem.role)) ||
                    (Array.isArray(chapter.perms['pointsCreate']) &&
                        chapter.perms['pointsCreate'].includes(mem.role) &&
                        points &&
                        points.author &&
                        user.getId() === points.author.id) ||
                    (Array.isArray(chapter.perms['pointsCreate']) &&
                        chapter.perms['pointsCreate'].includes(mem.role) &&
                        points &&
                        Array.isArray(points.admins) &&
                        points.admins.includes(user.getId()))
                )
            }
        }

        return false
    }

    const hasPointsAdmin = hasPointsAdminInner()
    const hasPointsAdminIgnoringSupervisor = hasPointsAdminIgnoringSupervisorInner()

    if (!hasGrabbed && !isLoading) {
        setIsLoading(true)
        if (!hasPointsAdmin) {
            grabPointSystem()
        }
    }

    function getPointsRef() {
        const db = app.firestore()
        return db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('points')
            .doc(pointsId)
    }

    React.useEffect(() => {
        if (points && points.allowLocationTrackingRequests && user) {
            const ref = app.database().ref(`chapters/${user.getChapter()}/points/${pointsId}/locationTracking/${user.getId()}/status`)

            ref.on('value', snapshot => {
                if (snapshot.exists()) {
                    const run = async () => {
                        const data = await snapshot.val()

                        setHasActiveLocationTrackingRequest(data)
                    }
                    run()
                } else {
                    setHasActiveLocationTrackingRequest(false)
                }
            })

            return () => {
                // Get rid of the ref
                ref.off('value')
            }
        }
    }, [points])

    React.useEffect(() => {
        if (hasPointsAdmin) {
            const unsubscribe = getPointsRef().onSnapshot(
                function(doc) {
                    if (doc.exists) {
                        setIsLoading(false)
                        setHasGrabbed(true)
                        let p = doc.data()
                        let ps = new Points(p)

                        if (ps.locations) {
                            ps.locations = convertObjectToList(ps.locations)
                        }

                        const applicableMembers =
                            ps.mType === 1
                                ? Object.keys(chapter.members).filter(memId => ps.mems.includes(memId))
                                : convertObjectToList(chapter.members)
                                      .filter(mem => ps.vis.includes(mem.status))
                                      .map(mem => mem.id)

                        setViewingUser(viewingUser => {
                            if (!viewingUser) {
                                if (applicableMembers.length > 0) {
                                    return applicableMembers[0]
                                }

                                return user ? user.getId() : ''
                            }

                            return viewingUser
                        })

                        setPoints(ps)
                        setCompiledMembers(compileMembers(ps))
                    } else {
                        // TODO: set points system as non existing
                    }
                },
                function(error) {
                    console.log('error', error)
                    //setEventExists(false)
                },
            )
            return () => {
                unsubscribe()
            }
        }
    }, [pointsId, hasPointsAdmin])

    function compileMembers(p) {
        let ps = points
        if (p !== undefined) {
            ps = p
        }

        let mems = chapter.members
        let memIds = Object.keys(mems)
        let memArray = []

        for (let i = 0; i < memIds.length; i++) {
            let memId = memIds[i]
            let memPoints = ps.getPoints(memId)
            let mem = { ...mems[memId], id: memId, points: memPoints }

            if (mem.roll && chapter && chapter.settings && chapter.settings.roll) {
                mem.roll = parseInt(mem.roll)
            }

            mem.or = ps.getNumOpenRequests(memId)

            if ((ps.mType === 0 && ps.vis.includes(mem.status)) || (ps.mType === 1 && ps.mems.includes(memId))) {
                memArray.push(mem)
            }
        }

        return memArray
    }

    function getUserPoints(uid) {
        for (let i = 0; i < compiledMembers.length; i++) {
            let mem = compiledMembers[i]
            if (mem.id === uid) {
                return mem.points
            }
        }

        return 0
    }

    function grabPointSystem() {
        getPointsRef()
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    setHasGrabbed(true)
                    setIsLoading(false)
                    let p = doc.data()
                    let ps = new Points(p)

                    if (ps.locations) {
                        ps.locations = convertObjectToList(ps.locations)
                    }

                    setPoints(ps)
                    setCompiledMembers(compileMembers(ps))
                } else {
                    //setEventExists(false)
                }
            })
            .catch(function(error) {
                //setEventExists(false)
                console.log('Error getting point system:', error)
            })
    }

    function getPoints() {
        let pointObject = new Points(points)

        let userPoints = getUserPoints(user.getId())

        if (pointObject.getType() === Points.TYPE_GOAL) {
            let goal = points.goal

            let val = (userPoints / goal) * 100

            if (val > 100) {
                val = 100
            } else if (val < 0) {
                val = 0
            }

            return (
                <Box display="flex" className={classes.progressBox}>
                    <LinearProgress
                        variant="determinate"
                        value={val}
                        style={{ flexGrow: 1 }}
                        classes={{
                            barColorPrimary: classes.progressBar,
                        }}
                        className={classes.progress}
                    />
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {userPoints}/{goal} ({Number(val.toFixed(2))}%)
                    </Typography>
                </Box>
            )
        } else {
            return (
                <Box display="flex" className={classes.progressBox}>
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {userPoints} Points
                    </Typography>
                </Box>
            )
        }
    }

    const [viewingUser, setViewingUser] = React.useState('')

    function getProgressHeader() {
        let pointObject = new Points(points)
        let userPoints = getUserPoints(viewingUser)
        if (pointObject.getType() === Points.TYPE_GOAL) {
            let goal = points.goal

            let val = (userPoints / goal) * 100

            if (val > 100) {
                val = 100
            } else if (val < 0) {
                val = 0
            }

            return <ProgressHeader key={`progress_${viewingUser}`} percentage={val} displayValue={`${userPoints}/${goal} (${Number(val.toFixed(2))}%)`} />
        } else {
            return (
                <StatusHeader
                    status={{
                        type: 'Points',
                        reason: `${userPoints}`,
                    }}
                />
            )
        }
    }

    function getMemberPlacing(userId) {
        let mems = [...compiledMembers]

        if (mems.length === 0) {
            return 1
        }

        mems.sort((a, b) => b.points - a.points)

        let lastPlace = 1
        let lastPoints = mems[0].points

        for (let i = 0; i < mems.length; i++) {
            let mem = mems[i]

            let place = mem.points === lastPoints ? lastPlace : i + 1

            if (mem.id === userId) {
                return place
            }

            lastPlace = place
            lastPoints = mem.points
        }

        return mems.length
    }

    function getPlacing(val, total) {
        if (val <= 3 && val >= 1) {
            let primaryColors = ['#F0E68C', '#D7D7D7', '#AD8A56']
            let secondaryColors = ['#DAA520', '#B4B4B4', '#6A3805']

            return (
                <>
                    <div
                        style={{
                            color: primaryColors[val - 1],
                            textAlign: 'center',
                            fontWeight: 'bold',
                            fontSize: '2rem',
                            lineHeight: 1,
                            textShadow: `${secondaryColors[val - 1]} 1px 1px`,
                            letterSpacing: `${val === 1 ? '-3' : '-1'}px`,
                        }}
                    >
                        {val}
                        <span style={{ verticalAlign: 'super', fontSize: '60%', letterSpacing: '0px' }}>{getOrdinalSuffix(val)}</span>
                    </div>
                    <div style={{ textAlign: 'center', lineHeight: 1 }}>of {total}</div>
                </>
            )
        }

        return (
            <>
                <div
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        fontSize: '2rem',
                        lineHeight: 1,
                    }}
                >
                    {val}
                    <span style={{ verticalAlign: 'super', fontSize: '60%', letterSpacing: '0px' }}>{getOrdinalSuffix(val)}</span>
                </div>
                <div style={{ textAlign: 'center', lineHeight: 1 }}>of {total}</div>
            </>
        )
    }

    function submitPointRequest() {
        setPointsRequest({ ...pointsRequest, loading: true, error: false })

        let record = PointRecord.getNew()
        record.setName(pointsRequest.name)
        record.setDescription(pointsRequest.description)
        record.setPoints(parseInt(pointsRequest.points))
        record.setAttachment(pointsRequest.attachment)

        let dt = moment(pointsRequest.date)

        if (dt.isValid()) {
            record.setDateFromMoment(dt)
        }

        let submission = {
            chapter: user.getChapter(),
            pointsID: pointsId,
            record: record.getRaw(),
        }

        const functionsCall = app.functions().httpsCallable('submitPointRequest')
        functionsCall(submission)
            .then(function(result) {
                setPointsRequest(null)
                if (!hasPointsAdmin) {
                    setHasGrabbed(false)
                }
            })
            .catch(function(error) {
                var message = error.message
                setPointsRequest({ ...pointsRequest, loading: false, error: message })
            })
    }

    function clearAllPointRecords() {
        const data = {
            chapter: user.getChapter(),
            id: pointsId,
            action: 'remove',
        }

        setClearPointsDialog({ ...clearPointsDialog, loading: true })

        let functionsCall = app.functions().httpsCallable('removePoints')
        functionsCall(data)
            .then(function() {
                setClearPointsDialog({ open: false })
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setClearPointsDialog({ ...clearPointsDialog, loading: false, error: message })
            })
    }

    function getNumOpenRequests() {
        return points.getNumOpenRequests()
    }

    function getMainTableHeaders() {
        if (hasPointsAdmin) {
            let headers = []

            if (hasPointsAdminIgnoringSupervisor) {
                headers.push(
                    <LinkHeader
                        title="Clear All Points"
                        icon={<CancelOutlinedIcon />}
                        onClick={() => {
                            setClearPointsDialog({ open: true })
                        }}
                        loading={false}
                    />,
                )
            }

            if (points && points.allowRequests) {
                const numOpenRequests = getNumOpenRequests()

                const openApproveOpenRequestsDialog = () => {
                    setApproveOpenRequestsDialog({ open: true })
                }

                headers.push(
                    <StatusHeader
                        status={{
                            type: 'Open Requests',
                            reason: numOpenRequests,
                            description: 'Number of point requests that are currently awaiting approval',
                        }}
                    />,
                )

                if (numOpenRequests > 0) {
                    headers.push(
                        <LinkHeader title="Approve All Open Requests" icon={<ApproveIcon />} onClick={openApproveOpenRequestsDialog} loading={false} />,
                    )
                }
            }

            return headers
        }
    }

    async function approveAllOpenRequests() {
        // TODO: open a dialog showing this action with a confirmation?
        // Upon confirmation, open a transaction to approve all open requests
        // Then close the dialog
        // If an error occurs, show it
        let errored = false

        await app
            .firestore()
            .runTransaction(async function(transaction) {
                const ref = getPointsRef()
                const pointSystemDoc = await transaction.get(ref)
                if (pointSystemDoc.exists) {
                    const pointSystemData = new Points(pointSystemDoc.data())

                    const updatedData = {
                        records: pointSystemData.records,
                    }

                    const userIds = Object.keys(updatedData.records)

                    for (let i = 0; i < userIds.length; i++) {
                        const userId = userIds[i]
                        const records = pointSystemData.getRecords(userId)
                        for (let j = 0; j < records.length; j++) {
                            const record = records[j]
                            if (record.status === PointRecord.STATUS_AWAITING) {
                                record.setStatus(PointRecord.STATUS_APPROVED)
                                records[j] = record
                            }
                        }
                        updatedData.records[userId] = PointRecord.convertArrayToRaw(records)
                    }

                    transaction.update(ref, updatedData)
                }
            })
            .catch(function(error) {
                var code = error.code
                var message = error.message
                console.log('AN ERROR OCCURRED', code, message)
                setApproveOpenRequestsDialog({ ...approveOpenRequestsDialog, loading: false, error: message })
                errored = true
            })

        if (!errored) {
            setApproveOpenRequestsDialog({ open: false })
        }
    }

    function getChapterStandingsColumns() {
        let items = []

        if (chapter && chapter.settings && chapter.settings.roll) {
            items.push({
                title: 'Roll',
                value: 'roll',
                type: 'NUMBER',
                size: 'small',
                sortable: true,
                searchable: true,
            })
        }

        items = [
            ...items,
            {
                title: 'First',
                value: 'first',
                sortable: true,
                searchable: true,
            },
            {
                title: 'Last',
                value: 'last',
                sortable: true,
                searchable: true,
            },
            {
                title: 'Points',
                value: 'points',
                size: 'small',
                type: 'NUMBER',
                sortable: true,
            },
        ]

        if (hasPointsAdmin && points.allowRequests) {
            items.push({
                title: 'Open Requests',
                value: 'or',
                type: 'NUMBER',
                sortable: true,
            })
        }

        return items
    }

    const renderPrimaryWidget = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontWeight: 700,
                        margin: '0 auto',
                    }}
                    align="center"
                >
                    {points.name}
                </Typography>
            </Grid>
            {canViewPersonalPoints && (
                <Grid item xs={12}>
                    {getPoints()}
                </Grid>
            )}
            {canViewPersonalPoints && points.rankingsVisibility !== Points.RANKINGS_ADMIN && (
                <Grid item xs={12}>
                    {getPlacing(getMemberPlacing(user.getId()), compiledMembers.length)}
                </Grid>
            )}
            {points.vis && (
                <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                    <Grid
                        item
                        style={{
                            height: 24,
                        }}
                    >
                        <VisibilityIcon />
                    </Grid>
                    <Grid item>
                        <Typography>{renderVisibility(points.vis)}</Typography>
                    </Grid>
                </Grid>
            )}
            {points.author && (
                <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                    <Grid
                        item
                        style={{
                            height: 24,
                        }}
                    >
                        <AuthorIcon />
                    </Grid>
                    <Grid item>
                        <Typography>{points.author.first + ' ' + points.author.last}</Typography>
                    </Grid>
                </Grid>
            )}
            {points.links &&
                points.links.length > 0 &&
                points.links.map(link => (
                    <Grid key={link.url} container direction="row" style={{ flexWrap: `nowrap` }}>
                        <Grid
                            item
                            style={{
                                height: 24,
                            }}
                        >
                            <LinkIcon />
                        </Grid>
                        <Grid item style={{ overflow: 'hidden' }}>
                            <Link target="_blank" rel="noreferrer" href={link.url}>
                                <Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{link.name ? link.name : link.url}</Typography>
                            </Link>
                        </Grid>
                    </Grid>
                ))}
            {points.description && (
                <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                    <Grid
                        item
                        style={{
                            height: 24,
                        }}
                    >
                        <DescriptionIcon />
                    </Grid>
                    <Grid item>
                        <MuiLinkify>
                            <Typography
                                style={{
                                    whiteSpace: `break-spaces`,
                                    wordBreak: `break-word`,
                                }}
                            >
                                {points.description}
                            </Typography>
                        </MuiLinkify>
                    </Grid>
                </Grid>
            )}
            {canViewPersonalPoints && pointsRequest !== null && pointsRequest.type === 'location' && (
                <>
                    <Grid item xs={12}>
                        <Map
                            center={userLocation.current}
                            style={{
                                height: 384,
                            }}
                        >
                            <MapRef ref={mapRef} />
                            <MapUserMarker location={userLocation.current} />
                            {points.locations.map(location => (
                                <MapCircle
                                    key={`${location.name}.${location.center.latitude}.${location.center.longitude}.circle`}
                                    center={location.center}
                                    radius={location.radius}
                                    centerRegion={false}
                                />
                            ))}
                            {points.locations.map(location => (
                                <MapMarker
                                    title={location.name}
                                    key={`${location.name}.${location.center.latitude}.${location.center.longitude}.marker`}
                                    center={location.center}
                                />
                            ))}
                        </Map>
                    </Grid>
                    <Grid item xs={12}>
                        <LocationOptions
                            onSelect={(location, isOutOfBounds) => {
                                if (mapkit && mapRef && mapRef.current) {
                                    let latitudes = [userLocation.current.latitude, location.center.latitude]
                                    let longitudes = [userLocation.current.longitude, location.center.longitude]

                                    const boundingRegion = new mapkit.BoundingRegion(
                                        Math.max(...latitudes),
                                        Math.max(...longitudes),
                                        Math.min(...latitudes),
                                        Math.min(...longitudes),
                                    )

                                    const coordinateRegion = boundingRegion.toCoordinateRegion()

                                    coordinateRegion.span = new mapkit.CoordinateSpan(
                                        coordinateRegion.span.latitudeDelta * 1.2,
                                        coordinateRegion.span.longitudeDelta * 1.2,
                                    )

                                    mapRef.current.setRegionAnimated(coordinateRegion)
                                }

                                setPointsRequest(request => ({
                                    ...request,
                                    selectedLocation: `${location.center.latitude}.${location.center.longitude}`,
                                    selectedLocationOutOfBounds: isOutOfBounds,
                                }))
                            }}
                            locations={points.locations}
                            selected={pointsRequest.selectedLocation}
                            userLocation={userLocation.current}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setPointsRequest(null)}
                            disableElevation
                            fullWidth
                            style={{ display: 'flex', margin: '0 auto', maxWidth: 384 }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setLocationTracking(true)}
                            disableElevation
                            disabled={!pointsRequest.selectedLocation || pointsRequest.selectedLocationOutOfBounds}
                            fullWidth
                            style={{ display: 'flex', margin: '0 auto', maxWidth: 384 }}
                        >
                            Start
                        </Button>
                    </Grid>
                </>
            )}
            {canViewPersonalPoints && pointsRequest !== null && pointsRequest.type === null && (
                <>
                    <Grid item xs={12} sm={6}>
                        <RequestButton
                            onClick={() =>
                                setPointsRequest(request => ({
                                    ...request,
                                    type: 'custom',
                                }))
                            }
                            title="Custom"
                            description="Submit requests that are reviewed by a chapter officer"
                            icon={<EditIcon />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RequestButton
                            onClick={() => {
                                // Request their location first, then display the locations based on distance...
                                const addLocation = async () => {
                                    try {
                                        const loc = await getCurrentLocation()

                                        if (loc && loc.coords) {
                                            const coordinate = new mapkit.Coordinate(loc.coords.latitude, loc.coords.longitude)

                                            userLocation.current = coordinate

                                            setPointsRequest(request => ({
                                                ...request,
                                                type: 'location',
                                            }))
                                        } else {
                                            alert('Need location access to use this feature')
                                        }
                                    } catch (e) {
                                        console.log('unable to grab location', e)
                                        alert('Need location access to use this feature')
                                    }
                                }

                                addLocation()
                            }}
                            title="Location"
                            description="Earn points based on how long you are in designated areas"
                            disabled={false && !Capacitor.isPluginAvailable('BackgroundGeolocation')}
                            icon={<LocationIcon />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setPointsRequest(null)}
                            disableElevation
                            fullWidth
                            style={{ display: 'flex', margin: '0 auto', maxWidth: 384 }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </>
            )}
            {canViewPersonalPoints && pointsRequest !== null && pointsRequest.type === 'custom' && (
                <>
                    <Grid item xs={12} sm={6} lg={4} id="point_request_name">
                        <TextField
                            variant="outlined"
                            margin="dense"
                            label={pointsRequest.name ? '' : `Title`}
                            type="text"
                            onChange={e => {
                                setPointsRequest({ ...pointsRequest, name: e.target.value })
                            }}
                            value={pointsRequest.name}
                            inputProps={{
                                maxLength: 64,
                            }}
                            style={{ marginTop: 4 }}
                            InputLabelProps={{ shrink: false }}
                            fullWidth
                            disabled={pointsRequest.loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} id="point_request_description">
                        <TextField
                            variant="outlined"
                            margin="dense"
                            label={pointsRequest.description ? '' : `Description`}
                            type="text"
                            onChange={e => {
                                setPointsRequest({ ...pointsRequest, description: e.target.value })
                            }}
                            value={pointsRequest.description}
                            inputProps={{
                                maxLength: 128,
                            }}
                            style={{ marginTop: 4 }}
                            InputLabelProps={{ shrink: false }}
                            fullWidth
                            disabled={pointsRequest.loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} id="point_request_points">
                        <TextField
                            variant="outlined"
                            margin="dense"
                            label={pointsRequest.points ? '' : `Points`}
                            type="number"
                            onChange={e => {
                                if (e.target.validity.valid || e.target.value === '') {
                                    const value = parseInt(e.target.value)
                                    setPointsRequest({ ...pointsRequest, points: isNaN(value) ? 0 : value })
                                } else {
                                    setPointsRequest({ ...pointsRequest, points: 0 })
                                }
                            }}
                            value={pointsRequest.points}
                            inputProps={{
                                maxLength: 5,
                                pattern: '[0-9]*',
                                inputMode: 'numeric',
                            }}
                            style={{ marginTop: 4 }}
                            InputLabelProps={{ shrink: false }}
                            fullWidth
                            disabled={pointsRequest.loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} id="point_request_date">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                inputVariant="outlined"
                                disableToolbar
                                format="MM/dd/yyyy"
                                label={pointsRequest.date ? '' : `Date`}
                                value={pointsRequest.date}
                                fullWidth
                                margin="dense"
                                onChange={e => setPointsRequest({ ...pointsRequest, date: e })}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{ marginTop: 4 }}
                                InputLabelProps={{ shrink: false }}
                                disabled={pointsRequest.loading}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} id="point_request_attachment">
                        <DocumentUploadLarge
                            onUpdateFile={file => {
                                setPointsRequest({ ...pointsRequest, attachment: file })
                            }}
                            title="Drag an attachment or click to upload"
                            icon={<ImageIcon style={{ fontSize: '6rem' }} disabled={pointsRequest.loading} />}
                            disabled={pointsRequest.loading}
                        />
                    </Grid>
                    {pointsRequest.error && (
                        <Grid item xs={12}>
                            <ErrorTypography text={pointsRequest.error} />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => setPointsRequest(null)}
                            disabled={pointsRequest.loading}
                            disableElevation
                            fullWidth
                            style={{ display: 'flex', margin: '0 auto', maxWidth: 384 }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => submitPointRequest()}
                            disabled={pointsRequest.loading || !pointsRequest.name || pointsRequest.date === null || !pointsRequest.points}
                            fullWidth
                            style={{ display: 'flex', margin: '0 auto', maxWidth: 384 }}
                        >
                            Submit Request
                        </Button>
                    </Grid>
                </>
            )}
            {hasActiveLocationTrackingRequest && isMobileDevice() ? (
                <Grid item xs={12}>
                    <RequestButton
                        onClick={() => setLocationTracking(true)}
                        title="Location"
                        description="Resume location tracking so you can earn your points"
                        icon={<LocationIcon />}
                    />
                </Grid>
            ) : (
                <>
                    {canViewPersonalPoints && points.allowRequests && pointsRequest === null && (
                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                    setPointsRequest({
                                        name: '',
                                        points: '',
                                        type: points.allowLocationTrackingRequests && isMobileDevice() ? null : 'custom',
                                        date: new Date(),
                                        description: '',
                                        attachment: null,
                                        loading: false,
                                        error: false,
                                        selectedLocation: null,
                                    })
                                }
                                disableElevation
                            >
                                Request Points
                            </Button>
                        </Grid>
                    )}
                </>
            )}
            {canViewPersonalPoints && (
                <>
                    <Grid item xs={12}>
                        <Divider style={isMobileDevice() ? { margin: '0 -16px' } : { marginLeft: -24, marginRight: -24 }} />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 0 }}>
                        <Box style={isMobileDevice() ? { margin: '0 -8px' } : { marginLeft: -20, marginRight: -20, marginTop: -18, marginBottom: -18 }}>
                            <ParentTable title="Your Points">
                                <Grid container>
                                    <Grid item xs={12} key={'records_personal'}>
                                        <Table
                                            title="Records"
                                            sortable
                                            defaultSortable={{
                                                value: 'dt',
                                                dir: 'asc',
                                            }}
                                            columns={[
                                                {
                                                    title: 'Attachment',
                                                    value: 'attachment',
                                                    type: 'IMAGE',
                                                    onClick: data => {
                                                        setViewAttachment(data.attachment)
                                                    },
                                                    tooltip: 'View Attachment',
                                                },
                                                {
                                                    title: 'Title',
                                                    value: 'name',
                                                    size: 'large',
                                                    required: true,
                                                    sortable: true,
                                                },
                                                {
                                                    title: 'Description',
                                                    value: 'description',
                                                    required: false,
                                                    sortable: true,
                                                },
                                                {
                                                    title: 'Status',
                                                    value: 'statusStr',
                                                    size: 'small',
                                                    type: 'SELECT',
                                                    required: true,
                                                    sortable: true,
                                                    options: PointRecord.getStatusOptions(),
                                                },
                                                {
                                                    title: 'Date',
                                                    value: 'dt',
                                                    type: 'TIME',
                                                    format: 'l',
                                                    disabled: true,
                                                    sortable: true,
                                                },
                                                {
                                                    title: 'Points',
                                                    value: 'points',
                                                    size: 'small',
                                                    type: 'NUMBER',
                                                    required: true,
                                                    sortable: true,
                                                },
                                            ]}
                                            showOnEmpty
                                            emptyText="No Records"
                                            data={points.getRecords(user ? user.getId() : '')}
                                        />
                                    </Grid>
                                </Grid>
                            </ParentTable>
                        </Box>
                    </Grid>
                </>
            )}
        </Grid>
    )

    const renderChapterWidget = () => (
        <ParentTable
            title="Chapter"
            actions={
                hasPointsAdmin
                    ? [
                          {
                              title: 'Download Standings',
                              icon: <DownloadIcon />,
                              onClick: event => {
                                  let finalized = []

                                  for (let i = 0; i < compiledMembers.length; i++) {
                                      let mem = compiledMembers[i]

                                      let data = {
                                          First: mem.first,
                                          Last: mem.last,
                                          Points: mem.points,
                                      }

                                      if (mem.roll) {
                                          data.Roll = mem.roll
                                      }

                                      finalized.push(data)
                                  }

                                  const element = document.createElement('a')
                                  const file = new Blob([Papa.unparse(finalized)], {
                                      type: 'text/csv',
                                  })

                                  let pointsName = points.name.replace(/\W/g, '')

                                  element.href = URL.createObjectURL(file)
                                  element.download = `${pointsName}_records.csv`
                                  document.body.appendChild(element)
                                  element.click()
                              },
                          },
                      ]
                    : []
            }
            rows={getMainTableHeaders()}
        >
            <Grid container>
                <Grid item xs={12} key={`table_standings_${compiledMembers.length}`}>
                    <Table
                        title="Standings"
                        sortable
                        defaultSortable={
                            hasPointsAdmin && points.allowRequests
                                ? {
                                      value: 'or',
                                      dir: 'desc',
                                  }
                                : {
                                      value: 'points',
                                      dir: 'desc',
                                  }
                        }
                        columns={getChapterStandingsColumns()}
                        selectActions={null}
                        addRowText="Add Recipient"
                        showOnEmpty
                        emptyText="No members"
                        data={compiledMembers}
                        searchable
                        onClick={
                            hasPointsAdmin
                                ? rowData => {
                                      setViewingUser(rowData.id)
                                  }
                                : undefined
                        }
                    />
                </Grid>
            </Grid>
        </ParentTable>
    )

    const renderInvididualWidget = () => (
        <ParentTable
            title="Individual"
            actions={[
                {
                    title: 'Download Records',
                    icon: <DownloadIcon />,
                    onClick: event => {
                        let records = points.getRecords(viewingUser)

                        let finalized = []

                        for (let i = 0; i < records.length; i++) {
                            let rec = records[i]
                            let dt = moment(getFirebaseDate({ date: rec.dt })).format('l')
                            finalized.push({
                                Title: rec.name,
                                Description: rec.description,
                                Status: rec.statusStr,
                                Date: dt,
                                Points: rec.points,
                                Attachment: rec.attachment,
                            })
                        }

                        const element = document.createElement('a')
                        const file = new Blob([Papa.unparse(finalized)], {
                            type: 'text/csv',
                        })

                        let mem = null

                        for (let i = 0; i < compiledMembers.length; i++) {
                            if (compiledMembers[i].id === viewingUser) {
                                mem = compiledMembers[i]
                                i = compiledMembers.length
                            }
                        }

                        let pointsName = points.name.replace(/\W/g, '')

                        element.href = URL.createObjectURL(file)
                        element.download = mem !== null ? `${pointsName}_records_${mem.first}_${mem.last}.csv` : `${pointsName}_records_${viewingUser}.csv`
                        document.body.appendChild(element)
                        element.click()
                    },
                },
            ]}
            rows={[
                <StatusHeader
                    status={{
                        type: 'Member',
                        reason: viewingUser in chapter.members ? `${chapter.members[viewingUser].first} ${chapter.members[viewingUser].last}` : 'Unknown',
                    }}
                />,
                getProgressHeader(),
            ]}
        >
            <Grid container>
                <Grid item xs={12} key={`records_${viewingUser}`}>
                    <Table
                        title="Records"
                        sortable
                        defaultSortable={{
                            value: 'dt',
                            dir: 'asc',
                        }}
                        columns={[
                            {
                                title: 'Attachment',
                                value: 'attachment',
                                type: 'IMAGE',
                                onClick: data => {
                                    setViewAttachment(data.attachment)
                                },
                                tooltip: 'View Attachment',
                            },
                            {
                                title: 'Title',
                                value: 'name',
                                size: 'large',
                                required: true,
                                sortable: true,
                            },
                            {
                                title: 'Description',
                                value: 'description',
                                required: false,
                                sortable: true,
                            },
                            {
                                title: 'Status',
                                value: 'statusStr',
                                size: 'small',
                                type: 'SELECT',
                                required: true,
                                sortable: true,
                                options: PointRecord.getStatusOptions(),
                            },
                            {
                                title: 'Date',
                                value: 'dt',
                                type: 'DATE',
                                placeholder: 'YYYY-MM-DD',
                                format: 'l',
                                sortable: true,
                            },
                            {
                                title: 'Points',
                                value: 'points',
                                size: 'small',
                                type: 'NUMBER',
                                required: true,
                                sortable: true,
                            },
                        ]}
                        selectActions={[
                            {
                                key: 0,
                                title: num => {
                                    if (num === 1) {
                                        return 'Remove 1 Record'
                                    }
                                    return 'Remove ' + num + ' Records'
                                },
                                icon: <RemoveIcon />,
                                onClick: rows => {
                                    let p = _.cloneDeep(points)
                                    for (let i = 0; i < rows.length; i++) {
                                        p.removeRecord(viewingUser, rows[i].id)
                                    }

                                    let updatedField = `records.${viewingUser}`

                                    getPointsRef().update({ [updatedField]: PointRecord.convertArrayToRaw(p.records[viewingUser]) })
                                },
                            },
                        ]}
                        rowActions={[
                            {
                                title: 'Approve',
                                icon: <ApproveIcon />,
                                onClick: data => {
                                    if (data.statusStr !== 'Approved') {
                                        let records = points.getRecords(viewingUser)

                                        for (let i = 0; i < records.length; i++) {
                                            let record = records[i]
                                            if (record.id === data.id) {
                                                record = new PointRecord(record)

                                                record.setStatusFromString('Approved')
                                                records[i] = record
                                            }
                                        }

                                        let updatedField = `records.${viewingUser}`

                                        getPointsRef().update({ [updatedField]: PointRecord.convertArrayToRaw(records) })
                                    }
                                },
                            },
                            {
                                title: 'Edit',
                                icon: <EditOutline />,
                                toggleEditing: true,
                            },
                        ]}
                        onUpdateRow={data => {
                            let records = points.getRecords(viewingUser)

                            for (let i = 0; i < records.length; i++) {
                                let record = records[i]
                                if (record.id === data.id) {
                                    let oldAttachment = record.getAttachment()
                                    record = new PointRecord(record)
                                    record.setName(data.name)
                                    record.setDescription(data.description)
                                    record.setPoints(parseInt(data.points))
                                    record.setStatusFromString(data.statusStr)
                                    record.setAttachment(oldAttachment)

                                    let dt = moment(data.dt)

                                    if (dt.isValid()) {
                                        record.setDateFromMoment(dt)
                                    }

                                    records[i] = record
                                }
                            }

                            let updatedField = `records.${viewingUser}`

                            getPointsRef().update({ [updatedField]: PointRecord.convertArrayToRaw(records) })
                        }}
                        id="id"
                        onAdd={data => {
                            let p = _.cloneDeep(points)
                            let record = PointRecord.getNew()
                            record.setName(data.name)
                            record.setDescription(data.description)
                            record.setPoints(parseInt(data.points))
                            record.setStatusFromString(data.statusStr)

                            let dt = moment(data.dt)

                            if (dt.isValid()) {
                                record.setDateFromMoment(dt)
                            }

                            p.addRecord(viewingUser, record)

                            let updatedField = `records.${viewingUser}`

                            getPointsRef().update({ [updatedField]: PointRecord.convertArrayToRaw(p.records[viewingUser]) })
                        }}
                        showOnEmpty={true}
                        emptyText="No Records"
                        addRowText="Add Record"
                        data={points.getRecords(viewingUser)}
                    />
                </Grid>
            </Grid>
        </ParentTable>
    )

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Points',
                            link: '/app/applications/points/',
                            icon: <PointsIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: 'System',
                        },
                    ]}
                    rightButtons={
                        hasPointsAdminIgnoringSupervisor
                            ? [
                                  {
                                      name: 'Edit',
                                      type: 'icon',
                                      innerIcon: <EditIcon />,
                                      onClick: () => {
                                          props.history.push('/app/applications/points/edit/' + pointsId)
                                      },
                                  },
                              ]
                            : []
                    }
                    key={hasPointsAdminIgnoringSupervisor ? 'admin' : 'nah'}
                    grid
                />
                {hasGrabbed && (
                    <>
                        <Grid item xs={12}>
                            {isMobileDevice() ? (
                                renderPrimaryWidget()
                            ) : (
                                <Widget disableWidgetMenu inheritHeight>
                                    {renderPrimaryWidget()}
                                </Widget>
                            )}
                        </Grid>
                        {(hasPointsAdmin || points.rankingsVisibility === Points.RANKINGS_ALL) && isMobileDevice() && (
                            <Grid item xs={12}>
                                <Divider style={{ margin: '-8px -16px' }} />
                            </Grid>
                        )}
                        {(hasPointsAdmin || points.rankingsVisibility === Points.RANKINGS_ALL) && (
                            <Grid item xs={12}>
                                {isMobileDevice() ? (
                                    <Grid container spacing={2} style={{ margin: '0 -16px', width: 'calc(100% + 32px)' }}>
                                        <Grid item xs={12}>
                                            <Box style={{ margin: '0 -8px' }}>{renderChapterWidget()}</Box>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Widget disableWidgetMenu noBodyPadding>
                                        {renderChapterWidget()}
                                    </Widget>
                                )}
                            </Grid>
                        )}
                        {hasPointsAdmin && isMobileDevice() && (
                            <Grid item xs={12}>
                                <Divider style={{ margin: '-8px -16px' }} />
                            </Grid>
                        )}
                        {hasPointsAdmin && (
                            <Grid item xs={12}>
                                {isMobileDevice() ? (
                                    <Grid container spacing={2} style={{ margin: '0 -16px', width: 'calc(100% + 32px)' }}>
                                        <Grid item xs={12}>
                                            <Box style={{ margin: '0 -8px' }}> {renderInvididualWidget()}</Box>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Widget disableWidgetMenu noBodyPadding>
                                        {renderInvididualWidget()}
                                    </Widget>
                                )}
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            {clearPointsDialog.open && (
                <Dialog
                    open={clearPointsDialog.open}
                    onClose={() => {
                        if (!clearPointsDialog.loading) {
                            setClearPointsDialog({ open: false })
                        }
                    }}
                    aria-labelledby="clear-points-title"
                >
                    <DialogTitle id="clear-points-title">Clear System Points?</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to clear all the point records for the system?</Typography>
                        {clearPointsDialog.error && <ErrorTypography text={clearPointsDialog.error} isCentered={true} />}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={clearPointsDialog.loading} onClick={() => setClearPointsDialog({ open: false })} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={clearPointsDialog.loading} onClick={() => clearAllPointRecords()} color="red">
                            Clear
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {approveOpenRequestsDialog.open && (
                <Dialog
                    open={approveOpenRequestsDialog.open}
                    onClose={() => {
                        if (!approveOpenRequestsDialog.loading) {
                            setApproveOpenRequestsDialog({ open: false })
                        }
                    }}
                    aria-labelledby="approve-points-title"
                >
                    <DialogTitle id="approve-points-title">Approve all open point requests?</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to approve all the open point requests?</Typography>
                        {approveOpenRequestsDialog.error && <ErrorTypography text={approveOpenRequestsDialog.error} isCentered={true} />}
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={approveOpenRequestsDialog.loading} onClick={() => setApproveOpenRequestsDialog({ open: false })} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={approveOpenRequestsDialog.loading} onClick={() => approveAllOpenRequests()} color="primary">
                            Approve
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <FullScreenImage open={viewAttachment !== false} onClose={() => setViewAttachment(false)} src={viewAttachment} />
            {locationTracking && (
                <LocationTrackingDialog
                    points={{ ...points, id: pointsId }}
                    location={
                        pointsRequest && pointsRequest.selectedLocation
                            ? points.locations[
                                  points.locations
                                      .map(location => `${location.center.latitude}.${location.center.longitude}` === pointsRequest.selectedLocation)
                                      .indexOf(true)
                              ]
                            : null
                    }
                    onClose={() => {
                        setLocationTracking(false)
                        setPointsRequest(null)
                    }}
                />
            )}
        </>
    )
}
