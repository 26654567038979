import React from 'react'

import { Box, Tooltip, IconButton, LinearProgress, Icon, Typography, Button } from '@material-ui/core'
import { HelpOutline as HelpIcon } from '@material-ui/icons'
import CopyToClipboard from '../../CopyToClipboard/CopyToClipboard'

// styles
import useStyles from '../ParentTable/styles'

export function Progress({ percentage, displayValue, description, ...props }) {
    var classes = useStyles()

    return (
        <Box display="flex" className={classes.tableHeader} {...props}>
            <LinearProgress
                variant="determinate"
                value={percentage}
                style={{ flexGrow: 1 }}
                classes={{
                    barColorPrimary: classes.progressBar,
                }}
                className={classes.progress}
            />
            <Typography variant="body1" style={{ fontWeight: 700 }}>
                {displayValue}
            </Typography>
            {description && (
                <Tooltip title={description}>
                    <IconButton size="small" style={{ marginLeft: 4, paddingLeft: 2 }}>
                        <HelpIcon style={{ fontSize: '18px' }} />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    )
}

export function Clipboard(props) {
    var classes = useStyles()

    const { title, displayText, copyText } = props

    return (
        <Box display="flex" alignItems="center" justifyContent="center" className={classes.tableHeader}>
            <Typography
                variant="subtitle2"
                style={{
                    fontWeight: 500,
                    textAlign: 'center',
                }}
                noWrap
            >
                {title}: <span style={{ fontWeight: 700 }}>{displayText}</span>
            </Typography>
            <CopyToClipboard>
                {({ copy }) => (
                    <IconButton size="small" onClick={() => copy(copyText)}>
                        <Icon className={'far fa-clipboard'} fontSize="small" />
                    </IconButton>
                )}
            </CopyToClipboard>
        </Box>
    )
}

export function Link(props) {
    var classes = useStyles()

    const { title, onClick, icon, loading, disabled } = props

    return (
        <Box display="flex" alignItems="center" justifyContent="center" className={classes.tableHeader} style={{ paddingTop: 9 }}>
            <Button onClick={() => onClick()} disabled={loading || disabled} color="primary" size="small" style={{ lineHeight: 1 }}>
                <Box className={classes.linkParent}>
                    {icon ? <Box style={{ marginRight: 4 }}>{icon}</Box> : <></>}
                    <Box className={classes.linkText}>
                        <Typography variant="subtitle2" className={classes.linkTypography} noWrap>
                            {title}
                        </Typography>
                    </Box>
                </Box>
            </Button>
        </Box>
    )
}

export function Status(props) {
    var classes = useStyles()

    const { status } = props

    return (
        <Box display="flex" alignItems="center" justifyContent="center" className={classes.tableHeader}>
            <Typography
                variant="body1"
                style={{
                    textAlign: 'center',
                }}
                noWrap
            >
                <span style={{ lineHeight: `24px`, display: 'inline-block', verticalAlign: 'middle' }}>
                    {`${status.type ? status.type : 'Status'}:`}
                    <span
                        style={{
                            fontWeight: 500,
                        }}
                    >{` ${status.reason}`}</span>
                </span>
                {status.description && (
                    <Tooltip title={status.description}>
                        <IconButton size="small" style={{ marginLeft: 4, paddingLeft: 2 }}>
                            <HelpIcon style={{ fontSize: '18px' }} />
                        </IconButton>
                    </Tooltip>
                )}
            </Typography>
        </Box>
    )
}
