import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    cancelParent: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cancel: {
        marginRight: 4,
    },
    submitParent: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    submitParentCentered: {
        display: 'flex',
        justifyContent: 'center',
    },
    loadingParent: {
        paddingLeft: 25,
    },
    submit: {
        marginLeft: 4,
    },
}))
