import React from 'react'
import { Box } from '@material-ui/core'
import { CancelRounded as ErrorIcon } from '@material-ui/icons'
import { Typography } from '../../Wrappers'

export default function ErrorTypography(props) {
    const { text, isCentered, style } = props
    return (
        <Box
            display="flex"
            style={{
                ...style,
                justifyContent: isCentered ? 'center' : 'flex-start',
                width: '100%',
                alignItems: 'center',
            }}
        >
            <ErrorIcon style={{ color: '#E66868', margin: 2 }} fontSize="small" />
            <Typography color="red" style={{ marginLeft: 4, fontWeight: 700, marginRight: 2 }}>
                {text}
            </Typography>
        </Box>
    )
}
