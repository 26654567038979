import {
    Box,
    Button as ButtonBase,
    Collapse,
    Divider,
    Grid,
    Grow,
    IconButton,
    LinearProgress,
    Step,
    StepConnector,
    StepContent,
    StepLabel,
    Stepper,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
    Add as AddIcon,
    /*Chapter Setup Icon*/
    Settings as AdminIcon,
    SmsOutlined as AnnouncementsIcon,
    AssignmentIndOutlined as AssignedIcon,
    CakeOutlined as BirthdayIcon,
    CalendarToday as CalendarIcon,
    CancelOutlined as CancelIcon,
    CheckCircleOutlineOutlined as CheckIcon,
    LibraryBooks as DocumentationIcon,
    Launch as ExternalLinkIcon,
    HomeOutlined as HomeIcon,
    LaptopMac as LaptopIcon,
    Forum as MessagesIcon,
    NotificationsNone as NotificationsIcon,
    /*Applications Icons*/
    Timeline as PointsIcon,
    PollOutlined as PollsIcon,
    /*Tasks Icons*/
    AssignmentOutlined as TasksIcon,
    /*Tip Icons*/
    Brightness5 as ThemeIcon,
    AccessTime as TimeIcon,
    ReportOutlined as WarningIcon,
} from '@material-ui/icons'
import React from 'react'
import { TransitionGroup } from 'react-transition-group'

import _ from 'lodash'

import MuiLinkify from 'material-ui-linkify'

import { canShowTip } from 'code/Tips'

import { getChapterSetup, getWeek, getWeekYear, markSetupComplete, markSetupStepComplete, shouldShowSetup } from 'code/Helper'

import { RateApp } from 'capacitor-rate-app'

import GCWidget from 'components/GCWidget'
import NavigationBar from 'components/NavigationBar'
import Tip from 'components/Tip'
import Widget from 'components/Widget'
import { Avatar, Link, Typography } from 'components/Wrappers'

import { ConfettiIcon, ManageAccountsIcon as MemberManagementIcon, PushPinFilledIcon, TumbleWeedOutlinedIcon, WaveHandIcon } from 'components/Icons'

import { Points } from 'objects/Points'
import { Tasks as Task } from 'objects/Tasks'

import { getEventEnd, getFirebaseDate, hasSignInEnded, hasSignInStarted, timeAgo } from 'code/TimeAgo'

import format from 'date-fns/format'

import { useTheme } from '@material-ui/styles'
import useStyles from './styles'

import app from 'firebase/app'
import moment from 'moment'

import appStoreBadge from 'images/app-store-badge.svg'
import googlePlayBadge from 'images/google-play-badge.png'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { FraternityContext } from 'context/FraternityContext'

import { Capacitor } from '@capacitor/core'

import { PushNotifications } from '@capacitor/push-notifications'

export default function House({ history }) {
    const classes = useStyles()
    const theme = useTheme()

    const { needsFraternityUpdate } = React.useContext(FraternityContext)
    const { chapter, needsChapterUpdate } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)

    const [isIOS] = React.useState(navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform))
    const pwaWarningExpired = () =>
        localStorage.getItem('cache_pwa_warning') ? isCacheOld(new Date(localStorage.getItem('cache_pwa_warning')), 60 * 24 * 7) : true
    const isInStandaloneMode = () =>
        window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://')
    const [showPWAWarning, setShowPWAWarning] = React.useState(isInStandaloneMode() && pwaWarningExpired())

    const [events, setEvents] = React.useState([])
    const [birthdayUsers, setBirthdayUsers] = React.useState([])
    const [pointSystems, setPointSystems] = React.useState([])
    const [isDifferentTimezone] = React.useState(checkTimezoneDF())
    const [assignedTasks, setAssignedTasks] = React.useState([])
    const [polls, setPolls] = React.useState([])
    const [menu, setMenu] = React.useState(null)

    const [updatedPush, setUpdatedPush] = React.useState(false)

    function getAvatar(photoURL, userId, options) {
        return (
            <Box style={{ margin: -4, marginRight: 2 }}>
                <IconButton
                    className={classes.widgetAvatarButton}
                    onClick={() => {
                        if (!options?.disableLink) {
                            history.push(`/app/profile/${userId}`)
                        }
                    }}
                    style={options?.disableLink ? { pointerEvents: 'none' } : null}
                >
                    <Avatar src={photoURL} className={classes.widgetAvatar} />
                </IconButton>
            </Box>
        )
    }

    React.useEffect(() => {
        async function updateDevice(token) {
            if (user && user.id) {
                const db = app.firestore()
                let userRef = db.collection('users').doc(user.id)

                if (!Array.isArray(user.devices) || !user.devices.includes(token.value)) {
                    await userRef.update({ devices: app.firestore.FieldValue.arrayUnion(token.value) }).catch(function(error) {
                        // Getting the Error details.
                        var code = error.code
                        var message = error.message
                        var details = error.details
                        console.log('AN ERROR OCCURRED', code, message, details)
                    })

                    setUpdatedPush(true)

                    window.location.reload()
                }
            }
        }

        const arePushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications')
        if (arePushNotificationsAvailable) {
            if (!updatedPush) {
                PushNotifications.requestPermissions().then(result => {
                    if (result.receive === 'granted') {
                        // Register with Apple / Google to receive push via APNS/FCM
                        PushNotifications.register()
                    }
                })
            }

            // On success, we should be able to receive notifications
            PushNotifications.addListener('registration', token => {
                updateDevice(token)
            })
        }
    }, [user, updatedPush])

    const [cache, _setCache] = React.useState({ cache: getCache(), timer: 0, status: 0 })

    React.useEffect(() => {
        if (needsChapterUpdate() || needsFraternityUpdate()) {
            setTimeout(() => {
                grabData()
            }, 600)
        } else {
            grabData()
        }
    }, [])

    function updateCache() {
        localStorage.setItem('cache_myHouse', JSON.stringify(cache.cache))
        _setCache(cache => ({ ...cache, status: 0, timer: 0 }))
    }

    React.useEffect(() => {
        let interval = setInterval(() => {
            if (cache.status === 1) {
                if (cache.timer >= 2) {
                    updateCache()
                } else {
                    _setCache(cache => ({ ...cache, timer: cache.timer + 1 }))
                }
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [cache])

    const [currentProgress, setCurrentProgress] = React.useState({ searching: ['birthdays', 'meals', 'calendar', 'tasks', 'polls', 'points'], empty: true })

    function removeProgressItem(item, isEmpty) {
        setCurrentProgress(val => ({ ...val, searching: val.searching.filter(a => a !== item), empty: val.empty && isEmpty }))
    }

    function grabData() {
        shouldAskForReview()

        !shouldIgnoreBirthdays() ? getBirthdays() : removeProgressItem('birthdays', true)

        !shouldIgnoreMenu() ? getMenu() : removeProgressItem('meals', true)

        getCalendarEvents()
        getRecurringWeeklyAssignedTasks()
        getPolls()
        getPointSystems()

        removeProgressItem('chapter_welcome', !shouldShowSetup())
    }

    function getViews() {
        let views = []

        // sort by action requested then rank then date
        for (let i = 0; i < events.length; i++) {
            let v = events[i]

            const start = getFirebaseDate(v)
            const end = getEventEnd(v)

            let hasStarted = new Date() - start >= 0
            let hasEnded = new Date() - end >= 0

            let signInOpen = v.signIn.enabled && hasSignInStarted(v) && !hasSignInEnded(v)

            views.push({
                data: v,
                type: 'events',
                rank: !hasStarted ? 100 : !hasEnded ? 200 : 0,
                ts: hasEnded ? end : hasStarted ? new Date() : start,
                action: signInOpen,
            })
        }

        if (birthdayUsers.length > 0) {
            views.push({ data: birthdayUsers, type: 'BIRTHDAY', rank: 100, ts: birthdayUsers[0].bdayCurYear, action: false })
        }

        const announcements = [
            /*{
                id: 'abc123',
                sender: {
                    first: 'Brooks',
                    last: 'Lynch',
                    photoURL:
                        'https://firebasestorage.googleapis.com/v0/b/greekhouse-d5684.appspot.com/o/userPhotos%2FTESTING%2F409797d3-a351-4c07-91dd-af38e3de8feb.jpg?alt=media&token=9c1daa64-9eee-4b99-b7aa-1b5dbc565d02',
                },
                text: 'This is an example announcement that someone from headquarters could send, just like a normal message would be',
                pin: true,
                sentTime: { seconds: Date.now() / 1000 - 60 * 3 - 15 },
            },*/
        ]

        for (let i = 0; i < announcements.length; i++) {
            let v = announcements[i]
            // not seen? 110 : 100
            views.push({
                data: v,
                type: 'ANNOUNCEMENT',
                rank: /* hasViewedAnnouncement(v) ? 80 : 250*/ 250,
                ts: getDateFromTimestamp(v.sentTime),
                action: false,
            })
        }

        for (let i = 0; i < pointSystems.length; i++) {
            let v = pointSystems[i]
            // Not seen recently? 110 : 100
            views.push({ data: v, type: 'POINT_SYSTEM', rank: 150, ts: new Date(), action: false })
        }

        for (let i = 0; i < assignedTasks.length; i++) {
            let v = assignedTasks[i]

            let hasStarted = v.hasStarted(new Date())

            views.push({
                data: v,
                type: 'TASK_ASSIGNED',
                rank: 80,
                ts: hasStarted ? v.getEndTime() : v.getStartTime(),
                action: hasStarted && !v.isCompleted(new Date()),
            })
        }

        for (let i = 0; i < polls.length; i++) {
            let v = polls[i]

            views.push({ data: v, type: 'POLL', rank: v.ar ? 80 : 100, ts: getDateFromTimestamp(v.settings.luopen), action: !v.ar })
        }

        if (menu !== null) {
            views.push({ data: menu, type: 'menu', rank: 175, ts: getDateFromTimestamp(menu.cd), action: false })
        }

        if (shouldShowSetup()) {
            views.push({ type: 'CHAPTER_CREATION_WELCOME', rank: 400, action: true })
        }

        views.sort((a, b) => {
            if (a.action !== b.action) {
                return b.action ? 1 : -1
            }

            if (a.rank !== b.rank) {
                return b.rank > a.rank ? 1 : -1
            }

            return compareDates(a.ts, b.ts)
        })

        return views
    }

    function compareDates(a, b) {
        return Math.abs(new Date() - a) - Math.abs(new Date() - b)
    }

    const [chapterRep, setChapterRep] = React.useState(null)

    function getRepresentative() {
        if (chapter && chapter.settings && chapter.settings.representative) {
            const db = app.firestore()
            let representativeRef = db.collection('users').doc(chapter.settings.representative)
            representativeRef.get().then(doc => {
                if (doc.exists) {
                    let data = doc.data()
                    setChapterRep({ ...data, id: chapter.settings.representative })
                }
            })
        }
    }

    const getStartingCCWidgetProgress = () => {
        const steps = getChapterSetupSteps().map(step => step.id)
        const setup = getChapterSetup()

        if (!setup) {
            return { step: 0, completed: new Set(), ts: new Date().getTime() }
        }

        const completed = setup.complete.map(step => steps.indexOf(step)).filter(i => i >= 0)

        let incompleteSteps = steps.map((val, i) => i).filter(i => !completed.includes(i))

        const result = { step: incompleteSteps.length > 0 ? incompleteSteps[0] : steps.length, completed: new Set(completed), ts: setup.ts }

        return result
    }

    const getChapterSetupSteps = () => {
        const steps = [
            {
                id: 'welcome',
                label: 'Welcome to Greek Connect',
                content: index => (
                    <>
                        <Typography>
                            <WaveHandIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top', transform: 'scale(-1, 1)' }} /> Hey there, I'm{' '}
                            <strong>{chapterRep.first}</strong>, your chapter's representative with Greek Connect
                        </Typography>
                        <Typography>
                            You can chat with me from the <AdminIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} />{' '}
                            <strong>Admin</strong> page
                        </Typography>
                        <br />
                        <Typography>If you ever have any questions, please reach out to me!</Typography>
                        <br />
                        <ButtonBase
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={() => {
                                markCCStepComplete(index, true)
                                markSetupStepComplete('welcome')
                            }}
                        >
                            Next
                        </ButtonBase>
                    </>
                ),
            },
            {
                id: 'documentation',
                label: 'Check Out Documentation',
                content: index => (
                    <>
                        <Typography>
                            You can also checkout our <DocumentationIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} />{' '}
                            <strong>Documentation</strong> at any time if you need help
                        </Typography>
                        <br />
                        <Box style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                            <ButtonBase
                                color="primary"
                                variant="contained"
                                disableElevation
                                onClick={() => {
                                    markCCStepComplete(index, true)
                                    markSetupStepComplete('documentation')
                                }}
                            >
                                Next
                            </ButtonBase>
                            <ButtonBase
                                color="primary"
                                onClick={() => window.open('https://getgreekconnect.com/docs/getting-started/chapter-setup', '_blank')}
                                startIcon={<DocumentationIcon />}
                                endIcon={<ExternalLinkIcon />}
                            >
                                Documentation
                            </ButtonBase>
                        </Box>
                    </>
                ),
            },
            {
                id: 'members',
                label: 'Add Chapter Members',
                content: index => (
                    <>
                        <Typography>Let's add some members to your chapter!</Typography>
                        <br />
                        <Typography>
                            Go to <AdminIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} /> <strong>Admin</strong> and then click{' '}
                            <MemberManagementIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} /> <strong>Member Management</strong> to
                            start inviting members to your chapter
                        </Typography>
                        <br />
                        <Box style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                            <ButtonBase
                                color="primary"
                                variant="contained"
                                disableElevation
                                onClick={() => {
                                    if (chapter && chapter.members && Object.keys(chapter.members).length >= 3) {
                                        markCCStepComplete(index, true)
                                        markSetupStepComplete('members')
                                    } else {
                                        skipCCStep()
                                    }
                                }}
                            >
                                {chapter && chapter.members && Object.keys(chapter.members).length >= 3 ? 'Next' : 'Skip'}
                            </ButtonBase>
                            <ButtonBase
                                color="primary"
                                onClick={() => window.open('https://getgreekconnect.com/docs/admin/member-management', '_blank')}
                                startIcon={<DocumentationIcon />}
                                endIcon={<ExternalLinkIcon />}
                            >
                                Documentation
                            </ButtonBase>
                        </Box>
                    </>
                ),
            },
            {
                id: 'calendar',
                label: 'Create Calendar Event',
                content: index => (
                    <>
                        <Typography>Let's create your first calendar event!</Typography>
                        <br />
                        <Typography>
                            Go to <CalendarIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} /> <strong>Calendar</strong> and then click{' '}
                            <AddIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} /> <strong>New Event</strong> to start creating your
                            first event
                        </Typography>
                        <br />
                        <Box style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                            <ButtonBase color="primary" variant="contained" disableElevation onClick={() => skipCCStep()}>
                                {ccWidget.completed.has(index) ? 'Next' : 'Skip'}
                            </ButtonBase>
                            <ButtonBase
                                color="primary"
                                onClick={() => window.open('https://getgreekconnect.com/docs/calendar/event-creation', '_blank')}
                                startIcon={<DocumentationIcon />}
                                endIcon={<ExternalLinkIcon />}
                            >
                                Documentation
                            </ButtonBase>
                        </Box>
                    </>
                ),
            },
            {
                id: 'points',
                label: 'Add Points System',
                content: index => (
                    <>
                        <Typography>Let's create a points system!</Typography>
                        <br />
                        <Typography>
                            Go to <PointsIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} /> <strong>Points</strong> then click{' '}
                            <AddIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} /> <strong>New Points System</strong> to create your
                            points system
                        </Typography>
                        <br />
                        <Box style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                            <ButtonBase color="primary" variant="contained" disableElevation onClick={() => skipCCStep()}>
                                {ccWidget.completed.has(index) ? 'Next' : 'Skip'}
                            </ButtonBase>
                            <ButtonBase
                                color="primary"
                                onClick={() => window.open('https://getgreekconnect.com/docs/points/system-creation', '_blank')}
                                startIcon={<DocumentationIcon />}
                                endIcon={<ExternalLinkIcon />}
                            >
                                Documentation
                            </ButtonBase>
                        </Box>
                    </>
                ),
                hide: chapter && chapter.enabledFeatures && !chapter.enabledFeatures.points,
            },
            {
                id: 'messages',
                label: 'Create Messages Conversation',
                content: index => (
                    <>
                        <Typography>Let's create a messages conversation!</Typography>
                        <br />
                        <Typography>
                            Go to <MessagesIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} /> <strong>Messages</strong> then click{' '}
                            <AddIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} /> <strong>New Conversation</strong> to create your
                            first conversation
                        </Typography>
                        <br />
                        <Box style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
                            <ButtonBase color="primary" variant="contained" disableElevation onClick={() => skipCCStep()}>
                                {ccWidget.completed.has(index) ? 'Next' : 'Skip'}
                            </ButtonBase>
                            <ButtonBase
                                color="primary"
                                onClick={() => window.open('https://getgreekconnect.com/docs/messages/creation', '_blank')}
                                startIcon={<DocumentationIcon />}
                                endIcon={<ExternalLinkIcon />}
                            >
                                Documentation
                            </ButtonBase>
                        </Box>
                    </>
                ),
                hide: chapter && chapter.enabledFeatures && !chapter.enabledFeatures.messages,
            },
            {
                id: 'finish',
                label: 'Finish Chapter Setup',
                content: index => (
                    <>
                        <Typography>
                            <ConfettiIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-top' }} />
                            {` `}
                            You have almost finished setting up your chapter
                        </Typography>
                        <Typography>
                            Click <strong>finish</strong> below to mark your chapter's setup as complete
                        </Typography>
                        <ButtonBase
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={() => {
                                markSetupComplete()
                                setCCWidget(widget => ({ ...widget, finished: true }))
                            }}
                        >
                            Finish
                        </ButtonBase>
                    </>
                ),
            },
        ]

        return steps.filter(step => !step.hide)
    }

    // Chapter Creation Widget
    const [ccWidget, setCCWidget] = React.useState(getStartingCCWidgetProgress())

    const isCCStepCompleted = index => ccWidget.completed.has(index)

    const markCCStepComplete = (index, increment) =>
        setCCWidget(widget => ({
            ...widget,
            step: widget.step === index && increment ? widget.step + 1 : widget.step,
            completed: new Set(widget.completed).add(index),
        }))

    const skipCCStep = () =>
        setCCWidget(widget => ({
            ...widget,
            step: widget.step + 1,
        }))

    function renderView(view) {
        if (view.type === 'ANNOUNCEMENT') {
            let announcement = view.data

            return (
                <Collapse key={announcement.id} classes={{ root: classes.componentBoxContainer }}>
                    <Grow appear in>
                        <Box className={classes.componentBox}>
                            <Box className={classes.widgetParent}>
                                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                    <Box className={classes.widgetTop}>
                                        <Box className={classes.widgetTopTitle}>
                                            {getAvatar(announcement.sender.photoURL, announcement.sender.id)}
                                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography
                                                    style={{ fontWeight: 500, lineHeight: 1 }}
                                                >{`${announcement.sender.first} ${announcement.sender.last}`}</Typography>
                                                <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                                                    <AnnouncementsIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-bottom' }} />
                                                    {` • ${timeAgo(getDateFromTimestamp(announcement.sentTime))}`}
                                                    {announcement.pin && (
                                                        <>
                                                            {` • `}
                                                            <PushPinFilledIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-bottom' }} />
                                                        </>
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.widgetBody}>
                                        <Box style={{ wordBreak: `break-word` }}>
                                            <MuiLinkify>{announcement.text}</MuiLinkify>
                                        </Box>
                                    </Box>
                                </Widget>
                            </Box>
                        </Box>
                    </Grow>
                </Collapse>
            )
        }

        if (view.type === 'BIRTHDAY') {
            let birthdayUsers = view.data

            return (
                <Collapse key="BIRTHDAYS" classes={{ root: classes.componentBoxContainer }}>
                    <Grow appear in>
                        <Box className={classes.componentBox}>
                            <Box className={classes.widgetParent}>
                                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                    <Box className={classes.widgetTop}>
                                        <Box className={classes.widgetTopTitle}>
                                            <Avatar className={classes.widgetAvatar} style={{ backgroundColor: '#1e88e5', marginRight: 6 }}>
                                                <BirthdayIcon style={{ color: 'white' }} />
                                            </Avatar>
                                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography style={{ fontWeight: 500, lineHeight: 1 }}>Birthdays</Typography>
                                                <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                                                    Coming up soon
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.widgetBody}>
                                        <Grid container spacing={2}>
                                            {birthdayUsers.map((user, index) => (
                                                <Grid
                                                    item
                                                    xs={getSize(6, birthdayUsers.length, index)}
                                                    sm={getSize(4, birthdayUsers.length, index)}
                                                    md={getSize(3, birthdayUsers.length, index)}
                                                    key={`birthday.${user.id}`}
                                                >
                                                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden' }}>
                                                        <Avatar src={user.photoURL} style={{ width: 44, height: 44 }} />
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={{
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                maxWidth: '100%',
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            {`${user.first} ${user.last}`}
                                                        </Typography>
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={{
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                maxWidth: '100%',
                                                            }}
                                                        >
                                                            {user._renderedBirthday}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Widget>
                            </Box>
                        </Box>
                    </Grow>
                </Collapse>
            )
        }

        if (view.type === 'menu' || view.type === 'events') {
            return (
                <Collapse key={view.type === 'events' ? view.data.id : 'menu'} classes={{ root: classes.componentBoxContainer }}>
                    <Grow appear in>
                        <Box className={classes.componentBox}>
                            <Box className={classes.widgetParent}>
                                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                    <GCWidget type={view.type} {...view.data} history={history} isDifferentTimezone={isDifferentTimezone} />
                                </Widget>
                            </Box>
                        </Box>
                    </Grow>
                </Collapse>
            )
        }

        if (view.type === 'CHAPTER_CREATION_WELCOME') {
            // CHAPTER_CREATION_WELCOME

            if (chapterRep === null) {
                getRepresentative()
                setChapterRep(false)
            }

            if (!chapterRep) {
                return <></>
            }

            const StepperStyled = withStyles({
                line: {
                    minHeight: 0,
                },
            })(StepConnector)

            const steps = getChapterSetupSteps()

            return (
                <Collapse key="chapter_creation_welcome" classes={{ root: classes.componentBoxContainer }}>
                    <Grow appear in>
                        <Box className={classes.componentBox}>
                            <Box className={classes.widgetParent}>
                                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                    <Box className={classes.widgetTop}>
                                        <Box className={classes.widgetTopTitle}>
                                            {getAvatar(chapterRep.photoURL, chapterRep.id, { disableLink: true })}
                                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography style={{ fontWeight: 500, lineHeight: 1 }}>{`${chapterRep.first} ${chapterRep.last}`}</Typography>
                                                <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                                                    <WaveHandIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-bottom' }} />
                                                    {` • `}
                                                    {timeAgo(ccWidget.ts)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.widgetBody}>
                                        <Collapse in={!ccWidget.finished}>
                                            <Stepper
                                                activeStep={ccWidget.step}
                                                orientation="vertical"
                                                className={classes.stepper}
                                                connector={<StepperStyled />}
                                            >
                                                {steps.map((step, index) => (
                                                    <Step key={step.id} completed={isCCStepCompleted(index)}>
                                                        <StepLabel
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => setCCWidget(widget => ({ ...widget, step: index }))}
                                                        >
                                                            {step.label}
                                                        </StepLabel>
                                                        <StepContent>{step.content(index)}</StepContent>
                                                    </Step>
                                                ))}
                                            </Stepper>
                                        </Collapse>
                                        <Collapse in={ccWidget.finished}>
                                            <Box style={{ display: 'flex', flexDirection: 'column', gap: 8, justifyContent: 'center' }}>
                                                <ConfettiIcon style={{ width: `3rem`, height: `3rem` }} />
                                                <Typography>Chapter Setup Complete</Typography>
                                                <Typography>Welcome to Greek Connect</Typography>
                                            </Box>
                                        </Collapse>
                                    </Box>
                                </Widget>
                            </Box>
                        </Box>
                    </Grow>
                </Collapse>
            )
        }

        if (view.type === 'POINT_SYSTEM') {
            let system = view.data

            let userPoints = system.getPoints(user.getId())

            let goal = 100
            let progress = 0

            if (system.getType() === Points.TYPE_GOAL) {
                goal = system.goal

                progress = (userPoints / goal) * 100

                if (progress > 100) {
                    progress = 100
                } else if (progress < 0) {
                    progress = 0
                }
            }

            const hasPointsAdmin = () => {
                if (chapter && chapter.members && user) {
                    let mem = chapter.members[user.getId()]
                    if (mem) {
                        return (
                            mem.role === 'ADMIN' ||
                            (Array.isArray(chapter.perms['pointsAdmin']) && chapter.perms['pointsAdmin'].includes(mem.role)) ||
                            (
                                Array.isArray(chapter.perms['pointsCreate']) &&
                                chapter.perms['pointsCreate'].includes(mem.role) &&
                                system &&
                                system.author &&
                                user.getId() === system.author.id
                            )(
                                Array.isArray(chapter.perms['pointsCreate']) &&
                                    chapter.perms['pointsCreate'].includes(mem.role) &&
                                    system &&
                                    Array.isArray(system.admins) &&
                                    system.admins.includes(user.getId()),
                            )
                        )
                    }
                }

                return false
            }

            return (
                <Collapse key={system.id} classes={{ root: classes.componentBoxContainer }}>
                    <Grow appear in>
                        <Box className={classes.componentBox}>
                            <Box className={classes.widgetParent}>
                                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                    <Box className={classes.widgetTop}>
                                        <Box className={classes.widgetTopTitle}>
                                            {getAvatar(system.author.photo, system.author.id)}
                                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography
                                                    style={{ fontWeight: 500, lineHeight: 1 }}
                                                >{`${system.author.first} ${system.author.last}`}</Typography>
                                                <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                                                    <PointsIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-bottom' }} />
                                                    {` • ${timeAgo(getDateFromTimestamp(system.cd))}`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.widgetBody}>
                                        <Typography align="center" style={{ fontWeight: 500 }}>
                                            {system.name}
                                        </Typography>
                                        <Typography align="center" style={{ fontWeight: 500 }}>{`${userPoints}${
                                            system.getType() === Points.TYPE_GOAL ? `/${goal}` : ''
                                        } Points`}</Typography>
                                        {system.getType() === Points.TYPE_GOAL && (
                                            <LinearProgress
                                                variant="determinate"
                                                value={progress}
                                                style={{ flexGrow: 1 }}
                                                classes={{
                                                    barColorPrimary: classes.progressBar,
                                                }}
                                                className={classes.progress}
                                            />
                                        )}
                                        {hasPointsAdmin && system.allowRequests && (
                                            <Typography align="center">Open Requests: {system.getNumOpenRequests()}</Typography>
                                        )}
                                    </Box>
                                    <Divider />
                                    <Box className={classes.widgetBottom}>
                                        <ButtonBase
                                            color="primary"
                                            className={classes.widgetAction}
                                            onClick={() => history.push(`/app/applications/points/view/${system.id}`)}
                                        >
                                            View
                                        </ButtonBase>
                                    </Box>
                                </Widget>
                            </Box>
                        </Box>
                    </Grow>
                </Collapse>
            )
        }

        if (view.type === 'POLL') {
            let poll = view.data

            return (
                <Collapse key={poll.id} classes={{ root: classes.componentBoxContainer }}>
                    <Grow appear in>
                        <Box className={classes.componentBox}>
                            <Box className={classes.widgetParent}>
                                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                    <Box className={classes.widgetTop}>
                                        <Box className={classes.widgetTopTitle}>
                                            {getAvatar(poll.author.photo, poll.author.id)}
                                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography style={{ fontWeight: 500, lineHeight: 1 }}>{`${poll.author.first} ${poll.author.last}`}</Typography>
                                                <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                                                    <PollsIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-bottom' }} />
                                                    {` `}
                                                    {poll.settings.luopen ? ` • ${timeAgo(getDateFromTimestamp(poll.settings.luopen))}` : ''}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.widgetBody}>
                                        <Typography align="center" style={{ fontWeight: 500 }}>
                                            {poll.title ? poll.title : 'Untitled'}
                                        </Typography>
                                        <Typography align="center">{poll.description}</Typography>
                                        <Typography align="center">{poll.ar ? 'Already Responded' : 'Not Yet Responded'}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.widgetBottom}>
                                        <ButtonBase
                                            color="primary"
                                            className={classes.widgetAction}
                                            onClick={() => {
                                                history.push(`/app/applications/polls/view/${user.getChapter()}/${poll.id}`)
                                            }}
                                        >
                                            View
                                        </ButtonBase>
                                    </Box>
                                </Widget>
                            </Box>
                        </Box>
                    </Grow>
                </Collapse>
            )
        }

        if (view.type === 'TASK_ASSIGNED') {
            let task = view.data

            return (
                <Collapse key={task.id} classes={{ root: classes.componentBoxContainer }}>
                    <Grow appear in>
                        <Box className={classes.componentBox}>
                            <Box className={classes.widgetParent}>
                                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                    <Box className={classes.widgetTop}>
                                        <Box className={classes.widgetTopTitle}>
                                            {getAvatar(task.author.photo, task.author.id)}
                                            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography style={{ fontWeight: 500, lineHeight: 1 }}>{`${task.author.first} ${task.author.last}`}</Typography>
                                                <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                                                    <TasksIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-bottom' }} />
                                                    {` • `}
                                                    <AssignedIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-bottom' }} />
                                                    {` ${task.getAssigneesCaption(user, true)}`}
                                                    {` • `}
                                                    <TimeIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-bottom' }} />
                                                    {` ${task.getTiming()}`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.widgetBody}>
                                        <Typography style={{ fontWeight: 'bold' }} align="center">
                                            {task.name}
                                        </Typography>
                                        <Typography align="center">{task.getStatus()}</Typography>
                                    </Box>
                                    <Divider />
                                    <Box className={classes.widgetBottom}>
                                        <ButtonBase
                                            color="primary"
                                            className={classes.widgetAction}
                                            onClick={() => {
                                                history.push(`/app/applications/tasks/${task.id}`)
                                            }}
                                        >
                                            View
                                        </ButtonBase>
                                    </Box>
                                </Widget>
                            </Box>
                        </Box>
                    </Grow>
                </Collapse>
            )
        }

        return <></>
    }

    function getCache() {
        const defaultCache = {
            uid: user.getId(),
            points: {
                systemsLS: {},
            },
            meals: {
                mrweek: '',
            },
            birthdays: {
                mrdate: '',
            },
        }

        let cache = localStorage.getItem('cache_myHouse')

        if (cache) {
            cache = JSON.parse(cache)
            if (cache.uid !== user.getId()) {
                cache = defaultCache
            }
        } else {
            cache = defaultCache
        }
        return cache
    }

    function isCacheOld(cacheTimestamp, numDays) {
        let timeDiff = Math.floor((new Date() - cacheTimestamp) / 1000 / 60)
        return timeDiff > 60 * 24 * numDays || isNaN(timeDiff)
    }

    function shouldIgnoreMenu() {
        if (!cache.cache || !cache.cache.meals || !cache.cache.meals.mrweek) {
            return false
        }

        let curDate = new Date()

        return cache.cache.meals.mrweek === `${getWeekYear(curDate)}.${getWeek(curDate)}`
    }

    function shouldAskForReview() {
        let lastRequestedDate = localStorage.getItem('requestReviewDate') ? new Date(localStorage.getItem('requestReviewDate')) : null

        if (lastRequestedDate) {
            // 1000 = milliseconds -> seconds; 3600 = seconds -> hours; 24 = hours -> days
            let timeDiffDays = Math.floor((new Date() - lastRequestedDate) / 1000 / 3600 / 24)
            if (isNaN(timeDiffDays)) {
                localStorage.setItem('requestReviewDate', new Date().toString())
            } else if (timeDiffDays >= 3) {
                // They have used the app for at least 3 days since their last review
                localStorage.setItem('requestReviewDate', new Date().toString())
                RateApp.requestReview()
            }
        } else {
            localStorage.setItem('requestReviewDate', new Date().toString())
        }
    }

    function shouldIgnoreBirthdays() {
        if (!cache.cache || !cache.cache.birthdays || !cache.cache.birthdays.mrdate) {
            return false
        }

        const mrBirthdays = new Date(cache.cache.birthdays.mrdate)

        const isOld = mrBirthdays && isCacheOld(mrBirthdays, 1)

        return mrBirthdays && !isOld
    }

    function getIgnoredPointSystems() {
        let ignored = []

        const recentSystems = cache.cache.points.systemsLS
        let recentSystemIds = Object.keys(recentSystems)

        for (let i = 0; i < recentSystemIds.length; i++) {
            let recentSystemId = recentSystemIds[i]
            let recentSystem = recentSystems[recentSystemId]
            if (recentSystem) {
                let rsDate = new Date(recentSystem)
                if (!isCacheOld(rsDate, 3)) {
                    ignored.push(recentSystemId)
                }
            }
        }

        return ignored
    }

    function setCache(cName, cValue) {
        _setCache(c => {
            return { ...c, cache: { ...c.cache, [cName]: cValue }, status: 1, timer: 0 }
        })
    }

    function checkTimezoneDF() {
        let userTZ = moment.tz.guess()

        let chapterTZ = chapter && chapter.settings && chapter.settings.timezone ? chapter.settings.timezone : 'America/New_York'
        return userTZ !== chapterTZ
    }

    async function getPointSystems() {
        try {
            if (user && user.getChapter() && chapter && user.getId() in chapter.members) {
                const db = app.firestore()
                let querySnapshotPromise = db
                    .collection('chapters')
                    .doc(user.getChapter())
                    .collection('points')
                    .where('mType', '!=', 1)
                    .where('vis', 'array-contains', chapter.members[user.getId()].status)
                    .get()
                let querySnapshotCustomPromise = db
                    .collection('chapters')
                    .doc(user.getChapter())
                    .collection('points')
                    .where('mType', '==', 1)
                    .where(`mems`, 'array-contains', user.getId())
                    .get()

                const [querySnapshotResult, querySnapshotResultCustom] = await Promise.all([querySnapshotPromise, querySnapshotCustomPromise])

                let arr = getIgnoredPointSystems()

                let pointSystems = []
                let newlyIgnoredSystems = []

                querySnapshotResult.forEach(function(doc) {
                    let data = doc.data()
                    data.id = doc.id

                    if (!arr.includes(doc.id)) {
                        newlyIgnoredSystems.push(doc.id)
                        data = new Points(data)
                        pointSystems.push(data)
                    }
                })

                querySnapshotResultCustom.forEach(function(doc) {
                    let data = doc.data()
                    data.id = doc.id

                    if (!arr.includes(doc.id)) {
                        newlyIgnoredSystems.push(doc.id)
                        data = new Points(data)
                        pointSystems.push(data)
                    }
                })

                let newCache = _.cloneDeep(cache.cache)

                for (let i = 0; i < newlyIgnoredSystems.length; i++) {
                    newCache.points.systemsLS[newlyIgnoredSystems[i]] = new Date().toString()
                }

                setCache('points', newCache.points)

                setPointSystems(pointSystems)
                removeProgressItem('points', pointSystems.length === 0)
            }
        } catch (e) {
            console.log('got error', e)
        }
    }

    function getRelevantDates() {
        let arr = []
        let curMoment = moment()

        // Yesterday + 1 Week
        // curMoment.subtract(1, 'days')

        for (let i = 0; i < 7; i++) {
            arr.push(curMoment.format(`YYYY-MM-DD`))
            curMoment.add(1, 'days')
        }

        return arr
    }

    async function getCalendarEvents() {
        if (user && user.getChapter() && chapter && user.getId() in chapter.members) {
            const db = app.firestore()
            const querySnapshot = await db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('events')
                .where(`vis.${chapter.members[user.getId()].status}`, '==', true)
                .where(`datesArr`, 'array-contains-any', getRelevantDates())
                .orderBy('startDate')
                .limit(8)
                .get()

            let events = []

            querySnapshot.forEach(doc => {
                let data = doc.data()
                data.id = doc.id

                events.push(data)
            })

            setEvents(events)

            removeProgressItem('calendar', events.length === 0)
        }
    }

    async function getBirthdays() {
        if (user && user.getChapter() && chapter && user.getId() in chapter.members) {
            let sow = moment()
                .startOf('day')
                .toDate()
            let eow = moment()
                .endOf('day')
                .toDate()
            eow.setDate(eow.getDate() + 7)

            sow.setFullYear(2000)
            eow.setFullYear(2000)

            const db = app.firestore()
            let querySnapshot = db.collection('users').where('chapter', '==', user.getChapter())

            /* Keep == do not change to === */
            /* eslint eqeqeq: 0 */
            if (user.status == 4) {
                querySnapshot = querySnapshot.where('status', '==', 4)
            } else {
                querySnapshot = querySnapshot.where('status', 'in', [0, 1, 2, 3])
            }

            querySnapshot = await querySnapshot
                .where('_biy', '<', eow)
                .where('_biy', '>=', sow)
                .orderBy('_biy')
                .get()

            let users = []

            querySnapshot.forEach(doc => {
                let data = doc.data()
                data.id = doc.id

                let curDate = new Date()
                let bday = new Date(0)

                bday.setUTCSeconds(data.birthday.seconds ? data.birthday.seconds : data.birthday._seconds)
                curDate.setMonth(bday.getMonth(), bday.getDate())

                data._renderedBirthday = format(curDate, 'eeee (do)')
                data.bdayCurYear = curDate

                users.push(data)
            })

            let newCache = _.cloneDeep(cache.cache)

            newCache.birthdays.mrdate = new Date().toString()

            setCache('birthdays', newCache.birthdays)

            setBirthdayUsers(users)
            removeProgressItem('birthdays', users.length === 0)
        }
    }

    async function getRecurringWeeklyAssignedTasks() {
        if (user && user.getChapter() && chapter && user.getId() in chapter.members) {
            const db = app.firestore()
            const querySnapshot = await db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('tasks')
                .where('type', '==', 1)
                .where('assigneesArr', 'array-contains', user.getId())
                .limit(6)
                .get()

            let tsks = []

            querySnapshot.forEach(doc => {
                let data = doc.data()
                data.id = doc.id

                tsks.push(new Task(data))
            })

            setAssignedTasks(tsks)
            removeProgressItem('tasks', tsks.length === 0)
        }
    }

    async function getMenu() {
        if (user && user.getChapter() && chapter && user.getId() in chapter.members) {
            const db = app.firestore()
            const querySnapshot = await db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('menus')
                .where('week', '==', getWeek(new Date()))
                .where('year', '==', getWeekYear(new Date()))
                .get()

            let menu = null

            querySnapshot.forEach(doc => {
                let data = doc.data()
                data.id = doc.id

                menu = data
            })

            if (menu !== null) {
                setMenu(menu)

                let newCache = _.cloneDeep(cache.cache)

                let curDate = new Date()

                newCache.meals.mrweek = `${getWeekYear(curDate)}.${getWeek(curDate)}`

                setCache('meals', newCache.meals)
            }

            removeProgressItem('meals', menu === null)
        }
    }

    async function getPolls() {
        if (user && user.getChapter() && chapter && user.getId() in chapter.members) {
            const db = app.firestore()
            let pollsCollection = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('polls')
            let pollsRef = pollsCollection.doc('INFO')

            let chapterMember = chapter.members[user.getId()]

            pollsRef.get().then(function(pollsDoc) {
                if (pollsDoc.exists) {
                    let objectPolls = pollsDoc.data().polls
                    let polls = []
                    let keys = Object.keys(objectPolls)
                    let pollCount = 0
                    let curNum = 0
                    for (let i = 0; i < keys.length; i++) {
                        let id = keys[i]
                        let curItem = objectPolls[id]

                        if (curItem.open && curItem.vis.groups.includes(chapterMember.status) && !curItem.vis.roles.includes(chapterMember.role)) {
                            pollCount += 1

                            let pollRef = pollsCollection.doc(id)
                            pollRef
                                .get()
                                .then(function(pollDoc) {
                                    if (pollDoc.exists) {
                                        let pollData = pollDoc.data()
                                        pollData.id = id
                                        pollData.lastUpdated = curItem.lastUpdated

                                        pollData.ar = Array.isArray(pollData.submitted) && pollData.submitted.includes(user.getId())

                                        polls.push(pollData)
                                    }

                                    if (curNum >= pollCount - 1) {
                                        polls.sort(function(a, b) {
                                            return b.lastUpdated.seconds - a.lastUpdated.seconds
                                        })
                                        setPolls(polls)
                                    }

                                    curNum += 1
                                })
                                .catch(function(error) {
                                    // Doesn't have event permission
                                    curNum += 1
                                    if (curNum >= pollCount) {
                                        polls.sort(function(a, b) {
                                            return b.lastUpdated.seconds - a.lastUpdated.seconds
                                        })
                                        setPolls(polls)
                                    }
                                })
                        }
                    }

                    removeProgressItem('polls', pollCount === 0)
                } else {
                    removeProgressItem('polls', true)
                }
            })
        }
    }

    function getDateFromTimestamp(d) {
        if (d) {
            return new Date(0).setUTCSeconds(d.seconds ? d.seconds : d._seconds)
        }

        return new Date()
    }

    function getSize(minSize, num, index) {
        //           4        5    2
        // 5 items but 3 per row
        let numPerRow = 12 / minSize // 12 / 4 = 3
        let overflow = num % numPerRow // 5 % 3 = 2

        if (overflow !== 0 && index >= num - overflow) {
            // There's an uneven amount & we are in the last row
            return 12 / overflow
        }

        return minSize
    }

    function getTip() {
        if (false && canShowTip(user, 'profile_dark_theme')) {
            // Only valid for mobile devices now
            return (
                <Collapse key="tip_profile_dark_theme" classes={{ root: classes.componentBoxContainer }}>
                    <Grow appear in>
                        <Box className={classes.componentBox}>
                            <Tip id="profile_dark_theme" icon={<ThemeIcon />} description="Click your profile photo to toggle themes" />
                        </Box>
                    </Grow>
                </Collapse>
            )
        }

        if (canShowTip(user, 'profile_notifications')) {
            return (
                <Collapse key="tip_profile_notifications" classes={{ root: classes.componentBoxContainer }}>
                    <Grow appear in>
                        <Box className={classes.componentBox}>
                            <Tip id="profile_notifications" icon={<NotificationsIcon />} description="Click your profile photo to toggle notifications" />
                        </Box>
                    </Grow>
                </Collapse>
            )
        }

        if (canShowTip(user, 'desktop_app')) {
            return (
                <Collapse key="tip_desktop_app" classes={{ root: classes.componentBoxContainer }}>
                    <Grow appear in>
                        <Box className={classes.componentBox}>
                            <Tip id="desktop_app" icon={<LaptopIcon />} description="Visit GreekConnect.app for desktop access" />
                        </Box>
                    </Grow>
                </Collapse>
            )
        }

        return <></>
    }

    if (currentProgress.empty && currentProgress.searching.length === 0) {
        // If nothing was returned
        return (
            <>
                <Box style={{ margin: -8 }}>
                    <NavigationBar
                        titles={[
                            {
                                icon: <HomeIcon style={{ margin: 3 }} />,
                            },
                        ]}
                        rightSubtitle={moment(new Date()).format('ddd, MMM D')}
                        component
                    />
                </Box>
                <Box className={classes.componentBox} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 16,
                            flexGrow: 1,
                        }}
                    >
                        <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor }} />
                        <Typography style={{ color: theme.borderColor }}>There is nothing coming up soon</Typography>
                    </Box>
                </Box>
            </>
        )
    }

    return (
        <>
            <Box style={{ width: 'calc(100% + 16px)', margin: -8, display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box' }} className={classes.grid}>
                <TransitionGroup key="my_house" component={null}>
                    <Collapse key="navigation_bar" classes={{ root: classes.componentBoxContainer }}>
                        <NavigationBar
                            titles={[
                                {
                                    icon: <HomeIcon style={{ margin: 3 }} />,
                                },
                            ]}
                            rightSubtitle={moment(new Date()).format('ddd, MMM D')}
                            component
                        />
                    </Collapse>
                    {!currentProgress.empty && getTip()}
                    {showPWAWarning && (
                        <Collapse key="pwa_warning" classes={{ root: classes.componentBoxContainer }}>
                            <Grow appear in>
                                <Box className={classes.componentBox}>
                                    <Box className={classes.widgetParent}>
                                        <Widget
                                            disableWidgetMenu
                                            inheritHeight
                                            noBodyPadding
                                            paper
                                            style={{ width: 'fit-content', margin: '0 auto' }}
                                            paperClass={classes.downloadAppPaper}
                                        >
                                            <Box className={classes.downloadAppBox}>
                                                <Typography
                                                    variant="h6"
                                                    align="center"
                                                    style={{
                                                        fontWeight: 700,
                                                        fontSize: '2.5rem',
                                                        textShadow:
                                                            '0 1px 0 #cccccc, 0 2px 0 #bbbbbb, 0 3px 0 #aaaaaa, 0 4px 0 #999999, 0 0 5px rgba(215, 215, 215, .05)',
                                                    }}
                                                >
                                                    Whoops!
                                                </Typography>
                                                <IconButton
                                                    size="small"
                                                    style={{ position: 'absolute', top: 8, right: 8 }}
                                                    onClick={() => {
                                                        setShowPWAWarning(false)
                                                        localStorage.setItem('cache_pwa_warning', new Date().toString())
                                                    }}
                                                >
                                                    <CancelIcon fontSize="large" style={{ color: 'white' }} />
                                                </IconButton>
                                                <Typography align="center">you are using the progressive web app</Typography>
                                                <Typography style={{ fontWeight: 500, paddingTop: 16 }}>Go native for a better experience</Typography>
                                                <Typography style={{ paddingTop: 4, paddingBottom: 4 }}>
                                                    <CheckIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
                                                    Improved messaging experience
                                                </Typography>
                                                <Typography style={{ paddingTop: 4, paddingBottom: 4 }}>
                                                    <CheckIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
                                                    Faster performance
                                                </Typography>{' '}
                                                <Typography style={{ paddingTop: 4, paddingBottom: 4 }}>
                                                    <CheckIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
                                                    Push notifications
                                                </Typography>
                                                <Typography style={{ paddingTop: 4, paddingBottom: 4 }}>
                                                    <CheckIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
                                                    and more!
                                                </Typography>
                                                {isIOS ? (
                                                    <>
                                                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <ButtonBase
                                                                component={Link}
                                                                target="_blank"
                                                                href="https://apps.apple.com/us/app/greek-connect/id1576086717"
                                                                disableRipple
                                                                className={classes.downloadAppButton}
                                                            >
                                                                <img className={classes.downloadAppBadge} src={appStoreBadge} alt="App Store" />
                                                            </ButtonBase>
                                                        </Box>
                                                        <Typography variant="caption" align="center">
                                                            App Store is a trademark of Apple Inc.
                                                        </Typography>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography color="orange" align="center" style={{ paddingTop: 12, paddingBottom: 4 }}>
                                                            <WarningIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
                                                            Google Sign In is still in development on Native Android
                                                        </Typography>
                                                        <Typography color="orange" align="center" style={{ paddingTop: 4, paddingBottom: 4 }}>
                                                            <WarningIcon style={{ verticalAlign: 'middle', marginRight: 4 }} />
                                                            To sign in on Android, put your BA/AM email address in the email address then click "Forgot/Setup
                                                            Password" to setup an account password
                                                        </Typography>
                                                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <ButtonBase
                                                                component={Link}
                                                                target="_blank"
                                                                href="https://play.google.com/store/apps/details?id=com.greekconnect.app"
                                                                disableRipple
                                                                className={classes.downloadAppButton}
                                                            >
                                                                <img className={classes.downloadAppBadge} src={googlePlayBadge} alt="Google Play" />
                                                            </ButtonBase>
                                                        </Box>
                                                        <Typography variant="caption" align="center">
                                                            Google Play and the Google Play logo are trademarks of Google LLC.
                                                        </Typography>
                                                    </>
                                                )}
                                            </Box>
                                        </Widget>
                                    </Box>
                                </Box>
                            </Grow>
                        </Collapse>
                    )}
                    {getViews().map(view => renderView(view))}
                </TransitionGroup>
            </Box>
        </>
    )
}
