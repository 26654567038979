import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 10,
        width: '100%',
        overflow: 'scroll',
    },
    exitButton: {
        position: 'absolute',
        right: 16,
        bottom: 16,
        width: 48,
        height: 48,
        zIndex: theme.zIndex.drawer + 20,
        backgroundColor: 'rgba(0,0,0,0.4)',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
    },
    parentBox: {
        minWidth: `100%`,
        minHeight: `100%`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    buttonBox: {
        left: 0, //calc(80px + env(safe-area-inset-left))
        top: 0, //'calc(80px + env(safe-area-inset-top))'
        position: `fixed`,
    },
    buttonBoxInner: {
        paddingTop: 'calc(80px + env(safe-area-inset-top))',
        paddingLeft: 'calc(80px + env(safe-area-inset-left))',
    },
    obj: {
        width: '100vw',
        height: '100vh',
        objectFit: 'contain',
        touchAction: 'auto',
    },
    img: {
        maxWidth: '100vw',
        maxHeight: '100vh',
        objectFit: 'contain',
        touchAction: 'auto',
    },
    pdfDocument: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        alignItems: 'center',
        paddingTop: `calc(72px + env(safe-area-inset-top))`, // 16px for margin + 56px for appbar
        paddingBottom: `calc(16px + env(safe-area-inset-bottom))`,
    },
    pdfPage: {
        maxWidth: '100%',
        '& > canvas': {
            paddingLeft: `calc(16px + env(safe-area-inset-left))`,
            paddingRight: `calc(16px + env(safe-area-inset-right))`,
        },
    },
}))
