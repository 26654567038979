export const cutoffs = {
    540: '9:00 AM',
    555: '9:15 AM',
    570: '9:30 AM',
    585: '9:45 AM',
    600: '10:00 AM',
    615: '10:15 AM',
    630: '10:30 AM',
    645: '10:45 AM',
    660: '11:00 AM',
    675: '11:15 AM',
    690: '11:30 AM',
    705: '11:45 AM',
    720: '12:00 PM',
    735: '12:15 PM',
    750: '12:30 PM',
    765: '12:45 PM',
    780: '1:00 PM',
    795: '1:15 PM',
    810: '1:30 PM',
    825: '1:45 PM',
    840: '2:00 PM',
    855: '2:15 PM',
    870: '2:30 PM',
    885: '2:45 PM',
    900: '3:00 PM',
    915: '3:15 PM',
    930: '3:30 PM',
    945: '3:45 PM',
    960: '4:00 PM',
    975: '4:15 PM',
    990: '4:30 PM',
    1005: '4:45 PM',
    1020: '5:00 PM',
    1035: '5:15 PM',
    1050: '5:30 PM',
    1065: '5:45 PM',
    1080: '6:00 PM',
    1095: '6:15 PM',
    1110: '6:30 PM',
    1125: '6:45 PM',
    1140: '7:00 PM',
    1155: '7:15 PM',
    1170: '7:30 PM',
    1185: '7:45 PM',
    1200: '8:00 PM',
    1215: '8:15 PM',
    1230: '8:30 PM',
    1245: '8:45 PM',
    1260: '9:00 PM',
    1275: '9:15 PM',
    1290: '9:30 PM',
    1305: '9:45 PM',
}
