import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    sectionNoHighlight: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `0px !important`,
    },
    section: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
        '&:hover': {
            background: 'rgba(150, 150, 150, 0.16)',
            cursor: 'pointer',
        },
    },
    title: {
        fontSize: 36,
    },
    subtitle: {
        fontSize: 20,
    },
    subheader: {
        fontSize: 16,
    },
    pollDetails: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
    },
    viewPrevResponses: {
        color: theme.palette.primary.main,
    },
    innerPadding: {
        paddingLeft: 12,
        paddingRight: 12,
        display: 'block',
    },
    homeIcon: {
        backgroundColor: 'white',
        boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        color: 'rgba(0, 0, 0, 0.87)',
        '&:active': {
            boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        },
        margin: 0,
        top: 'auto',
        right: 24,
        bottom: 24,
        left: 'auto',
        position: 'fixed',
    },
    backIcon: {
        margin: 0,
        bottom: 'calc(24px + env(safe-area-inset-bottom))',
        left: 'calc(24px + env(safe-area-inset-left))',
        top: 'auto',
        right: 'auto',
        position: 'fixed',
    },
    editIcon: {
        margin: 0,
        top: 'auto',
        right: 24,
        bottom: 24,
        left: 'auto',
        position: 'fixed',
    },
    headerMenuButton: {
        padding: theme.spacing(0.5),
    },
    viewPollParent: {
        paddingTop: `env(safe-area-inset-top)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
        paddingBottom: `env(safe-area-inset-bottom)`,
    },
    chipHolder: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },

    /* View Poll */
    viewsBox: {
        flexGrow: 1,
        overflow: 'hidden',
        '& > div': {
            '& > div': {
                height: '100%',
            },
            height: '100%',
        },
    },
    scrollableContainer: {
        height: '100%',
        paddingTop: 16,
        paddingBottom: 16,
    },
    scrollableBox: { width: '100%', display: 'flex', flexDirection: 'column', gap: 16, height: '100%' },
    enabledFeatures: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        overflowY: 'scroll',
        overflowX: 'hidden',
        margin: '-16px 0',
        paddingTop: 16,
        paddingBottom: 16,
        '&::-webkit-scrollbar': {
            '-webkit-appearance': 'none',
            display: 'none',
        },
    },
    closeDivFullScreen: {
        right: 'env(safe-area-inset-right)',
        top: 'calc(32px + env(safe-area-inset-top))',
        position: `absolute`,
    },
    iconClose: {
        right: 16,
        bottom: 16,
        width: 48,
        height: 48,
        zIndex: theme.zIndex.drawer + 2,
        backgroundColor: 'rgba(0,0,0,0.4)',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
    },
    table: {
        '& .MuiDataGrid-root': {
            color: 'inherit',
        },
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-withBorderColor': {
            outline: 'none !important',
        },
    },
}))
