import React from 'react'

import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { ImageOutlined as ImageIcon } from '@material-ui/icons'
import { Document, Page, pdfjs } from 'react-pdf'

import FileTypes from 'objects/FileTypes'

import { resizeImage, uploadURI } from 'code/Image'
import { generateUUID } from 'code/UUID'

import { isMobileDevice } from 'code/Helper'

// styles
import useStyles from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `${isMobileDevice() ? 'https:' : ''}//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const FileUpload = ({ allowedTypes, folderPath, onUploadImage, onDeleteImage }) => {
    var classes = useStyles()

    const fileTypes = new FileTypes(allowedTypes)

    const inputRef = React.useRef(null)

    let [file, setFile] = React.useState(null)
    let [image, setImage] = React.useState(null)
    let [isPDF, setIsPDF] = React.useState(false)

    const uploadImage = async image => {
        let url = await uploadPossiblePhoto(image)
        onUploadImage(url)
    }

    const uploadPossiblePhoto = async image => {
        let path = `${folderPath}/${generateUUID()}.jpg`

        const uri = await resizeImage(image, 1024, 1024, 'JPEG', 80)
        const url = await uploadURI(uri, path)

        return url
    }

    function fileListToArray(list) {
        const array = []
        for (var i = 0; i < list.length; i++) {
            array.push(list.item(i))
        }
        return array
    }

    function onDrop(e) {
        const files = e.dataTransfer.files
        const array = fileListToArray(files)
        if (!file && array[0]) {
            if (fileTypes.getRaw().includes(array[0].type)) {
                addFile(array[0])
            }
        }
        e.preventDefault()
    }

    function onFilesAdded(event) {
        const files = event.target.files
        const array = fileListToArray(files)
        if (!file && array[0]) {
            addFile(array[0])
        }
    }

    function addFile(file) {
        setFile(URL.createObjectURL(file))

        if (file.type !== 'application/pdf') {
            uploadImage(file)
            setIsPDF(false)
        } else {
            setIsPDF(true)
        }
    }

    function delFile() {
        if (file != null) {
            setFile(null)
            setImage(null)
            if (inputRef.current) {
                inputRef.current.value = null
            }
            onDeleteImage()
        }
    }

    // TODO: Upload image to proper location automatically

    return (
        <Box>
            <input
                type="file"
                multiple={false}
                style={{ display: 'none' }}
                ref={inputRef}
                onChange={event => onFilesAdded(event)}
                accept={fileTypes.getSystemFacingTypes()}
            />
            <Box
                className={classes.uploadPhoto}
                onClick={() => {
                    if (!file) {
                        if (inputRef.current) {
                            inputRef.current.click()
                        }
                    }
                }}
                style={{ cursor: file ? 'default' : 'pointer' }}
                onDrop={e => onDrop(e)}
                onDragOver={e => {
                    e.preventDefault()
                }}
            >
                <Box className={classes.uploadPhotoPic} id="pdfRenderMenu">
                    {file ? (
                        <>
                            {isPDF && (
                                <>
                                    <Box style={{ position: 'absolute', left: -9999, top: -9999 }}>
                                        <Document loading="" renderMode="canvas" file={file}>
                                            <Page
                                                pageNumber={1}
                                                renderTextLayer={false}
                                                height={1960}
                                                onRenderSuccess={() => {
                                                    const canvas = document.getElementById('pdfRenderMenu').children[0].children[0].children[0].children[0]

                                                    canvas.toBlob(function(blob) {
                                                        uploadImage(blob)
                                                    })
                                                }}
                                            />
                                        </Document>
                                    </Box>
                                </>
                            )}
                            {image ? (
                                <img src={image} className={classes.menuImage} alt="" />
                            ) : (
                                <Box style={{ height: 196, width: '100%', display: 'flex' }}>
                                    <CircularProgress size={28} style={{ margin: 'auto' }} />
                                </Box>
                            )}
                        </>
                    ) : (
                        <ImageIcon className={classes.uploadPhotoPicInner} />
                    )}
                </Box>
                <Box className={classes.uploadPhotoDesc}>
                    {file ? (
                        <>
                            {onDeleteImage && (
                                <Button onClick={() => delFile()} color="primary" style={{ margin: '0 auto' }}>
                                    <Typography align="center">Delete File</Typography>
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            <Typography style={{ userSelect: 'none', WebkitUserSelect: 'none' }} align="center">
                                Drag a file or click to upload
                            </Typography>
                            <Typography style={{ userSelect: 'none', WebkitUserSelect: 'none' }} align="center">
                                {fileTypes.getUserFacingTypes()}
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default FileUpload
