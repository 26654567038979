import React from 'react'

import { Box, Button, ButtonBase, Divider, Grid, IconButton, Snackbar, Tooltip } from '@material-ui/core'

import {
    Add as AddIcon,
    CalendarToday as CalendarIcon,
    ExpandLess as ExpandHiddenIcon,
    ExpandMore as ExpandShownIcon,
    HomeOutlined as HomeIcon,
    LaptopMac as LaptopIcon,
    Settings as SettingsIcon,
} from '@material-ui/icons'
import { ShareIosIcon } from 'components/Icons'

import { isIOSDevice, isMobileDevice } from 'code/Helper'
import { canShowTip } from 'code/Tips'

import copy from 'clipboard-copy'

import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { ThemeStateContext } from 'context/ThemeContext'

// components
import WarningTypography from 'components/Typography/WarningTypography'
import { compareDates, getEventMoment, getEvents, getFirebaseDate, getFirebaseEndDate } from '../../../code/TimeAgo'
import Calendar from '../../../components/Calendar'
import Event from '../../../components/Calendar/Event'
import NavigationBar from '../../../components/NavigationBar'
import Tabs from '../../../components/Tabs'
import Tip from '../../../components/Tip'
import Widget from '../../../components/Widget'
import { AuthContext } from '../../../context/AuthContext'
import { ChapterContext } from '../../../context/ChapterContext'

import {
    Dialog as DialogMobile,
    DialogActions as DialogActionsMobile,
    DialogContent as DialogContentMobile,
    DialogTitle as DialogTitleMobile,
    Link,
    Typography,
} from 'components/Wrappers'

import MuiAlert from '@mui/material/Alert'

import moment from 'moment'

// styles
import { useTheme } from '@material-ui/styles'
import useStyles from './styles'

export default function UpcomingEvents(props) {
    var classes = useStyles()
    var theme = useTheme()

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }

    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)
    const curTheme = React.useContext(ThemeStateContext)

    const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'))

    const [isSearching, setIsSearching] = React.useState(false)
    const [eventResults, setEventResults] = React.useState(null)
    const [curSearch, setCurSearch] = React.useState('')
    const [isCalendarView, setIsCalendarView] = React.useState(
        localStorage.getItem('calendarView') !== null ? localStorage.getItem('calendarView') === 'CALENDAR_VIEW' : true,
    )
    const [_date, _setDate] = React.useState(moment())

    const date = props.match.params.date && isMobileDevice() ? moment(props.match.params.date) : _date

    function setDate(date) {
        if (isMobileDevice()) {
            let _date = typeof date === 'string' ? date : date.format('YYYY-MM-DD')
            props.history.replace(`/app/calendar/${_date}`)
        } else {
            if (typeof date === 'string') {
                _setDate(moment(date))
            } else {
                _setDate(date)
            }
        }
    }

    const [exportCalendarDialog, setExportCalendarDialog] = React.useState({ open: false })

    const [snackbar, setSnackbar] = React.useState(null)

    const dateText = date.calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'MMMM D', //dddd,
    })

    function isUserCalendarAdmin() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || chapter.perms['calendarAdmin'].includes(mem.role)
            }
        }

        return false
    }

    function isUserCalendarCreate() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || chapter.perms['calendarAdmin'].includes(mem.role) || chapter.perms['calendarCreate'].includes(mem.role)
            }
        }

        return false
    }

    let dateEvents = getEvents(date, eventResults)

    if (!eventResults) {
        if (chapter && chapter.events) {
            let hasAdmin = isUserCalendarAdmin()
            let events = []
            let eventIDs = Object.keys(chapter.events)
            for (let i = 0; i < eventIDs.length; i++) {
                let e = chapter.events[eventIDs[i]]
                e.id = eventIDs[i]
                if (
                    hasAdmin ||
                    e.visibility === undefined ||
                    e.visibility === null ||
                    (user.id in chapter.members && user.id in chapter.members && e.visibility.includes(chapter.members[user.id].status))
                ) {
                    events.push(e)
                }
            }
            events.sort((a, b) => {
                return compareDates(a, b)
            })
            setEventResults(events)
        }
    }

    function hasEventEnded(curEvent) {
        if (curEvent) {
            if (curEvent.date.ad !== undefined) {
                let eventEnd = getFirebaseEndDate(curEvent)
                let diff = new Date() - eventEnd
                return diff >= 0
            } else {
                if (curEvent.length > 0) {
                    let eventStart = getFirebaseDate(curEvent)
                    eventStart = new Date(eventStart.getTime() + curEvent.length * 1000 * 60)
                    let diff = new Date() - eventStart
                    return diff >= 0
                } else if (curEvent.length === 0) {
                    let time = getFirebaseDate(curEvent)
                    //All day
                    time.setDate(time.getDate() + 1)
                    let diff = new Date() - time
                    return diff >= 0
                }
            }
        }

        // If event length is less than 0 that means there is not any specific length
        return false
    }

    function viewEvent(history, eventID) {
        history.push('/app/calendar/event/' + eventID)
    }

    let count = 0

    function closeExportCalendarDialog() {
        StatusBar.setStyle({
            style: StatusBarStyle.Dark,
        })
        setExportCalendarDialog({ open: false })
    }

    const getNavBarKey = () => {
        let val = 'key'

        if (isUserCalendarCreate()) {
            val += 'add'
        }

        if (isUserCalendarAdmin()) {
            val += 'settings'
        }

        return val
    }

    function getButtons() {
        let buttons = [
            {
                name: 'Share',
                tooltip: 'Share Calendar',
                type: 'icon',
                innerIcon: <ShareIosIcon />,
                onClick: () => {
                    setExportCalendarDialog({ ...exportCalendarDialog, open: true, tab: 'sync' })
                    if (curTheme.palette.type !== 'dark') {
                        StatusBar.setStyle({
                            style: StatusBarStyle.Light,
                        })
                    }
                },
            },
        ]

        if (isUserCalendarCreate()) {
            buttons.push({
                name: 'Add',
                tooltip: 'New Event',
                type: 'icon',
                innerIcon: <AddIcon />,
                onClick: () => {
                    props.history.push('/app/calendar/event/new')
                },
            })
        }

        if (isUserCalendarAdmin()) {
            buttons.push({
                name: 'Settings',
                tooltip: 'Calendar Settings',
                type: 'icon',
                innerIcon: <SettingsIcon />,
                onClick: () => {
                    props.history.push('/app/calendar/settings')
                },
            })
        }

        return buttons
    }

    const updateDate = React.useCallback(day => setDate(day), [])

    const renderCalendar = () => {
        return <Calendar events={eventResults} date={date} setDate={updateDate} maxPastMonths={isUserCalendarCreate() ? 33 : 0} maxFutureMonths={11} />
        // return <></>
    }

    const renderEvents = () => (
        <>
            <Box className={classes.calendarWidgetHeader}>
                <>
                    <Typography variant="h5" style={{ flexGrow: 1, margin: `auto` }}>
                        {dateText}
                    </Typography>
                    {isUserCalendarCreate() && (
                        <Tooltip title="New Event" aria-label="New Event">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    props.history.push({ pathname: '/app/calendar/event/new', data: { date: date } })
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            </Box>
            {dateEvents.length === 0 ? (
                <>
                    <Box>
                        <Divider />
                    </Box>
                    <Box className={classes.calendarWidgetItem}>
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: 700,
                            }}
                        >
                            No events
                        </Typography>
                    </Box>
                </>
            ) : (
                dateEvents.map(e => (
                    <Box key={e.id}>
                        <Box>
                            <Divider />
                        </Box>
                        {isMobileDevice() ? (
                            <ButtonBase
                                onClick={() => {
                                    viewEvent(props.history, e.id)
                                }}
                                style={{ fontSize: 'unset', width: '100%' }}
                            >
                                <Box className={classes.calendarWidgetItem} style={{ width: '100%' }}>
                                    <Event event={e} showDate={!getEventMoment(e).isSame(date, 'day')} showLength={true} />
                                </Box>
                            </ButtonBase>
                        ) : (
                            <Link
                                underline="none"
                                onClick={() => {
                                    viewEvent(props.history, e.id)
                                }}
                            >
                                <Box className={classes.hover}>
                                    <Box className={classes.calendarWidgetItem}>
                                        <Event event={e} showDate={!getEventMoment(e).isSame(date, 'day')} showLength={true} />
                                    </Box>
                                </Box>
                            </Link>
                        )}
                    </Box>
                ))
            )}
        </>
    )

    const renderNoEvents = () => (
        <Box
            display="flex"
            flexDirection="column"
            style={{
                marginLeft: 24,
                marginRight: 24,
                marginTop: 8,
                marginBottom: 6,
            }}
        >
            <Grid container direction="row" justifyContent="center" alignItems="center" wrap={'nowrap'} style={{ overflowX: 'auto' }}>
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: 700,
                            textAlign: `center`,
                        }}
                    >
                        {curSearch === '' ? 'No upcoming events' : 'No events found'}
                    </Typography>
                </Box>
            </Grid>
        </Box>
    )

    const renderEventBox = e => (
        <Box
            display="flex"
            flexDirection="column"
            style={{
                marginLeft: 24,
                marginRight: 24,
                marginTop: 8,
                marginBottom: 6,
            }}
        >
            <Event event={e} showDate={true} />
        </Box>
    )

    const renderTabs = () => (
        <Tabs
            tabs={[
                { id: 'calendar', name: 'Month' },
                { id: 'list', name: 'List' },
            ]}
            selected={isCalendarView ? 'calendar' : 'list'}
            onChange={val => {
                setIsCalendarView(val === 'calendar')
                localStorage.setItem('calendarView', val === 'calendar' ? 'CALENDAR_VIEW' : 'LIST_VIEW')
            }}
            autoSize
        />
    )

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Calendar',
                            icon: atLeastSmall ? null : <CalendarIcon />,
                        },
                    ]}
                    rightButtons={getButtons()}
                    key={getNavBarKey()}
                    onSearch={text => setCurSearch(text ? text.toLowerCase() : '')}
                    onToggleSearch={val => {
                        setIsSearching(val)
                    }}
                    grid
                />
                {!isSearching && curSearch === '' && (
                    <>
                        <Grid item xs={12}>
                            {isMobileDevice() ? (
                                <Box style={{ marginLeft: -16, marginRight: -16, marginTop: -8, borderBottom: theme.border }}>{renderTabs()}</Box>
                            ) : (
                                <Box
                                    style={{
                                        maxWidth: 480,
                                        margin: `0 auto`,
                                    }}
                                >
                                    <Widget disableWidgetMenu inheritHeight bodyClass={classes.sectionNoHighlight}>
                                        {renderTabs()}
                                    </Widget>
                                </Box>
                            )}
                        </Grid>
                    </>
                )}
                {isCalendarView && curSearch === '' && !isSearching ? (
                    <>
                        {canShowTip(user, 'calendar_sync_phone') && (
                            <Grid item xs={12}>
                                <Tip id="calendar_sync_phone" icon={<ShareIosIcon />} description="Share your calendar to sync with your phone" />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            {isMobileDevice() ? (
                                <Box style={{ margin: -16 }}>{renderCalendar()}</Box>
                            ) : (
                                <Box style={{ maxWidth: 480, margin: `0 auto` }}>
                                    <Widget disableWidgetMenu noBodyPadding className={classes.eventWidget}>
                                        {renderCalendar()}
                                    </Widget>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {isMobileDevice() ? (
                                <Box style={{ marginLeft: -16, borderTop: theme.border, borderBottom: theme.border, marginRight: -16 }}>{renderEvents()}</Box>
                            ) : (
                                <Widget disableWidgetMenu noBodyPadding className={classes.eventWidget} bodyClass={classes.calendarWidget}>
                                    {renderEvents()}
                                </Widget>
                            )}
                        </Grid>
                    </>
                ) : (
                    <>
                        {eventResults &&
                            eventResults.map(
                                e =>
                                    (!hasEventEnded(e) || curSearch || isSearching) &&
                                    (!curSearch || e.name.toLowerCase().indexOf(curSearch) !== -1) && (
                                        <Grid item xs={12} key={e.id} className={isMobileDevice() ? classes.listItem : null}>
                                            {count++ >= 0 ? null : null}
                                            {isMobileDevice() ? (
                                                <ButtonBase
                                                    onClick={() => {
                                                        viewEvent(props.history, e.id)
                                                    }}
                                                    style={{ fontSize: 'unset', width: '100%' }}
                                                >
                                                    {renderEventBox(e)}
                                                </ButtonBase>
                                            ) : (
                                                <Link
                                                    underline="none"
                                                    onClick={() => {
                                                        viewEvent(props.history, e.id)
                                                    }}
                                                >
                                                    <Widget disableWidgetMenu noBodyPadding className={classes.eventWidget} bodyClass={classes.eventWidgetBody}>
                                                        {renderEventBox(e)}
                                                    </Widget>
                                                </Link>
                                            )}
                                        </Grid>
                                    ),
                            )}
                        <>
                            {count === 0 && (
                                <Grid item xs={12}>
                                    {isMobileDevice() ? (
                                        <Box>{renderNoEvents()}</Box>
                                    ) : (
                                        <Widget disableWidgetMenu noBodyPadding className={classes.eventWidget}>
                                            {renderNoEvents()}
                                        </Widget>
                                    )}
                                </Grid>
                            )}
                        </>
                    </>
                )}
            </Grid>
            <DialogMobile maxWidth="md" open={exportCalendarDialog.open} onClose={() => closeExportCalendarDialog()} aria-labelledby="export-calendar-title">
                <DialogTitleMobile
                    id="export-calendar-title"
                    onClose={() => {
                        closeExportCalendarDialog()
                    }}
                >
                    Share
                </DialogTitleMobile>
                <DialogContentMobile dividers style={{ display: 'flex', flexDirection: 'column' }}>
                    <Tabs
                        tabs={[{ id: 'sync', name: 'Sync', width: 50 }]}
                        selected={exportCalendarDialog.tab}
                        onChange={val => {
                            setExportCalendarDialog({ ...exportCalendarDialog, tab: val })
                        }}
                        style={{ marginTop: -8 }}
                        disabled={exportCalendarDialog.loading}
                    />
                    <Divider style={{ marginLeft: -16, marginRight: -16, marginBottom: 16 }} />
                    {exportCalendarDialog.tab === 'sync' && (
                        <>
                            <Typography gutterBottom align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                                Sync
                            </Typography>
                            {isIOSDevice() ? (
                                <>
                                    <Typography gutterBottom align="center">
                                        Which system do you use?
                                    </Typography>
                                    {exportCalendarDialog.mobile_other && (
                                        <WarningTypography gutterBottom isCentered>
                                            Must be on a desktop to sync with other systems
                                            <LaptopIcon style={{ verticalAlign: 'bottom', marginLeft: 6 }} />
                                        </WarningTypography>
                                    )}
                                    {exportCalendarDialog.mobile_google && (
                                        <WarningTypography gutterBottom isCentered>
                                            Must be on a desktop to sync with Google Calendar
                                            <LaptopIcon style={{ verticalAlign: 'bottom', marginLeft: 6 }} />
                                        </WarningTypography>
                                    )}
                                    <Box style={{ width: '100%', flexGrow: 1 }}></Box>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={() => {
                                            if (user) {
                                                let downloadLink = document.createElement('a')
                                                downloadLink.href =
                                                    'webcal://us-central1-greekhouse-d5684.cloudfunctions.net/memberICAL/' +
                                                    user.getChapter() +
                                                    '/' +
                                                    user.getId()
                                                document.body.appendChild(downloadLink)
                                                downloadLink.click()
                                                document.body.removeChild(downloadLink)
                                            }
                                        }}
                                        size="large"
                                        style={{ display: 'flex', width: !atLeastSmall ? '100%' : '188px', margin: '8px auto' }}
                                    >
                                        Apple Calendar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={() => {
                                            setExportCalendarDialog({ ...exportCalendarDialog, mobile_google: true, mobile_other: false })
                                        }}
                                        size="large"
                                        style={{ display: 'flex', width: !atLeastSmall ? '100%' : '188px', margin: '8px auto' }}
                                    >
                                        Google Calendar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation
                                        onClick={() => {
                                            setExportCalendarDialog({ ...exportCalendarDialog, mobile_google: false, mobile_other: true })
                                        }}
                                        size="large"
                                        style={{ display: 'flex', width: !atLeastSmall ? '100%' : '188px', margin: '8px auto' }}
                                    >
                                        Other
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <WarningTypography gutterBottom isCentered>
                                        Must be on a desktop
                                        <LaptopIcon style={{ verticalAlign: 'bottom', marginLeft: 6 }} />
                                    </WarningTypography>
                                    <Typography gutterBottom align="center">
                                        View the details for your calendar system
                                    </Typography>
                                    <Box style={{ marginLeft: -16, marginRight: -16 }}>
                                        <Divider />
                                        <ButtonBase
                                            style={{
                                                width: `100%`,
                                            }}
                                            onClick={() => {
                                                setExportCalendarDialog({ ...exportCalendarDialog, desktop_apple: !exportCalendarDialog.desktop_apple })
                                            }}
                                        >
                                            <Typography variant="h6">Apple Calendar</Typography>
                                            {exportCalendarDialog.desktop_apple ? <ExpandHiddenIcon /> : <ExpandShownIcon />}
                                        </ButtonBase>
                                        <Divider />
                                        {exportCalendarDialog.desktop_apple && (
                                            <>
                                                <Typography gutterBottom style={{ marginTop: '0.35em' }} align="center">
                                                    Click "Copy URL" below
                                                </Typography>
                                                <Typography gutterBottom align="center">
                                                    Open Apple Calendar
                                                </Typography>
                                                <Typography gutterBottom align="center">
                                                    Click "File" then "New Calendar Subscription"
                                                </Typography>
                                                <Typography gutterBottom align="center">
                                                    Paste the copied link then click "Subscribe"
                                                </Typography>
                                                <Divider />
                                            </>
                                        )}
                                    </Box>
                                    <Box style={{ marginLeft: -16, marginRight: -16 }}>
                                        <ButtonBase
                                            style={{
                                                width: `100%`,
                                            }}
                                            onClick={() => {
                                                setExportCalendarDialog({ ...exportCalendarDialog, desktop_google: !exportCalendarDialog.desktop_google })
                                            }}
                                        >
                                            <Typography variant="h6">Google Calendar</Typography>
                                            {exportCalendarDialog.desktop_google ? <ExpandHiddenIcon /> : <ExpandShownIcon />}
                                        </ButtonBase>
                                        <Divider />
                                        {exportCalendarDialog.desktop_google && (
                                            <>
                                                <Typography gutterBottom style={{ marginTop: '0.35em' }} align="center">
                                                    Click "Copy URL" below
                                                </Typography>
                                                <Typography gutterBottom align="center">
                                                    Go to calendar.google.com
                                                </Typography>
                                                <Typography gutterBottom align="center">
                                                    Click "+" from the other calendars tab in the sidebar
                                                </Typography>
                                                <Typography gutterBottom align="center">
                                                    Click "From URL"
                                                </Typography>
                                                <Typography gutterBottom align="center">
                                                    Paste the copied link then click "Add Calendar"
                                                </Typography>
                                                <Divider />
                                            </>
                                        )}
                                    </Box>
                                    <Box style={{ marginLeft: -16, marginRight: -16 }}>
                                        <ButtonBase
                                            style={{
                                                width: `100%`,
                                            }}
                                            onClick={() => {
                                                setExportCalendarDialog({ ...exportCalendarDialog, desktop_other: !exportCalendarDialog.desktop_other })
                                            }}
                                        >
                                            <Typography variant="h6">Other</Typography>
                                            {exportCalendarDialog.desktop_other ? <ExpandHiddenIcon /> : <ExpandShownIcon />}
                                        </ButtonBase>
                                        <Divider />
                                        {exportCalendarDialog.desktop_other && (
                                            <>
                                                <Typography gutterBottom style={{ marginTop: '0.35em' }} align="center">
                                                    Click "Copy URL" below
                                                </Typography>
                                                <Typography gutterBottom align="center">
                                                    Go to your calendar system
                                                </Typography>
                                                <Typography gutterBottom align="center">
                                                    Add a new calendar subscription or import the calendar from URL using the copied link
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                </>
                            )}
                        </>
                    )}
                </DialogContentMobile>
                {!isIOSDevice() && (
                    <DialogActionsMobile>
                        <Button
                            onClick={() => {
                                closeExportCalendarDialog()
                            }}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                closeExportCalendarDialog()
                                setSnackbar('Copied to Clipboard')
                                copy('webcal://us-central1-greekhouse-d5684.cloudfunctions.net/memberICAL/' + user.getChapter() + '/' + user.getId())
                            }}
                            color="primary"
                        >
                            Copy URL
                        </Button>
                    </DialogActionsMobile>
                )}
            </DialogMobile>
            <Snackbar open={snackbar !== null} autoHideDuration={2000} onClose={() => setSnackbar(null)}>
                <Alert onClose={() => setSnackbar(null)} severity="success">
                    {snackbar}
                </Alert>
            </Snackbar>
        </>
    )
}
