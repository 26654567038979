import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    applicationBox: {
        alignItems: 'center',
        border: theme.border,
        borderRadius: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: '0.5em',
        position: 'relative',
    },
    applicationIcon: {
        fontSize: '2em',
        height: '2em',
        margin: '0.5em',
        marginBottom: 0,
        width: '2em',
    },
    applicationInfo: {
        position: 'absolute',
        right: 2,
        top: 2,
        color: 'inherit',
    },
    ribbon: {
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top: 0,
        left: 0,
        padding: `4px 10px`,
        borderTopLeftRadius: 15,
        borderBottomRightRadius: 15,
        color: 'white',
        textTransform: 'uppercase',
    },
}))
