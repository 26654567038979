import React from 'react'

import { Box, ButtonBase } from '@material-ui/core'

import { Typography } from 'components/Wrappers'

import useStyles from '../styles'

const LocationOption = ({ location, userLocation, selected, distance, ...props }) => {
    const classes = useStyles()

    const innerContent = (
        <Box className={classes.locationOptionsBoxParent}>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', gap: 8 }}>
                <Typography
                    style={{
                        fontSize: '1.15rem',
                        fontWeight: 'bold',
                        flexGrow: 1,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textAlign: 'start',
                    }}
                >
                    {location.name}
                </Typography>
                {distance > 0 && (
                    <Typography color={selected ? 'white' : 'red'} style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                        {Math.ceil(distance / 528) / 10} mi
                    </Typography>
                )}
            </Box>
            <Typography style={{ display: 'flex', textAlign: 'center' }}>
                {location.points.value} {location.points.value === 1 ? 'point' : 'points'} for every{' '}
                {location.points.length === 1 ? location.points.lengthUnits.slice(0, -1) : `${location.points.length} ${location.points.lengthUnits}`}
            </Typography>
        </Box>
    )

    if (selected) {
        return <Box className={classes.locationOptionBoxSelected}>{innerContent}</Box>
    }

    return (
        <ButtonBase {...props} className={classes.locationOptionButton}>
            {innerContent}
        </ButtonBase>
    )
}

export default LocationOption
