export default {
    type: 'Feature',
    properties: { name: 'Northeast' },
    geometry: {
        type: 'MultiPolygon',
        coordinates: [
            [
                [
                    [-82.644739, 38.165487],
                    [-82.636466, 38.13786],
                    [-82.621164, 38.123239],
                    [-82.602618, 38.11835],
                    [-82.600127, 38.117389],
                    [-82.547284, 38.061094],
                    [-82.539139, 38.039093],
                    [-82.519665, 38.008538],
                    [-82.509812, 38.001249],
                    [-82.487732, 37.99833],
                    [-82.465473, 37.98478],
                    [-82.464096, 37.982437],
                    [-82.464987, 37.976859],
                    [-82.4973, 37.945507],
                    [-82.497358, 37.945338],
                    [-82.500386, 37.936518],
                    [-82.474666, 37.910388],
                    [-82.417679, 37.870658],
                    [-82.400234, 37.851833],
                    [-82.329867, 37.775897],
                    [-82.319023, 37.758892],
                    [-82.326404, 37.740815],
                    [-82.325739, 37.735799],
                    [-82.307235, 37.707669],
                    [-82.297011, 37.687273],
                    [-82.25028, 37.657776],
                    [-82.21967, 37.643046],
                    [-82.141814, 37.595052],
                    [-82.125601, 37.579021],
                    [-82.125892, 37.574031],
                    [-82.116584, 37.559588],
                    [-82.048521, 37.531078],
                    [-82.008274, 37.533357],
                    [-81.999844, 37.542579],
                    [-81.998177, 37.543082],
                    [-81.968297, 37.537798],
                    [-81.92787, 37.512118],
                    [-81.94264, 37.508844],
                    [-81.992916, 37.482969],
                    [-81.996578, 37.476705],
                    [-81.99227, 37.460916],
                    [-81.987006, 37.454878],
                    [-81.968795, 37.451496],
                    [-81.949367, 37.445687],
                    [-81.935621, 37.438397],
                    [-81.92749, 37.413251],
                    [-81.933895, 37.372747],
                    [-81.930194, 37.366728],
                    [-81.929915, 37.366589],
                    [-81.926589, 37.358942],
                    [-81.925643, 37.357316],
                    [-81.921571, 37.356423],
                    [-81.920711, 37.355416],
                    [-81.911487, 37.348839],
                    [-81.910875, 37.348729],
                    [-81.907895, 37.343783],
                    [-81.907322, 37.343119],
                    [-81.906368, 37.34276],
                    [-81.905945, 37.342775],
                    [-81.903795, 37.34305],
                    [-81.902992, 37.34234],
                    [-81.899495, 37.341102],
                    [-81.899459, 37.340277],
                    [-81.896001, 37.331967],
                    [-81.895489, 37.332022],
                    [-81.894797, 37.332012],
                    [-81.894768, 37.331381],
                    [-81.893773, 37.330105],
                    [-81.892876, 37.330134],
                    [-81.887722, 37.331156],
                    [-81.886952, 37.330725],
                    [-81.885075, 37.330665],
                    [-81.880886, 37.331146],
                    [-81.879601, 37.332074],
                    [-81.878713, 37.331753],
                    [-81.878343, 37.328837],
                    [-81.873213, 37.325065],
                    [-81.865219, 37.308839],
                    [-81.86476, 37.308404],
                    [-81.853978, 37.300418],
                    [-81.854465, 37.299937],
                    [-81.853488, 37.294763],
                    [-81.854059, 37.291352],
                    [-81.843167, 37.285586],
                    [-81.84231, 37.285556],
                    [-81.810559, 37.28298],
                    [-81.809184, 37.283003],
                    [-81.793639, 37.282188],
                    [-81.793425, 37.281674],
                    [-81.774747, 37.274847],
                    [-81.774684, 37.274807],
                    [-81.767837, 37.274216],
                    [-81.765195, 37.275099],
                    [-81.763836, 37.275218],
                    [-81.762776, 37.275391],
                    [-81.761752, 37.275713],
                    [-81.76022, 37.275254],
                    [-81.757631, 37.274003],
                    [-81.75773, 37.271934],
                    [-81.757714, 37.271124],
                    [-81.757531, 37.27001],
                    [-81.755012, 37.26772],
                    [-81.752912, 37.266614],
                    [-81.752123, 37.265568],
                    [-81.75129, 37.265131],
                    [-81.747656, 37.264329],
                    [-81.743505, 37.247601],
                    [-81.74342, 37.245858],
                    [-81.744291, 37.244178],
                    [-81.738378, 37.240917],
                    [-81.73332, 37.238127],
                    [-81.728194, 37.239823],
                    [-81.683544, 37.211452],
                    [-81.678603, 37.202467],
                    [-81.560625, 37.206663],
                    [-81.558353, 37.208145],
                    [-81.557315, 37.207697],
                    [-81.507325, 37.2338],
                    [-81.50488, 37.247697],
                    [-81.504168, 37.250115],
                    [-81.409729, 37.284837],
                    [-81.409196, 37.286071],
                    [-81.388132, 37.319903],
                    [-81.386727, 37.320474],
                    [-81.38581, 37.320085],
                    [-81.384914, 37.318832],
                    [-81.384127, 37.318596],
                    [-81.380159, 37.317838],
                    [-81.377349, 37.318447],
                    [-81.371315, 37.324115],
                    [-81.367599, 37.327569],
                    [-81.369379, 37.331827],
                    [-81.36809, 37.332423],
                    [-81.367052, 37.334504],
                    [-81.366315, 37.335927],
                    [-81.362156, 37.337687],
                    [-81.320105, 37.299323],
                    [-81.225104, 37.234874],
                    [-81.204774, 37.243013],
                    [-81.167029, 37.262881],
                    [-81.112596, 37.278497],
                    [-81.084012, 37.284401],
                    [-81.034652, 37.290751],
                    [-80.981322, 37.293465],
                    [-80.980044, 37.293118],
                    [-80.973889, 37.291444],
                    [-80.966556, 37.292158],
                    [-80.947896, 37.295872],
                    [-80.919259, 37.306163],
                    [-80.900535, 37.315],
                    [-80.849451, 37.346909],
                    [-80.872589, 37.372347],
                    [-80.862761, 37.411829],
                    [-80.859558, 37.429555],
                    [-80.859556, 37.429568],
                    [-80.858473, 37.428301],
                    [-80.85836, 37.428168],
                    [-80.811639, 37.407507],
                    [-80.798869, 37.395807],
                    [-80.776766, 37.384131],
                    [-80.776649, 37.383679],
                    [-80.770082, 37.372363],
                    [-80.622664, 37.433307],
                    [-80.511391, 37.481672],
                    [-80.494867, 37.43507],
                    [-80.475601, 37.422949],
                    [-80.46482, 37.426144],
                    [-80.443025, 37.438126],
                    [-80.425656, 37.449876],
                    [-80.320627, 37.49888],
                    [-80.314806, 37.500943],
                    [-80.330306, 37.536244],
                    [-80.312393, 37.546239],
                    [-80.288766, 37.58196],
                    [-80.240272, 37.606961],
                    [-80.223386, 37.623185],
                    [-80.220984, 37.627767],
                    [-80.239288, 37.637672],
                    [-80.254431, 37.642352],
                    [-80.254469, 37.642333],
                    [-80.263281, 37.645082],
                    [-80.263291, 37.645101],
                    [-80.26483, 37.645526],
                    [-80.264874, 37.645511],
                    [-80.267228, 37.646011],
                    [-80.267455, 37.646108],
                    [-80.270352, 37.648929],
                    [-80.270323, 37.648982],
                    [-80.292258, 37.683732],
                    [-80.292337, 37.683976],
                    [-80.296138, 37.691783],
                    [-80.287107, 37.696403],
                    [-80.263936, 37.719137],
                    [-80.259342, 37.731205],
                    [-80.260313, 37.733517],
                    [-80.262765, 37.738336],
                    [-80.257411, 37.756084],
                    [-80.231551, 37.792591],
                    [-80.227092, 37.798886],
                    [-80.162202, 37.875122],
                    [-80.148964, 37.886057],
                    [-80.147316, 37.885936],
                    [-80.14613, 37.884453],
                    [-80.130464, 37.893194],
                    [-80.055278, 37.951702],
                    [-80.002507, 37.992767],
                    [-79.978427, 38.029082],
                    [-79.959844, 38.063697],
                    [-79.933911, 38.099168],
                    [-79.931034, 38.101402],
                    [-79.938394, 38.130563],
                    [-79.933751, 38.135508],
                    [-79.928747, 38.144436],
                    [-79.928683, 38.144928],
                    [-79.925512, 38.150237],
                    [-79.925251, 38.150465],
                    [-79.921026, 38.179954],
                    [-79.921196, 38.180378],
                    [-79.917061, 38.183741],
                    [-79.916174, 38.184386],
                    [-79.892345, 38.202397],
                    [-79.891999, 38.203378],
                    [-79.794568, 38.264856],
                    [-79.790134, 38.267654],
                    [-79.788945, 38.268703],
                    [-79.810115, 38.305037],
                    [-79.808711, 38.309429],
                    [-79.731661, 38.374143],
                    [-79.691377, 38.439558],
                    [-79.689544, 38.442511],
                    [-79.688962, 38.449538],
                    [-79.688205, 38.450476],
                    [-79.699006, 38.475148],
                    [-79.695565, 38.477842],
                    [-79.669128, 38.510883],
                    [-79.669128, 38.510975],
                    [-79.649075, 38.591515],
                    [-79.597955, 38.572767],
                    [-79.555471, 38.560217],
                    [-79.53687, 38.550917],
                    [-79.521469, 38.533918],
                    [-79.476638, 38.457228],
                    [-79.312276, 38.411876],
                    [-79.297758, 38.416438],
                    [-79.295712, 38.418129],
                    [-79.291813, 38.419627],
                    [-79.290529, 38.420757],
                    [-79.280263, 38.425475],
                    [-79.282663, 38.431021],
                    [-79.267414, 38.438322],
                    [-79.265327, 38.441772],
                    [-79.263376, 38.443762],
                    [-79.26291, 38.444586],
                    [-79.242024, 38.464332],
                    [-79.240059, 38.469841],
                    [-79.234408, 38.473011],
                    [-79.23162, 38.474041],
                    [-79.225839, 38.479446],
                    [-79.210026, 38.494231],
                    [-79.210008, 38.494283],
                    [-79.209703, 38.495574],
                    [-79.207873, 38.500122],
                    [-79.207884, 38.500428],
                    [-79.174881, 38.566314],
                    [-79.174512, 38.566531],
                    [-79.146974, 38.625641],
                    [-79.146741, 38.625819],
                    [-79.137012, 38.640655],
                    [-79.136374, 38.6424],
                    [-79.135546, 38.643715],
                    [-79.135472, 38.644057],
                    [-79.092271, 38.699208],
                    [-79.092555, 38.700149],
                    [-79.057253, 38.761413],
                    [-79.055354, 38.782213],
                    [-78.999752, 38.846162],
                    [-78.993997, 38.850102],
                    [-78.869276, 38.762991],
                    [-78.835191, 38.811499],
                    [-78.808181, 38.856175],
                    [-78.788031, 38.885123],
                    [-78.779198, 38.892298],
                    [-78.757278, 38.903203],
                    [-78.719806, 38.922638],
                    [-78.719755, 38.922135],
                    [-78.716956, 38.916273],
                    [-78.714135, 38.911176],
                    [-78.710949, 38.910175],
                    [-78.601655, 38.964603],
                    [-78.557647, 39.013189],
                    [-78.554222, 39.019672],
                    [-78.565837, 39.026303],
                    [-78.571901, 39.031995],
                    [-78.544111, 39.056676],
                    [-78.508132, 39.08863],
                    [-78.459869, 39.113351],
                    [-78.439429, 39.132146],
                    [-78.418377, 39.156656],
                    [-78.41074, 39.171983],
                    [-78.426722, 39.188903],
                    [-78.438651, 39.198049],
                    [-78.437053, 39.199766],
                    [-78.43213, 39.204717],
                    [-78.431167, 39.205744],
                    [-78.429803, 39.207014],
                    [-78.427911, 39.208611],
                    [-78.423968, 39.212049],
                    [-78.399669, 39.243874],
                    [-78.399785, 39.244129],
                    [-78.360035, 39.317771],
                    [-78.35894, 39.319484],
                    [-78.34048, 39.353492],
                    [-78.362267, 39.357784],
                    [-78.347087, 39.466012],
                    [-78.228766, 39.391233],
                    [-78.033185, 39.264626],
                    [-78.033183, 39.264624],
                    [-78.032841, 39.264403],
                    [-77.828157, 39.132329],
                    [-77.822182, 39.139985],
                    [-77.771415, 39.236776],
                    [-77.767277, 39.24938],
                    [-77.768992, 39.256417],
                    [-77.768, 39.257657],
                    [-77.761768, 39.263031],
                    [-77.761217, 39.263721],
                    [-77.758733, 39.268114],
                    [-77.758412, 39.269197],
                    [-77.755698, 39.274575],
                    [-77.755193, 39.275191],
                    [-77.753105, 39.27734],
                    [-77.75306, 39.277971],
                    [-77.753357, 39.280331],
                    [-77.750267, 39.289284],
                    [-77.719029, 39.321125],
                    [-77.677505, 39.318699],
                    [-77.667749, 39.318129],
                    [-77.592739, 39.30129],
                    [-77.560854, 39.286152],
                    [-77.545846, 39.271535],
                    [-77.543228, 39.266937],
                    [-77.534461, 39.262361],
                    [-77.511222, 39.2535],
                    [-77.484605, 39.245941],
                    [-77.46021, 39.228359],
                    [-77.45768, 39.22502],
                    [-77.458779, 39.22028],
                    [-77.458884, 39.219826],
                    [-77.478596, 39.189168],
                    [-77.485971, 39.185665],
                    [-77.505162, 39.18205],
                    [-77.510631, 39.178484],
                    [-77.516426, 39.170891],
                    [-77.527282, 39.146236],
                    [-77.524559, 39.127821],
                    [-77.519929, 39.120925],
                    [-77.458202, 39.073723],
                    [-77.42318, 39.066878],
                    [-77.375079, 39.061297],
                    [-77.340287, 39.062991],
                    [-77.328002, 39.058554],
                    [-77.291605, 39.045408],
                    [-77.261403, 39.031009],
                    [-77.251803, 39.011409],
                    [-77.255703, 39.002409],
                    [-77.249803, 38.985909],
                    [-77.235403, 38.97661],
                    [-77.221502, 38.97131],
                    [-77.197502, 38.96681],
                    [-77.166901, 38.96811],
                    [-77.148179, 38.965002],
                    [-77.137701, 38.95531],
                    [-77.1199, 38.934311],
                    [-77.1007, 38.94891],
                    [-77.054299, 38.98511],
                    [-77.040999, 38.99511],
                    [-77.036299, 38.99171],
                    [-77.015598, 38.97591],
                    [-77.013798, 38.97441],
                    [-77.008298, 38.97011],
                    [-77.002636, 38.965521],
                    [-77.002498, 38.96541],
                    [-76.941722, 38.918019],
                    [-76.909395, 38.892812],
                    [-76.910795, 38.891712],
                    [-76.919295, 38.885112],
                    [-76.920195, 38.884412],
                    [-76.949696, 38.861312],
                    [-76.953696, 38.858512],
                    [-77.001397, 38.821513],
                    [-77.024392, 38.80297],
                    [-77.038598, 38.791513],
                    [-77.039239, 38.7852],
                    [-77.041398, 38.763914],
                    [-77.05991, 38.734419],
                    [-77.074599, 38.711015],
                    [-77.086113, 38.705792],
                    [-77.1059, 38.696815],
                    [-77.121101, 38.686616],
                    [-77.132501, 38.673816],
                    [-77.135901, 38.649817],
                    [-77.1302, 38.635017],
                    [-77.12463, 38.619778],
                    [-77.125191, 38.619096],
                    [-77.12634, 38.6177],
                    [-77.128841, 38.61466],
                    [-77.129084, 38.614364],
                    [-77.129213, 38.614306],
                    [-77.135539, 38.611473],
                    [-77.146087, 38.606748],
                    [-77.148651, 38.6056],
                    [-77.161585, 38.606381],
                    [-77.162649, 38.606446],
                    [-77.16316, 38.606477],
                    [-77.163409, 38.606492],
                    [-77.163945, 38.606524],
                    [-77.167523, 38.60674],
                    [-77.169671, 38.60687],
                    [-77.169968, 38.60674],
                    [-77.175969, 38.604113],
                    [-77.183767, 38.600699],
                    [-77.205261, 38.574525],
                    [-77.209905, 38.56887],
                    [-77.221117, 38.555217],
                    [-77.226465, 38.554139],
                    [-77.236728, 38.552071],
                    [-77.237724, 38.55187],
                    [-77.242736, 38.544214],
                    [-77.246089, 38.539093],
                    [-77.254882, 38.52566],
                    [-77.257378, 38.521847],
                    [-77.263599, 38.512344],
                    [-77.271099, 38.490753],
                    [-77.274125, 38.482044],
                    [-77.27422, 38.48177],
                    [-77.27417, 38.481608],
                    [-77.274021, 38.481127],
                    [-77.269474, 38.466476],
                    [-77.266845, 38.458004],
                    [-77.263682, 38.44781],
                    [-77.26076, 38.438394],
                    [-77.259962, 38.435821],
                    [-77.259962, 38.427902],
                    [-77.26129, 38.42367],
                    [-77.264238, 38.414282],
                    [-77.261937, 38.409131],
                    [-77.257177, 38.39847],
                    [-77.256412, 38.396755],
                    [-77.253202, 38.389567],
                    [-77.25122, 38.385127],
                    [-77.250862, 38.384325],
                    [-77.250172, 38.382781],
                    [-77.236231, 38.374601],
                    [-77.216834, 38.363221],
                    [-77.216729, 38.363159],
                    [-77.212189, 38.361572],
                    [-77.208334, 38.360224],
                    [-77.207371, 38.359888],
                    [-77.207312, 38.359867],
                    [-77.207214, 38.359894],
                    [-77.205009, 38.360511],
                    [-77.189412, 38.364872],
                    [-77.18668, 38.365636],
                    [-77.184917, 38.366559],
                    [-77.183268, 38.367423],
                    [-77.182638, 38.367753],
                    [-77.1798, 38.369239],
                    [-77.177, 38.370706],
                    [-77.168697, 38.375055],
                    [-77.163531, 38.377761],
                    [-77.147541, 38.386136],
                    [-77.143152, 38.388435],
                    [-77.139968, 38.390102],
                    [-77.136947, 38.391684],
                    [-77.136728, 38.391799],
                    [-77.136434, 38.392094],
                    [-77.135224, 38.393311],
                    [-77.130287, 38.398271],
                    [-77.12909, 38.399474],
                    [-77.128872, 38.399692],
                    [-77.128823, 38.399742],
                    [-77.128649, 38.399917],
                    [-77.128377, 38.40019],
                    [-77.127737, 38.400833],
                    [-77.127392, 38.4016],
                    [-77.125933, 38.404845],
                    [-77.123325, 38.410646],
                    [-77.110623, 38.409214],
                    [-77.110586, 38.40921],
                    [-77.109171, 38.408162],
                    [-77.109055, 38.408076],
                    [-77.107881, 38.407207],
                    [-77.107631, 38.407022],
                    [-77.106968, 38.406531],
                    [-77.106571, 38.406237],
                    [-77.091073, 38.407546],
                    [-77.086393, 38.414755],
                    [-77.081418, 38.419297],
                    [-77.081252, 38.419449],
                    [-77.075489, 38.42471],
                    [-77.074703, 38.42517],
                    [-77.074339, 38.425382],
                    [-77.074174, 38.425479],
                    [-77.062607, 38.432244],
                    [-77.057684, 38.435123],
                    [-77.05746, 38.435254],
                    [-77.057277, 38.435361],
                    [-77.056892, 38.435586],
                    [-77.05532, 38.436505],
                    [-77.055162, 38.436598],
                    [-77.054972, 38.436709],
                    [-77.053979, 38.43729],
                    [-77.053663, 38.437475],
                    [-77.053445, 38.437602],
                    [-77.05329, 38.437693],
                    [-77.053187, 38.437753],
                    [-77.044188, 38.443016],
                    [-77.042879, 38.443607],
                    [-77.042046, 38.443983],
                    [-77.040638, 38.444618],
                    [-77.016674, 38.44556],
                    [-77.016371, 38.445572],
                    [-77.006767, 38.430176],
                    [-77.006495, 38.429738],
                    [-77.00257, 38.423447],
                    [-77.001902, 38.422376],
                    [-77.001638, 38.421952],
                    [-77.001541, 38.421548],
                    [-77.000997, 38.419293],
                    [-76.998843, 38.410362],
                    [-76.998585, 38.409294],
                    [-76.996724, 38.406708],
                    [-76.996663, 38.406623],
                    [-76.993839, 38.402699],
                    [-76.990305, 38.397788],
                    [-76.989306, 38.396401],
                    [-76.989271, 38.396352],
                    [-76.98828, 38.394975],
                    [-76.987838, 38.391965],
                    [-76.986996, 38.386237],
                    [-76.986699, 38.384213],
                    [-76.986464, 38.382618],
                    [-76.983582, 38.362999],
                    [-76.975492, 38.347327],
                    [-76.975092, 38.347067],
                    [-76.966349, 38.341372],
                    [-76.953928, 38.333282],
                    [-76.942132, 38.329601],
                    [-76.929554, 38.321088],
                    [-76.927019, 38.318454],
                    [-76.924668, 38.316011],
                    [-76.923629, 38.314932],
                    [-76.922177, 38.311339],
                    [-76.922161, 38.311086],
                    [-76.920932, 38.291568],
                    [-76.920778, 38.291529],
                    [-76.908506, 38.28843],
                    [-76.886535, 38.277004],
                    [-76.866416, 38.269715],
                    [-76.866302, 38.269673],
                    [-76.864292, 38.268945],
                    [-76.847074, 38.25616],
                    [-76.846036, 38.255809],
                    [-76.844885, 38.25542],
                    [-76.844221, 38.255195],
                    [-76.843422, 38.254925],
                    [-76.842139, 38.254491],
                    [-76.842038, 38.254657],
                    [-76.837789, 38.261609],
                    [-76.837697, 38.26199],
                    [-76.835136, 38.272628],
                    [-76.834803, 38.274012],
                    [-76.834908, 38.274299],
                    [-76.838539, 38.28417],
                    [-76.840383, 38.289184],
                    [-76.841241, 38.289564],
                    [-76.841703, 38.289768],
                    [-76.84622, 38.291768],
                    [-76.846221, 38.29196],
                    [-76.846252, 38.297718],
                    [-76.846118, 38.297739],
                    [-76.845846, 38.297783],
                    [-76.834046, 38.29968],
                    [-76.824889, 38.301152],
                    [-76.824834, 38.30113],
                    [-76.82167, 38.299869],
                    [-76.821569, 38.299829],
                    [-76.820799, 38.299064],
                    [-76.816323, 38.29462],
                    [-76.815098, 38.293404],
                    [-76.811182, 38.289516],
                    [-76.810596, 38.288934],
                    [-76.806596, 38.284962],
                    [-76.80547, 38.283844],
                    [-76.805363, 38.283738],
                    [-76.805037, 38.283414],
                    [-76.804922, 38.2833],
                    [-76.802347, 38.280743],
                    [-76.802947, 38.275094],
                    [-76.805224, 38.253648],
                    [-76.80577, 38.252615],
                    [-76.805949, 38.252275],
                    [-76.808411, 38.252209],
                    [-76.80888, 38.252197],
                    [-76.809009, 38.252193],
                    [-76.811927, 38.252115],
                    [-76.811815, 38.251318],
                    [-76.811647, 38.250129],
                    [-76.797452, 38.236918],
                    [-76.79659, 38.236531],
                    [-76.791932, 38.234441],
                    [-76.779865, 38.229026],
                    [-76.778625, 38.22847],
                    [-76.769988, 38.226388],
                    [-76.752286, 38.222121],
                    [-76.752085, 38.222336],
                    [-76.752017, 38.222409],
                    [-76.749384, 38.225231],
                    [-76.744265, 38.230716],
                    [-76.740055, 38.235227],
                    [-76.719602, 38.233503],
                    [-76.717537, 38.233329],
                    [-76.716376, 38.233231],
                    [-76.716139, 38.233237],
                    [-76.712594, 38.233334],
                    [-76.687075, 38.23403],
                    [-76.678687, 38.234259],
                    [-76.673462, 38.234401],
                    [-76.659182, 38.229894],
                    [-76.645166, 38.22547],
                    [-76.645034, 38.225429],
                    [-76.643999, 38.225102],
                    [-76.643929, 38.22508],
                    [-76.632544, 38.225657],
                    [-76.632464, 38.225635],
                    [-76.631997, 38.225508],
                    [-76.631958, 38.225497],
                    [-76.629893, 38.224933],
                    [-76.629703, 38.224881],
                    [-76.628078, 38.224437],
                    [-76.625659, 38.223777],
                    [-76.624387, 38.223429],
                    [-76.590637, 38.214212],
                    [-76.588683, 38.21295],
                    [-76.57444, 38.203751],
                    [-76.566297, 38.198492],
                    [-76.552957, 38.187209],
                    [-76.548946, 38.178982],
                    [-76.548256, 38.177566],
                    [-76.547902, 38.176839],
                    [-76.547455, 38.175923],
                    [-76.547333, 38.175673],
                    [-76.545335, 38.165373],
                    [-76.54038, 38.152991],
                    [-76.529868, 38.134083],
                    [-76.529439, 38.134388],
                    [-76.52899, 38.134708],
                    [-76.522418, 38.139391],
                    [-76.514824, 38.141219],
                    [-76.508825, 38.140713],
                    [-76.502306, 38.138156],
                    [-76.501258, 38.137744],
                    [-76.500332, 38.137381],
                    [-76.499923, 38.137221],
                    [-76.499842, 38.137189],
                    [-76.499018, 38.136255],
                    [-76.497866, 38.13495],
                    [-76.497081, 38.134059],
                    [-76.49504, 38.131746],
                    [-76.492029, 38.128333],
                    [-76.48632, 38.121862],
                    [-76.486274, 38.12181],
                    [-76.485961, 38.121456],
                    [-76.485149, 38.120535],
                    [-76.48403, 38.119266],
                    [-76.482007, 38.116974],
                    [-76.481073, 38.115915],
                    [-76.48105, 38.115889],
                    [-76.481036, 38.115873],
                    [-76.481, 38.115789],
                    [-76.479799, 38.113005],
                    [-76.479056, 38.111281],
                    [-76.478817, 38.110726],
                    [-76.478797, 38.11068],
                    [-76.47861, 38.110247],
                    [-76.478168, 38.109221],
                    [-76.476222, 38.104709],
                    [-76.475448, 38.104271],
                    [-76.473266, 38.103035],
                    [-76.467879, 38.104932],
                    [-76.46533, 38.10583],
                    [-76.465479, 38.106603],
                    [-76.465892, 38.108738],
                    [-76.466364, 38.111183],
                    [-76.466387, 38.111302],
                    [-76.466404, 38.111392],
                    [-76.466568, 38.111596],
                    [-76.466973, 38.112099],
                    [-76.467333, 38.112546],
                    [-76.469738, 38.115534],
                    [-76.46974, 38.115653],
                    [-76.46976, 38.116874],
                    [-76.469784, 38.118381],
                    [-76.469787, 38.118606],
                    [-76.469795, 38.119061],
                    [-76.469798, 38.119264],
                    [-76.465727, 38.127408],
                    [-76.460673, 38.137515],
                    [-76.460072, 38.138717],
                    [-76.459689, 38.139484],
                    [-76.459236, 38.139471],
                    [-76.439841, 38.138933],
                    [-76.437242, 38.135699],
                    [-76.429581, 38.126165],
                    [-76.429744, 38.124856],
                    [-76.43013, 38.121753],
                    [-76.430412, 38.119488],
                    [-76.430425, 38.119383],
                    [-76.429471, 38.118001],
                    [-76.429384, 38.117875],
                    [-76.421896, 38.107026],
                    [-76.421214, 38.106039],
                    [-76.421066, 38.105989],
                    [-76.41655, 38.104459],
                    [-76.414994, 38.105572],
                    [-76.414475, 38.105943],
                    [-76.41316, 38.106884],
                    [-76.405972, 38.106967],
                    [-76.405368, 38.106974],
                    [-76.398067, 38.10469],
                    [-76.397656, 38.104561],
                    [-76.396478, 38.104192],
                    [-76.394006, 38.103419],
                    [-76.393121, 38.103142],
                    [-76.392335, 38.102896],
                    [-76.392058, 38.102581],
                    [-76.391823, 38.102315],
                    [-76.390917, 38.101286],
                    [-76.384886, 38.094437],
                    [-76.383896, 38.093312],
                    [-76.381842, 38.090981],
                    [-76.381752, 38.090878],
                    [-76.381298, 38.090362],
                    [-76.380991, 38.090014],
                    [-76.378292, 38.086949],
                    [-76.37179, 38.079565],
                    [-76.370845, 38.077771],
                    [-76.370204, 38.076556],
                    [-76.369306, 38.074853],
                    [-76.361779, 38.06057],
                    [-76.361668, 38.06036],
                    [-76.361538, 38.060114],
                    [-76.361237, 38.059542],
                    [-76.35275, 38.053182],
                    [-76.350656, 38.053277],
                    [-76.345697, 38.053502],
                    [-76.344327, 38.053565],
                    [-76.341404, 38.053697],
                    [-76.341172, 38.053596],
                    [-76.332812, 38.049938],
                    [-76.326994, 38.045105],
                    [-76.322093, 38.036503],
                    [-76.32195, 38.036874],
                    [-76.321499, 38.03805],
                    [-76.319476, 38.043315],
                    [-76.320592, 38.046531],
                    [-76.325044, 38.059368],
                    [-76.329165, 38.071247],
                    [-76.329308, 38.07166],
                    [-76.329433, 38.073986],
                    [-76.330787, 38.099201],
                    [-76.330794, 38.099331],
                    [-76.330807, 38.099354],
                    [-76.33124, 38.100106],
                    [-76.331484, 38.100531],
                    [-76.331807, 38.101092],
                    [-76.337402, 38.11082],
                    [-76.337411, 38.110888],
                    [-76.338535, 38.119472],
                    [-76.338161, 38.119856],
                    [-76.337342, 38.120696],
                    [-76.335637, 38.122444],
                    [-76.335243, 38.122848],
                    [-76.331812, 38.126366],
                    [-76.330982, 38.127217],
                    [-76.324108, 38.134266],
                    [-76.320136, 38.138339],
                    [-76.320492, 38.138966],
                    [-76.327124, 38.150641],
                    [-76.328419, 38.152921],
                    [-76.328778, 38.153552],
                    [-76.328805, 38.1536],
                    [-76.328864, 38.153703],
                    [-76.329688, 38.155155],
                    [-76.329705, 38.155184],
                    [-76.329711, 38.15519],
                    [-76.336106, 38.161353],
                    [-76.337611, 38.162805],
                    [-76.343517, 38.168497],
                    [-76.347983, 38.172802],
                    [-76.352395, 38.177054],
                    [-76.352561, 38.177214],
                    [-76.353516, 38.178135],
                    [-76.353799, 38.178606],
                    [-76.359887, 38.188737],
                    [-76.361877, 38.192048],
                    [-76.364135, 38.194532],
                    [-76.372535, 38.203771],
                    [-76.377693, 38.209445],
                    [-76.378104, 38.209897],
                    [-76.385244, 38.217751],
                    [-76.391623, 38.236573],
                    [-76.391659, 38.23668],
                    [-76.39228, 38.238511],
                    [-76.392464, 38.239055],
                    [-76.399078, 38.258569],
                    [-76.39932, 38.259284],
                    [-76.399313, 38.259398],
                    [-76.399078, 38.263275],
                    [-76.399049, 38.263753],
                    [-76.399013, 38.264338],
                    [-76.398852, 38.266997],
                    [-76.396913, 38.27165],
                    [-76.394171, 38.278233],
                    [-76.382051, 38.289384],
                    [-76.376607, 38.294393],
                    [-76.374481, 38.296348],
                    [-76.374517, 38.296556],
                    [-76.375023, 38.299419],
                    [-76.37531, 38.299583],
                    [-76.38012, 38.302343],
                    [-76.381493, 38.30313],
                    [-76.382163, 38.303389],
                    [-76.402894, 38.311402],
                    [-76.405716, 38.317794],
                    [-76.409291, 38.325891],
                    [-76.40494, 38.341089],
                    [-76.403101, 38.343157],
                    [-76.402043, 38.344348],
                    [-76.401532, 38.344923],
                    [-76.400871, 38.345666],
                    [-76.398261, 38.348602],
                    [-76.388915, 38.359115],
                    [-76.388806, 38.359238],
                    [-76.387408, 38.360811],
                    [-76.387002, 38.361267],
                    [-76.386957, 38.362462],
                    [-76.386931, 38.363184],
                    [-76.386243, 38.381631],
                    [-76.386229, 38.382013],
                    [-76.388328, 38.387727],
                    [-76.388348, 38.387781],
                    [-76.393378, 38.389477],
                    [-76.40271, 38.396003],
                    [-76.415384, 38.414682],
                    [-76.435674, 38.432893],
                    [-76.436271, 38.433429],
                    [-76.438919, 38.435053],
                    [-76.44118, 38.436439],
                    [-76.442431, 38.437206],
                    [-76.442441, 38.437212],
                    [-76.445376, 38.439012],
                    [-76.450937, 38.442422],
                    [-76.45414, 38.44588],
                    [-76.456002, 38.447891],
                    [-76.455799, 38.451233],
                    [-76.461078, 38.455756],
                    [-76.46401, 38.458268],
                    [-76.467186, 38.46099],
                    [-76.467194, 38.460996],
                    [-76.467342, 38.461123],
                    [-76.471253, 38.464474],
                    [-76.473372, 38.46629],
                    [-76.491642, 38.481944],
                    [-76.492699, 38.482849],
                    [-76.498222, 38.491869],
                    [-76.498792, 38.4928],
                    [-76.499256, 38.493559],
                    [-76.506023, 38.50461],
                    [-76.50686, 38.506716],
                    [-76.507489, 38.5083],
                    [-76.51535, 38.528093],
                    [-76.515706, 38.528988],
                    [-76.515754, 38.52926],
                    [-76.517506, 38.539149],
                    [-76.51552, 38.5529],
                    [-76.515415, 38.553623],
                    [-76.515409, 38.553667],
                    [-76.515376, 38.553895],
                    [-76.515106, 38.555763],
                    [-76.51634, 38.590229],
                    [-76.515764, 38.593131],
                    [-76.51576, 38.593154],
                    [-76.515741, 38.593248],
                    [-76.514812, 38.59793],
                    [-76.512583, 38.609168],
                    [-76.512452, 38.609827],
                    [-76.511278, 38.615745],
                    [-76.515554, 38.629361],
                    [-76.516988, 38.631985],
                    [-76.517862, 38.633586],
                    [-76.524122, 38.645044],
                    [-76.52462, 38.645956],
                    [-76.525007, 38.647568],
                    [-76.527428, 38.657655],
                    [-76.52785, 38.659416],
                    [-76.530454, 38.670263],
                    [-76.532056, 38.676936],
                    [-76.532398, 38.678363],
                    [-76.532409, 38.680064],
                    [-76.532432, 38.6836],
                    [-76.532527, 38.698207],
                    [-76.532537, 38.699669],
                    [-76.532465, 38.699974],
                    [-76.530824, 38.706881],
                    [-76.530799, 38.706983],
                    [-76.530575, 38.707929],
                    [-76.529721, 38.711523],
                    [-76.529532, 38.712317],
                    [-76.529237, 38.713561],
                    [-76.526655, 38.72443],
                    [-76.52718, 38.727062],
                    [-76.529284, 38.728137],
                    [-76.529868, 38.728435],
                    [-76.539769, 38.72794],
                    [-76.542053, 38.727826],
                    [-76.543435, 38.727757],
                    [-76.54357, 38.72775],
                    [-76.543853, 38.727736],
                    [-76.544475, 38.727705],
                    [-76.544561, 38.727784],
                    [-76.551971, 38.734636],
                    [-76.552743, 38.73535],
                    [-76.553978, 38.737756],
                    [-76.556954, 38.743554],
                    [-76.557091, 38.743823],
                    [-76.5573, 38.744229],
                    [-76.557535, 38.744687],
                    [-76.558933, 38.758177],
                    [-76.559778, 38.766342],
                    [-76.559884, 38.767361],
                    [-76.559697, 38.767443],
                    [-76.554693, 38.769639],
                    [-76.554443, 38.769749],
                    [-76.544413, 38.774151],
                    [-76.544372, 38.774169],
                    [-76.542973, 38.774783],
                    [-76.539088, 38.776488],
                    [-76.53723, 38.777304],
                    [-76.536849, 38.777471],
                    [-76.536819, 38.777484],
                    [-76.536284, 38.777719],
                    [-76.535642, 38.778],
                    [-76.535426, 38.778095],
                    [-76.535379, 38.778116],
                    [-76.535352, 38.778145],
                    [-76.535207, 38.778298],
                    [-76.533975, 38.779604],
                    [-76.532487, 38.781181],
                    [-76.528136, 38.78579],
                    [-76.527315, 38.78666],
                    [-76.526979, 38.787016],
                    [-76.527045, 38.787645],
                    [-76.527105, 38.788223],
                    [-76.52737, 38.790774],
                    [-76.527372, 38.790788],
                    [-76.527463, 38.791667],
                    [-76.527479, 38.791816],
                    [-76.527455, 38.791844],
                    [-76.525531, 38.794043],
                    [-76.52521, 38.794409],
                    [-76.525039, 38.794605],
                    [-76.524999, 38.79465],
                    [-76.524806, 38.794871],
                    [-76.524679, 38.795016],
                    [-76.523405, 38.795557],
                    [-76.51583, 38.798774],
                    [-76.514296, 38.799425],
                    [-76.51113, 38.800769],
                    [-76.510078, 38.801216],
                    [-76.509314, 38.802328],
                    [-76.506876, 38.805875],
                    [-76.50653, 38.80638],
                    [-76.50291, 38.811648],
                    [-76.499056, 38.817258],
                    [-76.498878, 38.817516],
                    [-76.497692, 38.820309],
                    [-76.496965, 38.822022],
                    [-76.491831, 38.834116],
                    [-76.491684, 38.834462],
                    [-76.491606, 38.834644],
                    [-76.491387, 38.835161],
                    [-76.490107, 38.838176],
                    [-76.489878, 38.838715],
                    [-76.489878, 38.842815],
                    [-76.489919, 38.842878],
                    [-76.491494, 38.845299],
                    [-76.491581, 38.845433],
                    [-76.492017, 38.846102],
                    [-76.492761, 38.847246],
                    [-76.493639, 38.848595],
                    [-76.496474, 38.852953],
                    [-76.496579, 38.853115],
                    [-76.497562, 38.852749],
                    [-76.499158, 38.852156],
                    [-76.509211, 38.848416],
                    [-76.509285, 38.848388],
                    [-76.516582, 38.851026],
                    [-76.516944, 38.851157],
                    [-76.519442, 38.863135],
                    [-76.511135, 38.869396],
                    [-76.507858, 38.871866],
                    [-76.498467, 38.878944],
                    [-76.494858, 38.881665],
                    [-76.494588, 38.881868],
                    [-76.4944, 38.88201],
                    [-76.491442, 38.884239],
                    [-76.491107, 38.884492],
                    [-76.49068, 38.884814],
                    [-76.48938, 38.887414],
                    [-76.489667, 38.888772],
                    [-76.489675, 38.888811],
                    [-76.489713, 38.888991],
                    [-76.489933, 38.890031],
                    [-76.490339, 38.891955],
                    [-76.490403, 38.892254],
                    [-76.49088, 38.894514],
                    [-76.49278, 38.895614],
                    [-76.49388, 38.899614],
                    [-76.493856, 38.900846],
                    [-76.493806, 38.903443],
                    [-76.493798, 38.903865],
                    [-76.493791, 38.904225],
                    [-76.49368, 38.910013],
                    [-76.475761, 38.914469],
                    [-76.46948, 38.911513],
                    [-76.46938, 38.907613],
                    [-76.46528, 38.907406],
                    [-76.462312, 38.907256],
                    [-76.460716, 38.907175],
                    [-76.460253, 38.907152],
                    [-76.459479, 38.907113],
                    [-76.45808, 38.914313],
                    [-76.458759, 38.916045],
                    [-76.458991, 38.916639],
                    [-76.460272, 38.919909],
                    [-76.461747, 38.923674],
                    [-76.46188, 38.924013],
                    [-76.450756, 38.940412],
                    [-76.45028, 38.941113],
                    [-76.451695, 38.94249],
                    [-76.457229, 38.947875],
                    [-76.457781, 38.948412],
                    [-76.460234, 38.948505],
                    [-76.463081, 38.948612],
                    [-76.471281, 38.956512],
                    [-76.473243, 38.962397],
                    [-76.474743, 38.966895],
                    [-76.474882, 38.967312],
                    [-76.47445, 38.970683],
                    [-76.474198, 38.972646],
                    [-76.474198, 38.972647],
                    [-76.454581, 38.974512],
                    [-76.451599, 38.976767],
                    [-76.450481, 38.977612],
                    [-76.450152, 38.978751],
                    [-76.449188, 38.982092],
                    [-76.448981, 38.982811],
                    [-76.448928, 38.982823],
                    [-76.427301, 38.987827],
                    [-76.422181, 38.989011],
                    [-76.421535, 38.989524],
                    [-76.414631, 38.995002],
                    [-76.412326, 38.996832],
                    [-76.39408, 39.011311],
                    [-76.394358, 39.01216],
                    [-76.394699, 39.0132],
                    [-76.395338, 39.015154],
                    [-76.395639, 39.016074],
                    [-76.396395, 39.01838],
                    [-76.396417, 39.018449],
                    [-76.397738, 39.022482],
                    [-76.39778, 39.022611],
                    [-76.4026, 39.029609],
                    [-76.405021, 39.033124],
                    [-76.405081, 39.033211],
                    [-76.405338, 39.03336],
                    [-76.407205, 39.034439],
                    [-76.407213, 39.034444],
                    [-76.407398, 39.034551],
                    [-76.422933, 39.043536],
                    [-76.437044, 39.051698],
                    [-76.438928, 39.052788],
                    [-76.438845, 39.0529],
                    [-76.432374, 39.061648],
                    [-76.423081, 39.07421],
                    [-76.42186, 39.081442],
                    [-76.42208, 39.08207],
                    [-76.422212, 39.082448],
                    [-76.422714, 39.083881],
                    [-76.423271, 39.085473],
                    [-76.423321, 39.085615],
                    [-76.423609, 39.086438],
                    [-76.426276, 39.094057],
                    [-76.426283, 39.094076],
                    [-76.426456, 39.094571],
                    [-76.427065, 39.096309],
                    [-76.427196, 39.096685],
                    [-76.432942, 39.113098],
                    [-76.432981, 39.113209],
                    [-76.432702, 39.120752],
                    [-76.432481, 39.126709],
                    [-76.428681, 39.131709],
                    [-76.43013, 39.132419],
                    [-76.430946, 39.132818],
                    [-76.44011, 39.137305],
                    [-76.452782, 39.143509],
                    [-76.458192, 39.146749],
                    [-76.45835, 39.146844],
                    [-76.458649, 39.147023],
                    [-76.458873, 39.147157],
                    [-76.459119, 39.147304],
                    [-76.468898, 39.153161],
                    [-76.471483, 39.154709],
                    [-76.473802, 39.158007],
                    [-76.474807, 39.159436],
                    [-76.475983, 39.161109],
                    [-76.483845, 39.164334],
                    [-76.484023, 39.164407],
                    [-76.500512, 39.161362],
                    [-76.500926, 39.161286],
                    [-76.508384, 39.169408],
                    [-76.511834, 39.171093],
                    [-76.519292, 39.174736],
                    [-76.52027, 39.175214],
                    [-76.524221, 39.177144],
                    [-76.525031, 39.17754],
                    [-76.525454, 39.177746],
                    [-76.525785, 39.177908],
                    [-76.534185, 39.190608],
                    [-76.535385, 39.203808],
                    [-76.53476, 39.204841],
                    [-76.533349, 39.207172],
                    [-76.533085, 39.207608],
                    [-76.533103, 39.20763],
                    [-76.535885, 39.211008],
                    [-76.53496, 39.21228],
                    [-76.534285, 39.213208],
                    [-76.529649, 39.216693],
                    [-76.528587, 39.217492],
                    [-76.520941, 39.22324],
                    [-76.520584, 39.223508],
                    [-76.519804, 39.222946],
                    [-76.500984, 39.209376],
                    [-76.500834, 39.209113],
                    [-76.500086, 39.207798],
                    [-76.498384, 39.204808],
                    [-76.497977, 39.204697],
                    [-76.49077, 39.202724],
                    [-76.489777, 39.202453],
                    [-76.489505, 39.202378],
                    [-76.488883, 39.202208],
                    [-76.486371, 39.203036],
                    [-76.485527, 39.203314],
                    [-76.482153, 39.204426],
                    [-76.480083, 39.205108],
                    [-76.471126, 39.20554],
                    [-76.463483, 39.205908],
                    [-76.462679, 39.205506],
                    [-76.46156, 39.204947],
                    [-76.447621, 39.197977],
                    [-76.442482, 39.195408],
                    [-76.441411, 39.196049],
                    [-76.425413, 39.205629],
                    [-76.425281, 39.205708],
                    [-76.419201, 39.216988],
                    [-76.41829, 39.218677],
                    [-76.417681, 39.219808],
                    [-76.41762, 39.219838],
                    [-76.399122, 39.229048],
                    [-76.398314, 39.22945],
                    [-76.394381, 39.231408],
                    [-76.393626, 39.232012],
                    [-76.389563, 39.235261],
                    [-76.38938, 39.235408],
                    [-76.384699, 39.242242],
                    [-76.38438, 39.242708],
                    [-76.382358, 39.247292],
                    [-76.38138, 39.249508],
                    [-76.386937, 39.249216],
                    [-76.401911, 39.258053],
                    [-76.402355, 39.258315],
                    [-76.402047, 39.258783],
                    [-76.401103, 39.260219],
                    [-76.400187, 39.261612],
                    [-76.400094, 39.261753],
                    [-76.395301, 39.269042],
                    [-76.395136, 39.269293],
                    [-76.385151, 39.275766],
                    [-76.384901, 39.275928],
                    [-76.383891, 39.281465],
                    [-76.383345, 39.284455],
                    [-76.383021, 39.286231],
                    [-76.380734, 39.298765],
                    [-76.380662, 39.299161],
                    [-76.365532, 39.31095],
                    [-76.36439, 39.31184],
                    [-76.355495, 39.312155],
                    [-76.354817, 39.311709],
                    [-76.353205, 39.31065],
                    [-76.341432, 39.30291],
                    [-76.339817, 39.304216],
                    [-76.337858, 39.305799],
                    [-76.327579, 39.314108],
                    [-76.33382, 39.319611],
                    [-76.33957, 39.324681],
                    [-76.338898, 39.325783],
                    [-76.333924, 39.333935],
                    [-76.334021, 39.334197],
                    [-76.334401, 39.335222],
                    [-76.335412, 39.337949],
                    [-76.339942, 39.350169],
                    [-76.341443, 39.354217],
                    [-76.330027, 39.356139],
                    [-76.323679, 39.357208],
                    [-76.322687, 39.357092],
                    [-76.314356, 39.35612],
                    [-76.312864, 39.355946],
                    [-76.31261, 39.355917],
                    [-76.311679, 39.355808],
                    [-76.31136, 39.355693],
                    [-76.310829, 39.3555],
                    [-76.310618, 39.355423],
                    [-76.30177, 39.352216],
                    [-76.295678, 39.350008],
                    [-76.294978, 39.346608],
                    [-76.295558, 39.345631],
                    [-76.297569, 39.342244],
                    [-76.298778, 39.340208],
                    [-76.298778, 39.329208],
                    [-76.291078, 39.318108],
                    [-76.297878, 39.302408],
                    [-76.296546, 39.302383],
                    [-76.2817, 39.30211],
                    [-76.281578, 39.302108],
                    [-76.281556, 39.302369],
                    [-76.281429, 39.303872],
                    [-76.281374, 39.304531],
                    [-76.281088, 39.30793],
                    [-76.280778, 39.311608],
                    [-76.280599, 39.312037],
                    [-76.278499, 39.317088],
                    [-76.278351, 39.317444],
                    [-76.278239, 39.317712],
                    [-76.277866, 39.31861],
                    [-76.277813, 39.318738],
                    [-76.276078, 39.322908],
                    [-76.272671, 39.326015],
                    [-76.266407, 39.331727],
                    [-76.266257, 39.331864],
                    [-76.266094, 39.332013],
                    [-76.265777, 39.332302],
                    [-76.265615, 39.33245],
                    [-76.263624, 39.334265],
                    [-76.263577, 39.334308],
                    [-76.263073, 39.334436],
                    [-76.262471, 39.33459],
                    [-76.262008, 39.334708],
                    [-76.253928, 39.336768],
                    [-76.258342, 39.345737],
                    [-76.258377, 39.345808],
                    [-76.263019, 39.348634],
                    [-76.265277, 39.350008],
                    [-76.266365, 39.353352],
                    [-76.250483, 39.361136],
                    [-76.250107, 39.36132],
                    [-76.245273, 39.361671],
                    [-76.244988, 39.361691],
                    [-76.244443, 39.361731],
                    [-76.243377, 39.361808],
                    [-76.241268, 39.361567],
                    [-76.239877, 39.361408],
                    [-76.237943, 39.358429],
                    [-76.23652, 39.356236],
                    [-76.235795, 39.355119],
                    [-76.234765, 39.353532],
                    [-76.234743, 39.353498],
                    [-76.233776, 39.352008],
                    [-76.229614, 39.350723],
                    [-76.227625, 39.350109],
                    [-76.226976, 39.349908],
                    [-76.202346, 39.364455],
                    [-76.199356, 39.366221],
                    [-76.180074, 39.377609],
                    [-76.180057, 39.377638],
                    [-76.175928, 39.384648],
                    [-76.175597, 39.38521],
                    [-76.175121, 39.386019],
                    [-76.171747, 39.391747],
                    [-76.171474, 39.39221],
                    [-76.171134, 39.392588],
                    [-76.159358, 39.405661],
                    [-76.158774, 39.40631],
                    [-76.158592, 39.406295],
                    [-76.157108, 39.406176],
                    [-76.152357, 39.405793],
                    [-76.152059, 39.405769],
                    [-76.151948, 39.40576],
                    [-76.150934, 39.405678],
                    [-76.150516, 39.405644],
                    [-76.150249, 39.405623],
                    [-76.147048, 39.405364],
                    [-76.146373, 39.40531],
                    [-76.142224, 39.408007],
                    [-76.132114, 39.414579],
                    [-76.121889, 39.421226],
                    [-76.121754, 39.421396],
                    [-76.120991, 39.422358],
                    [-76.117172, 39.42717],
                    [-76.11682, 39.427614],
                    [-76.115096, 39.428565],
                    [-76.112761, 39.429852],
                    [-76.102232, 39.435659],
                    [-76.093595, 39.436871],
                    [-76.083405, 39.438302],
                    [-76.083269, 39.438321],
                    [-76.081409, 39.436891],
                    [-76.081176, 39.436712],
                    [-76.060989, 39.447722],
                    [-76.060988, 39.447775],
                    [-76.060944, 39.451174],
                    [-76.060931, 39.452208],
                    [-76.062259, 39.454955],
                    [-76.063874, 39.458295],
                    [-76.069814, 39.470577],
                    [-76.071836, 39.47476],
                    [-76.071975, 39.475047],
                    [-76.072309, 39.47513],
                    [-76.073119, 39.475331],
                    [-76.08159, 39.477438],
                    [-76.083082, 39.477809],
                    [-76.083286, 39.47786],
                    [-76.098315, 39.476116],
                    [-76.099506, 39.476618],
                    [-76.100218, 39.476918],
                    [-76.104665, 39.478792],
                    [-76.108813, 39.482333],
                    [-76.113929, 39.486701],
                    [-76.11461, 39.488619],
                    [-76.117253, 39.496068],
                    [-76.116831, 39.496882],
                    [-76.111583, 39.507001],
                    [-76.107126, 39.515597],
                    [-76.106215, 39.517353],
                    [-76.105678, 39.518388],
                    [-76.105571, 39.518595],
                    [-76.102955, 39.523639],
                    [-76.096072, 39.536912],
                    [-76.075714, 39.543032],
                    [-76.063811, 39.54661],
                    [-76.063379, 39.546638],
                    [-76.046876, 39.547713],
                    [-76.03042, 39.548784],
                    [-76.006341, 39.550352],
                    [-76.006272, 39.550457],
                    [-76.006213, 39.550546],
                    [-76.004207, 39.553594],
                    [-76.003434, 39.554768],
                    [-76.002793, 39.555741],
                    [-76.001244, 39.558096],
                    [-76.00019, 39.559696],
                    [-75.999669, 39.560488],
                    [-75.992633, 39.563098],
                    [-75.970337, 39.557637],
                    [-75.967221, 39.55014],
                    [-75.967186, 39.548627],
                    [-75.967173, 39.548047],
                    [-75.967093, 39.544623],
                    [-75.966959, 39.538839],
                    [-75.966955, 39.53865],
                    [-75.973678, 39.532237],
                    [-75.975313, 39.530678],
                    [-75.976057, 39.529968],
                    [-75.976105, 39.529876],
                    [-75.980279, 39.5219],
                    [-75.985238, 39.512424],
                    [-75.985403, 39.512108],
                    [-75.986298, 39.510398],
                    [-75.993469, 39.490583],
                    [-75.994135, 39.488743],
                    [-75.995289, 39.483014],
                    [-75.99657, 39.476658],
                    [-76.002926, 39.469642],
                    [-76.010009, 39.45717],
                    [-76.011716, 39.454165],
                    [-76.012312, 39.453115],
                    [-76.01188, 39.452524],
                    [-76.009536, 39.449316],
                    [-76.009452, 39.449201],
                    [-76.009071, 39.449256],
                    [-76.002579, 39.450194],
                    [-76.002513, 39.450204],
                    [-76.002497, 39.450231],
                    [-75.998276, 39.457182],
                    [-75.994432, 39.457862],
                    [-75.991779, 39.458332],
                    [-75.990128, 39.458624],
                    [-75.990005, 39.458646],
                    [-75.976601, 39.447808],
                    [-75.976698, 39.44569],
                    [-75.976747, 39.444627],
                    [-75.977751, 39.44302],
                    [-75.982585, 39.435287],
                    [-75.98884, 39.433187],
                    [-75.992425, 39.431983],
                    [-75.992742, 39.431877],
                    [-75.996697, 39.430549],
                    [-75.997396, 39.430314],
                    [-75.998586, 39.428333],
                    [-76.002856, 39.421225],
                    [-76.00688, 39.414527],
                    [-76.009693, 39.41276],
                    [-76.012567, 39.410955],
                    [-76.015763, 39.408947],
                    [-76.016531, 39.408465],
                    [-76.018061, 39.407929],
                    [-76.018726, 39.407696],
                    [-76.03472, 39.402093],
                    [-76.035002, 39.401994],
                    [-76.035298, 39.401609],
                    [-76.040612, 39.394692],
                    [-76.040962, 39.394237],
                    [-76.040854, 39.393594],
                    [-76.039973, 39.388327],
                    [-76.039932, 39.38808],
                    [-76.039279, 39.387796],
                    [-76.035644, 39.386213],
                    [-76.035568, 39.38618],
                    [-76.035464, 39.386176],
                    [-76.022304, 39.385716],
                    [-76.006805, 39.385174],
                    [-76.002515, 39.385024],
                    [-76.002514, 39.384805],
                    [-76.002463, 39.37644],
                    [-76.002408, 39.367501],
                    [-76.006546, 39.366374],
                    [-76.019091, 39.362958],
                    [-76.02299, 39.361896],
                    [-76.030064, 39.365826],
                    [-76.031767, 39.366772],
                    [-76.032923, 39.367414],
                    [-76.049846, 39.370644],
                    [-76.054157, 39.370759],
                    [-76.057876, 39.370857],
                    [-76.05872, 39.37088],
                    [-76.059335, 39.370896],
                    [-76.061504, 39.370954],
                    [-76.065716, 39.371066],
                    [-76.065887, 39.37107],
                    [-76.074992, 39.371312],
                    [-76.108373, 39.3722],
                    [-76.108942, 39.372215],
                    [-76.110527, 39.372257],
                    [-76.110598, 39.372119],
                    [-76.113215, 39.367032],
                    [-76.115935, 39.361744],
                    [-76.116356, 39.360925],
                    [-76.116698, 39.360744],
                    [-76.129983, 39.353702],
                    [-76.13495, 39.35107],
                    [-76.136971, 39.344414],
                    [-76.135105, 39.34246],
                    [-76.133225, 39.340491],
                    [-76.145524, 39.334399],
                    [-76.152722, 39.335167],
                    [-76.157163, 39.335641],
                    [-76.159673, 39.335909],
                    [-76.168365, 39.332824],
                    [-76.169411, 39.332453],
                    [-76.170422, 39.332094],
                    [-76.170541, 39.331994],
                    [-76.170588, 39.331954],
                    [-76.185507, 39.319396],
                    [-76.185581, 39.319334],
                    [-76.186001, 39.317814],
                    [-76.186647, 39.315475],
                    [-76.186637, 39.315426],
                    [-76.186024, 39.312462],
                    [-76.179092, 39.310098],
                    [-76.178983, 39.309926],
                    [-76.176865, 39.306584],
                    [-76.176778, 39.306447],
                    [-76.176804, 39.306229],
                    [-76.177178, 39.303099],
                    [-76.177704, 39.298701],
                    [-76.177712, 39.298686],
                    [-76.181478, 39.291829],
                    [-76.181496, 39.291797],
                    [-76.185674, 39.285367],
                    [-76.185909, 39.285157],
                    [-76.189868, 39.281623],
                    [-76.191285, 39.280358],
                    [-76.193029, 39.278801],
                    [-76.20259, 39.270265],
                    [-76.203031, 39.269871],
                    [-76.210748, 39.269816],
                    [-76.211179, 39.269813],
                    [-76.211253, 39.269812],
                    [-76.211306, 39.269761],
                    [-76.21752, 39.263754],
                    [-76.218339, 39.262963],
                    [-76.219312, 39.262022],
                    [-76.219338, 39.261997],
                    [-76.220475, 39.259433],
                    [-76.22622, 39.246485],
                    [-76.232051, 39.233341],
                    [-76.232612, 39.232333],
                    [-76.248741, 39.203333],
                    [-76.251032, 39.199214],
                    [-76.255831, 39.191595],
                    [-76.266602, 39.180523],
                    [-76.266963, 39.179848],
                    [-76.268631, 39.176727],
                    [-76.269467, 39.175163],
                    [-76.274303, 39.166115],
                    [-76.274637, 39.16549],
                    [-76.274741, 39.164961],
                    [-76.27566, 39.160304],
                    [-76.276807, 39.154484],
                    [-76.278527, 39.145764],
                    [-76.276721, 39.145522],
                    [-76.274907, 39.145279],
                    [-76.268096, 39.144367],
                    [-76.26654, 39.144158],
                    [-76.264417, 39.143874],
                    [-76.260894, 39.143402],
                    [-76.260343, 39.142722],
                    [-76.252968, 39.133626],
                    [-76.252946, 39.133577],
                    [-76.24505, 39.116484],
                    [-76.240603, 39.106856],
                    [-76.233457, 39.091385],
                    [-76.231748, 39.082826],
                    [-76.231427, 39.071714],
                    [-76.231117, 39.061017],
                    [-76.231212, 39.060769],
                    [-76.237065, 39.045521],
                    [-76.2375, 39.044872],
                    [-76.239443, 39.041977],
                    [-76.240905, 39.039798],
                    [-76.242687, 39.028926],
                    [-76.241932, 39.028206],
                    [-76.240226, 39.026581],
                    [-76.231765, 39.018518],
                    [-76.223671, 39.015478],
                    [-76.221148, 39.01453],
                    [-76.21685, 39.012916],
                    [-76.209285, 39.010074],
                    [-76.209114, 39.01001],
                    [-76.202176, 39.013714],
                    [-76.201005, 39.014339],
                    [-76.200666, 39.01452],
                    [-76.205958, 39.021475],
                    [-76.206796, 39.022575],
                    [-76.206852, 39.022649],
                    [-76.208346, 39.024614],
                    [-76.208502, 39.024818],
                    [-76.209517, 39.028848],
                    [-76.209827, 39.030081],
                    [-76.210477, 39.032664],
                    [-76.211589, 39.03708],
                    [-76.21204, 39.03887],
                    [-76.212616, 39.041158],
                    [-76.212602, 39.041288],
                    [-76.212563, 39.041641],
                    [-76.211838, 39.048184],
                    [-76.210876, 39.056874],
                    [-76.210251, 39.062511],
                    [-76.210041, 39.06441],
                    [-76.207604, 39.072176],
                    [-76.205108, 39.080129],
                    [-76.203548, 39.085101],
                    [-76.203383, 39.085626],
                    [-76.203333, 39.085654],
                    [-76.194703, 39.090403],
                    [-76.189907, 39.093042],
                    [-76.183908, 39.096344],
                    [-76.169891, 39.09507],
                    [-76.169414, 39.095027],
                    [-76.169087, 39.094997],
                    [-76.167347, 39.094839],
                    [-76.15809, 39.093998],
                    [-76.145174, 39.092824],
                    [-76.150528, 39.079421],
                    [-76.153465, 39.074568],
                    [-76.156572, 39.069433],
                    [-76.15896, 39.065486],
                    [-76.16906, 39.062787],
                    [-76.175284, 39.058805],
                    [-76.177046, 39.056329],
                    [-76.179456, 39.052941],
                    [-76.184207, 39.046264],
                    [-76.178281, 39.031663],
                    [-76.178115, 39.031456],
                    [-76.177832, 39.031102],
                    [-76.174633, 39.0271],
                    [-76.173393, 39.02555],
                    [-76.167574, 39.018273],
                    [-76.163616, 39.010057],
                    [-76.163898, 39.002096],
                    [-76.163956, 39.000437],
                    [-76.163988, 38.999542],
                    [-76.163988, 38.999541],
                    [-76.164004, 38.99953],
                    [-76.168332, 38.996546],
                    [-76.180663, 38.988042],
                    [-76.182461, 38.986802],
                    [-76.187165, 38.983558],
                    [-76.202081, 38.973272],
                    [-76.20236, 38.973079],
                    [-76.218773, 38.970562],
                    [-76.218929, 38.970538],
                    [-76.228748, 38.97722],
                    [-76.229277, 38.97758],
                    [-76.229993, 38.977728],
                    [-76.243619, 38.980534],
                    [-76.246049, 38.981035],
                    [-76.258724, 38.983646],
                    [-76.258813, 38.983664],
                    [-76.275964, 38.982587],
                    [-76.277457, 38.982493],
                    [-76.277478, 38.982492],
                    [-76.278058, 38.983246],
                    [-76.280355, 38.986236],
                    [-76.28328, 38.990044],
                    [-76.284893, 38.992143],
                    [-76.286484, 38.994215],
                    [-76.289326, 38.997914],
                    [-76.2919, 39.001264],
                    [-76.293962, 39.003948],
                    [-76.29409, 39.004263],
                    [-76.30265, 39.025346],
                    [-76.302846, 39.025828],
                    [-76.301027, 39.031595],
                    [-76.30104, 39.031721],
                    [-76.301054, 39.031862],
                    [-76.301506, 39.036298],
                    [-76.301645, 39.037663],
                    [-76.301847, 39.039651],
                    [-76.302029, 39.039571],
                    [-76.311766, 39.035257],
                    [-76.320269, 39.02302],
                    [-76.320274, 39.023013],
                    [-76.320277, 39.022998],
                    [-76.32259, 39.0131],
                    [-76.323557, 39.008961],
                    [-76.322296, 39.006375],
                    [-76.322346, 39.005488],
                    [-76.322679, 38.999602],
                    [-76.323293, 38.998767],
                    [-76.33129, 38.98789],
                    [-76.333703, 38.984607],
                    [-76.343672, 38.971048],
                    [-76.351637, 38.960214],
                    [-76.353828, 38.957234],
                    [-76.354949, 38.954672],
                    [-76.357752, 38.948262],
                    [-76.357933, 38.947848],
                    [-76.359669, 38.94388],
                    [-76.361727, 38.939175],
                    [-76.36205, 38.936568],
                    [-76.364523, 38.916632],
                    [-76.36478, 38.914553],
                    [-76.36513, 38.911736],
                    [-76.365658, 38.907477],
                    [-76.365388, 38.898219],
                    [-76.365225, 38.892614],
                    [-76.364678, 38.873831],
                    [-76.367344, 38.868424],
                    [-76.367549, 38.868009],
                    [-76.372553, 38.85786],
                    [-76.375874, 38.851126],
                    [-76.376183, 38.8505],
                    [-76.376202, 38.850461],
                    [-76.376031, 38.848777],
                    [-76.375443, 38.842988],
                    [-76.375303, 38.841611],
                    [-76.375138, 38.839981],
                    [-76.375086, 38.839474],
                    [-76.373122, 38.838519],
                    [-76.372719, 38.838324],
                    [-76.368359, 38.836205],
                    [-76.368195, 38.836125],
                    [-76.368164, 38.836194],
                    [-76.366501, 38.839936],
                    [-76.36132, 38.851589],
                    [-76.361141, 38.851992],
                    [-76.360095, 38.852518],
                    [-76.35996, 38.852586],
                    [-76.356361, 38.854396],
                    [-76.35039, 38.857399],
                    [-76.348826, 38.857134],
                    [-76.340587, 38.85574],
                    [-76.339667, 38.85637],
                    [-76.338136, 38.857418],
                    [-76.337901, 38.857579],
                    [-76.334019, 38.860238],
                    [-76.33103, 38.86432],
                    [-76.331103, 38.864686],
                    [-76.332651, 38.872438],
                    [-76.333211, 38.875241],
                    [-76.333258, 38.875478],
                    [-76.333801, 38.878194],
                    [-76.335341, 38.885907],
                    [-76.335364, 38.886021],
                    [-76.335681, 38.886673],
                    [-76.336326, 38.888001],
                    [-76.33656, 38.888481],
                    [-76.336986, 38.889357],
                    [-76.337946, 38.891332],
                    [-76.338501, 38.892474],
                    [-76.338416, 38.892954],
                    [-76.338123, 38.894601],
                    [-76.338047, 38.895034],
                    [-76.337358, 38.898915],
                    [-76.337227, 38.899653],
                    [-76.337097, 38.90038],
                    [-76.336966, 38.901118],
                    [-76.336827, 38.901905],
                    [-76.336116, 38.905907],
                    [-76.336104, 38.905977],
                    [-76.324102, 38.911172],
                    [-76.324097, 38.911174],
                    [-76.323252, 38.911193],
                    [-76.322715, 38.911205],
                    [-76.319272, 38.911282],
                    [-76.31853, 38.911299],
                    [-76.317977, 38.911311],
                    [-76.317947, 38.911312],
                    [-76.308425, 38.898404],
                    [-76.293254, 38.902568],
                    [-76.293255, 38.902582],
                    [-76.293309, 38.903244],
                    [-76.293328, 38.903479],
                    [-76.293341, 38.903635],
                    [-76.293358, 38.903854],
                    [-76.293492, 38.905499],
                    [-76.293794, 38.909222],
                    [-76.293829, 38.909649],
                    [-76.293843, 38.909826],
                    [-76.293867, 38.910121],
                    [-76.293963, 38.910266],
                    [-76.298492, 38.917121],
                    [-76.299406, 38.918505],
                    [-76.299431, 38.918542],
                    [-76.298208, 38.92206],
                    [-76.295911, 38.928663],
                    [-76.29558, 38.928855],
                    [-76.291211, 38.931394],
                    [-76.288813, 38.932787],
                    [-76.284227, 38.935452],
                    [-76.276033, 38.940213],
                    [-76.273258, 38.941826],
                    [-76.273083, 38.941927],
                    [-76.273022, 38.94184],
                    [-76.271625, 38.939844],
                    [-76.270954, 38.938886],
                    [-76.269179, 38.936349],
                    [-76.26626, 38.932179],
                    [-76.265772, 38.931482],
                    [-76.264943, 38.930297],
                    [-76.264692, 38.924764],
                    [-76.264683, 38.924576],
                    [-76.262226, 38.919976],
                    [-76.258206, 38.919185],
                    [-76.25808, 38.91916],
                    [-76.256397, 38.918829],
                    [-76.25619, 38.918893],
                    [-76.255819, 38.919008],
                    [-76.249889, 38.920841],
                    [-76.249674, 38.920907],
                    [-76.249355, 38.921465],
                    [-76.249285, 38.921587],
                    [-76.249163, 38.9218],
                    [-76.248023, 38.92379],
                    [-76.248472, 38.924494],
                    [-76.248574, 38.924655],
                    [-76.248702, 38.924855],
                    [-76.248844, 38.925076],
                    [-76.248896, 38.925159],
                    [-76.249246, 38.925707],
                    [-76.249622, 38.926297],
                    [-76.250838, 38.928204],
                    [-76.250868, 38.92825],
                    [-76.250157, 38.938667],
                    [-76.234681, 38.941956],
                    [-76.233895, 38.942123],
                    [-76.232038, 38.942518],
                    [-76.228405, 38.941489],
                    [-76.22813, 38.941412],
                    [-76.213842, 38.937366],
                    [-76.213731, 38.937269],
                    [-76.207695, 38.931954],
                    [-76.203638, 38.928382],
                    [-76.204091, 38.917058],
                    [-76.204122, 38.916269],
                    [-76.204129, 38.916096],
                    [-76.204544, 38.905715],
                    [-76.205063, 38.892726],
                    [-76.200082, 38.882885],
                    [-76.202314, 38.864906],
                    [-76.202598, 38.862616],
                    [-76.200728, 38.855389],
                    [-76.199544, 38.850817],
                    [-76.197736, 38.84383],
                    [-76.197705, 38.843712],
                    [-76.192973, 38.833661],
                    [-76.192946, 38.833603],
                    [-76.192874, 38.83345],
                    [-76.191172, 38.829834],
                    [-76.19109, 38.82966],
                    [-76.192537, 38.82479],
                    [-76.192765, 38.824024],
                    [-76.192865, 38.823687],
                    [-76.192924, 38.823489],
                    [-76.19302, 38.823165],
                    [-76.193073, 38.822989],
                    [-76.193272, 38.822319],
                    [-76.19343, 38.821787],
                    [-76.19537, 38.818759],
                    [-76.196426, 38.817111],
                    [-76.196488, 38.817015],
                    [-76.197432, 38.815541],
                    [-76.198138, 38.81444],
                    [-76.219328, 38.812371],
                    [-76.221162, 38.813052],
                    [-76.228071, 38.815617],
                    [-76.229199, 38.816036],
                    [-76.23877, 38.81959],
                    [-76.245886, 38.822232],
                    [-76.250296, 38.825389],
                    [-76.250364, 38.825438],
                    [-76.255322, 38.832525],
                    [-76.255343, 38.832554],
                    [-76.255787, 38.833189],
                    [-76.257353, 38.835428],
                    [-76.261627, 38.841536],
                    [-76.261907, 38.841936],
                    [-76.262179, 38.842325],
                    [-76.265678, 38.847326],
                    [-76.265808, 38.847512],
                    [-76.265759, 38.847638],
                    [-76.264221, 38.851572],
                    [-76.265999, 38.85162],
                    [-76.267175, 38.851652],
                    [-76.271575, 38.851771],
                    [-76.274405, 38.844766],
                    [-76.27804, 38.835768],
                    [-76.278151, 38.835494],
                    [-76.27742, 38.831468],
                    [-76.277411, 38.831419],
                    [-76.277748, 38.831295],
                    [-76.277854, 38.831256],
                    [-76.284768, 38.828706],
                    [-76.288455, 38.827347],
                    [-76.292144, 38.827729],
                    [-76.293648, 38.827884],
                    [-76.295436, 38.828069],
                    [-76.295454, 38.828071],
                    [-76.29555, 38.828081],
                    [-76.295992, 38.828127],
                    [-76.296259, 38.828155],
                    [-76.29632, 38.828161],
                    [-76.296329, 38.828162],
                    [-76.296364, 38.828165],
                    [-76.296394, 38.828168],
                    [-76.296471, 38.828176],
                    [-76.296564, 38.828186],
                    [-76.2978, 38.828314],
                    [-76.298956, 38.827519],
                    [-76.300886, 38.826192],
                    [-76.300889, 38.82619],
                    [-76.301886, 38.824595],
                    [-76.307267, 38.815993],
                    [-76.308127, 38.814618],
                    [-76.308294, 38.814349],
                    [-76.308306, 38.814331],
                    [-76.308922, 38.813346],
                    [-76.310081, 38.796846],
                    [-76.310556, 38.796237],
                    [-76.310626, 38.796147],
                    [-76.310664, 38.796098],
                    [-76.310743, 38.795996],
                    [-76.312886, 38.793247],
                    [-76.3227, 38.780657],
                    [-76.323768, 38.779287],
                    [-76.329721, 38.775789],
                    [-76.333752, 38.77342],
                    [-76.334619, 38.772911],
                    [-76.341302, 38.751901],
                    [-76.341297, 38.751756],
                    [-76.341293, 38.751654],
                    [-76.341288, 38.751505],
                    [-76.3411, 38.746148],
                    [-76.341096, 38.746058],
                    [-76.341093, 38.745959],
                    [-76.34074, 38.735932],
                    [-76.340739, 38.735894],
                    [-76.340732, 38.735712],
                    [-76.340715, 38.735215],
                    [-76.340677, 38.734136],
                    [-76.340645, 38.733225],
                    [-76.340641, 38.733123],
                    [-76.340637, 38.733003],
                    [-76.340635, 38.73296],
                    [-76.340632, 38.732857],
                    [-76.340628, 38.732756],
                    [-76.340618, 38.732464],
                    [-76.340608, 38.732179],
                    [-76.340567, 38.731008],
                    [-76.340543, 38.730338],
                    [-76.344597, 38.706352],
                    [-76.345072, 38.703546],
                    [-76.347998, 38.686234],
                    [-76.344199, 38.678798],
                    [-76.343784, 38.677985],
                    [-76.343277, 38.676992],
                    [-76.34322, 38.67688],
                    [-76.340341, 38.671245],
                    [-76.340065, 38.671369],
                    [-76.33861, 38.672023],
                    [-76.322418, 38.679304],
                    [-76.321915, 38.688584],
                    [-76.321905, 38.688768],
                    [-76.321898, 38.688902],
                    [-76.321881, 38.689217],
                    [-76.321865, 38.689512],
                    [-76.332079, 38.700956],
                    [-76.333862, 38.702953],
                    [-76.334017, 38.703127],
                    [-76.333777, 38.704086],
                    [-76.333532, 38.705063],
                    [-76.333066, 38.706927],
                    [-76.332517, 38.709118],
                    [-76.331479, 38.713266],
                    [-76.330149, 38.714682],
                    [-76.328338, 38.716609],
                    [-76.328035, 38.716932],
                    [-76.327162, 38.717861],
                    [-76.326135, 38.718954],
                    [-76.32353, 38.721727],
                    [-76.323214, 38.722063],
                    [-76.322975, 38.722317],
                    [-76.32286, 38.722439],
                    [-76.322807, 38.722496],
                    [-76.32273, 38.722578],
                    [-76.322706, 38.722604],
                    [-76.322705, 38.722605],
                    [-76.322663, 38.72265],
                    [-76.322028, 38.723326],
                    [-76.322019, 38.723335],
                    [-76.321885, 38.723478],
                    [-76.321803, 38.723565],
                    [-76.316146, 38.729586],
                    [-76.312756, 38.730708],
                    [-76.299401, 38.727395],
                    [-76.29935, 38.727347],
                    [-76.298186, 38.726255],
                    [-76.295957, 38.724162],
                    [-76.296537, 38.723283],
                    [-76.296603, 38.723183],
                    [-76.299171, 38.719287],
                    [-76.298499, 38.718005],
                    [-76.275019, 38.712715],
                    [-76.275015, 38.712714],
                    [-76.271596, 38.713216],
                    [-76.271553, 38.713576],
                    [-76.271447, 38.71448],
                    [-76.271243, 38.716209],
                    [-76.270277, 38.724385],
                    [-76.26952, 38.724988],
                    [-76.26937, 38.725107],
                    [-76.268739, 38.725609],
                    [-76.268288, 38.725969],
                    [-76.261863, 38.731085],
                    [-76.260976, 38.731791],
                    [-76.260001, 38.732568],
                    [-76.259876, 38.732667],
                    [-76.259867, 38.732675],
                    [-76.259741, 38.732775],
                    [-76.259665, 38.732836],
                    [-76.259238, 38.733176],
                    [-76.258738, 38.733573],
                    [-76.257851, 38.73428],
                    [-76.257437, 38.734609],
                    [-76.257423, 38.734621],
                    [-76.257335, 38.734691],
                    [-76.255348, 38.736273],
                    [-76.255093, 38.736476],
                    [-76.238685, 38.735434],
                    [-76.23704, 38.724518],
                    [-76.239841, 38.719756],
                    [-76.239427, 38.717191],
                    [-76.239377, 38.71688],
                    [-76.238725, 38.712845],
                    [-76.238306, 38.712344],
                    [-76.238172, 38.712185],
                    [-76.238077, 38.712071],
                    [-76.237916, 38.711879],
                    [-76.237818, 38.711762],
                    [-76.234828, 38.708191],
                    [-76.234392, 38.70767],
                    [-76.234379, 38.707655],
                    [-76.234249, 38.707499],
                    [-76.232893, 38.70588],
                    [-76.232875, 38.705859],
                    [-76.232845, 38.705823],
                    [-76.232814, 38.705785],
                    [-76.232535, 38.705452],
                    [-76.232365, 38.705249],
                    [-76.232127, 38.704964],
                    [-76.231758, 38.704524],
                    [-76.231392, 38.704088],
                    [-76.229242, 38.701519],
                    [-76.226343, 38.698057],
                    [-76.212808, 38.681892],
                    [-76.200334, 38.670774],
                    [-76.199948, 38.670997],
                    [-76.199722, 38.671127],
                    [-76.196716, 38.67286],
                    [-76.175159, 38.673236],
                    [-76.174611, 38.672811],
                    [-76.168824, 38.668325],
                    [-76.164465, 38.664946],
                    [-76.160539, 38.661903],
                    [-76.155611, 38.658083],
                    [-76.154924, 38.656358],
                    [-76.154889, 38.656268],
                    [-76.154604, 38.655553],
                    [-76.154581, 38.655495],
                    [-76.154093, 38.654269],
                    [-76.153769, 38.653455],
                    [-76.153414, 38.652562],
                    [-76.15299, 38.651495],
                    [-76.152622, 38.650572],
                    [-76.152603, 38.650523],
                    [-76.152554, 38.6504],
                    [-76.149353, 38.642356],
                    [-76.148955, 38.641356],
                    [-76.148559, 38.640362],
                    [-76.148202, 38.639463],
                    [-76.147577, 38.637893],
                    [-76.147158, 38.63684],
                    [-76.152885, 38.631819],
                    [-76.160148, 38.625452],
                    [-76.170066, 38.629225],
                    [-76.174969, 38.628791],
                    [-76.190902, 38.621092],
                    [-76.202598, 38.613011],
                    [-76.203065, 38.610741],
                    [-76.212414, 38.606744],
                    [-76.212427, 38.606738],
                    [-76.220701, 38.609945],
                    [-76.222982, 38.61083],
                    [-76.22882, 38.613093],
                    [-76.229159, 38.613224],
                    [-76.229358, 38.613301],
                    [-76.231187, 38.61401],
                    [-76.234929, 38.624002],
                    [-76.235987, 38.626827],
                    [-76.23665, 38.628598],
                    [-76.24651, 38.626282],
                    [-76.247896, 38.625404],
                    [-76.253926, 38.621586],
                    [-76.260317, 38.617539],
                    [-76.261135, 38.617021],
                    [-76.262791, 38.615973],
                    [-76.263577, 38.615475],
                    [-76.264155, 38.615109],
                    [-76.271827, 38.615661],
                    [-76.276734, 38.611779],
                    [-76.278146, 38.610662],
                    [-76.279589, 38.60952],
                    [-76.275658, 38.605298],
                    [-76.275282, 38.604895],
                    [-76.275246, 38.604856],
                    [-76.274888, 38.604471],
                    [-76.274611, 38.604174],
                    [-76.272584, 38.601997],
                    [-76.268633, 38.597753],
                    [-76.273496, 38.59139],
                    [-76.290895, 38.582548],
                    [-76.291998, 38.581988],
                    [-76.305172, 38.575293],
                    [-76.308321, 38.571769],
                    [-76.299301, 38.57048],
                    [-76.296469, 38.570076],
                    [-76.290667, 38.569247],
                    [-76.290043, 38.569158],
                    [-76.289017, 38.567982],
                    [-76.283632, 38.561807],
                    [-76.283189, 38.5613],
                    [-76.27964, 38.557231],
                    [-76.275913, 38.548809],
                    [-76.277461, 38.541851],
                    [-76.281047, 38.53613],
                    [-76.278106, 38.532468],
                    [-76.274057, 38.531207],
                    [-76.253624, 38.539393],
                    [-76.251033, 38.539191],
                    [-76.250265, 38.539131],
                    [-76.248885, 38.539023],
                    [-76.244396, 38.536966],
                    [-76.2473, 38.523818],
                    [-76.247894, 38.523019],
                    [-76.26035, 38.506255],
                    [-76.262133, 38.504874],
                    [-76.263968, 38.503452],
                    [-76.281761, 38.502165],
                    [-76.283595, 38.504157],
                    [-76.289507, 38.503906],
                    [-76.318054, 38.498199],
                    [-76.327257, 38.500121],
                    [-76.33636, 38.492235],
                    [-76.33199, 38.475225],
                    [-76.331758, 38.474324],
                    [-76.331559, 38.473548],
                    [-76.331469, 38.473432],
                    [-76.331383, 38.473323],
                    [-76.320843, 38.459862],
                    [-76.311767, 38.450439],
                    [-76.3116, 38.450266],
                    [-76.301488, 38.439767],
                    [-76.300186, 38.437916],
                    [-76.290681, 38.424404],
                    [-76.28302, 38.413512],
                    [-76.280551, 38.403143],
                    [-76.282271, 38.393118],
                    [-76.282144, 38.392754],
                    [-76.281697, 38.39147],
                    [-76.281396, 38.390604],
                    [-76.28132, 38.390384],
                    [-76.281214, 38.390079],
                    [-76.280749, 38.388743],
                    [-76.278813, 38.383179],
                    [-76.278722, 38.382919],
                    [-76.273003, 38.366483],
                    [-76.258022, 38.366695],
                    [-76.257479, 38.366702],
                    [-76.256788, 38.366712],
                    [-76.249666, 38.364214],
                    [-76.248766, 38.363076],
                    [-76.24735, 38.361285],
                    [-76.24041, 38.352508],
                    [-76.240196, 38.352238],
                    [-76.239902, 38.351866],
                    [-76.239601, 38.351486],
                    [-76.239056, 38.350795],
                    [-76.23901, 38.350738],
                    [-76.238611, 38.350233],
                    [-76.238541, 38.349238],
                    [-76.238478, 38.348355],
                    [-76.238452, 38.347986],
                    [-76.257898, 38.342014],
                    [-76.258383, 38.341865],
                    [-76.259235, 38.341603],
                    [-76.259261, 38.341595],
                    [-76.259286, 38.341619],
                    [-76.264186, 38.346436],
                    [-76.266602, 38.339502],
                    [-76.258189, 38.318373],
                    [-76.254473, 38.31512],
                    [-76.243897, 38.310313],
                    [-76.226408, 38.309989],
                    [-76.226376, 38.309988],
                    [-76.218051, 38.305899],
                    [-76.217616, 38.305686],
                    [-76.216266, 38.305023],
                    [-76.211446, 38.302656],
                    [-76.203909, 38.293461],
                    [-76.20343, 38.292876],
                    [-76.202897, 38.292226],
                    [-76.202628, 38.291898],
                    [-76.202334, 38.291539],
                    [-76.201603, 38.290647],
                    [-76.201591, 38.290633],
                    [-76.197871, 38.286094],
                    [-76.197693, 38.285877],
                    [-76.197155, 38.28522],
                    [-76.190531, 38.277139],
                    [-76.189655, 38.272633],
                    [-76.189627, 38.272487],
                    [-76.188644, 38.267434],
                    [-76.17335, 38.247037],
                    [-76.151035, 38.234215],
                    [-76.140068, 38.231305],
                    [-76.131332, 38.23288],
                    [-76.128898, 38.235267],
                    [-76.128452, 38.235704],
                    [-76.125756, 38.238348],
                    [-76.125856, 38.23888],
                    [-76.126453, 38.242046],
                    [-76.126623, 38.242949],
                    [-76.130952, 38.24443],
                    [-76.132544, 38.244974],
                    [-76.13498, 38.245807],
                    [-76.135169, 38.245872],
                    [-76.146297, 38.249678],
                    [-76.163249, 38.248913],
                    [-76.164388, 38.250061],
                    [-76.171254, 38.256984],
                    [-76.175783, 38.261551],
                    [-76.178282, 38.270336],
                    [-76.179123, 38.273291],
                    [-76.180103, 38.276739],
                    [-76.180165, 38.276956],
                    [-76.180115, 38.277019],
                    [-76.174683, 38.283818],
                    [-76.174598, 38.283924],
                    [-76.174484, 38.284068],
                    [-76.174104, 38.284543],
                    [-76.174011, 38.284659],
                    [-76.173844, 38.284869],
                    [-76.173555, 38.28523],
                    [-76.173418, 38.285326],
                    [-76.166154, 38.290431],
                    [-76.161348, 38.290898],
                    [-76.160838, 38.290948],
                    [-76.160474, 38.290983],
                    [-76.149876, 38.284663],
                    [-76.149398, 38.284525],
                    [-76.143127, 38.282714],
                    [-76.140947, 38.282085],
                    [-76.140674, 38.282006],
                    [-76.138596, 38.281406],
                    [-76.138524, 38.281385],
                    [-76.138479, 38.281394],
                    [-76.137442, 38.281606],
                    [-76.137238, 38.281648],
                    [-76.131085, 38.282904],
                    [-76.12694, 38.283751],
                    [-76.111296, 38.286946],
                    [-76.102549, 38.277153],
                    [-76.107592, 38.262525],
                    [-76.09972, 38.253647],
                    [-76.094689, 38.25335],
                    [-76.092723, 38.253234],
                    [-76.092417, 38.253216],
                    [-76.089797, 38.253061],
                    [-76.082549, 38.252633],
                    [-76.082268, 38.252616],
                    [-76.074897, 38.252181],
                    [-76.07476, 38.251832],
                    [-76.074726, 38.251745],
                    [-76.074607, 38.251443],
                    [-76.074568, 38.251344],
                    [-76.074515, 38.251208],
                    [-76.074491, 38.251148],
                    [-76.073725, 38.2492],
                    [-76.073493, 38.248609],
                    [-76.071154, 38.242657],
                    [-76.070831, 38.241836],
                    [-76.069502, 38.238455],
                    [-76.05801, 38.227079],
                    [-76.032044, 38.216684],
                    [-76.033338, 38.221599],
                    [-76.033455, 38.222046],
                    [-76.035695, 38.230556],
                    [-76.036102, 38.231094],
                    [-76.037103, 38.232418],
                    [-76.037109, 38.232426],
                    [-76.03796, 38.233551],
                    [-76.043814, 38.241294],
                    [-76.044108, 38.241682],
                    [-76.044141, 38.243447],
                    [-76.044251, 38.249373],
                    [-76.043927, 38.249712],
                    [-76.039738, 38.254093],
                    [-76.038935, 38.254932],
                    [-76.033737, 38.266363],
                    [-76.03361, 38.266644],
                    [-76.032998, 38.267989],
                    [-76.032771, 38.268487],
                    [-76.032664, 38.268722],
                    [-76.032595, 38.268875],
                    [-76.027487, 38.280108],
                    [-76.027557, 38.280288],
                    [-76.028234, 38.282035],
                    [-76.030532, 38.28796],
                    [-76.040019, 38.295738],
                    [-76.047147, 38.301582],
                    [-76.05022, 38.304101],
                    [-76.049647, 38.309017],
                    [-76.049609, 38.309348],
                    [-76.04958, 38.309594],
                    [-76.049523, 38.309718],
                    [-76.049207, 38.310404],
                    [-76.048965, 38.31093],
                    [-76.048637, 38.311643],
                    [-76.047992, 38.313044],
                    [-76.047401, 38.314329],
                    [-76.046367, 38.316576],
                    [-76.045964, 38.317452],
                    [-76.045599, 38.318246],
                    [-76.045057, 38.318776],
                    [-76.044649, 38.319175],
                    [-76.043923, 38.319884],
                    [-76.04318, 38.32061],
                    [-76.041659, 38.322097],
                    [-76.041618, 38.322137],
                    [-76.041431, 38.322163],
                    [-76.033947, 38.323211],
                    [-76.016682, 38.332429],
                    [-76.010437, 38.352504],
                    [-76.010366, 38.352732],
                    [-76.010217, 38.353211],
                    [-76.010737, 38.354251],
                    [-76.011033, 38.354844],
                    [-76.012149, 38.357077],
                    [-76.011869, 38.360582],
                    [-76.006949, 38.370216],
                    [-76.004946, 38.372045],
                    [-76.002282, 38.374477],
                    [-76.002156, 38.374439],
                    [-76.001839, 38.374343],
                    [-75.995706, 38.37248],
                    [-75.980829, 38.367962],
                    [-75.98018, 38.367765],
                    [-75.979727, 38.367627],
                    [-75.973876, 38.36585],
                    [-75.972281, 38.362584],
                    [-75.972174, 38.362365],
                    [-75.97191, 38.361826],
                    [-75.971541, 38.361069],
                    [-75.971019, 38.360002],
                    [-75.97084, 38.359635],
                    [-75.969161, 38.356197],
                    [-75.968881, 38.355623],
                    [-75.966397, 38.35054],
                    [-75.965366, 38.348429],
                    [-75.961948, 38.341431],
                    [-75.961946, 38.336018],
                    [-75.961945, 38.33583],
                    [-75.961944, 38.332572],
                    [-75.964237, 38.324285],
                    [-75.96888, 38.320498],
                    [-75.96929, 38.320164],
                    [-75.969577, 38.320044],
                    [-75.981345, 38.315147],
                    [-75.982523, 38.315022],
                    [-75.983171, 38.314954],
                    [-75.983186, 38.314952],
                    [-75.984433, 38.314821],
                    [-75.984784, 38.314783],
                    [-75.986025, 38.314652],
                    [-75.990485, 38.314181],
                    [-75.991151, 38.31411],
                    [-76.008647, 38.312261],
                    [-76.009377, 38.311997],
                    [-76.017364, 38.309106],
                    [-76.017308, 38.309007],
                    [-76.016514, 38.3076],
                    [-76.016314, 38.307247],
                    [-76.016291, 38.307206],
                    [-76.011458, 38.30564],
                    [-76.011245, 38.305572],
                    [-76.007478, 38.304351],
                    [-76.007375, 38.304318],
                    [-76.007254, 38.304165],
                    [-76.007118, 38.303994],
                    [-76.00401, 38.300079],
                    [-76.000241, 38.295331],
                    [-75.993391, 38.286702],
                    [-75.992829, 38.285994],
                    [-75.992669, 38.285792],
                    [-75.991687, 38.284555],
                    [-75.991162, 38.283894],
                    [-75.990385, 38.282915],
                    [-75.988731, 38.280581],
                    [-75.987814, 38.279287],
                    [-75.985815, 38.276466],
                    [-75.984274, 38.265155],
                    [-75.963453, 38.251793],
                    [-75.962235, 38.24754],
                    [-75.963969, 38.241598],
                    [-75.964119, 38.241085],
                    [-75.964528, 38.240692],
                    [-75.969326, 38.236076],
                    [-75.969383, 38.236021],
                    [-75.970115, 38.235317],
                    [-75.9704, 38.235043],
                    [-75.972212, 38.2333],
                    [-75.971487, 38.233457],
                    [-75.970514, 38.233668],
                    [-75.962931, 38.235311],
                    [-75.962729, 38.235355],
                    [-75.962111, 38.235489],
                    [-75.961972, 38.235519],
                    [-75.958794, 38.236208],
                    [-75.958338, 38.236306],
                    [-75.955833, 38.236849],
                    [-75.955164, 38.236994],
                    [-75.951156, 38.237862],
                    [-75.948197, 38.238504],
                    [-75.946414, 38.23889],
                    [-75.942804, 38.243949],
                    [-75.94119, 38.246211],
                    [-75.940697, 38.246902],
                    [-75.942263, 38.247826],
                    [-75.942465, 38.247945],
                    [-75.943032, 38.248279],
                    [-75.9445, 38.249145],
                    [-75.945678, 38.249051],
                    [-75.947876, 38.248875],
                    [-75.948796, 38.248802],
                    [-75.949458, 38.249273],
                    [-75.950056, 38.249699],
                    [-75.954542, 38.252894],
                    [-75.954561, 38.253476],
                    [-75.954582, 38.254108],
                    [-75.95481, 38.261016],
                    [-75.954908, 38.263997],
                    [-75.954908, 38.263998],
                    [-75.954824, 38.264071],
                    [-75.954737, 38.264146],
                    [-75.954701, 38.264177],
                    [-75.954568, 38.264293],
                    [-75.954483, 38.264366],
                    [-75.951647, 38.266819],
                    [-75.951563, 38.266892],
                    [-75.951512, 38.266936],
                    [-75.951497, 38.266949],
                    [-75.95136, 38.267006],
                    [-75.948346, 38.268261],
                    [-75.942703, 38.270611],
                    [-75.942462, 38.270711],
                    [-75.940302, 38.271611],
                    [-75.938577, 38.272329],
                    [-75.925492, 38.26667],
                    [-75.920279, 38.264415],
                    [-75.919446, 38.264056],
                    [-75.917297, 38.263126],
                    [-75.911143, 38.257951],
                    [-75.908272, 38.252045],
                    [-75.90845, 38.246648],
                    [-75.906016, 38.242447],
                    [-75.905852, 38.242165],
                    [-75.901381, 38.234448],
                    [-75.900661, 38.233206],
                    [-75.90004, 38.232133],
                    [-75.899781, 38.231921],
                    [-75.895879, 38.228717],
                    [-75.895689, 38.228561],
                    [-75.894583, 38.228439],
                    [-75.890669, 38.228009],
                    [-75.885909, 38.230866],
                    [-75.885676, 38.231006],
                    [-75.889356, 38.2395],
                    [-75.888513, 38.241423],
                    [-75.887409, 38.24208],
                    [-75.886841, 38.242418],
                    [-75.8852, 38.243395],
                    [-75.883435, 38.244445],
                    [-75.882873, 38.244449],
                    [-75.874653, 38.244514],
                    [-75.872464, 38.243968],
                    [-75.870318, 38.243432],
                    [-75.87031, 38.243425],
                    [-75.864628, 38.23832],
                    [-75.85916, 38.233407],
                    [-75.851528, 38.22655],
                    [-75.851396, 38.226432],
                    [-75.846377, 38.210477],
                    [-75.846713, 38.210295],
                    [-75.847032, 38.210122],
                    [-75.847299, 38.209977],
                    [-75.848473, 38.20934],
                    [-75.852258, 38.207286],
                    [-75.854406, 38.20612],
                    [-75.85696, 38.204734],
                    [-75.862444, 38.201759],
                    [-75.864104, 38.200858],
                    [-75.86847, 38.200037],
                    [-75.870975, 38.199566],
                    [-75.87725, 38.198386],
                    [-75.877751, 38.198292],
                    [-75.878293, 38.198407],
                    [-75.884544, 38.199739],
                    [-75.884603, 38.199751],
                    [-75.88494, 38.200493],
                    [-75.886217, 38.203309],
                    [-75.888073, 38.203813],
                    [-75.933932, 38.18967],
                    [-75.941448, 38.187352],
                    [-75.942375, 38.187066],
                    [-75.945419, 38.184094],
                    [-75.946586, 38.182955],
                    [-75.948738, 38.180854],
                    [-75.948891, 38.180704],
                    [-75.94942, 38.180188],
                    [-75.949575, 38.180037],
                    [-75.951566, 38.178093],
                    [-75.951583, 38.178014],
                    [-75.95185, 38.176794],
                    [-75.951972, 38.176239],
                    [-75.951812, 38.176053],
                    [-75.948709, 38.172451],
                    [-75.948566, 38.172285],
                    [-75.94822, 38.171884],
                    [-75.948129, 38.171778],
                    [-75.94728, 38.170792],
                    [-75.947417, 38.169439],
                    [-75.947487, 38.168736],
                    [-75.947534, 38.168274],
                    [-75.949752, 38.164486],
                    [-75.951273, 38.161887],
                    [-75.951425, 38.161436],
                    [-75.952411, 38.158513],
                    [-75.959616, 38.137141],
                    [-75.959496, 38.136915],
                    [-75.958786, 38.135572],
                    [-75.956434, 38.131126],
                    [-75.956428, 38.131115],
                    [-75.949557, 38.118127],
                    [-75.945297, 38.113091],
                    [-75.938484, 38.109976],
                    [-75.936663, 38.109956],
                    [-75.936866, 38.116745],
                    [-75.937015, 38.121749],
                    [-75.937055, 38.123077],
                    [-75.937089, 38.124209],
                    [-75.936773, 38.124355],
                    [-75.932738, 38.126216],
                    [-75.923797, 38.130339],
                    [-75.907264, 38.137964],
                    [-75.906497, 38.138317],
                    [-75.905599, 38.138732],
                    [-75.903442, 38.139726],
                    [-75.902004, 38.14039],
                    [-75.901058, 38.140826],
                    [-75.900355, 38.14115],
                    [-75.880978, 38.137015],
                    [-75.880707, 38.136957],
                    [-75.871037, 38.134893],
                    [-75.870056, 38.134684],
                    [-75.868636, 38.134381],
                    [-75.866, 38.134886],
                    [-75.85954, 38.140542],
                    [-75.858667, 38.140893],
                    [-75.854507, 38.142567],
                    [-75.849919, 38.144414],
                    [-75.843862, 38.144599],
                    [-75.827712, 38.133464],
                    [-75.827674, 38.133438],
                    [-75.827892, 38.133004],
                    [-75.827993, 38.132803],
                    [-75.837048, 38.114777],
                    [-75.837165, 38.114546],
                    [-75.837204, 38.114468],
                    [-75.837563, 38.113753],
                    [-75.842604, 38.113111],
                    [-75.86381, 38.100968],
                    [-75.865146, 38.098893],
                    [-75.865697, 38.098036],
                    [-75.870282, 38.090911],
                    [-75.871394, 38.089183],
                    [-75.880515, 38.075011],
                    [-75.874189, 38.060288],
                    [-75.871503, 38.05887],
                    [-75.868899, 38.059131],
                    [-75.867511, 38.05927],
                    [-75.867267, 38.059295],
                    [-75.866194, 38.059402],
                    [-75.86153, 38.05987],
                    [-75.861033, 38.059919],
                    [-75.860946, 38.059928],
                    [-75.860629, 38.05996],
                    [-75.858881, 38.060135],
                    [-75.859005, 38.060717],
                    [-75.859281, 38.062018],
                    [-75.85944, 38.062769],
                    [-75.859717, 38.064073],
                    [-75.860072, 38.065743],
                    [-75.859568, 38.066448],
                    [-75.859026, 38.067208],
                    [-75.858944, 38.067323],
                    [-75.852305, 38.069561],
                    [-75.84718, 38.071289],
                    [-75.844265, 38.072272],
                    [-75.841206, 38.071595],
                    [-75.841057, 38.071562],
                    [-75.839935, 38.071314],
                    [-75.832742, 38.069723],
                    [-75.831103, 38.069361],
                    [-75.830017, 38.06912],
                    [-75.819591, 38.066814],
                    [-75.819479, 38.066682],
                    [-75.819415, 38.066606],
                    [-75.813468, 38.059587],
                    [-75.813378, 38.059481],
                    [-75.812913, 38.058932],
                    [-75.825987, 38.0464],
                    [-75.826492, 38.045915],
                    [-75.829276, 38.043247],
                    [-75.829375, 38.043152],
                    [-75.830023, 38.042845],
                    [-75.833418, 38.041238],
                    [-75.833657, 38.041125],
                    [-75.834643, 38.040657],
                    [-75.836018, 38.040007],
                    [-75.847716, 38.034468],
                    [-75.847922, 38.03437],
                    [-75.84998, 38.034294],
                    [-75.850343, 38.035877],
                    [-75.850531, 38.036697],
                    [-75.852655, 38.037331],
                    [-75.854711, 38.037944],
                    [-75.855468, 38.03817],
                    [-75.856854, 38.038583],
                    [-75.857507, 38.038778],
                    [-75.858891, 38.03839],
                    [-75.864648, 38.036773],
                    [-75.865912, 38.036418],
                    [-75.869513, 38.035407],
                    [-75.87319, 38.034375],
                    [-75.875399, 38.028241],
                    [-75.875297, 38.011965],
                    [-75.882768, 38.002995],
                    [-75.898956, 37.974514],
                    [-75.890871, 37.954847],
                    [-75.89281, 37.940239],
                    [-75.893655, 37.933879],
                    [-75.894065, 37.93079],
                    [-75.898316, 37.925114],
                    [-75.89594, 37.921625],
                    [-75.895791, 37.921406],
                    [-75.892686, 37.916848],
                    [-75.885032, 37.911717],
                    [-75.883708, 37.912076],
                    [-75.881913, 37.912563],
                    [-75.860727, 37.91831],
                    [-75.849103, 37.92447],
                    [-75.848133, 37.924983],
                    [-75.847933, 37.925089],
                    [-75.847817, 37.925151],
                    [-75.847473, 37.925333],
                    [-75.847207, 37.925474],
                    [-75.846621, 37.925785],
                    [-75.845992, 37.926118],
                    [-75.843768, 37.927297],
                    [-75.832793, 37.933112],
                    [-75.832414, 37.933313],
                    [-75.831836, 37.934519],
                    [-75.831707, 37.934789],
                    [-75.830154, 37.938028],
                    [-75.829901, 37.938556],
                    [-75.824448, 37.942584],
                    [-75.824242, 37.942736],
                    [-75.822901, 37.943726],
                    [-75.820774, 37.945297],
                    [-75.819088, 37.946542],
                    [-75.807755, 37.954912],
                    [-75.80693, 37.955522],
                    [-75.806147, 37.9561],
                    [-75.789943, 37.968068],
                    [-75.788351, 37.969244],
                    [-75.785487, 37.971359],
                    [-75.785273, 37.971517],
                    [-75.785209, 37.971564],
                    [-75.785007, 37.971714],
                    [-75.783815, 37.972594],
                    [-75.783444, 37.972565],
                    [-75.778975, 37.972216],
                    [-75.776773, 37.972044],
                    [-75.759091, 37.970663],
                    [-75.750244, 37.968873],
                    [-75.737997, 37.963526],
                    [-75.737514, 37.963705],
                    [-75.735125, 37.964592],
                    [-75.727952, 37.967256],
                    [-75.72533, 37.969266],
                    [-75.724692, 37.969754],
                    [-75.722662, 37.97131],
                    [-75.722085, 37.973416],
                    [-75.71315, 37.976623],
                    [-75.708179, 37.974972],
                    [-75.686525, 37.967783],
                    [-75.685995, 37.967607],
                    [-75.671681, 37.966576],
                    [-75.669374, 37.96513],
                    [-75.66502, 37.962401],
                    [-75.663095, 37.961195],
                    [-75.665057, 37.956282],
                    [-75.669711, 37.950796],
                    [-75.660956, 37.959174],
                    [-75.646507, 37.973],
                    [-75.646289, 37.973209],
                    [-75.645251, 37.974202],
                    [-75.645096, 37.97435],
                    [-75.644665, 37.974763],
                    [-75.644591, 37.974833],
                    [-75.644545, 37.974877],
                    [-75.639786, 37.979432],
                    [-75.635502, 37.983531],
                    [-75.630222, 37.988584],
                    [-75.626129, 37.9925],
                    [-75.624341, 37.994211],
                    [-75.435956, 38.010282],
                    [-75.42881, 38.010854],
                    [-75.398839, 38.013277],
                    [-75.377851, 38.015145],
                    [-75.263779, 38.025295],
                    [-75.262088, 38.025445],
                    [-75.260635, 38.025574],
                    [-75.256076, 38.02598],
                    [-75.250358, 38.026489],
                    [-75.242296, 38.027206],
                    [-75.242266, 38.027209],
                    [-75.241817, 38.027802],
                    [-75.237538, 38.033461],
                    [-75.236065, 38.035409],
                    [-75.23586, 38.035679],
                    [-75.227592, 38.046612],
                    [-75.22434, 38.050912],
                    [-75.216117, 38.061786],
                    [-75.206515, 38.07651],
                    [-75.204911, 38.07897],
                    [-75.204684, 38.079317],
                    [-75.195382, 38.093582],
                    [-75.193796, 38.096013],
                    [-75.192925, 38.097819],
                    [-75.178945, 38.126798],
                    [-75.177394, 38.130014],
                    [-75.16164, 38.176383],
                    [-75.15897, 38.184242],
                    [-75.155351, 38.192572],
                    [-75.143229, 38.220475],
                    [-75.116837, 38.274229],
                    [-75.103757, 38.309349],
                    [-75.102947, 38.311525],
                    [-75.093888, 38.323432],
                    [-75.092142, 38.323252],
                    [-75.087466, 38.322769],
                    [-75.085518, 38.32427],
                    [-75.085468, 38.324389],
                    [-75.085327, 38.324724],
                    [-75.085171, 38.325096],
                    [-75.084149, 38.327526],
                    [-75.074532, 38.35039],
                    [-75.073852, 38.352006],
                    [-75.072476, 38.355278],
                    [-75.072111, 38.356402],
                    [-75.071694, 38.357686],
                    [-75.071687, 38.357707],
                    [-75.071632, 38.357876],
                    [-75.071329, 38.358809],
                    [-75.069845, 38.363376],
                    [-75.069817, 38.363463],
                    [-75.068559, 38.367335],
                    [-75.068548, 38.36737],
                    [-75.068404, 38.367812],
                    [-75.068162, 38.368559],
                    [-75.068111, 38.368716],
                    [-75.06137, 38.389466],
                    [-75.057288, 38.404738],
                    [-75.056182, 38.408876],
                    [-75.056, 38.40956],
                    [-75.055838, 38.410164],
                    [-75.054591, 38.41483],
                    [-75.053309, 38.423095],
                    [-75.053277, 38.423297],
                    [-75.053137, 38.424201],
                    [-75.0531, 38.424441],
                    [-75.05282, 38.426243],
                    [-75.052804, 38.426349],
                    [-75.052746, 38.42672],
                    [-75.052505, 38.428275],
                    [-75.052471, 38.428493],
                    [-75.052467, 38.428521],
                    [-75.052426, 38.428784],
                    [-75.052368, 38.429159],
                    [-75.052226, 38.430077],
                    [-75.052206, 38.430206],
                    [-75.052167, 38.430457],
                    [-75.052008, 38.431479],
                    [-75.051997, 38.431549],
                    [-75.049582, 38.447117],
                    [-75.049442, 38.448023],
                    [-75.049365, 38.448518],
                    [-75.048939, 38.451263],
                    [-75.049748, 38.486387],
                    [-75.060032, 38.607709],
                    [-75.060478, 38.608012],
                    [-75.061259, 38.608602],
                    [-75.06192, 38.608869],
                    [-75.065217, 38.632394],
                    [-75.06551, 38.66103],
                    [-75.079221, 38.738238],
                    [-75.080217, 38.750112],
                    [-75.082153, 38.772157],
                    [-75.089473, 38.797198],
                    [-75.093805, 38.803812],
                    [-75.097197, 38.803101],
                    [-75.093654, 38.793992],
                    [-75.097103, 38.788703],
                    [-75.113331, 38.782998],
                    [-75.134022, 38.782242],
                    [-75.159022, 38.790193],
                    [-75.160748, 38.791224],
                    [-75.190552, 38.806861],
                    [-75.205329, 38.823386],
                    [-75.232029, 38.844254],
                    [-75.263115, 38.877351],
                    [-75.304078, 38.91316],
                    [-75.312282, 38.924594],
                    [-75.302552, 38.939002],
                    [-75.311542, 38.944633],
                    [-75.311882, 38.945698],
                    [-75.311923, 38.945917],
                    [-75.312546, 38.94928],
                    [-75.312546, 38.951065],
                    [-75.311607, 38.967637],
                    [-75.314951, 38.980775],
                    [-75.318354, 38.988191],
                    [-75.34089, 39.01996],
                    [-75.345763, 39.024857],
                    [-75.379873, 39.04879],
                    [-75.387914, 39.051174],
                    [-75.396277, 39.057884],
                    [-75.395806, 39.059211],
                    [-75.400294, 39.065645],
                    [-75.402035, 39.066885],
                    [-75.401193, 39.088762],
                    [-75.410625, 39.156246],
                    [-75.408266, 39.174625],
                    [-75.400144, 39.186456],
                    [-75.398584, 39.186616],
                    [-75.39479, 39.188354],
                    [-75.393015, 39.204512],
                    [-75.396892, 39.216141],
                    [-75.404745, 39.222666],
                    [-75.405716, 39.223834],
                    [-75.405927, 39.243631],
                    [-75.404823, 39.245898],
                    [-75.402964, 39.254626],
                    [-75.408376, 39.264698],
                    [-75.427953, 39.285049],
                    [-75.435374, 39.296676],
                    [-75.435551, 39.297546],
                    [-75.436936, 39.309379],
                    [-75.439027, 39.313384],
                    [-75.460423, 39.328236],
                    [-75.469324, 39.33082],
                    [-75.479963, 39.336577],
                    [-75.479845, 39.337472],
                    [-75.490377, 39.342818],
                    [-75.491688, 39.343963],
                    [-75.493148, 39.345527],
                    [-75.494122, 39.34658],
                    [-75.491797, 39.351845],
                    [-75.494158, 39.354613],
                    [-75.505276, 39.359169],
                    [-75.511788, 39.365191],
                    [-75.512372, 39.365656],
                    [-75.512996, 39.366153],
                    [-75.521682, 39.387871],
                    [-75.523583, 39.391583],
                    [-75.535977, 39.409384],
                    [-75.538512, 39.416502],
                    [-75.55589, 39.430351],
                    [-75.57183, 39.438897],
                    [-75.570985, 39.442486],
                    [-75.578914, 39.44788],
                    [-75.580185, 39.450786],
                    [-75.589439, 39.460812],
                    [-75.589901, 39.462022],
                    [-75.593068, 39.477996],
                    [-75.593068, 39.479186],
                    [-75.587729, 39.495369],
                    [-75.587729, 39.496353],
                    [-75.576436, 39.509195],
                    [-75.566933, 39.508273],
                    [-75.560728, 39.520472],
                    [-75.570362, 39.527223],
                    [-75.569418, 39.539124],
                    [-75.569359, 39.540589],
                    [-75.565636, 39.558509],
                    [-75.564649, 39.559922],
                    [-75.563034, 39.56224],
                    [-75.570783, 39.56728],
                    [-75.571599, 39.567728],
                    [-75.586016, 39.578448],
                    [-75.586608, 39.57888],
                    [-75.5872, 39.580256],
                    [-75.587744, 39.580672],
                    [-75.591984, 39.583248],
                    [-75.592224, 39.583568],
                    [-75.603584, 39.58896],
                    [-75.60464, 39.58992],
                    [-75.611873, 39.597408],
                    [-75.611905, 39.597568],
                    [-75.613793, 39.606192],
                    [-75.613473, 39.606832],
                    [-75.613477, 39.606861],
                    [-75.613233, 39.607408],
                    [-75.613665, 39.61256],
                    [-75.613345, 39.613056],
                    [-75.614273, 39.61464],
                    [-75.614929, 39.615952],
                    [-75.614065, 39.61832],
                    [-75.613377, 39.620288],
                    [-75.613153, 39.62096],
                    [-75.611969, 39.621968],
                    [-75.587147, 39.651012],
                    [-75.562246, 39.656712],
                    [-75.529744, 39.692613],
                    [-75.509742, 39.686113],
                    [-75.509342, 39.685313],
                    [-75.526344, 39.656413],
                    [-75.526844, 39.655713],
                    [-75.559446, 39.629812],
                    [-75.570798, 39.626768],
                    [-75.571759, 39.623584],
                    [-75.567694, 39.613744],
                    [-75.561934, 39.605216],
                    [-75.55587, 39.605824],
                    [-75.545405, 39.596784],
                    [-75.543965, 39.596],
                    [-75.539949, 39.594384],
                    [-75.53954, 39.594251],
                    [-75.537213, 39.592944],
                    [-75.534477, 39.590384],
                    [-75.531133, 39.587984],
                    [-75.525677, 39.584048],
                    [-75.512732, 39.578],
                    [-75.511932, 39.567616],
                    [-75.514756, 39.562612],
                    [-75.527141, 39.500112],
                    [-75.528088, 39.498114],
                    [-75.542693, 39.496568],
                    [-75.544368, 39.479602],
                    [-75.542894, 39.470447],
                    [-75.536431, 39.460559],
                    [-75.508383, 39.459131],
                    [-75.505672, 39.452927],
                    [-75.483572, 39.440824],
                    [-75.476279, 39.438126],
                    [-75.465212, 39.43893],
                    [-75.442393, 39.402291],
                    [-75.431803, 39.391625],
                    [-75.422099, 39.386521],
                    [-75.407294, 39.381954],
                    [-75.399304, 39.37949],
                    [-75.395181, 39.371398],
                    [-75.394331, 39.363753],
                    [-75.39003, 39.358259],
                    [-75.365016, 39.341388],
                    [-75.355558, 39.347823],
                    [-75.341969, 39.348697],
                    [-75.333743, 39.345335],
                    [-75.327463, 39.33927],
                    [-75.326754, 39.332473],
                    [-75.315201, 39.310593],
                    [-75.30601, 39.301712],
                    [-75.288898, 39.289557],
                    [-75.285333, 39.292212],
                    [-75.28262, 39.299055],
                    [-75.271629, 39.304041],
                    [-75.251806, 39.299913],
                    [-75.244357, 39.2857],
                    [-75.242881, 39.280574],
                    [-75.244056, 39.27769],
                    [-75.241639, 39.274097],
                    [-75.21251, 39.262755],
                    [-75.205857, 39.262619],
                    [-75.177506, 39.242746],
                    [-75.170444, 39.234643],
                    [-75.164798, 39.216606],
                    [-75.165979, 39.201842],
                    [-75.139136, 39.180021],
                    [-75.136548, 39.179425],
                    [-75.12707, 39.189766],
                    [-75.114748, 39.207554],
                    [-75.107286, 39.211403],
                    [-75.101019, 39.211657],
                    [-75.086395, 39.208159],
                    [-75.062506, 39.213564],
                    [-75.052326, 39.213609],
                    [-75.047797, 39.211702],
                    [-75.041663, 39.215511],
                    [-75.035672, 39.215415],
                    [-75.026376, 39.20985],
                    [-75.023437, 39.204791],
                    [-75.023586, 39.202594],
                    [-75.027824, 39.199482],
                    [-75.028885, 39.19456],
                    [-75.026179, 39.193621],
                    [-74.998002, 39.191253],
                    [-74.976266, 39.192271],
                    [-74.962382, 39.190238],
                    [-74.914936, 39.177553],
                    [-74.905181, 39.174945],
                    [-74.887167, 39.158825],
                    [-74.885914, 39.143627],
                    [-74.892547, 39.113183],
                    [-74.897784, 39.098811],
                    [-74.903664, 39.087437],
                    [-74.93832, 39.035185],
                    [-74.94947, 39.015637],
                    [-74.955363, 39.001262],
                    [-74.971995, 38.94037],
                    [-74.967274, 38.933413],
                    [-74.963463, 38.931194],
                    [-74.933571, 38.928519],
                    [-74.920414, 38.929136],
                    [-74.90705, 38.931994],
                    [-74.882309, 38.941759],
                    [-74.870497, 38.943543],
                    [-74.865198, 38.941439],
                    [-74.864458, 38.94041],
                    [-74.850748, 38.954538],
                    [-74.819354, 38.979402],
                    [-74.807917, 38.985948],
                    [-74.792723, 38.991991],
                    [-74.786356, 39.000113],
                    [-74.778777, 39.023073],
                    [-74.738316, 39.074727],
                    [-74.705876, 39.102937],
                    [-74.704409, 39.107858],
                    [-74.714135, 39.114631],
                    [-74.71532, 39.116893],
                    [-74.714341, 39.119804],
                    [-74.67143, 39.179802],
                    [-74.651443, 39.198578],
                    [-74.646595, 39.212002],
                    [-74.636306, 39.220834],
                    [-74.614481, 39.244659],
                    [-74.597921, 39.258851],
                    [-74.581008, 39.270819],
                    [-74.560957, 39.278677],
                    [-74.553439, 39.286915],
                    [-74.551151, 39.293539],
                    [-74.541443, 39.300245],
                    [-74.521797, 39.313816],
                    [-74.459894, 39.345016],
                    [-74.412692, 39.360816],
                    [-74.408237, 39.365071],
                    [-74.406792, 39.373916],
                    [-74.406692, 39.377516],
                    [-74.36699, 39.402017],
                    [-74.334804, 39.432001],
                    [-74.304343, 39.471445],
                    [-74.302184, 39.478935],
                    [-74.304778, 39.482945],
                    [-74.308344, 39.483945],
                    [-74.313689, 39.493874],
                    [-74.312451, 39.499869],
                    [-74.311037, 39.506715],
                    [-74.291585, 39.507705],
                    [-74.27737, 39.514064],
                    [-74.249043, 39.547994],
                    [-74.240506, 39.554911],
                    [-74.190974, 39.625118],
                    [-74.141733, 39.689435],
                    [-74.113655, 39.740719],
                    [-74.101443, 39.756173],
                    [-74.09892, 39.759538],
                    [-74.096906, 39.76303],
                    [-74.097071, 39.767847],
                    [-74.090945, 39.799978],
                    [-74.077247, 39.910991],
                    [-74.064135, 39.979157],
                    [-74.058798, 40.001244],
                    [-74.039421, 40.081437],
                    [-74.033546, 40.099518],
                    [-74.031318, 40.100541],
                    [-74.031861, 40.101047],
                    [-74.03408, 40.103115],
                    [-74.030181, 40.122814],
                    [-74.016017, 40.166914],
                    [-73.993292, 40.237669],
                    [-73.981681, 40.279411],
                    [-73.977442, 40.299373],
                    [-73.971381, 40.34801],
                    [-73.971381, 40.371709],
                    [-73.976982, 40.408508],
                    [-73.978282, 40.440208],
                    [-73.995683, 40.468707],
                    [-74.0071, 40.475298],
                    [-74.014031, 40.476471],
                    [-74.017917, 40.474338],
                    [-74.017783, 40.472207],
                    [-74.006077, 40.464625],
                    [-73.991682, 40.442908],
                    [-73.995486, 40.419472],
                    [-73.998505, 40.410911],
                    [-74.006383, 40.411108],
                    [-74.047884, 40.418908],
                    [-74.058984, 40.422708],
                    [-74.076185, 40.433707],
                    [-74.088085, 40.438407],
                    [-74.116863, 40.446069],
                    [-74.122327, 40.448258],
                    [-74.124692, 40.44958],
                    [-74.127466, 40.451061],
                    [-74.131135, 40.453245],
                    [-74.133727, 40.454672],
                    [-74.135823, 40.455196],
                    [-74.138415, 40.454468],
                    [-74.139886, 40.453407],
                    [-74.142886, 40.450407],
                    [-74.151952, 40.448062],
                    [-74.152686, 40.447344],
                    [-74.153611, 40.447647],
                    [-74.157787, 40.446607],
                    [-74.163314, 40.448424],
                    [-74.164029, 40.448312],
                    [-74.166193, 40.447128],
                    [-74.167009, 40.448737],
                    [-74.169977, 40.45064],
                    [-74.175346, 40.446607],
                    [-74.176842, 40.44774],
                    [-74.175074, 40.449144],
                    [-74.174893, 40.454491],
                    [-74.174787, 40.455607],
                    [-74.187787, 40.447407],
                    [-74.191309, 40.44299],
                    [-74.193908, 40.440995],
                    [-74.202128, 40.43894],
                    [-74.207205, 40.435434],
                    [-74.208655, 40.43752],
                    [-74.206419, 40.438789],
                    [-74.206188, 40.440707],
                    [-74.209788, 40.447407],
                    [-74.222959, 40.452499],
                    [-74.224047, 40.452919],
                    [-74.225035, 40.453301],
                    [-74.236689, 40.457806],
                    [-74.261889, 40.464706],
                    [-74.26759, 40.471806],
                    [-74.27269, 40.488405],
                    [-74.269998, 40.495014],
                    [-74.26829, 40.499205],
                    [-74.246237, 40.520963],
                    [-74.251441, 40.542301],
                    [-74.248641, 40.549601],
                    [-74.231589, 40.559204],
                    [-74.218189, 40.557204],
                    [-74.214788, 40.560604],
                    [-74.208988, 40.576304],
                    [-74.206731, 40.594569],
                    [-74.202223, 40.631053],
                    [-74.189106, 40.643832],
                    [-74.186027, 40.646076],
                    [-74.181083, 40.646484],
                    [-74.161397, 40.644092],
                    [-74.143387, 40.641903],
                    [-74.094086, 40.649703],
                    [-74.087397, 40.653607],
                    [-74.089986, 40.659903],
                    [-74.082786, 40.673702],
                    [-74.069885, 40.684502],
                    [-74.051185, 40.695802],
                    [-74.038538, 40.710741],
                    [-74.024543, 40.709436],
                    [-74.019526, 40.706985],
                    [-74.0168, 40.701794],
                    [-74.01849, 40.695457],
                    [-74.021721, 40.693504],
                    [-74.024827, 40.687007],
                    [-74.023982, 40.68236],
                    [-74.022911, 40.681267],
                    [-74.020467, 40.67877],
                    [-74.018272, 40.659019],
                    [-74.032066, 40.646479],
                    [-74.038336, 40.637074],
                    [-74.042412, 40.624847],
                    [-74.03959, 40.612934],
                    [-74.032856, 40.604421],
                    [-74.010926, 40.600789],
                    [-74.003281, 40.595754],
                    [-74.001591, 40.590684],
                    [-74.00635, 40.584767],
                    [-74.007276, 40.583616],
                    [-74.012996, 40.578169],
                    [-74.012022, 40.574528],
                    [-74.00903, 40.572846],
                    [-74.002056, 40.570623],
                    [-73.991346, 40.57035],
                    [-73.95938, 40.572682],
                    [-73.95005, 40.573363],
                    [-73.944558, 40.568716],
                    [-73.938598, 40.566161],
                    [-73.935686, 40.564914],
                    [-73.932729, 40.560266],
                    [-73.934466, 40.555281],
                    [-73.934512, 40.545175],
                    [-73.878906, 40.560888],
                    [-73.834408, 40.577201],
                    [-73.806834, 40.584619],
                    [-73.80143, 40.585659],
                    [-73.774928, 40.590759],
                    [-73.753349, 40.59056],
                    [-73.754323, 40.586357],
                    [-73.754776, 40.584404],
                    [-73.701138, 40.58361],
                    [-73.646674, 40.582804],
                    [-73.610873, 40.587703],
                    [-73.583773, 40.586703],
                    [-73.562372, 40.583703],
                    [-73.450369, 40.603501],
                    [-73.423806, 40.609869],
                    [-73.391967, 40.617501],
                    [-73.351465, 40.6305],
                    [-73.319257, 40.635795],
                    [-73.30974, 40.622532],
                    [-73.306396, 40.620756],
                    [-73.264493, 40.621437],
                    [-73.262106, 40.621476],
                    [-73.23914, 40.6251],
                    [-73.20844, 40.630884],
                    [-73.145266, 40.645491],
                    [-73.054963, 40.666371],
                    [-73.012545, 40.679651],
                    [-72.923214, 40.713282],
                    [-72.863164, 40.732962],
                    [-72.768152, 40.761587],
                    [-72.757176, 40.764371],
                    [-72.753112, 40.763571],
                    [-72.745208, 40.767091],
                    [-72.573441, 40.813251],
                    [-72.469996, 40.84274],
                    [-72.39585, 40.86666],
                    [-72.114448, 40.972085],
                    [-72.029357, 40.999909],
                    [-71.935689, 41.034182],
                    [-71.903736, 41.040166],
                    [-71.87391, 41.052278],
                    [-71.856214, 41.070598],
                    [-71.857494, 41.073558],
                    [-71.86447, 41.076918],
                    [-71.869558, 41.075046],
                    [-71.889543, 41.075701],
                    [-71.895496, 41.077381],
                    [-71.899256, 41.080837],
                    [-71.919385, 41.080517],
                    [-71.93825, 41.077413],
                    [-71.959595, 41.071237],
                    [-71.961563, 41.064021],
                    [-71.960355, 41.059878],
                    [-71.961078, 41.054277],
                    [-71.96704, 41.047772],
                    [-71.99926, 41.039669],
                    [-72.015013, 41.028348],
                    [-72.035792, 41.020759],
                    [-72.047468, 41.022565],
                    [-72.051928, 41.020506],
                    [-72.051549, 41.015741],
                    [-72.049526, 41.009697],
                    [-72.051585, 41.006437],
                    [-72.055188, 41.005236],
                    [-72.057075, 41.004893],
                    [-72.057934, 41.004789],
                    [-72.061448, 41.009442],
                    [-72.076175, 41.009093],
                    [-72.079208, 41.006437],
                    [-72.079951, 41.003429],
                    [-72.083039, 40.996453],
                    [-72.095456, 40.991349],
                    [-72.10216, 40.991509],
                    [-72.109008, 40.994084],
                    [-72.116368, 40.999796],
                    [-72.137409, 41.023908],
                    [-72.135137, 41.031284],
                    [-72.137297, 41.039684],
                    [-72.153857, 41.051859],
                    [-72.16037, 41.053827],
                    [-72.162898, 41.053187],
                    [-72.174882, 41.046147],
                    [-72.17949, 41.038435],
                    [-72.183266, 41.035619],
                    [-72.190563, 41.032579],
                    [-72.201859, 41.032275],
                    [-72.217476, 41.040611],
                    [-72.229364, 41.044355],
                    [-72.241252, 41.04477],
                    [-72.260515, 41.042065],
                    [-72.273657, 41.051533],
                    [-72.283093, 41.067874],
                    [-72.276709, 41.076722],
                    [-72.280373, 41.080402],
                    [-72.297718, 41.081042],
                    [-72.317238, 41.088659],
                    [-72.335177, 41.106917],
                    [-72.335271, 41.120274],
                    [-72.32663, 41.132162],
                    [-72.318146, 41.137134],
                    [-72.312734, 41.138546],
                    [-72.306381, 41.13784],
                    [-72.300044, 41.132059],
                    [-72.300374, 41.112274],
                    [-72.265124, 41.128482],
                    [-72.253572, 41.137138],
                    [-72.237731, 41.156434],
                    [-72.238211, 41.15949],
                    [-72.245348, 41.161217],
                    [-72.2681, 41.154146],
                    [-72.272997, 41.15501],
                    [-72.278789, 41.158722],
                    [-72.291109, 41.155874],
                    [-72.322381, 41.140664],
                    [-72.333351, 41.138018],
                    [-72.356087, 41.133635],
                    [-72.397, 41.096307],
                    [-72.417945, 41.087955],
                    [-72.445242, 41.086116],
                    [-72.460778, 41.067012],
                    [-72.477306, 41.052212],
                    [-72.521548, 41.037652],
                    [-72.549853, 41.019844],
                    [-72.560974, 41.015444],
                    [-72.565406, 41.009508],
                    [-72.585327, 40.997587],
                    [-72.635374, 40.990536],
                    [-72.665018, 40.987496],
                    [-72.689341, 40.989776],
                    [-72.714425, 40.985596],
                    [-72.760031, 40.975334],
                    [-72.774104, 40.965314],
                    [-72.826057, 40.969794],
                    [-72.88825, 40.962962],
                    [-72.913834, 40.962466],
                    [-72.955163, 40.966146],
                    [-72.995931, 40.966498],
                    [-73.040445, 40.964498],
                    [-73.043701, 40.962185],
                    [-73.081582, 40.973058],
                    [-73.110368, 40.971938],
                    [-73.140785, 40.966178],
                    [-73.144673, 40.955842],
                    [-73.146242, 40.935074],
                    [-73.148994, 40.928898],
                    [-73.229285, 40.905121],
                    [-73.295059, 40.924497],
                    [-73.295061, 40.924497],
                    [-73.33136, 40.929597],
                    [-73.344161, 40.927297],
                    [-73.345561, 40.925297],
                    [-73.352761, 40.926697],
                    [-73.365961, 40.931697],
                    [-73.374462, 40.937597],
                    [-73.392862, 40.955297],
                    [-73.399762, 40.955197],
                    [-73.400862, 40.953997],
                    [-73.403462, 40.942197],
                    [-73.402963, 40.925097],
                    [-73.406074, 40.920235],
                    [-73.428836, 40.921506],
                    [-73.429665, 40.928203],
                    [-73.429863, 40.929797],
                    [-73.436664, 40.934897],
                    [-73.437509, 40.934985],
                    [-73.445026, 40.935763],
                    [-73.456523, 40.936953],
                    [-73.460603, 40.937375],
                    [-73.463708, 40.937697],
                    [-73.47441, 40.941056],
                    [-73.478365, 40.942297],
                    [-73.48011, 40.943319],
                    [-73.484798, 40.946065],
                    [-73.485365, 40.946397],
                    [-73.491765, 40.942097],
                    [-73.496642, 40.923476],
                    [-73.497061, 40.922801],
                    [-73.499941, 40.918166],
                    [-73.514999, 40.912821],
                    [-73.519267, 40.914298],
                    [-73.548068, 40.908698],
                    [-73.566169, 40.915798],
                    [-73.569969, 40.915398],
                    [-73.59517, 40.907298],
                    [-73.60187, 40.902798],
                    [-73.617571, 40.897898],
                    [-73.626972, 40.899397],
                    [-73.633771, 40.898198],
                    [-73.641072, 40.892599],
                    [-73.654372, 40.878199],
                    [-73.655872, 40.863899],
                    [-73.670692, 40.858708],
                    [-73.675573, 40.856999],
                    [-73.713674, 40.870099],
                    [-73.729575, 40.8665],
                    [-73.730675, 40.8654],
                    [-73.726675, 40.8568],
                    [-73.728275, 40.8529],
                    [-73.7544, 40.826837],
                    [-73.754032, 40.820941],
                    [-73.768301, 40.800797],
                    [-73.768431, 40.800704],
                    [-73.776032, 40.795275],
                    [-73.781369, 40.794907],
                    [-73.785964, 40.800862],
                    [-73.797332, 40.815597],
                    [-73.804518, 40.818546],
                    [-73.811889, 40.825363],
                    [-73.815205, 40.831075],
                    [-73.815574, 40.835129],
                    [-73.81281, 40.846737],
                    [-73.806914, 40.849501],
                    [-73.799543, 40.848027],
                    [-73.797252, 40.852196],
                    [-73.793785, 40.855583],
                    [-73.792253, 40.855825],
                    [-73.789512, 40.85139],
                    [-73.791044, 40.846552],
                    [-73.788221, 40.842036],
                    [-73.785399, 40.838004],
                    [-73.783867, 40.836795],
                    [-73.782577, 40.837601],
                    [-73.781206, 40.838891],
                    [-73.782254, 40.842359],
                    [-73.782093, 40.844616],
                    [-73.782174, 40.847358],
                    [-73.784754, 40.851793],
                    [-73.78806, 40.854131],
                    [-73.788786, 40.858485],
                    [-73.785502, 40.869079],
                    [-73.784803, 40.878528],
                    [-73.783545, 40.88104],
                    [-73.781338, 40.885447],
                    [-73.756776, 40.912599],
                    [-73.731775, 40.924999],
                    [-73.721739, 40.932037],
                    [-73.697974, 40.939598],
                    [-73.686473, 40.945198],
                    [-73.683273, 40.948998],
                    [-73.678073, 40.962798],
                    [-73.664472, 40.967198],
                    [-73.662072, 40.966198],
                    [-73.659972, 40.968398],
                    [-73.655972, 40.979597],
                    [-73.657336, 40.985171],
                    [-73.651175, 40.995229],
                    [-73.643478, 41.002171],
                    [-73.603952, 41.015054],
                    [-73.595699, 41.015995],
                    [-73.584988, 41.010537],
                    [-73.583968, 41.000897],
                    [-73.570068, 41.001597],
                    [-73.567668, 41.010897],
                    [-73.561968, 41.016797],
                    [-73.551494, 41.024336],
                    [-73.535338, 41.03192],
                    [-73.532786, 41.03167],
                    [-73.530189, 41.028776],
                    [-73.531169, 41.021919],
                    [-73.528866, 41.016397],
                    [-73.522666, 41.019297],
                    [-73.516766, 41.029497],
                    [-73.516903, 41.038738],
                    [-73.493327, 41.048173],
                    [-73.477364, 41.035997],
                    [-73.468239, 41.051347],
                    [-73.450364, 41.057096],
                    [-73.435063, 41.056696],
                    [-73.41367, 41.073258],
                    [-73.400154, 41.086299],
                    [-73.392162, 41.087696],
                    [-73.372296, 41.10402],
                    [-73.33066, 41.109996],
                    [-73.31186, 41.116296],
                    [-73.296359, 41.125696],
                    [-73.286759, 41.127896],
                    [-73.262358, 41.117496],
                    [-73.247958, 41.126396],
                    [-73.235058, 41.143996],
                    [-73.228295, 41.142602],
                    [-73.202656, 41.158096],
                    [-73.177774, 41.166697],
                    [-73.170701, 41.164945],
                    [-73.170074, 41.160532],
                    [-73.16437, 41.158565],
                    [-73.130253, 41.146797],
                    [-73.111052, 41.150797],
                    [-73.108352, 41.153718],
                    [-73.109952, 41.156997],
                    [-73.110352, 41.159697],
                    [-73.107987, 41.168738],
                    [-73.105493, 41.172194],
                    [-73.105483, 41.172203],
                    [-73.104328, 41.17317],
                    [-73.092147, 41.183377],
                    [-73.092, 41.1835],
                    [-73.09122, 41.184153],
                    [-73.07945, 41.194015],
                    [-73.07761, 41.195176],
                    [-73.05935, 41.206697],
                    [-73.054947, 41.208468],
                    [-73.05065, 41.210197],
                    [-73.045602, 41.209658],
                    [-73.024783, 41.207435],
                    [-73.022549, 41.207197],
                    [-73.020449, 41.206397],
                    [-73.020254, 41.204906],
                    [-73.02021, 41.204568],
                    [-73.020195, 41.204446],
                    [-73.020167, 41.204237],
                    [-73.020149, 41.204097],
                    [-73.014948, 41.204297],
                    [-73.013988, 41.205062],
                    [-73.013465, 41.205479],
                    [-73.007548, 41.210197],
                    [-73.003639, 41.215287],
                    [-72.997948, 41.222697],
                    [-72.986247, 41.233497],
                    [-72.985095, 41.234358],
                    [-72.983751, 41.235364],
                    [-72.962047, 41.251597],
                    [-72.961345, 41.25178],
                    [-72.959633, 41.252228],
                    [-72.956984, 41.25292],
                    [-72.935646, 41.258497],
                    [-72.933472, 41.260024],
                    [-72.931887, 41.261139],
                    [-72.920846, 41.268897],
                    [-72.920714, 41.27078],
                    [-72.920658, 41.271574],
                    [-72.920062, 41.280056],
                    [-72.917037, 41.281905],
                    [-72.916827, 41.282033],
                    [-72.9082, 41.282932],
                    [-72.907962, 41.282549],
                    [-72.903129, 41.274794],
                    [-72.89637, 41.263949],
                    [-72.893845, 41.259897],
                    [-72.89473, 41.25626],
                    [-72.894745, 41.256197],
                    [-72.902808, 41.252894],
                    [-72.903045, 41.252797],
                    [-72.905245, 41.248297],
                    [-72.904345, 41.247297],
                    [-72.900803, 41.245864],
                    [-72.895445, 41.243697],
                    [-72.881445, 41.242597],
                    [-72.861344, 41.245297],
                    [-72.854055, 41.24774],
                    [-72.85021, 41.255544],
                    [-72.847767, 41.25669],
                    [-72.826883, 41.256755],
                    [-72.819372, 41.254061],
                    [-72.818737, 41.252244],
                    [-72.786142, 41.264796],
                    [-72.757477, 41.266913],
                    [-72.754444, 41.266913],
                    [-72.732813, 41.254727],
                    [-72.722439, 41.259138],
                    [-72.71246, 41.254167],
                    [-72.711208, 41.251018],
                    [-72.713674, 41.249007],
                    [-72.710821, 41.244812],
                    [-72.710595, 41.24448],
                    [-72.709193, 41.244523],
                    [-72.708963, 41.24453],
                    [-72.708658, 41.24454],
                    [-72.707212, 41.244585],
                    [-72.706236, 41.244615],
                    [-72.701806, 41.244752],
                    [-72.69547, 41.244948],
                    [-72.694744, 41.24497],
                    [-72.693441, 41.245493],
                    [-72.690439, 41.246697],
                    [-72.690237, 41.246887],
                    [-72.689446, 41.247629],
                    [-72.685539, 41.251297],
                    [-72.685414, 41.252607],
                    [-72.684939, 41.257597],
                    [-72.674319, 41.26552],
                    [-72.672339, 41.266997],
                    [-72.671673, 41.267151],
                    [-72.667176, 41.268192],
                    [-72.662838, 41.269197],
                    [-72.662203, 41.268964],
                    [-72.654715, 41.266219],
                    [-72.653931, 41.265931],
                    [-72.653838, 41.265897],
                    [-72.650697, 41.266178],
                    [-72.642811, 41.266884],
                    [-72.641538, 41.266998],
                    [-72.641001, 41.267108],
                    [-72.631363, 41.269092],
                    [-72.617983, 41.271845],
                    [-72.617521, 41.27194],
                    [-72.617237, 41.271998],
                    [-72.610236, 41.270795],
                    [-72.607863, 41.270387],
                    [-72.598036, 41.268698],
                    [-72.590967, 41.270141],
                    [-72.589818, 41.270375],
                    [-72.587926, 41.270761],
                    [-72.586674, 41.271017],
                    [-72.585934, 41.271168],
                    [-72.585181, 41.271321],
                    [-72.583336, 41.271698],
                    [-72.571136, 41.268098],
                    [-72.571076, 41.268054],
                    [-72.570655, 41.267744],
                    [-72.547235, 41.250499],
                    [-72.546833, 41.250718],
                    [-72.537776, 41.255646],
                    [-72.536746, 41.256207],
                    [-72.533247, 41.26269],
                    [-72.529416, 41.264421],
                    [-72.521312, 41.2656],
                    [-72.51866, 41.261253],
                    [-72.506634, 41.260099],
                    [-72.499534, 41.265866],
                    [-72.485693, 41.270881],
                    [-72.472539, 41.270103],
                    [-72.451925, 41.278885],
                    [-72.40593, 41.278398],
                    [-72.398688, 41.278172],
                    [-72.386629, 41.261798],
                    [-72.348068, 41.269698],
                    [-72.348643, 41.277446],
                    [-72.34146, 41.28011],
                    [-72.333894, 41.282916],
                    [-72.327595, 41.27846],
                    [-72.31776, 41.277782],
                    [-72.261487, 41.282926],
                    [-72.251323, 41.289997],
                    [-72.250515, 41.294386],
                    [-72.251895, 41.29862],
                    [-72.248161, 41.299488],
                    [-72.235531, 41.300413],
                    [-72.225276, 41.299047],
                    [-72.212924, 41.291365],
                    [-72.204022, 41.299097],
                    [-72.203022, 41.313197],
                    [-72.201422, 41.315697],
                    [-72.191022, 41.323197],
                    [-72.184122, 41.323997],
                    [-72.177622, 41.322497],
                    [-72.173922, 41.317597],
                    [-72.16158, 41.310262],
                    [-72.134221, 41.299398],
                    [-72.11182, 41.299098],
                    [-72.09982, 41.306998],
                    [-72.094443, 41.314164],
                    [-72.084487, 41.319634],
                    [-72.021898, 41.316838],
                    [-71.988153, 41.320577],
                    [-71.982194, 41.329861],
                    [-71.979447, 41.329987],
                    [-71.970955, 41.324526],
                    [-71.956747, 41.329871],
                    [-71.945652, 41.337799],
                    [-71.936284, 41.337959],
                    [-71.923282, 41.335113],
                    [-71.922092, 41.334518],
                    [-71.91671, 41.332217],
                    [-71.886302, 41.33641],
                    [-71.868235, 41.330941],
                    [-71.85957, 41.322399],
                    [-71.862109, 41.316612],
                    [-71.862772, 41.309791],
                    [-71.857432, 41.306318],
                    [-71.833755, 41.315631],
                    [-71.785957, 41.325739],
                    [-71.773702, 41.327977],
                    [-71.729247, 41.33099],
                    [-71.72074, 41.331567],
                    [-71.701631, 41.336968],
                    [-71.68807, 41.342823],
                    [-71.624505, 41.36087],
                    [-71.555381, 41.373316],
                    [-71.526724, 41.376636],
                    [-71.513401, 41.374702],
                    [-71.483295, 41.371722],
                    [-71.474918, 41.386104],
                    [-71.455371, 41.407962],
                    [-71.455845, 41.432986],
                    [-71.448948, 41.438479],
                    [-71.441199, 41.441602],
                    [-71.43767, 41.441302],
                    [-71.433612, 41.444995],
                    [-71.428652, 41.454158],
                    [-71.427935, 41.459529],
                    [-71.430926, 41.465655],
                    [-71.430744, 41.470636],
                    [-71.422991, 41.472682],
                    [-71.421157, 41.469888],
                    [-71.418404, 41.472652],
                    [-71.417621, 41.477934],
                    [-71.417957, 41.482073],
                    [-71.419971, 41.484758],
                    [-71.421425, 41.498629],
                    [-71.414937, 41.516303],
                    [-71.414825, 41.523126],
                    [-71.417398, 41.534536],
                    [-71.421649, 41.537892],
                    [-71.442567, 41.565075],
                    [-71.447712, 41.5804],
                    [-71.40377, 41.589321],
                    [-71.408636, 41.653819],
                    [-71.409302, 41.662643],
                    [-71.425452, 41.670785],
                    [-71.430038, 41.667541],
                    [-71.444468, 41.664409],
                    [-71.449318, 41.687401],
                    [-71.445923, 41.691144],
                    [-71.441896, 41.690025],
                    [-71.443082, 41.688303],
                    [-71.441336, 41.686446],
                    [-71.418069, 41.684208],
                    [-71.390551, 41.684096],
                    [-71.389432, 41.683425],
                    [-71.390775, 41.680629],
                    [-71.38988, 41.671903],
                    [-71.382049, 41.667317],
                    [-71.37791, 41.666646],
                    [-71.372988, 41.672575],
                    [-71.365717, 41.694947],
                    [-71.365717, 41.711615],
                    [-71.353748, 41.724702],
                    [-71.353172, 41.725191],
                    [-71.350057, 41.727835],
                    [-71.342786, 41.728506],
                    [-71.31482, 41.723808],
                    [-71.305759, 41.718662],
                    [-71.291217, 41.702666],
                    [-71.293119, 41.688347],
                    [-71.298935, 41.681524],
                    [-71.302627, 41.681747],
                    [-71.306095, 41.672575],
                    [-71.303746, 41.654788],
                    [-71.301396, 41.649978],
                    [-71.299159, 41.649531],
                    [-71.290546, 41.662395],
                    [-71.287637, 41.672463],
                    [-71.280366, 41.672575],
                    [-71.26918, 41.6549],
                    [-71.270075, 41.652439],
                    [-71.267055, 41.644945],
                    [-71.25956, 41.642595],
                    [-71.24155, 41.667205],
                    [-71.237635, 41.681635],
                    [-71.240991, 41.697744],
                    [-71.227875, 41.705498],
                    [-71.224798, 41.710498],
                    [-71.19564, 41.67509],
                    [-71.212136, 41.641945],
                    [-71.251082, 41.63878],
                    [-71.271862, 41.623986],
                    [-71.275234, 41.619444],
                    [-71.272412, 41.615041],
                    [-71.273445, 41.60699],
                    [-71.285142, 41.577127],
                    [-71.288376, 41.573274],
                    [-71.294363, 41.571416],
                    [-71.303652, 41.559925],
                    [-71.310533, 41.54692],
                    [-71.313079, 41.534672],
                    [-71.330831, 41.518364],
                    [-71.330694, 41.507699],
                    [-71.327804, 41.504258],
                    [-71.323125, 41.503088],
                    [-71.317414, 41.488776],
                    [-71.316519, 41.47756],
                    [-71.335992, 41.469647],
                    [-71.34707, 41.47123],
                    [-71.36152, 41.464831],
                    [-71.36176, 41.463955],
                    [-71.362012, 41.463041],
                    [-71.362736, 41.460405],
                    [-71.362743, 41.460379],
                    [-71.362699, 41.460343],
                    [-71.362288, 41.460006],
                    [-71.362201, 41.459934],
                    [-71.361852, 41.459647],
                    [-71.359292, 41.457542],
                    [-71.359136, 41.457414],
                    [-71.359015, 41.457314],
                    [-71.358657, 41.457019],
                    [-71.358356, 41.456772],
                    [-71.358262, 41.456695],
                    [-71.358166, 41.456616],
                    [-71.351457, 41.451099],
                    [-71.351096, 41.450802],
                    [-71.337695, 41.448902],
                    [-71.337377, 41.449033],
                    [-71.32141, 41.4556],
                    [-71.312718, 41.454597],
                    [-71.312694, 41.451402],
                    [-71.311404, 41.450807],
                    [-71.311394, 41.450802],
                    [-71.311344, 41.450829],
                    [-71.310473, 41.451289],
                    [-71.310357, 41.45135],
                    [-71.305896, 41.453708],
                    [-71.304604, 41.454391],
                    [-71.304394, 41.454502],
                    [-71.304247, 41.454976],
                    [-71.302929, 41.459213],
                    [-71.302895, 41.459323],
                    [-71.302741, 41.459817],
                    [-71.302583, 41.460325],
                    [-71.300438, 41.46722],
                    [-71.296517, 41.479829],
                    [-71.295111, 41.48435],
                    [-71.285639, 41.487805],
                    [-71.282262, 41.487982],
                    [-71.267579, 41.488755],
                    [-71.264793, 41.488902],
                    [-71.252692, 41.485902],
                    [-71.245992, 41.481302],
                    [-71.246174, 41.478917],
                    [-71.246676, 41.472323],
                    [-71.246703, 41.47196],
                    [-71.24071, 41.474872],
                    [-71.240047, 41.476296],
                    [-71.239804, 41.476817],
                    [-71.237902, 41.480899],
                    [-71.237783, 41.481155],
                    [-71.236751, 41.483369],
                    [-71.237175, 41.486546],
                    [-71.238586, 41.486845],
                    [-71.240614, 41.500557],
                    [-71.233435, 41.514045],
                    [-71.233141, 41.514598],
                    [-71.233056, 41.514757],
                    [-71.229444, 41.521544],
                    [-71.227989, 41.528297],
                    [-71.234775, 41.532538],
                    [-71.236642, 41.535852],
                    [-71.23613, 41.574767],
                    [-71.2436, 41.587508],
                    [-71.240709, 41.619225],
                    [-71.21616, 41.62549],
                    [-71.212004, 41.62299],
                    [-71.212417, 41.61829],
                    [-71.212656, 41.610072],
                    [-71.20778, 41.60066],
                    [-71.20865, 41.571028],
                    [-71.213563, 41.545818],
                    [-71.200788, 41.514371],
                    [-71.206382, 41.499215],
                    [-71.199692, 41.495511],
                    [-71.19939, 41.491769],
                    [-71.190167, 41.484285],
                    [-71.190016, 41.478275],
                    [-71.196607, 41.464756],
                    [-71.196857, 41.461116],
                    [-71.194967, 41.459037],
                    [-71.19302, 41.457931],
                    [-71.170131, 41.463974],
                    [-71.167345, 41.471405],
                    [-71.140224, 41.485855],
                    [-71.141093, 41.489937],
                    [-71.136867, 41.493942],
                    [-71.12057, 41.497448],
                    [-71.085663, 41.509292],
                    [-71.058418, 41.505967],
                    [-71.035514, 41.499047],
                    [-71.023523, 41.506326],
                    [-71.019354, 41.508857],
                    [-71.003275, 41.511912],
                    [-70.983354, 41.520616],
                    [-70.979225, 41.530427],
                    [-70.941785, 41.540121],
                    [-70.931545, 41.540169],
                    [-70.93783, 41.565239],
                    [-70.9473, 41.573659],
                    [-70.948797, 41.579038],
                    [-70.946911, 41.581089],
                    [-70.941588, 41.581034],
                    [-70.937978, 41.577416],
                    [-70.931338, 41.5842],
                    [-70.927393, 41.594064],
                    [-70.93, 41.600441],
                    [-70.929722, 41.609479],
                    [-70.927172, 41.611253],
                    [-70.920074, 41.61081],
                    [-70.916581, 41.607483],
                    [-70.910814, 41.595506],
                    [-70.901381, 41.592504],
                    [-70.899981, 41.593504],
                    [-70.904522, 41.610361],
                    [-70.913202, 41.619266],
                    [-70.905765, 41.623494],
                    [-70.904513, 41.624205],
                    [-70.889594, 41.632685],
                    [-70.88926, 41.632875],
                    [-70.889209, 41.632904],
                    [-70.887643, 41.632422],
                    [-70.87904, 41.629777],
                    [-70.872665, 41.627816],
                    [-70.869624, 41.625608],
                    [-70.86836, 41.622664],
                    [-70.868904, 41.614664],
                    [-70.868501, 41.613733],
                    [-70.863486, 41.602143],
                    [-70.862998, 41.601014],
                    [-70.862852, 41.600678],
                    [-70.857239, 41.587705],
                    [-70.85324, 41.587332],
                    [-70.853121, 41.587321],
                    [-70.852551, 41.588526],
                    [-70.852488, 41.588658],
                    [-70.85222, 41.589223],
                    [-70.850181, 41.593529],
                    [-70.853445, 41.613592],
                    [-70.854211, 41.618302],
                    [-70.854232, 41.618429],
                    [-70.855162, 41.624145],
                    [-70.855031, 41.624283],
                    [-70.852518, 41.626919],
                    [-70.844165, 41.628983],
                    [-70.843528, 41.628663],
                    [-70.843522, 41.62866],
                    [-70.843177, 41.628487],
                    [-70.835296, 41.624532],
                    [-70.810279, 41.624873],
                    [-70.801063, 41.629513],
                    [-70.800215, 41.631753],
                    [-70.804664, 41.641157],
                    [-70.816351, 41.645995],
                    [-70.815729, 41.652796],
                    [-70.813286, 41.65567],
                    [-70.809118, 41.656437],
                    [-70.776709, 41.650756],
                    [-70.775798, 41.649145],
                    [-70.773654, 41.645033],
                    [-70.769318, 41.641145],
                    [-70.765463, 41.641575],
                    [-70.757622, 41.654265],
                    [-70.758198, 41.661225],
                    [-70.76236, 41.667735],
                    [-70.761497, 41.676641],
                    [-70.761481, 41.676808],
                    [-70.755347, 41.694326],
                    [-70.744396, 41.696967],
                    [-70.729395, 41.68814],
                    [-70.719575, 41.685002],
                    [-70.717451, 41.69398],
                    [-70.721302, 41.712968],
                    [-70.728933, 41.723433],
                    [-70.726331, 41.732731],
                    [-70.718739, 41.73574],
                    [-70.708193, 41.730959],
                    [-70.670453, 41.721912],
                    [-70.656596, 41.715401],
                    [-70.651093, 41.715715],
                    [-70.644641, 41.71898],
                    [-70.642914, 41.71841],
                    [-70.626529, 41.712995],
                    [-70.623652, 41.707398],
                    [-70.62544, 41.698691],
                    [-70.645962, 41.693794],
                    [-70.661475, 41.681756],
                    [-70.649285, 41.680943],
                    [-70.646308, 41.678433],
                    [-70.637632, 41.654573],
                    [-70.638695, 41.649427],
                    [-70.650419, 41.644202],
                    [-70.652614, 41.637829],
                    [-70.645251, 41.633547],
                    [-70.640003, 41.624616],
                    [-70.651986, 41.610184],
                    [-70.652449, 41.60521],
                    [-70.64204, 41.583066],
                    [-70.640948, 41.577325],
                    [-70.642748, 41.572385],
                    [-70.64878, 41.56987],
                    [-70.653899, 41.56516],
                    [-70.655365, 41.557498],
                    [-70.654302, 41.549926],
                    [-70.658659, 41.543385],
                    [-70.6948, 41.52564],
                    [-70.745053, 41.500966],
                    [-70.753905, 41.492256],
                    [-70.775268, 41.477465],
                    [-70.787769, 41.474609],
                    [-70.802186, 41.460864],
                    [-70.828546, 41.456448],
                    [-70.855265, 41.448892],
                    [-70.883247, 41.432239],
                    [-70.906011, 41.425708],
                    [-70.91164, 41.424484],
                    [-70.918983, 41.4253],
                    [-70.923698, 41.430716],
                    [-70.928165, 41.431265],
                    [-70.949861, 41.415323],
                    [-70.951045, 41.411777],
                    [-70.948431, 41.409193],
                    [-70.937282, 41.411618],
                    [-70.928197, 41.415781],
                    [-70.902763, 41.421061],
                    [-70.866946, 41.422378],
                    [-70.857528, 41.425767],
                    [-70.835867, 41.441877],
                    [-70.817478, 41.445562],
                    [-70.79027, 41.446339],
                    [-70.760863, 41.460947],
                    [-70.756481, 41.465977],
                    [-70.757171, 41.469917],
                    [-70.734306, 41.486335],
                    [-70.705181, 41.496677],
                    [-70.675379, 41.512623],
                    [-70.669518, 41.513339],
                    [-70.663856, 41.514031],
                    [-70.654168, 41.518992],
                    [-70.654104, 41.519025],
                    [-70.65205, 41.521639],
                    [-70.651566, 41.522254],
                    [-70.650128, 41.524084],
                    [-70.650059, 41.524172],
                    [-70.643627, 41.532357],
                    [-70.6338, 41.538141],
                    [-70.633713, 41.538192],
                    [-70.633607, 41.538254],
                    [-70.617911, 41.541553],
                    [-70.617638, 41.541611],
                    [-70.617223, 41.541698],
                    [-70.616715, 41.541805],
                    [-70.614398, 41.542292],
                    [-70.613006, 41.542584],
                    [-70.611147, 41.542975],
                    [-70.611081, 41.542989],
                    [-70.59657, 41.544497],
                    [-70.58957, 41.545225],
                    [-70.588894, 41.545295],
                    [-70.58881, 41.545304],
                    [-70.588494, 41.545336],
                    [-70.588412, 41.545345],
                    [-70.574023, 41.54684],
                    [-70.561261, 41.548167],
                    [-70.560909, 41.548203],
                    [-70.559728, 41.548326],
                    [-70.559689, 41.54833],
                    [-70.559482, 41.548334],
                    [-70.53164, 41.548807],
                    [-70.522327, 41.548965],
                    [-70.522009, 41.548999],
                    [-70.493244, 41.552044],
                    [-70.486815, 41.553887],
                    [-70.485571, 41.554244],
                    [-70.485155, 41.554434],
                    [-70.48335, 41.555259],
                    [-70.476275, 41.558493],
                    [-70.476256, 41.558502],
                    [-70.476231, 41.558524],
                    [-70.473328, 41.561106],
                    [-70.472778, 41.561595],
                    [-70.462239, 41.570965],
                    [-70.462206, 41.570995],
                    [-70.46196, 41.571213],
                    [-70.461278, 41.57182],
                    [-70.461093, 41.572051],
                    [-70.457356, 41.576724],
                    [-70.456052, 41.578355],
                    [-70.450382, 41.585445],
                    [-70.446543, 41.590247],
                    [-70.445289, 41.591815],
                    [-70.44435, 41.593393],
                    [-70.442225, 41.596963],
                    [-70.440556, 41.599767],
                    [-70.437889, 41.604249],
                    [-70.437246, 41.605329],
                    [-70.413944, 41.606965],
                    [-70.408535, 41.607345],
                    [-70.401148, 41.606451],
                    [-70.400581, 41.606382],
                    [-70.379151, 41.611361],
                    [-70.369854, 41.615888],
                    [-70.364744, 41.623671],
                    [-70.364892, 41.626721],
                    [-70.360352, 41.631069],
                    [-70.351634, 41.634687],
                    [-70.338067, 41.636338],
                    [-70.329924, 41.634578],
                    [-70.321588, 41.630508],
                    [-70.29062, 41.635196],
                    [-70.28132, 41.635125],
                    [-70.274522, 41.632927],
                    [-70.26913, 41.625742],
                    [-70.269687, 41.617775],
                    [-70.267587, 41.610912],
                    [-70.265424, 41.609333],
                    [-70.259601, 41.610863],
                    [-70.25542, 41.617541],
                    [-70.25621, 41.620698],
                    [-70.245867, 41.628479],
                    [-70.191061, 41.645259],
                    [-70.158621, 41.650438],
                    [-70.148435, 41.650429],
                    [-70.147011, 41.650428],
                    [-70.145882, 41.650427],
                    [-70.142592, 41.650424],
                    [-70.141141, 41.650423],
                    [-70.140877, 41.650423],
                    [-70.140437, 41.650529],
                    [-70.140207, 41.650584],
                    [-70.140163, 41.650594],
                    [-70.134702, 41.651905],
                    [-70.122689, 41.654787],
                    [-70.122597, 41.654809],
                    [-70.121351, 41.655108],
                    [-70.120935, 41.655208],
                    [-70.119904, 41.655455],
                    [-70.089238, 41.662813],
                    [-70.055523, 41.664843],
                    [-70.037757, 41.666812],
                    [-70.037255, 41.666868],
                    [-70.037145, 41.66688],
                    [-70.029346, 41.667744],
                    [-70.029319, 41.667751],
                    [-70.029119, 41.667807],
                    [-70.020964, 41.670085],
                    [-70.015789, 41.67153],
                    [-70.014211, 41.671971],
                    [-70.007153, 41.671587],
                    [-70.007011, 41.671579],
                    [-70.006833, 41.671506],
                    [-70.003016, 41.669931],
                    [-70.002697, 41.669799],
                    [-69.996509, 41.667246],
                    [-69.996359, 41.667184],
                    [-69.996194, 41.667076],
                    [-69.99079, 41.663556],
                    [-69.990748, 41.663528],
                    [-69.990734, 41.663519],
                    [-69.990668, 41.663476],
                    [-69.990659, 41.663471],
                    [-69.975811, 41.653798],
                    [-69.975719, 41.653738],
                    [-69.973153, 41.646963],
                    [-69.973037, 41.641126],
                    [-69.973035, 41.641046],
                    [-69.975245, 41.635978],
                    [-69.975579, 41.635212],
                    [-69.976641, 41.632777],
                    [-69.976886, 41.632213],
                    [-69.977489, 41.630832],
                    [-69.980418, 41.624113],
                    [-69.982946, 41.618316],
                    [-69.987111, 41.608765],
                    [-69.987192, 41.608579],
                    [-69.991723, 41.58851],
                    [-69.994357, 41.576846],
                    [-70.000627, 41.563827],
                    [-70.000937, 41.563184],
                    [-70.001281, 41.56247],
                    [-70.00153, 41.561953],
                    [-70.008854, 41.55451],
                    [-70.010644, 41.552692],
                    [-70.011596, 41.552766],
                    [-70.015059, 41.553037],
                    [-70.016584, 41.550772],
                    [-70.014456, 41.545534],
                    [-70.011725, 41.54312],
                    [-70.011504, 41.542924],
                    [-70.011211, 41.542892],
                    [-70.004554, 41.542166],
                    [-70.004136, 41.54212],
                    [-70.003768, 41.542213],
                    [-69.999063, 41.5434],
                    [-69.998071, 41.54365],
                    [-69.996546, 41.54536],
                    [-69.988495, 41.55439],
                    [-69.988215, 41.554704],
                    [-69.982768, 41.581812],
                    [-69.976478, 41.603664],
                    [-69.967869, 41.627503],
                    [-69.963234, 41.633794],
                    [-69.958272, 41.639429],
                    [-69.956862, 41.639701],
                    [-69.953924, 41.640268],
                    [-69.952844, 41.640476],
                    [-69.951911, 41.640656],
                    [-69.951169, 41.640799],
                    [-69.947599, 41.645394],
                    [-69.943474, 41.652406],
                    [-69.942742, 41.653649],
                    [-69.93412, 41.668304],
                    [-69.933149, 41.669955],
                    [-69.933114, 41.670014],
                    [-69.9309, 41.67991],
                    [-69.929457, 41.686357],
                    [-69.929377, 41.686713],
                    [-69.928723, 41.689634],
                    [-69.928261, 41.6917],
                    [-69.928265, 41.692242],
                    [-69.928265, 41.692247],
                    [-69.928275, 41.693504],
                    [-69.928308, 41.697681],
                    [-69.928311, 41.697981],
                    [-69.928652, 41.74125],
                    [-69.935952, 41.809422],
                    [-69.945314, 41.845222],
                    [-69.968598, 41.9117],
                    [-69.986024, 41.949465],
                    [-69.986085, 41.949597],
                    [-69.986196, 41.949769],
                    [-70.004271, 41.977865],
                    [-70.004608, 41.978389],
                    [-70.005332, 41.979515],
                    [-70.005458, 41.97971],
                    [-70.005717, 41.980113],
                    [-70.01051, 41.987563],
                    [-70.011898, 41.98972],
                    [-70.011957, 41.989796],
                    [-70.013876, 41.992285],
                    [-70.014109, 41.992587],
                    [-70.01628, 41.995403],
                    [-70.029449, 42.012482],
                    [-70.029953, 42.013135],
                    [-70.031054, 42.014562],
                    [-70.033501, 42.017736],
                    [-70.034806, 42.018916],
                    [-70.058531, 42.040363],
                    [-70.082624, 42.054657],
                    [-70.115968, 42.067638],
                    [-70.154177, 42.077139],
                    [-70.160166, 42.078628],
                    [-70.189305, 42.082337],
                    [-70.206899, 42.0819],
                    [-70.225626, 42.078601],
                    [-70.238087, 42.072878],
                    [-70.245385, 42.063733],
                    [-70.24354, 42.060569],
                    [-70.238875, 42.060479],
                    [-70.233256, 42.057714],
                    [-70.218701, 42.045848],
                    [-70.208016, 42.03073],
                    [-70.196693, 42.022429],
                    [-70.190834, 42.020028],
                    [-70.186708, 42.019904],
                    [-70.186295, 42.021308],
                    [-70.193074, 42.027576],
                    [-70.195345, 42.034163],
                    [-70.194456, 42.03947],
                    [-70.186816, 42.05045],
                    [-70.178468, 42.05642],
                    [-70.169781, 42.059736],
                    [-70.155415, 42.062409],
                    [-70.148294, 42.06195],
                    [-70.147411, 42.06159],
                    [-70.123043, 42.051668],
                    [-70.10806, 42.043601],
                    [-70.095595, 42.032832],
                    [-70.089578, 42.024896],
                    [-70.083775, 42.012041],
                    [-70.077421, 41.985497],
                    [-70.074006, 41.93865],
                    [-70.073039, 41.899783],
                    [-70.070889, 41.882973],
                    [-70.067566, 41.877793],
                    [-70.066002, 41.877011],
                    [-70.064084, 41.878924],
                    [-70.065372, 41.887702],
                    [-70.065723, 41.899641],
                    [-70.065671, 41.911658],
                    [-70.054464, 41.927366],
                    [-70.044995, 41.930049],
                    [-70.030537, 41.929154],
                    [-70.025553, 41.911699],
                    [-70.024335, 41.89882],
                    [-70.012154, 41.891656],
                    [-70.002922, 41.890315],
                    [-70.000188, 41.886938],
                    [-70.009013, 41.876625],
                    [-70.004486, 41.838826],
                    [-70.003842, 41.80852],
                    [-70.008462, 41.800786],
                    [-70.008882, 41.80044],
                    [-70.009936, 41.79957],
                    [-70.022381, 41.789305],
                    [-70.022809, 41.788952],
                    [-70.023082, 41.788726],
                    [-70.024658, 41.787427],
                    [-70.024734, 41.787364],
                    [-70.033223, 41.78425],
                    [-70.033874, 41.784011],
                    [-70.03439, 41.783822],
                    [-70.060674, 41.77418],
                    [-70.064314, 41.772845],
                    [-70.065142, 41.772681],
                    [-70.067603, 41.772193],
                    [-70.081833, 41.769371],
                    [-70.086376, 41.76847],
                    [-70.096061, 41.766549],
                    [-70.096768, 41.766339],
                    [-70.097658, 41.766074],
                    [-70.097666, 41.766072],
                    [-70.109319, 41.762606],
                    [-70.115067, 41.760896],
                    [-70.115895, 41.76065],
                    [-70.121978, 41.758841],
                    [-70.122132, 41.758851],
                    [-70.12407, 41.758973],
                    [-70.124364, 41.758991],
                    [-70.129065, 41.759287],
                    [-70.136007, 41.759724],
                    [-70.14038, 41.759999],
                    [-70.141519, 41.760071],
                    [-70.141533, 41.760072],
                    [-70.143444, 41.759639],
                    [-70.143848, 41.759547],
                    [-70.159127, 41.756085],
                    [-70.176566, 41.752134],
                    [-70.182076, 41.750885],
                    [-70.189117, 41.751961],
                    [-70.189254, 41.751982],
                    [-70.193202, 41.750657],
                    [-70.197525, 41.749206],
                    [-70.198037, 41.749034],
                    [-70.216073, 41.742981],
                    [-70.216697, 41.742674],
                    [-70.220842, 41.740632],
                    [-70.224006, 41.739074],
                    [-70.224462, 41.738849],
                    [-70.225312, 41.738431],
                    [-70.225881, 41.73815],
                    [-70.23485, 41.733733],
                    [-70.235048, 41.733572],
                    [-70.237418, 41.731648],
                    [-70.24705, 41.723825],
                    [-70.247379, 41.723558],
                    [-70.247935, 41.723106],
                    [-70.250704, 41.720858],
                    [-70.251794, 41.719973],
                    [-70.252686, 41.719248],
                    [-70.256395, 41.716236],
                    [-70.259205, 41.713954],
                    [-70.26144, 41.714035],
                    [-70.263097, 41.714095],
                    [-70.263654, 41.714115],
                    [-70.263831, 41.714263],
                    [-70.272289, 41.721346],
                    [-70.274188, 41.724471],
                    [-70.275164, 41.726079],
                    [-70.275203, 41.726143],
                    [-70.275393, 41.726242],
                    [-70.277064, 41.727108],
                    [-70.288323, 41.732946],
                    [-70.290788, 41.734224],
                    [-70.290957, 41.734312],
                    [-70.291301, 41.73433],
                    [-70.314667, 41.735567],
                    [-70.31529, 41.7356],
                    [-70.318091, 41.735748],
                    [-70.375253, 41.738774],
                    [-70.375341, 41.738779],
                    [-70.376992, 41.739029],
                    [-70.412071, 41.744336],
                    [-70.412476, 41.744397],
                    [-70.413713, 41.744756],
                    [-70.458968, 41.757906],
                    [-70.471552, 41.761563],
                    [-70.471807, 41.761702],
                    [-70.471969, 41.761791],
                    [-70.471999, 41.761808],
                    [-70.473335, 41.762539],
                    [-70.473707, 41.762743],
                    [-70.474051, 41.762931],
                    [-70.482152, 41.767368],
                    [-70.483477, 41.768094],
                    [-70.492884, 41.773245],
                    [-70.493016, 41.773318],
                    [-70.494048, 41.773883],
                    [-70.514424, 41.788771],
                    [-70.514483, 41.788814],
                    [-70.514603, 41.788901],
                    [-70.516101, 41.789996],
                    [-70.516651, 41.790398],
                    [-70.516921, 41.790595],
                    [-70.517411, 41.790953],
                    [-70.517615, 41.791139],
                    [-70.518468, 41.791912],
                    [-70.52507, 41.797907],
                    [-70.532656, 41.804796],
                    [-70.533303, 41.805643],
                    [-70.533823, 41.806323],
                    [-70.537289, 41.810859],
                    [-70.54103, 41.815754],
                    [-70.543168, 41.824446],
                    [-70.542065, 41.831263],
                    [-70.535487, 41.839381],
                    [-70.525567, 41.85873],
                    [-70.532084, 41.889568],
                    [-70.545949, 41.907158],
                    [-70.54741, 41.911934],
                    [-70.546386, 41.916751],
                    [-70.552941, 41.929641],
                    [-70.583572, 41.950007],
                    [-70.598078, 41.947772],
                    [-70.608166, 41.940701],
                    [-70.616491, 41.940204],
                    [-70.623513, 41.943273],
                    [-70.631251, 41.950475],
                    [-70.648365, 41.961672],
                    [-70.651673, 41.958701],
                    [-70.662476, 41.960592],
                    [-70.698981, 41.987103],
                    [-70.710034, 41.999544],
                    [-70.712204, 42.007586],
                    [-70.695809, 42.013346],
                    [-70.686798, 42.012764],
                    [-70.678798, 42.00551],
                    [-70.670934, 42.007786],
                    [-70.667512, 42.01232],
                    [-70.671666, 42.02139],
                    [-70.66936, 42.037116],
                    [-70.650874, 42.046247],
                    [-70.644337, 42.045895],
                    [-70.643208, 42.050821],
                    [-70.64819, 42.068441],
                    [-70.647349, 42.076331],
                    [-70.63848, 42.081579],
                    [-70.640169, 42.088633],
                    [-70.663931, 42.108336],
                    [-70.685315, 42.133025],
                    [-70.706264, 42.163137],
                    [-70.714301, 42.168783],
                    [-70.718707, 42.184853],
                    [-70.722269, 42.207959],
                    [-70.73056, 42.21094],
                    [-70.74723, 42.221816],
                    [-70.754488, 42.228673],
                    [-70.764757, 42.244062],
                    [-70.770964, 42.249197],
                    [-70.780722, 42.251792],
                    [-70.788724, 42.25392],
                    [-70.811742, 42.262935],
                    [-70.824661, 42.265935],
                    [-70.831075, 42.267424],
                    [-70.851093, 42.26827],
                    [-70.861807, 42.275965],
                    [-70.870873, 42.285668],
                    [-70.881242, 42.300663],
                    [-70.882764, 42.30886],
                    [-70.907556, 42.307889],
                    [-70.91749, 42.305686],
                    [-70.915588, 42.302463],
                    [-70.897123, 42.29586],
                    [-70.895778, 42.292436],
                    [-70.896267, 42.2851],
                    [-70.906302, 42.271636],
                    [-70.910941, 42.265412],
                    [-70.923169, 42.263211],
                    [-70.935886, 42.264189],
                    [-70.945547, 42.269081],
                    [-70.948971, 42.272505],
                    [-70.967351, 42.268168],
                    [-70.98909, 42.267449],
                    [-70.996097, 42.271222],
                    [-71.0049, 42.28272],
                    [-71.006158, 42.28811],
                    [-71.000948, 42.302483],
                    [-71.005399, 42.307196],
                    [-71.013165, 42.315419],
                    [-71.01568, 42.326019],
                    [-71.011804, 42.335274],
                    [-71.010146, 42.339234],
                    [-71.006877, 42.347039],
                    [-70.998253, 42.352788],
                    [-70.982282, 42.35592],
                    [-70.979927, 42.356382],
                    [-70.974897, 42.355843],
                    [-70.972418, 42.353875],
                    [-70.963578, 42.34686],
                    [-70.953022, 42.343973],
                    [-70.953292, 42.349698],
                    [-70.960798, 42.360648],
                    [-70.972223, 42.377316],
                    [-70.972706, 42.381759],
                    [-70.972513, 42.385042],
                    [-70.97174, 42.387071],
                    [-70.970195, 42.388036],
                    [-70.972706, 42.389968],
                    [-70.980336, 42.391513],
                    [-70.983426, 42.396246],
                    [-70.985068, 42.402041],
                    [-70.989195, 42.402598],
                    [-70.990595, 42.407098],
                    [-70.987694, 42.416696],
                    [-70.982994, 42.423996],
                    [-70.960835, 42.441272],
                    [-70.960647, 42.443787],
                    [-70.96047, 42.446166],
                    [-70.94702, 42.456236],
                    [-70.943612, 42.452092],
                    [-70.943295, 42.436248],
                    [-70.936393, 42.418097],
                    [-70.905692, 42.416197],
                    [-70.901992, 42.420297],
                    [-70.908392, 42.425197],
                    [-70.913192, 42.427697],
                    [-70.928226, 42.430986],
                    [-70.933155, 42.437833],
                    [-70.934264, 42.444646],
                    [-70.934993, 42.457896],
                    [-70.921993, 42.466696],
                    [-70.917693, 42.467996],
                    [-70.908092, 42.466896],
                    [-70.894292, 42.460896],
                    [-70.887292, 42.464896],
                    [-70.887992, 42.467096],
                    [-70.886493, 42.470197],
                    [-70.879692, 42.478796],
                    [-70.857791, 42.490296],
                    [-70.847391, 42.491496],
                    [-70.841591, 42.487596],
                    [-70.835991, 42.490496],
                    [-70.831091, 42.503596],
                    [-70.842091, 42.519495],
                    [-70.857125, 42.521492],
                    [-70.859751, 42.520441],
                    [-70.866279, 42.522617],
                    [-70.872357, 42.542952],
                    [-70.871382, 42.546404],
                    [-70.848492, 42.550195],
                    [-70.823291, 42.551495],
                    [-70.815391, 42.554195],
                    [-70.804091, 42.561595],
                    [-70.757283, 42.570455],
                    [-70.737044, 42.576863],
                    [-70.729688, 42.57151],
                    [-70.698574, 42.577393],
                    [-70.684502, 42.588858],
                    [-70.681428, 42.593173],
                    [-70.678819, 42.594389],
                    [-70.675747, 42.594669],
                    [-70.672583, 42.594296],
                    [-70.670442, 42.592249],
                    [-70.668488, 42.589643],
                    [-70.668115, 42.585361],
                    [-70.668022, 42.581732],
                    [-70.664887, 42.580436],
                    [-70.654727, 42.582234],
                    [-70.635635, 42.600243],
                    [-70.61842, 42.62864],
                    [-70.605611, 42.634898],
                    [-70.594014, 42.63503],
                    [-70.591469, 42.639821],
                    [-70.591742, 42.648508],
                    [-70.595474, 42.660336],
                    [-70.61482, 42.65765],
                    [-70.622791, 42.660873],
                    [-70.623815, 42.665481],
                    [-70.622864, 42.67599],
                    [-70.620031, 42.688006],
                    [-70.630077, 42.692699],
                    [-70.645101, 42.689423],
                    [-70.663548, 42.677603],
                    [-70.681594, 42.662342],
                    [-70.682594, 42.654525],
                    [-70.689402, 42.653319],
                    [-70.728845, 42.663877],
                    [-70.72982, 42.669602],
                    [-70.744427, 42.682092],
                    [-70.748752, 42.683878],
                    [-70.764421, 42.68565],
                    [-70.778671, 42.693622],
                    [-70.778552, 42.69852],
                    [-70.770453, 42.704824],
                    [-70.772267, 42.711064],
                    [-70.792867, 42.747118],
                    [-70.80522, 42.781798],
                    [-70.817731, 42.850613],
                    [-70.817296, 42.87229],
                    [-70.816321, 42.884487],
                    [-70.81461, 42.891346],
                    [-70.810069, 42.909549],
                    [-70.795596, 42.93629],
                    [-70.759975, 42.988592],
                    [-70.749024, 42.995467],
                    [-70.74457, 43.00009],
                    [-70.718936, 43.03235],
                    [-70.703936, 43.059621],
                    [-70.673114, 43.070314],
                    [-70.665958, 43.076234],
                    [-70.656223, 43.093164],
                    [-70.655322, 43.098008],
                    [-70.654213, 43.099065],
                    [-70.638355, 43.114182],
                    [-70.634311, 43.122162],
                    [-70.634455, 43.127603],
                    [-70.618973, 43.163625],
                    [-70.587814, 43.199858],
                    [-70.576692, 43.217651],
                    [-70.575787, 43.221859],
                    [-70.591022, 43.237851],
                    [-70.593907, 43.249295],
                    [-70.585184, 43.270113],
                    [-70.562779, 43.310614],
                    [-70.553854, 43.321886],
                    [-70.536702, 43.335605],
                    [-70.535244, 43.336771],
                    [-70.517695, 43.344037],
                    [-70.485312, 43.346391],
                    [-70.472933, 43.343972],
                    [-70.465975, 43.340246],
                    [-70.460717, 43.34325],
                    [-70.442438, 43.356487],
                    [-70.424986, 43.375928],
                    [-70.424421, 43.379656],
                    [-70.427672, 43.389254],
                    [-70.421282, 43.395777],
                    [-70.406416, 43.400942],
                    [-70.401666, 43.401262],
                    [-70.400035, 43.399927],
                    [-70.39089, 43.402607],
                    [-70.383981, 43.41294],
                    [-70.384949, 43.418839],
                    [-70.370514, 43.434133],
                    [-70.362015, 43.439077],
                    [-70.349684, 43.442032],
                    [-70.37223, 43.45508],
                    [-70.380233, 43.46423],
                    [-70.382928, 43.469674],
                    [-70.385615, 43.487031],
                    [-70.384885, 43.49604],
                    [-70.379123, 43.507202],
                    [-70.361214, 43.52919],
                    [-70.353392, 43.535405],
                    [-70.352826, 43.535855],
                    [-70.341793, 43.540484],
                    [-70.307764, 43.544315],
                    [-70.299184, 43.550589],
                    [-70.272497, 43.562616],
                    [-70.261917, 43.553687],
                    [-70.244331, 43.551849],
                    [-70.231963, 43.561118],
                    [-70.219784, 43.562149],
                    [-70.216782, 43.556874],
                    [-70.206123, 43.557627],
                    [-70.196911, 43.565146],
                    [-70.20112, 43.586515],
                    [-70.214369, 43.590445],
                    [-70.217087, 43.596717],
                    [-70.211204, 43.625765],
                    [-70.22299, 43.639023],
                    [-70.240987, 43.659132],
                    [-70.241942, 43.663296],
                    [-70.240119, 43.664685],
                    [-70.239512, 43.665986],
                    [-70.242289, 43.669544],
                    [-70.247321, 43.671973],
                    [-70.252961, 43.67501],
                    [-70.254144, 43.676839],
                    [-70.251812, 43.683251],
                    [-70.23199, 43.704822],
                    [-70.227909, 43.701907],
                    [-70.216832, 43.704822],
                    [-70.215666, 43.707737],
                    [-70.219164, 43.715899],
                    [-70.217998, 43.71998],
                    [-70.194678, 43.742134],
                    [-70.194095, 43.745632],
                    [-70.197593, 43.753211],
                    [-70.194678, 43.766037],
                    [-70.190014, 43.771866],
                    [-70.17544, 43.777113],
                    [-70.172525, 43.773615],
                    [-70.177772, 43.764871],
                    [-70.176023, 43.76079],
                    [-70.153869, 43.774781],
                    [-70.153869, 43.781194],
                    [-70.142792, 43.791688],
                    [-70.107229, 43.809178],
                    [-70.080995, 43.819672],
                    [-70.06642, 43.819672],
                    [-70.064671, 43.813259],
                    [-70.053011, 43.821421],
                    [-70.053594, 43.828417],
                    [-70.032023, 43.849988],
                    [-70.022845, 43.856245],
                    [-70.019197, 43.858733],
                    [-70.009869, 43.859315],
                    [-70.002874, 43.852903],
                    [-70.002874, 43.848239],
                    [-70.006954, 43.844158],
                    [-70.023278, 43.834247],
                    [-70.026193, 43.829],
                    [-70.026193, 43.822587],
                    [-70.011035, 43.810927],
                    [-70.002874, 43.812093],
                    [-69.999376, 43.805097],
                    [-69.99821, 43.798684],
                    [-70.005205, 43.787607],
                    [-70.02561, 43.769534],
                    [-70.034355, 43.759041],
                    [-70.040768, 43.745049],
                    [-70.041351, 43.738053],
                    [-70.001708, 43.744466],
                    [-69.998793, 43.740385],
                    [-70.001125, 43.733389],
                    [-70.005205, 43.727559],
                    [-70.006954, 43.717065],
                    [-70.001645, 43.717666],
                    [-69.992615, 43.724793],
                    [-69.992396, 43.726852],
                    [-69.994479, 43.728451],
                    [-69.989131, 43.743227],
                    [-69.982574, 43.750801],
                    [-69.958056, 43.767786],
                    [-69.953246, 43.768806],
                    [-69.948539, 43.765948],
                    [-69.927011, 43.780174],
                    [-69.915593, 43.775112],
                    [-69.903164, 43.77239],
                    [-69.88739, 43.777052],
                    [-69.884066, 43.778035],
                    [-69.869732, 43.775656],
                    [-69.862155, 43.758962],
                    [-69.868673, 43.742701],
                    [-69.858947, 43.740531],
                    [-69.855595, 43.73266],
                    [-69.857927, 43.723915],
                    [-69.855081, 43.704746],
                    [-69.851297, 43.703581],
                    [-69.838689, 43.70514],
                    [-69.835323, 43.721125],
                    [-69.778348, 43.744612],
                    [-69.778494, 43.747089],
                    [-69.782283, 43.75117],
                    [-69.782429, 43.753794],
                    [-69.780097, 43.755397],
                    [-69.761587, 43.757],
                    [-69.752801, 43.75594],
                    [-69.719723, 43.786685],
                    [-69.717074, 43.792403],
                    [-69.717804, 43.801047],
                    [-69.714873, 43.810264],
                    [-69.705838, 43.823024],
                    [-69.697858, 43.824918],
                    [-69.697239, 43.825065],
                    [-69.692429, 43.824336],
                    [-69.685579, 43.820546],
                    [-69.685473, 43.816328],
                    [-69.664922, 43.791033],
                    [-69.653337, 43.79103],
                    [-69.650818, 43.803785],
                    [-69.65315, 43.817194],
                    [-69.649798, 43.836287],
                    [-69.634932, 43.845907],
                    [-69.629685, 43.843429],
                    [-69.630268, 43.837016],
                    [-69.621086, 43.826814],
                    [-69.61511, 43.831623],
                    [-69.613069, 43.837453],
                    [-69.613215, 43.845032],
                    [-69.604616, 43.858004],
                    [-69.594705, 43.858878],
                    [-69.589167, 43.851299],
                    [-69.592373, 43.830895],
                    [-69.598495, 43.825502],
                    [-69.604616, 43.825793],
                    [-69.605928, 43.814862],
                    [-69.604179, 43.813551],
                    [-69.588551, 43.81836],
                    [-69.578527, 43.823316],
                    [-69.575466, 43.841972],
                    [-69.572697, 43.844012],
                    [-69.568325, 43.844449],
                    [-69.558122, 43.84066],
                    [-69.552606, 43.841347],
                    [-69.545028, 43.861241],
                    [-69.550616, 43.872579],
                    [-69.550908, 43.877971],
                    [-69.54945, 43.880012],
                    [-69.543912, 43.881615],
                    [-69.524673, 43.875639],
                    [-69.520301, 43.868498],
                    [-69.513267, 43.84479],
                    [-69.516212, 43.837222],
                    [-69.514889, 43.831298],
                    [-69.50329, 43.837673],
                    [-69.486243, 43.869118],
                    [-69.483498, 43.88028],
                    [-69.459637, 43.903316],
                    [-69.45107, 43.941955],
                    [-69.441596, 43.964254],
                    [-69.436495, 43.966878],
                    [-69.431686, 43.964546],
                    [-69.42876, 43.957929],
                    [-69.416165, 43.977267],
                    [-69.398455, 43.971804],
                    [-69.388059, 43.96434],
                    [-69.375478, 43.964584],
                    [-69.366702, 43.964755],
                    [-69.351961, 43.974748],
                    [-69.331411, 43.974311],
                    [-69.321808, 43.969975],
                    [-69.304301, 43.962068],
                    [-69.305176, 43.956676],
                    [-69.319751, 43.94487],
                    [-69.31427, 43.942951],
                    [-69.307776, 43.943451],
                    [-69.288513, 43.957665],
                    [-69.283998, 43.958569],
                    [-69.282559, 43.958105],
                    [-69.280498, 43.95744],
                    [-69.267515, 43.943667],
                    [-69.265257, 43.933393],
                    [-69.259838, 43.921427],
                    [-69.24271, 43.925465],
                    [-69.237368, 43.931596],
                    [-69.214205, 43.935583],
                    [-69.203668, 43.941806],
                    [-69.19633, 43.950504],
                    [-69.193805, 43.959762],
                    [-69.197803, 43.967547],
                    [-69.193805, 43.975543],
                    [-69.170345, 43.995637],
                    [-69.162559, 43.999003],
                    [-69.148883, 43.998582],
                    [-69.128611, 44.005922],
                    [-69.124475, 44.007419],
                    [-69.128052, 44.017309],
                    [-69.125738, 44.019623],
                    [-69.113113, 44.028881],
                    [-69.094177, 44.038981],
                    [-69.081131, 44.041295],
                    [-69.073767, 44.046135],
                    [-69.079805, 44.055256],
                    [-69.067876, 44.067596],
                    [-69.064299, 44.069911],
                    [-69.056093, 44.06949],
                    [-69.050622, 44.068017],
                    [-69.050566, 44.063152],
                    [-69.048917, 44.062506],
                    [-69.031878, 44.079036],
                    [-69.043403, 44.092164],
                    [-69.050814, 44.094888],
                    [-69.056303, 44.095162],
                    [-69.076452, 44.090634],
                    [-69.089078, 44.085326],
                    [-69.092, 44.085734],
                    [-69.101107, 44.093601],
                    [-69.100863, 44.104529],
                    [-69.080331, 44.117824],
                    [-69.075667, 44.129991],
                    [-69.079608, 44.143962],
                    [-69.080978, 44.156768],
                    [-69.079835, 44.160953],
                    [-69.077776, 44.165043],
                    [-69.06124, 44.165498],
                    [-69.054546, 44.171542],
                    [-69.05269, 44.188082],
                    [-69.05181, 44.19592],
                    [-69.042807, 44.215173],
                    [-69.043599, 44.225029],
                    [-69.040193, 44.233673],
                    [-69.029434, 44.248558],
                    [-69.02859, 44.249139],
                    [-69.017051, 44.257086],
                    [-69.005071, 44.274071],
                    [-69.003682, 44.294582],
                    [-68.979005, 44.296327],
                    [-68.958889, 44.314353],
                    [-68.954465, 44.32405],
                    [-68.948164, 44.355882],
                    [-68.961111, 44.375076],
                    [-68.9673, 44.381106],
                    [-68.978815, 44.38634],
                    [-68.984404, 44.396879],
                    [-68.990767, 44.415033],
                    [-68.982449, 44.426195],
                    [-68.946582, 44.429108],
                    [-68.931934, 44.43869],
                    [-68.927452, 44.448039],
                    [-68.900934, 44.452062],
                    [-68.897104, 44.450643],
                    [-68.892073, 44.445075],
                    [-68.8928, 44.443415],
                    [-68.890826, 44.437995],
                    [-68.886491, 44.430676],
                    [-68.880271, 44.428112],
                    [-68.858993, 44.444923],
                    [-68.829153, 44.462242],
                    [-68.810059, 44.468737],
                    [-68.796206, 44.471437],
                    [-68.783679, 44.473879],
                    [-68.785898, 44.462611],
                    [-68.801634, 44.434803],
                    [-68.815325, 44.42808],
                    [-68.821767, 44.40894],
                    [-68.818703, 44.375077],
                    [-68.814811, 44.362194],
                    [-68.817647, 44.353093],
                    [-68.821311, 44.349594],
                    [-68.825419, 44.334547],
                    [-68.828377, 44.316549],
                    [-68.827197, 44.31216],
                    [-68.795063, 44.30786],
                    [-68.771489, 44.320523],
                    [-68.766197, 44.327015],
                    [-68.762021, 44.329597],
                    [-68.746164, 44.331148],
                    [-68.733004, 44.328388],
                    [-68.725657, 44.321591],
                    [-68.682979, 44.299201],
                    [-68.630036, 44.286235],
                    [-68.627515, 44.284435],
                    [-68.626075, 44.280473],
                    [-68.61563, 44.275431],
                    [-68.603385, 44.27471],
                    [-68.572772, 44.252741],
                    [-68.562687, 44.248059],
                    [-68.551162, 44.238335],
                    [-68.550802, 44.236534],
                    [-68.534595, 44.229331],
                    [-68.525302, 44.227554],
                    [-68.52348, 44.235819],
                    [-68.528153, 44.241263],
                    [-68.529802, 44.249594],
                    [-68.519819, 44.260209],
                    [-68.519516, 44.265046],
                    [-68.528611, 44.276117],
                    [-68.532266, 44.28634],
                    [-68.531532, 44.290388],
                    [-68.538595, 44.299902],
                    [-68.556236, 44.300819],
                    [-68.564005, 44.308022],
                    [-68.566203, 44.313007],
                    [-68.566936, 44.317603],
                    [-68.563209, 44.333039],
                    [-68.553873, 44.346256],
                    [-68.545434, 44.355],
                    [-68.550051, 44.371788],
                    [-68.559285, 44.374307],
                    [-68.564741, 44.385219],
                    [-68.56642, 44.394453],
                    [-68.565161, 44.39907],
                    [-68.560964, 44.402847],
                    [-68.555088, 44.403687],
                    [-68.534522, 44.397811],
                    [-68.529905, 44.39907],
                    [-68.51452, 44.41334],
                    [-68.505562, 44.411661],
                    [-68.499686, 44.414179],
                    [-68.494649, 44.429709],
                    [-68.485415, 44.434326],
                    [-68.480379, 44.432647],
                    [-68.472824, 44.404106],
                    [-68.480798, 44.397391],
                    [-68.483317, 44.388157],
                    [-68.47828, 44.378084],
                    [-68.466109, 44.377245],
                    [-68.461072, 44.378504],
                    [-68.461072, 44.385639],
                    [-68.464262, 44.391081],
                    [-68.464106, 44.398078],
                    [-68.458849, 44.412141],
                    [-68.46382, 44.436592],
                    [-68.460003, 44.443317],
                    [-68.455095, 44.447498],
                    [-68.448006, 44.449497],
                    [-68.439281, 44.448043],
                    [-68.429648, 44.439136],
                    [-68.432556, 44.426594],
                    [-68.433901, 44.401534],
                    [-68.427874, 44.3968],
                    [-68.421783, 44.396411],
                    [-68.416412, 44.397973],
                    [-68.392559, 44.41807],
                    [-68.390932, 44.427387],
                    [-68.387678, 44.430936],
                    [-68.3791, 44.430049],
                    [-68.372445, 44.42369],
                    [-68.359082, 44.402847],
                    [-68.3581, 44.392337],
                    [-68.360318, 44.389674],
                    [-68.36372, 44.388935],
                    [-68.367565, 44.39071],
                    [-68.398035, 44.376191],
                    [-68.395516, 44.369561],
                    [-68.396552, 44.363941],
                    [-68.406089, 44.356679],
                    [-68.409867, 44.356259],
                    [-68.421471, 44.337754],
                    [-68.421619, 44.336113],
                    [-68.409867, 44.329397],
                    [-68.409027, 44.32562],
                    [-68.411965, 44.322262],
                    [-68.430853, 44.312609],
                    [-68.430946, 44.298624],
                    [-68.426107, 44.295102],
                    [-68.421302, 44.284468],
                    [-68.41965, 44.274612],
                    [-68.401268, 44.252244],
                    [-68.389848, 44.247066],
                    [-68.377982, 44.247563],
                    [-68.369759, 44.243311],
                    [-68.365364, 44.237871],
                    [-68.343132, 44.229505],
                    [-68.339498, 44.222893],
                    [-68.317588, 44.225101],
                    [-68.290818, 44.247673],
                    [-68.295265, 44.261722],
                    [-68.297641, 44.263035],
                    [-68.298643, 44.26665],
                    [-68.298223, 44.276303],
                    [-68.289409, 44.283858],
                    [-68.275139, 44.288895],
                    [-68.233435, 44.288578],
                    [-68.191924, 44.306675],
                    [-68.173608, 44.328397],
                    [-68.174687, 44.343604],
                    [-68.184532, 44.369145],
                    [-68.196937, 44.386352],
                    [-68.20354, 44.392365],
                    [-68.209664, 44.392984],
                    [-68.21554, 44.390466],
                    [-68.249956, 44.414809],
                    [-68.249956, 44.417747],
                    [-68.24366, 44.420685],
                    [-68.2445, 44.429919],
                    [-68.247438, 44.433276],
                    [-68.268423, 44.440411],
                    [-68.294865, 44.432857],
                    [-68.299063, 44.437893],
                    [-68.298223, 44.449225],
                    [-68.281015, 44.451324],
                    [-68.270522, 44.459718],
                    [-68.268004, 44.47147],
                    [-68.261708, 44.484062],
                    [-68.252474, 44.483222],
                    [-68.2445, 44.471051],
                    [-68.22939, 44.463496],
                    [-68.224354, 44.464335],
                    [-68.227292, 44.479865],
                    [-68.223934, 44.487],
                    [-68.213861, 44.492456],
                    [-68.192036, 44.487419],
                    [-68.189937, 44.484901],
                    [-68.189517, 44.478605],
                    [-68.194554, 44.47189],
                    [-68.17105, 44.470211],
                    [-68.163075, 44.473149],
                    [-68.162656, 44.477346],
                    [-68.159298, 44.479445],
                    [-68.150904, 44.482383],
                    [-68.123203, 44.478815],
                    [-68.117746, 44.475038],
                    [-68.115228, 44.467903],
                    [-68.119425, 44.459508],
                    [-68.119845, 44.445658],
                    [-68.116487, 44.429289],
                    [-68.11271, 44.421314],
                    [-68.11229, 44.401588],
                    [-68.103818, 44.385111],
                    [-68.092983, 44.370949],
                    [-68.090045, 44.371369],
                    [-68.086268, 44.376405],
                    [-68.077873, 44.373047],
                    [-68.076066, 44.347925],
                    [-68.067047, 44.335692],
                    [-68.060356, 44.331988],
                    [-68.049334, 44.33073],
                    [-68.043037, 44.343667],
                    [-68.044716, 44.351222],
                    [-68.044296, 44.357938],
                    [-68.039679, 44.360876],
                    [-68.034223, 44.360456],
                    [-68.01399, 44.390255],
                    [-68.019533, 44.396971],
                    [-68.010719, 44.407464],
                    [-68.006102, 44.409562],
                    [-68.000646, 44.406624],
                    [-67.997288, 44.399909],
                    [-67.985668, 44.386917],
                    [-67.978876, 44.387034],
                    [-67.961613, 44.39907],
                    [-67.961613, 44.4125],
                    [-67.957089, 44.415408],
                    [-67.955737, 44.416278],
                    [-67.947342, 44.415858],
                    [-67.936531, 44.411187],
                    [-67.931453, 44.411848],
                    [-67.927616, 44.421314],
                    [-67.930554, 44.428869],
                    [-67.926357, 44.431807],
                    [-67.92132, 44.433066],
                    [-67.913346, 44.430128],
                    [-67.911667, 44.419216],
                    [-67.899571, 44.394078],
                    [-67.892033, 44.409668],
                    [-67.887323, 44.426351],
                    [-67.887323, 44.433066],
                    [-67.878509, 44.435585],
                    [-67.868856, 44.424672],
                    [-67.855108, 44.419434],
                    [-67.851697, 44.424282],
                    [-67.851764, 44.428695],
                    [-67.868875, 44.456881],
                    [-67.868774, 44.465272],
                    [-67.866801, 44.471812],
                    [-67.860994, 44.477576],
                    [-67.855579, 44.478676],
                    [-67.851648, 44.484901],
                    [-67.851228, 44.492456],
                    [-67.853746, 44.497492],
                    [-67.856684, 44.523934],
                    [-67.843254, 44.542822],
                    [-67.845772, 44.551636],
                    [-67.844513, 44.556252],
                    [-67.839896, 44.558771],
                    [-67.829823, 44.557512],
                    [-67.808837, 44.544081],
                    [-67.805479, 44.536946],
                    [-67.805479, 44.52981],
                    [-67.802541, 44.523934],
                    [-67.79726, 44.520685],
                    [-67.781556, 44.520577],
                    [-67.781975, 44.534008],
                    [-67.779457, 44.543661],
                    [-67.774001, 44.547438],
                    [-67.767285, 44.548278],
                    [-67.758891, 44.546599],
                    [-67.753854, 44.543661],
                    [-67.742942, 44.526453],
                    [-67.740076, 44.508944],
                    [-67.742789, 44.506176],
                    [-67.743353, 44.497418],
                    [-67.733986, 44.496252],
                    [-67.722876, 44.498524],
                    [-67.71419, 44.495238],
                    [-67.703489, 44.504837],
                    [-67.698872, 44.51575],
                    [-67.702649, 44.527922],
                    [-67.696354, 44.533798],
                    [-67.685861, 44.537155],
                    [-67.660678, 44.537575],
                    [-67.656901, 44.535896],
                    [-67.653123, 44.525823],
                    [-67.648506, 44.525403],
                    [-67.568159, 44.531117],
                    [-67.562321, 44.539435],
                    [-67.569836, 44.556788],
                    [-67.575056, 44.560659],
                    [-67.552392, 44.619629],
                    [-67.551133, 44.621938],
                    [-67.543368, 44.626554],
                    [-67.54022, 44.626345],
                    [-67.533925, 44.621518],
                    [-67.530777, 44.621938],
                    [-67.524061, 44.626554],
                    [-67.522802, 44.63306],
                    [-67.505804, 44.636837],
                    [-67.493632, 44.628863],
                    [-67.492373, 44.61795],
                    [-67.457747, 44.598014],
                    [-67.448513, 44.600322],
                    [-67.447464, 44.60326],
                    [-67.443686, 44.605779],
                    [-67.428367, 44.609136],
                    [-67.420602, 44.607877],
                    [-67.418923, 44.60347],
                    [-67.411815, 44.596954],
                    [-67.405492, 44.594236],
                    [-67.398987, 44.602631],
                    [-67.395839, 44.612914],
                    [-67.388704, 44.626554],
                    [-67.386605, 44.626974],
                    [-67.377554, 44.619757],
                    [-67.368269, 44.624672],
                    [-67.363158, 44.631825],
                    [-67.367298, 44.652472],
                    [-67.374014, 44.662965],
                    [-67.37905, 44.665483],
                    [-67.381149, 44.66947],
                    [-67.376742, 44.681852],
                    [-67.355966, 44.69906],
                    [-67.347782, 44.69948],
                    [-67.308538, 44.707454],
                    [-67.299176, 44.705705],
                    [-67.300144, 44.696752],
                    [-67.307909, 44.691295],
                    [-67.309627, 44.659316],
                    [-67.298449, 44.654377],
                    [-67.292462, 44.648455],
                    [-67.293665, 44.634316],
                    [-67.310745, 44.613212],
                    [-67.322491, 44.612458],
                    [-67.32297, 44.609394],
                    [-67.314938, 44.598215],
                    [-67.302427, 44.597323],
                    [-67.293403, 44.599265],
                    [-67.27916, 44.606782],
                    [-67.273076, 44.610873],
                    [-67.27706, 44.61795],
                    [-67.274122, 44.626345],
                    [-67.251247, 44.640825],
                    [-67.24726, 44.641664],
                    [-67.234275, 44.637201],
                    [-67.213025, 44.63922],
                    [-67.189427, 44.645533],
                    [-67.191438, 44.64775],
                    [-67.192068, 44.655515],
                    [-67.186612, 44.66265],
                    [-67.181785, 44.663699],
                    [-67.169857, 44.662105],
                    [-67.154479, 44.668114],
                    [-67.155119, 44.66944],
                    [-67.148061, 44.684114],
                    [-67.139209, 44.693849],
                    [-67.128792, 44.695421],
                    [-67.103957, 44.717444],
                    [-67.098931, 44.741311],
                    [-67.092542, 44.742693],
                    [-67.083477, 44.739899],
                    [-67.073439, 44.741957],
                    [-67.063308, 44.758238],
                    [-67.062239, 44.769543],
                    [-67.05516, 44.771442],
                    [-67.04335, 44.765071],
                    [-67.02615, 44.768199],
                    [-67.01995, 44.771427],
                    [-66.995154, 44.791073],
                    [-66.989351, 44.79878],
                    [-66.979708, 44.80736],
                    [-66.97626, 44.808315],
                    [-66.970026, 44.805713],
                    [-66.961068, 44.807269],
                    [-66.950569, 44.814539],
                    [-66.949895, 44.817419],
                    [-66.952112, 44.82007],
                    [-66.966468, 44.819063],
                    [-66.975009, 44.815495],
                    [-66.986318, 44.820657],
                    [-66.996523, 44.844654],
                    [-66.99296, 44.849181],
                    [-66.978142, 44.856963],
                    [-66.981008, 44.862813],
                    [-66.990351, 44.882551],
                    [-66.989235, 44.89648],
                    [-66.985901, 44.89715],
                    [-66.983558, 44.903277],
                    [-66.984466, 44.912557],
                    [-66.990937, 44.917835],
                    [-67.002118, 44.918836],
                    [-67.033474, 44.939923],
                    [-67.038299, 44.945433],
                    [-67.05461, 44.986764],
                    [-67.068274, 45.001014],
                    [-67.072753, 45.008329],
                    [-67.074914, 45.019254],
                    [-67.082074, 45.029608],
                    [-67.099749, 45.04501],
                    [-67.117688, 45.05673],
                    [-67.105899, 45.065786],
                    [-67.090786, 45.068721],
                    [-67.112414, 45.112323],
                    [-67.128935, 45.132168],
                    [-67.161247, 45.162879],
                    [-67.271076, 45.191081],
                    [-67.283619, 45.192022],
                    [-67.290603, 45.187589],
                    [-67.302568, 45.161348],
                    [-67.305472, 45.144826],
                    [-67.339869, 45.125594],
                    [-67.345004, 45.12621],
                    [-67.354306, 45.129886],
                    [-67.405208, 45.165686],
                    [-67.46357, 45.244097],
                    [-67.489333, 45.281282],
                    [-67.485683, 45.291433],
                    [-67.465833, 45.297223],
                    [-67.460101, 45.301179],
                    [-67.429161, 45.344247],
                    [-67.422153, 45.379661],
                    [-67.430001, 45.392965],
                    [-67.460281, 45.419594],
                    [-67.473366, 45.425328],
                    [-67.503771, 45.488522],
                    [-67.463481, 45.508516],
                    [-67.448729, 45.510576],
                    [-67.425674, 45.502917],
                    [-67.420875, 45.55056],
                    [-67.423646, 45.572153],
                    [-67.425452, 45.579086],
                    [-67.449674, 45.60286],
                    [-67.455406, 45.604665],
                    [-67.476704, 45.604157],
                    [-67.489793, 45.60118],
                    [-67.491061, 45.598917],
                    [-67.488452, 45.594643],
                    [-67.490923, 45.591488],
                    [-67.499444, 45.587014],
                    [-67.51858, 45.587925],
                    [-67.561359, 45.594906],
                    [-67.64581, 45.613597],
                    [-67.754245, 45.667791],
                    [-67.803313, 45.677886],
                    [-67.805483, 45.680241],
                    [-67.817892, 45.693705],
                    [-67.806598, 45.794723],
                    [-67.801989, 45.803546],
                    [-67.780443, 45.816206],
                    [-67.763767, 45.820315],
                    [-67.763955, 45.829983],
                    [-67.768709, 45.901997],
                    [-67.781095, 45.943032],
                    [-67.782114, 46.279381],
                    [-67.789284, 46.758219],
                    [-67.78938, 46.765528],
                    [-67.789461, 47.062544],
                    [-67.790515, 47.067921],
                    [-67.816345, 47.081188],
                    [-67.846865, 47.092404],
                    [-67.889155, 47.118772],
                    [-67.935868, 47.164843],
                    [-67.949369, 47.182542],
                    [-67.952269, 47.196142],
                    [-67.955669, 47.199542],
                    [-68.153509, 47.314038],
                    [-68.224005, 47.348456],
                    [-68.240168, 47.354217],
                    [-68.284101, 47.360389],
                    [-68.33368, 47.36007],
                    [-68.361559, 47.355605],
                    [-68.474851, 47.297534],
                    [-68.580398, 47.286957],
                    [-68.723168, 47.239569],
                    [-68.85343, 47.193443],
                    [-68.900985, 47.178519],
                    [-68.926421, 47.19786],
                    [-68.941948, 47.206248],
                    [-68.982002, 47.220295],
                    [-69.033456, 47.240984],
                    [-69.0402, 47.2451],
                    [-69.050334, 47.256621],
                    [-69.053819, 47.375036],
                    [-69.046448, 47.383934],
                    [-69.043958, 47.383682],
                    [-69.03928, 47.385052],
                    [-69.041733, 47.397326],
                    [-69.04079, 47.39808],
                    [-69.031127, 47.403106],
                    [-69.032909, 47.407907],
                    [-69.042702, 47.426651],
                    [-69.055465, 47.432274],
                    [-69.146439, 47.44886],
                    [-69.22442, 47.459686],
                    [-69.566383, 47.125032],
                    [-69.818552, 46.87503],
                    [-69.997086, 46.69523],
                    [-70.023016, 46.573486],
                    [-70.056433, 46.41659],
                    [-70.133367, 46.368906],
                    [-70.174709, 46.358472],
                    [-70.188046, 46.350015],
                    [-70.207415, 46.331316],
                    [-70.292736, 46.191599],
                    [-70.290896, 46.185838],
                    [-70.278034, 46.175001],
                    [-70.237947, 46.147378],
                    [-70.252411, 46.106221],
                    [-70.284554, 46.098713],
                    [-70.28978, 46.094325],
                    [-70.310609, 46.064544],
                    [-70.313364, 46.016383],
                    [-70.312055, 45.971544],
                    [-70.26541, 45.962692],
                    [-70.252242, 45.954777],
                    [-70.240177, 45.943729],
                    [-70.253704, 45.902981],
                    [-70.262655, 45.887853],
                    [-70.284204, 45.872034],
                    [-70.307798, 45.85894],
                    [-70.34244, 45.852192],
                    [-70.36702, 45.834993],
                    [-70.387916, 45.819043],
                    [-70.397208, 45.807572],
                    [-70.398159, 45.80412],
                    [-70.396362, 45.802703],
                    [-70.417674, 45.79457],
                    [-70.40775, 45.773654],
                    [-70.39721, 45.756872],
                    [-70.451503, 45.704432],
                    [-70.469869, 45.701639],
                    [-70.551038, 45.661885],
                    [-70.564789, 45.65515],
                    [-70.659286, 45.58688],
                    [-70.688214, 45.563981],
                    [-70.686531, 45.54999],
                    [-70.700776, 45.532777],
                    [-70.721611, 45.515058],
                    [-70.723167, 45.507606],
                    [-70.716332, 45.486909],
                    [-70.67591, 45.452582],
                    [-70.663701, 45.447049],
                    [-70.650161, 45.443685],
                    [-70.635498, 45.427817],
                    [-70.626527, 45.407011],
                    [-70.634661, 45.383608],
                    [-70.651986, 45.377146],
                    [-70.660029, 45.377901],
                    [-70.712286, 45.390611],
                    [-70.729972, 45.399359],
                    [-70.743775, 45.411925],
                    [-70.744077, 45.421091],
                    [-70.753977, 45.427789],
                    [-70.781471, 45.431159],
                    [-70.798483, 45.42474],
                    [-70.825612, 45.400305],
                    [-70.824053, 45.391094],
                    [-70.807664, 45.378118],
                    [-70.808322, 45.325824],
                    [-70.831612, 45.260055],
                    [-70.839482, 45.237829],
                    [-70.842845, 45.234422],
                    [-70.857042, 45.22916],
                    [-70.885029, 45.234873],
                    [-70.896898, 45.242031],
                    [-70.907978, 45.269316],
                    [-70.913732, 45.292746],
                    [-70.912176, 45.296768],
                    [-70.917904, 45.311924],
                    [-70.950824, 45.33453],
                    [-70.990233, 45.336277],
                    [-71.005087, 45.331545],
                    [-71.002401, 45.327956],
                    [-71.00905, 45.319022],
                    [-71.014268, 45.316761],
                    [-71.030565, 45.312652],
                    [-71.084334, 45.305293],
                    [-71.097772, 45.301906],
                    [-71.105151, 45.294635],
                    [-71.120112, 45.265738],
                    [-71.119914, 45.262287],
                    [-71.124517, 45.25527],
                    [-71.131953, 45.245423],
                    [-71.13943, 45.242958],
                    [-71.183785, 45.244932],
                    [-71.232998, 45.256625],
                    [-71.259614, 45.27324],
                    [-71.263042, 45.277401],
                    [-71.266754, 45.29123],
                    [-71.284396, 45.302434],
                    [-71.297092, 45.298985],
                    [-71.342961, 45.271281],
                    [-71.401182, 45.242544],
                    [-71.44288, 45.234799],
                    [-71.403267, 45.215348],
                    [-71.39781, 45.203553],
                    [-71.428828, 45.123881],
                    [-71.489145, 45.072308],
                    [-71.496098, 45.069235],
                    [-71.502487, 45.013367],
                    [-71.767452, 45.011437],
                    [-71.897622, 45.009172],
                    [-72.103058, 45.005598],
                    [-72.342453, 45.005354],
                    [-72.554253, 45.011794],
                    [-72.67477, 45.015459],
                    [-72.845633, 45.016659],
                    [-73.014766, 45.01498],
                    [-73.015539, 45.015072],
                    [-73.085972, 45.015494],
                    [-73.191948, 45.013576],
                    [-73.343124, 45.01084],
                    [-73.624588, 45.003954],
                    [-73.874597, 45.001223],
                    [-74.027392, 44.995765],
                    [-74.146814, 44.9915],
                    [-74.291307, 44.992058],
                    [-74.702018, 45.003322],
                    [-74.72498, 45.005915],
                    [-74.801625, 45.014571],
                    [-74.826578, 45.01585],
                    [-74.972463, 44.983402],
                    [-74.992756, 44.977449],
                    [-74.99927, 44.971638],
                    [-74.999655, 44.965921],
                    [-75.005155, 44.958402],
                    [-75.027125, 44.946568],
                    [-75.064826, 44.929449],
                    [-75.139868, 44.896676],
                    [-75.189313, 44.883037],
                    [-75.216486, 44.877478],
                    [-75.283136, 44.849156],
                    [-75.30763, 44.836813],
                    [-75.301975, 44.826637],
                    [-75.333744, 44.806378],
                    [-75.433124, 44.750062],
                    [-75.491201, 44.712947],
                    [-75.696586, 44.567583],
                    [-75.765495, 44.516285],
                    [-75.807778, 44.471644],
                    [-75.82083, 44.432244],
                    [-75.86006, 44.403282],
                    [-75.871496, 44.394839],
                    [-75.912985, 44.368084],
                    [-75.94954, 44.349129],
                    [-75.970185, 44.342835],
                    [-75.978281, 44.34688],
                    [-76.000998, 44.347534],
                    [-76.045228, 44.331724],
                    [-76.118136, 44.29485],
                    [-76.164265, 44.239603],
                    [-76.191328, 44.221244],
                    [-76.206777, 44.214543],
                    [-76.245487, 44.203669],
                    [-76.286547, 44.203773],
                    [-76.312647, 44.199044],
                    [-76.355679, 44.133258],
                    [-76.358163, 44.123337],
                    [-76.363835, 44.111696],
                    [-76.366972, 44.100409],
                    [-76.360798, 44.087644],
                    [-76.360306, 44.070907],
                    [-76.304207, 44.059445],
                    [-76.300532, 44.057188],
                    [-76.296986, 44.045455],
                    [-76.299592, 44.030956],
                    [-76.300222, 44.022762],
                    [-76.298962, 44.017719],
                    [-76.296755, 44.013307],
                    [-76.287821, 44.01142],
                    [-76.281928, 44.009177],
                    [-76.269672, 44.001148],
                    [-76.266733, 43.995578],
                    [-76.268702, 43.987278],
                    [-76.268706, 43.980846],
                    [-76.264294, 43.978009],
                    [-76.258306, 43.976118],
                    [-76.252318, 43.975803],
                    [-76.244439, 43.975803],
                    [-76.236864, 43.9779],
                    [-76.22805, 43.982737],
                    [-76.184874, 43.971128],
                    [-76.169802, 43.962202],
                    [-76.146072, 43.964705],
                    [-76.139086, 43.962111],
                    [-76.134296, 43.954726],
                    [-76.134359, 43.945614],
                    [-76.133697, 43.940356],
                    [-76.130446, 43.933082],
                    [-76.125023, 43.912773],
                    [-76.127285, 43.897889],
                    [-76.133267, 43.892975],
                    [-76.145506, 43.888681],
                    [-76.158249, 43.887542],
                    [-76.180604, 43.877529],
                    [-76.192777, 43.869175],
                    [-76.202257, 43.864898],
                    [-76.219313, 43.86682],
                    [-76.227485, 43.875061],
                    [-76.234578, 43.877388],
                    [-76.243384, 43.877975],
                    [-76.249842, 43.875626],
                    [-76.261584, 43.873278],
                    [-76.269217, 43.868581],
                    [-76.276262, 43.863297],
                    [-76.28272, 43.858601],
                    [-76.284481, 43.850968],
                    [-76.283307, 43.843923],
                    [-76.277812, 43.841205],
                    [-76.266977, 43.838046],
                    [-76.250135, 43.825713],
                    [-76.229268, 43.804135],
                    [-76.213205, 43.753513],
                    [-76.205436, 43.718751],
                    [-76.2005, 43.680231],
                    [-76.196596, 43.649761],
                    [-76.199138, 43.600454],
                    [-76.203473, 43.574978],
                    [-76.209853, 43.560136],
                    [-76.217958, 43.545156],
                    [-76.228701, 43.532987],
                    [-76.235834, 43.529256],
                    [-76.259858, 43.524728],
                    [-76.297103, 43.51287],
                    [-76.330911, 43.511978],
                    [-76.345492, 43.513437],
                    [-76.368849, 43.525822],
                    [-76.410636, 43.523159],
                    [-76.417581, 43.521285],
                    [-76.437473, 43.509213],
                    [-76.472498, 43.492781],
                    [-76.486962, 43.47535],
                    [-76.506858, 43.469127],
                    [-76.515882, 43.471136],
                    [-76.521999, 43.468617],
                    [-76.53181, 43.460299],
                    [-76.562826, 43.448537],
                    [-76.607093, 43.423374],
                    [-76.617109, 43.419137],
                    [-76.630774, 43.413356],
                    [-76.642672, 43.401241],
                    [-76.669624, 43.366526],
                    [-76.684856, 43.352691],
                    [-76.69836, 43.344436],
                    [-76.722501, 43.343686],
                    [-76.731039, 43.343421],
                    [-76.747067, 43.331477],
                    [-76.769025, 43.318452],
                    [-76.794708, 43.309632],
                    [-76.841675, 43.305399],
                    [-76.854976, 43.298443],
                    [-76.877397, 43.292926],
                    [-76.886913, 43.293891],
                    [-76.904288, 43.291816],
                    [-76.922351, 43.285006],
                    [-76.952174, 43.270692],
                    [-76.958402, 43.270005],
                    [-76.988445, 43.2745],
                    [-76.999691, 43.271456],
                    [-77.033875, 43.271218],
                    [-77.067295, 43.280937],
                    [-77.111866, 43.287945],
                    [-77.130429, 43.285635],
                    [-77.143416, 43.287561],
                    [-77.173088, 43.281509],
                    [-77.214058, 43.284114],
                    [-77.264177, 43.277363],
                    [-77.303979, 43.27815],
                    [-77.314619, 43.28103],
                    [-77.341092, 43.280661],
                    [-77.376038, 43.277652],
                    [-77.385388, 43.276847],
                    [-77.391015, 43.276363],
                    [-77.414516, 43.269263],
                    [-77.436831, 43.265701],
                    [-77.476642, 43.254522],
                    [-77.50092, 43.250363],
                    [-77.534184, 43.234569],
                    [-77.551022, 43.235763],
                    [-77.577223, 43.243263],
                    [-77.602161, 43.256949],
                    [-77.628315, 43.271303],
                    [-77.653759, 43.279484],
                    [-77.660359, 43.282998],
                    [-77.701429, 43.308261],
                    [-77.714129, 43.323561],
                    [-77.73063, 43.330161],
                    [-77.756931, 43.337361],
                    [-77.760231, 43.341161],
                    [-77.785132, 43.339261],
                    [-77.797381, 43.339857],
                    [-77.816533, 43.34356],
                    [-77.875335, 43.34966],
                    [-77.904836, 43.35696],
                    [-77.922736, 43.35696],
                    [-77.952937, 43.36346],
                    [-77.965238, 43.368059],
                    [-77.976438, 43.369159],
                    [-77.994838, 43.365259],
                    [-77.995591, 43.365293],
                    [-78.023609, 43.366575],
                    [-78.104509, 43.375628],
                    [-78.145195, 43.37551],
                    [-78.233609, 43.36907],
                    [-78.250641, 43.370866],
                    [-78.358711, 43.373988],
                    [-78.370221, 43.376505],
                    [-78.465502, 43.371232],
                    [-78.473099, 43.370812],
                    [-78.482526, 43.374425],
                    [-78.488857, 43.374763],
                    [-78.547395, 43.369541],
                    [-78.634346, 43.357624],
                    [-78.696856, 43.341255],
                    [-78.747158, 43.334555],
                    [-78.777759, 43.327055],
                    [-78.834061, 43.317555],
                    [-78.836261, 43.318455],
                    [-78.859362, 43.310955],
                    [-78.930764, 43.293254],
                    [-78.971866, 43.281254],
                    [-79.019848, 43.273686],
                    [-79.070469, 43.262454],
                    [-79.058399, 43.238924],
                    [-79.042366, 43.143655],
                    [-79.044066, 43.138055],
                    [-79.056767, 43.126855],
                    [-79.066269, 43.09097],
                    [-79.058399, 43.075231],
                    [-79.028653, 43.069474],
                    [-79.028353, 43.066897],
                    [-79.019964, 42.994756],
                    [-79.011563, 42.985256],
                    [-78.972524, 42.966804],
                    [-78.93236, 42.955857],
                    [-78.921206, 42.948422],
                    [-78.918859, 42.946857],
                    [-78.909159, 42.933257],
                    [-78.905659, 42.923357],
                    [-78.905758, 42.899957],
                    [-78.912458, 42.886557],
                    [-78.891655, 42.884845],
                    [-78.882557, 42.867258],
                    [-78.872227, 42.853306],
                    [-78.865592, 42.852358],
                    [-78.859456, 42.841358],
                    [-78.860445, 42.83511],
                    [-78.865656, 42.826758],
                    [-78.863656, 42.813058],
                    [-78.859356, 42.800658],
                    [-78.856456, 42.800258],
                    [-78.851355, 42.791758],
                    [-78.853455, 42.783958],
                    [-78.868556, 42.770258],
                    [-78.918157, 42.737258],
                    [-78.944158, 42.731958],
                    [-78.991159, 42.705358],
                    [-79.00616, 42.704558],
                    [-79.01886, 42.701558],
                    [-79.04886, 42.689158],
                    [-79.062261, 42.668358],
                    [-79.06376, 42.644758],
                    [-79.073261, 42.639958],
                    [-79.078761, 42.640058],
                    [-79.111361, 42.613358],
                    [-79.113713, 42.605994],
                    [-79.121921, 42.594234],
                    [-79.126261, 42.590937],
                    [-79.12963, 42.589824],
                    [-79.136725, 42.569693],
                    [-79.138569, 42.564462],
                    [-79.148723, 42.553672],
                    [-79.193232, 42.545881],
                    [-79.223195, 42.536087],
                    [-79.242889, 42.531757],
                    [-79.264624, 42.523159],
                    [-79.283364, 42.511228],
                    [-79.31774, 42.499884],
                    [-79.323079, 42.494795],
                    [-79.331483, 42.489076],
                    [-79.335129, 42.488321],
                    [-79.342316, 42.489664],
                    [-79.351989, 42.48892],
                    [-79.36213, 42.480195],
                    [-79.381943, 42.466491],
                    [-79.405458, 42.453281],
                    [-79.429119, 42.42838],
                    [-79.453533, 42.411157],
                    [-79.474794, 42.404291],
                    [-79.510999, 42.382373],
                    [-79.546262, 42.363417],
                    [-79.593992, 42.341641],
                    [-79.645358, 42.315631],
                    [-79.717825, 42.284711],
                    [-79.761951, 42.26986],
                    [-79.798447, 42.255939],
                    [-79.844661, 42.235486],
                    [-79.867979, 42.230999],
                    [-79.886187, 42.224933],
                    [-79.90105, 42.216701],
                    [-79.923924, 42.207546],
                    [-79.931324, 42.206737],
                    [-79.989186, 42.177051],
                    [-80.06108, 42.144857],
                    [-80.07198, 42.146057],
                    [-80.076281, 42.147857],
                    [-80.078781, 42.151457],
                    [-80.071981, 42.155357],
                    [-80.080028, 42.163625],
                    [-80.073381, 42.168658],
                    [-80.077388, 42.171262],
                    [-80.088512, 42.173184],
                    [-80.117368, 42.166341],
                    [-80.13043, 42.156331],
                    [-80.136213, 42.149937],
                    [-80.154084, 42.114757],
                    [-80.165884, 42.105857],
                    [-80.188085, 42.094257],
                    [-80.230486, 42.077957],
                    [-80.296758, 42.049076],
                    [-80.329976, 42.036168],
                    [-80.349169, 42.030243],
                    [-80.363251, 42.027973],
                    [-80.371869, 42.023966],
                    [-80.373066, 42.024102],
                    [-80.409776, 42.011578],
                    [-80.435451, 42.005611],
                    [-80.519425, 41.977523],
                    [-80.519425, 41.977522],
                    [-80.519405, 41.976158],
                    [-80.519304, 41.943992],
                    [-80.519345, 41.929168],
                    [-80.519294, 41.849563],
                    [-80.519239, 41.765138],
                    [-80.519369, 41.752487],
                    [-80.519408, 41.739359],
                    [-80.519373, 41.701473],
                    [-80.519424, 41.671228],
                    [-80.519357, 41.669767],
                    [-80.519339, 41.539297],
                    [-80.519157, 41.528769],
                    [-80.519225, 41.499924],
                    [-80.519209, 41.489013],
                    [-80.519169, 41.462581],
                    [-80.518993, 41.435454],
                    [-80.518993, 41.416437],
                    [-80.519025, 41.416438],
                    [-80.519249, 41.378918],
                    [-80.519217, 41.372006],
                    [-80.519249, 41.36103],
                    [-80.519297, 41.350833],
                    [-80.519345, 41.34074],
                    [-80.519345, 41.340145],
                    [-80.519293, 41.339654],
                    [-80.519293, 41.339054],
                    [-80.519311, 41.339052],
                    [-80.519281, 41.337174],
                    [-80.519281, 41.335958],
                    [-80.519265, 41.333495],
                    [-80.519129, 41.312408],
                    [-80.518794, 41.305509],
                    [-80.518996, 41.2683],
                    [-80.518993, 41.268155],
                    [-80.518893, 41.265155],
                    [-80.518693, 41.248855],
                    [-80.518893, 41.232556],
                    [-80.518893, 41.219356],
                    [-80.51883, 41.209213],
                    [-80.519144, 41.171203],
                    [-80.519115, 41.14552],
                    [-80.519167, 41.133388],
                    [-80.519167, 41.133343],
                    [-80.519012, 41.125116],
                    [-80.519012, 41.125057],
                    [-80.519056, 41.125057],
                    [-80.518992, 41.115958],
                    [-80.519192, 41.105358],
                    [-80.519125, 41.100819],
                    [-80.519092, 41.090658],
                    [-80.519088, 41.082074],
                    [-80.518999, 41.075014],
                    [-80.51896, 41.071866],
                    [-80.518928, 41.070954],
                    [-80.51896, 41.061546],
                    [-80.518927, 41.015387],
                    [-80.518989, 40.995445],
                    [-80.519, 40.98738],
                    [-80.519091, 40.921061],
                    [-80.51979, 40.900761],
                    [-80.519764, 40.899858],
                    [-80.519039, 40.851339],
                    [-80.51902, 40.850073],
                    [-80.519081, 40.849157],
                    [-80.518992, 40.801221],
                    [-80.519058, 40.792298],
                    [-80.518991, 40.638801],
                    [-80.56072, 40.62368],
                    [-80.589041, 40.620128],
                    [-80.594065, 40.623664],
                    [-80.601494, 40.625387],
                    [-80.627171, 40.619936],
                    [-80.634355, 40.616095],
                    [-80.665892, 40.587728],
                    [-80.667957, 40.582496],
                    [-80.666917, 40.573664],
                    [-80.610259, 40.490962],
                    [-80.599194, 40.482566],
                    [-80.612295, 40.434867],
                    [-80.617346, 40.396004],
                    [-80.619196, 40.381768],
                    [-80.609313, 40.360468],
                    [-80.599895, 40.317669],
                    [-80.602895, 40.307069],
                    [-80.616696, 40.28027],
                    [-80.637198, 40.25507],
                    [-80.644598, 40.25127],
                    [-80.652098, 40.24497],
                    [-80.685391, 40.187642],
                    [-80.703052, 40.157231],
                    [-80.705482, 40.153047],
                    [-80.726504, 40.089072],
                    [-80.733104, 40.058772],
                    [-80.730904, 40.049172],
                    [-80.731504, 40.037472],
                    [-80.732051, 40.03324],
                    [-80.740126, 39.970793],
                    [-80.743166, 39.969113],
                    [-80.759455, 39.958233],
                    [-80.806018, 39.91713],
                    [-80.808722, 39.91257],
                    [-80.809619, 39.905626],
                    [-80.806179, 39.897306],
                    [-80.812342, 39.848845],
                    [-80.828618, 39.720848],
                    [-80.829764, 39.711839],
                    [-80.831871, 39.705655],
                    [-80.88036, 39.620706],
                    [-80.892208, 39.616756],
                    [-80.943782, 39.606926],
                    [-81.020055, 39.55541],
                    [-81.038691, 39.540464],
                    [-81.063587, 39.520497],
                    [-81.127696, 39.464879],
                    [-81.215617, 39.388602],
                    [-81.223581, 39.386062],
                    [-81.249088, 39.389992],
                    [-81.270716, 39.385914],
                    [-81.295373, 39.37529],
                    [-81.347567, 39.34577],
                    [-81.356911, 39.343178],
                    [-81.371271, 39.342062],
                    [-81.375961, 39.341697],
                    [-81.384556, 39.343449],
                    [-81.393794, 39.351706],
                    [-81.406689, 39.38809],
                    [-81.412706, 39.394618],
                    [-81.435642, 39.408474],
                    [-81.446543, 39.410374],
                    [-81.456143, 39.409274],
                    [-81.467744, 39.403774],
                    [-81.542346, 39.352874],
                    [-81.605816, 39.275851],
                    [-81.695724, 39.242859],
                    [-81.721551, 39.212448],
                    [-81.740061, 39.190652],
                    [-81.749853, 39.186489],
                    [-81.749689, 39.180742],
                    [-81.747253, 39.095379],
                    [-81.747253, 39.095378],
                    [-81.812355, 39.082078],
                    [-81.814155, 39.073478],
                    [-81.811655, 39.059578],
                    [-81.803355, 39.047678],
                    [-81.793304, 39.040353],
                    [-81.772854, 39.026179],
                    [-81.764253, 39.015279],
                    [-81.756131, 38.933545],
                    [-81.759995, 38.925828],
                    [-81.874857, 38.881174],
                    [-81.908645, 38.87846],
                    [-81.926967, 38.891602],
                    [-81.928352, 38.895371],
                    [-81.926671, 38.901311],
                    [-81.90091, 38.924338],
                    [-81.89847, 38.929603],
                    [-81.900595, 38.937671],
                    [-81.919783, 38.968494],
                    [-81.935619, 38.9895],
                    [-82.002261, 39.027878],
                    [-82.017562, 39.030078],
                    [-82.035963, 39.025478],
                    [-82.041563, 39.017878],
                    [-82.045663, 39.003778],
                    [-82.051563, 38.994378],
                    [-82.093165, 38.97098],
                    [-82.098762, 38.958288],
                    [-82.139279, 38.866407],
                    [-82.184428, 38.816529],
                    [-82.191172, 38.815137],
                    [-82.215759, 38.797462],
                    [-82.221566, 38.787187],
                    [-82.220449, 38.773739],
                    [-82.198882, 38.757725],
                    [-82.188268, 38.734082],
                    [-82.182467, 38.708782],
                    [-82.172066, 38.625984],
                    [-82.172066, 38.619284],
                    [-82.175167, 38.608484],
                    [-82.177267, 38.603784],
                    [-82.181967, 38.599384],
                    [-82.193824, 38.593096],
                    [-82.218967, 38.591683],
                    [-82.245969, 38.598483],
                    [-82.26207, 38.598183],
                    [-82.27427, 38.593683],
                    [-82.287102, 38.582588],
                    [-82.291271, 38.578983],
                    [-82.293871, 38.572683],
                    [-82.293271, 38.560283],
                    [-82.295571, 38.539783],
                    [-82.313935, 38.468084],
                    [-82.320223, 38.454516],
                    [-82.323999, 38.449268],
                    [-82.330335, 38.4445],
                    [-82.34064, 38.440948],
                    [-82.50667, 38.410092],
                    [-82.520178, 38.407582],
                    [-82.560664, 38.404338],
                    [-82.593673, 38.421809],
                    [-82.596281, 38.417681],
                    [-82.597113, 38.412881],
                    [-82.595369, 38.382722],
                    [-82.595382, 38.382712],
                    [-82.593008, 38.375082],
                    [-82.571877, 38.315781],
                    [-82.574656, 38.263873],
                    [-82.578254, 38.254809],
                    [-82.584001, 38.246371],
                    [-82.59497, 38.245453],
                    [-82.60423, 38.247303],
                    [-82.644739, 38.165487],
                ],
            ],
            [
                [
                    [-76.095548, 38.125123],
                    [-76.090872, 38.11446],
                    [-76.089166, 38.115221],
                    [-76.089018, 38.115287],
                    [-76.086224, 38.116534],
                    [-76.085947, 38.116658],
                    [-76.085885, 38.116844],
                    [-76.085542, 38.117869],
                    [-76.085311, 38.118561],
                    [-76.08523, 38.118589],
                    [-76.081587, 38.119854],
                    [-76.081127, 38.120014],
                    [-76.064683, 38.125723],
                    [-76.063661, 38.126078],
                    [-76.06147, 38.126839],
                    [-76.061111, 38.126963],
                    [-76.061, 38.127002],
                    [-76.056811, 38.125123],
                    [-76.048373, 38.12055],
                    [-76.043423, 38.117868],
                    [-76.040665, 38.113606],
                    [-76.039696, 38.112108],
                    [-76.03962, 38.11199],
                    [-76.040874, 38.110906],
                    [-76.041083, 38.110726],
                    [-76.042083, 38.109862],
                    [-76.044482, 38.109237],
                    [-76.045279, 38.109029],
                    [-76.046494, 38.108712],
                    [-76.047291, 38.108505],
                    [-76.050156, 38.107758],
                    [-76.059304, 38.095751],
                    [-76.05831, 38.094906],
                    [-76.052207, 38.089716],
                    [-76.036676, 38.076509],
                    [-76.0233, 38.07076],
                    [-76.015235, 38.071825],
                    [-76.012701, 38.072159],
                    [-76.011544, 38.072312],
                    [-76.005904, 38.07717],
                    [-76.008168, 38.095385],
                    [-76.015477, 38.102332],
                    [-76.017394, 38.104154],
                    [-76.020996, 38.107578],
                    [-76.02137, 38.107934],
                    [-76.021353, 38.108108],
                    [-76.021305, 38.108608],
                    [-76.020496, 38.117044],
                    [-76.015702, 38.119933],
                    [-76.014698, 38.120537],
                    [-76.013363, 38.121342],
                    [-76.011916, 38.122214],
                    [-76.012487, 38.131731],
                    [-76.013903, 38.131634],
                    [-76.015155, 38.131548],
                    [-76.01779, 38.131367],
                    [-76.018574, 38.131713],
                    [-76.019637, 38.132183],
                    [-76.020476, 38.132553],
                    [-76.020982, 38.132776],
                    [-76.022515, 38.133453],
                    [-76.031442, 38.147612],
                    [-76.031868, 38.148288],
                    [-76.031945, 38.148631],
                    [-76.033978, 38.157636],
                    [-76.034038, 38.157902],
                    [-76.032816, 38.169141],
                    [-76.032767, 38.169154],
                    [-76.025892, 38.170946],
                    [-76.021941, 38.171976],
                    [-76.02217, 38.177882],
                    [-76.025899, 38.181504],
                    [-76.0269, 38.182476],
                    [-76.028411, 38.183943],
                    [-76.02858, 38.184107],
                    [-76.036757, 38.192049],
                    [-76.038929, 38.194158],
                    [-76.040718, 38.195896],
                    [-76.041952, 38.197094],
                    [-76.04228, 38.197412],
                    [-76.042385, 38.197515],
                    [-76.042615, 38.197737],
                    [-76.042691, 38.197811],
                    [-76.046273, 38.20129],
                    [-76.046539, 38.201549],
                    [-76.048786, 38.203731],
                    [-76.048945, 38.203729],
                    [-76.050511, 38.203714],
                    [-76.052165, 38.203697],
                    [-76.053753, 38.203681],
                    [-76.058925, 38.203629],
                    [-76.05902, 38.203628],
                    [-76.059158, 38.203626],
                    [-76.059218, 38.203626],
                    [-76.059562, 38.203622],
                    [-76.059731, 38.203621],
                    [-76.061906, 38.203599],
                    [-76.066994, 38.203547],
                    [-76.0671, 38.203546],
                    [-76.067797, 38.203539],
                    [-76.07147, 38.203502],
                    [-76.088639, 38.192649],
                    [-76.092334, 38.151355],
                    [-76.090216, 38.144765],
                    [-76.089918, 38.143837],
                    [-76.089017, 38.141033],
                    [-76.090527, 38.131919],
                    [-76.09064, 38.13124],
                    [-76.090649, 38.131185],
                    [-76.095548, 38.125123],
                ],
            ],
            [
                [
                    [-76.049608, 37.983628],
                    [-76.04653, 37.953586],
                    [-76.045561, 37.953669],
                    [-76.041691, 37.954],
                    [-76.041402, 37.954006],
                    [-76.038026, 37.953901],
                    [-76.030122, 37.953655],
                    [-76.029463, 37.953775],
                    [-76.029405, 37.953776],
                    [-76.022325, 37.953878],
                    [-76.021714, 37.953887],
                    [-76.020932, 37.953879],
                    [-76.020796, 37.953877],
                    [-76.017686, 37.953832],
                    [-76.005888, 37.953662],
                    [-75.994739, 37.953501],
                    [-75.993905, 37.953489],
                    [-75.988879, 37.960337],
                    [-75.991543, 37.966874],
                    [-75.992789, 37.969932],
                    [-75.99473, 37.974693],
                    [-75.99473, 37.974694],
                    [-75.992468, 37.981021],
                    [-75.989101, 37.99044],
                    [-75.988719, 37.991509],
                    [-75.988541, 37.992006],
                    [-75.986571, 37.997516],
                    [-75.986249, 37.998416],
                    [-75.985723, 37.999889],
                    [-75.98502, 38.001855],
                    [-75.984675, 38.002821],
                    [-75.984479, 38.003369],
                    [-75.984251, 38.004006],
                    [-75.98288, 38.004236],
                    [-75.980683, 38.004603],
                    [-75.979879, 38.004738],
                    [-75.970917, 38.006239],
                    [-75.97039, 38.006327],
                    [-75.970345, 38.008222],
                    [-75.970178, 38.01526],
                    [-75.970168, 38.015683],
                    [-75.970168, 38.015685],
                    [-75.970172, 38.015688],
                    [-75.972304, 38.01775],
                    [-75.973432, 38.018841],
                    [-75.975487, 38.020827],
                    [-75.975494, 38.020834],
                    [-75.977246, 38.021334],
                    [-75.981195, 38.02246],
                    [-75.991846, 38.025497],
                    [-76.004592, 38.036805],
                    [-76.008695, 38.038226],
                    [-76.008835, 38.038275],
                    [-76.013128, 38.039762],
                    [-76.020827, 38.039124],
                    [-76.041668, 38.032148],
                    [-76.046213, 38.025533],
                    [-76.048617, 38.014843],
                    [-76.049396, 37.990315],
                    [-76.049527, 37.986184],
                    [-76.049549, 37.985495],
                    [-76.049562, 37.985065],
                    [-76.049608, 37.983628],
                ],
            ],
            [
                [
                    [-75.579615, 39.598656],
                    [-75.578719, 39.591504],
                    [-75.576271, 39.588144],
                    [-75.564927, 39.583248],
                    [-75.565823, 39.590608],
                    [-75.579615, 39.598656],
                ],
            ],
            [
                [
                    [-74.256088, 40.507903],
                    [-74.254588, 40.502303],
                    [-74.250188, 40.496703],
                    [-74.246688, 40.496103],
                    [-74.23324, 40.501299],
                    [-74.219787, 40.502603],
                    [-74.210474, 40.509448],
                    [-74.199923, 40.511729],
                    [-74.182157, 40.520634],
                    [-74.177986, 40.519603],
                    [-74.160859, 40.52679],
                    [-74.148697, 40.534489],
                    [-74.144428, 40.53516],
                    [-74.14023, 40.533738],
                    [-74.137241, 40.530076],
                    [-74.121672, 40.542691],
                    [-74.112585, 40.547603],
                    [-74.111471, 40.546908],
                    [-74.090797, 40.566463],
                    [-74.068184, 40.584102],
                    [-74.059184, 40.593502],
                    [-74.053125, 40.603678],
                    [-74.060345, 40.611999],
                    [-74.067598, 40.623865],
                    [-74.0697, 40.641216],
                    [-74.075884, 40.648101],
                    [-74.086485, 40.648601],
                    [-74.120186, 40.642201],
                    [-74.152973, 40.638886],
                    [-74.170187, 40.642201],
                    [-74.174085, 40.645109],
                    [-74.180191, 40.645521],
                    [-74.1894, 40.642121],
                    [-74.20058, 40.631448],
                    [-74.201812, 40.619507],
                    [-74.200994, 40.616906],
                    [-74.196096, 40.616169],
                    [-74.195407, 40.601806],
                    [-74.19682, 40.597037],
                    [-74.204054, 40.589336],
                    [-74.210887, 40.560902],
                    [-74.216997, 40.554991],
                    [-74.229002, 40.555041],
                    [-74.247808, 40.543396],
                    [-74.241732, 40.531273],
                    [-74.242888, 40.520903],
                    [-74.252702, 40.513895],
                    [-74.256088, 40.507903],
                ],
            ],
            [
                [
                    [-74.047313, 40.690466],
                    [-74.046359, 40.689175],
                    [-74.044451, 40.688445],
                    [-74.043441, 40.68968],
                    [-74.037998, 40.698995],
                    [-74.039401, 40.700454],
                    [-74.040018, 40.700678],
                    [-74.04086, 40.700117],
                    [-74.04692, 40.691139],
                    [-74.047313, 40.690466],
                ],
            ],
            [
                [
                    [-73.775276, 40.882199],
                    [-73.770876, 40.879299],
                    [-73.766976, 40.880099],
                    [-73.766276, 40.881099],
                    [-73.767076, 40.883499],
                    [-73.767076, 40.885399],
                    [-73.767176, 40.886299],
                    [-73.767276, 40.886899],
                    [-73.768276, 40.887599],
                    [-73.770576, 40.888399],
                    [-73.772276, 40.887499],
                    [-73.772776, 40.884599],
                    [-73.775176, 40.884199],
                    [-73.775276, 40.882199],
                ],
            ],
            [
                [
                    [-73.773717, 40.854831],
                    [-73.773038, 40.848125],
                    [-73.769648, 40.84466],
                    [-73.766032, 40.844961],
                    [-73.765128, 40.854228],
                    [-73.766333, 40.857317],
                    [-73.770552, 40.86033],
                    [-73.773361, 40.859449],
                    [-73.773717, 40.854831],
                ],
            ],
            [
                [
                    [-72.142929, 41.097811],
                    [-72.141921, 41.094371],
                    [-72.139233, 41.092451],
                    [-72.12056, 41.093171],
                    [-72.1064, 41.088883],
                    [-72.103152, 41.086484],
                    [-72.097136, 41.075844],
                    [-72.0972, 41.054884],
                    [-72.095711, 41.05402],
                    [-72.086975, 41.058292],
                    [-72.081167, 41.09394],
                    [-72.084207, 41.101524],
                    [-72.126704, 41.115139],
                    [-72.128352, 41.108131],
                    [-72.132225, 41.104387],
                    [-72.140737, 41.100835],
                    [-72.142929, 41.097811],
                ],
            ],
            [
                [
                    [-72.036846, 41.249794],
                    [-72.002461, 41.252867],
                    [-71.994717, 41.256451],
                    [-71.978926, 41.265002],
                    [-71.962598, 41.270968],
                    [-71.94627, 41.276306],
                    [-71.935259, 41.280579],
                    [-71.926802, 41.290122],
                    [-71.943563, 41.286675],
                    [-71.952864, 41.285098],
                    [-71.980061, 41.280291],
                    [-71.991117, 41.281331],
                    [-72.006872, 41.27348],
                    [-72.018926, 41.274114],
                    [-72.023422, 41.270994],
                    [-72.029438, 41.26309],
                    [-72.034958, 41.255458],
                    [-72.036846, 41.249794],
                ],
            ],
            [
                [
                    [-71.613133, 41.160281],
                    [-71.611706, 41.153239],
                    [-71.599993, 41.146932],
                    [-71.5937, 41.146339],
                    [-71.551953, 41.151718],
                    [-71.547051, 41.153684],
                    [-71.543872, 41.161321],
                    [-71.544446, 41.164912],
                    [-71.550226, 41.166787],
                    [-71.560969, 41.176186],
                    [-71.565752, 41.184373],
                    [-71.564119, 41.195372],
                    [-71.557459, 41.204542],
                    [-71.554067, 41.212957],
                    [-71.555006, 41.216822],
                    [-71.561093, 41.224207],
                    [-71.573785, 41.228436],
                    [-71.576661, 41.224434],
                    [-71.577301, 41.21471],
                    [-71.580228, 41.204837],
                    [-71.58955, 41.196557],
                    [-71.594994, 41.188392],
                    [-71.605565, 41.182139],
                    [-71.613133, 41.160281],
                ],
            ],
            [
                [
                    [-71.40056, 41.46094],
                    [-71.399568, 41.448596],
                    [-71.390275, 41.455043],
                    [-71.389284, 41.460605],
                    [-71.383586, 41.464782],
                    [-71.380947, 41.474561],
                    [-71.360403, 41.483121],
                    [-71.363292, 41.501952],
                    [-71.359868, 41.556308],
                    [-71.36356, 41.57086],
                    [-71.370194, 41.573963],
                    [-71.373618, 41.573214],
                    [-71.379021, 41.567772],
                    [-71.384478, 41.556736],
                    [-71.392137, 41.524468],
                    [-71.391005, 41.514578],
                    [-71.378914, 41.504948],
                    [-71.386511, 41.493071],
                    [-71.395927, 41.492215],
                    [-71.40056, 41.46094],
                ],
            ],
            [
                [
                    [-71.366165, 41.66098],
                    [-71.362869, 41.651457],
                    [-71.34657, 41.632229],
                    [-71.333305, 41.629536],
                    [-71.325877, 41.623988],
                    [-71.326609, 41.616114],
                    [-71.329559, 41.609097],
                    [-71.333751, 41.605859],
                    [-71.337048, 41.594688],
                    [-71.335949, 41.585898],
                    [-71.3312, 41.580318],
                    [-71.326103, 41.578583],
                    [-71.317474, 41.583187],
                    [-71.307381, 41.597984],
                    [-71.303352, 41.606591],
                    [-71.30555, 41.622523],
                    [-71.314889, 41.630398],
                    [-71.330711, 41.632992],
                    [-71.343666, 41.6399],
                    [-71.342514, 41.644791],
                    [-71.337048, 41.646146],
                    [-71.336182, 41.647961],
                    [-71.338696, 41.658782],
                    [-71.348402, 41.663727],
                    [-71.366165, 41.66098],
                ],
            ],
            [
                [
                    [-71.343013, 41.495615],
                    [-71.327822, 41.482985],
                    [-71.325365, 41.487601],
                    [-71.326769, 41.491286],
                    [-71.341122, 41.498598],
                    [-71.343013, 41.495615],
                ],
            ],
            [
                [
                    [-71.286755, 41.642725],
                    [-71.283791, 41.637797],
                    [-71.274315, 41.638125],
                    [-71.278171, 41.647309],
                    [-71.281571, 41.648207],
                    [-71.283005, 41.644434],
                    [-71.286755, 41.642725],
                ],
            ],
            [
                [
                    [-70.838777, 41.347209],
                    [-70.819415, 41.327212],
                    [-70.802083, 41.314207],
                    [-70.775665, 41.300982],
                    [-70.768687, 41.303702],
                    [-70.766166, 41.308962],
                    [-70.768015, 41.311959],
                    [-70.764188, 41.318706],
                    [-70.747541, 41.329952],
                    [-70.733253, 41.336226],
                    [-70.709826, 41.341723],
                    [-70.599157, 41.349272],
                    [-70.538294, 41.348958],
                    [-70.496162, 41.346452],
                    [-70.451084, 41.348161],
                    [-70.448262, 41.353651],
                    [-70.449268, 41.380422],
                    [-70.446233, 41.39648],
                    [-70.450431, 41.420703],
                    [-70.463833, 41.419145],
                    [-70.470788, 41.412875],
                    [-70.473035, 41.408757],
                    [-70.472604, 41.399128],
                    [-70.484503, 41.38629],
                    [-70.490758, 41.383634],
                    [-70.498959, 41.384339],
                    [-70.501306, 41.385391],
                    [-70.502372, 41.392005],
                    [-70.506984, 41.400242],
                    [-70.517584, 41.403769],
                    [-70.528581, 41.4051],
                    [-70.538301, 41.409241],
                    [-70.547567, 41.415831],
                    [-70.553096, 41.423952],
                    [-70.555588, 41.430882],
                    [-70.552943, 41.443394],
                    [-70.553277, 41.452955],
                    [-70.56328, 41.469127],
                    [-70.567356, 41.471208],
                    [-70.57485, 41.468259],
                    [-70.59628, 41.471905],
                    [-70.598444, 41.481151],
                    [-70.603555, 41.482384],
                    [-70.64933, 41.461068],
                    [-70.686881, 41.441334],
                    [-70.701378, 41.430925],
                    [-70.711493, 41.41546],
                    [-70.712432, 41.40885],
                    [-70.724366, 41.398942],
                    [-70.729225, 41.397728],
                    [-70.768901, 41.353246],
                    [-70.774974, 41.349176],
                    [-70.783291, 41.347829],
                    [-70.800289, 41.3538],
                    [-70.812309, 41.355745],
                    [-70.833802, 41.353386],
                    [-70.838777, 41.347209],
                ],
            ],
            [
                [
                    [-70.838452, 41.59646],
                    [-70.838147, 41.596056],
                    [-70.837632, 41.595374],
                    [-70.830087, 41.585385],
                    [-70.82191, 41.582841],
                    [-70.821743, 41.583656],
                    [-70.821001, 41.587268],
                    [-70.820918, 41.587673],
                    [-70.823735, 41.598569],
                    [-70.827398, 41.602067],
                    [-70.828025, 41.602666],
                    [-70.831802, 41.606272],
                    [-70.832044, 41.606504],
                    [-70.834529, 41.60261],
                    [-70.838452, 41.59646],
                ],
            ],
            [
                [
                    [-70.275526, 41.310464],
                    [-70.273478, 41.301528],
                    [-70.266776, 41.294453],
                    [-70.256164, 41.288123],
                    [-70.237175, 41.282724],
                    [-70.170681, 41.255881],
                    [-70.118669, 41.242351],
                    [-70.096967, 41.24085],
                    [-70.083239, 41.2444],
                    [-70.052807, 41.242685],
                    [-70.015225, 41.237964],
                    [-70.001586, 41.239353],
                    [-69.975, 41.247392],
                    [-69.965725, 41.252466],
                    [-69.964422, 41.25457],
                    [-69.960181, 41.264546],
                    [-69.960277, 41.278731],
                    [-70.018446, 41.36863],
                    [-70.033514, 41.385816],
                    [-70.049053, 41.391702],
                    [-70.049564, 41.3879],
                    [-70.045586, 41.383598],
                    [-70.038458, 41.376399],
                    [-70.035162, 41.372161],
                    [-70.030924, 41.367453],
                    [-70.028805, 41.359919],
                    [-70.031332, 41.339332],
                    [-70.046088, 41.321651],
                    [-70.062565, 41.308726],
                    [-70.082072, 41.299093],
                    [-70.092142, 41.297741],
                    [-70.12446, 41.293851],
                    [-70.196304, 41.294612],
                    [-70.20869, 41.290171],
                    [-70.229541, 41.290171],
                    [-70.240153, 41.295384],
                    [-70.244435, 41.303203],
                    [-70.249276, 41.305623],
                    [-70.260632, 41.310092],
                    [-70.275526, 41.310464],
                ],
            ],
            [
                [
                    [-70.213948, 43.666161],
                    [-70.21313, 43.662973],
                    [-70.210825, 43.661695],
                    [-70.207341, 43.662562],
                    [-70.192574, 43.673139],
                    [-70.187536, 43.678669],
                    [-70.186213, 43.682655],
                    [-70.191041, 43.689071],
                    [-70.196535, 43.688784],
                    [-70.201893, 43.685483],
                    [-70.209627, 43.676308],
                    [-70.213948, 43.666161],
                ],
            ],
            [
                [
                    [-70.211062, 43.641842],
                    [-70.207165, 43.63369],
                    [-70.205934, 43.633633],
                    [-70.190323, 43.642986],
                    [-70.171136, 43.6633],
                    [-70.171245, 43.663498],
                    [-70.184659, 43.674699],
                    [-70.188047, 43.673762],
                    [-70.200116, 43.662978],
                    [-70.211062, 43.641842],
                ],
            ],
            [
                [
                    [-70.173571, 43.683734],
                    [-70.170284, 43.675441],
                    [-70.168227, 43.675136],
                    [-70.154503, 43.680933],
                    [-70.135563, 43.700658],
                    [-70.146115, 43.701635],
                    [-70.156787, 43.694706],
                    [-70.163884, 43.692404],
                    [-70.171339, 43.687546],
                    [-70.173571, 43.683734],
                ],
            ],
            [
                [
                    [-70.138711, 43.727559],
                    [-70.138128, 43.718231],
                    [-70.129383, 43.70832],
                    [-70.124136, 43.70832],
                    [-70.108978, 43.722312],
                    [-70.109561, 43.730474],
                    [-70.107812, 43.734555],
                    [-70.100233, 43.742134],
                    [-70.094986, 43.753211],
                    [-70.097318, 43.757292],
                    [-70.113059, 43.74913],
                    [-70.119671, 43.748621],
                    [-70.127051, 43.742717],
                    [-70.138711, 43.727559],
                ],
            ],
            [
                [
                    [-70.118291, 43.683343],
                    [-70.115908, 43.682978],
                    [-70.099594, 43.695366],
                    [-70.093704, 43.6918],
                    [-70.087621, 43.699913],
                    [-70.091929, 43.698111],
                    [-70.097184, 43.700929],
                    [-70.092137, 43.709468],
                    [-70.093113, 43.710524],
                    [-70.095727, 43.709278],
                    [-70.100683, 43.705962],
                    [-70.115961, 43.689202],
                    [-70.118174, 43.686375],
                    [-70.118291, 43.683343],
                ],
            ],
            [
                [
                    [-69.441894, 43.916331],
                    [-69.440913, 43.909767],
                    [-69.438066, 43.909539],
                    [-69.423324, 43.915507],
                    [-69.422153, 43.917702],
                    [-69.423323, 43.922871],
                    [-69.42792, 43.928798],
                    [-69.421072, 43.938261],
                    [-69.429806, 43.948438],
                    [-69.433762, 43.949353],
                    [-69.441894, 43.916331],
                ],
            ],
            [
                [
                    [-69.323569, 43.758994],
                    [-69.322559, 43.755883],
                    [-69.314325, 43.756707],
                    [-69.300818, 43.768599],
                    [-69.300376, 43.772144],
                    [-69.302995, 43.774591],
                    [-69.306751, 43.775095],
                    [-69.307908, 43.773767],
                    [-69.313631, 43.772762],
                    [-69.321141, 43.765763],
                    [-69.323569, 43.758994],
                ],
            ],
            [
                [
                    [-69.092994, 43.979063],
                    [-69.0782, 43.974132],
                    [-69.043981, 44.005684],
                    [-69.047583, 44.013995],
                    [-69.061966, 44.015023],
                    [-69.092994, 43.979063],
                ],
            ],
            [
                [
                    [-68.967074, 44.251968],
                    [-68.965896, 44.249754],
                    [-68.959468, 44.247439],
                    [-68.955332, 44.243873],
                    [-68.94709, 44.226792],
                    [-68.95189, 44.218719],
                    [-68.945976, 44.220824],
                    [-68.92648, 44.233035],
                    [-68.916872, 44.242866],
                    [-68.904255, 44.279889],
                    [-68.899445, 44.283775],
                    [-68.88746, 44.303094],
                    [-68.896587, 44.321986],
                    [-68.89285, 44.334653],
                    [-68.888706, 44.338196],
                    [-68.883065, 44.338193],
                    [-68.87169, 44.344662],
                    [-68.864338, 44.355002],
                    [-68.860649, 44.364425],
                    [-68.868444, 44.38144],
                    [-68.87894, 44.386584],
                    [-68.90353, 44.378613],
                    [-68.911634, 44.365027],
                    [-68.919325, 44.335392],
                    [-68.919301, 44.309872],
                    [-68.942826, 44.281073],
                    [-68.953686, 44.272346],
                    [-68.965264, 44.259332],
                    [-68.967074, 44.251968],
                ],
            ],
            [
                [
                    [-68.944597, 44.11284],
                    [-68.943105, 44.10973],
                    [-68.908984, 44.110001],
                    [-68.907812, 44.105518],
                    [-68.913406, 44.08519],
                    [-68.905098, 44.077344],
                    [-68.899997, 44.06696],
                    [-68.889717, 44.032516],
                    [-68.874139, 44.025359],
                    [-68.862845, 44.025037],
                    [-68.828465, 44.032118],
                    [-68.818441, 44.032046],
                    [-68.807315, 44.035796],
                    [-68.790595, 44.053832],
                    [-68.785601, 44.053503],
                    [-68.77965, 44.057754],
                    [-68.77029, 44.069566],
                    [-68.772639, 44.078439],
                    [-68.781772, 44.084274],
                    [-68.790525, 44.09292],
                    [-68.806832, 44.116339],
                    [-68.815562, 44.115836],
                    [-68.81953, 44.122056],
                    [-68.820515, 44.130198],
                    [-68.819659, 44.135434],
                    [-68.818039, 44.136852],
                    [-68.802162, 44.137857],
                    [-68.792065, 44.136759],
                    [-68.780693, 44.143274],
                    [-68.782375, 44.14531],
                    [-68.786886, 44.143961],
                    [-68.792221, 44.145998],
                    [-68.818423, 44.160978],
                    [-68.82284, 44.173693],
                    [-68.822206, 44.178815],
                    [-68.819156, 44.180462],
                    [-68.825067, 44.186338],
                    [-68.847249, 44.183017],
                    [-68.87868, 44.166612],
                    [-68.888597, 44.15955],
                    [-68.917286, 44.148239],
                    [-68.935327, 44.13038],
                    [-68.944597, 44.11284],
                ],
            ],
            [
                [
                    [-68.908222, 43.849852],
                    [-68.903957, 43.848684],
                    [-68.898045, 43.849545],
                    [-68.8947, 43.84397],
                    [-68.893537, 43.831498],
                    [-68.899108, 43.821925],
                    [-68.898319, 43.820987],
                    [-68.886725, 43.822195],
                    [-68.876224, 43.836597],
                    [-68.882554, 43.848525],
                    [-68.880242, 43.863398],
                    [-68.889367, 43.87553],
                    [-68.902618, 43.868855],
                    [-68.908222, 43.849852],
                ],
            ],
            [
                [
                    [-68.839422, 44.236547],
                    [-68.837849, 44.227112],
                    [-68.829593, 44.21689],
                    [-68.822909, 44.216496],
                    [-68.815439, 44.214137],
                    [-68.809149, 44.212565],
                    [-68.801285, 44.208633],
                    [-68.789884, 44.203915],
                    [-68.780055, 44.203129],
                    [-68.769047, 44.213351],
                    [-68.769833, 44.222787],
                    [-68.792139, 44.237819],
                    [-68.825631, 44.242556],
                    [-68.827627, 44.242838],
                    [-68.833524, 44.240872],
                    [-68.839422, 44.236547],
                ],
            ],
            [
                [
                    [-68.722956, 44.219607],
                    [-68.721156, 44.212404],
                    [-68.714313, 44.20376],
                    [-68.715033, 44.191154],
                    [-68.718995, 44.183231],
                    [-68.720435, 44.169185],
                    [-68.716474, 44.162702],
                    [-68.713232, 44.160541],
                    [-68.709631, 44.158741],
                    [-68.700987, 44.15838],
                    [-68.692343, 44.153698],
                    [-68.686581, 44.147216],
                    [-68.681899, 44.138212],
                    [-68.675056, 44.137131],
                    [-68.671454, 44.138572],
                    [-68.670014, 44.151537],
                    [-68.652366, 44.153698],
                    [-68.643002, 44.15766],
                    [-68.618872, 44.18107],
                    [-68.618511, 44.186472],
                    [-68.619592, 44.189354],
                    [-68.625715, 44.194756],
                    [-68.624994, 44.197637],
                    [-68.612749, 44.207722],
                    [-68.605906, 44.230772],
                    [-68.611669, 44.244818],
                    [-68.623554, 44.255622],
                    [-68.658849, 44.268588],
                    [-68.668213, 44.276511],
                    [-68.675416, 44.279753],
                    [-68.680773, 44.279242],
                    [-68.677577, 44.275431],
                    [-68.677577, 44.268948],
                    [-68.680458, 44.262105],
                    [-68.694144, 44.248779],
                    [-68.700627, 44.234013],
                    [-68.711792, 44.228971],
                    [-68.718635, 44.228611],
                    [-68.722956, 44.223568],
                    [-68.722956, 44.219607],
                ],
            ],
            [
                [
                    [-68.661594, 44.075837],
                    [-68.654783, 44.059599],
                    [-68.650767, 44.039908],
                    [-68.657369, 44.024404],
                    [-68.659874, 44.022758],
                    [-68.659972, 44.016013],
                    [-68.657031, 44.003823],
                    [-68.652881, 44.003845],
                    [-68.651863, 44.009859],
                    [-68.64736, 44.0145],
                    [-68.635315, 44.018886],
                    [-68.618212, 44.012367],
                    [-68.615896, 44.009761],
                    [-68.610703, 44.013422],
                    [-68.611473, 44.025176],
                    [-68.601099, 44.058362],
                    [-68.590792, 44.058662],
                    [-68.588098, 44.06127],
                    [-68.584074, 44.070578],
                    [-68.585916, 44.075335],
                    [-68.589563, 44.075585],
                    [-68.602863, 44.08665],
                    [-68.609722, 44.094674],
                    [-68.6181, 44.096706],
                    [-68.62535, 44.092906],
                    [-68.627893, 44.088128],
                    [-68.661594, 44.075837],
                ],
            ],
            [
                [
                    [-68.530394, 44.333583],
                    [-68.515173, 44.324797],
                    [-68.489641, 44.313705],
                    [-68.478785, 44.319563],
                    [-68.498637, 44.369686],
                    [-68.501364, 44.382281],
                    [-68.50766, 44.385219],
                    [-68.512697, 44.384799],
                    [-68.518573, 44.381022],
                    [-68.52193, 44.367591],
                    [-68.520671, 44.358357],
                    [-68.528635, 44.344605],
                    [-68.530394, 44.333583],
                ],
            ],
            [
                [
                    [-68.518703, 44.113222],
                    [-68.51706, 44.10341],
                    [-68.502942, 44.099722],
                    [-68.491521, 44.109833],
                    [-68.492892, 44.116921],
                    [-68.499465, 44.12419],
                    [-68.506979, 44.127237],
                    [-68.511266, 44.125082],
                    [-68.518703, 44.113222],
                ],
            ],
            [
                [
                    [-68.502096, 44.152388],
                    [-68.496639, 44.146855],
                    [-68.484696, 44.146495],
                    [-68.479934, 44.1478],
                    [-68.456813, 44.145268],
                    [-68.447505, 44.133493],
                    [-68.448646, 44.125581],
                    [-68.438518, 44.11618],
                    [-68.427534, 44.119266],
                    [-68.396634, 44.14069],
                    [-68.384903, 44.154955],
                    [-68.408207, 44.176298],
                    [-68.416434, 44.187047],
                    [-68.424441, 44.190753],
                    [-68.437789, 44.188216],
                    [-68.453236, 44.189998],
                    [-68.474365, 44.181875],
                    [-68.495511, 44.162429],
                    [-68.500817, 44.160026],
                    [-68.502096, 44.152388],
                ],
            ],
            [
                [
                    [-68.487227, 44.209517],
                    [-68.48452, 44.202886],
                    [-68.459182, 44.197681],
                    [-68.454224, 44.199534],
                    [-68.453843, 44.201683],
                    [-68.460205, 44.212498],
                    [-68.472831, 44.219767],
                    [-68.468572, 44.223999],
                    [-68.470323, 44.22832],
                    [-68.480565, 44.228591],
                    [-68.482726, 44.227058],
                    [-68.487227, 44.209517],
                ],
            ],
            [
                [
                    [-68.378872, 44.184222],
                    [-68.347416, 44.169459],
                    [-68.339029, 44.171839],
                    [-68.332639, 44.192131],
                    [-68.321178, 44.199032],
                    [-68.318476, 44.196608],
                    [-68.314789, 44.197157],
                    [-68.31606, 44.200244],
                    [-68.32423, 44.205732],
                    [-68.333227, 44.207308],
                    [-68.355279, 44.199096],
                    [-68.364469, 44.197534],
                    [-68.371235, 44.193003],
                    [-68.378872, 44.184222],
                ],
            ],
            [
                [
                    [-68.376593, 44.112207],
                    [-68.365176, 44.101464],
                    [-68.338012, 44.101473],
                    [-68.331032, 44.10758],
                    [-68.330716, 44.110598],
                    [-68.346724, 44.127749],
                    [-68.35301, 44.127884],
                    [-68.358388, 44.125082],
                    [-68.365514, 44.124079],
                    [-68.375382, 44.11646],
                    [-68.376591, 44.113762],
                    [-68.376593, 44.112207],
                ],
            ],
            [
                [
                    [-68.276857, 44.240794],
                    [-68.274427, 44.237099],
                    [-68.266669, 44.234033],
                    [-68.248913, 44.235443],
                    [-68.240806, 44.239723],
                    [-68.241545, 44.247543],
                    [-68.24031, 44.251622],
                    [-68.23713, 44.25343],
                    [-68.231469, 44.256016],
                    [-68.221383, 44.257254],
                    [-68.212474, 44.255405],
                    [-68.211329, 44.257074],
                    [-68.214641, 44.263156],
                    [-68.23638, 44.266254],
                    [-68.241142, 44.260354],
                    [-68.246598, 44.257836],
                    [-68.254153, 44.257836],
                    [-68.262128, 44.260774],
                    [-68.274719, 44.258675],
                    [-68.276857, 44.240794],
                ],
            ],
            [
                [
                    [-67.619761, 44.519754],
                    [-67.619211, 44.506009],
                    [-67.614954, 44.503576],
                    [-67.607199, 44.503576],
                    [-67.604919, 44.502056],
                    [-67.592755, 44.458572],
                    [-67.588346, 44.449754],
                    [-67.574206, 44.45173],
                    [-67.571774, 44.453403],
                    [-67.569189, 44.455531],
                    [-67.562651, 44.472104],
                    [-67.580288, 44.488068],
                    [-67.590627, 44.49415],
                    [-67.589259, 44.50084],
                    [-67.582113, 44.513459],
                    [-67.587738, 44.516196],
                    [-67.61541, 44.521973],
                    [-67.619761, 44.519754],
                ],
            ],
        ],
    },
}
