import app from 'firebase/app'

import { generateUUID } from 'code/UUID'

export class PointRecord {
    /*
    id - string
    name - string
    description - string
    status - integer: 0 = awaiting approval, 1 = approved, 2 = denied
    dt - firebase timestamp
    points - integer: goal if points type = 1
    */

    static getStatusOptions() {
        return {
            0: {
                title: 'Awaiting',
                default: false,
            },
            1: {
                title: 'Approved',
                default: true,
            },
            2: {
                title: 'Denied',
                default: false,
            },
        }
    }

    static get STATUS_AWAITING() {
        return 0
    }

    static get STATUS_APPROVED() {
        return 1
    }

    static get STATUS_DENIED() {
        return 2
    }

    constructor(record) {
        this.id = record.id
        this.trigger = record.trigger
        this.event = record.event
        this.name = record.name
        this.description = record.description
        this.status = record.status
        this.dt = record.dt
        this.points = record.points
        this.attachment = record.attachment
    }

    static convertArrayToRaw(arr) {
        let a = []

        for (let i = 0; i < arr.length; i++) {
            a.push(arr[i].getRaw())
        }

        return a
    }

    getRaw() {
        let data = {
            id: this.id,
            name: this.name,
            description: this.description,
            status: this.status,
            dt: this.dt,
            points: this.points,
        }

        if (this.attachment) {
            data.attachment = this.attachment
        }

        if (this.event) {
            data.event = this.event
        }

        if (this.trigger) {
            data.trigger = this.trigger
        }

        return data
    }

    static getNew() {
        return new PointRecord({
            id: generateUUID(),
            name: '',
            description: '',
            status: PointRecord.STATUS_AWAITING,
            dt: app.firestore.Timestamp.fromDate(new Date()),
            points: 0,
        })
    }

    getId() {
        return this.id
    }

    setId(id) {
        this.id = id
    }

    setName(name) {
        this.name = name
    }

    getName() {
        return this.name
    }

    setDescription(description) {
        this.description = description
    }

    getDescription() {
        return this.description
    }

    setStatus(status) {
        this.status = status
    }

    setStatusFromString(status) {
        if (status === 'Awaiting' || status === '0') {
            this.status = PointRecord.STATUS_AWAITING
        } else if (status === 'Approved' || status === '1') {
            this.status = PointRecord.STATUS_APPROVED
        } else if (status === 'Denied' || status === '2') {
            this.status = PointRecord.STATUS_DENIED
        }
    }

    getStatus() {
        return this.status
    }

    getStatusString() {
        if (this.status === PointRecord.STATUS_AWAITING) {
            return 'Awaiting'
        }

        if (this.status === PointRecord.STATUS_APPROVED) {
            return 'Approved'
        }

        return 'Denied'
    }

    arePointsCounted() {
        if (this.status === PointRecord.STATUS_APPROVED) {
            return true
        }

        return false
    }

    setDT(dt) {
        this.dt = dt
    }

    setDateFromMoment(m) {
        this.dt = app.firestore.Timestamp.fromDate(m.toDate())
    }

    getDT() {
        return this.dt
    }

    getAttachment() {
        return this.attachment
    }

    setAttachment(attachment) {
        this.attachment = attachment
    }

    getTriggerId() {
        return this.trigger
    }

    setTriggerId(id) {
        this.trigger = id
    }

    getEventId() {
        return this.event
    }

    setEventId(id) {
        this.event = id
    }

    setPoints(points) {
        this.points = points
    }

    getPoints() {
        return this.points
    }
}
