import React from 'react'
import app from 'firebase/app'

import { Grid } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'

import NavigationBar from 'components/NavigationBar'
import TinyCard from 'components/Cards/TinyCard'

export default function Organizations(props) {
    const [organizations, setOrganizations] = React.useState(null)
    const [hasTried, setHasTried] = React.useState(false)

    const [orgSearch, setOrgSearch] = React.useState('')

    if (!hasTried) {
        setHasTried(true)
        grabOrgs()
    }

    function grabOrgs() {
        const db = app.firestore()
        db.collection('fraternities')
            .get()
            .then(function(querySnapshot) {
                let curOrgs = []
                querySnapshot.forEach(function(doc) {
                    let data = doc.data()
                    data['id'] = doc.id
                    curOrgs.push(data)
                })

                setOrganizations(curOrgs)
            })
            .catch(function(error) {
                console.log('Error getting documents: ', error)
            })
    }

    function renderOrgs() {
        if (organizations !== null) {
            let newOrgs = organizations.filter(
                org =>
                    org.fullName
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .indexOf(orgSearch) !== -1,
            )
            if (newOrgs.length > 0) {
                return newOrgs.map(org => (
                    <Grid item xl={3} lg={4} sm={6} xs={12} key={org.id}>
                        <TinyCard onClick={() => props.history.push('/app/gc/organizations/' + org.id)} text={org.fullName} />
                    </Grid>
                ))
            } else {
                return (
                    <Grid item xs={12}>
                        <TinyCard text="No organizations found" centered={true} />
                    </Grid>
                )
            }
        }

        return <></>
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'Organizations',
                        },
                    ]}
                    rightButtons={[
                        {
                            name: 'Add',
                            type: 'icon',
                            innerIcon: <AddIcon />,
                            onClick: () => {
                                props.history.push('/app/gc/organizations/new')
                            },
                        },
                    ]}
                    grid
                    onSearch={text => setOrgSearch(text.toLowerCase().replace(/\s+/g, ''))}
                />
                {renderOrgs()}
            </Grid>
        </>
    )
}
