import React from 'react'

import Stack from '@mui/material/Stack'

import _ from 'lodash'

const Courses = props => {
    var API = {}

    const courseId = 'courseId'

    const iframeRef = React.useRef(null)

    API.LMSInitialize = LMSInitialize
    API.LMSGetValue = LMSGetValue
    API.LMSSetValue = LMSSetValue
    API.LMSCommit = LMSCommit
    API.LMSFinish = LMSFinish
    API.LMSGetLastError = LMSGetLastError
    API.LMSGetDiagnostic = LMSGetDiagnostic
    API.LMSGetErrorString = LMSGetErrorString

    API.Initialize = LMSInitialize
    API.Terminate = LMSFinish
    API.GetValue = LMSGetValue
    API.SetValue = LMSSetValue
    API.Commit = LMSCommit
    API.GetLastError = LMSGetLastError
    API.GetErrorString = LMSGetErrorString
    API.GetDiagnostic = LMSGetDiagnostic

    // document.getElementsByTagName('iframe')[0].API

    function getInitialValue() {
        let data = localStorage.getItem(`course/${courseId}/status`)

        if (!!data) {
            return JSON.parse(data)
        }

        return {
            cmi: {
                learner_name: 'Hunter Copp',
                learner_id: 'abc123',
                suspend_data: '',
                completion_status: '',
            },
        }
    }

    // https://scorm.com/scorm-explained/technical-scorm/run-time/run-time-reference

    React.useEffect(() => {
        window.open('https://storage.googleapis.com/greek-connect-courses/abc123/index.html#/')
    })

    const data = React.useRef(getInitialValue())

    function LMSInitialize(initializeInput) {
        console.log('LMSInitialize: ' + initializeInput)
        return true
    }
    function LMSGetValue(varname) {
        let val = _.get(data.current, varname)

        if (!!val) {
            console.log('LMSGetValue: ' + varname + ' = ' + val)
            return val
        }

        console.log('LMSGetValue: ' + varname + ' NOT FOUND')
        return ''
    }

    function LMSSetValue(varname, varvalue) {
        _.set(data.current, varname, varvalue)

        localStorage.setItem(`course/${courseId}/status`, JSON.stringify(data.current))
        console.log('LMSSetValue: ' + varname + ' = ' + varvalue)
        return ''
    }
    function LMSCommit(commitInput) {
        console.log('LMSCommit: ' + commitInput)
        return true
    }
    function LMSFinish(finishInput) {
        console.log('LMSFinish: ' + finishInput)
        return true
    }
    function LMSGetLastError() {
        console.log('LMSGetLastError: ')
        return 0
    }
    function LMSGetDiagnostic(errorCode) {
        console.log('LMSGetDiagnostic: ' + errorCode)
        return ''
    }
    function LMSGetErrorString(errorCode) {
        console.log('LMSGetErrorString: ' + errorCode)
        return ''
    }

    window.API = API
    window.API_1484_11 = API

    if (iframeRef.current !== null) {
        iframeRef.current.API = API
    }

    if (iframeRef.current !== null) {
        iframeRef.current.API_1484_11 = API
    }

    // src="http://courses.greekconnect.app/def456/index.html"

    return (
        <Stack sx={{ width: '100%', height: '100%', flexGrow: 1 }} direction="column">
            <iframe
                type="text/html"
                src="https://storage.googleapis.com/greek-connect-courses/abc123/index.html#/"
                width="100%"
                style={{ flexGrow: 1, border: 'none' }}
                ref={iframeRef}
                title="Course"
            />
        </Stack>
    )
}

export default Courses
