import { Box, Button as ButtonBase, Collapse, Grid, Typography } from '@material-ui/core'
import {
    CheckCircleRounded as CheckIcon,
    Link as LinkIcon,
    LocationOnOutlined as LocationIcon,
    ForumOutlined as MessagesIcon,
    PollOutlined as PollsIcon,
    RemoveCircleOutline as RemoveIcon,
    ErrorRounded as WarningIcon,
} from '@material-ui/icons'
import React from 'react'

import { generateUUID } from 'code/UUID'

import { FileDownloadIcon } from 'components/Icons'
import ParentTable from 'components/Tables/ParentTable'
import Table from 'components/Tables/Table'
import { Progress as ProgressHeader } from 'components/Tables/TableHeader'

import Sway from '../Sway'

import { TransitionGroup } from 'react-transition-group'

import cn from 'classnames'

import useStyles from './styles'

const Operation = () => {
    const classes = useStyles()

    const [userID] = React.useState(generateUUID())

    const [event, setEvent] = React.useState({
        name: 'Beach Weekend',
        rsvps: [
            { id: generateUUID(), name: 'Harris Flanders' },
            { id: generateUUID(), name: 'Ross Clayton' },
            { id: generateUUID(), name: 'Terry Walters' },
        ],
    })

    const numSpots = 5
    const numSpotsTaken = event.rsvps.length
    const numSpotsLeft = numSpots - numSpotsTaken

    const [hasRSVPD, setHasRSVPD] = React.useState(false)

    return (
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: '100%' }}>
            <Sway rotation={-4} duration={2700}>
                <Box className={classes.parentBox}>
                    <Typography className={classes.titleText} align="center">
                        Beach Weekend
                    </Typography>
                    <Typography className={classes.subtitleText} align="center">
                        Friday - Sunday
                    </Typography>
                    <IconSection icon={<LocationIcon style={{ fontSize: '1.5em' }} />} label="Panama City" linkLabel />
                    <IconSection icon={<LinkIcon style={{ fontSize: '1.5em' }} />} label="Carpool Signups" linkLabel />
                    <IconSection icon={<LinkIcon style={{ fontSize: '1.5em' }} />} label="Hotel Directions" linkLabel />
                    <IconSection icon={<LinkIcon style={{ fontSize: '1.5em' }} />} label="Room Signups" linkLabel />
                    <IconSection icon={<PollsIcon style={{ fontSize: '1.5em' }} />} label="T-Shirts" linkLabel />
                    <Box className={classes.divider} />
                    <Typography className={classes.secondaryTitleText} align="center">
                        Itinerary
                    </Typography>
                    <ItinerarySection label="Hotel Check In Opens" secondary="4:00 pm" />
                    <Box className={classes.divider} />
                    <Typography className={classes.secondaryTitleText} align="center">
                        RSVP
                    </Typography>
                    <TransitionGroup>
                        {!hasRSVPD && numSpotsLeft <= 3 && (
                            <Collapse key="warning">
                                <WarningSection
                                    label={
                                        numSpotsLeft === 1
                                            ? 'There is 1 spot remaining'
                                            : numSpotsLeft <= 0
                                            ? 'This event is full'
                                            : `There are ${numSpotsLeft} spots remaining`
                                    }
                                />
                            </Collapse>
                        )}
                        {hasRSVPD && (
                            <Collapse key="success">
                                <SuccessSection label="You have successfully RSVP'd!" />
                            </Collapse>
                        )}
                        {!hasRSVPD && numSpotsLeft >= 1 && (
                            <Collapse key="button">
                                <Button
                                    label="RSVP"
                                    onClick={() => {
                                        setEvent(e => {
                                            let event = { ...e }
                                            event.rsvps = [...event.rsvps]
                                            event.rsvps.push({ id: userID, name: 'Your Name' })
                                            return event
                                        })
                                        setHasRSVPD(true)
                                    }}
                                />
                            </Collapse>
                        )}
                    </TransitionGroup>
                </Box>
            </Sway>
            <Sway rotation={2.5} duration={3400}>
                <Box className={classes.rsvpBoxParent}>
                    <Box className={classes.rsvpBox}>
                        <ParentTable
                            title="RSVP's"
                            actions={[
                                {
                                    title: "Message RSVP'd Members",
                                    icon: <MessagesIcon />,
                                },
                                {
                                    title: 'Download RSVP List',
                                    icon: <FileDownloadIcon />,
                                },
                            ]}
                            rows={[
                                <ProgressHeader
                                    percentage={numSpotsLeft <= 0 ? 100 : (numSpotsTaken / numSpots) * 100}
                                    displayValue={`${numSpotsLeft <= 0 ? 100 : (numSpotsTaken / numSpots) * 100}% (${numSpotsTaken}/${numSpots})`}
                                />,
                            ]}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Table
                                        addRowText="Add"
                                        onAdd={data => {
                                            setEvent(e => {
                                                let event = { ...e }
                                                event.rsvps = [...event.rsvps]
                                                event.rsvps.push({ id: generateUUID(), name: data.name })
                                                return event
                                            })
                                        }}
                                        showOnEmpty={true}
                                        emptyText="No RSVP's"
                                        sortable
                                        defaultSortable={{
                                            value: 'name',
                                            dir: 'desc',
                                        }}
                                        rowActions={[
                                            {
                                                title: 'Remove',
                                                icon: <RemoveIcon />,
                                                onClick: rowData => {
                                                    setEvent(e => {
                                                        let event = { ...e }
                                                        event.rsvps = [...event.rsvps]
                                                        event.rsvps = event.rsvps.filter(rsvp => rsvp.id !== rowData.id)
                                                        return event
                                                    })

                                                    if (rowData.id === userID) {
                                                        setHasRSVPD(false)
                                                    }
                                                },
                                            },
                                        ]}
                                        columns={[
                                            {
                                                title: 'Name',
                                                value: 'name',
                                                sortable: true,
                                                required: true,
                                            },
                                        ]}
                                        data={event.rsvps}
                                        numRows={5}
                                    />
                                </Grid>
                            </Grid>
                        </ParentTable>
                    </Box>
                </Box>
            </Sway>
        </Box>
    )
}

const ItinerarySection = ({ label, secondary, ...props }) => {
    const classes = useStyles()

    return (
        <Box className={classes.itinerarySectionBox}>
            <Typography className={classes.itinerarySectionUpNext} align="center">
                Up Next
            </Typography>
            <Typography className={classes.itinerarySectionLabel} align="center">
                {label}
            </Typography>
            <Box className={classes.itineryInnerSection}>
                <Box className={classes.itineryInnerSectionBefore} />
                <Box className={classes.itineryInnerSectionBubble} />
                <Box className={classes.itineryInnerSectionAfter} />
            </Box>
            <Typography className={classes.itinerarySectionLabel} align="center">
                {secondary}
            </Typography>
        </Box>
    )
}

const SuccessSection = ({ label, ...props }) => {
    const classes = useStyles()

    return (
        <Box className={classes.warningSectionBox}>
            <CheckIcon className={classes.successSectionIcon} />
            <Typography className={classes.successSectionLabel}>{label}</Typography>
        </Box>
    )
}

const WarningSection = ({ label, ...props }) => {
    const classes = useStyles()

    return (
        <Box className={classes.warningSectionBox}>
            <WarningIcon className={classes.warningSectionIcon} />
            <Typography className={classes.warningSectionLabel}>{label}</Typography>
        </Box>
    )
}

const IconSection = ({ icon, label, linkLabel, ...props }) => {
    const classes = useStyles()

    return (
        <Box className={classes.iconSectionBox}>
            {icon}
            <Typography className={cn(classes.iconSectionLabel, { [classes.iconSectionLinkLabel]: linkLabel })}>{label}</Typography>
        </Box>
    )
}

const Button = ({ label, ...props }) => {
    const classes = useStyles()

    return (
        <Box className={classes.buttonBox}>
            <ButtonBase {...props} variant="contained" color="primary" disableElevation>
                {label}
            </ButtonBase>
        </Box>
    )
}

export default React.memo(Operation)
