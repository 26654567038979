import React from 'react'
import Themes from '../themes'

import { isMobileDevice as _isMobileDevice } from 'code/Helper'

const ThemeStateContext = React.createContext()
const ThemeDispatchContext = React.createContext()

function ThemeProvider({ children }) {
    const isMobileDevice = _isMobileDevice()
    const getCurrentTheme = () => (window.matchMedia('(prefers-color-scheme: dark)').matches ? Themes.dark : Themes.default)
    var [theme, setTheme] = React.useState(Themes[localStorage.getItem('theme')] || Themes.default)
    return (
        <ThemeStateContext.Provider value={isMobileDevice ? getCurrentTheme() : theme}>
            <ThemeDispatchContext.Provider value={setTheme}>{children}</ThemeDispatchContext.Provider>
        </ThemeStateContext.Provider>
    )
}

function toggleTheme(themeDispatch) {
    if (localStorage.getItem('theme') === 'dark') {
        localStorage.setItem('theme', 'default')
        themeDispatch(Themes.default)
    } else {
        localStorage.setItem('theme', 'dark')
        themeDispatch(Themes.dark)
    }
}

function useThemeState() {
    var context = React.useContext(ThemeStateContext)
    if (context === undefined) {
        throw new Error('useThemeState must be used within a ThemeProvider')
    }
    return context
}

function useThemeDispatch() {
    var context = React.useContext(ThemeDispatchContext)
    if (context === undefined) {
        throw new Error('useThemeDispatch must be used within a ThemeProvider')
    }
    return context
}

export { ThemeProvider, useThemeState, useThemeDispatch, ThemeStateContext, toggleTheme }
