import app from 'firebase/app'
import React from 'react'

import _ from 'lodash'

import { useTheme } from '@material-ui/core/styles'

import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'

import { GridIcon, ListIcon, TumbleWeedOutlinedIcon } from 'components/Icons'

import {
    Add as AddIcon,
    ChevronRightRounded as ArrowIcon,
    Check as CheckIcon,
    FolderOutlined as FolderIcon,
    HomeOutlined as HomeIcon,
    InfoOutlined as InfoIcon,
    PollOutlined as PollsIcon,
} from '@material-ui/icons'

import { Link } from 'react-router-dom'

// components
import Card from 'components/Card'
import DeleteDialog from 'components/Dialogs/DeleteDialog'
import NavigationBar from 'components/NavigationBar'
import Paper from 'components/Paper'
import { Avatar } from 'components/Wrappers'

import MoveToDialog from './MoveToDialog'
import PollItemInfo from './PollItemInfo'
import PollsCreateItem from './PollsCreateItem'
import UpdateFolderDialog from './UpdateFolderDialog'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

// styles
import { capitalizeFirstLetter, combinePaths, convertObjectToList, getRelativeTime, isMobileDevice, tsToDate } from 'code/Helper'
import useStyles from './styles'

export default function Polls(props) {
    const classes = useStyles()
    const theme = useTheme()

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const [hasGrabbed, setHasGrabbed] = React.useState(false)
    const [hasLoaded, setHasLoaded] = React.useState(false)

    const [polls, setPolls] = React.useState({})
    const [folders, setFolders] = React.useState({})
    const [view, _setView] = React.useState(localStorage.getItem('polls_viewType') ?? 'grid')

    const [curItems, setCurItems] = React.useState([])

    const setView = v => {
        localStorage.setItem('polls_viewType', v)
        _setView(v)
    }

    const [onAdd, setOnAdd] = React.useState(false)
    const [updateFolderDialog, setUpdateFolderDialog] = React.useState({ open: false })
    const [infoDialog, setInfoDialog] = React.useState({ open: false })
    const [deleteDialog, setDeleteDialog] = React.useState({ open: false, id: null, name: null, type: 'poll', loading: false })
    const [moveToDialog, setMoveToDialog] = React.useState({ open: false, id: null, name: null, type: 'poll' })

    let path = ''
    const paths = []

    if (props.match.params !== undefined && props.match.params[0]) {
        let possiblePaths = props.match.params[0].split('/').filter(id => id.length > 0)

        for (let i = 0; i < possiblePaths.length; i++) {
            let possiblePath = possiblePaths[i]
            paths.push(possiblePath)
            path = possiblePath
        }
    }

    const db = app.firestore()

    const getItems = (polls, folders, isBase, ids) => {
        let compiled = []
        let keys = Object.keys(polls)

        const flattened = _.flatten(convertObjectToList(folders).map(f => f.items))

        if (isBase) {
            keys = keys.filter(id => !flattened.includes(id))
        } else {
            keys = keys.filter(id => ids.includes(id))
        }

        for (let i = 0; i < keys.length; i++) {
            let curItem = polls[keys[i]]

            curItem.id = keys[i]
            curItem.type = 'poll'
            compiled.push(curItem)
        }

        let folderKeys = Object.keys(folders)

        if (isBase) {
            folderKeys = folderKeys.filter(id => !flattened.includes(id))
        } else {
            folderKeys = folderKeys.filter(id => ids.includes(id))
        }

        for (let i = 0; i < folderKeys.length; i++) {
            let curFolder = folders[folderKeys[i]]
            curFolder.id = folderKeys[i]

            if (curFolder.title) {
                compiled.push(curFolder)
            }
        }

        return compiled
    }

    const getCurItems = React.useCallback(() => {
        if (path && path in folders) {
            return getItems(polls, folders, false, folders[path].items)
        }
        return getItems(polls, folders, true)
    }, [path, polls, folders])

    React.useEffect(() => {
        if (hasGrabbed) {
            setCurItems(getCurItems())
            setHasLoaded(true)
        }
    }, [path, hasGrabbed, polls, folders, getCurItems])

    React.useEffect(() => {
        const unsubscribe = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('polls')
            .doc('INFO')
            .onSnapshot(
                function(doc) {
                    if (doc.exists) {
                        let objectPolls = doc.data().polls
                        let objectFolders = doc.data().folders ?? {}

                        setPolls(objectPolls)
                        setFolders(objectFolders)
                        setHasGrabbed(true)
                    }
                },
                function(error) {
                    console.log('Error getting document:', error)
                },
            )
        return () => {
            unsubscribe()
        }
    }, [db, user])

    function isPollCreateAdmin() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || chapter.perms['pollAdmin'].includes(mem.role) || chapter.perms['pollCreate'].includes(mem.role)
            }
        }
    }

    function isPollAdmin(poll) {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    chapter.perms['pollAdmin'].includes(mem.role) ||
                    (chapter.perms['pollCreate'].includes(mem.role) && poll && poll.author !== undefined && poll.author.id === user.getId())
                )
            }
        }
        return false
    }

    function deletePoll(pollID) {
        setDeleteDialog(dialog => ({ ...dialog, loading: true }))

        let pollDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('polls')
            .doc(pollID)

        let pollMetaDoc = pollDoc.collection('data').doc('meta')

        pollMetaDoc
            .delete()
            .then(function() {
                pollDoc
                    .delete()
                    .then(function() {
                        setDeleteDialog({ open: false, id: null, name: null, type: 'poll' })
                    })
                    .catch(function(error) {
                        console.log('Unable to delete poll', error.message)
                    })
            })
            .catch(function(error) {
                console.log('Unable to delete poll', error.message)
            })
    }

    const deleteFolder = async id => {
        setDeleteDialog(dialog => ({ ...dialog, loading: true }))

        const pollsInfoDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('polls')
            .doc('INFO')

        await pollsInfoDoc.update({ [`folders.${id}`]: app.firestore.FieldValue.delete() })

        setDeleteDialog({ open: false, id: null, name: null, type: 'poll' })
    }

    const onUpdateFolder = async ({ title }) => {
        if (updateFolderDialog.id) {
            const pollsInfoDoc = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('polls')
                .doc('INFO')

            await pollsInfoDoc.update({ [`folders.${updateFolderDialog.id}.title`]: title })
        } else {
            onCreateFolder({ title })
        }
    }

    const onCreateFolder = async ({ title }) => {
        const data = {
            title: title,
            author: {
                id: user.id,
                photo: user.photoURL,
                first: user.first,
                last: user.last,
            },
            type: 'folder',
            items: [],
            lastUpdated: app.firestore.Timestamp.fromDate(new Date()),
        }

        const pollsCollection = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('polls')

        const folderId = pollsCollection.doc().id

        const pollsInfoDoc = pollsCollection.doc('INFO')

        const updateData = { [`folders.${folderId}`]: data }

        if (path) {
            updateData[`folders.${path}.items`] = app.firestore.FieldValue.arrayUnion(folderId)
        }

        await pollsInfoDoc.update(updateData)
    }

    const NoRowsOverlay = () => (
        <Stack height="100%" alignItems="center" justifyContent="center">
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ flexGrow: 1 }}>
                <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor }} />
                <Typography style={{ color: theme.borderColor }}>There are no polls here</Typography>
            </Stack>
        </Stack>
    )

    const renderDrivePopups = () => (
        <>
            <DeleteDialog
                {...deleteDialog}
                title={`Delete ${capitalizeFirstLetter(deleteDialog.type)}?`}
                text={
                    <span>
                        Are you sure you want to delete <span style={{ fontWeight: 700 }}>{deleteDialog.name}</span>?<br />
                        {deleteDialog.type === 'folder' ? (
                            <span>
                                Deleting this folder will <span style={{ fontWeight: 700 }}>NOT</span> delete any polls/folders inside of it, but will instead
                                be placed in the base polls folder.
                            </span>
                        ) : (
                            'This action cannot be undone!'
                        )}
                    </span>
                }
                onClose={() => {
                    setDeleteDialog({ open: false, id: null, name: null, type: 'poll' })
                }}
                onConfirmDelete={() => {
                    if (deleteDialog.type === 'poll') {
                        deletePoll(deleteDialog.id)
                    } else {
                        deleteFolder(deleteDialog.id)
                    }
                }}
                loading={!!deleteDialog.loading}
            />
            <MoveToDialog
                open={moveToDialog.open}
                onClose={() => setMoveToDialog(dialog => ({ ...dialog, open: false }))}
                onMove={async folderId => {
                    try {
                        if (moveToDialog.open && moveToDialog.id) {
                            const updates = {}
                            let changed = false

                            if (path && folderId !== path) {
                                // Currently in a folder
                                changed = true
                                updates[`folders.${path}.items`] = app.firestore.FieldValue.arrayRemove(moveToDialog.id)
                            }

                            if (folderId && folderId in folders) {
                                // Sending it to folder
                                changed = true
                                updates[`folders.${folderId}.items`] = app.firestore.FieldValue.arrayUnion(moveToDialog.id)
                            }

                            if (changed) {
                                await db
                                    .collection('chapters')
                                    .doc(user.getChapter())
                                    .collection('polls')
                                    .doc('INFO')
                                    .update(updates)
                            }
                        }
                    } catch (e) {
                        console.log(e)
                    }

                    setMoveToDialog(dialog => ({ ...dialog, open: false }))
                }}
                folders={folders}
                parent={path ? path : null}
                base={!path}
                id={moveToDialog.id}
                name={moveToDialog.name}
                icon={moveToDialog.type === 'folder' ? FolderIcon : PollsIcon}
            />
            <PollsCreateItem
                open={!!onAdd}
                target={onAdd}
                onClose={() => setOnAdd(false)}
                type={isMobileDevice() ? 'sheet' : 'menu'}
                onSelectAction={action => {
                    if (action === 'poll') {
                        if (path) {
                            props.history.push('/app/applications/polls/edit/new', { path })
                        } else {
                            props.history.push('/app/applications/polls/edit/new')
                        }
                    } else if (action === 'folder') {
                        setUpdateFolderDialog({ open: true })
                    }

                    setOnAdd(false)
                }}
                disableFolder={paths.length >= 9}
            />
            <PollItemInfo
                open={!!infoDialog.open}
                onClose={() => setInfoDialog({ open: false })}
                info={infoDialog.item}
                history={props.history}
                onSelectAction={action => {
                    if (infoDialog.item) {
                        const item = infoDialog.item
                        if (item.type === 'folder') {
                            if (action === 'rename') {
                                setUpdateFolderDialog({ open: true, id: item.id, defaultTitle: item.title })
                            } else if (action === 'delete') {
                                setDeleteDialog({ open: true, id: item.id, name: item.title ? item.title : 'Untitled', type: item.type })
                            } else if (action === 'move') {
                                setMoveToDialog({ open: true, id: item.id, name: item.title ? item.title : 'Untitled', type: item.type })
                            }
                        } else {
                            if (action === 'edit') {
                                props.history.push(`/app/applications/polls/edit/${item.id}`)
                            } else if (action === 'move') {
                                // Show dialog where they can select a folder and then it gets moved to there
                                setMoveToDialog({ open: true, id: item.id, name: item.title ? item.title : 'Untitled', type: item.type })
                            } else if (action === 'delete') {
                                setDeleteDialog({ open: true, id: item.id, name: item.title ? item.title : 'Untitled', type: item.type })
                            }
                        }
                    }
                }}
                hasAdmin={infoDialog.open && isPollAdmin(infoDialog.item)}
            />
            <UpdateFolderDialog
                open={updateFolderDialog.open}
                isNew={!updateFolderDialog.id}
                defaultTitle={updateFolderDialog.defaultTitle}
                onClose={() => setUpdateFolderDialog({ open: false })}
                onSubmit={data => onUpdateFolder(data)}
            />
        </>
    )

    const getLink = data => {
        if (data.type === 'folder') {
            return combinePaths(props.history.location.pathname, data.id)
        } else {
            return `/app/applications/polls/view/${user.getChapter()}/${data.id}`
        }
    }

    const getTitles = () => {
        let titles = [
            {
                name: 'My House',
                link: '/app/dashboard/',
                icon: <HomeIcon />,
            },
        ]

        let hiddenTitles = []

        if (path) {
            titles.push({ name: 'Polls', link: '/app/applications/polls/' })

            let totalPath = '/app/applications/polls'

            for (let i = 0; i < paths.length; i++) {
                let curPath = paths[i]
                let isLastPath = i >= paths.length - 1

                let title = folders[curPath] ? folders[curPath].title : curPath

                totalPath = combinePaths(totalPath, curPath)

                if (isLastPath) {
                    if (hiddenTitles.length > 0) {
                        titles.push({
                            collapsed: hiddenTitles,
                        })
                    }
                    titles.push({ name: title })
                } else {
                    hiddenTitles.push({ name: title, link: totalPath })
                }

                if (title !== curPath) sessionStorage.setItem(totalPath, title)
            }
        } else {
            titles.push({ name: 'Polls' })
        }

        return { titles, hiddenTitles }
    }

    const renderNavBar = isGrid => {
        let icons = []

        if (!isMobileDevice()) {
            if (view === 'grid') {
                icons.push({
                    name: 'Grid',
                    type: 'icon',
                    innerIcon: <GridIcon />,
                    onClick: () => {
                        setView('list')
                    },
                })
            } else {
                icons.push({
                    name: 'List',
                    type: 'icon',
                    innerIcon: <ListIcon />,
                    onClick: () => {
                        setView('grid')
                    },
                })
            }
        }

        if (isPollCreateAdmin()) {
            icons.push({
                name: 'Add',
                type: 'icon',
                innerIcon: <AddIcon />,
                onClick: e => {
                    setOnAdd(e.currentTarget)
                },
            })
        }
        return <NavigationBar {...getTitles()} rightButtons={icons} key={isPollCreateAdmin() ? 'admin' : 'nah'} grid={isGrid} />
    }

    if (hasLoaded && Array.isArray(curItems) && curItems.length === 0 && (view !== 'list' || isMobileDevice())) {
        return (
            <>
                <Box>{renderNavBar(false)}</Box>
                <Box style={{ padding: 8, width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 16,
                            flexGrow: 1,
                        }}
                    >
                        <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor }} />
                        <Typography style={{ color: theme.borderColor }}>There are no polls here</Typography>
                    </Box>
                </Box>
                {renderDrivePopups()}
            </>
        )
    }

    const getSecondaryIcons = item => {
        return [
            {
                label: 'Info',
                onClick: () => setInfoDialog({ open: true, item }),
                icon: <InfoIcon />,
                id: `${item.id}.info`,
            },
        ]
    }

    if (isMobileDevice()) {
        return (
            <>
                <Stack direction="column" spacing={2}>
                    {renderNavBar(false)}
                    <Stack direction="column">
                        {curItems &&
                            curItems.map((item, index) => (
                                <React.Fragment key={item.id}>
                                    {index > 0 && <Divider sx={{ ml: 5, mr: 5.25 }} />}
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <ButtonBase
                                            sx={{
                                                flexGrow: 1,
                                                width: '100%',
                                                textAlign: 'left',
                                                p: 1,
                                                borderTopLeftRadius: index === 0 ? 4 : 0,
                                                borderTopRightRadius: index === 0 ? 4 : 0,
                                                borderBottomLeftRadius: index === curItems.length - 1 ? 4 : 0,
                                                borderBottomRightRadius: index === curItems.length - 1 ? 4 : 0,
                                            }}
                                            component={Link}
                                            to={getLink(item)}
                                        >
                                            <Stack direction="row" spacing={1} key={item.id} alignItems="center" sx={{ width: '100%' }}>
                                                {item.type === 'folder' ? <FolderIcon /> : <PollsIcon />}
                                                <Stack direction="column" sx={{ flexGrow: 1 }}>
                                                    <Typography>{item.title ? item.title : 'Untitled'}</Typography>
                                                    <Typography sx={{ lineHeight: 1, fontSize: '0.75rem' }}>
                                                        Modified {getRelativeTime(item.lastUpdated)}
                                                    </Typography>
                                                </Stack>
                                                <ArrowIcon />
                                            </Stack>
                                        </ButtonBase>
                                        <IconButton size="small" onClick={() => setInfoDialog({ open: true, item })}>
                                            <InfoIcon />
                                        </IconButton>
                                    </Stack>
                                </React.Fragment>
                            ))}
                    </Stack>
                </Stack>
                {renderDrivePopups()}
            </>
        )
    }

    if (view === 'list') {
        return (
            <>
                <Stack direction="column" spacing={2} sx={{ height: '100%' }} className={classes.table}>
                    {renderNavBar(false)}
                    <Paper style={{ display: 'flex', flexGrow: 1, height: `calc(100vh - 155px)` }}>
                        <DataGrid
                            sx={{ border: 'none' }}
                            rows={curItems ?? []}
                            loading={!hasLoaded}
                            slots={{
                                noRowsOverlay: NoRowsOverlay,
                            }}
                            rowHeight={44}
                            columnHeaderHeight={44}
                            columns={[
                                {
                                    field: 'title',
                                    headerName: hasGrabbed ? 'Title' : 'loading',
                                    minWidth: 250,
                                    flex: 2,
                                    renderCell: ({ value, row }) => (
                                        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            {row.type === 'folder' ? (
                                                <FolderIcon style={{ marginInline: 12, verticalAlign: 'middle' }} />
                                            ) : (
                                                <PollsIcon style={{ marginInline: 12, verticalAlign: 'middle' }} />
                                            )}
                                            {value}
                                        </Typography>
                                    ),
                                    valueGetter: ({ value }) => (value ? value : 'Untitled'),
                                    renderHeader: () => (
                                        <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                            {hasGrabbed ? 'Title' : 'Loading'}
                                        </Typography>
                                    ),
                                },
                                {
                                    field: 'author',
                                    headerName: 'Author',
                                    minWidth: 125,
                                    flex: 1,
                                    renderCell: ({ value }) => (
                                        <Stack spacing={1.5} direction="row" alignItems="center">
                                            <Avatar src={value.photo} style={{ width: 32, height: 32 }} />
                                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                {value.first} {value.last}
                                            </Typography>
                                        </Stack>
                                    ),
                                    renderHeader: () => <Typography fontWeight="500">Author</Typography>,
                                },
                                {
                                    field: 'open',
                                    headerName: 'Open for responses',
                                    type: 'boolean',
                                    renderHeader: () => <Typography fontWeight="500">Open</Typography>,
                                    renderCell: ({ value }) => <>{!!value && <CheckIcon />}</>,
                                },
                                {
                                    field: 'lastUpdated',
                                    headerName: 'Last updated',
                                    type: 'dateTime',
                                    align: 'right',
                                    headerAlign: 'right',
                                    minWidth: 125,
                                    flex: 1,
                                    renderCell: ({ value }) => (
                                        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                            {getRelativeTime(value)}
                                        </Typography>
                                    ),
                                    valueGetter: ({ value }) => tsToDate(value),
                                    renderHeader: () => <Typography fontWeight="500">Last updated</Typography>,
                                },
                                {
                                    field: 'actions',
                                    type: 'actions',
                                    getActions: ({ row: item }) => [
                                        <GridActionsCellItem icon={<InfoIcon />} onClick={() => setInfoDialog({ open: true, item })} label="Info" />,
                                    ],
                                    /*isPollAdmin(item.id)
                                            ? [

                                                  <GridActionsCellItem
                                                      icon={<EditIcon />}
                                                      onClick={() => {
                                                        if (item.type === 'folder') {
                                                            setUpdateFolderDialog({ open: true, id: item.id })
                                                        } else {
                                                            props.history.push(`/app/applications/polls/edit/${item.id}`)
                                                        }
                                                      }}
                                                      label="Edit"
                                                  />,
                                                  <GridActionsCellItem
                                                      icon={<DeleteIcon style={{ color: theme.palette.red.main }} />}
                                                      onClick={() => {
                                                          setDeleteDialog({ open: true, id: item.id, name: item.title ? item.title : 'Untitled', type: item.type })
                                                      }}
                                                      label="Delete"
                                                  />,
                                              ]
                                            : [],*/
                                },
                            ]}
                            onRowClick={({ row }) => {
                                props.history.push(getLink(row))
                            }}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            hideFooter
                            disableColumnMenu
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'title', sort: 'asc' }],
                                },
                            }}
                        />
                    </Paper>
                </Stack>
                {renderDrivePopups()}
            </>
        )
    }

    return (
        <>
            <Stack direction="row">
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    {renderNavBar(true)}
                    {curItems &&
                        curItems.map(item => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                                <Card
                                    style={{ height: 196 }}
                                    title={item.title ? item.title : 'Untitled'}
                                    variant={isMobileDevice() ? 'outlined' : 'card'}
                                    buttonProps={{
                                        component: Link,
                                        to: getLink(item),
                                    }}
                                    secondaryIcons={getSecondaryIcons(item)}
                                    icon={item.type === 'folder' ? FolderIcon : PollsIcon}
                                    animate
                                />
                            </Grid>
                        ))}
                </Grid>
            </Stack>
            {renderDrivePopups()}
        </>
    )
}
