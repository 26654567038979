import tinycolor from 'tinycolor2'

const primary = '#1e88e5' // '#F4511E' //"#0984e3";//"#536DFE";
const secondary = '#f5cd7a' //'#c0c0c0' //"#34495e";//"#2ecc71";//"#FF5C93";

export const orange = '#ffb74d'
export const darkorange = '#ef6c00'
const yellow = '#F5CD7A'
const blue = '#1e88e5'
const red = '#E66868'
const pink = '#F78FB3'
const bluegray = '#303A52'
const green = '#2ecc71'
const gray = '#dfe6e9'
const purple = '#6c5ce7'
const white = '#FFFFFF'
const blackgray = '#4a4a4a'

const lightenRate = 7.5
const darkenRate = 15

export default {
    title: 'Default Dark',
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        contrastText: '#fff',
        type: 'dark',
        mode: 'dark',
        primary: {
            main: primary,
            light: tinycolor(primary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(primary)
                .darken(darkenRate)
                .toHexString(),
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(secondary)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#fff',
        },
        purple: {
            main: purple,
            light: tinycolor(purple)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(purple)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#fff',
        },
        white: {
            main: white,
            light: tinycolor(white)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(white)
                .darken(darkenRate)
                .toHexString(),
        },
        red: {
            main: red,
            light: tinycolor(red)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(red)
                .darken(darkenRate)
                .toHexString(),
        },
        gray: {
            main: gray,
            light: tinycolor(gray)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(gray)
                .darken(darkenRate)
                .toHexString(),
        },
        green: {
            main: green,
            light: tinycolor(green)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(green)
                .darken(darkenRate)
                .toHexString(),
        },
        orange: {
            main: orange,
            light: tinycolor(orange)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(orange)
                .darken(darkenRate)
                .toHexString(),
        },
        darkorange: {
            main: darkorange,
            light: tinycolor(darkorange)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(darkorange)
                .darken(darkenRate)
                .toHexString(),
        },
        pink: {
            main: pink,
            light: tinycolor(pink)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(pink)
                .darken(darkenRate)
                .toHexString(),
        },
        blue: {
            main: blue,
            light: tinycolor(blue)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(blue)
                .darken(darkenRate)
                .toHexString(),
        },
        yellow: {
            main: yellow,
            light: tinycolor(yellow)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(yellow)
                .darken(darkenRate)
                .toHexString(),
        },
        bluegray: {
            main: bluegray,
            light: tinycolor(bluegray)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(bluegray)
                .darken(darkenRate)
                .toHexString(),
        },
        blackgray: {
            main: blackgray,
            light: tinycolor(blackgray)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(blackgray)
                .darken(darkenRate)
                .toHexString(),
        },
        inherit: {
            main: 'inherit',
            light: tinycolor('inherit')
                .lighten('inherit')
                .toHexString(),
            dark: tinycolor('inherit')
                .darken('inherit')
                .toHexString(),
        },
        text: {
            primary: '#ffffff',
            secondary: '#ffffff',
            hint: '#56565D',
        },
        background: {
            default: '#111111',
            light: '#343434',
            paper: '#232323',
        },
    },
    border: '1px solid #3a3a3a',
    borderColor: '#3a3a3a',
    document: {
        outline: 'rgba(255, 255, 255, 0.23)',
        hoverOutline: 'white',
        color: '#D6D6D6',
    },
    customShadows: {
        widget: '0px 1px 8px rgba(0, 0, 0, 0.103475), 0px 3px 3px rgba(0, 0, 0, 0.0988309), 0px 3px 4px rgba(0, 0, 0, 0.10301) !important',
        widgetDark: '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetWide: '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    },
    transitionDuration: 275,
}
