// TitleComponent.jsx

import React from 'react'
import { Helmet } from 'react-helmet'
import { FraternityContext } from '../../context/FraternityContext'
import { getAppName } from '../../code/App'

const TitleComponent = props => {
    var defaultIcon = 'favicon.png'

    let { fraternity } = React.useContext(FraternityContext)
    var title = getAppName(fraternity)
    var icon = fraternity ? fraternity.customAppIcon : null
    return (
        <Helmet>
            <link
                rel="icon"
                href={icon ? icon : defaultIcon}
                sizes="16x16"
                type="image/png"
            />
            <link
                rel="icon"
                href={icon ? icon : defaultIcon}
                sizes="32x32"
                type="image/png"
            />
            <link
                rel="icon"
                href={icon ? icon : defaultIcon}
                sizes="48x48"
                type="image/png"
            />
            <link
                rel="icon"
                href={icon ? icon : defaultIcon}
                sizes="62x62"
                type="image/png"
            />
            <link
                rel="icon"
                href={icon ? icon : defaultIcon}
                sizes="80x80"
                type="image/png"
            />
            <link rel="apple-touch-icon" href={icon ? icon : defaultIcon} />
            <title>{title}</title>
        </Helmet>
    )
}

export { TitleComponent }
