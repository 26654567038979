import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    gradient: {
        width: '100%',
        height: 16,
        backgroundImage: `linear-gradient(${theme.palette.background.paper}00, ${theme.palette.background.paper})`,
    },
    parentBox: {
        display: 'flex',
    },
    parentBoxHorizontal: {
        flexDirection: 'row',
        overflowX: 'scroll',
    },
    parentBoxVertical: {
        flexDirection: 'column',
        overflowY: 'scroll',
    },
    sectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    sectionBoxVertical: {
        flexDirection: 'row',
    },
    sectionBoxHorizontal: {
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    sectionBubble: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: theme.palette.text.primary,
    },
    sectionBubbleVertical: {
        margin: `${theme.spacing(0.5)}px 0`,
    },
    sectionBubbleHorizontal: {
        margin: `0 ${theme.spacing(0.5)}px`,
    },
    sectionBubbleBox: {
        display: 'flex',
        alignItems: 'center',
    },
    sectionBubbleBoxHorizontal: {
        flexDirection: 'row',
        width: '100%',
    },
    sectionBubbleBoxVertical: {
        flexDirection: 'column',
        height: '100%',
        margin: `0 ${theme.spacing(1)}px`,
    },
    sectionLabelLabel: {
        margin: `0 ${theme.spacing(0.5)}px`,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    sectionTime: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    sectionTimeHorizontal: {
        margin: `0 ${theme.spacing(1)}px`,
    },
    sectionTimeVertical: {
        width: `calc(50% - 6px - ${theme.spacing(1)}px)`,
    },
    sectionLabel: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    sectionLabelHorizontal: {
        margin: `0 ${theme.spacing(1)}px`,
    },
    sectionLabelVertical: {
        width: `calc(50% - 6px - ${theme.spacing(1)}px)`,
    },
    sectionLineBox: {
        display: 'flex',
        alignItems: 'center',
    },
    sectionLineBoxHorizontal: {
        flexDirection: 'row',
    },
    sectionLineBoxVertical: {
        flexDirection: 'column',
    },
    sectionBubbleLine: {
        height: 2,
        backgroundColor: theme.palette.text.primary,
        flexGrow: 1,
    },
    sectionBubbleLineVertical: {
        width: 2,
        height: 16,
    },
    sectionBubblePast: {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.3,
    },
    sectionBubblePresent: {
        backgroundColor: theme.palette.primary.main,
    },
    sectionLabelLine: {
        backgroundColor: theme.palette.text.primary,
    },
    sectionLabelLineHorizontal: {
        height: 2,
        width: 32,
    },
    sectionLabelLineVertical: {
        height: 32,
        width: 2,
    },
    hidden: {
        display: 'none',
    },
    hiddenVis: {
        visibility: 'hidden',
    },
}))
