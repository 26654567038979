const hostApi = process.env.NODE_ENV === 'development' ? 'http://localhost:3030' : 'https://greekhouse-d5684.firebaseapp.com'
const portApi = process.env.NODE_ENV === 'development' ? 8080 : ''
const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}`

export default {
    hostApi,
    portApi,
    baseURLApi,
    remote: 'https://greekhouse-d5684.firebaseapp.com',
    isBackend: process.env.REACT_APP_BACKEND,
    auth: {
        email: 'admin@flatlogic.com',
        password: 'password',
    },
}
