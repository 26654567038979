import React from 'react'

import { Box, Button, Typography } from '@material-ui/core'

import { Refresh as RefreshIcon } from '@material-ui/icons'

import moment from 'moment'

const RefreshResults = ({ lastGrabbed, onClick, minSeconds, disabled }) => {
    const [curTime, setCurTime] = React.useState(new Date())

    React.useEffect(() => {
        // Redraw every second
        setTimeout(
            () => setCurTime(new Date()),
            moment()
                .startOf('second')
                .add(1, 'second')
                .diff(moment()),
        )
    }, [curTime])

    const secondsOff = Math.round((curTime.getTime() - lastGrabbed) / 1000)

    const beenLongEnough = secondsOff >= minSeconds

    let seconds = secondsOff % 60
    let minutes = Math.floor(secondsOff / 60)

    let time = ''
    if (minutes > 0) {
        time = `${minutes}m`
    }

    if (seconds > 0) {
        time += `${minutes > 0 ? ' ' : ''}${seconds}s`
    }

    if (time.length > 0) {
        time += ' ago'
    } else {
        time = 'just now'
    }

    return (
        <>
            <Box style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center', justifyContent: 'center' }}>
                <Typography>Last Updated {time}</Typography>
                <Button color="primary" onClick={onClick} disabled={disabled || !beenLongEnough} startIcon={<RefreshIcon />}>
                    Refresh
                </Button>
            </Box>
        </>
    )
}

export default RefreshResults
