import React from 'react'

import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Menu, MenuItem } from '@material-ui/core'
import { Add as AddIcon, MoreVert as ExpandIcon, HomeOutlined as HomeIcon, WhatshotOutlined as RushIcon } from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'

import app from 'firebase/app'
import 'firebase/functions'

// components
import TinyCard from 'components/Cards/TinyCard'
import NavigationBar from 'components/NavigationBar'
import Notification from 'components/Notification/Notification'
import { Button } from 'components/Wrappers'

import { isMobileDevice } from 'code/Helper'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

const modifyItemsInactive = [
    { id: 0, message: 'Delete', type: 'delete' },
    { id: 1, message: 'Activate', type: 'upload' },
]

const modifyItemsActive = [
    { id: 0, message: 'Delete', type: 'delete' },
    { id: 2, message: 'Deactivate', type: 'archive' },
]

export default function RushSettings(props) {
    const theme = useTheme()

    const db = app.firestore()

    const [hasTried, setHasTried] = React.useState(false)
    const [rushesData, setRushesData] = React.useState(null)
    const [modifyMenu, setModifyMenu] = React.useState(null)
    const [modifyMenuRush, setModifyMenuRush] = React.useState(null)

    const [toggleRushDialog, setToggleRushDialog] = React.useState({ open: false, active: false })

    const { user } = React.useContext(AuthContext)
    const { chapter, requestChapterUpdate } = React.useContext(ChapterContext)

    function getRushes() {
        let rushesDoc = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')

        rushesDoc
            .get()
            .then(function(querySnapshot) {
                const result = []
                querySnapshot.forEach(function(doc) {
                    let data = doc.data()
                    data['id'] = doc.id
                    data.isActive = chapter.activeRush === doc.id
                    result.push(data)
                })
                setRushesData(result)
                localStorage.setItem('cache_numRushes', result.length)
            })
            .catch(function(error) {
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
            })
    }

    if (hasTried === false) {
        setHasTried(true)
        getRushes()
    }

    const [deleteDialog, setDeleteDialog] = React.useState({
        isOpen: false,
    })

    function deleteRush() {
        if (rushesData) {
            let rushID = deleteDialog.rushID
            if (rushID) {
                let submission = {
                    chapter: user.getChapter(),
                    rushID: rushID,
                }

                let functionsCall = app.functions().httpsCallable('deleteRush')
                functionsCall(submission).catch(function(error) {
                    // Getting the Error details.
                    var code = error.code
                    var message = error.message
                    var details = error.details
                    console.log('AN ERROR OCCURRED', code, message, details)
                })
            }
            let rushes = rushesData
            for (let i = 0; i < rushes.length; i++) {
                let r = rushes[i]
                if (r.id === rushID) {
                    rushes.splice(i, 1)
                    i = rushes.Length
                }
            }

            setRushesData(rushes)

            setDeleteDialog({ isOpen: false })
        }
    }

    function toggleRush(rushID) {
        if (rushesData) {
            if (rushID) {
                let submission = {
                    chapter: user.getChapter(),
                    rushID: rushID,
                }

                let curStatus = false

                let rushes = [...rushesData]
                for (let i = 0; i < rushes.length; i++) {
                    let r = { ...rushes[i] }
                    if (r.id === rushID) {
                        curStatus = r.isActive
                        r.isActive = !r.isActive
                        rushes[i] = r
                        i = rushes.Length
                    } else if (r.isActive) {
                        r.isActive = false
                        rushes[i] = r
                    }
                }

                let functionsCall = app.functions().httpsCallable('toggleRushActivation')
                functionsCall(submission)
                    .then(function() {
                        setRushesData(rushes)
                        requestChapterUpdate()
                        setToggleRushDialog({ open: false, active: false })
                    })
                    .catch(function(error) {
                        // Getting the Error details.
                        var code = error.code
                        var message = error.message
                        var details = error.details
                        console.log('AN ERROR OCCURRED', code, message, details)
                    })

                setToggleRushDialog({ open: true, active: curStatus })
            }
        }
    }

    function askDeleteRush(rushID) {
        if (!deleteDialog.isOpen) {
            let rushName = ''
            for (let i = 0; i < rushesData.length; i++) {
                let r = rushesData[i]
                if (r.id === rushID) {
                    rushName = r.name
                }
            }
            if (rushName !== '') {
                setDeleteDialog({
                    isOpen: true,
                    rushID: rushID,
                    rushName: rushName,
                })
            }
        }
    }

    function getRows() {
        if (rushesData) {
            return rushesData.map(rush => (
                <Grid item lg={4} md={6} sm={6} xs={12} key={rush.id}>
                    <TinyCard
                        disabled
                        text={rush.name}
                        description={rush.isActive ? <span style={{ fontWeight: 'bold', color: theme.palette.green.main }}>Active</span> : <br />}
                        buttons={[
                            {
                                onClick: e => {
                                    setModifyMenu(e.currentTarget)
                                    setModifyMenuRush(rush)
                                },
                                icon: <ExpandIcon />,
                                id: `${rush.id}.edit`,
                            },
                        ]}
                        variant={isMobileDevice() ? 'outlined' : 'default'}
                    />
                </Grid>
            ))
        }

        return <></>
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Rush',
                            link: '/app/applications/rush/',
                            icon: <RushIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: 'Settings',
                        },
                    ]}
                    rightButtons={[
                        {
                            name: 'Add',
                            type: 'icon',
                            innerIcon: <AddIcon />,
                            onClick: () => {
                                props.history.push('/app/applications/rush/settings/create')
                            },
                        },
                    ]}
                    grid
                />
                {getRows()}
            </Grid>
            <Dialog open={deleteDialog.isOpen} onClose={() => setDeleteDialog({ isOpen: false })}>
                <DialogTitle>Delete rush?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete "<span style={{ fontWeight: 700 }}>{deleteDialog.rushName}</span>
                        "?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog({ isOpen: false })} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => deleteRush()} color="red">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={toggleRushDialog.open}>
                <DialogTitle>{toggleRushDialog.active ? 'Deactivating Rush' : 'Activating Rush'}</DialogTitle>
                <DialogContent>
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={28} />
                    </Box>
                </DialogContent>
            </Dialog>
            <Menu id="new-menu" open={Boolean(modifyMenu)} anchorEl={modifyMenu} onClose={() => setModifyMenu(null)} disableAutoFocusItem>
                {modifyMenuRush && modifyMenuRush.isActive
                    ? modifyItemsActive.map(menuItem => (
                          <MenuItem
                              key={menuItem.id}
                              onClick={event => {
                                  if (menuItem.message === 'Delete') {
                                      askDeleteRush(modifyMenuRush.id)
                                  }
                                  if (menuItem.message === 'Deactivate') {
                                      toggleRush(modifyMenuRush.id)
                                  }
                                  setModifyMenu(null)
                                  setModifyMenuRush(null)
                              }}
                          >
                              <Notification {...menuItem} typographyVariant="inherit" />
                          </MenuItem>
                      ))
                    : modifyItemsInactive.map(menuItem => (
                          <MenuItem
                              key={menuItem.id}
                              onClick={event => {
                                  if (menuItem.message === 'Delete') {
                                      askDeleteRush(modifyMenuRush.id)
                                  }
                                  if (menuItem.message === 'Activate') {
                                      toggleRush(modifyMenuRush.id)
                                  }
                                  setModifyMenu(null)
                              }}
                          >
                              <Notification {...menuItem} typographyVariant="inherit" />
                          </MenuItem>
                      ))}
            </Menu>
        </>
    )
}
