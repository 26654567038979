import React from 'react'

import { Box, IconButton, Tooltip } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { HelpOutline as InfoIcon } from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import { Typography } from '../../Wrappers'

// styles
import useStyles from './styles'

export default function FormSection(props) {
    var classes = useStyles()
    var theme = useTheme()

    const useSmall = useMediaQuery(theme.breakpoints.down('sm'))

    const { label, labelTooltip, icon, showHeader, children, control, enabled } = props

    return useSmall || showHeader || control !== undefined ? (
        <Box className={classes.parentBoxSmall}>
            <Box className={classes.headerBox}>
                {icon && <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>{icon}</Box>}
                <Box style={{ marginLeft: icon ? 4 : 0, display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                    <Typography style={{ fontWeight: 500, lineHeight: '30px' }}>{label}</Typography>
                    {labelTooltip && (
                        <Tooltip title={labelTooltip}>
                            <IconButton size="small" aria-label={labelTooltip} style={{ width: 30, height: 30, marginLeft: 4 }}>
                                <InfoIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
                {control && control}
            </Box>
            {((control && enabled) || control === undefined) && <Box className={classes.mainBox}>{children}</Box>}
        </Box>
    ) : (
        <Box className={classes.parentBox}>
            <Box className={classes.iconBox}>{icon}</Box>
            <Box className={classes.mainBox}>{children}</Box>
        </Box>
    )
}
