import React from 'react'

import { MapContext } from '../Map'

const Ref = React.forwardRef((props, ref) => {
    const { map } = React.useContext(MapContext)

    React.useEffect(() => {
        ref.current = map

        return () => {
            ref.current = null
        }
    }, [map, ref])

    return null
})

export default Ref
