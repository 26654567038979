import React from 'react'

import { Box } from '@material-ui/core'
import { InfoOutlined as InfoIcon } from '@material-ui/icons'

import ChatContainer from 'components/Messages/ChatContainer'
import ChatMobileComposerBar from 'components/Messages/ChatMobile/ChatMobileComposerBar'

import { HeaderContext } from 'context/HeaderContext'

import { isMobileDevice } from 'code/Helper'

const ViewMessages = ({ history, ...props }) => {
    const { setIcons } = React.useContext(HeaderContext)

    const conversationId = props.match.params.conversationId

    React.useEffect(() => {
        setIcons([
            {
                name: 'Info',
                type: 'icon',
                innerIcon: <InfoIcon />,
                onClick: () => history.push(`/app/messages/${conversationId}/details`),
            },
        ])
    }, [])

    if (isMobileDevice()) {
        return (
            <Box
                style={{
                    position: 'absolute',
                    top: 'calc(56px + env(safe-area-inset-top))',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box style={{ flexGrow: 1 }}>
                    <ChatContainer version="desktop" conversationId={conversationId} history={history} />
                </Box>
                <Box>
                    <ChatMobileComposerBar version="mobile" conversationId={conversationId} history={history} />
                </Box>
            </Box>
        )
    }

    return (
        <Box
            style={{
                position: 'absolute',
                top: 'calc(56px + env(safe-area-inset-top))',
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box style={{ flexGrow: 1 }}>
                <ChatContainer version="desktop" conversationId={conversationId} history={history} />
            </Box>
            <Box style={{ margin: 16, marginTop: 0 }}>
                <ChatMobileComposerBar version="desktop" conversationId={conversationId} history={history} />
            </Box>
        </Box>
    )
}

export default React.memo(ViewMessages)
