import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    noScrollbar: {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    table: {
        '& .MuiDataGrid-root': {
            color: 'inherit',
        },
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-withBorderColor': {
            outline: 'none !important',
        },
    },
    navBarContainer: {
        '& .MuiGrid-container > .MuiBox-root': {
            maxWidth: 'calc(100% - 72px)',
        },
        '& .MuiBreadcrumbs-root': {
            maxWidth: '100%',
        },
        '& .MuiBreadcrumbs-ol': {
            flexWrap: 'nowrap',
        },
        '& .MuiBreadcrumbs-li:last-of-type, & .MuiBreadcrumbs-li:last-of-type h4': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
    },
}))
