import React from 'react'

import { Box, Collapse, Divider, FormControlLabel, Switch } from '@material-ui/core'
import { CheckRounded as CheckIcon, DescriptionOutlined as UploadFileIcon } from '@material-ui/icons'

import { ClipboardIcon } from 'components/Icons'

import {
    Button,
    Dialog as DialogMobile,
    DialogActions as DialogActionsMobile,
    DialogContent as DialogContentMobile,
    DialogTitle as DialogTitleMobile,
} from 'components/Wrappers'

import { ButtonLargeItem } from 'pages/setup/components/ToggleItem'

import { pdf } from '@react-pdf/renderer'
import logo from 'pages/login/assets/logo.png'
import PDF from 'pages/setup/components/PDF'

import { exportFile } from 'code/Helper'

import copy from 'clipboard-copy'

import useStyles from './styles'

const JoinableLinkDialog = ({ title, id, enabled, toggleEnabled, loading, code, open, onClose }) => {
    const classes = useStyles()

    const [copied, setCopied] = React.useState(false)
    const [downloadedPDF, setDownloadedPDF] = React.useState(false)

    const handleCopy = () => {
        setCopied(true)
        copy(`https://greekconnect.app/join/${code}`).then(() => {
            setTimeout(() => setCopied(false), 2000)
        })
    }

    const downloadPDF = () => {
        setDownloadedPDF(true)
        pdf(<PDF icon={logo} code={code} />)
            .toBlob()
            .then(function(blob) {
                exportFile(blob, 'greek_connect_chapter_signup.pdf', 'application/pdf')
                setTimeout(() => setDownloadedPDF(false), 2000)
            })
    }
    return (
        <DialogMobile open={open} onClose={onClose}>
            <DialogTitleMobile aria-labelledby={`${id}-title`} onClose={onClose}>
                Joinable Link
            </DialogTitleMobile>
            <DialogContentMobile dividers style={{ paddingBottom: 0 }}>
                <Box style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                    <FormControlLabel
                        labelPlacement="start"
                        control={<Switch color="primary" checked={enabled} onChange={toggleEnabled} disabled={loading} />}
                        label="Enabled"
                        className={classes.switch}
                    />
                    <Collapse in={enabled}>
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: 16, paddingBottom: 16 }}>
                            <Divider style={{ marginLeft: -16, marginRight: -16, overflow: 'visible !important' }} />
                            <ButtonLargeItem
                                icon={copied ? <CheckIcon color="primary" /> : <ClipboardIcon />}
                                title={copied ? 'Copied!' : 'Copy Link'}
                                subtitle={`greekconnect.app/join/${code}`}
                                onClick={handleCopy}
                                disabled={loading || !enabled}
                            />
                            <ButtonLargeItem
                                icon={downloadedPDF ? <CheckIcon color="primary" /> : <UploadFileIcon />}
                                title={downloadedPDF ? 'Downloaded!' : 'Download PDF'}
                                subtitle="includes qr code & instructions"
                                onClick={downloadPDF}
                                disabled={loading || !enabled}
                            />
                        </Box>
                    </Collapse>
                </Box>
            </DialogContentMobile>
            <DialogActionsMobile>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    Close
                </Button>
            </DialogActionsMobile>
        </DialogMobile>
    )
}

export default JoinableLinkDialog