export default {
    type: 'Feature',
    properties: { name: 'Southwest' },
    geometry: {
        type: 'MultiPolygon',
        coordinates: [
            [
                [
                    [-114.813613, 32.494277],
                    [-114.250775, 32.32391],
                    [-113.493196, 32.088943],
                    [-113.333794, 32.038521],
                    [-113.125961, 31.97278],
                    [-112.867074, 31.895488],
                    [-112.246102, 31.704195],
                    [-111.57953, 31.494095],
                    [-111.366635, 31.42588],
                    [-111.098097, 31.339836],
                    [-110.976828, 31.33256],
                    [-110.760997, 31.332765],
                    [-110.460172, 31.333051],
                    [-109.426931, 31.334033],
                    [-109.050044, 31.332502],
                    [-108.861028, 31.332315],
                    [-108.851105, 31.332301],
                    [-108.208573, 31.333395],
                    [-108.208572, 31.499742],
                    [-108.208521, 31.499798],
                    [-108.208394, 31.783599],
                    [-107.422246, 31.783599],
                    [-107.296824, 31.783762],
                    [-107.00056, 31.783513],
                    [-107.00056, 31.783679],
                    [-106.998235, 31.783671],
                    [-106.993544, 31.783689],
                    [-106.750547, 31.783898],
                    [-106.750547, 31.783706],
                    [-106.528543, 31.783907],
                    [-106.472514, 31.751802],
                    [-106.436743, 31.756872],
                    [-106.41794, 31.752009],
                    [-106.381039, 31.73211],
                    [-106.356938, 31.70221],
                    [-106.254062, 31.547512],
                    [-106.218538, 31.47972],
                    [-106.212918, 31.473409],
                    [-106.2092, 31.469234],
                    [-106.207524, 31.467872],
                    [-106.206018, 31.466648],
                    [-106.202851, 31.464073],
                    [-106.132782, 31.425367],
                    [-106.099393, 31.41476],
                    [-106.00693, 31.392826],
                    [-105.997658, 31.386489],
                    [-105.981027, 31.375122],
                    [-105.923131, 31.312089],
                    [-105.900315, 31.300008],
                    [-105.869353, 31.288634],
                    [-105.793719, 31.201713],
                    [-105.750519, 31.16494],
                    [-105.709491, 31.136375],
                    [-105.648834, 31.115902],
                    [-105.60333, 31.082625],
                    [-105.585323, 31.057488],
                    [-105.579756, 31.036588],
                    [-105.57765, 31.019811],
                    [-105.55679, 30.989469],
                    [-105.411833, 30.893367],
                    [-105.394627, 30.85892],
                    [-105.265479, 30.802536],
                    [-105.250028, 30.799068],
                    [-105.211234, 30.799609],
                    [-105.129328, 30.750139],
                    [-105.044407, 30.683895],
                    [-104.985365, 30.623792],
                    [-104.972071, 30.61026],
                    [-104.929778, 30.59924],
                    [-104.872808, 30.500145],
                    [-104.859521, 30.390413],
                    [-104.78102, 30.314564],
                    [-104.761634, 30.301148],
                    [-104.708916, 30.235826],
                    [-104.687296, 30.179464],
                    [-104.685793, 30.092656],
                    [-104.693592, 30.019077],
                    [-104.679877, 29.920946],
                    [-104.674932, 29.911112],
                    [-104.667301, 29.910401],
                    [-104.633275, 29.870485],
                    [-104.566055, 29.771379],
                    [-104.549117, 29.728814],
                    [-104.549773, 29.716091],
                    [-104.538569, 29.677396],
                    [-104.515512, 29.641493],
                    [-104.394351, 29.560535],
                    [-104.321603, 29.527923],
                    [-104.290728, 29.519326],
                    [-104.264155, 29.514001],
                    [-104.235847, 29.496744],
                    [-104.21341, 29.462069],
                    [-104.167506, 29.402928],
                    [-104.143692, 29.383278],
                    [-104.025488, 29.314404],
                    [-103.963076, 29.298404],
                    [-103.924976, 29.293913],
                    [-103.838303, 29.278304],
                    [-103.792705, 29.262931],
                    [-103.792005, 29.262695],
                    [-103.767814, 29.224954],
                    [-103.714867, 29.184137],
                    [-103.627067, 29.163336],
                    [-103.61007, 29.165727],
                    [-103.558679, 29.154962],
                    [-103.525471, 29.137744],
                    [-103.503236, 29.11911],
                    [-103.470518, 29.085108],
                    [-103.471037, 29.075368],
                    [-103.383508, 29.024112],
                    [-103.281903, 28.990089],
                    [-103.163865, 28.972099],
                    [-103.156646, 28.972831],
                    [-103.115328, 28.98527],
                    [-103.10443, 29.03359],
                    [-102.995688, 29.161219],
                    [-102.95089, 29.176835],
                    [-102.87802, 29.214698],
                    [-102.866846, 29.225015],
                    [-102.871347, 29.241625],
                    [-102.881135, 29.246022],
                    [-102.886308, 29.24568],
                    [-102.895886, 29.284778],
                    [-102.875813, 29.353715],
                    [-102.83332, 29.408981],
                    [-102.831938, 29.437943],
                    [-102.808692, 29.522319],
                    [-102.781807, 29.542454],
                    [-102.745494, 29.592967],
                    [-102.698347, 29.695591],
                    [-102.689738, 29.722349],
                    [-102.665194, 29.737421],
                    [-102.612879, 29.748182],
                    [-102.554915, 29.748966],
                    [-102.500741, 29.781446],
                    [-102.486819, 29.786725],
                    [-102.434063, 29.7767],
                    [-102.404422, 29.765105],
                    [-102.386677, 29.76148],
                    [-102.364647, 29.843624],
                    [-102.323389, 29.875193],
                    [-102.320819, 29.875193],
                    [-102.297331, 29.875194],
                    [-102.282247, 29.863598],
                    [-102.262466, 29.853765],
                    [-102.116076, 29.792527],
                    [-102.050044, 29.78507],
                    [-102.040539, 29.790061],
                    [-101.944913, 29.801468],
                    [-101.883655, 29.796193],
                    [-101.786353, 29.782047],
                    [-101.761839, 29.777187],
                    [-101.646418, 29.754304],
                    [-101.635128, 29.758675],
                    [-101.625958, 29.771063],
                    [-101.455007, 29.770188],
                    [-101.403926, 29.757587],
                    [-101.343743, 29.662047],
                    [-101.314135, 29.659054],
                    [-101.250383, 29.624171],
                    [-101.247319, 29.618482],
                    [-101.235275, 29.524854],
                    [-101.137503, 29.473542],
                    [-101.087149, 29.469414],
                    [-101.060151, 29.458661],
                    [-101.043364, 29.42988],
                    [-101.037642, 29.414681],
                    [-100.995607, 29.363403],
                    [-100.954042, 29.347598],
                    [-100.797671, 29.246943],
                    [-100.793662, 29.242146],
                    [-100.674656, 29.099777],
                    [-100.668356, 29.08397],
                    [-100.664065, 29.073206],
                    [-100.645894, 28.986421],
                    [-100.648104, 28.98386],
                    [-100.651512, 28.943432],
                    [-100.640092, 28.915833],
                    [-100.589854, 28.861618],
                    [-100.546804, 28.825374],
                    [-100.535438, 28.805195],
                    [-100.507613, 28.740599],
                    [-100.506701, 28.716745],
                    [-100.511998, 28.705352],
                    [-100.510055, 28.690723],
                    [-100.500354, 28.66196],
                    [-100.446659, 28.608659],
                    [-100.429856, 28.596441],
                    [-100.405058, 28.53578],
                    [-100.386963, 28.514023],
                    [-100.338518, 28.501833],
                    [-100.333814, 28.499252],
                    [-100.346986, 28.407232],
                    [-100.291391, 28.275398],
                    [-100.257788, 28.240341],
                    [-100.209464, 28.196866],
                    [-100.202448, 28.190554],
                    [-100.171323, 28.17623],
                    [-100.14414, 28.168238],
                    [-100.088143, 28.147503],
                    [-100.083393, 28.144035],
                    [-100.080868, 28.137192],
                    [-100.057049, 28.095656],
                    [-99.991447, 27.99456],
                    [-99.922046, 27.922119],
                    [-99.895828, 27.904178],
                    [-99.904385, 27.875284],
                    [-99.901486, 27.864162],
                    [-99.876174, 27.806276],
                    [-99.843463, 27.77456],
                    [-99.666175, 27.638324],
                    [-99.627123, 27.638412],
                    [-99.626294, 27.64245],
                    [-99.623478, 27.644136],
                    [-99.600619, 27.641314],
                    [-99.541644, 27.605772],
                    [-99.519381, 27.573569],
                    [-99.480219, 27.485796],
                    [-99.487704, 27.409924],
                    [-99.492831, 27.390491],
                    [-99.50696, 27.35642],
                    [-99.537771, 27.316073],
                    [-99.536443, 27.312538],
                    [-99.529654, 27.306051],
                    [-99.494678, 27.277595],
                    [-99.47088, 27.265444],
                    [-99.463309, 27.268437],
                    [-99.453949, 27.264673],
                    [-99.452391, 27.264046],
                    [-99.441549, 27.24992],
                    [-99.426348, 27.176262],
                    [-99.429879, 27.094323],
                    [-99.446593, 27.070593],
                    [-99.451035, 27.066765],
                    [-99.452316, 27.062669],
                    [-99.446524, 27.023008],
                    [-99.379149, 26.93449],
                    [-99.321819, 26.906846],
                    [-99.268613, 26.843213],
                    [-99.242444, 26.788262],
                    [-99.208951, 26.689489],
                    [-99.172058, 26.57086],
                    [-99.167996, 26.557799],
                    [-99.105719, 26.43872],
                    [-99.047575, 26.406922],
                    [-98.861354, 26.36599],
                    [-98.822373, 26.370902],
                    [-98.807348, 26.369421],
                    [-98.751164, 26.331307],
                    [-98.705676, 26.27748],
                    [-98.6329, 26.250348],
                    [-98.623335, 26.259592],
                    [-98.58672, 26.257456],
                    [-98.58581, 26.257403],
                    [-98.466167, 26.223245],
                    [-98.438822, 26.212022],
                    [-98.386694, 26.157872],
                    [-98.264514, 26.085507],
                    [-98.241664, 26.074544],
                    [-98.132605, 26.057507],
                    [-98.044517, 26.043746],
                    [-98.011883, 26.056608],
                    [-98.008307, 26.061147],
                    [-97.888699, 26.064931],
                    [-97.863056, 26.061273],
                    [-97.671568, 26.033955],
                    [-97.668099, 26.022522],
                    [-97.582565, 25.937857],
                    [-97.511161, 25.887458],
                    [-97.496861, 25.880058],
                    [-97.408357, 25.856459],
                    [-97.366723, 25.885066],
                    [-97.367045, 25.903021],
                    [-97.362345, 25.915724],
                    [-97.348275, 25.931143],
                    [-97.271382, 25.951832],
                    [-97.228025, 25.958936],
                    [-97.172633, 25.962883],
                    [-97.145567, 25.971132],
                    [-97.151922, 26.017653],
                    [-97.15321, 26.038906],
                    [-97.152009, 26.062108],
                    [-97.164982, 26.063876],
                    [-97.18273, 26.053126],
                    [-97.178659, 26.045492],
                    [-97.172043, 26.044729],
                    [-97.162628, 26.023482],
                    [-97.162755, 26.014576],
                    [-97.167208, 26.007069],
                    [-97.17446, 26.000072],
                    [-97.195834, 25.993074],
                    [-97.208557, 25.991802],
                    [-97.216954, 25.993838],
                    [-97.219244, 25.996128],
                    [-97.226114, 26.024372],
                    [-97.224842, 26.027426],
                    [-97.214918, 26.030734],
                    [-97.204995, 26.030225],
                    [-97.195071, 26.04193],
                    [-97.199651, 26.077044],
                    [-97.208048, 26.079589],
                    [-97.24698, 26.080352],
                    [-97.270898, 26.086459],
                    [-97.279804, 26.092057],
                    [-97.295072, 26.108342],
                    [-97.294054, 26.11394],
                    [-97.283112, 26.117757],
                    [-97.282094, 26.120301],
                    [-97.28536, 26.128378],
                    [-97.296598, 26.142439],
                    [-97.306776, 26.159487],
                    [-97.296089, 26.182388],
                    [-97.294817, 26.192312],
                    [-97.296598, 26.200709],
                    [-97.304486, 26.20249],
                    [-97.32128, 26.228699],
                    [-97.32128, 26.236078],
                    [-97.308049, 26.249055],
                    [-97.307031, 26.253126],
                    [-97.311866, 26.273737],
                    [-97.322807, 26.271956],
                    [-97.331967, 26.265595],
                    [-97.341128, 26.265595],
                    [-97.343927, 26.267376],
                    [-97.347489, 26.292821],
                    [-97.34698, 26.311396],
                    [-97.354359, 26.313941],
                    [-97.352833, 26.318521],
                    [-97.336802, 26.331753],
                    [-97.330441, 26.350582],
                    [-97.335275, 26.355672],
                    [-97.343418, 26.359234],
                    [-97.358176, 26.356435],
                    [-97.369372, 26.348547],
                    [-97.372171, 26.339895],
                    [-97.376242, 26.336333],
                    [-97.387947, 26.330481],
                    [-97.391001, 26.332262],
                    [-97.392019, 26.339386],
                    [-97.388965, 26.36585],
                    [-97.374461, 26.380862],
                    [-97.369627, 26.394603],
                    [-97.377769, 26.409107],
                    [-97.382485, 26.411326],
                    [-97.395072, 26.417249],
                    [-97.394309, 26.41445],
                    [-97.398126, 26.410888],
                    [-97.406014, 26.409107],
                    [-97.4195, 26.413178],
                    [-97.42179, 26.417249],
                    [-97.412884, 26.433026],
                    [-97.411612, 26.447275],
                    [-97.41721, 26.44982],
                    [-97.421026, 26.446766],
                    [-97.425861, 26.446003],
                    [-97.437566, 26.44982],
                    [-97.441383, 26.455418],
                    [-97.441383, 26.466614],
                    [-97.43553, 26.470176],
                    [-97.429169, 26.478064],
                    [-97.42637, 26.484425],
                    [-97.430696, 26.494603],
                    [-97.430696, 26.506563],
                    [-97.425861, 26.516741],
                    [-97.422299, 26.520303],
                    [-97.416955, 26.553637],
                    [-97.428151, 26.572466],
                    [-97.441206, 26.599901],
                    [-97.445708, 26.609362],
                    [-97.444945, 26.633535],
                    [-97.467337, 26.710126],
                    [-97.468609, 26.740915],
                    [-97.471663, 26.758727],
                    [-97.478024, 26.7572],
                    [-97.484385, 26.763562],
                    [-97.509831, 26.803511],
                    [-97.537566, 26.824885],
                    [-97.547745, 26.824631],
                    [-97.552579, 26.827938],
                    [-97.563266, 26.842188],
                    [-97.558432, 26.864325],
                    [-97.552325, 26.867633],
                    [-97.552325, 26.888499],
                    [-97.547999, 26.895114],
                    [-97.540111, 26.900967],
                    [-97.540874, 26.90631],
                    [-97.555378, 26.93888],
                    [-97.555378, 26.947277],
                    [-97.552325, 26.952112],
                    [-97.549526, 26.965344],
                    [-97.551053, 26.980865],
                    [-97.555378, 26.99028],
                    [-97.549271, 26.995878],
                    [-97.536803, 26.999695],
                    [-97.484131, 27.000458],
                    [-97.480569, 26.997659],
                    [-97.478533, 26.999186],
                    [-97.473444, 27.02285],
                    [-97.473953, 27.029212],
                    [-97.477515, 27.03252],
                    [-97.486676, 27.03481],
                    [-97.487693, 27.053639],
                    [-97.48693, 27.057711],
                    [-97.482257, 27.061942],
                    [-97.479042, 27.0628],
                    [-97.477515, 27.066108],
                    [-97.493291, 27.078067],
                    [-97.495836, 27.094098],
                    [-97.49151, 27.101222],
                    [-97.480569, 27.102494],
                    [-97.47548, 27.098423],
                    [-97.461739, 27.095624],
                    [-97.45665, 27.099695],
                    [-97.455887, 27.110383],
                    [-97.452324, 27.115217],
                    [-97.443673, 27.116235],
                    [-97.444945, 27.144734],
                    [-97.434767, 27.202241],
                    [-97.422299, 27.257712],
                    [-97.42408, 27.264073],
                    [-97.450289, 27.262546],
                    [-97.458431, 27.259493],
                    [-97.467083, 27.25364],
                    [-97.485149, 27.250841],
                    [-97.500162, 27.24448],
                    [-97.503215, 27.2399],
                    [-97.509831, 27.23532],
                    [-97.520009, 27.231248],
                    [-97.54291, 27.229213],
                    [-97.561231, 27.232775],
                    [-97.573953, 27.238882],
                    [-97.582605, 27.240409],
                    [-97.597363, 27.242444],
                    [-97.628916, 27.242953],
                    [-97.635022, 27.247024],
                    [-97.639094, 27.253131],
                    [-97.640111, 27.270943],
                    [-97.63668, 27.281727],
                    [-97.636549, 27.282139],
                    [-97.63146, 27.28621],
                    [-97.621791, 27.287228],
                    [-97.609068, 27.285193],
                    [-97.584132, 27.30962],
                    [-97.5709, 27.315727],
                    [-97.53833, 27.335574],
                    [-97.520518, 27.352877],
                    [-97.514411, 27.361529],
                    [-97.501688, 27.366618],
                    [-97.48693, 27.358984],
                    [-97.483877, 27.351351],
                    [-97.483877, 27.338628],
                    [-97.499144, 27.327941],
                    [-97.502706, 27.322343],
                    [-97.498126, 27.308602],
                    [-97.504742, 27.30504],
                    [-97.517465, 27.30504],
                    [-97.524589, 27.297915],
                    [-97.526625, 27.291808],
                    [-97.536803, 27.289264],
                    [-97.546981, 27.290791],
                    [-97.544437, 27.284175],
                    [-97.532223, 27.278577],
                    [-97.508304, 27.275014],
                    [-97.482859, 27.297915],
                    [-97.450798, 27.313691],
                    [-97.430441, 27.313691],
                    [-97.420263, 27.317254],
                    [-97.413138, 27.321325],
                    [-97.404996, 27.329977],
                    [-97.401942, 27.335574],
                    [-97.399398, 27.344735],
                    [-97.37955, 27.390028],
                    [-97.372935, 27.401224],
                    [-97.369881, 27.41242],
                    [-97.371917, 27.425142],
                    [-97.36581, 27.450588],
                    [-97.359194, 27.458221],
                    [-97.350543, 27.478578],
                    [-97.347489, 27.503005],
                    [-97.343418, 27.517764],
                    [-97.336802, 27.527433],
                    [-97.325118, 27.560927],
                    [-97.321535, 27.571199],
                    [-97.302196, 27.585957],
                    [-97.294054, 27.5941],
                    [-97.297616, 27.59868],
                    [-97.298634, 27.604787],
                    [-97.296598, 27.613947],
                    [-97.266064, 27.678579],
                    [-97.259957, 27.679597],
                    [-97.253955, 27.696696],
                    [-97.316446, 27.712676],
                    [-97.34698, 27.725398],
                    [-97.368355, 27.741683],
                    [-97.386166, 27.76662],
                    [-97.393291, 27.782905],
                    [-97.391764, 27.813948],
                    [-97.379042, 27.837867],
                    [-97.361246, 27.849891],
                    [-97.360547, 27.850363],
                    [-97.360212, 27.85059],
                    [-97.354614, 27.849572],
                    [-97.327494, 27.866405],
                    [-97.325097, 27.867893],
                    [-97.317892, 27.870335],
                    [-97.301455, 27.875907],
                    [-97.295072, 27.878071],
                    [-97.285466, 27.879672],
                    [-97.276632, 27.881144],
                    [-97.273698, 27.881633],
                    [-97.272253, 27.881427],
                    [-97.26301, 27.880106],
                    [-97.258305, 27.878538],
                    [-97.256394, 27.877901],
                    [-97.250797, 27.876035],
                    [-97.242922, 27.865208],
                    [-97.242654, 27.864839],
                    [-97.241393, 27.858953],
                    [-97.241127, 27.857714],
                    [-97.234512, 27.849063],
                    [-97.228388, 27.843661],
                    [-97.226514, 27.838307],
                    [-97.227317, 27.832952],
                    [-97.227317, 27.829204],
                    [-97.226012, 27.827083],
                    [-97.225811, 27.826755],
                    [-97.225435, 27.826145],
                    [-97.225176, 27.825723],
                    [-97.222957, 27.824919],
                    [-97.222212, 27.824649],
                    [-97.220771, 27.824126],
                    [-97.219738, 27.823939],
                    [-97.211226, 27.822391],
                    [-97.209575, 27.822091],
                    [-97.196852, 27.822091],
                    [-97.193701, 27.822754],
                    [-97.192874, 27.822928],
                    [-97.188866, 27.823772],
                    [-97.187183, 27.824126],
                    [-97.186871, 27.825001],
                    [-97.186709, 27.825453],
                    [-97.184639, 27.831251],
                    [-97.183455, 27.833231],
                    [-97.155122, 27.880615],
                    [-97.144435, 27.894356],
                    [-97.136615, 27.898956],
                    [-97.135783, 27.899445],
                    [-97.134801, 27.902467],
                    [-97.129168, 27.919801],
                    [-97.121534, 27.923364],
                    [-97.075732, 27.986977],
                    [-97.061992, 27.996138],
                    [-97.04876, 28.022092],
                    [-97.040618, 28.028708],
                    [-97.030948, 28.033288],
                    [-97.025859, 28.041939],
                    [-97.031457, 28.053644],
                    [-97.035528, 28.074],
                    [-97.035528, 28.084688],
                    [-97.031966, 28.093848],
                    [-97.023824, 28.103517],
                    [-97.022806, 28.107588],
                    [-97.028913, 28.117258],
                    [-97.027386, 28.124382],
                    [-97.007539, 28.136087],
                    [-97.000414, 28.137614],
                    [-96.962755, 28.123365],
                    [-96.934765, 28.123873],
                    [-96.910337, 28.147283],
                    [-96.898123, 28.152881],
                    [-96.872678, 28.176291],
                    [-96.842143, 28.193594],
                    [-96.810037, 28.217086],
                    [-96.800413, 28.224128],
                    [-96.787181, 28.250083],
                    [-96.787181, 28.255681],
                    [-96.799302, 28.272716],
                    [-96.806011, 28.282144],
                    [-96.809573, 28.290287],
                    [-96.806011, 28.296902],
                    [-96.791761, 28.31217],
                    [-96.791159, 28.319095],
                    [-96.790744, 28.323874],
                    [-96.79254, 28.336705],
                    [-96.793765, 28.345453],
                    [-96.793835, 28.345959],
                    [-96.793926, 28.346606],
                    [-96.794016, 28.347249],
                    [-96.794306, 28.34932],
                    [-96.794357, 28.350865],
                    [-96.794812, 28.364503],
                    [-96.794812, 28.364515],
                    [-96.794815, 28.364587],
                    [-96.794796, 28.364668],
                    [-96.794554, 28.365688],
                    [-96.790235, 28.383926],
                    [-96.780796, 28.398421],
                    [-96.775985, 28.405809],
                    [-96.772209, 28.408074],
                    [-96.768352, 28.410389],
                    [-96.76696, 28.410737],
                    [-96.762245, 28.411916],
                    [-96.749013, 28.408862],
                    [-96.72255, 28.408862],
                    [-96.710336, 28.406827],
                    [-96.705756, 28.400211],
                    [-96.700158, 28.369676],
                    [-96.705247, 28.348811],
                    [-96.698122, 28.342704],
                    [-96.69456, 28.347284],
                    [-96.688453, 28.347284],
                    [-96.672677, 28.335579],
                    [-96.650794, 28.346775],
                    [-96.600412, 28.354409],
                    [-96.59176, 28.357462],
                    [-96.570386, 28.368658],
                    [-96.559699, 28.377819],
                    [-96.542905, 28.385452],
                    [-96.520513, 28.394104],
                    [-96.504737, 28.397666],
                    [-96.481836, 28.407844],
                    [-96.46148, 28.421585],
                    [-96.403973, 28.44245],
                    [-96.402446, 28.449066],
                    [-96.410589, 28.459244],
                    [-96.419749, 28.467387],
                    [-96.450284, 28.490796],
                    [-96.505755, 28.525911],
                    [-96.514406, 28.535071],
                    [-96.52204, 28.55441],
                    [-96.526111, 28.557972],
                    [-96.536289, 28.559499],
                    [-96.561226, 28.570695],
                    [-96.564279, 28.576293],
                    [-96.565297, 28.5824],
                    [-96.573949, 28.584436],
                    [-96.608045, 28.583418],
                    [-96.611099, 28.585962],
                    [-96.622804, 28.622095],
                    [-96.61975, 28.627693],
                    [-96.61059, 28.636344],
                    [-96.61059, 28.638889],
                    [-96.615679, 28.644487],
                    [-96.623313, 28.649576],
                    [-96.627893, 28.650085],
                    [-96.634, 28.654665],
                    [-96.635018, 28.668914],
                    [-96.657918, 28.687744],
                    [-96.664534, 28.696904],
                    [-96.655528, 28.704167],
                    [-96.648758, 28.709627],
                    [-96.645867, 28.710457],
                    [-96.634912, 28.713601],
                    [-96.634419, 28.713743],
                    [-96.604921, 28.72221],
                    [-96.593796, 28.725403],
                    [-96.591647, 28.724838],
                    [-96.584196, 28.722877],
                    [-96.584127, 28.722859],
                    [-96.584091, 28.722798],
                    [-96.580564, 28.716752],
                    [-96.579669, 28.712455],
                    [-96.579639, 28.712312],
                    [-96.57802, 28.704538],
                    [-96.577727, 28.704365],
                    [-96.575141, 28.702837],
                    [-96.566824, 28.697922],
                    [-96.561226, 28.696395],
                    [-96.559699, 28.691306],
                    [-96.55919, 28.687235],
                    [-96.570386, 28.674003],
                    [-96.572931, 28.667897],
                    [-96.563262, 28.644487],
                    [-96.555119, 28.646013],
                    [-96.54545, 28.645505],
                    [-96.506264, 28.63838],
                    [-96.499648, 28.635835],
                    [-96.496595, 28.630746],
                    [-96.497612, 28.625148],
                    [-96.510335, 28.617515],
                    [-96.510844, 28.61497],
                    [-96.490488, 28.610899],
                    [-96.485907, 28.607845],
                    [-96.480309, 28.596649],
                    [-96.482854, 28.580364],
                    [-96.487943, 28.569677],
                    [-96.473694, 28.57324],
                    [-96.384635, 28.615988],
                    [-96.376492, 28.620059],
                    [-96.373439, 28.626675],
                    [-96.328655, 28.640924],
                    [-96.322921, 28.64186],
                    [-96.303718, 28.644996],
                    [-96.305245, 28.660263],
                    [-96.304227, 28.671459],
                    [-96.287942, 28.683164],
                    [-96.268604, 28.688762],
                    [-96.263515, 28.683673],
                    [-96.256899, 28.68469],
                    [-96.231453, 28.696395],
                    [-96.222802, 28.698431],
                    [-96.202446, 28.700976],
                    [-96.19583, 28.69894],
                    [-96.19125, 28.69436],
                    [-96.192267, 28.687744],
                    [-96.214659, 28.665352],
                    [-96.208552, 28.662298],
                    [-96.230944, 28.641433],
                    [-96.212624, 28.622604],
                    [-96.21415, 28.613443],
                    [-96.222293, 28.607336],
                    [-96.233998, 28.601738],
                    [-96.233998, 28.596649],
                    [-96.228909, 28.580873],
                    [-96.221784, 28.580364],
                    [-96.198374, 28.58698],
                    [-96.187178, 28.593596],
                    [-96.148501, 28.611408],
                    [-96.099137, 28.624639],
                    [-96.072165, 28.635326],
                    [-96.047737, 28.649067],
                    [-96.033488, 28.652629],
                    [-96.006516, 28.648049],
                    [-96.002954, 28.656192],
                    [-95.996338, 28.658736],
                    [-95.986066, 28.655468],
                    [-95.978526, 28.650594],
                    [-95.983106, 28.641942],
                    [-95.985651, 28.621077],
                    [-95.982088, 28.614461],
                    [-95.98616, 28.606319],
                    [-96.007534, 28.599703],
                    [-96.032979, 28.589016],
                    [-96.046211, 28.58698],
                    [-96.104735, 28.559499],
                    [-96.145448, 28.544741],
                    [-96.21505, 28.509679],
                    [-96.218978, 28.500383],
                    [-96.223825, 28.495067],
                    [-96.268341, 28.477992],
                    [-96.335119, 28.437795],
                    [-96.374138, 28.404275],
                    [-96.37596, 28.401682],
                    [-96.381864, 28.393276],
                    [-96.379372, 28.386089],
                    [-96.378616, 28.383909],
                    [-96.370717, 28.387667],
                    [-96.372101, 28.393875],
                    [-96.371117, 28.397661],
                    [-96.341617, 28.417334],
                    [-96.328817, 28.423659],
                    [-96.32156, 28.425148],
                    [-96.303212, 28.441871],
                    [-96.270391, 28.46193],
                    [-96.244751, 28.475055],
                    [-96.220376, 28.491966],
                    [-96.194412, 28.502224],
                    [-96.077868, 28.556626],
                    [-96.000682, 28.588238],
                    [-95.884026, 28.633098],
                    [-95.812504, 28.664942],
                    [-95.568136, 28.789998],
                    [-95.507041, 28.824755],
                    [-95.486769, 28.836287],
                    [-95.439594, 28.859022],
                    [-95.416174, 28.859482],
                    [-95.38239, 28.866348],
                    [-95.376979, 28.87616],
                    [-95.353451, 28.898145],
                    [-95.309704, 28.928262],
                    [-95.297147, 28.934073],
                    [-95.272266, 28.961546],
                    [-95.238924, 28.988644],
                    [-95.191391, 29.02309],
                    [-95.125134, 29.067321],
                    [-95.122525, 29.074],
                    [-95.119271, 29.077844],
                    [-95.110484, 29.088224],
                    [-95.081773, 29.111222],
                    [-95.026219, 29.148064],
                    [-94.803695, 29.279237],
                    [-94.786095, 29.290737],
                    [-94.769695, 29.304936],
                    [-94.731082, 29.331833],
                    [-94.72253, 29.331446],
                    [-94.73132, 29.338066],
                    [-94.744945, 29.33641],
                    [-94.745529, 29.334235],
                    [-94.777064, 29.336811],
                    [-94.779995, 29.334935],
                    [-94.784895, 29.335535],
                    [-94.810696, 29.353435],
                    [-94.822307, 29.344254],
                    [-94.822547, 29.321882],
                    [-94.824953, 29.306005],
                    [-94.84973, 29.297345],
                    [-94.865126, 29.293977],
                    [-94.875952, 29.293015],
                    [-94.886536, 29.297826],
                    [-94.893994, 29.30817],
                    [-94.894234, 29.338],
                    [-94.886536, 29.366386],
                    [-94.8873, 29.415132],
                    [-94.8908, 29.433432],
                    [-94.919401, 29.448031],
                    [-94.930861, 29.450504],
                    [-94.925914, 29.469047],
                    [-94.913385, 29.487254],
                    [-94.909465, 29.496838],
                    [-94.909898, 29.49691],
                    [-94.933039, 29.500773],
                    [-94.933483, 29.500847],
                    [-94.934876, 29.501079],
                    [-94.958443, 29.505013],
                    [-94.981916, 29.511141],
                    [-94.999581, 29.521093],
                    [-95.013378, 29.537822],
                    [-95.015165, 29.539989],
                    [-95.016889, 29.548303],
                    [-95.018191, 29.554584],
                    [-95.018198, 29.554618],
                    [-95.018253, 29.554885],
                    [-95.016672, 29.558388],
                    [-95.016627, 29.558487],
                    [-95.016353, 29.55897],
                    [-95.016145, 29.559336],
                    [-95.00767, 29.574257],
                    [-94.988993, 29.591155],
                    [-94.982923, 29.600992],
                    [-94.982855, 29.601103],
                    [-94.982706, 29.601344],
                    [-94.982936, 29.60167],
                    [-94.984831, 29.604361],
                    [-94.988871, 29.610095],
                    [-94.999082, 29.618014],
                    [-94.999481, 29.618323],
                    [-95.00046, 29.619082],
                    [-95.000781, 29.619331],
                    [-95.01166, 29.627768],
                    [-95.013499, 29.629194],
                    [-95.013623, 29.62979],
                    [-95.0156, 29.639285],
                    [-95.015636, 29.639457],
                    [-95.013777, 29.644322],
                    [-95.01272, 29.647088],
                    [-95.011683, 29.649802],
                    [-95.011025, 29.650803],
                    [-95.007623, 29.65598],
                    [-95.006381, 29.657871],
                    [-95.005648, 29.658985],
                    [-95.005398, 29.659366],
                    [-94.988924, 29.672202],
                    [-94.972666, 29.68487],
                    [-94.965963, 29.70033],
                    [-94.942681, 29.697778],
                    [-94.936089, 29.692704],
                    [-94.934167, 29.678682],
                    [-94.931154, 29.673874],
                    [-94.921318, 29.658178],
                    [-94.915413, 29.656614],
                    [-94.893107, 29.661336],
                    [-94.872551, 29.67125],
                    [-94.867438, 29.678768],
                    [-94.865007, 29.695337],
                    [-94.851108, 29.721373],
                    [-94.816085, 29.75671],
                    [-94.792238, 29.767433],
                    [-94.771512, 29.773889],
                    [-94.740919, 29.787081],
                    [-94.735271, 29.785433],
                    [-94.724616, 29.774766],
                    [-94.695317, 29.723052],
                    [-94.692434, 29.70361],
                    [-94.693154, 29.694453],
                    [-94.708741, 29.625226],
                    [-94.734626, 29.584046],
                    [-94.755237, 29.562782],
                    [-94.771053, 29.548439],
                    [-94.779439, 29.549472],
                    [-94.790605, 29.548401],
                    [-94.78954, 29.546494],
                    [-94.786649, 29.541319],
                    [-94.786512, 29.541073],
                    [-94.784034, 29.536635],
                    [-94.783296, 29.535314],
                    [-94.780938, 29.531093],
                    [-94.779831, 29.530602],
                    [-94.779708, 29.530548],
                    [-94.779674, 29.530533],
                    [-94.774798, 29.528372],
                    [-94.774571, 29.528271],
                    [-94.77084, 29.526618],
                    [-94.769573, 29.526057],
                    [-94.769418, 29.525988],
                    [-94.768676, 29.525659],
                    [-94.767246, 29.525523],
                    [-94.757883, 29.524635],
                    [-94.757788, 29.524626],
                    [-94.757689, 29.524617],
                    [-94.755514, 29.524776],
                    [-94.740699, 29.525858],
                    [-94.718276, 29.533547],
                    [-94.691625, 29.537787],
                    [-94.689353, 29.538306],
                    [-94.682706, 29.539825],
                    [-94.62589, 29.552808],
                    [-94.621953, 29.553843],
                    [-94.621819, 29.553878],
                    [-94.618028, 29.554875],
                    [-94.610147, 29.556947],
                    [-94.593518, 29.561319],
                    [-94.578211, 29.567281],
                    [-94.570867, 29.571713],
                    [-94.570593, 29.571878],
                    [-94.570006, 29.572232],
                    [-94.553988, 29.573882],
                    [-94.546385, 29.572048],
                    [-94.542532, 29.569],
                    [-94.541108, 29.567561],
                    [-94.532021, 29.558379],
                    [-94.526336, 29.552634],
                    [-94.523743, 29.545987],
                    [-94.522421, 29.545672],
                    [-94.511086, 29.542971],
                    [-94.509487, 29.54259],
                    [-94.503429, 29.54325],
                    [-94.495025, 29.525031],
                    [-94.511045, 29.51965],
                    [-94.532348, 29.5178],
                    [-94.546994, 29.524379],
                    [-94.55399, 29.529559],
                    [-94.566674, 29.531988],
                    [-94.580274, 29.525295],
                    [-94.591407, 29.513858],
                    [-94.59544, 29.507669],
                    [-94.594211, 29.492127],
                    [-94.608557, 29.483345],
                    [-94.628217, 29.475986],
                    [-94.645948, 29.473769],
                    [-94.656737, 29.478033],
                    [-94.665853, 29.478401],
                    [-94.6724, 29.476843],
                    [-94.681541, 29.471389],
                    [-94.686386, 29.466509],
                    [-94.706365, 29.436805],
                    [-94.723818, 29.426536],
                    [-94.7541, 29.401],
                    [-94.766848, 29.393489],
                    [-94.783131, 29.375642],
                    [-94.782356, 29.364266],
                    [-94.778691, 29.361483],
                    [-94.761491, 29.361883],
                    [-94.744834, 29.369158],
                    [-94.731047, 29.369141],
                    [-94.723959, 29.383268],
                    [-94.708473, 29.403049],
                    [-94.694158, 29.415632],
                    [-94.670389, 29.43078],
                    [-94.631084, 29.451464],
                    [-94.594853, 29.467903],
                    [-94.500807, 29.505367],
                    [-94.370866, 29.55507],
                    [-94.354163, 29.561459],
                    [-94.132577, 29.646217],
                    [-94.056506, 29.671163],
                    [-94.001406, 29.681486],
                    [-93.931, 29.679612],
                    [-93.88999, 29.674013],
                    [-93.866981, 29.673085],
                    [-93.852868, 29.675885],
                    [-93.837971, 29.690619],
                    [-93.892246, 29.765241],
                    [-93.89847, 29.771577],
                    [-93.890679, 29.843159],
                    [-93.840799, 29.914423],
                    [-93.789431, 29.987812],
                    [-93.75663, 30.014163],
                    [-93.752038, 30.016403],
                    [-93.722481, 30.050898],
                    [-93.721589, 30.051939],
                    [-93.712101, 30.067346],
                    [-93.695684, 30.135729],
                    [-93.695252, 30.1476],
                    [-93.712008, 30.194304],
                    [-93.716223, 30.244318],
                    [-93.720575, 30.295961],
                    [-93.723586, 30.294951],
                    [-93.735896, 30.29944],
                    [-93.765822, 30.333318],
                    [-93.757654, 30.390423],
                    [-93.751437, 30.396288],
                    [-93.741701, 30.403007],
                    [-93.702665, 30.429947],
                    [-93.698302, 30.438657],
                    [-93.697828, 30.443838],
                    [-93.710117, 30.5064],
                    [-93.740045, 30.538765],
                    [-93.727844, 30.57407],
                    [-93.578395, 30.802047],
                    [-93.563292, 30.818503],
                    [-93.558672, 30.868829],
                    [-93.558617, 30.869424],
                    [-93.55497, 30.876685],
                    [-93.554576, 30.87747],
                    [-93.530936, 30.924534],
                    [-93.540354, 31.008135],
                    [-93.527644, 31.074509],
                    [-93.531744, 31.180817],
                    [-93.535097, 31.185614],
                    [-93.548931, 31.186601],
                    [-93.55254, 31.185605],
                    [-93.552649, 31.185575],
                    [-93.579215, 31.167422],
                    [-93.588503, 31.165581],
                    [-93.598828, 31.174679],
                    [-93.599705, 31.176456],
                    [-93.602315, 31.181742],
                    [-93.607243, 31.204806],
                    [-93.608158, 31.227835],
                    [-93.614402, 31.260869],
                    [-93.620829, 31.271299],
                    [-93.644407, 31.27711],
                    [-93.671676, 31.299586],
                    [-93.640805, 31.372546],
                    [-93.670182, 31.387184],
                    [-93.695866, 31.409392],
                    [-93.729613, 31.487922],
                    [-93.798087, 31.534044],
                    [-93.818582, 31.554826],
                    [-93.834923, 31.58621],
                    [-93.834924, 31.586211],
                    [-93.82629, 31.614903],
                    [-93.817059, 31.671694],
                    [-93.812477, 31.715246],
                    [-93.840029, 31.800596],
                    [-93.878225, 31.844276],
                    [-93.889193, 31.856819],
                    [-93.92029, 31.888651],
                    [-93.932135, 31.893672],
                    [-93.975377, 31.92366],
                    [-94.01563, 31.979856],
                    [-94.04272, 31.999265],
                    [-94.0427, 32.056012],
                    [-94.042337, 32.119914],
                    [-94.042681, 32.137956],
                    [-94.042591, 32.158097],
                    [-94.042539, 32.166826],
                    [-94.042566, 32.166894],
                    [-94.042621, 32.196005],
                    [-94.042662, 32.218146],
                    [-94.042732, 32.26962],
                    [-94.042733, 32.269696],
                    [-94.042739, 32.363559],
                    [-94.042763, 32.373332],
                    [-94.042901, 32.392283],
                    [-94.042923, 32.399918],
                    [-94.042899, 32.400659],
                    [-94.042986, 32.435507],
                    [-94.042908, 32.439891],
                    [-94.042903, 32.470386],
                    [-94.042875, 32.471348],
                    [-94.042902, 32.472906],
                    [-94.042995, 32.478004],
                    [-94.042955, 32.480261],
                    [-94.043072, 32.4843],
                    [-94.043089, 32.486561],
                    [-94.042911, 32.492852],
                    [-94.042885, 32.505145],
                    [-94.043081, 32.513613],
                    [-94.043142, 32.559502],
                    [-94.043083, 32.564261],
                    [-94.042919, 32.610142],
                    [-94.042929, 32.61826],
                    [-94.042926, 32.622015],
                    [-94.042824, 32.640305],
                    [-94.04278, 32.643466],
                    [-94.042913, 32.655127],
                    [-94.043147, 32.69303],
                    [-94.043147, 32.693031],
                    [-94.042947, 32.767991],
                    [-94.043027, 32.776863],
                    [-94.042938, 32.780558],
                    [-94.042829, 32.785277],
                    [-94.042747, 32.786973],
                    [-94.043026, 32.797476],
                    [-94.042785, 32.871486],
                    [-94.043025, 32.880446],
                    [-94.042886, 32.880965],
                    [-94.042886, 32.881089],
                    [-94.042859, 32.892771],
                    [-94.042885, 32.898911],
                    [-94.043092, 32.910021],
                    [-94.043067, 32.937903],
                    [-94.043088, 32.955592],
                    [-94.042964, 33.019219],
                    [-94.043036, 33.079485],
                    [-94.04287, 33.092727],
                    [-94.043007, 33.13389],
                    [-94.043077, 33.138162],
                    [-94.043185, 33.143476],
                    [-94.042875, 33.199785],
                    [-94.042892, 33.202666],
                    [-94.042876, 33.215219],
                    [-94.04273, 33.241823],
                    [-94.043004, 33.250128],
                    [-94.04305, 33.260904],
                    [-94.04299, 33.271227],
                    [-94.04299, 33.271243],
                    [-94.043067, 33.330498],
                    [-94.043067, 33.352097],
                    [-94.043128, 33.358757],
                    [-94.042869, 33.37117],
                    [-94.043053, 33.377716],
                    [-94.042887, 33.420225],
                    [-94.042988, 33.431024],
                    [-94.042988, 33.435824],
                    [-94.043188, 33.470324],
                    [-94.043279, 33.49103],
                    [-94.043009, 33.493039],
                    [-94.043375, 33.542315],
                    [-94.043428, 33.551425],
                    [-94.04345, 33.552253],
                    [-94.066846, 33.568909],
                    [-94.085243, 33.575546],
                    [-94.181805, 33.593217],
                    [-94.252656, 33.586144],
                    [-94.257801, 33.582508],
                    [-94.355945, 33.54318],
                    [-94.409329, 33.568265],
                    [-94.464336, 33.598819],
                    [-94.470963, 33.60594],
                    [-94.478366, 33.620847],
                    [-94.485875, 33.637867],
                    [-94.485577, 33.65331],
                    [-94.485528, 33.663388],
                    [-94.48452, 33.687909],
                    [-94.484616, 33.691592],
                    [-94.48384, 33.711332],
                    [-94.483874, 33.716733],
                    [-94.48287, 33.750564],
                    [-94.482862, 33.75078],
                    [-94.482777, 33.753638],
                    [-94.482682, 33.756286],
                    [-94.481842, 33.789008],
                    [-94.481543, 33.795719],
                    [-94.481361, 33.802649],
                    [-94.481355, 33.802887],
                    [-94.480574, 33.830166],
                    [-94.479954, 33.85133],
                    [-94.478994, 33.881197],
                    [-94.478842, 33.881485],
                    [-94.477387, 33.937759],
                    [-94.477318, 33.940932],
                    [-94.477038, 33.953838],
                    [-94.476957, 33.957365],
                    [-94.474895, 34.019655],
                    [-94.474896, 34.021838],
                    [-94.474896, 34.021877],
                    [-94.470292, 34.189864],
                    [-94.465847, 34.352073],
                    [-94.465425, 34.359548],
                    [-94.464176, 34.402713],
                    [-94.463816, 34.414465],
                    [-94.463671, 34.419585],
                    [-94.461149, 34.507457],
                    [-94.460058, 34.545264],
                    [-94.460052, 34.547869],
                    [-94.4575, 34.634945],
                    [-94.45753, 34.642961],
                    [-94.454576, 34.728962],
                    [-94.450233, 34.855413],
                    [-94.45014, 34.858694],
                    [-94.450065, 34.861335],
                    [-94.44963, 34.875253],
                    [-94.449058, 34.890556],
                    [-94.449086, 34.894152],
                    [-94.449253, 34.895869],
                    [-94.447889, 34.933941],
                    [-94.441232, 35.119724],
                    [-94.440754, 35.128806],
                    [-94.43955, 35.169037],
                    [-94.439509, 35.171807],
                    [-94.439056, 35.193588],
                    [-94.439084, 35.197298],
                    [-94.43847, 35.208587],
                    [-94.438247, 35.210992],
                    [-94.437774, 35.239271],
                    [-94.437578, 35.242202],
                    [-94.435812, 35.2713],
                    [-94.435706, 35.274267],
                    [-94.435316, 35.275893],
                    [-94.43528, 35.287485],
                    [-94.43517, 35.291494],
                    [-94.434115, 35.306493],
                    [-94.431815, 35.362891],
                    [-94.432685, 35.380806],
                    [-94.433742, 35.386467],
                    [-94.433915, 35.387391],
                    [-94.431215, 35.39429],
                    [-94.431789, 35.397659],
                    [-94.463318, 35.58266],
                    [-94.464097, 35.587265],
                    [-94.464457, 35.588909],
                    [-94.465272, 35.594037],
                    [-94.472647, 35.638556],
                    [-94.487585, 35.726147],
                    [-94.48821, 35.72924],
                    [-94.492932, 35.759166],
                    [-94.493362, 35.761892],
                    [-94.494549, 35.768303],
                    [-94.499045, 35.79346],
                    [-94.499647, 35.79691],
                    [-94.500526, 35.802642],
                    [-94.500764, 35.80382],
                    [-94.501162, 35.80643],
                    [-94.503011, 35.81721],
                    [-94.504438, 35.826369],
                    [-94.505642, 35.833628],
                    [-94.507631, 35.845901],
                    [-94.522658, 35.93425],
                    [-94.522658, 35.934799],
                    [-94.522634, 35.934892],
                    [-94.52291, 35.936127],
                    [-94.524344, 35.94405],
                    [-94.52464, 35.945727],
                    [-94.528162, 35.965665],
                    [-94.528305, 35.966054],
                    [-94.532071, 35.987852],
                    [-94.533646, 35.996804],
                    [-94.534852, 36.002678],
                    [-94.535724, 36.007807],
                    [-94.547715, 36.077271],
                    [-94.547871, 36.078281],
                    [-94.552184, 36.102235],
                    [-94.561165, 36.15211],
                    [-94.562803, 36.161749],
                    [-94.562828, 36.161895],
                    [-94.565655, 36.178439],
                    [-94.566588, 36.183774],
                    [-94.571253, 36.210901],
                    [-94.571806, 36.213748],
                    [-94.574395, 36.229996],
                    [-94.57488, 36.232741],
                    [-94.575071, 36.233682],
                    [-94.576003, 36.24007],
                    [-94.577899, 36.249548],
                    [-94.577883, 36.25008],
                    [-94.5862, 36.299969],
                    [-94.593397, 36.345742],
                    [-94.599723, 36.387587],
                    [-94.601984, 36.40212],
                    [-94.602623, 36.405283],
                    [-94.605408, 36.421949],
                    [-94.611609, 36.461528],
                    [-94.61383, 36.476248],
                    [-94.615311, 36.484992],
                    [-94.617919, 36.499414],
                    [-94.617877, 36.514999],
                    [-94.617883, 36.517799],
                    [-94.617997, 36.53428],
                    [-94.617868, 36.53609],
                    [-94.617897, 36.536983],
                    [-94.617814, 36.577732],
                    [-94.617853, 36.59912],
                    [-94.617865, 36.606851],
                    [-94.617815, 36.612604],
                    [-94.618019, 36.667921],
                    [-94.618025, 36.66943],
                    [-94.61813, 36.701423],
                    [-94.618307, 36.76656],
                    [-94.61838, 36.84732],
                    [-94.618658, 36.880064],
                    [-94.618243, 36.897027],
                    [-94.618282, 36.911472],
                    [-94.618207, 36.926236],
                    [-94.618295, 36.929647],
                    [-94.618166, 36.937584],
                    [-94.618109, 36.946564],
                    [-94.618026, 36.950158],
                    [-94.618031, 36.994704],
                    [-94.618049, 36.996208],
                    [-94.61808, 36.998135],
                    [-94.699735, 36.998805],
                    [-94.701797, 36.998814],
                    [-94.71277, 36.998794],
                    [-94.737183, 36.998665],
                    [-94.739324, 36.998687],
                    [-94.777257, 36.998764],
                    [-94.83128, 36.998812],
                    [-94.840926, 36.998833],
                    [-94.849801, 36.998876],
                    [-94.853197, 36.998874],
                    [-94.995293, 36.999529],
                    [-95.00762, 36.999514],
                    [-95.011433, 36.999535],
                    [-95.030324, 36.999517],
                    [-95.037857, 36.999497],
                    [-95.049499, 36.99958],
                    [-95.073504, 36.999509],
                    [-95.073509, 36.999509],
                    [-95.155187, 36.999539],
                    [-95.155372, 36.99954],
                    [-95.177301, 36.99952],
                    [-95.195307, 36.999565],
                    [-95.322565, 36.999358],
                    [-95.328058, 36.999365],
                    [-95.328327, 36.999366],
                    [-95.33121, 36.99938],
                    [-95.407572, 36.999241],
                    [-95.407683, 36.999241],
                    [-95.511578, 36.999235],
                    [-95.522415, 36.999281],
                    [-95.534401, 36.999332],
                    [-95.573598, 36.99931],
                    [-95.61214, 36.999321],
                    [-95.615934, 36.999365],
                    [-95.62435, 36.99936],
                    [-95.630079, 36.99932],
                    [-95.664301, 36.999322],
                    [-95.686452, 36.999349],
                    [-95.696659, 36.999215],
                    [-95.71038, 36.999371],
                    [-95.714887, 36.999279],
                    [-95.718054, 36.999255],
                    [-95.741908, 36.999244],
                    [-95.759905, 36.999271],
                    [-95.768719, 36.999205],
                    [-95.786762, 36.99931],
                    [-95.80798, 36.999124],
                    [-95.866899, 36.999261],
                    [-95.873944, 36.9993],
                    [-95.875257, 36.999302],
                    [-95.877151, 36.999304],
                    [-95.91018, 36.999336],
                    [-95.928122, 36.999245],
                    [-95.936992, 36.999268],
                    [-95.96427, 36.999094],
                    [-96.00081, 36.99886],
                    [-96.14121, 36.998973],
                    [-96.143207, 36.999134],
                    [-96.147143, 36.999022],
                    [-96.149709, 36.99904],
                    [-96.152384, 36.999051],
                    [-96.154017, 36.999161],
                    [-96.184768, 36.999211],
                    [-96.200028, 36.999028],
                    [-96.217571, 36.99907],
                    [-96.276368, 36.999271],
                    [-96.279079, 36.999272],
                    [-96.394272, 36.999221],
                    [-96.415412, 36.999113],
                    [-96.525493, 36.999147],
                    [-96.705431, 36.999203],
                    [-96.710482, 36.999271],
                    [-96.73659, 36.999286],
                    [-96.74127, 36.999239],
                    [-96.749838, 36.998988],
                    [-96.79206, 36.99918],
                    [-96.795199, 36.99886],
                    [-96.822791, 36.999182],
                    [-96.867517, 36.999217],
                    [-96.87629, 36.999233],
                    [-96.902083, 36.999155],
                    [-96.90351, 36.999132],
                    [-96.917093, 36.999182],
                    [-96.921915, 36.999151],
                    [-96.934642, 36.99907],
                    [-96.967371, 36.999067],
                    [-96.975562, 36.999019],
                    [-97.030082, 36.998929],
                    [-97.039784, 36.999],
                    [-97.100652, 36.998998],
                    [-97.104276, 36.99902],
                    [-97.120285, 36.999014],
                    [-97.122597, 36.999036],
                    [-97.147721, 36.999111],
                    [-97.372421, 36.998861],
                    [-97.384925, 36.998843],
                    [-97.46228, 36.998685],
                    [-97.462346, 36.998685],
                    [-97.472861, 36.998721],
                    [-97.527292, 36.99875],
                    [-97.5459, 36.998709],
                    [-97.546498, 36.998747],
                    [-97.564536, 36.998711],
                    [-97.606549, 36.998682],
                    [-97.637137, 36.99909],
                    [-97.650466, 36.999004],
                    [-97.697104, 36.998826],
                    [-97.768704, 36.99875],
                    [-97.783432, 36.998961],
                    [-97.783489, 36.998847],
                    [-97.802298, 36.998713],
                    [-97.802313, 36.998713],
                    [-98.033955, 36.998366],
                    [-98.03989, 36.998349],
                    [-98.045342, 36.998327],
                    [-98.111985, 36.998133],
                    [-98.147452, 36.998162],
                    [-98.177596, 36.998009],
                    [-98.208218, 36.997997],
                    [-98.219499, 36.997824],
                    [-98.237712, 36.997972],
                    [-98.346188, 36.997962],
                    [-98.347149, 36.997962],
                    [-98.354073, 36.997961],
                    [-98.408991, 36.998513],
                    [-98.418268, 36.998538],
                    [-98.420209, 36.998516],
                    [-98.54466, 36.998996],
                    [-98.544872, 36.998997],
                    [-98.714512, 36.99906],
                    [-98.718465, 36.99918],
                    [-98.761597, 36.999425],
                    [-98.791936, 36.999255],
                    [-98.793711, 36.999227],
                    [-98.797452, 36.999229],
                    [-98.869449, 36.999286],
                    [-98.880009, 36.999263],
                    [-98.88058, 36.999309],
                    [-98.994371, 36.999493],
                    [-99.000303, 36.99951],
                    [-99.029337, 36.999595],
                    [-99.124883, 36.99942],
                    [-99.129449, 36.999422],
                    [-99.24812, 36.999565],
                    [-99.277506, 36.999579],
                    [-99.456203, 36.999471],
                    [-99.484333, 36.999626],
                    [-99.500395, 36.999576],
                    [-99.500395, 36.999637],
                    [-99.502665, 36.999645],
                    [-99.504093, 36.999648],
                    [-99.508574, 36.999658],
                    [-99.541116, 36.999573],
                    [-99.558068, 36.999528],
                    [-99.625399, 36.999671],
                    [-99.657658, 37.000197],
                    [-99.774255, 37.000837],
                    [-99.774816, 37.000841],
                    [-99.786016, 37.000931],
                    [-99.995201, 37.001631],
                    [-100.001286, 37.001699],
                    [-100.002563, 37.001706],
                    [-100.005706, 37.001726],
                    [-100.089484, 37.002092],
                    [-100.115722, 37.002206],
                    [-100.187547, 37.002082],
                    [-100.192371, 37.002036],
                    [-100.193754, 37.002133],
                    [-100.201676, 37.002081],
                    [-100.551598, 37.00062],
                    [-100.552683, 37.000735],
                    [-100.591328, 37.000376],
                    [-100.591413, 37.000399],
                    [-100.62977, 37.000025],
                    [-100.633323, 36.999936],
                    [-100.633327, 36.999936],
                    [-100.675552, 36.999688],
                    [-100.756894, 36.999357],
                    [-100.765484, 36.999177],
                    [-100.806116, 36.999091],
                    [-100.814277, 36.999085],
                    [-100.855634, 36.998626],
                    [-100.89166, 36.998604],
                    [-100.904274, 36.998745],
                    [-100.904588, 36.998561],
                    [-100.945469, 36.998153],
                    [-100.945566, 36.998152],
                    [-100.996502, 36.998044],
                    [-101.012641, 36.998176],
                    [-101.053589, 36.997967],
                    [-101.066742, 36.997921],
                    [-101.211486, 36.997124],
                    [-101.212909, 36.997044],
                    [-101.357797, 36.996271],
                    [-101.359674, 36.996232],
                    [-101.37818, 36.996164],
                    [-101.413868, 36.996008],
                    [-101.415005, 36.995966],
                    [-101.485326, 36.995611],
                    [-101.519066, 36.995546],
                    [-101.555239, 36.995414],
                    [-101.55526, 36.995414],
                    [-101.600396, 36.995153],
                    [-101.601593, 36.995095],
                    [-101.90244, 36.993702],
                    [-102.000447, 36.993249],
                    [-102.000447, 36.993272],
                    [-102.028204, 36.993125],
                    [-102.028207, 36.993125],
                    [-102.04224, 36.993083],
                    [-102.041952, 37.024742],
                    [-102.04195, 37.030805],
                    [-102.041921, 37.032178],
                    [-102.041749, 37.034397],
                    [-102.04192, 37.035083],
                    [-102.041983, 37.106551],
                    [-102.041809, 37.111973],
                    [-102.042092, 37.125021],
                    [-102.042135, 37.125021],
                    [-102.042002, 37.141744],
                    [-102.041963, 37.258164],
                    [-102.041664, 37.29765],
                    [-102.041817, 37.30949],
                    [-102.041974, 37.352613],
                    [-102.042089, 37.352819],
                    [-102.041826, 37.389191],
                    [-102.041676, 37.409898],
                    [-102.041669, 37.43474],
                    [-102.041755, 37.434855],
                    [-102.041801, 37.469488],
                    [-102.041786, 37.506066],
                    [-102.042016, 37.535261],
                    [-102.041899, 37.541186],
                    [-102.041894, 37.557977],
                    [-102.041618, 37.607868],
                    [-102.041585, 37.644282],
                    [-102.041582, 37.654495],
                    [-102.041694, 37.665681],
                    [-102.041574, 37.680436],
                    [-102.041876, 37.723875],
                    [-102.04199, 37.738541],
                    [-102.042158, 37.760164],
                    [-102.042668, 37.788758],
                    [-102.042953, 37.803535],
                    [-102.043033, 37.824146],
                    [-102.043219, 37.867929],
                    [-102.043845, 37.926135],
                    [-102.043844, 37.928102],
                    [-102.044398, 38.250015],
                    [-102.04451, 38.262412],
                    [-102.044567, 38.268749],
                    [-102.044568, 38.268819],
                    [-102.044613, 38.312324],
                    [-102.044944, 38.384419],
                    [-102.045263, 38.505395],
                    [-102.045262, 38.505532],
                    [-102.045112, 38.523784],
                    [-102.045223, 38.543797],
                    [-102.045189, 38.558732],
                    [-102.045211, 38.581609],
                    [-102.045288, 38.615168],
                    [-102.045288, 38.615249],
                    [-102.045074, 38.669617],
                    [-102.045102, 38.674946],
                    [-102.04516, 38.675221],
                    [-102.045127, 38.686725],
                    [-102.045156, 38.688555],
                    [-102.045212, 38.697567],
                    [-102.045375, 38.754339],
                    [-102.045287, 38.755528],
                    [-102.045371, 38.770064],
                    [-102.045448, 38.783453],
                    [-102.045334, 38.799463],
                    [-102.045388, 38.813392],
                    [-102.046571, 39.047038],
                    [-102.047134, 39.129701],
                    [-102.047189, 39.133147],
                    [-102.04725, 39.13702],
                    [-102.048449, 39.303138],
                    [-102.04896, 39.373712],
                    [-102.049167, 39.403597],
                    [-102.04937, 39.41821],
                    [-102.049369, 39.423333],
                    [-102.049679, 39.506183],
                    [-102.049673, 39.536691],
                    [-102.049554, 39.538932],
                    [-102.049764, 39.56818],
                    [-102.049806, 39.574058],
                    [-102.049954, 39.592331],
                    [-102.050594, 39.675594],
                    [-102.051254, 39.818992],
                    [-102.051318, 39.833311],
                    [-102.051363, 39.843471],
                    [-102.051569, 39.849805],
                    [-102.051744, 40.003078],
                    [-102.052001, 40.148359],
                    [-102.051909, 40.162674],
                    [-102.051894, 40.229193],
                    [-102.051922, 40.235344],
                    [-102.051669, 40.349213],
                    [-102.051572, 40.39308],
                    [-102.05184, 40.396396],
                    [-102.051465, 40.440008],
                    [-102.051519, 40.520094],
                    [-102.051725, 40.537839],
                    [-102.051398, 40.697542],
                    [-102.051292, 40.749586],
                    [-102.051292, 40.749591],
                    [-102.051614, 41.002377],
                    [-102.051718, 41.002377],
                    [-102.070598, 41.002423],
                    [-102.124972, 41.002338],
                    [-102.19121, 41.002326],
                    [-102.209361, 41.002442],
                    [-102.2122, 41.002462],
                    [-102.231931, 41.002327],
                    [-102.267812, 41.002383],
                    [-102.2721, 41.002245],
                    [-102.291354, 41.002207],
                    [-102.292553, 41.002207],
                    [-102.292622, 41.00223],
                    [-102.292833, 41.002207],
                    [-102.364066, 41.002174],
                    [-102.379593, 41.002301],
                    [-102.469223, 41.002424],
                    [-102.470537, 41.002382],
                    [-102.487955, 41.002445],
                    [-102.556789, 41.002219],
                    [-102.566048, 41.0022],
                    [-102.575496, 41.0022],
                    [-102.575738, 41.002268],
                    [-102.578696, 41.002291],
                    [-102.621033, 41.002597],
                    [-102.653463, 41.002332],
                    [-102.739624, 41.00223],
                    [-102.754617, 41.002361],
                    [-102.766723, 41.002275],
                    [-102.773546, 41.002414],
                    [-102.82728, 41.002143],
                    [-102.830303, 41.002351],
                    [-102.846455, 41.002256],
                    [-102.849263, 41.002301],
                    [-102.865784, 41.001988],
                    [-102.867822, 41.002183],
                    [-102.885746, 41.002131],
                    [-102.887407, 41.002178],
                    [-102.904796, 41.002207],
                    [-102.906547, 41.002276],
                    [-102.924029, 41.002142],
                    [-102.925568, 41.00228],
                    [-102.943109, 41.002051],
                    [-102.94483, 41.002303],
                    [-102.959624, 41.002095],
                    [-102.960706, 41.002059],
                    [-102.962522, 41.002072],
                    [-102.963669, 41.002186],
                    [-102.981483, 41.002112],
                    [-102.98269, 41.002157],
                    [-103.000102, 41.0024],
                    [-103.002026, 41.002486],
                    [-103.038704, 41.002251],
                    [-103.043444, 41.002344],
                    [-103.057998, 41.002368],
                    [-103.059538, 41.002368],
                    [-103.076536, 41.002253],
                    [-103.077804, 41.002298],
                    [-103.362979, 41.001844],
                    [-103.365314, 41.001846],
                    [-103.382493, 41.001883],
                    [-103.421925, 41.001969],
                    [-103.421975, 41.002007],
                    [-103.486697, 41.001914],
                    [-103.497447, 41.001635],
                    [-103.574522, 41.001721],
                    [-103.858449, 41.001681],
                    [-103.877967, 41.001673],
                    [-103.896207, 41.00175],
                    [-103.953525, 41.001596],
                    [-103.971373, 41.001524],
                    [-103.972642, 41.001615],
                    [-104.018223, 41.001617],
                    [-104.023383, 41.001887],
                    [-104.039238, 41.001502],
                    [-104.053249, 41.001406],
                    [-104.053158, 41.016809],
                    [-104.053097, 41.018045],
                    [-104.053177, 41.089725],
                    [-104.053025, 41.090274],
                    [-104.053083, 41.104985],
                    [-104.053142, 41.114457],
                    [-104.052666, 41.275251],
                    [-104.052574, 41.278019],
                    [-104.052453, 41.278202],
                    [-104.052568, 41.316202],
                    [-104.052476, 41.320961],
                    [-104.052324, 41.321144],
                    [-104.052287, 41.393214],
                    [-104.052287, 41.393307],
                    [-104.05216, 41.407662],
                    [-104.05234, 41.417865],
                    [-104.052478, 41.515754],
                    [-104.052476, 41.522343],
                    [-104.052686, 41.539111],
                    [-104.052692, 41.541154],
                    [-104.052584, 41.55265],
                    [-104.052531, 41.552723],
                    [-104.05254, 41.564274],
                    [-104.052859, 41.592254],
                    [-104.052735, 41.613676],
                    [-104.052975, 41.622931],
                    [-104.052945, 41.638167],
                    [-104.052913, 41.64519],
                    [-104.05283, 41.697954],
                    [-104.052774, 41.733401],
                    [-104.053026, 41.885464],
                    [-104.052931, 41.906143],
                    [-104.052991, 41.914973],
                    [-104.052734, 41.973007],
                    [-104.052856, 41.975958],
                    [-104.05283, 41.9946],
                    [-104.052761, 41.994967],
                    [-104.052699, 41.998673],
                    [-104.052704, 42.001718],
                    [-104.052729, 42.016318],
                    [-104.05288, 42.021761],
                    [-104.052967, 42.075004],
                    [-104.052954, 42.089077],
                    [-104.0526, 42.124963],
                    [-104.052738, 42.133769],
                    [-104.053001, 42.137254],
                    [-104.052547, 42.166801],
                    [-104.052761, 42.170278],
                    [-104.052793, 42.249962],
                    [-104.052776, 42.25822],
                    [-104.052775, 42.610813],
                    [-104.052775, 42.61159],
                    [-104.052773, 42.611766],
                    [-104.052586, 42.630917],
                    [-104.052741, 42.633982],
                    [-104.052583, 42.650062],
                    [-104.052809, 42.749966],
                    [-104.052863, 42.754569],
                    [-104.053127, 43.000585],
                    [-104.053876, 43.289801],
                    [-104.053884, 43.297047],
                    [-104.054218, 43.30437],
                    [-104.054403, 43.325914],
                    [-104.054614, 43.390949],
                    [-104.054766, 43.428914],
                    [-104.054779, 43.477815],
                    [-104.054786, 43.503072],
                    [-104.054787, 43.503328],
                    [-104.055032, 43.558603],
                    [-104.05484, 43.579368],
                    [-104.054885, 43.583512],
                    [-104.054902, 43.583852],
                    [-104.055133, 43.747105],
                    [-104.055138, 43.750421],
                    [-104.055104, 43.853478],
                    [-104.055077, 43.936535],
                    [-104.05495, 43.93809],
                    [-104.054562, 44.141081],
                    [-104.054487, 44.180381],
                    [-104.055927, 44.51773],
                    [-104.055892, 44.543341],
                    [-104.055877, 44.571016],
                    [-104.05581, 44.691343],
                    [-104.055938, 44.693881],
                    [-104.055777, 44.700466],
                    [-104.05587, 44.723422],
                    [-104.055934, 44.72372],
                    [-104.055963, 44.767962],
                    [-104.055963, 44.768236],
                    [-104.057698, 44.997431],
                    [-104.470117, 44.998453],
                    [-104.470422, 44.998453],
                    [-104.663882, 44.998869],
                    [-104.665171, 44.998618],
                    [-104.759855, 44.999066],
                    [-104.765063, 44.999183],
                    [-105.01824, 45.000437],
                    [-105.019284, 45.000329],
                    [-105.025266, 45.00029],
                    [-105.038405, 45.000345],
                    [-105.076607, 45.000347],
                    [-105.848065, 45.000396],
                    [-105.918995, 44.997771],
                    [-105.928184, 44.993647],
                    [-106.024814, 44.993688],
                    [-106.263586, 44.993788],
                    [-106.888773, 44.995885],
                    [-106.892875, 44.995947],
                    [-107.080624, 44.996791],
                    [-107.13418, 45.000109],
                    [-107.607824, 45.000929],
                    [-107.608854, 45.00086],
                    [-107.911495, 45.000948],
                    [-108.125666, 45.001011],
                    [-108.249345, 44.999458],
                    [-108.565921, 45.000578],
                    [-108.578484, 45.000484],
                    [-108.621313, 45.000408],
                    [-109.062262, 44.999623],
                    [-109.08301, 44.99961],
                    [-109.103445, 45.005904],
                    [-109.263431, 45.005345],
                    [-109.269294, 45.005283],
                    [-109.798687, 45.002188],
                    [-110.025544, 45.003602],
                    [-110.026347, 45.003665],
                    [-110.110103, 45.003905],
                    [-110.221343, 44.996176],
                    [-110.547165, 44.992459],
                    [-110.552433, 44.992237],
                    [-110.705272, 44.992324],
                    [-110.750767, 44.997948],
                    [-110.875772, 45.002101],
                    [-111.044275, 45.001345],
                    [-111.056207, 44.935901],
                    [-111.055629, 44.933578],
                    [-111.056888, 44.866658],
                    [-111.055495, 44.666262],
                    [-111.055208, 44.624927],
                    [-111.048974, 44.474072],
                    [-111.049194, 44.438058],
                    [-111.049216, 44.435811],
                    [-111.048633, 44.062903],
                    [-111.048751, 44.060838],
                    [-111.048751, 44.060403],
                    [-111.048107, 43.983096],
                    [-111.046715, 43.815832],
                    [-111.04634, 43.726957],
                    [-111.046435, 43.726545],
                    [-111.046421, 43.722059],
                    [-111.04611, 43.687848],
                    [-111.046051, 43.685812],
                    [-111.046118, 43.684902],
                    [-111.04588, 43.681033],
                    [-111.045706, 43.659112],
                    [-111.045205, 43.501136],
                    [-111.044617, 43.31572],
                    [-111.044229, 43.195579],
                    [-111.044168, 43.189244],
                    [-111.044232, 43.18444],
                    [-111.044266, 43.177236],
                    [-111.044235, 43.177121],
                    [-111.044143, 43.072364],
                    [-111.044162, 43.068222],
                    [-111.04415, 43.066172],
                    [-111.044117, 43.060309],
                    [-111.044086, 43.054819],
                    [-111.044063, 43.046302],
                    [-111.044058, 43.04464],
                    [-111.043997, 43.041415],
                    [-111.044094, 43.02927],
                    [-111.044033, 43.026411],
                    [-111.044034, 43.024844],
                    [-111.044034, 43.024581],
                    [-111.044206, 43.022614],
                    [-111.044156, 43.020052],
                    [-111.044129, 43.018702],
                    [-111.043924, 42.975063],
                    [-111.043957, 42.969482],
                    [-111.043959, 42.96445],
                    [-111.046249, 42.513116],
                    [-111.04708, 42.34942],
                    [-111.047074, 42.280787],
                    [-111.047097, 42.194773],
                    [-111.047058, 42.182672],
                    [-111.047107, 42.148971],
                    [-111.047109, 42.142497],
                    [-111.046689, 42.001567],
                    [-111.415873, 42.000748],
                    [-111.420898, 42.000793],
                    [-111.425535, 42.00084],
                    [-111.507264, 41.999518],
                    [-111.876491, 41.998528],
                    [-111.915622, 41.998496],
                    [-111.915837, 41.998519],
                    [-112.109528, 41.997105],
                    [-112.163956, 41.996708],
                    [-112.239107, 42.001217],
                    [-112.264936, 42.000991],
                    [-112.38617, 42.001126],
                    [-112.450567, 42.001092],
                    [-112.450814, 42.000953],
                    [-112.648019, 42.000307],
                    [-112.709375, 42.000309],
                    [-112.788542, 41.999681],
                    [-112.833084, 41.999305],
                    [-112.833125, 41.999345],
                    [-112.880619, 41.998921],
                    [-112.882367, 41.998922],
                    [-112.909587, 41.998791],
                    [-112.979218, 41.998263],
                    [-113.00082, 41.998223],
                    [-113.000821, 41.998223],
                    [-113.396497, 41.99425],
                    [-113.40223, 41.994161],
                    [-113.431563, 41.993799],
                    [-113.76453, 41.989459],
                    [-113.796082, 41.989104],
                    [-113.893261, 41.988057],
                    [-114.041723, 41.99372],
                    [-114.041107, 41.850573],
                    [-114.041152, 41.850595],
                    [-114.039882, 41.741991],
                    [-114.042145, 40.999926],
                    [-114.043803, 40.759205],
                    [-114.043831, 40.758666],
                    [-114.043505, 40.726292],
                    [-114.045281, 40.506586],
                    [-114.045577, 40.495801],
                    [-114.045518, 40.494474],
                    [-114.046555, 40.116931],
                    [-114.047134, 39.906037],
                    [-114.047728, 39.542742],
                    [-114.049893, 38.677365],
                    [-114.050154, 38.57292],
                    [-114.049862, 38.547764],
                    [-114.049834, 38.543784],
                    [-114.050091, 38.404673],
                    [-114.05012, 38.404536],
                    [-114.049903, 38.148601],
                    [-114.050423, 37.999961],
                    [-114.048473, 37.809861],
                    [-114.051109, 37.756276],
                    [-114.05167, 37.746958],
                    [-114.051785, 37.746249],
                    [-114.051728, 37.745997],
                    [-114.052472, 37.604776],
                    [-114.052689, 37.517859],
                    [-114.052718, 37.517264],
                    [-114.052685, 37.502513],
                    [-114.052701, 37.492014],
                    [-114.051927, 37.370734],
                    [-114.051927, 37.370459],
                    [-114.0518, 37.293548],
                    [-114.0518, 37.293044],
                    [-114.051974, 37.284511],
                    [-114.051974, 37.283848],
                    [-114.051822, 37.090976],
                    [-114.051749, 37.088434],
                    [-114.0506, 37.000396],
                    [-114.050583, 36.843141],
                    [-114.050578, 36.800173],
                    [-114.050562, 36.656259],
                    [-114.046488, 36.473449],
                    [-114.046403, 36.371873],
                    [-114.048515, 36.289598],
                    [-114.047106, 36.250591],
                    [-114.046838, 36.194069],
                    [-114.060302, 36.189363],
                    [-114.063021, 36.186942],
                    [-114.120193, 36.102228],
                    [-114.125796, 36.077531],
                    [-114.138202, 36.041284],
                    [-114.148774, 36.02731],
                    [-114.15413, 36.023862],
                    [-114.21369, 36.015613],
                    [-114.233289, 36.014289],
                    [-114.238799, 36.014561],
                    [-114.252651, 36.020193],
                    [-114.263146, 36.025937],
                    [-114.292663, 36.051118],
                    [-114.303614, 36.066456],
                    [-114.307235, 36.076544],
                    [-114.310857, 36.083787],
                    [-114.328777, 36.105501],
                    [-114.365835, 36.133722],
                    [-114.381446, 36.141665],
                    [-114.513256, 36.151217],
                    [-114.572031, 36.15161],
                    [-114.631716, 36.142306],
                    [-114.736165, 36.104367],
                    [-114.744857, 36.098693],
                    [-114.755618, 36.087166],
                    [-114.743576, 35.983721],
                    [-114.731296, 35.945157],
                    [-114.70613, 35.878712],
                    [-114.704211, 35.851984],
                    [-114.705611, 35.848884],
                    [-114.70771, 35.811885],
                    [-114.700308, 35.700387],
                    [-114.689407, 35.651412],
                    [-114.676707, 35.640989],
                    [-114.657506, 35.618289],
                    [-114.653406, 35.610789],
                    [-114.653806, 35.59949],
                    [-114.671147, 35.520641],
                    [-114.674981, 35.510564],
                    [-114.676733, 35.499063],
                    [-114.677643, 35.489742],
                    [-114.662125, 35.444241],
                    [-114.652005, 35.429165],
                    [-114.627137, 35.409504],
                    [-114.604314, 35.353584],
                    [-114.595931, 35.325234],
                    [-114.569238, 35.18348],
                    [-114.569569, 35.163053],
                    [-114.572747, 35.138725],
                    [-114.578524, 35.12875],
                    [-114.5843, 35.124999],
                    [-114.59912, 35.12105],
                    [-114.621956, 35.094678],
                    [-114.635469, 35.028266],
                    [-114.633544, 35.015644],
                    [-114.633013, 35.002085],
                    [-114.62919, 34.991887],
                    [-114.629015, 34.986148],
                    [-114.630877, 34.907263],
                    [-114.636725, 34.889107],
                    [-114.635176, 34.875003],
                    [-114.633051, 34.869971],
                    [-114.552682, 34.766871],
                    [-114.465607, 34.69226],
                    [-114.452547, 34.653494],
                    [-114.339627, 34.451435],
                    [-114.264317, 34.401329],
                    [-114.199482, 34.361373],
                    [-114.176909, 34.349306],
                    [-114.138282, 34.30323],
                    [-114.131489, 34.260387],
                    [-114.133264, 34.258462],
                    [-114.164476, 34.251667],
                    [-114.225861, 34.201774],
                    [-114.244191, 34.179625],
                    [-114.312592, 34.144453],
                    [-114.321618, 34.138093],
                    [-114.366521, 34.118575],
                    [-114.390565, 34.110084],
                    [-114.401352, 34.111652],
                    [-114.411681, 34.110031],
                    [-114.420499, 34.103466],
                    [-114.43338, 34.088413],
                    [-114.435429, 34.079727],
                    [-114.434949, 34.037784],
                    [-114.438266, 34.022609],
                    [-114.46361, 33.993431],
                    [-114.499883, 33.961789],
                    [-114.518434, 33.917518],
                    [-114.525539, 33.838614],
                    [-114.523365, 33.80612],
                    [-114.50434, 33.756381],
                    [-114.494197, 33.707922],
                    [-114.496489, 33.696901],
                    [-114.519113, 33.688473],
                    [-114.523959, 33.685879],
                    [-114.530348, 33.679245],
                    [-114.540617, 33.591412],
                    [-114.5403, 33.580615],
                    [-114.535965, 33.569154],
                    [-114.535664, 33.568788],
                    [-114.558898, 33.531819],
                    [-114.594534, 33.495059],
                    [-114.622918, 33.456561],
                    [-114.627125, 33.433554],
                    [-114.665278, 33.415358],
                    [-114.721233, 33.396912],
                    [-114.700103, 33.341045],
                    [-114.672088, 33.258499],
                    [-114.67536, 33.185489],
                    [-114.679359, 33.159519],
                    [-114.687074, 33.142196],
                    [-114.696829, 33.131209],
                    [-114.706175, 33.105335],
                    [-114.707819, 33.091102],
                    [-114.675104, 33.047532],
                    [-114.62387, 33.02872],
                    [-114.606282, 33.025703],
                    [-114.589778, 33.026228],
                    [-114.584765, 33.028231],
                    [-114.578287, 33.035375],
                    [-114.571653, 33.036624],
                    [-114.523578, 33.030961],
                    [-114.516912, 33.026871],
                    [-114.511343, 33.023455],
                    [-114.468605, 32.971649],
                    [-114.46289, 32.905797],
                    [-114.465715, 32.87942],
                    [-114.465715, 32.879191],
                    [-114.496284, 32.822326],
                    [-114.496827, 32.822119],
                    [-114.615733, 32.729427],
                    [-114.615585, 32.728446],
                    [-114.65826, 32.733799],
                    [-114.65884, 32.73383],
                    [-114.677091, 32.736218],
                    [-114.678632, 32.736614],
                    [-114.719633, 32.718763],
                    [-114.748, 32.664184],
                    [-114.753111, 32.658304],
                    [-114.781872, 32.62505],
                    [-114.799302, 32.625115],
                    [-114.80739, 32.621332],
                    [-114.809393, 32.617119],
                    [-114.813613, 32.494277],
                ],
            ],
            [
                [
                    [-97.391649, 26.90197],
                    [-97.385626, 26.888876],
                    [-97.383531, 26.875521],
                    [-97.387459, 26.820789],
                    [-97.373056, 26.808481],
                    [-97.368343, 26.795649],
                    [-97.370438, 26.781508],
                    [-97.368866, 26.774699],
                    [-97.364646, 26.767122],
                    [-97.364153, 26.758987],
                    [-97.367557, 26.740394],
                    [-97.370961, 26.736204],
                    [-97.370438, 26.723896],
                    [-97.363105, 26.71054],
                    [-97.345822, 26.700589],
                    [-97.336394, 26.666022],
                    [-97.338489, 26.647429],
                    [-97.324872, 26.611814],
                    [-97.318458, 26.60027],
                    [-97.317015, 26.597673],
                    [-97.308635, 26.576723],
                    [-97.308112, 26.571223],
                    [-97.31073, 26.556558],
                    [-97.292399, 26.528014],
                    [-97.276425, 26.521729],
                    [-97.262546, 26.482972],
                    [-97.254166, 26.471188],
                    [-97.247619, 26.456261],
                    [-97.243167, 26.434263],
                    [-97.240849, 26.411504],
                    [-97.240286, 26.405981],
                    [-97.226931, 26.385555],
                    [-97.214885, 26.353606],
                    [-97.194458, 26.27164],
                    [-97.183983, 26.214289],
                    [-97.178746, 26.177103],
                    [-97.179532, 26.146202],
                    [-97.171781, 26.102522],
                    [-97.169842, 26.077853],
                    [-97.161462, 26.06764],
                    [-97.154271, 26.066841],
                    [-97.161471, 26.088705],
                    [-97.173265, 26.192314],
                    [-97.185844, 26.267103],
                    [-97.194644, 26.306513],
                    [-97.223728, 26.411492],
                    [-97.229844, 26.433569],
                    [-97.269392, 26.554046],
                    [-97.275119, 26.565415],
                    [-97.287885, 26.600341],
                    [-97.30069, 26.635375],
                    [-97.333028, 26.736479],
                    [-97.351413, 26.808604],
                    [-97.364726, 26.871693],
                    [-97.370731, 26.909706],
                    [-97.378362, 26.992877],
                    [-97.37913, 27.047996],
                    [-97.377001, 27.101021],
                    [-97.370941, 27.161166],
                    [-97.363401, 27.210366],
                    [-97.350398, 27.268105],
                    [-97.347438, 27.277963],
                    [-97.326523, 27.347612],
                    [-97.30447, 27.407734],
                    [-97.276091, 27.472145],
                    [-97.221912, 27.576315],
                    [-97.201866, 27.614858],
                    [-97.166682, 27.676583],
                    [-97.116277, 27.752599],
                    [-97.085395, 27.793245],
                    [-97.04598, 27.835004],
                    [-97.045409, 27.837452],
                    [-97.041799, 27.852926],
                    [-97.03166, 27.869975],
                    [-97.001441, 27.911442],
                    [-96.966996, 27.950531],
                    [-96.929053, 27.99044],
                    [-96.853455, 28.061345],
                    [-96.849624, 28.064939],
                    [-96.758141, 28.136873],
                    [-96.694666, 28.18212],
                    [-96.621534, 28.2297],
                    [-96.547774, 28.270798],
                    [-96.439099, 28.319052],
                    [-96.4137, 28.327343],
                    [-96.397846, 28.343513],
                    [-96.401242, 28.366892],
                    [-96.403206, 28.371475],
                    [-96.412896, 28.369511],
                    [-96.417217, 28.367154],
                    [-96.415253, 28.362833],
                    [-96.418919, 28.354846],
                    [-96.434108, 28.344764],
                    [-96.450998, 28.337039],
                    [-96.476269, 28.330754],
                    [-96.528906, 28.322505],
                    [-96.542131, 28.315958],
                    [-96.546975, 28.305614],
                    [-96.55326, 28.302341],
                    [-96.581019, 28.30221],
                    [-96.592934, 28.296972],
                    [-96.61048, 28.283093],
                    [-96.611527, 28.281391],
                    [-96.608123, 28.280081],
                    [-96.607992, 28.27707],
                    [-96.651856, 28.251275],
                    [-96.663116, 28.233206],
                    [-96.660628, 28.228885],
                    [-96.662462, 28.227314],
                    [-96.686816, 28.21841],
                    [-96.703314, 28.216446],
                    [-96.702659, 28.211208],
                    [-96.697422, 28.202959],
                    [-96.703838, 28.198246],
                    [-96.733037, 28.190913],
                    [-96.791958, 28.188687],
                    [-96.816443, 28.174808],
                    [-96.818656, 28.17228],
                    [-96.822859, 28.167476],
                    [-96.820315, 28.163494],
                    [-96.816836, 28.158048],
                    [-96.810944, 28.136378],
                    [-96.81042, 28.126034],
                    [-96.816574, 28.119618],
                    [-96.827049, 28.112417],
                    [-96.83003, 28.111842],
                    [-96.84538, 28.108881],
                    [-96.857165, 28.115559],
                    [-96.864628, 28.126296],
                    [-96.870782, 28.131271],
                    [-96.874972, 28.133236],
                    [-96.879424, 28.131402],
                    [-96.886887, 28.11713],
                    [-96.889113, 28.099454],
                    [-96.888328, 28.086622],
                    [-96.886233, 28.084396],
                    [-96.890947, 28.076802],
                    [-96.906004, 28.076147],
                    [-96.927085, 28.057292],
                    [-96.929573, 28.0514],
                    [-96.92643, 28.043413],
                    [-96.932454, 28.035426],
                    [-96.946988, 28.026522],
                    [-96.952618, 28.01644],
                    [-96.962569, 28.012381],
                    [-96.965188, 28.013297],
                    [-96.966759, 28.020368],
                    [-96.967807, 28.020041],
                    [-96.978282, 28.001709],
                    [-96.986661, 27.980759],
                    [-96.986007, 27.976177],
                    [-96.9809, 27.978272],
                    [-96.978805, 27.978272],
                    [-96.977889, 27.976439],
                    [-96.985745, 27.954048],
                    [-97.016384, 27.917255],
                    [-97.017955, 27.911494],
                    [-97.013634, 27.90678],
                    [-97.043226, 27.859119],
                    [-97.055823, 27.843404],
                    [-97.056713, 27.842294],
                    [-97.132394, 27.827301],
                    [-97.134489, 27.825206],
                    [-97.130299, 27.820493],
                    [-97.126109, 27.819707],
                    [-97.098874, 27.82285],
                    [-97.092589, 27.819183],
                    [-97.092851, 27.81447],
                    [-97.098874, 27.808447],
                    [-97.102279, 27.798233],
                    [-97.103326, 27.789068],
                    [-97.127681, 27.76],
                    [-97.127942, 27.75581],
                    [-97.130823, 27.75162],
                    [-97.147321, 27.735384],
                    [-97.153606, 27.733289],
                    [-97.1612, 27.734074],
                    [-97.166176, 27.732372],
                    [-97.170628, 27.720326],
                    [-97.190007, 27.692829],
                    [-97.203089, 27.688001],
                    [-97.203474, 27.684533],
                    [-97.197601, 27.678426],
                    [-97.197339, 27.664547],
                    [-97.200743, 27.650144],
                    [-97.214099, 27.631551],
                    [-97.219075, 27.630241],
                    [-97.221955, 27.63286],
                    [-97.231383, 27.632336],
                    [-97.242643, 27.607458],
                    [-97.241072, 27.602483],
                    [-97.236882, 27.598293],
                    [-97.247619, 27.581533],
                    [-97.247877, 27.581366],
                    [-97.252071, 27.578652],
                    [-97.260451, 27.567392],
                    [-97.26176, 27.563464],
                    [-97.257832, 27.556393],
                    [-97.266474, 27.542514],
                    [-97.282972, 27.521564],
                    [-97.293709, 27.497209],
                    [-97.317277, 27.46369],
                    [-97.329847, 27.43436],
                    [-97.330895, 27.425456],
                    [-97.329585, 27.418124],
                    [-97.331157, 27.412362],
                    [-97.336132, 27.402411],
                    [-97.346607, 27.390365],
                    [-97.361796, 27.359988],
                    [-97.366771, 27.333276],
                    [-97.36232, 27.32673],
                    [-97.357606, 27.307875],
                    [-97.359963, 27.304732],
                    [-97.364676, 27.302637],
                    [-97.367033, 27.298709],
                    [-97.372861, 27.278069],
                    [-97.373318, 27.27645],
                    [-97.37489, 27.250262],
                    [-97.382222, 27.229051],
                    [-97.386674, 27.204696],
                    [-97.379865, 27.202863],
                    [-97.377508, 27.199459],
                    [-97.390078, 27.156512],
                    [-97.390602, 27.094186],
                    [-97.387459, 27.090519],
                    [-97.386412, 27.083187],
                    [-97.389816, 27.067213],
                    [-97.39034, 27.052286],
                    [-97.389554, 26.945965],
                    [-97.391649, 26.90197],
                ],
            ],
        ],
    },
}
