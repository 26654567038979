import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    attachmentsBox: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        alignItems: 'center',
        marginTop: 8,
        marginBottom: 8,
        marginLeft: -16,
        marginRight: -16,
        overflowX: 'scroll',
        overflowY: 'hidden',
        width: '100vw',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    attachment: {
        height: '6em',
        border: theme.border,
        borderRadius: 4,
    },
    messageOuter: {
        position: 'relative',
        paddingBottom: 0,
        paddingLeft: 16,
        paddingRight: 16,
        width: '100%',
    },
    conversationOuter: {
        paddingTop: 8,
        paddingBottom: 8,
    },
    messageConversationBody: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    messageOuterHover: {
        backgroundColor: `${theme.palette.text.hint}40`,
    },
    messageInner: {
        /*pointerEvents: 'none',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        cursor: 'default',*/
    },
    messageReactionBox: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: 16,
        padding: 4,
        borderRadius: '19px',
        backgroundColor: theme.palette.background.paper,
        border: theme.border,
        alignItems: 'center',
    },
    messageReactionBoxBorder: {
        backgroundColor: theme.palette.background.light,
        marginLeft: 4,
        marginRight: 4,
        width: 1,
        height: '24px',
    },
    messageReactionBoxTOD: {
        marginLeft: 4,
        marginRight: 4,
        userSelect: 'none',
        WebkitUserSelect: 'none',
        cursor: 'default',
    },
    channelCircle: {
        position: 'absolute',
        right: -3.5,
        bottom: -3.5,
        backgroundColor: theme.palette.background.paper,
        width: 19,
        height: 19,
        borderRadius: '50%',
    },
    unreadCircle: {
        position: 'absolute',
        right: -3.5,
        top: -3.5,
        backgroundColor: theme.palette.background.paper,
        width: 19,
        height: 19,
        borderRadius: '50%',
    },
    chatList: {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        userSelect: 'none',
        WebkitUserSelect: 'none',
        cursor: 'default',
        outline: 'none !important',
    },
    circleSelected: {
        backgroundColor: `${theme.palette.background.light} !important`,
    },
    conversationAvatar: { height: 48, width: 48, backgroundColor: theme.palette.primary.main },
    messageAvatar: { height: 48, width: 48, backgroundColor: theme.palette.primary.main },
    conversationWidgetSelected: {
        backgroundColor: theme.palette.background.light,
    },
    emojiParent: {
        lineHeight: `1 !important`,
        verticalAlign: 'middle !important',
    },
    widgetAvatar: {
        width: 32,
        height: 32,
    },
    widgetAvatarButton: {
        padding: 4,
    },
    unreadDot: { zIndex: 100, backgroundColor: theme.palette.red.main, borderRadius: '50%' },
    /* ChatMobile */
    mobileAppBarBottom: {
        top: 'auto',
        bottom: 0,
        backgroundColor: theme.palette.background.light,
        paddingBottom: `env(safe-area-inset-bottom)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
    },
    mobileAppBarTop: {
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : theme.palette.primary.main,
        paddingTop: `env(safe-area-inset-top)`,
        paddingLeft: `env(safe-area-inset-left)`,
        paddingRight: `env(safe-area-inset-right)`,
        top: 0,
        boxShadow: 'none !important',
    },
    mobileBackdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.default,
    },
    mobileContainer: {
        fontSize: '0.75em',
        height: '100vh',
        overflow: 'scroll',
        paddingTop: `calc(56px + ${/*theme.spacing(1)*/ 0}px + env(safe-area-inset-top))` /* Toolbars are 56 pixels in height */,
        paddingLeft: `env(safe-area-inset-left) !important`,
        paddingRight: `env(safe-area-inset-right) !important`,
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        backgroundColor: theme.palette.background.paper,
    },
    mobileContainerAttachment: {
        /* Toolbars are 56 pixels in height, attachment bar is 104px, divider is 1 px */
        paddingBottom: `calc(56px + 104px + 1px + ${/*theme.spacing(1)*/ 0}px + env(safe-area-inset-bottom)) !important`,
    },
    mobileContainerChat: {
        paddingBottom: `calc(56px + ${/*theme.spacing(1)*/ 0}px + env(safe-area-inset-bottom))` /* Toolbars are 56 pixels in height */,
    },
    mobileContainerInfo: {
        paddingTop: `calc(56px + 16px + env(safe-area-inset-top))`,
        paddingLeft: `calc(16px + env(safe-area-inset-left)) !important`,
        paddingRight: `calc(16px + env(safe-area-inset-right)) !important`,
        paddingBottom: `calc(${theme.spacing(1)}px + env(safe-area-inset-bottom))` /* Toolbars are 56 pixels in height */,
    },
    mobileEditorBox: {
        flexGrow: 1,
        color: theme.palette.text.primary,
    },
    mobileMenuButton: {
        marginRight: theme.spacing(2),
    },
    mobileTitle: {
        flexGrow: 1,
    },
    reactionChip: {
        marginRight: theme.spacing(1),
    },
    timestamp: {
        padding: `4px 16px`,
        borderRadius: 4,
        border: theme.border,
    },
    timestampBar: {
        flexGrow: 1,
        height: 1,
        backgroundColor: theme.borderColor,
    },
}))
