import React from 'react'
import { Box } from '@material-ui/core'
import { ErrorRounded as WarningIcon } from '@material-ui/icons'
import { Typography } from '../../Wrappers'

export default function WarningTypography(props) {
    const { text, isCentered, children, gutterBottom } = props
    return (
        <Box
            display="flex"
            style={{
                justifyContent: isCentered ? 'center' : 'flex-start',
                width: '100%',
                alignItems: 'center',
                marginBottom: gutterBottom ? '0.35em' : 0,
            }}
        >
            <WarningIcon style={{ color: '#ffb74d', margin: '2px' }} fontSize="small" />
            <Typography align={isCentered ? `center` : `inherit`} color="orange" style={{ marginLeft: 4, fontWeight: 700 }}>
                {text}
                {children}
            </Typography>
        </Box>
    )
}
