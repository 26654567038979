import { useTheme } from '@material-ui/core/styles'
import React from 'react'

import { Picker } from 'emoji-mart'

import { getEmojiSet } from 'code/Helper'

import './styles.css'

const EmojiPicker = ({ onSelect, ...props }) => {
    const theme = useTheme()

    const baseElement = (
        <Picker
            {...props}
            color={theme.palette.primary.main}
            native
            onSelect={onSelect ? emoji => onSelect(emoji) : null}
            showPreview={false}
            showSkinTones={false}
            theme={theme.palette.type === 'dark' ? 'dark' : 'light'}
            set={getEmojiSet()}
        />
    )

    return baseElement
}

export default React.memo(EmojiPicker)
