import React from 'react'

import { Box, IconButton, Link, Switch, Typography } from '@material-ui/core'
import { InfoOutlined as InfoIcon } from '@material-ui/icons'

import useStyles from './styles'

const ToggleSection = ({ icon, label, href, checked, onChange, disabled, showPaid, ...props }) => {
    let classes = useStyles()

    return (
        <Box className={classes.applicationBox} {...props}>
            {React.cloneElement(icon, { className: classes.applicationIcon })}
            <Switch checked={checked} onChange={onChange} color="primary" disabled={disabled} />
            <IconButton href={href} target="_blank" component={Link} size="small" className={classes.applicationInfo} aria-label="info">
                <InfoIcon />
            </IconButton>
            {showPaid && (
                <Box className={classes.ribbon}>
                    <Typography style={{ fontWeight: 'bold' }}>Paid</Typography>
                </Box>
            )}
            <Typography variant="h6" style={{ fontWeight: 500 }} align="center">
                {label}
            </Typography>
        </Box>
    )
}

export default ToggleSection
