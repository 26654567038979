import React from 'react'

import app from 'firebase/app'

import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

import {
    Launch as ExternalLinkIcon,
    HomeOutlined as HomeIcon,
    VpnKey as PasswordIcon,
    Policy as PrivacyIcon,
    Gavel as TermsIcon,
    Delete as DeleteIcon,
    ExitToApp as SignOutIcon,
} from '@material-ui/icons'
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    /* Delete Account Dialog */
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core'
import TextField from '@mui/material/TextField'
import { useTheme } from '@material-ui/styles'

import { AuthContext } from 'context/AuthContext'

import NavigationBar from 'components/NavigationBar'
import ErrorTypography from 'components/Typography/ErrorTypography'
import Widget from 'components/Widget'
import {
    Dialog as DialogMobile,
    DialogActions as DialogActionsMobile,
    DialogContent as DialogContentMobile,
    DialogTitle as DialogTitleMobile,
} from 'components/Wrappers'

import { isMobileDevice } from 'code/Helper'

export default function ProfileSettings(props) {
    var theme = useTheme()

    const memberID = props.match.params.id
    const { user, signOut } = React.useContext(AuthContext)

    const clearDialogFields = {
        open: false,
        title: '',
        type: '',
        loading: false,
        fields: { currentEmail: user.email, newEmail: '', confirmEmail: '', currentPassword: '', newPassword: '', confirmPassword: '' },
        error: null,
        credential: null,
    }

    const [providers, setProviders] = React.useState([])
    const [dialog, setDialog] = React.useState(clearDialogFields)
    const [deleteDialog, setDeleteDialog] = React.useState({ open: false, error: false, loading: false })
    const [showSuccessUpdate, setShowSuccessUpdate] = React.useState(null)

    React.useEffect(() => {
        const user = app.auth().currentUser

        if (user && Array.isArray(user.providerData)) {
            setProviders(user.providerData.map(p => p.providerId))
        }
    }, [])

    const onCloseDialog = () => {
        setDialog(dialog => ({ ...dialog, open: false }))
    }

    const deleteProfile = () => {
        let submission = {
            chapter: user.getChapter(),
            memberID: memberID,
        }

        let functionsCall = app.functions().httpsCallable('deleteMember')
        functionsCall(submission)
            .then(function(result) {
                signOut()
                if (props.history.length === 0) {
                    props.history.replace('/app/dashboard')
                } else {
                    props.history.goBack()
                }
            })
            .catch(function(error) {
                var message = error.message
                setDeleteDialog(dialog => ({ ...dialog, loading: false, error: message }))
            })
        setDeleteDialog(dialog => ({ ...dialog, loading: true }))
    }

    const SettingsListItem = ({ icon, label, secondaryIcon, isFirst, isLast, color, onClick, props }) => {
        let sx = {}

        if (isFirst) sx = { ...sx, borderTopLeftRadius: 4, borderTopRightRadius: 4 }
        if (isLast) sx = { ...sx, borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }

        return (
            <ListItem button style={sx} onClick={onClick} {...props}>
                <ListItemIcon style={color ? { color, minWidth: 32 } : { minWidth: 32 }}>{icon}</ListItemIcon>
                <ListItemText primary={label} style={color ? { color: color } : {}} />
                {secondaryIcon && <ListItemIcon style={{ minWidth: 'unset' }}>{secondaryIcon}</ListItemIcon>}
            </ListItem>
        )
    }

    const getSettingsItems = () => {
        let data = []

        if (providers.includes('password')) {
            data.push({
                label: `Change Password`,
                icon: <PasswordIcon />,
                onClick: () => {
                    setDialog({ ...clearDialogFields, open: true, title: 'Change Password', type: 'password' })
                    // Ask them to sign in with password, then set it again
                },
            })
        } else {
            data.push({
                label: `Setup Password`,
                icon: <PasswordIcon />,
                onClick: () => {
                    app.auth()
                        .sendPasswordResetEmail(user.email)
                        .then(() => {
                            setShowSuccessUpdate(`Reset password email has been sent to ${user.email}`)
                        })
                        .catch(function(error) {
                            setShowSuccessUpdate(`Unable to send reset password email`)
                        })
                },
            })
        }

        return [
            ...data,
            {
                label: 'Privacy Policy',
                icon: <PrivacyIcon />,
                secondaryIcon: <ExternalLinkIcon />,
                props: { component: 'a', href: 'https://getgreekconnect.com/privacy', target: '_blank' },
            },
            {
                label: 'User Terms of Service',
                icon: <TermsIcon />,
                secondaryIcon: <ExternalLinkIcon />,
                props: { component: 'a', href: 'https://getgreekconnect.com/terms', target: '_blank' },
            },
            {
                label: 'Sign Out',
                icon: <SignOutIcon />,
                onClick: () => {
                    signOut()
                },
            },
            {
                label: 'Delete Account',
                icon: <DeleteIcon />,
                onClick: () => {
                    setDeleteDialog(dialog => ({ ...dialog, open: true }))
                },
                color: theme.palette.red.main,
            },
        ]
    }

    const settingsItems = getSettingsItems()

    const renderList = () => (
        <List disablePadding>
            {settingsItems.map((listItem, i) => (
                <>
                    <SettingsListItem {...listItem} isFirst={i === 0} isLast={i === settingsItems.length - 1} />
                    {i < settingsItems.length - 1 && <Divider variant="inset" style={{ marginLeft: 16 + 32 }} />}
                </>
            ))}
        </List>
    )

    const canSubmitDialog = dialog.open && !dialog.loading

    // Errors
    // Invalid email or password -> show on submit, clear once one of the fields blurs
    // New email address is not valid -> show on blur email and reset on change of field to valid
    // Confirm email != email -> show on blur confirm email and reset on change of either to equal
    // New password is not valid
    // Confirm password != password

    const getDialogError = (dialog, options) => {
        if (dialog.type === 'password') {
            if (dialog.fields.newPassword.length >= 6 && dialog.fields.newPassword.length <= 32) {
                if (dialog.fields.newPassword !== dialog.fields.confirmPassword) {
                    // TODO: Check to see if we just blurred the confirmPassword field or are editing it
                    return 'New password must match'
                }
            } else {
                // TODO: Check to see if we just blurred the newPassword field or are editing it after the first time?
                return 'New password must be valid (6-32 characters)'
            }
        } else if (dialog.type === 'email') {
            // TODO: Same as above but also call isEmailValue
        }
        return false
    }

    const handleDialogError = error => {
        let errorText = error.message

        if (error.code === 'auth/weak-password') {
            errorText = 'New password is not strong enough (& must be 6-32 characters)'
        } else if (error.code === 'auth/requires-recent-login') {
            errorText = 'Please try again'
        } else if (error.code === 'auth/invalid-email') {
            errorText = 'Incorrect or invalid email'
        } else if (error.code === 'auth/email-already-in-use') {
            errorText = 'Email already in use by a different account'
        } else if (
            error.code === 'auth/user-mismatch' ||
            error.code === 'auth/user-not-found' ||
            error.code === 'auth/invalid-credential' ||
            error.code === 'auth/wrong-password'
        ) {
            console.log('got error', error)
            errorText = 'Incorrect email or password'
        }

        setDialog(dialog => ({ ...dialog, error: errorText, loading: false }))
    }

    const onSubmitDialog = async () => {
        if (dialog.open && canSubmitDialog) {
            let error = getDialogError(dialog)

            if (error) {
                setDialog(dialog => ({ ...dialog, error: error }))
            } else {
                const user = app.auth().currentUser

                setDialog(dialog => ({ ...dialog, loading: true }))

                const credential = app.auth.EmailAuthProvider.credential(dialog.fields.currentEmail, dialog.fields.currentPassword)

                user.reauthenticateWithCredential(credential)
                    .then(() => {
                        // User re-authenticated.
                        if (dialog.type === 'email') {
                            // Submit change email
                            // TODO: Update the email address in chapter & user fields
                            user.updateEmail(dialog.fields.newEmail)
                                .then(() => {
                                    setShowSuccessUpdate('Email change successful')
                                    setDialog(dialog => ({ ...dialog, open: false, error: null }))
                                })
                                .catch(handleDialogError)
                        } else if (dialog.type === 'password') {
                            // Submit change password
                            user.updatePassword(dialog.fields.newPassword)
                                .then(() => {
                                    setShowSuccessUpdate('Password change successful')
                                    setDialog(dialog => ({ ...dialog, open: false, error: null }))
                                })
                                .catch(handleDialogError)
                        }
                    })
                    .catch(handleDialogError)
            }
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Settings',
                        },
                    ]}
                    grid
                />
                {providers.length > 0 && (
                    <Grid item xs={12}>
                        {isMobileDevice() ? (
                            renderList()
                        ) : (
                            <Widget disableWidgetMenu inheritHeight noBodyPadding>
                                {renderList()}
                            </Widget>
                        )}
                    </Grid>
                )}
            </Grid>
            <Dialog
                open={deleteDialog.open}
                onClose={() => {
                    if (!deleteDialog.loading) {
                        setDeleteDialog(dialog => ({ ...dialog, open: false, error: false, loading: false }))
                    }
                }}
            >
                <DialogTitle>Delete account?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete your account?</DialogContentText>
                    <DialogContentText style={{ fontWeight: 'bold', textAlign: 'center', color: theme.palette.red.main }}>
                        This cannot be undone
                    </DialogContentText>
                    {deleteDialog.loading ? (
                        <>
                            <CircularProgress
                                style={{
                                    display: 'block',
                                    margin: 'auto',
                                    marginTop: 12,
                                }}
                            />
                        </>
                    ) : (
                        deleteDialog.error && (
                            <DialogContentText
                                style={{
                                    color: '#E66868',
                                    textAlign: `center`,
                                    marginTop: 12,
                                    marginBottom: 0,
                                }}
                            >
                                {deleteDialog.error}
                            </DialogContentText>
                        )
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteDialog(dialog => ({ ...dialog, open: false, error: false, loading: false }))}
                        color="primary"
                        disabled={deleteDialog.loading}
                    >
                        Cancel
                    </Button>
                    <Button onClick={() => deleteProfile()} disabled={deleteDialog.loading} style={{ color: theme.palette.red.main }}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <DialogMobile open={dialog.open} onClose={onCloseDialog} disableClose={dialog.loading}>
                <DialogTitleMobile onClose={onCloseDialog} disableClose={dialog.loading}>
                    {dialog.title}
                </DialogTitleMobile>
                <DialogContentMobile dividers>
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            label={dialog.type === 'email' ? 'Current Email' : 'Email'}
                            type="text"
                            onChange={e => setDialog(dialog => ({ ...dialog, error: null, fields: { ...dialog.fields, currentEmail: e.target.value } }))}
                            value={dialog.fields.currentEmail}
                            fullWidth
                            disabled
                        />
                        {!dialog.credential && (
                            <TextField
                                variant="outlined"
                                margin="dense"
                                label={dialog.type === 'password' ? 'Current Password' : 'Password'}
                                type="password"
                                inputProps={{
                                    maxLength: 32,
                                }}
                                onChange={e =>
                                    setDialog(dialog => ({
                                        ...dialog,
                                        error: null,
                                        fields: { ...dialog.fields, currentPassword: e.target.value },
                                    }))
                                }
                                value={dialog.fields.currentPassword}
                                fullWidth
                                required
                                autoComplete="current-password"
                            />
                        )}
                        {dialog.type === 'password' && (
                            <>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label={dialog.credential ? 'Password' : 'New Password'}
                                    type="password"
                                    inputProps={{
                                        maxLength: 32,
                                    }}
                                    onChange={e =>
                                        setDialog(dialog => ({
                                            ...dialog,
                                            error: null,
                                            fields: { ...dialog.fields, newPassword: e.target.value },
                                        }))
                                    }
                                    value={dialog.fields.newPassword}
                                    fullWidth
                                    required
                                    autoComplete="new-password"
                                />
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label="Confirm Password"
                                    type="password"
                                    inputProps={{
                                        maxLength: 32,
                                    }}
                                    onChange={e =>
                                        setDialog(dialog => ({
                                            ...dialog,
                                            error: null,
                                            fields: { ...dialog.fields, confirmPassword: e.target.value },
                                        }))
                                    }
                                    value={dialog.fields.confirmPassword}
                                    fullWidth
                                    required
                                    autoComplete="new-password"
                                />
                            </>
                        )}
                        {dialog.type === 'email' && (
                            <>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label="New Email"
                                    type="email"
                                    autoComplete="email"
                                    value={dialog.fields.newEmail}
                                    onChange={e =>
                                        setDialog(dialog => ({
                                            ...dialog,
                                            error: null,
                                            fields: { ...dialog.fields, newEmail: e.target.value },
                                        }))
                                    }
                                    required
                                    fullWidth
                                />
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label="Confirm Email"
                                    type="email"
                                    autoComplete="email"
                                    value={dialog.fields.confirmEmail}
                                    onChange={e =>
                                        setDialog(dialog => ({
                                            ...dialog,
                                            error: null,
                                            fields: { ...dialog.fields, confirmEmail: e.target.value },
                                        }))
                                    }
                                    required
                                    fullWidth
                                />
                            </>
                        )}
                        {dialog.error && <ErrorTypography text={dialog.error} isCentered />}
                    </Box>
                </DialogContentMobile>
                <DialogActionsMobile>
                    <Button onClick={onCloseDialog} color="primary" disabled={dialog.loading}>
                        Close
                    </Button>
                    <Button onClick={onSubmitDialog} disabled={!canSubmitDialog} color="primary">
                        Submit
                    </Button>
                </DialogActionsMobile>
            </DialogMobile>
            <Snackbar
                open={!!showSuccessUpdate}
                autoHideDuration={6000}
                onClose={() => setShowSuccessUpdate(false)}
                anchorOrigin={{ vertical: isMobileDevice() ? 'top' : 'bottom', horizontal: isMobileDevice() ? 'center' : 'left' }}
                sx={{ paddingTop: 'env(safe-area-inset-top)' }}
            >
                <MuiAlert onClose={() => setShowSuccessUpdate(false)} severity="success" sx={{ width: '100%' }}>
                    {showSuccessUpdate ?? ''}
                </MuiAlert>
            </Snackbar>
        </>
    )
}
