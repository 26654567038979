import React from 'react'

import { Avatar, Box, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Typography } from '@material-ui/core'
import { DeleteOutline as DeleteIcon, CreateOutlined as EditIcon, FolderOutlined as FolderIcon, PollOutlined as PollsIcon } from '@material-ui/icons'

import { FolderDeleteIcon, FileMoveIcon } from 'components/Icons'

import { useTheme } from '@material-ui/styles'

const PollItemInfo = ({ open, onClose, onSelectAction, hasAdmin, info, history }) => {
    var theme = useTheme()

    const showList = hasAdmin

    const onSelect = (action) => {
        onSelectAction(action)
        onClose()
    }

    return (
        <Box style={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
            <SwipeableDrawer anchor="bottom" open={open} onClose={onClose} disableSwipeToOpen onOpen={() => {}}>
                <Box
                    style={{
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: `env(safe-area-inset-bottom)`,
                        paddingLeft: `env(safe-area-inset-left)`,
                        paddingRight: `env(safe-area-inset-right)`,
                    }}
                >
                    {info && info.title && info.type && (
                        <>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                }}
                            >
                                {info.type === 'folder' ? <FolderIcon /> : <PollsIcon />}
                                <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginLeft: 8 }}>
                                    <Typography>{info.title ? info.title : 'Untitled'}</Typography>
                                </Box>
                            </Box>
                            <Divider />
                        </>
                    )}
                    {info && info.author && (
                        <>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                }}
                            >
                                <IconButton color="inherit" size="small" onClick={() => history.push('/app/profile/' + info.author.id)}>
                                    <Avatar color="primary" alt="" src={info.author.photo}>
                                        {`${info.author.first.charAt(0)}${info.author.last.charAt(0)}`}
                                    </Avatar>
                                </IconButton>
                                <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                    <Typography>{`${info.author.first} ${info.author.last}`}</Typography>
                                </Box>
                            </Box>
                            {showList && <Divider />}
                        </>
                    )}
                    {showList && (
                        <List disablePadding>
                            {info && info.type === 'poll' && (
                                <ListItem button onClick={() => onSelect('edit')}>
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" />
                                </ListItem>
                            )}
                            {info && (
                                <ListItem button onClick={() => onSelect('move')}>
                                    <ListItemIcon>
                                        <FileMoveIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Move to" />
                                </ListItem>
                            )}
                            {info && info.type === 'folder' && (
                                <ListItem button onClick={() => onSelect('rename')}>
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Rename" />
                                </ListItem>
                            )}
                            <ListItem button onClick={() => onSelect('delete')} style={{ color: theme.palette.red.main }}>
                                <ListItemIcon>
                                    {info && info.type === 'folder' ? (
                                        <FolderDeleteIcon style={{ color: theme.palette.red.main }} />
                                    ) : (
                                        <DeleteIcon style={{ color: theme.palette.red.main }} />
                                    )}
                                </ListItemIcon>
                                <ListItemText primary={info && info.type === 'folder' ? 'Delete folder' : 'Delete'} />
                            </ListItem>
                        </List>
                    )}
                </Box>
            </SwipeableDrawer>
        </Box>
    )
}

export default React.memo(PollItemInfo)
