import React from 'react'
import app from 'firebase/app'

import { Grid } from '@material-ui/core'

import NavigationBar from '../../../components/NavigationBar'
import TinyCard from '../../../components/Cards/TinyCard'

import moment from 'moment'

export default function Quotes(props) {
    const [quotes, setQuotes] = React.useState(null)
    const [hasTried, setHasTried] = React.useState(false)

    const [quoteSearch, setQuoteSearch] = React.useState('')

    if (!hasTried) {
        setHasTried(true)
        grabQuotes()
    }

    function grabQuotes() {
        const db = app.firestore()
        db.collection('quotes')
            .get()
            .then(function(querySnapshot) {
                let curQuotes = []
                querySnapshot.forEach(function(doc) {
                    let data = doc.data()
                    data['id'] = doc.id

                    let fullName = ''

                    if (data.orgName && data.orgSchool) {
                        fullName = `${data.orgName} @ ${data.orgSchool}`
                    } else if (data.orgName) {
                        fullName = data.orgName
                    } else if (data.orgSchool) {
                        fullName = data.orgSchool
                    }

                    data['fullName'] = fullName

                    curQuotes.push(data)
                })

                curQuotes = curQuotes.map(a => {
                    return { ...a, created: a.activity.length === 0 ? moment() : moment(new Date(0).setUTCSeconds(a.activity[0].ts.seconds)) }
                })

                curQuotes.sort((a, b) => a.created.isBefore(b.created))

                setQuotes(curQuotes)
            })
            .catch(function(error) {
                console.log('Error getting documents: ', error)
            })
    }

    function renderQuotes() {
        if (quotes !== null) {
            let newQuotes = quotes.filter(
                org =>
                    org.fullName
                        .toLowerCase()
                        .replace(/\s+/g, '')
                        .indexOf(quoteSearch) !== -1,
            )
            if (newQuotes.length > 0) {
                return newQuotes.map(quote => (
                    <Grid item xl={3} lg={4} sm={6} xs={12} key={quote.id}>
                        <TinyCard
                            onClick={() => props.history.push('/app/gc/quotes/' + quote.id)}
                            text={quote.fullName}
                            description={`Created: ${quote.created.format('MMMM D @ h:mm a')}`}
                        />
                    </Grid>
                ))
            }
        }

        return <></>
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'Quotes',
                        },
                    ]}
                    onSearch={text => setQuoteSearch(text.toLowerCase().replace(/\s+/g, ''))}
                    grid
                />
                {renderQuotes()}
            </Grid>
        </>
    )
}
