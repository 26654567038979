import React from 'react'

import { isMobileDevice as _isMobileDevice } from 'code/Helper'

import { Crisp } from 'crisp-sdk-web'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

const CrispContext = React.createContext()

const CRISP_STATUS = {
    OPEN: 'OPEN',
    NOT_INITIALIZED: 'NOT_INITIALIZED',
    CLOSED: 'CLOSED',
    INITIALIZING: 'INITIALIZING',
}

const isCrispInitiated = () => Crisp && Crisp.chat && window.$crisp && typeof window.$crisp.is === 'function'
const isCrispVisible = () => isCrispInitiated() && Crisp.chat.isVisible()

const LOCAL_STORAGE_PREFERS_CHAT_OPEN = 'chat_open_by_default'

function CrispProvider({ children }) {
    const [isMobileDevice] = React.useState(_isMobileDevice())

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const [crispStatus, setCrispStatus] = React.useState(
        !isCrispInitiated() ? CRISP_STATUS.NOT_INITIALIZED : isCrispVisible() ? CRISP_STATUS.OPEN : CRISP_STATUS.CLOSED,
    )

    function isChatAvailable() {
        // Must be on the web app
        if (isMobileDevice) return false

        // Must be signed in & a member of a chapter
        if (!user || !chapter) return false

        // Must be an admin in their chapter
        if (!(user.id in chapter.members) || chapter.members[user.id].role !== 'ADMIN') return false

        return true
    }

    const turnOnCrisp = shouldOpen => {
        if (crispStatus === CRISP_STATUS.CLOSED) {
            Crisp.chat.show()
            setCrispStatus(CRISP_STATUS.OPEN)
        } else if (crispStatus === CRISP_STATUS.NOT_INITIALIZED) {
            setCrispStatus(CRISP_STATUS.INITIALIZING)

            if (shouldOpen) {
                Crisp.chat.open() // show
            } else {
                Crisp.load()
            }

            Crisp.user.setEmail(user.email)
            Crisp.user.setNickname(`${user.first} ${user.last}`)
            Crisp.user.setAvatar(user.photoURL)

            Crisp.setAvailabilityTooltip(false)

            Crisp.session.onLoaded(() => {
                setCrispStatus(CRISP_STATUS.OPEN)

                /* Crisp.message.show('text', 'Welcome to Greek Connect!')
                Crisp.message.show('text', 'You can talk to me at any point from the Admin tab')

                Crisp.chat.Crisp.chat.onChatOpened(() => {
                    // Executed once the chat was opened
                    Crisp.message.show('text', 'Message 1')
                    Crisp.message.show('text', 'This can only be seen by admins')
                    Crisp.message.show('text', 'You can acces')
                })*/
            })
        }
        localStorage.setItem(LOCAL_STORAGE_PREFERS_CHAT_OPEN, true)
    }

    const turnOffCrisp = () => {
        if (isCrispInitiated()) {
            Crisp.chat.hide()
            setCrispStatus(CRISP_STATUS.CLOSED)
        }
        localStorage.removeItem(LOCAL_STORAGE_PREFERS_CHAT_OPEN)
    }

    React.useEffect(() => {
        Crisp.configure(`08a10b77-c718-43d8-8193-33cafbeb7e89`, { autoload: false })
    }, [])

    React.useEffect(() => {
        if (isChatAvailable() && crispStatus === CRISP_STATUS.NOT_INITIALIZED) {
            // check to see if it should be initialized
            if (localStorage.getItem(LOCAL_STORAGE_PREFERS_CHAT_OPEN) === 'true') {
                // initialize the chat
                Crisp.load()

                Crisp.session.onLoaded(() => {
                    setCrispStatus(CRISP_STATUS.OPEN)

                    // Crisp.message.show('text', `Hey ${user.first}, thanks for creating your chapter!`)
                    // Crisp.message.show('text', 'If you have any questions, please reach out to me from the Admin screen.')

                    /*Crisp.message.show('picker', {
                        id: 'enjoying-survey',
                        text: '${user.first}, are you enjoying Greek Connect?',
                        choices: [
                            {
                                value: 'no',
                                label: 'No',
                                selected: false,
                            },
                            {
                                value: 'somewhat',
                                label: 'Somewhat',
                                selected: false,
                            },
                            {
                                value: 'yes',
                                label: 'Yes',
                                selected: false,
                            },
                        ],
                    })*/

                    // Based on that...
                    // No -> Sorry to hear that, what can we do to improve your experience?
                    // Somewhat -> Thanks for the honest feedback. We want evreyone to enjoy Greek Connect.
                    // What don't you like about it?
                    // What do you enjoy about Greek Connect?
                    // Yes
                    // Glad to hear you like Greek Connect.
                    // Is there any feedback you have for us?
                    // Ask them for a testimonial after they provide feeback
                    // Would you be willing to give us a testimonial?
                    // Sure, maybe later, or no, thanks
                    // If sure, ask them to write out a 1-3 sentence description about what problems Greek Connect has solved for them and why they like it.
                })
            }
        }
    }, [user, chapter, isMobileDevice, crispStatus, isChatAvailable])

    return <CrispContext.Provider value={{ crispStatus, setCrispStatus, isChatAvailable, turnOffCrisp, turnOnCrisp }}>{children}</CrispContext.Provider>
}

export { CRISP_STATUS, Crisp, CrispContext, CrispProvider }
