import React from 'react'
import { Avatar, Box, IconButton, NativeSelect, InputBase, FormControl, Tooltip } from '@material-ui/core'
import { DeleteForeverOutlined as DeleteForeverIcon } from '@material-ui/icons'

import { withStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/styles'

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        border: 'none',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase)

const PollAuthors = ({ responses, curIndex, onChangeIndex, onDelete, history }) => {
    const theme = useTheme()

    const curResponse = responses[curIndex % responses.length]

    return (
        <Box style={{ display: 'flex', justifyContent: 'center', margin: 3 }}>
            <IconButton
                aria-haspopup="true"
                color="inherit"
                style={{ padding: theme.spacing(0.5) }}
                onClick={e => history.push('/app/profile/' + curResponse.author.id)}
            >
                <Avatar color="primary" alt="" src={curResponse.author.photo}>
                    {`${curResponse.author.first.charAt(0)}${curResponse.author.last.charAt(0)}`}
                </Avatar>
            </IconButton>
            <FormControl style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <NativeSelect
                    aria-label="Name"
                    value={curResponse.author.id}
                    onChange={e => {
                        let val = e.target.value

                        for (let i = 0; i < responses.length; i++) {
                            if (responses[i].author.id === val) {
                                onChangeIndex(i)
                            }
                        }
                    }}
                    input={<BootstrapInput />}
                >
                    {responses.map(resp => (
                        <option value={resp.author.id}>{`${resp.author.first} ${resp.author.last}`}</option>
                    ))}
                </NativeSelect>
            </FormControl>
            {onDelete && (
                <Box style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Tooltip title="Delete Response">
                        <IconButton onClick={onDelete}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        </Box>
    )
}

export default React.memo(PollAuthors)
