import React from 'react'

import { Box, ButtonBase } from '@material-ui/core'
import { Typography } from '../Wrappers'

// styles
import { useTheme } from '@material-ui/core/styles'
import cn from 'classnames'
import useStyles from './styles'

export default function Title(props) {
    var classes = useStyles()
    const theme = useTheme()

    const { icon, variant, onClick, slantLeft, slantRight, title, style, fullWidth } = props

    let color = props.color ? props.color : 'primary'
    let direction = props.direction ? props.direction : 'row'

    if (direction === 'column') {
        return (
            <Box
                className={classes.parent}
                style={{
                    filter: variant === 'white' ? null : `drop-shadow(0 0 4px ${theme.palette[color]['main']}80)`,
                    ...style,
                }}
            >
                <Box
                    className={cn(classes.box, {
                        [classes.fullWidthBox]: fullWidth === true,
                        [classes.boxYellow]: variant !== 'white' && color === 'yellow',
                        [classes.boxPurple]: variant !== 'white' && color === 'purple',
                        [classes.boxOrange]: variant !== 'white' && color === 'orange',
                        [classes.boxGreen]: variant !== 'white' && color === 'green',
                        [classes.boxBlue]: variant !== 'white' && color === 'blue',
                        [classes.boxDarkorange]: variant !== 'white' && color === 'darkorange',
                        [classes.boxRed]: variant !== 'white' && color === 'red',
                        [classes.boxGray]: variant !== 'white' && color === 'gray',
                        [classes.boxBluegray]: variant !== 'white' && color === 'bluegray',
                        [classes.boxSkewLeft]: variant !== 'white' && slantLeft,
                        [classes.boxSkewRight]: variant !== 'white' && slantRight,
                        [classes.boxWhite]: variant === 'white',
                        [classes.boxPurpleColor]: variant === 'white' && color === 'purple',
                        [classes.boxYellowColor]: variant === 'white' && color === 'yellow',
                        [classes.boxOrangeColor]: variant === 'white' && color === 'orange',
                        [classes.boxGreenColor]: variant === 'white' && color === 'green',
                        [classes.boxBlueColor]: variant === 'white' && color === 'blue',
                        [classes.boxDarkorangeColor]: variant === 'white' && color === 'darkorange',
                        [classes.boxRedColor]: variant === 'white' && color === 'red',
                        [classes.boxGrayColor]: variant === 'white' && color === 'gray',
                        [classes.boxBluegrayColor]: variant === 'white' && color === 'bluegray',
                    })}
                >
                    {onClick ? (
                        <ButtonBase
                            onClick={onClick}
                            className={cn(classes.button, classes.buttonVertical, {
                                [classes.buttonSkewLeft]: variant !== 'white' && slantLeft,
                                [classes.buttonSkewRight]: variant !== 'white' && slantRight,
                            })}
                        >
                            <Box className={classes.buttonVerticalInner}>
                                {icon && (
                                    <Box style={{ paddingTop: 8, paddingBottom: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        {icon}
                                    </Box>
                                )}
                                <Typography variant="h5" weight="bold" className={cn(classes.titleVertical, { [classes.titleHover]: variant !== 'white' })}>
                                    {title}
                                </Typography>
                            </Box>
                        </ButtonBase>
                    ) : (
                        <Box
                            className={cn(classes.button, classes.buttonVertical, {
                                [classes.buttonSkewLeft]: variant !== 'white' && slantLeft,
                                [classes.buttonSkewRight]: variant !== 'white' && slantRight,
                            })}
                        >
                            <Box className={classes.buttonVerticalInner}>
                                {icon && <Box style={{ paddingLeft: 8, paddingRight: 0, display: 'flex' }}>{icon}</Box>}
                                <Typography variant="h5" weight="bold" className={classes.titleVertical}>
                                    {title}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        )
    }

    return (
        <Box
            className={classes.parent}
            style={{
                filter: variant === 'white' ? null : `drop-shadow(0 0 4px ${theme.palette[color]['main']}80)`,
                ...style,
            }}
        >
            <Box
                className={cn(classes.box, {
                    [classes.boxYellow]: variant !== 'white' && color === 'yellow',
                    [classes.boxPurple]: variant !== 'white' && color === 'purple',
                    [classes.boxOrange]: variant !== 'white' && color === 'orange',
                    [classes.boxGreen]: variant !== 'white' && color === 'green',
                    [classes.boxBlue]: variant !== 'white' && color === 'blue',
                    [classes.boxDarkorange]: variant !== 'white' && color === 'darkorange',
                    [classes.boxRed]: variant !== 'white' && color === 'red',
                    [classes.boxGray]: variant !== 'white' && color === 'gray',
                    [classes.boxBluegray]: variant !== 'white' && color === 'bluegray',
                    [classes.boxSkewLeft]: variant !== 'white' && slantLeft,
                    [classes.boxSkewRight]: variant !== 'white' && slantRight,
                    [classes.boxWhite]: variant === 'white',
                    [classes.boxPurpleColor]: variant === 'white' && color === 'purple',
                    [classes.boxYellowColor]: variant === 'white' && color === 'yellow',
                    [classes.boxOrangeColor]: variant === 'white' && color === 'orange',
                    [classes.boxGreenColor]: variant === 'white' && color === 'green',
                    [classes.boxBlueColor]: variant === 'white' && color === 'blue',
                    [classes.boxDarkorangeColor]: variant === 'white' && color === 'darkorange',
                    [classes.boxRedColor]: variant === 'white' && color === 'red',
                    [classes.boxGrayColor]: variant === 'white' && color === 'gray',
                    [classes.boxBluegrayColor]: variant === 'white' && color === 'bluegray',
                })}
            >
                {onClick ? (
                    <ButtonBase
                        onClick={onClick}
                        className={cn(classes.button, {
                            [classes.buttonSkewLeft]: variant !== 'white' && slantLeft,
                            [classes.buttonSkewRight]: variant !== 'white' && slantRight,
                        })}
                    >
                        {icon && <Box style={{ paddingLeft: 8, paddingRight: 4, display: 'flex' }}>{icon}</Box>}
                        <Typography
                            variant="h5"
                            weight="bold"
                            className={cn(icon ? classes.titleIcon : classes.title, { [classes.titleHover]: variant !== 'white' })}
                        >
                            {title}
                        </Typography>
                    </ButtonBase>
                ) : (
                    <Box
                        className={cn(classes.button, {
                            [classes.buttonSkewLeft]: variant !== 'white' && slantLeft,
                            [classes.buttonSkewRight]: variant !== 'white' && slantRight,
                        })}
                    >
                        {icon && <Box style={{ paddingLeft: 8, paddingRight: 4, display: 'flex' }}>{icon}</Box>}
                        <Typography variant="h5" weight="bold" className={icon ? classes.titleIcon : classes.title}>
                            {title}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
