export class Rushee {
    /*
    id
    first
    last
    instagram
    snapchat
    major
    hometown
    year
    contact
    bidStatus
    newMemberNumber
    phone
    photoURL
    comments
    timeline
    votes
    */

    constructor(rushee) {
        this.id = rushee.id
        this.first = rushee.first
        this.last = rushee.last
        this.instagram = rushee.instagram
        this.snapchat = rushee.snapchat
        this.major = rushee.major
        this.hometown = rushee.hometown
        this.year = rushee.year
        this.contact = rushee.contact
        this.bidStatus = rushee.bidStatus ? rushee.bidStatus : 0
        this.newMemberNumber = rushee.newMemberNumber
        this.phone = rushee.phone
        this.photoURL = rushee.photoURL
        this.comments = rushee.comments
        this.timeline = rushee.timeline ? rushee.timeline : {}
        this.votes = rushee.votes
        this.eventsAttended = rushee.eventsAttended
        this.numVotes = rushee.numVotes
    }

    getEventsAttended() {
        let len = this.eventsAttended.length
        if (len > 0) {
            let text = 'Attended '
            for (let i = 0; i < len; i++) {
                let e = this.eventsAttended[i]
                text = text + e.name
                if (i < len - 2) {
                    //At least 2 more
                    text = text + ', '
                } else if (i < len - 1) {
                    //1 more
                    if (i > 0) {
                        text = text + ', and '
                    } else {
                        text = text + ' and '
                    }
                }
            }

            return text
        }

        return ''
    }

    getSubtitle() {
        if (this.year && this.major && this.hometown) {
            return this.major + ' | ' + this.hometown + ' | ' + this.year
        }

        if (this.year && this.major) {
            return this.major + ' | ' + this.year
        }

        if (this.year && this.hometown) {
            return this.hometown + ' | ' + this.year
        }

        if (this.hometown && this.major) {
            return this.major + ' | ' + this.hometown
        }

        if (this.hometown) {
            return this.hometown
        }

        if (this.year) {
            return this.hometown
        }

        if (this.major) {
            return this.hometown
        }

        return null
    }

    getVotes() {
        return this.votes
    }

    getVoteFields(voteFields) {
        var fieldIDs = Object.keys(voteFields)
        var fields = []
        for (var i = 0; i < fieldIDs.length; i++) {
            var fieldID = fieldIDs[i]
            var field = voteFields[fieldID]
            var fieldName = field.name

            let numVotes = 0
            let totalValue = 0

            //TODO: Calculate field value...
            let votes = this.votes
            for (let j = 0; j < this.votes.length; j++) {
                let vote = votes[j]
                if (vote !== undefined) {
                    numVotes += 1
                    if (field.type === 'CHECKBOX') {
                        if (vote[field.id] === true) {
                            totalValue += 1
                        }
                    } else {
                        totalValue += vote[field.id]
                    }
                }
            }

            if (numVotes === 0) {
                fields.push({ name: fieldName, value: 0 })
            } else {
                if (field.type === 'CHECKBOX') {
                    fields.push({
                        name: fieldName,
                        value: (totalValue / numVotes) * 100,
                    })
                } else {
                    fields.push({
                        name: fieldName,
                        value: (totalValue / numVotes) * 10,
                    })
                }
            }
        }

        return fields
    }

    getTimeline() {
        return this.timeline
    }

    getSortedTimeline() {
        let timeline = []

        for (var timelineID in this.timeline) {
            let tl = this.timeline[timelineID]
            timeline.push([timelineID, tl])
        }

        timeline.sort(function(a, b) {
            return a[1].timestamp.seconds - b[1].timestamp.seconds
        })

        return timeline
    }

    getComments() {
        return this.comments
    }

    getSortedComments() {
        let comments = []

        for (var commentID in this.comments) {
            let comment = this.comments[commentID]
            comments.push([commentID, comment])
        }

        comments.sort(function(a, b) {
            let aSize = a[1].likes.length - a[1].dislikes.length
            let bSize = b[1].likes.length - b[1].dislikes.length
            if (aSize !== bSize) {
                return bSize - aSize
            }
            return a[1].timestamp.seconds - b[1].timestamp.seconds
        })

        return comments
    }

    getPhotoURL() {
        return this.photoURL
    }

    getPhoto() {
        return this.photoURL
    }

    getPhone() {
        return this.phone
    }

    getNewMemberNumber() {
        return this.newMemberNumber
    }

    /*getBidStatusString() {

    }*/

    /*getBidStatus() {
        return this.bidStatus
    }*/

    getContact() {
        return this.contact
    }

    getYear() {
        return this.year
    }

    getHometown() {
        return this.hometown
    }

    getMajor() {
        return this.major
    }

    getInstagram() {
        return this.instagram
    }

    getSnapchat() {
        return this.snapchat
    }

    getId() {
        return this.id
    }

    getChapter() {
        return this.chapter
    }

    getFirst() {
        return this.first
    }

    getBidWeightedValue() {
        if (this.bidStatus === 0) {
            return 3
        } else if (this.bidStatus === 1) {
            return 1
        } else if (this.bidStatus === 2) {
            return 0
        } else if (this.bidStatus === 3) {
            return 4
        } else if (this.bidStatus === 4) {
            return 5
        } else if (this.bidStatus === 5) {
            return 2
        }
    }

    getBidStatus() {
        if (this.bidStatus === 0) {
            return 'None'
        } else if (this.bidStatus === 1) {
            return 'Received'
        } else if (this.bidStatus === 2) {
            return 'Accepted'
        } else if (this.bidStatus === 3) {
            return 'Denied'
        } else if (this.bidStatus === 4) {
            return 'Rejected'
        } else if (this.bidStatus === 5) {
            return 'Voted'
        }

        return ''
    }

    getLast() {
        return this.last
    }

    getFullName() {
        return this.getFirst() + ' ' + this.getLast()
    }

    getInitials() {
        let first = this.getFirst()
        let last = this.getLast()

        let text = ''
        if (first) {
            text += first.charAt(0)
        }

        if (last) {
            text += last.charAt(0)
        }
        return text
    }
}
