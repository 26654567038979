import React from 'react'

import { InfoOutlined as InfoIcon } from '@material-ui/icons'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { TumbleWeedOutlinedIcon } from 'components/Icons'
import Paper from 'components/Paper'
import { Avatar } from 'components/Wrappers'

import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'

import { tsToDate, getRelativeTime } from 'code/Helper'

import { useTheme } from '@material-ui/styles'

const DriveList = ({ loading, rows, onViewInfo, onClick }) => {
    const theme = useTheme()

    const NoRowsOverlay = () => (
        <Stack height="100%" alignItems="center" justifyContent="center">
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ flexGrow: 1 }}>
                <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor }} />
                <Typography style={{ color: theme.borderColor }}>There are no files here</Typography>
            </Stack>
        </Stack>
    )

    return (
        <Paper style={{ display: 'flex', flexGrow: 1, height: `calc(100vh - 155px)` }}>
            <DataGrid
                sx={{ border: 'none' }}
                rows={rows ?? []}
                loading={loading}
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                }}
                rowHeight={44}
                columnHeaderHeight={44}
                columns={[
                    {
                        field: 'name',
                        headerName: 'Name',
                        minWidth: 250,
                        flex: 2,
                        renderCell: ({ value, row }) => (
                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                {React.createElement(row.icon, { style: { marginInline: 12, verticalAlign: 'middle' } })}
                                {value ? value : 'Unnamed'}
                            </Typography>
                        ),
                        valueGetter: ({ value }) => (value ? value : 'unnamed'),
                        renderHeader: () => (
                            <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                Name
                            </Typography>
                        ),
                    },
                    {
                        field: 'author',
                        headerName: 'Author',
                        minWidth: 125,
                        flex: 1,
                        renderCell: ({ value, row }) => (
                            <Stack spacing={1.5} direction="row" alignItems="center">
                                <Avatar src={row.author.photo} style={{ width: 32, height: 32 }} />
                                <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {row.author.first} {row.author.last}
                                </Typography>
                            </Stack>
                        ),
                        valueGetter: ({ value }) => (value ? `${value.first} ${value.last}` : ''),
                        renderHeader: () => <Typography fontWeight="500">Author</Typography>,
                    },
                    {
                        field: 'lu',
                        headerName: 'Date added',
                        type: 'dateTime',
                        align: 'right',
                        headerAlign: 'right',
                        minWidth: 125,
                        flex: 1,
                        renderCell: ({ value }) => (
                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{getRelativeTime(value)}</Typography>
                        ),
                        valueGetter: ({ value }) => tsToDate(value),
                        renderHeader: () => <Typography fontWeight="500">Date added</Typography>,
                    },
                    {
                        field: 'actions',
                        type: 'actions',
                        getActions: ({ row: file }) => [
                            <GridActionsCellItem
                                icon={<InfoIcon />}
                                onClick={() => {
                                    onViewInfo(file.id)
                                }}
                                label="Info"
                            />,
                        ],
                    },
                ]}
                onRowClick={params => {
                    onClick(params.row.id)
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'name', sort: 'asc' }],
                    },
                }}
                hideFooterPagination
                hideFooterSelectedRowCount
                hideFooter
                disableColumnMenu
            />
        </Paper>
    )
}
export default DriveList
