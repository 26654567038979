import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    parentBox: {
        width: 256,
        backgroundColor: 'white',
        border: `2px solid #D8D8D8`,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.16)',
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'row',
    },
    titleIcon: {
        color: theme.palette.primary.main,
    },
    titleIconBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    titleText: {
        paddingLeft: 5,
        fontWeight: 'bold !important',
        fontSize: '18px !important',
        color: 'black',
        lineHeight: 1,
    },
    titleTextBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    votesBox: {
        height: 60,
        marginTop: 8,
        position: 'relative',
    },
    votePercentageBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    votePercentageLabel: {
        fontWeight: '500 !important',
        fontSize: '14px !important',
        color: 'black',
        flexGrow: 1,
        lineHeight: `21px`,
    },
    votePercentage: {
        fontWeight: 'bold !important',
        fontSize: '14px !important',
        color: 'black',
        lineHeight: `21px`,
    },
    voteCountBox: {
        marginTop: 8,
    },
    voteCountText: {
        fontSize: '16px !important',
        fontWeight: 'bold !important',
        color: 'black',
    },
    voteKeyBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    voteKeyColor: {
        borderRadius: 6,
        width: 12,
        height: 12,
    },
    voteKeyLabel: {
        fontSize: '12px !important',
        color: 'black',
        flexGrow: 1,
        margin: 2,
    },
    voteChart: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
    },
    voteResults: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    voteLegend: {
        display: 'flex',
        flexDirection: 'column',
    },
}))
