import React from 'react'

// import MUIPaper from '@mui/material/Paper'
import Widget from 'components/Widget'

import useStyles from './styles'

const Paper = ({ children, style }) => {
    const classes = useStyles()

    return (
        <Widget disableWidgetMenu noBodyPadding inheritHeight bodyClass={classes.paperBody} style={style}>
            {children}
        </Widget>
    )
}

export default Paper
