export const colors = [
    '#f44336',
    '#e91e63',
    '#6c5ce7',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#fce38a',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
]

export const daysArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const days = [
    { str: 'Sunday', val: 0 },
    { str: 'Monday', val: 1 },
    { str: 'Tuesday', val: 2 },
    { str: 'Wednesday', val: 3 },
    { str: 'Thursday', val: 4 },
    { str: 'Friday', val: 5 },
    { str: 'Saturday', val: 6 },
]

export const times = [
    { str: '12:00 AM', val: 0 },
    { str: '12:15 AM', val: 15 },
    { str: '12:30 AM', val: 30 },
    { str: '12:45 AM', val: 45 },
    { str: '1:00 AM', val: 60 },
    { str: '1:15 AM', val: 75 },
    { str: '1:30 AM', val: 90 },
    { str: '1:45 AM', val: 105 },
    { str: '2:00 AM', val: 120 },
    { str: '2:15 AM', val: 135 },
    { str: '2:30 AM', val: 150 },
    { str: '2:45 AM', val: 165 },
    { str: '3:00 AM', val: 180 },
    { str: '3:15 AM', val: 195 },
    { str: '3:30 AM', val: 210 },
    { str: '3:45 AM', val: 225 },
    { str: '4:00 AM', val: 240 },
    { str: '4:15 AM', val: 255 },
    { str: '4:30 AM', val: 270 },
    { str: '4:45 AM', val: 285 },
    { str: '5:00 AM', val: 300 },
    { str: '5:15 AM', val: 315 },
    { str: '5:30 AM', val: 330 },
    { str: '5:45 AM', val: 345 },
    { str: '6:00 AM', val: 360 },
    { str: '6:15 AM', val: 375 },
    { str: '6:30 AM', val: 390 },
    { str: '6:45 AM', val: 405 },
    { str: '7:00 AM', val: 420 },
    { str: '7:15 AM', val: 435 },
    { str: '7:30 AM', val: 450 },
    { str: '7:45 AM', val: 465 },
    { str: '8:00 AM', val: 480 },
    { str: '8:15 AM', val: 495 },
    { str: '8:30 AM', val: 510 },
    { str: '8:45 AM', val: 525 },
    { str: '9:00 AM', val: 540 },
    { str: '9:15 AM', val: 555 },
    { str: '9:30 AM', val: 570 },
    { str: '9:45 AM', val: 585 },
    { str: '10:00 AM', val: 600 },
    { str: '10:15 AM', val: 615 },
    { str: '10:30 AM', val: 630 },
    { str: '10:45 AM', val: 645 },
    { str: '11:00 AM', val: 660 },
    { str: '11:15 AM', val: 675 },
    { str: '11:30 AM', val: 690 },
    { str: '11:45 AM', val: 705 },
    { str: '12:00 PM', val: 720 },
    { str: '12:15 PM', val: 735 },
    { str: '12:30 PM', val: 750 },
    { str: '12:45 PM', val: 765 },
    { str: '1:00 PM', val: 780 },
    { str: '1:15 PM', val: 795 },
    { str: '1:30 PM', val: 810 },
    { str: '1:45 PM', val: 825 },
    { str: '2:00 PM', val: 840 },
    { str: '2:15 PM', val: 855 },
    { str: '2:30 PM', val: 870 },
    { str: '2:45 PM', val: 885 },
    { str: '3:00 PM', val: 900 },
    { str: '3:15 PM', val: 915 },
    { str: '3:30 PM', val: 930 },
    { str: '3:45 PM', val: 945 },
    { str: '4:00 PM', val: 960 },
    { str: '4:15 PM', val: 975 },
    { str: '4:30 PM', val: 990 },
    { str: '4:45 PM', val: 1005 },
    { str: '5:00 PM', val: 1020 },
    { str: '5:15 PM', val: 1035 },
    { str: '5:30 PM', val: 1050 },
    { str: '5:45 PM', val: 1065 },
    { str: '6:00 PM', val: 1080 },
    { str: '6:15 PM', val: 1095 },
    { str: '6:30 PM', val: 1110 },
    { str: '6:45 PM', val: 1125 },
    { str: '7:00 PM', val: 1140 },
    { str: '7:15 PM', val: 1155 },
    { str: '7:30 PM', val: 1170 },
    { str: '7:45 PM', val: 1185 },
    { str: '8:00 PM', val: 1200 },
    { str: '8:15 PM', val: 1215 },
    { str: '8:30 PM', val: 1230 },
    { str: '8:45 PM', val: 1245 },
    { str: '9:00 PM', val: 1260 },
    { str: '9:15 PM', val: 1275 },
    { str: '9:30 PM', val: 1290 },
    { str: '9:45 PM', val: 1305 },
    { str: '10:00 PM', val: 1320 },
    { str: '10:15 PM', val: 1335 },
    { str: '10:30 PM', val: 1350 },
    { str: '10:45 PM', val: 1365 },
    { str: '11:00 PM', val: 1380 },
    { str: '11:15 PM', val: 1395 },
    { str: '11:30 PM', val: 1410 },
    { str: '11:45 PM', val: 1425 },
]

export const EXCUSE_APPROVED = 2
export const EXCUSE_DENIED = 1
export const EXCUSE_AWAITING = 0

export const alarmsEventStart = [
    {
        text: 'at start',
        value: 0,
    },
    {
        text: '5 minutes before',
        value: -5,
    },
    {
        text: '10 minutes before',
        value: -10,
    },
    {
        text: '15 minutes before',
        value: -15,
    },
    {
        text: '30 minutes before',
        value: -30,
    },
    {
        text: '45 minutes before',
        value: -45,
    },
    {
        text: '1 hour before',
        value: -60,
    },
    {
        text: '2 hours before',
        value: -60 * 2,
    },
    {
        text: '3 hours before',
        value: -60 * 3,
    },
    {
        text: '6 hours before',
        value: -60 * 6,
    },
    {
        text: '12 hours before',
        value: -60 * 12,
    },
    {
        text: '1 day before',
        value: -60 * 24,
    },
]

export const alarmsEventDeadline = [
    {
        text: '1 hour before deadline',
        value: -60,
    },
    {
        text: '2 hours before deadline',
        value: -60 * 2,
    },
    {
        text: '3 hours before deadline',
        value: -60 * 3,
    },
    {
        text: '6 hours before deadline',
        value: -60 * 6,
    },
    {
        text: '12 hours before deadline',
        value: -60 * 12,
    },
    {
        text: '1 day before deadline',
        value: -60 * 24,
    },
]

export const alarmsRSVPDeadline = [
    {
        text: '1 hour before',
        value: -60,
    },
    {
        text: '2 hours before',
        value: -60 * 2,
    },
    {
        text: '3 hours before',
        value: -60 * 3,
    },
    {
        text: '6 hours before',
        value: -60 * 6,
    },
    {
        text: '12 hours before',
        value: -60 * 12,
    },
    {
        text: '1 day before',
        value: -60 * 24,
    },
]

export const alarmsSignInOpen = [
    {
        text: 'on open',
        value: 0,
    },
]

export const excuseDeadlines = [
    {
        text: 'at start',
        value: 0,
    },
    {
        text: '5 minutes before',
        value: -5,
    },
    {
        text: '10 minutes before',
        value: -10,
    },
    {
        text: '15 minutes before',
        value: -15,
    },
    {
        text: '30 minutes before',
        value: -30,
    },
    {
        text: '45 minutes before',
        value: -45,
    },
    {
        text: '1 hour before',
        value: -60,
    },
    {
        text: '2 hours before',
        value: -120,
    },
    {
        text: '3 hours before',
        value: -180,
    },
    {
        text: '4 hours before',
        value: -240,
    },
    {
        text: '5 hours before',
        value: -300,
    },
    {
        text: '6 hours before',
        value: -360,
    },
    {
        text: '12 hours before',
        value: -720,
    },
    {
        text: '1 day before',
        value: -1440,
    },
    {
        text: '2 days before',
        value: -1440 * 2,
    },
    {
        text: '3 days before',
        value: -1440 * 3,
    },
]
export const rsvpWaitlistDeadline = [
    /* {
        text: '10 minutes',
        value: 10,
    },*/
    {
        text: '30 minutes',
        value: 30,
    },
    {
        text: '1 hour',
        value: 60,
    },
    {
        text: '2 hours',
        value: 120,
    },
    {
        text: '3 hours',
        value: 180,
    },
    {
        text: '4 hours',
        value: 240,
    },
    {
        text: '5 hours',
        value: 300,
    },
    {
        text: '6 hours',
        value: 360,
    },
    {
        text: '8 hours',
        value: 60 * 8,
    },
    {
        text: '10 hours',
        value: 60 * 10,
    },
    {
        text: '12 hours',
        value: 720,
    },
    {
        text: '1 day',
        value: 1440,
    },
]

export const eventLengths = [
    /*{
        text: 'None',
        value: -1,
    },*/
    {
        text: '5 minutes',
        value: 5,
    },
    {
        text: '10 minutes',
        value: 10,
    },
    {
        text: '15 minutes',
        value: 15,
    },
    {
        text: '30 minutes',
        value: 30,
    },
    {
        text: '45 minutes',
        value: 45,
    },
    {
        text: '1 hour',
        value: 60,
    },
    {
        text: '1.5 hours',
        value: 90,
    },
    {
        text: '2 hours',
        value: 120,
    },
    {
        text: '2.5 hours',
        value: 150,
    },
    {
        text: '3 hours',
        value: 180,
    },
    {
        text: '4 hours',
        value: 240,
    },
    {
        text: '5 hours',
        value: 300,
    },
    {
        text: '6 hours',
        value: 360,
    },
    {
        text: '7 hours',
        value: 420,
    },
    {
        text: '8 hours',
        value: 480,
    },
    {
        text: '9 hours',
        value: 540,
    },
    {
        text: '10 hours',
        value: 600,
    },
    {
        text: '11 hours',
        value: 660,
    },
    {
        text: '12 hours',
        value: 720,
    },
    {
        text: '1 day',
        value: 1440,
    },
    {
        text: '2 days',
        value: 2880,
    },
    {
        text: '3 days',
        value: 4320,
    },
    {
        text: '4 days',
        value: 5760,
    },
    {
        text: '5 days',
        value: 7200,
    },
    {
        text: '6 days',
        value: 8640,
    },
    {
        text: '1 week',
        value: 10080,
    },
]

export const categories = [
    { name: 'Social', color: 'yellow' },
    { name: 'Worship', id: 'purple' },
    { name: 'Rush', id: 'primary' },
]
