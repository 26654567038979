import { convertListToObject, convertObjectToList, getWeek, getWeekYear } from 'code/Helper'

import { Subtasks } from './Subtasks'

import { daysArr } from 'pages/calendar/upcoming-events/mock'

import moment from 'moment-timezone'

export class Tasks {
    /*
    id - string
    name - string
    description - string
    type - integer: -1 = untimed, 0 = single occurance, 1 = repeats weekly
    approval - integer: 0 = automatic approval, 1 = manual approval
    assignees - array of user id
    supervisors - array of user id
    author - user id
    tasks - array of Subtask
    cd - creation date of the task
    */

    static get TYPE_UNTIMED() {
        return -1
    }

    static get TYPE_SINGLE() {
        return 0
    }

    static get TYPE_WEEKLY() {
        return 1
    }

    static get TYPE_BIWEEKLY() {
        return 2
    }

    static get APPROVAL_AUTOMATIC() {
        return 0
    }

    static get APPROVAL_MANUAL() {
        return 1
    }

    constructor(task) {
        this.id = task.id
        this.name = task.name
        this.description = task.description
        this.type = task.type
        this.approval = task.approval
        this.approved = task.approved
        this.assignees = task.assignees
        this.supervisors = task.supervisors ? task.supervisors : {}
        this.author = task.author
        this.subtasks = task.subtasks
        this.date = task.date ? task.date : null
        this.cd = task.cd ? task.cd : null
        this.weeks = task.weeks ? task.weeks : null
        this.notifications = task.notifications ? true : false
        this.supervisorsArr = task.supervisorsArr ? task.supervisorsArr : []
        this.assigneesArr = task.assigneesArr ? task.assigneesArr : []
    }

    static getNew() {
        return new Tasks({
            id: '',
            name: '',
            description: '',
            type: Tasks.TYPE_WEEKLY,
            approval: Tasks.APPROVAL_AUTOMATIC,
            assignees: {},
            supervisors: {},
            author: null,
            subtasks: [],
            date: null,
            notifications: true,
        })
    }

    getRaw() {
        let data = {
            id: this.id,
            name: this.name,
            description: this.description,
            type: this.type,
            approval: this.approval,
            assignees: this.assignees,
            author: this.author,
            subtasks: convertListToObject(this.subtasks, true),
            cd: this.cd,
            notifications: this.notifications ? true : false,
        }

        if (this.type !== Tasks.TYPE_UNTIMED) {
            data.date = this.date
        }

        if (this.approval === Tasks.APPROVAL_MANUAL) {
            data.approved = this.approved ? this.approved : false
            data.supervisors = this.supervisors
            data.supervisorsArr = Object.keys(this.supervisors)
        }

        if (this.weeks) {
            data.weeks = this.weeks
        }

        data.assigneesArr = Object.keys(this.assignees)

        return data
    }

    getId() {
        return this.id
    }

    setId(id) {
        this.id = id
    }

    setName(name) {
        this.name = name
    }

    getName() {
        return this.name
    }

    setDescription(description) {
        this.description = description
    }

    getDescription() {
        return this.description
    }

    getType() {
        return this.type
    }

    setType(type) {
        this.type = type
    }

    getApproval() {
        return this.approval
    }

    setApproval(approval) {
        this.approval = approval
    }

    isAssignee(userId) {
        return this.assignees && Object.keys(this.assignees).includes(userId)
    }

    isSupervisor(userId) {
        return this.supervisors && Object.keys(this.supervisors).includes(userId)
    }

    getAssignees() {
        return this.assignees
    }

    getSupervisors() {
        return this.supervisors
    }

    getTiming() {
        if (this.type === Tasks.TYPE_WEEKLY) {
            if (typeof this.date.sdow === 'number' && typeof this.date.edow === 'number') {
                let str = `${daysArr[this.date.sdow % 7]}s @ `

                let sm = this.date.st % 60
                let sh = Math.floor(this.date.st / 60)
                let smd = sh >= 12
                sh = sh % 12

                let st = sh === 0 ? '12' : '' + sh

                if (sm > 0) {
                    st += ':' + sm
                }

                st += smd ? ' pm' : ' am'

                let em = this.date.et % 60
                let eh = Math.floor(this.date.et / 60)
                let emd = eh >= 12
                eh = eh % 12

                let et = eh === 0 ? '12' : '' + eh

                if (em > 0) {
                    et += ':' + em
                }

                et += emd ? ' pm' : ' am'

                str += st

                if (this.date.sdow === this.date.edow) {
                    str += ' - ' + et
                } else {
                    str += ' - ' + daysArr[this.date.edow % 7] + 's @ ' + et
                }

                return str
            }
        }

        return ''
    }

    getVal() {
        if (this.isCompleted()) {
            return -2
        }

        if (!this.hasStarted()) {
            return -1
        }

        if (this.isLate()) {
            return 1
        }

        return 0
    }

    getStatus() {
        // If approved, show as approved
        if (this.isApproved(new Date())) {
            return 'Approved'
        }
        // If completed, show as completed
        if (this.isCompleted(new Date())) {
            // If awaiting approval, show as waiting approval
            if (this.approval === Tasks.APPROVAL_MANUAL) {
                return 'Awaiting Approval'
            }
            return 'Completed'
        }

        // If not timed, say that it is in progress
        if (!this.isTimed()) {
            return 'In progress'
        }

        // If late, say how late it is...
        if (this.isLate(new Date())) {
            return 'Late'
        }

        // If not complete but has started, say when it is due
        if (this.hasStarted(new Date())) {
            if (this.type === Tasks.TYPE_WEEKLY && this.date.et !== null && this.date.edow !== null) {
                let minutes = this.date.et % 60
                let hours = Math.floor(this.date.et / 60)
                let isPM = hours >= 12
                hours = hours % 12

                let str = hours === 0 ? '12' : '' + hours

                if (minutes > 0) {
                    str += ':' + minutes
                }

                str += isPM ? ' pm' : ' am'

                return `Due by ${str} ${daysArr[this.date.edow % 7]}`
            }

            return 'Started'
        }

        // Say when it starts
        if (this.type === Tasks.TYPE_WEEKLY && this.date.st !== null && this.date.sdow !== null) {
            let minutes = this.date.st % 60
            let hours = Math.floor(this.date.st / 60)
            let isPM = hours >= 12
            hours = hours % 12

            let str = hours === 0 ? '12' : '' + hours

            if (minutes > 0) {
                str += ':' + minutes
            }

            str += isPM ? ' pm' : ' am'

            return `Starts at ${str} ${daysArr[this.date.sdow % 7]}`
        }

        return 'Not Started'
    }

    getUsersCaption(u, self, isShort, baseCase) {
        let users = convertObjectToList(u)

        if (!Array.isArray(users) || users.length === 0) {
            return baseCase
        }

        // If user is in the assigned list, place their name first
        users.sort((a, b) => (a.id === self.id ? -1 : b.id === self.id ? 1 : a.last.localeCompare(b.last)))

        let str = users[0].id === self.id ? 'You' : `${users[0].first} ${users[0].last}`

        if (isShort) {
            if (users.length > 1) {
                return str + ` & ${users.length - 1} ${users.length === 2 ? 'Other' : 'Others'}`
            }
        }

        for (let i = 1; i < users.length; i++) {
            let user = users[i]

            if (i === 1 && users.length === 2) {
                str += ' & '
            } else if (users.length - i === 1) {
                str += ', & '
            } else if (users.length - i > 1) {
                str += ', '
            }

            str += user.id === self.id ? 'You' : `${user.first} ${user.last}`
        }

        return str
    }

    getSupervisorsCaption(user, isShort) {
        return this.getUsersCaption(this.supervisors, user, isShort, 'Unsupervised')
    }

    getAssigneesCaption(user, isShort) {
        return this.getUsersCaption(this.assignees, user, isShort, 'Unassigned')
    }

    setSupervisors(supervisors) {
        this.supervisors = supervisors
    }

    setAssignees(assignees) {
        this.assignees = assignees
    }

    isTimed() {
        return this.type !== Tasks.TYPE_UNTIMED
    }

    getStartTime(activeDate) {
        if (!this.isTimed()) {
            return activeDate ? activeDate : new Date()
        }

        let date = moment(activeDate ? activeDate : new Date())

        if (this.type === Tasks.TYPE_WEEKLY) {
            date.weekday(this.date.sdow)
            date.hour(Math.floor(this.date.st / 60))
            date.minute(this.date.st % 60)
        }

        return date.toDate()
    }

    getEndTime(activeDate) {
        if (!this.isTimed()) {
            return activeDate ? activeDate : new Date()
        }

        let date = moment(activeDate ? activeDate : new Date())

        if (this.type === Tasks.TYPE_WEEKLY) {
            date.weekday(this.date.edow)
            date.hour(Math.floor(this.date.et / 60))
            date.minute(this.date.et % 60)
        }

        return date.toDate()
    }

    hasStarted(activeDate) {
        if (!this.isTimed()) {
            return true
        }

        if (this.type === Tasks.TYPE_WEEKLY) {
            if (this.date && this.date.sdow !== null && this.date.st !== null) {
                // TODO: set the timezone to match the chapter's
                let date = moment()

                if (activeDate) {
                    // Check to see if the active date === than the current week
                    let aDate = moment(activeDate)
                    if (!aDate.isSame(date, 'week')) {
                        if (aDate.isBefore(date)) {
                            return true
                        } else {
                            return false
                        }
                    }
                }

                let dow = date.weekday()

                let minutes = date.get('hour') * 60 + date.get('minute')

                if (dow > this.date.sdow || (dow === this.date.sdow && this.date.st <= minutes)) {
                    return true
                }
            }
        }

        return false
    }

    isLate(activeDate) {
        if (!this.isTimed()) {
            return false
        }

        if (this.type === Tasks.TYPE_WEEKLY) {
            if (this.date && this.date.edow !== null && this.date.et !== null) {
                // TODO: set the timezone to match the chapter's
                let date = moment()

                if (activeDate) {
                    // Check to see if the active date === than the current week
                    let aDate = moment(activeDate)
                    if (!aDate.isSame(date, 'week')) {
                        if (aDate.isBefore(date)) {
                            return true
                        } else {
                            return false
                        }
                    }
                }

                let dow = date.weekday()

                let minutes = date.get('hour') * 60 + date.get('minute')

                if (dow > this.date.edow || (dow === this.date.edow && this.date.et < minutes)) {
                    return true
                }
            }
        }

        if (this.type === Tasks.TYPE_SINGLE) {
            // TODO: calculate to see if the timing is correct...
        }

        return false
    }

    isCompleted(activeDate, ignoreIds) {
        let subtasks = this.getSubtasks(activeDate)

        if (!subtasks) {
            return false
        }

        let curTasks = convertObjectToList(subtasks)

        for (let i = 0; i < curTasks.length; i++) {
            let curTask = curTasks[i]
            if (curTask.status === Subtasks.STATUS_NOT_COMPLETED) {
                if (!(Array.isArray(ignoreIds) && ignoreIds.includes(curTask.id))) {
                    return false
                }
            }
        }

        return true
    }

    isApproved(activeDate) {
        if (this.type === Tasks.TYPE_WEEKLY) {
            let date = activeDate ? activeDate : new Date()
            let year = getWeekYear(date)
            let week = getWeek(date)
            let str = `${year}_${week}`
            if (this.weeks && this.weeks[str]) {
                return this.weeks[str].approved === true
            }

            return false
        }

        return this.approved
    }

    getSubtasks(activeDate) {
        if (this.type === Tasks.TYPE_WEEKLY) {
            let date = activeDate ? activeDate : new Date()
            let year = getWeekYear(date)
            let week = getWeek(date)
            let str = `${year}_${week}`
            if (this.weeks && this.weeks[str]) {
                return convertObjectToList(this.weeks[str].subtasks)
            } else {
                let subtasks = [...convertObjectToList(this.subtasks)]
                for (let i = 0; i < subtasks.length; i++) {
                    let subtask = subtasks[i]
                    subtasks[i] = { i: subtask.i, name: subtask.name, status: 0 }
                }
            }
        }

        return this.subtasks
    }

    hasThisWeeksProgressSetup(activeDate) {
        if (this.type === Tasks.TYPE_WEEKLY) {
            let date = new Date(activeDate)
            let year = getWeekYear(date)
            let week = getWeek(date)
            let str = `${year}_${week}`
            if (this.weeks && this.weeks[str]) {
                return true
            }
        }

        return false
    }

    setSubtasks(subtasks, activeDate) {
        if (this.type === Tasks.TYPE_WEEKLY) {
            let date = new Date(activeDate)
            let year = getWeekYear(date)
            let week = getWeek(date)
            let str = `${year}_${week}`
            if (this.weeks && this.weeks[str]) {
                this.weeks[str].subtasks = subtasks
                return
            }
        }

        this.subtasks = subtasks
    }

    setDate(date) {
        this.date = date
    }

    getDate() {
        return this.date
    }

    hasAnyInvalidSubtasks() {
        if (!this.subtasks) {
            return false
        }

        let curTasks = convertObjectToList(this.subtasks)

        for (let i = 0; i < curTasks.length; i++) {
            let curTask = curTasks[i]
            if (!curTask.name || !curTask.name.trim()) {
                return true
            }
        }

        return false
    }
}
