import React from 'react'

import { getCoordinate } from 'code/Helper'

import { LocationContext } from 'context/LocationContext'
import { MapContext } from '../../Map'

import './style.css'

const UserMarker = ({ location, ...props }) => {
    const { mapkit } = React.useContext(LocationContext)
    const { map } = React.useContext(MapContext)

    const center = getCoordinate(mapkit, location)

    var factory = function(coordinate, options) {
        var div = document.createElement('div')
        div.className = 'user-marker'
        return div
    }

    /*new mapkit.BoundingRegion(
    number northLatitude,
    number eastLongitude,
    number southLatitude,
    number westLongitude
    );*/

    React.useEffect(() => {
        if (mapkit && map) {
            var annotation = new mapkit.Annotation(center, factory)
            map.addAnnotation(annotation)

            const radius = 30 // 30 feet is roughly the accuracy of GPS at its best

            /* Center the map location to the user */
            const span = new mapkit.CoordinateSpan(0.0001 * radius)
            const region = new mapkit.CoordinateRegion(center, span)

            map.setRegionAnimated(region)

            return () => {
                map && annotation && map.removeAnnotation(annotation)
            }
        }
    }, [mapkit, map, location])
    return null
}

export default UserMarker
