import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    sectionOuter: {
        marginLeft: 24,
        marginRight: 12,
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    dividerOuter: {
        padding: 0,
    },
    sectionInner: {
        display: 'flex',
        flexDirection: 'row',
    },
    sectionInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: 1,
    },
    sectionSwitch: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 'min(256px, 25%)',
    },
}))
