import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    dateRangePicker: { alignItems: 'center' },
    itineraryDay: {
        border: theme.border,
        borderRadius: 16,
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    widgetBody: { margin: 16 },
}))
