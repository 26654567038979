import React from 'react'

import { Capacitor, registerPlugin } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'

export const BackgroundGeolocation = registerPlugin('BackgroundGeolocation')

export const LocationContext = React.createContext()

/*global mapkit*/
export const LocationProvider = ({ children }) => {
    const tokenID =
        'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkJXSjc2WEE0NVYifQ.eyJpc3MiOiI1OEs3UVYyWjc1IiwiaWF0IjoxNzA2NzI5ODA3LCJleHAiOjE3MzgyODE2MDB9.vNWv9Jea62n13ToLGQMJLasU1Nkk4cYgLRiMLp04TcHF6XhzVr5tB1igp_OH-Wc3frYL2rEcDywyYu6gADqPzQ'

    React.useEffect(() => {
        mapkit.init({
            authorizationCallback: function(done) {
                done(tokenID)
            },
            language: 'en',
        })
    }, [])

    const getCurrentLocation = async props => {
        if (Capacitor.isPluginAvailable('Geolocation')) {
            const coordinates = await Geolocation.getCurrentPosition()

            return coordinates
        }

        return null
    }

    return (
        <LocationContext.Provider
            value={{
                mapkit,
                getCurrentLocation,
            }}
        >
            {children}
        </LocationContext.Provider>
    )
}
