import React from 'react'

import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import TextFieldOld from '@material-ui/core/TextField'
import Typography from '@mui/material/Typography'

import moment from 'moment'

import { MobileDatePicker as DatePicker } from '@mui/x-date-pickers/MobileDatePicker'

import { times } from 'pages/calendar/upcoming-events/mock'

import { getDateFromYMD, getYMDFromDate } from 'code/TimeAgo'
import { isIOSDevice } from 'code/Helper'

const DateRangePicker = ({ date, setDate }) => {
    const startRef = React.useRef(null)
    const endRef = React.useRef(null)

    const [isSafari] = React.useState(
        isIOSDevice() || (navigator.userAgent && navigator.userAgent.indexOf('Safari') > -1 && !(navigator.userAgent.indexOf('Chrome') > -1)),
    )

    const onDateChange = (start, end) => {
        let newStart = start
        let newEnd = end

        let newSD = date.sd
        let newED = date.ed

        if (newStart !== null) {
            newSD = getYMDFromDate(newStart)
        }

        if (newEnd !== null) {
            newED = getYMDFromDate(newEnd)
        }

        // If same day
        if (newSD.y === newED.y && newSD.m === newED.m && newSD.d === newED.d) {
            // if not all day
            if (!date.ad) {
                // IF it starts at the end of the day
                if (date.st === 1425) {
                    //Set the endtime to the next morning
                    let newET = (date.st + 60) % 1440
                    newED = moment(newStart)
                    newED.add('days', 1)
                    newED = getYMDFromDate(newED.toDate())
                    setDate({ ...date, et: newET, sd: newSD, ed: newED })
                } else {
                    // See if start time is greater than or equal to end time,
                    // if so, set et to start time + 15
                    if (date.st >= date.et) {
                        setDate({ ...date, et: date.st + 15, sd: newSD, ed: newED })
                    } else {
                        setDate({ ...date, sd: newSD, ed: newED })
                    }
                }
            } else {
                // if the whole day
                setDate({ ...date, sd: newSD, ed: newED })
            }
        } else {
            // Check if start date is after end date
            let newDate = date
            if (newSD.y > newED.y || (newSD.m > newED.m && newSD.y === newED.y) || (newSD.d > newED.d && newSD.m === newED.m && newSD.y === newED.y)) {
                if (start && !end) {
                    newED = newSD
                } else {
                    newSD = newED
                }

                if (!newDate.ad) {
                    // Check the times, and if problematic
                    if (newDate.st > newDate.et) {
                        if (newDate.st >= 1380) {
                            newDate.st = 1365
                            newDate.et = 1425
                        } else {
                            newDate.et = newDate.st + 60
                        }
                    }
                }
            }

            setDate({ ...newDate, sd: newSD, ed: newED })
        }
    }

    const StartTextField = function StartTextField(props) {
        return (
            <TextField
                {...props}
                label={props.value && props.value.length > 0 ? '' : 'Start Date'}
                margin="dense"
                size="small"
                style={{ marginTop: 4, width: 130.5, marginRight: !date.ad ? 4 : 0 }}
                InputLabelProps={{ shrink: false }}
                fullWidth={false}
                helperText={null}
                variant="outlined"
            />
        )
    }

    const EndTextField = function EndTextField(props) {
        return (
            <TextField
                {...props}
                label={props.value && props.value.length > 0 ? '' : 'End Date'}
                margin="dense"
                size="small"
                style={{ marginTop: 4, width: 130.5, marginLeft: !date.ad ? 4 : 0 }}
                InputLabelProps={{ shrink: false }}
                fullWidth={false}
                helperText={null}
                variant="outlined"
            />
        )
    }

    const RenderStartDate = () => {
        if (isSafari) {
            let value = moment(getDateFromYMD(date.sd)).format('YYYY-MM-DD')

            if (startRef.current) {
                startRef.current.value = value
            }

            return (
                <TextFieldOld
                    label={value && value.length > 0 ? '' : 'Start Date'}
                    margin="dense"
                    size="small"
                    style={{ marginTop: 4, flexGrow: 1, minWidth: 123, marginRight: !date.ad ? 4 : 0 }}
                    InputLabelProps={{ shrink: false }}
                    fullWidth={false}
                    helperText={null}
                    type="date"
                    variant="outlined"
                    defaultValue={value}
                    ref={startRef}
                    onBlur={e => {
                        if (e && e.target.value) {
                            let date = moment(e.target.value).toDate()
                            onDateChange(date, null)
                        }
                    }}
                />
            )
        }

        return (
            <DatePicker
                label="Start Date"
                value={moment(getDateFromYMD(date.sd)).startOf('day')}
                onChange={newValue => {
                    onDateChange(newValue.toDate(), null)
                }}
                slots={{
                    textField: StartTextField,
                }}
            />
        )
    }

    const RenderEndDate = () => {
        if (isSafari) {
            let value = moment(getDateFromYMD(date.ed)).format('YYYY-MM-DD')

            if (endRef.current) {
                endRef.current.value = value
            }

            return (
                <TextFieldOld
                    label={value && value.length > 0 ? '' : 'End Date'}
                    margin="dense"
                    size="small"
                    style={{ marginTop: 4, flexGrow: 1, minWidth: 123, marginLeft: !date.ad ? 4 : 0 }}
                    InputLabelProps={{ shrink: false }}
                    fullWidth={false}
                    helperText={null}
                    type="date"
                    variant="outlined"
                    defaultValue={value}
                    ref={endRef}
                    onBlur={e => {
                        if (e && e.target.value) {
                            let date = moment(e.target.value).toDate()
                            onDateChange(null, date)
                        }
                    }}
                />
            )
        }

        return (
            <DatePicker
                label="End Date"
                value={moment(getDateFromYMD(date.ed)).startOf('day')}
                onChange={newValue => {
                    onDateChange(null, newValue.toDate())
                }}
                slots={{
                    textField: EndTextField,
                }}
            />
        )
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                <Box>
                    <RenderStartDate />
                </Box>
                {!date.ad && (
                    <Box style={{ marginLeft: 4 }}>
                        <Select
                            native
                            variant="outlined"
                            margin="dense"
                            size="small"
                            value={date.st !== undefined ? date.st : 0}
                            onChange={event => {
                                let val = parseInt(event.target.value)

                                if (val >= date.et && date.sd.y === date.ed.y && date.sd.m === date.ed.m && date.sd.d === date.ed.d) {
                                    let val2 = val + 60
                                    if (val2 >= 1440) {
                                        val2 -= 1440
                                        // Incremement 1 to the date
                                        let newED = moment(getDateFromYMD(date.ed))
                                        newED.add('days', 1)

                                        setDate({
                                            ...date,
                                            st: val,
                                            et: val2,
                                            ed: getYMDFromDate(newED.toDate()),
                                        })
                                    } else {
                                        setDate({ ...date, st: val, et: val2 })
                                    }
                                } else {
                                    setDate({ ...date, st: val })
                                }
                            }}
                            style={{ marginTop: 4, marginBottom: 4 }}
                        >
                            {times.map(time => (
                                <option value={time.val} key={time.val}>
                                    {time.str}
                                </option>
                            ))}
                        </Select>
                    </Box>
                )}
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row', marginLeft: 8, marginRight: 8 }}>
                <Typography variant="body1"> to </Typography>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
                {!date.ad && (
                    <Box style={{ marginRight: 4 }}>
                        <Select
                            native
                            variant="outlined"
                            margin="dense"
                            size="small"
                            value={date.et !== undefined ? date.et : 15}
                            onChange={event => setDate({ ...date, et: parseInt(event.target.value) })}
                            style={{ marginTop: 4, marginBottom: 4 }}
                        >
                            {times.map(
                                time =>
                                    (date.sd.y !== date.ed.y ||
                                        date.sd.m !== date.ed.m ||
                                        date.sd.d !== date.ed.d ||
                                        (time.val > date.st && date.st !== undefined) ||
                                        (time.val > 0 && date.st === undefined)) && (
                                        <option value={time.val} key={time.val}>
                                            {time.str}
                                        </option>
                                    ),
                            )}
                        </Select>
                    </Box>
                )}
                <Box>
                    <RenderEndDate />
                </Box>
            </Box>
        </Box>
    )
}

export default React.memo(DateRangePicker)
