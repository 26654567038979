import React from 'react'

import { Link as LinkIcon } from '@material-ui/icons'
import { FileUploadIcon, FolderAddIcon } from 'components/Icons'

import Menu from 'components/Menu'
import Item from 'components/Menu/Item'

const DriveCreateItem = ({ disableFolder, onSelectAction, ...props }) => {
    const getItems = () => {
        const items = [<Item key="link" icon={LinkIcon} onClick={() => onSelectAction('link')} label="New Link" type={props.type} />]
        if (!disableFolder) items.push(<Item key="folder" icon={FolderAddIcon} onClick={() => onSelectAction('folder')} label="New Folder" type={props.type} />)
        items.push(<Item icon={FileUploadIcon} key="file" onClick={() => onSelectAction('file')} label="Upload File" type={props.type} />)
        //if (!disableFolder)
        //    items.push(<Item icon={FolderUploadIcon} key="folderV2" onClick={() => onSelectAction('folderV2')} label="Upload Folder" type={props.type} />)
        return items
    }

    return <Menu {...props}>{getItems()}</Menu>
}

export default React.memo(DriveCreateItem)
