import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    button: {
        '&:hover': {
            //background: '#dadce0',
            borderColor: theme.document.hoverOutline,
        },
        cursor: 'pointer',
    },
    selected: {
        background: theme.document.outline,
    },
    outline: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 4,
        fontSize: 16,
        marginTop: 4,
        marginBottom: 4,
        borderColor: theme.document.outline,
        width: '100%',
        color: theme.document.color,
        paddingTop: 7,
        paddingBottom: 7,
    },
    typography: {
        marginLeft: 2,
        marginRight: 2,
        flexGrow: 1,
    },

    closeLarge: {
        padding: 2,
        marginRight: 12,
    },

    closeMedium: {
        padding: 0,
        marginRight: 12,
    },

    closeSmall: {
        padding: 0,
        marginRight: 12,
    },
    disabled: {
        borderStyle: 'dotted !important',
        pointerEvents: 'none',
        opacity: 0.7,
    },
}))
