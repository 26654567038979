import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    section: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
    },
    focused: {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
    },
    focusedOther: {
        borderStyle: 'solid',
        borderWidth: 3,
        position: `relative`,
        '&:hover': {
            '& .focusedOtherChild': {
                opacity: 1,
            },
        },
        '&.blue-editor': {
            borderColor: theme.palette.blue.main,
            '& .focusedOtherChild': {
                backgroundColor: theme.palette.blue.main,
            },
        },
        '&.red-editor': {
            borderColor: theme.palette.red.main,
            '& .focusedOtherChild': {
                backgroundColor: theme.palette.red.main,
            },
        },
        '&.green-editor': {
            borderColor: theme.palette.green.main,
            '& .focusedOtherChild': {
                backgroundColor: theme.palette.green.main,
            },
        },
        '&.orange-editor': {
            borderColor: theme.palette.orange.main,
            '& .focusedOtherChild': {
                backgroundColor: theme.palette.orange.main,
            },
        },
        '&.purple-editor': {
            borderColor: theme.palette.purple.main,
            '& .focusedOtherChild': {
                backgroundColor: theme.palette.purple.main,
            },
        },
        '&.bluegray-editor': {
            borderColor: theme.palette.bluegray.main,
            '& .focusedOtherChild': {
                backgroundColor: theme.palette.bluegray.main,
            },
        },
    },
    focusedOtherInner: {
        position: 'absolute',
        right: -3,
        top: -3,
        borderRadius: 4,
        padding: 6,
        opacity: 0,
        transition: `opacity 0.2s`,
        color: 'white',
    },
    titleInput: {
        fontSize: 24,
        fontWeight: 600,
    },
    descriptionInput: {
        fontSize: 12,
    },
    descriptionRoot: {
        padding: `0 !important`,
    },
    picture: {
        maxWidth: `100%`,
        maxHeight: 512,
        borderRadius: 4,
    },
    pictureHolder: {
        display: `flex`,
        justifyContent: `center`,
    },
    textColor: {
        fill: theme.palette.text.primary,
    },
    responseBlock: {
        borderRadius: 4,
        backgroundColor: `${theme.palette.text.primary}0E`,
        padding: 10,
        width: '100%',
        marginTop: 4,
    },
    responseBlockAccessory: {
        fontWeight: 'bold !important',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: '1em',
    },
    responseBlockProgress: {
        borderRadius: 4,
        border: `2px solid ${theme.palette.text.primary}`,
        padding: '0.5em',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
    },
    responseBlockProgressInner: {
        backgroundColor: `${theme.palette.text.primary}20`,
        top: 0,
        left: 0,
        bottom: 0,
        position: 'absolute',
    },
    responseBlockText: {
        flexGrow: 1,
        marginLeft: '0.25em',
        marginRight: '0.25em',
        fontSize: '1em',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}))
