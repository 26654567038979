import React from 'react'

import { Avatar, Box, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Typography } from '@material-ui/core'
import { DeleteOutline as DeleteIcon, CreateOutlined as RenameIcon, VisibilityOutlined as VisibilityIcon } from '@material-ui/icons'

import { renderVisibility } from 'data/Visibility'

import { FileDownloadIcon, FileMoveIcon, FolderDeleteIcon } from 'components/Icons'

import { useTheme } from '@material-ui/styles'

const DriveItemInfo = ({ open, onClose, onSelectAction, hasAdmin, info, history, ...props }) => {
    var theme = useTheme()

    const showList = hasAdmin || (info && info.type === 'file')

    return (
        <Box style={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
            <SwipeableDrawer anchor="bottom" open={open} onClose={onClose} disableSwipeToOpen onOpen={() => {}}>
                <Box
                    style={{
                        height: '100%',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: 8,
                        paddingBottom: `env(safe-area-inset-bottom)`,
                        paddingLeft: `env(safe-area-inset-left)`,
                        paddingRight: `env(safe-area-inset-right)`,
                    }}
                >
                    {info && info.vis && (
                        <Typography align="center" variant="caption" style={{ lineHeight: 1, paddingBottom: 8 }}>
                            <VisibilityIcon style={{ width: '1rem', height: '1rem', verticalAlign: 'middle', paddingRight: '2px' }} />
                            {renderVisibility(info.vis)}
                        </Typography>
                    )}
                    {info && info.author && (
                        <>
                            <Divider />
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                }}
                            >
                                <IconButton color="inherit" size="small" onClick={() => history.push('/app/profile/' + info.author.id)}>
                                    <Avatar color="primary" alt="" src={info.author.photo}>
                                        {`${info.author.first.charAt(0)}${info.author.last.charAt(0)}`}
                                    </Avatar>
                                </IconButton>
                                <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                    <Typography>{`${info.author.first} ${info.author.last}`}</Typography>
                                </Box>
                            </Box>
                            {showList && <Divider />}
                        </>
                    )}
                    {showList && (
                        <List disablePadding>
                            {info && info.type === 'file' && (
                                <ListItem button onClick={() => onSelectAction('download')}>
                                    <ListItemIcon>
                                        <FileDownloadIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Download" />
                                </ListItem>
                            )}
                            {hasAdmin && (
                                <ListItem button onClick={() => onSelectAction('move')}>
                                    <ListItemIcon>
                                        <FileMoveIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Move to" />
                                </ListItem>
                            )}
                            {hasAdmin && (
                                <ListItem button onClick={() => onSelectAction('rename')}>
                                    <ListItemIcon>
                                        <RenameIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Rename" />
                                </ListItem>
                            )}
                            {hasAdmin && (
                                <ListItem button onClick={() => onSelectAction('delete')} style={{ color: theme.palette.red.main }}>
                                    <ListItemIcon>
                                        {info && info.type === 'folder' ? (
                                            <FolderDeleteIcon style={{ color: theme.palette.red.main }} />
                                        ) : (
                                            <DeleteIcon style={{ color: theme.palette.red.main }} />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText primary={info && info.type === 'folder' ? 'Delete folder' : 'Delete'} />
                                </ListItem>
                            )}
                        </List>
                    )}
                </Box>
            </SwipeableDrawer>
        </Box>
    )
}

export default React.memo(DriveItemInfo)
