import { Box, Grid } from '@material-ui/core'
import {
    CakeOutlined as BirthdayIcon,
    EditOutlined as EditIcon,
    HomeOutlined as HomeIcon,
    Instagram as InstagramIcon,
    LinkedIn as LinkedInIcon,
    LocationOnOutlined as LocationIcon,
    PhoneOutlined as PhoneIcon,
    ReportOutlined as ReportIcon,
    SchoolOutlined as SchoolIcon,
    Twitter as TwitterIcon,
} from '@material-ui/icons'
import moment from 'moment'
import React from 'react'

import axios from 'axios'

import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'
import { Link, Typography } from 'components/Wrappers' //Button

import { BackpackOutlinedIcon as BackpackIcon } from 'components/Icons'

import { useTheme } from '@material-ui/styles'
import useStyles from './styles'

import { apiPrefix, isAndroidDevice, isMobileDevice } from 'code/Helper'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { HeaderContext } from 'context/HeaderContext'

import Avatar from '@mui/material/Avatar'
import app from 'firebase/app'
import 'firebase/functions'

export default function ViewProfile(props) {
    const classes = useStyles()
    const theme = useTheme()

    //var member = members[0]

    const { currentUser } = React.useContext(AuthContext)

    const profile = {
        chapter: currentUser['chapter'],
        memberID: props.match.params.id,
    }
    const { chapter } = React.useContext(ChapterContext)
    const { setIcons } = React.useContext(HeaderContext)

    const [user, setUser] = React.useState(null)
    const [userID, setUserID] = React.useState(null)

    if (!userID || userID !== profile.memberID) {
        // Get member
        setUserID(profile.memberID)

        if (profile.memberID === currentUser.uid) {
            setUser(currentUser)
        } else {
            getProfile()
            setUser(null)
        }
    }

    function getProfile() {
        const db = app.firestore()
        db.collection('users')
            .doc(profile.memberID)
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    setUser(doc.data())
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    function getBirthday() {
        if (user && user.birthday && user.birthday.seconds) {
            return moment(new Date(0).setUTCSeconds(user.birthday.seconds)).format('MMMM D')
        } else {
            return moment(new Date(0).setUTCSeconds(user.birthday._seconds)).format('MMMM D')
        }
    }

    const getIcons = React.useCallback(() => {
        const reportUser = async () => {
            const body = {
                user: {
                    first: user.first,
                    last: user.last,
                    id: profile.memberID,
                },
                report: 'user',
            }

            try {
                await axios.post(`${apiPrefix}/app/messages/report`, body)
                window.alert(
                    `Thank you for your report, please consider blocking the user while we look over their content to see if it breaks our objectionable content policy.`,
                )
            } catch (e) {
                window.alert(`Ran into error while trying to report user, please try again.`)
            }
        }

        const icons = []

        if (isAndroidDevice() && user && currentUser && currentUser.uid !== profile.memberID) {
            icons.push({
                name: 'Report',
                type: 'icon',
                innerIcon: <ReportIcon />,
                onClick: () => {
                    reportUser()
                },
            })
        }

        icons.push({
            name: 'Edit',
            type: 'icon',
            innerIcon: <EditIcon />,
            onClick: () => {
                props.history.push('/app/profile/' + profile.memberID + '/edit')
            },
        })

        return icons
    }, [user, currentUser, profile.memberID, props.history])

    React.useEffect(() => {
        setIcons(getIcons())
    }, [getIcons, setIcons])

    const renderProfileData = () => (
        <Box
            display="flex"
            flexDirection="column"
            style={{
                marginLeft: 24,
                marginRight: 24,
                marginTop: 8,
                marginBottom: 6,
                gap: 4,
            }}
        >
            <Typography variant="h3">
                <span style={{ fontWeight: 'bold' }}>
                    {user.first} {user.last}
                </span>
                {user.rollNumber &&
                    profile.memberID in chapter.members &&
                    (chapter.members[profile.memberID].status > 0 ? (
                        <span style={{ marginLeft: 8, fontWeight: 'semibold' }}>
                            {chapter.letters} {user.rollNumber}
                        </span>
                    ) : (
                        <span style={{ marginLeft: 8, fontWeight: 'semibold' }}>
                            {chapter.newMemberShortname} {user.rollNumber}
                        </span>
                    ))}
            </Typography>
            {user.major && (
                <Typography>
                    <SchoolIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                    {user.major}
                </Typography>
            )}
            {user.hometown && (
                <Typography>
                    <LocationIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                    {user.hometown}
                </Typography>
            )}
            {user.class && (
                <Typography>
                    <BackpackIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                    Class of {user.class}
                </Typography>
            )}
            {user.birthday && (
                <Typography>
                    <BirthdayIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                    {getBirthday()}
                </Typography>
            )}
            {user.phone && (
                <Typography component={Link} href={'sms:' + user.phone}>
                    <PhoneIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                    {user.phone}
                </Typography>
            )}
            {user && (user.instagram || user.twitter || user.linkedIn) && (
                <Grid
                    container
                    direction="row"
                    style={{
                        justifyContent: 'center',
                        margin: '0 auto',
                    }}
                >
                    {user.instagram && (
                        <Link href={'http://instagram.com/' + user.instagram} className={classes.socialIcon}>
                            <InstagramIcon />
                        </Link>
                    )}
                    {user.twitter && (
                        <Link href={'http://twitter.com/' + user.twitter} className={classes.socialIcon}>
                            <TwitterIcon />
                        </Link>
                    )}
                    {user.linkedIn && (
                        <Link href={user.linkedIn} className={classes.socialIcon}>
                            <LinkedInIcon />
                        </Link>
                    )}
                </Grid>
            )}
        </Box>
    )

    //TODO: Let admins edit anyone
    return (
        <>
            <Grid container spacing={2}>
                {(currentUser && currentUser.uid && currentUser.uid === userID) ||
                (chapter && chapter.members && chapter.members[currentUser.uid] && chapter.members[currentUser.uid].role === 'ADMIN') ? (
                    <NavigationBar
                        titles={[
                            {
                                name: 'My House',
                                link: '/app/dashboard/',
                                icon: <HomeIcon />,
                            },
                            { name: 'Account' },
                        ]}
                        rightButtons={isAndroidDevice() ? null : getIcons()}
                        grid
                    />
                ) : (
                    <NavigationBar titles={[{ name: 'Account' }]} grid />
                )}
                {user && (
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item sm={4} xs={12} style={{ paddingBottom: 6, paddingTop: 5 }}>
                                {user.photoURL && user.photoURL.length > 0 && (
                                    <Avatar
                                        src={user.photoURL}
                                        sx={
                                            isMobileDevice()
                                                ? { width: '100%', maxWidth: 384, aspectRatio: '1', height: 'unset', margin: '0 auto', borderRadius: '4px' }
                                                : {
                                                      maxWidth: 256,
                                                      width: '100%',
                                                      aspectRatio: '1',
                                                      height: 'unset',
                                                      borderRadius: '4px',
                                                      margin: 'auto',
                                                      boxShadow: theme.customShadows.widget,
                                                  }
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                {isMobileDevice() ? (
                                    renderProfileData()
                                ) : (
                                    <Widget
                                        disableWidgetMenu
                                        noBodyPadding
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {renderProfileData()}
                                    </Widget>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    )
}
