import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    icon: {
        fontSize: theme.spacing(2),
    },
    divider: {
        margin: `0px -${theme.spacing(3)}px`,
    },
    wrapper: {
        '& > *': { paddingTop: theme.spacing(5) },
        '& > :first-child': {
            paddingTop: 0,
        },
    },
    navPadding: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
    },
    addCommentBox: {
        marginTop: 12,
    },
    dropdownField: {
        marginTop: 5,
        marginBottom: 4,
    },
    commentProgress: {
        paddingLeft: 12,
        paddingRight: 4,
        paddingBottom: 11,
        paddingTop: 4,
    },
    commentsSection: { padding: '4px 8px' },
    progressSection: {
        marginBottom: theme.spacing(1),
    },
    progressTitle: {
        marginBottom: theme.spacing(2),
    },
    deleteButton: {
        paddingLeft: `8px !important`,
        paddingRight: `8px !important`,
    },
    progress: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.gray.main,
    },
    progressBar: {
        backgroundColor: theme.palette.primary.main,
    },
    chip: {
        margin: 4,
    },
}))
