import { Grid, TextField } from '@material-ui/core'
import { HomeWorkOutlined as OrganizationsIcon } from '@material-ui/icons'
import app from 'firebase/app'
import React from 'react'

import FormSubmission from 'components/Forms/FormSubmission'
import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'

export default function UpdateOrganization(props) {
    let orgId = props.match.params.id
    let isNew = orgId ? false : true

    const [state, setState] = React.useState({ exists: null, loading: false })

    const [organization, setOrganization] = React.useState(null)

    if (state.exists === null && state.loading === false) {
        if (!isNew) {
            getOrganization()
        } else {
            setOrganization({
                customAppName: '',
                fullName: '',
                letters: '',
            })
            setState(state => ({ ...state, exists: true }))
        }
    }

    function getOrganization() {
        setState(state => ({ ...state, loading: true }))
        const db = app.firestore()
        db.collection('fraternities')
            .doc(orgId)
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    setState(state => ({ ...state, loading: false, exists: true }))
                    setOrganization(doc.data())
                } else {
                    setState(state => ({ ...state, loading: false, exists: false }))
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
                setState(state => ({ ...state, loading: false, exists: false, error: error }))
            })
    }

    return (
        <Grid container spacing={2}>
            <NavigationBar
                titles={[
                    {
                        name: 'Organizations',
                        link: '/app/gc/organizations',
                        icon: <OrganizationsIcon />,
                    },
                    {
                        name: isNew ? 'Create' : 'Update',
                    },
                ]}
                grid
            />
            {organization && (
                <Grid item xs={12}>
                    <Widget disableWidgetMenu inheritHeight>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    autoFocus={isNew}
                                    margin="dense"
                                    label={organization.fullName ? '' : 'Full Name'}
                                    InputLabelProps={{ shrink: false }}
                                    type="text"
                                    value={organization.fullName}
                                    onChange={e => {
                                        setOrganization(organization => ({ ...organization, fullName: e.target.value }))
                                    }}
                                    inputProps={{
                                        maxLength: 64,
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label={organization.letters ? '' : 'Letters'}
                                    InputLabelProps={{ shrink: false }}
                                    type="text"
                                    value={organization.letters}
                                    onChange={e => {
                                        setOrganization(organization => ({ ...organization, letters: e.target.value }))
                                    }}
                                    inputProps={{
                                        maxLength: 24,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label={organization.customAppName ? '' : 'Custom App Name'}
                                    InputLabelProps={{ shrink: false }}
                                    type="text"
                                    value={organization.customAppName}
                                    onChange={e => {
                                        setOrganization(organization => ({ ...organization, customAppName: e.target.value }))
                                    }}
                                    inputProps={{
                                        maxLength: 24,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormSubmission
                                    isLoading={state.loading}
                                    onCancel={() => {}}
                                    onSubmit={() => {
                                        console.log('todo!')
                                    }}
                                    submitText={isNew ? 'Create' : 'Update'}
                                    errorText={state.error}
                                    submitDisabled={organization.fullName === ''}
                                />
                            </Grid>
                        </Grid>
                    </Widget>
                </Grid>
            )}
        </Grid>
    )
}
