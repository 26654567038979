import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    button: {
        '&:hover': {
            background: '#dadce0',
            cursor: 'pointer',
        },
    },
    disabled: {
        borderStyle: 'dotted !important',
        pointerEvents: 'none',
        opacity: 0.7,
    },
    large: {
        height: 63,
        width: '100%',
        paddingTop: 12,
        paddingBottom: 12,
        border: '2px solid #dadce0',
        borderRadius: 6,
    },

    medium: {
        height: 40,
        width: '100%',
        paddingTop: 6,
        paddingBottom: 6,
        border: '2px solid #dadce0',
        borderRadius: 4,
    },

    small: {
        height: 30,
        width: '100%',
        paddingTop: 3,
        paddingBottom: 3,
        border: '1px solid #dadce0',
        borderRadius: 4,
    },

    typography: {
        marginLeft: 2,
        marginRight: 2,
        flexGrow: 1,
    },

    closeLarge: {
        padding: 2,
        marginRight: 12,
    },

    closeMedium: {
        padding: 0,
        marginRight: 12,
    },

    closeSmall: {
        padding: 0,
        marginRight: 12,
    },
}))

//dadce0
