import React from 'react'

import { Document, Page, Text } from 'components/PDF'

import { Svg, Path, View, Image, G, Defs, ClipPath, Rect, Stop, LinearGradient } from '@react-pdf/renderer'

import QRCode from 'qrcode.react'

import { renderToString } from 'react-dom/server'

const PDF = ({ icon, code, ...props }) => {
    const qrCode = (
        <QRCode
            id="sign-in-qr-code"
            renderAs="svg"
            size={512}
            bgColor="#bababa"
            fgColor="#000000"
            includeMargin={true}
            style={{ width: `100%`, height: `100%`, maxWidth: 256, borderRadius: 6 }}
            value={`https://greekconnect.app/join/${code}`}
        />
    )

    const str = renderToString(qrCode)

    const getInnerPath = str => {
        const checkStart = '<path fill="#000000" d="'
        const start = str.substring(str.indexOf(checkStart) + checkStart.length)

        const checkEnd = '"></path></svg>'

        const end = start.substring(0, start.indexOf(checkEnd))

        return end
    }

    const textTransform = 'uppercase'

    return (
        <Document title="Greek Connect Signups" author="Greek Connect" creator="Greek Connect">
            <Page>
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                    fixed
                >
                    <Svg width="612" height="792" viewBox="0 0 612 792">
                        <Defs>
                            <LinearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                <Stop offset="0" stopColor="#3493e7" />
                                <Stop offset="1" stopColor="#0b6abe" />
                            </LinearGradient>
                            <ClipPath id="c">
                                <Rect width="612" height="792" />
                            </ClipPath>
                        </Defs>
                        <G id="b" clipPath="url(#c)">
                            <Path d="M0,0H612V356L0,514Z" fill="url(#a)" />
                        </G>
                    </Svg>
                </View>
                <Image
                    src={icon}
                    style={{
                        width: 128,
                        height: 128,
                        display: 'block',
                        margin: '0 auto',
                        marginBottom: 16,
                    }}
                />
                <Text variant="title" style={{ fontSize: 38, color: '#ffffff', textTransform: textTransform, fontFamily: 'Roboto-Bold', textAlign: 'center' }}>
                    Greek Connect
                </Text>
                <Text variant="title" style={{ fontSize: 34, color: '#ffffff', textTransform: textTransform, fontFamily: 'Roboto-Bold', textAlign: 'center' }}>
                    scan
                </Text>
                <Text variant="title" style={{ fontSize: 24, color: '#ffffff', textTransform: textTransform, fontFamily: 'Roboto-Bold', textAlign: 'center' }}>
                    to register
                </Text>
                <Svg width={256} height={256} viewBox="0 0 37 37" style={{ display: 'block', margin: '0 auto' }}>
                    <Path d={getInnerPath(str)} fill="#000000" />
                </Svg>
                <Text variant="title" style={{ fontSize: 24, color: '#000000', textTransform: textTransform, fontFamily: 'Roboto-Bold', textAlign: 'center' }}>
                    or go to
                </Text>
                <Text variant="title" style={{ fontSize: 38, color: '#000000', textTransform: textTransform, fontFamily: 'Roboto-Bold', textAlign: 'center' }}>
                    greekconnect.app
                </Text>
                <Text variant="title" style={{ fontSize: 24, color: '#000000', textTransform: textTransform, fontFamily: 'Roboto-Bold', textAlign: 'center' }}>
                    & use activation code
                </Text>
                <Text variant="title" style={{ fontSize: 34, color: '#1e88e5', textTransform: textTransform, fontFamily: 'Roboto-Bold', textAlign: 'center' }}>
                    {code}
                </Text>
            </Page>
        </Document>
    )
}

export default PDF
