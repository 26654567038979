import React from 'react'
import { Box, Link, Typography } from '@material-ui/core'

// styles
import useStyles from './styles'

export default function Tabs(props) {
    var classes = useStyles()

    const { tabs, autoSize, selected, onChange, style, disabled } = props

    return (
        <Box className={classes.parentBox} style={style}>
            <Box display="flex" justifyContent="center" style={{ margin: '0 auto', width: 'fit-content' }}>
                {tabs.map(tab => (
                    <Box key={tab.id} className={classes.tabOuterBox}>
                        <Box style={autoSize ? { paddingLeft: 4, paddingRight: 4 } : null}>
                            <Link
                                onClick={() => {
                                    if (onChange && !(disabled === true || tab.disabled)) {
                                        onChange(tab.id)
                                    }
                                }}
                                disabled={disabled === true || tab.disabled}
                                className={
                                    selected === tab.id
                                        ? classes.selectedLink
                                        : !(disabled === true || tab.disabled)
                                        ? classes.selectableLink
                                        : classes.disabledLink
                                }
                            >
                                <Typography className={classes.text} style={autoSize ? { marginLeft: 4, marginRight: 4 } : null}>
                                    {tab.name}
                                </Typography>
                            </Link>
                            {selected === tab.id ? (
                                <div style={{ width: tab.width ? tab.width : 'auto' }} className={classes.underline}></div>
                            ) : (
                                <div style={{ width: tab.width ? tab.width : 'auto' }} className={classes.underlineHidden}></div>
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export function TabsIcon(props) {
    var classes = useStyles()

    const { tabs, selected, onChange, style, disabled } = props

    return (
        <Box className={classes.parentBox} style={style}>
            <Box display="flex" justifyContent="center" style={{ margin: '0 auto', display: 'flex', flexDirection: 'row' }}>
                {tabs.map(tab => (
                    <Box key={tab.key ? tab.key : tab.id} className={classes.tabOuterBox} style={{ flexGrow: 1 }}>
                        <Box>
                            <Link
                                onClick={() => {
                                    if (onChange && !(disabled === true || tab.disabled)) {
                                        onChange(tab.id)
                                    }
                                }}
                                disabled={disabled === true || tab.disabled}
                                className={
                                    selected === tab.id
                                        ? classes.selectedLink
                                        : !(disabled === true || tab.disabled)
                                        ? classes.selectableLink
                                        : classes.disabledLink
                                }
                                style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: 4 }}
                            >
                                {tab.icon}
                            </Link>
                            {selected === tab.id ? (
                                <div style={{ width: tab.width ? tab.width : 'auto' }} className={classes.underline}></div>
                            ) : (
                                <div style={{ width: tab.width ? tab.width : 'auto' }} className={classes.underlineHidden}></div>
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}
