import { Box, Button, CircularProgress, Divider, IconButton } from '@material-ui/core'
import React from 'react'

import {
    /*Tasks Icons*/
    /*Tip Icons*/
    /* Other Icons*/
    AccessTime as TimeIcon,
    CalendarToday as CalendarIcon,
    FastfoodOutlined as MealsIcon,
    LocationOnOutlined as LocationIcon,
    PollOutlined as PollsIcon,
} from '@material-ui/icons'

import { Avatar, Chip as CustomChip, Typography } from 'components/Wrappers'

import { getEventTiming, timeAgo } from 'code/TimeAgo'

import format from 'date-fns/format'
import startOfWeek from 'date-fns/startOfWeek'

import { AuthContext } from 'context/AuthContext'

import cn from 'classnames'

// styles
import useStyles from './styles'

export default function GCWidget(props) {
    var classes = useStyles()

    const { user } = React.useContext(AuthContext)

    const { type, history, hideHeader } = props

    function getDateFromTimestamp(d) {
        if (d) {
            return new Date(0).setUTCSeconds(d.seconds ? d.seconds : d._seconds)
        }

        return new Date()
    }

    function getAvatar(photoURL, userId) {
        return (
            <Box className={classes.avatarParent}>
                <IconButton className={classes.avatarButton} onClick={_ => history.push(`/app/profile/${userId}`)}>
                    <Avatar src={photoURL} className={classes.avatar} />
                </IconButton>
            </Box>
        )
    }

    if (props.loading) {
        let text = type
        if (text === 'events') {
            text = 'Event'
        } else if (text === 'polls') {
            text = 'Poll'
        }

        return (
            <Box className={classes.boxLoading}>
                <Typography style={{ fontWeight: 500, marginLeft: 24, marginRight: 8 }}>Loading {text}</Typography>
                <CircularProgress size="1.5rem" className={classes.progressCircle} />
            </Box>
        )
    }

    if (props.exists === false) {
        let text = type
        if (text === 'events') {
            text = 'Event'
        } else if (text === 'polls') {
            text = 'Poll'
        }

        return (
            <Box className={classes.boxLoading}>
                <Typography style={{ fontWeight: 500, marginLeft: 24, marginRight: 24 }}>{text} doesn't exist</Typography>
            </Box>
        )
    }

    if (type === 'events') {
        return (
            <Box className={classes.root}>
                {hideHeader ? (
                    <Box className={classes.topIconHeader}>
                        <CalendarIcon className={cn(classes.icon, classes.topIconHeaderIcon, { [classes.iconShrunk]: props.shrunk })} />
                    </Box>
                ) : (
                    <>
                        <Box className={classes.top}>
                            <Box className={classes.topTitle}>
                                {getAvatar(props.creator.photo, props.author)}
                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography
                                        style={{ fontWeight: 500, lineHeight: 1, fontSize: '1em' }}
                                    >{`${props.creator.first} ${props.creator.last}`}</Typography>
                                    <Typography variant="subtitle2" style={{ lineHeight: 1, fontSize: '0.875em' }} className={classes.nonOverflowText}>
                                        <CalendarIcon className={cn(classes.icon, { [classes.iconShrunk]: props.shrunk })} />
                                        {props.location || props.address ? (
                                            <>
                                                {` • `}
                                                <LocationIcon className={cn(classes.icon, { [classes.iconShrunk]: props.shrunk })} />
                                                {props.location ? props.location : props.address}
                                            </>
                                        ) : (
                                            <>
                                                {` • `}
                                                <TimeIcon className={cn(classes.icon, { [classes.iconShrunk]: props.shrunk })} />
                                                {` ${getEventTiming(props, { includeLength: false, includeTZ: false, notRelative: true })}`}
                                            </>
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </>
                )}
                <Box className={classes.body}>
                    <Typography align="center" style={{ fontWeight: 500, fontSize: '1em' }} className={classes.nonOverflowText}>
                        {props.name}
                    </Typography>
                    {hideHeader && (props.location || props.address) && (
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <LocationIcon className={cn(classes.icon, { [classes.iconShrunk]: props.shrunk })} />
                            {props.location ? props.location : props.address}
                        </Box>
                    )}
                    <Typography align="center" style={{ fontSize: '1em' }} className={classes.nonOverflowText}>
                        {getEventTiming(props, { includeLength: true, includeTZ: props.isDifferentTimezone })}
                    </Typography>
                    {props.message}
                    {!hideHeader && Array.isArray(props.categories) && props.categories.length > 0 && (
                        <Box display="flex" justifyContent="center" alignItems="center">
                            {props.categories.map(category => (
                                <CustomChip
                                    key={`${props.id}.${category.id}`}
                                    className={classes.chip}
                                    color={category.color}
                                    label={category.name}
                                    style={{ margin: '0.25em' }}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
                <Divider />
                <Box className={classes.bottom}>
                    <Button
                        color="primary"
                        className={classes.action}
                        onClick={() => {
                            history.push('/app/calendar/event/' + props.id)
                        }}
                    >
                        View
                    </Button>
                </Box>
            </Box>
        )
    }

    if (type === 'polls') {
        return (
            <Box className={classes.root}>
                {hideHeader ? (
                    <Box className={classes.topIconHeader}>
                        <PollsIcon className={cn(classes.icon, classes.topIconHeaderIcon, { [classes.iconShrunk]: props.shrunk })} />
                    </Box>
                ) : (
                    <>
                        <Box className={classes.top}>
                            <Box className={classes.topTitle}>
                                {getAvatar(props.author.photo, props.author.id)}
                                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography
                                        style={{ fontWeight: 500, lineHeight: 1, fontSize: '1em' }}
                                        className={classes.nonOverflowText}
                                    >{`${props.author.first} ${props.author.last}`}</Typography>
                                    <Typography variant="subtitle2" style={{ lineHeight: 1, fontSize: '0.875em' }} className={classes.nonOverflowText}>
                                        <PollsIcon className={cn(classes.icon, { [classes.iconShrunk]: props.shrunk })} />
                                        {` `}
                                        {props.settings.luopen ? ` • ${timeAgo(getDateFromTimestamp(props.settings.luopen))}` : ''}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                    </>
                )}
                <Box className={classes.body}>
                    <Typography align="center" style={{ fontWeight: 500, fontSize: '1em' }} className={classes.nonOverflowText}>
                        {props.title ? props.title : 'Untitled'}
                    </Typography>
                    {!hideHeader && (
                        <Typography align="center" style={{ fontSize: '1em' }} className={classes.nonOverflowText}>
                            {props.description}
                        </Typography>
                    )}
                    {!hideHeader && (
                        <Typography align="center" style={{ fontSize: '1em' }} className={classes.nonOverflowText}>
                            {props.ar ? 'Already Responded' : 'Not Yet Responded'}
                        </Typography>
                    )}
                </Box>
                <Divider />
                <Box className={classes.bottom}>
                    <Button
                        color="primary"
                        className={classes.action}
                        onClick={() => {
                            props.onClick ? props.onClick() : history.push(`/app/applications/polls/view/${user.getChapter()}/${props.id}`)
                        }}
                    >
                        View
                    </Button>
                </Box>
            </Box>
        )
    }

    if (type === 'menu') {
        return (
            <Box className={classes.root}>
                <Box className={classes.top}>
                    <Box className={classes.topTitle}>
                        {getAvatar(props.author.photo, props.author.id)}
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography style={{ fontWeight: 500, lineHeight: 1 }}>{`${props.author.first} ${props.author.last}`}</Typography>
                            <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                                <MealsIcon style={{ width: `1rem`, height: `1rem`, verticalAlign: 'text-bottom' }} />
                                {` • ${timeAgo(getDateFromTimestamp(props.cd))}`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box className={classes.body}>
                    <Typography align="center" style={{ fontWeight: 500 }}>
                        Week of {format(startOfWeek(new Date()), 'MMMM do')}
                    </Typography>
                    <img src={props.photo} className={classes.menuImage} alt="" />
                </Box>
                <Divider />
                <Box className={classes.bottom}>
                    <Button
                        color="primary"
                        className={classes.action}
                        onClick={() => {
                            history.push('/app/applications/meals/')
                        }}
                    >
                        View
                    </Button>
                </Box>
            </Box>
        )
    }

    return <></>
}
