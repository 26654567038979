import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    tableHeader: {
        padding: 18,
        paddingBottom: 0,
    },
    progress: {
        marginBottom: 10,
        marginTop: 10,
        marginRight: 12,
        backgroundColor: theme.palette.gray.main,
    },
    progressTitle: {
        marginRight: 12,
        textAlign: 'right',
    },
    progressBar: {
        backgroundColor: theme.palette.primary.main,
    },
    linkHover: {
        cursor: `pointer`,
    },
    linkParent: { display: `flex`, flexDirection: `row` },
    linkText: { display: `flex`, flexDirection: `column`, justifyContent: `center` },
    linkTypography: {
        fontWeight: 500,
        textAlign: 'center',
    },
}))
