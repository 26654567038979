import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    footerPage: {
        minHeight: 'calc(100vh - 72px)',
        alignContent: 'flex-start',
        position: 'relative',
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
}))
