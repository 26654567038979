import app from 'firebase/app'
import React from 'react'

import { Box, Button, Collapse, Container, Divider, IconButton, Slide } from '@material-ui/core'
import { CheckCircleOutlined as CheckIcon, Close as CloseIcon, Create as EditIcon, Person as AuthorIcon, PollOutlined as PollsIcon } from '@material-ui/icons'

import { AnonymousIcon, NotAnonymousIcon } from 'components/Icons'

import _ from 'lodash'

// components
import Question from 'components/Question'
import ErrorTypography from 'components/Typography/ErrorTypography'

import Tabs from 'components/Tabs'
import { Typography } from 'components/Wrappers'
import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import { Question as Qs } from 'objects/AnnualReport/Question'

import moment from 'moment'

// styles
import useStyles from '../styles'

import { useTheme } from '@material-ui/styles'

import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar'

import RefreshResults from './RefreshResults'

export default function ViewPoll(props) {
    const theme = useTheme()
    var classes = useStyles()

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const db = app.firestore()
    const [pollID] = React.useState(props.match.params.pollID)
    const [poll, setPoll] = React.useState(null)

    const chapterID = props.match.params.chapterID

    const questions = poll ? poll.questions : []

    const isAdmin = isUserPollAdmin()

    const [loadingStatus, setLoadingStatus] = React.useState(-2)

    const [accessResult, setAccessResult] = React.useState({ value: false, reason: 'TRYING' })

    const [qValues, setQValues] = React.useState({})
    const [qOtherValues, setQOtherValues] = React.useState({})
    const [viewSubmission, setViewSubmission] = React.useState({ error: null, loading: false })

    const [viewingResults, setViewingResults] = React.useState({
        viewing: false,
        responses: [],
        hasGrabbed: false,
        hasTriedGrabbing: false,
        loading: false,
        grabbedTime: null,
    })

    if (loadingStatus === -2) {
        getPoll()
        setLoadingStatus(-1)
    }

    function getPollResponses(tryingAgain) {
        if (!viewingResults.hasTriedGrabbing || !!tryingAgain) {
            setViewingResults({ ...viewingResults, hasTriedGrabbing: true, viewing: true, loading: true })

            let submission = {
                chapter: chapterID,
                pollID: pollID,
            }

            let functionsCall = app.functions().httpsCallable('getPollResponses')
            functionsCall(submission)
                .then(function(result) {
                    setViewingResults({
                        ...viewingResults,
                        hasTriedGrabbing: true,
                        viewing: true,
                        hasGrabbed: true,
                        responses: result.data,
                        loading: false,
                        grabbedTime: new Date().getTime(),
                    })
                })
                .catch(function(error) {
                    // Getting the Error details.
                    var code = error.code
                    var message = error.message
                    var details = error.details
                    console.log('AN ERROR OCCURRED', code, message, details)
                    setViewingResults({ ...viewingResults, hasGrabbed: false, error: message, loading: false })
                })
        } else {
            setViewingResults({ ...viewingResults, viewing: true })
        }
    }

    React.useEffect(() => {
        if (!(theme.palette.type === 'dark')) {
            StatusBar.setStyle({
                style: StatusBarStyle.Light,
            })
        }
        return () => {
            StatusBar.setStyle({
                style: StatusBarStyle.Dark,
            })
        }
    }, [theme.palette.type])

    function getPoll() {
        let pollsDoc = db
            .collection('chapters')
            .doc(chapterID)
            .collection('polls')
            .doc(pollID)

        pollsDoc
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    let data = doc.data()

                    let questions = data.questions

                    for (let i = 0; i < questions.length; i++) {
                        let question = questions[i]

                        if (question.type === Qs.RANKED) {
                            const options = _.shuffle(question.choices.map(choice => choice.id))

                            setQValues(values => {
                                let val = { ...values }

                                val[question.id] = options

                                return val
                            })
                        }
                    }

                    setPoll(data)

                    if (user !== undefined && user !== null) {
                        let mem = chapter.members[user.getId()]
                        mem.id = user.getId()

                        let result = canMemberCompletePoll(mem, data)
                        setAccessResult(result)

                        if (result.result) {
                            setLoadingStatus(0)
                        }
                    } else {
                        setLoadingStatus(0)
                        setAccessResult({ value: true, reason: '' })
                    }
                } else {
                    setAccessResult({ result: false, reason: 'NOT_FOUND' })
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
                setAccessResult({ result: false, reason: 'PERMISSION_DENIED' })
            })
    }

    const [tab, _setTab] = React.useState('respond')

    function renderQuestion(question, index, showResponses) {
        if (showResponses) {
            let responses = viewingResults.responses
            let values = []

            for (let i = 0; i < responses.length; i++) {
                let response = responses[i]
                if (tab === 'results' || (tab === 'responses' && response.author && response.author.id === user.getId())) {
                    if (question.id in response) {
                        values.push(response[question.id])
                    }
                }
            }

            return <Question question={question} viewMode="results" value={values} hideTitle={poll && poll.vis && poll.vis.messages} disableCard />
        }

        return (
            <Question
                question={question}
                onChange={(type, val) => {
                    if (type === 'other') {
                        setQOtherValues({ ...qOtherValues, [question.id]: val })
                    } else {
                        setQValues({ ...qValues, [question.id]: val })
                    }
                }}
                viewMode={'view'}
                value={qValues[question.id]}
                hideTitle={poll && poll.vis && poll.vis.messages}
                disableCard
                isDisabled={loadingStatus === 1 || viewSubmission.loading}
            />
        )
    }

    const submitPoll = () => {
        if (hasCompletedPoll()) {
            let finalizedResponse = {}

            for (let i = 0; i < poll.questions.length; i++) {
                let question = poll.questions[i]
                let response = qValues[question.id]

                if (question.type === Qs.CHECKBOX || question.type === Qs.RANKED) {
                    if (!Array.isArray(response)) {
                        response = []
                    }
                } else {
                    if (!response) {
                        response = ''
                    }
                }

                if (question.type === Qs.MC) {
                    if (response !== 'OTHER') {
                        for (let j = 0; j < question.choices.length; j++) {
                            let choice = question.choices[j]
                            if (choice.id === response) {
                                response = choice.text
                                j = question.choices.length
                            }
                        }
                    } else {
                        // Handle other option
                        response = qOtherValues[question.id]
                    }
                } else if (question.type === Qs.CHECKBOX) {
                    let newResponse = []

                    for (let j = 0; j < response.length; j++) {
                        let r = response[j]
                        if (r !== 'OTHER') {
                            for (let k = 0; k < question.choices.length; k++) {
                                let choice = question.choices[k]
                                if (choice.id === r) {
                                    r = choice.text
                                    k = question.choices.length
                                }
                            }
                        } else {
                            // Handle other option
                            r = qOtherValues[question.id]
                        }
                        newResponse.push(r)
                    }

                    response = newResponse
                } else if (question.type === Qs.RANKED) {
                    let newResponse = []

                    for (let j = 0; j < response.length; j++) {
                        let r = response[j]

                        for (let k = 0; k < question.choices.length; k++) {
                            let choice = question.choices[k]
                            if (choice.id === r) {
                                r = choice.text
                                k = question.choices.length
                            }
                        }

                        newResponse.push(r)
                    }

                    response = newResponse
                } else if (question.type === Qs.NUMBER) {
                    // Do nothing?
                    // Maybe talk about invalid answers
                } else if (question.type === Qs.TEXT || question.type === Qs.PARAGRAPH) {
                    // Do nothing?
                } else if (question.type === Qs.DATE) {
                    if (response) {
                        response = moment(response).format('MM-DD-YYYY')
                    }
                }
                finalizedResponse[question.id] = response
            }

            let submission = {
                chapter: chapterID,
                pollID: pollID,
                response: finalizedResponse,
            }

            let functionsCall = app.functions().httpsCallable('submitPoll')
            functionsCall(submission)
                .then(function(result) {
                    setViewSubmission({ ...viewSubmission, loading: false })
                    setLoadingStatus(1)
                })
                .catch(function(error) {
                    // Getting the Error details.
                    var code = error.code
                    var message = error.message
                    var details = error.details
                    console.log('AN ERROR OCCURRED', code, message, details)
                    setViewSubmission({ ...viewSubmission, error: message, loading: false })
                })

            setViewSubmission({ ...viewSubmission, error: null, loading: true })
        } else {
            // TODO: Set error
            setViewSubmission({ ...viewSubmission, error: 'Form not completed', loading: false })
        }
    }

    function canMemberCompletePoll(member, pollData) {
        if (!pollData || !member) {
            return { value: false, reason: 'BAD_CONNECTION' }
        }

        let id = member.id

        let vis = pollData.vis

        if (!pollData.settings.open) {
            return { value: false, reason: 'NOT_OPEN' }
        }

        if (!vis.groups.includes(member.status) || vis.roles.includes(member.role)) {
            return { value: false, reason: 'PERMISSION_DENIED' }
        }

        if (!pollData.settings.multiple && pollData.submitted.includes(id)) {
            return { value: false, reason: 'ALREADY_SUBMITTED' }
        }

        return { value: true, reason: '' }
    }

    function isUserPollAdmin() {
        if (chapter && chapter.members && user && chapter.perms) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    (chapter.perms['pollAdmin'] && chapter.perms['pollAdmin'].includes(mem.role)) ||
                    (chapter.perms['pollCreate'] && chapter.perms['pollCreate'].includes(mem.role) && poll && poll.author && poll.author.id === user.getId())
                )
            }
        }

        return false
    }

    function hasCompletedPoll() {
        if (!poll) {
            return false
        }

        let finished = true
        //qValues
        for (let i = 0; i < poll.questions.length; i++) {
            let question = poll.questions[i]
            if (question.required && question.type !== Qs.TITLE) {
                if (!qValues[question.id]) {
                    finished = false
                }
            }
        }
        return finished
    }

    const showViewResponses = poll && poll.settings && poll.settings.viewing && !poll.settings.anonymous

    function renderAccessResult() {
        let result = accessResult

        if (result.reason === 'TRYING') {
            // Poll is loading
            return null
        }

        let title = 'Ruh Roh'
        let subtitle = 'Unknown issue, please retry later'

        if (result.reason === 'ALREADY_SUBMITTED') {
            title = 'Already Responded'
            subtitle = 'You can only complete this poll once'
        }

        if (result.reason === 'PERMISSION_DENIED') {
            title = 'Permission Denied'
            subtitle = 'You do not have permission to respond to this poll'
        }

        if (result.reason === 'NOT_OPEN') {
            title = 'Not Open'
            subtitle = 'This poll is not currently open for responses'
        }

        if (result.reason === 'BAD_CONNECTION') {
            title = 'Unable to access'
            subtitle = 'Unable to access, please check your internet connection'
        }

        if (result.reason === 'NOT_FOUND') {
            title = 'Not found'
            subtitle = 'Please try again or double check that this poll exists'
        }

        if (title === 'Ruh Roh') {
            return null
        }

        return (
            <>
                <Typography align="center" style={{ fontWeight: 'bold', fontSize: '1.5em' }}>
                    {title}
                </Typography>
                <Typography align="center">{subtitle}</Typography>
            </>
        )
    }

    const onClose = () => {
        if (props.history.length === 0) {
            props.history.replace('/app/dashboard/')
        } else {
            props.history.goBack()
        }
    }

    const result = renderAccessResult()

    const setTab = val => {
        _setTab(val)

        if (val === 'responses' || val === 'results') {
            getPollResponses()
        }
    }

    const getTabs = () => {
        let tabs = [{ id: 'respond', name: 'Respond' }]

        if (showViewResponses) {
            tabs.push({ id: 'responses', name: 'Your Responses' })
        }

        if (poll.settings.results) {
            tabs.push({ id: 'results', name: 'Results' })
        }

        return tabs
    }

    return (
        <>
            <Box
                style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Box style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', paddingTop: `env(safe-area-inset-top)` }}>
                    <Box className={classes.viewsBox}>
                        <Container maxWidth="md" className={classes.scrollableContainer}>
                            <Box className={classes.scrollableBox} style={{ fontSize: '1rem' }}>
                                <Box className={classes.enabledFeatures}>
                                    <Box style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                                        <Box style={{ fontSize: '2em', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                            <PollsIcon
                                                style={{ fontSize: '1.15em', lineHeight: 1, width: '1em', height: '1em', verticalAlign: 'text-bottom' }}
                                            />
                                        </Box>
                                        <Typography align="center" style={{ fontWeight: 500, fontSize: '1em' }} className={classes.nonOverflowText}>
                                            {poll ? (poll.title ? poll.title : 'Untitled') : ''}
                                        </Typography>
                                        <Typography align="center" style={{ fontSize: '0.875em' }} className={classes.nonOverflowText}>
                                            {poll ? poll.description : ''}
                                        </Typography>
                                        {poll && poll.settings && (
                                            <>
                                                {poll.settings.anonymous ? (
                                                    <Typography align="center" style={{ fontSize: '0.875em' }} className={classes.nonOverflowText}>
                                                        <AnonymousIcon style={{ verticalAlign: 'middle' }} /> Anonymous
                                                    </Typography>
                                                ) : (
                                                    <Typography align="center" style={{ fontSize: '0.875em' }} className={classes.nonOverflowText}>
                                                        <NotAnonymousIcon style={{ verticalAlign: 'middle' }} /> Not Anonymous
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                        {poll && poll.author && (
                                            <Typography align="center" style={{ fontSize: '0.875em' }} className={classes.nonOverflowText}>
                                                <AuthorIcon style={{ verticalAlign: 'middle' }} /> {`${poll.author.first} ${poll.author.last}`}
                                            </Typography>
                                        )}
                                        {poll && poll.vis && !poll.vis.messages && isAdmin && (
                                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                <Button
                                                    color="primary"
                                                    startIcon={<EditIcon />}
                                                    onClick={() => props.history.push('/app/applications/polls/edit/' + pollID)}
                                                    disabled={viewSubmission.loading}
                                                >
                                                    Edit Poll
                                                </Button>
                                            </Box>
                                        )}
                                    </Box>
                                    {poll && (
                                        <Box>
                                            <Divider style={{ marginLeft: -24, marginRight: -24 }} />
                                            <Collapse
                                                in={
                                                    poll &&
                                                    poll.settings &&
                                                    (showViewResponses || poll.settings.results) &&
                                                    (poll.submitted.includes(user.getId()) || loadingStatus === 1)
                                                }
                                            >
                                                <>
                                                    <Box style={{ marginTop: 8, height: 32 }}>
                                                        <Tabs
                                                            autoSize
                                                            onChange={val => {
                                                                setTab(val)
                                                            }}
                                                            selected={tab}
                                                            tabs={getTabs()}
                                                        />
                                                        <Divider style={{ marginLeft: -24, marginRight: -24 }} />
                                                    </Box>
                                                </>
                                            </Collapse>
                                        </Box>
                                    )}
                                    {viewSubmission.error && <ErrorTypography isCentered text={viewSubmission.error} />}
                                    {tab === 'respond' && (
                                        <>
                                            {loadingStatus === 1 ? (
                                                <Box style={{ display: 'flex', flexDirection: 'column', paddingTop: 32, alignItems: 'center' }}>
                                                    <Box style={{ fontSize: '2em', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <CheckIcon style={{ fontSize: '2em', color: theme.palette.green.main }} />
                                                    </Box>
                                                    <Typography align="center" style={{ fontWeight: 'bold', fontSize: '1.5em' }}>
                                                        Thanks
                                                    </Typography>
                                                    <Typography align="center">Your response has been recorded</Typography>
                                                </Box>
                                            ) : !!result ? (
                                                result
                                            ) : (
                                                questions.map((question, index) => (
                                                    <Box
                                                        style={{
                                                            width: '100%',
                                                            padding: [Qs.CHECKBOX, Qs.MC, Qs.RANKED].includes(question.type) ? `16px 16px 8px` : 16,
                                                            border: theme.border,
                                                            borderRadius: 4,
                                                        }}
                                                        key={index}
                                                    >
                                                        {renderQuestion(question, index, false)}
                                                    </Box>
                                                ))
                                            )}
                                        </>
                                    )}
                                    {tab === 'responses' && ( // Your Responses
                                        <>
                                            <Typography align="center">Any anonymous responses of yours will not be displayed</Typography>
                                            {viewingResults.hasGrabbed &&
                                                questions.map((question, index) => (
                                                    <Box
                                                        style={{
                                                            width: '100%',
                                                            padding: 16,
                                                            border: theme.border,
                                                            borderRadius: 4,
                                                        }}
                                                        key={index}
                                                    >
                                                        {renderQuestion(question, index, true)}
                                                    </Box>
                                                ))}
                                        </>
                                    )}
                                    {tab === 'results' && ( // Overall
                                        <>
                                            {viewingResults.hasGrabbed && (
                                                <RefreshResults
                                                    lastGrabbed={viewingResults.grabbedTime}
                                                    onClick={() => getPollResponses(true)}
                                                    disabled={viewingResults.loading}
                                                    minSeconds={10}
                                                />
                                            )}
                                            {viewingResults.hasGrabbed &&
                                                questions.map((question, index) => (
                                                    <Box
                                                        style={{
                                                            width: '100%',
                                                            padding: 16,
                                                            border: theme.border,
                                                            borderRadius: 4,
                                                        }}
                                                        key={index}
                                                    >
                                                        {renderQuestion(question, index, true)}
                                                    </Box>
                                                ))}
                                        </>
                                    )}
                                </Box>
                                <Slide
                                    direction="up"
                                    in={poll && tab === 'respond' && !viewSubmission.loading && !result && loadingStatus !== 1}
                                    appear={false}
                                    timeout={375}
                                >
                                    <Box style={{ display: 'flex', flexDirection: 'column', paddingBottom: 'env(safe-area-inset-bottom)' }}>
                                        <Divider style={{ marginLeft: -24, marginRight: -24 }} />
                                        <Box
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 8,
                                                justifyContent: 'flex-end',
                                                marginBottom: -8,
                                                marginTop: 8,
                                            }}
                                        >
                                            <Button color="primary" onClick={onClose} disabled={viewSubmission.loading}>
                                                Cancel
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={() => submitPoll()}
                                                disabled={
                                                    !hasCompletedPoll() ||
                                                    !(poll && tab === 'respond' && !viewSubmission.loading && !result && loadingStatus !== 1)
                                                }
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    </Box>
                                </Slide>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Box>
            {!viewSubmission.loading && <Box className={classes.closeDivFullScreen}>
                <IconButton className={classes.iconClose} onClick={onClose}>
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
            </Box>}
        </>
    )
}
