import React from 'react'

import { useTheme } from '@material-ui/styles'

// components
import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'
import { Chip, Typography } from 'components/Wrappers'

import { Rushee } from 'objects/Rushee'

import templatePerson from './templatePerson.svg'

import app from 'firebase/app'

import { Box, Card, CardActionArea, CardContent, CardMedia, Grid } from '@material-ui/core'

import { Add as AddIcon, HomeOutlined as HomeIcon, Settings as SettingsIcon } from '@material-ui/icons'

import { NoSearchResultsIcon, TumbleWeedOutlinedIcon } from 'components/Icons'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import Tabs from 'components/Tabs'

// styles
import useStyles from './styles'

export default function Rush(props) {
    var classes = useStyles()
    var theme = useTheme()

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const [newMemberName] = React.useState(chapter ? chapter.newMemberShortname : 'AM')

    const [rushState, setRushState] = React.useState({
        rush: null,
        hasTried: false,
    })

    const [sortingType, setSortingType] = React.useState(localStorage.getItem('rushSorting') ? localStorage.getItem('rushSorting') : 'name')
    const [isAdmin] = React.useState(isUserRushAdmin())

    const [rushExists, setRushExists] = React.useState(chapter.activeRush !== undefined && chapter.activeRush.length > 0)

    const [curSearch, setCurSearch] = React.useState('')

    function getActiveRush() {
        let activeRush = chapter.activeRush

        const db = app.firestore()
        db.collection('chapters')
            .doc(user.getChapter())
            .collection('rushes')
            .doc(activeRush)
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    setRushState({ rush: doc.data() })

                    localStorage.setItem('cache_numRushees', doc.data().rushees.length)
                } else {
                    setRushExists(false)
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    function isUserRushAdmin() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || chapter.perms['rushAdmin'].includes(mem.role)
            }
        }

        return false
    }

    if (rushState.hasTried === false) {
        setRushState({ hasTried: true })
        if (!rushState.isSelf && rushExists) {
            getActiveRush()
        }
    }

    function viewRushee(rushee) {
        //saveState()
        localStorage.setItem('cache_rushee_' + rushee.getId(), JSON.stringify(rushee))
        props.history.push('/app/applications/rush/rushee/' + rushee.getId())
    }

    function getRightButtons() {
        if (rushExists) {
            return [
                {
                    name: 'Add',
                    type: 'icon',
                    innerIcon: <AddIcon />,
                    onClick: () => {
                        props.history.push('/app/applications/rush/add-rushee')
                    },
                },
                {
                    name: 'Settings',
                    type: 'icon',
                    innerIcon: <SettingsIcon />,
                    onClick: () => {
                        props.history.push('/app/applications/rush/settings/')
                    },
                },
            ]
        }

        return [
            {
                name: 'Settings',
                type: 'icon',
                innerIcon: <SettingsIcon />,
                onClick: () => {
                    props.history.push('/app/applications/rush/settings/')
                },
            },
        ]
    }

    function getRenderedBidStatus(rushee) {
        var status = rushee.getBidStatus()

        if (status) {
            if (status === 'Accepted') {
                return (
                    <>
                        {rushee.newMemberNumber ? (
                            <Chip size="small" className={classes.chip} color="yellow" label={newMemberName + ' ' + rushee.newMemberNumber} />
                        ) : (
                            <Chip size="small" className={classes.chip} color="green" label="Accepted" />
                        )}
                    </>
                )
            } else if (status === 'Received') {
                return (
                    <>
                        <Chip size="small" className={classes.chip} color="red" label="Received" />
                    </>
                )
            } else if (status === 'Voted') {
                return (
                    <>
                        <Chip size="small" className={classes.chip} color="blue" label="Voted" />
                    </>
                )
            } else if (status === 'Denied') {
                return (
                    <>
                        <Chip size="small" className={classes.chip} color="gray" label="Denied" />
                    </>
                )
            } else if (status === 'Rejected') {
                return (
                    <>
                        <Chip size="small" className={classes.chip} color="bluegray" label="Rejected" />
                    </>
                )
            }
        }

        return null
    }

    function getRushees() {
        if (rushState && rushState.rush && rushState.rush.rushees) {
            let origRushees = rushState.rush.rushees
            let rushees = []

            for (let i = 0; i < origRushees.length; i++) {
                let rushee = new Rushee(origRushees[i])

                if (curSearch.length <= 2) {
                    rushees.push({ rushee: rushee, reason: '' })
                } else {
                    if (
                        rushee
                            .getFullName()
                            .toLowerCase()
                            .indexOf(curSearch) !== -1
                    ) {
                        rushees.push({ rushee: rushee, reason: '' })
                    } else {
                        let added = false
                        if (Array.isArray(origRushees[i].ea)) {
                            let ea = origRushees[i].ea

                            for (let j = 0; j < ea.length; j++) {
                                if (ea[j].toLowerCase().indexOf(curSearch) !== -1) {
                                    added = true
                                    rushees.push({ rushee: rushee, reason: `Attended "${ea[j]}"` })
                                }
                            }
                        }

                        if (!added) {
                            if (rushee.major.toLowerCase().indexOf(curSearch) !== -1) {
                                added = true
                                rushees.push({ rushee: rushee, reason: `Major: ${rushee.getMajor()}` })
                            } else if (
                                rushee
                                    .getHometown()
                                    .toLowerCase()
                                    .indexOf(curSearch) !== -1
                            ) {
                                added = true
                                rushees.push({ rushee: rushee, reason: `Hometown: ${rushee.getHometown()}` })
                            }
                        }
                    }
                }
            }

            if (sortingType === 'name') {
                rushees.sort(function(a, b) {
                    return a.rushee.getFullName().localeCompare(b.rushee.getFullName())
                })
            } else if (sortingType === 'met') {
                // Has met someone
                rushees = rushees.filter(a => {
                    return a.rushee.numVotes && a.rushee.numVotes > 0
                })

                rushees.sort(function(a, b) {
                    const aBid = a.rushee.getBidStatus()
                    const bBid = b.rushee.getBidStatus()

                    if (aBid === bBid) {
                        if (aBid === 'Accepted') {
                            return a.rushee.getNewMemberNumber() - b.rushee.getNewMemberNumber()
                        }

                        return a.rushee.getFullName().localeCompare(b.rushee.getFullName())
                    }

                    return a.rushee.getBidWeightedValue() - b.rushee.getBidWeightedValue()
                })
            } else {
                // Status or nothing
                rushees.sort(function(a, b) {
                    const aBid = a.rushee.getBidStatus()
                    const bBid = b.rushee.getBidStatus()

                    if (aBid === bBid) {
                        if (aBid === 'Accepted') {
                            return a.rushee.getNewMemberNumber() - b.rushee.getNewMemberNumber()
                        }

                        return a.rushee.getFullName().localeCompare(b.rushee.getFullName())
                    }

                    return a.rushee.getBidWeightedValue() - b.rushee.getBidWeightedValue()
                })
            }

            if (rushees.length === 0) {
                if (curSearch.length <= 2) {
                    return (
                        <Grid
                            item
                            xs={12}
                            style={{
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 'calc(100vh - 194px)',
                            }}
                        >
                            <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor, marginBottom: 16 }} />
                            <Typography style={{ color: theme.borderColor }}>There are no rushees</Typography>
                            <Typography style={{ color: theme.borderColor }}>Click "+" to add a rushee</Typography>
                        </Grid>
                    )
                }
                return (
                    <Grid
                        item
                        xs={12}
                        style={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 'calc(100vh - 194px)',
                        }}
                    >
                        <NoSearchResultsIcon style={{ fontSize: '7.5em', color: theme.borderColor, marginBottom: 16 }} />
                        <Typography style={{ color: theme.borderColor }}>No rushees found</Typography>
                        <Typography style={{ color: theme.borderColor }}>Try a different search</Typography>
                    </Grid>
                )
            }

            return rushees.map(c => (
                <Grid item xs={6} sm={6} md={4} lg={3} xl={2} key={c.rushee.id}>
                    <Card className={classes.card}>
                        <CardActionArea
                            onClick={() => {
                                viewRushee(c.rushee)
                            }}
                        >
                            <CardMedia
                                className={classes.media}
                                image={c.rushee.getPhoto() ? c.rushee.getPhoto() : templatePerson}
                                title={c.rushee.getFullName()}
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="h2"
                                    style={{
                                        textAlign: 'center',
                                        margin: 0,
                                    }}
                                >
                                    {c.rushee.getFullName()}
                                </Typography>
                                {c.rushee.getBidStatus() !== '' && c.rushee.getBidStatus() !== 'None' && (
                                    <Box className={classes.bidStatusChip}>{getRenderedBidStatus(c.rushee)}</Box>
                                )}
                                {(c.rushee.getBidStatus() === '' || c.rushee.getBidStatus() === 'None') && (!c.reason || c.reason.length === 0) && (
                                    <Typography
                                        variant="body2"
                                        color="text"
                                        colorBrightness={'secondary'}
                                        component="p"
                                        style={{
                                            textAlign: 'center',
                                            margin: 2,
                                        }}
                                    >
                                        {c.rushee.getMajor()}
                                    </Typography>
                                )}
                                {c.reason && c.reason.length > 0 && (
                                    <Typography
                                        variant="body2"
                                        color="text"
                                        colorBrightness={'secondary'}
                                        component="p"
                                        style={{
                                            textAlign: 'center',
                                            margin: 2,
                                        }}
                                    >
                                        {c.reason}
                                    </Typography>
                                )}
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))
        }

        return <></>
    }

    const renderNavBar = showGrid => {
        if (isAdmin && (rushState.rush || !rushExists)) {
            return (
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Rush',
                        },
                    ]}
                    grid={showGrid}
                    rightButtons={getRightButtons()}
                    key={rushExists ? 'exists' : 'not active'}
                    onSearch={rushExists ? text => setCurSearch(text ? text.toLowerCase() : '') : undefined}
                />
            )
        }

        return (
            <NavigationBar
                titles={[
                    {
                        name: 'My House',
                        link: '/app/dashboard/',
                        icon: <HomeIcon />,
                    },
                    {
                        name: 'Rush',
                    },
                ]}
                grid={showGrid}
            />
        )
    }

    if (!rushExists) {
        return (
            <>
                <Box>{renderNavBar(false)}</Box>
                <Box style={{ padding: 8, width: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 16,
                            flexGrow: 1,
                        }}
                    >
                        <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor }} />
                        <Typography style={{ color: theme.borderColor }}>There is no active rush</Typography>
                        <Typography style={{ color: theme.borderColor }}>Go to settings to make a new rush</Typography>
                    </Box>
                </Box>
            </>
        )
    }

    return (
        <>
            <Grid container spacing={2}>
                {renderNavBar(true)}
                {rushExists && (
                    <Grid item xs={12}>
                        <Box
                            style={{
                                maxWidth: 480,
                                margin: `0 auto`,
                            }}
                        >
                            <Widget disableWidgetMenu inheritHeight bodyClass={classes.tabSection}>
                                <Tabs
                                    tabs={[
                                        { id: 'name', name: 'Alphabetical', width: 100 },
                                        { id: 'status', name: 'Status', width: 60 },
                                        { id: 'met', name: 'Met Anyone', width: 95 },
                                    ]}
                                    selected={sortingType}
                                    onChange={val => {
                                        localStorage.setItem('rushSorting', val)
                                        setSortingType(val)
                                    }}
                                />
                            </Widget>
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box display={'flex'} flexWrap={'wrap'}>
                        <Grid container item spacing={2}>
                            {getRushees()}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
