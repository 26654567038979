import React from 'react'
import { Box, ButtonBase, Divider, Typography } from '@material-ui/core'
import { Launch as ExternalLinkIcon } from '@material-ui/icons'

import useWidgetStyles from 'components/GCWidget/styles'
import useMessagesStyles from 'pages/messages/styles'

const MessageLink = ({ data, url, key }) => {
    const messagesClasses = useMessagesStyles()
    const widgetClasses = useWidgetStyles()

    const [domain] = React.useState(new URL(url).hostname.replace('www.', ''))

    return (
        <Box className={messagesClasses.elementGCBox} key={key} style={{ display: 'flex' }}>
            <ButtonBase
                style={{ flexGrow: 1, fontSize: 'inherit', borderRadius: 2, maxWidth: '100%' }}
                component="a"
                href={url}
                target="_blank"
                rel="noreferrer"
            >
                <Box className={widgetClasses.root} style={{ flexGrow: 1, maxWidth: '100%' }}>
                    {data.ogImage && (
                        <>
                            <Box className={widgetClasses.body}>
                                <img className={widgetClasses.bodyImage} src={data.ogImage} alt="" />
                            </Box>
                            <Divider />
                        </>
                    )}
                    <Box className={widgetClasses.bottom}>
                        <Box style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, maxWidth: '100%', padding: `8px 12px` }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    maxWidth: 'calc(100% - 1.5em - 8px)',
                                }}
                            >
                                {data.ogTitle && (
                                    <Typography className={widgetClasses.nonOverflowText} style={{ fontSize: '1em' }}>
                                        {data.ogTitle}
                                    </Typography>
                                )}
                                <Typography className={widgetClasses.nonOverflowText} variant="caption" style={{ fontSize: '0.75em' }}>
                                    {domain}
                                </Typography>
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: 8 }}>
                                <ExternalLinkIcon style={{ fontSize: '1.5em' }} />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ButtonBase>
        </Box>
    )
}

export default React.memo(MessageLink)
