import React from 'react'

import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Collapse from '@material-ui/core/Collapse'
import Grow from '@material-ui/core/Grow'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Widget from 'components/Widget'

// styles
import { useTheme } from '@material-ui/styles'

const Card = ({ animate, onClick, image, href, style, icon, title, buttonProps, secondaryIcons, variant }) => {
    var theme = useTheme()

    const getButtonProps = () => {
        if (buttonProps) {
            return buttonProps
        }

        if (onClick) {
            return {
                onClick,
            }
        }

        if (href) {
            return {
                component: 'a',
                href: href,
            }
        }
    }

    const renderItem = size => (
        <Stack sx={{ width: '100%', height: '100%' }} direction="column" spacing={0}>
            <ButtonBase
                sx={{
                    borderRadiusTopLeft: 3,
                    borderRadiusTopRight: 3,
                    borderBottom: theme.border,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexGrow: 1,
                    overflow: 'hidden',
                }}
                {...getButtonProps()}
            >
                <Box sx={{ width: '100%', overflow: 'hidden', height: '100%' }}>
                    {image ? (
                        <img
                            alt=""
                            src={image}
                            style={{ objectFit: 'cover', width: '100%', height: '100%', borderTopLeftRadius: 3, borderTopRightRadius: 3 }}
                        />
                    ) : (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                style={{
                                    width: 96,
                                    height: 96,
                                    backgroundColor: `${theme.palette.primary.main}`,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {React.createElement(icon, { style: { color: 'white', fontSize: '3em' } })}
                            </Box>
                        </Box>
                    )}
                </Box>
            </ButtonBase>
            <Stack sx={{ width: '100%', pr: 0.5 }} direction="row" spacing="4px">
                <Box sx={{ display: 'flex', flexDirection: 'row', padding: '12px', gap: '12px', flexGrow: 1, overflow: 'hidden' }}>
                    {React.createElement(icon)}
                    <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{title}</Typography>
                </Box>
                {Array.isArray(secondaryIcons) &&
                    secondaryIcons.map(({ id, icon, ...other }) => (
                        <Stack key={id} justifyContent="center">
                            <IconButton size="small" {...other}>
                                {icon}
                            </IconButton>
                        </Stack>
                    ))}
            </Stack>
        </Stack>
    )

    const renderInner = () => {
        if (variant === 'tiny') {
            return (
                <Box display="flex" sx={style}>
                    <Box sx={{ border: theme.border, borderRadius: '4px', width: '100%' }}>
                        <ButtonBase sx={{ borderRadius: '3px', width: '100%' }}>
                            <Stack sx={{ width: '100%', pr: 0.5 }} direction="row" spacing="4px">
                                <Box sx={{ display: 'flex', flexDirection: 'row', padding: '12px', gap: '12px', flexGrow: 1, overflow: 'hidden' }}>
                                    {React.createElement(icon)}
                                    <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{title}</Typography>
                                </Box>

                                {Array.isArray(secondaryIcons) &&
                                    secondaryIcons.map(({ id, icon, ...other }) => (
                                        <Stack key={id} justifyContent="center">
                                            <IconButton size="small" {...other}>
                                                {icon}
                                            </IconButton>
                                        </Stack>
                                    ))}
                            </Stack>
                        </ButtonBase>
                    </Box>
                </Box>
            )
        }

        if (variant === 'outlined') {
            return (
                <Box display="flex" sx={style}>
                    <Box sx={{ border: theme.border, borderRadius: '4px', width: '100%' }}>{renderItem()}</Box>
                </Box>
            )
        }

        return (
            <Widget disableWidgetMenu inheritHeight noBodyPadding>
                <Box sx={style}>{renderItem()}</Box>
            </Widget>
        )
    }

    if (!!animate) {
        return (
            <Collapse appear in key="testing">
                <Grow appear in key={'testing2'}>
                    {renderInner()}
                </Grow>
            </Collapse>
        )
    }

    return renderInner()
}

export default Card
