import React from 'react'
import app from 'firebase/app'

import { Grid, Typography } from '@material-ui/core'
import { CreateOutlined as EditIcon } from '@material-ui/icons'

import NavigationBar from '../../../../components/NavigationBar'
import TinyCard from '../../../../components/Cards/TinyCard'

import Widget from 'components/Widget'

export default function ViewOrganization(props) {
    const orgId = props.match.params.id

    const [org, setOrg] = React.useState(null)
    const [hasTried, setHasTried] = React.useState(false)

    if (!hasTried) {
        setHasTried(true)
        grabOrg()
    }

    function grabOrg() {
        const db = app.firestore()
        db.collection('fraternities')
            .doc(orgId)
            .get()
            .then(function(doc) {
                setOrg(doc.data())
            })
            .catch(function(error) {
                console.log('Error getting document: ', error)
            })
    }

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'Organizations',
                            link: '/app/gc/organizations',
                        },
                        {
                            name: 'View',
                        },
                    ]}
                    rightButtons={[
                        {
                            name: 'Edit',
                            tooltip: 'Edit',
                            type: 'icon',
                            innerIcon: <EditIcon />,
                            onClick: () => {
                                props.history.push('/app/gc/organizations/' + orgId + '/edit')
                            },
                        },
                    ]}
                    grid
                />
                <Grid item xs={12}>
                    <Widget disableWidgetMenu inheritHeight>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 700,
                                        margin: '0 auto',
                                    }}
                                    align="center"
                                >
                                    {org ? `${org.fullName} (${org.letters})` : 'Loading...'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center">
                                    {org ? `${org.chapters.length} ${org.chapters.length === 1 ? 'chapter' : 'chapters'}` : 'Loading...'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Widget>
                </Grid>
                <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                    <TinyCard onClick={() => props.history.push('/app/gc/organizations/' + orgId + '/mm/')} text="Member Management" />
                </Grid>
            </Grid>
        </>
    )
}
