import 'firebase/functions'

import DateFnsUtils from '@date-io/date-fns'
import { Box, CircularProgress, FormHelperText, Grid, TextField } from '@material-ui/core'
import { HomeOutlined as HomeIcon, PersonOutline as ProfileIcon } from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import app from 'firebase/app'
import MuiPhoneNumber from 'material-ui-phone-number'
import React from 'react'
import Resizer from 'react-image-file-resizer'

import InputAdornment from '@mui/material/InputAdornment'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { useTheme } from '@material-ui/styles'
import DocumentUpload from '../../../components/DocumentUpload'
import NavigationBar from '../../../components/NavigationBar'
import Widget from '../../../components/Widget'
import { Button } from '../../../components/Wrappers'
import { AuthContext } from '../../../context/AuthContext'
import useStyles from '../styles'
import { isMobileDevice } from 'code/Helper'

export default function EditProfile(props) {
    const classes = useStyles()
    const theme = useTheme()

    const atLeastSmall = useMediaQuery(theme.breakpoints.up('sm'))

    const { currentUser, requestUserUpdate } = React.useContext(AuthContext)

    let chapterID = currentUser && currentUser.chapter ? currentUser.chapter : ''

    const profile = {
        chapter: chapterID,
        memberID: props.match.params.id,
    }

    var isSelf = false
    var viewingUser = null

    if (currentUser && currentUser.uid && props.match.params.id === currentUser.uid) {
        isSelf = true
        viewingUser = currentUser
    }

    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [major, setMajor] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [instagram, setInstagram] = React.useState('')
    const [twitter, setTwitter] = React.useState('')
    const [linkedIn, setLinkedIn] = React.useState('')
    const [linkedInError, setLinkedInError] = React.useState(false)
    const [hometown, setHometown] = React.useState('')
    const [_class, setClass] = React.useState(null)
    const [photoURL, setPhotoURL] = React.useState('')

    const [error, setError] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)

    const [state, setState] = React.useState({
        member: viewingUser,
        isSelf: isSelf,
        hasTried: false,
    })

    function getProfile() {
        const db = app.firestore()
        db.collection('users')
            .doc(profile.memberID)
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    setState({ isSelf: state.isSelf, hasTried: true, member: doc.data() })
                    setFields(doc.data())
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    function createUUID() {
        // http://www.ietf.org/rfc/rfc4122.txt
        var s = []
        var hexDigits = '0123456789abcdef'
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
        }
        s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-'

        var uuid = s.join('')
        return uuid
    }

    const [uploadProgress, setUploadProgress] = React.useState(null)

    const [birthday, setBirthday] = React.useState(new Date())

    const handleBirthdayChange = date => {
        setBirthday(date)
    }

    function uploadURI(uri) {
        setUploadProgress(0)
        let imageName = `userPhotos/` + chapterID + `/` + createUUID() + `.jpg`
        const uploadTask = app
            .storage()
            .ref(imageName)
            .put(uri, { cacheControl: 'private, max-age=15552000' })
        uploadTask.on(
            'state_changed',
            snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                setUploadProgress(progress)
            },
            error => {
                setUploadProgress(null)
                setError(error.message)
            },
            () => {
                // complete function ...
                app.storage()
                    .ref(imageName)
                    .getDownloadURL()
                    .then(function(url) {
                        setUploadProgress(100)
                        setPhotoURL(url)
                    })
                    .catch(function(error) {
                        // Handle any errors
                        setError(error.message)
                    })
            },
        )
    }

    function uploadImage(image) {
        Resizer.imageFileResizer(
            image, //is the file of the new image that can now be uploaded...
            512, // is the maxWidth of the  new image
            512, // is the maxHeight of the  new image
            'JPEG', // is the compressFormat of the  new image
            80, // is the quality of the  new image
            0, // is the rotatoion of the  new image
            uri => {
                uploadURI(uri)
            },
            'blob', // is the output type of the new image
        )
    }

    if (state.hasTried === false && currentUser && currentUser.uid) {
        setState({ isSelf: state.isSelf, member: state.member, hasTried: true })
        if (state.member && state.member.first) {
            setFields(state.member)
        }
        if (!state.isSelf) {
            getProfile()
        }
    }

    function setFields(member) {
        setFirstName(member.first)
        setLastName(member.last)
        setMajor(member.major ? member.major : '')
        setHometown(member.hometown ? member.hometown : '')
        setPhone(member.phone ? member.phone : '')
        setClass(member.class ? member.class : null)
        setInstagram(member.instagram ? member.instagram : '')
        setTwitter(member.twitter ? member.twitter : '')
        setLinkedIn(member.linkedIn ? member.linkedIn : '')
        if (member.birthday) {
            let bdayStr = member.birthday
            let bday = getBirthdayStr(bdayStr)
            setBirthday(bday)
        } else {
            setBirthday(new Date())
        }
    }

    function getBirthdayStr(birthday) {
        if (state.isSelf || birthday.seconds) {
            let seconds = birthday.seconds
            let date = new Date(0)
            date.setUTCSeconds(seconds)
            return date
        }

        let date = new Date(0)
        date.setUTCSeconds(birthday._seconds)
        return date
    }

    function handleSubmit() {
        if (firstName.length === 0) {
            setError('First name must be filled in')
            return
        }

        if (lastName.length === 0) {
            setError('Last name must be filled in')
            return
        }

        if (linkedIn.length > 0 && !validURL(linkedIn)) {
            return
        }

        let submission = {
            chapter: chapterID,
            memberID: profile.memberID,
            first: firstName,
            last: lastName,
            hometown: hometown,
            major: major,
            class: _class,
            phone: phone,
            instagram: instagram,
            twitter: twitter,
            linkedIn: linkedIn,
            photoURL: photoURL,
            birthday: birthday ? birthday.toISOString() : null,
        }

        var functionsCall = app.functions().httpsCallable('editProfile')
        functionsCall(submission)
            .then(function(result) {
                setIsLoading(false)
                if (state.isSelf) {
                    requestUserUpdate()
                }
                if (props.history.length === 0) {
                    props.history.replace('/app/profile/' + profile.memberID)
                } else {
                    props.history.goBack()
                }
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
                setIsLoading(false)
                setError(message)
            })

        setIsLoading(true)
    }

    function validURL(str) {
        return str.indexOf('http://') > -1 || str.indexOf('https://') > -1
    }

    const getClass = () => {
        if (_class) {
            let d = new Date()
            d.setFullYear(_class)
            return d
        }

        return null
    }

    const renderEditProfile = () => (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        label="First"
                        type="text"
                        inputProps={{
                            maxLength: 16,
                        }}
                        onChange={e => {
                            setFirstName(e.target.value)
                        }}
                        value={firstName}
                        required
                        fullWidth
                    />
                    <FormHelperText error>{firstName.length === 0 && "The first name field can't be blank"}</FormHelperText>
                </>
            </Grid>
            <Grid item xs={12} sm={6}>
                <>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        label="Last"
                        type="text"
                        inputProps={{
                            maxLength: 16,
                        }}
                        onChange={e => {
                            setLastName(e.target.value)
                        }}
                        value={lastName}
                        required
                        fullWidth
                        endAdornment={<InputAdornment position="start">kg</InputAdornment>}
                    />
                    <FormHelperText error>{lastName.length === 0 && "The last name field can't be blank"}</FormHelperText>
                </>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    margin="dense"
                    label="Major"
                    type="text"
                    required
                    inputProps={{
                        maxLength: 16,
                    }}
                    onChange={e => {
                        setMajor(e.target.value)
                    }}
                    value={major}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    margin="dense"
                    label="Hometown"
                    type="text"
                    inputProps={{
                        maxLength: 32,
                    }}
                    onChange={e => {
                        setHometown(e.target.value)
                    }}
                    value={hometown}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        openTo="year"
                        views={['year']}
                        inputVariant="outlined"
                        disableToolbar
                        id="class"
                        label="Class"
                        value={getClass()}
                        placeholder="2026"
                        fullWidth
                        margin="dense"
                        onChange={e => {
                            setClass(e && e instanceof Date ? e.getFullYear() : null)
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change year',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
                <MuiPhoneNumber
                    variant="outlined"
                    defaultCountry={'us'}
                    margin="dense"
                    label="Phone"
                    type="text"
                    inputProps={{
                        maxLength: 32,
                    }}
                    onChange={e => {
                        setPhone(e)
                    }}
                    value={phone}
                    fullWidth
                    className={classes.phoneNumberField}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    margin="dense"
                    label="Instagram Handle"
                    type="text"
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={e => {
                        setInstagram(e.target.value)
                    }}
                    value={instagram}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    variant="outlined"
                    margin="dense"
                    label="Twitter Username"
                    type="text"
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={e => {
                        setTwitter(e.target.value)
                    }}
                    value={twitter}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    margin="dense"
                    label="LinkedIn URL"
                    type="url"
                    error={linkedInError}
                    placeholder="https://linkedin.com/..."
                    inputProps={{
                        maxLength: 120,
                    }}
                    helperText={linkedInError ? 'Invalid URL' : false}
                    onChange={e => {
                        setLinkedIn(e.target.value)
                        if (linkedInError) {
                            if (linkedIn.length > 0 && validURL(linkedIn)) {
                                setLinkedInError(false)
                            }
                        }
                    }}
                    onBlur={() => {
                        if (linkedIn.length > 0 && !validURL(linkedIn)) {
                            setLinkedInError(true)
                        } else {
                            setLinkedInError(false)
                        }
                    }}
                    value={linkedIn}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <KeyboardDatePicker
                    inputVariant="outlined"
                    disableToolbar
                    format="MM/dd/yyyy"
                    id="date-picker"
                    label="Birthday"
                    value={birthday}
                    fullWidth
                    margin="dense"
                    onChange={handleBirthdayChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    required
                    style={{
                        marginBottom: 0,
                        marginTop: 0,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <DocumentUpload
                    onUpdateFile={file => {
                        if (file !== null) {
                            uploadImage(file)
                        } else {
                            setUploadProgress(null)
                        }
                    }}
                    progress={uploadProgress}
                    size="small"
                    multiple={false}
                    fileType="image/png,image/jpeg"
                    fileTypeText=".png, .jpg, or .jpeg"
                    title="Update photo"
                />
            </Grid>
            <Grid item xs={12}>
                <FormHelperText error style={{ textAlign: 'center' }}>
                    {error !== null ? error : null}
                </FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            style={{
                                marginRight: 4,
                            }}
                            onClick={() => {
                                props.history.replace('/app/profile/' + profile.memberID)
                            }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {isLoading || (uploadProgress !== null && uploadProgress < 100) ? (
                            <Box
                                style={{
                                    paddingLeft: 25,
                                }}
                            >
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={() => handleSubmit()}
                                style={{
                                    marginLeft: 4,
                                }}
                                disabled={linkedInError || !major || !birthday}
                            >
                                Update
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Account',
                            link: '/app/profile/' + profile.memberID,
                            icon: atLeastSmall ? null : <ProfileIcon />,
                        },
                        {
                            name: 'Edit',
                        },
                    ]}
                    grid
                />
                {state.member ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12}>
                            {isMobileDevice() ? (
                                renderEditProfile()
                            ) : (
                                <Widget disableWidgetMenu inheritHeight>
                                    {renderEditProfile()}
                                </Widget>
                            )}
                        </Grid>
                    </MuiPickersUtilsProvider>
                ) : (
                    error && (
                        <Grid item xs={12}>
                            {isMobileDevice() ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormHelperText error style={{ textAlign: 'center' }}>
                                            {error !== null ? error : null}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Widget disableWidgetMenu inheritHeight>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormHelperText error style={{ textAlign: 'center' }}>
                                                {error !== null ? error : null}
                                            </FormHelperText>
                                        </Grid>
                                    </Grid>
                                </Widget>
                            )}
                        </Grid>
                    )
                )}
            </Grid>
        </>
    )
}
