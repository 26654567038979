import React from 'react'

import { LocationContext } from 'context/LocationContext'

import { MapContext } from '../Map'

const Items = React.forwardRef((props, marker) => {
    const { mapkit } = React.useContext(LocationContext)
    const { map } = React.useContext(MapContext)

    const isMountedRef = React.useRef(true)
    const isMounted = React.useCallback(() => isMountedRef.current, [])

    React.useEffect(() => {
        return () => (isMountedRef.current = false)
    }, [])

    React.useEffect(() => {
        if (mapkit && map && props.items) {
            map.addItems(props.items)
        }
        return () => {
            !isMounted && marker.current && map && map.removeAnnotation(marker.current)
        }
    }, [mapkit, map, props])
    return null
})

export default Items
