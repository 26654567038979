import {
    Accordion as AccordionBase,
    AccordionDetails as AccordionDetailsBase,
    AccordionSummary as AccordionSummaryBase,
    AppBar as AppBarBase,
    Avatar as AvatarBase,
    Badge as BadgeBase,
    Box,
    Button as ButtonBase,
    Chip as ChipBase,
    CircularProgress as CircularProgressBase,
    DialogActions as DialogActionsBase,
    Dialog as DialogBase,
    DialogContent as DialogContentBase,
    DialogTitle as DialogTitleBase,
    IconButton as IconButtonBase,
    LinearProgress as LinearProgressBase,
    Link as LinkBase,
    Paper as PaperBase,
    Popover as PopoverBase,
    Popper as PopperBase,
    Radio as RadioBase,
    Tooltip as TooltipBase,
    Typography as TypographyBase,
    withStyles,
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles, useTheme } from '@material-ui/styles'
import classnames from 'classnames'
import React from 'react'

import { isMobileDevice } from 'code/Helper'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Capacitor } from '@capacitor/core'

import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar'

// styles
const useStyles = makeStyles(theme => ({
    badge: {
        fontWeight: 600,
        height: props => {
            if (!props.variant) return 16
        },
        minWidth: props => {
            if (!props.variant) return 16
        },
    },
}))

function BadgeNoContent({ children, colorBrightness, color, ...props }) {
    const classes = useStyles(props)
    const theme = useTheme()
    const Styled = createStyled({
        badge: {
            backgroundColor: getColor(color, theme, colorBrightness),
            color: 'white',
            top: 4,
            right: 10,
            height: 12,
            minWidth: 12,
            width: 12,
            padding: 0,
        },
    })

    return (
        <Styled>
            {styledProps => (
                <BadgeBase
                    classes={{
                        badge: classnames(classes.badge, styledProps.classes.badge),
                    }}
                    badgeContent=" "
                    {...props}
                >
                    {children}
                </BadgeBase>
            )}
        </Styled>
    )
}

function BadgeSide({ children, colorBrightness, color, style, ...props }) {
    const classes = useStyles(props)
    const theme = useTheme()
    const Styled = createStyled({
        badge: {
            backgroundColor: getColor(color, theme, colorBrightness),
            color: 'white',
            right: 0,
            top: 4,
            border: `1.5px solid ${theme.palette.type === 'dark' ? theme.palette.background.paper : theme.palette.primary.main}`,
            padding: '0 4px',
            height: 15,
            minWidth: 15,
            ...style,
        },
    })

    return (
        <Styled>
            {styledProps => (
                <BadgeBase
                    classes={{
                        badge: classnames(classes.badge, styledProps.classes.badge),
                    }}
                    badgeContent=" "
                    {...props}
                >
                    {children}
                </BadgeBase>
            )}
        </Styled>
    )
}

function BadgeSidebar({ children, colorBrightness, color, style, ...props }) {
    const classes = useStyles(props)
    const theme = useTheme()
    const Styled = createStyled({
        badge: {
            backgroundColor: getColor(color, theme, colorBrightness),
            color: 'white',
            right: 0,
            top: 4,
            border: `1.5px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
            height: 15,
            minWidth: 15,
            ...style,
        },
    })

    return (
        <Styled>
            {styledProps => (
                <BadgeBase
                    classes={{
                        badge: classnames(classes.badge, styledProps.classes.badge),
                    }}
                    badgeContent=" "
                    {...props}
                >
                    {children}
                </BadgeBase>
            )}
        </Styled>
    )
}

function Badge({ children, colorBrightness, color, ...props }) {
    const classes = useStyles(props)
    const theme = useTheme()
    const Styled = createStyled({
        badge: {
            backgroundColor: getColor(color, theme, colorBrightness),
            color: 'white',
        },
    })

    return (
        <Styled>
            {styledProps => (
                <BadgeBase
                    classes={{
                        badge: classnames(classes.badge, styledProps.classes.badge),
                    }}
                    {...props}
                >
                    {children}
                </BadgeBase>
            )}
        </Styled>
    )
}

function Accordion({ children, ...props }) {
    const classes = useStyles(props)
    const theme = useTheme()
    const Styled = createStyled({
        root: {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.text.hint}`,
        },
    })

    return (
        <Styled>
            {styledProps => (
                <AccordionBase
                    classes={{
                        root: classnames(classes.root, styledProps.classes.root),
                    }}
                    {...props}
                >
                    {children}
                </AccordionBase>
            )}
        </Styled>
    )
}

function AccordionDetails({ children, ...props }) {
    const classes = useStyles(props)
    const Styled = createStyled({
        root: {
            padding: '8px 16px',
        },
    })

    return (
        <Styled>
            {styledProps => (
                <AccordionDetailsBase
                    classes={{
                        root: classnames(classes.root, styledProps.classes.root),
                    }}
                    {...props}
                >
                    {children}
                </AccordionDetailsBase>
            )}
        </Styled>
    )
}

function AccordionSummary({ children, ...props }) {
    const classes = useStyles(props)
    const Styled = createStyled({
        root: {
            minHeight: `38px !important`,
        },
        expandIcon: {
            padding: 3,
        },
        content: {
            margin: '6px 0 !important',
        },
    })

    return (
        <Styled>
            {styledProps => (
                <AccordionSummaryBase
                    classes={{
                        content: classnames(classes.content, styledProps.classes.content),
                        expandIcon: classnames(classes.expandIcon, styledProps.classes.expandIcon),
                        root: classnames(classes.root, styledProps.classes.root),
                    }}
                    {...props}
                >
                    {children}
                </AccordionSummaryBase>
            )}
        </Styled>
    )
}

function Chip({ colorBrightness, color, ...props }) {
    const theme = useTheme()
    const Styled = createStyled({
        root: {
            backgroundColor: color[0] === '#' ? color : getColor(color, theme, colorBrightness),
            color: 'white',
            textTransform: 'uppercase',
            fontWeight: '500',
            letterSpacing: '0.1em',
            marginTop: '0.25em',
            marginBottom: '0.25em',
            fontSize: '1em',
            height: '2em',
        },
        label: {
            fontSize: '0.8125em',
            paddingLeft: `${(1 / 0.8125) * 0.75}em`,
            paddingRight: `${(1 / 0.8125) * 0.75}em`,
        },
    })

    return (
        <Styled>
            {styledProps => (
                <ChipBase
                    classes={{
                        root: classnames(styledProps.classes.root),
                        label: classnames(styledProps.classes.label),
                    }}
                    {...props}
                />
            )}
        </Styled>
    )
}

function IconChip({ colorBrightness, color, size, ...props }) {
    const Styled = createStyled({
        icon: {
            marginLeft: `18px !important`,
        },
        iconSmall: {
            marginLeft: `12px !important`,
        },
        root: {
            width: size === 'small' ? 24 : 32,
        },
    })

    return (
        <Styled>
            {styledProps => (
                <ChipBase
                    classes={{
                        root: classnames(styledProps.classes.root),
                        iconSmall: classnames(styledProps.classes.iconSmall),
                        icon: classnames(styledProps.classes.icon),
                    }}
                    size={size}
                    {...props}
                />
            )}
        </Styled>
    )
}

function Typography({ children, weight, size, colorBrightness, color, block, uppercase, style, ...props }) {
    const theme = useTheme()

    return (
        <TypographyBase
            style={{
                color: getColor(color, theme, colorBrightness),
                fontWeight: getFontWeight(weight),
                fontSize: getFontSize(size, props.variant, theme),
                textTransform: uppercase ? 'uppercase' : 'none',
                ...style,
            }}
            component={block ? 'div' : 'p'}
            {...props}
        >
            {children}
        </TypographyBase>
    )
}

function IconButton({ children, color, ...props }) {
    const useStyles = makeStyles(theme => ({
        root: {
            color: getColor(color, theme),
        },
    }))
    const classes = useStyles()

    let cs = {
        root: classes.root,
    }

    return (
        <IconButtonBase classes={cs} {...props}>
            {children}
        </IconButtonBase>
    )
}

function Button({ children, color, className, style, loading, ...props }) {
    const useStyles = makeStyles(theme => ({
        root: {
            color: getColor(color, theme),
        },
        contained: {
            backgroundColor: getColor(color, theme),
            boxShadow: props.disableElevation ? null : theme.customShadows.widget,
            color: theme.palette.type === 'dark' && !color ? '#000' : `${color ? 'white' : theme.palette.text.primary} !important`,
            '&:hover': {
                backgroundColor: getColor(color, theme, 'light'),
                boxShadow: props.disableElevation ? null : theme.customShadows.widgetWide,
            },
            '&:active': {
                boxShadow: props.disableElevation ? null : theme.customShadows.widgetWide,
            },
        },
        loadingTextColor: {
            color: `rgba(0,0,0,0)`,
        },
        outlined: {
            color: getColor(color, theme),
            borderColor: getColor(color, theme),
        },
        select: {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
        progress: {
            color: theme.palette.type === 'dark' && !color ? '#000' : `${color ? 'white' : theme.palette.text.primary} !important`,
            position: 'absolute',
        },
    }))
    const classes = useStyles()

    let cs = {
        contained: classes.contained,
        root: classes.root,
        outlined: classes.outlined,
    }

    if (loading) {
        cs.label = classes.loadingTextColor
    }

    return (
        <ButtonBase
            classes={cs}
            {...props}
            className={classnames(
                {
                    [classes.select]: props.select,
                },
                className,
            )}
            style={{ ...style }}
        >
            {loading && <CircularProgress size={20} className={classes.progress} />}
            {children}
        </ButtonBase>
    )
}

function Avatar({ children, color, colorBrightness, ...props }) {
    const theme = useTheme()

    const Styled = createStyled({
        colorDefault: {
            backgroundColor: getColor(color, theme, colorBrightness),
        },
    })

    return (
        <Styled>
            {({ classes }) => (
                <AvatarBase classes={{ colorDefault: classes.colorDefault }} {...props}>
                    {children}
                </AvatarBase>
            )}
        </Styled>
    )
}

function Tooltip({ children, color, ...props }) {
    const theme = useTheme()

    const Styled = createStyled({
        tooltip: {
            backgroundColor: getColor(color, theme),
            color: 'white',
        },
    })

    return (
        <Styled>
            {({ classes }) => (
                <TooltipBase classes={{ tooltip: classes.tooltip }} {...props}>
                    {children}
                </TooltipBase>
            )}
        </Styled>
    )
}

function Paper({ children, color, ...props }) {
    const theme = useTheme()

    const Styled = createStyled({
        root: {
            backgroundColor: getColor(color, theme),
        },
    })

    return (
        <Styled>
            {({ classes }) => (
                <PaperBase classes={{ root: classes.root }} {...props}>
                    {children}
                </PaperBase>
            )}
        </Styled>
    )
}

function AppBar({ children, color, ...props }) {
    const useStyles = makeStyles(theme => ({
        root: {
            backgroundColor: getColor(color, theme),
        },
    }))

    const classes = useStyles()

    return (
        <AppBarBase classes={{ root: classes.root }} {...props}>
            {children}
        </AppBarBase>
    )
}

function Link({ children, color, ...props }) {
    const useStyles = makeStyles(theme => ({
        root: {
            color: color ? `${getColor(color, theme)} !important` : theme.palette.text.primary,
        },
    }))

    const classes = useStyles()

    return (
        <LinkBase classes={{ root: classes.root }} {...props}>
            {children}
        </LinkBase>
    )
}

function Popover({ children, ...props }) {
    const useStyles = makeStyles(theme => ({
        paper: {
            boxShadow: theme.customShadows.widget,
        },
    }))

    const classes = useStyles()

    return (
        <PopoverBase classes={{ paper: classes.paper }} {...props}>
            {children}
        </PopoverBase>
    )
}

function Popper({ children, ...props }) {
    const useStyles = makeStyles(theme => ({
        paper: {
            boxShadow: theme.customShadows.widget,
        },
    }))

    const classes = useStyles()

    return (
        <PopperBase classes={{ paper: classes.paper }} {...props}>
            {children}
        </PopperBase>
    )
}

function CircularProgress({ children, color, ...props }) {
    const useStyles = makeStyles(theme => ({
        paper: {
            color: color ? `${getColor(color, theme)} !important` : theme.palette.primary.main,
        },
    }))

    const classes = useStyles()

    return (
        <CircularProgressBase classes={{ paper: classes.paper }} {...props}>
            {children}
        </CircularProgressBase>
    )
}

function LinearProgress({ children, color, ...props }) {
    const theme = useTheme()

    const Styled = createStyled({
        root: {
            backgroundColor: getCustomBackgroundColor(color),
        },
        bar: {
            backgroundColor: color ? `${getColor(color, theme)} !important` : theme.palette.primary.main,
        },
    })

    return (
        <Styled>
            {({ classes }) => (
                <LinearProgressBase classes={{ root: classes.root, bar: classes.bar }} {...props}>
                    {children}
                </LinearProgressBase>
            )}
        </Styled>
    )
}

function Radio({ children, color, ...props }) {
    const Styled = createStyled({
        root: {
            color: 'green',
            '&$checked': {
                color: 'green',
            },
        },
        checked: {},
        // '&.Mui-checked': {
        // color: theme.palette[color].main
        // },
        // '&:hover': {
        //   backgroundColor: `${theme.palette[color].main} !important`,
        //   opacity: .1
        // }
    })

    return (
        <Styled>
            {({ classes }) => (
                <RadioBase
                    classes={{
                        root: classes.root,
                        checked: classes.checked,
                    }}
                    {...props}
                />
            )}
        </Styled>
    )
}

function Dialog({ children, paddingTop, paddingBottom, floatingClose, open, alwaysFullscreen, disableClose, rootClass, ...props }) {
    const theme = useTheme()

    const isPhone = useMediaQuery(theme.breakpoints.down('xs'))

    const isFullScreen = isPhone || alwaysFullscreen || isMobileDevice()

    React.useEffect(() => {
        if (open && Capacitor.isPluginAvailable('PushNotifications') && isMobileDevice() && theme.palette.type !== 'dark') {
            StatusBar.setStyle({
                style: StatusBarStyle.Light,
            })

            return () => {
                StatusBar.setStyle({
                    style: StatusBarStyle.Dark,
                })
            }
        }
    }, [theme.palette.type, open, isFullScreen])

    const styles = {
        iconClose: {
            right: 16,
            bottom: 16,
            width: 48,
            height: 48,
            zIndex: theme.zIndex.drawer + 2,
            backgroundColor: 'rgba(0,0,0,0.4)',
            '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.2)',
            },
        },
        closeDivFullScreen: {
            right: 'env(safe-area-inset-right)',
            top: 'calc(32px + env(safe-area-inset-top))',
            position: `absolute`,
        },
        closeDiv: {
            right: 0,
            top: 32,
            position: `absolute`,
        },
    }

    return (
        <DialogBase
            {...props}
            open={open}
            fullWidth
            fullScreen={isFullScreen}
            PaperProps={{
                style: {
                    paddingTop: 'env(safe-area-inset-top)',
                    paddingBottom: 'env(safe-area-inset-bottom)',
                },
            }}
            classes={{ root: `dialogMobileRoot ${rootClass ?? ''}` }}
        >
            {children}
            {floatingClose && (
                <Box style={isFullScreen ? styles.closeDivFullScreen : styles.closeDiv}>
                    <IconButtonBase style={styles.iconClose} onClick={props.onClose} disabled={disableClose}>
                        <CloseIcon style={{ color: 'white' }} />
                    </IconButtonBase>
                </Box>
            )}
        </DialogBase>
    )
}

function DialogTitle({ children, onClose, other, disableClose, ...props }) {
    const theme = useTheme()

    const Styled = createStyled({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: 60,
        },
        closeButton: {
            height: 48,
            width: 48,
            margin: -1 * theme.spacing(1),
            color: theme.palette.grey[500],
        },
    })

    return (
        <Styled>
            {({ classes }) => (
                <DialogTitleBase disableTypography className={classes.root} {...other}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                        <IconButtonBase aria-label="close" className={classes.closeButton} onClick={onClose} disabled={disableClose}>
                            <CloseIcon />
                        </IconButtonBase>
                    ) : null}
                </DialogTitleBase>
            )}
        </Styled>
    )
}

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(DialogContentBase)

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(DialogActionsBase)

export {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Avatar,
    Badge,
    BadgeNoContent,
    BadgeSide,
    BadgeSidebar,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    IconChip,
    LinearProgress,
    Link,
    Paper,
    Popover,
    Popper,
    Radio,
    Tooltip,
    Typography,
}

// ########################################################################

function getColor(color, theme, brightness = 'main') {
    if (color && theme.palette[color] && theme.palette[color][brightness]) {
        return theme.palette[color][brightness]
    }
}

function getCustomBackgroundColor(color) {
    switch (color) {
        case 'primary':
            return 'rgba(83, 109, 254, .3)'
        case 'secondary':
            return 'rgba(255, 198, 208, 0.3)'
        case 'warning':
            return 'rgba(255, 219, 198, 0.3)'
        case 'success':
            return 'rgba(147, 212, 185, 0.3)'
        case 'info':
            return 'rgba(214, 172, 254, 0.3)'
        default:
            return '#C4D4FE'
    }
}

function getFontWeight(style) {
    switch (style) {
        case 'light':
            return 300
        case 'medium':
            return 500
        case 'bold':
            return 600
        default:
            return 400
    }
}

function getFontSize(size, variant = '', theme) {
    let multiplier

    switch (size) {
        case 'sm':
            multiplier = 0.8
            break
        case 'md':
            multiplier = 1.5
            break
        case 'xl':
            multiplier = 2
            break
        case 'xxl':
            multiplier = 3
            break
        default:
            multiplier = 1
            break
    }

    const defaultSize = variant && theme.typography[variant] ? theme.typography[variant].fontSize : theme.typography.fontStyle + 'px'

    return `calc(${defaultSize} * ${multiplier})`
}

function createStyled(styles, options) {
    const Styled = function(props) {
        const { children, ...other } = props
        return children(other)
    }

    return withStyles(styles, options)(Styled)
}
