import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    cleanTable: {
        boxShadow: theme.customShadows.widget,
    },
    switch: {
        '& .MuiFormControlLabel-label': {
            flexGrow: 1,
        },
    },
}))
