import React from 'react'
import { Box, Backdrop, IconButton, Modal } from '@material-ui/core'

import { Close as CloseIcon } from '@material-ui/icons'

import PrismaZoom from 'react-prismazoom'

import { Document, Page } from 'react-pdf'

// styles
import useStyles from './styles'

export default function FullScreenImage(props) {
    var classes = useStyles()

    const { open, onClose, src, isObj, extension } = props

    const [numPages, setNumPages] = React.useState(null)

    React.useEffect(() => {
        sessionStorage.setItem('previewItem', true)

        return () => {
            sessionStorage.removeItem('previewItem')
        }
    }, [])

    const renderItem = () => {
        if (isObj) {
            if (extension === 'pdf') {
                return (
                    <Box className={classes.obj}>
                        <Document
                            file={{ url: src }}
                            onLoadSuccess={pdf => {
                                console.log('got pdf', pdf)
                                setNumPages(pdf.numPages)
                            }}
                            className={classes.pdfDocument}
                            onLoadError={console.error}
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>
                    </Box>
                )
            }

            return <></>
        }

        return (
            <PrismaZoom maxZoom={2} animDuration={0.25} style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={src} className={classes.img} alt="" />
            </PrismaZoom>
        )
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Backdrop className={classes.backdrop} open={open}>
                <Box className={classes.parentBox}>
                    {open && renderItem()}
                    <Box className={classes.buttonBox}>
                        <Box className={classes.buttonBoxInner}>
                            <IconButton id="exit-newsletter-button" className={classes.exitButton} onClick={onClose}>
                                <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Backdrop>
        </Modal>
    )
}
