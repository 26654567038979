import { makeStyles } from '@material-ui/styles'
import { alpha } from '@material-ui/core/styles'

export default makeStyles(theme => ({
    curDay: { backgroundColor: theme.palette.primary.main, color: `white` },
    todaysDay: { borderWidth: 2, borderStyle: `solid`, borderColor: theme.palette.primary.main, color: theme.palette.text.primary },
    selectedDay: { backgroundColor: alpha(theme.palette.primary.main, 0.85), color: `white` },
    day: { color: theme.palette.text.primary },
    sameWeek: {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    differentWeek: {},
    dot: {
        width: '0.625em',
        height: '0.625em',
        borderRadius: '0.3125em',
        border: `1px solid ` + theme.palette.text.primary,
        margin: `0 0.0625em`,
    },
    dotPrimary: {
        width: '0.625em',
        height: '0.625em',
        borderRadius: '0.3125em',
        border: `1px solid white`,
        margin: `0 0.0625em`,
    },
    chip: {
        margin: '0.25em',
    },
    calendarParent: {
        userSelect: `none`,
    },
    locationTypography: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    eventWidget: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))
