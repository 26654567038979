import React from 'react'

import {
    Launch as ExternalLinkIcon,
    LibraryBooksOutlined as DocumentationIcon,
    EmojiObjectsOutlined as FeedbackIcon,
    NotificationsNone as NotificationsIcon,
    PersonOutline as AccountIcon,
    LockOutlined as SignOutIcon,
    BugReportOutlined as BugReportIcon,
    SettingsOutlined as SettingsIcon,
} from '@material-ui/icons'

import { WhatsNewIcon } from 'components/Icons'

export const sidebarIconsPrimary = [
    {
        id: 'account',
        label: 'Account',
        icon: <AccountIcon />,
        onClick: props => {
            props.history.push(`/app/profile/${props.user && props.user.getId() ? props.user.getId() : ''}`)
        },
    },
    {
        id: 'notifications',
        label: 'Notifications',
        icon: <NotificationsIcon />,
        onClick: props => {
            props.history.push('/app/notifications/')
        },
    },
    {
        id: 'settings',
        label: 'Settings',
        icon: <SettingsIcon />,
        onClick: props => {
            props.history.push('/app/settings/')
        },
    },
]

export const sidebarIconsSecondary = [
    {
        id: 'sign_out',
        label: 'Sign Out',
        icon: <SignOutIcon />,
        onClick: ({ signOut }) => signOut(),
    },
]

export const sidebarIconsTertiary = [
    {
        id: 'whats_new',
        label: "What's New",
        icon: <WhatsNewIcon />,
        onClick: ({ whatsNew }) => whatsNew(),
    },
    {
        id: 'documentation',
        label: 'Documentation',
        icon: <DocumentationIcon />,
        secondaryIcon: <ExternalLinkIcon />,
        props: {
            component: 'a',
            href: 'https://getgreekconnect.com/docs/getting-started/overview',
            target: '_blank',
        },
    },
    {
        id: 'feedback',
        label: 'Feedback',
        icon: <FeedbackIcon />,
        secondaryIcon: <ExternalLinkIcon />,
        props: {
            component: 'a',
            href: 'https://forms.gle/2DnmXruEvMfx2TXn9',
            target: '_blank',
        },
    },
    {
        id: 'report_bug',
        label: 'Report Bug',
        icon: <BugReportIcon />,
        secondaryIcon: <ExternalLinkIcon />,
        props: {
            component: 'a',
            href: 'https://forms.gle/qCpAL7Wzbq3Btxt3A',
            target: '_blank',
        },
    },
]
