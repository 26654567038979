import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    icon: {
        fontSize: theme.spacing(2),
    },
    divider: {
        margin: `0px -${theme.spacing(3)}px`,
    },
    wrapper: {
        '& > *': { paddingTop: theme.spacing(5) },
        '& > :first-child': {
            paddingTop: 0,
        },
    },
    navPadding: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
    },
    progressSection: {
        marginBottom: theme.spacing(1),
    },
    progressTitle: {
        marginBottom: theme.spacing(2),
    },
    progress: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.gray.main,
    },
    progressBar: {
        backgroundColor: theme.palette.primary.main,
    },
    chip: {
        margin: 4,
    },
    socialIcon: {
        marginLeft: 6,
        marginRight: 6,
    },
}))
