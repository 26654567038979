import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    boxLeftArrow: { display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: 8 },
    boxRightArrow: { display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 8 },
    tabSection: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `0px !important`,
        paddingLeft: 0,
        paddingRight: 0,
        overflow: 'auto',
        borderRadius: 4,
    },
}))
