import React from 'react'

import { Box, Divider, SwipeableDrawer } from '@material-ui/core'

import { isMobileDevice } from 'code/Helper'

import { DialogContent as DialogContentMobile, Dialog as DialogMobile, Typography } from 'components/Wrappers'

// styles
import useStyles from './styles'

const PopupDrawer = ({ children, icon, id, open, onClose, subtitle, title, ...props }) => {
    var classes = useStyles()

    if (isMobileDevice()) {
        return (
            <SwipeableDrawer anchor="bottom" open={open} onClose={onClose} style={{ zIndex: 2300 }} disableSwipeToOpen onOpen={() => {}}>
                <Box className={classes.drawer}>
                    <Box className={classes.iconBox}>{React.cloneElement(icon, { className: classes.icon })}</Box>
                    <Box style={{ marginBottom: '0.5rem' }}>
                        <Typography align="center" style={{ fontWeight: 500, fontSize: '1em' }} className={classes.nonOverflowText}>
                            {title}
                        </Typography>
                        <Typography align="center" style={{ fontSize: '0.875em' }} className={classes.nonOverflowText}>
                            {subtitle}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box style={{ marginTop: '0.5rem' }}>{children}</Box>
                </Box>
            </SwipeableDrawer>
        )
    }

    return (
        <DialogMobile maxWidth="md" id={id} aria-labelledby={`${id}-title`} open={open} fullWidth onClose={onClose} floatingClose>
            <DialogContentMobile dividers className={classes.dialog}>
                <Box className={classes.iconBox}>{React.cloneElement(icon, { className: classes.icon })}</Box>
                <Box style={{ marginBottom: '0.5rem' }}>
                    <Typography id={`${id}-title`} align="center" style={{ fontWeight: 500, fontSize: '1em' }} className={classes.nonOverflowText}>
                        {title}
                    </Typography>
                    <Typography align="center" style={{ fontSize: '0.875em' }} className={classes.nonOverflowText}>
                        {subtitle}
                    </Typography>
                </Box>
                <Divider />
                <Box style={{ marginTop: '0.5rem' }}>{children}</Box>
            </DialogContentMobile>
        </DialogMobile>
    )
}

export default React.memo(PopupDrawer)
