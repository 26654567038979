const USER_PERMISSION_RUSH_VIEW = 'rushView'
const USER_PERMISSION_RUSH_ADMIN = 'rushAdmin'
const USER_PERMISSION_CALENDAR_ADMIN = 'calendarAdmin'
const USER_PERMISSION_ADMIN = 'ADMIN'
const USER_PERMISSION_NEWSLETTER_ADMIN = 'newsletterAdmin'
const USER_PERMISSION_LINK_CREATE = 'linkCreate'
const USER_PERMISSION_LINK_ADMIN = 'linkAdmin'
const USER_PERMISSION_POLL_CREATE = 'pollCreate'
const USER_PERMISSION_POLL_ADMIN = 'pollAdmin'

export default class Permissions {
    static get RUSH_VIEW() {
        return USER_PERMISSION_RUSH_VIEW
    }

    static get RUSH_ADMIN() {
        return USER_PERMISSION_RUSH_ADMIN
    }

    static get CALENDAR_ADMIN() {
        return USER_PERMISSION_CALENDAR_ADMIN
    }

    static get LINK_ADMIN() {
        return USER_PERMISSION_LINK_ADMIN
    }

    static get LINK_CREATE() {
        return USER_PERMISSION_LINK_CREATE
    }

    static get POLL_CREATE() {
        return USER_PERMISSION_POLL_CREATE
    }

    static get POLL_ADMIN() {
        return USER_PERMISSION_POLL_ADMIN
    }

    static get ADMIN() {
        return USER_PERMISSION_ADMIN
    }

    static get NEWSLETTER_ADMIN() {
        return USER_PERMISSION_NEWSLETTER_ADMIN
    }
}
