import React from 'react'

import { DeleteOutline } from '@material-ui/icons'

import { Grid } from '@material-ui/core'

import NavigationBar from '../../../components/NavigationBar'
import Widget from '../../../components/Widget'

import ParentTable from '../../../components/Tables/ParentTable'
import Table from '../../../components/Tables/Table'

export default function Staff() {
    function renderStaff() {
        return (
            <Grid item xs={12}>
                <Widget disableWidgetMenu inheritHeight noBodyPadding>
                    <ParentTable title="Staff">
                        <Grid container>
                            <Grid item xs={12}>
                                <Table
                                    id="email"
                                    columns={[
                                        {
                                            title: 'First',
                                            value: 'first',
                                            required: true,
                                        },
                                        {
                                            title: 'Last',
                                            value: 'last',
                                            required: true,
                                        },
                                        {
                                            title: 'Email',
                                            value: 'email',
                                            size: 'large',
                                            required: true,
                                        },
                                    ]}
                                    rowActions={[
                                        {
                                            title: 'Delete Account',
                                            icon: <DeleteOutline />,
                                            onClick: rowData => {
                                                // TODO
                                            },
                                        },
                                    ]}
                                    onAdd={member => {
                                        // TODO
                                    }}
                                    addRowText="Add Account"
                                    showOnEmpty={true}
                                    isLoading={false}
                                    emptyText="No Staff"
                                    data={[]}
                                />
                            </Grid>
                        </Grid>
                    </ParentTable>
                </Widget>
            </Grid>
        )
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <NavigationBar
                        titles={[
                            {
                                name: 'Staff',
                            },
                        ]}
                    />
                </Grid>
                {renderStaff()}
            </Grid>
        </>
    )
}
