import React from 'react'

import app from 'firebase/app'

import ButtonBase from '@mui/material/ButtonBase'
import DividerBase from '@mui/material/Divider'
import Grid from '@material-ui/core/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import {
    LocationOnOutlined as LocationIcon,
    MapOutlined as MapIcon,
    SupervisorAccountOutlined as AdvisorIcon,
    ChevronRightRounded as ArrowIcon,
    SmsOutlined as AnnouncementsIcon,
    CalendarToday as CalendarIcon,
    PollOutlined as PollsIcon,
    Timeline as PointsIcon,
    WhatshotOutlined as RushIcon,
    ForumOutlined as MessagesIcon,
} from '@material-ui/icons'

import { alpha } from '@material-ui/core/styles'

import Box from '@mui/material/Box'

import { useTheme } from '@material-ui/styles'

import TinyCard from 'components/Cards/TinyCard'
import NavigationBar from 'components/NavigationBar'
import Paper from 'components/Paper'

import Map from 'components/Map'
import { StagnantMarker as MapMarker } from 'components/Map/Marker'

import { DataGrid } from '@mui/x-data-grid'

import { Link } from 'react-router-dom'
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'

import { convertObjectToList } from 'code/Helper'

import { SchoolIcon, DriveIcon } from 'components/Icons'

import { LocationContext } from 'context/LocationContext'

import useStyles from '../styles'

export default function ViewChapter(props) {
    const theme = useTheme()
    const classes = useStyles()

    const { mapkit } = React.useContext(LocationContext)

    const Divider = ({ sx, ...props }) => <DividerBase {...props} sx={{ ...sx, borderColor: `rgba(224, 224, 224, 1)` }} />

    const visibilityNameOptions = ['Associate Member', 'Active', 'Inactive', 'Officer', 'Alumni', 'Guest']

    const [chapter, setChapter] = React.useState(null)
    const [hasTried, setHasTried] = React.useState(false)
    const [members, setMembers] = React.useState([])

    if (!hasTried) {
        setHasTried(true)
        grabChapter()
    }

    function grabChapter() {
        const db = app.firestore()
        db.collection('chapters')
            .doc(props.match.params.id)
            .get()
            .then(function(doc) {
                const data = doc.data()
                setChapter(data)
                let mems = convertObjectToList(data.members)
                    .filter(m => [0, 1, 3].includes(m.status))
                    .map(m => ({ ...m, class: m.roll < 1063 ? '2023' : m.roll < 1078 ? '2024' : m.roll < 1106 ? '2025' : '2026' }))
                setMembers(mems)
                console.log('got chapter', data)
            })
            .catch(function(error) {
                console.log('Error getting document: ', error)
            })
    }

    const data = [
        { id: 0, name: 'Associate Member', value: members.filter(member => member.status === 0).length, color: 'gray' },
        { id: 1, name: 'Active', value: members.filter(member => member.status === 1).length, color: 'primary' },
        { id: 2, name: 'Inactive', value: members.filter(member => member.status === 2).length, color: 'yellow' },
        { id: 3, name: 'Officer', value: members.filter(member => member.status === 3).length, color: 'green' },
    ].filter(d => d.value > 0)

    const classData = [
        { id: 0, name: '2023', value: members.filter(member => member.class === '2023').length, color: 'primary', opacity: 1 },
        { id: 1, name: '2024', value: members.filter(member => member.class === '2024').length, color: 'primary', opacity: 0.9 },
        { id: 2, name: '2025', value: members.filter(member => member.class === '2025').length, color: 'primary', opacity: 0.8 },
        { id: 3, name: '2026', value: members.filter(member => member.class === '2026').length, color: 'primary', opacity: 0.7 },
    ].filter(d => d.value > 0)

    const renderMemberCharts = (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography fontWeight="500" fontSize="1.25em" align="center">
                    Statuses
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography fontWeight="500" fontSize="1.25em" align="center">
                    Classes
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 6,
                    }}
                >
                    <ResponsiveContainer width="100%" height={88}>
                        <PieChart>
                            <Pie data={data} outerRadius={40} dataKey="value" label={data => `${data.payload.name} (${data.payload.value})`}>
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={theme.palette[entry.color].main} stroke={''} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 6,
                    }}
                >
                    <ResponsiveContainer width="100%" height={88}>
                        <PieChart>
                            <Pie data={classData} outerRadius={40} dataKey="value" label={data => `${data.payload.name} (${data.payload.value})`}>
                                {classData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={alpha(theme.palette[entry.color].main, entry.opacity ?? 1)} stroke={''} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        </Grid>
    )

    const RenderedLine = ({ icon, label }) => (
        <Stack direction="row" spacing={0.5}>
            {React.createElement(icon)}
            <Typography>{label}</Typography>
        </Stack>
    )

    const RenderFeature = ({ top, bottom, to, icon, title }) => (
        <Stack direction="row" alignItems="center" spacing={1}>
            <ButtonBase
                sx={{
                    flexGrow: 1,
                    width: '100%',
                    textAlign: 'left',
                    p: 1,
                    pl: 2,
                    pr: 2,
                    borderTopLeftRadius: !!top ? 4 : 0,
                    borderTopRightRadius: !!top ? 4 : 0,
                    borderBottomLeftRadius: !!bottom ? 4 : 0,
                    borderBottomRightRadius: !!bottom ? 4 : 0,
                }}
                component={Link}
                to={to}
            >
                <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }}>
                    {React.createElement(icon)}
                    <Stack direction="column" sx={{ flexGrow: 1 }}>
                        <Typography>{title}</Typography>
                    </Stack>
                    <ArrowIcon />
                </Stack>
            </ButtonBase>
        </Stack>
    )

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <NavigationBar
                        titles={[
                            {
                                name: 'Chapters',
                                link: '/app/hq/chapters',
                            },
                            {
                                name: 'View',
                            },
                        ]}
                    />
                </Grid>
                {chapter && (
                    <>
                        <Grid item xs={12} md={4}>
                            <Paper>
                                <Map
                                    style={{
                                        height: 216 + 16 + 4,
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4,
                                    }}
                                    center={new mapkit.Coordinate(33.7758, -84.3917)}
                                    span={new mapkit.CoordinateSpan(0.1)}
                                >
                                    <MapMarker
                                        center={new mapkit.Coordinate(33.7758, -84.3917)}
                                        subtitle={chapter.school}
                                        title={chapter.fullName}
                                        glyphText={'BA'}
                                        color={theme.palette.primary.main}
                                    />
                                </Map>
                                <Stack sx={{ p: 2 }} spacing={1}>
                                    <Typography variant="h3" fontWeight="bold">
                                        {chapter.fullName} {props.match.params.id === 'aRJv1lfQMHVjlhtZ0dAY' ? 'Colony' : 'Chapter'}
                                    </Typography>
                                    <RenderedLine icon={SchoolIcon} label="Georgia Institute of Technology" />
                                    <RenderedLine icon={LocationIcon} label="Atlanta, Georgia" />
                                    <RenderedLine icon={MapIcon} label="Southern District" />
                                    <RenderedLine icon={AdvisorIcon} label="Chip Freeney" />
                                </Stack>
                                <Divider />
                                <RenderFeature to="/apps/X" icon={AnnouncementsIcon} title="Announcements" />
                                <Divider />
                                <RenderFeature to="/apps/X" icon={CalendarIcon} title="Calendar" />
                                <Divider />
                                <RenderFeature to="/apps/X" icon={DriveIcon} title="Drive" />
                                <Divider />
                                <RenderFeature to="/apps/X" icon={MessagesIcon} title="Messages" />
                                <Divider />
                                <RenderFeature to="/apps/X" icon={PointsIcon} title="Points" />
                                <Divider />
                                <RenderFeature to="/apps/X" icon={PollsIcon} title="Polls" />
                                <Divider />
                                <RenderFeature to="/apps/X" icon={RushIcon} title="Recruitment" bottom />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.table}>
                                    <Paper>
                                        <Stack sx={{ pt: 2, pb: 2, ml: 2.5, mr: 2.5 }} spacing={0}>
                                            <Stack direction="row" spacing={1}>
                                                <Typography sx={{ flexGrow: 1 }} fontWeight="500" fontSize="1.5em">
                                                    Members
                                                </Typography>
                                            </Stack>
                                            {renderMemberCharts}
                                        </Stack>
                                        <Divider />
                                        <DataGrid
                                            autoHeight
                                            sx={{ border: 'none' }}
                                            rows={members ?? []}
                                            loading={members === null}
                                            rowHeight={42 /*44*/}
                                            columnHeaderHeight={42}
                                            footerHeight={42}
                                            columns={[
                                                {
                                                    field: 'name',
                                                    headerName: 'Name',
                                                    minWidth: 250,
                                                    flex: 1,
                                                    renderCell: ({ value, row }) => (
                                                        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ml: 1.5 }}>
                                                            {row.first} {row.last}
                                                        </Typography>
                                                    ),
                                                    valueGetter: ({ row }) => `${row.last} ${row.row}`,
                                                    renderHeader: () => (
                                                        <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                                            Name
                                                        </Typography>
                                                    ),
                                                },
                                                {
                                                    field: 'status',
                                                    headerName: 'Status',
                                                    align: 'center',
                                                    headerAlign: 'center',
                                                    minWidth: 175,
                                                    renderCell: ({ value }) => (
                                                        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ml: 1.5 }}>
                                                            {value}
                                                        </Typography>
                                                    ),
                                                    valueGetter: ({ value }) => visibilityNameOptions[value],
                                                    renderHeader: () => (
                                                        <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                                            Status
                                                        </Typography>
                                                    ),
                                                },
                                                {
                                                    field: 'class',
                                                    headerName: 'Class',
                                                    align: 'center',
                                                    headerAlign: 'center',
                                                    minWidth: 125,
                                                    renderCell: ({ value }) => (
                                                        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ml: 1.5 }}>
                                                            {value}
                                                        </Typography>
                                                    ),
                                                    renderHeader: () => (
                                                        <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                                            Class
                                                        </Typography>
                                                    ),
                                                },
                                                {
                                                    field: 'roll',
                                                    headerName: 'Roll',
                                                    align: 'center',
                                                    headerAlign: 'center',
                                                    minWidth: 125,
                                                    renderCell: ({ value }) => (
                                                        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', ml: 1.5 }}>
                                                            {value}
                                                        </Typography>
                                                    ),
                                                    valueGetter: ({ value, row }) => (row.status === 0 ? `AM ${value}` : `${chapter.letters} ${value}`),
                                                    renderHeader: () => (
                                                        <Typography fontWeight="500" sx={{ ml: 1.5 }}>
                                                            Roll
                                                        </Typography>
                                                    ),
                                                },
                                            ]}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 10 } },
                                            }}
                                            pageSizeOptions={[5, 10, 25, 50, 100]}
                                            hideFooterSelectedRowCount
                                            disableColumnMenu
                                            onRowClick={params => {}}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        {false && (
                            <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                                <TinyCard
                                    onClick={() => props.history.push('/app/hq/chapters/' + props.match.params.id + '/member-management/')}
                                    text="Member Management"
                                />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </>
    )
}
