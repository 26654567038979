import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    parentBox: {
        width: 384,
        backgroundColor: 'white',
        border: `2px solid #D8D8D8`,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.16)',
        color: 'black',
    },
    titleText: {
        fontWeight: 'bold !important',
        fontSize: '18px !important',
    },
    secondaryTitleText: {
        fontWeight: 'bold !important',
        fontSize: '16px !important',
    },
    subtitleText: {
        fontWeight: '500 !important',
        fontSize: '14px !important',
    },
    warningSectionBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(0.5),
    },
    warningSectionLabel: {
        fontSize: '14px !important',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        fontWeight: '500 !important',
        color: theme.palette.orange.main,
    },
    warningSectionIcon: {
        fontSize: '1.25em',
        color: theme.palette.orange.main,
    },
    successSectionLabel: {
        fontSize: '14px !important',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        fontWeight: '500 !important',
        color: theme.palette.green.main,
    },
    successSectionIcon: {
        fontSize: '1.25em',
        color: theme.palette.green.main,
    },
    iconSectionBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconSectionLabel: {
        flexGrow: 1,
        fontSize: '14px !important',
        marginLeft: theme.spacing(0.5),
        fontWeight: '500 !important',
    },
    iconSectionLinkLabel: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
    divider: {
        width: 'calc(100% + 16px)',
        margin: `8px -8px`,
        height: 1,
        backgroundColor: `#D8D8D8`,
    },
    itinerarySectionBox: {
        display: 'flex',
        flexDirection: 'column',
    },
    itinerarySectionUpNext: {
        fontWeight: '500 !important',
        fontSize: '14px !important',
        marginTop: theme.spacing(1),
    },
    itinerarySectionLabel: {
        fontSize: '12px !important',
        fontWeight: '500 !important',
    },
    itineryInnerSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: -8,
        marginRight: -8,
    },
    itineryInnerSectionBefore: {
        height: 6,
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
        opacity: 0.3,
    },
    itineryInnerSectionBubble: {
        width: 16,
        height: 16,
        borderRadius: 8,
        backgroundColor: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    itineryInnerSectionAfter: {
        height: 6,
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
        flexGrow: 1,
        backgroundColor: 'black',
    },
    buttonBox: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    /*  RSVP Box */
    rsvpBoxParent: {
        width: 384,
        backgroundColor: 'white',
        border: `2px solid #D8D8D8`,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.16)',
        color: 'black',
    },
    rsvpBox: {
        marginTop: -10,
    },
}))
