import { Box, Link, Typography } from '@material-ui/core'
import React from 'react'

import moment from 'moment'

import { getEventMoment, getFirebaseEndDate } from 'code/TimeAgo'

import { useTheme } from '@material-ui/styles'

// styles
import useStyles from '../styles'

const CalendarDate = ({ day, date, events, setDate, isToday, isSameWeek, isSameMonth, isSelected }) => {
    const classes = useStyles()
    const theme = useTheme()

    const percentage = '' + 100 / 7 + '%'

    const getDots = React.useCallback(() => {
        let date = moment(day)
        if (!Array.isArray(events)) {
            return []
        }

        let dots = []
        for (let i = 0; i < events.length; i++) {
            let e = events[i]
            let eMoment = getEventMoment(e)
            let eEnd = getFirebaseEndDate(e)
            let eMomentEnd = moment(eEnd)
            if (eMomentEnd.isSame(date, 'day') || eMoment.isSame(date, 'day') || (eMoment.isBefore(date) && eMomentEnd.isAfter(date))) {
                if (e.categories.length > 0) {
                    dots.push({ color: e.categories[0].color, id: e.id })
                } else {
                    dots.push({ color: theme.palette.text.primary, id: e.id })
                }

                if (dots.length === 2) {
                    i = events.length
                }
            }
        }

        return dots
    }, [day, events, theme.palette.text.primary])

    const dots = getDots()

    return (
        <Box style={{ width: percentage }} className={isSameWeek ? classes.sameWeek : classes.diffWeek}>
            <Link
                style={{
                    width: '2.5em',
                    height: '2.5em',
                    margin: `0 auto`,
                    borderRadius: '0.625em',
                    display: `flex`,
                    position: `relative`,
                    opacity: isSameMonth ? 1 : 0.5,
                    cursor: `pointer`,
                }}
                className={isSelected && isToday ? classes.curDay : isToday ? classes.todaysDay : isSelected ? classes.selectedDay : classes.day}
                onClick={() => setDate(day)}
                underline="none"
            >
                <Box style={{ margin: dots.length === 0 ? `auto` : `0 auto` }}>
                    <Typography
                        style={{
                            textAlign: `center`,
                            fontSize: `1.125em`,
                            fontWeight: 600,
                            marginTop: isToday && !isSelected && /*Day has no events */ dots.length !== 0 ? -2 : 0,
                        }}
                    >
                        {date}
                    </Typography>
                    <Box
                        style={{
                            width: `100%`,
                            height: '0.25em',
                            left: 0,
                            top: isToday && !isSelected ? '1.4375em' : '1.5625em',
                            position: `absolute`,
                        }}
                    >
                        <Box style={{ display: `flex`, justifyContent: `center` }}>
                            {dots.map(dot => (
                                <Box
                                    key={`${dot.id}.${dot.color}`}
                                    className={isSelected ? classes.dotPrimary : classes.dot}
                                    style={{
                                        backgroundColor: dot.color,
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Link>
        </Box>
    )
}

export default React.memo(CalendarDate)
