import defaultTheme from './default'
import successTheme from './success'
import secondaryTheme from './secondary'
import darkTheme from './dark'

import _ from 'lodash'

import { createTheme as createMuiTheme } from '@material-ui/core'
import { createTheme } from '@mui/material/styles'

const overrides = {
    typography: {
        h1: {
            fontSize: '3rem',
        },
        h2: {
            fontSize: '2rem',
        },
        h3: {
            fontSize: '1.64rem',
        },
        h4: {
            fontSize: '1.5rem',
        },
        h5: {
            fontSize: '1.285rem',
        },
        h6: {
            fontSize: '1.142rem',
        },
    },
    overrides: {
        MuiCard: {
            root: {
                boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
            },
        },
        MUIDataTable: {
            paper: {
                boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: '#6E6E6E1A',
            },
        },
        MuiMenu: {
            paper: {
                boxShadow: '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
            },
        },
        MuiSelect: {
            icon: {
                color: '#B9B9B9',
            },
        },
        MuiListItem: {
            root: {
                '&$selected': {
                    backgroundColor: '#F5F5F5 !important',
                    '&:focus': {
                        backgroundColor: '#F5F5F5',
                    },
                },
            },
            button: {
                '&:hover, &:focus': {
                    backgroundColor: '#F5F5F5',
                },
            },
        },
        MuiTouchRipple: {
            child: {
                backgroundColor: 'white',
            },
        },
        MuiTableRow: {
            root: {
                height: 56,
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid rgba(224, 224, 224, .5)',
                padding: '14px 40px 14px 24px',
            },
            head: {
                fontSize: '0.95rem',
            },
            body: {
                fontSize: '0.95rem',
            },
            paddingCheckbox: {
                padding: '0 0 0 15px',
            },
        },
        MuiToolbar: {
            regular: {
                minHeight: `56px !important`,
            },
        },
    },
}

const darkModeOverrides = {
    overrides: {
        MuiPaper: {
            root: {
                backgroundColor: '#232323',
                boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.103475), 0px 3px 3px rgba(0, 0, 0, 0.0988309), 0px 3px 4px rgba(0, 0, 0, 0.10301) !important',
            },
        },
        MuiAppBar: {
            root: {
                backgroundColor: '#232323',
            },
            colorPrimary: {
                backgroundColor: '#232323',
            },
        },
        MuiButton: {
            root: {
                boxShadow: 'none !important',
            },
        },
        typography: {
            root: {
                color: 'white !important',
            },
        },
        MuiToolbar: {
            regular: {
                minHeight: `56px !important`,
            },
        },
    },
}

const v5DarkModeOverrides = {}

const v5Overrides = {
    components: {
        MuiButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiListItem: {
            defaultProps: {
                dense: true,
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                margin: 'dense',
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiTextField: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
            },
        },
        MuiToolbar: {
            defaultProps: {
                variant: 'dense',
            },
        },
    },
}

const deepMerge = arr => {
    let results = {}

    for (let i = 0; i < arr.length; i++) {
        results = _.merge(results, arr[i])
    }

    return results
}

export default {
    default: createMuiTheme({
        ...defaultTheme,
        ...overrides,
    }),
    secondary: createMuiTheme({ ...secondaryTheme, ...overrides }),
    success: createMuiTheme({ ...successTheme, ...overrides }),
    dark: createMuiTheme({ ...darkTheme, ...overrides, ...darkModeOverrides }),
    defaultV5: createTheme(deepMerge([defaultTheme, v5Overrides, overrides])),
    secondaryV5: createTheme(deepMerge([secondaryTheme, v5Overrides, overrides])),
    successV5: createTheme(deepMerge([successTheme, v5Overrides, overrides])),
    darkV5: createTheme(deepMerge([darkTheme, v5Overrides, overrides, darkModeOverrides, v5DarkModeOverrides])),
}
