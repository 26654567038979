import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    dialogBackground: {
        'backdrop-filter': 'blur(5px)',
        '-webkit-backdrop-filter': 'blur(5px)',
    },
    appIcon: {
        height: 60,
        borderRadius: 15,
        boxShadow: '0 10px 15px rgba(30, 136, 229, 0.13)',
    },
    dialogBottomFade: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            bottom: '100%',
            left: 0,
            right: 0,
            height: 64,
            background: `linear-gradient(to bottom, transparent, ${theme.palette.background.paper} 50%, ${theme.palette.background.paper} 100%)`,
        },
    },
}))
