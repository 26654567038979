import React from 'react'

import { CreateOutlined as EditIcon } from '@material-ui/icons'

import ChatInfo from 'components/Messages/ChatInfo'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { MessagesContext } from 'context/MessagesContext'

import { HeaderContext } from 'context/HeaderContext'

import { isMobileDevice } from 'code/Helper'

const ViewMessagesDetails = ({ history, ...props }) => {
    const { setIcons } = React.useContext(HeaderContext)

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const conversationId = props.match.params.conversationId

    const { getConversation } = React.useContext(MessagesContext)

    let conversation = getConversation(conversationId)

    function isUserMessagesAdmin() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || (chapter.perms['messagesAdmin'] && chapter.perms['messagesAdmin'].includes(mem.role))
            }
        }

        return false
    }

    React.useEffect(() => {
        if (
            conversation &&
            (conversation.owner === user.getId() || (Array.isArray(conversation.admins) && conversation.admins.includes(user.getId())) || isUserMessagesAdmin())
        ) {
            setIcons([
                {
                    name: 'Edit Conversation',
                    type: 'icon',
                    innerIcon: <EditIcon />,
                    onClick: () => history.push(`/app/messages/${conversationId}/edit`),
                },
            ])
        } else {
            setIcons([])
        }
    }, [conversation])

    return <ChatInfo conversationId={conversationId} history={history} version={isMobileDevice() ? 'mobile' : 'desktop'} />
}

export default React.memo(ViewMessagesDetails)
