import React from 'react'

import { TextField } from '@material-ui/core'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from 'components/Wrappers'

const UpdateFolderDialog = ({ open, onClose, onSubmit, isNew, defaultTitle }) => {
    const [title, setTitle] = React.useState('')

    React.useEffect(() => {
        if (!title && defaultTitle && !isNew) setTitle(defaultTitle)
        // Specifically we cannot trigger an update when title is changed
    }, [defaultTitle, title, isNew])

    const closeDialog = () => {
        setTitle('')
        onClose()
    }

    const submit = () => {
        if (title) {
            onSubmit({ title: title })
            closeDialog()
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog} fullWidth aria-labelledby="update-folder-dialog-title" maxWidth="xs">
            <DialogTitle id="update-folder-dialog-title" onClose={closeDialog}>
                {isNew ? 'Create' : 'Rename'} Folder
            </DialogTitle>
            <DialogContent dividers>
                <TextField autoFocus margin="dense" variant="outlined" label="Title" value={title} onChange={e => setTitle(e.target.value)} fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={submit} disabled={!title} color="primary">
                    {isNew ? 'Create' : 'Rename'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateFolderDialog
