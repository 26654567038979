import React from 'react'

import 'firebase/functions'

import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import {
    CheckCircle as RadioSelectedIcon,
    CheckCircleOutline as RadioPartialSelectedIcon,
    Clear as CancelIcon,
    CloudDownloadOutlined as DownloadIcon,
    FilterListOutlined as FilterIcon,
    HomeOutlined as HomeIcon,
    RadioButtonUnchecked as RadioEmptyIcon,
    Search as SearchIcon,
} from '@material-ui/icons'

import _ from 'lodash'

import { VariableSizeList as List } from 'react-window'

import { AutoSizer } from 'react-virtualized'

import {
    Button,
    Dialog as DialogMobile,
    DialogActions as DialogActionsMobile,
    DialogContent as DialogContentMobile,
    DialogTitle as DialogTitleMobile,
} from 'components/Wrappers'

import moment from 'moment'

import app from 'firebase/app'

import Tabs from 'components/Tabs'
import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import ContactCard from '../../components/Cards/ContactCard'
import NavigationBar from '../../components/NavigationBar'
import Widget from '../../components/Widget'

import { convertObjectToList, isMobileDevice as _isMobileDevice } from 'code/Helper'

import { visibilityNameOptionsNoPublic as visibilityOptions } from 'data/Visibility'

// styles
import { useTheme } from '@material-ui/styles'
import useStyles from './styles'

export default function Members(props) {
    var classes = useStyles()
    var theme = useTheme()

    const [isMobileDevice] = React.useState(_isMobileDevice())

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const listRef = React.useRef(null)

    const [selection, setSelection] = React.useState({ active: false, selection: [] })
    const [hasLoaded, setHasLoaded] = React.useState(false)

    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase()
        return /iphone|ipad|ipod/.test(userAgent)
    }

    var newMems
    if (!hasLoaded) {
        newMems = chapter ? convertObjectToList(chapter.members) : []
        setHasLoaded(true)
        getMembers(newMems)
    }

    const [curSearch, setCurSearch] = React.useState('')
    const [sortingType, setSortingType] = React.useState(localStorage.getItem('memberSorting') ? localStorage.getItem('memberSorting') : 'name')
    const [members, setMembers] = React.useState(localStorage.getItem('cache_members') ? JSON.parse(localStorage.getItem('cache_members')) : newMems)

    const jsonVal = localStorage.getItem('memberSortingTypes') ? JSON.parse(localStorage.getItem('memberSortingTypes')) : null
    const [sortedMemberTypes, setSortedMemberTypes] = React.useState(jsonVal ? jsonVal : [0, 1, 3])

    function getMembers(chapterMembers) {
        let lastChecked = localStorage.getItem('cache_members_date')
        let shouldCheck = false

        if (!lastChecked) {
            shouldCheck = true
        } else {
            lastChecked = new Date(lastChecked)
            if (lastChecked) {
                let cachedMembers = localStorage.getItem('cache_members') ? JSON.parse(localStorage.getItem('cache_members')) : newMems
                let hoursDiff = Math.floor((new Date() - lastChecked) / 1000 / 60 / 60)
                shouldCheck = hoursDiff >= 24 || isNaN(hoursDiff) || (Array.isArray(cachedMembers) && Array.isArray(chapterMembers) && chapterMembers.length !== cachedMembers.length)
            }
        }

        if (shouldCheck) {
            const db = app.firestore()
            db.collection('users')
                .where('chapter', '==', user.getChapter())
                .get()
                .then(function(querySnapshot) {
                    let users = []
                    querySnapshot.forEach(function(doc) {
                        let data = doc.data()
                        data.id = doc.id

                        if (data.rollNumber) {
                            data.roll = parseInt(data.rollNumber)
                            delete data.rollNumber
                        }

                        if (data.id in chapter.members) {
                            let mem = chapter.members[data.id]
                            data.status = mem.status
                        }

                        users.push(data)
                    })

                    localStorage.setItem('cache_members', JSON.stringify(users))
                    localStorage.setItem('cache_members_date', new Date().toString())
                    setMembers(users)

                    if (listRef && listRef.current) {
                        listRef.current.resetAfterIndex(0)
                    }
                })
                .catch(function(error) {
                    console.log('Error getting documents: ', error)
                })
        }
    }

    function memberInSearch(member, search) {
        if (!search || search.length === 0) {
            return true
        }

        if (!member || !member.first || !member.last) {
            return false
        }

        let fullName = member.first + ' ' + member.last
        fullName = fullName.toLowerCase()

        if (fullName.indexOf(search) !== -1) {
            return true
        }

        if (member.roll) {
            let roll = String(member.roll)

            if (roll.indexOf(search) !== -1) {
                return true
            }
        }

        if (member.phone) {
            let phone = String(member.phone).replace(/[- )(]/g, '')

            if (phone.indexOf(search) !== -1) {
                return 'phone'
            }
        }

        let checks = ['email', 'major', 'hometown', 'year', 'instagram', 'twitter']

        for (let i = 0; i < checks.length; i++) {
            let check = checks[i]

            if (check in member && typeof member[check] === 'string' && member[check].length > 0) {
                if (member[check].toLowerCase().indexOf(search) !== -1) {
                    return check
                }
            }
        }

        return false
    }

    const getSortedMems = newMemsInner => {
        let mems = _getSortedMems(newMemsInner)

        let prevMem = mems[0]
        for (let i = 1; i < mems.length; i++) {
            let mem = mems[i]

            mems[i] = { ...mems[i], showBorder: prevMem.type !== 'HEADER' }

            prevMem = mem
        }

        return mems
    }

    function getFilteredMemsByStatus(newMemsInner) {
        return newMemsInner.filter(member => sortedMemberTypes.includes(member.status))
    }

    function _getSortedMems(newMemsInner) {
        if (newMemsInner !== undefined) {
            let newMems = newMemsInner.filter(member => memberInSearch(member, curSearch) !== false)
            newMems = newMems.filter(member => sortedMemberTypes.includes(member.status))
            if (sortingType === 'name') {
                newMems.sort(function(a, b) {
                    let aName = a.last.toUpperCase() + ' ' + a.first.toUpperCase()
                    let bName = b.last.toUpperCase() + ' ' + b.first.toUpperCase()
                    return aName.localeCompare(bName)
                })

                let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ#'
                let sorted = []

                for (let i = 0; i < alphabet.length; i++) {
                    let letter = alphabet.charAt(i)

                    let addedHeader = false

                    if (letter === '#') {
                        if (newMems.length > 0) {
                            sorted.push({ type: 'HEADER', value: letter })
                        }

                        for (let j = 0; j < newMems.length; j++) {
                            sorted.push({ type: 'MEMBER', value: newMems[j] })
                        }
                    } else {
                        for (let j = 0; j < newMems.length; j++) {
                            if (newMems[j].last.charAt(0).toUpperCase() === letter) {
                                if (!addedHeader) {
                                    addedHeader = true
                                    sorted.push({ type: 'HEADER', value: letter })
                                }
                                let mem = newMems.splice(j, 1)[0]
                                sorted.push({ type: 'MEMBER', value: mem })
                                j--
                            }
                        }
                    }
                }

                return sorted
            } else if (sortingType === 'major') {
                newMems.sort(function(a, b) {
                    let aName = a.last.toUpperCase() + ' ' + a.first.toUpperCase()
                    let bName = b.last.toUpperCase() + ' ' + b.first.toUpperCase()
                    return aName.localeCompare(bName)
                })

                let sorted = []
                let compiledSorted = []

                let unknownFiltered = { letter: 'Unknown', members: newMems.filter(mem => !mem.major) }

                newMems = newMems.filter(mem => !!mem.major)

                while (newMems.length > 0) {
                    let major = newMems[0].major
                    sorted.push({ letter: major, members: newMems.filter(mem => mem.major === major) })
                    newMems = newMems.filter(mem => mem.major !== major)
                }

                sorted.sort(function(a, b) {
                    let aName = a.letter.toUpperCase()
                    let bName = b.letter.toUpperCase()
                    return aName.localeCompare(bName)
                })

                if (unknownFiltered.members.length > 0) {
                    sorted.push(unknownFiltered)
                }

                for (let i = 0; i < sorted.length; i++) {
                    compiledSorted.push({ type: 'HEADER', value: sorted[i].letter })
                    for (let j = 0; j < sorted[i].members.length; j++) {
                        let mem = sorted[i].members[j]
                        compiledSorted.push({ type: 'MEMBER', value: mem })
                    }
                }

                return compiledSorted
            } else if (sortingType === 'status') {
                newMems.sort(function(a, b) {
                    let aName = a.last.toUpperCase() + ' ' + a.first.toUpperCase()
                    let bName = b.last.toUpperCase() + ' ' + b.first.toUpperCase()
                    return aName.localeCompare(bName)
                })

                let sorted = []
                let compiledSorted = []

                while (newMems.length > 0) {
                    let status = newMems[0].status
                    sorted.push({ letter: '' + status, members: newMems })
                    newMems = []
                }

                sorted.sort(function(a, b) {
                    let aName = a.letter.toUpperCase()
                    let bName = b.letter.toUpperCase()
                    return aName.localeCompare(bName)
                })

                for (let i = 0; i < sorted.length; i++) {
                    compiledSorted.push({ type: 'HEADER', value: sorted[i].letter })
                    for (let j = 0; j < sorted[i].members.length; j++) {
                        let mem = sorted[i].members[j]
                        compiledSorted.push({ type: 'MEMBER', value: mem })
                    }
                }

                return compiledSorted
            } else if (sortingType === 'year') {
                newMems.sort(function(a, b) {
                    let aName = a.last.toUpperCase() + ' ' + a.first.toUpperCase()
                    let bName = b.last.toUpperCase() + ' ' + b.first.toUpperCase()
                    return aName.localeCompare(bName)
                })

                let sorted = []
                let compiledSorted = []

                let unknownFiltered = { letter: 'Unknown', members: newMems.filter(mem => !mem.class) }

                newMems = newMems.filter(mem => !!mem.class)

                while (newMems.length > 0) {
                    let _class = newMems[0].class
                    sorted.push({ letter: `${_class}`, members: newMems.filter(mem => mem.class === _class) })
                    newMems = newMems.filter(mem => mem.class !== _class)
                }

                sorted.sort(function(a, b) {
                    let aName = a.letter.toUpperCase()
                    let bName = b.letter.toUpperCase()
                    return aName.localeCompare(bName)
                })

                if (unknownFiltered.members.length > 0) {
                    sorted.push(unknownFiltered)
                }

                for (let i = 0; i < sorted.length; i++) {
                    compiledSorted.push({ type: 'HEADER', value: sorted[i].letter })
                    for (let j = 0; j < sorted[i].members.length; j++) {
                        let mem = sorted[i].members[j]
                        compiledSorted.push({ type: 'MEMBER', value: mem })
                    }
                }

                return compiledSorted
            } else if (sortingType === 'birthday') {
                newMems.sort(function(a, b) {
                    let aName = a.last.toUpperCase() + ' ' + a.first.toUpperCase()
                    let bName = b.last.toUpperCase() + ' ' + b.first.toUpperCase()
                    return aName.localeCompare(bName)
                })

                let months = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                    'Unknown',
                ]
                let sorted = []
                let compiledSorted = []

                for (let i = 0; i < months.length; i++) {
                    let month = months[i]

                    let curItem = {
                        letter: month,
                        members: [],
                    }

                    if (month === 'Unknown') {
                        for (let j = 0; j < newMems.length; j++) {
                            let mem = newMems[j]
                            if (!mem.birthday || !mem.birthday.seconds) {
                                curItem.members.push(mem)
                            }
                        }
                    } else {
                        for (let j = 0; j < newMems.length; j++) {
                            let mem = newMems[j]
                            if (mem.birthday && mem.birthday.seconds) {
                                let date = moment(new Date(0).setUTCSeconds(mem.birthday.seconds))
                                let memMonth = date.month()

                                if (memMonth === i) {
                                    let mem = newMems.splice(j, 1)[0]
                                    mem.mBirthday = date
                                    mem.dBirthday = date.date()

                                    curItem.members.push(mem)
                                    j--
                                }
                            }
                        }

                        curItem.members.sort(function(a, b) {
                            return a.dBirthday - b.dBirthday
                        })
                    }

                    if (curItem.members.length > 0) {
                        sorted.push(curItem)
                    }
                }

                for (let i = 0; i < sorted.length; i++) {
                    compiledSorted.push({ type: 'HEADER', value: sorted[i].letter })
                    for (let j = 0; j < sorted[i].members.length; j++) {
                        let mem = sorted[i].members[j]
                        compiledSorted.push({ type: 'MEMBER', value: mem })
                    }
                }

                return compiledSorted
            } else {
                // Roll
                newMems.sort(function(a, b) {
                    return a.roll - b.roll
                })

                let sorted = [
                    {
                        letter: chapter.newMemberShortname,
                        members: [],
                    },
                    {
                        letter: chapter.letters,
                        members: [],
                    },
                    { letter: 'Unknown', members: newMems.filter(mem => !mem.roll) },
                ]

                let compiledSorted = []

                newMems = newMems.filter(mem => mem.roll)

                for (let j = 0; j < newMems.length; j++) {
                    let mem = newMems[j]

                    if (mem.status === 0) {
                        sorted[0].members.push(mem)
                    } else {
                        sorted[1].members.push(mem)
                    }
                }

                if (sorted[2].members.length === 0) {
                    sorted.splice(2, 1)
                }

                if (sorted[1].members.length === 0) {
                    sorted.splice(1, 1)
                }

                if (sorted[0].members.length === 0) {
                    sorted.splice(0, 1)
                }

                for (let i = 0; i < sorted.length; i++) {
                    compiledSorted.push({ type: 'HEADER', value: sorted[i].letter })
                    for (let j = 0; j < sorted[i].members.length; j++) {
                        let mem = sorted[i].members[j]
                        compiledSorted.push({ type: 'MEMBER', value: mem })
                    }
                }

                return compiledSorted
            }
        }

        return []
    }

    function getContact(member) {
        let str = 'BEGIN:VCARD'
        str += '\nVERSION:3.0'
        str += '\nN:' + member.last + ';' + member.first + ';;;'

        if (member.phone) {
            str += '\nTEL;type=IPHONE;type=CELL;type=VOICE;type=pref:' + member.phone
        }

        str += '\nEND:VCARD'

        return str
    }

    function downloadContacts(contacts) {
        if (isIos()) {
            if (
                window.confirm(
                    'It appears you are on an iOS device.\nFor this to work, you must be on Safari or the standalone app (not Chrome or some other browser).\n\nTo properly save the contact(s) on an iOS device, click the share icon, then click "Contacts".\nThe Contacts app will then open, where you can add the contact(s).',
                )
            ) {
                actuallyDownloadContacts(contacts)
            }
        } else {
            actuallyDownloadContacts(contacts)
        }
    }

    const actuallyDownloadContacts = async contacts => {
        let text = ''
        let name = ''

        for (let i = 0; i < contacts.length; i++) {
            let mem = null
            for (let j = 0; j < members.length; j++) {
                let member = members[j]
                if (contacts[i] === member.id) {
                    mem = member
                }
            }

            if (mem !== null) {
                text += getContact(mem) + '\n'
                name = mem.first + '_' + mem.last
            }
        }

        const filename = contacts.length === 1 && name.length > 0 ? name + '.vcf' : 'contacts.vcf'

        const element = document.createElement('a')
        const file = new File([text], filename, { type: 'text/vcard' })
        const blob = new Blob([text], {
            type: 'text/vcard',
        })

        const url = URL.createObjectURL(blob)

        if (isMobileDevice && navigator.canShare && navigator.canShare({ files: [file] })) {
            await navigator.share({
                files: [file],
            })

            setSelection({ active: false, selection: [] })
        } else {
            element.href = url
            element.download = filename
            document.body.appendChild(element) // Required for this to work in FireFox
            element.click()

            setSelection({ active: false, selection: [] })
        }
    }

    function viewProfile(memberID) {
        props.history.push('/app/profile/' + memberID)
    }

    function getMemberSubtitle(member) {
        let memInSearch = memberInSearch(member, curSearch)

        if (typeof memInSearch === 'string' && memInSearch in member) {
            return member[memInSearch]
        }

        if (chapter.settings && chapter.settings.roll) {
            return sortingType === 'birthday' && member.mBirthday
                ? member.mBirthday.format('MMMM D')
                : member.roll
                ? member.status === 0
                    ? chapter.newMemberShortname + ' ' + member.roll
                    : chapter.letters + ' ' + member.roll
                : member.email
        } else {
            return sortingType === 'birthday' && member.mBirthday ? member.mBirthday.format('MMMM D') : member.phone ? member.phone : member.email
        }
    }

    const memsByStatus = React.useCallback(getFilteredMemsByStatus(newMems ? newMems : members), [newMems, members, sortedMemberTypes])

    const membersSorted = React.useCallback(getSortedMems(newMems ? newMems : members), [newMems, members, sortingType, curSearch, sortedMemberTypes])

    const ContactRow = ({ index, style }) => {
        let row = membersSorted[index]

        if (row.type === 'HEADER') {
            let header = row.value

            return (
                <Box display="flex" key={header} style={index === 0 ? { ...style, paddingTop: 8 } : style}>
                    <Typography style={{ marginLeft: 12, fontWeight: 600 }}>{header}</Typography>
                    <Box display="flex" style={{ flexGrow: 1, marginLeft: 12, flexDirection: `column`, justifyContent: `center` }}>
                        <Divider className={classes.divider} />
                    </Box>
                </Box>
            )
        }
        let member = row.value

        return (
            <Box display="flex" style={style} key={member.id}>
                <Box style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <ContactCard
                        first={member.first}
                        last={member.last}
                        subtitle={getMemberSubtitle(member)}
                        icons={[]}
                        onClick={e => {
                            if (selection.active) {
                                if (selection.selection.includes(member.id)) {
                                    setSelection(selection => ({ ...selection, selection: selection.selection.filter(id => id !== member.id) }))
                                } else {
                                    setSelection(selection => ({ ...selection, selection: _.uniq([...selection.selection, member.id]) }))
                                }
                            } else {
                                viewProfile(member.id)
                            }
                        }}
                        onLongClick={() => {
                            setSelection(selection => ({ ...selection, active: true }))
                        }}
                        selected={selection.selection.includes(member.id)}
                        selectable={selection.active}
                        checkbox={{
                            active: selection.active,
                            checked: selection.selection.includes(member.id),
                            onChange: e => {
                                let selArray = selection.selection
                                if (e.target.checked) {
                                    selArray.push(member.id)
                                } else {
                                    selArray.splice(selArray.indexOf(member.id), 1)
                                }
                                setSelection({ ...selection, selection: selArray })
                            },
                        }}
                        variant={isMobileDevice ? 'mobile' : 'desktop'}
                        showBorder={row.showBorder}
                    />
                </Box>
            </Box>
        )
    }

    const getTabs = () => {
        let tabs = [
            { id: 'name', name: 'Alphabetical' },
            { id: 'birthday', name: 'Birthday' },
            { id: 'major', name: 'Major' },
        ]

        if (chapter.settings && chapter.settings.roll) {
            tabs.push({ id: 'roll', name: 'Roll' })
        }

        tabs.push({ id: 'year', name: 'Class' })

        return tabs
    }

    const renderTabs = () => (
        <Tabs
            tabs={getTabs()}
            selected={sortingType}
            onChange={val => {
                localStorage.setItem('memberSorting', val)
                setSortingType(val)

                if (listRef && listRef.current) {
                    listRef.current.resetAfterIndex(0)
                }
            }}
            autoSize
        />
    )

    const [filterDialog, setFilterDialog] = React.useState({ open: false, statuses: sortedMemberTypes.map(type => visibilityOptions[type]) })

    const closeFilterDialog = () => setFilterDialog(dialog => ({ ...dialog, open: false, statuses: sortedMemberTypes.map(type => visibilityOptions[type]) }))

    const saveFilterDialog = () => {
        let savedStatuses = filterDialog.statuses.map(status => visibilityOptions.indexOf(status))
        setSortedMemberTypes(savedStatuses)
        localStorage.setItem('memberSortingTypes', JSON.stringify(savedStatuses))
        setFilterDialog(dialog => ({ ...dialog, open: false }))
    }

    const toggleFilterOption = title => {
        if (filterDialog.statuses.includes(title)) {
            setFilterDialog(dialog => ({ ...dialog, statuses: dialog.statuses.filter(t => t !== title) }))
        } else {
            setFilterDialog(dialog => ({ ...dialog, statuses: [...dialog.statuses, title] }))
        }
    }

    const renderFilterOption = title => (
        <FormControlLabel
            control={<Checkbox checked={filterDialog.statuses.includes(title)} onChange={() => toggleFilterOption(title)} name={title} color="primary" />}
            label={title}
            key={title}
        />
    )

    if (isMobileDevice) {
        return (
            <>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        { name: 'Members' },
                    ]}
                    rightButtons={[
                        {
                            name: 'Search',
                            tooltip: 'Search',
                            type: 'icon',
                            innerIcon: <SearchIcon />,
                            toggleSearch: text => setCurSearch(text ? text.toLowerCase().replace(/[- )(]/g, '') : ''),
                        },
                        {
                            name: 'Filter',
                            tooltip: 'Filter',
                            type: 'icon',
                            innerIcon: <FilterIcon />,
                            onClick: () => setFilterDialog(dialog => ({ ...dialog, open: true })),
                        },
                    ]}
                />
                <Box
                    style={{
                        height: `calc(100vh - env(safe-area-inset-top) - 56px)`,
                        margin: -16,
                        marginBottom: 'min(calc(0px - env(safe-area-inset-bottom)), -16px)',
                    }}
                >
                    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Box style={{ marginTop: 8, borderBottom: theme.border }}>{renderTabs()}</Box>
                        <Box style={{ flexGrow: 1 }} key={`${sortingType}.${curSearch}.${JSON.stringify(sortedMemberTypes)}`}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        ref={listRef}
                                        height={height}
                                        itemCount={membersSorted.length}
                                        itemSize={i =>
                                            membersSorted[i].type === 'HEADER' ? (i === 0 ? 32 : 24) : membersSorted[i].showBorder === true ? 63 : 62
                                        }
                                        estimatedItemSize={i =>
                                            membersSorted[i].type === 'HEADER' ? (i === 0 ? 32 : 24) : membersSorted[i].showBorder === true ? 63 : 62
                                        }
                                        width={width}
                                        overscanCount={6}
                                        itemKey={(i, data) =>
                                            membersSorted[i].type === 'HEADER' ? membersSorted[i].value : `${sortingType}.${membersSorted[i].value.id}`
                                        }
                                        itemData={membersSorted}
                                        className={classes.noScrollbar}
                                    >
                                        {ContactRow}
                                    </List>
                                )}
                            </AutoSizer>
                        </Box>
                        {selection.active && (
                            <Box
                                style={{
                                    borderTop: theme.border,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingBottom: 'env(safe-area-inset-bottom)',
                                }}
                            >
                                <Box style={{ marginLeft: 4 }}>
                                    <IconButton
                                        style={{ padding: 8 }}
                                        onClick={() => {
                                            if (selection.selection.length < memsByStatus.length) {
                                                let memIDs = []
                                                for (let i = 0; i < memsByStatus.length; i++) {
                                                    memIDs.push(memsByStatus[i].id)
                                                }
                                                setSelection({ active: true, selection: memIDs })
                                            } else {
                                                setSelection({ active: true, selection: [] })
                                            }
                                        }}
                                    >
                                        {selection.selection.length === 0 ? (
                                            <RadioEmptyIcon />
                                        ) : selection.selection.length < memsByStatus.length ? (
                                            <RadioPartialSelectedIcon />
                                        ) : (
                                            <RadioSelectedIcon color="primary" />
                                        )}
                                    </IconButton>
                                </Box>
                                <Typography style={{ marginLeft: 4, flexGrow: 1, fontWeight: 'bold' }}>
                                    {selection.selection.length === 1 ? '1 Contact' : '' + selection.selection.length + ' Contacts'}
                                </Typography>
                                <Box style={{ display: 'flex', flexDirection: 'row', gap: 8, padding: 8 }}>
                                    <Button color="primary" onClick={() => setSelection({ active: false, selection: [] })}>
                                        Cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            if (selection.selection.length > 0) {
                                                actuallyDownloadContacts(selection.selection)
                                            }
                                        }}
                                        disabled={selection.selection.length === 0}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
                <DialogMobile maxWidth="md" open={filterDialog.open} onClose={closeFilterDialog} aria-labelledby="filter-members-title">
                    <DialogTitleMobile id="filter-members-title" onClose={closeFilterDialog}>
                        Filter
                    </DialogTitleMobile>
                    <DialogContentMobile dividers style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography gutterBottom align="center" variant="h6" style={{ fontWeight: 'bold' }}>
                            Status
                        </Typography>
                        <FormGroup>{visibilityOptions.map(filter => renderFilterOption(filter))}</FormGroup>
                    </DialogContentMobile>
                    <DialogActionsMobile>
                        <Button onClick={closeFilterDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={saveFilterDialog} color="primary" disabled={filterDialog.statuses.length === 0}>
                            Save
                        </Button>
                    </DialogActionsMobile>
                </DialogMobile>
            </>
        )
    }

    return (
        <>
            <Grid container spacing={2}>
                {selection.active ? (
                    <Grid item xs={12}>
                        <Widget disableWidgetMenu inheritHeight bodyClass={classes.navPadding}>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center" wrap={'nowrap'}>
                                <Box display="flex" alignItems="center" className={classes.navTitle}>
                                    <Checkbox
                                        indeterminate={selection.selection.length > 0 && selection.selection.length < memsByStatus.length}
                                        checked={selection.selection.length === memsByStatus.length}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                let memIDs = []
                                                for (let i = 0; i < memsByStatus.length; i++) {
                                                    memIDs.push(members[i].id)
                                                }
                                                setSelection({ active: true, selection: memIDs })
                                            } else {
                                                setSelection({ active: true, selection: [] })
                                            }
                                        }}
                                        style={{ padding: 2 }}
                                    />
                                    <Typography variant="h4">
                                        {selection.selection.length === 1 ? '1 Contact' : '' + selection.selection.length + ' Contacts'}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Tooltip title="Download Contact(s)">
                                        <span>
                                            <IconButton
                                                aria-label="Download Contacts"
                                                disabled={selection.selection.length === 0}
                                                className={classes.navButtonIcon}
                                                onClick={() => {
                                                    if (selection.selection.length > 0) {
                                                        downloadContacts(selection.selection)
                                                    }
                                                }}
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title="Cancel">
                                        <IconButton
                                            aria-label="Cancel"
                                            className={classes.navButtonIcon}
                                            onClick={() => {
                                                setSelection({ active: false, selection: [] })
                                            }}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Widget>
                    </Grid>
                ) : (
                    <NavigationBar
                        titles={[
                            {
                                name: 'My House',
                                link: '/app/dashboard/',
                                icon: <HomeIcon />,
                            },
                            { name: 'Members' },
                        ]}
                        rightButtons={[
                            {
                                name: 'Select',
                                tooltip: 'Download Contacts',
                                type: 'icon',
                                innerIcon: <DownloadIcon />,
                                onClick: () => {
                                    setCurSearch('')
                                    setSelection({ active: !selection.active, selection: [] })

                                    if (listRef && listRef.current) {
                                        listRef.current.resetAfterIndex(0)
                                    }
                                },
                            },
                        ]}
                        onSearch={text => {
                            setCurSearch(text ? text.toLowerCase().replace(/[- )(]/g, '') : '')

                            if (listRef && listRef.current) {
                                listRef.current.resetAfterIndex(0)
                            }
                        }}
                        grid
                    />
                )}
                <Grid item xs={12}>
                    <Box
                        style={{
                            maxWidth: 480,
                            margin: `0 auto`,
                        }}
                    >
                        <Widget disableWidgetMenu inheritHeight bodyClass={classes.tabSection}>
                            {renderTabs()}
                        </Widget>
                    </Box>
                </Grid>
                <Grid item xs={12} style={{ height: `calc(100vh - 194px)` }} key={sortingType}>
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                ref={listRef}
                                height={height}
                                itemCount={membersSorted.length}
                                itemSize={i => (membersSorted[i].type === 'HEADER' ? 24 : 79)}
                                estimatedItemSize={i => (membersSorted[i].type === 'HEADER' ? 24 : 79)}
                                width={width}
                                overscanCount={6}
                                itemKey={(i, data) =>
                                    membersSorted[i].type === 'HEADER' ? membersSorted[i].value : `${sortingType}.${membersSorted[i].value.id}`
                                }
                                itemData={membersSorted}
                                className={classes.noScrollbar}
                            >
                                {ContactRow}
                            </List>
                        )}
                    </AutoSizer>
                </Grid>
            </Grid>
        </>
    )
}
