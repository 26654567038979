import React from 'react'

import { Box, ButtonBase, Divider, IconButton } from '@material-ui/core'
import Widget from '../../Widget'
import { Avatar, Typography } from '../../Wrappers'

// styles
import useStyles from './styles'

export default function TinyCard(props) {
    var classes = useStyles()

    const { onClick, text, description, centered, author, buttons, variant, ...buttonProps } = props

    if (variant === 'outlined') {
        return (
            <Box className={classes.outlined}>
                <ButtonBase
                    {...buttonProps}
                    onClick={onClick}
                    className={classes.button}
                    style={{ ...buttonProps?.style, borderTopLeftRadius: 3, borderTopRightRadius: 3 }}
                >
                    <Box display="flex" flexDirection="column" className={classes.sectionInner}>
                        {centered ? (
                            <Typography variant="body1" style={{ margin: `0 auto`, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {text}
                            </Typography>
                        ) : (
                            <Typography variant="body1" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {text}
                            </Typography>
                        )}
                        {description && typeof description !== 'string' && description}
                        {description && typeof description === 'string' && centered && (
                            <Typography variant="caption" style={{ margin: `0 auto`, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {description}
                            </Typography>
                        )}
                        {description && typeof description === 'string' && !centered && (
                            <Typography variant="caption" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {description}
                            </Typography>
                        )}
                    </Box>
                </ButtonBase>
                {(author || (buttons && buttons.length > 0)) && (
                    <>
                        <Divider />
                        <Box
                            display="flex"
                            flexDirection="row"
                            style={{
                                marginLeft: 9,
                                marginRight: 9,
                                marginTop: 3,
                                marginBottom: 3,
                                flexGrow: 1,
                            }}
                        >
                            {author ? (
                                <Box style={{ margin: 3, display: 'flex', flexGrow: 1 }}>
                                    {author.photo && <Avatar src={author.photo} style={{ marginRight: 6, width: 24, height: 24 }} />}
                                    {author.icon && <>{author.icon}</>}
                                    <Typography>{author.first + ' ' + author.last}</Typography>
                                </Box>
                            ) : (
                                <Box style={{ margin: 3, display: 'flex', flexGrow: 1 }}></Box>
                            )}
                            {buttons &&
                                buttons.map(button => (
                                    <IconButton style={{ padding: 3 }} onClick={button.onClick} key={button.id}>
                                        {button.icon}
                                    </IconButton>
                                ))}
                        </Box>
                    </>
                )}
            </Box>
        )
    }

    return (
        <Widget disableWidgetMenu noBodyPadding className={classes.sectionWidget} bodyClass={onClick && !author && !buttons ? classes.sectionWidgetBody : ''}>
            <Box className={onClick && (author || buttons) ? classes.sectionWidgetBodyComplex : ''}>
                <Box className={onClick && (author || buttons) ? classes.sectionWidgetBodyInnerComplex : ''}>
                    <ButtonBase {...buttonProps} onClick={onClick} className={classes.button}>
                        <Box display="flex" flexDirection="column" className={classes.sectionInner}>
                            {centered ? (
                                <Typography variant="body1" style={{ margin: `0 auto`, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {text}
                                </Typography>
                            ) : (
                                <Typography variant="body1" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {text}
                                </Typography>
                            )}
                            {description && typeof description !== 'string' && description}
                            {description && typeof description === 'string' && centered && (
                                <Typography variant="caption" style={{ margin: `0 auto`, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {description}
                                </Typography>
                            )}
                            {description && typeof description === 'string' && !centered && (
                                <Typography variant="caption" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {description}
                                </Typography>
                            )}
                        </Box>
                    </ButtonBase>
                </Box>
            </Box>
            {(author || (buttons && buttons.length > 0)) && (
                <>
                    <Divider className={classes.divider} />
                    <Box
                        display="flex"
                        flexDirection="row"
                        style={{
                            marginLeft: 9,
                            marginRight: 9,
                            marginTop: 3,
                            marginBottom: 3,
                            flexGrow: 1,
                        }}
                    >
                        {author ? (
                            <Box style={{ margin: 3, display: 'flex', flexGrow: 1 }}>
                                {author.photo && <Avatar src={author.photo} style={{ marginRight: 6, width: 24, height: 24 }} />}
                                {author.icon && <>{author.icon}</>}
                                <Typography>{author.first + ' ' + author.last}</Typography>
                            </Box>
                        ) : (
                            <Box style={{ margin: 3, display: 'flex', flexGrow: 1 }}></Box>
                        )}
                        {buttons &&
                            buttons.map(button => (
                                <IconButton style={{ padding: 3 }} onClick={button.onClick} key={button.id}>
                                    {button.icon}
                                </IconButton>
                            ))}
                    </Box>
                </>
            )}
        </Widget>
    )
}
