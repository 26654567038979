import app from 'firebase/app'
import React from 'react'

import { Box, Divider, FormControl, Grid, IconButton, Select, Switch, Tooltip, Typography } from '@material-ui/core'
import {
    Add as AddIcon,
    DeleteOutlined as DeleteIcon,
    InfoOutlined as InfoIcon,
    NotificationsOffOutlined as NotificationsOffIcon,
    VisibilityOutlined as VisibilityIcon,
} from '@material-ui/icons'

import { CrownAddOutlineIcon, CrownOutlineIcon, ModeratorAddOutlineIcon, ModeratorOutlineIcon, ModeratorRemoveOutlineIcon } from 'components/Icons'
import SelectAutocomplete from 'components/SelectAutocomplete'
import { Button, Link } from 'components/Wrappers'

import ConversationAvatar from '../Conversation/ConversationAvatar'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'
import { MessagesContext } from 'context/MessagesContext'

import { getPossibleMembers } from 'code/Helper'

import { renderVisibility } from 'data/Visibility'

import { Messages } from 'objects/Messages'

import { compileMembers, expandMembers } from 'data/Members'

import cn from 'classnames'

import useStyles from 'pages/messages/styles'

const ChatInfo = ({ conversationId, version, history }) => {
    const classes = useStyles()

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const {
        addMembersToConversation,
        getConversation,
        deleteConversation,
        markConversationAsMuted,
        removeMemberFromConversation,
        updateConversation,
    } = React.useContext(MessagesContext)

    function isUserMessagesAdmin() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || (chapter.perms['messagesAdmin'] && chapter.perms['messagesAdmin'].includes(mem.role))
            }
        }

        return false
    }

    let conversation = getConversation(conversationId)

    const getPossibleMems = React.useMemo(
        () => getPossibleMembers(chapter.members, [...(conversation && conversation.mType === 1 ? conversation.mems : []), user.getId()]),
        [conversation, chapter, user],
    )

    const [addedMems, setAddedMems] = React.useState(null)

    if (!conversation) {
        return <></>
    }

    let isMuted = Array.isArray(conversation.muted) && conversation.muted.includes(user.getId())

    function renderAllMemberSections() {
        let owner = getMembers('owner')
        let admins = getMembers('admin')
        let members = getMembers('member')

        return (
            <Box>
                {owner.length > 0 && (
                    <Box style={{ marginTop: 12 }}>
                        <Typography align="center" style={{ fontWeight: 500 }}>
                            Owner
                        </Typography>
                        <Box style={{ marginTop: 12 }}>
                            <Grid container spacing={1} className={classes.membersGrid}>
                                {owner}
                            </Grid>
                        </Box>
                    </Box>
                )}
                {admins.length > 0 && (
                    <Box style={{ marginTop: 12 }}>
                        <Typography align="center" style={{ fontWeight: 500 }}>
                            Admins
                        </Typography>
                        <Box style={{ marginTop: 12 }}>
                            <Grid container spacing={1} className={classes.membersGrid}>
                                {admins}
                            </Grid>
                        </Box>
                    </Box>
                )}
                {members.length > 0 && (
                    <Box style={{ marginTop: 12 }}>
                        <Typography align="center" style={{ fontWeight: 500 }}>
                            Members
                        </Typography>
                        <Box style={{ marginTop: 12 }}>
                            <Grid container spacing={1} className={classes.membersGrid}>
                                {members}
                            </Grid>
                        </Box>
                    </Box>
                )}
            </Box>
        )
    }

    const getMembers = (status = 'all') => {
        if (conversation) {
            let hasAdmins = Array.isArray(conversation.admins)
            let isAdmin = hasAdmins && conversation.admins.includes(user.getId())
            let isOwner = conversation.owner === user.getId()

            let memObjs = []
            let members = []

            if (conversation.mType === 0) {
                let memIds = Object.keys(chapter.members).filter(memId => conversation.vis.includes(chapter.members[memId].status))
                memObjs = memIds.map(memId => {
                    return { ...chapter.members[memId], id: memId }
                })
            } else {
                for (let i = 0; i < conversation.mems.length; i++) {
                    let mem = conversation.mems[i]

                    if (mem in chapter.members) {
                        memObjs.push({ ...chapter.members[mem], id: mem })
                    }
                }
            }

            memObjs = memObjs.map(mem => {
                let isOwner = conversation.owner === mem.id
                let isAdmin = hasAdmins && conversation.admins.includes(mem.id)

                let userStatus = isOwner ? 'owner' : isAdmin ? 'admin' : 'member'

                return { ...mem, status: userStatus }
            })

            if (status !== 'all') {
                if (status === 'member') {
                    memObjs = memObjs.filter(mem => mem.status === 'member')
                } else if (status === 'admin' && hasAdmins) {
                    memObjs = memObjs.filter(mem => mem.status === 'admin')
                } else if (status === 'owner') {
                    memObjs = memObjs.filter(mem => mem.status === 'owner')
                }
            }

            for (let i = 0; i < memObjs.length; i++) {
                let mem = memObjs[i]

                let fullSize = version === 'mobile' || (i === memObjs.length - 1 && i % 2 === 0)
                let lastRow = i === memObjs.length - 1 || (i === memObjs.length - 2 && memObjs.length % 2 === 0 && !fullSize)
                let firstRow = i === 0 || (i === 1 && !fullSize)

                let memberHasMuted = Array.isArray(conversation.muted) && conversation.muted.includes(mem.id)

                members.push(
                    <Grid
                        item
                        xs={fullSize ? 12 : 6}
                        className={cn(fullSize ? classes.membersGridItemLast : classes.membersGridItem, {
                            [classes.membersGridItemBottom]: !lastRow,
                            [classes.membersGridItemTop]: !firstRow,
                        })}
                    >
                        <Box style={{ display: 'flex', flexDirection: 'row', paddingLeft: 12 }}>
                            <Box style={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
                                <Typography style={{ lineHeight: '30px' }}>
                                    {mem.first} {mem.last}
                                </Typography>
                                {mem.status === 'owner' && (
                                    <Box style={{ padding: 3, height: 30 }}>
                                        <CrownOutlineIcon />
                                    </Box>
                                )}
                                {mem.status === 'admin' && (
                                    <Box style={{ padding: 3, height: 30 }}>
                                        <ModeratorOutlineIcon />
                                    </Box>
                                )}
                                {memberHasMuted && (
                                    <Box style={{ padding: 3, height: 30 }}>
                                        <NotificationsOffIcon aria-label="muted" />
                                    </Box>
                                )}
                            </Box>
                            {isOwner && mem.status === 'admin' && (
                                <Tooltip title="Promote to Owner">
                                    <IconButton
                                        aria-label="promote to owner"
                                        size="small"
                                        onClick={() => {
                                            updateConversation(conversationId, { admins: app.firestore.FieldValue.arrayRemove(mem.id) })
                                            updateConversation(conversationId, { admins: app.firestore.FieldValue.arrayUnion(user.getId()), owner: mem.id })
                                        }}
                                    >
                                        <CrownAddOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {(isAdmin || isOwner) && mem.status === 'member' && (
                                <Tooltip title="Promote to Admin">
                                    <IconButton
                                        aria-label="promote to admin"
                                        size="small"
                                        onClick={() => updateConversation(conversationId, { admins: app.firestore.FieldValue.arrayUnion(mem.id) })}
                                    >
                                        <ModeratorAddOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {isOwner && mem.status === 'admin' && (
                                <Tooltip title="Demote to Member">
                                    <IconButton
                                        aria-label="demote to member"
                                        size="small"
                                        onClick={() => updateConversation(conversationId, { admins: app.firestore.FieldValue.arrayRemove(mem.id) })}
                                    >
                                        <ModeratorRemoveOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {(isAdmin || isOwner) && mem.status === 'member' && conversation.mType !== Messages.MEMBERSHIP_AUTOMATIC && (
                                <Tooltip title="Remove from Conversation">
                                    <IconButton
                                        aria-label="remove from conversation"
                                        className={classes.deleteIcon}
                                        size="small"
                                        onClick={() => removeMemberFromConversation(conversationId, mem.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title="View Profile">
                                <IconButton aria-label="view profile" size="small" onClick={() => history.push(`/app/profile/${mem.id}`)}>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Grid>,
                )
            }

            return members
        }

        return []
    }

    const render = (
        <Grid container spacing={1} style={{ marginBottom: 16 }}>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <ConversationAvatar avatar={conversation.avatar} channel={conversation.type === Messages.TYPE_CHANNEL} src={conversation.photo} size={72} />
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" variant="h5" style={{ fontWeight: 500 }}>
                    {conversation.name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 8 }}>
                    {conversation.owner === user.getId() ? (
                        <Button
                            onClick={() => {
                                deleteConversation(conversationId)
                                history.push('/app/messages')
                            }}
                            variant="outlined"
                            color="red"
                            style={{ margin: '0 6px' }}
                            disableElevation
                        >
                            Delete {conversation.type === 0 ? 'Chat' : 'Channel'}
                        </Button>
                    ) : (
                        <>
                            {conversation.mType !== Messages.MEMBERSHIP_AUTOMATIC && (
                                <Button
                                    onClick={() => removeMemberFromConversation(conversationId, user.getId())}
                                    variant="contained"
                                    color="red"
                                    style={{ margin: '0 6px' }}
                                    disableElevation
                                >
                                    Leave {conversation.type === 0 ? 'Chat' : 'Channel'}
                                </Button>
                            )}
                        </>
                    )}
                    {conversation.owner !== user.getId() && isUserMessagesAdmin() && (
                        <Button
                            onClick={() => {
                                console.log('deleting conversation')
                                deleteConversation(conversationId)
                                history.push('/app/messages')
                            }}
                            variant="outlined"
                            color="red"
                            style={{ margin: '0 6px' }}
                            disableElevation
                        >
                            Delete {conversation.type === 0 ? 'Chat' : 'Channel'}
                        </Button>
                    )}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Box className={classes.muteNotificationsBox}>
                    <Box className={classes.muteNotificationsText}>
                        <Typography>Mute Notifications</Typography>
                    </Box>
                    <Box className={classes.muteNotificationsSwitch}>
                        <Switch color="primary" checked={isMuted} onChange={() => markConversationAsMuted(conversationId, !isMuted)} />
                    </Box>
                </Box>
            </Grid>
            {conversation.owner === user.getId() && (
                <>
                    <Grid item xs={12}>
                        <Divider style={{ marginLeft: -16, marginRight: -16 }} />
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                        <Box className={classes.membersBox}>
                            <Typography align="center" variant="h6" style={{ fontWeight: 500 }}>
                                Settings
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: 4 }}>
                                <Typography id="messages-send-permission">Messages</Typography>
                            </Box>
                            <FormControl variant="outlined" margin="dense" style={{ marginTop: 4, marginLeft: 4, flexGrow: 1 }}>
                                <Select
                                    native
                                    aria-labelledBy="messages-send-permission"
                                    value={conversation.settings ? conversation.settings.sendPerms : 0}
                                    onChange={e => {
                                        updateConversation(conversationId, { ['settings.sendPerms']: parseInt(e.target.value) })
                                    }}
                                >
                                    <option value={Messages.SEND_PERMISSIONS_EVERYONE}>Everyone</option>
                                    <option value={Messages.SEND_PERMISSIONS_OWNER}>Owner only</option>
                                    <option value={Messages.SEND_PERMISSIONS_OWNER_ADMINS}>Owner and admins only</option>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: 4 }}>
                                <Typography id="reactions-permission">Reactions</Typography>
                            </Box>
                            <FormControl variant="outlined" margin="dense" style={{ marginTop: 4, marginLeft: 4, flexGrow: 1 }}>
                                <Select
                                    native
                                    aria-labelledBy="reactions-permission"
                                    value={
                                        conversation.settings && conversation.settings.reactionsPerms
                                            ? conversation.settings.reactionsPerms
                                            : Messages.REACTION_PERMISSIONS_ENABLED
                                    }
                                    onChange={e => {
                                        updateConversation(conversationId, { ['settings.reactionsPerms']: parseInt(e.target.value) })
                                    }}
                                >
                                    <option value={Messages.REACTION_PERMISSIONS_ENABLED}>Enabled</option>
                                    <option value={Messages.REACTION_PERMISSIONS_DISABLED}>Disabled</option>
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Divider style={{ marginLeft: -16, marginRight: -16 }} />
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Box className={classes.membersBox}>
                    <Typography align="center" variant="h6" style={{ fontWeight: 500 }}>
                        All Members
                    </Typography>
                    {conversation.mType === Messages.MEMBERSHIP_AUTOMATIC && (
                        <Typography align="center" variant="caption">
                            <VisibilityIcon style={{ width: '1rem', height: '1rem', verticalAlign: 'middle', paddingRight: '2px' }} />
                            {renderVisibility(conversation.vis)}
                        </Typography>
                    )}
                    {renderAllMemberSections()}
                    {conversation.mType !== Messages.MEMBERSHIP_AUTOMATIC && (
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 16 }}>
                            {conversation.event && conversation.event.name && conversation.event.id && (
                                <Typography align="center" variant="caption">
                                    Membership automatically managed by{' '}
                                    <Link
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => history.push(`/app/calendar/event/${conversation.event.id}`)}
                                        color="primary"
                                    >
                                        {conversation.event.name}
                                    </Link>{' '}
                                    {conversation.mType === 2 ? 'attendance' : 'rsvp'}
                                </Typography>
                            )}
                            {addedMems !== null && (
                                <Box>
                                    <SelectAutocomplete
                                        style={{ marginTop: 4 }}
                                        variant="outlined"
                                        value={expandMembers(addedMems, getPossibleMems)}
                                        onUpdate={(event, value) => {
                                            setAddedMems(compileMembers(value))
                                        }}
                                        title="Members"
                                        freeSolo={false}
                                        aria-label="Members"
                                        events={getPossibleMems}
                                    />
                                </Box>
                            )}
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 4 }}>
                                <Button
                                    startIcon={<AddIcon aria-label="Add" />}
                                    onClick={() => {
                                        if (addedMems === null) {
                                            setAddedMems([])
                                        } else {
                                            // Add the members & then set to null
                                            addMembersToConversation(conversationId, addedMems)
                                            setAddedMems(null)
                                        }
                                    }}
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    disabled={addedMems !== null && addedMems.length === 0}
                                >
                                    Members
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Grid>
        </Grid>
    )

    return render
}

export default React.memo(ChatInfo)
