import React from 'react'

import GCWidget from 'components/GCWidget'

import { MessagesContext } from 'context/MessagesContext'

const MessageGCWidget = ({ id, type, history, ...props }) => {
    let { getGCWidget, widgets } = React.useContext(MessagesContext)

    const getData = () => {
        if (type === 'polls') {
            return props.data
        }

        if (id in widgets[type]) {
            return widgets[type][id]
        }

        return null
    }

    let [data, setData] = React.useState(getData())

    React.useEffect(() => {
        if (type !== 'polls') {
            if (!(id in widgets[type])) {
                getGCWidget(type, id)
            } else {
                if (data !== widgets[type][id]) {
                    setData(widgets[type][id])
                }
            }
        }
    }, [widgets])

    return <GCWidget {...props} type={type} hideHeader loading={data === null} history={history} {...data} id={id} />
}

export default MessageGCWidget
