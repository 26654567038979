import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    paper: {
        paddingBottom: `env(safe-area-inset-bottom)`,
        borderTop: theme.border,
        boxShadow: 'none !important',
    },
    root: {
        padding: 0,
    },
    selected: {
        paddingTop: `0 !important`,
    },
}))
