import { Box, Paper, Typography } from '@material-ui/core'
import { DragHandle as DragIcon } from '@material-ui/icons'
import React from 'react'
import DraggableList from 'react-draggable-list'

import { getOrdinalSuffix } from 'code/Helper'

// styles
import useStyles from '../styles'

export default function Ranked(props) {
    var classes = useStyles()

    const { container, options, onChange, value } = props

    let sortedOptions = [...options]

    if (Array.isArray(value)) {
        sortedOptions.sort((a, b) => {
            if (value.includes(a.id)) {
                if (value.includes(b.id)) {
                    return value.indexOf(a.id) - value.indexOf(b.id)
                }
                return -1
            } else if (value.includes(b.id)) {
                return 1
            }

            return 0
        })
    }

    return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
                {options.map((option, index) => (
                    <Box
                        style={{
                            height: 40,
                            marginBottom: 10,
                            marginRight: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Typography className={classes.responseBlockAccessory}>{`${index + 1}${getOrdinalSuffix(index + 1)}`}</Typography>
                    </Box>
                ))}
            </Box>
            <Box style={{ flexGrow: 1 }}>
                <DraggableList
                    itemKey="id"
                    template={ListItem}
                    list={sortedOptions}
                    onMoveEnd={val => {
                        let vals = []

                        for (let i = 0; i < val.length; i++) {
                            vals.push(val[i].id)
                        }

                        onChange(vals)
                    }}
                    container={() => (container && container.current ? container.current : document.body)}
                />
            </Box>
        </Box>
    )
}

function ListItem(props) {
    const { dragHandleProps, item, itemSelected } = props
    return (
        <Paper variant="outlined" elevation={itemSelected ? 1 : 0}>
            <Box style={{ display: 'flex', flexDirection: 'row', margin: '0.5em' }}>
                <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center' }}>
                    <Typography>{item.text}</Typography>
                </Box>
                <DragIcon {...dragHandleProps} />
            </Box>
        </Paper>
    )
}
