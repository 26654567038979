import app from 'firebase/app'
import React from 'react'

import cn from 'classnames'
import _ from 'lodash'
import moment from 'moment'

import { Editor, Text, Transforms } from 'slate'

import { convertObjectToList } from 'code/Helper'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

import GCWidget from 'components/Messages/MessageGCWidget'

import Chat from 'components/Messages/Chat'

import { Messages } from 'objects/Messages'

import useStyles from 'pages/messages/styles'

export const MessagesContext = React.createContext()

export const MessagesProvider = ({ children, ...props }) => {
    let classes = useStyles()

    const { user } = React.useContext(AuthContext)
    const { chapter } = React.useContext(ChapterContext)

    const db = app.firestore()

    const [convosObj, setConvosObj] = React.useState({})

    const [currentlyViewingConversations, setCurrentlyViewingConversations] = React.useState(false)

    const [viewingConversation, setViewingConversation] = React.useState('')

    React.useEffect(() => {
        if (viewingConversation !== '' && user) {
            const unsubscribe = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('messages')
                .doc(viewingConversation)
                .onSnapshot(
                    function(doc) {
                        if (doc.exists) {
                            let data = doc.data()
                            setConvosObj(conversations => {
                                let convos = _.cloneDeep(conversations)
                                convos[doc.id] = new Messages(data)
                                return convos
                            })

                            if (Array.isArray(data.views) && !data.views.includes(user.getId())) {
                                markConversationAsViewed(doc.id)
                            }
                        } else {
                            setConvosObj(conversations => {
                                let convos = _.cloneDeep(conversations)
                                convos[doc.id] = { exists: false }
                                return convos
                            })
                        }
                    },
                    function(error) {
                        console.log('error getting conversation', error)
                    },
                )
            return () => {
                unsubscribe()
            }
        }
    }, [viewingConversation])

    React.useEffect(() => {
        if (currentlyViewingConversations) {
            const updateChanges = change => {
                const doc = change.doc
                const data = doc.data()

                if (change.type === 'added' || change.type === 'modified') {
                    setConvosObj(conversations => {
                        let convos = _.cloneDeep(conversations)
                        convos[doc.id] = new Messages(data)
                        return convos
                    })
                }

                if (change.type === 'removed') {
                    setConvosObj(conversations => {
                        let convos = _.cloneDeep(conversations)
                        delete convos[doc.id]
                        return convos
                    })
                }
            }

            const onSnapshot = snapshot => {
                snapshot.docChanges().forEach(updateChanges)
            }

            let automaticMembershipConversations = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('messages')
                .where('mType', '==', 0)
                .where(
                    'vis',
                    'array-contains',
                    user.getStatus() ? user.getStatus() : user.getId() in chapter.members ? chapter.members[user.getId()].status : 1 /*user status*/,
                )
                .orderBy('lu', 'desc')
                .limit(50)
                .onSnapshot(onSnapshot)

            let customMembershipConversations = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('messages')
                .where('mType', 'in', [1, 2, 3])
                .where('mems', 'array-contains', user.getId() /*user id*/)
                .orderBy('lu', 'desc')
                .limit(50)
                .onSnapshot(onSnapshot)

            return () => {
                automaticMembershipConversations()
                customMembershipConversations()
            }
        }
    }, [currentlyViewingConversations])

    const addMembersToConversation = async (convoId, memberIds) => {
        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc(convoId)

        await ref.update({ mems: app.firestore.FieldValue.arrayUnion(...memberIds) }).catch(function(error) {
            console.log('Error getting document:', error)
        })
    }

    const removeMemberFromConversation = async (convoId, memberId) => {
        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc(convoId)

        await ref.update({ mems: app.firestore.FieldValue.arrayRemove(memberId) }).catch(function(error) {
            console.log('Error getting document:', error)
        })
    }

    const markConversationAsViewed = async convoId => {
        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc(convoId)

        await ref.update({ views: app.firestore.FieldValue.arrayUnion(user.getId()) }).catch(function(error) {
            console.log('Error getting document:', error)
        })
    }

    const markConversationAsMuted = async (convoId, isMuted) => {
        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc(convoId)

        await ref
            .update({ muted: isMuted ? app.firestore.FieldValue.arrayUnion(user.getId()) : app.firestore.FieldValue.arrayRemove(user.getId()) })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    const removeReactionToMessage = async (convoId, messageId) => {
        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc(convoId)

        await ref.update({ [`messages.${messageId}.reactions.${user.getId()}`]: app.firestore.FieldValue.delete() }).catch(function(error) {
            console.log('Error getting document:', error)
        })
    }

    const addReactionToMessage = async (convoId, messageId, reaction) => {
        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc(convoId)

        await ref.update({ [`messages.${messageId}.reactions.${user.getId()}`]: reaction }).catch(function(error) {
            console.log('Error getting document:', error)
        })
    }

    const addResponseToMessagePoll = async (convoId, messageId, pollId, response) => {
        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc(convoId)

        await ref.update({ [`messages.${messageId}.attachments.${pollId}.responses.${user.getId()}`]: response }).catch(function(error) {
            console.log('Error getting document:', error)
        })
    }

    const getResponsesToMessagePoll = (convoId, messageId, pollId) => {
        let convo = getConversation(convoId)

        if (convo) {
            let messages = convo._messages

            if (messages && messageId in messages) {
                let message = messages[messageId]

                let attachments = message.attachments

                if (attachments && pollId in attachments) {
                    let attachment = attachments[pollId]

                    if (attachment && 'responses' in attachment) {
                        return attachment.responses
                    }
                }
            }
        }

        return {}
    }

    const removeMessageFromConversation = async (convoId, messageId) => {
        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc(convoId)

        await ref
            .update({
                [`messages.${messageId}.deleted`]: true,
                [`messages.${messageId}.message`]: getDeletedMessage(),
                [`messages.${messageId}.reactions`]: app.firestore.FieldValue.delete(),
                [`messages.${messageId}.lu`]: new Date(),
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
            })
    }

    const addMessageToConversation = async (convoId, message) => {
        console.log('got message', message)
        let messageId = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc().id

        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc(convoId)

        await ref.update({ [`messages.${messageId}`]: message, lu: new Date(), views: [user.getId()] }).catch(function(error) {
            console.log('Error getting document:', error)
        })
    }

    const getDeletedMessage = () => [
        {
            type: 'paragraph',
            children: [{ text: 'This message has been deleted' }],
        },
    ]

    const blankParagraph = {
        type: 'paragraph',
        children: [{ text: '' }],
    }

    const compileNode = (editor, node, loc) => {
        let urlRegex = /(http[s]?:\/\/){0,1}(www\.)?[-a-z0-9+&@#/%?=~_|!:,.;]+\.[-a-z0-9+&@#/%?=~_|!:,.;]+[-a-z0-9+&@#/%=~_|]/gi

        if (Text.isText(node)) {
            let text = node.text
            var m
            while ((m = urlRegex.exec(text))) {
                let endIndex = urlRegex.lastIndex
                let startIndex = urlRegex.lastIndex - m[0].length

                let selection = {
                    anchor: { path: loc, offset: startIndex },
                    focus: { path: loc, offset: endIndex },
                }

                let href = !m[0].includes('http://') && !m[0].includes('https://') ? 'https://' + m[0] : m[0]

                Transforms.select(editor, selection)

                Editor.addMark(editor, 'link', href)
            }
        } else if (node.children) {
            node.children.forEach((child, index) => compileNode(editor, child, [...loc, index]))
        }
    }

    const compileMessage = (editor, val, attachments) => {
        val.forEach((node, index) => {
            compileNode(editor, node, [index])
        })

        const compiledChildren = []

        for (let i = 0; i < editor.children.length; i++) {
            const child = editor.children[i]
            if (!_.isEqual(child, blankParagraph)) {
                compiledChildren.push(child)
            }
        }
        const isBlank = compiledChildren.length === 0

        if (isBlank && attachments.length === 0) {
            return null
        }

        let compiledMessage = isBlank ? [...attachments] : [...attachments, ...compiledChildren]

        let data = {
            message: compiledMessage,
            sender: {
                photo: user.photoURL ? user.photoURL : '',
                first: user.first,
                last: user.last,
                id: user.id,
            },
            cd: new Date(),
            reactions: {},
        }

        let foundPoll = false
        for (let i = 0; i < attachments.length; i++) {
            let attachment = attachments[i]

            if (attachment.type === 'polls') {
                if (foundPoll) {
                    data.attachments[attachment.data.id] = { responses: {} }
                } else {
                    foundPoll = true
                    data.attachments = { [attachment.data.id]: { responses: {} } }
                }
            }
        }

        return data
    }

    const deleteConversation = async convoId => {
        if (convoId in convosObj) {
            await db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('messages')
                .doc(convoId)
                .delete()
        }
    }

    const createConversation = async conversation => {
        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc()

        await ref.set(conversation).catch(function(error) {
            console.log('Error getting document:', error)
        })

        return ref.id
    }

    const updateConversation = async (convoId, updates) => {
        let ref = db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('messages')
            .doc(convoId)

        await ref.update(updates).catch(function(error) {
            console.log('Error getting document:', error)
        })
    }

    const initialWidgetState = { events: {}, polls: {} }

    const getWidgetFromFirestore = async (type, gcId) => {
        let failed = false

        const doc = await db
            .collection('chapters')
            .doc(user.getChapter())
            .collection(type)
            .doc(gcId)
            .get()
            .catch(e => {
                failed = true
            })

        if (!failed && doc.exists) {
            const data = doc.data()

            setWidgets(widgets => {
                return { ...widgets, [type]: { ...widgets[type], [gcId]: data } }
            })
        } else {
            setWidgets(widgets => {
                return { ...widgets, [type]: { ...widgets[type], [gcId]: { exists: false } } }
            })
        }
    }

    const usePrevious = value => {
        const ref = React.useRef(initialWidgetState)
        React.useEffect(() => {
            ref.current = value
        })
        return ref.current
    }

    const [widgets, setWidgets] = React.useState(initialWidgetState) ///React.useReducer(reduceWidgets, initialWidgetState)
    const prevWidgets = usePrevious(widgets)

    React.useEffect(() => {
        let types = Object.keys(widgets)

        for (let i = 0; i < types.length; i++) {
            let type = types[i]

            let beforeKeys = Object.keys(prevWidgets[type])
            let afterKeys = Object.keys(widgets[type])
            let additions = afterKeys.filter(x => !beforeKeys.includes(x))

            for (let j = 0; j < additions.length; j++) {
                let addition = additions[j]

                if (widgets[type][addition].loading === true) {
                    getWidgetFromFirestore(type, addition)
                }
            }
        }
    }, [prevWidgets, widgets])

    const renderElement = React.useCallback(props => <Element {...props} />, [])
    const renderLeaf = React.useCallback(props => <Leaf {...props} />, [])

    const Leaf = ({ attributes, children, leaf }) => {
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.code) {
            children = <code>{children}</code>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underline) {
            children = <u>{children}</u>
        }

        if (leaf.link) {
            children = (
                <a href={leaf.link} className={classes.messageLink}>
                    {children}
                </a>
            )
        }

        return <span {...attributes}>{children}</span>
    }

    const Element = elProps => {
        if (elProps.element.type === 'image') {
            return (
                <div {...elProps.attributes} contentEditable={false}>
                    <div className={classes.elementImgParent}>
                        <img
                            src={elProps.element.src}
                            className={classes.elementImg}
                            onClick={elProps.onClickImage ? () => elProps.onClickImage(elProps.element.src) : null}
                        />
                    </div>
                </div>
            )
        }

        if (elProps.element.type === 'link') {
            return (
                <div {...elProps.attributes} contentEditable={false}>
                    <div>
                        <a href={elProps.url} />
                    </div>
                </div>
            )
        }

        if (elProps.element.type === 'file') {
            return <div {...elProps.attributes} contentEditable={false}></div>
        }

        if (elProps.element.type === 'events') {
            return (
                <div {...elProps.attributes} contentEditable={false}>
                    <div className={cn(classes.elementGCBox, { [classes.elementGCBoxPreview]: elProps.preview === true })}>
                        <GCWidget
                            id={elProps.element.id}
                            type="events"
                            isDifferentTimezone={false}
                            shrunk={elProps.preview === true}
                            history={elProps.history}
                        />
                    </div>
                </div>
            )
        }

        if (elProps.element.type === 'polls') {
            let attachmentData =
                elProps.attachmentsData && elProps.element.data.id in elProps.attachmentsData ? elProps.attachmentsData[elProps.element.data.id] : {}

            return (
                <div {...elProps.attributes} contentEditable={false}>
                    <div className={cn(classes.elementGCBox, { [classes.elementGCBoxPreview]: elProps.preview === true })}>
                        <GCWidget
                            id={elProps.element.id}
                            type="polls"
                            isDifferentTimezone={false}
                            shrunk={elProps.preview === true}
                            history={elProps.history}
                            data={{ ...elProps.element.data, ...attachmentData }}
                            onClick={elProps.onClickPoll ? () => elProps.onClickPoll({ ...elProps.element.data, ...attachmentData }) : null}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div {...elProps.attributes} style={{ fontSize: '1rem' }}>
                {elProps.children}
            </div>
        )
    }

    const clearAllMarks = editor => {
        const marks = Editor.marks(editor)
        if (marks) {
            let markKeys = Object.keys(marks)

            for (let i = 0; i < markKeys.length; i++) {
                Editor.removeMark(editor, markKeys[i])
            }
        }
    }

    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }

    const getGCWidget = async (type, gcId) => {
        if (!(gcId in widgets[type])) {
            setWidgets(widgets => {
                return { ...widgets, [type]: { ...widgets[type], [gcId]: { loading: true } } }
            })

            /*let failed = false

            const doc = await db
                .collection('chapters')
                .doc(user.getChapter())
                .collection(type)
                .doc(gcId)
                .get()
                .catch(e => {
                    failed = true
                })

            if (doc.exists && !failed) {
                const data = doc.data()

                console.log('retrieved')

                dispatchWidgets({ action: 'retrieved', type: type, id: gcId, data: data })
            } else {
                dispatchWidgets({ action: 'retrieved', type: type, id: gcId, data: { exists: false } })
            }*/
        }
    }

    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }

    const renderChat = React.useCallback((conversationId, history, messagesObj) => {
        let chats = []

        let messages = convertObjectToList(messagesObj)

        let prevAuthor = ''
        let prevDate = moment(new Date(0))

        for (let i = 0; i < messages.length; i++) {
            let curMessage = messages[i]
            let curSender = curMessage.sender

            let curDate =
                curMessage.cd.toDate !== undefined
                    ? moment(curMessage.cd.toDate())
                    : curMessage.cd.seconds || curMessage.cd._seconds
                    ? moment(new Date().setUTCSeconds(curMessage.cd.seconds ? curMessage.cd.seconds : curMessage.cd._seconds))
                    : moment(curMessage.cd)

            let showTimestamp = !curDate.isSame(prevDate, 'day')

            let showAuthor = showTimestamp || moment.duration(curDate.diff(prevDate)).as('hours') >= 1 || curSender.id !== prevAuthor

            chats.push({
                ...curMessage,
                showAuthor: showAuthor,
                showTimestamp: showTimestamp,
            })

            prevAuthor = curSender.id
            prevDate = curDate
        }

        return <Chat conversationId={conversationId} messages={chats} history={history} />
    }, [])

    const getConversation = convoId => {
        if (convoId in convosObj) {
            return convosObj[convoId]
        }

        return null
    }

    const getConversationName = convoId => {
        if (convoId in convosObj && convosObj[convoId].name) {
            let convo = convosObj[convoId]

            if (convo.avatar.type === 0 && convo.avatar.emoji) {
                return `${convo.avatar.emoji} ${convo.name}`
            }

            return convo.name
        }

        return ''
    }

    const getAllConversations = () => {
        let convos = { ...convosObj }
        let convoIds = Object.keys(convos)

        for (let i = 0; i < convoIds.length; i++) {
            if (convos[convoIds[i]].exists === false) {
                delete convos[convoIds[i]]
            }
        }

        return convos
    }

    const canSendMessage = (conversationId, userId) => {
        let conversation = getConversation(conversationId)

        if (!conversation || !conversation.settings || !conversation.settings.sendPerms) {
            return true
        }

        if (conversation.settings.sendPerms === Messages.SEND_PERMISSIONS_EVERYONE) {
            return true
        }

        let isAdmin = Array.isArray(conversation.admins) && conversation.admins.includes(userId)
        let isOwner = conversation.owner === userId

        if (conversation.settings.sendPerms === Messages.SEND_PERMISSIONS_OWNER_ADMINS && (isOwner || isAdmin)) {
            return true
        }

        if (conversation.settings.sendPerms === Messages.SEND_PERMISSIONS_OWNER && isOwner) {
            return true
        }

        return false
    }

    const getBlankMessage = () => [blankParagraph]

    return (
        <MessagesContext.Provider
            value={{
                Element,
                Leaf,
                clearAllMarks,
                toggleMark,
                isMarkActive,
                renderChat,
                renderElement,
                renderLeaf,
                getGCWidget,
                widgets,
                getBlankMessage,
                setCurrentlyViewingConversations,
                /* Conversation */
                addResponseToMessagePoll,
                getResponsesToMessagePoll,
                addMembersToConversation,
                addReactionToMessage,
                canSendMessage,
                removeMemberFromConversation,
                removeMessageFromConversation,
                removeReactionToMessage,
                deleteConversation,
                markConversationAsMuted,
                addMessageToConversation,
                compileMessage,
                createConversation,
                updateConversation,
                getAllConversations,
                getConversation,
                getConversationName,
                markConversationAsViewed,
                /* Viewing Conversation Updates */
                viewingConversation,
                setViewingConversation,
            }}
        >
            {children}
        </MessagesContext.Provider>
    )
}
