import React from 'react'
import { Box, ButtonBase } from '@material-ui/core'
import { ExpandMore as ExpandShownIcon, ExpandLess as ExpandHiddenIcon } from '@material-ui/icons'

import { Typography } from 'components/Wrappers'

import cn from 'classnames'

import moment from 'moment'

import useStyles from './styles'

const Timeline = ({ orientation, timeline, showDays, togglable, ...props }) => {
    const classes = useStyles()

    const [expanded, setExpanded] = React.useState(togglable ? false : true)

    const timelineArr = []

    let sortedTimeline = timeline.sort((a, b) => {
        let aVal = typeof a.time.sd === 'string' ? a.time.sd : a.time.sd.val
        let bVal = typeof b.time.sd === 'string' ? b.time.sd : b.time.sd.val

        if (aVal !== bVal) {
            return aVal.localeCompare(bVal)
        }
        return a.time.st - b.time.st
    })

    let prevDate = moment('1970-01-01')

    const length = sortedTimeline.length > 1 && !expanded ? 1 : sortedTimeline.length

    for (let i = 0; i < length; i++) {
        let t = sortedTimeline[i]

        if (t) {
            let date = moment(typeof t.time.sd === 'string' ? t.time.sd : t.time.sd.val)

            let needsNewDate = !prevDate.isSame(date, 'day')

            if (needsNewDate && showDays) {
                timelineArr.push(
                    <TimelineLabel
                        key={date.format('YYYY-MM-DD')}
                        label={date.format('dddd')}
                        before={timelineArr.length > 0}
                        after
                        orientation={orientation}
                    />,
                )
            }

            timelineArr.push(<TimelineSection {...t} key={t.id} before={showDays || i > 0} after={i < timeline.length - 1} orientation={orientation} />)

            prevDate = date
        }
    }

    return (
        <Box
            className={cn(classes.parentBox, {
                [classes.parentBoxHorizontal]: orientation !== 'vertical',
                [classes.parentBoxVertical]: orientation === 'vertical',
            })}
        >
            {timelineArr}
            {togglable && sortedTimeline.length > 1 && (
                <Box style={{ display: 'flex', marginTop: -16, flexDirection: 'column' }}>
                    <Box className={classes.gradient} />
                    <ButtonBase
                        style={{
                            width: `100%`,
                        }}
                        onClick={() => {
                            setExpanded(!expanded)
                        }}
                    >
                        <Typography variant="h6">{expanded ? 'Show Less' : 'Show More'}</Typography>
                        {expanded ? <ExpandHiddenIcon /> : <ExpandShownIcon />}
                    </ButtonBase>
                </Box>
            )}
        </Box>
    )
}

const TIMES = { PAST: 'past', PRESENT: 'present', FUTURE: 'future' }

const TimelineLabel = ({ label, before, after, orientation, ...props }) => {
    const classes = useStyles()

    return (
        <Box
            className={cn(classes.sectionLineBox, {
                [classes.sectionLineBoxHorizontal]: orientation !== 'vertical',
                [classes.sectionLineBoxVertical]: orientation === 'vertical',
            })}
        >
            <Box
                className={cn(classes.sectionLabelLine, classes.lineBefore, {
                    [classes.hidden]: !before,
                    [classes.sectionLabelLineHorizontal]: orientation !== 'vertical',
                    [classes.sectionLabelLineVertical]: orientation === 'vertical',
                })}
            />
            <Typography weight="bold" className={classes.sectionLabelLabel}>
                {label}
            </Typography>
            <Box
                className={cn(classes.sectionLabelLine, classes.lineAfter, {
                    [classes.hidden]: !after,
                    [classes.sectionLabelLineHorizontal]: orientation !== 'vertical',
                    [classes.sectionLabelLineVertical]: orientation === 'vertical',
                })}
            />
        </Box>
    )
}

const TimelineSection = ({ time, label, before, after, endTime, orientation, ...props }) => {
    const classes = useStyles()

    let bubbleTimeType = TIMES.FUTURE

    let timeMoment = moment(time.sd.val ? time.sd.val : time.sd)

    if (time.st) {
        timeMoment.set({ hour: Math.floor(parseInt(time.st) / 60), minute: parseInt(time.st) % 60 })
    }

    let timeLabel = timeMoment.format('h:mm')

    if (time.ed) {
        let end = moment(time.ed.val ? time.ed.val : time.ed)
        if (time.et) {
            end.set({ hour: Math.floor(parseInt(time.et) / 60), minute: parseInt(time.et) % 60 })
        }

        if (!timeMoment.isSame(end, 'day')) {
            timeLabel += ` ${timeMoment.format('a')} - ${end.format('h:mm a dddd')}`
        } else if (Math.floor(timeMoment.hour() / 12) !== Math.floor(end.hour() / 12)) {
            timeLabel += ` ${timeMoment.format('a')} - ${end.format('h:mm a')}`
        } else {
            timeLabel += ` - ${end.format('h:mm a')}`
        }
    } else {
        timeLabel += ` ${timeMoment.format('a')}`
    }

    if (orientation === 'vertical') {
        return (
            <Box className={cn(classes.sectionBox, classes.sectionBoxVertical)}>
                <Typography className={cn(classes.sectionTime, classes.sectionTimeVertical)} align="right">
                    {timeLabel}
                </Typography>
                <Box className={cn(classes.sectionBubbleBox, classes.sectionBubbleBoxVertical)}>
                    <Box className={cn(classes.sectionBubbleLine, classes.sectionBubbleLineVertical, classes.lineBefore, { [classes.hiddenVis]: !before })} />
                    <Box
                        className={cn(classes.sectionBubble, classes.sectionBubbleVertical, {
                            [classes.sectionBubblePast]: bubbleTimeType === TIMES.PAST,
                            [classes.sectionBubblePresent]: bubbleTimeType === TIMES.PRESENT,
                        })}
                    />
                    <Box className={cn(classes.sectionBubbleLine, classes.sectionBubbleLineVertical, classes.lineAfter, { [classes.hiddenVis]: !after })} />
                </Box>
                <Typography className={cn(classes.sectionLabel, classes.sectionLabelVertical)}>{label}</Typography>
            </Box>
        )
    }

    return (
        <Box className={cn(classes.sectionBox, classes.sectionBoxHorizontal)}>
            <Typography className={cn(classes.sectionLabel, classes.sectionLabelHorizontal)}>{label}</Typography>
            <Box className={cn(classes.sectionBubbleBox, classes.sectionBubbleBoxHorizontal)}>
                <Box className={cn(classes.sectionBubbleLine, classes.lineBefore, { [classes.hiddenVis]: !before })} />
                <Box
                    className={cn(classes.sectionBubble, classes.sectionBubbleHorizontal, {
                        [classes.sectionBubblePast]: bubbleTimeType === TIMES.PAST,
                        [classes.sectionBubblePresent]: bubbleTimeType === TIMES.PRESENT,
                    })}
                />
                <Box className={cn(classes.sectionBubbleLine, classes.lineAfter, { [classes.hiddenVis]: !after })} />
            </Box>
            <Typography className={cn(classes.sectionTime, classes.sectionTimeHorizontal)}>{timeLabel}</Typography>
        </Box>
    )
}

export default React.memo(Timeline)
