import React, { useEffect, useState } from 'react'
import app from 'firebase/app'
import 'firebase/firestore'

import { ChapterContext } from './ChapterContext'
import { AuthContext } from './AuthContext'

export const FraternityContext = React.createContext()

export const FraternityProvider = props => {
    //TODO: Get user and if user exists and has chapter id, get chapter and store it in chapter cache...
    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)
    const children = props.children
    const db = app.firestore()

    let fraternityStorageItem = localStorage.getItem('cache_fraternity')
    let fraternityDateStorageItem = localStorage.getItem('cache_fraternityDate') ? new Date(localStorage.getItem('cache_fraternityDate')) : null
    const [fraternity, setFraternity] = useState(fraternityStorageItem ? JSON.parse(fraternityStorageItem) : null)

    function isCacheOld(cacheTimestamp) {
        let timeDiff = Math.floor((new Date() - cacheTimestamp) / 1000 / 60)
        return timeDiff > 5 || isNaN(timeDiff)
    }

    function setFraternityCache(fratData) {
        localStorage.setItem('cache_fraternity', JSON.stringify(fratData))
    }

    function requestFraternityUpdate() {
        if (user && chapter && chapter.fraternity) {
            db.collection('fraternities')
                .doc(chapter.fraternity)
                .get()
                .then(function(doc) {
                    if (doc.exists) {
                        var fraternityData = doc.data()
                        setFraternityCache(fraternityData)

                        setFraternity(fraternityData)

                        resetFraternityDate()
                    }
                })
                .catch(function(error) {
                    console.log('Error getting document:', error)
                })
        } else if (user && user.isHeadquartersStaff()) {
            db.collection('fraternities')
                .doc(user.fraternity)
                .get()
                .then(function(doc) {
                    if (doc.exists) {
                        var fraternityData = doc.data()
                        setFraternityCache(fraternityData)

                        setFraternity(fraternityData)
                    }
                })
                .catch(function(error) {
                    console.log('Error getting document:', error)
                })
        }
    }

    function resetFraternityDate() {
        fraternityDateStorageItem = new Date()
        localStorage.setItem('cache_fraternityDate', new Date().toString())
    }

    useEffect(() => {
        if (needsFraternityUpdate()) {
            requestFraternityUpdate()
        }
    })

    function needsFraternityUpdate() {
        return !fraternityDateStorageItem || !fraternity || isCacheOld(fraternityDateStorageItem)
    }

    return (
        <FraternityContext.Provider
            value={{
                fraternity,
                requestFraternityUpdate,
                needsFraternityUpdate,
            }}
        >
            {children}
        </FraternityContext.Provider>
    )
}
