export default {
    type: 'Feature',
    properties: { name: 'West' },
    geometry: {
        type: 'MultiPolygon',
        coordinates: [
            [
                [
                    [-124.733174, 48.163393],
                    [-124.731703, 48.160402],
                    [-124.721725, 48.153185],
                    [-124.695088, 48.114878],
                    [-124.687101, 48.098657],
                    [-124.688602, 48.092466],
                    [-124.695114, 48.087096],
                    [-124.696542, 48.069274],
                    [-124.693676, 48.058697],
                    [-124.688359, 48.054927],
                    [-124.685393, 48.049238],
                    [-124.682157, 48.035987],
                    [-124.679024, 48.015697],
                    [-124.67083, 47.982366],
                    [-124.672427, 47.964414],
                    [-124.662334, 47.951451],
                    [-124.651966, 47.943177],
                    [-124.645442, 47.935338],
                    [-124.629706, 47.896968],
                    [-124.630153, 47.892467],
                    [-124.625512, 47.887963],
                    [-124.610763, 47.880607],
                    [-124.609538, 47.879996],
                    [-124.588172, 47.877878],
                    [-124.562363, 47.866216],
                    [-124.559034, 47.863085],
                    [-124.558254, 47.855979],
                    [-124.539927, 47.836967],
                    [-124.51278, 47.822518],
                    [-124.50668, 47.82391],
                    [-124.497987, 47.822605],
                    [-124.489737, 47.816988],
                    [-124.482154, 47.797454],
                    [-124.47657, 47.769671],
                    [-124.471687, 47.766907],
                    [-124.453927, 47.765334],
                    [-124.430546, 47.746249],
                    [-124.425195, 47.738434],
                    [-124.420219, 47.725294],
                    [-124.412106, 47.691199],
                    [-124.395983, 47.665534],
                    [-124.382215, 47.632302],
                    [-124.374927, 47.603891],
                    [-124.371746, 47.599575],
                    [-124.366221, 47.582439],
                    [-124.359028, 47.547616],
                    [-124.355955, 47.545698],
                    [-124.353651, 47.53361],
                    [-124.345155, 47.48903],
                    [-124.336724, 47.415996],
                    [-124.32665, 47.388759],
                    [-124.324091, 47.367602],
                    [-124.319379, 47.355559],
                    [-124.307509, 47.352268],
                    [-124.299943, 47.34836],
                    [-124.293288, 47.339309],
                    [-124.286369, 47.325162],
                    [-124.271193, 47.305025],
                    [-124.257452, 47.304059],
                    [-124.25359, 47.30248],
                    [-124.242234, 47.295101],
                    [-124.236349, 47.287287],
                    [-124.209017, 47.218151],
                    [-124.195893, 47.174],
                    [-124.189725, 47.146827],
                    [-124.185806, 47.136017],
                    [-124.182802, 47.134041],
                    [-124.183833, 47.124807],
                    [-124.176745, 47.092999],
                    [-124.173501, 47.06637],
                    [-124.169113, 46.994508],
                    [-124.171161, 46.958443],
                    [-124.174503, 46.941623],
                    [-124.180111, 46.926357],
                    [-124.158624, 46.929439],
                    [-124.141267, 46.940266],
                    [-124.124386, 46.94387],
                    [-124.138035, 46.970959],
                    [-124.139733, 46.98837],
                    [-124.151288, 47.021112],
                    [-124.149043, 47.029294],
                    [-124.141517, 47.035142],
                    [-124.122057, 47.04165],
                    [-124.106378, 47.04264],
                    [-124.065856, 47.04114],
                    [-124.026345, 47.030187],
                    [-124.016999, 47.014848],
                    [-124.017035, 47.011717],
                    [-124.005248, 47.003915],
                    [-124.010068, 46.997882],
                    [-124.019727, 46.991189],
                    [-124.012218, 46.985176],
                    [-123.991612, 46.980215],
                    [-123.959185, 46.981759],
                    [-123.947996, 46.971818],
                    [-123.939214, 46.969739],
                    [-123.921617, 46.971864],
                    [-123.898245, 46.971927],
                    [-123.889402, 46.968904],
                    [-123.876136, 46.961054],
                    [-123.86018, 46.948556],
                    [-123.882884, 46.939946],
                    [-123.915256, 46.932964],
                    [-123.957493, 46.921261],
                    [-123.979378, 46.923038],
                    [-123.985082, 46.921916],
                    [-124.009519, 46.910325],
                    [-124.01366, 46.90363],
                    [-124.03624, 46.898473],
                    [-124.046344, 46.893972],
                    [-124.049279, 46.891253],
                    [-124.055085, 46.870429],
                    [-124.061051, 46.865127],
                    [-124.066349, 46.863504],
                    [-124.073113, 46.861493],
                    [-124.089286, 46.867716],
                    [-124.090422, 46.8955],
                    [-124.093392, 46.901168],
                    [-124.110641, 46.91252],
                    [-124.117712, 46.91238],
                    [-124.138225, 46.905534],
                    [-124.122979, 46.879809],
                    [-124.108078, 46.836388],
                    [-124.101232, 46.810656],
                    [-124.098359, 46.794157],
                    [-124.096655, 46.784374],
                    [-124.095041, 46.756812],
                    [-124.096515, 46.746202],
                    [-124.092176, 46.741624],
                    [-124.080983, 46.735003],
                    [-124.063117, 46.733664],
                    [-124.046399, 46.725686],
                    [-124.042111, 46.722783],
                    [-124.042478, 46.72004],
                    [-124.022413, 46.708973],
                    [-124.003458, 46.702337],
                    [-123.994242, 46.707929],
                    [-123.987521, 46.707507],
                    [-123.973663, 46.703353],
                    [-123.966886, 46.705184],
                    [-123.975157, 46.713971],
                    [-123.979655, 46.724658],
                    [-123.974994, 46.733391],
                    [-123.968564, 46.736106],
                    [-123.948683, 46.725369],
                    [-123.929073, 46.725278],
                    [-123.916874, 46.726739],
                    [-123.91285, 46.730647],
                    [-123.91584, 46.737322],
                    [-123.916371, 46.741322],
                    [-123.910716, 46.746715],
                    [-123.898641, 46.750205],
                    [-123.893054, 46.750204],
                    [-123.87668, 46.730657],
                    [-123.870782, 46.728327],
                    [-123.862149, 46.727749],
                    [-123.848725, 46.719898],
                    [-123.84621, 46.716795],
                    [-123.851356, 46.70256],
                    [-123.864902, 46.698685],
                    [-123.895601, 46.683672],
                    [-123.915596, 46.678649],
                    [-123.923269, 46.672708],
                    [-123.920916, 46.653576],
                    [-123.921913, 46.650262],
                    [-123.940616, 46.640862],
                    [-123.960642, 46.636364],
                    [-123.959175, 46.613581],
                    [-123.955556, 46.60357],
                    [-123.939139, 46.596326],
                    [-123.928861, 46.588875],
                    [-123.922332, 46.577057],
                    [-123.920247, 46.567343],
                    [-123.916902, 46.562633],
                    [-123.903321, 46.55191],
                    [-123.894254, 46.537028],
                    [-123.897242, 46.52848],
                    [-123.896703, 46.522665],
                    [-123.921192, 46.507731],
                    [-123.943667, 46.477197],
                    [-123.968044, 46.473497],
                    [-123.97083, 46.47537],
                    [-123.979213, 46.489949],
                    [-123.979053, 46.497378],
                    [-123.983688, 46.498542],
                    [-123.988386, 46.497008],
                    [-123.99268, 46.488617],
                    [-123.994181, 46.468868],
                    [-123.99087, 46.465738],
                    [-123.990615, 46.463019],
                    [-124.001271, 46.459992],
                    [-124.026032, 46.462978],
                    [-124.031737, 46.496375],
                    [-124.026019, 46.531589],
                    [-124.023148, 46.564113],
                    [-124.023566, 46.582559],
                    [-124.028799, 46.59104],
                    [-124.050842, 46.617421],
                    [-124.052708, 46.622796],
                    [-124.035874, 46.630822],
                    [-124.048444, 46.645827],
                    [-124.056476, 46.645645],
                    [-124.062715, 46.642582],
                    [-124.068655, 46.634879],
                    [-124.069583, 46.630651],
                    [-124.06842, 46.601397],
                    [-124.061953, 46.556165],
                    [-124.057024, 46.493338],
                    [-124.057425, 46.409315],
                    [-124.058351, 46.386503],
                    [-124.064624, 46.326899],
                    [-124.071384, 46.305504],
                    [-124.076262, 46.296498],
                    [-124.081729, 46.274714],
                    [-124.082187, 46.269159],
                    [-124.080671, 46.267239],
                    [-124.060961, 46.278761],
                    [-124.044018, 46.275925],
                    [-124.038797, 46.283675],
                    [-124.035599, 46.296843],
                    [-124.029924, 46.308312],
                    [-124.020551, 46.315737],
                    [-124.001264, 46.31326],
                    [-123.985204, 46.309039],
                    [-123.974509, 46.303063],
                    [-123.970355, 46.299352],
                    [-123.970912, 46.293866],
                    [-123.969427, 46.291398],
                    [-123.954353, 46.277001],
                    [-123.919581, 46.251217],
                    [-123.909306, 46.245491],
                    [-123.875525, 46.239787],
                    [-123.806139, 46.283588],
                    [-123.795556, 46.284501],
                    [-123.782654, 46.280227],
                    [-123.775054, 46.274599],
                    [-123.766682, 46.273499],
                    [-123.75956, 46.275073],
                    [-123.741478, 46.290274],
                    [-123.728585, 46.288725],
                    [-123.727913, 46.289661],
                    [-123.724038, 46.295058],
                    [-123.724273, 46.301161],
                    [-123.700764, 46.305278],
                    [-123.687763, 46.299235],
                    [-123.680574, 46.296025],
                    [-123.67876, 46.290721],
                    [-123.678069, 46.286469],
                    [-123.68008, 46.277943],
                    [-123.679125, 46.272502],
                    [-123.669501, 46.266832],
                    [-123.613544, 46.259988],
                    [-123.581642, 46.260502],
                    [-123.564405, 46.262172],
                    [-123.559923, 46.265098],
                    [-123.547636, 46.265595],
                    [-123.547659, 46.259109],
                    [-123.548194, 46.248245],
                    [-123.586205, 46.228654],
                    [-123.60019, 46.234814],
                    [-123.605487, 46.2393],
                    [-123.613459, 46.239228],
                    [-123.622812, 46.23664],
                    [-123.625219, 46.233868],
                    [-123.626247, 46.226434],
                    [-123.6325, 46.216681],
                    [-123.636474, 46.214359],
                    [-123.65539, 46.217974],
                    [-123.666751, 46.218228],
                    [-123.673831, 46.215418],
                    [-123.67538, 46.212401],
                    [-123.706667, 46.199665],
                    [-123.71278, 46.198751],
                    [-123.736747, 46.200687],
                    [-123.759976, 46.2073],
                    [-123.793936, 46.196283],
                    [-123.821834, 46.190293],
                    [-123.838801, 46.192211],
                    [-123.864209, 46.189527],
                    [-123.866643, 46.187674],
                    [-123.863347, 46.18235],
                    [-123.841521, 46.169824],
                    [-123.842849, 46.160529],
                    [-123.854801, 46.157342],
                    [-123.891186, 46.164778],
                    [-123.9042, 46.169293],
                    [-123.912405, 46.17945],
                    [-123.927038, 46.191617],
                    [-123.950148, 46.204097],
                    [-123.961739, 46.207916],
                    [-123.982149, 46.209662],
                    [-123.987196, 46.211521],
                    [-123.990117, 46.21763],
                    [-123.988429, 46.224132],
                    [-123.998052, 46.235327],
                    [-124.001998, 46.237316],
                    [-124.011355, 46.236223],
                    [-124.024305, 46.229256],
                    [-124.010344, 46.223514],
                    [-123.996766, 46.20399],
                    [-123.974124, 46.168798],
                    [-123.95919, 46.141675],
                    [-123.947531, 46.116131],
                    [-123.933366, 46.071672],
                    [-123.92933, 46.041978],
                    [-123.927891, 46.009564],
                    [-123.937471, 45.977306],
                    [-123.941831, 45.97566],
                    [-123.957438, 45.974469],
                    [-123.969991, 45.969139],
                    [-123.993703, 45.946431],
                    [-123.99304, 45.938842],
                    [-123.979501, 45.930389],
                    [-123.96763, 45.907807],
                    [-123.962736, 45.869974],
                    [-123.961544, 45.837101],
                    [-123.96934, 45.783197],
                    [-123.969459, 45.782371],
                    [-123.981864, 45.768285],
                    [-123.982578, 45.761815],
                    [-123.968563, 45.757019],
                    [-123.946027, 45.733249],
                    [-123.943121, 45.727031],
                    [-123.939448, 45.708795],
                    [-123.939005, 45.661923],
                    [-123.951246, 45.585775],
                    [-123.956711, 45.571303],
                    [-123.947556, 45.564878],
                    [-123.957568, 45.510399],
                    [-123.96634, 45.493417],
                    [-123.970794, 45.493507],
                    [-123.976544, 45.489733],
                    [-123.972953, 45.467513],
                    [-123.964074, 45.449112],
                    [-123.960557, 45.430778],
                    [-123.965728, 45.386242],
                    [-123.973398, 45.354791],
                    [-123.979715, 45.347724],
                    [-124.007494, 45.33974],
                    [-124.007756, 45.336813],
                    [-123.978671, 45.338854],
                    [-123.972899, 45.33689],
                    [-123.964169, 45.317026],
                    [-123.962887, 45.280218],
                    [-123.972919, 45.216784],
                    [-123.968187, 45.201217],
                    [-123.975425, 45.145476],
                    [-123.989529, 45.094045],
                    [-124.004863, 45.084232],
                    [-124.006057, 45.084736],
                    [-124.012163, 45.076921],
                    [-124.015851, 45.064759],
                    [-124.017991, 45.049808],
                    [-124.00977, 45.047266],
                    [-124.004668, 45.048167],
                    [-124.004386, 45.046197],
                    [-124.004598, 45.044959],
                    [-124.015243, 44.982904],
                    [-124.023834, 44.949825],
                    [-124.025678, 44.936542],
                    [-124.025136, 44.928175],
                    [-124.032296, 44.900809],
                    [-124.048814, 44.850007],
                    [-124.054151, 44.838233],
                    [-124.063155, 44.835333],
                    [-124.066746, 44.831191],
                    [-124.074066, 44.798107],
                    [-124.075473, 44.771403],
                    [-124.066325, 44.762671],
                    [-124.059077, 44.737656],
                    [-124.063406, 44.703177],
                    [-124.070394, 44.683514],
                    [-124.060043, 44.669361],
                    [-124.058281, 44.658866],
                    [-124.065008, 44.632504],
                    [-124.065202, 44.622445],
                    [-124.084476, 44.611056],
                    [-124.082326, 44.608861],
                    [-124.06914, 44.612979],
                    [-124.067251, 44.60804],
                    [-124.076387, 44.531214],
                    [-124.083601, 44.501123],
                    [-124.084429, 44.486927],
                    [-124.082061, 44.478171],
                    [-124.082113, 44.441518],
                    [-124.079301, 44.430863],
                    [-124.073941, 44.434481],
                    [-124.067569, 44.428582],
                    [-124.071706, 44.423662],
                    [-124.080989, 44.419728],
                    [-124.084401, 44.415611],
                    [-124.092101, 44.370388],
                    [-124.100587, 44.331926],
                    [-124.109556, 44.314545],
                    [-124.108088, 44.309926],
                    [-124.10907, 44.303707],
                    [-124.1152, 44.286486],
                    [-124.115849, 44.276277],
                    [-124.115953, 44.274641],
                    [-124.114869, 44.272721],
                    [-124.109744, 44.270597],
                    [-124.108945, 44.265475],
                    [-124.111054, 44.235071],
                    [-124.115671, 44.206554],
                    [-124.114424, 44.198164],
                    [-124.117006, 44.171913],
                    [-124.125824, 44.12613],
                    [-124.122406, 44.104442],
                    [-124.133547, 44.035845],
                    [-124.142704, 43.958182],
                    [-124.150267, 43.91085],
                    [-124.158684, 43.863504],
                    [-124.168392, 43.808903],
                    [-124.193455, 43.706085],
                    [-124.198275, 43.689481],
                    [-124.204888, 43.673976],
                    [-124.203028, 43.667825],
                    [-124.218876, 43.610319],
                    [-124.233534, 43.55713],
                    [-124.255609, 43.502172],
                    [-124.286896, 43.436296],
                    [-124.315012, 43.388389],
                    [-124.341587, 43.351337],
                    [-124.353332, 43.342667],
                    [-124.373037, 43.338953],
                    [-124.387642, 43.325968],
                    [-124.402814, 43.305872],
                    [-124.400404, 43.302121],
                    [-124.393988, 43.29926],
                    [-124.388891, 43.290523],
                    [-124.38246, 43.270167],
                    [-124.395607, 43.223908],
                    [-124.395302, 43.211101],
                    [-124.401726, 43.184896],
                    [-124.424113, 43.126859],
                    [-124.434451, 43.115986],
                    [-124.432236, 43.097383],
                    [-124.436198, 43.071312],
                    [-124.456918, 43.000315],
                    [-124.462619, 42.99143],
                    [-124.479344, 42.95497],
                    [-124.480938, 42.951495],
                    [-124.500141, 42.917502],
                    [-124.552441, 42.840568],
                    [-124.544179, 42.822958],
                    [-124.536073, 42.814175],
                    [-124.524439, 42.789793],
                    [-124.516236, 42.753632],
                    [-124.514669, 42.736806],
                    [-124.513368, 42.735068],
                    [-124.510017, 42.734746],
                    [-124.499122, 42.738606],
                    [-124.498473, 42.741077],
                    [-124.491679, 42.741789],
                    [-124.473864, 42.732671],
                    [-124.448418, 42.689909],
                    [-124.447487, 42.68474],
                    [-124.451484, 42.677787],
                    [-124.45074, 42.675798],
                    [-124.416774, 42.661594],
                    [-124.413119, 42.657934],
                    [-124.401177, 42.627192],
                    [-124.399421, 42.618079],
                    [-124.400918, 42.597518],
                    [-124.389977, 42.574758],
                    [-124.390664, 42.566593],
                    [-124.399065, 42.539928],
                    [-124.421381, 42.491737],
                    [-124.423084, 42.478952],
                    [-124.422038, 42.461226],
                    [-124.435105, 42.440163],
                    [-124.434882, 42.434916],
                    [-124.428068, 42.420333],
                    [-124.424863, 42.395426],
                    [-124.424066, 42.377242],
                    [-124.425554, 42.351874],
                    [-124.427222, 42.33488],
                    [-124.429288, 42.331746],
                    [-124.410556, 42.307431],
                    [-124.405148, 42.278107],
                    [-124.408514, 42.260588],
                    [-124.411534, 42.254115],
                    [-124.410982, 42.250547],
                    [-124.383633, 42.22716],
                    [-124.377762, 42.218809],
                    [-124.375553, 42.20882],
                    [-124.376215, 42.196381],
                    [-124.374949, 42.193129],
                    [-124.373175, 42.190218],
                    [-124.367751, 42.188321],
                    [-124.361009, 42.180752],
                    [-124.360318, 42.162272],
                    [-124.363389, 42.158588],
                    [-124.366832, 42.15845],
                    [-124.366028, 42.152343],
                    [-124.361563, 42.143767],
                    [-124.355696, 42.141964],
                    [-124.351784, 42.134965],
                    [-124.351535, 42.129796],
                    [-124.357122, 42.118016],
                    [-124.356229, 42.114952],
                    [-124.34101, 42.092929],
                    [-124.314289, 42.067864],
                    [-124.299649, 42.051736],
                    [-124.287374, 42.046016],
                    [-124.270464, 42.045553],
                    [-124.214213, 42.005939],
                    [-124.211605, 41.99846],
                    [-124.204948, 41.983441],
                    [-124.203402, 41.940964],
                    [-124.208439, 41.888192],
                    [-124.219592, 41.846432],
                    [-124.230678, 41.818681],
                    [-124.245027, 41.7923],
                    [-124.255994, 41.783014],
                    [-124.248704, 41.771459],
                    [-124.242288, 41.772034],
                    [-124.23972, 41.7708],
                    [-124.203843, 41.747035],
                    [-124.194953, 41.736778],
                    [-124.19104, 41.736079],
                    [-124.185363, 41.739351],
                    [-124.17739, 41.745756],
                    [-124.164716, 41.740126],
                    [-124.154246, 41.728801],
                    [-124.147412, 41.717955],
                    [-124.143479, 41.709284],
                    [-124.138373, 41.678881],
                    [-124.139354, 41.671652],
                    [-124.135552, 41.657307],
                    [-124.120225, 41.640354],
                    [-124.116037, 41.628849],
                    [-124.114413, 41.616768],
                    [-124.100961, 41.602499],
                    [-124.097385, 41.585251],
                    [-124.101403, 41.578524],
                    [-124.101123, 41.569192],
                    [-124.092404, 41.553615],
                    [-124.081987, 41.547761],
                    [-124.081427, 41.511228],
                    [-124.075917, 41.501757],
                    [-124.066057, 41.470258],
                    [-124.065521, 41.464739],
                    [-124.063076, 41.439579],
                    [-124.072294, 41.374844],
                    [-124.079015, 41.347135],
                    [-124.092284, 41.287695],
                    [-124.106389, 41.240682],
                    [-124.106986, 41.229678],
                    [-124.122677, 41.189726],
                    [-124.1438, 41.144686],
                    [-124.149674, 41.140845],
                    [-124.158539, 41.143021],
                    [-124.163988, 41.138675],
                    [-124.165414, 41.129822],
                    [-124.159065, 41.121957],
                    [-124.160556, 41.099011],
                    [-124.154513, 41.087159],
                    [-124.154028, 41.059923],
                    [-124.153622, 41.05355],
                    [-124.151266, 41.051101],
                    [-124.148939, 41.051467],
                    [-124.147216, 41.052884],
                    [-124.142867, 41.054032],
                    [-124.138217, 41.054342],
                    [-124.132946, 41.052482],
                    [-124.125448, 41.048504],
                    [-124.112165, 41.028173],
                    [-124.118147, 40.989263],
                    [-124.137066, 40.925732],
                    [-124.158322, 40.876069],
                    [-124.176715, 40.843618],
                    [-124.201921, 40.805111],
                    [-124.228244, 40.76939],
                    [-124.248406, 40.735166],
                    [-124.289119, 40.67963],
                    [-124.312558, 40.641333],
                    [-124.315141, 40.639526],
                    [-124.329404, 40.61643],
                    [-124.363545, 40.548698],
                    [-124.379096, 40.522865],
                    [-124.382816, 40.519],
                    [-124.387023, 40.504954],
                    [-124.383224, 40.499852],
                    [-124.38494, 40.48982],
                    [-124.396642, 40.462119],
                    [-124.408601, 40.443201],
                    [-124.409591, 40.438076],
                    [-124.402623, 40.422105],
                    [-124.391496, 40.407047],
                    [-124.379082, 40.398828],
                    [-124.373599, 40.392923],
                    [-124.365357, 40.374855],
                    [-124.362796, 40.350046],
                    [-124.356595, 40.335016],
                    [-124.353124, 40.331425],
                    [-124.347853, 40.314634],
                    [-124.363634, 40.276212],
                    [-124.363414, 40.260974],
                    [-124.352715, 40.250453],
                    [-124.34307, 40.243979],
                    [-124.327691, 40.23737],
                    [-124.320912, 40.226617],
                    [-124.296497, 40.208816],
                    [-124.258405, 40.184277],
                    [-124.231095, 40.171581],
                    [-124.214895, 40.160902],
                    [-124.187874, 40.130542],
                    [-124.170767, 40.124207],
                    [-124.139952, 40.11635],
                    [-124.110549, 40.103765],
                    [-124.087086, 40.078442],
                    [-124.080709, 40.06611],
                    [-124.079983, 40.029773],
                    [-124.072509, 40.022657],
                    [-124.068908, 40.021307],
                    [-124.065069, 40.024785],
                    [-124.056408, 40.024305],
                    [-124.035904, 40.013319],
                    [-124.023938, 40.001284],
                    [-123.99586, 39.973045],
                    [-123.980031, 39.962458],
                    [-123.962655, 39.937635],
                    [-123.954952, 39.922373],
                    [-123.930047, 39.909697],
                    [-123.915853, 39.881114],
                    [-123.915142, 39.875313],
                    [-123.907664, 39.863028],
                    [-123.881458, 39.845422],
                    [-123.853764, 39.8341],
                    [-123.851714, 39.832041],
                    [-123.839797, 39.795637],
                    [-123.83715, 39.776232],
                    [-123.838089, 39.752409],
                    [-123.835092, 39.738768],
                    [-123.831599, 39.730629],
                    [-123.829545, 39.723071],
                    [-123.824744, 39.718128],
                    [-123.808208, 39.710715],
                    [-123.792659, 39.684122],
                    [-123.78636, 39.659932],
                    [-123.782322, 39.621486],
                    [-123.78354, 39.609517],
                    [-123.787417, 39.604552],
                    [-123.76721, 39.559852],
                    [-123.766475, 39.552803],
                    [-123.778521, 39.521478],
                    [-123.78417, 39.509419],
                    [-123.795639, 39.492215],
                    [-123.81469, 39.446538],
                    [-123.821887, 39.406809],
                    [-123.822325, 39.379987],
                    [-123.826306, 39.36871],
                    [-123.825331, 39.360814],
                    [-123.822085, 39.343857],
                    [-123.817369, 39.3388],
                    [-123.808772, 39.324368],
                    [-123.811387, 39.312825],
                    [-123.803081, 39.291747],
                    [-123.801757, 39.28353],
                    [-123.803848, 39.278771],
                    [-123.798991, 39.271355],
                    [-123.787893, 39.264327],
                    [-123.777368, 39.237214],
                    [-123.774998, 39.212083],
                    [-123.765891, 39.193657],
                    [-123.76101, 39.191595],
                    [-123.742221, 39.164885],
                    [-123.737913, 39.143442],
                    [-123.735936, 39.139644],
                    [-123.721505, 39.125327],
                    [-123.713392, 39.108422],
                    [-123.693969, 39.057363],
                    [-123.690095, 39.031157],
                    [-123.69074, 39.021293],
                    [-123.6969, 39.004401],
                    [-123.711149, 38.977316],
                    [-123.721347, 38.963879],
                    [-123.729053, 38.956667],
                    [-123.732892, 38.954994],
                    [-123.738886, 38.95412],
                    [-123.726315, 38.936367],
                    [-123.72763, 38.9295],
                    [-123.725367, 38.917438],
                    [-123.71054, 38.91323],
                    [-123.688099, 38.893594],
                    [-123.659846, 38.872529],
                    [-123.654696, 38.865638],
                    [-123.652212, 38.854582],
                    [-123.647387, 38.845472],
                    [-123.642676, 38.844005],
                    [-123.638637, 38.843865],
                    [-123.605317, 38.822765],
                    [-123.601569, 38.81899],
                    [-123.600221, 38.814115],
                    [-123.58638, 38.802857],
                    [-123.579856, 38.802835],
                    [-123.571987, 38.798189],
                    [-123.541837, 38.776764],
                    [-123.533535, 38.768408],
                    [-123.525152, 38.753801],
                    [-123.514784, 38.741966],
                    [-123.490021, 38.732213],
                    [-123.461291, 38.717001],
                    [-123.441774, 38.699744],
                    [-123.43272, 38.687131],
                    [-123.405663, 38.656729],
                    [-123.40301, 38.649449],
                    [-123.398166, 38.647044],
                    [-123.379303, 38.621953],
                    [-123.371876, 38.607235],
                    [-123.349612, 38.596805],
                    [-123.343338, 38.590008],
                    [-123.331899, 38.565542],
                    [-123.297151, 38.543452],
                    [-123.287156, 38.540223],
                    [-123.249797, 38.511045],
                    [-123.202277, 38.494314],
                    [-123.166428, 38.474947],
                    [-123.145325, 38.459422],
                    [-123.128825, 38.450418],
                    [-123.122379, 38.437314],
                    [-123.103706, 38.415541],
                    [-123.085572, 38.390525],
                    [-123.068265, 38.359865],
                    [-123.068437, 38.33521],
                    [-123.074684, 38.322574],
                    [-123.063671, 38.302178],
                    [-123.058239, 38.298355],
                    [-123.053504, 38.299385],
                    [-123.052021, 38.302246],
                    [-123.053476, 38.305722],
                    [-123.051061, 38.310693],
                    [-123.038742, 38.313576],
                    [-123.024333, 38.310573],
                    [-123.004122, 38.297012],
                    [-123.002911, 38.295708],
                    [-122.997106, 38.289458],
                    [-122.994603, 38.283096],
                    [-122.986319, 38.273164],
                    [-122.977082, 38.267902],
                    [-122.967203, 38.250691],
                    [-122.968569, 38.242879],
                    [-122.987149, 38.237538],
                    [-122.993235, 38.239686],
                    [-122.993959, 38.237602],
                    [-122.991953, 38.233185],
                    [-122.968112, 38.202428],
                    [-122.96637, 38.198514],
                    [-122.965408, 38.187113],
                    [-122.953629, 38.17567],
                    [-122.949626, 38.164041],
                    [-122.949074, 38.15406],
                    [-122.952086, 38.138562],
                    [-122.960889, 38.112962],
                    [-122.99242, 38.041758],
                    [-123.011533, 38.003438],
                    [-123.016303, 38.001691],
                    [-123.020562, 37.999544],
                    [-123.024066, 37.994878],
                    [-122.97439, 37.992429],
                    [-122.976764, 37.99568],
                    [-122.980147, 38.000831],
                    [-122.982386, 38.004274],
                    [-122.981776, 38.009119],
                    [-122.972378, 38.020247],
                    [-122.956811, 38.02872],
                    [-122.939711, 38.031908],
                    [-122.882114, 38.025273],
                    [-122.856573, 38.016717],
                    [-122.821383, 37.996735],
                    [-122.797405, 37.976657],
                    [-122.791739, 37.969422],
                    [-122.783244, 37.951334],
                    [-122.766138, 37.938004],
                    [-122.754606, 37.935527],
                    [-122.736898, 37.925825],
                    [-122.732898, 37.920225],
                    [-122.727297, 37.904626],
                    [-122.70264, 37.89382],
                    [-122.693569, 37.901171],
                    [-122.682171, 37.90645],
                    [-122.678474, 37.906604],
                    [-122.656519, 37.904519],
                    [-122.639977, 37.897349],
                    [-122.627113, 37.88608],
                    [-122.60129, 37.875126],
                    [-122.584289, 37.859227],
                    [-122.561487, 37.851827],
                    [-122.548986, 37.836227],
                    [-122.537285, 37.830328],
                    [-122.523585, 37.824828],
                    [-122.522836, 37.824717],
                    [-122.505383, 37.822128],
                    [-122.492474, 37.82484],
                    [-122.483483, 37.826728],
                    [-122.47986, 37.825641],
                    [-122.479151, 37.825428],
                    [-122.476473, 37.832513],
                    [-122.476536, 37.832812],
                    [-122.483102, 37.863957],
                    [-122.483429, 37.868866],
                    [-122.480811, 37.873448],
                    [-122.474266, 37.874429],
                    [-122.462158, 37.868866],
                    [-122.45005, 37.871157],
                    [-122.438268, 37.880974],
                    [-122.43925, 37.88392],
                    [-122.448413, 37.89341],
                    [-122.458558, 37.894064],
                    [-122.472303, 37.902573],
                    [-122.471975, 37.910427],
                    [-122.478193, 37.918608],
                    [-122.486375, 37.921881],
                    [-122.493574, 37.921881],
                    [-122.503064, 37.928753],
                    [-122.503064, 37.936607],
                    [-122.499465, 37.939225],
                    [-122.48572, 37.937589],
                    [-122.479175, 37.941516],
                    [-122.480484, 37.945443],
                    [-122.487684, 37.948716],
                    [-122.490302, 37.959188],
                    [-122.490302, 37.964751],
                    [-122.488665, 37.966714],
                    [-122.471975, 37.981768],
                    [-122.462485, 37.981441],
                    [-122.456595, 37.978823],
                    [-122.448413, 37.984713],
                    [-122.448413, 37.988313],
                    [-122.452995, 37.996167],
                    [-122.462812, 38.003367],
                    [-122.481466, 38.007621],
                    [-122.494556, 38.015148],
                    [-122.497828, 38.019402],
                    [-122.499465, 38.032165],
                    [-122.492265, 38.056381],
                    [-122.483757, 38.071762],
                    [-122.486702, 38.090088],
                    [-122.489974, 38.096961],
                    [-122.491283, 38.108087],
                    [-122.489974, 38.112014],
                    [-122.484411, 38.11496],
                    [-122.454958, 38.118887],
                    [-122.450377, 38.116269],
                    [-122.439577, 38.116923],
                    [-122.409798, 38.136231],
                    [-122.40358, 38.15063],
                    [-122.403514, 38.150624],
                    [-122.39638, 38.149976],
                    [-122.366273, 38.141467],
                    [-122.314567, 38.115287],
                    [-122.301804, 38.105142],
                    [-122.282824, 38.082889],
                    [-122.273006, 38.07438],
                    [-122.266669, 38.06007],
                    [-122.262861, 38.051473],
                    [-122.262861, 38.0446],
                    [-122.283478, 38.022674],
                    [-122.300823, 38.010893],
                    [-122.315549, 38.013511],
                    [-122.321112, 38.012857],
                    [-122.331912, 38.00533],
                    [-122.340093, 38.003694],
                    [-122.359493, 38.009941],
                    [-122.363655, 38.014166],
                    [-122.367909, 38.01253],
                    [-122.368891, 38.007948],
                    [-122.366928, 37.998458],
                    [-122.363001, 37.994375],
                    [-122.361905, 37.989991],
                    [-122.367582, 37.978168],
                    [-122.399832, 37.956009],
                    [-122.408383, 37.957544],
                    [-122.411761, 37.960497],
                    [-122.415361, 37.963115],
                    [-122.42976, 37.965405],
                    [-122.430087, 37.963115],
                    [-122.417371, 37.943513],
                    [-122.413725, 37.937262],
                    [-122.401289, 37.928426],
                    [-122.395071, 37.927117],
                    [-122.39049, 37.922535],
                    [-122.389181, 37.9101],
                    [-122.385908, 37.908136],
                    [-122.378709, 37.905191],
                    [-122.367582, 37.903882],
                    [-122.362346, 37.904209],
                    [-122.35711, 37.908791],
                    [-122.33453, 37.908791],
                    [-122.32373, 37.905845],
                    [-122.313496, 37.897211],
                    [-122.313258, 37.89701],
                    [-122.309986, 37.892755],
                    [-122.310477, 37.873938],
                    [-122.301313, 37.847758],
                    [-122.303931, 37.830087],
                    [-122.306222, 37.827469],
                    [-122.317676, 37.826814],
                    [-122.323567, 37.823214],
                    [-122.333711, 37.809797],
                    [-122.335675, 37.799652],
                    [-122.33555, 37.799538],
                    [-122.331748, 37.796052],
                    [-122.330963, 37.786035],
                    [-122.33079, 37.78383],
                    [-122.329159, 37.783173],
                    [-122.318909, 37.77904],
                    [-122.304345, 37.774632],
                    [-122.293996, 37.770416],
                    [-122.286139, 37.769458],
                    [-122.275408, 37.76735],
                    [-122.264101, 37.764667],
                    [-122.253753, 37.761218],
                    [-122.242638, 37.753744],
                    [-122.244938, 37.750294],
                    [-122.252226, 37.747619],
                    [-122.257134, 37.745001],
                    [-122.257953, 37.739601],
                    [-122.255989, 37.735674],
                    [-122.246826, 37.72193],
                    [-122.221628, 37.705567],
                    [-122.213774, 37.698695],
                    [-122.203971, 37.697769],
                    [-122.197411, 37.692804],
                    [-122.179085, 37.680041],
                    [-122.170904, 37.676114],
                    [-122.163049, 37.667933],
                    [-122.152905, 37.640771],
                    [-122.14636, 37.607391],
                    [-122.145378, 37.600846],
                    [-122.147014, 37.588411],
                    [-122.144396, 37.581866],
                    [-122.137524, 37.567467],
                    [-122.133924, 37.562885],
                    [-122.128688, 37.560594],
                    [-122.111998, 37.528851],
                    [-122.111344, 37.50758],
                    [-122.116112, 37.505386],
                    [-122.127706, 37.500053],
                    [-122.130979, 37.503652],
                    [-122.140142, 37.507907],
                    [-122.149632, 37.502671],
                    [-122.155686, 37.501198],
                    [-122.168449, 37.504143],
                    [-122.194957, 37.522469],
                    [-122.196593, 37.537196],
                    [-122.214264, 37.538505],
                    [-122.225135, 37.545594],
                    [-122.236323, 37.552891],
                    [-122.242832, 37.557136],
                    [-122.244372, 37.55814],
                    [-122.251898, 37.566321],
                    [-122.262698, 37.572866],
                    [-122.305895, 37.575484],
                    [-122.315713, 37.583666],
                    [-122.315385, 37.587265],
                    [-122.317676, 37.590865],
                    [-122.360219, 37.592501],
                    [-122.378545, 37.605592],
                    [-122.373309, 37.613773],
                    [-122.370364, 37.614427],
                    [-122.358583, 37.611155],
                    [-122.35531, 37.615736],
                    [-122.365455, 37.626208],
                    [-122.386072, 37.637662],
                    [-122.387381, 37.648462],
                    [-122.37789, 37.650425],
                    [-122.3756, 37.652389],
                    [-122.374291, 37.662206],
                    [-122.387626, 37.67906],
                    [-122.39319, 37.707531],
                    [-122.391374, 37.708331],
                    [-122.370411, 37.717572],
                    [-122.361749, 37.71501],
                    [-122.356784, 37.729505],
                    [-122.365478, 37.734621],
                    [-122.367697, 37.734943],
                    [-122.370094, 37.732331],
                    [-122.375854, 37.734979],
                    [-122.385323, 37.790724],
                    [-122.398139, 37.80563],
                    [-122.407452, 37.811441],
                    [-122.425942, 37.810979],
                    [-122.463793, 37.804653],
                    [-122.470336, 37.808671],
                    [-122.478083, 37.810828],
                    [-122.485783, 37.790629],
                    [-122.492883, 37.787929],
                    [-122.50531, 37.788312],
                    [-122.514483, 37.780829],
                    [-122.511983, 37.77113],
                    [-122.509397, 37.748841],
                    [-122.506483, 37.723731],
                    [-122.502427, 37.708133],
                    [-122.496784, 37.686433],
                    [-122.494085, 37.644035],
                    [-122.496786, 37.612136],
                    [-122.501386, 37.599637],
                    [-122.517187, 37.590637],
                    [-122.518088, 37.576138],
                    [-122.513688, 37.552239],
                    [-122.514789, 37.546139],
                    [-122.516589, 37.544939],
                    [-122.519533, 37.537302],
                    [-122.516689, 37.52134],
                    [-122.499289, 37.495341],
                    [-122.494429, 37.49269],
                    [-122.493789, 37.492341],
                    [-122.487139, 37.494277],
                    [-122.486749, 37.49439],
                    [-122.485888, 37.494641],
                    [-122.482351, 37.496187],
                    [-122.476443, 37.498768],
                    [-122.472388, 37.50054],
                    [-122.467888, 37.49814],
                    [-122.452087, 37.48054],
                    [-122.445987, 37.461541],
                    [-122.443687, 37.435941],
                    [-122.423286, 37.392542],
                    [-122.409258, 37.374805],
                    [-122.40085, 37.359225],
                    [-122.401323, 37.337009],
                    [-122.411686, 37.265844],
                    [-122.418452, 37.248521],
                    [-122.419113, 37.24147],
                    [-122.415822, 37.232839],
                    [-122.408982, 37.225258],
                    [-122.407181, 37.219465],
                    [-122.405073, 37.195791],
                    [-122.397065, 37.187249],
                    [-122.390599, 37.182988],
                    [-122.37927, 37.181128],
                    [-122.367085, 37.172817],
                    [-122.36179, 37.163593],
                    [-122.359791, 37.155574],
                    [-122.344029, 37.144099],
                    [-122.337833, 37.135936],
                    [-122.337085, 37.130795],
                    [-122.338856, 37.120854],
                    [-122.337071, 37.117382],
                    [-122.330463, 37.115338],
                    [-122.322971, 37.11546],
                    [-122.313907, 37.118161],
                    [-122.306139, 37.116383],
                    [-122.292974, 37.107318],
                    [-122.284882, 37.101747],
                    [-122.260481, 37.072548],
                    [-122.252181, 37.059448],
                    [-122.20618, 37.013949],
                    [-122.186879, 37.00345],
                    [-122.155078, 36.98085],
                    [-122.140578, 36.97495],
                    [-122.105976, 36.955951],
                    [-122.079356, 36.950783],
                    [-122.066421, 36.948271],
                    [-122.050122, 36.948523],
                    [-122.027174, 36.95115],
                    [-122.023373, 36.96215],
                    [-122.012373, 36.96455],
                    [-121.983896, 36.958727],
                    [-121.975871, 36.954051],
                    [-121.972771, 36.954151],
                    [-121.95167, 36.97145],
                    [-121.93947, 36.97805],
                    [-121.930069, 36.97815],
                    [-121.906468, 36.96895],
                    [-121.894667, 36.961851],
                    [-121.880167, 36.950151],
                    [-121.862266, 36.931552],
                    [-121.827664, 36.879353],
                    [-121.810552, 36.850648],
                    [-121.809363, 36.848654],
                    [-121.791544, 36.815186],
                    [-121.788278, 36.803994],
                    [-121.805643, 36.750239],
                    [-121.807062, 36.714157],
                    [-121.814462, 36.682858],
                    [-121.825052, 36.657207],
                    [-121.831995, 36.644856],
                    [-121.842263, 36.630059],
                    [-121.860604, 36.611136],
                    [-121.871364, 36.604559],
                    [-121.886764, 36.601459],
                    [-121.889064, 36.601759],
                    [-121.890164, 36.609259],
                    [-121.923866, 36.634559],
                    [-121.929666, 36.636959],
                    [-121.93643, 36.636746],
                    [-121.938551, 36.633908],
                    [-121.941666, 36.618059],
                    [-121.970427, 36.582754],
                    [-121.978592, 36.580488],
                    [-121.972594, 36.57337],
                    [-121.957335, 36.564482],
                    [-121.95146, 36.564009],
                    [-121.949659, 36.567602],
                    [-121.942533, 36.566435],
                    [-121.932508, 36.559935],
                    [-121.925937, 36.525173],
                    [-121.928769, 36.523147],
                    [-121.944666, 36.521861],
                    [-121.943678, 36.511802],
                    [-121.938763, 36.506423],
                    [-121.939216, 36.496896],
                    [-121.9416, 36.485602],
                    [-121.937205, 36.472488],
                    [-121.9255, 36.453918],
                    [-121.91474, 36.42589],
                    [-121.917463, 36.414809],
                    [-121.914378, 36.404344],
                    [-121.905657, 36.398206],
                    [-121.903195, 36.393603],
                    [-121.901813, 36.381879],
                    [-121.902669, 36.363901],
                    [-121.905446, 36.358269],
                    [-121.892917, 36.340428],
                    [-121.894714, 36.317806],
                    [-121.888491, 36.30281],
                    [-121.874797, 36.289064],
                    [-121.851967, 36.277831],
                    [-121.83935, 36.260478],
                    [-121.835785, 36.250748],
                    [-121.826425, 36.24186],
                    [-121.813734, 36.234235],
                    [-121.806979, 36.232907],
                    [-121.797059, 36.234211],
                    [-121.779851, 36.227407],
                    [-121.717176, 36.195146],
                    [-121.680145, 36.165818],
                    [-121.629634, 36.114452],
                    [-121.622009, 36.099695],
                    [-121.618672, 36.087767],
                    [-121.606845, 36.072065],
                    [-121.592853, 36.065062],
                    [-121.589183, 36.053775],
                    [-121.590395, 36.050363],
                    [-121.574602, 36.025156],
                    [-121.569612, 36.021539],
                    [-121.553716, 36.019798],
                    [-121.531876, 36.014368],
                    [-121.51159, 36.006598],
                    [-121.503112, 36.000299],
                    [-121.4862, 35.970348],
                    [-121.472435, 35.91989],
                    [-121.463452, 35.904416],
                    [-121.461227, 35.896906],
                    [-121.462264, 35.885618],
                    [-121.439584, 35.86695],
                    [-121.426955, 35.860103],
                    [-121.413146, 35.855316],
                    [-121.406823, 35.844623],
                    [-121.388053, 35.823483],
                    [-121.356737, 35.804187],
                    [-121.346363, 35.795183],
                    [-121.332449, 35.783106],
                    [-121.324918, 35.769347],
                    [-121.315786, 35.75252],
                    [-121.314632, 35.71331],
                    [-121.304583, 35.701794],
                    [-121.296473, 35.696824],
                    [-121.289794, 35.689428],
                    [-121.284973, 35.674109],
                    [-121.272322, 35.666711],
                    [-121.251034, 35.656641],
                    [-121.195291, 35.640734],
                    [-121.188897, 35.643138],
                    [-121.166712, 35.635399],
                    [-121.143561, 35.606046],
                    [-121.133556, 35.600455],
                    [-121.126027, 35.593058],
                    [-121.101595, 35.548814],
                    [-121.059913, 35.509671],
                    [-121.05308, 35.50753],
                    [-121.025621, 35.484598],
                    [-121.003359, 35.46071],
                    [-120.976122, 35.459028],
                    [-120.969436, 35.460197],
                    [-120.955863, 35.453743],
                    [-120.950742, 35.44802],
                    [-120.946546, 35.446715],
                    [-120.907937, 35.449069],
                    [-120.896862, 35.442243],
                    [-120.884757, 35.430196],
                    [-120.869209, 35.403276],
                    [-120.866099, 35.393045],
                    [-120.862133, 35.360763],
                    [-120.862684, 35.346776],
                    [-120.8672, 35.327154],
                    [-120.87957, 35.294184],
                    [-120.889354, 35.277819],
                    [-120.896876, 35.25399],
                    [-120.89679, 35.247877],
                    [-120.873046, 35.225688],
                    [-120.856047, 35.206487],
                    [-120.846674, 35.204429],
                    [-120.805258, 35.184973],
                    [-120.786076, 35.177666],
                    [-120.778998, 35.168897],
                    [-120.760492, 35.15971],
                    [-120.756086, 35.160459],
                    [-120.756862, 35.169208],
                    [-120.754823, 35.174701],
                    [-120.74887, 35.177795],
                    [-120.734231, 35.178472],
                    [-120.714185, 35.175998],
                    [-120.704203, 35.173206],
                    [-120.698906, 35.171192],
                    [-120.686974, 35.160708],
                    [-120.675074, 35.153061],
                    [-120.667994, 35.15203],
                    [-120.662475, 35.153357],
                    [-120.651134, 35.147768],
                    [-120.644311, 35.139616],
                    [-120.635787, 35.123805],
                    [-120.630957, 35.101941],
                    [-120.629583, 35.078362],
                    [-120.629931, 35.061515],
                    [-120.63357, 35.033085],
                    [-120.63999, 35.002963],
                    [-120.648905, 34.974393],
                    [-120.670835, 34.904115],
                    [-120.662889, 34.901183],
                    [-120.647328, 34.901133],
                    [-120.642212, 34.894145],
                    [-120.639283, 34.880413],
                    [-120.616325, 34.866739],
                    [-120.610266, 34.85818],
                    [-120.609898, 34.842751],
                    [-120.616296, 34.816308],
                    [-120.62297, 34.7933],
                    [-120.637415, 34.755895],
                    [-120.62632, 34.738072],
                    [-120.614852, 34.730709],
                    [-120.601672, 34.709721],
                    [-120.60045, 34.70464],
                    [-120.60197, 34.692095],
                    [-120.625127, 34.634489],
                    [-120.640244, 34.604406],
                    [-120.645739, 34.581035],
                    [-120.637805, 34.56622],
                    [-120.622575, 34.554017],
                    [-120.612005, 34.553564],
                    [-120.608355, 34.556656],
                    [-120.581293, 34.556959],
                    [-120.524776, 34.531291],
                    [-120.511421, 34.522953],
                    [-120.490523, 34.490075],
                    [-120.480372, 34.481059],
                    [-120.47661, 34.475131],
                    [-120.471376, 34.447846],
                    [-120.451425, 34.447094],
                    [-120.441975, 34.451512],
                    [-120.341369, 34.458789],
                    [-120.301822, 34.467077],
                    [-120.299169, 34.469731],
                    [-120.295051, 34.470623],
                    [-120.283001, 34.468354],
                    [-120.25777, 34.467451],
                    [-120.238002, 34.468098],
                    [-120.225498, 34.470587],
                    [-120.183505, 34.470372],
                    [-120.141165, 34.473405],
                    [-120.118411, 34.469927],
                    [-120.097212, 34.461809],
                    [-120.088591, 34.460208],
                    [-120.050682, 34.461651],
                    [-120.038828, 34.463434],
                    [-120.008077, 34.460447],
                    [-119.971951, 34.444641],
                    [-119.956433, 34.435288],
                    [-119.925227, 34.433931],
                    [-119.873971, 34.408795],
                    [-119.853771, 34.407996],
                    [-119.835771, 34.415796],
                    [-119.794771, 34.417597],
                    [-119.785871, 34.415997],
                    [-119.74547, 34.402898],
                    [-119.729369, 34.395897],
                    [-119.709067, 34.395397],
                    [-119.691749, 34.403154],
                    [-119.684666, 34.408297],
                    [-119.688167, 34.412497],
                    [-119.671866, 34.416096],
                    [-119.648664, 34.417396],
                    [-119.638864, 34.415696],
                    [-119.616862, 34.420995],
                    [-119.559459, 34.413395],
                    [-119.536957, 34.395495],
                    [-119.510655, 34.386295],
                    [-119.478265, 34.377197],
                    [-119.472678, 34.375628],
                    [-119.461036, 34.374064],
                    [-119.435888, 34.355839],
                    [-119.431066, 34.355297],
                    [-119.42777, 34.353016],
                    [-119.390449, 34.318198],
                    [-119.388249, 34.317398],
                    [-119.37578, 34.321118],
                    [-119.370356, 34.319486],
                    [-119.349187, 34.304383],
                    [-119.337475, 34.290576],
                    [-119.313034, 34.275689],
                    [-119.302131, 34.272761],
                    [-119.290945, 34.274902],
                    [-119.278644, 34.266902],
                    [-119.270144, 34.252903],
                    [-119.265927, 34.234609],
                    [-119.257043, 34.213304],
                    [-119.237142, 34.175804],
                    [-119.227743, 34.161728],
                    [-119.216441, 34.146105],
                    [-119.211241, 34.144905],
                    [-119.20314, 34.144505],
                    [-119.18864, 34.139005],
                    [-119.159554, 34.119653],
                    [-119.130169, 34.100102],
                    [-119.109784, 34.094566],
                    [-119.098216, 34.099334],
                    [-119.088536, 34.09831],
                    [-119.069959, 34.09047],
                    [-119.037494, 34.083111],
                    [-119.004644, 34.066231],
                    [-118.99698, 34.065943],
                    [-118.977751, 34.059822],
                    [-118.954722, 34.048167],
                    [-118.944887, 34.04534],
                    [-118.938081, 34.043383],
                    [-118.928048, 34.045847],
                    [-118.896159, 34.039207],
                    [-118.854653, 34.034215],
                    [-118.84038, 34.027527],
                    [-118.821579, 34.013959],
                    [-118.805114, 34.001239],
                    [-118.787094, 34.019545],
                    [-118.783433, 34.021543],
                    [-118.744952, 34.032103],
                    [-118.732391, 34.032743],
                    [-118.706215, 34.029383],
                    [-118.679366, 34.033255],
                    [-118.67543, 34.037479],
                    [-118.668358, 34.038887],
                    [-118.609652, 34.036424],
                    [-118.603572, 34.039048],
                    [-118.569235, 34.04164],
                    [-118.543115, 34.038508],
                    [-118.519514, 34.027509],
                    [-118.502813, 34.015509],
                    [-118.484212, 33.99771],
                    [-118.482729, 33.995912],
                    [-118.460611, 33.969111],
                    [-118.44241, 33.940312],
                    [-118.412708, 33.883913],
                    [-118.392107, 33.840915],
                    [-118.391507, 33.815415],
                    [-118.394307, 33.804315],
                    [-118.394376, 33.804289],
                    [-118.405007, 33.800215],
                    [-118.423407, 33.782015],
                    [-118.428407, 33.774715],
                    [-118.411211, 33.741985],
                    [-118.396606, 33.735917],
                    [-118.385006, 33.741417],
                    [-118.360505, 33.736817],
                    [-118.354705, 33.732317],
                    [-118.317205, 33.712818],
                    [-118.297104, 33.708319],
                    [-118.277208, 33.707091],
                    [-118.258687, 33.703741],
                    [-118.207476, 33.716905],
                    [-118.181367, 33.717367],
                    [-118.1837, 33.736118],
                    [-118.187701, 33.749218],
                    [-118.180831, 33.763072],
                    [-118.1755, 33.763617],
                    [-118.1569, 33.760317],
                    [-118.132698, 33.753217],
                    [-118.116703, 33.743549],
                    [-118.101097, 33.734117],
                    [-118.088896, 33.729817],
                    [-118.064895, 33.711018],
                    [-118.029694, 33.676418],
                    [-118.000593, 33.654319],
                    [-117.957114, 33.629466],
                    [-117.940591, 33.620021],
                    [-117.927091, 33.605521],
                    [-117.89979, 33.599622],
                    [-117.87679, 33.592322],
                    [-117.840289, 33.573523],
                    [-117.814188, 33.552224],
                    [-117.801288, 33.546324],
                    [-117.784888, 33.541525],
                    [-117.761387, 33.516326],
                    [-117.726486, 33.483427],
                    [-117.715349, 33.460556],
                    [-117.691384, 33.454028],
                    [-117.691984, 33.456627],
                    [-117.689284, 33.460155],
                    [-117.684584, 33.461927],
                    [-117.645592, 33.440733],
                    [-117.645582, 33.440728],
                    [-117.631682, 33.430528],
                    [-117.607905, 33.406317],
                    [-117.59588, 33.386629],
                    [-117.571722, 33.378988],
                    [-117.547693, 33.365491],
                    [-117.50565, 33.334063],
                    [-117.469794, 33.296417],
                    [-117.445583, 33.268517],
                    [-117.401926, 33.213598],
                    [-117.39148, 33.202762],
                    [-117.362572, 33.168437],
                    [-117.359484, 33.164231],
                    [-117.328359, 33.121842],
                    [-117.315278, 33.093504],
                    [-117.309771, 33.07454],
                    [-117.29337, 33.034642],
                    [-117.28077, 33.012343],
                    [-117.262547, 32.939542],
                    [-117.26047, 32.931245],
                    [-117.25447, 32.900146],
                    [-117.25167, 32.874346],
                    [-117.25617, 32.859447],
                    [-117.26067, 32.852647],
                    [-117.26497, 32.848947],
                    [-117.27387, 32.851447],
                    [-117.28117, 32.843047],
                    [-117.28217, 32.839547],
                    [-117.280971, 32.822247],
                    [-117.26107, 32.803148],
                    [-117.25497, 32.786948],
                    [-117.25257, 32.752949],
                    [-117.25537, 32.745449],
                    [-117.25757, 32.72605],
                    [-117.255169, 32.700051],
                    [-117.246069, 32.669352],
                    [-117.236239, 32.671353],
                    [-117.223868, 32.683051],
                    [-117.213068, 32.687751],
                    [-117.196767, 32.688851],
                    [-117.192967, 32.687751],
                    [-117.180366, 32.681652],
                    [-117.168866, 32.671952],
                    [-117.159865, 32.660652],
                    [-117.139464, 32.627054],
                    [-117.136664, 32.618754],
                    [-117.132963, 32.597054],
                    [-117.133363, 32.575625],
                    [-117.124862, 32.534156],
                    [-117.118865, 32.534661],
                    [-116.390091, 32.59602],
                    [-116.106159, 32.618328],
                    [-115.875842, 32.636424],
                    [-115.465164, 32.6671],
                    [-114.719633, 32.718763],
                    [-114.678632, 32.736614],
                    [-114.677091, 32.736218],
                    [-114.65884, 32.73383],
                    [-114.65826, 32.733799],
                    [-114.615585, 32.728446],
                    [-114.615733, 32.729427],
                    [-114.496827, 32.822119],
                    [-114.496284, 32.822326],
                    [-114.465715, 32.879191],
                    [-114.465715, 32.87942],
                    [-114.46289, 32.905797],
                    [-114.468605, 32.971649],
                    [-114.511343, 33.023455],
                    [-114.516912, 33.026871],
                    [-114.523578, 33.030961],
                    [-114.571653, 33.036624],
                    [-114.578287, 33.035375],
                    [-114.584765, 33.028231],
                    [-114.589778, 33.026228],
                    [-114.606282, 33.025703],
                    [-114.62387, 33.02872],
                    [-114.675104, 33.047532],
                    [-114.707819, 33.091102],
                    [-114.706175, 33.105335],
                    [-114.696829, 33.131209],
                    [-114.687074, 33.142196],
                    [-114.679359, 33.159519],
                    [-114.67536, 33.185489],
                    [-114.672088, 33.258499],
                    [-114.700103, 33.341045],
                    [-114.721233, 33.396912],
                    [-114.665278, 33.415358],
                    [-114.627125, 33.433554],
                    [-114.622918, 33.456561],
                    [-114.594534, 33.495059],
                    [-114.558898, 33.531819],
                    [-114.535664, 33.568788],
                    [-114.535965, 33.569154],
                    [-114.5403, 33.580615],
                    [-114.540617, 33.591412],
                    [-114.530348, 33.679245],
                    [-114.523959, 33.685879],
                    [-114.519113, 33.688473],
                    [-114.496489, 33.696901],
                    [-114.494197, 33.707922],
                    [-114.50434, 33.756381],
                    [-114.523365, 33.80612],
                    [-114.525539, 33.838614],
                    [-114.518434, 33.917518],
                    [-114.499883, 33.961789],
                    [-114.46361, 33.993431],
                    [-114.438266, 34.022609],
                    [-114.434949, 34.037784],
                    [-114.435429, 34.079727],
                    [-114.43338, 34.088413],
                    [-114.420499, 34.103466],
                    [-114.411681, 34.110031],
                    [-114.401352, 34.111652],
                    [-114.390565, 34.110084],
                    [-114.366521, 34.118575],
                    [-114.321618, 34.138093],
                    [-114.312592, 34.144453],
                    [-114.244191, 34.179625],
                    [-114.225861, 34.201774],
                    [-114.164476, 34.251667],
                    [-114.133264, 34.258462],
                    [-114.131489, 34.260387],
                    [-114.138282, 34.30323],
                    [-114.176909, 34.349306],
                    [-114.199482, 34.361373],
                    [-114.264317, 34.401329],
                    [-114.339627, 34.451435],
                    [-114.452547, 34.653494],
                    [-114.465607, 34.69226],
                    [-114.552682, 34.766871],
                    [-114.633051, 34.869971],
                    [-114.635176, 34.875003],
                    [-114.636725, 34.889107],
                    [-114.630877, 34.907263],
                    [-114.629015, 34.986148],
                    [-114.62919, 34.991887],
                    [-114.633013, 35.002085],
                    [-114.633544, 35.015644],
                    [-114.635469, 35.028266],
                    [-114.621956, 35.094678],
                    [-114.59912, 35.12105],
                    [-114.5843, 35.124999],
                    [-114.578524, 35.12875],
                    [-114.572747, 35.138725],
                    [-114.569569, 35.163053],
                    [-114.569238, 35.18348],
                    [-114.595931, 35.325234],
                    [-114.604314, 35.353584],
                    [-114.627137, 35.409504],
                    [-114.652005, 35.429165],
                    [-114.662125, 35.444241],
                    [-114.677643, 35.489742],
                    [-114.676733, 35.499063],
                    [-114.674981, 35.510564],
                    [-114.671147, 35.520641],
                    [-114.653806, 35.59949],
                    [-114.653406, 35.610789],
                    [-114.657506, 35.618289],
                    [-114.676707, 35.640989],
                    [-114.689407, 35.651412],
                    [-114.700308, 35.700387],
                    [-114.70771, 35.811885],
                    [-114.705611, 35.848884],
                    [-114.704211, 35.851984],
                    [-114.70613, 35.878712],
                    [-114.731296, 35.945157],
                    [-114.743576, 35.983721],
                    [-114.755618, 36.087166],
                    [-114.744857, 36.098693],
                    [-114.736165, 36.104367],
                    [-114.631716, 36.142306],
                    [-114.572031, 36.15161],
                    [-114.513256, 36.151217],
                    [-114.381446, 36.141665],
                    [-114.365835, 36.133722],
                    [-114.328777, 36.105501],
                    [-114.310857, 36.083787],
                    [-114.307235, 36.076544],
                    [-114.303614, 36.066456],
                    [-114.292663, 36.051118],
                    [-114.263146, 36.025937],
                    [-114.252651, 36.020193],
                    [-114.238799, 36.014561],
                    [-114.233289, 36.014289],
                    [-114.21369, 36.015613],
                    [-114.15413, 36.023862],
                    [-114.148774, 36.02731],
                    [-114.138202, 36.041284],
                    [-114.125796, 36.077531],
                    [-114.120193, 36.102228],
                    [-114.063021, 36.186942],
                    [-114.060302, 36.189363],
                    [-114.046838, 36.194069],
                    [-114.047106, 36.250591],
                    [-114.048515, 36.289598],
                    [-114.046403, 36.371873],
                    [-114.046488, 36.473449],
                    [-114.050562, 36.656259],
                    [-114.050578, 36.800173],
                    [-114.050583, 36.843141],
                    [-114.0506, 37.000396],
                    [-114.051749, 37.088434],
                    [-114.051822, 37.090976],
                    [-114.051974, 37.283848],
                    [-114.051974, 37.284511],
                    [-114.0518, 37.293044],
                    [-114.0518, 37.293548],
                    [-114.051927, 37.370459],
                    [-114.051927, 37.370734],
                    [-114.052701, 37.492014],
                    [-114.052685, 37.502513],
                    [-114.052718, 37.517264],
                    [-114.052689, 37.517859],
                    [-114.052472, 37.604776],
                    [-114.051728, 37.745997],
                    [-114.051785, 37.746249],
                    [-114.05167, 37.746958],
                    [-114.051109, 37.756276],
                    [-114.048473, 37.809861],
                    [-114.050423, 37.999961],
                    [-114.049903, 38.148601],
                    [-114.05012, 38.404536],
                    [-114.050091, 38.404673],
                    [-114.049834, 38.543784],
                    [-114.049862, 38.547764],
                    [-114.050154, 38.57292],
                    [-114.049893, 38.677365],
                    [-114.047728, 39.542742],
                    [-114.047134, 39.906037],
                    [-114.046555, 40.116931],
                    [-114.045518, 40.494474],
                    [-114.045577, 40.495801],
                    [-114.045281, 40.506586],
                    [-114.043505, 40.726292],
                    [-114.043831, 40.758666],
                    [-114.043803, 40.759205],
                    [-114.042145, 40.999926],
                    [-114.039882, 41.741991],
                    [-114.041152, 41.850595],
                    [-114.041107, 41.850573],
                    [-114.041723, 41.99372],
                    [-113.893261, 41.988057],
                    [-113.796082, 41.989104],
                    [-113.76453, 41.989459],
                    [-113.431563, 41.993799],
                    [-113.40223, 41.994161],
                    [-113.396497, 41.99425],
                    [-113.000821, 41.998223],
                    [-113.00082, 41.998223],
                    [-112.979218, 41.998263],
                    [-112.909587, 41.998791],
                    [-112.882367, 41.998922],
                    [-112.880619, 41.998921],
                    [-112.833125, 41.999345],
                    [-112.833084, 41.999305],
                    [-112.788542, 41.999681],
                    [-112.709375, 42.000309],
                    [-112.648019, 42.000307],
                    [-112.450814, 42.000953],
                    [-112.450567, 42.001092],
                    [-112.38617, 42.001126],
                    [-112.264936, 42.000991],
                    [-112.239107, 42.001217],
                    [-112.163956, 41.996708],
                    [-112.109528, 41.997105],
                    [-111.915837, 41.998519],
                    [-111.915622, 41.998496],
                    [-111.876491, 41.998528],
                    [-111.507264, 41.999518],
                    [-111.425535, 42.00084],
                    [-111.420898, 42.000793],
                    [-111.415873, 42.000748],
                    [-111.046689, 42.001567],
                    [-111.047109, 42.142497],
                    [-111.047107, 42.148971],
                    [-111.047058, 42.182672],
                    [-111.047097, 42.194773],
                    [-111.047074, 42.280787],
                    [-111.04708, 42.34942],
                    [-111.046249, 42.513116],
                    [-111.043959, 42.96445],
                    [-111.043957, 42.969482],
                    [-111.043924, 42.975063],
                    [-111.044129, 43.018702],
                    [-111.044156, 43.020052],
                    [-111.044206, 43.022614],
                    [-111.044034, 43.024581],
                    [-111.044034, 43.024844],
                    [-111.044033, 43.026411],
                    [-111.044094, 43.02927],
                    [-111.043997, 43.041415],
                    [-111.044058, 43.04464],
                    [-111.044063, 43.046302],
                    [-111.044086, 43.054819],
                    [-111.044117, 43.060309],
                    [-111.04415, 43.066172],
                    [-111.044162, 43.068222],
                    [-111.044143, 43.072364],
                    [-111.044235, 43.177121],
                    [-111.044266, 43.177236],
                    [-111.044232, 43.18444],
                    [-111.044168, 43.189244],
                    [-111.044229, 43.195579],
                    [-111.044617, 43.31572],
                    [-111.045205, 43.501136],
                    [-111.045706, 43.659112],
                    [-111.04588, 43.681033],
                    [-111.046118, 43.684902],
                    [-111.046051, 43.685812],
                    [-111.04611, 43.687848],
                    [-111.046421, 43.722059],
                    [-111.046435, 43.726545],
                    [-111.04634, 43.726957],
                    [-111.046715, 43.815832],
                    [-111.048107, 43.983096],
                    [-111.048751, 44.060403],
                    [-111.048751, 44.060838],
                    [-111.048633, 44.062903],
                    [-111.049216, 44.435811],
                    [-111.049194, 44.438058],
                    [-111.048974, 44.474072],
                    [-111.055208, 44.624927],
                    [-111.055495, 44.666262],
                    [-111.056888, 44.866658],
                    [-111.055629, 44.933578],
                    [-111.056207, 44.935901],
                    [-111.044275, 45.001345],
                    [-110.875772, 45.002101],
                    [-110.750767, 44.997948],
                    [-110.705272, 44.992324],
                    [-110.552433, 44.992237],
                    [-110.547165, 44.992459],
                    [-110.221343, 44.996176],
                    [-110.110103, 45.003905],
                    [-110.026347, 45.003665],
                    [-110.025544, 45.003602],
                    [-109.798687, 45.002188],
                    [-109.269294, 45.005283],
                    [-109.263431, 45.005345],
                    [-109.103445, 45.005904],
                    [-109.08301, 44.99961],
                    [-109.062262, 44.999623],
                    [-108.621313, 45.000408],
                    [-108.578484, 45.000484],
                    [-108.565921, 45.000578],
                    [-108.249345, 44.999458],
                    [-108.125666, 45.001011],
                    [-107.911495, 45.000948],
                    [-107.608854, 45.00086],
                    [-107.607824, 45.000929],
                    [-107.13418, 45.000109],
                    [-107.080624, 44.996791],
                    [-106.892875, 44.995947],
                    [-106.888773, 44.995885],
                    [-106.263586, 44.993788],
                    [-106.024814, 44.993688],
                    [-105.928184, 44.993647],
                    [-105.918995, 44.997771],
                    [-105.848065, 45.000396],
                    [-105.076607, 45.000347],
                    [-105.038405, 45.000345],
                    [-105.025266, 45.00029],
                    [-105.019284, 45.000329],
                    [-105.01824, 45.000437],
                    [-104.765063, 44.999183],
                    [-104.759855, 44.999066],
                    [-104.665171, 44.998618],
                    [-104.663882, 44.998869],
                    [-104.470422, 44.998453],
                    [-104.470117, 44.998453],
                    [-104.057698, 44.997431],
                    [-104.040128, 44.999987],
                    [-104.040274, 45.212891],
                    [-104.040358, 45.335946],
                    [-104.040265, 45.345356],
                    [-104.040114, 45.374214],
                    [-104.04041, 45.393474],
                    [-104.041764, 45.490789],
                    [-104.041274, 45.499994],
                    [-104.041145, 45.503367],
                    [-104.041717, 45.539122],
                    [-104.041647, 45.550691],
                    [-104.041937, 45.557915],
                    [-104.043814, 45.868385],
                    [-104.044009, 45.871974],
                    [-104.04403, 45.881971],
                    [-104.04403, 45.881975],
                    [-104.045443, 45.94531],
                    [-104.04667, 46.000001],
                    [-104.045633, 46.280188],
                    [-104.045469, 46.324545],
                    [-104.045462, 46.341895],
                    [-104.045335, 46.540929],
                    [-104.045271, 46.641443],
                    [-104.045271, 46.641449],
                    [-104.045474, 46.708738],
                    [-104.045572, 46.713881],
                    [-104.04537, 46.721332],
                    [-104.045403, 46.722177],
                    [-104.045402, 46.725423],
                    [-104.045542, 46.933887],
                    [-104.045535, 46.934009],
                    [-104.045566, 46.941231],
                    [-104.045076, 47.037589],
                    [-104.045052, 47.040863],
                    [-104.045195, 47.053639],
                    [-104.045227, 47.057502],
                    [-104.045259, 47.063901],
                    [-104.045354, 47.078574],
                    [-104.045018, 47.081202],
                    [-104.045081, 47.092813],
                    [-104.045159, 47.263874],
                    [-104.045091, 47.265953],
                    [-104.045057, 47.266868],
                    [-104.045088, 47.271406],
                    [-104.045155, 47.27393],
                    [-104.045121, 47.276969],
                    [-104.045307, 47.330128],
                    [-104.045313, 47.331955],
                    [-104.045333, 47.343452],
                    [-104.045069, 47.397461],
                    [-104.044797, 47.438445],
                    [-104.044621, 47.45938],
                    [-104.043242, 47.747106],
                    [-104.043199, 47.747292],
                    [-104.042384, 47.803256],
                    [-104.042432, 47.805358],
                    [-104.042567, 47.808237],
                    [-104.041869, 47.841699],
                    [-104.041662, 47.862282],
                    [-104.04223, 47.891031],
                    [-104.043329, 47.949554],
                    [-104.043497, 47.95449],
                    [-104.043933, 47.971515],
                    [-104.044162, 47.992836],
                    [-104.04412, 47.996107],
                    [-104.045399, 48.16439],
                    [-104.045498, 48.176249],
                    [-104.045424, 48.192473],
                    [-104.04556, 48.193913],
                    [-104.045692, 48.241415],
                    [-104.045729, 48.244586],
                    [-104.045645, 48.246179],
                    [-104.045861, 48.255097],
                    [-104.046039, 48.256761],
                    [-104.046332, 48.34229],
                    [-104.046371, 48.374154],
                    [-104.046654, 48.374773],
                    [-104.046913, 48.389429],
                    [-104.046913, 48.389433],
                    [-104.046969, 48.390675],
                    [-104.047134, 48.411057],
                    [-104.04696, 48.421065],
                    [-104.04709, 48.445903],
                    [-104.047192, 48.447251],
                    [-104.047294, 48.452529],
                    [-104.047259, 48.452941],
                    [-104.047392, 48.467086],
                    [-104.047555, 48.49414],
                    [-104.047675, 48.517852],
                    [-104.047513, 48.525913],
                    [-104.047876, 48.530798],
                    [-104.047648, 48.531489],
                    [-104.047783, 48.539737],
                    [-104.047811, 48.56277],
                    [-104.047974, 48.591606],
                    [-104.048212, 48.599055],
                    [-104.04793, 48.62019],
                    [-104.047586, 48.625644],
                    [-104.04762, 48.627015],
                    [-104.047582, 48.633976],
                    [-104.047582, 48.633984],
                    [-104.047819, 48.648631],
                    [-104.047887, 48.649911],
                    [-104.047865, 48.65745],
                    [-104.047861, 48.658856],
                    [-104.047849, 48.663163],
                    [-104.047883, 48.664191],
                    [-104.04834, 48.747133],
                    [-104.048548, 48.751356],
                    [-104.048537, 48.788552],
                    [-104.048569, 48.797052],
                    [-104.0489, 48.847387],
                    [-104.048652, 48.865734],
                    [-104.048824, 48.867539],
                    [-104.048883, 48.874008],
                    [-104.048893, 48.875739],
                    [-104.048719, 48.879921],
                    [-104.048643, 48.902609],
                    [-104.048746, 48.906858],
                    [-104.048744, 48.912113],
                    [-104.048807, 48.933636],
                    [-104.048701, 48.940331],
                    [-104.04877, 48.943301],
                    [-104.048872, 48.94963],
                    [-104.048698, 48.951823],
                    [-104.048627, 48.957124],
                    [-104.0488, 48.958997],
                    [-104.048555, 48.963772],
                    [-104.048616, 48.966736],
                    [-104.048478, 48.987007],
                    [-104.048736, 48.999877],
                    [-104.543636, 48.999541],
                    [-104.647389, 48.999344],
                    [-105.057514, 48.999448],
                    [-105.265192, 48.9995],
                    [-105.277521, 48.999457],
                    [-105.355888, 48.999357],
                    [-105.38749, 48.999382],
                    [-105.391379, 48.999475],
                    [-105.407909, 48.99948],
                    [-105.411972, 48.999582],
                    [-105.522636, 48.999591],
                    [-105.578616, 48.999673],
                    [-105.607542, 48.999624],
                    [-105.612577, 48.999703],
                    [-105.65027, 48.999444],
                    [-105.775808, 48.999637],
                    [-105.834181, 48.999707],
                    [-105.966197, 48.999445],
                    [-106.050543, 48.999207],
                    [-106.112108, 48.999279],
                    [-106.233987, 48.999423],
                    [-106.243154, 48.999373],
                    [-106.24621, 48.999258],
                    [-106.274267, 48.999312],
                    [-106.368151, 48.999503],
                    [-106.374616, 48.999617],
                    [-106.500592, 48.999756],
                    [-106.518201, 48.999564],
                    [-106.617539, 48.999583],
                    [-106.625597, 48.99964],
                    [-107.179865, 48.999559],
                    [-108.236495, 48.999405],
                    [-108.488063, 48.999368],
                    [-108.543194, 48.999377],
                    [-108.994722, 48.999237],
                    [-109.000708, 48.999234],
                    [-109.060292, 48.999621],
                    [-109.06057, 48.999666],
                    [-109.384068, 49.000374],
                    [-109.384762, 49.000397],
                    [-109.489686, 49.000267],
                    [-109.995618, 48.999642],
                    [-110.171595, 48.999262],
                    [-110.215516, 48.999197],
                    [-110.216135, 48.999239],
                    [-110.743209, 48.998363],
                    [-110.886706, 48.998124],
                    [-110.887459, 48.998087],
                    [-111.270702, 48.997895],
                    [-111.761613, 48.99765],
                    [-111.761679, 48.997614],
                    [-111.85409, 48.998039],
                    [-111.854088, 48.998067],
                    [-112.193588, 48.998229],
                    [-113.009895, 48.998619],
                    [-113.011041, 48.998643],
                    [-113.085576, 48.998581],
                    [-113.087863, 48.998557],
                    [-113.092055, 48.998543],
                    [-113.095436, 48.998533],
                    [-113.098147, 48.998494],
                    [-113.103212, 48.99853],
                    [-113.106891, 48.998501],
                    [-113.110155, 48.99855],
                    [-113.116356, 48.998462],
                    [-113.692982, 48.997632],
                    [-114.068151, 48.999364],
                    [-114.43883, 49.001076],
                    [-114.674398, 49.000679],
                    [-114.678217, 49.000725],
                    [-114.728125, 49.000623],
                    [-115.251126, 48.999557],
                    [-116.049193, 49.000912],
                    [-116.757234, 48.999943],
                    [-116.757185, 48.999791],
                    [-117.032351, 48.999188],
                    [-117.268247, 48.999818],
                    [-117.268192, 48.999928],
                    [-117.429539, 48.999957],
                    [-118.196891, 49.000094],
                    [-118.836794, 49.000209],
                    [-119.132102, 49.000262],
                    [-119.137274, 49.000297],
                    [-119.428678, 49.000253],
                    [-119.4577, 49.000261],
                    [-119.701218, 49.000258],
                    [-119.702016, 49.000269],
                    [-120.851202, 49.001191],
                    [-121.12624, 49.001412],
                    [-121.751252, 48.997399],
                    [-122.098357, 49.002146],
                    [-122.405989, 49.002239],
                    [-122.407829, 49.002193],
                    [-122.75802, 49.002357],
                    [-122.756037, 48.999512],
                    [-122.756318, 48.996881],
                    [-122.766307, 48.991672],
                    [-122.774276, 48.991038],
                    [-122.796887, 48.975026],
                    [-122.817226, 48.95597],
                    [-122.822464, 48.944911],
                    [-122.821631, 48.941369],
                    [-122.818232, 48.939062],
                    [-122.787539, 48.931702],
                    [-122.770432, 48.942528],
                    [-122.766096, 48.941955],
                    [-122.755624, 48.93866],
                    [-122.746596, 48.930731],
                    [-122.745371, 48.921227],
                    [-122.747514, 48.915582],
                    [-122.751289, 48.911239],
                    [-122.783747, 48.894639],
                    [-122.792584, 48.894732],
                    [-122.793175, 48.892927],
                    [-122.785659, 48.885066],
                    [-122.722685, 48.852855],
                    [-122.717073, 48.84719],
                    [-122.711805, 48.832408],
                    [-122.709169, 48.817829],
                    [-122.7112, 48.79146],
                    [-122.709815, 48.786205],
                    [-122.703106, 48.786321],
                    [-122.699303, 48.789063],
                    [-122.699507, 48.794906],
                    [-122.698675, 48.800522],
                    [-122.697219, 48.80281],
                    [-122.693683, 48.804475],
                    [-122.680246, 48.80275],
                    [-122.659708, 48.786523],
                    [-122.656528, 48.784969],
                    [-122.646777, 48.785011],
                    [-122.645743, 48.781538],
                    [-122.647443, 48.773998],
                    [-122.661111, 48.753962],
                    [-122.666953, 48.748445],
                    [-122.673472, 48.733082],
                    [-122.646323, 48.708001],
                    [-122.630422, 48.696625],
                    [-122.620338, 48.693651],
                    [-122.615169, 48.693839],
                    [-122.606105, 48.698556],
                    [-122.605733, 48.701066],
                    [-122.612562, 48.714932],
                    [-122.626287, 48.72093],
                    [-122.638082, 48.732486],
                    [-122.637146, 48.735708],
                    [-122.627808, 48.74466],
                    [-122.606787, 48.759143],
                    [-122.598033, 48.769489],
                    [-122.596844, 48.771492],
                    [-122.567498, 48.779185],
                    [-122.535803, 48.776128],
                    [-122.528203, 48.768428],
                    [-122.510902, 48.757728],
                    [-122.490401, 48.751128],
                    [-122.495301, 48.737328],
                    [-122.505684, 48.724524],
                    [-122.515511, 48.720992],
                    [-122.519172, 48.713095],
                    [-122.506718, 48.669692],
                    [-122.500308, 48.656163],
                    [-122.49399, 48.651596],
                    [-122.488754, 48.645358],
                    [-122.486878, 48.643122],
                    [-122.46425, 48.625717],
                    [-122.448702, 48.622624],
                    [-122.425271, 48.599522],
                    [-122.433059, 48.581609],
                    [-122.44456, 48.570115],
                    [-122.478431, 48.559303],
                    [-122.482406, 48.559653],
                    [-122.488421, 48.564665],
                    [-122.495904, 48.575927],
                    [-122.512372, 48.578067],
                    [-122.534787, 48.57596],
                    [-122.534719, 48.574246],
                    [-122.531978, 48.568644],
                    [-122.52537, 48.567344],
                    [-122.504428, 48.564775],
                    [-122.498463, 48.556206],
                    [-122.485288, 48.528106],
                    [-122.483872, 48.521891],
                    [-122.484996, 48.50962],
                    [-122.483501, 48.49243],
                    [-122.478851, 48.481736],
                    [-122.473763, 48.47975],
                    [-122.46967, 48.474975],
                    [-122.469634, 48.472187],
                    [-122.471832, 48.470724],
                    [-122.500721, 48.460887],
                    [-122.511348, 48.461825],
                    [-122.515056, 48.465554],
                    [-122.526943, 48.468004],
                    [-122.532845, 48.466057],
                    [-122.537355, 48.466749],
                    [-122.556834, 48.498812],
                    [-122.568071, 48.50821],
                    [-122.598469, 48.512169],
                    [-122.599951, 48.520946],
                    [-122.606961, 48.522152],
                    [-122.615183, 48.521427],
                    [-122.671386, 48.50398],
                    [-122.676922, 48.504484],
                    [-122.679122, 48.507797],
                    [-122.684521, 48.509123],
                    [-122.701644, 48.497622],
                    [-122.712981, 48.47879],
                    [-122.712322, 48.464143],
                    [-122.710362, 48.461584],
                    [-122.700603, 48.457632],
                    [-122.695587, 48.460558],
                    [-122.695725, 48.464785],
                    [-122.689121, 48.476849],
                    [-122.664623, 48.478128],
                    [-122.657753, 48.47294],
                    [-122.654844, 48.454087],
                    [-122.667249, 48.442503],
                    [-122.674085, 48.441979],
                    [-122.674188, 48.443327],
                    [-122.677072, 48.444059],
                    [-122.678928, 48.439466],
                    [-122.674158, 48.424726],
                    [-122.665338, 48.416453],
                    [-122.649839, 48.408526],
                    [-122.61448, 48.41488],
                    [-122.581607, 48.429244],
                    [-122.575254, 48.443333],
                    [-122.568348, 48.44499],
                    [-122.557298, 48.444438],
                    [-122.551221, 48.439465],
                    [-122.558403, 48.426758],
                    [-122.554536, 48.40604],
                    [-122.547492, 48.399889],
                    [-122.539449, 48.39719],
                    [-122.533452, 48.383409],
                    [-122.507437, 48.364666],
                    [-122.497686, 48.361837],
                    [-122.482423, 48.361737],
                    [-122.475529, 48.359912],
                    [-122.442678, 48.337934],
                    [-122.424102, 48.334346],
                    [-122.408718, 48.326413],
                    [-122.38431, 48.304123],
                    [-122.37821, 48.29759],
                    [-122.376818, 48.296099],
                    [-122.371693, 48.287839],
                    [-122.392058, 48.269628],
                    [-122.395328, 48.257187],
                    [-122.405757, 48.252193],
                    [-122.406516, 48.25183],
                    [-122.463962, 48.270541],
                    [-122.466803, 48.269604],
                    [-122.474494, 48.255227],
                    [-122.480925, 48.251706],
                    [-122.493448, 48.252043],
                    [-122.497727, 48.253389],
                    [-122.499648, 48.256611],
                    [-122.503786, 48.257045],
                    [-122.530996, 48.249821],
                    [-122.535209, 48.240213],
                    [-122.534431, 48.223005],
                    [-122.538916, 48.209683],
                    [-122.53722, 48.183745],
                    [-122.522576, 48.161712],
                    [-122.512031, 48.133931],
                    [-122.489986, 48.120617],
                    [-122.486736, 48.12095],
                    [-122.477983, 48.129048],
                    [-122.4675, 48.130353],
                    [-122.44966, 48.114041],
                    [-122.423703, 48.102941],
                    [-122.400692, 48.085255],
                    [-122.393413, 48.078472],
                    [-122.390787, 48.069477],
                    [-122.38769, 48.065189],
                    [-122.377114, 48.057568],
                    [-122.363107, 48.054546],
                    [-122.358375, 48.056133],
                    [-122.360345, 48.061527],
                    [-122.379481, 48.087384],
                    [-122.411649, 48.11321],
                    [-122.442383, 48.130841],
                    [-122.475803, 48.166792],
                    [-122.479008, 48.175703],
                    [-122.478535, 48.188087],
                    [-122.47025, 48.194007],
                    [-122.464801, 48.194767],
                    [-122.461888, 48.193137],
                    [-122.45493, 48.196639],
                    [-122.442051, 48.20935],
                    [-122.441731, 48.211776],
                    [-122.444508, 48.214522],
                    [-122.449513, 48.214736],
                    [-122.453618, 48.22683],
                    [-122.45371, 48.228859],
                    [-122.449605, 48.232598],
                    [-122.433767, 48.23655],
                    [-122.430578, 48.236237],
                    [-122.425572, 48.232887],
                    [-122.396121, 48.229233],
                    [-122.395499, 48.228551],
                    [-122.385703, 48.217811],
                    [-122.382102, 48.207106],
                    [-122.372492, 48.193022],
                    [-122.362044, 48.187568],
                    [-122.363479, 48.174438],
                    [-122.370253, 48.164809],
                    [-122.364744, 48.151304],
                    [-122.363797, 48.142759],
                    [-122.365078, 48.125822],
                    [-122.363842, 48.12393],
                    [-122.343241, 48.097631],
                    [-122.326119, 48.092877],
                    [-122.321709, 48.085507],
                    [-122.305838, 48.073415],
                    [-122.281087, 48.049793],
                    [-122.231761, 48.029876],
                    [-122.224979, 48.016626],
                    [-122.228767, 48.012468],
                    [-122.23022, 48.007154],
                    [-122.232391, 47.987713],
                    [-122.226346, 47.976417],
                    [-122.230046, 47.970917],
                    [-122.249007, 47.959507],
                    [-122.278047, 47.956517],
                    [-122.307048, 47.949117],
                    [-122.311148, 47.936717],
                    [-122.309747, 47.929117],
                    [-122.310747, 47.925117],
                    [-122.311927, 47.923703],
                    [-122.321847, 47.911817],
                    [-122.328546, 47.897917],
                    [-122.333543, 47.880246],
                    [-122.330145, 47.875318],
                    [-122.329545, 47.869418],
                    [-122.33595, 47.852306],
                    [-122.339944, 47.846718],
                    [-122.346544, 47.842418],
                    [-122.353244, 47.840618],
                    [-122.392044, 47.807718],
                    [-122.394944, 47.803318],
                    [-122.397043, 47.779719],
                    [-122.396422, 47.777927],
                    [-122.394442, 47.772219],
                    [-122.380241, 47.758519],
                    [-122.382641, 47.749119],
                    [-122.37314, 47.729219],
                    [-122.375607, 47.719724],
                    [-122.37644, 47.716519],
                    [-122.38044, 47.709119],
                    [-122.393248, 47.701602],
                    [-122.403841, 47.689419],
                    [-122.407841, 47.680119],
                    [-122.429841, 47.658919],
                    [-122.414645, 47.639766],
                    [-122.40424, 47.63392],
                    [-122.393739, 47.63102],
                    [-122.386039, 47.63172],
                    [-122.367819, 47.624213],
                    [-122.344937, 47.60912],
                    [-122.339513, 47.599113],
                    [-122.342937, 47.59122],
                    [-122.358238, 47.58482],
                    [-122.370167, 47.583087],
                    [-122.375421, 47.585181],
                    [-122.387139, 47.59572],
                    [-122.401839, 47.58392],
                    [-122.421139, 47.57602],
                    [-122.409839, 47.56892],
                    [-122.398338, 47.55012],
                    [-122.393938, 47.52482],
                    [-122.396538, 47.51522],
                    [-122.386637, 47.50222],
                    [-122.365236, 47.48842],
                    [-122.361336, 47.481421],
                    [-122.363062, 47.475702],
                    [-122.368036, 47.459221],
                    [-122.383136, 47.450521],
                    [-122.367036, 47.447621],
                    [-122.355135, 47.441921],
                    [-122.348035, 47.415921],
                    [-122.335234, 47.408421],
                    [-122.328434, 47.400621],
                    [-122.325734, 47.391521],
                    [-122.324833, 47.348521],
                    [-122.336934, 47.341421],
                    [-122.364168, 47.335953],
                    [-122.418268, 47.320614],
                    [-122.423535, 47.319121],
                    [-122.443008, 47.306333],
                    [-122.444635, 47.300421],
                    [-122.432335, 47.296021],
                    [-122.424235, 47.297521],
                    [-122.413735, 47.293921],
                    [-122.409199, 47.288556],
                    [-122.418074, 47.281765],
                    [-122.429605, 47.269707],
                    [-122.437727, 47.268046],
                    [-122.4442, 47.266723],
                    [-122.471652, 47.277321],
                    [-122.533338, 47.31662],
                    [-122.540238, 47.31852],
                    [-122.547408, 47.317734],
                    [-122.547747, 47.316403],
                    [-122.571239, 47.315619],
                    [-122.573739, 47.318419],
                    [-122.575985, 47.32642],
                    [-122.57134, 47.327219],
                    [-122.55584, 47.347519],
                    [-122.551536, 47.35954],
                    [-122.537935, 47.359203],
                    [-122.52603, 47.358908],
                    [-122.528129, 47.345542],
                    [-122.52416, 47.33837],
                    [-122.51885, 47.33332],
                    [-122.504918, 47.33068],
                    [-122.493123, 47.330253],
                    [-122.491063, 47.332427],
                    [-122.491237, 47.335172],
                    [-122.469703, 47.344623],
                    [-122.457494, 47.342567],
                    [-122.453998, 47.343337],
                    [-122.448399, 47.354987],
                    [-122.437809, 47.365607],
                    [-122.401768, 47.381325],
                    [-122.378482, 47.38533],
                    [-122.373629, 47.388718],
                    [-122.395054, 47.399277],
                    [-122.427327, 47.402129],
                    [-122.437657, 47.407424],
                    [-122.440083, 47.417647],
                    [-122.43984, 47.458083],
                    [-122.433386, 47.46643],
                    [-122.460029, 47.486861],
                    [-122.460503, 47.494773],
                    [-122.452399, 47.503471],
                    [-122.467796, 47.509956],
                    [-122.484993, 47.512731],
                    [-122.494882, 47.510265],
                    [-122.500543, 47.51528],
                    [-122.52305, 47.524],
                    [-122.532909, 47.522184],
                    [-122.546611, 47.52355],
                    [-122.547207, 47.528257],
                    [-122.542355, 47.53784],
                    [-122.543118, 47.556326],
                    [-122.534664, 47.566122],
                    [-122.529915, 47.568441],
                    [-122.518367, 47.57408],
                    [-122.503672, 47.575178],
                    [-122.479089, 47.583654],
                    [-122.483805, 47.586721],
                    [-122.493933, 47.588963],
                    [-122.49824, 47.598242],
                    [-122.500357, 47.617816],
                    [-122.494518, 47.623625],
                    [-122.492809, 47.629591],
                    [-122.493205, 47.635122],
                    [-122.502116, 47.639074],
                    [-122.518277, 47.65132],
                    [-122.508709, 47.670843],
                    [-122.504452, 47.685888],
                    [-122.504604, 47.699136],
                    [-122.511196, 47.708715],
                    [-122.523962, 47.708034],
                    [-122.525851, 47.705095],
                    [-122.53094, 47.704814],
                    [-122.543161, 47.710941],
                    [-122.554454, 47.745704],
                    [-122.537318, 47.74714],
                    [-122.519325, 47.74622],
                    [-122.515193, 47.743911],
                    [-122.507638, 47.74304],
                    [-122.488491, 47.743605],
                    [-122.477344, 47.746019],
                    [-122.471844, 47.749819],
                    [-122.470333, 47.757109],
                    [-122.471402, 47.765965],
                    [-122.495458, 47.786692],
                    [-122.495346, 47.79704],
                    [-122.485214, 47.804128],
                    [-122.482437, 47.809255],
                    [-122.482529, 47.815511],
                    [-122.502224, 47.826395],
                    [-122.506122, 47.831745],
                    [-122.512778, 47.863879],
                    [-122.513986, 47.880807],
                    [-122.527593, 47.905882],
                    [-122.549072, 47.919072],
                    [-122.581846, 47.920282],
                    [-122.592184, 47.922519],
                    [-122.601507, 47.931726],
                    [-122.603861, 47.940478],
                    [-122.611956, 47.940772],
                    [-122.617022, 47.938987],
                    [-122.620316, 47.931553],
                    [-122.616701, 47.925139],
                    [-122.596228, 47.92021],
                    [-122.588235, 47.912923],
                    [-122.586339, 47.902023],
                    [-122.573098, 47.874081],
                    [-122.573672, 47.857582],
                    [-122.608105, 47.856728],
                    [-122.614585, 47.850806],
                    [-122.623192, 47.836199],
                    [-122.648108, 47.825123],
                    [-122.682015, 47.800882],
                    [-122.684085, 47.798574],
                    [-122.690562, 47.778372],
                    [-122.714801, 47.768176],
                    [-122.719712, 47.760976],
                    [-122.722686, 47.748827],
                    [-122.733012, 47.737625],
                    [-122.740159, 47.736228],
                    [-122.749199, 47.741063],
                    [-122.758498, 47.746036],
                    [-122.757885, 47.757744],
                    [-122.75054, 47.773966],
                    [-122.748061, 47.800546],
                    [-122.731956, 47.809741],
                    [-122.719609, 47.813036],
                    [-122.688596, 47.831438],
                    [-122.683742, 47.838773],
                    [-122.681602, 47.850405],
                    [-122.690974, 47.860118],
                    [-122.69376, 47.868002],
                    [-122.666417, 47.867497],
                    [-122.650083, 47.86386],
                    [-122.63636, 47.866186],
                    [-122.633879, 47.868401],
                    [-122.631857, 47.874815],
                    [-122.610341, 47.887343],
                    [-122.618873, 47.890242],
                    [-122.637425, 47.889945],
                    [-122.646494, 47.894771],
                    [-122.655085, 47.905058],
                    [-122.65399, 47.91589],
                    [-122.651063, 47.920985],
                    [-122.657722, 47.931156],
                    [-122.66238, 47.9307],
                    [-122.681924, 47.936415],
                    [-122.68445, 47.939593],
                    [-122.684688, 47.944049],
                    [-122.676215, 47.958743],
                    [-122.6788, 47.96793],
                    [-122.683223, 47.972226],
                    [-122.701294, 47.972979],
                    [-122.718082, 47.987739],
                    [-122.723374, 48.008095],
                    [-122.70184, 48.016106],
                    [-122.697185, 48.014978],
                    [-122.690066, 48.00842],
                    [-122.686898, 48.008305],
                    [-122.669868, 48.017217],
                    [-122.668942, 48.032026],
                    [-122.677153, 48.036346],
                    [-122.682264, 48.042723],
                    [-122.69222, 48.087081],
                    [-122.69164, 48.096726],
                    [-122.68724, 48.101662],
                    [-122.698465, 48.103102],
                    [-122.718558, 48.097567],
                    [-122.733257, 48.091232],
                    [-122.748345, 48.072097],
                    [-122.747389, 48.070795],
                    [-122.741184, 48.070958],
                    [-122.739271, 48.069153],
                    [-122.740007, 48.054116],
                    [-122.74229, 48.049324],
                    [-122.766648, 48.04429],
                    [-122.770496, 48.047897],
                    [-122.770559, 48.053432],
                    [-122.801399, 48.087561],
                    [-122.798464, 48.092451],
                    [-122.792902, 48.09718],
                    [-122.778466, 48.106135],
                    [-122.773177, 48.106864],
                    [-122.748911, 48.117026],
                    [-122.760448, 48.14324],
                    [-122.784076, 48.142974],
                    [-122.833173, 48.134406],
                    [-122.876282, 48.110877],
                    [-122.882013, 48.100779],
                    [-122.878255, 48.076072],
                    [-122.857727, 48.065774],
                    [-122.849273, 48.053808],
                    [-122.877641, 48.047025],
                    [-122.918602, 48.058238],
                    [-122.926851, 48.064593],
                    [-122.927146, 48.065133],
                    [-122.927975, 48.06665],
                    [-122.926644, 48.0741],
                    [-122.920911, 48.088199],
                    [-122.917942, 48.091535],
                    [-122.929095, 48.096244],
                    [-122.946119, 48.098552],
                    [-122.979413, 48.09594],
                    [-123.004128, 48.090516],
                    [-123.016651, 48.08538],
                    [-123.038727, 48.081138],
                    [-123.050446, 48.102825],
                    [-123.06621, 48.120469],
                    [-123.085154, 48.127137],
                    [-123.116479, 48.150208],
                    [-123.124816, 48.153472],
                    [-123.131422, 48.152736],
                    [-123.143229, 48.156633],
                    [-123.139258, 48.16648],
                    [-123.132417, 48.174704],
                    [-123.133445, 48.177276],
                    [-123.144783, 48.175943],
                    [-123.1644, 48.165894],
                    [-123.191521, 48.143821],
                    [-123.21719, 48.127203],
                    [-123.239129, 48.118217],
                    [-123.248615, 48.115745],
                    [-123.268917, 48.116094],
                    [-123.280178, 48.117309],
                    [-123.288265, 48.121036],
                    [-123.314578, 48.113725],
                    [-123.332699, 48.11297],
                    [-123.360923, 48.115864],
                    [-123.395048, 48.114243],
                    [-123.424668, 48.118065],
                    [-123.441972, 48.124259],
                    [-123.439127, 48.141278],
                    [-123.440128, 48.142014],
                    [-123.455458, 48.140047],
                    [-123.473379, 48.134079],
                    [-123.507235, 48.131807],
                    [-123.52232, 48.135539],
                    [-123.534879, 48.14578],
                    [-123.551131, 48.151382],
                    [-123.560591, 48.150697],
                    [-123.574214, 48.140756],
                    [-123.590839, 48.134949],
                    [-123.628819, 48.139279],
                    [-123.641108, 48.146127],
                    [-123.636967, 48.150319],
                    [-123.651408, 48.156952],
                    [-123.672445, 48.162715],
                    [-123.702743, 48.166783],
                    [-123.706432, 48.165822],
                    [-123.706226, 48.1634],
                    [-123.71835, 48.158713],
                    [-123.725352, 48.159191],
                    [-123.72829, 48.160858],
                    [-123.728736, 48.1628],
                    [-123.756395, 48.161057],
                    [-123.778122, 48.155466],
                    [-123.831571, 48.157937],
                    [-123.858821, 48.154273],
                    [-123.866677, 48.154796],
                    [-123.880068, 48.160621],
                    [-123.915589, 48.159352],
                    [-123.934921, 48.16084],
                    [-123.955347, 48.165455],
                    [-123.981032, 48.164761],
                    [-124.050734, 48.177747],
                    [-124.072124, 48.189903],
                    [-124.090717, 48.196458],
                    [-124.107215, 48.200082],
                    [-124.101773, 48.216883],
                    [-124.110974, 48.220557],
                    [-124.14129, 48.227413],
                    [-124.192692, 48.246316],
                    [-124.217873, 48.253294],
                    [-124.238582, 48.262471],
                    [-124.250882, 48.264773],
                    [-124.252267, 48.261004],
                    [-124.255109, 48.258972],
                    [-124.265824, 48.254842],
                    [-124.272017, 48.25441],
                    [-124.295693, 48.259282],
                    [-124.297643, 48.260676],
                    [-124.296924, 48.261796],
                    [-124.295589, 48.262983],
                    [-124.299146, 48.268239],
                    [-124.342412, 48.277695],
                    [-124.361351, 48.287582],
                    [-124.380874, 48.284699],
                    [-124.395593, 48.288772],
                    [-124.414007, 48.300887],
                    [-124.510582, 48.343236],
                    [-124.525453, 48.349022],
                    [-124.538821, 48.349893],
                    [-124.546259, 48.353594],
                    [-124.564841, 48.367921],
                    [-124.572864, 48.366228],
                    [-124.590733, 48.373604],
                    [-124.597331, 48.381882],
                    [-124.599278, 48.381035],
                    [-124.611782, 48.378182],
                    [-124.631108, 48.376522],
                    [-124.639389, 48.385524],
                    [-124.653243, 48.390691],
                    [-124.694511, 48.389004],
                    [-124.716947, 48.389776],
                    [-124.725839, 48.386012],
                    [-124.731828, 48.381157],
                    [-124.730863, 48.3762],
                    [-124.727022, 48.371101],
                    [-124.713817, 48.366309],
                    [-124.696703, 48.349748],
                    [-124.670072, 48.341341],
                    [-124.65894, 48.331057],
                    [-124.662068, 48.31045],
                    [-124.665908, 48.299324],
                    [-124.669265, 48.296353],
                    [-124.676319, 48.295143],
                    [-124.680877, 48.26535],
                    [-124.684677, 48.255228],
                    [-124.699663, 48.245812],
                    [-124.70592, 48.239894],
                    [-124.705031, 48.238774],
                    [-124.690389, 48.219745],
                    [-124.6909, 48.212617],
                    [-124.696111, 48.198599],
                    [-124.704153, 48.184422],
                    [-124.731746, 48.169997],
                    [-124.733174, 48.163393],
                ],
            ],
            [
                [
                    [-123.237148, 48.683466],
                    [-123.215917, 48.669352],
                    [-123.134956, 48.63724],
                    [-123.119677, 48.632972],
                    [-123.106165, 48.633473],
                    [-123.122016, 48.647065],
                    [-123.130962, 48.656789],
                    [-123.14799, 48.668001],
                    [-123.172066, 48.679866],
                    [-123.186076, 48.684917],
                    [-123.197953, 48.68466],
                    [-123.212892, 48.689713],
                    [-123.236567, 48.68895],
                    [-123.237148, 48.683466],
                ],
            ],
            [
                [
                    [-123.203026, 48.596178],
                    [-123.20268, 48.590214],
                    [-123.197754, 48.586216],
                    [-123.184941, 48.58697],
                    [-123.173061, 48.579086],
                    [-123.171958, 48.572255],
                    [-123.175852, 48.568483],
                    [-123.176266, 48.562131],
                    [-123.172412, 48.556486],
                    [-123.16147, 48.547618],
                    [-123.161853, 48.539255],
                    [-123.164057, 48.535622],
                    [-123.163234, 48.529544],
                    [-123.151065, 48.513955],
                    [-123.141478, 48.505291],
                    [-123.119451, 48.492576],
                    [-123.067675, 48.479497],
                    [-123.058154, 48.471522],
                    [-123.039156, 48.460003],
                    [-122.962009, 48.451161],
                    [-122.937881, 48.456221],
                    [-122.926901, 48.460874],
                    [-122.920099, 48.458428],
                    [-122.91646, 48.453263],
                    [-122.928004, 48.439966],
                    [-122.917771, 48.439781],
                    [-122.913888, 48.443231],
                    [-122.903214, 48.436979],
                    [-122.889016, 48.435947],
                    [-122.893646, 48.422655],
                    [-122.883759, 48.418793],
                    [-122.874135, 48.418196],
                    [-122.825803, 48.424131],
                    [-122.812208, 48.422326],
                    [-122.803521, 48.428748],
                    [-122.802509, 48.433098],
                    [-122.807708, 48.444058],
                    [-122.8131, 48.452856],
                    [-122.81973, 48.458843],
                    [-122.817912, 48.483888],
                    [-122.816332, 48.487841],
                    [-122.800414, 48.494467],
                    [-122.779124, 48.508911],
                    [-122.777467, 48.517799],
                    [-122.787347, 48.523012],
                    [-122.788503, 48.530393],
                    [-122.782618, 48.545191],
                    [-122.772384, 48.552143],
                    [-122.770349, 48.558106],
                    [-122.771206, 48.562426],
                    [-122.786586, 48.576666],
                    [-122.801096, 48.585425],
                    [-122.804869, 48.595932],
                    [-122.800217, 48.60169],
                    [-122.79877, 48.602352],
                    [-122.79901, 48.604683],
                    [-122.808864, 48.61531],
                    [-122.809622, 48.619035],
                    [-122.792147, 48.633502],
                    [-122.783875, 48.635419],
                    [-122.755031, 48.649512],
                    [-122.742082, 48.660689],
                    [-122.743049, 48.661991],
                    [-122.800267, 48.67962],
                    [-122.802545, 48.682682],
                    [-122.833124, 48.698173],
                    [-122.875938, 48.71212],
                    [-122.894599, 48.71503],
                    [-122.918252, 48.713505],
                    [-122.942367, 48.706723],
                    [-122.941316, 48.702904],
                    [-122.949116, 48.693398],
                    [-122.984853, 48.672686],
                    [-122.988884, 48.667246],
                    [-123.009924, 48.655064],
                    [-123.014829, 48.647503],
                    [-123.015592, 48.642567],
                    [-123.023495, 48.634001],
                    [-123.048652, 48.621002],
                    [-123.04653, 48.61149],
                    [-123.041189, 48.611947],
                    [-123.023433, 48.599477],
                    [-123.024902, 48.594484],
                    [-123.034101, 48.591767],
                    [-123.016647, 48.580244],
                    [-123.0048, 48.580788],
                    [-122.995026, 48.578162],
                    [-122.989649, 48.574668],
                    [-122.98611, 48.569984],
                    [-122.987296, 48.561895],
                    [-123.015046, 48.560821],
                    [-123.033669, 48.563409],
                    [-123.048403, 48.569216],
                    [-123.06004, 48.582105],
                    [-123.074611, 48.591816],
                    [-123.101552, 48.59782],
                    [-123.102074, 48.604035],
                    [-123.098254, 48.610092],
                    [-123.098462, 48.612834],
                    [-123.107362, 48.622451],
                    [-123.135645, 48.620171],
                    [-123.139705, 48.622786],
                    [-123.151643, 48.623686],
                    [-123.178425, 48.622115],
                    [-123.195725, 48.607055],
                    [-123.203026, 48.596178],
                ],
            ],
            [
                [
                    [-123.090546, 49.001976],
                    [-123.084498, 48.986535],
                    [-123.083834, 48.976139],
                    [-123.060717, 48.975388],
                    [-123.040967, 48.977305],
                    [-123.028091, 48.973943],
                    [-123.021459, 48.977299],
                    [-123.035393, 49.002154],
                    [-123.090546, 49.001976],
                ],
            ],
            [
                [
                    [-123.070427, 48.699971],
                    [-123.047058, 48.695772],
                    [-123.03636, 48.69008],
                    [-123.035672, 48.68535],
                    [-123.041645, 48.678633],
                    [-123.042337, 48.675663],
                    [-123.021215, 48.681416],
                    [-123.014449, 48.684978],
                    [-123.005086, 48.694342],
                    [-123.007511, 48.718863],
                    [-123.009787, 48.722291],
                    [-123.019699, 48.721312],
                    [-123.025486, 48.717966],
                    [-123.040179, 48.717296],
                    [-123.070427, 48.699971],
                ],
            ],
            [
                [
                    [-123.014303, 37.696205],
                    [-123.011464, 37.691907],
                    [-123.005543, 37.689392],
                    [-123.000677, 37.690203],
                    [-122.997189, 37.697909],
                    [-123.00019, 37.702937],
                    [-123.004489, 37.706262],
                    [-123.012194, 37.706749],
                    [-123.013897, 37.704478],
                    [-123.013916, 37.700355],
                    [-123.014303, 37.696205],
                ],
            ],
            [
                [
                    [-122.770045, 48.224395],
                    [-122.763042, 48.215342],
                    [-122.744612, 48.20965],
                    [-122.73503, 48.199964],
                    [-122.711508, 48.193573],
                    [-122.693084, 48.181509],
                    [-122.686626, 48.174653],
                    [-122.679556, 48.155113],
                    [-122.677337, 48.154587],
                    [-122.671235, 48.157312],
                    [-122.65602, 48.162513],
                    [-122.633167, 48.163281],
                    [-122.617464, 48.159055],
                    [-122.609568, 48.15186],
                    [-122.602109, 48.135249],
                    [-122.598301, 48.110616],
                    [-122.607291, 48.088034],
                    [-122.613217, 48.079485],
                    [-122.614028, 48.072788],
                    [-122.594922, 48.056318],
                    [-122.593621, 48.0472],
                    [-122.596786, 48.038834],
                    [-122.607342, 48.030992],
                    [-122.58178, 48.010386],
                    [-122.560018, 48.006502],
                    [-122.542924, 47.996404],
                    [-122.541564, 47.992998],
                    [-122.543063, 47.985983],
                    [-122.551032, 47.977346],
                    [-122.552053, 47.973644],
                    [-122.546824, 47.967215],
                    [-122.521219, 47.972997],
                    [-122.51778, 47.974916],
                    [-122.514813, 47.981152],
                    [-122.501257, 47.987089],
                    [-122.487505, 47.990729],
                    [-122.47266, 47.988449],
                    [-122.446682, 47.963155],
                    [-122.44076, 47.951845],
                    [-122.44079, 47.946594],
                    [-122.445759, 47.93619],
                    [-122.445519, 47.930226],
                    [-122.431035, 47.914732],
                    [-122.419274, 47.912125],
                    [-122.397349, 47.912401],
                    [-122.39042, 47.905696],
                    [-122.380497, 47.904023],
                    [-122.3773, 47.905941],
                    [-122.37578, 47.910252],
                    [-122.376837, 47.923703],
                    [-122.367876, 47.932415],
                    [-122.358812, 47.93742],
                    [-122.350741, 47.953235],
                    [-122.349597, 47.958796],
                    [-122.350254, 47.969355],
                    [-122.353611, 47.981433],
                    [-122.369161, 47.995295],
                    [-122.373263, 48.000791],
                    [-122.376259, 48.034457],
                    [-122.387382, 48.03403],
                    [-122.400628, 48.036563],
                    [-122.431266, 48.045001],
                    [-122.448419, 48.054323],
                    [-122.461606, 48.068501],
                    [-122.491104, 48.094242],
                    [-122.513276, 48.097538],
                    [-122.525422, 48.096537],
                    [-122.516906, 48.081085],
                    [-122.511081, 48.075301],
                    [-122.513994, 48.059077],
                    [-122.516314, 48.057181],
                    [-122.538953, 48.050232],
                    [-122.54512, 48.05255],
                    [-122.554559, 48.077392],
                    [-122.571853, 48.102143],
                    [-122.571615, 48.105113],
                    [-122.559911, 48.114186],
                    [-122.558205, 48.119579],
                    [-122.567936, 48.148624],
                    [-122.574905, 48.155593],
                    [-122.582595, 48.170424],
                    [-122.585778, 48.182352],
                    [-122.588138, 48.18594],
                    [-122.606406, 48.208262],
                    [-122.628352, 48.222467],
                    [-122.63126, 48.220686],
                    [-122.668385, 48.223967],
                    [-122.66921, 48.240614],
                    [-122.65343, 48.25934],
                    [-122.652639, 48.265081],
                    [-122.623779, 48.269431],
                    [-122.620748, 48.282961],
                    [-122.626757, 48.288991],
                    [-122.618466, 48.294159],
                    [-122.599532, 48.298303],
                    [-122.584086, 48.297987],
                    [-122.574872, 48.294903],
                    [-122.558332, 48.282061],
                    [-122.551793, 48.281512],
                    [-122.530976, 48.282445],
                    [-122.522756, 48.285504],
                    [-122.519535, 48.288314],
                    [-122.505828, 48.297677],
                    [-122.504729, 48.300373],
                    [-122.506568, 48.310041],
                    [-122.515979, 48.320419],
                    [-122.551334, 48.342138],
                    [-122.565525, 48.348217],
                    [-122.585162, 48.353304],
                    [-122.588891, 48.363005],
                    [-122.585038, 48.395166],
                    [-122.595351, 48.3972],
                    [-122.596732, 48.405626],
                    [-122.60198, 48.409907],
                    [-122.609715, 48.411565],
                    [-122.617174, 48.407145],
                    [-122.627809, 48.3972],
                    [-122.63582, 48.395128],
                    [-122.637892, 48.395681],
                    [-122.637339, 48.398029],
                    [-122.634024, 48.398858],
                    [-122.632643, 48.401068],
                    [-122.634991, 48.404244],
                    [-122.644798, 48.405488],
                    [-122.664659, 48.401508],
                    [-122.664928, 48.374823],
                    [-122.673731, 48.354683],
                    [-122.707077, 48.315286],
                    [-122.72259, 48.304268],
                    [-122.732022, 48.279425],
                    [-122.752563, 48.260061],
                    [-122.769939, 48.227548],
                    [-122.770045, 48.224395],
                ],
            ],
            [
                [
                    [-122.739898, 48.583949],
                    [-122.73944, 48.573893],
                    [-122.736199, 48.569005],
                    [-122.73048, 48.565602],
                    [-122.724031, 48.549906],
                    [-122.722407, 48.540606],
                    [-122.717278, 48.539739],
                    [-122.68944, 48.543903],
                    [-122.670638, 48.568812],
                    [-122.691745, 48.590612],
                    [-122.694672, 48.596602],
                    [-122.714512, 48.60878],
                    [-122.72493, 48.603263],
                    [-122.739898, 48.583949],
                ],
            ],
            [
                [
                    [-122.722262, 48.731624],
                    [-122.721981, 48.723375],
                    [-122.718833, 48.716818],
                    [-122.702223, 48.717004],
                    [-122.691795, 48.711498],
                    [-122.673538, 48.680809],
                    [-122.635299, 48.651846],
                    [-122.616956, 48.645563],
                    [-122.609576, 48.645018],
                    [-122.618225, 48.670721],
                    [-122.644901, 48.691389],
                    [-122.663259, 48.697077],
                    [-122.668947, 48.706644],
                    [-122.695907, 48.737273],
                    [-122.70306, 48.743602],
                    [-122.715709, 48.748672],
                    [-122.722262, 48.731624],
                ],
            ],
            [
                [
                    [-122.699266, 48.621115],
                    [-122.686136, 48.613267],
                    [-122.676796, 48.610055],
                    [-122.666149, 48.608088],
                    [-122.657016, 48.609891],
                    [-122.674173, 48.629944],
                    [-122.69806, 48.62308],
                    [-122.699266, 48.621115],
                ],
            ],
            [
                [
                    [-122.654342, 48.537956],
                    [-122.652041, 48.531329],
                    [-122.649256, 48.528769],
                    [-122.640414, 48.52586],
                    [-122.635738, 48.526021],
                    [-122.619858, 48.529246],
                    [-122.599948, 48.536904],
                    [-122.590194, 48.536259],
                    [-122.583565, 48.53234],
                    [-122.572967, 48.529028],
                    [-122.578856, 48.54813],
                    [-122.583985, 48.551534],
                    [-122.592901, 48.553635],
                    [-122.610841, 48.561146],
                    [-122.629321, 48.5722],
                    [-122.642597, 48.588339],
                    [-122.649405, 48.588457],
                    [-122.652385, 48.583432],
                    [-122.650786, 48.554019],
                    [-122.653612, 48.548975],
                    [-122.654342, 48.537956],
                ],
            ],
            [
                [
                    [-122.446316, 37.861046],
                    [-122.443302, 37.855448],
                    [-122.434403, 37.852434],
                    [-122.418698, 37.852717],
                    [-122.41847, 37.852721],
                    [-122.41847, 37.861764],
                    [-122.421341, 37.869946],
                    [-122.421439, 37.869969],
                    [-122.430958, 37.872242],
                    [-122.438565, 37.868367],
                    [-122.446316, 37.861046],
                ],
            ],
            [
                [
                    [-122.3785, 37.826505],
                    [-122.37267, 37.81651],
                    [-122.372422, 37.811301],
                    [-122.362661, 37.807577],
                    [-122.358779, 37.814278],
                    [-122.363244, 37.823951],
                    [-122.369941, 37.832137],
                    [-122.377879, 37.830648],
                    [-122.3785, 37.826505],
                ],
            ],
            [
                [
                    [-122.334524, 48.018916],
                    [-122.326115, 48.010295],
                    [-122.306629, 48.004397],
                    [-122.303455, 48.005603],
                    [-122.321721, 48.019977],
                    [-122.328343, 48.021335],
                    [-122.334524, 48.018916],
                ],
            ],
            [
                [
                    [-120.465329, 34.038448],
                    [-120.459635, 34.031537],
                    [-120.454134, 34.028081],
                    [-120.427408, 34.025425],
                    [-120.419021, 34.028949],
                    [-120.415225, 34.032245],
                    [-120.409368, 34.032198],
                    [-120.375143, 34.018775],
                    [-120.35793, 34.015029],
                    [-120.35532, 34.017914],
                    [-120.347706, 34.020114],
                    [-120.317052, 34.018837],
                    [-120.304543, 34.021171],
                    [-120.302122, 34.023574],
                    [-120.313175, 34.036576],
                    [-120.319032, 34.041979],
                    [-120.331161, 34.049097],
                    [-120.346946, 34.046576],
                    [-120.358608, 34.050235],
                    [-120.36029, 34.05582],
                    [-120.354982, 34.059256],
                    [-120.362251, 34.073056],
                    [-120.368278, 34.076465],
                    [-120.370176, 34.074907],
                    [-120.368584, 34.071214],
                    [-120.368813, 34.06778],
                    [-120.374211, 34.062658],
                    [-120.390906, 34.051994],
                    [-120.396188, 34.050187],
                    [-120.403613, 34.050442],
                    [-120.411314, 34.052869],
                    [-120.415287, 34.05496],
                    [-120.418768, 34.052093],
                    [-120.440248, 34.036918],
                    [-120.46258, 34.042627],
                    [-120.465329, 34.038448],
                ],
            ],
            [
                [
                    [-120.248484, 33.999329],
                    [-120.224461, 33.989059],
                    [-120.209372, 33.972376],
                    [-120.200085, 33.956904],
                    [-120.198602, 33.952211],
                    [-120.192339, 33.950266],
                    [-120.18984, 33.947703],
                    [-120.179049, 33.927994],
                    [-120.168974, 33.91909],
                    [-120.121817, 33.895712],
                    [-120.109137, 33.899129],
                    [-120.105489, 33.90428],
                    [-120.098601, 33.907853],
                    [-120.077793, 33.908886],
                    [-120.049682, 33.914563],
                    [-120.048611, 33.915775],
                    [-120.048315, 33.917625],
                    [-120.046881, 33.919597],
                    [-120.017715, 33.936366],
                    [-120.00096, 33.941554],
                    [-119.973691, 33.942481],
                    [-119.97026, 33.944359],
                    [-119.971141, 33.950401],
                    [-119.976857, 33.956693],
                    [-119.979913, 33.969623],
                    [-119.978876, 33.983081],
                    [-119.984316, 33.983948],
                    [-120.003815, 33.979547],
                    [-120.011123, 33.979894],
                    [-120.025653, 33.985553],
                    [-120.041311, 33.994507],
                    [-120.046575, 34.000002],
                    [-120.048926, 34.009898],
                    [-120.050382, 34.013331],
                    [-120.047798, 34.021227],
                    [-120.044004, 34.02482],
                    [-120.043259, 34.035806],
                    [-120.055107, 34.037729],
                    [-120.057637, 34.03734],
                    [-120.061953, 34.033976],
                    [-120.062778, 34.031161],
                    [-120.073609, 34.024477],
                    [-120.090182, 34.019806],
                    [-120.115058, 34.019866],
                    [-120.135853, 34.026087],
                    [-120.140362, 34.025974],
                    [-120.147647, 34.024831],
                    [-120.151663, 34.018126],
                    [-120.167306, 34.008219],
                    [-120.19578, 34.004284],
                    [-120.208478, 34.005655],
                    [-120.221287, 34.010367],
                    [-120.230001, 34.010136],
                    [-120.238657, 34.007592],
                    [-120.247393, 34.001911],
                    [-120.248484, 33.999329],
                ],
            ],
            [
                [
                    [-119.923337, 34.069361],
                    [-119.916216, 34.058351],
                    [-119.892821, 34.045529],
                    [-119.876329, 34.032087],
                    [-119.876916, 34.023527],
                    [-119.882531, 34.011674],
                    [-119.884896, 34.008814],
                    [-119.883033, 34.000802],
                    [-119.877057, 33.985757],
                    [-119.873358, 33.980375],
                    [-119.842748, 33.97034],
                    [-119.795938, 33.962929],
                    [-119.758141, 33.959212],
                    [-119.750438, 33.963759],
                    [-119.742966, 33.963877],
                    [-119.721206, 33.959583],
                    [-119.714696, 33.961439],
                    [-119.712363, 33.965422],
                    [-119.706952, 33.969178],
                    [-119.69011, 33.972225],
                    [-119.662825, 33.985889],
                    [-119.64771, 33.987786],
                    [-119.621117, 33.98899],
                    [-119.619082, 33.987228],
                    [-119.596877, 33.988611],
                    [-119.5902, 33.989712],
                    [-119.575636, 33.996009],
                    [-119.560464, 33.99553],
                    [-119.554472, 33.99782],
                    [-119.547072, 34.005469],
                    [-119.54828, 34.009819],
                    [-119.542449, 34.021082],
                    [-119.538847, 34.023988],
                    [-119.532413, 34.024949],
                    [-119.52177, 34.032247],
                    [-119.52064, 34.034262],
                    [-119.529603, 34.041155],
                    [-119.5667, 34.053452],
                    [-119.57341, 34.05011],
                    [-119.59324, 34.049625],
                    [-119.609239, 34.03735],
                    [-119.608798, 34.035245],
                    [-119.604287, 34.031561],
                    [-119.612226, 34.021256],
                    [-119.619343, 34.016468],
                    [-119.637742, 34.013178],
                    [-119.686507, 34.019805],
                    [-119.704628, 34.037681],
                    [-119.712576, 34.043265],
                    [-119.726437, 34.047908],
                    [-119.739472, 34.049299],
                    [-119.755521, 34.056716],
                    [-119.763688, 34.057155],
                    [-119.766081, 34.05537],
                    [-119.770729, 34.055051],
                    [-119.789798, 34.05726],
                    [-119.807825, 34.052127],
                    [-119.818742, 34.052997],
                    [-119.825865, 34.059794],
                    [-119.857304, 34.071298],
                    [-119.89113, 34.072856],
                    [-119.912857, 34.077508],
                    [-119.919155, 34.07728],
                    [-119.923337, 34.069361],
                ],
            ],
            [
                [
                    [-119.578942, 33.278628],
                    [-119.570642, 33.257729],
                    [-119.566014, 33.252639],
                    [-119.565641, 33.250029],
                    [-119.564971, 33.24744],
                    [-119.545872, 33.233406],
                    [-119.517514, 33.226737],
                    [-119.511659, 33.223027],
                    [-119.500684, 33.220569],
                    [-119.476029, 33.21552],
                    [-119.464725, 33.215432],
                    [-119.444269, 33.21919],
                    [-119.429559, 33.228167],
                    [-119.458466, 33.254661],
                    [-119.465717, 33.259239],
                    [-119.48278, 33.263973],
                    [-119.50504, 33.272829],
                    [-119.528141, 33.284929],
                    [-119.532941, 33.284728],
                    [-119.543842, 33.280329],
                    [-119.547642, 33.280328],
                    [-119.555242, 33.273429],
                    [-119.562042, 33.271129],
                    [-119.578942, 33.278628],
                ],
            ],
            [
                [
                    [-119.441226, 34.014075],
                    [-119.441116, 34.012426],
                    [-119.437734, 34.01],
                    [-119.427589, 34.006445],
                    [-119.422972, 34.004368],
                    [-119.414528, 34.004994],
                    [-119.391551, 34.002505],
                    [-119.376396, 34.010551],
                    [-119.357462, 34.015919],
                    [-119.366591, 34.016785],
                    [-119.389983, 34.006099],
                    [-119.396251, 34.005918],
                    [-119.411317, 34.008005],
                    [-119.421376, 34.015012],
                    [-119.433912, 34.015975],
                    [-119.441226, 34.014075],
                ],
            ],
            [
                [
                    [-118.606559, 33.01469],
                    [-118.596037, 33.015357],
                    [-118.586928, 33.008281],
                    [-118.573522, 32.969183],
                    [-118.560887, 32.957891],
                    [-118.551134, 32.945155],
                    [-118.535823, 32.90628],
                    [-118.524531, 32.895488],
                    [-118.524641, 32.893175],
                    [-118.507193, 32.876264],
                    [-118.508095, 32.871321],
                    [-118.506902, 32.868503],
                    [-118.496298, 32.851572],
                    [-118.487908, 32.84459],
                    [-118.476074, 32.841754],
                    [-118.44492, 32.820593],
                    [-118.428372, 32.806872],
                    [-118.42943, 32.805429],
                    [-118.425634, 32.800595],
                    [-118.401268, 32.820338],
                    [-118.394565, 32.823978],
                    [-118.387375, 32.825327],
                    [-118.379968, 32.824545],
                    [-118.36053, 32.819921],
                    [-118.356541, 32.817311],
                    [-118.353504, 32.821962],
                    [-118.369984, 32.839273],
                    [-118.446771, 32.895424],
                    [-118.460623, 32.90951],
                    [-118.479039, 32.920363],
                    [-118.485288, 32.923545],
                    [-118.496811, 32.933847],
                    [-118.529228, 32.970921],
                    [-118.540069, 32.980933],
                    [-118.559171, 33.006291],
                    [-118.564527, 33.018637],
                    [-118.564445, 33.024914],
                    [-118.569013, 33.029151],
                    [-118.57516, 33.033961],
                    [-118.594033, 33.035951],
                    [-118.605534, 33.030999],
                    [-118.606559, 33.01469],
                ],
            ],
            [
                [
                    [-118.60403, 33.47654],
                    [-118.601185, 33.469853],
                    [-118.593969, 33.467198],
                    [-118.575901, 33.448261],
                    [-118.570927, 33.439351],
                    [-118.563442, 33.434381],
                    [-118.558715, 33.433419],
                    [-118.53738, 33.434608],
                    [-118.52323, 33.430733],
                    [-118.516267, 33.425075],
                    [-118.515914, 33.422417],
                    [-118.503952, 33.424234],
                    [-118.48875, 33.419826],
                    [-118.484949, 33.412131],
                    [-118.478465, 33.38632],
                    [-118.482609, 33.369914],
                    [-118.48877, 33.356649],
                    [-118.481886, 33.344123],
                    [-118.465368, 33.326056],
                    [-118.456309, 33.32182],
                    [-118.440047, 33.318638],
                    [-118.402941, 33.320901],
                    [-118.374768, 33.320065],
                    [-118.360332, 33.31533],
                    [-118.343249, 33.305234],
                    [-118.325244, 33.299075],
                    [-118.316787, 33.301137],
                    [-118.305084, 33.310323],
                    [-118.303174, 33.320264],
                    [-118.310213, 33.335795],
                    [-118.316083, 33.342928],
                    [-118.32446, 33.348782],
                    [-118.365094, 33.388374],
                    [-118.368301, 33.40711],
                    [-118.370323, 33.409285],
                    [-118.382037, 33.409883],
                    [-118.423576, 33.427258],
                    [-118.445812, 33.428907],
                    [-118.477646, 33.448392],
                    [-118.48557, 33.446213],
                    [-118.499669, 33.447879],
                    [-118.500212, 33.449592],
                    [-118.530702, 33.468071],
                    [-118.54453, 33.474119],
                    [-118.585936, 33.473819],
                    [-118.598783, 33.477939],
                    [-118.603375, 33.478098],
                    [-118.60403, 33.47654],
                ],
            ],
        ],
    },
}
