import { Checkbox, FormControl, FormControlLabel, Grid, Select, TextField } from '@material-ui/core'
import { HouseOutlined as ChaptersIcon } from '@material-ui/icons'
import app from 'firebase/app'
import React from 'react'

import FormSubmission from 'components/Forms/FormSubmission'
import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'

export default function UpdateChapter(props) {
    const db = app.firestore()

    let chapterId = props.match.params.id
    let isNew = chapterId ? false : true

    const [state, setState] = React.useState({ exists: null, loading: false })

    const [chapter, setChapter] = React.useState({
        fullName: '',
        letters: '',
        timezone: '',
        newMemberName: '',
        newMemberShortname: '',
        fraternity: 'CICNqrSTI5c9937fJTky',
        usesRoll: false,
    })

    if (state.exists === null && state.loading === false) {
        if (!isNew) {
            getChapter()
        } else {
            setState(state => ({ ...state, exists: true }))
        }
    }

    function getChapter() {
        setState(state => ({ ...state, loading: true }))
        db.collection('chapters')
            .doc(chapterId)
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    setState(state => ({ ...state, loading: false, exists: true }))
                    setChapter(doc.data())
                } else {
                    setState(state => ({ ...state, loading: false, exists: false }))
                }
            })
            .catch(function(error) {
                console.log('Error getting document:', error)
                setState(state => ({ ...state, loading: false, exists: false, error: error }))
            })
    }

    const updateChapter = async () => {
        // TODO: Update the chapter
        /*let functionsCall = app.functions().httpsCallable('updateCalendarEvent2')
        functionsCall(submission)
            .then(function(result) {
                requestChapterUpdate()
                setIsLoading(false)
                props.history.push('/app/calendar/event/' + result.data.eventID)
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                setError(message)
                console.log('AN ERROR OCCURRED', code, message, details)
                setIsLoading(false)
            })

        setIsLoading(true)*/
        let data = {
            categories: [
                { id: 'social', color: '#fce38a', name: 'Social' },
                { id: 'service', color: '#6c5ce7', name: 'Service' },
                { id: 'service', color: '#007fff', name: 'Chapter' },
                { id: 'school', color: '#ff5722', name: 'School' },
            ],
            enabledFeatures: {
                drive: true,
                meals: true,
                messages: true,
                points: true,
                polls: true,
                tasks: true,
                rush: true,
            },
            events: {},
            fraternity: chapter.fraternity,
            fullName: chapter.fullName,
            letters: chapter.letters,
            perms: {},
            points: {},
            roles: {},
            settings: {
                roll: chapter.usesRoll ? true : false,
                timezone: chapter.timezone,
                tier: 'premium',
            },
            /* Legacy */
            activeNewsletter: '',
            activeRush: '',
            announcements: [],
            invitedMembers: [],
            members: {},
            newMemberName: chapter.newMemberName,
            newMemberShortname: chapter.newMemberShortname,
        }

        let mealsData = {
            meals: {},
            menus: {},
            plans: {},
            recurring: {},
            restrictions: {},
            settings: {},
        }

        if (isNew) {
            let newChapterDoc = db.collection('chapters').doc()

            await newChapterDoc.set(data)

            let pollInfoDoc = newChapterDoc.collection('polls').doc('INFO')

            await pollInfoDoc.set({ polls: {} })

            let mealsInfoDoc = newChapterDoc.collection('menus').doc('INFO')

            await mealsInfoDoc.set(mealsData)

            props.history.push(`/app/gc/chapters/${newChapterDoc.id}`)
        }
    }

    // fullName, letters, fraternity, newMemberName, newMemberShortname, timezone

    return (
        <Grid container spacing={2}>
            <NavigationBar
                titles={[
                    {
                        name: 'Chapters',
                        link: '/app/gc/chapters',
                        icon: <ChaptersIcon />,
                    },
                    {
                        name: isNew ? 'Create' : 'Update',
                    },
                ]}
                grid
            />
            {chapter && (
                <Grid item xs={12}>
                    <Widget disableWidgetMenu inheritHeight>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    autoFocus={isNew}
                                    margin="dense"
                                    label={chapter.fullName ? '' : 'Full Name'}
                                    InputLabelProps={{ shrink: false }}
                                    type="text"
                                    value={chapter.fullName}
                                    onChange={e => {
                                        let val = e.target.value
                                        setChapter(chapter => ({ ...chapter, fullName: val }))
                                    }}
                                    inputProps={{
                                        maxLength: 64,
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label={chapter.fraternity ? '' : 'Fraternity ID'}
                                    InputLabelProps={{ shrink: false }}
                                    type="text"
                                    value={chapter.fraternity}
                                    onChange={e => {
                                        let val = e.target.value
                                        setChapter(chapter => ({ ...chapter, fraternity: val }))
                                    }}
                                    inputProps={{
                                        maxLength: 64,
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label={chapter.letters ? '' : 'Letters'}
                                    InputLabelProps={{ shrink: false }}
                                    type="text"
                                    value={chapter.letters}
                                    onChange={e => {
                                        let val = e.target.value
                                        setChapter(chapter => ({ ...chapter, letters: val }))
                                    }}
                                    inputProps={{
                                        maxLength: 24,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label={chapter.newMemberName ? '' : 'New Member name'}
                                    InputLabelProps={{ shrink: false }}
                                    type="text"
                                    value={chapter.newMemberName}
                                    onChange={e => {
                                        let val = e.target.value
                                        setChapter(chapter => ({ ...chapter, newMemberName: val }))
                                    }}
                                    inputProps={{
                                        maxLength: 32,
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    label={chapter.newMemberShortname ? '' : 'New Member Shortname'}
                                    InputLabelProps={{ shrink: false }}
                                    type="text"
                                    value={chapter.newMemberShortname}
                                    onChange={e => {
                                        let val = e.target.value
                                        setChapter(chapter => ({ ...chapter, newMemberShortname: val }))
                                    }}
                                    inputProps={{
                                        maxLength: 24,
                                    }}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <FormControl fullWidth variant="outlined" margin="dense" style={{ marginTop: 4 }} label="timezone">
                                <Select
                                    native
                                    aria-label="Timezone"
                                    value={chapter.timezone}
                                    onChange={e => {
                                        let val = e.target.value
                                        setChapter(chapter => ({ ...chapter, timezone: val }))
                                    }}
                                >
                                    <option value="Pacific/Honolulu">Hawaii Standard Time</option>
                                    <option value="America/Anchorage">Alaska Standard Time</option>
                                    <option value="America/Los_Angeles">Pacific Standard Time</option>
                                    <option value="America/Phoenix">Arizona Standard Time</option>
                                    <option value="America/Denver">Mountain Standard Time</option>
                                    <option value="America/Chicago">Central Standard Time</option>
                                    <option value="America/New_York">Eastern Standard Time</option>
                                </Select>
                            </FormControl>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={chapter.usesRoll}
                                            onChange={e => {
                                                let checked = e.target.checked
                                                setChapter(chapter => ({ ...chapter, usesRoll: checked }))
                                            }}
                                            color="primary"
                                        />
                                    }
                                    label="Roll Numbers"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormSubmission
                                    isLoading={state.loading}
                                    onCancel={() => props.history.push(isNew ? '/app/gc/chapters' : `/app/gc/chapters/${chapterId}`)}
                                    onSubmit={() => updateChapter()}
                                    submitText={isNew ? 'Create' : 'Update'}
                                    errorText={state.error}
                                    submitDisabled={chapter.fullName === ''}
                                />
                            </Grid>
                        </Grid>
                    </Widget>
                </Grid>
            )}
        </Grid>
    )
}
