import React from 'react'

import _ from 'lodash'

import {
    Folder as FolderIcon,
    FolderOutlined as FolderOutlinedIcon
} from '@material-ui/icons'

import Box from '@mui/material/Box'
import DialogContentText from '@mui/material/DialogContentText'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { Button, DialogContent, DialogTitle, DialogActions, Dialog } from 'components/Wrappers'
import { convertObjectToList } from 'code/Helper'


const MoveToDialog = ({ open, onClose, onMove, folders, ...item }) => {
    const closeDialog = onClose

    const getFolders = (folders, parentFolder) => {
        let compiled = []
        const flattened = _.flatten(convertObjectToList(folders).map(f => f.items))

        let keys = Object.keys(folders)

        if (parentFolder) {
            keys = keys.filter(id => parentFolder.items.includes(id))
        } else {
            keys = keys.filter(id => !flattened.includes(id))
        }

        for (let i = 0; i < keys.length; i++) {
            let curFolder = folders[keys[i]]
            curFolder.id = keys[i]

            if (curFolder.title) {
                curFolder.folders = getFolders(folders, curFolder)
                compiled.push(curFolder)
            }
        }
        
        return compiled
    }

    const compiledFolders = React.useMemo(() => getFolders(folders, null), [folders])

    const [selectedFolder, setSelectedFolder] = React.useState('')

    React.useEffect(() => {
        if (item) setSelectedFolder(item.parent ?? '')
    }, [open])

    const RenderFolder = ({ folder, padding, disabled }) => (
        <>
            <ListItemButton
                selected={selectedFolder === folder.id}
                onClick={() => setSelectedFolder(folder.id)}
                disabled={disabled || (item && item.id === folder.id)}
                sx={{ pl: padding }}
            >
                <ListItemIcon>
                    {selectedFolder === folder.id ? <FolderIcon /> : <FolderOutlinedIcon />}
                </ListItemIcon>
                <ListItemText primary={folder.title} />
            </ListItemButton>
            {folder.folders && (
                <List component="div" disablePadding>
                    {folder.folders.map(folder => <RenderFolder folder={folder} key={folder.id} padding={padding + 2} disabled={disabled || (item && item.id === folder.id)} />)}
                </List>
            )}
        </>
    )

    return (
        <Dialog open={open} onClose={closeDialog} fullWidth aria-labelledby="move-to-dialog-title" maxWidth="sm">
            <DialogTitle id="move-to-dialog-title" onClose={closeDialog}>
                Move
            </DialogTitle>
            <DialogContent dividers>
                {item && item.name && (
                    <DialogContentText sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingBottom: 1 }}>
                        {item.icon && React.createElement(item.icon, { style: { marginInline: 12, verticalAlign: 'middle' } })}
                        {item.name}
                    </DialogContentText>
                )}
                <Box sx={{ paddingTop: 1, paddingBottom: 2 }}>
                    <Box sx={theme => ({ border: theme.border, borderRadius: 4, overflow: 'hidden' })}>
                        <List sx={{ padding: 0 }}>
                            <ListItemButton
                                selected={selectedFolder === ''}
                                onClick={() => setSelectedFolder('')}
                                sx={{ pl: 2 }}
                            >
                                <ListItemIcon>
                                    {selectedFolder === '' ? <FolderIcon /> : <FolderOutlinedIcon />}
                                </ListItemIcon>
                                <ListItemText primary={"Polls"} />
                            </ListItemButton>
                            {compiledFolders && compiledFolders.map(folder => 
                                <RenderFolder folder={folder} key={folder.id} padding={4} />
                            )}
                        </List>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeDialog()} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onMove(selectedFolder)} disabled={(selectedFolder === '' && item && item.base) || (item && item.parent === selectedFolder)} color="primary">
                    Move
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MoveToDialog
