import React from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'

const CreateFolderDialog = ({ open, onClose, onRenameFile, name }) => {
    const [curName, setCurName] = React.useState('')

    React.useEffect(() => {
        if (name) {
            setCurName(name)
        }
    }, [name])

    const closeDialog = () => {
        setCurName('')
        onClose()
    }

    const createFolder = () => {
        if (curName) {
            onRenameFile(curName)
            onClose()
        }
    }

    return (
        <Dialog open={open} onClose={closeDialog} fullWidth aria-labelledby="rename-dialog-title" maxWidth="xs">
            <DialogTitle id="rename-dialog-title">Rename</DialogTitle>
            <DialogContent>
                <TextField autoFocus margin="dense" variant="outlined" label="Name" value={curName} onChange={e => setCurName(e.target.value)} fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={createFolder} disabled={!curName || curName === name} color="primary">
                    Rename
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateFolderDialog
