import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    avatarEmojiSelectionBox: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    avatarEmojiSelectionBoxParent: {
        marginLeft: -12,
        marginRight: -12,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    avatarEmojiOuter: { width: 32, height: 32, textShadow: '0 2px 2px rgba(0, 0, 0, .25)', lineHeight: '1 !important', verticalAlign: 'middle !important' },
    avatarEmojiParentBox: {
        width: 48,
        height: 48,
        padding: 8,
        borderRadius: '50%',
    },
    avatarEmojiParentBoxSelected: {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    attachmentsBoxParent: {
        margin: 4,
    },
    calendarEventsBox: {
        marginTop: '8px !important',
    },
    calendarBox: { width: '100%', fontSize: '0.8em', margin: '0 auto', maxWidth: '384px', borderRadius: 4, border: theme.border },
    elementGCBox: {
        margin: '4px 0',
        border: theme.border,
        maxWidth: '100%',
        borderRadius: 4,
        [theme.breakpoints.only('sm')]: {
            maxWidth: 'calc(100% - 44px)',
        },
        width: 'fit-content',
        minWidth: '65%',
        backgroundColor: theme.palette.background.paper,
    },
    elementGCBoxPreview: {
        width: '100% !important',
        maxWidth: '100% !important',
        margin: '0px !important',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        pointerEvents: 'none',
        fontSize: '0.5rem',
    },
    elementImgParent: { margin: '4px 0' },
    elementImg: {
        height: '18em',
        maxWidth: '100%',
        border: theme.border,
        borderRadius: 4,
        '&:hover': {
            background: 'rgba(150, 150, 150, 0.16)',
            cursor: 'pointer',
        },
    },
    eventHover: {
        '&:hover': {
            background: 'rgba(150, 150, 150, 0.16)',
            cursor: 'pointer',
        },
    },
    giphyAttributionPadding: {
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        background: theme.palette.background.paper,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    giphyAttributionBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 36,
        padding: 6.75,
        marginBottom: `env(safe-area-inset-bottom)`,
    },
    giphyLink: {
        height: 18,
        display: theme.palette.type === 'dark' ? 'block' : 'none',
    },
    giphyLinkBlack: {
        height: 22.5,
        display: theme.palette.type === 'dark' ? 'none' : 'block',
    },
    giphyLogo: {
        height: 18,
    },
    giphyLogoBlack: {
        height: 22.5,
    },
    giphyPoweredBy: {
        marginRight: 8,
        fontWeight: 'bold',
    },
    giphySelectorBox: {
        flexGrow: 1,
        maxHeight: 'calc(100% - 52px)',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    giphySelectorMarginTop: {
        width: '100%',
        height: 16,
    },
    giphySelectorMarginBottom: {
        width: '100%',
        height: `calc(53px + env(safe-area-inset-bottom))`,
    },
    navPadding: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
    },
    navPaddingRightButton: {
        paddingTop: `${theme.spacing(1)}px !important`,
        paddingBottom: `6px !important`,
        paddingRight: 4,
    },
    navPaddingButtons: {
        paddingTop: `6px !important`,
        paddingBottom: `6px !important`,
        paddingLeft: 4,
        paddingRight: 4,
        border: theme.border,
        borderRadius: 4,
    },
    navPaddingButtonsMobile: {
        paddingTop: `6px !important`,
        paddingBottom: `calc(6px + env(safe-area-inset-bottom)) !important`,
        paddingLeft: 4,
        paddingRight: 4,
        borderTop: theme.border,
    },
    navPaddingButtonsMobileEditing: {
        paddingTop: `6px !important`,
        paddingBottom: `6px !important`,
        paddingLeft: 4,
        paddingRight: 4,
        borderTop: theme.border,
    },
    navEditing: {
        borderColor: theme.palette.text.primary,
    },
    pollInput: {
        borderTop: `1px solid ${theme.palette.text.hint}80`,
        borderBottom: `1px solid ${theme.palette.text.hint}80`,
        backgroundColor: theme.palette.background.light,
        padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
        margin: 0,
        marginBottom: -1,
    },
    pollInputBox: {
        marginBottom: theme.spacing(2),
    },
    pollInputLabel: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    conversationListPadding: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },
    scrollable: {
        position: 'relative',
        overflowY: 'scroll',
        webkitOverflowScrolling: 'touch',
    },
    sidebar: {
        gridRowStart: 1,
        gridRowEnd: 'span 3',
        overflow: 'initial',
    },
    navButtonIcon: {
        /*padding: '0 !important',
        marginLeft: 12,
        marginRight: 12,*/
    },
    navButtonNewLine: {
        display: 'none',
        [theme.breakpoints.up(900)]: {
            display: 'flex',
            alignItems: 'center',
            opacity: 0.7,
        },
    },
    buttonIconActive: {
        color: theme.palette.text.primary,
    },
    buttonIconInactive: {
        color: theme.palette.text.hint,
    },
    messagesWidgetPaper: {
        maxHeight: '100%',
        flexGrow: 1,
        overflowY: 'scroll',
        fontSize: '1rem',
    },
    messagesWidget: {
        maxHeight: '100%',
    },
    messagesWidgetBody: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        flexDirection: 'column',
    },
    messagesInfoWidgetBody: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexGrow: 1,
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 155px)',
        overflowY: 'scroll',
        padding: 16,
    },
    membersGrid: {
        border: theme.border,
        borderRadius: 24,
    },
    membersBox: {
        display: 'flex',
        flexDirection: 'column',
    },
    membersGridItem: {
        '&:nth-child(even)': {
            borderLeft: `0.5px solid ${theme.palette.text.hint}80`,
        },
        '&:nth-child(odd)': {
            borderRight: `0.5px solid ${theme.palette.text.hint}80`,
        },
    },
    membersGridItemBottom: {
        borderBottom: `0.5px solid ${theme.palette.text.hint}80`,
    },
    membersGridItemTop: {
        borderTop: `0.5px solid ${theme.palette.text.hint}80`,
    },
    membersGridItemLast: {},
    muteNotificationsBox: {
        display: 'flex',
        flexDirection: 'row',
    },
    muteNotificationsText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: 1,
    },
    muteNotificationsSwitch: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: 'min(256px, 25%)',
    },
    newConversationWidget: {
        padding: `8px 12px`,
        maxHeight: 'calc(100vh - 155px)',
        overflowY: 'scroll',
    },
    conversationSeparator: {
        position: 'absolute',
        top: 0,
        left: 48,
        right: 0,
        backgroundColor: `${theme.palette.text.hint}80`,
        height: 1,
    },
    deleteIcon: {
        color: theme.palette.red.main,
    },
    messageLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
    iconClose: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.2)',
        },
    },
}))
