import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    locationOptionsBox: {
        ' & section:first-child': {
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            overflow: 'hidden',
        },
        ' & section:last-child': {
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            overflow: 'hidden',
        },
        ' & section:not(:last-child)': {
            borderBottom: theme.border,
        },
    },
    locationOptionBoxSelected: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        color: 'white',
    },
    locationOptionsBoxParent: {
        padding: '8px 12px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        userSelect: 'none',
    },
    locationOptionButton: {
        backgroundColor: theme.palette.background.light,
        width: '100%',
    },
    map: {
        ' & canvas': {
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            width: '100% important',
        },
    },
    nonOverflowText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '100%',
    },
    progressBar: {
        backgroundColor: theme.palette.primary.main,
    },
    progress: {
        marginBottom: 10,
        marginTop: 10,
        marginRight: 12,
        backgroundColor: theme.palette.gray.main,
    },
    progressBox: {
        maxWidth: 512,
        margin: '0 auto',
        justifyContent: 'center',
    },
    requestButton: {
        borderRadius: 4,
        backgroundColor: theme.palette.background.light,
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        width: '100%',
    },
    requestButtonDisabled: {
        opacity: 0.5,
    },
    requestIcon: {
        fontSize: '2em',
        height: '2em',
        margin: '0.5em',
        marginBottom: 0,
        width: '2em',
    },
    progressBar: {
        backgroundColor: theme.palette.primary.main,
    },
    progress: {
        backgroundColor: theme.palette.gray.main,
    },
}))
