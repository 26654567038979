import React from 'react'

import ListItemButton from '@mui/material/ListItemButton'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'

const Item = ({ icon, onClick, label, type, style }) => {
    if (type === 'menu') {
        return (
            <MenuItem onClick={onClick} style={style}>
                <ListItemIcon>{React.createElement(icon, { fontSize: 'small', style })}</ListItemIcon>
                <ListItemText>{label}</ListItemText>
            </MenuItem>
        )
    }

    return (
        <ListItemButton onClick={onClick} style={style}>
            <ListItemIcon>{React.createElement(icon, { style })}</ListItemIcon>
            <ListItemText primary={label} />
        </ListItemButton>
    )
}

export default Item
