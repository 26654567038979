import { Box, Checkbox, Divider, Grid, IconButton } from '@material-ui/core'
import {
    AssignmentIndOutlined as AssignedIcon,
    PersonOutlineOutlined as AuthorIcon,
    Subject as DescriptionIcon,
    CreateOutlined as EditIcon,
    HomeOutlined as HomeIcon,
    ChevronLeft as LeftIcon,
    ChevronRight as RightIcon,
    SupervisedUserCircleOutlined as SupervisedIcon,
    AssignmentOutlined as TasksIcon,
    AccessTime as TimeIcon,
} from '@material-ui/icons'
import React from 'react'

import format from 'date-fns/format'

import { Tasks } from 'objects/Tasks'
import { Subtasks } from 'objects/Tasks/Subtasks'

import MuiLinkify from 'material-ui-linkify'

import { clearNotifications, convertListToObject, convertObjectToList, getWeek, getWeekYear, isMobileDevice } from 'code/Helper'

import NavigationBar from 'components/NavigationBar'
import Widget from 'components/Widget'
import { Button, Chip as CustomChip, Typography } from 'components/Wrappers'

import moment from 'moment'

import app from 'firebase/app'
import useStyles from '../styles'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

export default function ViewTask(props) {
    var classes = useStyles()

    const taskId = props.match.params.id

    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)

    const [task, setTask] = React.useState(null)

    const [hasGrabbed, setHasGrabbed] = React.useState(false)
    const [activeDate, innerSetActiveDate] = React.useState(getSunday())

    function getSunday() {
        return new Date()
    }

    function setActiveDate(date) {
        innerSetActiveDate(date)
    }

    function hasAdminInner() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    (Array.isArray(chapter.perms['tasksAdmin']) && chapter.perms['tasksAdmin'].includes(mem.role)) ||
                    (Array.isArray(chapter.perms['tasksCreate']) &&
                        chapter.perms['tasksCreate'].includes(mem.role) &&
                        task &&
                        task.author &&
                        user.getId() === task.author.id)
                )
            }
        }

        return false
    }

    const hasAdmin = hasAdminInner()

    const getTaskRef = React.useCallback(() => {
        const db = app.firestore()
        return db
            .collection('chapters')
            .doc(user.getChapter())
            .collection('tasks')
            .doc(taskId)
    }, [user, taskId])

    React.useEffect(() => {
        const unsubscribe = getTaskRef().onSnapshot(
            function(doc) {
                if (doc.exists) {
                    let data = doc.data()
                    const newSubtasks = convertObjectToList(data.subtasks)
                    data.subtasks = newSubtasks
                    setTask(new Tasks(data))
                    setHasGrabbed(true)

                    clearNotifications(data.name)
                } else {
                    console.log('task does not exist')
                    alert('Task does not exist')
                }
            },
            function(error) {
                console.log('error', error)
            },
        )

        return () => {
            unsubscribe()
        }
    }, [getTaskRef])

    function getTaskCaption(t) {
        let tsk = new Subtasks(t)
        return tsk.getCaption(user)
    }

    function sendTaskNotification(type) {
        if (task.notifications === true) {
            let submission = {
                chapter: user.getChapter(),
                taskID: taskId,
                type: type,
            }

            let functionsCall = app.functions().httpsCallable('notifyTask')
            functionsCall(submission)
        }
    }

    function approveTask() {
        if (task.type === Tasks.TYPE_WEEKLY) {
            let year = getWeekYear(activeDate)
            let week = getWeek(activeDate)
            if (task.hasThisWeeksProgressSetup(activeDate)) {
                if (task.weeks && task.weeks[`${year}_${week}`] && !task.weeks[`${year}_${week}`].approved) {
                    // Send the notification
                    sendTaskNotification('APPROVE')
                    updateTask({
                        [`weeks.${year}_${week}.approved`]: true,
                    })
                }
            }
            return
        }

        if (!task.approved) {
            // Send the notification
            sendTaskNotification('APPROVE')
            updateTask({
                approved: true,
            })
        }
    }

    const addIndices = _arr => {
        let arr = _arr
        for (let i = 0; i < arr.length; i++) {
            arr[i] = { ...arr[i], i: i }
        }

        return arr
    }

    function updateSubtask(tsk, index, val) {
        let newTask = new Subtasks(tsk)
        const id = newTask.id
        let str = `subtasks.${id}`

        newTask.updateStatus(val, user)

        newTask = newTask.getRaw()

        newTask.i = index

        delete newTask.id

        if (val === 0) {
            if (task.isCompleted(activeDate)) {
                sendTaskNotification('DENY')
            }
        } else if (val === 1) {
            // Check to see if it was not completed but now it will be
            if (!task.isCompleted(activeDate)) {
                if (task.isCompleted(activeDate, [id])) {
                    sendTaskNotification('AWAITING')
                }
            }
        }

        if (task.type === Tasks.TYPE_WEEKLY) {
            let year = getWeekYear(activeDate)
            let week = getWeek(activeDate)
            if (task.hasThisWeeksProgressSetup(activeDate)) {
                str = `weeks.${year}_${week}.subtasks.${id}`
            } else {
                // Setup this whole weeks progress
                let thisWeeksSubtasks = convertListToObject(addIndices(task.getSubtasks(activeDate)))

                thisWeeksSubtasks[id] = newTask

                updateTask({
                    [`weeks.${year}_${week}.subtasks`]: thisWeeksSubtasks,
                })
                return
            }
        }

        updateTask({
            [str]: newTask,
        })
    }

    function updateTask(val) {
        // { [`settings.${name}`]: value }
        getTaskRef()
            .update(val)
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code
                var message = error.message
                var details = error.details
                console.log('AN ERROR OCCURRED', code, message, details)
            })
    }

    const taskApproved = hasGrabbed && task.isApproved(activeDate)
    const taskStarted = hasGrabbed && task.hasStarted(activeDate)
    const taskCompleted = hasGrabbed && task.isCompleted(activeDate)
    const taskLate = hasGrabbed && task.isLate(activeDate)

    const canModifyPoints =
        hasGrabbed && taskStarted && !taskApproved && (!taskCompleted || (Array.isArray(task.supervisorsArr) && task.supervisorsArr.includes(user.getId())))

    const dividerStyle = isMobileDevice() ? { marginLeft: -16, marginRight: -16 } : { marginLeft: -24, marginRight: -24 }

    const renderWidget = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    style={{
                        fontWeight: 700,
                        margin: '0 auto',
                    }}
                    align="center"
                >
                    {task.name}
                </Typography>
            </Grid>
            {task.isTimed() && (
                <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                    <Grid
                        item
                        style={{
                            height: 24,
                        }}
                    >
                        <TimeIcon />
                    </Grid>
                    <Grid item>
                        <Typography>{task.getTiming()}</Typography>
                    </Grid>
                </Grid>
            )}
            {task.author && (
                <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                    <Grid
                        item
                        style={{
                            height: 24,
                        }}
                    >
                        <AuthorIcon />
                    </Grid>
                    <Grid item>
                        <Typography>{task.author.first + ' ' + task.author.last}</Typography>
                    </Grid>
                </Grid>
            )}
            {task.description && (
                <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                    <Grid
                        item
                        style={{
                            height: 24,
                        }}
                    >
                        <DescriptionIcon />
                    </Grid>
                    <Grid item>
                        <MuiLinkify>
                            <Typography
                                style={{
                                    whiteSpace: `break-spaces`,
                                    wordBreak: `break-word`,
                                }}
                            >
                                {task.description}
                            </Typography>
                        </MuiLinkify>
                    </Grid>
                </Grid>
            )}
            <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                <Grid
                    item
                    style={{
                        height: 24,
                    }}
                >
                    <AssignedIcon />
                </Grid>
                <Grid item>
                    <Typography>{task.getAssigneesCaption(user, false)}</Typography>
                </Grid>
            </Grid>
            {task.approval === Tasks.APPROVAL_MANUAL && (
                <Grid container direction="row" style={{ flexWrap: `nowrap` }}>
                    <Grid
                        item
                        style={{
                            height: 24,
                        }}
                    >
                        <SupervisedIcon />
                    </Grid>
                    <Grid item>
                        <Typography>{task.getSupervisorsCaption(user, false)}</Typography>
                    </Grid>
                </Grid>
            )}
            {task.type === Tasks.TYPE_WEEKLY && (
                <>
                    <Grid item xs={12}>
                        <Divider style={dividerStyle} />
                    </Grid>
                    <Grid container direction="row" style={{ flexWrap: `nowrap`, justifyContent: 'center' }}>
                        <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                            <Box className={classes.boxLeftArrow}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setActiveDate(
                                            moment(activeDate)
                                                .subtract(7, 'days')
                                                .toDate(),
                                        )
                                    }}
                                    disabled={moment(activeDate).isSame(moment((task.cd.seconds ? task.cd.seconds : task.cd._seconds) * 1000), 'week')}
                                >
                                    <LeftIcon />
                                </IconButton>
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography variant="overline" style={{ lineHeight: 1, textTransform: 'uppercase', fontWeight: 500, textAlign: 'center' }}>
                                    Week of
                                </Typography>
                                <Typography variant="subtitle2" style={{ lineHeight: 1, margin: '2px 0', textAlign: 'center' }}>
                                    {format(activeDate, 'MMMM do')}
                                </Typography>
                            </Box>
                            <Box className={classes.boxRightArrow}>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setActiveDate(
                                            moment(activeDate)
                                                .add(7, 'days')
                                                .toDate(),
                                        )
                                    }}
                                    disabled={false}
                                >
                                    <RightIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider style={dividerStyle} />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <Box
                    display="flex"
                    style={{
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    {taskApproved ? (
                        <CustomChip color="green" label="Approved" />
                    ) : (
                        <>
                            {taskCompleted ? (
                                <>
                                    {task.approval === Tasks.APPROVAL_MANUAL ? (
                                        <CustomChip color="orange" label="Awaiting Approval" />
                                    ) : (
                                        <CustomChip color="green" label="Complete" />
                                    )}
                                </>
                            ) : (
                                <>
                                    {taskLate ? (
                                        <CustomChip color="red" label="Late" />
                                    ) : taskStarted ? (
                                        <CustomChip color="orange" label="Not Complete" />
                                    ) : (
                                        <CustomChip color="blue" label="Not Open" />
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Grid>
            {task.approval === Tasks.APPROVAL_MANUAL &&
                !taskApproved &&
                taskCompleted &&
                Array.isArray(task.supervisorsArr) &&
                task.supervisorsArr.includes(user.getId()) && (
                    <>
                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: 700,
                                    margin: '0 auto',
                                }}
                                align="center"
                            >
                                Review
                            </Typography>
                            <Typography align="center" variant="caption">
                                To deny, please uncheck the subtask(s) that are not completed
                            </Typography>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{
                                    margin: `4px auto`,
                                }}
                                onClick={() => approveTask()}
                            >
                                Approve
                            </Button>
                        </Grid>
                    </>
                )}
            {task.getSubtasks(activeDate).map((t, index) => (
                <>
                    <Grid item xs={12} key={`${t.id}.divider`}>
                        <Divider style={dividerStyle} />
                    </Grid>
                    <Grid item xs={12} style={{ padding: 0 }} key={`${t.id}`}>
                        <Box style={{ display: 'flex', flexDirection: 'row', marginLeft: -9, justifyContent: 'flex-start' }}>
                            <Box>
                                <Checkbox
                                    checked={taskApproved || t.status === 1}
                                    onChange={() => {
                                        updateSubtask(t, index, t.status === 1 ? 0 : 1)
                                    }}
                                    color="primary"
                                    inputProps={{ 'aria-labelledby': t.id }}
                                    disabled={!canModifyPoints}
                                />
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                                <Typography
                                    id={t.id}
                                    style={
                                        t.status === 1
                                            ? { textDecoration: 'line-through', cursor: canModifyPoints ? 'pointer' : 'auto' }
                                            : { cursor: canModifyPoints ? 'pointer' : 'auto' }
                                    }
                                    onClick={
                                        canModifyPoints
                                            ? () => {
                                                  updateSubtask(t, index, t.status === 1 ? 0 : 1)
                                              }
                                            : null
                                    }
                                >
                                    {t.name}
                                </Typography>
                                <Typography variant="caption">{getTaskCaption(t)}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </>
            ))}
        </Grid>
    )

    return (
        <>
            <Grid container spacing={2}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Tasks',
                            link: '/app/applications/tasks/',
                            icon: <TasksIcon />,
                            iconMobileOnly: true,
                        },
                        {
                            name: 'View',
                        },
                    ]}
                    rightButtons={
                        hasAdmin
                            ? [
                                  {
                                      name: 'Edit',
                                      type: 'icon',
                                      innerIcon: <EditIcon />,
                                      onClick: () => {
                                          props.history.push('/app/applications/tasks/' + taskId + '/edit')
                                      },
                                  },
                              ]
                            : []
                    }
                    key={hasAdmin ? 'admin' : 'nah'}
                    grid
                />
                {hasGrabbed && (
                    <>
                        <Grid item xs={12}>
                            {isMobileDevice() ? (
                                renderWidget()
                            ) : (
                                <Widget disableWidgetMenu noBodyPadding>
                                    <Box
                                        style={{
                                            paddingTop: 17,
                                            paddingBottom: 17,
                                            paddingLeft: 24,
                                            paddingRight: 24,
                                        }}
                                    >
                                        {renderWidget()}
                                    </Box>
                                </Widget>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}
