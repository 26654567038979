export class User {
    /*
    chapter
    first
    last
    birthday
    email
    hometown
    instagram
    twitter
    major
    phone
    photoURL
    rollNumber
    */

    constructor(databaseUser) {
        this.first = databaseUser.first
        this.last = databaseUser.last
        this.chapter = databaseUser.chapter
        this.birthday = databaseUser.birthday
        this.email = databaseUser.email
        this.hometown = databaseUser.hometown
        this.instagram = databaseUser.instagram
        this.twitter = databaseUser.twitter
        this.major = databaseUser.major
        this.phone = databaseUser.phone
        this.photoURL = databaseUser.photoURL
        this.rollNumber = databaseUser.rollNumber
        this.id = databaseUser.uid
        this.announcements = databaseUser.announcements
        this.tryingToRegister = databaseUser.isTryingToRegister
        this.fraternity = databaseUser.fraternity
        this.type = databaseUser.type ? databaseUser.type : 'CHAPTER_MEMBER'
        this.notifications = databaseUser.notifications
        this.tips = Array.isArray(databaseUser.tips) ? databaseUser.tips : []
        this.devices = Array.isArray(databaseUser.devices) ? databaseUser.devices : []
        this.status = databaseUser.status
        this.class = databaseUser.class
    }

    needsDetailsUpdate() {
        if (this.tryingToRegister) {
            return false
        }

        if (!this.major || !this.hometown || !this.class || !this.phone || !this.birthday) {
            if (!localStorage.getItem('cache_ignoreInfo')) {
                return true
            }
        }

        return false
    }

    getAdminURL() {
        if (this.isHQ()) {
            return '/app/hq/'
        }
        return '/app/admin/'
    }

    getStatus() {
        return this.status
    }

    isHeadquartersStaff() {
        return this.fraternity !== undefined && this.fraternity !== null
    }

    isHQStaff() {
        return this.isHeadquartersStaff()
    }

    isHQ() {
        return this.isHQStaff()
    }

    isGCStaff() {
        return this.type && this.type === 'ADMIN'
    }

    hasRegistered() {
        return !this.tryingToRegister
    }

    getAnnouncements() {
        return this.announcements
    }

    getId() {
        return this.id
    }

    getChapter() {
        return this.chapter
    }

    getClass() {
        return this.class
    }

    getFirst() {
        return this.first
    }

    getLast() {
        return this.last
    }

    getFullName() {
        return this.getFirst() + ' ' + this.getLast()
    }

    getAuthor() {
        return {
            id: this.id,
            first: this.getFirst(),
            last: this.getLast(),
            photo: this.getPhoto(),
        }
    }

    getInitials() {
        let first = this.getFirst()
        let last = this.getLast()

        let text = ''
        if (first) {
            text += first.charAt(0)
        }

        if (last) {
            text += last.charAt(0)
        }

        return text
    }

    getBirthday() {
        return this.birthday
    }

    getEmail() {
        return this.email
    }

    getHometown() {
        return this.hometown
    }

    getInstagram() {
        return this.instagram
    }

    getTwitter() {
        return this.twitter
    }

    getMajor() {
        return this.major
    }

    getPhone() {
        return this.phone
    }

    getPhoto() {
        return this.photoURL
    }

    getRoll() {
        return this.rollNumber
    }
}
