import React from 'react'

import { Box, Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Add as AddIcon, AssignmentIndOutlined as AssignedIcon, Create as EditIcon, HomeOutlined as HomeIcon } from '@material-ui/icons'

import { convertListToObject, convertObjectToList, isMobileDevice as _isMobileDevice } from 'code/Helper'

import TinyCard from 'components/Cards/TinyCard'
import NavigationBar from 'components/NavigationBar'
import Tabs from 'components/Tabs'
import Widget from 'components/Widget'
import { Typography } from 'components/Wrappers'

import app from 'firebase/app'
import useStyles from './styles'

import { Tasks as Task } from 'objects/Tasks'

import { TumbleWeedOutlinedIcon } from 'components/Icons'

import { AuthContext } from 'context/AuthContext'
import { ChapterContext } from 'context/ChapterContext'

export default function Tasks(props) {
    var classes = useStyles()
    const theme = useTheme()

    const { chapter } = React.useContext(ChapterContext)
    const { user } = React.useContext(AuthContext)

    const isMobileDevice = _isMobileDevice()

    const [grabbedTasks, setGrabbedTasks] = React.useState({ assigned: false, supervising: false, all: false })
    const [tasks, innerSetTasks] = React.useState([])
    const [hasTried, setHasTried] = React.useState(false)
    const [hasLoaded, setHasLoaded] = React.useState(false)

    const [sortingType, setSortingType] = React.useState(localStorage.getItem('tasksSorting') ? localStorage.getItem('tasksSorting') : 'assigned')

    const isAdmin = isUserTasksAdmin()
    const isCreateAdmin = isUserTasksCreate()

    function setTasks(t) {
        let l = []

        for (let i = 0; i < t.length; i++) {
            l.push(new Task(t[i]))
        }

        l.sort((a, b) => {
            let bVal = b.getVal()
            let aVal = a.getVal()
            if (bVal !== aVal) {
                return bVal - aVal
            }

            return b.name.localeCompare(a.name)
        })

        innerSetTasks(l)
    }

    function isUserTasksAdmin() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return mem.role === 'ADMIN' || (chapter.perms['tasksAdmin'] && chapter.perms['tasksAdmin'].includes(mem.role))
            }
        }

        return false
    }

    function isUserTasksCreate() {
        if (chapter && chapter.members && user) {
            let mem = chapter.members[user.getId()]
            if (mem) {
                return (
                    mem.role === 'ADMIN' ||
                    (chapter.perms['tasksAdmin'] && chapter.perms['tasksAdmin'].includes(mem.role)) ||
                    (chapter.perms['tasksCreate'] && chapter.perms['tasksCreate'].includes(mem.role))
                )
            }
        }

        return false
    }

    if (!hasTried) {
        if (sortingType === 'all' && !isAdmin && !isCreateAdmin) {
            localStorage.setItem('tasksSorting', 'assigned')
            setSortingType('assigned')
        } else {
            setHasTried(true)
            grabTasks(sortingType)
        }
    }

    async function getTasksWithQuery(query) {
        setHasLoaded(false)

        let snapshot = await query.get()

        let tasks = {}

        snapshot.forEach(function(doc) {
            let data = doc.data()
            tasks[doc.id] = data
        })

        setHasLoaded(true)

        return tasks
    }

    async function grabTasks(sortingType) {
        if (!grabbedTasks[sortingType]) {
            const db = app.firestore()
            let tasksRef = db
                .collection('chapters')
                .doc(user.getChapter())
                .collection('tasks')

            if (sortingType === 'assigned') {
                let assignedRef = tasksRef.where('assigneesArr', 'array-contains', user.getId())
                let newTasks = await getTasksWithQuery(assignedRef)

                setTasks(convertObjectToList(combineObjects(newTasks, convertListToObject(tasks))))
                setGrabbedTasks({ ...grabbedTasks, assigned: true })
            } else if (sortingType === 'supervising') {
                let supervisingRef = tasksRef.where('supervisorsArr', 'array-contains', user.getId())
                let newTasks = await getTasksWithQuery(supervisingRef)

                setTasks(convertObjectToList(combineObjects(newTasks, convertListToObject(tasks))))
                setGrabbedTasks({ ...grabbedTasks, supervising: true })
            } else if (sortingType === 'all') {
                if (!isAdmin && isCreateAdmin) {
                    tasksRef = tasksRef.where('author.id', '==', user.getId())
                }
                let newTasks = await getTasksWithQuery(tasksRef)

                setTasks(convertObjectToList(combineObjects(newTasks, convertListToObject(tasks))))
                setGrabbedTasks({ ...grabbedTasks, all: true })
            }
        }
    }

    function combineObjects(a, b) {
        let aKeys = Object.keys(a)
        let bKeys = Object.keys(b)

        let c = { ...a }

        for (let i = 0; i < bKeys.length; i++) {
            let key = bKeys[i]
            if (!aKeys.includes(key)) {
                c[key] = b[key]
            }
        }

        return c
    }

    function getShowingTasks() {
        if (sortingType === 'all' && isAdmin) {
            return tasks
        }

        let t = []

        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i]

            if (sortingType === 'assigned' && task.isAssignee(user.getId())) {
                t.push(task)
            } else if (sortingType === 'supervising' && task.isSupervisor(user.getId())) {
                t.push(task)
            } else if (sortingType === 'all' && task.author.id === user.getId()) {
                t.push(task)
            }
        }

        return t
    }

    function getValidTabs() {
        let tabs = [
            { id: 'assigned', name: 'Assigned', width: 80 },
            { id: 'supervising', name: 'Supervising', width: 100 },
        ]

        if (isAdmin) {
            tabs.push({ id: 'all', name: 'All', width: 40 })
        } else if (isCreateAdmin) {
            tabs.push({ id: 'all', name: 'Author', width: 60 })
        }

        return tabs
    }

    const renderTabs = () => (
        <Tabs
            tabs={getValidTabs()}
            selected={sortingType}
            onChange={val => {
                localStorage.setItem('tasksSorting', val)
                setSortingType(val)
                grabTasks(val)
            }}
        />
    )

    const tasksToShow = getShowingTasks()

    if (isMobileDevice) {
        return (
            <>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Tasks',
                        },
                    ]}
                    rightButtons={
                        isAdmin || isCreateAdmin
                            ? [
                                  {
                                      name: 'Add',
                                      type: 'icon',
                                      innerIcon: <AddIcon />,
                                      onClick: () => {
                                          props.history.push('/app/applications/tasks/new/edit')
                                      },
                                  },
                              ]
                            : []
                    }
                    key={isAdmin || isCreateAdmin ? 'admin' : 'nah'}
                    grid
                />
                <Box
                    style={{
                        height: `calc(100vh - env(safe-area-inset-top) - 56px)`,
                        margin: -16,
                        marginBottom: 'min(calc(0px - env(safe-area-inset-bottom)), -16px)',
                    }}
                >
                    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Box style={{ marginTop: 8, borderBottom: theme.border }}>{renderTabs()}</Box>
                        {hasLoaded && tasksToShow.length === 0 ? (
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 16,
                                    flexGrow: 1,
                                    padding: 16,
                                }}
                            >
                                <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor }} />
                                <Typography style={{ color: theme.borderColor }}>There are no tasks here</Typography>
                            </Box>
                        ) : (
                            <Box style={{ flexGrow: 1, padding: 16, overflow: 'scroll' }}>
                                <Grid container spacing={2}>
                                    {tasksToShow.map(task => (
                                        <Grid item xs={12} sm={6} lg={4} key={task.id}>
                                            <TinyCard
                                                onClick={() => props.history.push('/app/applications/tasks/' + task.id)}
                                                text={task.name}
                                                description={task.getStatus()}
                                                author={{
                                                    first: '',
                                                    last: task.getAssigneesCaption(user, true),
                                                    icon: <AssignedIcon />,
                                                }}
                                                variant={isMobileDevice ? 'outlined' : 'default'}
                                                buttons={
                                                    isAdmin || (user.getId() === task.author.id && isCreateAdmin)
                                                        ? [
                                                              {
                                                                  onClick: () => {
                                                                      props.history.push(`/app/applications/tasks/${task.id}/edit/`)
                                                                  },
                                                                  icon: <EditIcon />,
                                                                  id: `${task.id}.edit`,
                                                              },
                                                          ]
                                                        : null
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                    </Box>
                </Box>
            </>
        )
    }

    return (
        <>
            <Grid container spacing={2} style={{ flexGrow: 1, flexDirection: 'row', alignContent: 'flex-start' }}>
                <NavigationBar
                    titles={[
                        {
                            name: 'My House',
                            link: '/app/dashboard/',
                            icon: <HomeIcon />,
                        },
                        {
                            name: 'Tasks',
                        },
                    ]}
                    rightButtons={
                        isAdmin || isCreateAdmin
                            ? [
                                  {
                                      name: 'Add',
                                      type: 'icon',
                                      innerIcon: <AddIcon />,
                                      onClick: () => {
                                          props.history.push('/app/applications/tasks/new/edit')
                                      },
                                  },
                              ]
                            : []
                    }
                    key={isAdmin || isCreateAdmin ? 'admin' : 'nah'}
                    grid
                />
                <Grid item xs={12}>
                    <Box
                        style={{
                            maxWidth: 480,
                            margin: `0 auto`,
                        }}
                    >
                        <Widget disableWidgetMenu inheritHeight bodyClass={classes.tabSection}>
                            {renderTabs()}
                        </Widget>
                    </Box>
                </Grid>
                {hasLoaded && tasksToShow.length === 0 ? (
                    <Grid
                        item
                        xs={12}
                        style={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 16,
                            height: 'calc(100vh - 194px)',
                        }}
                    >
                        <TumbleWeedOutlinedIcon style={{ fontSize: '7.5em', transform: 'rotate(-25deg)', color: theme.borderColor }} />
                        <Typography style={{ color: theme.borderColor }}>There are no tasks here</Typography>
                    </Grid>
                ) : (
                    tasksToShow.map(task => (
                        <Grid item xs={12} sm={6} lg={4} key={task.id}>
                            <TinyCard
                                onClick={() => props.history.push('/app/applications/tasks/' + task.id)}
                                text={task.name}
                                description={task.getStatus()}
                                author={{
                                    first: '',
                                    last: task.getAssigneesCaption(user, true),
                                    icon: <AssignedIcon />,
                                }}
                                variant={isMobileDevice ? 'outlined' : 'default'}
                                buttons={
                                    isAdmin || (user.getId() === task.author.id && isCreateAdmin)
                                        ? [
                                              {
                                                  onClick: () => {
                                                      props.history.push(`/app/applications/tasks/${task.id}/edit/`)
                                                  },
                                                  icon: <EditIcon />,
                                                  id: `${task.id}.edit`,
                                              },
                                          ]
                                        : null
                                }
                            />
                        </Grid>
                    ))
                )}
            </Grid>
        </>
    )
}
