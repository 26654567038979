import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    widget: {
        paddingTop: 8,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 6,
    },
    containerBox: {
        marginTop: 0,
        marginBottom: 0,
    },
    avatar: {
        marginRight: 8,
    },
    typography: {
        flexGrow: 1,
    },
    divider: {
        margin: `0 4px`,
    },
    selectableWidget: {
        paddingTop: 8,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 6,
        '&:hover': {
            background: 'rgba(150, 150, 150, 0.16)',
            cursor: 'pointer',
        },
    },
    selectableWidgetMobile: {
        color: theme.palette.text.primary,
        paddingTop: 8,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 6,
        width: '100%',
        fontSize: 'unset',
        justifyContent: 'unset',
    },
}))
