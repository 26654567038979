import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    text: {
        textAlign: `center`,
        marginBottom: 3,
        textDecoration: `none !important`,
    },
    underline: {
        height: 4,
        margin: `0 auto`,
        borderRadius: `3px 3px 0 0`,
        backgroundColor: theme.palette.primary.main,
    },
    underlineHidden: {
        height: 4,
        margin: `0 auto`,
        borderRadius: `3px 3px 0 0`,
    },
    selectableLink: {
        textDecoration: `none !important`,
        cursor: `pointer`,
        color: theme.palette.text.primary,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    disabledLink: {
        textDecoration: `none !important`,
        color: `${theme.palette.text.primary}80`,
        cursor: `default`,
    },
    selectedLink: {
        textDecoration: `none !important`,
        color: theme.palette.primary.main,
        cursor: `default`,
    },
    parentBox: {
        overflowX: 'scroll',
        scrollbarWidth: 0,
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': { display: 'none' },
    },
    tabOuterBox: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}))
