import { Box, Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import React from 'react'
import { Chip as CustomChip, Typography } from '../../Wrappers'

import { isMobileDevice } from 'code/Helper'
import { getEventTiming } from 'code/TimeAgo'

import { LocationOnOutlined as LocationIcon } from '@material-ui/icons'

import useMediaQuery from '@material-ui/core/useMediaQuery'

// styles
import useStyles from '../styles'

export default function Event(props) {
    var classes = useStyles()
    var theme = useTheme()

    const { event, showDate, showLength, showSmall } = props

    const e = event

    const atLeastMedium = useMediaQuery(theme.breakpoints.up('md'))

    if (!atLeastMedium || showSmall || isMobileDevice()) {
        // Return small version
        return (
            <>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap={'nowrap'}
                    style={{
                        overflowX: 'auto',
                        userSelect: 'none',
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <Typography
                            variant="h6"
                            style={{
                                fontWeight: 700,
                                fontSize: '1.142em',
                            }}
                            align="center"
                        >
                            {e.name}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Grid container direction="row">
                            <Grid item>
                                <Typography align="center" style={{ fontSize: '1em' }}>
                                    {getEventTiming(e, { includeLength: !showDate || showLength, onlyLength: !showDate })}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {(e.location !== '' || (e.cost && e.cost > 0) || e.categories.length > 0) && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        wrap={'nowrap'}
                        style={{
                            overflowX: 'auto',
                        }}
                    >
                        <Box display="flex" alignItems="center" style={{ overflow: 'hidden' }}>
                            {e.location !== '' && (
                                <Grid
                                    container
                                    direction="row"
                                    style={{
                                        paddingTop: '0.5em',
                                        paddingBottom: '0.5em',
                                        flexWrap: 'nowrap',
                                    }}
                                >
                                    <Grid
                                        item
                                        style={{
                                            height: '1.5em',
                                            marginLeft: '-3px',
                                        }}
                                    >
                                        <LocationIcon style={{ fontSize: '1.5em' }} />
                                    </Grid>
                                    <Grid item style={{ overflow: 'hidden' }}>
                                        <Typography style={{ fontSize: '1em' }} className={classes.locationTypography}>
                                            {e.location}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                        <Box display="flex" alignItems="center">
                            {e.cost && e.cost > 0 ? (
                                <CustomChip className={classes.chip} color="green" label={'$' + e.cost} style={{ marginRight: 0 }} />
                            ) : (
                                <></>
                            )}
                            {e.categories.map(category => (
                                <CustomChip
                                    key={`Event.${e.id}.${category.id}`}
                                    className={classes.chip}
                                    color={category.color}
                                    label={category.name}
                                    style={{ marginRight: 0 }}
                                />
                            ))}
                        </Box>
                    </Grid>
                )}
            </>
        )
    }

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap={'nowrap'}
                style={{
                    overflowX: 'auto',
                    userSelect: 'none',
                }}
            >
                <Box display="flex" alignItems="center">
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: 700,
                            fontSize: '1.142em',
                        }}
                    >
                        {e.name}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <Grid container direction="row">
                        <Grid item>
                            <Typography align="center" style={{ fontSize: '1em' }}>
                                {getEventTiming(e, { includeLength: !showDate || showLength, onlyLength: !showDate })}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {(e.location !== '' || (e.cost && e.cost > 0) || e.categories.length > 0) && (
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    wrap={'nowrap'}
                    style={{
                        overflowX: 'auto',
                    }}
                >
                    <Box display="flex" alignItems="center" style={{ overflow: 'hidden' }}>
                        {e.location !== '' && (
                            <Grid
                                container
                                direction="row"
                                style={{
                                    paddingTop: '0.5em',
                                    paddingBottom: '0.5em',
                                    flexWrap: 'nowrap',
                                }}
                            >
                                <Grid
                                    item
                                    style={{
                                        height: '1.5em',
                                        marginLeft: '-3px',
                                    }}
                                >
                                    <LocationIcon style={{ fontSize: '1.5em' }} />
                                </Grid>
                                <Grid item style={{ overflow: 'hidden' }}>
                                    <Typography className={classes.locationTypography} style={{ fontSize: '1em' }}>
                                        {e.location}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                    <Box display="flex" alignItems="center">
                        {e.cost && e.cost > 0 ? <CustomChip className={classes.chip} color="green" label={'$' + e.cost} style={{ marginRight: 0 }} /> : <></>}
                        {e.categories.map(category => (
                            <CustomChip
                                key={`Event.${e.id}.${category.id}`}
                                className={classes.chip}
                                color={category.color}
                                label={category.name}
                                style={{ marginRight: 0 }}
                            />
                        ))}
                    </Box>
                </Grid>
            )}
        </>
    )
}
