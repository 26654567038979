import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    uploadFile: {
        borderRadius: 4,
        border: `1px solid ${theme.palette.text.hint}`,
        width: `100%`,
        height: 256,
        '&:hover, &:focus': {
            borderColor: theme.palette.text.primary,
        },
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    uploadFilePreview: {
        flexGrow: 1,
        display: 'flex',
    },
    file: {
        height: 196,
        objectFit: 'cover',
        margin: '0 auto',
        borderRadius: 4,
        border: `1px solid ${theme.palette.text.hint}`,
    },
    uploadFileDesc: {
        display: 'flex',
        flexDirection: 'column',
    },
    uploadFileIcon: {
        margin: 'auto',
    },
}))
